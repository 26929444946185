import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../shared/components/button';
import { Forbidden } from '../../../../shared/components/forbidden';
import { HookField } from '../../../../shared/components/hookField';
import { SelectSuggestion } from '../../../../shared/components/select/model';
import { Typography } from '../../../../shared/components/typography';
import { useUpdateFromAttributesStyles } from '../styles';
import { StateProps } from './model';

export const View = ({
    form,
    onSubmit,
    onCancel,
    hadleClClick,
    hasAccess,
    isDefected,
    networkOperators,
}: StateProps) => {
    if (!hasAccess) {
        return <Forbidden />;
    }

    const {
        handleSubmit,
        formState: { isDirty, dirtyFields },
    } = form;
    const classes = useUpdateFromAttributesStyles();

    const networkOperatorUsersSuggestions = networkOperators.map<SelectSuggestion>(
        (item) => ({
            label: `[${item.id}] ${item.name}`,
            value: item.id,
        })
    );

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={3} className={classes.inputLabel}>
                                <Typography variant="bodyBold">
                  Платформа
                                    <br />
                  Центр2М
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <HookField
                                    fullWidth
                                    name="networkOperatorId"
                                    type="select"
                                    label="Платформа Центр2М"
                                    inputProps={{
                                        className: classNames({
                                            [classes.pristineField]: !dirtyFields[
                                                'networkOperatorId'
                                            ],
                                        }),
                                    }}
                                    suggestions={networkOperatorUsersSuggestions}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={3} className={classes.inputLabel}>
                                <Typography variant="bodyBold">ICCID</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <HookField
                                    name="iccId"
                                    type="text"
                                    label="ICCID"
                                    inputProps={{
                                        className: classNames({
                                            [classes.pristineField]: !dirtyFields['iccId'],
                                        }),
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={3} className={classes.inputLabel}>
                                <Typography variant="bodyBold">Сетевой адрес</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <HookField
                                    name="networkAddress"
                                    type="text"
                                    label="Сетевой адрес"
                                    inputProps={{
                                        className: classNames({
                                            [classes.pristineField]: !dirtyFields['networkAddress'],
                                        }),
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={3} className={classes.inputLabel}>
                                <Typography variant="bodyBold">Порт</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <HookField
                                    name="ipPort"
                                    type="text"
                                    label="Порт"
                                    inputProps={{
                                        className: classNames({
                                            [classes.pristineField]: !dirtyFields['ipPort'],
                                        }),
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {isDefected && (
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={3} />
                                <Grid item xs={9}>
                                    <HookField
                                        name="changeStatusToActive"
                                        type="checkbox"
                                        label="Исправить статус ПУ на активный"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={3}
                            justify="center"
                            className={classes.buttonContainer}
                        >
                            <Grid item>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={!isDirty && !isDefected}
                                    icon="check"
                                >
                  Сохранить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="secondary" type="button" onClick={onCancel}>
                  Отмена
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="primary" onClick={hadleClClick}>
                  Отправить CL
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
