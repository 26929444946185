import classNames from 'classnames';
import * as React from 'react';
export { ReactComponent as SortIcon } from 'assets/icons/sort.svg';

import './style.scss';

import MaterialTable from '@softmedialab/materialui-table';
import { InputModel } from './model';
import { Icon } from 'app/shared/components/icon';

export const View = (props: InputModel) => {
    return (
        <div id='powerSwitchOrdersTable' className={classNames('custom-table', {
            'white-table': props.variant === 'white',
            'gray-table': props.variant === 'gray',
        })}>
            <MaterialTable
                {...props}
                options={{
                    ...props.options,
                    headerSortIcon: () => <Icon name='sort' />
                }}
            />
        </div>
    );
};
