import { Chip, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Icon } from '../icon';
import { IconType } from '../icon/model';
import { StateProps } from './model';

const useStyles = makeStyles((theme) => ({
    badge: {
        textTransform: 'uppercase',
    },
    open: {
        color: theme.palette.system.success,
        borderColor: theme.palette.system.success,
        backgroundColor: theme.palette.neutral.white,
        '& svg': {
            height: '20px',
            strokeWidth: 0,
        }
    },
    success: {
        color: theme.palette.system.success,
        borderColor: theme.palette.system.success,
        backgroundColor: theme.palette.neutral.white,
    },
    fail: {
        color: theme.palette.system.error,
        borderColor: theme.palette.system.error,
        backgroundColor: theme.palette.neutral.white,
    },
    notDetermined: {
        color: theme.palette.text.hint,
        borderColor: theme.palette.text.hint,
        backgroundColor: theme.palette.neutral.white,
    },
}));

export const View = ({ state, text, stroke, showIcon = true }: StateProps) => {

    const classes = useStyles();

    let icon: IconType = null;
    switch (state) {
        case 'open':
            icon = 'open';
            break;
        case 'closed':
            icon = 'checkCircle';
            break;
        case 'success':
            icon = 'success';
            break;
        case 'fail':
            icon = 'warning';
            break;
        case 'notDetermined':
            icon = 'help';
            break;
        default:
            icon = null;
    }

    return (
        <Chip
            className={classNames(classes.badge, classes[state])}
            icon={showIcon ? <Icon name={icon} className={classes[state]} stroke={stroke || 'inherit'} /> : null}
            label={text}
            variant="outlined"
        />
    );
};
