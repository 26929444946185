import { createSelector } from 'reselect';
import { StorageState } from '../../reducers';

const baseSelector = (state: StorageState) => state.partners;

export const partnersListSelector = () => createSelector(
    baseSelector,
    resources => resources.list.available ?? [],
);

export const partnersDetailsSelector = () => createSelector(
    baseSelector,
    resources => resources.details.available,
);

export const partnersDetailsMetersSelector = () => createSelector(
    baseSelector,
    resources => resources.details.meters ?? [],
);

export const partnersDetailsMetersTotalSelector = () => createSelector(
    baseSelector,
    resources => resources.details.totalMeters ?? 0,
);
