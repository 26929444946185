import { Box, Divider, makeStyles } from '@material-ui/core';
import React from 'react';
import { HookField } from '../../../../shared/components/hookField';
import { Button } from '../../../../shared/components/button';
import { GisTaskFilterViewProps } from './model';
import { FormProvider } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: 'auto',
        backgroundColor: theme.palette.neutral.light,
        marginLeft: -16,
        marginRight: -16
    },
    whiteText: {
        color: theme.palette.neutral.white,
    },
    form: {
        height: '100%',
    },
    fieldsWrapper: {
        height: '100%',
    }
}));

export const View = ({
    form,
    onSubmit,
    onResetForm,
    statuses,
    onSearch,
    suggestions,
    usersAutocomplete,
    searchProps,
    foreignId,
}: GisTaskFilterViewProps) => {

    const classes = useStyles();
    const { handleSubmit } = form;

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <Box className={classes.fieldsWrapper} display='flex' flexDirection='column' gridGap='16px'>
                    <HookField
                        type='search'
                        name='search'
                        {...searchProps}
                    />
                    <HookField
                        type='select'
                        name='status'
                        label='Статус'
                        suggestions={statuses}
                        fullWidth
                        dark
                        isClearable
                    />
                    { !foreignId && (
                        <HookField
                            type='multiselect-with-search'
                            name='foreignId'
                            value={null}
                            placeholder='Заводской номер'
                            searchable={true}
                            onSearch={onSearch}
                            maxVisibleSelections={0}
                            values={suggestions}
                            fullWidth
                            dark
                        />
                    )}
                    <HookField
                        type='datepicker'
                        name='dateFrom'
                        label='От'
                        fullWidth
                        dark
                    />
                    <HookField
                        type='datepicker'
                        name='dateTo'
                        label='До'
                        fullWidth
                        dark
                    />
                    <HookField
                        type='autocomplete'
                        name='createdBy'
                        label='Кем создан'
                        fullWidth
                        dark
                        {...usersAutocomplete}
                    />

                    <Divider className={classes.divider} />
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Button type='submit' className='m-r-16' variant='primary'>Применить</Button>
                        <Button onClick={onResetForm} variant='secondary'>Очистить фильтры</Button>
                    </Box>
                </Box>
            </form>
        </FormProvider>
    );
};
