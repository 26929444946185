import React from 'react';
import { getPassword } from '../../../../../../redux/actions/password';
import { BlockItemProps } from '../model';
import { View } from './view';

export const BlockPassword: React.FC<BlockItemProps> = (props) => {
    if (props.item.type !== 'password') {
        return null;
    }

    const { passwordType, entityId } = props.item;

    return View({
        ...props,
        fetchPassword: (): Promise<string> => {
            return getPassword(passwordType, entityId);
        },
    });
};
