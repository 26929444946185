import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { MeterPartnerViewModel } from '../../../../../common/model/meter/meter/meterPartnerViewModel';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Button } from '../../../../shared/components/button';
import { Heading } from '../../../../shared/components/heading';
import { DetailsButtonView } from '../../../../shared/components/helpers/detailsButtonView';
import { HookField } from '../../../../shared/components/hookField';
import { Pagination } from '../../../../shared/components/pagination';
import { Table } from '../../../../shared/components/table';
import { Wrapper } from '../../../../shared/components/wrapper';
import { setColumnWidth } from '../../../../shared/pipes/styles';
import { IStateProps } from './model';
import { FormProvider } from 'react-hook-form';
import { CrqSystemTable } from './components/crqSystemTable';
import { readingSourceDictionary } from '../../../../../common/model/meter/readingSource';

export const ReadingSourceDetailsView: React.FC<{ item: MeterPartnerViewModel; onClick: () => void; }> = ({ item, onClick }) => {
    return (
        <div className='readingSource'>
            <div className="readingSource__zone">
                {readingSourceDictionary[item.readingSource] || ''}
            </div>
            <div className="readingSource__details">
                <DetailsButtonView
                    boxProps={{ justifyContent: 'flex-start' }}
                    title='Перейти'
                    onClick={onClick}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    addButtonWrapper: {
        marginLeft: 'auto',
    },
    tableCols: {
        '&.col-meterModel': {
            ...setColumnWidth('120px'),
        },
        '&.col-meterSerialNum': {
            ...setColumnWidth('160px'),
        },
        '&.col-code': {
            ...setColumnWidth('160px'),
        },
        '&.col-address': {
            ...setColumnWidth('472px'),
        },
        '&.col-cTRatio': {
            ...setColumnWidth('120px'),
        },
        '&.col-pTRatio': {
            ...setColumnWidth('120px'),
        },
        '&.col-timeZone': {
            ...setColumnWidth('100px'),
        },
        '&.col-readingSource': {
            ...setColumnWidth('120px'),
        },
    },
    tableRow: {
        '&:not(:hover) .readingSource__details': {
            display: 'none',
        },
        '&:hover .readingSource__zone': {
            display: 'none',
        },
    },
    addMeterButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const classes = useStyles();
    const {
        form,
        data,
        dataTotal,
        setPage,
        pagination,
        heading,
        onSubmit,
        breadcrumbs,
        onClickAddMeter,
        metersColumns,
        onBackHandler,
        onClickBreadcrumb,
        isSaveDisabled,
        isNewPartner
    } = props;
    const { handleSubmit } = form;

    const metersBlockActions = (
        <Grid container spacing={2} justify='space-between'>
            {onClickAddMeter && (
                <Grid item className={classes.addMeterButton}>
                    <Button
                        variant='primary'
                        type='button'
                        icon='add'
                        onClick={onClickAddMeter}
                    >
                        Новый ПУ
                    </Button>
                </Grid>
            )}
        </Grid>
    );

    return (
        <div>
            <Box mb={1}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2}>
                <Heading heading={heading} onBackHandler={onBackHandler} />
            </Box>
            <Box mb={2}>
                <Wrapper title='ОБЩАЯ ИНФОРМАЦИЯ'>
                    <FormProvider {...form}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item lg={3}>
                                    <HookField
                                        fullWidth
                                        name='name'
                                        placeholder='Название'
                                        type='text'
                                    />
                                </Grid>
                                <Grid item lg={3}>
                                    <HookField
                                        fullWidth
                                        name='innCode'
                                        placeholder='ИНН'
                                        type='text'
                                    />
                                </Grid>
                                <Grid item lg={6}>
                                    <Button
                                        variant='primary'
                                        type='submit'
                                        disabled={isSaveDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </FormProvider>
                </Wrapper>
            </Box>

            <CrqSystemTable isNewPartner={isNewPartner} />

            <Box>
                <Wrapper actions={metersBlockActions} title='ПРИБОРЫ УЧЕТА ПАРТНЕРА' isTitleFullWidth>
                    <Table
                        title={null}
                        data={data ?? []}
                        columns={metersColumns}
                        variant='white'
                        options={{
                            headerClassName: classes.tableCols,
                            rowClassName: classes.tableRow,
                            paging: 'disabled',
                            grouping: false,
                            filtering: false,
                            search: false,
                            toolbar: false,
                            showTitle: false,
                            sorting: false,
                        }}
                    />
                    <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={!data} />

                </Wrapper>
            </Box>
        </div>
    );
};
