import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { MeterModelUpdater } from '../../../../../common/model/meter/meterModel/meterModelUpdater';
import { resourcesTimeSynchronizationSchemesSelector } from '../../../../../redux/selectors';
import { InputProps } from './model';
import { View } from './view';

export const MeterModelForm: React.FC<InputProps> = (props) => {
    const { meterModel } = props;

    const defaultValues: MeterModelUpdater = {
        isSinglePhase: meterModel.isSinglePhase,
        timeSynchronizationSchemeId: meterModel.timeSynchronizationSchemeId,
        allowChangePassword: meterModel.allowChangePassword,
    };

    const form = useForm<MeterModelUpdater>({ defaultValues });

    const timeSynchronizationSchemes = useSelector(resourcesTimeSynchronizationSchemesSelector());

    return View({
        ...props,
        form,
        timeSynchronizationSchemes,
    });
};
