import { useState } from 'react';
import { View } from './view';

export const PasswordInputText = ({ password }: { password: string }) => {
    const [values, setValues] = useState({
        password,
        showPassword: false
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: true });
    };

    const handleClickHidePassword = () => {
        setValues({ ...values, showPassword: false });
    };

    return View({ values, handleClickShowPassword, handleClickHidePassword });
};
