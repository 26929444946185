import { StorageState } from '../../reducers';
import { createSelector } from 'reselect';

const userSelector = (state: StorageState) => state.users.active.user;
const usersSelector = (state: StorageState) => state.users.list;

export const activeUserSelector = () => createSelector(userSelector, user => user);

export const usersListSelector = createSelector(usersSelector, list => list.users);
export const usersListTotalSelector = createSelector(usersSelector, list => list.total);
export const editableUserSelector = createSelector(
    (state: StorageState) => state.users.editable,
    editable => editable.user
);
export const usersAllListSelector = createSelector(usersSelector, list => list.allUsers);
