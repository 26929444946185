export enum MeterReadingInstantOrderStatus {
    Success = 'Success',
    Error = 'Error',
    TTLExpired = 'TTLExpired',
    OperationNotAvailable = 'OperationNotAvailable',
    InvalidMeterStatus = 'InvalidMeterStatus',
    MeterNotFound = 'MeterNotFound',
    Created = 'Created',
    RejectedDueToPendingOrder = 'RejectedDueToPendingOrder',
    OutOfWorkTime = 'OutOfWorkTime',
}

export const meterReadingInstantOrderStatusDictionary: Record<MeterReadingInstantOrderStatus, string> = {
    [MeterReadingInstantOrderStatus.Created]: 'Создано',
    [MeterReadingInstantOrderStatus.Error]: 'Неизвестная ошибка',
    [MeterReadingInstantOrderStatus.InvalidMeterStatus]: 'Неверный статус счетчика',
    [MeterReadingInstantOrderStatus.MeterNotFound]: 'Счетчик не найден',
    [MeterReadingInstantOrderStatus.OperationNotAvailable]: 'Операция не доступна',
    [MeterReadingInstantOrderStatus.OutOfWorkTime]: 'Операция в данный момент не доступна',
    [MeterReadingInstantOrderStatus.RejectedDueToPendingOrder]: 'Отклонено тк операция уже запущена',
    [MeterReadingInstantOrderStatus.Success]: 'Успешно',
    [MeterReadingInstantOrderStatus.TTLExpired]: 'Время жизни задания истекло',
};
