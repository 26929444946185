import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MeterUpdateAttributesActions } from '../../../../../common/model/meter/meter/meterUpdateAttributesActions';
import { BaseDispatch } from '../../../../../redux/actions';
import { getMeter } from '../../../../../redux/actions/meters';
import { meterSelector, meterUpdateLoadingSelector } from '../../../../../redux/selectors/meters';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { actionQueryParam } from '../model';
import { View } from './view';

export const MeterAttributesUpdateModal = () => {

    const { meterId } = useParams<{ meterId: string }>();
    const meter = useSelector(meterSelector);

    const queryParams = useQueryParams();
    const action = queryParams.get(actionQueryParam) as MeterUpdateAttributesActions;

    const dispatch = useDispatch<BaseDispatch>();

    const meterUpdateLoading = useSelector(meterUpdateLoadingSelector);

    useEffect(() => {
        if (!meter || meter.id !== meterId) {
            dispatch(getMeter(meterId));
        }
    }, [meterId, meter]);

    const handleCloseModal = () => {
        queryParams.delete(actionQueryParam);
        dispatch(push({
            search: queryParams.toString()
        }));
    };

    return View({ action, handleCloseModal, isLoading: meterUpdateLoading });
};
