import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TariffScheduleViewModel } from '../../../../common/model/meter/tariffSchedule/tariffScheduleViewModel';
import { TariffScheduleVersionViewModel } from '../../../../common/model/meter/tariffScheduleVersion/tariffScheduleVersionViewModel';

export interface TariffScheduleEditableState {
    tariffSchedule: TariffScheduleViewModel;
    tariffScheduleVersion: TariffScheduleVersionViewModel;
}

const initialState: TariffScheduleEditableState = {
    tariffSchedule: null,
    tariffScheduleVersion: null,
};

export const tariffScheduleEditableSlice = createSlice({
    name: '@@vv/tariffSchedule/editable',
    initialState,
    reducers: {
        setTariffSchedule(state, action: PayloadAction<TariffScheduleViewModel>) {
            state.tariffSchedule = action.payload;
        },
        setTariffScheduleVersion(state, action: PayloadAction<TariffScheduleVersionViewModel>) {
            state.tariffScheduleVersion = action.payload;
        },
    }
});
