import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PartnerViewModel } from '../../../common/model/meter/partners/partnerViewModel';
import { BaseDispatch } from '../../../redux/actions';
import { getPartner } from '../../../redux/actions/partners';
import { partnersDetailsSelector } from '../../../redux/selectors/partners';
import { RelatedSystemsTabs, Routes } from '../../shared/constants';
import { getRoute } from '../../shared/pipes';

export interface PartnerResolverChildProps {
    partner: PartnerViewModel;
}

export const PartnerResolver = (viewComponent: React.FC<any>) => {
    return (props: any) => {
        const dispatch = useDispatch<BaseDispatch>();
        const partner = useSelector(partnersDetailsSelector());
        const { partnerId } = useParams<{ partnerId: string; }>();

        React.useEffect(() => {
            dispatch(getPartner(+partnerId));
        }, [partnerId]);

        React.useEffect(() => {
            if (partner !== null && !partner) {
                dispatch(push(getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.Partners })));
            }
        }, [partner]);

        if (!partner) {
            return null;
        }

        return React.createElement(viewComponent, {
            ...props,
            partner,
        });
    };
};
