import { combineReducers } from 'redux';

import { dictionariesSlice, DictionariesState } from './dictionaries';
import { regionsSlice, RegionsState } from './regions';
import { resourcesSettingsSlice, ResourcesSettingsState } from './settings';

export interface ResourcesState {
    dictionaries: DictionariesState;
    settings: ResourcesSettingsState;
    regions: RegionsState;
}

export const resources = combineReducers<ResourcesState>({
    dictionaries: dictionariesSlice.reducer,
    settings: resourcesSettingsSlice.reducer,
    regions: regionsSlice.reducer,
});
