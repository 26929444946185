import React from 'react';
import { GisDictionaryViewProps } from './model';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Table } from '../../../../shared/components/table';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { setColumnWidth } from '../../../../shared/pipes/styles';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Heading } from '../../../../shared/components/heading';
import { Button } from '../../../../shared/components/button';
import { Modal } from '../../../../shared/components/modal';
import { CreateTaskNameForm } from './createForm';
import { EditTaskNameForm } from './editForm';
import { HookField } from '../../../../shared/components/hookField';
import { FormProvider } from 'react-hook-form';
import { ActionModal } from '../../../../shared/components/actionModal';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '20px',
        '& .MuiCheckbox-root.Mui-checked': {
            color: theme.palette.neutral.light,
        },
    },
    tableRow: {
        '&:not(:hover) button': {
            visibility: 'hidden',
        },
    },
    tableCols: {
        '&.cell-id': {
            ...setColumnWidth('80px'),
        },
        '&.cell-title': {
            ...setColumnWidth('320px'),
        },
    },
    newOrderButton: {
        position: 'absolute',
        right: 40,
    },
    tableContainer: {
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
        },
    },
    buttonContainer: {
        paddingTop: '16px',
    },
}));

export const View: React.FC<GisDictionaryViewProps> = ({
    tasksName,
    columns,
    heading,
    onBackHandler,
    onClickBreadcrumb,
    breadcrumbs,
    isCreateModalOpen,
    isEditModalOpen,
    isDeleteModalOpen,
    onClickNewTaskName,
    onCloseModal,
    onCreateSubmit,
    currentTaskName,
    onUpdatedSubmit,
    onDeleteSubmit,
    form,
    tasksNameAutocomplete,
}) => {
    const classes = useStyles();
    const tableActionRender = (
        <Grid container spacing={2}>
            <Grid item>
                <Box width='320px'>
                    <FormProvider {...form}>
                        <form>
                            <HookField
                                type='autocomplete'
                                name='search'
                                label='Тип инцидентов'
                                fullWidth
                                {...tasksNameAutocomplete} />
                        </form>
                    </FormProvider>
                </Box>

            </Grid>
            <Grid item>
                <Button
                    icon='add'
                    variant='primary'
                    className={classes.newOrderButton}
                    onClick={onClickNewTaskName}
                >
                    Новый тип
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Box mb={2} marginBottom='0'>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box display='flex' alignItems='center' marginTop='8px'>
                <Heading heading={heading} onBackHandler={onBackHandler} />
            </Box>
            <Wrapper
                className={classes.tableWrapper}
                isBigHeader
                actions={tableActionRender}
                isHeaderUppercase={false}
            >
                <Box className={classes.tableContainer}>
                    <Table
                        title={null}
                        data={tasksName}
                        columns={columns}
                        variant='white'
                        useQueryString={true}
                        options={{
                            headerClassName: classes.tableCols,
                            paging: 'disabled',
                            grouping: false,
                            filtering: false,
                            search: false,
                            toolbar: false,
                            showTitle: false,
                            rowClassName: classes.tableRow,
                        }}
                    />
                </Box>
            </Wrapper>

            {isCreateModalOpen && (
                <Modal title='Новый тип инцидента' close='Отмена' onHide={onCloseModal} width='513px'>
                    <CreateTaskNameForm
                        onSubmit={onCreateSubmit}
                        onCancel={onCloseModal}
                    />
                </Modal>
            )}
            {isEditModalOpen && (
                <Modal title='Наименование типа инцидента' close='Отмена' onHide={onCloseModal} width='513px'>
                    <EditTaskNameForm
                        onSubmit={onUpdatedSubmit}
                        onCancel={onCloseModal}
                        taskName={currentTaskName}
                    />
                </Modal>
            )}
            {isDeleteModalOpen && (
                <ActionModal
                    title='Удаление типа инцидента'
                    description={`Вы уверены, что хотите удалить тип '${currentTaskName.value}' ?`}
                    actions={[
                        {
                            onClick: onDeleteSubmit,
                            title: 'Да, удалить',
                            variant: 'primary',
                        },
                        {
                            onClick: onCloseModal,
                            title: 'Отмена',
                            variant: 'secondary',
                        }
                    ]}
                    closeModal={onCloseModal}
                />
            )}
        </>
    );
};
