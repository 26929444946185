import { Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../shared/components/button';
import { HookField } from '../../../../shared/components/hookField';
import { SelectSuggestion } from '../../../../shared/components/select/model';
import { Typography } from '../../../../shared/components/typography';
import { IStateProps } from './model';
import { PasswordRequirementsTable } from './passwordRequirementsTable';

export const useStyles = makeStyles((theme) => ({
    pristineField: {
        color: theme.palette.neutral.light,
    },
    inputLabel: {
        textAlign: 'right',
    },
    buttonContainer: {
        paddingTop: '16px',
    },
    meterModelName: {
        ...theme.typography.bodyBold,
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const { form, onSubmit, onCancel, timeSynchronizationSchemes, meterModel, isLoading } = props;
    const classes = useStyles();
    const { handleSubmit, formState: { isDirty, dirtyFields } } = form;

    const timeSynchronizationSchemesSuggestion: SelectSuggestion[] = timeSynchronizationSchemes
        .map(item => ({ label: item.name, value: item.id }));

    return (
        <FormProvider { ...form }>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Тип прибора<br />
                                    учета
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.meterModelName}>
                                    {meterModel.shortName}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Количество фаз
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='isSinglePhase'
                                    type='radio'
                                    isHorizontalGroup
                                    transform={{
                                        output: (v) => {
                                            return v?.toString() === 'true';
                                        },
                                    }}
                                    disabled={isLoading}
                                    values={[
                                        {
                                            id: true,
                                            name: 'Однофазный',
                                        },
                                        {
                                            id: false,
                                            name: 'Трехфазный',
                                        }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Схема синхронизации<br />
                                    времени по умолчанию
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='timeSynchronizationSchemeId'
                                    type='select'
                                    label='Название'
                                    suggestions={timeSynchronizationSchemesSuggestion}
                                    isClearable
                                    disabled={isLoading}
                                    inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['timeSynchronizationSchemeId'] }) }}
                                    fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                    {meterModel.isReadable && (
                        <>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={4} className={classes.inputLabel}>
                                        <Typography variant='bodyBold'>
                                            Разрешено менять <br />
                                            пароли
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <HookField
                                            name='allowChangePassword'
                                            type='radio'
                                            isHorizontalGroup
                                            disabled={isLoading}
                                            transform={{
                                                output: (v) => {
                                                    return v?.toString() === 'true';
                                                },
                                            }}
                                            values={[
                                                {
                                                    id: true,
                                                    name: 'Да',
                                                },
                                                {
                                                    id: false,
                                                    name: 'Нет',
                                                }
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {meterModel.passwordRequirements && (
                                <PasswordRequirementsTable passwordRequirements={meterModel.passwordRequirements} />
                            )}
                        </>
                    )}
                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                            <Grid item>
                                <Button variant='primary' type='submit' disabled={!isDirty || isLoading} icon='check'>Сохранить</Button>
                            </Grid>
                            <Grid item>
                                <Button variant='secondary' type='button' disabled={isLoading} onClick={onCancel}>Отмена</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
