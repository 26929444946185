import { incrementalProfilesListSlice, IncrementalProfilesListState } from './list';
import { incrementalProfilesChartSlice, IncrementalProfilesChartState } from './chart';
import { combineReducers } from 'redux';


export interface IncrementalProfilesState {
    list: IncrementalProfilesListState;
    chart: IncrementalProfilesChartState;
}

export const incrementalProfiles = combineReducers<IncrementalProfilesState>({
    list: incrementalProfilesListSlice.reducer,
    chart: incrementalProfilesChartSlice.reducer,
});
