import React from 'react';
import { DateFormats } from '../../../../../../common/constants/date';
import { SettingViewModel } from '../../../../../../common/model/resources/settings/settingViewModel';
import { formatDate } from '../../../../../shared/utils/dates';

export interface Props {
    setting: SettingViewModel;
}

export const ChangedColumn: React.FC<Props> = ({
    setting: { changedAt, user }
}) => {
    if (!changedAt && !user) {
        return <span>-</span>;
    }
    return (
        <div>
            {changedAt && (<div>{formatDate(changedAt, DateFormats.dateFullTimeFormat)}</div>)}
            {user && (<div>{user.name || user.email}</div>)}
        </div>
    );
};
