import { Button } from '../../../../shared/components/button';
import React from 'react';
import { MetersActionsProps } from './model';
import './style.scss';

export const MetersActions = (props: MetersActionsProps) => {
    const { actions } = props;
    return <div className='meters-actions'>
        {actions.map(({ square = true, text, variant = 'outline', label, onPress, icon }) => {
            return (<div key={label}>
                <Button className='m-r-8' variant={variant} icon={icon} square={square} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onPress();
                }}>{text}</Button>
            </div>);
        })}
    </div>;
};
