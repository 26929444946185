import { makeStyles } from '@material-ui/core';
import React from 'react';
import { SurveyConditionMetersData } from '../../../../../../common/model/meter/monitoring/surveryCondition';
import { Icon } from '../../../../../shared/components/icon';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        alignItems: 'center',
        ...theme.typography.bodyBold,
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
}));

interface Props {
    meter: SurveyConditionMetersData;
    onClick: () => void;
    meterModel: string;
}

export const MeterNameColumn: React.FC<Props> = ({ meter, onClick, meterModel }) => {
    const classes = useStyles();
    return (
        <div className={classes.link} onClick={onClick}>
            {meterModel} №{meter.meterSerialNum} <Icon name="arrowBigRight" color="error" />
        </div>
    );
};
