import { AppThunk } from '../index';
import { appUrls } from '../../../common/appUrls';
import { getApiRoute } from '../../../app/shared/pipes';
import { httpService } from '../../../app/shared/httpWrapper';
import { MeterPowerSwitchCounters } from '../../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchCounters';
import { meterPowerSwitchCountersSlice } from '../../reducers/meterPowerSwitchCounters';

export const getMeterPowerSwitchCounters = (): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_power_switch_counters);
    const data = await httpService.get<MeterPowerSwitchCounters>(route);
    dispatch(meterPowerSwitchCountersSlice.actions.setMeterPowerSwitchCounters(data));
};
