import React, { useEffect, useMemo } from 'react';
import { usePagination } from '../../../shared/components/pagination';
import { View } from './view';
import { EventLogViewModel } from '../../../../common/model/meter/eventLog/eventLogViewModel';
import { getRoute, RouterParams } from '../../../shared/pipes';
import { MeterTabs, Routes } from '../../../shared/constants';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { DateFormats } from '../../../../common/constants/date';
import { CriticalEventLogFindRequest } from '../../../../common/model/meter/eventLog/eventLogFindRequest';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import { findCriticalLevelHighEventLogs } from '../../../../redux/actions/eventLogs';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { InitialFilterCriticalEvent } from './constants';
import {
    commonCriticalEventHighLevel,
    commonCriticalEventHighLevelTotal,
} from '../../../../redux/selectors/eventLogs';
import { useForm } from 'react-hook-form';
import { useEventTypeAutocomplete } from '../../../shared/components/autocomplete/hooks/useEventTypeAutocomplete';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { push } from 'connected-react-router';
import { ISort } from '../../../../common/sort';
import { useTheme } from '@material-ui/core';

const initialFilterForm: CriticalEventLogFindRequest = {
    dateFrom: undefined,
    dateTo: undefined,
    eventType: [],
};

const searchIsVisibleEventTypes = true;

export const UnlegalAccess = () => {
    const theme = useTheme();
    const queryParams = useQueryParams();
    const eventTypeFilter = queryParams.get('eventType') || undefined;
    const dateFrom = queryParams.get('dateFrom');
    const dateTo = queryParams.get('dateTo');
    const dispatch = useDispatch<BaseDispatch>();
    const form = useForm<CriticalEventLogFindRequest>({ defaultValues: initialFilterForm });
    const params = useParams<{meterId?: string}>();
    const eventTypeAutocomplete = useEventTypeAutocomplete(searchIsVisibleEventTypes);

    const filterValues = useMemo(() => {
        return ({
            ...InitialFilterCriticalEvent,
            eventType: eventTypeFilter ? eventTypeFilter.split(',') : [],
            dateTo,
            dateFrom,
            // TODO add count param for infinite scroll
        });
    }, [eventTypeFilter, dateFrom, dateTo]);

    const data = useSelector(commonCriticalEventHighLevel);
    const dataTotal = useSelector(commonCriticalEventHighLevelTotal);

    const fetch = async (sort: ISort) => {
        await dispatch(findCriticalLevelHighEventLogs({ ...filterValues, ...sort }));
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 10,
        orderBy: null,
        orderDirection: null,
    }, fetch);

    const refreshTableData = () => {
        onFetch(pagination);
    };

    const updateData = () => {
        refreshTableData();
    };

    useEffect(() => {
        refreshTableData();
    }, [filterValues]);

    const columnDefinition = [
        {
            title: 'Событие',
            field: 'eventTypeId',
            sorting: true,
            render: (dataItem: EventLogViewModel) => {
                const { eventType } = dataItem;
                return eventType?.description;
            },
        },
        {
            title: 'Дата',
            field: 'eventDateTime',
            sorting: true,
            render: (dataItem: EventLogViewModel) => moment(dataItem.eventDateTime).format(DateFormats.dateFullTimeFormat),
        },
        {
            title: 'Прибор учета',
            field: 'meterId',
            sorting: true,
            render: (dataItem: EventLogViewModel) => {
                if (!dataItem.meterName) {
                    return `Счётчик не найден (${dataItem.meterId})`;
                }
                const route = getRoute(Routes.meter, { meterId: dataItem.meterId }, { tab: MeterTabs.Information });
                return <Link style={{ color: theme.palette.primary.light }} to={route}>{dataItem.meterName}</Link>;
            }
        },
    ];

    const onSubmit: SubmitHandler<CriticalEventLogFindRequest> = (values) => {
        const { dateFrom, dateTo } = values;

        const dateFromStr = dateFrom ? moment(dateFrom, DateFormats.dateFormatNative).format(DateFormats.dateFormatNative) : null;
        const dateToStr = dateTo ? moment(dateTo, DateFormats.dateFormatNative).format(DateFormats.dateFormatNative) : null;

        const filterQueryParams: RouterParams = {
            eventType: values.eventType && values.eventType.length ? values.eventType.toString() : undefined,
            dateFrom: dateFromStr,
            dateTo: dateToStr,
        };

        const route = params.meterId
            ? getRoute(Routes.meter, { meterId: params.meterId }, {
                tab: MeterTabs.EventLog,
                ...filterQueryParams,
            })
            : getRoute(Routes.monitoring, {}, { tab: 'unlegalAccess', ...filterQueryParams });

        dispatch(push(route));
    };

    useEffect(() => {
        const subscription = form.watch((formData: unknown) => {
            const timeoutBeforeRequest = 300;
            setTimeout(() => onSubmit(formData), timeoutBeforeRequest);
        });
        return () => subscription.unsubscribe();
    }, [form.watch]);


    return View({
        eventTypeAutocomplete,
        columnDefinition,
        data,
        dataTotal,
        pagination,
        setOrder,
        setPage,
        orderParams,
        onSubmit,
        form,
        filterValues,
        updateData,
    });
};
