import { View } from './view';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from 'redux/actions';
import { fileToBase64 } from 'app/shared';
import { UploaderFile } from 'app/shared/components/uploader/uploaderFile/model';
import { SnackbarNotification } from 'redux/reducers/controls/snackbar';
import { createSnackbar } from 'redux/actions/controls';
import { postImportMeterActivationOrder } from 'redux/actions/import';

export const ImportMeterActivationOrders = () => {
    const dispatch = useDispatch<BaseDispatch>();
    return View({
        onSubmit: (files: UploaderFile[]) => {
            return files.map(file => ({
                file,
                promise: async () => {
                    const base64 = await fileToBase64(file.file);
                    const response = await postImportMeterActivationOrder({ file: {
                        base64,
                        filename: file.file.name,
                    } });
                    const options: SnackbarNotification = {
                        message: `Загрузка файла ${file.file.name} выполнена успешно: ${response.message}`,
                        type: 'green',
                    };
                    dispatch(createSnackbar(options));
                }
            }));
        },
        onUploadEnd: () => {
            const options: SnackbarNotification = {
                message: 'Загрузка всех файлов выполнена успешно, наблюдайте окончание ввода ПУ на странице списка заявок на Ввод ПУ.',
                type: 'green',
            };
            dispatch(createSnackbar(options));
        },
    });
};
