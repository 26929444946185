import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionsObject } from 'notistack';

export interface SnackbarNotification {
    key?: string | number;
    message: string;
    type: 'white' | 'dark' | 'red' | 'green';
    delay?: number;
    dismissAll?: boolean;
    dismissed?: boolean;
    options?: OptionsObject;
}

export interface SnackbarClose {
    dismissAll?: boolean;
    key: string | number;
}

export interface SnackbarRemove {
    key: string | number;
}


export interface SnackbarState {
    notifications: SnackbarNotification[];
    displayed: any[];
}

const initialState: SnackbarState = {
    notifications: [],
    displayed: [],
};

export const snackbarSlice = createSlice({
    name: '@@snackbar',
    initialState,
    reducers: {
        add(state, action: PayloadAction<SnackbarNotification>): void {
            state.notifications.push({
                key: action.payload.key,
                ...action.payload
            });
        },
        close(state, action: PayloadAction<SnackbarClose>): void {
            state.notifications = state.notifications.map(notification => {
                return (action.payload.dismissAll || notification.key === action.payload.key)
                    ? { ...notification, dismissed: true }
                    : { ...notification };
            });
        },
        addDisplayed(state, action: PayloadAction<string | number>): void {
            state.displayed.push(action.payload);
        },
        removeDisplayed(state, action: PayloadAction<string | number>): void {
            const index = state.displayed.findIndex(item => item === action.payload);
            state.displayed.splice(index, 1);
        },
        remove(state, action: PayloadAction<SnackbarRemove>): void {
            state.notifications = state.notifications.filter(
                notification => notification.key !== action.payload.key,
            );
        }
    }
});
