import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { Breadcrumbs } from '../../../shared/components/breadcrumbs';
import { Heading } from '../../../shared/components/heading';
import { MeterModelForm } from './meterModelForm';
import { IStateProps } from './model';
import { Table } from '../../../shared/components/table';
import { Wrapper } from '../../../shared/components/wrapper';
import { Modal } from '../../../shared/components/modal';

const useStyles = makeStyles(() => ({
    tableRow: {
        '&:not(:hover) button': {
            visibility: 'hidden',
        },
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const {
        data,
        columns,
        onSubmit,
        editableMeterModel,
        onCloseEditMeterModel,
        breadcrumbs,
        onClickBreadcrumb,
        onBackHandler,
        heading,
        isLoading,
    } = props;
    const classes = useStyles();

    return <>
        <Box mb={2}>
            <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
        </Box>
        <Box mb={2}>
            <Heading heading={heading} onBackHandler={onBackHandler} />
        </Box>
        <Box>
            <Wrapper>
                <Table
                    data={data}
                    title={null}
                    variant='white'
                    columns={columns}
                    options={{
                        paging: 'disabled',
                        search: false,
                        toolbar: false,
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        sorting: true,
                        showTitle: false,
                        rowClassName: classes.tableRow,
                    }}
                />
            </Wrapper>
        </Box>
        {editableMeterModel && (
            <Modal
                alignDialog='center'
                size='mdl'
                close='Отмена'
                onHide={onCloseEditMeterModel}
                title='Название типа прибора учета'
            >
                <MeterModelForm
                    meterModel={editableMeterModel}
                    onCancel={onCloseEditMeterModel}
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                />
            </Modal>
        )}
    </>;
};
