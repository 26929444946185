import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { meterLabelsSelector } from '../../../../../redux/selectors/meters';

import './style.scss';

export const ProblemTitle = () => {

    const meterLabelsBase = useSelector(meterLabelsSelector);
    const meterLabels = useMemo(() => {
        return (meterLabelsBase || []).filter(item => !item.closedAt);
    }, [meterLabelsBase]);

    return <div className='problem-tab-label-container'>
        <span>Проблемы</span>
        {meterLabels?.length ? <div className='problem-count-container'>{meterLabels.length }</div> : null}
    </div>;
};
