import { Grid, makeStyles } from '@material-ui/core';
import { HookField } from '../../../../shared/components/hookField';
import { FormProvider } from 'react-hook-form';
import React from 'react';
import { groupTypeTransformer } from '../model';
import { MeterGroupFormProps } from './model';
import { Button } from '../../../../shared/components/button';
import { useWebDictionarySelectSuggestions } from '../../../../shared/hooks/useWebDictionary';
import { groupTypeSuggestions } from '../index';

const useStyles = makeStyles(() => ({
    form: {
        width: '100%',
    },
    checkboxWrapper: {
        marginLeft: 'auto',
    },
}));

export const View = (props: MeterGroupFormProps) => {
    const {
        form,
        onSubmitGroup,
        groupId,
        isDisabledSave,
        disabledIsStatic,
    } = props;
    const {
        handleSubmit,
        formState: { isDirty },
    } = form;
    const classes = useStyles();
    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmitGroup)} className={classes.form}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xl={2} md={3} sm={4} xs={12}>
                        <HookField
                            type="select"
                            name="regionId"
                            label="Регион"
                            fullWidth
                            suggestions={regionSuggestions}
                        />
                    </Grid>
                    <Grid item xl={2} md={3} sm={4} xs={12}>
                        <HookField
                            type="text"
                            name="name"
                            label="Название группы"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xl={3} md={3} sm={4} xs={12}>
                        <HookField
                            type="select"
                            name="isStatic"
                            label="Тип группы"
                            fullWidth
                            disabled={disabledIsStatic}
                            suggestions={groupTypeSuggestions}
                            transform={groupTypeTransformer}
                        />
                    </Grid>
                    <Grid item className={classes.checkboxWrapper}>
                        <HookField
                            type="checkbox"
                            name="visibleToAll"
                            label="Показывать всем"
                        />
                    </Grid>
                    <Grid item>
                        {groupId ? (
                            <Button
                                variant="outline"
                                disabled={!isDirty && isDisabledSave}
                                type="submit"
                            >
                Сохранить
                            </Button>
                        ) : (
                            <Button variant="primary" icon="add" type="submit">
                Создать группу
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
