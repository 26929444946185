import React from 'react';
import { Wrapper } from 'app/shared/components/wrapper';
import { HookField } from 'app/shared/components/hookField';
import { ImportCrqViewProps } from './model';
import { FormProvider } from 'react-hook-form';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from 'app/shared/components/button';
import { meterFileImportStatuses } from 'app/shared/constants/enums';
import { Modal } from 'app/shared/components/modal';
import { ImportCrqUploader } from './components/importCrqUploader';
import { ImportCrqTable } from './components/importCrqTable';

const useStyles = makeStyles(() => ({
    form: {
        display: 'flex',
        width: '100%',
        marginLeft: 40,
        flex: 1,
    },
    modal: {
        maxWidth: 644,
        '& .modal-title': {
            fontWeight: 400,
            fontSize: 14,
        },
        '& .modal-header': {
            justifyContent: 'flex-start',
            marginBottom: -28,
        },
        '& .modal-body': {
            padding: '0 16px!important',
        }
    },
    statusSelect: {
        minWidth: 240
    },
    uploadBtn: {
        marginLeft: 16
    }
}));

export const View: React.FC<ImportCrqViewProps> = ({
    form,
    isVisibleUploadModal,
    onOpenUploadModal,
    onCloseUploadModal,
    data,
    isLoading,
    setOrder,
    orderParams,
    isDisabledUploadFile,
    updateData,
}) => {
    const classes = useStyles();

    const actions = (
        <FormProvider {...form}>
            <form className={classes.form} >
                <Grid container spacing={2}>
                    <Grid item>
                        <HookField
                            name='status'
                            type='select'
                            suggestions={meterFileImportStatuses}
                            label='Статус'
                            isClearable
                            className={classes.statusSelect}
                        />
                    </Grid>
                    <Grid item>
                        <HookField name='isErrors' type='checkbox' label='Есть ошибки обработки' />
                    </Grid>
                </Grid>
                <Button onClick={onOpenUploadModal} variant='primary' disabled={isDisabledUploadFile} icon='add'>Загрузить файл</Button>
            </form>
            <Button
                className={classes.uploadBtn}
                variant='outline'
                icon='refresh'
                square
                onClick={updateData}
            />
        </FormProvider>
    );

    return (
        <>
            <Wrapper actions={actions} title='ЗАГРУЖЕННЫЕ ФАЙЛЫ' >
                <ImportCrqTable
                    data={data}
                    isLoading={isLoading}
                    setOrder={setOrder}
                    orderParams={orderParams}
                />
            </Wrapper>
            {isVisibleUploadModal && (
                <Modal
                    title='ФАЙЛЫ ДЛЯ ИМПОРТА'
                    onHide={onCloseUploadModal}
                    className={classes.modal}
                >
                    <ImportCrqUploader handleCloseModal={onCloseUploadModal} updateData={updateData} />
                </Modal>
            )}
        </>
    );
};
