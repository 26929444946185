import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { MeterActionSchemeViewModel } from '../../../../common/model/meter/meter-action-scheme/meterActionSchemeViewModel';
import { meterActionSchemeProcessStatusDictionary } from '../../../../common/model/meter/meterActionSchemeProcessStatus';
import { getMeterSchemes } from '../../../../redux/actions/meterSchemes';
import { resourcesRegionsByIdSelector } from '../../../../redux/selectors';
import { meterSchemesSelector } from '../../../../redux/selectors/meterSchemes';
import { DetailsButtonView } from '../../../shared/components/helpers/detailsButtonView';
import { Routes } from '../../../shared/constants';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { getRoute } from '../../../shared/pipes';
import { SchemesFilter } from './model';
import { DefaultSchemeView, RegionSchemeView, View } from './view';

const initialFormParams: SchemesFilter = {
    regionId: null,
    status: null,
    isDefault: null,
};

const filterSchemes = (filter: SchemesFilter, schemes: MeterActionSchemeViewModel[]): MeterActionSchemeViewModel[] => {
    if (!schemes) {
        return schemes;
    }
    return schemes.filter((scheme) => {
        let filtered = true;

        if (filter.regionId) {
            filtered = scheme.regionId === filter.regionId;
        }
        if (filter.status) {
            filtered = scheme.processStatus === filter.status;
        }
        if (filter.isDefault) {
            filtered = scheme.isDefault;
        }

        return filtered;
    });
};

export const MeterActionSchemeList: React.FC = () => {
    const schemes = useSelector(meterSchemesSelector);
    const regionsById = useSelector(resourcesRegionsByIdSelector());
    const dispatch = useDispatch();
    const isLoading = false;

    const [schemesFilter, setSchemesFilter] = useSyncQueryParams<SchemesFilter>(initialFormParams);

    const form = useForm<SchemesFilter>({ defaultValues: schemesFilter });

    const formData = form.watch();

    useEffect(() => {
        setSchemesFilter({
            ...initialFormParams,
            ...formData
        });
    }, [formData]);


    useEffect(() => {
        dispatch(getMeterSchemes());
    }, []);

    const handleClear = (name: string) => {
        const value: any = { [name]: null };
        form.reset({ ...formData, ...value });
        dispatch(getMeterSchemes());
    };

    const columnDefinition: Column[] = [
        {
            title: 'Название',
            field: 'name',
            sorting: true,
        },
        {
            title: 'Схема по умолчанию',
            field: 'isDefault',
            sorting: false,
            cellClassName: 'col-isCrq',
            render: (data: MeterActionSchemeViewModel) => {
                return DefaultSchemeView(data.isDefault);
            }
        },
        {
            title: 'Регион',
            field: 'regionId',
            sorting: false,
            render: (data) => {
                return RegionSchemeView({
                    region: regionsById[data.regionId],
                });
            }
        },
        {
            title: 'Количество ПУ',
            field: 'metersCount',
            sorting: true,
        },
        {
            title: 'Статус процесса опроса',
            field: 'processStatus',
            lookup: meterActionSchemeProcessStatusDictionary,
            sorting: false,
        },
        {
            title: 'Получение данных по протоколу CRQ',
            field: 'isCrq',
            sorting: false,
            cellClassName: 'col-isCrq',
            render: (data: MeterActionSchemeViewModel) => {
                return DefaultSchemeView(data.isCrq);
            }
        },
        {
            title: '',
            sorting: false,
            render: (scheme) => {
                return DetailsButtonView({
                    title: 'Перейти',
                    onClick: () => {
                        dispatch(push(getRoute(Routes.meterActionSchemeEdit, { schemeId: scheme.id })));
                    },
                });
            }
        }
    ];

    const onClickNewScheme = () => {
        dispatch(push(getRoute(Routes.meterActionSchemeCreate)));
    };

    const filteredSchemes = useMemo(() => {
        return filterSchemes(formData, schemes);
    }, [formData, schemes]);

    return View({
        form,
        isLoading,
        columnDefinition,
        onClickNewScheme,
        handleClear,
        schemes: filteredSchemes,
    });
};
