import { MeterActionSchemeViewModel } from '../../../../../common/model/meter/meter-action-scheme/meterActionSchemeViewModel';

export const meterActionSchemeFieldHelps: Partial<Record<keyof MeterActionSchemeViewModel, string>> = {
    configurationSnapshotIntervalHours: 'Интервал между чтениями конфигурации в часах',
    currentReadingAllowedDelayHours: 'Максимальная задержка снятия текущих показаний по накоплению энергий в часах. Необходимо формировать задачу на считывание текущих показаний, если разница между текущим моментом и последней датой текущих показаний больше этой величины.',
    dailyReadingAllowedDelayHours: 'Максимальная задержка снятия суточных показаний в часах (по умолчанию 24). Необходимо формировать задачу на считывание суточных показаний, если разница между текущим моментом и последней датой суточных показаний больше этой величины.',
    dailyReadingMaxDays: 'Максимальный интервал считывания суточных показаний в днях (по умолчанию 6). Если последняя дата суточных показаний отстоит от текущей больше, чем на эту величину, то считываем показания только за первые столько суток. Остальное считаем при следующем опросе',
    eventsCollectingAllowedDelayHours: 'Максимальная задержка сбора событий в часах. Задача на сбор событий формируется, если с момента последнего сбора событий прошло больше этого времени',
    incrementalReadingAllowedDelayHours: 'Максимальная задержка снятия профиля мощности в часах (по умолчанию 4). Необходимо формировать задачу на считывание профиля мощности, если разница между текущим моментом и последней датой профиля мощности больше этой величины.',
    incrementalReadingMaxHours: 'Максимальный интервал считывания профиля мощности в часах (по умолчанию 12). Если последняя дата профиля мощности отстоит от текущей больше, чем на эту величину, то считываем показания только за первые столько часов. Остальное будет считываться при следующем опросе.',
    monthlyReadingAllowedDelayMonths: 'Максимальная задержка снятия месячных показаний в месяцах (по умолчанию 1). Необходимо формировать задачу на считывание месячных показаний, если разница между текущим месяцем и месяцем последних месячных показаний больше или равна этой величины.',
    minRetryDelay: 'Минимальная задержка (в минутах) после неуспешно ошибочного опроса до следующей попытки запроса. По умолчанию 15 минут',
    maxRetryDelay: 'Максимальная задержка (в минутах) после неуспешно ошибочного опроса до следующей попытки запроса. По умолчанию 12 часов.',
    maxFailureCount: 'Максимальное количество неудачных опросов одного типа подряд (не включая таймауты по TTL), по достижении которого ПУ переводится в неисправные. По умолчанию 10.',
    timeWindows: 'Бинарное временное окно в десятичном виде. По умолчанию 32760.',
    validateOrChangeTariffIntervalHours: 'Задержка в часах для регулярного опроса валидации и установки тарифных расписаний. По умолчанию 24.',
};
