import * as React from 'react';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import {
    createStyles,
    makeStyles,
    Theme,
    Box,
} from '@material-ui/core';

import { Typography } from '../typography';
import { MenuButton } from './menuButton';
import { IStateProps } from './model';

export const drawerWidth = 352;
export const drawerClosedWidth = 64;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerPaper: {
            background: theme.palette.neutral.dark,
            color: theme.palette.neutral.white,
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            overflow: 'hidden',
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: drawerClosedWidth,
        },
        title: {
            color: theme.palette.neutral.white,
            marginLeft: '12px',
        }
    }),
);


export const View = (props: IStateProps) => {

    const { title, collapsed, children, onChangeMenuCollapsed } = props;

    const classes = useStyles();

    return (
        <Drawer
            style={{ width: 400 }}
            variant='permanent'
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: !collapsed,
                [classes.drawerClose]: collapsed,
            })}
            classes={{
                paper: classNames(classes.drawerPaper, {
                    [classes.drawerOpen]: !collapsed,
                    [classes.drawerClose]: collapsed,
                })
            }}
            onMouseOver={() => onChangeMenuCollapsed(false)}
            onMouseLeave={() => onChangeMenuCollapsed(true)}
        >
            <Box display='flex' flexDirection='column' alignItems='flex-start' height='100%'>
                <Box display='flex' alignItems='center' marginY={1.5} justifyContent='flex-start' padding='0 16px'>
                    <MenuButton active={!collapsed} />
                    <Typography
                        variant='h2'
                        className={classNames(classes.title, { [classes.hide]: collapsed })}
                    >{title}</Typography>
                </Box>
                {!collapsed && children}
            </Box>
        </Drawer>
    );
};
