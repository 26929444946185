import { ListItemIcon, ListItemText, makeStyles, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../shared/components/button';
import { Icon } from '../../../shared/components/icon';
import { MeterAttributesUpdateModal } from './meterAttributesUpdateModal';
import { StateProps } from './model';

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        minWidth: '0',
        padding: 0,
    },
    actionsPopover: {
        marginTop: '8px',
    },
    actionIcon: {
        color: theme.palette.primary.light,
        margin: '16px 9px 16px 12px',
    },
    menuItemContainer: {
        height: 40,
        paddingLeft: 0,
    },
    itemText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#343434',
        '& span': {
            fontSize: 14
        }
    },
}));

export const View = ({
    handleOpenActionsPopover,
    isActionsPopoverOpen,
    handleCloseActionsPopover,
    handleAction,
    actions,
    actionsPopoverAnchor,
}: StateProps) => {

    const classes = useStyles();

    return (
        <>
            <Button variant='primary' icon='more' onClick={handleOpenActionsPopover}>Действия</Button>
            <Menu
                open={isActionsPopoverOpen}
                onClose={handleCloseActionsPopover}
                anchorEl={actionsPopoverAnchor}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                PopoverClasses={{ root: classes.actionsPopover }}
            >
                {actions.filter(action => action.hasAccess).map(item => {
                    return (
                        <MenuItem key={item.key} onClick={() => handleAction(item.action)} className={classes.menuItemContainer}>
                            <ListItemIcon className={classes.iconContainer}>
                                <Icon name={item.icon} className={classes.actionIcon} />
                            </ListItemIcon>
                            <ListItemText className={classes.itemText}>{item.name}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
            <MeterAttributesUpdateModal />
        </>
    );
};
