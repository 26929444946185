import { makeStyles } from '@material-ui/core';
import React from 'react';
import { LogEntrySeverity } from '../../../../../../common/model/logbook/logEntrySeverity';
import { IconType } from '../../../../../shared/components/icon/model';
import { Icon } from '../../../../../shared/components/icon';
import { Tooltip } from '../../../../../shared/components/tooltip';

interface Props {
    severity: LogEntrySeverity;
    tooltip: string;
}

const iconBySeverity: Record<LogEntrySeverity, IconType> = {
    [LogEntrySeverity.Undefined]: 'alert',
    [LogEntrySeverity.Critical]: 'warningCircle',
    [LogEntrySeverity.Error]: 'errorOutline',
    [LogEntrySeverity.Info]: 'infoOutline',
    [LogEntrySeverity.Warning]: 'warningTriangle',
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    }
}));

export const SeverityColumn: React.FC<Props> = ({ severity, tooltip }) => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <Tooltip text={tooltip}>
                <Icon name={iconBySeverity[severity] ?? 'alert'} />
            </Tooltip>
        </div>
    );
};
