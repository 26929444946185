export enum MeterConsumptionInterval {
    HalfHour = 'half_hour',
    Hour = 'hour',
    Day = 'day',
    Month = 'month',
}

export const meterConsumptionIntervalDictionary: Record<MeterConsumptionInterval, string> = {
    [MeterConsumptionInterval.HalfHour]: '30 мин',
    [MeterConsumptionInterval.Hour]: '1 час',
    [MeterConsumptionInterval.Day]: '1 день',
    [MeterConsumptionInterval.Month]: '1 месяц',
};
