import { minutesInHour } from '../../../common/constants/date';
import { timeZoneDictionary } from '../../../common/constants/timeZone';

export const timeZonePipe = (tz: number): string => {
    if (tz === null || tz === undefined || isNaN(+tz)) {
        return '–';
    }
    if (timeZoneDictionary[tz]) {
        return timeZoneDictionary[tz];
    }
    const timeZone = tz / minutesInHour;
    return timeZone > 0 ? `+${timeZone}:00` : `${timeZone}:00`;
};
