import { View } from './view';
import { MeterLabelViewModel } from '../../../../common/model/meter/meterLabel/meterLabelViewModel';
import moment from 'moment';
import { DateFormats } from '../../../../common/constants/date';
import { ProblemsFilter, ProblemsProps, ProblemsViewProps } from './model';
import { useDispatch, useSelector } from 'react-redux';
import { meterLabelsSelector } from '../../../../redux/selectors/meters';
import { TextWithIcon } from '../../../shared/components/helpers/textWithIcon';
import { useTheme } from '@material-ui/core';
import { push } from 'connected-react-router';
import { getRoute } from '../../../shared/pipes';
import { Routes } from '../../../shared/constants';
import { BaseDispatch } from '../../../../redux/actions';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { closeMeterLabel, getMeterLabels } from '../../../../redux/actions/meters';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { DetailsDoubleButtonView } from './detailDoubleButton/detailsDoubleButtonView';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { MeterGisTaskCreator } from '../../../../common/model/meter/meterGisTask/meterGisTaskCreator';
import { createMeterGisTask } from '../../../../redux/actions/meterGisTasks';

const initialFormParams: ProblemsFilter = {
    isClosedOnly: null,
};

const fetchDebounceTimeMS = 600;

export const Problems = (problemsProps: ProblemsProps) => {
    const [isCreateIncidentModalOpened, setIsCreateIncidentModalOpened] = useState<boolean>(false);
    const [activeMeterLabelTypeId, setActiveMeterLabelTypeId] = useState<number>(null);
    const meterLabels = useSelector(meterLabelsSelector);
    const theme = useTheme();
    const dispatch = useDispatch<BaseDispatch>();

    const [problemsFilter, setProblemsFilter] = useSyncQueryParams<ProblemsFilter>(initialFormParams);

    const form = useForm<ProblemsFilter>({ defaultValues: problemsFilter });

    const formData = form.watch();

    const changesFormData = useDebounce(formData, fetchDebounceTimeMS);

    useEffect(() => {
        setProblemsFilter({
            ...initialFormParams,
            ...formData
        });
    }, [formData]);

    const onMeterConfigurationHistoryClick = () => {
        dispatch(push(getRoute(Routes.meterConfigurationHistory, { meterId: problemsProps.meterId })));
    };

    const refreshTableData = async (filterParams: ProblemsFilter) => {
        await dispatch(getMeterLabels(problemsProps.meterId, filterParams));
    };

    useEffect(() => {
        refreshTableData(problemsFilter);
    }, [changesFormData]);

    const onCloseCreateIncidentModal = () => {
        setIsCreateIncidentModalOpened(false);
    };

    const onCreateSubmit: SubmitHandler<MeterGisTaskCreator> = async (values) => {
        await dispatch(createMeterGisTask(values));
        onCloseCreateIncidentModal();
        refreshTableData(problemsFilter);
    };

    const props: ProblemsViewProps = {
        activeMeterLabelTypeId,
        isCreateIncidentModalOpened,
        onCreateSubmit,
        onCloseCreateIncidentModal,
        meterLabels,
        form,
        onMeterConfigurationHistoryClick,
        columns: [
            {
                title: 'Проблема',
                field: 'name',
                sorting: true,
                cellClassName: 'cell-name',
                render: (data: MeterLabelViewModel) => data?.meterLabelType?.taskTitle,
            },
            {
                title: 'Дата открытия',
                field: 'createdAt',
                sorting: true,
                render: (data: MeterLabelViewModel) => moment(data.createdAt).format(DateFormats.dateTimeFormat),
                cellClassName: 'cell-date'
            },
            {
                title: 'Описание',
                field: 'description',
                sorting: false,
                cellClassName: 'cell-description',
                render: (data: MeterLabelViewModel) => data?.description,
            },
            {
                title: 'Инцидент',
                field: 'systemValue',
                sorting: false,
                cellClassName: 'cell-incident',
                render: (data: MeterLabelViewModel) => data.meterGisTask && TextWithIcon({
                    title: data.meterGisTask?.number,
                    icon: data.closedAt ? 'checkCircle' : 'open',
                    fillColor: data.closedAt ? theme.palette.text.hint : theme.palette.system.success,
                    width: '21px',
                    height: '21px',
                    isTextAsLink: true,
                    route: getRoute(Routes.meterGisTaskEdit, { taskId: data.meterGisTask?.id }),
                    theme,
                })
            },
            {
                title: '',
                sorting: false,
                render: (data: MeterLabelViewModel) => {
                    if (data.closedAt) {
                        return null;
                    }
                    return DetailsDoubleButtonView({
                        onClickClose: async () => {
                            await dispatch(closeMeterLabel(problemsProps.meterId, data.meterLabelTypeId));
                            refreshTableData(problemsFilter);
                        },
                        onClickCreate: () => {
                            setIsCreateIncidentModalOpened(true);
                            setActiveMeterLabelTypeId(data.meterLabelTypeId);
                        },
                        meterGisTask: data.meterGisTask,
                    });
                }
            }
        ],
        ...problemsProps,
    };

    return View(props);
};
