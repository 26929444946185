import { IStateProps } from './model';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { Box, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { Checkbox } from '../checkbox';
import './style.scss';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    chartContainer: {
        backgroundColor: theme.palette.neutral.white,

        '& .apexcharts-xaxistooltip': {
            display: 'none',
        },

        '& .apexcharts-tooltip': {
            '& .apexcharts-tooltip-series-group': {
                '& .apexcharts-tooltip-text': {
                    '& .apexcharts-tooltip-y-group': {
                        padding: 0,
                    },
                },
                '& .apexcharts-tooltip-marker': {
                    borderRadius: 0,
                    height: '2px',
                },
            },
            '& .apexcharts-tooltip-title': {
                backgroundColor: 'inherit',
                border: 'none',
                paddingLeft: '10px',
                marginTop: '8px',
                paddingBottom: '0px',
                ...theme.typography.captionBold,
            },
        },
    },
    chartContainerMiddleBackground: {
        backgroundColor: theme.palette.neutral.background,
    },
}));

export const View = ({ chartProps: { series, options, type, height }, backgroundColor = 'default' }: IStateProps) => {
    const [chartData, setChartData] = useState(series.map((item) => {
        return {
            name: item.name,
            checked: true
        };
    }));

    const classes = useStyles();

    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        ApexCharts.exec(options.chart.id, 'toggleSeries', e.target.name);
        setChartData(chartData.map(item => {
            if (item.name === e.target.name) {
                return {
                    ...item,
                    checked: !item.checked,
                };
            }
            return item;
        }));
    };

    return (
        <Box py={3} px={2}
            className={
                classNames(classes.chartContainer,
                    { [classes.chartContainerMiddleBackground]: backgroundColor === 'middle' },
                )
            }
        >
            <Chart
                options={options}
                series={series}
                type={type}
                height={height}
            />
            <div className="checkboxWrapper">
                {chartData.map((item, i) => {
                    return (
                        <Checkbox
                            label={item.name}
                            name={item.name}
                            color={options.colors[i]}
                            checked={item.checked}
                            onChange={handleToggle}
                            key={item.name}
                        />
                    );
                })}
            </div>
        </Box>
    );
};
