import React from 'react';
import { Box } from '@material-ui/core';
import { Heading } from '../../shared/components/heading';
import { Select } from '../../shared/components/select';
import { TabMenu } from '../../shared/components/tabMenu';
import { MonitoringTabs } from '../../shared/constants';
import { TabMenuItem } from '../../shared/components/tabMenuItem';
import { AdvancedCrashReport } from './advancedCrashReport';
import { MassOperation } from './massOperation';
import { MonitoringProps } from './model';
import { SurveyCondition } from './surveyCondition';
import { UnlegalAccess } from './unlegalAccess';
import { MeterHealth } from './meterHealth';

export const View: React.FC<MonitoringProps> = ({
    onChangeTab,
    activeTab,
    tabs,
    regionId,
    onChangeRegion,
    regionSuggestions,
    isAdvancedCrashReportVisible,
    setIsAdvancedCrashReportVisible,
}) => {
    return (
        <>
            <Box mb={2}>
                <Heading heading='Мониторинг системы' />
            </Box>
            <Box width={320} marginBottom='27px'>
                <Select
                    fullWidth
                    label='Территория'
                    value={regionId}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => onChangeRegion(e.target.value as number)}
                    suggestions={regionSuggestions}
                />
            </Box>

            <TabMenu value={activeTab || MonitoringTabs.UnlegalAccess} onChange={onChangeTab}>
                {
                    tabs.map(tab =>
                        <TabMenuItem
                            label={tab.title}
                            key={tab.key}
                            value={tab.key}
                            disabled={tab.disabled}
                        />
                    )
                }
            </TabMenu>

            <Box py={3}>
                {activeTab === MonitoringTabs.MassOperation && <MassOperation regionId={regionId} />}
                {activeTab === MonitoringTabs.MeterHealth && <MeterHealth />}
                {activeTab === MonitoringTabs.UnlegalAccess && <UnlegalAccess />}
                {activeTab === MonitoringTabs.SurveyCondition && (
                    isAdvancedCrashReportVisible
                        ? <AdvancedCrashReport
                            regionId={regionId}
                            isAdvancedCrashReportVisible={isAdvancedCrashReportVisible}
                            setIsAdvancedCrashReportVisible={setIsAdvancedCrashReportVisible}
                        />
                        : <SurveyCondition
                            regionId={regionId}
                            isAdvancedCrashReportVisible={isAdvancedCrashReportVisible}
                            setIsAdvancedCrashReportVisible={setIsAdvancedCrashReportVisible}
                        />
                )}
            </Box>
        </>
    );
};
