import { httpService } from '../../../app/shared/httpWrapper';
import { appUrls } from '../../../common/appUrls';
import { SettingUpdater } from '../../../common/model/resources/settings/settingUpdater';
import { SettingViewModel } from '../../../common/model/resources/settings/settingViewModel';
import { settingsSlice } from '../../reducers/settings';
import { AppThunk } from '../index';

export const getSettings = (): AppThunk => async dispatch => {
    const resources = await httpService.get<SettingViewModel[]>(appUrls.api_get_settings);
    dispatch(settingsSlice.actions.setAvailable(resources));
};

export const updateSetting = (updater: SettingUpdater): AppThunk => async () => {
    await httpService.post<SettingViewModel>(appUrls.api_post_settings, updater);
};
