import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    MeterChangePasswordOrderListResponse
} from '../../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderListResponse';

export interface MeterChangePasswordOrdersList {
    available: MeterChangePasswordOrderListResponse | null;
}

const initialState: MeterChangePasswordOrdersList = {
    available: null,
};

export const meterChangePasswordOrdersListSlice = createSlice({
    name: '@@vv/meterChangePasswordOrders/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<MeterChangePasswordOrderListResponse>): void {
            state.available = action.payload;
        }
    }
});
