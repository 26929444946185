import { FormHelperText, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import {
    meterChangePasswordPeriodDictionary
} from '../../../../../common/model/meter/meter-action-scheme/meterChangePasswordPeriod';
import { Button } from '../../../../shared/components/button';
import { HookField } from '../../../../shared/components/hookField';
import { Typography } from '../../../../shared/components/typography';
import { useWebDictionarySelectSuggestions } from '../../../../shared/hooks/useWebDictionary';
import { meterActionSchemeFieldHelps } from './constants';
import { MeterActionSchemeFormPropsStateProps } from './model';
import { meterActionSchemeValidationRules } from '../../../../../common/model/meter/meter-action-scheme/validation';

import './styles.css';
import { defaults } from '../../../../shared/constants';

const spacing = 4;

const useStyles = makeStyles(() => ({
    blockTitle: {
        paddingBottom: '0px !important',
    },
}));
export const View = (props: MeterActionSchemeFormPropsStateProps) => {
    const { form, onSubmit, onCancel, showChangeIsDefaultAlert, isCrq, disabled } = props;
    const { handleSubmit } = form;
    const classes = useStyles();

    const { meterActionSchemeStatuses, regions } = useWebDictionarySelectSuggestions();

    return <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={spacing} className='element-container'>
                <Grid item xs={12} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} lg={4} >
                            <HookField
                                disabled={disabled}
                                type='text'
                                name='name'
                                label='Название'
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} >
                            <HookField
                                disabled={disabled}
                                type='select'
                                name='regionId'
                                label='Регион'
                                fullWidth
                                suggestions={regions} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} >
                            <HookField
                                disabled={disabled}
                                type='select'
                                name='processStatus'
                                label='Статус процесса опроса'
                                fullWidth
                                suggestions={meterActionSchemeStatuses} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} lg={4} xl={6} className='checkbox-container'>
                            <HookField
                                disabled={disabled}
                                type='checkbox'
                                name='isCrq'
                                label='Получение данных по протоколу CRQ'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={6} className='checkbox-container'>
                            <HookField
                                type='checkbox'
                                name='isDefault'
                                disabled={isCrq || disabled}
                                label='Использовать по умолчанию для региона'
                            />
                            {showChangeIsDefaultAlert && (
                                <FormHelperText>
                                    Текущая дефолтная перестанет быть таковой, а дефолтной станет новая
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={4} xl={6}>
                    <Grid container spacing={spacing}>
                        <Grid item sm={12} className={classes.blockTitle}>
                            <Typography variant='h3'>
                                Суточные опросы
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12} xl={6}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                name='dailyReadingAllowedDelayHours'
                                label='Макс. задержка снятия показаний, ч.'
                                infoTooltipText={meterActionSchemeFieldHelps['dailyReadingAllowedDelayHours']}
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12} xl={6}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                name='dailyReadingMaxDays'
                                label='Макс. интервал считывания показаний, дней'
                                infoTooltipText={meterActionSchemeFieldHelps['dailyReadingMaxDays']}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4} xl={6}>
                    <Grid container spacing={spacing}>
                        <Grid item sm={12} className={classes.blockTitle}>
                            <Typography variant='h3'>
                                Текущие опросы
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12} xl={6}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                name='currentReadingAllowedDelayHours'
                                label='Макс. задержка снятия показаний, ч.'
                                infoTooltipText={meterActionSchemeFieldHelps['currentReadingAllowedDelayHours']}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4} xl={6}>
                    <Grid container spacing={spacing}>
                        <Grid item sm={12} className={classes.blockTitle}>
                            <Typography variant='h3'>
                                Профиль мощности
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12} xl={6}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                name='incrementalReadingAllowedDelayHours'
                                label='Макс. задержка снятия показаний, ч.'
                                infoTooltipText={meterActionSchemeFieldHelps['incrementalReadingAllowedDelayHours']}
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12} xl={6}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                name='incrementalReadingMaxHours'
                                label='Макс. интервал считывания показаний, ч.'
                                infoTooltipText={meterActionSchemeFieldHelps['incrementalReadingMaxHours']}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={4} xl={3}>
                    <Grid container spacing={spacing}>
                        <Grid item sm={12} className={classes.blockTitle}>
                            <Typography variant='h3'>
                                Опрос конфигурации
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                min={meterActionSchemeValidationRules.configurationSnapshotIntervalHours.min}
                                name='configurationSnapshotIntervalHours'
                                label='Интервал между проверками конфигурации, ч.'
                                infoTooltipText={meterActionSchemeFieldHelps['configurationSnapshotIntervalHours']}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                    <Grid container spacing={spacing}>
                        <Grid item sm={12} className={classes.blockTitle}>
                            <Typography variant='h3'>
                                Сбор событий ПУ
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                name='eventsCollectingAllowedDelayHours'
                                label='Макс. задержка сбора событий, ч.'
                                infoTooltipText={meterActionSchemeFieldHelps['eventsCollectingAllowedDelayHours']}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                    <Grid container spacing={spacing}>
                        <Grid item xs={12} className={classes.blockTitle}>
                            <Typography variant='h3'>
                                Месячные опросы
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                name='monthlyReadingAllowedDelayMonths'
                                label='Макс. задержка снятия показаний, мес.'
                                infoTooltipText={meterActionSchemeFieldHelps['monthlyReadingAllowedDelayMonths']}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                    <Grid container spacing={spacing}>
                        <Grid item xs={12} className={classes.blockTitle}>
                            <Typography variant='h3'>
                                Валидация и установка тарифных расписаний
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                            <HookField
                                disabled={disabled || isCrq}
                                type='number'
                                min={meterActionSchemeValidationRules.validateOrChangeTariffIntervalHours.min}
                                name='validateOrChangeTariffIntervalHours'
                                label='Интервал между опросами, ч.'
                                infoTooltipText={meterActionSchemeFieldHelps['validateOrChangeTariffIntervalHours']}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <Grid container spacing={spacing}>
                        <Grid item sm={12} className={classes.blockTitle}>
                            <Typography variant='h3'>
                                Настройки повторов
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                name='minRetryDelay'
                                label='Мин. задержка после ошибочного запроса, мин.'
                                infoTooltipText={meterActionSchemeFieldHelps['minRetryDelay']}
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                name='maxRetryDelay'
                                label='Макс. задержка после ошибочного запроса, мин.'
                                infoTooltipText={meterActionSchemeFieldHelps['maxRetryDelay']}
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <HookField
                                disabled={disabled}
                                type='number'
                                name='maxFailureCount'
                                label='Макс. кол-во неудачных опросов подряд'
                                infoTooltipText={meterActionSchemeFieldHelps['maxFailureCount']}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <Grid container spacing={spacing}>
                        <Grid item sm={12} className={classes.blockTitle}>
                            <Typography variant='h3'>
                                Настройки окон опроса
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <HookField
                                disabled={disabled}
                                type='timeWindow'
                                name='timeWindows'
                                shift={defaults.shiftTimeZone}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <Grid container spacing={spacing}>
                        <Grid item sm={12} className={classes.blockTitle}>
                            <Typography variant='h3'>
                                Смена паролей
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={spacing} alignItems='center'>
                                <Grid item sm={3}>
                                    Повторять с интервалом
                                </Grid>
                                <Grid item sm={3}>
                                    <HookField
                                        disabled={disabled}
                                        label='Интервал'
                                        type='number'
                                        name='changePasswordInterval'
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <HookField
                                        label='Период'
                                        disabled={disabled}
                                        type='select'
                                        name='changePasswordPeriod'
                                        suggestions={Object.entries(meterChangePasswordPeriodDictionary).map(([value, label]) => ({
                                            label,
                                            value: +value,
                                        }))}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <Grid container spacing={spacing}>
                        <Grid item>
                            <Button disabled={disabled} variant='primary' type='submit'>Сохранить</Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={disabled} variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </FormProvider>;
};
