import { combineReducers } from 'redux';
import { blockingCallCountSlice, BlockingCallCountState } from './blockingCallCount';
import { messagesSlice, MessagesState } from './messages';

export class HttpWrapperState {
    public blockingCallCount: BlockingCallCountState;
    public messages: MessagesState;
}

const httpWrapper = combineReducers<HttpWrapperState>({
    blockingCallCount: blockingCallCountSlice.reducer,
    messages: messagesSlice.reducer,
});

export { httpWrapper };
