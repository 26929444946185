import { keyBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
    MeterChangePasswordRequestViewModel
} from '../../../../common/model/meter/meterChangePasswordRequest/meterChangePasswordRequestViewModel';
import { ISort } from '../../../../common/sort';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import { getMeterChangePasswordOrders, setMeterChangePasswordOrdersAvailable } from '../../../../redux/actions/meterChangePasswordOrders';
import {
    createMeterChangePasswordRequest,
    getMeterChangePasswordRequests,
    setMeterChangePasswordRequestsAvailable
} from '../../../../redux/actions/meterChangePasswordRequests';
import { resourcesMeterModelsSelector, resourcesRegionsByIdSelector } from '../../../../redux/selectors';
import { meterChangePasswordOrdersListSelector } from '../../../../redux/selectors/meterChangePasswordOrders';
import { meterChangePasswordRequestsListSelector } from '../../../../redux/selectors/meterChangePasswordRequests';
import { useMeterGroupAutocomplete } from '../../../shared/components/autocomplete/hooks/useMeterGroupAutocomplete';
import { usePagination } from '../../../shared/components/pagination';
import { useInterval } from '../../../shared/hooks/useInterval';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { getErrorMessage } from '../../../shared/utils/error';
import { transformDatesParams } from '../../../shared/utils/transformers/dates';
import { useYupValidationResolver } from '../../../shared/utils/validation';
import { MeterChangePasswordRequestForm } from './model';
import { View } from './view';

const meterPasswordManagementFormValidationScheme = yup.object({
    regionId: yup.number(),
    meterGroupId: yup.number(),
    dateFrom: yup.date(),
    dateTo: yup.date(),
});

const defaultValues: MeterChangePasswordRequestForm = {
    regionId: undefined,
    meterGroupId: undefined,
    dateFrom: undefined,
    dateTo: undefined,
};

const refreshDelayMs = 15000;

export const MeterChangePasswordRequests = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const [isOrdersLoading, setIsOrdersLoading] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [isRequestsLoading, setIsRequestsLoading] = useState(false);
    const [isCreateRequestFormOpen, setIsCreateRequestFormOpen] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState<MeterChangePasswordRequestViewModel>(null);

    const isLoading = isOrdersLoading || isRequestsLoading;

    const requestsResponse = useSelector(meterChangePasswordRequestsListSelector);
    const ordersResponse = useSelector(meterChangePasswordOrdersListSelector);
    const meterModels = useSelector(resourcesMeterModelsSelector());
    const regionById = useSelector(resourcesRegionsByIdSelector());

    const resolver = useYupValidationResolver<MeterChangePasswordRequestForm>(meterPasswordManagementFormValidationScheme);

    const [filterParams, setFilter] = useSyncQueryParams<MeterChangePasswordRequestForm>(defaultValues);
    const form = useForm<MeterChangePasswordRequestForm>({
        resolver,
        defaultValues: filterParams,
    });

    const formData = form.watch();

    const requests = requestsResponse?.data ?? [];
    const orders = ordersResponse?.data ?? [];

    const resetRequests = () => {
        dispatch(setMeterChangePasswordRequestsAvailable(null));
    };

    const resetOrders = () => {
        dispatch(setMeterChangePasswordOrdersAvailable(null));
    };

    const fetchRequests = async (sort: ISort, filter: MeterChangePasswordRequestForm = formData) => {
        setIsRequestsLoading(true);
        try {
            setFilter(transformDatesParams(filter));
            await dispatch(getMeterChangePasswordRequests({
                ...filter,
                count: sort.count,
                offset: sort.offset,
            }));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsRequestsLoading(false);
    };

    const fetchOrders = async (sort: ISort, request: MeterChangePasswordRequestViewModel = selectedRequest) => {
        setIsOrdersLoading(true);
        try {
            await dispatch(getMeterChangePasswordOrders(request.id, {
                count: sort.count,
                offset: sort.offset,
            }));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsOrdersLoading(false);
    };

    const requestsPagination = usePagination({
        page: 1,
        rowsPerPage: 15,
    }, fetchRequests, 'requests_');

    const ordersPagination = usePagination({
        page: 1,
        rowsPerPage: 15,
    }, fetchOrders, 'orders_');

    const refetchRequests = async () => {
        await requestsPagination.onFetch(requestsPagination.pagination, formData);
    };

    const refetchOrders = async () => {
        await ordersPagination.onFetch(ordersPagination.pagination, selectedRequest);
    };

    useEffect(() => {
        refetchRequests();
        return () => {
            resetOrders();
            resetRequests();
        };
    }, []);

    useInterval(async () => {
        await refetchRequests();
        if (selectedRequest) {
            await refetchOrders();
        }
    }, refreshDelayMs);

    const meterGroupAutocomplete = useMeterGroupAutocomplete({
        regionId: formData.regionId,
    });

    const meterModelById = useMemo(() => keyBy(meterModels, 'id'), [meterModels]);

    return View({
        form,
        regionById,
        meterModelById,
        meterGroupAutocomplete,
        selectedRequest,
        requests,
        orders,
        isLoading,
        isOrdersLoading,
        isFormSubmitting,
        isRequestsLoading,
        isCreateRequestFormOpen,
        onSubmitCreateRequest: async (data) => {
            setIsFormSubmitting(true);
            try {
                await dispatch(createMeterChangePasswordRequest(data));
                setIsCreateRequestFormOpen(false);
                await refetchRequests();
                if (selectedRequest) {
                    await refetchOrders();
                }
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsFormSubmitting(false);
        },
        onClickSelectRequest: async (val) => {
            resetOrders();
            setSelectedRequest(val);
            await ordersPagination.onFetch(ordersPagination.pagination, val);
        },
        onClickRefresh: async () => {
            resetOrders();
            await requestsPagination.onFetch(requestsPagination.pagination, formData);
        },
        onClickCreateRequest: () => {
            setIsCreateRequestFormOpen(true);
        },
        onClickCloseRequest: () => {
            setIsCreateRequestFormOpen(false);
        },
        requestsPagination: {
            ...requestsPagination,
            dataTotal: requestsResponse?.total ?? 0
        },
        ordersPagination: {
            ...ordersPagination,
            dataTotal: ordersResponse?.total ?? 0
        }
    });
};
