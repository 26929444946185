import { push } from 'connected-react-router';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormats } from '../../../../common/constants/date';
import { meterPowerSwitchOperationTypeDictionary } from '../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchOperationType';
import { MeterPowerSwitchOrderStatus, meterPowerSwitchOrderStatusDictionary } from '../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchOrderStatus';
import { meterPowerSwitchRequestStatusDictionary } from '../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchRequestStatus';
import { MeterPowerSwitchRequestsParams } from '../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchRequestView';
import {
    MeterPowerSwitchOrderShortViewModel,
    MeterPowerSwitchRequestShortViewModel
} from '../../../../common/model/meter/meterPowerSwitchOrder/MeterPowerSwitchOrderCommonModel';
import { meterRelayStatusDictionary } from '../../../../common/model/meter/relayStatus';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import {
    approveMeterPowerSwitchOrder,
    getMeterPowerSwitchOrders,
    getMeterPowerSwitchRequests
} from '../../../../redux/actions/meterPowerSwitchOrders';
import {
    meterPowerSwitchOrdersSelector,
    meterPowerSwitchRequestsSelector, meterPowerSwitchTotalCountRequestsSelector
} from '../../../../redux/selectors/meterPowerSwitchOrders';
import { useMeterAutocomplete } from '../../../shared/components/autocomplete/hooks/useMeterAutocomplete';
import { MeterTabs, Routes } from '../../../shared/constants';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { useWebDictionarySelectSuggestions } from '../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../shared/pipes';
import { formatDate, getCurrentDate } from '../../../shared/utils/dates';
import { transformDatesParams } from '../../../shared/utils/transformers/dates';
import { MeterSerialNumView } from '../../reports/components/common/meterSerianNumView';
import { DetailsView } from './columns';
import { makeExcelReport } from './excel';
import { View } from './view';
import { usePagination } from '../../../shared/components/pagination';
import { ISort } from '../../../../common/sort';
import { meterPowerSwitchOrdersListSlice } from '../../../../redux/reducers/meterPowerSwitchOrders/list';
import { initialFilter } from './initialFilter';
import { meterPowerSwitchCountersAvailableSelector } from '../../../../redux/selectors/meterPowerSwitchCounters';
import { resourcesSettingsSelector } from '../../../../redux/selectors';
import { getMeterPowerSwitchCounters } from '../../../../redux/actions/meterPowerSwitchCounters';
import { useInterval } from '../../../shared/hooks/useInterval';

const isRowSelectable = (row: MeterPowerSwitchOrderShortViewModel) =>
    row.status === MeterPowerSwitchOrderStatus.ApprovalRequired;

const rowsPerPage = 30;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const refreshDelayMs = 10000;

export const PowerSwitchOrders: React.FC = () => {
    const dispatch = useDispatch<BaseDispatch>();

    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState<MeterPowerSwitchOrderShortViewModel[]>([]);
    const [filter, setFilter] = useSyncQueryParams<MeterPowerSwitchRequestsParams>(initialFilter);
    const form = useForm<MeterPowerSwitchRequestsParams>({
        defaultValues: filter,
    });
    const requests = useSelector(meterPowerSwitchRequestsSelector);
    const dataTotalCount = useSelector(meterPowerSwitchTotalCountRequestsSelector);
    const orders = useSelector(meterPowerSwitchOrdersSelector);
    const meterPowerSwitchCounters = useSelector(meterPowerSwitchCountersAvailableSelector);
    const { powerSwitchMetersCountPerDay } = useSelector(resourcesSettingsSelector());

    const fetchCounters = async () => {
        await dispatch(getMeterPowerSwitchCounters());
    };

    useEffect(() => {
        fetchCounters();
    }, []);

    useInterval(fetchCounters, refreshDelayMs);

    const data = useMemo(() => {
        return requests.reduce((acc, item) => {
            if (item.requestId in orders) {
                orders[item.requestId].forEach((order) => {
                    acc.push({
                        ...order,
                        tableData: {
                            checked: selected.some(s => s.id === order.id),
                            id: order.id
                        }
                    });
                });
            } else {
                acc.push(item);
            }

            return acc;
        }, []);
    }, [requests, orders, selected]);
    const requestsByRequestId = useMemo(() => _.keyBy(requests, 'requestId'), [requests]);

    const formData = form.watch();
    const appliedFiltersCount = [
        !!formData.dateFrom,
        !!formData.dateTo,
        !!formData.meterId,
        !!formData.regionId,
        !!formData.status,
    ].filter(x => x).length;
    const meterAutocomplete = useMeterAutocomplete({
        id: formData.meterId,
        regionId: formData.regionId,
    });
    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const fetchRequests = async (pagination: ISort, data: MeterPowerSwitchRequestsParams = formData) => {
        setIsLoading(true);

        const newFormValues = transformDatesParams(data);
        setFilter({
            ...newFormValues
        });

        try {
            dispatch(meterPowerSwitchOrdersListSlice.actions.clearAvailableMeterPowerSwitchOrders());
            await dispatch(getMeterPowerSwitchRequests({ ...newFormValues, ...pagination }));
        } catch (e) {
            const error = e as Error;
            const dataError = e as {data: {errorDetails: string}};
            dispatch(createSnackbar({
                type: 'red',
                message: error?.message || dataError?.data?.errorDetails || 'Произошла ошибка при загрузке настроек',
            }));
        }

        setSelected([]);
        setIsLoading(false);
    };

    const { pagination, setPage, onFetch } = usePagination({ page: 1, rowsPerPage }, fetchRequests);

    const onSubmit: SubmitHandler<MeterPowerSwitchRequestsParams> = async (data) => {
        await onFetch(pagination, data);
    };

    const onSelectionChange = useCallback((
        selectedRows: MeterPowerSwitchOrderShortViewModel[],
        clickedRow: MeterPowerSwitchOrderShortViewModel | undefined
    ) => {
        const newSelected = selectedRows.filter(isRowSelectable);

        if (!clickedRow && selected.length === newSelected.length) {
            setSelected([]);
            return;
        }

        setSelected(newSelected);
    }, [setSelected, selected.length]);
    const onApprove = useCallback(selected.length
        ? async () => {
            setIsLoading(true);
            try {
                await dispatch(approveMeterPowerSwitchOrder(selected.map(x => x.id)));
                setSelected([]);
            } finally {
                setIsLoading(false);
            }
        }
        : undefined,
    [selected]);

    useEffect(() => {
        onFetch(pagination, formData);
    }, []);
    useEffect(() => {
        const tableOnCick = async (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            const cell: HTMLTableCellElement = target.closest('td.request-row-group');
            if (!cell) {
                return;
            }

            const state = cell.dataset['state'] ?? 'not-expanded';
            const button: HTMLButtonElement = target.closest('button.MuiButtonBase-root.MuiIconButton-root');
            if (!button || (button.disabled && state === 'expanding')) {
                event.stopPropagation();
                return;
            }

            const match = /requestId-(\d+)/.exec(cell.className);
            if (match && match[1]) {
                const requestId = +match[1];
                if (state === 'expanded') {
                    cell.dataset['state'] = 'not-expanded';
                } else if (state === 'expanding') {
                    cell.dataset['state'] = 'expanded';
                } else {
                    if (!orders[requestId]) {
                        event.stopPropagation();
                        button.disabled = true;
                        cell.dataset['state'] = 'expanding';
                    }

                    await dispatch(getMeterPowerSwitchOrders({
                        ...transformDatesParams(filter),
                        requestId
                    }));

                    if (!orders[requestId]) {
                        button.disabled = false;
                        button.click();
                    } else {
                        cell.dataset['state'] = 'expanded';
                    }
                }
            }
        };

        const table = document.getElementById('powerSwitchOrdersTable');
        if (table) {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            table.addEventListener('click', tableOnCick, { capture: true });
        }

        return () => {
            if (table) {
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                table.removeEventListener('click', tableOnCick, { capture: true });
            }
        };
    }, [orders]);

    return View({
        appliedFiltersCount,
        data,
        dataTotalCount,
        form,
        isLoading,
        onSubmit,
        meterAutocomplete,
        regionSuggestions,
        isExcelExportDisabled: data.length === 0,
        onApprove,
        meterPowerSwitchCounters,
        powerSwitchMetersCountPerDay,
        showSelectAllCheckbox: data.some(isRowSelectable),
        onExcelExport: async () => {
            const date = getCurrentDate();
            const filename = `power_switch_report_${formatDate(date, DateFormats.dateTimeFormatShort)}`;
            await makeExcelReport({
                date,
                filter,
                filename,
                response: data,
                metersSuggestions: meterAutocomplete.options,
                regionSuggestions,
            });
        },
        onSelectionChange,
        pagination,
        setPage,
        columns: [
            {
                field: 'requestId',
                title: 'Запрос',
                cellClassName: (requestId) => `request-row-group requestId-${requestId}`,
                defaultGroupOrder: 0,
                grouping: true,
                sorting: false,
                defaultSort: 'desc',
                defaultGroupSort: 'desc',
                render: (item: MeterPowerSwitchRequestShortViewModel | number, type) => {
                    switch (type) {
                        case 'group': {
                            const dataItem = requestsByRequestId[(item as number)];
                            if (!dataItem) {
                                return 'Не известно';
                            }

                            const operationTypeString = meterPowerSwitchOperationTypeDictionary[dataItem.operationType];
                            const stateString = meterPowerSwitchRequestStatusDictionary[dataItem.requestStatus];

                            return <b>{`№${dataItem.requestId}. ${operationTypeString}. Состояние: ${stateString}`}</b>;
                        }
                        default: {
                            return `№${(item as MeterPowerSwitchRequestShortViewModel).requestId}`;
                        }
                    }
                },
            },
            {
                field: 'meter.meterModel',
                cellClassName: 'col-meterMeterModel',
                title: 'Тип ПУ',
                grouping: false,
                sorting: false,
                render: (item: MeterPowerSwitchOrderShortViewModel) => item?.shortName ?? '–',
            },
            {
                field: 'meter.meterSerialNum',
                cellClassName: 'col-meterMeterSerialNum',
                title: 'Заводской номер',
                grouping: false,
                sorting: false,
                render: (item: MeterPowerSwitchOrderShortViewModel) => {
                    if (!item.meterId) {
                        return '–';
                    }
                    const route = getRoute(Routes.meter, { meterId: item.meterId }, { tab: MeterTabs.Information });
                    return <MeterSerialNumView label={item.meterSerialNum} url={route} />;
                }
            },
            {
                field: 'status',
                cellClassName: 'col-status',
                title: 'Статус обработки',
                grouping: false,
                sorting: false,
                render: (item: MeterPowerSwitchOrderShortViewModel) => {
                    return item.status ? meterPowerSwitchOrderStatusDictionary[item.status] : '–';
                },
            },
            {
                field: 'operationTime',
                cellClassName: 'col-operationTime',
                title: 'Дата переключения реле',
                grouping: false,
                sorting: false,
                render: (item: MeterPowerSwitchOrderShortViewModel) => {
                    return item.operationTime ? formatDate(item.operationTime, DateFormats.dateTimeZoneFormat) : '–';
                },
            },
            // ToDo: 'Пользователь / внешняя система' по userId/userName
            {
                field: 'creationDate',
                cellClassName: 'col-creationDate',
                title: 'Дата создания заявки',
                grouping: false,
                sorting: false,
                render: (item: MeterPowerSwitchOrderShortViewModel) => {
                    return item.creationDate ? formatDate(item.creationDate, DateFormats.dateTimeZoneFormat) : '–';
                },
            },
            {
                field: 'cumulativeActiveEnergyImp',
                cellClassName: 'col-cumulativeActiveEnergyImp',
                title: 'Текущие показания',
                grouping: false,
                sorting: false,
            },
            {
                field: 'relayStatusBefore',
                cellClassName: 'col-relay',
                title: 'Состояние реле до операции',
                grouping: false,
                sorting: false,
                render: (item: MeterPowerSwitchOrderShortViewModel) => {
                    return item.relayStatusBefore !== null ? meterRelayStatusDictionary[item.relayStatusBefore] : '–';
                },
            },
            {
                field: 'relayStatusAfter',
                cellClassName: 'col-relay',
                title: 'Состояние реле после операции',
                grouping: false,
                sorting: false,
                render: (item: MeterPowerSwitchOrderShortViewModel) => {
                    return item.relayStatusAfter !== null ? meterRelayStatusDictionary[item.relayStatusAfter] : '–';
                },
            },
            {
                field: 'cumulativeActiveEnergyImp',
                cellClassName: 'col-detailsView',
                title: '',
                grouping: false,
                sorting: false,
                render: (item: MeterPowerSwitchOrderShortViewModel) => {
                    const route = getRoute(Routes.meterPowerSwitchOrder, {
                        meterPowerSwitchOrderId: item.id?.toString()
                    });
                    return <DetailsView onClick={() => dispatch(push(route))} />;
                }
            }
        ],
        selectedCount: selected.length,
    });
};
