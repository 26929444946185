import React from 'react';

import './style.scss';
import { Props } from './model';
import { Table } from 'app/shared/components/table';
import { SliderHeader } from '../../shared/sliderHeader';
import { Button } from 'app/shared/components/button';

export const View = (props: Props) => {
    return <div className='error-detalization-container'>
        <SliderHeader
            prevId={props.prevId}
            nextId={props.nextId}
            showSlider={!!props.categoryId}
            sliderText={props.title}
            onClick={props.onSliderClick}
            withoutSliderText='ДЕТАЛИЗАЦИЯ ОШИБОК'
        />
        <div className='main-container'>
            {!props.categoryId ? <span>Для отображения выберите тип ошибок</span> : null}
            {props.categoryId ? <Table
                columns={[
                    {
                        title: 'Ошибка',
                        field: 'label',
                        cellStyle: { width: 252 },
                        sorting: false,
                    },
                    {
                        title: 'Количество',
                        field: 'count',
                        sorting: false,
                    },
                    {
                        title: 'Создано инцидентов',
                        field: 'created',
                        sorting: false,
                    },
                    {
                        title: 'Открытых инцидентов',
                        field: 'opened',
                        sorting: false,
                        render: (rowData: {opened: string, typeId: number}) => {
                            return <div className='summary-information-opened-row-container'>
                                <span className='text'>{rowData.opened}</span>
                                <Button
                                    className='button'
                                    variant='primary'
                                    type='button'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.onMoreClick(rowData.typeId);
                                    }}
                                >Показать ПУ</Button>
                            </div>;
                        },
                    },
                ]}
                onRowClick={() => { return; }}
                data={props.data}
                options={{
                    showTitle: false,
                    toolbar: false,
                    search: false,
                    draggableHeader: false,
                    paging: 'disabled',
                }}
                title={null}
            /> : null}
        </div>
    </div>;
};
