import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { Breadcrumbs } from '../../../shared/components/breadcrumbs';
import { Heading } from '../../../shared/components/heading';
import { HookField } from '../../../shared/components/hookField';
import { Pagination } from '../../../shared/components/pagination';
import { setOrderTransformer } from '../../../shared/components/pagination/helpers';
import { Table } from '../../../shared/components/table';
import { VisualizationSource } from '../../../shared/components/table/model';
import { Wrapper } from '../../../shared/components/wrapper';
import { defaults, Tables } from '../../../shared/constants';
import { legalTypes, meterActionPlanStatusTypes, tariffCountSuggestions } from '../../../shared/constants/enums';
import { IStateProps } from './model';
import { FormProvider } from 'react-hook-form';

export const View: React.FC<IStateProps> = (props) => {
    const {
        breadcrumbs,
        onClickBreadcrumb,
        heading,
        onBackHandler,
        data,
        columns,
        loading,
        setOrder,
        orderParams,
        pagination,
        dataTotal,
        setPage,
        form,
        regionsSuggestions,
    } = props;

    const actions = (
        <>
            <FormProvider {...form}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <HookField
                            isClearable
                            type='select'
                            name='regionId'
                            label='Регион'
                            fullWidth
                            suggestions={regionsSuggestions} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <HookField
                            isClearable
                            type='select'
                            name='tariffCount'
                            label='Кол-во тарифов'
                            fullWidth
                            suggestions={tariffCountSuggestions} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <HookField
                            isClearable
                            type='select'
                            name='legalEntityType'
                            label='Правовая форма'
                            fullWidth
                            suggestions={legalTypes} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <HookField
                            isClearable
                            type='select'
                            name='planStatus'
                            label='Статус опроса'
                            fullWidth
                            suggestions={meterActionPlanStatusTypes} />
                    </Grid>
                </Grid>
            </FormProvider>
        </>
    );

    return <>
        <Box mb={2}>
            <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
        </Box>
        <Box mb={2}>
            <Heading heading={heading} onBackHandler={onBackHandler} />
        </Box>
        <Box mb={2}>
            <Wrapper isBigHeader actions={actions}>
                <Table
                    visualizationSource={VisualizationSource.clientProfile}
                    name={Tables.commonEventLogTable}
                    columns={columns}
                    data={data || []}
                    title={null}
                    variant='white'
                    options={{
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        search: false,
                        toolbar: false,
                        sorting: true,
                        showTitle: false,
                        paging: 'disabled',
                    }}
                    fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                    isLoading={loading}
                    onOrderChange={setOrderTransformer(columns, setOrder)}
                    {...orderParams}
                />
                <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={loading || !data} />
            </Wrapper>
        </Box>
    </>;
};
