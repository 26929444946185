import React, { useEffect, useRef, useState } from 'react';
import { VisibilityMap } from './model';
import { View } from './view';


export const IntersectionObserverWrapper: React.FC = ({ children }) => {
    const navRef = useRef(null);
    const [visibilityMap, setVisibilityMap] = useState<VisibilityMap>({});
    const handleIntersection: IntersectionObserverCallback = (entries) => {
        const updatedEntries: VisibilityMap = {};
        entries.forEach(entry => {
            if (entry.target instanceof HTMLElement) {
                const targetId = entry.target.dataset.targetid;
                updatedEntries[targetId] = entry.isIntersecting;
            }
        });

        setVisibilityMap((prev) => ({
            ...prev,
            ...updatedEntries
        }));
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: navRef.current,
            rootMargin: '5px',
            threshold: 1,
        });

        Array.from(navRef.current.children).forEach((item) => {
            if (item instanceof HTMLElement && item.dataset?.targetid) {
                observer.observe(item);
            }
        });

        return () => observer.disconnect();
    }, [children]);

    return View({
        navRef,
        visibilityMap,
        children
    });
};
