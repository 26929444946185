export enum MeterTariffScheduleStatus {
    Planned = 1,
    IsSetup = 2,
    SetupNotRequired = 3,
}

export const meterTariffScheduleStatusDictionary: Record<MeterTariffScheduleStatus, string> = {
    [MeterTariffScheduleStatus.Planned]: 'Запланирована установка',
    [MeterTariffScheduleStatus.IsSetup]: 'Установлена',
    [MeterTariffScheduleStatus.SetupNotRequired]: 'Установка не требуется',
};
