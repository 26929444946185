import { makeStyles } from '@material-ui/core';
import { CheckboxStyleProps } from './model';

export const useStyles = makeStyles((theme) => ({
    defaultCheckbox: (props: CheckboxStyleProps) => ({
        color: theme.palette.text.secondary,

        '.MuiSvgIcon-root': {
            color: theme.palette.text.hint,
        },

        '.MuiFormControlLabel-label': {
            ...theme.typography.body,
            marginLeft: '-3px',
        },
        '&.Mui-checked .Mui-checked': {
            'svg.MuiSvgIcon-root': {
                color: props.color,
            },
        },
        '&.Mui-disabled svg': {
            opacity: '0.5',
        },
    }),

    darkCheckbox: {
        color: theme.palette.neutral.white,
        '.MuiSvgIcon-root': {
            color: theme.palette.neutral.white,
        },
    },
}));
