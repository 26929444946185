import { push } from 'connected-react-router';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BaseDispatch } from '../../../redux/actions';
import { meterSchemesSelector } from '../../../redux/selectors/meterSchemes';
import { AdministrationTabs, Routes } from '../../shared/constants';
import { getRoute } from '../../shared/pipes';

export const MeterActionSchemeResolver = (viewComponent: React.FC<any>) => {
    return (props: any) => {
        const dispatch = useDispatch<BaseDispatch>();
        const meterSchemes = useSelector(meterSchemesSelector);
        const { schemeId } = useParams<{ schemeId: string; }>();
        const scheme = useMemo(() => meterSchemes.find(item => item.id === +schemeId), [meterSchemes, schemeId]);

        React.useEffect(() => {
            if (!scheme) {
                dispatch(push(getRoute(Routes.administration, {}, { tab: AdministrationTabs.Schemes })));
            }
        }, [scheme]);

        if (!scheme) {
            return null;
        }

        return React.createElement(viewComponent, {
            ...props,
            scheme,
        });
    };
};
