export const yupValidationTranslation = {
    mixed: {
        'default': '${path} недействительно',
        'required': '${path} обязательное поле',
        'oneOf': '${path} должно быть одним из следующих значений: ${values}',
        'notOneOf': '${path} не должно быть одним из следующих значений: ${values}',
        'notType': '${path} тип не соответствует ожидаемому ${type}',
    },
    'string': {
        'length': '${path} должно быть длиной ${length} символов',
        'min': '${path} должно быть минимум ${min} символов',
        'max': '${path} должно быть максимум ${max} символов',
        'matches': '${path} должен соответствовать следующему: "${regex}"',
        'email': '${path} должно быть адресом электронной почты',
        'url': '${path} должно быть URL',
        'uuid': '${path} должно быть UUID',
        'trim': '${path} должна быть обрезанная строка без пробелов по бокам',
        'lowercase': '${path} должно быть в нижнем регистре',
        'uppercase': '${path} должно быть в верхнем регистре',
    },
    'number': {
        'min': '${path} должно быть больше или равно ${min}',
        'max': '${path} должно быть меньше или равно ${max}',
        'lessThan': '${path} должно быть меньше чем ${less}',
        'moreThan': '${path} должно быть больше чем ${more}',
        'positive': '${path} должно быть положительное число',
        'negative': '${path} должно быть отрицательное число',
        'integer': '${path} должно быть целым числом',
    },
    'date': {
        'min': '${path} должно быть позже, чем ${min}',
        'max': '${path} должно быть раньше, чем ${max}',
    },
    'object': {
        'noUnknown': '${path} имеет неопределенные ключи: ${unknown}',
    },
    'array': {
        'min': '${path} должно иметь не менее ${min} элементов',
        'max': '${path} должно иметь меньше или равно ${max} элементов',
        'length': '${path} должен иметь ${length} элементов',
    },
    'boolean': {
        'isValue': '${path} должно быть ${value}',
    },
};
