import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Heading } from '../../shared/components/heading';
import { Breadcrumbs } from '../../shared/components/breadcrumbs';
import { Typography } from '../../shared/components/typography';
import { InspectionLog } from '../inspectionLog';
import { InspectionLogPanel } from '../inspectionLog/components/inspectionLogPanel';
import { InspectionLogFilterTypes, InspectionLogTableColumns } from '../inspectionLog/model';
import { MeterGisTaskProps } from './model';
import { MeterGisTasksStatus } from '../../../common/model/enum/meterGisTasks';
import { Badge } from '../../shared/components/badge';
import { TaskAction } from './taskAction';
import { Wrapper } from '../../shared/components/wrapper';
import { formatDate } from '../../shared/utils/dates';
import { TaskForm } from './taskForm';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.hint,
        marginLeft: '16px',
        paddingTop: '4px',
    },
    taskWrapper: {
        marginTop: '20px',
    },
    infoLine: {
        display: 'flex',
        flexDirection: 'row',
    },
    infoLineTitle: {
        paddingRight: '8px',
    },
}));

export const View: React.FC<MeterGisTaskProps> = ({
    breadcrumbs,
    onClickBreadcrumb,
    task,
    heading,
    onBackHandler,
}) => {
    const classes = useStyles();
    return (
        <>
            <Box mb={2} marginBottom='0'>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box display='flex' alignItems='center' marginTop='8px'>
                <Heading heading={heading} onBackHandler={onBackHandler} />
                {
                    !!task?.number && (
                        <Typography variant='h4' className={classes.title}>№{task.number}</Typography>
                    )
                }
                {/*//TODO: replace with Record*/}
                {task?.status === MeterGisTasksStatus.open && <Box ml={1.5}><Badge state='open' text='Открыт' stroke='#39CC59' /></Box> }
                {task?.status === MeterGisTasksStatus.closed && <Box ml={1.5}><Badge state='closed' text='Закрыт' /></Box> }
                {task?.status === MeterGisTasksStatus.archived && <Box ml={1.5}><Badge state='archived' text='В архиве' /></Box> }
                <Box marginLeft='auto'>
                    <TaskAction taskStatus={task?.status} taskId={task?.id} />
                </Box>
            </Box>
            <Wrapper className={classes.taskWrapper}>
                <Box className={classes.infoLine}>
                    <Typography variant='captionBold' className={classes.infoLineTitle}>Прибор учета:</Typography>
                    <Typography variant='caption'>{task?.meterSerialNum} {task?.address}</Typography>
                </Box>
                <Box className={classes.infoLine}>
                    <Typography variant='captionBold' className={classes.infoLineTitle}>Создан:</Typography>
                    <Typography variant='caption'>{formatDate(task?.createdAt)}</Typography>
                </Box>
                <Box className={classes.infoLine}>
                    <Typography variant='captionBold' className={classes.infoLineTitle}>Изменен:</Typography>
                    <Typography variant='caption'>{task?.updatedAt && formatDate(task?.updatedAt)}</Typography>
                </Box>
                {
                    task && (
                        <TaskForm
                            task={task}
                        />
                    )
                }
            </Wrapper>
            {task?.id && (
                <InspectionLogPanel>
                    <InspectionLog
                        meterId={task?.meterId}
                        gisTaskId={task?.id}
                        isDumpVisible={false}
                        visibleFilters={[
                            InspectionLogFilterTypes.Critical,
                            InspectionLogFilterTypes.IsMy,
                            InspectionLogFilterTypes.DateFrom,
                            InspectionLogFilterTypes.DateTo,
                            InspectionLogFilterTypes.Search,
                        ]}
                        visibleColumns={[
                            InspectionLogTableColumns.Critical,
                            InspectionLogTableColumns.Date,
                            InspectionLogTableColumns.Message,
                        ]}
                    />
                </InspectionLogPanel>
            )}
        </>
    );
};
