import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import {
    ElectricityTariffReportQuery,
    ElectricityTariffReportResponse,
} from '../../../../common/model/meter/reports/electricityTariffReport';
import { electricityTariffReportSlice } from '../../../reducers/reports/electricityTariffReport';
import { AppThunk } from '../../index';
import { meterLegalTypeDictionary } from '../../../../common/model/meter/legalEntityType';

export const getElectricityTariffReport = (
    params: ElectricityTariffReportQuery
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_reports_electricity_tariff, {}, params);
    const data = await httpService.get<ElectricityTariffReportResponse>(route);

    if (data && data.data) {
        data.data.map((record) => {
            record.entityType = meterLegalTypeDictionary[record.entityType];
        });
    }

    dispatch(electricityTariffReportSlice.actions.setReport(data));
};

export const resetElectricityTariffReport = (): AppThunk => (dispatch) => {
    dispatch(electricityTariffReportSlice.actions.setReport(null));
};
