// Состояние задачи на опрос
export enum MeterActionTaskStatus {
	Queue,
	Pending,
	Processed,
	NotProcessed,
}

export const meterActionTaskStatusDictionary: Record<MeterActionTaskStatus, string> = {
	[MeterActionTaskStatus.Queue]: 'В очереди',
	[MeterActionTaskStatus.Pending]: 'В обработке',
	[MeterActionTaskStatus.Processed]: 'Обработана',
	[MeterActionTaskStatus.NotProcessed]: 'Не обработана',
};
