/**
 * Категории меток ПУ
 */
export enum MeterLabelCategory {
    CriticalEvents = 1,
    ConfigValidation = 2,
    RegularReadingErrors = 3,
    ActivationErrors = 4,
    SuspiciousProfile = 5,
    ConnectionLoss = 6,
}

export const meterLabelCategoryDictionary: Record<MeterLabelCategory, string> = {
    [MeterLabelCategory.CriticalEvents]: 'Критические события в журналах ПУ',
    [MeterLabelCategory.ConfigValidation]: 'Валидация конфигурации и сверка времени',
    [MeterLabelCategory.RegularReadingErrors]: 'Ошибки регулярного опроса',
    [MeterLabelCategory.ActivationErrors]: 'Ошибки ввода в эксплуатацию',
    [MeterLabelCategory.SuspiciousProfile]: 'Подозрительный профиль потребления',
    [MeterLabelCategory.ConnectionLoss]: 'Потеря связи',
};
