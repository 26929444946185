import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StorageState } from 'redux/reducers';

import { useSyncQueryParams } from 'app/shared/hooks/useQueryParams';
import { useWebDictionarySelectSuggestions } from 'app/shared/hooks/useWebDictionary';
import { MeterLabelCategory } from 'common/model/meter/meterLabel/meterLabelCategory';
import {
    setMetersCountByRegion, setMetersErrorDetalization, setMetersSummaryInformationByRegion
} from 'redux/actions/monitoring/metersHealth';
import { BaseDispatch } from '../../../../../../redux/actions';
import { createSnackbar } from '../../../../../../redux/actions/controls';
import { getErrorMessage } from '../../../../../shared/utils/error';
import { columnNamesByCategory } from '../../constant';
import { MeterHealthQueryParams } from '../../queryParams';
import { View } from './view';
import { MetersErrorsFindRequest } from './model';
import { useForm } from 'react-hook-form';
import { ReadingSource } from 'common/model/meter/readingSource';

const initialFilterForm: MetersErrorsFindRequest = {
    readingSource: ReadingSource.Reading,
};

export const SummaryInformation = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const { regions } = useWebDictionarySelectSuggestions();
    const [isLoading, setIsLoading] = useState(false);

    const metersCount = useSelector((state: StorageState) => state.monitoring.metersHealth.count);
    const metersData = useSelector((state: StorageState) => state.monitoring.metersHealth.summaryInformation);

    const [filterParams, updateFilterParams] = useSyncQueryParams<MeterHealthQueryParams>({
        regionId: null,
        readingSource: null,
        metersHealthActiveCategoryId: null,
        ...initialFilterForm
    });

    const form = useForm<MetersErrorsFindRequest>({
        defaultValues: {
            readingSource: filterParams?.readingSource ?? initialFilterForm.readingSource,
        }
    });

    const { readingSource } = form.watch();

    const regionId = useMemo(() => {
        return filterParams.regionId ?? regions[0].value;
    }, [filterParams.regionId, regions]);

    useEffect(() => {
        if (regionId) {
            updateFilterParams({ regionId, readingSource });
        }
    }, [regions, regionId]);

    const regionName = useMemo(() => {
        const res = regionId ? regions.find(r => r.value === +regionId)?.label : regions[0]?.label;
        return res;
    }, [filterParams, regions, regionId]);

    const data = useMemo(() => {
        const fullPercent = 100;
        return columnNamesByCategory.filter(i => {
            if (readingSource === ReadingSource.ImportCrq) {
                return (
                    i.categoryId === MeterLabelCategory.RegularReadingErrors ||
                    i.categoryId === MeterLabelCategory.CriticalEvents
                );
            }
            return true;
        }
        )
            .map(categoryInfo => {
                const categoryData = metersData
                    .find(item => item.label === categoryInfo.categoryId);
                return {
                    categoryId: categoryInfo.categoryId,
                    label: categoryInfo.label,
                    noRequestPercent: MeterLabelCategory.RegularReadingErrors === categoryInfo.categoryId
                        ? ((categoryData?.count / metersCount) * fullPercent).toFixed(0) || 0
                        : null,
                    count: categoryData?.count ?? 0,
                    created: categoryData?.created ?? 0,
                    opened: categoryData?.opened ?? 0
                };
            });
    }, [metersData, regionId, metersCount]);

    const onMoreClick = async (categoryId: number) => {
        updateFilterParams({
            metersHealthActiveCategoryId: categoryId,
        });
        await dispatch(setMetersErrorDetalization(regionId, categoryId, readingSource));
    };

    const fetch = async () => {
        setIsLoading(true);
        updateFilterParams({
            ...filterParams,
            readingSource,
            metersHealthActiveCategoryId: null,
            metersHealthActiveTypeId: null,
        });
        try {
            await dispatch(setMetersCountByRegion(regionId, readingSource));
            await dispatch(setMetersSummaryInformationByRegion(regionId, readingSource));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetch();
    }, [regionId, readingSource]);

    return View({
        regionName,
        metersCount,
        onMoreClick,
        isLoading,
        data,
        form,
    });
};
