import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { EllipsisTooltip } from '../../../ellipsisTooltip';
import React from 'react';
import { Icon } from '../../../icon';
import { useStyles } from '../styles';
import { BlockItemProps } from '../model';
import { Typography } from 'app/shared/components/typography';

export const View: React.FC<BlockItemProps> = ({
    item,
    type,
    className,
    count,
}) => {
    const classes = useStyles({ count });
    const withAction = item.onActionClick && item.actionIcon;
    return (
        <Box className={classNames(
            type === 'vertical' ? classes.verticalPosition : null,
            {
                [classes.itemWrapperHorizontal]: type === 'horizontal',
                [classes.itemWithAction]: withAction,
            },
            className,
        )}>
            <Box mb={1} mr={1}>
                <Typography className={classes.title} variant="caption">
                    {item.title}
                </Typography>
            </Box>
            <Typography className={classes.item} variant="caption">
                <EllipsisTooltip text={item.value}>{item.value}</EllipsisTooltip>
            </Typography>
            {(withAction) && (
                <span className={classNames(classes.itemAction)} onClick={item.onActionClick}>
                    <Icon name={item.actionIcon} />
                </span>
            )}
        </Box>

    );
};
