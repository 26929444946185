export enum OrderType {
	Activation = 1,
    PowerSwitch = 2,
    InstantReading = 3,
    CurrentReading = 4,
    RegularReading = 5,
    MeterUpdateTechnicalConfig = 6,
    MeterChangePasswordOrder = 7,
}

export const OrderTypeDictionary: Record<OrderType, string> = {
    [OrderType.Activation]: 'Заявка на ввод в эксплуатацию ПУ',
    [OrderType.PowerSwitch]: 'Заявка на управление реле',
    [OrderType.InstantReading]: 'Заявка на чтение мгновенных значений',
    [OrderType.CurrentReading]: 'Заявка на чтение текущих показаний',
    [OrderType.RegularReading]: 'Заявка на регулярный опрос',
    [OrderType.MeterUpdateTechnicalConfig]: 'Заявка на изменение технических параметров',
    [OrderType.MeterChangePasswordOrder]: 'Заявка на изменение/валидацию пароля',
};
