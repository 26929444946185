import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    NetworkOperatorCheckResult
} from '../../../../../../common/model/meter/networkOperator/networkOperatorCheckResult';
import { checkNetworkOperator } from '../../../../../../redux/actions/networkOperators';
import { useYupValidationResolver } from '../../../../../shared/utils/validation';
import yup from '../../../../../shared/utils/yup';
import { InputProps, NetworkOperatorFormData } from './model';
import { View } from './view';

const networkOperatorValidationSchema = yup.object({
    name: yup
        .string()
        .label('"Название"')
        .required(),
    server:
        yup
            .string()
            .label('"Сервер"')
            .matches(/^https?:\/\/.+/, { message: 'Ожидается: http[s]://адрес сервера m2m' })
            .required(),
    login:
        yup
            .string()
            .label('"Логин"')
            .required(),
    password:
        yup
            .string()
            .label('"Пароль"')
            .required(),
});

export const NetworkOperatorForm: React.FC<InputProps> = (props) => {

    const {
        networkOperator,
    } = props;

    const resolver = useYupValidationResolver<NetworkOperatorFormData>(networkOperatorValidationSchema);
    const [checkResult, setCheckResult] = useState<NetworkOperatorCheckResult>(null);
    const [isChecking, setIsChecking] = useState<boolean>(false);

    const defaultValues: NetworkOperatorFormData = {
        ...networkOperator,
    };

    const form = useForm<NetworkOperatorFormData>({
        defaultValues,
        resolver,
    });

    const formData = form.watch();

    useEffect(() => {
        if (checkResult) {
            setCheckResult(null);
        }
    }, [formData?.server, formData?.login, formData?.password]);

    return View({
        ...props,
        form,
        isChecking,
        checkResult,
        check: async () => {
            setCheckResult(null);
            setIsChecking(true);
            try {
                const result = await checkNetworkOperator(formData);
                setCheckResult(result);
            } finally {
                setIsChecking(false);
            }
        },
    });
};
