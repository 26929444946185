import { combineReducers } from 'redux';
import { commonEventTypesSlice, CommonEventTypesState } from './list';
import { commonSearchEventTypesSlice, CommonSearchEventTypesState } from './searchList';

export interface EventTypesState {
    list: CommonEventTypesState;
    searchList: CommonSearchEventTypesState,
}

export const eventTypes = combineReducers<EventTypesState>({
    list: commonEventTypesSlice.reducer,
    searchList: commonSearchEventTypesSlice.reducer
});
