import { Column } from '@softmedialab/materialui-table';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { StaticMeterGroupContentViewModel } from '../../../../../../../../common/model/meter/meter/staticMeterGroupContentViewModel';
import { MeterSearchQuery } from '../../../../../../common/model/meter/meter/meterSearchQuery';
import { MeterViewModel } from '../../../../../../common/model/meter/meter/meterViewModel';
import { BaseDispatch } from '../../../../../../redux/actions';
import { createSnackbar } from '../../../../../../redux/actions/controls';
import { getMetersDynamicGroupList } from '../../../../../../redux/actions/meterGroup';
import {
    deleteStaticMeterGroupContent,
    getStaticMeterGroupContent,
    updateStaticMeterGroupContent
} from '../../../../../../redux/actions/meters';
import { meterGroupMetersListSelector } from '../../../../../../redux/selectors/meterGroup';
import { staticMeterGroupContentsSelector } from '../../../../../../redux/selectors/meters';
import { Icon } from '../../../../../shared/components/icon';
import { MeterTabs, Routes } from '../../../../../shared/constants';
import { useSyncQueryParams } from '../../../../../shared/hooks/useQueryParams';
import { useWebDictionaryLookup } from '../../../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../../../shared/pipes';
import { getErrorMessage } from '../../../../../shared/utils/error';
import { MeterSerialNumView } from '../../../../reports/components/common/meterSerianNumView';
import { MeterSearchModel } from '../../../../search/model';
import { calculateSelectedFiltersCount } from './helpers';
import { EditGroupProps, ExpandedPanels } from './model';
import './style.css';
import { View } from './view';

const initialFormParams: MeterSearchModel = {
    meterModelValues: [],
    meterPurpose: null,
    legalEntityType: null,
    priceCategory: null,
    status: null,
    accountType: null,
    connectionType: null,
    meterSerialNum: null,
    installationSite: null,
    id: null,
    nextVerificationDate: null,
    communicatorSerialNum: null,
    installationDate: null,
    fiasCode: null,
    relayOn: null,
    relayStatus: null,
    meterModelError: null,
    meterFirmwareVersionError: null,
    cTRatioError: null,
    pTRatioError: null,
    manufactureDateError: null,
    areaName: null,
    townName: null,
    streetName: null,
    houseNumber: null,
    addressString: null,
    meterLabelTypes: []
};

export const EditStaticGroupForm = (props: EditGroupProps) => {
    const { group } = props;
    const dispatch = useDispatch<BaseDispatch>();
    const metersSelector = useSelector(meterGroupMetersListSelector());
    const meters = metersSelector?.data ?? [];
    const staticMeterGroupsContents = useSelector(staticMeterGroupContentsSelector);
    const [isShowResult, setIsShowResult] = useState(false);
    const [isSelectedRow, setIsSelectedRow] = useState(false);
    const [metersGroupList, setMetersGroupList] = useState([]);
    const [expanded, setExpanded] = useState(ExpandedPanels.filterPanel);
    const tableRef: RefObject<any> = useRef();
    const [metersFilter, setMetersFilter] = useSyncQueryParams<MeterSearchModel>(initialFormParams);
    const form = useForm<MeterSearchModel>({ defaultValues: metersFilter });

    const formData = form.watch();

    const countActiveFilters = useMemo(() => {
        return calculateSelectedFiltersCount(formData);
    }, [formData]);

    useEffect(() => {
        dispatch(getStaticMeterGroupContent(group.id));
    }, []);

    //TODO счетчиков нет и нет возможности вывести контент группы
    useEffect(() => {
        const metersGroup = staticMeterGroupsContents.map((content: StaticMeterGroupContentViewModel) => content.meter);
        setMetersGroupList([...metersGroup]);
    }, [staticMeterGroupsContents]);


    const loadMeters = async (query: MeterSearchQuery) => {
        await dispatch(getMetersDynamicGroupList(query));
    };

    const getMetersData = useMemo(() => {
        return meters.filter(meter => {
            return !metersGroupList.some(item => item.id === meter.id);
        });
    }, [meters, metersGroupList]);

    const onClickRemoveRow = async (el: any) => {
        const list = metersGroupList.filter((item) => item.id !== el.id);
        setMetersGroupList([...list]);
        const staticMeterGroupContentId = staticMeterGroupsContents.find(content => content.meterId === el.id);
        await dispatch(deleteStaticMeterGroupContent(staticMeterGroupContentId.id));
        dispatch(getStaticMeterGroupContent(group.id));
    };

    const { meterModels } = useWebDictionaryLookup();

    const columnsDefinition: Column[] = [
        {
            title: 'Тип ПУ',
            field: 'meterModelId',
            lookup: meterModels,
            sorting: true,
            cellClassName: 'col-meter-model',
        },
        {
            title: 'Заводской номер',
            field: 'meterSerialNum',
            sorting: false,
            cellClassName: 'col-number',
            render: (item: MeterViewModel) => {
                const route = getRoute(Routes.meter, { meterId: item.id }, { tab: MeterTabs.Information });
                return <MeterSerialNumView
                    label={item.meterSerialNum}
                    url={route}
                />;
            }
        },
        {
            title: 'Адрес',
            field: 'address.address',
            sorting: false,
        },
    ];

    const columnsGroup: Column[] = [
        {
            title: 'Тип ПУ',
            field: 'meterModelId',
            lookup: meterModels,
            sorting: true,
        },
        {
            title: 'Заводской номер',
            field: 'meterSerialNum',
            sorting: false,
            render: (item: MeterViewModel) => {
                const route = getRoute(Routes.meter, { meterId: item.id }, { tab: MeterTabs.Information });
                return <MeterSerialNumView
                    label={item.meterSerialNum}
                    url={route}
                />;
            }
        },
        {
            title: 'Адрес',
            field: 'address.address',
            sorting: false,
        },
        {
            title: '',
            render: (data) => {
                return <Icon name='close' className='icon' color='error' onClick={ () => onClickRemoveRow(data)} />;
            },
            cellClassName: 'cell-icon',
        },
    ];
    return View({
        data: getMetersData,
        isShowResult,
        columnsDefinition,
        columnsGroup,
        form,
        tableRef,
        metersGroupList,
        expanded,
        countActiveFilters,
        isSelectedRow,
        onClickAddToGroup: async () => {
            const minSelected = tableRef.current?.state?.data?.some((item: any) => item.tableData.checked);
            if (!tableRef.current || !minSelected) {
                dispatch(createSnackbar({
                    type: 'dark',
                    delay: 5000,
                    message: 'Выберите хотя бы один ПУ из поиска'
                }));
                return;
            }
            const selectMeters: MeterViewModel[] = tableRef.current.state.data.filter((meter: any) => {
                if (meter.tableData.checked) {
                    return {
                        id: meter.id,
                        meterSerialNum: meter.meterSerialNum,
                        fiasCode: meter.fiasCode
                    };
                } else {
                    return false;
                }
            });
            const request = {
                groupId: group.id,
                meters: selectMeters,
            };
            await dispatch(updateStaticMeterGroupContent(request));
            dispatch(getStaticMeterGroupContent(group.id));
        },
        onSubmit: async (data) => {
            try {
                setMetersFilter({ ...data });
                await loadMeters({
                    ...data,
                    regionId: group.regionId,
                });
                setExpanded(ExpandedPanels.tablePanel);
                setIsShowResult(true);
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
        },
        handleChange: (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : ExpandedPanels.none);
        },
        onCancelSearchForm: () => {
            setMetersFilter({ ...initialFormParams });
            form.reset(initialFormParams);
            setIsShowResult(false);
        },
        onSelectionChange: (data) => {
            const isSelected = Boolean(data.length);
            setIsSelectedRow(isSelected);
        },
        addressFilterDefaults: {
            addressString: metersFilter.addressString,
            areaName: metersFilter.areaName,
            townName: metersFilter.townName,
            streetName: metersFilter.streetName,
            houseNumber: metersFilter.houseNumber,
        },
    });
};
