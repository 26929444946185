import { Box, Divider, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../shared/components/button';
import { HookField } from '../../../shared/components/hookField';
import { Typography } from '../../../shared/components/typography';
import { FilterFormViewProps } from './model';

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: 'auto',
        backgroundColor: theme.palette.neutral.light,
        marginLeft: -16,
        marginRight: -16
    },
    whiteText: {
        color: theme.palette.neutral.white,
    },
    form: {
        height: '100%',
    },
    fieldsWrapper: {
        height: '100%',
    }
}));

export const View = ({
    form,
    onSubmit,
    onResetForm,
    logTypesSuggestions,
    eventTypesSuggestions,
    watchDateTo,
    watchDateFrom,
    suggestions,
    onSearch,
    params,
    onSearchEventType,
    eventTypesSuggestionsSelected,
}: FilterFormViewProps) => {

    const {
        handleSubmit,
    } = form;

    const classes = useStyles();

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <Box className={classes.fieldsWrapper} display='flex' flexDirection='column' gridGap='16px'>
                    <HookField
                        name='logType'
                        type='select'
                        label='Журнал'
                        fullWidth
                        dark
                        suggestions={logTypesSuggestions}
                    />
                    <HookField
                        name='eventType'
                        placeholder='Событие'
                        searchable
                        selectAll
                        dark
                        onSearch={onSearchEventType}
                        maxVisibleSelections={0}
                        values={eventTypesSuggestions}
                        value={eventTypesSuggestionsSelected}
                        type='multiselect-with-search'
                    />
                    {
                        !params.meterId && <HookField
                            name='meterIds'
                            placeholder='Приборы учета'
                            searchable
                            onSearch={onSearch}
                            maxVisibleSelections={0}
                            values={suggestions}
                            type='multiselect-with-search'
                            dark
                            isClearable
                        />
                    }
                    <HookField
                        type='checkbox'
                        name='isCritical'
                        label='Критическое событие'
                        dark
                    />
                    <Typography className={classes.whiteText} variant='h3'>Дата события</Typography>
                    <HookField
                        type='datepicker'
                        name='dateFrom'
                        label='От'
                        fullWidth
                        dark
                        showTime
                        startDate={watchDateFrom}
                        maxDate={watchDateTo}
                    />
                    <HookField
                        type='datepicker'
                        name='dateTo'
                        label='До'
                        fullWidth
                        dark
                        showTime
                        startDate={watchDateFrom}
                        minDate={watchDateFrom}
                    />
                    <Divider className={classes.divider} />
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Button type='submit' className='m-r-16' variant='primary'>Применить</Button>
                        <Button onClick={onResetForm} variant='secondary'>Очистить фильтры</Button>
                    </Box>
                </Box>
            </form>
        </FormProvider>
    );
};
