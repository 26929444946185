import moment from 'moment';
import React, { useMemo } from 'react';
import { DateFormats } from '../../../../../common/constants/date';
import {
    MeterTariffSchedulesResponse
} from '../../../../../common/model/meter/meterTariffSchedule/meterTariffSchedulesResponse';
import { TariffScheduleGraph } from '../../../../shared/components/tariffScheduleGraph';
import {
    TariffScheduleWidgetTariffGroup,
    TariffScheduleWidgetTariffZone
} from '../../../../shared/components/tariffScheduleGraph/model';
import { BlockProps, BlockPropsItem } from '../../../../shared/components/wrapper/block/model';
import { spreadItemsByCondition } from '../../../../shared/pipes/spreadItemsByCondition';
import { formatDate } from '../../../../shared/utils/dates';
import { InputProps } from './model';
import * as _ from 'lodash';

const formatDateOrUndefined = (date: Date): string => {
    if (!date) {
        return undefined;
    }
    return formatDate(date, DateFormats.dateFormat);
};

const mapData = (
    source: MeterTariffSchedulesResponse, currentMonth: number
): { seasonRange: string; groups: TariffScheduleWidgetTariffGroup[]; } => {
    if (!source?.currentMeterTariffSchedule) {
        return { seasonRange: null, groups: [] };
    }
    const { currentMeterTariffSchedule } = source;
    const { tariffScheduleVersion } = currentMeterTariffSchedule;
    const {
        tariffDays,
        tariffZones,
        tariffDayNames,
        specialDays,
        tariffSeasons,
    } = tariffScheduleVersion;
    const tariffDaysById = _.keyBy(tariffDays, 'id');
    const tariffZonesByDayId = _.groupBy(tariffZones, 'tariffDayId');
    const tariffDayNamesById = _.keyBy(tariffDayNames, 'id');

    const currentSeason = useMemo(() => {
        const sortedSeasons = [...tariffSeasons]
            .sort((a, b) => a.startMonth > b.startMonth ? 1 : -1);
        const currentSeasons = sortedSeasons.filter((item) => item.startMonth <= currentMonth);
        return currentSeasons[0] ?? sortedSeasons[0];
    }, [tariffSeasons]);
    if (!currentSeason) {
        return { seasonRange: null, groups: [] };
    }
    const now = moment();
    const start = now.clone();
    start.set('month', currentSeason.startMonth - 1);
    start.set('date', currentSeason.startDay);
    const nextSeason = useMemo(() => {
        const sortedSeasons = [...tariffSeasons]
            .sort((a, b) => a.startMonth > b.startMonth ? 1 : -1);
        if (sortedSeasons.length <= 1) {
            return null;
        }
        const currentSeasons = sortedSeasons.filter((item) => item.startMonth > currentSeason);
        return currentSeasons[0];
    }, [currentSeason]);
    const end = now.clone();
    if (nextSeason) {
        end.set('month', nextSeason.startMonth - 1);
        end.set('date', nextSeason.startDay);
    } else {
        end.set('month', 0);
        end.set('date', 1);
        end.year(+start.year() + 1);
    }
    end.add(-1, 'day');
    const seasonRange = `${formatDateOrUndefined(start.toDate()) ?? '?'} - ${formatDateOrUndefined(end.toDate()) ?? '?'}`;
    return {
        seasonRange,
        groups: [
            ...[
                tariffDaysById[currentSeason.workingTariffDayId],
                tariffDaysById[currentSeason.weekendTariffDayId],
            ].map<TariffScheduleWidgetTariffGroup>((tariffDay) => ({
                name: tariffDayNamesById[tariffDay.tariffDayNameId]?.name ?? '?',
                zones: tariffZonesByDayId[tariffDay.id].map<TariffScheduleWidgetTariffZone>((tariffZone) => ({
                    startTime: tariffZone.startTime,
                    tariffNumber: tariffZone.tariffNumber,
                }))
            })),
            ...specialDays
                .map<TariffScheduleWidgetTariffGroup>((specialDay) => ({
                    name: tariffDayNamesById[tariffDaysById[specialDay.tariffDayId].tariffDayNameId]?.name ?? '?',
                    zones: tariffZonesByDayId[specialDay.tariffDayId].map<TariffScheduleWidgetTariffZone>((tariffZone) => ({
                        startTime: tariffZone.startTime,
                        tariffNumber: tariffZone.tariffNumber,
                    }))
                }))
        ]
    };
};

export const View: React.FC<InputProps> = (props) => {
    const { response, blockItems = [] } = props;
    const currentMonth = moment().month();
    const { groups, seasonRange } = mapData(response, currentMonth);
    const blockProps: BlockProps = {
        type: 'vertical',
        items: [
            ...blockItems,
            ...spreadItemsByCondition<BlockPropsItem>(!!seasonRange, [
                {
                    title: 'Сезон',
                    value: seasonRange,
                    type: 'text',
                }
            ])
        ]
    };
    return <>
        <TariffScheduleGraph
            blockProps={blockProps}
            groups={groups}
        />
    </>;
};
