import { makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { renderToString } from 'react-dom/server';
import { ElectricityWhiteIcon } from '../../../../assets/icons';
import { IStateProps } from './model';
import { Map } from '../../../shared/components/map';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        display: 'flex',
    },
    content: {
        marginLeft: 13,
    },
    meterInfo: {
        ...theme.typography.body,
        color: theme.palette.neutral.white,
    },
    meterAddress: {
        ...theme.typography.label,
        color: theme.palette.neutral.ultraLight,
    },
}));

export const View = ({ gis, meter }: IStateProps) => {
    const classes = useStyles();

    const getContent = useCallback(() => {
        return gis && meter ? renderToString(
            <div className={classes.root}>
                <div className={classes.icon}>
                    <ElectricityWhiteIcon />
                </div>
                <div className={classes.content}>
                    <div className={classes.meterInfo}>{meter.meterModel} №{meter.meterSerialNum}</div>
                    <div className={classes.meterAddress}>{meter.address?.address}</div>
                </div>
            </div>
        ) : null;
    }, [gis, meter]);

    if (!gis || !meter) {
        return null;
    }

    return <div>
        <Map
            center={[gis.lat, gis.lon]}
            placemarks={[
                {
                    content: getContent(),
                    coordinates: [gis.lat, gis.lon]
                }
            ]}
        />
    </div>;
};
