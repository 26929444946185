import React from 'react';
import { angleDegToRad } from '../../../../../../common/shared/utils/math';
import { drawLineArrow, useD3 } from '../../../../../shared/hooks/useD3';
import { Props } from './model';
const size = 300;

export const View: React.FC<Props> = ({ items }) => {

    const ref = useD3((selection, theme) => {
        /* eslint-disable @typescript-eslint/no-magic-numbers */

        selection
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', size)
            .attr('height', size)
            .attr('fill', '#E5E5E5');

        const linesPadding = 30;

        const circleRadius = (size / 2) - 33;

        let index = 0;

        const legendWidth = 76;
        const legendHeight = 28;

        const itemsLength = items.length;

        selection
            .append('g')
            .attr('transform', `translate(${size - (legendWidth * itemsLength)}, ${size - legendHeight})`)
            .append('rect')
            .attr('width', legendWidth * itemsLength)
            .attr('height', legendHeight)
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('fill', theme.palette.background.paper);

        const drawItem = (angle: number, rotateAngle: number, color: string, paramText: string) => {

            const group = selection
                .append('g')
                .attr('width', size)
                .attr('height', size);

            const mainLineAngleRad = angleDegToRad(-rotateAngle + 90);

            const cosM = Math.cos(mainLineAngleRad);

            const hypoM = (size / 2) - linesPadding;

            const Xm = hypoM * cosM;
            const Ym = hypoM * Math.sin(mainLineAngleRad);

            const lineXm = (size / 2) + Xm;
            const lineYm = (size / 2) - Ym;


            if (angle !== null) {
                drawLineArrow({
                    id: `arrow-${index}-1`,
                    selection: group,
                    arrowSize: 8,
                    color,
                    arrowStrokeWidth: 3,
                    coordinates: [
                        [size / 2, size / 2],
                        [lineXm, lineYm],
                    ],
                });
            }

            let axisTextIndex = 0;

            const drawAxisText = (text: string, x: number, y: number) => {
                if (angle !== null) {
                    const textGroup = group
                        .append('g')
                        .attr('transform', `translate(${x + (rotateAngle > 180 ? -20 : 20)}, ${y})`);
                    textGroup
                        .append('text')
                        .attr('font-size', 14)
                        .attr('font-weight', 600)
                        .attr('fill', theme.palette.text.primary)
                        .attr('dominant-baseline', 'middle')
                        .attr('text-anchor', 'middle')
                        .text(text);
                    drawLineArrow({
                        id: `arrow-${index}-${axisTextIndex}-axis-text`,
                        selection: textGroup,
                        arrowSize: 8,
                        arrowStrokeWidth: 1,
                        color,
                        coordinates: [
                            [-10, -15],
                            [10, -15],
                        ],
                    });
                }
                axisTextIndex++;
            };

            drawAxisText(paramText, lineXm, lineYm);

            const reversedAngle = -rotateAngle + 90 - angle;

            const degRad = angleDegToRad(reversedAngle);

            const cos = Math.cos(degRad);

            const hypo = circleRadius - 50;

            const X = hypo * cos;
            const Y = hypo * Math.sin(degRad);

            const lineX = (size / 2) + X;
            const lineY = (size / 2) - Y;

            if (angle !== null) {
                drawLineArrow({
                    id: `arrow-${index}-2`,
                    selection: group,
                    arrowSize: 8,
                    color,
                    coordinates: [
                        [size / 2, size / 2],
                        [lineX, lineY],
                    ],
                });
            }

            const legendGroup = selection
                .append('g')
                .attr('transform', `translate(${size - (legendWidth * (index + 1))}, ${size - legendHeight})`);


            legendGroup
                .append('text')
                .attr('font-size', 14)
                .attr('font-weight', 600)
                .attr('fill', color)
                .attr('dominant-baseline', 'middle')
                .attr('text-anchor', 'middle')
                .attr('x', legendWidth / 2)
                .attr('y', legendHeight / 2)
                .text(`${angle ?? 0}°`);

            index++;
        };

        const colors: Record<number, string> = {
            [0]: theme.palette.system.warning,
            [1]: theme.palette.system.success,
            [2]: theme.palette.primary.light,
            [3]: theme.palette.primary.dark,
        };

        for (let j = 0; j < itemsLength; j++) {
            const i = itemsLength - j - 1;
            const item = items[i];
            if (!item) {
                continue;
            }
            const angle = itemsLength > 0
                ? i * (360 / itemsLength)
                : 0;

            drawItem(item.angle, angle, colors[i], item.label);
        }

    }, [items]);

    return <>
        <svg
            ref={ref}
            style={{
                height: size,
                width: size,
                marginRight: '0px',
                marginLeft: '0px',
            }}
        />
    </>;
};
