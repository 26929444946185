import { useSelector } from 'react-redux';

import { IStateProps } from './model';
import { activeUserSelector } from '../../../redux/selectors';
import { View } from './view';

export const Profile = () => {
    const activeClient = useSelector(activeUserSelector());

    const props: IStateProps = {
        user: activeClient,
    };

    if (!props.user) {
        return null;
    }
    return View(props);
};
