import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../../redux/actions';
import { usersAllListSelector } from '../../../../../redux/selectors';
import { getEditableUser } from '../../../../../redux/actions/user/editable';
import { AutocompleteOptionsHookReturn, VAutocompleteOption } from '../model';
import { getUserName } from '../../../utils/userName';

export const useUsersAutocomplete = (): AutocompleteOptionsHookReturn => {
    const dispatch = useDispatch<BaseDispatch>();
    const users = useSelector(usersAllListSelector);

    const onSearchUser = async (value?: string) => {
        const userId = users?.find(user => user.name?.toLowerCase().includes(value)
          || user.patronymic?.toLowerCase().includes(value)
          || user.lastName?.toLowerCase().includes(value))?.id;

        await dispatch(getEditableUser(userId));
    };

    const usersOptions: VAutocompleteOption[] = users?.map(user => ({
        label: `${user.lastName || ''} ${user.name || ''} ${user.patronymic || ''}`,
        optionsLabel: getUserName(user.lastName, user.name, user.patronymic),
        value: user.id,
    }));

    return {
        options: usersOptions || [],
        onInputChange: (event, value) => onSearchUser(value),
        loading: false,
    };
};
