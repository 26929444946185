import { makeStyles, Theme } from '@material-ui/core';
import { menuIconHiddenScreenWidth } from '../../../../styles/materialUi';

export const useStyles = makeStyles((theme: Theme) => ({
    topMenu: {
        height: '100%',
        overflow: 'hidden',
        flex: 1,
    },
    listItemRoot: {
        height: '100%',
        width: 'auto',
        borderRight: `0.5px solid ${theme.palette.neutral.main}`,
        '&:hover': {
            backgroundColor: theme.palette.neutral.background,
        },
        '&$listItemSelected': {
            backgroundColor: theme.palette.neutral.white,
            color: theme.palette.primary.light,
        },
    },
    listItemSelected: {},
    listItemIconRoot: {
        minWidth: '24px',
        marginRight: '12px',
    },
    [`@media (max-width: ${menuIconHiddenScreenWidth}px)`]: {
        listItemIconRoot: {
            display: 'none',
        }
    },
    listItemTextRoot: {
        whiteSpace: 'nowrap',
    }
}));
