import { combineReducers } from 'redux';
import { MeterChangePasswordOrdersControl, meterChangePasswordOrdersControlSlice } from './control';
import { MeterChangePasswordOrdersList, meterChangePasswordOrdersListSlice } from './list';

export interface MeterChangePasswordOrdersState {
    list: MeterChangePasswordOrdersList;
    control: MeterChangePasswordOrdersControl;
}

export const meterChangePasswordOrders = combineReducers<MeterChangePasswordOrdersState>({
    list: meterChangePasswordOrdersListSlice.reducer,
    control: meterChangePasswordOrdersControlSlice.reducer,
});
