import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterFileImportViewModel } from 'common/model/meter/meterFileImport/meterFileImportViewModel';

export interface MeterImportCrqListState {
    available: MeterFileImportViewModel[];
}

const initialState: MeterImportCrqListState = {
    available: null,
};

export const meterImportCrqListSlice = createSlice({
    name: '@@vv/meterImportCrq/list',
    initialState,
    reducers: {
        setAvailableMeterImportCrqList(state, action: PayloadAction<MeterFileImportViewModel[]>) {
            state.available = action.payload;
        },
        setAvailableMeterImportCrq(state, action: PayloadAction<MeterFileImportViewModel>) {
            state.available = [action.payload];
        },
    }
});
