import React, { useState } from 'react';

import { IStateProps, OwnProps } from './model';
import { View } from './view';
import { getApiRoute } from '../../pipes';
import { appUrls } from '../../../../common/appUrls';

export const Profile = ({ user, onProfileClick }: OwnProps) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const loginRoute = getApiRoute(appUrls.api_login);
    const logoutRoute = getApiRoute(appUrls.api_logout);

    const props: IStateProps = {
        anchorEl,
        user,
        loginRoute,
        logoutRoute,
        onProfileClick,
        handleClick: (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        },
        handleClose: () => {
            setAnchorEl(null);
        },
    };

    return View(props);
};
