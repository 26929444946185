import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { DateFormats } from '../../../../../common/constants/date';
import { MassOperationCreator } from '../../../../../common/model/meter/massOperation/massOperationCreator';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { modalNotificationsPop, modalNotificationsPush } from '../../../../../redux/actions/controls/modalNotifications';
import { checkMassOperation, setMassOperation, startMassOperation } from '../../../../../redux/actions/massOperations';
import { ModalNotificationVariant } from '../../../../../redux/reducers/controls/modalNotifications';
import { useMeterGroupAutocomplete } from '../../../../shared/components/autocomplete/hooks/useMeterGroupAutocomplete';
import { MonitoringTabs, Routes } from '../../../../shared/constants';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { useHeading } from '../../../../shared/hooks/useHeading';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { getRoute } from '../../../../shared/pipes';
import { formatDate, transformStartDay } from '../../../../shared/utils/dates';
import { getErrorMessage } from '../../../../shared/utils/error';
import { MassOperationCreatorForm } from './model';
import { View } from './view';

export const MassOperationForm: React.FC = () => {

    const dispatch = useDispatch<BaseDispatch>();

    const [isLoading, setIsLoading] = useState(false);

    const queryParams = useQueryParams();

    const regionId = queryParams.get('regionId');

    const defaultValues: MassOperationCreatorForm = {
        isBeginDateSet: false,
        localBeginReadingDate: transformStartDay(new Date()),
        localEndReadingDate: new Date(),
        regionId: regionId
            ? +regionId
            : undefined,
    };

    const form = useForm<MassOperationCreatorForm>({
        defaultValues,
    });

    const formData = form.watch();

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Мониторинг', link: getRoute(Routes.monitoring) },
        { name: 'Массовый опрос', link: getRoute(Routes.monitoring, {}, { tab: MonitoringTabs.MassOperation }) },
        { name: 'Новый опрос', link: getRoute(Routes.massOperationCreate, {}, { regionId }) },
    ]);

    const headingProps = useHeading('Новый опрос', () => {
        dispatch(
            push(
                getRoute(
                    Routes.monitoring,
                    {},
                    { tab: MonitoringTabs.MassOperation, regionId }
                )
            )
        );
    });

    const meterGroupAutocomplete = useMeterGroupAutocomplete({
        regionId: formData.regionId,
    });

    const onSubmit: SubmitHandler<MassOperationCreatorForm> = async (formData) => {
        try {
            setIsLoading(true);
            const {
                isBeginDateSet,
                ...payload
            } = formData;
            const creator: MassOperationCreator = {
                ...payload,
                localBeginReadingDate: isBeginDateSet
                    ? payload.localBeginReadingDate
                    : undefined,
            };
            const creatableMassOperation = await checkMassOperation(creator);
            await dispatch(modalNotificationsPush({
                showHide: true,
                variant: ModalNotificationVariant.Alert,
                title: 'Запуск массового опроса',
                message:
                    `Плановая дата запуска массового опроса ${formatDate(
                        creatableMassOperation.planDate, DateFormats.dateTimeZoneFormat
                    )}. ` +
                    `Рассчитанное количество ПУ для опроса ${creatableMassOperation.meterCount}. Запланировать опрос?`,
                actions: [
                    {
                        children: 'Начать опрос',
                        variant: 'primary',
                        onClick: async () => {
                            try {
                                setIsLoading(true);
                                const creatableMassOperation = await startMassOperation(creator);
                                dispatch(setMassOperation(creatableMassOperation));
                                dispatch(push(getRoute(Routes.massOperation, { id: creatableMassOperation.id?.toString() })));
                            } catch (e) {
                                dispatch(createSnackbar({
                                    type: 'red',
                                    message: getErrorMessage(e),
                                }));
                            } finally {
                                setIsLoading(false);
                            }
                            await dispatch(modalNotificationsPop());
                        },
                        onEnter: true,
                    },
                    {
                        children: 'Отмена',
                        variant: 'secondary',
                        onClick: async () => {
                            await dispatch(modalNotificationsPop());
                        },
                        onEscape: true,
                    }
                ],
            }));
        } catch (e) {
            await dispatch(modalNotificationsPush({
                showHide: true,
                variant: ModalNotificationVariant.Alert,
                title: 'Опрос не запущен',
                message: getErrorMessage(e),
                actions: [
                    {
                        children: 'ОК',
                        variant: 'primary',
                        onClick: async () => {
                            await dispatch(modalNotificationsPop());
                        },
                        onEnter: true,
                    },
                ],
            }));
        } finally {
            setIsLoading(false);
        }
    };

    return View({
        ...breadcrumbProps,
        ...headingProps,
        form,
        formData,
        onSubmit,
        isLoading,
        meterGroupAutocomplete,
    });
};
