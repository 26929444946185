import { Box, Grid, makeStyles } from '@material-ui/core';

import React from 'react';
import { Table } from '../../../shared/components/table';
import { defaults, Tables } from '../../../shared/constants';
import { SettingEditForm } from './components/settingEditForm';
import { IStateProps } from './model';
import { Wrapper } from '../../../shared/components/wrapper';
import { FormProvider } from 'react-hook-form';
import { HookField } from '../../../shared/components/hookField';

const useStyles = makeStyles(() => ({
    tableWrapper: {
        display: 'flex',
        minHeight: '880px',
        '& .custom-table': {
            '& .editButton': {
                visibility: 'hidden',
            },
            '& .MuiTableRow-root': {
                '&:hover': {
                    '& .editButton': {
                        visibility: 'visible',
                    },
                }
            },
        },
        '& table': {
            overflow: 'hidden',
        }
    },
    actions: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    filterWrapper: {
        flex: 1,
    },
    actionsRight: {
        marginLeft: 'auto',
    },
    actionsLeft: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
        flex: 1,
    },
    fieldGroup: {
        flex: '0 0 320px'
    },
    tableCols: {
        '&.col-title': {
            width: '30%',
        },
        '&.col-value': { width: '30%' },
        '&.col-changed': { width: '20%' },
    }
}));

export const View: React.FC<IStateProps> = ({
    form,
    groupSuggestions,
    columns,
    settings,
    isLoading,
    editableSetting,
    onSubmitEditSetting,
    onCloseEditSettingModal,
    isFormLoading,
}) => {
    const classes = useStyles();

    const filterRender = (
        <FormProvider {...form}>
            <Grid className={classes.filterWrapper} container spacing={2}>
                <Grid item className={classes.fieldGroup}>
                    <HookField
                        type='select'
                        name='groupName'
                        label='Группа'
                        fullWidth
                        isClearable
                        disabled={groupSuggestions.length === 0}
                        suggestions={groupSuggestions} />
                </Grid>
            </Grid>
        </FormProvider>
    );

    const tableActionsRender = <Box className={classes.actions}>
        <Box className={classes.actionsLeft}>
            {filterRender}
        </Box>
    </Box>;

    return (
        <>
            <Wrapper actions={tableActionsRender} className={classes.tableWrapper} isHeaderUppercase={false} isBigHeader>
                {settings && (
                    <Table
                        name={Tables.administrationSettingsTable}
                        columns={columns}
                        data={settings}
                        title={null}
                        variant='white'
                        useQueryString={true}
                        options={{
                            headerClassName: classes.tableCols,
                            grouping: false,
                            columnsButton: false,
                            filtering: false,
                            search: false,
                            sorting: true,
                            paging: 'disabled',
                            toolbar: false,
                            showTitle: false,
                            defaultExpanded: true,
                        }}
                        fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                        isLoading={isLoading}
                    />
                )}
            </Wrapper>
            {editableSetting && (
                <SettingEditForm
                    isFormLoading={isFormLoading}
                    setting={editableSetting}
                    onSubmit={onSubmitEditSetting}
                    onCloseModal={onCloseEditSettingModal}
                />
            )}
        </>
    );
};
