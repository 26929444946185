import { push } from 'connected-react-router';
import { MeterActivationOrderMode } from '../../../app/content/relatedSystems/meterActivationOrders/meterActivationOrderDetails/model';
import { MeterActivationOrderTabs, Routes } from '../../../app/shared/constants';
import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute, getRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MeterActivationOrderCreator } from '../../../common/model/meter/meterActivationOrder/meterActivationOrderCreator';
import { MeterActivationOrderDetailsViewModel } from '../../../common/model/meter/meterActivationOrder/meterActivationOrderDetailsViewModel';
import { MeterActivationOrderFilter } from '../../../common/model/meter/meterActivationOrder/meterActivationOrderFilter';
import { MeterActivationOrderListResponse } from '../../../common/model/meter/meterActivationOrder/meterActivationOrderListResponse';
import { MeterActivationOrderSerialNumSuggestions } from '../../../common/model/meter/meterActivationOrder/meterActivationOrderSerialNumSuggestions';
import { ISort } from '../../../common/sort';
import { meterActivationOrderDetailsSlice } from '../../reducers/meterActivationOrders/details';
import { meterActivationOrdersListSlice } from '../../reducers/meterActivationOrders/list';
import { createSnackbar } from '../controls';
import { AppThunk } from '../index';


export const findMeterActivationOrders = (
    sort: ISort,
    filter: MeterActivationOrderFilter,
): AppThunk => async (dispatch) => {

    const route = getApiRoute(appUrls.api_find_meter_activation_orders, {}, {
        count: sort.count,
        offset: sort.offset,
        sortFieldName: sort.sortFieldName,
        asc: sort.asc,
        statuses: filter.statuses,
        meterModelId: filter.meterModelId,
        numberMeterActivationOrder: filter.numberMeterActivationOrder,
        meterSerialNum: filter.meterSerialNum,
        address: filter.address,
        dateFrom: filter.dateFrom,
        dateTo: filter.dateTo,
        networkOperatorId: filter.networkOperatorId,
    });
    const data = await httpService.get<MeterActivationOrderListResponse>(route);
    dispatch(meterActivationOrdersListSlice.actions.setAvailableMeterActivationOrders(data));
};

export const findMeterActivationOrderSerialNums = (
    value: string,
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_find_meter_activation_order_serial_nums, {}, { value });
    const data = await httpService.get<MeterActivationOrderSerialNumSuggestions>(route);
    dispatch(meterActivationOrdersListSlice.actions.setSerialNumSuggestions(data));
};

export const getMeterActivationOrder = (
    meterActivationOrderId: string
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_activation_order, { meterActivationOrderId });
    const order = await httpService.get<MeterActivationOrderDetailsViewModel>(route);
    dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderDetails(order));
};

export const resetMeterActivationOrder = (): AppThunk => (dispatch) => {
    dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderDetails(null));
};

export const updateAndReprocessMeterActivationOrder = (
    meterActivationOrderId: string,
    meterActivationOrder: MeterActivationOrderCreator,
    runInitialDiagnostic: boolean,
): AppThunk => async (dispatch) => {
    dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading());
    const route = getApiRoute(
        appUrls.api_update_and_reprocess_meter_activation_order,
        { meterActivationOrderId },
        { runInitialDiagnostic }
    );
    try {
        const order = await httpService.put<MeterActivationOrderDetailsViewModel>(route, meterActivationOrder);
        dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderDetails(order));
        dispatch(createSnackbar({
            type: 'green',
            message: runInitialDiagnostic ? 'Заявка успешно изменена и запущена' : 'Заявка успешно изменена'
        }));
    } catch (error) {
        dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading(false));
        dispatch(createSnackbar({
            type: 'red',
            message: error.message || `При сохранении произошла ошибка: ${error.message || error?.data?.errorDetails || ''}`
        }));
    }
};

export const reprocessMeterActivationOrder = (
    meterActivationOrderId: string,
): AppThunk => async (dispatch) => {
    dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading(true));
    const route = getApiRoute(appUrls.api_reprocess_meter_activation_order, { meterActivationOrderId });
    try {
        const order = await httpService.put<MeterActivationOrderDetailsViewModel>(route);
        dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderDetails(order));
        dispatch(createSnackbar({ type: 'green', message: 'Заявка успешно запущена' }));
    } catch (error) {
        dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading(false));
        dispatch(createSnackbar({
            type: 'red',
            message: error.message || `При сохранении произошла ошибка: ${error.message || error?.data?.errorDetails || ''}`
        }));
    }
};

export const createMeterActivationOrder = (
    meterActivationOrderCreator: MeterActivationOrderCreator,
    runInitialDiagnostic: boolean,
): AppThunk => async (dispatch) => {
    dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading(true));

    const route = getApiRoute(appUrls.api_post_meter_activation_order, {}, { runInitialDiagnostic });
    try {
        const order = await httpService
            .post<MeterActivationOrderDetailsViewModel>(route, meterActivationOrderCreator);
        if (order?.meterActivationOrderId) {
            dispatch(push(getRoute(
                Routes.meterActivationOrder,
                { meterActivationOrderId: order.meterActivationOrderId },
                { mode: MeterActivationOrderMode.Update }
            )));
            dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderDetails(order));
            dispatch(createSnackbar({
                type: 'green',
                message: runInitialDiagnostic ? 'Заявка успешно сохранена и запущена' : 'Заявка успешно сохранена',
            }));
        } else {
            dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading(false));
            dispatch(createSnackbar({ type: 'red', message: 'При сохранении заявки произошла ошибка' }));
        }
    } catch (error) {
        dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading(false));
        dispatch(createSnackbar({
            type: 'red',
            message: error.message || `При сохранении произошла ошибка: ${error.message || error?.data?.errorDetails || ''}`
        }));
    }
};

export const deleteMeterActivationOrder = (
    meterActivationOrderId: string,
): AppThunk => async (dispatch) => {
    dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading());
    const route = getApiRoute(appUrls.api_delete_meter_activation_order, { meterActivationOrderId });
    try {
        await httpService.delete(route);
        const ordersListRoute = getRoute(Routes.relatedSystems, {}, { meterActivationOrdersTab: MeterActivationOrderTabs.Opened });
        dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading(false));
        dispatch(push(ordersListRoute));
        dispatch(createSnackbar({ type: 'green', message: 'Заявка на ввод ПУ в эксплуатацию успешно удалена' }));
    } catch (error) {
        dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading(false));
        dispatch(createSnackbar({
            type: 'red',
            message: error.message || `При удалении произошла ошибка: ${error.message || error?.data?.errorDetails || ''}`
        }));
    }
};
