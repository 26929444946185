import * as _ from 'lodash';
import { DateFormats } from '../../../../../common/constants/date';
import {
    MeterProfileReportDataView,
    meterProfileReportIntervalDictionary, MeterProfileReportQueryParams,
    MeterProfileReportResponse
} from '../../../../../common/model/meter/reports/meterProfileReport';
import { roundReadings } from '../../../../../common/shared/utils';
import { formatDate } from '../../../../shared/utils/dates';
import { MeterProfileReportTableItem } from './model';

export const getMeterProfileReportResponsePeriodDateString = (
    response: MeterProfileReportResponse, params: MeterProfileReportQueryParams
): string => {
    const { dateFrom, dateTo } = params;
    const timeZone = formatDate(dateFrom, DateFormats.dateOnlyTimeZone);
    const dateFromFormatted = formatDate(dateFrom, DateFormats.dateTimeFormat);
    const dateToFormatted = formatDate(dateTo, DateFormats.dateTimeFormat);
    return `${dateFromFormatted}` +
        `-${dateToFormatted}` +
        ` (${timeZone})`;
};

export const getMeterProfileReportResponseIntervalString = (response: MeterProfileReportResponse): string => {
    return meterProfileReportIntervalDictionary[response?.groupingInterval] ?? null;
};

const calcSumByField = (data: MeterProfileReportDataView[], field: string): string => {
    const dataAvailable = data.filter(item => !!item[field]);
    if (dataAvailable.length === 0) {
        return '–';
    }
    return roundReadings(_.sumBy(dataAvailable, field)).toString();
};

const calcMeanByField = (data: MeterProfileReportDataView[], field: string): string => {
    const dataAvailable = data.filter(item => !!item[field]);
    if (dataAvailable.length === 0) {
        return '–';
    }
    return roundReadings(_.meanBy(dataAvailable, field)).toString();
};

export const transformMeterProfileReportDataViewToTableItem = (
    item: MeterProfileReportDataView,
    idx: number
): MeterProfileReportTableItem => {
    return {
        rowNumber: idx,
        readingDateView: item.readingDateView,
        sumActivePowerExp: roundReadings(item.sumActivePowerExp)?.toString() ?? '–',
        sumActivePowerImp: roundReadings(item.sumActivePowerImp)?.toString() ?? '–',
        sumReactivePowerExp: roundReadings(item.sumReactivePowerExp)?.toString() ?? '–',
        sumReactivePowerImp: roundReadings(item.sumReactivePowerImp)?.toString() ?? '–',
        isResultItem: false,
    };
};

export const transformMeterProfileReportDataViewsToSummary = (data: MeterProfileReportDataView[]): MeterProfileReportTableItem => {
    return {
        rowNumber: null,
        readingDateView: 'Сумма',
        sumActivePowerExp: calcSumByField(data, 'sumActivePowerExp'),
        sumActivePowerImp: calcSumByField(data, 'sumActivePowerImp'),
        sumReactivePowerExp: calcSumByField(data, 'sumReactivePowerExp'),
        sumReactivePowerImp: calcSumByField(data, 'sumReactivePowerImp'),
        isResultItem: true,
    };
};


export const transformMeterProfileReportDataViewsToMean = (data: MeterProfileReportDataView[]): MeterProfileReportTableItem => {
    return {
        rowNumber: null,
        readingDateView: 'Среднее',
        sumActivePowerExp: calcMeanByField(data, 'sumActivePowerExp'),
        sumActivePowerImp: calcMeanByField(data, 'sumActivePowerImp'),
        sumReactivePowerExp: calcMeanByField(data, 'sumReactivePowerExp'),
        sumReactivePowerImp: calcMeanByField(data, 'sumReactivePowerImp'),
        isResultItem: true,
    };
};
