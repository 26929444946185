import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { Button } from 'app/shared/components/button';
import { CrqChannelFormViewProps } from './model';
import { Typography } from 'app/shared/components/typography';
import { HookField } from 'app/shared/components/hookField';

const useStyles = makeStyles(() => ({
    footer: {
        marginTop: 20
    }
}));

export const View = (props: CrqChannelFormViewProps) => {
    const {
        form,
        onSubmit,
        handleCloseModal,
        categorySuggestions,
        crqChannelTypeSuggestions,
        unitOfMeasurementSuggestions
    } = props;

    const classes = useStyles();
    const { handleSubmit } = form;


    return <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Категория</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='select'
                                name='category'
                                fullWidth
                                label='Категория'
                                isClearable
                                suggestions={categorySuggestions}
                            />
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Тип канала</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='select'
                                name='type'
                                label="Тип канала"
                                isClearable
                                fullWidth
                                suggestions={crqChannelTypeSuggestions}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Номер канала</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='number'
                                name='number'
                                fullWidth
                                required
                                label='Номер канала'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Единица измерения</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='select'
                                name='unitOfMeasurement'
                                label='Единица измерения'
                                fullWidth
                                isClearable
                                suggestions={unitOfMeasurementSuggestions}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.footer}>
                    <Grid container spacing={3} justify='center'>
                        <Grid item>
                            <Button variant='primary' type='submit' icon='check'>Сохранить</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='secondary' type='button' onClick={handleCloseModal}>Отмена</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </FormProvider>
    ;
};
