import React from 'react';
import { Props } from './model';
import { Table } from 'app/shared/components/table';
import { SliderHeader } from '../../shared/sliderHeader';
import { useStyles } from './styles';
import { Pagination } from '../../../../../shared/components/pagination';

export const View = (props: Props) => {
    const {
        columns,
        nextId,
        prevId,
        metersHealthActiveTypeId,
        metersHealthActiveCategoryId,
        title,
        onSliderClick,
        data,
        pagination,
        dataTotal,
        setPage,
        isLoading
    } = props;
    const classes = useStyles();

    if (!metersHealthActiveCategoryId) {
        return null;
    }

    return (
        <div className={classes.root}>
            <SliderHeader
                nextId={nextId}
                prevId={prevId}
                showSlider={!!metersHealthActiveTypeId}
                sliderText={title}
                onClick={onSliderClick}
                withoutSliderText='ПУ С ОШИБКАМИ'
            />
            <div className={classes.mainContainer}>
                {metersHealthActiveTypeId
                    ? <>
                        <Table
                            title={null}
                            data={data}
                            columns={columns}
                            options={{
                                showTitle: false,
                                toolbar: false,
                                search: false,
                                draggableHeader: false,
                                paging: 'disabled',
                                pageSize: 10,
                            }}
                        />
                        <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={isLoading} /></>
                    : <span>Для отображения выберите ошибку</span> }
            </div>
        </div>
    );
};
