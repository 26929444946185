import { makeStyles, Grid, Box } from '@material-ui/core';
import { IStateProps } from './model';
import React from 'react';
import { Button } from '../button';
import { Dropzone } from '../dropzone';
import { UploaderFileComponent } from './uploaderFile';
import { UploaderFileState } from './uploaderFile/model';
import { Tooltip } from '../tooltip';
import classNames from 'classnames';
import { Icon } from '../icon';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
    },
    dropzone: {
        marginBottom: 16,
    },
    files: {
        padding: '0 0 8px',
        boxSizing: 'border-box',
        maxWidth: '100%',
        textAlign: 'left',
        maxHeight: 300,
        marginBottom: 12,
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& svg': {
            width: 20,
            height: 20,
        }
    },
    deleteButton: {
        '$file:not(:hover) &': {
            opacity: 0,
        },
        marginLeft: 5
    },
}));

export const View = (props: IStateProps) => {
    const classes = useStyles();
    return <div className={classes.root}>
        <div className={classes.dropzone}>
            <Dropzone
                maxFileSize={props.maxFileSize}
                accepted={props.accepted}
                disabled={props.uploading}
                onChange={props.handleDropFiles} />
        </div>
        {(props.files && props.files.length > 0) &&
        <Grid container>
            <Grid item xs={12}>
                <div className={classes.files}>
                    {props.files
                        .map((file, i) =>
                            <Box display='flex' key={i}>
                                <UploaderFileComponent file={file} onDelete={(id) => props.deleteFile(id)} />
                                <Tooltip text='Удалить'>
                                    <span
                                        onClick={() => props.deleteFile(file.id)}
                                        className={classNames(classes.action, classes.deleteButton)}>
                                        <Icon name='delete' />
                                    </span>
                                </Tooltip>
                            </Box>
                        )
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant='primary'
                    disabled={props.uploading || props.files.filter(item => item.state === UploaderFileState.new).length === 0}
                    icon='uploadAlt'
                    onClick={props.submit}>Загрузить</Button>
            </Grid>
        </Grid>
        }
    </div>;
};
