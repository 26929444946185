import classNames from 'classnames';
import React, { Ref } from 'react';
import { Checkbox, FormControlLabel, useTheme } from '@material-ui/core';
import { StateProps } from './model';
import { useStyles } from './styles';

// TODO: реализовать error, helperText
export const View: React.FC<StateProps> = React.forwardRef((props, ref: Ref<any>) => {
    const theme = useTheme();
    const {
        label,
        name,
        checked,
        color = theme.palette.primary.light,
        disabled,
        onChange,
        dark,
        required,
    } = props;
    const classes = useStyles({ color });
    return (
        <FormControlLabel
            control={
                <Checkbox
                    className="default-checkbox"
                    name={name}
                    checked={checked}
                    style={{ color }}
                    disabled={disabled}
                    onChange={onChange}
                    required={required}
                />
            }
            className={classNames(classes.defaultCheckbox, {
                [classes.darkCheckbox]: dark,
            })}
            label={label}
            ref={ref}
        />
    );
});
