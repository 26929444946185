export const uniqueStrings = (arr: string[]): string[] =>
    arr.filter((v, i, a) => a.indexOf(v) === i);

export const splitArrayToChunks = <S>(array: S[], chunk = 10): S[][] => {
    const result: S[][] = [];
    for (let i = 0, j = array.length; i < j; i += chunk) {
        result.push(array.slice(i, i + chunk));
    }
    return result;
};

export const getIndexesById = (array: { id?: string | number; }[]): Record<string, number> => {
    return array
        .map((item, index) => ({
            id: item.id,
            index,
        }))
        .reduce((prev, current) => ({ ...prev, [current.id]: current.index }), {});
};
