import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { AccessRule } from '../../../../common/model/access/accessRule';
import { Button } from '../../../shared/components/button';
import { Checkbox } from '../../../shared/components/checkbox';
import { HookField } from '../../../shared/components/hookField';
import { NoDataWrapper } from '../../../shared/components/noDataWrapper';
import { Table } from '../../../shared/components/table';
import { Wrapper } from '../../../shared/components/wrapper';
import { Tables } from '../../../shared/constants';
import { useAccessRules } from '../../../shared/hooks/useAccessRules';
import { IStateProps } from './model';
import { useStyles } from './styles';
import { readingSourceSuggestionsByReadingAndCrq } from '../../../shared/utils/readingSourceSuggestions';
import { httpService } from 'app/shared/httpWrapper';
import { MeterActionSchemeViewModel } from 'common/model/meter/meter-action-scheme/meterActionSchemeViewModel';
import { appUrls } from 'common/appUrls';
import { getApiRoute } from 'app/shared/pipes/routing';

export const View: React.FC<IStateProps> = (props) => {
    const {
        form,
        isLoading,
        onClickRefresh,
        onClickFormReport,
        leftTableColumns,
        leftTableData,
        rightTableColumns,
        rightTableData,
        isRightTableFormed,
        selectedLeftTableData,
        isLeftTableFormed,
        setIsAdvancedCrashReportVisible,
        isAdvancedCrashReportVisible,
    } = props;

    const [requestSchema, setRequestSchema] = useState([]);

    useEffect(() => {
        const route = getApiRoute(appUrls.api_get_meter_action_schemes);
        httpService.get<MeterActionSchemeViewModel[]>(route).then((response) => {
            if (!response || !response.length) {
                return;
            }

            const columnData = [{ value: '', label: 'Все' }];

            response?.forEach((record) => {
                columnData.push({ value: String(record.id), label: record.name });
            });

            setRequestSchema(columnData as []);
        });
    //dispatch(meterSchemesListSlice.actions.setAvailableSchemes(data));
    }, []);

    const { hasAccess } = useAccessRules();

    const classes = useStyles();

    const leftActions = (
        <FormProvider {...form}>
            <Grid container spacing={2}>
                {hasAccess(AccessRule.CanGetReportsAdvancedCrash) && (
                    <Grid item>
                        <Checkbox
                            label="Подробный отчет"
                            checked={isAdvancedCrashReportVisible}
                            onChange={() =>
                                setIsAdvancedCrashReportVisible(!isAdvancedCrashReportVisible)
                            }
                        />
                    </Grid>
                )}
                <Grid item xs={2}>
                    <HookField
                        type="number"
                        name="daysOffline"
                        label="Нет показаний, дней"
                        disabled={isLoading}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <HookField
                        fullWidth
                        name="readingSource"
                        type="select"
                        isClearable
                        required
                        suggestions={readingSourceSuggestionsByReadingAndCrq}
                        label="Источник показаний"
                    />
                </Grid>
                <Grid item xs={2}>
                    <HookField
                        fullWidth
                        name="actionScheme"
                        type="select"
                        isClearable
                        required
                        suggestions={requestSchema}
                        label="Схема опроса"
                    />
                </Grid>
                {isLeftTableFormed && (
                    <Grid item>
                        <Button
                            onClick={onClickRefresh}
                            variant="outline"
                            square
                            icon="refresh"
                            type="button"
                            disabled={isLoading}
                        />
                    </Grid>
                )}
                {!isLeftTableFormed && (
                    <Grid item>
                        <Button
                            onClick={onClickFormReport}
                            variant="primary"
                            type="button"
                            disabled={isLoading}
                        >
              Сформировать
                        </Button>
                    </Grid>
                )}
            </Grid>
        </FormProvider>
    );

    const rightTableActions = (
        <div>
            <div className={classes.rightTableTitle}>ПУ без показаний</div>
            <div className={classes.rightTableSubTitle}>
        Район {selectedLeftTableData?.areaName}
            </div>
        </div>
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
                <Wrapper
                    childrenWrapperClassName={classes.wrapperChildren}
                    isBigHeader
                    actions={leftActions}
                    title=""
                    justifyContent="start"
                    flexShrink={4}
                >
                    <NoDataWrapper isDataExists={isLeftTableFormed}>
                        <Box className={classes.tableContainer}>
                            {leftTableData && (
                                <Table
                                    name={Tables.monitoringSurveyConditionTable}
                                    columns={leftTableColumns}
                                    data={leftTableData || []}
                                    title=""
                                    isLoading={isLoading}
                                    variant={'white'}
                                    useQueryString={true}
                                    options={{
                                        toolbar: false,
                                        paging: 'disabled',
                                        grouping: false,
                                        columnsButton: false,
                                        filtering: false,
                                        search: false,
                                        showTitle: false,
                                    }}
                                />
                            )}
                        </Box>
                    </NoDataWrapper>
                </Wrapper>
            </Grid>
            {isRightTableFormed && (
                <Grid item xs={12} lg={5}>
                    <Wrapper
                        isBigHeader
                        actions={rightTableActions}
                        childrenWrapperClassName={classes.wrapperChildren}
                    >
                        <Box className={classes.tableContainer}>
                            <Table
                                name={Tables.monitoringSurveyConditionMetersTable}
                                columns={rightTableColumns}
                                data={rightTableData || []}
                                title={''}
                                isLoading={isLoading}
                                variant={'white'}
                                options={{
                                    toolbar: false,
                                    paging: 'disabled',
                                    grouping: false,
                                    columnsButton: false,
                                    filtering: false,
                                    search: false,
                                    showTitle: false,
                                }}
                            />
                        </Box>
                    </Wrapper>
                </Grid>
            )}
        </Grid>
    );
};
