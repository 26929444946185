import { SystemLogTokenQuery } from '../../../common/model/logbook/systemLogTokenQuery';
import { SystemLogViewResponse } from '../../../common/model/logbook/systemLogViewResponse';
import { SystemLogQuery } from '../../../common/model/logbook/systemLogQuery';
import { SystemLogTokenResponse } from '../../../common/model/meter/systemLog/systemLogTokenResponse';
import { ISort } from '../../../common/sort';
import { AppThunk } from '../index';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { httpService } from '../../../app/shared/httpWrapper';
import { auditSlice } from '../../reducers/audit/list';

export const findAuditLogs = (params: SystemLogQuery & ISort): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_system_log, {}, { ...params });
    const data = await httpService.get<SystemLogViewResponse>(route);
    dispatch(auditSlice.actions.setAuditLogs(data));
};

export const getAuditLogsToken = (params: SystemLogTokenQuery): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_system_log_token, {}, { ...params });
    const data = await httpService.get<SystemLogTokenResponse>(route);
    dispatch(auditSlice.actions.setAuditLogsToken(data));
};

export const resetAuditLogs = (): AppThunk => (dispatch) => {
    dispatch(auditSlice.actions.setAuditLogs({ total: 0, data: null }));
};
export const resetAuditLogsToken = (): AppThunk => (dispatch) => {
    dispatch(auditSlice.actions.setAuditLogsToken(null));
};
