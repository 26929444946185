import { InfoOutlined } from '@material-ui/icons';
import { Column } from '@softmedialab/materialui-table';
import React, { FC } from 'react';
import { Box, Grid, makeStyles, Tooltip } from '@material-ui/core';
import { DateFormats } from '../../../common/constants/date';
import { MeterActionType } from '../../../common/model/meter/meterActionType';
import {
    meterChangePasswordOrderStatusDictionary
} from '../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderStatus';
import {
    meterChangePasswordOrderTypeDictionary
} from '../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderType';
import {
    MeterChangePasswordOrderViewModel
} from '../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderViewModel';
import { Button } from '../../shared/components/button';
import { HookField } from '../../shared/components/hookField';
import { Pagination } from '../../shared/components/pagination';
import { Table } from '../../shared/components/table';
import { TextElipsis } from '../../shared/components/textElipsis';
import { Typography } from '../../shared/components/typography';
import { Wrapper } from '../../shared/components/wrapper';
import { Breadcrumbs } from '../../shared/components/breadcrumbs';
import { Heading } from '../../shared/components/heading';
import { setColumnWidth } from '../../shared/pipes/styles';
import { formatDate } from '../../shared/utils/dates';
import { InspectionLog } from '../inspectionLog';
import { InspectionLogPanel } from '../inspectionLog/components/inspectionLogPanel';
import { IStateProps } from './model';
import { FormProvider } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    headingNode: {
        marginLeft: 12,
    },
    errorStatus: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.light,
        '& svg': {
            width: 16,
            height: 16,
            marginLeft: 5,
        }
    },
    errorStatusText: {
        maxWidth: 130,
        overflow: 'hidden'
    },
    tableWrapper: {
        '& .cell-creationDate': {
            ...setColumnWidth('172px'),
        },
        '& .cell-actionType': {
            ...setColumnWidth('140px'),
        },
        '& .cell-status': {
            ...setColumnWidth('160px'),
        },
        '& .cell-oldAdminPassword': {
            ...setColumnWidth('200px'),
        },
        '& .cell-newAdminPassword': {
            ...setColumnWidth('200px'),
        },
        '& .cell-oldUserPassword': {
            ...setColumnWidth('200px'),
        },
        '& .cell-newUserPassword': {
            ...setColumnWidth('200px'),
        },
    }
}));


const FieldWrapper: FC<{ label: string; }> = ({ label, children }) => {
    return (
        <>
            <Box mb={2}>
                <Typography variant='bodyBold'>
                    {label}
                </Typography>
            </Box>
            <Box>
                {children}
            </Box>
        </>
    );
};

export const View: FC<IStateProps> = (props) => {
    const {
        data,
        dataTotal,
        pagination,
        setPage,
        form,
        meter,
        meterNote,
        breadcrumbs,
        heading,
        onClickBreadcrumb,
        onBackHandler,
        onClickWrite,
        onClickCheck,
        onClickChangeWithoutWrite,
        onClickHidePasswords,
        onClickShowPasswords,
        isPasswordsVisible,
        isPasswordsViewLoading,
        isLoading,
        isWriteLoading,
        isCheckLoading,
        isChangeWithoutWriteLoading
    } = props;

    const classes = useStyles();

    const { handleSubmit } = form;

    if (!meter) {
        return null;
    }

    const columns: Column[] = [
        {
            title: 'Дата',
            field: 'creationDate',
            cellClassName: 'cell-creationDate',
            render: (record: MeterChangePasswordOrderViewModel) => {
                if (!record.creationDate) {
                    return '—';
                }
                return formatDate(record.creationDate, DateFormats.dateTimeFormat);
            }
        },
        {
            title: 'Тип задачи',
            field: 'actionType',
            cellClassName: 'cell-actionType',
            render: (record: MeterChangePasswordOrderViewModel) => {
                if (!record.actionType) {
                    return '—';
                }
                return meterChangePasswordOrderTypeDictionary[record.actionType] ?? record.actionType;
            }
        },
        {
            title: 'Статус',
            field: 'status',
            cellClassName: 'cell-status',
            render: (record: MeterChangePasswordOrderViewModel) => {
                if (!record.status) {
                    return '—';
                }
                if (record.resultCode > 0) {
                    const text = meterChangePasswordOrderStatusDictionary[record.status];
                    return (
                        <Tooltip title={record.resultMessage ?? text}>
                            <span className={classes.errorStatus}>
                                <span className={classes.errorStatusText}>
                                    <TextElipsis lines={1} text={text}>
                                        {text}
                                    </TextElipsis>
                                </span>
                                <InfoOutlined width={16} height={16} />
                            </span>
                        </Tooltip>
                    );
                }
                return meterChangePasswordOrderStatusDictionary[record.status] ?? record.status;
            }
        },
        {
            title: 'Старый пароль администратора',
            field: 'oldAdminPassword',
            cellClassName: 'cell-oldAdminPassword',
            render: (record: MeterChangePasswordOrderViewModel) => {
                if (!record.oldAdminPassword) {
                    return '—';
                }
                return record.oldAdminPassword;
            }
        },
        {
            title: 'Новый пароль администратора',
            field: 'newAdminPassword',
            cellClassName: 'cell-newAdminPassword',
            render: (record: MeterChangePasswordOrderViewModel) => {
                if (!record.newAdminPassword) {
                    return '—';
                }
                return record.newAdminPassword;
            }
        },
        {
            title: 'Старый пароль пользователя',
            field: 'oldUserPassword',
            cellClassName: 'cell-oldUserPassword',
            render: (record: MeterChangePasswordOrderViewModel) => {
                if (!record.oldUserPassword) {
                    return '—';
                }
                return record.oldUserPassword;
            }
        },
        {
            title: 'Новый пароль пользователя',
            field: 'newUserPassword',
            cellClassName: 'cell-newUserPassword',
            render: (record: MeterChangePasswordOrderViewModel) => {
                if (!record.newUserPassword) {
                    return '—';
                }
                return record.newUserPassword;
            }
        },
    ];

    const tableActions = isPasswordsVisible ? (
        <Button
            disabled={isLoading}
            type='button'
            variant='outline'
            isLoading={isPasswordsViewLoading}
            onClick={onClickHidePasswords}
        >
            Скрыть пароли
        </Button>
    ) : (
        <Button
            disabled={isLoading}
            isLoading={isPasswordsViewLoading}
            type='button'
            variant='outline'
            onClick={onClickShowPasswords}
        >
            Показать пароли
        </Button>
    );

    return (
        <>
            <Box mb={2}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2}>
                <Heading
                    heading={heading}
                    note={<span className={classes.headingNode}>{meterNote}</span>}
                    onBackHandler={onBackHandler}
                />
            </Box>
            <Box>
                <Box mb={3}>
                    <Wrapper>
                        <FormProvider {...form}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FieldWrapper label='Автоматически генерировать пароли'>
                                        <HookField
                                            disabled={isLoading}
                                            type='radio'
                                            isHorizontalGroup
                                            name='autoGeneratePassword'
                                            transform={{
                                                output: (v) => {
                                                    return v?.toString() === 'true';
                                                },
                                            }}
                                            values={[
                                                {
                                                    id: true,
                                                    name: 'Да',
                                                },
                                                {
                                                    id: false,
                                                    name: 'Нет',
                                                }
                                            ]}
                                        />
                                    </FieldWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FieldWrapper label='Новый пароль пользователя'>
                                        <HookField
                                            disabled={isLoading}
                                            name='newUserPassword'
                                            type='password'
                                            isShowHidePasswordVisible={true}
                                            label='Пароль пользователя'
                                            fullWidth
                                        />
                                    </FieldWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FieldWrapper label='Новый пароль администратора'>
                                        <HookField
                                            disabled={isLoading}
                                            name='newAdminPassword'
                                            type='password'
                                            isShowHidePasswordVisible={true}
                                            label='Пароль администратора'
                                            fullWidth
                                        />
                                    </FieldWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FieldWrapper label='&shy;'>
                                        <Button
                                            disabled={isLoading}
                                            isLoading={isCheckLoading}
                                            icon='play'
                                            type='button'
                                            variant='outline'
                                            onClick={handleSubmit(onClickCheck)}
                                        >
                                            Запустить проверку паролей
                                        </Button>
                                    </FieldWrapper>
                                </Grid>
                                <Grid item sm={12}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Button
                                                disabled={isLoading}
                                                isLoading={isWriteLoading}
                                                type='button'
                                                variant='primary'
                                                onClick={handleSubmit(onClickWrite)}
                                            >
                                                Записать в ПУ
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                disabled={isLoading}
                                                isLoading={isChangeWithoutWriteLoading}
                                                type='button'
                                                variant='secondary'
                                                onClick={handleSubmit(onClickChangeWithoutWrite)}
                                            >
                                                Изменить без записи в ПУ
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </Wrapper>
                </Box>
                <Box className={classes.tableWrapper}>
                    <Wrapper title='Задачи смены и проверки паролей' actions={tableActions}>
                        <Table
                            title={null}
                            data={data}
                            columns={columns}
                            variant='white'
                            useQueryString={false}
                            options={{
                                paging: 'disabled',
                                grouping: false,
                                filtering: false,
                                search: false,
                                toolbar: false,
                                showTitle: false,
                            }}
                        />
                        <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={!data} />
                    </Wrapper>
                </Box>
            </Box>
            {meter?.id && (
                <InspectionLogPanel>
                    <InspectionLog
                        meterId={meter.id}
                        meterActionType={[
                            MeterActionType.ChangePassword,
                            MeterActionType.ValidatePassword
                        ]}
                    />
                </InspectionLogPanel>
            )}
        </>
    );
};
