import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventTypeViewModel } from '../../../../common/model/meter/eventLog/eventTypeViewModel';
import { EventTypesFindResponse } from '../../../../common/model/meter/eventLog/eventTypesFindResponse';

export interface CommonSearchEventTypesState {
    available: EventTypeViewModel[];
    loading: boolean;
}

const initialState: CommonSearchEventTypesState = {
    available: null,
    loading: false,
};

export const commonSearchEventTypesSlice = createSlice({
    name: '@@vv/searchEventTypes/common',
    initialState,
    reducers: {
        setSearchAvailableEventTypes(state, action: PayloadAction<EventTypesFindResponse>) {
            state.available = action.payload.data;
        },
        setSearchLoadingEventTypes(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        resetSearchAvailableEventTypes(state) {
            state.available = null;
        },
    }
});
