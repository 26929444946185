
import React from 'react';
import { Column } from '@softmedialab/materialui-table';
import { MeterFileImportViewModel } from 'common/model/meter/meterFileImport/meterFileImportViewModel';
import { MeterFileImportStatus, meterFileImportStatusDictionary } from 'common/model/meter/importCrq/meterFileImportStatus';
import { formatDate } from 'app/shared/utils/dates';
import { DateFormats } from 'common/constants/date';
import { Button } from 'app/shared/components/button';
import { Link } from 'app/shared/components/link';
import { getRoute } from 'app/shared/pipes';
import { Routes } from 'app/shared/constants';
import classNames from 'classnames';

export const columns: Column[] = [
    {
        title: '№',
        field: 'id',
        cellClassName: 'col-id',
        sorting: true,
    },
    {
        title: 'Имя файла',
        field: 'fileName',
        cellClassName: 'col-fileName',
        sorting: true,
    },
    {
        title: 'Статус',
        field: 'importStatus',
        cellClassName: 'col-importStatus',
        sorting: false,
        render: (dataItem: MeterFileImportViewModel) => {
            const status = dataItem.importStatus;
            if (!status) {
                return '-';
            }
            return (
                <div className="meterCrqImport__status">
                    <p>{meterFileImportStatusDictionary[status]}</p>
                    {status === MeterFileImportStatus.Processing && (
                        <p className="meterCrqImport__status-processed">Обработано строк: {dataItem.lastProcessedRow} из {dataItem.rowCount}</p>
                    )}
                </div>);
        }
    },
    {
        title: 'Начало обработки',
        field: 'createdAt',
        cellClassName: 'col-createdAt',
        sorting: false,
        render: (dataItem: MeterFileImportViewModel) => {
            if (!dataItem.createdAt) {
                return '-';
            }
            return formatDate(dataItem.createdAt, DateFormats.dateTimeZoneFormat);
        }
    },
    {
        title: 'Строк в файле',
        field: 'rowCount',
        cellClassName: 'col-rowCount',
        sorting: false,
    },
    {
        title: 'Ошибок',
        field: 'errorCount',
        cellClassName: 'col-errorCount',
        sorting: false,
        render: (dataItem: MeterFileImportViewModel) => {
            const count = dataItem.errorCount;
            if (!count && count !== 0) {
                return '-';
            }
            return (<div className={classNames('meterCrqImport__errorCount',
                { 'meterCrqImport__errorCount--red': count > 0 })}>
                {count}
            </div>);
        }
    },
    {
        title: '',
        sorting: false,
        cellClassName: 'col-btn',
        field: '',
        render: (dataItem: MeterFileImportViewModel) => {
            const importCrqFileId = dataItem.id?.toString();
            if (!importCrqFileId) {
                return '-';
            }

            return <Link to={{ pathname: getRoute(Routes.importCrqLog, { importCrqFileId }) }} >
                <Button variant='primary' type='button' className="meterCrqImport__btn">Подробнее</Button>
            </Link>;
        }
    },
];
