import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Props } from './model';
import { TextInput } from '../../textInput';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    button: {
        ...theme.typography.caption,
        color: theme.palette.info.main,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    input: {
        '& .MuiAutocomplete-inputRoot': {
            padding: '1px 0 1px 8px',
        },
    },
}));

export const View: React.FC<Props> = (props) => {

    const {
        options,
        helperText,
        error,
        required,
        dark,
        inputProps,
        label,
        value,
        onChange,
    } = props;
    const classes = useStyles();

    return (
        <>
            <div className={classes.wrapper}>
                <Autocomplete
                    freeSolo
                    disableClearable
                    onChange={(e, v) => {
                        if (!v) {
                            onChange(null);
                        }
                        onChange(v);
                    }}
                    value={value}
                    options={options.map(item => item.label)}
                    renderInput={(params) => (
                        <TextInput
                            helperText={helperText}
                            error={error}
                            required={required}
                            dark={dark}
                            className={classes.input}
                            {...params}
                            inputProps={{ ...params.inputProps, ...inputProps }}
                            label={label}
                            value={value}
                            onChange={(e) => onChange(e?.target?.value)}
                        />
                    )}
                />
            </div>
        </>
    );
};
