import React from 'react';
import { ReportType } from '../../model';
import { ConfigurationHistoryReport } from '../configurationHistoryReport';
import { CrashByRegionsReport } from '../crashByRegionsReport';
import { ElectricityConsumptionReport } from '../electricityConsumptionReport';
import { ElectricityTariffReport } from '../electricityTariffReport';
import { MeterProfileReport } from '../meterProfileReport';
import { NotConnectedMetersReport } from '../notConnectedMetersReport';
import { ElectricityConsumptionBillingReport } from '../electrucityConsumptionBillingReport';
import { IStateProps } from './model';

const getComponentByReportType = (reportType: ReportType): React.FC => {
    switch (reportType) {
        case ReportType.MetersNotConnectedReport: {
            return NotConnectedMetersReport;
        }
        case ReportType.MeterProfileReport: {
            return MeterProfileReport;
        }
        case ReportType.MeterElectricityConsumptionReport: {
            return ElectricityConsumptionReport;
        }
        case ReportType.MeterElectricityTariffReport: {
            return ElectricityTariffReport;
        }
        case ReportType.CrashByRegionsReport: {
            return CrashByRegionsReport;
        }
        case ReportType.ConfigurationHistoryReport: {
            return ConfigurationHistoryReport;
        }
        case ReportType.ElectricityConsumptionBillingReport: {
            return ElectricityConsumptionBillingReport;
        }
        default: {
            return null;
        }
    }
};

export const View = (props: IStateProps) => {
    const { reportType } = props;
    const ReportComponent = getComponentByReportType(reportType);
    if (!ReportComponent) {
        return null;
    }
    return <div>
        <ReportComponent />
    </div>;
};
