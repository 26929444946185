import React from 'react';
import { InfoBadgeProps } from './model';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '../../../../../shared/components/typography';
import classNames from 'classnames';
import { formatDate } from '../../../../../shared/utils/dates';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    badgeRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    badgeContainer: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '20px',
        padding: '2px 12px',
        textAlign: 'center',
    },
    badgeContainerDark: {
        backgroundColor: theme.palette.text.hint,
    },
    badgeText: {
        color: theme.palette.neutral.white,
    },
    updatedInfo: {
        paddingLeft: '8px',
    },
    resolution: {
        paddingLeft: '108px',
    }
}));

export const View = (props: InfoBadgeProps) => {
    const { historyGisTask } = props;
    const classes = useStyles();

    return (
        <Grid className={classes.root}>
            <Grid container className={classes.badgeRoot} xs={12} sm={12} lg={12}>
                <Grid item xs={3} lg={3} sm={3}>
                    <Box className={classNames(classes.badgeContainer, { [classes.badgeContainerDark]: historyGisTask.resolution })}>
                        <Typography
                            variant='body'
                            className={classes.badgeText}
                        >{historyGisTask.resolution ? 'Решено' : 'Изменено'}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={4} lg={4} sm={4}>
                    <Typography variant='caption' className={classes.updatedInfo}>{formatDate(historyGisTask.__changedate)}</Typography>
                </Grid>
                <Grid>
                    <Typography variant='caption' className={classes.updatedInfo}>{historyGisTask.updated_by || '-' }</Typography>
                </Grid>

            </Grid>
            {
                historyGisTask.resolution && (
                    <Grid item className={classes.resolution}>
                        <Typography variant='body'>{historyGisTask.resolution}</Typography>
                    </Grid>
                )
            }
        </Grid>
    );
};
