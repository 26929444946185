import React from 'react';
import { makeStyles, Tabs } from '@material-ui/core';
import { OwnProps } from './model';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '44px',
        position: 'relative',
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderBottom: `1px solid ${theme.palette.neutral.light}`,
        },
        '& .MuiTab-textColorInherit:hover': {
            fontWeight: '400',
            textShadow: '0px 0px 1px black',
        },
        '& .Mui-selected': {
            fontWeight: '400',
            textShadow: '0px 0px 1px black',
        },
    },
    indicator: {
        height: '2px',
        zIndex: 10,
    }
}));

export const View = (props: OwnProps) => {

    const classes = useStyles();

    return <Tabs
        {...props}
        classes={classes}
        indicatorColor='primary'
        variant='scrollable'
        scrollButtons='on'
    />;
};
