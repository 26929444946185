import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Button } from '../../../../shared/components/button';
import { Heading } from '../../../../shared/components/heading';
import { HookField } from '../../../../shared/components/hookField';
import { setOrderTransformer } from '../../../../shared/components/pagination/helpers';
import { Table } from '../../../../shared/components/table';
import { TabMenu } from '../../../../shared/components/tabMenu';
import { TabMenuItem } from '../../../../shared/components/tabMenuItem';
import { Typography } from '../../../../shared/components/typography';
import { Wrapper } from '../../../../shared/components/wrapper';
import { ReportPageTabs, Tables } from '../../../../shared/constants';
import { IStateProps, NotConnectedMetersReportTableData } from './model';

export const RenderFieldView = (
    data: NotConnectedMetersReportTableData, fieldName: keyof NotConnectedMetersReportTableData
): React.ReactNode => {
    return data.isStrong ? <strong>{data[fieldName]}</strong> : data[fieldName];
};

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: 0,
    },
    noDataText: {
        ...theme.typography.body,
        color: theme.palette.text.hint,
    },
    formExcelButton: {
        '& svg': {
            width: '16px',
            height: '16px',
        },
    },
    tableHeader: {
        padding: '16px',
    },
    tableContainer: {
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
        },
    },
}));

export const View = (props: IStateProps) => {
    const {
        data,
        form,
        tabs,
        setTab,
        columns,
        activeTab,
        isLoading,
        breadcrumbs,
        isReportFormed,
        onClickRefresh,
        onClickFormExcel,
        onClickFormReport,
        regionSuggestions,
        onClickBreadcrumb,
        headingOnBackHandler,
        isExcelExportNotAvailable,
        setOrder,
        orderParams,
    } = props;

    const classes = useStyles();

    const getTableTitle = () => {
        return tabs.find(tab => tab.key === activeTab).tableTitle;
    };

    const filterRender = <>
        <FormProvider {...form}>
            <Grid container spacing={2}>
                <Grid item>
                    <HookField
                        type='select'
                        name='regionId'
                        label='Регион'
                        fullWidth
                        disabled={isLoading}
                        suggestions={regionSuggestions} />
                </Grid>
                <Grid item>
                    <HookField
                        type='number'
                        name='daysOffline'
                        label='Нет показаний, дней'
                        min={0}
                        disabled={isLoading}
                        fullWidth />
                </Grid>
                {isReportFormed && (
                    <>
                        <Grid item>
                            <Button
                                onClick={onClickRefresh}
                                variant='outline'
                                square
                                icon='refresh'
                                type='button'
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClickFormExcel}
                                variant='primary'
                                icon='excel'
                                type='button'
                                disabled={isExcelExportNotAvailable || isLoading}
                                className={classes.formExcelButton}
                            >
                                Выгрузить в Excel
                            </Button>
                        </Grid>
                    </>
                )}
                {!isReportFormed && (
                    <Grid item>
                        <Button
                            onClick={onClickFormReport}
                            variant='primary'
                            type='button'
                            disabled={isLoading}
                        >
                            Сформировать
                        </Button>
                    </Grid>
                )}
            </Grid>
        </FormProvider>
    </>;

    const isTableAvailable = data.length > 0 && !isLoading;

    return <>
        <Box mb={2}>
            <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
        </Box>
        <Box mb={2}>
            <Heading heading='Перечень ПУ, с которыми нет связи' onBackHandler={headingOnBackHandler} />
        </Box>
        <Wrapper isBigHeader actions={filterRender} childrenWrapperClassName={classes.wrapper} isHeaderUppercase={false}>
            <Box>
                <TabMenu value={activeTab || ReportPageTabs.NotConnectedMeters} onChange={(e, tab) => setTab(tab)}>
                    {
                        tabs.map(tab =>
                            <TabMenuItem
                                label={tab.title}
                                key={tab.key}
                                value={tab.key}
                            />
                        )
                    }
                </TabMenu>
            </Box>
            {isTableAvailable && (
                <Box p={2} >
                    <Typography variant='h3' className={classes.tableHeader}>{getTableTitle()}</Typography>
                    <Box padding='17px 16px 16px 16px' className={classes.tableContainer}>
                        <Table
                            name={Tables.notConnectedMetersReportTable}
                            columns={columns}
                            data={data || []}
                            title=''
                            isLoading={isLoading}
                            options={{
                                paging: 'classic',
                                pageSize: 500,
                                grouping: false,
                                columnsButton: false,
                                filtering: false,
                                search: false,
                                toolbar: false,
                            }}
                            onOrderChange={setOrderTransformer(columns, setOrder)}
                            {...orderParams}
                        />
                    </Box>
                </Box>
            )}
            {!isTableAvailable && (
                <Box p={2}>
                    <Box className={classes.noDataText}>
                        <div>Нет данных</div>
                    </Box>
                </Box>
            )}
        </Wrapper>
    </>;
};
