import React from 'react';
import { Grid } from '@material-ui/core';
import { SummaryInformation } from './components/summaryInformation';
import { ErrorDetalization } from './components/errorDetalization';
import { MetersWithErrors } from './components/metersWithErrors';

export const View = () => {
    return <Grid container spacing={2}>
        <Grid item xs={6}>
            <SummaryInformation />
        </Grid>
        <Grid item xs={6}>
            <ErrorDetalization />
        </Grid>
        <Grid item xs={12}>
            <MetersWithErrors />
        </Grid>
    </Grid>;
};
