import { Loader } from '../../../../../shared/components/loader';
import { Table } from '../../../../../shared/components/table';
import { Button } from '../../../../../shared/components/button';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { HookField } from '../../../../../shared/components/hookField';
import { Box, Grid } from '@material-ui/core';
import './style.scss';
import { Props } from './model';

import { SliderHeader } from '../../shared/sliderHeader';
import { MeterLabelCategory } from 'common/model/meter/meterLabel/meterLabelCategory';
import { readingSourceSuggestionsByReadingAndCrq } from '../../../../../shared/utils/readingSourceSuggestions';

export const View = (props: Props) => {

    return <div className='summary-information-container'>
        {props.isLoading && (
            <Box className='loader-wrapper'>
                <Loader visible={true} size={50} />
            </Box>
        )}

        <SliderHeader
            showSlider={false}
            onClick={() => { return; }}
            sliderText=''

            withoutSliderText={`Сводная информация. ${props.regionName}`}
        >
            <FormProvider {...props.form}>
                <form>
                    <Grid container spacing={2} >
                        <Grid xs={10} item>
                            <HookField
                                fullWidth
                                name='readingSource'
                                type='select'
                                isClearable
                                required
                                suggestions={readingSourceSuggestionsByReadingAndCrq}
                                label='Источник показаний' />
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </SliderHeader>


        <div className='main-container'>
            <span className='sub-header'>Всего ПУ, введённых в эксплуатацию {props.metersCount}</span>
            <Table
                columns={[
                    {
                        title: 'Показатель',
                        field: 'label',
                        cellStyle: { width: 265 },
                        render: (rowData: {noRequestPercent: string, label: string, categoryId: number}) => {
                            if (rowData.categoryId !== MeterLabelCategory.RegularReadingErrors) {
                                return rowData.label;
                            }
                            return <div>
                                <span>{rowData.label}</span><br />
                                <span className='hint-text'>Процента неопроса: {rowData.noRequestPercent} %</span>
                            </div>;
                        },
                        sorting: false,
                    },
                    {
                        title: 'Количество',
                        field: 'count',
                        sorting: false,
                    },
                    {
                        title: 'Создано инцидентов',
                        field: 'created',
                        sorting: false,
                    },
                    {
                        title: 'Открытых инцидентов',
                        field: 'opened',
                        sorting: false,
                        render: (rowData: {opened: string, categoryId: number}) => {
                            return <div className='summary-information-opened-row-container'>
                                <span className='text'>{rowData.opened}</span>
                                <Button
                                    className='button'
                                    variant='primary'
                                    type='button'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.onMoreClick(rowData.categoryId);
                                    }}
                                >Подробнее</Button>
                            </div>;
                        },
                    },
                ]}
                onRowClick={() => { return; }}
                data={props.data}
                options={{
                    showTitle: false,
                    toolbar: false,
                    search: false,
                    draggableHeader: false,
                    paging: 'disabled',
                }}
                title={null}
            />
        </div>
    </div>;
};
