import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import {
    MeterTariffScheduleStatus
} from '../../../../../common/model/meter/meterTariffSchedule/meterTariffScheduleStatus';
import { Button } from '../../../../shared/components/button';
import { HookField } from '../../../../shared/components/hookField';
import { Modal } from '../../../../shared/components/modal';
import { Typography } from '../../../../shared/components/typography';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Tooltip } from '../../../../shared/components/tooltip';
import { BlockPropsItem } from '../../../../shared/components/wrapper/block/model';
import { tariffCountSuggestions } from '../../../../shared/constants/enums';
import { MeterTariffScheduleWrapper } from '../meterTariffScheduleWrapper';
import { IStateProps } from './model';
import { tariffScheduleIndicatorMapper } from './tariffScheduleIndicatorMapper';
import { TariffScheduleOrderIndicator, TariffScheduleOrderIndicatorProps } from './tariffScheduleOrderIndicator';

export const useStyles = makeStyles(() => ({
    inputLabel: {
        textAlign: 'right',
    },
    buttonContainer: {
        paddingTop: '16px',
    },
    actions: {
        width: 'auto',
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const {
        form,
        response,
        onSubmit,
        onCancel,
        isLoading,
        isFormActive,
        onRewriteTariff,
        onClickChangeTariff,
        meterTariffScheduleInOrder,
        changeTariffForbidden,
    } = props;
    const classes = useStyles();
    const { handleSubmit, formState: { isDirty } } = form;

    const tariffScheduleOrderIndicatorProps: TariffScheduleOrderIndicatorProps = tariffScheduleIndicatorMapper(meterTariffScheduleInOrder);

    const actions = (
        <Grid container spacing={1} className={classes.actions}>
            <Grid item>
                <Button
                    icon='edit'
                    type='button'
                    variant='outline'
                    onClick={onClickChangeTariff}
                    disabled={meterTariffScheduleInOrder?.status === MeterTariffScheduleStatus.Planned
                        || isLoading
                        || changeTariffForbidden}
                >
                    Изменить
                </Button>
            </Grid>
            <Grid item>
                <Tooltip text={changeTariffForbidden ? 'Запись в прибор запрещена' : 'Записать в прибор'}>
                    <Button
                        icon='uploadAlt'
                        type='button'
                        variant='secondary'
                        onClick={onRewriteTariff}
                        square={true}
                        iconWidth='24px'
                        iconHeight='16px'
                        disabled={meterTariffScheduleInOrder?.status === MeterTariffScheduleStatus.Planned
                            || isLoading
                            || changeTariffForbidden}
                    />
                </Tooltip>
            </Grid>
        </Grid>
    );

    const blockItems: BlockPropsItem[] = [
        ...(!tariffScheduleOrderIndicatorProps.hidden
            ? [
                {
                    title: 'Смена тарифного расписания',
                    type: 'text',
                    value: <TariffScheduleOrderIndicator
                        {...tariffScheduleOrderIndicatorProps}
                    />,
                }
            ]
            : []
        ) as BlockPropsItem[],
    ];

    return <>
        <Wrapper title='ТАРИФНОЕ РАСПИСАНИЕ' actions={actions}>
            <MeterTariffScheduleWrapper
                blockItems={blockItems}
                response={response}
            />
        </Wrapper>
        {isFormActive && (
            <Modal size='md'>
                <FormProvider {...form}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={4} className={classes.inputLabel}>
                                        <Typography variant='bodyBold'>
                                            Количество<br />
                                            тарифов
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <HookField
                                            name='tariffCount'
                                            type='select'
                                            label='Количество тарифов'
                                            isClearable
                                            suggestions={tariffCountSuggestions}
                                            fullWidth />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                                    <Grid item>
                                        <Button
                                            icon='check'
                                            type='submit'
                                            variant='primary'
                                            disabled={!isDirty}
                                        >
                                            Сохранить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type='button'
                                            variant='secondary'
                                            onClick={onCancel}
                                        >
                                            Отмена
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Modal>
        )}
    </>;
};
