import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DateFormats } from '../../../../../common/constants/date';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { MeterSyncSchemeUpdater } from '../../../../../common/model/meter/meter/meterSyncSchemeUpdater';
import { MeterActionPlanStatus } from '../../../../../common/model/meter/meterActionPlanStatus';
import { MeterActionPlanViewModel } from '../../../../../common/model/meter/meterActionPlanViewModel';
import { MeterActionTaskStatus } from '../../../../../common/model/meter/meterActionTaskStatus';
import { MeterActionType } from '../../../../../common/model/meter/meterActionType';
import { ReadingSource } from '../../../../../common/model/meter/readingSource';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import {
    getMeterActionPlans,
    getMeterData,
    meterActionPlanRestart,
    repeatMeterTasks,
    syncMeterActionPlans
} from '../../../../../redux/actions/meters';
import { getMeterSchemes } from '../../../../../redux/actions/meterSchemes';
import { updateMeterSyncSchemes } from '../../../../../redux/actions/meterUpdateAttributes';
import {
    resourcesMeterActionSchemesByIdSelector,
    resourcesTimeSynchronizationSchemesByIdSelector
} from '../../../../../redux/selectors';
import { meterActionPlanSelector, meterSelector } from '../../../../../redux/selectors/meters';
import { MeterTabs, Routes } from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { useInterval } from '../../../../shared/hooks/useInterval';
import { getRoute } from '../../../../shared/pipes';
import { transformLocalTimeMeter } from '../../../../shared/utils/dates';
import { getErrorMessage } from '../../../../shared/utils/error';
import { MeterActionPlanItem, MeterActionPlanItemData, MeterActionPlanProps, Props } from './model';
import { View } from './view';

const noDataText = 'нет данных';
const oneHour = 60;
const refreshDelayMs = 5000;
const autoRefresh = process.env.REACT_APP_REGULAR_AUTO_REFRESH !== 'false';

export const MeterActionPlan: React.FC<Props> = (ownProps) => {
    const { meterId } = ownProps;
    const history = useHistory();
    const dispatch = useDispatch<BaseDispatch>();
    const meterActionPlans = useSelector(meterActionPlanSelector);
    const [isLoading, setIsLoading] = useState(false);
    const [isSchemeFormOpen, setIsSchemeFormOpen] = useState(false);
    const { hasAccess } = useAccessRules();
    const canRepeatMeterTasks = hasAccess(AccessRule.CanRepeatMeterTasks);
    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Прибор учета', link: getRoute(Routes.meter, { meterId }, { tab: MeterTabs.Information }) },
        { name: 'План опроса', link: getRoute(Routes.meterActionPlan, null, { meterId }) }
    ]);

    const meter = useSelector(meterSelector);
    const isMeterCrq = meter.readingSource === ReadingSource.ImportCrq;

    const schemesById = useSelector(resourcesMeterActionSchemesByIdSelector());
    const timeSyncSchemesById = useSelector(resourcesTimeSynchronizationSchemesByIdSelector());

    const meterActionScheme = schemesById[meter?.meterActionSchemeId];
    const timeSyncScheme = timeSyncSchemesById[meter?.timeSynchronizationSchemeId];

    const hours = meter?.timeZone / oneHour;
    const timeZone = (hours > 0) ? `+${hours}:00` : `${hours}:00`;

    const onClickBack = (): void => {
        history.goBack();
    };

    const fetch = async () => {
        try {
            await dispatch(getMeterData(meterId));
            await dispatch(getMeterSchemes());
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
    };

    const handleMeterActionPlanRestart = async () => {
        if (window.confirm('Запустить внеочередной опрос?')) {
            try {
                await dispatch(meterActionPlanRestart(meterId));
            } catch {
                dispatch(createSnackbar({
                    type: 'red',
                    message: 'Произошла ошибка при запуске опроса',
                }));
            }
        }
    };

    const handleRepeatActionTask = async () => {
        if (window.confirm('Повторить опрос для данного счетчика? Все показания для данного счетчика будут удалены')) {
            try {
                await dispatch(repeatMeterTasks(meterId));
                await fetch();
            } catch {
                dispatch(
                    createSnackbar(
                        {
                            type: 'red',
                            message: 'Произошла ошибка при повторе опроса',
                        }
                    )
                );
            }
        }
    };

    const requestAllPlans = async (): Promise<void> => {
        setIsLoading(true);
        try {
            await dispatch(getMeterActionPlans(meterId));
            // eslint-disable-next-line no-empty
        } catch {}
        setIsLoading(false);
    };

    const fetchAll = async () => {
        await fetch();
        await requestAllPlans();
    };

    if (autoRefresh) {
        useInterval(fetchAll, refreshDelayMs);
    }

    const onClickSyncMeterPlans = async (): Promise<void> => {
        setIsLoading(true);
        if (confirm('Вы уверены что хотите синхронизировать планы?')) {
            try {
                await dispatch(syncMeterActionPlans());
                // eslint-disable-next-line no-empty
            } catch {}
        }
        setIsLoading(false);
    };

    const getDataPlan = (plan: MeterActionPlanViewModel): MeterActionPlanItem[] => {
        if (plan) {
            const status: 'success' | 'error' = plan.taskStatus === MeterActionTaskStatus.NotProcessed ? 'error' : 'success';
            const hidden = status !== 'error';
            const statusMessage = plan.taskStatus === MeterActionTaskStatus.Processed
                ? 'Успех'
                : plan.taskResultMessage;
            const isPlanDisabled = plan.planStatus === MeterActionPlanStatus.Disabled;
            return [
                {
                    title: 'Последний запуск опроса',
                    value: plan.lastAttemptTimestamp
                        ? `${transformLocalTimeMeter(
                            plan.lastAttemptTimestamp, meter?.timeZone
                        ).format(DateFormats.dateFullTimeFormat)} (${timeZone})`
                        : noDataText,
                    hidden: !plan.lastAttemptTimestamp,
                },
                {
                    title: 'Результат',
                    value: statusMessage,
                    status,
                    hidden: !statusMessage,
                    tooltip: plan.taskResultMessage,
                },
                {
                    title: 'Неуспешных попыток',
                    value: plan.failureCount,
                    hidden
                },
                {
                    title: 'Последний успешный запуск',
                    value: plan.lastSuccessTimestamp
                        ? `${transformLocalTimeMeter(
                            plan.lastSuccessTimestamp, meter?.timeZone
                        ).format(DateFormats.dateFullTimeFormat)} (${timeZone})`
                        : noDataText,
                    hidden
                },
                {
                    title: 'Задача на опрос в работе до',
                    value: plan.nextReviewTimestamp
                        ? `${transformLocalTimeMeter(
                            plan.nextReviewTimestamp, meter?.timeZone
                        ).format(DateFormats.dateFullTimeFormat)} (${timeZone})`
                        : noDataText,
                    hidden: plan.planStatus !== MeterActionPlanStatus.InProgress
                },
                {
                    title: 'Плановая дата следующего запуска',
                    value: plan.nextReviewTimestamp
                        ? `${transformLocalTimeMeter(
                            plan.nextReviewTimestamp, meter?.timeZone
                        ).format(DateFormats.dateFullTimeFormat)} (${timeZone})`
                        : noDataText,
                    hidden: plan.planStatus === MeterActionPlanStatus.InProgress,
                },
                {
                    title: 'Получены данные до даты:',
                    value: plan.startOfVoid
                        ? `${transformLocalTimeMeter(
                            plan.startOfVoid, meter?.timeZone
                        ).format(DateFormats.dateTimeFormat)} (${timeZone})`
                        : noDataText,
                    hidden: !plan.startOfVoid || !plan.lastSuccessTimestamp,
                },
                {
                    title: 'Планирование разрешено',
                    value: isPlanDisabled ? 'Запрещено' : 'Разрешено',
                    hidden: !isPlanDisabled,
                }
            ];
        } else {
            return null;
        }
    };


    const groups: MeterActionPlanItemData[] = useMemo(() => {
        return [
            {
                title: 'Сбор суточных показаний',
                data: getDataPlan(meterActionPlans[MeterActionType.DailyProfileReading]),
            },
            {
                title: 'Сбор текущих показаний, сверка времени',
                data: getDataPlan(meterActionPlans[MeterActionType.CurrentProfileReading]),
                hidden: isMeterCrq,
            },
            {
                title: 'Сбор профиля мощности',
                data: getDataPlan(meterActionPlans[MeterActionType.IncrementalProfileReading]),
            },
            {
                title: 'Чтение конфигурации',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.ConfigurationSnapshot]
                ),
            },
            {
                title: 'Валидация и установка тарифного расписания',
                data: getDataPlan(meterActionPlans[MeterActionType.ValidateOrChangeTariff]),
                hidden: isMeterCrq,
            },
            {
                title: 'Сбор журналов контроля доступа',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.AccessControlEventsCollection]
                ),
                hidden: isMeterCrq,
            },
            {
                title: 'Сбор журналов перепрограммирования ПУ',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.DataCorrectionEventsCollection]
                ),
                hidden: isMeterCrq,
            },
            {
                title: 'Сбор журналов включений/выключений',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.PowerSwitchEventsCollection]
                ),
                hidden: isMeterCrq,
            },
            {
                title: 'Сбор журналов внешних воздействий',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.TamperingEventsCollection]
                ),
                hidden: isMeterCrq,
            },
            {
                title: 'Сбор журналов напряжений',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.VoltageEventsCollection]
                ),
                hidden: isMeterCrq,
            },
            {
                title: 'Сбор журналов токов',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.CurrentEventsCollection]
                ),
                hidden: isMeterCrq,
            },
            {
                title: 'Сбор журналов самодиагностики',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.SelfDiagnosticEventsCollection]
                ),
                hidden: isMeterCrq,
            },
            {
                title: 'Сбор журналов ПКЭ',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.ElectricPowerQualityEventsCollection]
                ),
                hidden: isMeterCrq,
            },
            {
                title: 'Сбор журналов CRQ',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.CrqEventsCollection],
                ),
                hidden: !isMeterCrq,
            },
            {
                title: 'Смена паролей',
                data: getDataPlan(
                    meterActionPlans[MeterActionType.ChangePassword],
                ),
                hidden: isMeterCrq,
            },
        ];
    }, [meterActionPlans]);

    useEffect(() => {
        fetch();
        requestAllPlans();
    }, []);

    const onSubmitMeterSyncSchemeUpdate: SubmitHandler<MeterSyncSchemeUpdater> = async (data) => {
        await dispatch(updateMeterSyncSchemes(meter.id, data));
        setIsSchemeFormOpen(false);
    };

    const canSyncPlans = hasAccess(AccessRule.CanSyncMeterActionPlans);

    const inspectionMeterActionTypeDefaults: MeterActionType[] = Object.values(meterActionPlans)
        .filter(item => !!item)
        .filter(item => item.planStatus !== MeterActionPlanStatus.Disabled)
        .map(item => item.meterActionType);

    const props: MeterActionPlanProps = {
        ...ownProps,
        onClick: onClickBack,
        groups,
        isLoading,
        canSyncPlans,
        onClickSyncMeterPlans,
        canRepeatMeterTasks,
        handleMeterActionPlanRestart,
        handleRepeatActionTask,
        breadcrumbs,
        onClickBreadcrumb,
        meterActionScheme,
        timeSyncScheme,
        meter,
        isSchemeFormOpen,
        onSubmitMeterSyncSchemeUpdate,
        inspectionMeterActionTypeDefaults,
        onCloseSchemeForm: () => {
            setIsSchemeFormOpen(false);
        },
        onOpenSchemeForm: () => {
            setIsSchemeFormOpen(true);
        },
    };
    return View(props);
};
