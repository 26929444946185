import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { MeterActionSchemeViewModel } from '../../../../../common/model/meter/meter-action-scheme/meterActionSchemeViewModel';
import { meterActionSchemeValidationRules } from '../../../../../common/model/meter/meter-action-scheme/validation';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import yup from '../../../../shared/utils/yup';
import { useSchemeDefaultRegionCheck } from '../hooks/useSchemeDefaultRegionCheck';
import { MeterActionSchemeFormProps } from './model';
import { View } from './view';


const meterActionSchemeValidationSchema = yup.object({
    name: yup.string()
        .required(),
    regionId: yup.number()
        .required(),
    isCrq: yup.boolean(),
    configurationSnapshotIntervalHours: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.configurationSnapshotIntervalHours.min),
    currentReadingAllowedDelayHours: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.currentReadingAllowedDelayHours.min),
    dailyReadingAllowedDelayHours: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.dailyReadingAllowedDelayHours.min),
    dailyReadingMaxDays: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.dailyReadingMaxDays.min),
    eventsCollectingAllowedDelayHours: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.eventsCollectingAllowedDelayHours.min),
    incrementalReadingAllowedDelayHours: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.incrementalReadingAllowedDelayHours.min),
    incrementalReadingMaxHours: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.incrementalReadingMaxHours.min),
    monthlyReadingAllowedDelayMonths: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.monthlyReadingAllowedDelayMonths.min),
    minRetryDelay: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.minRetryDelay.min)
        .max(meterActionSchemeValidationRules.minRetryDelay.max),
    maxRetryDelay: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.maxRetryDelay.min)
        .max(meterActionSchemeValidationRules.maxRetryDelay.max),
    maxFailureCount: yup.number()
        .required()
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        .min(meterActionSchemeValidationRules.maxFailureCount.min)
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        .max(meterActionSchemeValidationRules.maxFailureCount.max),
    processStatus: yup.string()
        .required(),
    timeWindows: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.timeWindows.min)
        .max(meterActionSchemeValidationRules.timeWindows.max),
    validateOrChangeTariffIntervalHours: yup.number().nullable(true)
        .when('isCrq', {
            is: false,
            then: yup.number()
                .required()
                .min(meterActionSchemeValidationRules.validateOrChangeTariffIntervalHours.min)
                .max(meterActionSchemeValidationRules.validateOrChangeTariffIntervalHours.max),
        }),
    changePasswordInterval: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.changePasswordInterval.min)
        .max(meterActionSchemeValidationRules.changePasswordInterval.max), // replace by meterActionSchemeValidationRules
    changePasswordPeriod: yup.number()
        .required()
        .min(meterActionSchemeValidationRules.changePasswordPeriod.min)
        .max(meterActionSchemeValidationRules.changePasswordPeriod.max),
});


export const MeterActionSchemeForm = (props: MeterActionSchemeFormProps) => {
    const resolver = useYupValidationResolver<MeterActionSchemeViewModel>(meterActionSchemeValidationSchema);
    const form = useForm<MeterActionSchemeViewModel>({
        defaultValues: props.initialValues,
        resolver,
    });

    const formData = form.watch();
    const { isCrq, regionId, isDefault } = formData;

    const schemeDefaultRegionCheck = useSchemeDefaultRegionCheck();

    useEffect(() => {
        form.reset(props.initialValues);
    }, [props.initialValues]);

    const schemeId = props.initialValues.id;

    const showChangeIsDefaultAlert = useMemo(
        () => schemeDefaultRegionCheck(regionId, isDefault, schemeId),
        [
            regionId,
            schemeId,
            isDefault,
        ]
    );

    return View({
        ...props,
        showChangeIsDefaultAlert,
        form,
        isCrq
    });
};
