import React from 'react';
import { TreeItemType, RenderItemsProps, TreeItem } from './model';
import { Tooltip } from '../tooltip';

import classNames from 'classnames';

import './style.scss';
import { Icon } from '../icon';

export const TreeView: React.FC<RenderItemsProps> = ({
    items,
    handleClickItem,
    visibilityTooltip,
    setVisibilityTooltip,
}): JSX.Element => {
    const isContentOverFlow = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        return event.currentTarget.offsetWidth < event.currentTarget.scrollWidth;
    };

    const renderLastItemName = (item: TreeItem) => {
        const tempName = item.title.split(' ');

        const itemText = <>
            <div>{tempName[0]}</div>
            <div>{tempName[1]}</div>
        </>;

        const itemTextWithTooltip = visibilityTooltip
            ? (
                <Tooltip text={ item.title }>
                    {itemText}
                </Tooltip>
            )
            : itemText;

        const lastItemContent = <>
            <div className='last-item__text'>
                {itemTextWithTooltip}
            </div>
            {item.icon && (
                <Tooltip text={ item.iconTooltip }>
                    <div className='last-item__icon'>
                        <Icon width={32} height={32} name={item.icon} />
                    </div>
                </Tooltip>
            )}
        </>;

        if (visibilityTooltip) {
            return (
                <li
                    className='last-item'
                    onMouseOut={() => setVisibilityTooltip(false)}
                >
                    {lastItemContent}
                </li>
            );
        }
        return (
            <li
                className='last-item'
                onMouseOver={(event) => setVisibilityTooltip(isContentOverFlow(event))}
            >
                {lastItemContent}
            </li>
        );
    };

    const renderTooltip = (item: TreeItem): JSX.Element => {
        if (item && item.type === TreeItemType.Meter) {
            return renderLastItemName(item);
        }
        if (visibilityTooltip && item) {
            return (
                <Tooltip text={item.title}>
                    <li
                        className={classNames('item', { 'opened': item.isOpened })}
                        onMouseOut={() => setVisibilityTooltip(false)}
                    >{item.title}</li>
                </Tooltip>
            );
        }
        return item && (
            <li
                className={classNames('item', { 'opened': item.isOpened })}
                onMouseOver={(event) => setVisibilityTooltip(isContentOverFlow(event))}
            >{item.title}</li>
        );
    };

    const renderIcon = (item: TreeItem): JSX.Element | null => {
        if (item && item.type === TreeItemType.Meter) {
            return null;
        }

        return item && (
            <Icon
                name={item.isOpened ? 'arrowListClose' : 'arrowListOpen'}
                className={classNames({ 'arrow-limit-close': item.isOpened }, 'tree-icon')}
                stroke={!item.isOpened ? 'white' : 'none'}
                fill={item.isOpened ? 'white' : 'none'}
                width={22}
                height={22}
            />
        );
    };

    const renderList = (item: TreeItem): JSX.Element | null => {
        if (item?.items?.length === 0 || !item.isOpened) {
            return null;
        }

        return item && (
            <ul className={classNames('items-list', { 'item-list-opened': item.isOpened })}>
                {
                    item.isOpened
                    && (<TreeView
                        items={item.items}
                        handleClickItem={handleClickItem}
                        setVisibilityTooltip={setVisibilityTooltip}
                        visibilityTooltip={visibilityTooltip}
                    />) }
            </ul>
        );
    };

    return (
        <>
            {items?.map(item => (
                <div className='main-container' key={item.id}>
                    <div
                        className='elem-with-icon'
                        onClick={() => handleClickItem(item)}
                    >
                        {renderIcon(item)}
                        {renderTooltip(item)}
                    </div>
                    {renderList(item)}
                </div>
            ))}
        </>

    );
};
