export enum MeterPowerSwitchRequestStatus {
    Created = 'Created',
    InProgress = 'InProgress',
    Done = 'Done',
    InvalidSignature = 'InvalidSignature',
}

export const meterPowerSwitchRequestStatusDictionary: Record<MeterPowerSwitchRequestStatus, string> = {
    [MeterPowerSwitchRequestStatus.Created]: 'Создан',
    [MeterPowerSwitchRequestStatus.InProgress]: 'В обработке',
    [MeterPowerSwitchRequestStatus.Done]: 'Завершен',
    [MeterPowerSwitchRequestStatus.InvalidSignature]: 'Неправильная подпись',
};
