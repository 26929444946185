export enum MeterLabelName {
    MeterModelError = 'MeterModelError',
    MeterFirmwareVersionError = 'MeterFirmwareVersionError',
    CTRatioError = 'CTRatioError',
    PTRatioError = 'PTRatioError',
    ManufactureDate = 'ManufactureDate',
    CurrentThroughSwitchedOffRelay = 'CurrentThroughSwitchedOffRelay',
    TimeError = 'TimeError',
    TariffError = 'TariffError',
    CriticalEvents = 'CriticalEvents',
    AuthenticationError = 'AuthenticationError',
    InternalError = 'InternalError',
    UnsupportedMeterModel = 'UnsupportedMeterModel',
    ConnectionError = 'ConnectionError',
    SerialNumberError = 'SerialNumberError',
    TimeZoneError = 'TimeZoneError',
    DstError = 'DstError',
    TTLExpired = 'TTLExpired',
    IPAddressError = 'IPAddressError',
    ActivationConnectionError = 'ActivationConnectionError',
    ActivationAuthenticationError = 'ActivationAuthenticationError',
    ActivationSerialNumberError = 'ActivationSerialNumberError',
    MeterSetupError = 'MeterSetupError',
    CurrentReadingError = 'CurrentReadingError',
    ActivationTTLExpired = 'ActivationTTLExpired',
    LackOfDailyReadings = 'LackOfDailyReadings',
    LackOfIncrementalReadings = 'LackOfIncrementalReadings',
    // TODO: remove space, add migration
    RelayStatusError = 'RelayStatusError ',
    UnknownMeterFirmwareVersion = 'UnknownMeterFirmwareVersion ',
    ChangePasswordError = 'ChangePasswordError',
    ActivationMeterModelError = 'ActivationMeterModelError',
    LongConnectionLoss = 'LongConnectionLoss',
    TotalConnectionLack = 'TotalConnectionLack',
}
