import React from 'react';
import { Box, useTheme } from '@material-ui/core';

import { Chart } from '../../../../shared/components/chart';
import { DatePicker } from '../../../../shared/components/datePicker';
import { IStateProps } from './model';
import { Button } from '../../../../shared/components/button';
import { electricityUnit } from '../../../../../common/constants/units';

export const View = ({
    chartId,
    filter,
    handleChangeFilter,
    handleClearFilter,
    yaxis,
    xaxis,
    series,
}: IStateProps) => {
    const theme = useTheme();

    const colors = [
        theme.palette.primary.light,
        theme.palette.text.hint,
        theme.palette.diagrams.light,
        theme.palette.diagrams.medium,
        theme.palette.diagrams.dark,
        theme.palette.system.success,
        theme.palette.system.warning,
    ];

    return (
        <>
            <Box display='flex' alignItems='flex-end' mb={3}>
                <DatePicker className='m-r-16' label='Дата с' value={filter.dateFrom as any} onChange={(date: Date) => handleChangeFilter('dateFrom', date) } />
                <DatePicker className='m-r-16' label='Дата по' value={filter.dateTo as any} onChange={(date: Date) => handleChangeFilter('dateTo', date) } />
                <Button variant='primary' onClick={handleClearFilter} icon='close'>Сбросить фильтр</Button>
            </Box>

            <Chart chartId={chartId} title={electricityUnit} series={series} xaxis={xaxis} yaxis={yaxis} colors={colors} />
        </>
    );
};
