import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { lifecycle, branch, compose } from 'recompose';
import { UserViewModel } from '../../../common/model/access/user/userViewModel';

import { store } from '../../../index';

import { StorageState } from '../../../redux/reducers';
import { Routes } from '../../shared/constants';
import { UserResolver } from './user';

interface IModel {
    user: UserViewModel;
}

export const IsUserResolver = (viewComponent: any) => {

    const wrongWay = lifecycle<IModel, unknown, unknown>({
        componentDidMount() {
            // TODO переписать guard как-то по-другому, т.к. редирект на логин срабатывает, когда защищаемый компонент уже начинает рендериться
            store.dispatch(push(Routes.login));
        },
    });

    const redux = connect<IModel, unknown, unknown, StorageState>(
        (state) => ({
            user: state.users.active.user,
        }),
    );

    const guard = branch(
        (params: IModel) => {
            return !params.user;
        }, wrongWay
    );

    return compose(
        UserResolver,
        redux,
        guard,
    )(viewComponent);
};
