import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import { ImportMeterSubTabs, RelatedSystemsTabs, Routes } from '../../../shared/constants';
import { IStateProps } from './model';
import { push } from 'connected-react-router';
import { getRoute } from '../../../shared/pipes';
import { View } from './view';
import { useAccessRules } from 'app/shared/hooks/useAccessRules';
import { AccessRule } from 'common/model/access/accessRule';

export const ImportMeters: React.FC = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const { notHasAccess } = useAccessRules();
    const isDisabledFileImports = notHasAccess(AccessRule.CanReadMeterFileImports);
    const queryParams = useQueryParams();
    const activeSub = queryParams.get('sub') as ImportMeterSubTabs;

    const subs = useMemo(
        () => {
            const subs = [{
                key: ImportMeterSubTabs.ImportOrders,
                title: 'Источник\u00a0показаний:\u00a0Опрос',
                disabled: false,
            }];
            if (!isDisabledFileImports) {
                subs.push({
                    key: ImportMeterSubTabs.ImportCrq,
                    title: 'Источник\u00a0показаний:\u00a0CRQ',
                    disabled: isDisabledFileImports,
                });
            }

            return subs;
        },
        [isDisabledFileImports],
    );

    useEffect(() => {
        if (!activeSub || !Object.values(ImportMeterSubTabs).includes(activeSub)) {
            const defaultRoute = getRoute(
                Routes.relatedSystems,
                { },
                {
                    tab: RelatedSystemsTabs.ImportMeterActivationOrders,
                    sub: ImportMeterSubTabs.ImportOrders,
                }
            );
            dispatch(push(defaultRoute));
        }
    });

    const props: IStateProps = {
        activeSub,
        subs,
        isDisabledFileImports,
        onChangeSub: (e, sub) => dispatch(push(getRoute(
            Routes.relatedSystems,
            { },
            { tab: RelatedSystemsTabs.ImportMeterActivationOrders, sub }
        ))),
    };

    return View(props);
};
