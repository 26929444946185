import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersFindResponse } from '../../../../../../common/model/access/user/usersFindResponse';
import { UserViewModel } from '../../../../common/model/access/user/userViewModel';

export interface UserListState {
    users: UserViewModel[];
    total: number;
    allUsers: UserViewModel[];
}

const initialState: UserListState = {
    users: null,
    total: 0,
    allUsers: [],
};

export const userListSlice = createSlice({
    name: '@@vv/users/list',
    initialState,
    reducers: {
        setUsersList(state, action: PayloadAction<UsersFindResponse>): void {
            state.users = action.payload.data;
            state.total = action.payload.total;
        },
        setAllUsersList(state, action: PayloadAction<UserViewModel[]>): void {
            state.allUsers = action.payload;
        },
    }
});
