import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Heading } from 'app/shared/components/heading';
import { Wrapper } from 'app/shared/components/wrapper';
import { Uploader } from 'app/shared/components/uploader';
import { ImportMeterActivationOrderProps } from './model';

const useStyles = makeStyles((theme) => ({
    downloadLink: {
        ...theme.typography.body,
        color: theme.palette.primary.main,
    }
}));

export const View: React.FC<ImportMeterActivationOrderProps> = ({ onSubmit, onUploadEnd }) => {
    const classes = useStyles();

    const templateFile = '/xlsx/import-activation-orders.xlsx';

    const renderWrapperAction = (
        <Box width='100%' display='flex' justifyContent='flex-end'>
            <a href={templateFile} className={classes.downloadLink}>Скачать шаблон</a>
        </Box>
    );

    return (
        <>
            <Box mb={2} display='flex' justifyContent='space-between'>
                <Heading heading='Импорт заявок на ввод ПУ в эксплуатацию' />
            </Box>
            <Grid container>
                <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
                    <Wrapper actions={renderWrapperAction} title='ИМПОРТ XLSX' isBigHeader>
                        <Uploader
                            accepted={['xlsx']}
                            onSubmit={onSubmit}
                            onUploadEnd={onUploadEnd}
                        />
                    </Wrapper>
                </Grid>
            </Grid>
        </>
    );
};
