import { combineReducers } from 'redux';
import { meterLabelTypeListSlice, MeterLabelTypeState } from './list';

export interface MeterLabelTypesState {
    list: MeterLabelTypeState;
}

export const meterLabelType = combineReducers<MeterLabelTypesState>({
    list: meterLabelTypeListSlice.reducer,
});
