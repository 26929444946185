import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../shared/components/button';
import { Heading } from '../../../../shared/components/heading';
import { HookField } from '../../../../shared/components/hookField';
import { Table } from '../../../../shared/components/table';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Tables } from '../../../../shared/constants';
import { IStateProps } from './model';
import { useStyles } from './styles';
import { Typography } from '../../../../shared/components/typography';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';

export const View: React.FC<IStateProps> = (props) => {
    const {
        data,
        columns,
        form,
        onClickFormReport,
        regionSuggestions,
        isLoading,
        isReportFormed,
        onClickRefresh,
        onClickFormExcel,
        isExcelExportNotAvailable,
        headingOnBackHandler,
        meterGroupAutocomplete,
        breadcrumbs,
        onClickBreadcrumb,
    } = props;

    const classes = useStyles();

    const filterRender = <>
        <FormProvider {...form}>
            <Grid container spacing={2}>
                <Grid xs={2} item>
                    <HookField
                        type='select'
                        name='regionId'
                        label='Регион'
                        fullWidth
                        disabled={isLoading}
                        suggestions={regionSuggestions} />
                </Grid>
                <Grid xs={2} item>
                    <HookField
                        type='autocomplete'
                        name='meterGroupId'
                        label='Группа ПУ'
                        disabled={isLoading}
                        {...meterGroupAutocomplete}
                        fullWidth />
                </Grid>
                <Grid xs={2} item>
                    <HookField
                        fullWidth
                        name='date'
                        type='datepicker'
                        label='Дата'
                        showTime={true}
                        disabled={isLoading}
                    />
                </Grid>
                {isReportFormed && (
                    <>
                        <Grid item>
                            <Button
                                onClick={onClickRefresh}
                                variant='outline'
                                square
                                icon='refresh'
                                type='button'
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClickFormExcel}
                                variant='primary'
                                icon='excel'
                                type='button'
                                disabled={isExcelExportNotAvailable || isLoading}
                            >
                                Выгрузить в Excel
                            </Button>
                        </Grid>
                    </>
                )}
                {!isReportFormed && (
                    <Grid item>
                        <Button
                            onClick={onClickFormReport}
                            variant='primary'
                            type='button'
                            disabled={isLoading}
                        >
                            Сформировать
                        </Button>
                    </Grid>
                )}
            </Grid>
        </FormProvider>
    </>;

    const isTableAvailable = data?.length > 0;

    const getCreateReportDate = () => {
        const tempDate = new Date();
        const day = tempDate.getDate() < 10 ? `0${tempDate.getDate()}` : tempDate.getDate();
        const month = tempDate.getMonth() < 10 ? `0${tempDate.getMonth()}` : tempDate.getMonth();
        const year = tempDate.getFullYear();
        const timezone = tempDate.getTimezoneOffset();
        const hour = tempDate.getHours() < 10 ? `0${tempDate.getHours()}` : tempDate.getHours();
        const minutes = tempDate.getMinutes() < 10 ? `0${tempDate.getMinutes()}` : tempDate.getMinutes();
        const minutesInHour = 60;

        return `${day}.${month}.${year} ${hour}:${minutes} (UTC +${-timezone / minutesInHour})`;
    };

    return (
        <Box mb={3}>
            <Box mb={2}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2}>
                <Heading heading='Показания электроэнергии по тарифам' onBackHandler={headingOnBackHandler} />
            </Box>
            <Wrapper isBigHeader actions={filterRender} childrenWrapperClassName={classes.wrapper}>
                {isTableAvailable && (
                    <>
                        <Box padding='16px 16px 19px 16px'>
                            <Typography variant='caption' className={classes.noDataText}>Дата и время формирования отчёта</Typography>
                            <Typography variant='caption'>{getCreateReportDate()}</Typography>
                        </Box>
                        <Box padding='16px' className={classes.tableContainer}>
                            <Table
                                name={Tables.electricityTariffReportTable}
                                columns={columns}
                                data={data || []}
                                title={''}
                                useQueryString={true}
                                options={{
                                    headerClassName: classes.tableCols,
                                    showTitle: false,
                                    toolbar: false,
                                    paging: 'classic',
                                    pageSize: 500,
                                    grouping: false,
                                    columnsButton: false,
                                    filtering: false,
                                    search: false,
                                }}
                            />
                        </Box>
                    </>
                )}
                {!isTableAvailable && (
                    <Box p={2}>
                        <Box className={classes.noDataText}>
                            <div>Нет данных</div>
                        </Box>
                    </Box>
                )}
            </Wrapper>
        </Box>
    );
};
