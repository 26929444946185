// Тип команды на счётчики

import { OrderType } from '../inspectionLog/OrderType';

/**
 * Типы задач для обмена с серверами опроса.
 */
export enum MeterActionTypeExchange {
    // реализовано
    MonthlyProfileReading = 'MonthlyProfileReading', // Параметры ежемесячного профиля
    DailyProfileReading = 'DailyProfileReading', // Параметры ежесуточного профиля
    CurrentProfileReading = 'CurrentProfileReading', // Параметры текущего профиля
    IncrementalProfileReading = 'IncrementalProfileReading',
    TamperingEventsCollection = 'TamperingEventsCollection', // журнал внешних воздействий
    PowerSwitchEventsCollection = 'PowerSwitchEventsCollection', // журнал включений/выключений
    AccessControlEventsCollection = 'AccessControlEventsCollection', // журнал перепрограммирования ПУ
    DataCorrectionEventsCollection = 'DataCorrectionEventsCollection', // журнал корректировок
    CrqEventsCollection = 'CrqEventsCollection', // Сбор событий журналов по протоколу CRQ
    ConfigurationSnapshot = 'ConfigurationSnapshot', // журнал корректировок
    PowerSwitchControl = 'PowerSwitchControl', // Задача на переключение реле (ограничение \ возобновление потребления)
    InstantParametersProfile = 'InstantParametersProfile', // Мгновенные показания
    MeterSetup = 'MeterSetup', // Первичная диагностика
    ValidateOrChangeTariff = 'ValidateOrChangeTariff', // Задача на установку \ смену тарифного расписания
    VoltageEventsCollection = 'VoltageEventsCollection', // журнал напряжений
    CurrentEventsCollection = 'CurrentEventsCollection', // журнал токов
    SelfDiagnosticEventsCollection = 'SelfDiagnosticEventsCollection', // журнал самодиагностики
    ElectricPowerQualityEventsCollection = 'ElectricPowerQualityEventsCollection', // журнал ПКЭ
    MeterUpdateTechnicalConfig = 'MeterUpdateTechnicalConfig',
    ChangePassword = 'ChangePassword',
    ValidatePassword = 'ValidatePassword',

    // не реализовано
    TimeCorrection = 'TimeCorrection', // Коррекция времени
    PowerLoadProfile = 'PowerLoadProfile', // Параметры профиля нагрузки
    NetworkQualityProfile = 'NetworkQualityProfile', // Параметры качества сети

    /** @deprecated */
    TelemetryProfileReading = 'TelemetryProfileReading', // Параметры профиля телеизмерений
    /** @deprecated */
    ConsumptionRestriction = 'ConsumptionRestriction', // Ограничение потребления
    /** @deprecated */
    ResumptionOfConsumption = 'ResumptionOfConsumption', // Возобновление потребления
    /** @deprecated */
    MeterPassportData = 'MeterPassportData', // Паспортные данные
    /** @deprecated */
    ProgrammableParametersProfile = 'ProgrammableParametersProfile', // Программируемые параметры
    /** @deprecated */
    ExternalInfluencesCounters = 'ExternalInfluencesCounters', // Счётчики внешних воздействий
}

/**
 * Типы задач для внутреннего использования
 */
export enum MeterActionType {
    MonthlyProfileReading = 1,
    DailyProfileReading = 2,
    CurrentProfileReading = 3,
    IncrementalProfileReading = 4,
    TamperingEventsCollection = 5,
    PowerSwitchEventsCollection = 6,
    AccessControlEventsCollection = 7,
    DataCorrectionEventsCollection = 8,
    ConfigurationSnapshot = 9,
    PowerSwitchControl = 10,
    InstantParametersProfile = 11,
    MeterSetup = 12,
    ValidateOrChangeTariff = 13,
    VoltageEventsCollection = 14,
    CurrentEventsCollection = 15,
    SelfDiagnosticEventsCollection = 16,
    ElectricPowerQualityEventsCollection = 17,
    TimeCorrection = 18,
    PowerLoadProfile = 19,
    NetworkQualityProfile = 20,
    TelemetryProfileReading = 21,
    ConsumptionRestriction = 22,
    ResumptionOfConsumption = 23,
    MeterPassportData = 24,
    ProgrammableParametersProfile = 25,
    ExternalInfluencesCounters = 26,
    CrqEventsCollection = 27,
    MeterUpdateTechnicalConfig = 28,
    ChangePassword = 29,
    ValidatePassword = 30,
}

export const MeterActionTypeExchangeToMeterActionType: Record<MeterActionTypeExchange, MeterActionType> = {
    [MeterActionTypeExchange.MonthlyProfileReading]: MeterActionType.MonthlyProfileReading,
    [MeterActionTypeExchange.DailyProfileReading]: MeterActionType.DailyProfileReading,
    [MeterActionTypeExchange.CurrentProfileReading]: MeterActionType.CurrentProfileReading,
    [MeterActionTypeExchange.IncrementalProfileReading]: MeterActionType.IncrementalProfileReading,
    [MeterActionTypeExchange.TamperingEventsCollection]: MeterActionType.TamperingEventsCollection,
    [MeterActionTypeExchange.PowerSwitchEventsCollection]: MeterActionType.PowerSwitchEventsCollection,
    [MeterActionTypeExchange.AccessControlEventsCollection]: MeterActionType.AccessControlEventsCollection,
    [MeterActionTypeExchange.DataCorrectionEventsCollection]: MeterActionType.DataCorrectionEventsCollection,
    [MeterActionTypeExchange.ConfigurationSnapshot]: MeterActionType.ConfigurationSnapshot,
    [MeterActionTypeExchange.PowerSwitchControl]: MeterActionType.PowerSwitchControl,
    [MeterActionTypeExchange.InstantParametersProfile]: MeterActionType.InstantParametersProfile,
    [MeterActionTypeExchange.MeterSetup]: MeterActionType.MeterSetup,
    [MeterActionTypeExchange.ValidateOrChangeTariff]: MeterActionType.ValidateOrChangeTariff,
    [MeterActionTypeExchange.VoltageEventsCollection]: MeterActionType.VoltageEventsCollection,
    [MeterActionTypeExchange.CurrentEventsCollection]: MeterActionType.CurrentEventsCollection,
    [MeterActionTypeExchange.SelfDiagnosticEventsCollection]: MeterActionType.SelfDiagnosticEventsCollection,
    [MeterActionTypeExchange.ElectricPowerQualityEventsCollection]: MeterActionType.ElectricPowerQualityEventsCollection,
    [MeterActionTypeExchange.CrqEventsCollection]: MeterActionType.CrqEventsCollection,
    [MeterActionTypeExchange.TimeCorrection]: MeterActionType.TimeCorrection,
    [MeterActionTypeExchange.PowerLoadProfile]: MeterActionType.PowerLoadProfile,
    [MeterActionTypeExchange.NetworkQualityProfile]: MeterActionType.NetworkQualityProfile,
    [MeterActionTypeExchange.MeterUpdateTechnicalConfig]: MeterActionType.MeterUpdateTechnicalConfig,
    [MeterActionTypeExchange.ChangePassword]: MeterActionType.ChangePassword,
    [MeterActionTypeExchange.ValidatePassword]: MeterActionType.ValidatePassword,
    [MeterActionTypeExchange.TelemetryProfileReading]: MeterActionType.TelemetryProfileReading,
    [MeterActionTypeExchange.ConsumptionRestriction]: MeterActionType.ConsumptionRestriction,
    [MeterActionTypeExchange.ResumptionOfConsumption]: MeterActionType.ResumptionOfConsumption,
    [MeterActionTypeExchange.MeterPassportData]: MeterActionType.MeterPassportData,
    [MeterActionTypeExchange.ProgrammableParametersProfile]: MeterActionType.ProgrammableParametersProfile,
    [MeterActionTypeExchange.ExternalInfluencesCounters]: MeterActionType.ExternalInfluencesCounters,
};

export const MeterActionTypeToMeterActionTypeExchange: Record<MeterActionType, MeterActionTypeExchange> = {
    [MeterActionType.MonthlyProfileReading]: MeterActionTypeExchange.MonthlyProfileReading,
    [MeterActionType.DailyProfileReading]: MeterActionTypeExchange.DailyProfileReading,
    [MeterActionType.CurrentProfileReading]: MeterActionTypeExchange.CurrentProfileReading,
    [MeterActionType.IncrementalProfileReading]: MeterActionTypeExchange.IncrementalProfileReading,
    [MeterActionType.TamperingEventsCollection]: MeterActionTypeExchange.TamperingEventsCollection,
    [MeterActionType.PowerSwitchEventsCollection]: MeterActionTypeExchange.PowerSwitchEventsCollection,
    [MeterActionType.AccessControlEventsCollection]: MeterActionTypeExchange.AccessControlEventsCollection,
    [MeterActionType.DataCorrectionEventsCollection]: MeterActionTypeExchange.DataCorrectionEventsCollection,
    [MeterActionType.ConfigurationSnapshot]: MeterActionTypeExchange.ConfigurationSnapshot,
    [MeterActionType.PowerSwitchControl]: MeterActionTypeExchange.PowerSwitchControl,
    [MeterActionType.InstantParametersProfile]: MeterActionTypeExchange.InstantParametersProfile,
    [MeterActionType.MeterSetup]: MeterActionTypeExchange.MeterSetup,
    [MeterActionType.ValidateOrChangeTariff]: MeterActionTypeExchange.ValidateOrChangeTariff,
    [MeterActionType.VoltageEventsCollection]: MeterActionTypeExchange.VoltageEventsCollection,
    [MeterActionType.CurrentEventsCollection]: MeterActionTypeExchange.CurrentEventsCollection,
    [MeterActionType.SelfDiagnosticEventsCollection]: MeterActionTypeExchange.SelfDiagnosticEventsCollection,
    [MeterActionType.ElectricPowerQualityEventsCollection]: MeterActionTypeExchange.ElectricPowerQualityEventsCollection,
    [MeterActionType.CrqEventsCollection]: MeterActionTypeExchange.CrqEventsCollection,
    [MeterActionType.TimeCorrection]: MeterActionTypeExchange.TimeCorrection,
    [MeterActionType.PowerLoadProfile]: MeterActionTypeExchange.PowerLoadProfile,
    [MeterActionType.NetworkQualityProfile]: MeterActionTypeExchange.NetworkQualityProfile,
    [MeterActionType.MeterUpdateTechnicalConfig]: MeterActionTypeExchange.MeterUpdateTechnicalConfig,
    [MeterActionType.ChangePassword]: MeterActionTypeExchange.ChangePassword,
    [MeterActionType.ValidatePassword]: MeterActionTypeExchange.ValidatePassword,
    [MeterActionType.TelemetryProfileReading]: MeterActionTypeExchange.TelemetryProfileReading,
    [MeterActionType.ConsumptionRestriction]: MeterActionTypeExchange.ConsumptionRestriction,
    [MeterActionType.ResumptionOfConsumption]: MeterActionTypeExchange.ResumptionOfConsumption,
    [MeterActionType.MeterPassportData]: MeterActionTypeExchange.MeterPassportData,
    [MeterActionType.ProgrammableParametersProfile]: MeterActionTypeExchange.ProgrammableParametersProfile,
    [MeterActionType.ExternalInfluencesCounters]: MeterActionTypeExchange.ExternalInfluencesCounters,
};

export const MeterActionTypeDictionary: Record<MeterActionType, string> = {
    [MeterActionType.DailyProfileReading]: 'Параметры ежесуточного профиля',
    [MeterActionType.CurrentProfileReading]: 'Параметры текущего профиля',
    [MeterActionType.MonthlyProfileReading]: 'Параметры ежемесячного профиля',
    [MeterActionType.IncrementalProfileReading]: 'Профиль мощности',
    [MeterActionType.InstantParametersProfile]: 'Мгновенные параметры',
    [MeterActionType.MeterSetup]: 'Ввод ПУ в эксплуатацию',
    [MeterActionType.ValidateOrChangeTariff]: 'Задача на установку смену тарифного расписания',
    [MeterActionType.TimeCorrection]: 'Коррекция времени',
    [MeterActionType.PowerLoadProfile]: 'Параметры профиля нагрузки',
    [MeterActionType.NetworkQualityProfile]: 'Параметры качества сети',
    [MeterActionType.AccessControlEventsCollection]: 'Журнал контроля доступа',
    [MeterActionType.DataCorrectionEventsCollection]: 'Журнал перепрограммирования ПУ',
    [MeterActionType.PowerSwitchEventsCollection]: 'Журнал включений/выключений',
    [MeterActionType.TamperingEventsCollection]: 'Журнал внешних воздействий',
    [MeterActionType.CrqEventsCollection]: 'Сбор событий журналов по протоколу CRQ',
    [MeterActionType.ConfigurationSnapshot]: 'Чтение конфигурации',
    [MeterActionType.PowerSwitchControl]: 'Задача на переключение реле (ограничение \\ возобновление потребления)',
    [MeterActionType.VoltageEventsCollection]: 'Журнал напряжений',
    [MeterActionType.CurrentEventsCollection]: 'Журнал токов',
    [MeterActionType.SelfDiagnosticEventsCollection]: 'Журнал самодиагностики',
    [MeterActionType.ElectricPowerQualityEventsCollection]: 'Журнал ПКЭ',
    [MeterActionType.TelemetryProfileReading]: 'Параметры профиля телеизмерений',
    [MeterActionType.ConsumptionRestriction]: 'Ограничение потребления',
    [MeterActionType.ResumptionOfConsumption]: 'Возобновление потребления',
    [MeterActionType.MeterPassportData]: 'Паспортные данные',
    [MeterActionType.ProgrammableParametersProfile]: 'Программируемые параметры',
    [MeterActionType.ExternalInfluencesCounters]: 'Счётчики внешних воздействий',
    [MeterActionType.MeterUpdateTechnicalConfig]: 'Изменение технических параметров',
    [MeterActionType.ChangePassword]: 'Изменение паролей',
    [MeterActionType.ValidatePassword]: 'Валидация паролей',
};

export const OrderTypeByMeterActionType: Record<MeterActionType, OrderType> = {
    [MeterActionType.DailyProfileReading]: OrderType.RegularReading,
    [MeterActionType.CurrentProfileReading]: OrderType.RegularReading,
    [MeterActionType.MonthlyProfileReading]: OrderType.RegularReading,
    [MeterActionType.IncrementalProfileReading]: OrderType.RegularReading,
    [MeterActionType.InstantParametersProfile]: OrderType.InstantReading,
    [MeterActionType.MeterSetup]: OrderType.Activation,
    [MeterActionType.ValidateOrChangeTariff]: OrderType.RegularReading,
    [MeterActionType.TimeCorrection]: OrderType.RegularReading,
    [MeterActionType.PowerLoadProfile]: OrderType.RegularReading,
    [MeterActionType.NetworkQualityProfile]: OrderType.RegularReading,
    [MeterActionType.AccessControlEventsCollection]: OrderType.RegularReading,
    [MeterActionType.DataCorrectionEventsCollection]: OrderType.RegularReading,
    [MeterActionType.PowerSwitchEventsCollection]: OrderType.RegularReading,
    [MeterActionType.TamperingEventsCollection]: OrderType.RegularReading,
    [MeterActionType.VoltageEventsCollection]: OrderType.RegularReading,
    [MeterActionType.CurrentEventsCollection]: OrderType.RegularReading,
    [MeterActionType.SelfDiagnosticEventsCollection]: OrderType.RegularReading,
    [MeterActionType.ElectricPowerQualityEventsCollection]: OrderType.RegularReading,
    [MeterActionType.CrqEventsCollection]: OrderType.RegularReading,
    [MeterActionType.ConfigurationSnapshot]: OrderType.RegularReading,
    [MeterActionType.PowerSwitchControl]: OrderType.PowerSwitch,
    [MeterActionType.MeterUpdateTechnicalConfig]: OrderType.PowerSwitch,
    [MeterActionType.ChangePassword]: OrderType.MeterUpdateTechnicalConfig,
    [MeterActionType.ValidatePassword]: OrderType.RegularReading,
    [MeterActionType.TelemetryProfileReading]: OrderType.RegularReading,
    [MeterActionType.ConsumptionRestriction]: OrderType.PowerSwitch,
    [MeterActionType.ResumptionOfConsumption]: OrderType.PowerSwitch,
    [MeterActionType.MeterPassportData]: OrderType.RegularReading,
    [MeterActionType.ProgrammableParametersProfile]: OrderType.Activation,
    [MeterActionType.ExternalInfluencesCounters]: OrderType.RegularReading,
};
