import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from 'app/shared/components/typography';
import { MeterPowerSwitchOrderStatus } from 'common/model/meter/meterPowerSwitch/meterPowerSwitchOrderStatus';
import React from 'react';
import { MeterPowerSwitchOrderShortViewModel } from '../../../../common/model/meter/meterPowerSwitchOrder/MeterPowerSwitchOrderCommonModel';
import { Button } from '../../../shared/components/button';
import { DrawerPanel } from '../../../shared/components/drawerPanel';
import { Table } from '../../../shared/components/table';
import { Wrapper } from '../../../shared/components/wrapper';
import { setColumnWidth } from '../../../shared/pipes/styles';
import { IStateProps } from './model';
import { PowerSwitchOrdersFilterForm } from './powerSwitchOrdersFilterForm';
import { Pagination } from '../../../shared/components/pagination';
import { initialFilter } from './initialFilter';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
    },
    tableCols: {
        '&.MuiTableCell-paddingNone': {
            ...setColumnWidth('40px'),
        },
        '&.col-cumulativeActiveEnergyImp': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('80px'),
        },
        '&.col-relay': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('116px'),
        },
    },
    actions: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    filterWrapper: {
        flex: 1,
    },
    actionsLeft: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
        flex: 1,
    },
    actionsRight: {
        marginLeft: 'auto',
    },
    hidden: {
        display: 'none'
    },
    tableWrapper: {
        '& .custom-table': {
            '& .col-detailsView button': {
                visibility: 'hidden',
            },
            '& .MuiTableRow-root': {
                '&:hover': {
                    '& button': {
                        visibility: 'visible',
                    },
                }
            },
            '& .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters': {
                display: 'none',
            },
        },
        '& .MuiCheckbox-root.Mui-checked': {
            color: theme.palette.neutral.light,
        },
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const {
        appliedFiltersCount,
        data,
        form,
        columns,
        onSubmit,
        isLoading,
        onApprove,
        meterAutocomplete,
        regionSuggestions,
        onSelectionChange,
        selectedCount,
        showSelectAllCheckbox,
        pagination,
        setPage,
        dataTotalCount,
        meterPowerSwitchCounters,
        powerSwitchMetersCountPerDay,
    } = props;
    const classes = useStyles();
    const [isFilterVisible, setIsFilterVisible] = React.useState(false);

    const drawer = <DrawerPanel
        open={isFilterVisible}
        handleDrawerClose={() => setIsFilterVisible(false)}
        title='Фильтрация'
        anchor='left'
    >
        <PowerSwitchOrdersFilterForm
            form={form}
            initialFilterValues={initialFilter}
            meterAutocomplete={meterAutocomplete}
            regionSuggestions={regionSuggestions}
            onSubmit={onSubmit}
        />
    </DrawerPanel>;

    const tableActions = (
        <Grid container direction='row' alignItems='center'>
            <Grid item xs={3}>
                {
                    !(isFilterVisible || selectedCount) && (
                        <Button
                            variant='outline'
                            icon={appliedFiltersCount > 0 ? 'filterFilled' : 'filter'}
                            onClick={() => setIsFilterVisible(true)}
                        >
                             Фильтрация{appliedFiltersCount > 0 ? ` (${appliedFiltersCount})` : ''}
                        </Button>
                    )
                }
                {
                    !!selectedCount && (<Typography variant='bodyBold'>Выбрано заявок: { selectedCount }</Typography>)
                }
            </Grid>
            <Grid item container xs={9} justify='flex-end' spacing={1} alignItems='center' wrap='nowrap'>
                {meterPowerSwitchCounters && (
                    <Grid item container justify='flex-end' spacing={1} alignItems='center'>
                        <Grid item>
                            <Typography variant='bodyBold'>
                                Выполнено успешно: { meterPowerSwitchCounters.dailyCompleteCount }
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='bodyBold'>
                            В работе: { meterPowerSwitchCounters.readyToWorkCount }
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='bodyBold'>
                            Можно переключить: {
                                    +powerSwitchMetersCountPerDay -
                            (+meterPowerSwitchCounters.readyToWorkCount + meterPowerSwitchCounters.dailyCompleteCount)
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid item>
                    <Button
                        className='ml-3'
                        type='button'
                        variant='primary'
                        icon='check'
                        onClick={onApprove}
                        disabled={!onApprove || isLoading}
                    >
                    Подтвердить
                    </Button>
                </Grid>
                {/* Адаптировать к постраничному выводу */}
                {/* <Grid item>
                    <Button
                        className='ml-3'
                        type='button'
                        variant='outline'
                        icon='excel'
                        onClick={onExcelExport}
                        disabled={isExcelExportDisabled}
                    >
                    Выгрузить в Excel
                    </Button>
                </Grid> */}
            </Grid>
        </Grid>
    );

    return (
        <Wrapper drawer={drawer} actions={tableActions} className={classes.tableWrapper} isBigHeader>
            {data && (
                <>
                    <Table
                        title={''}
                        columns={columns}
                        data={data}
                        options={{
                            showSelectAllCheckbox,
                            paging: 'disabled',
                            headerClassName: classes.tableCols,
                            grouping: true,
                            columnsButton: false,
                            filtering: false,
                            search: false,
                            sorting: true,
                            toolbar: false,
                            showTitle: false,
                            defaultExpanded: false,
                            selection: true,
                            selectionProps: (item: MeterPowerSwitchOrderShortViewModel) => ({
                                className: 'default-checkbox',
                                disabled: item.status !== MeterPowerSwitchOrderStatus.ApprovalRequired,
                            }),
                        }}
                        onSelectionChange={onSelectionChange}
                    />
                    <Pagination
                        {...pagination}
                        count={dataTotalCount}
                        onChangePage={setPage}
                        isLoading={isLoading}
                    />
                </>
            )}
        </Wrapper>
    );
};
