import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MeterReadingDailyProfileViewModel } from '../../../../../common/model/meter/meter/meterReadingDailyProfileViewModel';
import { ISort } from '../../../../../common/sort';
import { usePagination } from '../../../../shared/components/pagination';
import { IStateProps } from './model';
import { BaseDispatch } from '../../../../../redux/actions';
import { findMeterReadingDailyProfilesList } from '../../../../../redux/actions/meterReading';
import {
    meterReadingDailyProfileListSelector,
    meterReadingDailyProfileListTotalSelector
} from '../../../../../redux/selectors/meterReading';
import { View } from './view';
import moment from 'moment';
import { DateFormats } from '../../../../../common/constants/date';

export const MeterReadingDailyList = () => {

    const dispatch = useDispatch<BaseDispatch>();
    const params = useParams<{meterId?: string}>();
    const meterId = params.meterId;

    const data = useSelector(meterReadingDailyProfileListSelector);
    const dataTotal = useSelector(meterReadingDailyProfileListTotalSelector);

    const fetch = async (sort: ISort) => {
        await dispatch(findMeterReadingDailyProfilesList(meterId, sort));
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 13,
        orderBy: 'readingDate',
        orderDirection: 'desc',
    }, fetch);

    useEffect(() => {
        onFetch(pagination);
    }, []);

    const props: IStateProps = {
        data,
        dataTotal,
        pagination,
        setOrder,
        setPage,
        orderParams,
        columnDefinition: [
            {
                title: 'Дата показаний',
                field: 'readingDate',
                cellClassName: 'col-readingDate',
                sorting: true,
                render: (dataItem: MeterReadingDailyProfileViewModel) => {
                    return (
                        <div>
                            {moment(dataItem.readingDate).format(DateFormats.dateFormat)}
                            <br />
                            {moment(dataItem.readingDate).format(DateFormats.timeFormat)}
                        </div>
                    );
                },
            },
            {
                title: 'Активная энергия +',
                field: 'cumulativeActiveEnergyImp',
                cellClassName: 'col-cumulativeActiveEnergyExp',
                sorting: false,
            },
            {
                title: 'Активная энергия -',
                field: 'cumulativeActiveEnergyExp',
                cellClassName: 'col-cumulativeActiveEnergyImp',
                sorting: false,
            },
            {
                title: 'Активная энергия + (1 тариф)',
                field: 'cumulativeActiveEnergyTZ1',
                cellClassName: 'col-cumulativeActiveEnergyTZ1',
                sorting: false,
            },
            {
                title: 'Активная энергия + (2 тариф)',
                field: 'cumulativeActiveEnergyTZ2',
                cellClassName: 'col-cumulativeActiveEnergyTZ2',
                sorting: false,
            },
            {
                title: 'Активная энергия + (3 тариф)',
                field: 'cumulativeActiveEnergyTZ3',
                cellClassName: 'col-cumulativeActiveEnergyTZ3',
                sorting: false,
            },
            {
                title: 'Реактивная энергия +',
                field: 'cumulativeReactiveEnergyImp',
                cellClassName: 'col-cumulativeReactiveEnergyImp',
                sorting: false,
            },
            {
                title: 'Реактивная энергия -',
                field: 'cumulativeReactiveEnergyExp',
                cellClassName: 'col-cumulativeReactiveEnergyExp',
                sorting: false,
            },
        ]
    };

    return View(props);
};
