import { RelatedSystemsTabs, Routes } from '../../shared/constants';
import { useTabs } from '../../shared/hooks/useTabs';
import { MeterRelatedSystemsTab } from './model';
import { View } from './view';

export const RelatedSystems = () => {
    const {
        activeTab,
        setTab
    } = useTabs<RelatedSystemsTabs>({
        values: RelatedSystemsTabs,
        defaultTab: RelatedSystemsTabs.MeterActivationOrders,
        route: Routes.relatedSystems,
    });
    const tabs: MeterRelatedSystemsTab[] = [
        {
            key: RelatedSystemsTabs.MeterActivationOrders,
            title: 'ЗАЯВКИ НА ВВОД ПУ'
        },
        {
            key: RelatedSystemsTabs.ImportMeterActivationOrders,
            title: 'ИМПОРТ ПУ'
        },
        {
            key: RelatedSystemsTabs.PowerSwitchOrders,
            title: 'ЗАЯВКИ НА ОГРАНИЧЕНИЕ'
        },
        {
            key: RelatedSystemsTabs.Import8002080040,
            title: 'ИМПОРТ 80020/80040'
        },
        {
            key: RelatedSystemsTabs.Export8002080040,
            title: 'ЭКСПОРТ 80020/80040'
        },
        {
            key: RelatedSystemsTabs.Partners,
            title: 'Партнеры'
        },
        {
            key: RelatedSystemsTabs.GisTasks,
            title: 'Инциденты'
        },
        {
            key: RelatedSystemsTabs.NetworkOperators,
            title: 'ЦЕНТР2М'
        }
    ];

    return View({
        activeTab,
        setTab,
        tabs,
    });
};
