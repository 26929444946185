import moment, { Moment } from 'moment';
import { DateFormats } from '../../../common/constants/date';

export const daysInWeek = 7;
const defaultTimeZoneMinutes = 300;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const msInSecond = 1000;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const msInMinute = 1000 * 60;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const msInHour = 1000 * 60 * 60;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const msInDay = 1000 * 60 * 60 * 24;

export const addMsToDate = (dateSrc: Date | string, ms: number): Date => {
    const date = new Date(dateSrc);
    const dateClone = new Date(date);
    dateClone.setTime(date.getTime() + ms);
    return dateClone;
};

export const getCurrentDate = () => {
    return new Date();
};

export const getDateMonthBefore = (): Date => {
    const now = getCurrentDate();
    now.setMonth(now.getMonth() - 1);
    return now;
};

export const getDateDaysBefore = (days: number): Date => {
    const now = getCurrentDate();
    now.setDate(now.getDate() - days);
    return now;
};

export const formatDate = (date: Date, format = DateFormats.dateTimeFormat): string => {
    return moment(date).format(format);
};

export const transformLocalTimeMeter = (date: Date, timeZoneMinutes?: number): Moment => {
    return moment.utc(date).add(timeZoneMinutes || defaultTimeZoneMinutes, 'minutes');
};

export const formatDateWithoutTime = (date: Date, format = DateFormats.dateFormat): string => {
    return moment(date).format(format);
};

export const isValidDate = (d: any): boolean => {
    return moment(d).isValid();
};

export const transformEndDay = (date: Date): Date => {
    return moment(date).endOf('day').toDate();
};

export const transformStartDay = (date: Date): Date => {
    return moment(date).startOf('day').toDate();
};

export const parseDate = (date: string, format: string): Date => {
    return moment(date, format).toDate();
};

export const transformDateToTimeZone = (date: Date, timeZoneMinutes: number): moment.Moment => {
    return moment(date)
        .utc()
        .utcOffset(timeZoneMinutes);
};

export const transformMomentToTimeZone = (moment: Moment, timeZoneMinutes: number): moment.Moment => {
    return moment.utc().utcOffset(timeZoneMinutes);
};

export const changeOffset = (source: string | Date, timeZone: number): Date => {
    return moment(source).utc().utcOffset(timeZone, true).toDate();
};
