import { InspectionLogEntryViewModel } from 'common/model/inspectionLog/inspectionLogEntryViewModel/inspectionLogEntryViewModel';
import React, { createContext } from 'react';
import { connect, Socket } from 'socket.io-client';

const wsUrl = process.env.REACT_APP_WS_BACKEND_URL || '/';
const WebSocketContext = createContext(null);

export { WebSocketContext };

export type WSMessage = {
    type: 'logs',
    data: InspectionLogEntryViewModel;
}

export default ({ children }: any) => {
    let socket: Socket<any, any> = undefined;
    let ws = undefined;

    const sendMessage = (data: any, type: string) => {
        socket.emit(type, JSON.stringify(data));
    };

    const subscribeOnMessage = (callback: (msg: WSMessage) => void) => {
        socket.removeListener('message');
        socket.on('message', callback);
    };

    if (!socket) {
        // eslint-disable-next-line no-console
        console.log(`Will connect to socket on ${wsUrl}`);
        socket = connect(
            wsUrl,
            {
                reconnection: true,
                reconnectionDelay: 10000,
                reconnectionDelayMax: 10000,
                secure: process.env.REACT_APP_WS_SECURE === 'true'
            });

        socket.on('connect', () => {
            // eslint-disable-next-line no-console
            console.log(` connected ${socket.id}`);
        });
        // eslint-disable-next-line no-console
        socket.on('connect_error', (a: any) => console.log(a, 'error'));


        ws = {
            socket,
            sendMessage,
            subscribeOnMessage
        };
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    );
};
