import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Box, Link, makeStyles } from '@material-ui/core';

import './style.scss';

import { IStateProps } from './model';
import { Typography } from '../typography';

const useStyles = makeStyles({
    link: {
        '&:hover': {
            textDecoration: 'none',
        }
    }
});

export const View = (props: IStateProps) => {
    const { user, logoutRoute, anchorEl, handleClick, handleClose, loginRoute } = props;
    const classes = useStyles();

    return (
        <div className='profile'>
            {user
                ? <>
                    <Box display='flex' alignItems='center'>
                        <Typography variant='bodyBold'>{user.name} {user.lastName}</Typography>
                        <IconButton
                            edge='end'
                            aria-owns={anchorEl ? 'profile-menu' : undefined}
                            aria-haspopup='true'
                            onClick={handleClick}
                            className='text-light'
                            title={user.name}
                        >
                            <AccountCircle color='primary' />
                        </IconButton>
                    </Box>
                    <Menu id='profile-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                        <MenuItem>
                            <Link color='textPrimary' href={logoutRoute} className={classes.link}>
                                Выйти
                            </Link>
                        </MenuItem>
                    </Menu>
                </>
                :
                <div>
                    <Link color='textPrimary' href={loginRoute}>
                        <Typography variant='h4'>Войти</Typography>
                    </Link>
                </div>}
        </div>
    );
};
