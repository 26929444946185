import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { MeterReplacingCommunicatorUpdater } from '../../../../../common/model/meter/meter/meterReplacingCommunicatorUpdater';
import { MeterStatus } from '../../../../../common/model/meter/meterStatus';
import { PasswordTypes } from '../../../../../common/model/meter/meterPassword/passwordTypes';
import { BaseDispatch } from '../../../../../redux/actions';
import { updateMeterReplacingCommunicator } from '../../../../../redux/actions/meterUpdateAttributes';
import { getPassword } from '../../../../../redux/actions/password';
import { meterSelector } from '../../../../../redux/selectors/meters';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import yup from '../../../../shared/utils/yup';
import { actionQueryParam } from '../model';
import { MeterCommunicatorUpdateFormValues, StateProps } from './model';
import { View } from './view';


const replacingCommunicatorValidationScheme = yup.object({
    communicatorModel: yup.string(),
    communicatorSerialNum: yup.string(),
    communicatorPassword1: yup.string(),
    communicatorPassword2: yup.string(),
});

export const ReplacingCommunicatorUpdateForm = () => {

    const { meterId } = useParams<{ meterId: string }>();
    const queryParams = useQueryParams();
    const meter = useSelector(meterSelector);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch<BaseDispatch>();

    const { hasAccess } = useAccessRules();

    const defaultValues: MeterCommunicatorUpdateFormValues = {
        communicatorModel: meter.communicatorModel ?? '',
        communicatorSerialNum: meter.communicatorSerialNum ?? '',
        communicatorPassword1: '',
        communicatorPassword2: '',
        changeStatusToActive: meter.status === MeterStatus.Defective,
    };

    const onCloseModal = () => {
        queryParams.delete(actionQueryParam);
        dispatch(push({
            search: queryParams.toString()
        }));
    };

    const onSubmit: SubmitHandler<MeterCommunicatorUpdateFormValues> = async (values) => {
        await dispatch(updateMeterReplacingCommunicator(meterId, values, values.changeStatusToActive));
        onCloseModal();
    };

    const resolver = useYupValidationResolver<MeterReplacingCommunicatorUpdater>(replacingCommunicatorValidationScheme);

    const form = useForm<MeterCommunicatorUpdateFormValues>({
        defaultValues,
        resolver,
    });

    const fetchPasswords = async () => {
        setIsLoading(true);
        const communicatorPassword1 = await getPassword(PasswordTypes.CommunicatorPassword1, meter.id);
        const communicatorPassword2 = await getPassword(PasswordTypes.CommunicatorPassword2, meter.id);
        form.reset({
            communicatorModel: meter.communicatorModel ?? '',
            communicatorSerialNum: meter.communicatorSerialNum ?? '',
            communicatorPassword1,
            communicatorPassword2,
            changeStatusToActive: meter.status === MeterStatus.Defective,
        });
        setIsLoading(false);
    };

    useEffect(() => {
        fetchPasswords();
    }, [meter.id]);

    const props: StateProps = {
        form,
        onCancel: onCloseModal,
        onSubmit,
        isLoading,
        hasAccess: hasAccess(AccessRule.CanUpdateMeterReplacingCommunicator),
        isDefected: meter.status === MeterStatus.Defective,
    };

    return View(props);
};
