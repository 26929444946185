import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Heading } from '../../../../shared/components/heading';
import { MeterActionSchemeForm } from '../form';
import { SchemeCreateViewProps } from './model';
import { Wrapper } from '../../../../shared/components/wrapper';

const useStyles = makeStyles(() => ({
    newSchemaWrapper: {
        paddingTop: '14px',
    },
}));

export const View = (props: SchemeCreateViewProps) => {
    const { breadcrumbs, onClickBreadcrumb, initialValues, onCancel, onSubmit } = props;
    const classes = useStyles();
    return (
        <div>
            <Box mb={1}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2}>
                <Heading heading='Новая схема опроса' onBackHandler={onCancel} />
            </Box>
            <Wrapper className={classes.newSchemaWrapper}>
                <MeterActionSchemeForm initialValues={initialValues} onCancel={onCancel} onSubmit={onSubmit} />
            </Wrapper>
        </div>
    );
};
