import { Box, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import moment from 'moment';
import classNames from 'classnames';
import React, { useRef, Ref, useImperativeHandle } from 'react';
import DatePicker from 'react-datepicker';
import { createPortal } from 'react-dom';
import { DateFormats } from '../../../../common/constants/date';
import { getCurrentDate } from '../../utils/dates';
import { Button } from '../button';
import { CustomDatePickerHeader } from './header';
import { TimeInput } from './timeInput';
import { DatePickerProps } from './model';
import ru from 'date-fns/locale/ru';
import { TextInput } from '../textInput';
import InputMask from 'react-input-mask';

import Close from './closeOrange.svg';

import 'react-datepicker/dist/react-datepicker.css';
import { Icon } from '../icon';

const dateRangeMiddleOpacity = 0.1;


const useStyles = makeStyles((theme) => ({
    datePickerWrapper: ({ selectsRange, fullWidth }: { selectsRange?: boolean; fullWidth?: boolean }) => ({
        width: fullWidth ? '100%' : 'auto',
        minWidth: selectsRange ? '235px' : '150px',
        '& .MuiFormControl-root': {
            width: '100%',
        },
        '& .react-datepicker__close-icon': {
            right: '42px',
            top: '10px',
            backgroundImage: `url(${Close})`,
            backgroundSize: '85%',
            width: '20px',
            height: '20px',
            backgroundPosition: '50%'
        },
        '& .react-datepicker__close-icon::after': {
            display: 'none',
        }
    }),
    datePickerFullWidth: {
        width: '100%',
    },
    calendar: ({ selectsRange }: { selectsRange?: boolean; }) => ({
        zIndex: 10000,
        '& .react-datepicker': {
            maxHeight: '400px',
            border: 'none',
            backgroundColor: theme.palette.neutral.white,
            fontFamily: 'SF Pro Display',
            fontSize: '14px',
            lineHeight: '20px',
            borderRadius: '4px',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 2px 24px rgba(0, 0, 0, 0.12)',
            flexDirection: selectsRange ? 'row' : 'column',
            overflow: selectsRange ? 'visible' : 'hidden',
            '&__input-time-container': {
                margin: 0,
                padding: '24px 16px 16px 0',
                width: 'auto',
                gridArea: 'time',
                overflow: 'hidden',
                borderBottom: `1px solid ${theme.palette.neutral.background}`,
                '& .react-datepicker-time__input-container .react-datepicker-time__input': {
                    margin: 0,
                },
            },
            '&-time__input': {
                margin: 0,
            },
            '&-time__caption': {
                display: 'none'
            },
            '&__header': {
                backgroundColor: theme.palette.neutral.white,
                border: 'none',
                borderRadius: 0,
                padding: 0,
            },
            '&__month-container': {
                padding: '24px 16px 16px 16px',
                borderRight: `2px solid ${theme.palette.neutral.background}`,
                borderBottom: `1px solid ${theme.palette.neutral.background}`,
            },
            '&__month': {
                margin: 0,
            },
            '&__day-names': {
                marginBottom: 0,
            },
            '&__day-name': {
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.palette.neutral.light,
                width: '36px',
                height: '36px',
                margin: 0,
                padding: '0 2px',
            },
            '&__day': {
                width: '36px',
                height: '36px',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.palette.text.primary,
                padding: '2px',
                margin: 0,
                position: 'relative',
                '&:hover': {
                    backgroundColor: theme.palette.neutral.background,
                    borderRadius: '100px',
                },
                '&--today': {
                    border: `1px solid ${theme.palette.neutral.lightGray}`,
                    borderRadius: '100px',
                    fontWeight: theme.typography.fontWeightRegular,
                },
                '&--outside-month': {
                    visibility: 'hidden',
                },
                '&--in-selecting-range': {
                    borderRadius: 0,
                    backgroundColor: fade(theme.palette.primary.light, dateRangeMiddleOpacity),
                    '&:hover': {
                        borderRadius: '100px',
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.neutral.white,
                    },
                },
                '&--in-range': {
                    borderRadius: 0,
                    backgroundColor: fade(theme.palette.primary.light, dateRangeMiddleOpacity),
                    '&:hover': {
                        backgroundColor: fade(theme.palette.primary.light, dateRangeMiddleOpacity),
                    },
                },
            },
        },
        // for increasing specificity
        '& .react-datepicker .react-datepicker__day': {
            '&--keyboard-selected': {
                borderRadius: '100px',
                backgroundColor: theme.palette.neutral.white,
                color: theme.palette.text.secondary,
            },
            '&--in-range': {
                borderRadius: 0,
                backgroundColor: fade(theme.palette.primary.light, dateRangeMiddleOpacity),
            },
            '&--selected, &--range-end, &--range-start': {
                borderRadius: '100px',
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.neutral.white,
                '&:hover': {
                    backgroundColor: theme.palette.primary.light,
                }
            },
            '&--range-end::before, &--selecting-range-end::before, &--range-start::after, &--selecting-range-start::after': {
                content: '\'\'',
                display: 'block',
                position: 'absolute',
                top: 0,
                width: '10px',
                height: '100%',
                backgroundColor: fade(theme.palette.primary.light, dateRangeMiddleOpacity),
            },
            '&--range-end::before, &--selecting-range-end::before': {
                left: 0,
            },
            '&--range-start::after, &--selecting-range-start::after': {
                right: 0,
            },
            '&--in-selecting-range:not(.react-datepicker__day--selecting-range-start):hover::before': {
                content: '\'\'',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '10px',
                height: '100%',
                backgroundColor: fade(theme.palette.primary.light, dateRangeMiddleOpacity),
            }
        }
    }),
    futureDay: {
        '&.react-datepicker__day': {
            color: theme.palette.neutral.light,
            '&--keyboard-selected, &--selected': {
                color: theme.palette.neutral.white,
            }
        },
    },
    calendarWithTime: {
        display: 'grid',
        gridTemplateColumns: '284px 116px',
        gridTemplateRows: '328px 72px',
        gridTemplateAreas: `
                "calendar time"
                "footer footer"
            `,
    },
    calendarWithoutTime: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '320px',
        '& .react-datepicker': {
            '&__month-container': {
                borderRight: 'none',
                borderBottom: `1px solid ${theme.palette.neutral.background}`,
            },
        }
    },
    errorText: {
        color: '#f44336',
        paddingLeft: '12px',
    },
    calendarDisabled: {
        opacity: 0.5,
    },
    footer: {
        position: 'absolute',
        bottom: '-70px',
        width: '100%',
        background: 'rgb(245, 245, 245)',
        border: '1px solid rgb(229, 229, 229)',
        borderRadius: '0 0 4px 4px',
        boxShadow: 'rgb(0 0 0 / 16%) 0px 0px 2px',
    },
    selectsRangeList: {
        width: '150px',
        margin: '2px 0'
    },
    selectsRangeItem: {
        color: theme.palette.text.secondary,
        padding: '10px 12px',
        background: 'rgb(245, 245, 245)',
        '&:hover': {
            background: 'rgb(214, 214, 214)',
            cursor: 'pointer'
        }
    },
    selectsRangeItemActive: {
        backgroundColor: theme.palette.primary.light,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        }
    }
}));

export const View = React.forwardRef(({
    value,
    label,
    onChange,
    showTime,
    fullWidth,
    selectsRange,
    startDate,
    endDate,
    minDate,
    maxDate,
    selectsEnd,
    selectsStart,
    disabled,
    className,
    dark,
    error,
    helperText,
    inputProps,
    required,
}: DatePickerProps, forwardRef: Ref<DatePicker>) => {
    const datePickerRef = useRef<DatePicker>();
    useImperativeHandle(forwardRef, () => datePickerRef.current);

    const onSubmitDate = () => {
        datePickerRef.current.setOpen(false);
    };

    const onCloseWithoutSaving = () => {
        datePickerRef.current.setOpen(false);
    };

    const classes = useStyles({ selectsRange, fullWidth });

    const currentDate = getCurrentDate();

    const getSelected = (selectedValue: Date | [Date, Date]) => {
        const tempValue = Array.isArray(value) ? selectedValue[0] : selectedValue;
        return !!Date.parse(tempValue?.toString()) ? new Date(tempValue) : null;
    };

    const CalendarContainer: React.FC = ({ children }) => children && React.isValidElement(children) ? (
        createPortal(React.cloneElement(children, {
            className: classes.calendar
        }), document.body)
    ) : null;

    const inputMask = () => {
        let mask = selectsRange ? '99.99.9999 - 99.99.9999' : '99.99.9999';

        if (showTime) {
            mask = '99.99.9999 99:99';
        }

        return mask;
    };

    const onDatesChangeToday = () => {
        const startDate = moment().toDate();
        const endDate = moment().toDate();
        onChange([startDate, endDate]);
    };

    const onDatesChangeYesterday = () => {
        const startDate = moment().subtract(1, 'day').toDate();
        const endDate = moment().subtract(1, 'day').toDate();
        onChange([startDate, endDate]);
    };

    const onDatesChangeCurrentWeek = () => {
        const startDate = moment().startOf('isoWeek').toDate();
        const endDate = moment().endOf('isoWeek').toDate();
        onChange([startDate, endDate]);
    };

    const onDatesChangeCurrentMonth = () => {
        const startDate = moment().startOf('month').toDate();
        const endDate = moment().endOf('month').toDate();
        onChange([startDate, endDate]);
    };

    const onDatesChangeCurrentYear = () => {
        const startDate = moment().startOf('year').toDate();
        const endDate = moment().endOf('year').toDate();
        onChange([startDate, endDate]);
    };

    const onDatesChangeCurrentQuarter = () => {
        const startDate = moment().startOf('quarter').toDate();
        const endDate = moment().endOf('quarter').toDate();
        onChange([startDate, endDate]);
    };

    const now = moment();
    const isActiveToday =
      now.clone().isSame(startDate, 'day') && now.clone().isSame(endDate, 'day');

    const isActiveYesterday =
      now.clone().subtract(1, 'day').isSame(startDate, 'day') &&
      now.clone().subtract(1, 'day').isSame(endDate, 'day');

    const isActiveCurrentWeek =
      now.clone().startOf('isoWeek').isSame(startDate, 'day') &&
      now.clone().endOf('isoWeek').isSame(endDate, 'day');

    const isActiveCurrentMonth =
      now.clone().startOf('month').isSame(startDate, 'day') &&
      now.clone().endOf('month').isSame(endDate, 'day');

    const isActiveCurrentQuarter =
      now.clone().startOf('quarter').isSame(startDate, 'day') &&
      now.clone().endOf('quarter').isSame(endDate, 'day');

    const isActiveCurrentYear =
      now.clone().startOf('year').isSame(startDate, 'day') &&
      now.clone().endOf('year').isSame(endDate, 'day');

    return (
        <DatePicker
            required={required}
            onChange={onChange}
            startDate={new Date(startDate)}
            endDate={new Date(endDate)}
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
            selected={getSelected(value)}
            showTimeInput={showTime}
            locale={ru}
            dateFormat={showTime ? DateFormats.dateTimeInputFormat : DateFormats.dateInputFormat}
            timeInputLabel={null}
            shouldCloseOnSelect={false}
            showPopperArrow={false}
            ref={datePickerRef}
            selectsRange={selectsRange}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            disabled={disabled}
            popperContainer={selectsRange ? null : CalendarContainer}
            customInput={
                <InputMask mask={inputMask()}>
                    {(maskProps: any) => (<TextInput
                        {...maskProps}
                        label={label}
                        fullWidth={fullWidth}
                        dark={dark}
                        inputProps={inputProps}
                        error={error}
                        helperText={helperText}
                        disabled={disabled}
                        icon={<Icon name='calendar' className={classNames({ [classes.calendarDisabled]: disabled })} />}
                    />)}
                </InputMask>
            }
            customTimeInput={<TimeInput minDateTime={new Date(minDate)} maxDateTime={new Date(maxDate)} />}
            renderCustomHeader={(headerProps) => <CustomDatePickerHeader {...headerProps} />}
            wrapperClassName={classNames(classes.datePickerWrapper, className, { [classes.datePickerFullWidth]: fullWidth })}
            popperClassName={classes.calendar}
            calendarClassName={showTime ? classes.calendarWithTime : classes.calendarWithoutTime}
            dayClassName={(date) => date > currentDate ? classes.futureDay : null }
            isClearable
        >
            <Box display='flex' alignItems='center' p={2} className={classNames({ [classes.footer]: selectsRange })} >
                <Box mr={1}>
                    <Button variant='primary' onClick={onSubmitDate}>Применить</Button>
                </Box>
                <Button variant='secondary' onClick={onCloseWithoutSaving}>Отменить</Button>
            </Box>

            {selectsRange &&
            <Box className={classes.selectsRangeList}>
                <Box
                    className={classNames(classes.selectsRangeItem, { [classes.selectsRangeItemActive]: isActiveToday })}
                    onClick={onDatesChangeToday}
                >
                    Сегодня
                </Box>
                <Box
                    className={classNames(classes.selectsRangeItem, { [classes.selectsRangeItemActive]: isActiveYesterday })}
                    onClick={onDatesChangeYesterday}
                >
                    Вчера
                </Box>
                <Box
                    className={classNames(classes.selectsRangeItem, { [classes.selectsRangeItemActive]: isActiveCurrentWeek })}
                    onClick={onDatesChangeCurrentWeek}
                >
                    Текущая неделя
                </Box>
                <Box
                    className={classNames(classes.selectsRangeItem, { [classes.selectsRangeItemActive]: isActiveCurrentMonth })}
                    onClick={onDatesChangeCurrentMonth}
                >
                    Текущий месяц
                </Box>
                <Box
                    className={classNames(classes.selectsRangeItem, { [classes.selectsRangeItemActive]: isActiveCurrentQuarter })}
                    onClick={onDatesChangeCurrentQuarter}
                >
                    Текущий квартал
                </Box>
                <Box
                    className={classNames(classes.selectsRangeItem, { [classes.selectsRangeItemActive]: isActiveCurrentYear })}
                    onClick={onDatesChangeCurrentYear}
                >
                    Текущий год
                </Box>
            </Box>
            }
        </DatePicker>
    );
});
