import { combineReducers } from 'redux';
import { specialDayBaseListSlice, SpecialDayBaseListState } from './specialDayBaseList';

export interface SpecialDayBaseState {
    list: SpecialDayBaseListState;
}

export const specialDayBase = combineReducers<SpecialDayBaseState>({
    list: specialDayBaseListSlice.reducer,
});
