/**
 * Категория канала определяет тип информации, собираемый каналом
 */
export enum CrqChannelCategory {
    activeEnergyImp = 'A+', // Активная энергия импорт
    activeEnergyExp = 'A-', // Активная энергия экспорт
    reactiveEnergyImp = 'R+', // Реактивная энергия импорт
    reactiveEnergyExp = 'R-', // Реактивная энергия экспорт
    eventLog = 'J', // Журнал событий
    relayStatus = 'E' // Состояние реле
}
