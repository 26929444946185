import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import {
    MeterChangePasswordRequestCreator
} from '../../../common/model/meter/meterChangePasswordRequest/meterChangePasswordRequestCreator';

import {
    MeterChangePasswordRequestListQuery
} from '../../../common/model/meter/meterChangePasswordRequest/meterChangePasswordRequestListQuery';
import {
    MeterChangePasswordRequestListResponse
} from '../../../common/model/meter/meterChangePasswordRequest/meterChangePasswordRequestListResponse';
import {
    MeterChangePasswordRequestViewModel
} from '../../../common/model/meter/meterChangePasswordRequest/meterChangePasswordRequestViewModel';
import { meterChangePasswordRequestsListSlice } from '../../reducers/meterChangePasswordRequests/list';
import { createSnackbar } from '../controls';
import { AppThunk } from '../index';

export const setMeterChangePasswordRequestsAvailable = (
    data: MeterChangePasswordRequestListResponse | null
): AppThunk => (dispatch) => {
    dispatch(meterChangePasswordRequestsListSlice.actions.setAvailable(data));
};

export const getMeterChangePasswordRequests = (query: MeterChangePasswordRequestListQuery): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_change_password_request, {}, { ...query });
    const data = await httpService.get<MeterChangePasswordRequestListResponse>(route);
    dispatch(setMeterChangePasswordRequestsAvailable(data));
};

export const createMeterChangePasswordRequest = (creator: MeterChangePasswordRequestCreator): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_post_meter_change_password_request);
    await httpService.post<MeterChangePasswordRequestViewModel>(route, creator);
    dispatch(createSnackbar({
        type: 'green',
        message: 'Заявка успешно создана'
    }));
};
