import { Container } from '@material-ui/core';
import React from 'react';
import { Wrapper } from '../wrapper';
import { Typography } from '../typography';

export const View = () => {
    return (
        <Container maxWidth='md'>
            <Wrapper title='Доступ невозможен'>
                <Typography variant='body'>Отсутствуют права на доступ к данному разделу</Typography>
            </Wrapper>
        </Container>
    );
};
