import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MeterPowerSwitchOperationType } from '../../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchOperationType';
import { RelayStatus } from '../../../../../common/model/meter/relayStatus';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { modalNotificationsPop, modalNotificationsPush } from '../../../../../redux/actions/controls/modalNotifications';
import { getMeterPowerSwitch, updateMeterPowerSwitch } from '../../../../../redux/actions/meters';
import { ModalNotificationVariant } from '../../../../../redux/reducers/controls/modalNotifications';
import { meterPowerSwitchSelector } from '../../../../../redux/selectors/meters';
import { useInterval } from '../../../../shared/hooks/useInterval';
import { getErrorMessage } from '../../../../shared/utils/error';
import { Props } from './model';
import { View } from './view';
import { getMeterPowerSwitchCounters } from '../../../../../redux/actions/meterPowerSwitchCounters';
import { meterPowerSwitchCountersAvailableSelector } from '../../../../../redux/selectors/meterPowerSwitchCounters';
import { resourcesSettingsSelector } from '../../../../../redux/selectors';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const refreshDelayMs = 5000;

export const MeterPowerSwitchManagement: React.FC<Props> = (props) => {
    const dispatch = useDispatch<BaseDispatch>();
    const { meterId } = props;
    const powerSwitchDetails = useSelector(meterPowerSwitchSelector);
    const isActive = powerSwitchDetails ? powerSwitchDetails?.relayStatus === RelayStatus.On : undefined;
    const meterPowerSwitchCounters = useSelector(meterPowerSwitchCountersAvailableSelector);
    const { powerSwitchMetersCountPerDay } = useSelector(resourcesSettingsSelector());
    const isPowerSwitchLimitsExceed = meterPowerSwitchCounters
        ? meterPowerSwitchCounters.isLimitExceeded && isActive
        : false;

    const isLoading = !meterPowerSwitchCounters;

    const fetch = async () => {
        await dispatch(getMeterPowerSwitch(meterId));
    };

    const fetchCounters = async () => {
        await dispatch(getMeterPowerSwitchCounters());
    };

    const updateOperation = (operationType: MeterPowerSwitchOperationType) => {
        const isTurnOnOperation = operationType === MeterPowerSwitchOperationType.TurnOn;
        dispatch(modalNotificationsPush({
            showHide: true,
            variant: ModalNotificationVariant.Alert,
            title: isTurnOnOperation ? 'Возобновление потребления' : 'Ограничение потребления',
            message: isTurnOnOperation ? 'Вы уверены, что хотите возобновить потребление?' : 'Вы уверены, что хотите ограничить потребление?',
            actions: [
                {
                    children: isTurnOnOperation ? 'Да, возобновить' : 'Да, ограничить',
                    variant: 'primary',
                    onClick: async () => {
                        try {
                            await dispatch(updateMeterPowerSwitch(meterId, { operationType }));
                            await fetch();
                            dispatch(createSnackbar({
                                type: 'green',
                                message: isTurnOnOperation
                                    ? 'Заявка на возобновление потребления создана успешно.'
                                    : 'Заявка на ограничение потребления создана успешно.',
                            }));
                        } catch (e) {
                            dispatch(createSnackbar({
                                type: 'red',
                                message: getErrorMessage(e),
                            }));
                        }
                        await dispatch(modalNotificationsPop());
                    },
                    onEnter: true,
                },
                {
                    children: 'Отмена',
                    variant: 'secondary',
                    onClick: async () => {
                        await dispatch(modalNotificationsPop());
                    },
                    onEscape: true,
                }
            ],
        }));
    };

    useInterval(fetch, refreshDelayMs);
    useInterval(fetchCounters, refreshDelayMs);

    useEffect(() => {
        fetch();
    }, [meterId]);


    return View({
        ...props,
        isLoading,
        powerSwitchDetails,
        isPowerSwitchLimitsExceed,
        powerSwitchMetersCountPerDay,
        onClickButton: () => {
            updateOperation(
                isActive
                    ? MeterPowerSwitchOperationType.TurnOff
                    : MeterPowerSwitchOperationType.TurnOn
            );
        },
    });
};
