import { Column } from '@softmedialab/materialui-table';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import {
    ElectricityTariffConsumptionType,
    MeterConsumptionElectricityTariffParams, parseMeterElectricityParamTypes, transformMeterElectricityParamTypes
} from '../../../../../common/model/meter/consumption/meterConsumptionElectricityTariffParams';
import { BaseDispatch } from '../../../../../redux/actions';
import {
    getMeterConsumptionElectricityTariff,
    meterConsumptionElectricityTariffDownloadExcel
} from '../../../../../redux/actions/meters';
import { meterConsumptionElectricityTariffSelector } from '../../../../../redux/selectors/meters';
import { useSyncQueryParams } from '../../../../shared/hooks/useQueryParams';
import { transformDatesParams } from '../../../../shared/utils/transformers/dates';
import { IModel, MeterConsumptionElectricityTariffParamsForm } from './model';
import { View } from './view';

const dateFrom = moment().add(-1, 'month').toDate();
const dateTo = moment().add(1, 'day').toDate();

const filterDefaultValues: MeterConsumptionElectricityTariffParams = {
    dateFrom,
    dateTo,
    parameters: transformMeterElectricityParamTypes([
        ElectricityTariffConsumptionType.CumulativeActiveEnergyExp,
        ElectricityTariffConsumptionType.CumulativeActiveEnergyImp,
        ElectricityTariffConsumptionType.CumulativeActiveEnergyTZ1,
        ElectricityTariffConsumptionType.CumulativeActiveEnergyTZ2,
        ElectricityTariffConsumptionType.CumulativeActiveEnergyTZ3,
        ElectricityTariffConsumptionType.CumulativeReactiveEnergyExp,
        ElectricityTariffConsumptionType.CumulativeReactiveEnergyImp,
    ]),
};

export const ConsumptionElectricityTariff: React.FC<IModel> = (props) => {
    const { meterId } = props;
    const dispatch = useDispatch<BaseDispatch>();

    const meterConsumptionElectricityTariff = useSelector(meterConsumptionElectricityTariffSelector);

    const [filter, setFilter] = useSyncQueryParams<MeterConsumptionElectricityTariffParams>(filterDefaultValues);

    const form = useForm<MeterConsumptionElectricityTariffParamsForm>({
        defaultValues: {
            ...filter,
            params: parseMeterElectricityParamTypes(filter.parameters),
        }
    });

    const formData = form.watch();

    const fetchData = () => {
        const params: MeterConsumptionElectricityTariffParamsForm = {
            ...filterDefaultValues,
            ...formData,
            parameters: transformMeterElectricityParamTypes(formData.params),
            params: undefined,
        };
        setFilter(transformDatesParams(params));
        dispatch(getMeterConsumptionElectricityTariff(meterId, transformDatesParams(params)));
    };

    useEffect(() => {
        fetchData();
    }, [meterId]);

    const onSubmit: SubmitHandler<MeterConsumptionElectricityTariffParamsForm> = () => {
        fetchData();
    };

    const columns: Column[] = [
        {
            field: 'tariffView',
            title: 'Показатель',
            sorting: true,
            cellClassName: 'col-tariff-view',
        },
        {
            field: 'valueView',
            title: 'Потребление',
            sorting: true,
            cellClassName: 'col-tariff-view',
        },
        {
            field: 'dimension',
            title: 'Ед. измерения',
            sorting: false,
        },
    ];

    const data = meterConsumptionElectricityTariff ?? [];

    const onClickExcelExport = async () => {
        const params: MeterConsumptionElectricityTariffParamsForm = {
            ...filterDefaultValues,
            ...formData,
            parameters: transformMeterElectricityParamTypes(formData.params),
            params: undefined,
        };
        await dispatch(meterConsumptionElectricityTariffDownloadExcel(meterId, transformDatesParams(params)));
    };

    return View({
        ...props,
        isLoading: !meterConsumptionElectricityTariff,
        form,
        columns,
        onSubmit,
        onClickExcelExport,
        data,
    });
};
