import React from 'react';
import { Box, makeStyles, SvgIcon } from '@material-ui/core';

import { ArrowSmallLeftIcon, ArrowSmallRightIcon } from '../../../../../assets/icons';
import { monthsDictionary } from '../../../../../common/shared/utils/date';
import { Typography } from '../../typography';
import { DatePickerHeaderProps } from './model';

const useStyles = makeStyles((theme) => ({
    changeMonthButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: 0,
    },
    changeMonthIcon: {
        fill: theme.palette.text.primary,
    }
}));

// TODO Add month picker
export const CustomDatePickerHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}: DatePickerHeaderProps) => {

    const classes = useStyles();

    const months = monthsDictionary;

    return (
        <Box display='flex' alignItems='center' pb={1}>
            <button type='button' className={classes.changeMonthButton} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <SvgIcon className={classes.changeMonthIcon} component={ArrowSmallLeftIcon} />
            </button>
            <Box flex={1}><Typography variant='h3'>{months[date.getMonth()]}, {date.getFullYear()}</Typography></Box>
            <button type='button' className={classes.changeMonthButton} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <SvgIcon className={classes.changeMonthIcon} component={ArrowSmallRightIcon} />
            </button>
        </Box>
    );
};
