import React from 'react';
import { DateFormats } from '../../../../../../common/constants/date';
import { SettingViewModel } from '../../../../../../common/model/resources/settings/settingViewModel';
import { SettingType } from '../../../../../../common/model/resources/settings/type';
import { formatDate } from '../../../../../shared/utils/dates';

interface Props {
    setting: SettingViewModel;
}

const getVisibleValue = (value: string, type: SettingType): string => {
    switch (type) {
        case SettingType.datetime: {
            if (!value) {
                return '–';
            }
            return formatDate(new Date(value), DateFormats.dateTimeZoneFormat);
        }
        case SettingType.time: {
            if (!value) {
                return '–';
            }
            return value;
        }
        default:
            return value;
    }
};

export const ValueColumn: React.FC<Props> = ({ setting: { value, label, type } }) => {
    const visibleValue = getVisibleValue(value, type);
    return (
        <div>
            {visibleValue}, {label.toLocaleLowerCase()}
        </div>
    );
};
