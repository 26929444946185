import { StorageState } from '../../../reducers';
import { createSelector } from '@reduxjs/toolkit';


const listSelector = (state: StorageState) => state.meterReading.dailyProfiles.list;
export const meterReadingDailyProfileListSelector = createSelector(
    listSelector,
    list => list.available
);

export const meterReadingDailyProfileListTotalSelector = createSelector(
    listSelector,
    list => list.total
);

const chartSelector = (state: StorageState) => state.meterReading.dailyProfiles.chart;
export const meterReadingDailyProfileChartSelector = createSelector(
    chartSelector,
    chart => chart.available
);

export const meterReadingDailyProfileChartFilterSelector = createSelector(
    chartSelector,
    chart => chart.filter
);
