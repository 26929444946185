export const PiHalfDegree = 180;

export const cosToAngleDeg = (cos: number): number => {
    return Math.floor(Math.acos(cos) * PiHalfDegree / Math.PI);
};

export const angleDegToRad = (angleDeg: number): number => {
    return angleDeg * (Math.PI / PiHalfDegree);
};

export const angleRadToDeg = (angleRad: number): number => {
    return Math.floor((angleRad * PiHalfDegree) / Math.PI);
};

export const angleRadToCos = (angleRad: number): number => {
    return Math.cos(angleRad);
};

export const signMultiplier = (number: number): number => {
    return number >= 0 ? 1 : -1;
};

export const roundNumber = (number: number, fraction = 5): number => {
    if (!number) {
        return number;
    }
    return +number.toFixed(fraction);
};

export enum MultiplierType {
    Kilo,
    Milli,
}

export const multiplierNum: Record<MultiplierType, number> = {
    [MultiplierType.Kilo]: 1000,
    [MultiplierType.Milli]: 0.001,
};
