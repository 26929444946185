import React from 'react';
import {
    MeterActivationOrderStatus,
    meterActivationOrderStatusDictionary
} from '../../../../../../common/model/meter/meterActivationOrder/meterActivationOrderStatus';
import { Wrapper } from '../../../../../shared/components/wrapper';
import { Block } from '../../../../../shared/components/wrapper/block';
import { BlockItemStatus } from '../../../../../shared/components/wrapper/block/model';
import { StateProps } from './model';


export const View = ({ meterActivationOrder }: StateProps) => {

    const { meterActivationOrderStatus, meterModel, meterSerialNum } = meterActivationOrder;

    let statusBlockType: BlockItemStatus = null;
    switch (meterActivationOrder.meterActivationOrderStatus) {
        case MeterActivationOrderStatus.Created:
            statusBlockType = 'notStarted';
            break;
        case MeterActivationOrderStatus.DeterminationOfIPAddress:
            statusBlockType = 'inProgress';
            break;
        case MeterActivationOrderStatus.InitialDiagnostic:
            statusBlockType = 'inProgress';
            break;
        case MeterActivationOrderStatus.CompletedSuccessfully:
            statusBlockType = 'success';
            break;
        case MeterActivationOrderStatus.RuntimeError:
            statusBlockType = 'error';
            break;
        default: statusBlockType = 'notStarted';
    }

    return (
        <Wrapper>
            <Block type='horizontal' items={[
                {
                    type: 'text',
                    title: 'Прибор учета',
                    value: (meterModel && meterSerialNum) ? `${meterModel} № ${meterSerialNum}` : '',
                },
                {
                    type: 'icon',
                    title: 'Текущий статус заявки',
                    value: meterActivationOrderStatusDictionary[meterActivationOrderStatus],
                    status: statusBlockType,
                }
            ]} />
        </Wrapper>
    );
};
