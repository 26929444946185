import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    actions: {
        display: 'inline-flex'
    },
    divider: {
        marginTop: 'auto',
        backgroundColor: theme.palette.neutral.light,
        marginLeft: -16,
        marginRight: -16
    },
    actionsWrapper: {
        marginTop: 'auto',
    },
    whiteText: {
        color: theme.palette.neutral.white,
    },
    form: {
        height: '100%',
        maxWidth: 332,
    },
    fieldsWrapper: {
        height: '100%',
    }
}));
