import { StaticMeterGroupContentPayload } from '../../../../../common/model/meter/meter/staticMeterGroupContentPayload';
import { MeterActionPlanViewModel } from '../../../../../common/model/meter/meterActionPlanViewModel';
import { MeterLatestProfile } from '../../../../../common/model/meter/meterLatestProfile';
import { MeterMeterTariffScheduleUpdater } from '../../../../../common/model/meter/meterMeterTariffScheduleUpdater';
import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { formatDate } from '../../../app/shared/utils/dates';
import { downloadExcelData } from '../../../app/shared/utils/download';
import { appUrls } from '../../../common/appUrls';
import { DateFormats } from '../../../common/constants/date';
import { MeterConsumptionElectricityDataView } from '../../../common/model/meter/consumption/meterConsumptionElectricityData';
import { MeterConsumptionElectricityDataExcelResponse } from '../../../common/model/meter/consumption/meterConsumptionElectricityDataExcelResponse';
import { MeterConsumptionElectricityParams } from '../../../common/model/meter/consumption/meterConsumptionElectricityParams';
import { MeterConsumptionElectricityTariffDataView } from '../../../common/model/meter/consumption/meterConsumptionElectricityTariffData';
import { MeterConsumptionElectricityTariffDataExcelResponse } from '../../../common/model/meter/consumption/meterConsumptionElectricityTariffDataExcelResponse';
import { MeterConsumptionElectricityTariffParams } from '../../../common/model/meter/consumption/meterConsumptionElectricityTariffParams';
import { MeterGroupQueryParams } from '../../../common/model/meter/meter-group/queryParams';
import { MeterCreator } from '../../../common/model/meter/meter/meterCreator';
import { MeterUpdater } from '../../../common/model/meter/meter/meterUpdater';
import { MeterUpdateTechnicalParamsOrderViewModel } from '../../../common/model/meter/meter/meterUpdateTechnicalParamsOrderViewModel';
import { MeterViewModel } from '../../../common/model/meter/meter/meterViewModel';
import { MeterConfigurationHistoryListResponse } from '../../../common/model/meter/meterConfigurationHistory/meterConfigurationHistoryListResponse';
import { MeterGis } from '../../../common/model/meter/meterGis';
import { MeterCommunicatorInformation } from '../../../common/model/meter/meterInformation';
import { MeterLabelViewModel } from '../../../common/model/meter/meterLabel/meterLabelViewModel';
import { MeterPowerSwitchDetails } from '../../../common/model/meter/meterPowerSwitch/meterPowerSwitchDetails';
import { MeterPowerSwitchPutPayload } from '../../../common/model/meter/meterPowerSwitch/meterPowerSwitchPutPayload';
import { MeterTariffScheduleInOrderViewModel } from '../../../common/model/meter/meterTariffSchedule/meterTariffScheduleInOrderViewModel';
import { MeterTariffSchedulesResponse } from '../../../common/model/meter/meterTariffSchedule/meterTariffSchedulesResponse';
import { MeterTariffScheduleViewModel } from '../../../common/model/meter/meterTariffSchedule/meterTariffScheduleViewModel';
import { SearchMetersResponse } from '../../../common/model/meter/searchMeters/searchMetersResponse';
import { meterDetailsSlice } from '../../reducers/meters/details';
import { metersListSlice } from '../../reducers/meters/list';
import { meterSchemesListSlice } from '../../reducers/meterSchemes/list';
import { createSnackbar } from '../controls';
import { AppThunk } from '../index';
import { MeterGroupViewModel } from '../../../common/model/meter/meterGroup/meterGroupViewModel';
import { MeterLabelResponse } from '../../../common/model/meter/meterLabel/meterLabelResponse';
import { MeterSearchQuery } from '../../../common/model/meter/meter/meterSearchQuery';
import { FindMetersFilter } from '../../../common/model/meter/meter/findMetersFilter';

export const findMeters = (query?: FindMetersFilter): AppThunk => async (
    dispatch
) => {
    const route = getApiRoute(appUrls.api_get_meters, {}, query);
    const data = await httpService.get<MeterViewModel[]>(route);
    dispatch(metersListSlice.actions.setAvailableMeters(data));
};

export const getMeterGroups = (
    query?: MeterGroupQueryParams
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_groups, {}, query);
    const data = await httpService.get<MeterGroupViewModel[]>(route);
    dispatch(meterDetailsSlice.actions.setMeterGroups(data));
    dispatch(meterDetailsSlice.actions.setNameListMeterGroups(data));
};

export const createMeterGroups = (
    creator: MeterGroupViewModel
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const route = getApiRoute(appUrls.api_create_meter_groups);
    const data = await httpService.post<MeterGroupViewModel>(route, creator);

    dispatch(
        meterDetailsSlice.actions.setMeterGroups([
            ...state.meters.details.meterGroups,
            data,
        ])
    );
    return data;
};

export const editMeterGroups = (
    groupId: number,
    updater: MeterGroupViewModel
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const route = getApiRoute(appUrls.api_put_meter_group, { groupId });
    const data = await httpService.put<MeterGroupViewModel>(route, updater);
    dispatch(
        meterSchemesListSlice.actions.setAvailableSchemes(
            state.meters.details.meterGroups.map((item) => {
                if (item.id === groupId) {
                    return data;
                }
                return item;
            })
        )
    );
};

export const meterActionPlanRestart = (id: string): AppThunk => async (
    dispatch
) => {
    const route = getApiRoute(appUrls.api_post_meters_action_plan_restart, {
        meterId: id,
    });
    await httpService.post<void>(route);
    dispatch(
        createSnackbar({
            type: 'white',
            message: 'Созданы новые задания на опрос',
        })
    );
};

export const repeatMeterTasks = (id: string): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_post_meters_repeat_tasks, {
        meterId: id,
    });
    await httpService.post<void>(route);
    dispatch(
        createSnackbar({
            type: 'white',
            message: 'Показания счётчика очищены и созданы новые задания на опрос',
        })
    );
};

export const searchMeters = (query?: MeterSearchQuery): AppThunk => async (
    dispatch
) => {
    const route = getApiRoute(appUrls.api_search_meters, {}, query);
    const data = await httpService.get<SearchMetersResponse>(route);

    dispatch(metersListSlice.actions.setMetersSearch(data));
};

export const resetSearchMeters = (): AppThunk => (dispatch) => {
    dispatch(metersListSlice.actions.setMetersSearch({ data: [], total: 0 }));
};

export const updateStaticMeterGroupContent = (
    updater: StaticMeterGroupContentPayload
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const route = getApiRoute(appUrls.api_create_static_meter_group_content);
    const data = await httpService.post<MeterGroupViewModel>(route, updater);
    dispatch(
        meterDetailsSlice.actions.setMeterGroups([
            ...state.meters.details.meterGroups,
            data,
        ])
    );
};

export const getStaticMeterGroupContent = (groupId: number): AppThunk => async (
    dispatch
) => {
    const route = getApiRoute(appUrls.api_get_static_meter_group_content, {
        groupId,
    });
    const data = await httpService.get<MeterGroupViewModel[]>(route);
    dispatch(meterDetailsSlice.actions.setStaticMeterGroupsContents(data));
};

export const deleteStaticMeterGroupContent = (
    staticMeterGroupContentId: number
): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_delete_static_meter_group_content, {
        staticMeterGroupContentId,
    });
    await httpService.delete<void>(route);
};

export const getMeter = (meterId: string): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter, { meterId });
    const data = await httpService.get<MeterViewModel>(route);
    dispatch(meterDetailsSlice.actions.setMeterDetails(data));
};

export const getMeterCommunicatorInformation = (
    meterId: string
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_communicator_information, {
        meterId,
    });
    try {
        const data = await httpService.get<MeterCommunicatorInformation>(route);
        dispatch(meterDetailsSlice.actions.setMeterCommunicatorInformation(data));
    } catch (error) {
        dispatch(meterDetailsSlice.actions.setMeterCommunicatorInformation(null));
    }
};

export const getMeterLatestProfile = (meterId: string): AppThunk => async (
    dispatch
) => {
    const route = getApiRoute(appUrls.api_get_meter_latest_profile, { meterId });
    try {
        const data = await httpService.get<MeterLatestProfile>(route);
        dispatch(meterDetailsSlice.actions.setMeterLatestProfile(data));
    } catch (error) {
        dispatch(meterDetailsSlice.actions.setMeterLatestProfile(null));
    }
};

export const getMeterGis = (meterId: string): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_gis, { meterId });
    try {
        const data = await httpService.get<MeterGis>(route);
        dispatch(meterDetailsSlice.actions.setMeterGis(data));
    } catch (error) {
        dispatch(meterDetailsSlice.actions.setMeterGis(null));
    }
};

interface getMeterLabelsParams {
  isClosedOnly: boolean;
}

export const getMeterLabels = (
    meterId: string,
    params?: getMeterLabelsParams
): AppThunk => async (dispatch) => {
    const route = getApiRoute(
        appUrls.api_get_meter_labels,
        { meterId },
        { ...params }
    );
    try {
        const data = await httpService.get<MeterLabelResponse>(route);
        dispatch(meterDetailsSlice.actions.setMeterLabels(data));
    } catch (error) {
        dispatch(meterDetailsSlice.actions.setMeterLabels(null));
    }
};

export const closeMeterLabel = (
    meterId: string,
    meterLabelTypeId: number
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_post_meter_label_close, {
        meterId,
        meterLabelTypeId,
    });
    const data = await httpService.put<MeterLabelViewModel>(route);
    dispatch(meterDetailsSlice.actions.updateMeterLabel(data));
};

export const getMeterTariffSchedules = (meterId: string): AppThunk => async (
    dispatch
) => {
    const route = getApiRoute(appUrls.api_get_meter_tariff_schedules, {
        meterId,
    });
    const data = await httpService.get<MeterTariffSchedulesResponse>(route);
    dispatch(meterDetailsSlice.actions.setMeterTariffSchedules(data));
};

export const getMeterMeterTariffScheduleInOrder = (
    meterId: string
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_meter_tariff_schedule_order, {
        meterId,
    });
    const order = await httpService.get<MeterTariffScheduleInOrderViewModel>(
        route
    );
    dispatch(meterDetailsSlice.actions.setMeterTariffScheduleInOrder(order));
};

export const getMeterData = (meterId: string): AppThunk => async (dispatch) => {
    await dispatch(getMeter(meterId));
    try {
        await dispatch(getMeterLabels(meterId));
    } catch {
    // do nothing
    }
    try {
        await dispatch(getMeterCommunicatorInformation(meterId));
    } catch {
    // do nothing
    }
    try {
        await dispatch(getMeterLatestProfile(meterId));
    } catch {
    // do nothing
    }
    try {
        await dispatch(getMeterGis(meterId));
    } catch {
    // do nothing
    }
    try {
        await dispatch(getMeterTariffSchedules(meterId));
    } catch {
    // do nothing
    }
    try {
        await dispatch(getMeterMeterTariffScheduleInOrder(meterId));
    } catch {
    // do nothing
    }
};

export const resetMeterData = (): AppThunk => (dispatch) => {
    dispatch(meterDetailsSlice.actions.setMeterDetails(null));
    dispatch(meterDetailsSlice.actions.setMeterCommunicatorInformation(null));
    dispatch(meterDetailsSlice.actions.setMeterLatestProfile(null));
    dispatch(meterDetailsSlice.actions.setMeterGis(null));
    dispatch(meterDetailsSlice.actions.setMeterLabels(null));
    dispatch(meterDetailsSlice.actions.setMeterTariffSchedules(null));
    dispatch(meterDetailsSlice.actions.setMeterTariffScheduleInOrder(null));
};

export const getMeterConfigurationHistory = (
    meterId: string,
    params?: Record<string, string>
): AppThunk => async (dispatch) => {
    const route = getApiRoute(
        appUrls.api_get_meter_configuration_history,
        { meterId },
        { ...params }
    );
    try {
        const data = await httpService.get<MeterConfigurationHistoryListResponse>(
            route
        );
        dispatch(meterDetailsSlice.actions.setMeterConfigurationHistory(data));
    } catch (error) {
        dispatch(meterDetailsSlice.actions.setMeterConfigurationHistory(null));
    }
};

export const createMeter = (meter: MeterCreator): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_post_meters);
    await httpService.post<MeterViewModel>(route, meter);
};

export const updateMeter = (
    id: string,
    meter: MeterUpdater
): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_put_meters, { meterId: id });
    await httpService.put<MeterViewModel>(route, meter);
};

export const deleteMeter = (id: string): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_delete_meters, { meterId: id });
    await httpService.delete<void>(route);
};

export const resetMeterActionPlans = (): AppThunk => (dispatch) => {
    dispatch(meterDetailsSlice.actions.resetMeterActionPlans());
};

export const getMeterActionPlans = (meterId: string): AppThunk => async (
    dispatch
) => {
    const route = getApiRoute(appUrls.api_get_meter_meter_action_plans, {
        meterId,
    });
    const meterActionPlans: MeterActionPlanViewModel[] = await httpService.get<
    MeterActionPlanViewModel[]
  >(route);
    await dispatch(resetMeterActionPlans());
    for (const meterActionPlan of meterActionPlans) {
        dispatch(meterDetailsSlice.actions.setMeterActionPlan(meterActionPlan));
    }
};

export const syncMeterActionPlans = (): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_post_meter_action_plan_sync);
    await httpService.get(route);
};

export const getMeterConsumptionElectricity = (
    meterId: string,
    params: MeterConsumptionElectricityParams
): AppThunk => async (dispatch) => {
    const route = getApiRoute(
        appUrls.api_get_meter_consumption_electricity,
        { meterId },
        { ...params }
    );
    const data = await httpService.get<MeterConsumptionElectricityDataView[]>(
        route
    );
    dispatch(meterDetailsSlice.actions.setMeterConsumptionElectricity(data));
};

export const meterConsumptionElectricityDownloadExcel = (
    meterId: string,
    params: MeterConsumptionElectricityParams
): AppThunk => async () => {
    const route = getApiRoute(
        appUrls.api_get_meter_consumption_electricity_download_excel,
        { meterId },
        { ...params }
    );
    const {
        data,
    } = await httpService.get<MeterConsumptionElectricityDataExcelResponse>(
        route
    );
    const dateFrom = formatDate(
        params.dateFrom,
        DateFormats.apiDateTransformFormat
    );
    const dateTo = formatDate(params.dateTo, DateFormats.apiDateTransformFormat);
    downloadExcelData(
        data,
        `electricity-volumes-${meterId}-${dateFrom}-${dateTo}`
    );
};

export const getMeterConsumptionElectricityTariff = (
    meterId: string,
    params: MeterConsumptionElectricityTariffParams
): AppThunk => async (dispatch) => {
    const route = getApiRoute(
        appUrls.api_get_meter_consumption_electricity_tariff,
        { meterId },
        { ...params }
    );
    const data = await httpService.get<
    MeterConsumptionElectricityTariffDataView[]
  >(route);
    dispatch(
        meterDetailsSlice.actions.setMeterConsumptionElectricityTariff(data)
    );
};

export const meterConsumptionElectricityTariffDownloadExcel = (
    meterId: string,
    params: MeterConsumptionElectricityTariffParams
): AppThunk => async () => {
    const route = getApiRoute(
        appUrls.api_get_meter_consumption_electricity_tariff_download_excel,
        { meterId },
        { ...params }
    );
    const {
        data,
    } = await httpService.get<MeterConsumptionElectricityTariffDataExcelResponse>(
        route
    );
    const dateFrom = formatDate(
        params.dateFrom,
        DateFormats.apiDateTransformFormat
    );
    const dateTo = formatDate(params.dateTo, DateFormats.apiDateTransformFormat);
    downloadExcelData(
        data,
        `electricity-tariff-volumes-${meterId}-${dateFrom}-${dateTo}`
    );
};

export const getMeterPowerSwitch = (meterId: string): AppThunk => async (
    dispatch
) => {
    const route = getApiRoute(appUrls.api_get_meter_power_switch, { meterId });
    const data = await httpService.get<MeterPowerSwitchDetails>(route);
    dispatch(meterDetailsSlice.actions.setMeterPowerSwitch(data));
};

export const updateMeterPowerSwitch = (
    meterId: string,
    payload: MeterPowerSwitchPutPayload
): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_get_meter_power_switch, { meterId });
    await httpService.put(route, payload);
};

export const updateMeterMeterTariffSchedule = async (
    meterId: string,
    updater: MeterMeterTariffScheduleUpdater
): Promise<void> => {
    const route = getApiRoute(appUrls.api_put_meter_meter_tariff_schedule, {
        meterId,
    });
    await httpService.put<MeterTariffScheduleViewModel>(route, updater);
};

export const rewriteMeterTariffSchedule = async (
    meterId: string
): Promise<MeterTariffScheduleViewModel> => {
    const route = getApiRoute(
        appUrls.api_post_meter_meter_tariff_schedule_rewrite,
        { meterId }
    );
    const createdMeterTariffSchedule = await httpService.post<MeterTariffScheduleViewModel>(
        route,
        {}
    );
    return createdMeterTariffSchedule;
};

export const setMeterUpdateTechnicalParamsOrder = (
    order: MeterUpdateTechnicalParamsOrderViewModel
): AppThunk => (dispatch) => {
    dispatch(meterDetailsSlice.actions.setMeterUpdateTechnicalParamsOrder(order));
};

export const getMeterUpdateTechnicalParamsOrder = (
    meterId: string
): AppThunk => async (dispatch) => {
    const route = getApiRoute(
        appUrls.api_get_meter_update_technical_params_order,
        { meterId }
    );
    const technicalParam = await httpService.get<MeterUpdateTechnicalParamsOrderViewModel>(
        route
    );
    dispatch(setMeterUpdateTechnicalParamsOrder(technicalParam));
};
