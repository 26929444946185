import React from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../shared/components/button';
import { CreateAddress } from '../../../../shared/components/createAddress';
import { AddAddressLink } from '../../../../shared/components/createAddress/addAddressLink';
import { Forbidden } from '../../../../shared/components/forbidden';
import { HookField } from '../../../../shared/components/hookField';
import { useUpdateFromAttributesStyles } from '../styles';
import { StateProps } from './model';

export const View = ({
    form,
    onSubmit,
    onCancel,
    hasAccess,
    addressAutocomplete,
    onCreateAddress,
    isCreateAddressFormOpen,
    onCloseCreateAddressForm,
    onOpenCreateAddressForm,
}: StateProps) => {

    if (!hasAccess) {
        return <Forbidden />;
    }

    const { handleSubmit, formState: { isDirty, dirtyFields } } = form;
    const classes = useUpdateFromAttributesStyles();

    return <>
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <HookField
                            name='addressId'
                            type='autocomplete'
                            label='Адрес'
                            inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['addressId'] }) }}
                            fullWidth
                            noOptionsText='Адрес не найден'
                            loadingText='Поиск...'
                            required
                            {...addressAutocomplete}
                        />
                        <AddAddressLink onClick={onOpenCreateAddressForm} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <HookField
                            name='addressSpecifier'
                            type='text'
                            label='Помещение'
                            inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['addressSpecifier'] }) }}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <HookField
                            name='installationSite'
                            type='text'
                            label='Место установки'
                            inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['installationSite'] }) }}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                            <Grid item>
                                <Button variant='primary' type='submit' disabled={!isDirty} icon='check'>Сохранить</Button>
                            </Grid>
                            <Grid item>
                                <Button variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
        {isCreateAddressFormOpen && (
            <CreateAddress onClose={onCloseCreateAddressForm} onCreateAddress={onCreateAddress} />
        )}
    </>;
};
