import { View } from './view';
import { CreateMeterGroupFormProps } from './model';
import { useParams } from 'react-router-dom';


export const MeterGroupForm = (props: CreateMeterGroupFormProps) => {
    const { groupId } = useParams<{ groupId: string; }>();
    return View({
        ...props,
        groupId,
    });
};
