import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MeterAddressUpdater } from '../../../common/model/meter/meter/meterAddressUpdater';
import { MeterCommunicationParamsUpdater } from '../../../common/model/meter/meter/meterCommunicationParamsUpdater';
import { MeterInformationFieldsUpdater } from '../../../common/model/meter/meter/meterInformationFieldsUpdater';
import { MeterPasswordFieldsUpdater } from '../../../common/model/meter/meter/meterPasswordFieldsUpdater';
import { MeterReplacingCommunicatorUpdater } from '../../../common/model/meter/meter/meterReplacingCommunicatorUpdater';
import { MeterSyncSchemeUpdater } from '../../../common/model/meter/meter/meterSyncSchemeUpdater';
import { MeterTechnicalParamsUpdater } from '../../../common/model/meter/meter/meterTechnicalParamsUpdater';
import { MeterTransformationRatiosUpdater } from '../../../common/model/meter/meter/meterTransformationRatiosUpdater';
import { MeterVerificationUpdater } from '../../../common/model/meter/meter/meterVerificationUpdater';
import { MeterViewModel } from '../../../common/model/meter/meter/meterViewModel';
import { MeterTechnicalParamsUpdaterResponse } from '../../../common/model/meter/meterTechnicalParamsUpdaterResponse';
import { meterDetailsSlice } from '../../reducers/meters/details';
import { createSnackbar } from '../controls';
import { AppThunk } from '../index';
import { getMeterData } from '../meters';

export const updateMeterPasswordFields = (
    meterId: string,
    updater: MeterPasswordFieldsUpdater
): AppThunk => async (dispatch) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(appUrls.api_put_meter_password_fields, {
            meterId,
        });
        await httpService.put<MeterViewModel>(route, updater);

        dispatch(getMeterData(meterId));
        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Пароли ПУ успешно обновлены',
            })
        );
    } finally {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
    }
};

export const updateMeterCommunicationParams = (
    meterId: string,
    updater: MeterCommunicationParamsUpdater,
    changeStatusToActive?: boolean,
    noValidate?: boolean
): AppThunk => async (dispatch) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(
            appUrls.api_put_meter_communication_params,
            { meterId },
            { changeStatusToActive, noValidate }
        );
        await httpService.put<MeterViewModel>(route, updater);

        dispatch(getMeterData(meterId));
        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Параметры связи обновлены успешно',
            })
        );
    } finally {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
    }
};

export const updateMeterReplacingCommunicator = (
    meterId: string,
    updater: MeterReplacingCommunicatorUpdater,
    changeStatusToActive?: boolean
): AppThunk => async (dispatch) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(
            appUrls.api_put_meter_replacing_communicator,
            { meterId },
            { changeStatusToActive }
        );
        await httpService.put<MeterViewModel>(route, updater);

        dispatch(getMeterData(meterId));
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));

        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Изменения сохранены успешно',
            })
        );
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    error.message ||
                    `При сохранении произошла ошибка: ${error?.data?.errorDetails || ''
                    }`,
            })
        );
    }
};

export const updateMeterTransformationRatios = (
    meterId: string,
    updater: MeterTransformationRatiosUpdater
): AppThunk => async (dispatch) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(appUrls.api_put_meter_transformation_ratios, {
            meterId,
        });
        await httpService.put<MeterViewModel>(route, updater);

        dispatch(getMeterData(meterId));
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));

        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Коэффициенты трансформации обновлены успешно',
            })
        );
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    error.message ||
                    `При сохранении произошла ошибка: ${error?.data?.errorDetails || ''
                    }`,
            })
        );
    }
};

export const updateMeterVerification = (
    meterId: string,
    updater: MeterVerificationUpdater
): AppThunk => async (dispatch) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(appUrls.api_put_meter_verification, { meterId });
        await httpService.put<MeterViewModel>(route, updater);

        dispatch(getMeterData(meterId));
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));

        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Срок межповерочного интервала успешно обновлен',
            })
        );
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    error.message ||
                    `При сохранении произошла ошибка: ${error?.data?.errorDetails || ''
                    }`,
            })
        );
    }
};

export const updateMeterInformationFields = (
    meterId: string,
    updater: MeterInformationFieldsUpdater
): AppThunk => async (dispatch) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(appUrls.api_put_meter_information_fields, {
            meterId,
        });
        await httpService.put<MeterViewModel>(route, updater);

        dispatch(getMeterData(meterId));
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));

        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Информационные поля счетчика успешно обновлены',
            })
        );
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    error.message ||
                    `При сохранении произошла ошибка: ${error?.data?.errorDetails || ''
                    }`,
            })
        );
    }
};

export const updateMeterTechnicalParams = (
    meterId: string,
    updater: MeterTechnicalParamsUpdater
): AppThunk => async (dispatch) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(appUrls.api_put_meter_technical_params, {
            meterId,
        });
        const response = await httpService.put<MeterTechnicalParamsUpdaterResponse>(
            route,
            updater
        );

        dispatch(getMeterData(meterId));
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));

        dispatch(
            createSnackbar({
                type: 'green',
                message: response.message,
            })
        );
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    error.message ||
                    `При сохранении произошла ошибка: ${error?.data?.errorDetails || ''
                    }`,
            })
        );
    }
};

export const updateMeterAddress = (
    meterId: string,
    updater: MeterAddressUpdater
): AppThunk => async (dispatch) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(appUrls.api_put_meter_address, { meterId });
        await httpService.put<MeterViewModel>(route, updater);

        dispatch(getMeterData(meterId));
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));

        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Адрес обновлен успешно',
            })
        );
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    error.message ||
                    `При сохранении произошла ошибка: ${error?.data?.errorDetails || ''
                    }`,
            })
        );
    }
};

export const updateMeterStatusToActive = (meterId: string): AppThunk => async (
    dispatch
) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(appUrls.api_put_meter_status_to_active, {
            meterId,
        });
        await httpService.put<MeterViewModel>(route);

        dispatch(getMeterData(meterId));
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));

        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Статус успешно изменен на \'Активен\'',
            })
        );
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    error.message ||
                    `При сохранении произошла ошибка: ${error?.data?.errorDetails || ''
                    }`,
            })
        );
    }
};

export const updateMeterStatusToDecommissioned = (
    meterId: string
): AppThunk => async (dispatch) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(appUrls.api_put_meter_status_to_decommissioned, {
            meterId,
        });
        await httpService.put<MeterViewModel>(route);

        dispatch(getMeterData(meterId));
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));

        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Статус успешно изменен на \'Выведен из эксплуатации\'',
            })
        );
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    error.message ||
                    `При сохранении произошла ошибка: ${error?.data?.errorDetails || ''
                    }`,
            })
        );
    }
};

export const updateMeterSyncSchemes = (
    meterId: string,
    updater: MeterSyncSchemeUpdater
): AppThunk => async (dispatch) => {
    try {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(true));

        const route = getApiRoute(appUrls.api_put_meter_sync_schemes, { meterId });
        await httpService.put<MeterViewModel>(route, updater);

        dispatch(getMeterData(meterId));
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));

        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Схемы успешно изменены',
            })
        );
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    error.message ||
                    `При сохранении произошла ошибка: ${error?.data?.errorDetails || ''
                    }`,
            })
        );
    }
};

export const updateMeterCl = (
    meterId: string,
    msisdn: string
): AppThunk => async (dispatch) => {
    try {
        if (!msisdn) {
            throw new Error('MSISDN не указан');
        }

        const route = getApiRoute(appUrls.api_get_meter_reset_connection, {
            meterId,
            msisdn,
        });
        const { status } = await httpService.get<{ status: boolean, ids: string[] | null; }>(route);

        dispatch(
            createSnackbar({
                type: status ? 'green' : 'red',
                message: status ? 'Схемы успешно изменены' : 'Не удалось изменить схемы. Попробуйте позже',
            })
        );
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    `При сохранении произошла ошибка: ${error.message || error.data?.errorDetails || ''}`,
            })
        );
    }
};

export const refreshM2m = (
    meterId: string,
    msisdn: string,
    showSnackBar: boolean
): AppThunk => async (dispatch) => {
    try {
        const route = getApiRoute(appUrls.api_refresh_communicator_m2m_info, {
            meterId,
            msisdn,
        });
        const result = await httpService.get<MeterViewModel>(route);

        if (showSnackBar) {
            dispatch(
                createSnackbar({
                    type: 'green',
                    message: 'Обновление даты последнего опроса',
                })
            );
        }

        return result;
    } catch (error: any) {
        dispatch(meterDetailsSlice.actions.setMeterUpdateLoading(false));
        dispatch(
            createSnackbar({
                type: 'red',
                message:
                    error.message ||
                    `При обновлении произошла ошибка: ${error.message || error?.data?.errorDetails || ''
                    }`,
            })
        );
        return null;
    }
};
