import { httpService } from 'app/shared/httpWrapper';
import { getApiRoute } from 'app/shared/pipes';
import { appUrls } from 'common/appUrls';
import { crqSystemListSlice } from 'redux/reducers/crqSystem/list';
import { CrqSystemViewModel } from 'common/model/meter/crq/crqSystemViewModel';
import { AppThunk } from '../../index';

export const getCrqSystemList = (partnerId: number): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_partner_crq_systems, { partnerId });
    const data = await httpService.get<CrqSystemViewModel[]>(route);
    dispatch(crqSystemListSlice.actions.setAvailable(data));
};

export const resetCrqSystemList = (): AppThunk => (dispatch) => {
    dispatch(crqSystemListSlice.actions.setAvailable(null));
};
