import React from 'react';
import { Box } from '@material-ui/core';
import { DropdownMenu } from 'app/shared/components/dropdownMenu';
import { CrqChannelType } from 'common/model/meter/crq/crqChannelType';

export const renderType = (type: CrqChannelType) => {
    return CrqChannelType[type];
};

export const actionsButtons = (handleEdit: ()=> void, handleRemove: ()=> void) => {
    return (

        <Box marginLeft='18px'>
            <DropdownMenu
                icon='moreVert'
                dropdownPosition='bottomRight'
                options={[
                    {
                        label: 'Редактировать',
                        icon: 'edit',
                        onClick: handleEdit,
                    },
                    {
                        label: 'Удалить',
                        icon: 'delete',
                        onClick: handleRemove,
                    }
                ]} />
        </Box>
    );
};
