import { RelayStatus, meterRelayStatusDictionary } from '../../../common/model/meter/relayStatus';
import { ISort } from '../../../common/sort';
import { createSnackbar } from '../../../redux/actions/controls';
import { Button } from '../../shared/components/button';
import { Link } from '../../shared/components/link';
import { usePagination } from '../../shared/components/pagination';
import { useWebDictionaryLookup } from '../../shared/hooks/useWebDictionary';
import { getErrorMessage } from '../../shared/utils/error';
import { View } from './view';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { metersSearchSelector, metersSearchTotalSelector } from '../../../redux/selectors/meters';
import React, { useEffect, useState } from 'react';
import { useSyncQueryParams } from '../../shared/hooks/useQueryParams';
import { useForm } from 'react-hook-form';
import { searchMeters } from '../../../redux/actions/meters';
import { BaseDispatch } from '../../../redux/actions';
import { getRoute } from '../../shared/pipes';
import { MeterTabs, Routes } from '../../shared/constants';
import { connectionTypeDictionary } from '../../../common/model/meter/connectionType';
import { MeterStatus, meterStatusDictionary } from '../../../common/model/meter/meterStatus';
import moment from 'moment';
import { DateFormats } from '../../../common/constants/date';
import { Icon } from '../../shared/components/icon';
import { Box } from '@material-ui/core';
import { MeterSearchModel } from './model';
import { Tooltip } from '../../shared/components/tooltip';
import { MeterLabelViewModel } from '../../../common/model/meter/meterLabel/meterLabelViewModel';
import './style.scss';
import { StorageState } from '../../../redux/reducers';
import { getMeterLabelTypes } from '../../../redux/actions/meterLabelType';

const emptyFormParams: MeterSearchModel = {
    meterModelValues: [],
    meterPurpose: null,
    legalEntityType: null,
    priceCategory: null,
    status: null,
    accountType: null,
    connectionType: null,
    meterSerialNum: null,
    installationSite: null,
    id: null,
    nextVerificationDate: null,
    communicatorSerialNum: null,
    installationDate: null,
    relayOn: null,
    relayStatus: null,
    meterModelError: null,
    meterFirmwareVersionError: null,
    cTRatioError: null,
    pTRatioError: null,
    currentThroughSwitchedOffRelay: null,
    timeError: null,
    notSyncTimeError: null,
    tariffError: null,
    manufactureDateError: null,
    regionId: null,
    areaName: '',
    townName: '',
    streetName: '',
    houseNumber: '',
    addressString: '',
    addressSpecifier: '',
    meterLabelTypes: [],
    meterActionSchemeId: null,
};

const initialFormParams: MeterSearchModel = {
    ...emptyFormParams,
    status: MeterStatus.Active,
};

export const Search = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const meters = useSelector(metersSearchSelector);
    const dataTotal = useSelector(metersSearchTotalSelector);
    const { meterModels } = useWebDictionaryLookup();
    const [metersFilter, setMetersFilter] = useSyncQueryParams<MeterSearchModel>(initialFormParams);
    const [isShowResult, setIsShowResult] = useState(false);
    const [isShowAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const form = useForm<MeterSearchModel>({ defaultValues: metersFilter });

    const meterLabelTypes = useSelector((state: StorageState) => state.meterLabelType.list.available);
    useEffect(() => {
        dispatch(getMeterLabelTypes());
    }, []);

    const formData = form.watch();

    const fetch = async (sort: ISort, query: MeterSearchModel = formData) => {
        setIsLoading(true);
        try {
            await dispatch(searchMeters({ ...query, ...sort }));
        } catch (err) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(err),
            }));
        }
        setIsLoading(false);
    };

    const { pagination, setOrder, setPage, onFetch, orderParams, setPagination } = usePagination({
        page: 1,
        rowsPerPage: 20,
        orderBy: null,
        orderDirection: null,
    }, fetch);

    const updateData = async (data?: MeterSearchModel) => {
        const params = data || initialFormParams;
        const newPagination = { ...pagination, page: 1 };
        setPagination(newPagination);
        setMetersFilter({ ...params });

        await onFetch(newPagination, {
            ...params
        });
        setIsShowResult(true);
    };

    useEffect(() => {
        onFetch(pagination, formData);
        setIsShowResult(true);
    }, []);

    return View({
        data: meters,
        form,
        isLoading,
        isShowResult,
        isShowAll,
        dataTotal,
        pagination,
        setPage,
        setOrder,
        orderParams,
        addressFilterDefaults: {
            areaName: metersFilter.areaName,
            townName: metersFilter.townName,
            streetName: metersFilter.streetName,
            houseNumber: metersFilter.houseNumber,
            regionId: metersFilter.regionId,
            addressSpecifier: metersFilter.addressSpecifier,
        },
        onClickShowAll: () => {
            setShowAll(!isShowAll);
        },
        onSubmit: async (data) => {
            try {
                await updateData(data);
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
        },
        onCancel: async () => {
            await updateData();
            form.reset(emptyFormParams);
        },
        columnDefinition: [
            {
                title: 'Тип ПУ',
                field: 'meterModelId',
                lookup: meterModels,
            },
            {
                title: 'Заводской номер',
                field: 'meterSerialNum',
                render: (data) => {
                    return (
                        <Box display='flex' alignItems='center' justifyContent='start'>
                            <Box>
                                {data.meterSerialNum}
                            </Box>
                        </Box>
                    );
                },
            },
            {
                title: '',
                field: '',
                render: (data) => {
                    return (
                        <Box display='flex' alignItems='center' justifyContent='start'>
                            {!!data.labels.length && <Box>
                                <Tooltip text={
                                    <div className='tooltip'>
                                        {data.labels.map((item: MeterLabelViewModel) => {
                                            return meterLabelTypes
                                                .find(type => type.id === item.meterLabelTypeId)
                                                ?.description || '';
                                        }).join('\n')}
                                    </div>
                                }>
                                    <Icon className='error-icon' name='alert' color='error' />
                                </Tooltip>
                            </Box>}
                        </Box>
                    );
                },
            },
            {
                title: 'Партнер',
                field: 'partner.name',
                render: (data) => {
                    return (
                        <Box display='flex' alignItems='center' justifyContent='start'>
                            <Box>
                                {data.partner?.name}
                            </Box>
                        </Box>
                    );
                },
            },
            {
                title: 'Состояние ПУ',
                field: 'status',
                lookup: meterStatusDictionary,
                render: (e) => {
                    const defective = meterStatusDictionary[e.status] === meterStatusDictionary.Defective;
                    const color = defective ? 'red' : null;
                    return (
                        <Box color={color}>
                            {meterStatusDictionary[e.status]}
                        </Box>
                    );
                }
            },
            {
                title: 'Состояние реле',
                field: 'relayStatus',
                render: (e) => {
                    const color = e.relayStatus === RelayStatus.On ? null : 'red';
                    return (
                        <Box color={color}>
                            {meterRelayStatusDictionary[e.relayStatus]}
                        </Box>
                    );
                }
            },
            {
                title: 'Тип учета',
                field: 'accountType',
            },
            {
                title: 'Назначение прибора',
                field: 'meterPurpose',
            },
            {
                title: 'Тип включения',
                field: 'connectionType',
                lookup: connectionTypeDictionary,
                hidden: !isShowAll,
                cellClassName: 'cell-connectionType'
            },
            {
                title: 'Правовая форма владельца',
                field: 'legalEntityType',
                hidden: !isShowAll,
            },
            {
                title: 'Ценовая категория',
                field: 'priceCategory',
                hidden: !isShowAll,
            },
            {
                title: 'Место установки',
                field: 'installationSite',
                hidden: !isShowAll,
            },
            {
                title: 'УИД ПУ',
                field: 'foreignId',
                hidden: !isShowAll,
                cellClassName: 'cell-foreignId'
            },
            {
                title: 'Заводской № коммуникатора',
                field: 'communicatorSerialNum',
                hidden: !isShowAll,
            },
            {
                title: 'Дата след. поверки',
                field: 'nextVerificationDate',
                hidden: !isShowAll,
                render: (data) => data.nextVerificationDate ?
                    moment(data.nextVerificationDate).format(DateFormats.dateFullTimeFormat)
                    : '-'
            },
            {
                title: 'Дата ввода в эксплуатацию',
                field: 'installationDate',
                hidden: !isShowAll,
                render: (data) => moment(data.installationDate).format(DateFormats.dateFullTimeFormat),
            },
            {
                title: 'Адрес',
                field: 'address.address',
                cellClassName: 'cell-address',
                cellStyle: {
                    minWidth: '350px'
                },
            },
            {
                title: '',
                cellClassName: classNames('cell-fixed', { 'cell-fixed--right': isShowAll }),
                render: ({ id }) => {
                    return <Link
                        to={{
                            pathname: getRoute(
                                Routes.meter,
                                { meterId: id },
                            ),
                            search: new URLSearchParams({ tab: MeterTabs.Information }).toString(),
                        }}
                        appendBackUrl={true}
                    >
                        <Button variant='primary' type='button'>Открыть</Button>
                    </Link>;
                },
            },
        ]
    });
};
