export enum UploaderFileState {
    new,
    error,
    uploading,
    uploaded,
}

export interface UploaderFile {
    id: string;
    file: File;
    state: UploaderFileState;
    message?: string;
}

export interface UploaderFileProps {
    file: UploaderFile;
    onDelete?: (id: string) => void;
}
