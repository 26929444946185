import { UsersFindResponse } from '../../../../../../common/model/access/user/usersFindResponse';
import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import { UserViewModel } from '../../../../common/model/access/user/userViewModel';
import { ISort } from '../../../../common/sort';
import { userListSlice } from '../../../reducers/users/list';
import { AppThunk } from '../../index';


export const getUsers = (sort: ISort & { search?: string; }): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_users, { }, { ...sort });
    try {
        const data = await httpService.get<UsersFindResponse>(route);
        dispatch(userListSlice.actions.setUsersList(data));
    } catch {
        dispatch(userListSlice.actions.setUsersList(null));
    }
};

export const getAllUsers = (): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_all_users);
    try {
        const data = await httpService.get<UserViewModel[]>(route);
        dispatch(userListSlice.actions.setAllUsersList(data));
    } catch {
        dispatch(userListSlice.actions.setAllUsersList([]));
    }
};
