import { Column } from '@softmedialab/materialui-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AdvancedCrashReportQuery } from '../../../../common/model/meter/reports/advancedCrashReportQuery';
import { AdvancedCrashReportRow } from '../../../../common/model/meter/reports/advancedCrashReportRow';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import {
    getAdvancedCrashReport, getAdvancedCrashReportExcel,
    resetAdvancedCrashReport
} from '../../../../redux/actions/reports/advancedCrashReport';
import { advancedCrashReportSelector } from '../../../../redux/selectors/reports/advancedCrashReport';
import { useMeterGroupAutocomplete } from '../../../shared/components/autocomplete/hooks/useMeterGroupAutocomplete';
import { MeterTabs, Routes } from '../../../shared/constants';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { useTabs } from '../../../shared/hooks/useTabs';
import { useWebDictionarySelectSuggestions } from '../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../shared/pipes';
import { getErrorMessage } from '../../../shared/utils/error';
import { MeterSerialNumView } from '../../reports/components/common/meterSerianNumView';
import { columnsTitles, getExtraColumnsByTab } from './helper';
import { View } from './view';
import {
    AdvancedCrashReportFormData, AdvancedCrashReportProps,
    AdvancedCrashReportTab,
    AdvancedCrashReportTabs
} from './model';


const filterFormDefaultValues: AdvancedCrashReportFormData = {
    meterGroupId: undefined,
};

export const AdvancedCrashReport: React.FC<AdvancedCrashReportProps> = (props) => {

    const {
        regionId,
    } = props;

    const dispatch = useDispatch<BaseDispatch>();
    const reportResponse = useSelector(advancedCrashReportSelector());
    const data = reportResponse?.data ?? [];
    const reportId = reportResponse?.reportId;
    const isReportFormed = !!reportResponse;

    const [isLoading, setIsLoading] = useState(false);
    const [queryParams, setQueryParams] = useSyncQueryParams<AdvancedCrashReportFormData>(filterFormDefaultValues);
    const [reportFilter, setReportFilter] = useState<AdvancedCrashReportQuery>(null);

    const form = useForm<AdvancedCrashReportFormData>({
        defaultValues: queryParams,
    });

    const formData = form.watch();

    useEffect(() => {
        return () => {
            dispatch(resetAdvancedCrashReport());
        };
    }, []);

    const {
        activeTab,
        setTab,
    } = useTabs<AdvancedCrashReportTabs>({
        values: AdvancedCrashReportTabs,
        defaultTab: AdvancedCrashReportTabs.DailyProfileReadings,
        route: Routes.monitoring,
        queryKey: 'reportTab',
    });

    const fetch = async () => {
        setIsLoading(true);
        const reportQuery: AdvancedCrashReportQuery = {
            ...formData,
            regionId,
        };
        try {
            setQueryParams({
                ...formData,
            });
            await dispatch(getAdvancedCrashReport(reportQuery));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        form.reset({
            ...formData,
        });
        setReportFilter(reportQuery);
        setIsLoading(false);
    };

    const meterGroupAutocomplete = useMeterGroupAutocomplete({
        regionId,
    });

    const tabs: AdvancedCrashReportTab[] = [
        {
            key: AdvancedCrashReportTabs.DailyProfileReadings,
            title: 'СБОР СУТОЧНЫХ ПОКАЗАНИЙ',
        },
        {
            key: AdvancedCrashReportTabs.CurrentProfileReadings,
            title: 'СБОР ТЕКУЩИХ ПОКАЗАНИЙ, СВЕРКА ВРЕМЕНИ',
        },
        {
            key: AdvancedCrashReportTabs.IncrementalProfileReadings,
            title: 'СБОР ПРОФИЛЯ МОЩНОСТИ',
        },
        {
            key: AdvancedCrashReportTabs.ConfigurationSnapshot,
            title: 'ЧТЕНИЕ КОНФИГУРАЦИИ',
        },
        {
            key: AdvancedCrashReportTabs.ValidateOrChangeTariff,
            title: 'ВАЛИДАЦИЯ И УСТАНОВКА ТАРИФНОГО РАСПИСАНИЯ',
        },
        {
            key: AdvancedCrashReportTabs.ElectricPowerQualityEvents,
            title: 'СБОР ЖУРНАЛА ПОКАЗАТЕЛЕЙ КАЧЕСТВА ЭЛЕКТРОЭНЕРГИИ',
        },
        {
            key: AdvancedCrashReportTabs.PowerSwitchEvents,
            title: 'СБОР ЖУРНАЛА ВКЛЮЧЕНИЙ \\ ВЫКЛЮЧЕНИЙ',
        },
        {
            key: AdvancedCrashReportTabs.DataCorrectionEvents,
            title: 'СБОР ЖУРНАЛА КОРРЕКЦИИ ДАННЫХ',
        },
        {
            key: AdvancedCrashReportTabs.TamperingEvents,
            title: 'СБОР ЖУРНАЛА ВНЕШНИХ ВОЗДЕЙСТВИЙ',
        },
        {
            key: AdvancedCrashReportTabs.AccessControlEvents,
            title: 'СБОР ЖУРНАЛА КОНТРОЛЯ ДОСТУПА',
        },
        {
            key: AdvancedCrashReportTabs.VoltageEvents,
            title: 'СБОР ЖУРНАЛА НАПРЯЖЕНИЙ',
        },
        {
            key: AdvancedCrashReportTabs.CurrentEvents,
            title: 'СБОР ЖУРНАЛА ТОКОВ',
        },
        {
            key: AdvancedCrashReportTabs.SelfDiagnosticEvents,
            title: 'СБОР ЖУРНАЛА САМОДИАГНОСТИКИ',
        },
    ];

    const columns: Column[] = useMemo(() => ([
        {
            title: columnsTitles.index,
            field: 'index',
            cellClassName: 'cell-index',
            sorting: false,
        },
        {
            title: columnsTitles.shortAddress,
            field: 'mainInfo.shortAddress',
            cellClassName: 'cell-shortAddress',
            sorting: false,
        },
        {
            title: columnsTitles.fullAddress,
            field: 'mainInfo.fullAddress',
            cellClassName: 'cell-fullAddress',
            sorting: false,
        },
        {
            title: columnsTitles.meterSerialNum,
            field: 'mainInfo.meterSerialNum',
            cellClassName: 'cell-meterSerialNum',
            render: (item: AdvancedCrashReportRow) => {
                return <MeterSerialNumView
                    label={item.mainInfo.meterSerialNum}
                    url={getRoute(
                        Routes.meter,
                        { meterId: item.mainInfo.meterId },
                        { tab: MeterTabs.Information }
                    )}
                />;
            }
        },
        {
            title: columnsTitles.lastReadingDate,
            field: 'mainInfo.lastReadingDate',
            cellClassName: 'cell-lastReadingDate',
            sorting: false,
        },
        {
            title: columnsTitles.lastReadingValue,
            field: 'mainInfo.lastReadingValue',
            cellClassName: 'cell-lastReadingValue',
            sorting: false,
        },
        ...getExtraColumnsByTab(activeTab),
    ]), [activeTab]);

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const isReportExcelNotAvailable = form.formState.isDirty;

    return View({
        ...props,
        tabs,
        data,
        form,
        columns,
        activeTab,
        isLoading,
        isReportFormed,
        meterGroupAutocomplete,
        isReportExcelNotAvailable,
        onChangeTab: (_, tab) => {
            setTab(tab);
        },
        onClickFormExcel: async () => {
            setIsLoading(true);
            try {
                await getAdvancedCrashReportExcel({
                    ...reportFilter,
                    reportId,
                });
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsLoading(false);
        },
        onClickFormReport: async () => {
            await fetch();
        },
        onClickRefresh: async () => {
            await fetch();
        },
        regionSuggestions,
    });
};
