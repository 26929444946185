import { httpService } from 'app/shared/httpWrapper';
import { getApiRoute } from 'app/shared/pipes';
import { appUrls } from 'common/appUrls';
import { CrqSystemViewModel } from 'common/model/meter/crq/crqSystemViewModel';
import { editableCrqSystemSlice } from 'redux/reducers/crqSystem/editable';
import { AppThunk, BaseDispatch } from '../../index';

export const setEditableCrqSystem = (crqSystem: CrqSystemViewModel): AppThunk => (dispatch): void => {
    dispatch(editableCrqSystemSlice.actions.setEditableCrqSystem(crqSystem));
};

export const getEditableCrqSystem = (id: number): AppThunk => async (dispatch: BaseDispatch): Promise<void> => {
    const crqSystem = await httpService.get<CrqSystemViewModel>(getApiRoute(appUrls.api_get_crq_system, { id }));
    await dispatch(setEditableCrqSystem(crqSystem));
};

export const resetEditableCrqSystem = (): AppThunk => (dispatch): void => {
    dispatch(setEditableCrqSystem(null));
};
