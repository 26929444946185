/* eslint-disable @typescript-eslint/no-magic-numbers */
import classNames from 'classnames';
import * as _ from 'lodash';
import { Box, makeStyles, Grid } from '@material-ui/core';
import React, { useMemo, useRef } from 'react';
import { minutesToTimeParser } from '../timePicker/helpers';
import { InputProps, TariffScheduleWidgetTariffZone } from './model';
import { Block } from '../wrapper/block';

const getLegendColors = (tariffCount: number): Record<number, string> => {
    if (tariffCount <= 1) {
        return {
            [0]: '#FF5722',
        };
    }
    return {
        [0]: '#f4e6e0',
        [1]: '#FF5722',
        [2]: '#5C6C7F',
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    legendBlock: {
        width: 110,
        height: 12,
    },
    group: {},
    groupZone: {
        height: 12,
    },
    groupZonesWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    linesWrapper: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 56,
        bottom: 0,
        zIndex: 0,
        display: 'flex',
        alignItems: 'center',
    },
    linesBlock: {
        height: '100%',
        borderRightWidth: 1,
        borderRightStyle: 'dashed',
        borderRightColor: theme.palette.neutral.ultraLight,
    },
    linesBlockFirst: {
        borderLeftWidth: 1,
        borderLeftStyle: 'dashed',
        borderLeftColor: theme.palette.neutral.ultraLight,
    },
    groupZoneName: {
        textAlign: 'left',
        color: theme.palette.text.secondary,
        paddingTop: 16,
        paddingBottom: 8,
        paddingLeft: 4,
        paddingRight: 4,
        ...theme.typography.body,
    },
    startTime: {
        height: 26,
        position: 'relative',
    },
    startTimeFirst: {
    },
    startTimeText: {
        color: theme.palette.text.hint,
        paddingTop: 6,
        paddingLeft: 4,
        paddingRight: 4,
        ...theme.typography.caption,
        position: 'absolute',
        right: '100%',
    },
    startTimeTextFirst: {
        left: 0,
        right: 'auto',
    },
    contentWrapper: {
        position: 'relative',
    },
}));

const minutesInDay = 24 * 60;
const hundredPercent = 100;

const calcZoneWidthPercent = (
    startTime: number,
    prevZoneStartTime: number,
    nextZoneStartTime: number,
): number => {
    return nextZoneStartTime === undefined
        ? (hundredPercent * ((minutesInDay - startTime) / minutesInDay))
        : hundredPercent * ((nextZoneStartTime - startTime) / minutesInDay);
};

const getZonesStartTimes = (zones: TariffScheduleWidgetTariffZone[]): TariffScheduleWidgetTariffZone[] => {
    if (zones.length === 0) {
        return zones;
    }
    const fakeZones = [...zones];
    const zoneStartTimes = zones.map(item => item.startTime);
    if (!zoneStartTimes.includes(0)) {
        fakeZones.unshift({ tariffNumber: fakeZones[fakeZones.length - 1].tariffNumber, startTime: 0 });
    }
    if (!zoneStartTimes.includes(minutesInDay)) {
        fakeZones.push({ tariffNumber: fakeZones[fakeZones.length - 1].tariffNumber, startTime: minutesInDay });
    }
    return fakeZones.sort((a, b) => a.startTime > b.startTime ? 1 : -1);
};

export const View: React.FC<InputProps> = (props) => {

    const classes = useStyles();

    const {
        blockProps,
        groups,
    } = props;

    const maxCounter = useRef<number>(0);

    const tariffs = useMemo(() => {
        return _.uniq(groups
            .flatMap(item => item.zones)
            .map(item => item.tariffNumber)
            .sort((a, b) => a > b ? 1 : -1));
    }, [groups]);

    const tariffNumberIndex = tariffs.reduce((prev, current, index) => ({ ...prev, [current]: index }), {});

    const startTimes = useMemo(() => {
        return _.uniq(
            [
                0,
                minutesInDay,
                ...groups
                    .flatMap(item => item.zones)
                    .map(item => item.startTime)
            ].sort((a, b) => a > b ? 1 : -1));
    }, [groups]);

    const palette = useMemo(() => {
        return getLegendColors(tariffs.length);
    }, [tariffs]);

    let counter = 0;

    startTimes.map((startTime, index) => {
        if (
            startTimes[index - 1] !== undefined &&
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            startTime - startTimes[index - 1] <= 180
        ) {
            counter++;
            if (counter > 3) {
                counter = 0;
            }
        } else {
            counter = 0;
        }
        maxCounter.current = counter > maxCounter.current ? counter : maxCounter.current;
    });
    counter = 0;

    const displayTariffs = !!tariffs?.length;

    return (
        <Box
            className={classes.root}
        >
            <Box mb={displayTariffs ? 3 : 0}>
                <Block {...blockProps} />
            </Box>
            {displayTariffs && (
                <>
                    <Box
                        mb={3}
                        className={classes.contentWrapper}
                        style={{ paddingBottom: maxCounter.current ? `${maxCounter.current * 18}px` : null }}
                    >
                        {groups.map((group) => {
                            const groupStartTimes = getZonesStartTimes(group.zones);
                            return (
                                <Box
                                    className={classes.group}
                                    key={group.name}>
                                    <Box className={classes.groupZoneName}>
                                        {group.name}
                                    </Box>
                                    <Box
                                        className={classes.groupZonesWrapper}
                                    >
                                        {groupStartTimes.map((zone, index) => {
                                            return (
                                                <Box
                                                    key={zone.startTime}
                                                    className={classes.groupZone}
                                                    style={{
                                                        backgroundColor: palette[tariffNumberIndex[zone.tariffNumber]],
                                                        width: `${
                                                            calcZoneWidthPercent(
                                                                zone.startTime,
                                                                groupStartTimes[index - 1]?.startTime,
                                                                groupStartTimes[index + 1]?.startTime,
                                                            )
                                                        }%`
                                                    }}
                                                />
                                            );
                                        })}
                                    </Box>
                                </Box>
                            );
                        })}
                        <Box className={classes.groupZonesWrapper}>
                            {startTimes.map((startTime, index) => {
                                if (
                                    startTimes[index - 1] !== undefined &&
                                    startTime - startTimes[index - 1] <= 180
                                ) {
                                    counter++;
                                    if (counter > 3) {
                                        counter = 0;
                                    }
                                } else {
                                    counter = 0;
                                }
                                return (
                                    <Box
                                        key={startTime}
                                        className={classNames(classes.startTime, { [classes.startTimeFirst]: index === 0 })}
                                        style={{
                                            width: `${calcZoneWidthPercent(startTime, startTimes[index - 1], startTimes[index + 1])}%`
                                        }}>
                                        <Box
                                            className={classNames(classes.startTimeText, { [classes.startTimeTextFirst]: index === 0 })}
                                            style={{ paddingTop: counter ? `${6 + (counter * 20)}px` : null }}
                                        >
                                            {minutesToTimeParser(startTime)}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                        <Box className={classes.linesWrapper}>
                            {startTimes.map((startTime, index) => (
                                <Box
                                    key={startTime}
                                    className={classNames(classes.linesBlock, { [classes.linesBlockFirst]: index === 0 })}
                                    style={{
                                        width: `${calcZoneWidthPercent(startTime, startTimes[index - 1], startTimes[index + 1])}%`
                                    }} />
                            ))}
                        </Box>
                    </Box>
                    <Box mt='auto'>
                        {tariffs.map((tariffNumber) => (
                            <Box key={tariffNumber}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item>
                                        <Box
                                            className={classes.legendBlock}
                                            style={{ backgroundColor: palette[tariffNumberIndex[tariffNumber]] }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        Тариф {tariffNumber}
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Box>
                </>
            )}
        </Box>
    );
};
