import { ElectricityConsumptionReportData } from '../../../../../common/model/meter/reports/electricityConsumptionReport';
import { roundReadings } from '../../../../../common/shared/utils';

export const transformElectricityConsumptionReportData = (source: ElectricityConsumptionReportData): ElectricityConsumptionReportData => {
    return {
        ...source,
        cumulativeActiveEnergyImpStart: +roundReadings(source.cumulativeActiveEnergyImpStart),
        cumulativeActiveEnergyImpEnd: +roundReadings(source.cumulativeActiveEnergyImpEnd),
        cumulativeActiveEnergyTZ1Start: +roundReadings(source.cumulativeActiveEnergyTZ1Start),
        cumulativeActiveEnergyTZ1End: +roundReadings(source.cumulativeActiveEnergyTZ1End),
        cumulativeActiveEnergyTZ2Start: +roundReadings(source.cumulativeActiveEnergyTZ2Start),
        cumulativeActiveEnergyTZ2End: +roundReadings(source.cumulativeActiveEnergyTZ2End),
        cumulativeActiveEnergyTZ3Start: +roundReadings(source.cumulativeActiveEnergyTZ3Start),
        cumulativeActiveEnergyTZ3End: +roundReadings(source.cumulativeActiveEnergyTZ3End),
        cumulativeActiveEnergyTZ1Diff: +roundReadings(source.cumulativeActiveEnergyTZ1Diff),
        cumulativeActiveEnergyTZ2Diff: +roundReadings(source.cumulativeActiveEnergyTZ2Diff),
        cumulativeActiveEnergyTZ3Diff: +roundReadings(source.cumulativeActiveEnergyTZ3Diff),
        cumulativeActiveEnergyTzSum: +roundReadings(source.cumulativeActiveEnergyTzSum),
    };
};
