import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import { ElectricityConsumptionBillingReportQuery, ElectricityConsumptionBillingReportResponse } from '../../../../../../common/model/meter/reports/electricityConsumptionBillingReport';
import { AppThunk } from '../../index';
import { electricityConsumptionBillingReportSlice } from '../../../reducers/reports/electricityConsumptionBillingReport';

export const getElectricityConsumptionBillingReport = (params: ElectricityConsumptionBillingReportQuery): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_reports_electricity_consumption_billing, {}, params);
    const data = await httpService.get<ElectricityConsumptionBillingReportResponse>(route);
    dispatch(electricityConsumptionBillingReportSlice.actions.setReport(data));
};

export const resetElectricityConsumptionBillingReport = (): AppThunk => (dispatch) => {
    dispatch(electricityConsumptionBillingReportSlice.actions.setReport(null));
};
