import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { AddressViewModel } from '../../../../../common/model/meter/addressViewModel/addressViewModel';
import { MeterAddressUpdater } from '../../../../../common/model/meter/meter/meterAddressUpdater';
import { BaseDispatch } from '../../../../../redux/actions';
import { findAddressSuggestions } from '../../../../../redux/actions/addresses';
import { updateMeterAddress } from '../../../../../redux/actions/meterUpdateAttributes';
import { meterSelector } from '../../../../../redux/selectors/meters';
import { useAddressAutocomplete } from '../../../../shared/components/autocomplete/hooks/useAddressAutocomplete';
import { useCreateAddress } from '../../../../shared/components/createAddress/useCreateAddress';
import { defaults } from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import yup from '../../../../shared/utils/yup';
import { actionQueryParam } from '../model';
import { StateProps } from './model';
import { View } from './view';


const addressValidationScheme = yup.object({
    addressId: yup.string().required(),
});

export const ChangeAddressUpdateForm = () => {

    const { meterId } = useParams<{ meterId: string }>();
    const queryParams = useQueryParams();
    const meter = useSelector(meterSelector);
    const dispatch = useDispatch<BaseDispatch>();
    const addressAutocomplete = useAddressAutocomplete();

    // TODO При открытии формы сразу запрашиваем все адреса, после интеграции адресов с биллингом необходимо убрать
    useEffect(() => {
        dispatch(findAddressSuggestions('', meter?.regionId));
    }, [meter]);

    const { hasAccess } = useAccessRules();

    const defaultValues: MeterAddressUpdater = {
        addressId: meter.addressId ?? defaults.defaultRegionId,
        addressSpecifier: meter.addressSpecifier ?? '',
        installationSite: meter.installationSite ?? '',
    };

    const onCloseModal = () => {
        queryParams.delete(actionQueryParam);
        dispatch(push({
            search: queryParams.toString()
        }));
    };

    const onSubmit: SubmitHandler<MeterAddressUpdater> = async (values) => {
        await dispatch(updateMeterAddress(meterId, values));
        onCloseModal();
    };

    const resolver = useYupValidationResolver<MeterAddressUpdater>(addressValidationScheme);

    const form = useForm<MeterAddressUpdater>({
        defaultValues,
        resolver
    });

    const useCreateAddressProps = useCreateAddress((address: AddressViewModel) => {
        addressAutocomplete.onInputChange(null, address.address, 'input');
        if (address?.id) {
            form.setValue('addressId', address.id);
        }
    });

    const props: StateProps = {
        ...useCreateAddressProps,
        form,
        onCancel: onCloseModal,
        addressAutocomplete,
        onSubmit,
        hasAccess: hasAccess(AccessRule.CanUpdateMeterAddress),
    };

    return View(props);
};
