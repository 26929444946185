import { httpService } from '../../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../../app/shared/pipes';
import { appUrls } from '../../../../../common/appUrls';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { RoleViewModel } from '../../../../../common/model/access/role/roleViewModel';
import { activeRoleAccessSlice } from '../../../../reducers/access/roles/active';
import { AppThunk, BaseDispatch } from '../../../index';

export const setActiveAccessRole = (role: RoleViewModel): AppThunk => (dispatch): void => {
    dispatch(activeRoleAccessSlice.actions.setActiveRole(role));
};


export const setActiveAccessRoleRules = (rules: AccessRule[]): AppThunk => (dispatch): void => {
    dispatch(activeRoleAccessSlice.actions.setAccessRules(rules));
};

export const getActiveAccessRoleRules = (roleId: string): AppThunk => async (dispatch: BaseDispatch): Promise<void> => {
    const rules = await httpService.get<AccessRule[]>(getApiRoute(appUrls.api_get_access_roles_access_rules, { roleId }));
    await dispatch(setActiveAccessRoleRules(rules));
};

export const updateActiveAccessRoleRules = (
    roleId: string,
    payload: AccessRule[]
): AppThunk => async (): Promise<void> => {
    await httpService.put<AccessRule[]>(
        getApiRoute(appUrls.api_put_access_roles_access_rules, { roleId }),
        payload,
    );
};

export const resetActiveRoleState = (): AppThunk => (dispatch): void => {
    dispatch(activeRoleAccessSlice.actions.setAccessRules([]));
    dispatch(activeRoleAccessSlice.actions.setActiveRole(null));
};
