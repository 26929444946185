import { makeStyles, Theme } from '@material-ui/core';
import { setColumnWidth } from '../../../../shared/pipes/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: 0,
    },
    noDataText: {
        ...theme.typography.body,
        color: theme.palette.text.hint,
    },
    tableWrapper: {
        '& .MuiPaper-root': {
            boxShadow: 'none',
        },
    },
    tableCols: {
        '&.col-rowNumber': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('70px'),
        },
        '&.col-areaName': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('140px'),
        },
        '&.col-address': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('240px'),
        },
        '&.col-meterModel': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('80px'),
        },
        '&.col-meterSerialNum': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('100px'),
        },
        '&.col-meterLabelTypeId': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('236px'),
        },
        '&.col-createdAt': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('148px'),
        },
        '&.col-meterValue': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('140px'),
        },
        '&.col-systemValue': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('140px'),
        },
    },
    reportDateKey: {
        ...theme.typography.caption,
        color: theme.palette.text.hint,
    },
    reportDateValue: {
        ...theme.typography.body,
        color: theme.palette.text.primary,
    },
}));
