import { httpService } from '../../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../../common/appUrls';
import { RoleViewModel } from '../../../../../common/model/access/role/roleViewModel';
import { rolesListAccessSlice } from '../../../../reducers/access/roles/list';
import { AppThunk, BaseDispatch } from '../../../index';

export const setAccessRolesList = (roles: RoleViewModel[]): AppThunk => async (dispatch): Promise<void> => {
    await dispatch(rolesListAccessSlice.actions.setRolesList(roles));
};

export const getAccessRolesList = (): AppThunk => async (dispatch: BaseDispatch): Promise<void> => {
    const roles = await httpService.get<RoleViewModel[]>(appUrls.api_get_access_roles);
    await dispatch(setAccessRolesList(roles));
};
