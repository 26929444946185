import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../redux/actions';
import { Routes } from '../constants';
import { getRoute, RouterParams } from '../pipes';
import { useQueryParams } from './useQueryParams';

export interface TabsHookConfig<T extends string | number> {
    route: Routes;
    defaultTab: T;
    values: { [key in string | number]: T };
    queryKey?: string;
    params?: RouterParams;
    defaultRedirectRoute?: Routes;
}

export const useTabs = <T extends string | number>(config: TabsHookConfig<T>) => {
    const { values, defaultTab, route, params = {}, queryKey = 'tab' } = config;
    const dispatch = useDispatch<BaseDispatch>();

    const queryParams = useQueryParams();
    const activeTab = queryParams.get(queryKey) as T;

    useEffect(() => {
        if (!activeTab || !Object.values(values).includes(activeTab)) {
            queryParams.set(queryKey, defaultTab.toString());
            const qParams = Object.fromEntries(queryParams.entries());
            const defaultRoute = getRoute(
                route,
                params,
                qParams,
            );
            dispatch(push(defaultRoute));
        }
    });

    const setTab = (tab: T) => {
        queryParams.set(queryKey, tab.toString());
        const qParams = Object.fromEntries(queryParams.entries());
        dispatch(push(getRoute(
            route,
            params,
            qParams,
        )));
    };

    return {
        activeTab,
        setTab,
    };
};
