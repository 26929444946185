/* eslint @typescript-eslint/no-magic-numbers: OFF */

import { isArray } from 'util';

export const numDeclinationPipe = (number: number, titles: string[] | string): string => {
    const cases = [2, 0, 1, 1, 1, 2];
    const normalizedTitles = isArray(titles)
        ? titles
        : titles.split(';');

    return normalizedTitles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};
