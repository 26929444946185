import { MeterActivationOrderDetailsViewModel } from '../../../../../common/model/meter/meterActivationOrder/meterActivationOrderDetailsViewModel';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { MeterActivationOrderCreator } from '../../../../../common/model/meter/meterActivationOrder/meterActivationOrderCreator';
import { WithBreadcrumbs } from '../../../../shared/components/breadcrumbs/model';

export enum MeterActivationOrderMode {
    Create = 'create',
    Update = 'update',
}

export interface Model {
    meterActivationOrder: MeterActivationOrderDetailsViewModel;
}

export interface Actions {
    onBackToList: () => void;
    onDeleteOrder: () => void;
    onReprocess: () => void;
    onCloseExecuteModal: () => void;
    onFormDirtyChange: (isDirty: boolean) => void;
    onSubmit: SubmitHandler<MeterActivationOrderCreator>;
    onOpenPUPage: () => void;
    onExecuteActivationOrder: (values: MeterActivationOrderCreator, runInitialDiagnostic: boolean) => void;
}

export interface StateProps extends Model, Actions, WithBreadcrumbs {
    mode: MeterActivationOrderMode;
    loading: boolean;
    isFormDirty: boolean;
    showExecuteModal: boolean;
    submittedMeterActivationOrder: MeterActivationOrderCreator;
    canReadMeter: boolean;
    canReadMeterActivationOrderDetails: boolean;
    canDeleteMeterActivationOrder: boolean;
    canReprocessMeterActivationOrder: boolean;
}
