import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MeterReadingInstantOrderStatus,
    meterReadingInstantOrderStatusDictionary
} from '../../../../../common/model/meter/meterReadingInstantOrder/meterReaddingInstantOrderStatus';
import { cosToAngleDeg, signMultiplier } from '../../../../../common/shared/utils/math';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { createMeterReadingInstantOrder, getMeterReadingInstantOrder } from '../../../../../redux/actions/meterReading';
import {
    getMeterReadingInstantProfile,
    resetMeterReadingInstantOrder,
    resetMeterReadingInstantProfile
} from '../../../../../redux/actions/meterReading/instantProfiles';
import { resourcesMeterModelsSelector } from '../../../../../redux/selectors';
import { meterReadingInstantOrderSelector, meterReadingInstantProfileSelector } from '../../../../../redux/selectors/meterReading';
import { useInterval } from '../../../../shared/hooks/useInterval';
import { getErrorMessage } from '../../../../shared/utils/error';
import { getInstantProfileDataColumns, getPhaseVectorDiagramChartVectors } from './helpers';
import { Props } from './model';
import { View } from './view';

const refreshDelay = 5000;
const pi2Degree = 360;

export const MeterReadingInstant: React.FC<Props> = (props) => {

    const dispatch = useDispatch<BaseDispatch>();

    const [prevOrderStatus, setPrevOrderStatus] = useState(null);

    const { meter } = props;
    const { id: meterId } = meter;

    const meterModels = useSelector(resourcesMeterModelsSelector());

    const order = useSelector(meterReadingInstantOrderSelector);
    const profile = useSelector(meterReadingInstantProfileSelector);

    const fetchProfile = async () => {
        try {
            await dispatch(getMeterReadingInstantProfile(meterId));
        } catch {
            // do nothing
        }
    };

    const fetchOrder = async () => {
        try {
            await dispatch(getMeterReadingInstantOrder(meterId));
        } catch {
            // do nothing
        }
    };

    useEffect(() => {
        if (order) {
            setPrevOrderStatus(order.status);
            if (prevOrderStatus && prevOrderStatus !== order.status) {
                if (order.status === MeterReadingInstantOrderStatus.Success) {
                    dispatch(createSnackbar({
                        type: 'white',
                        message: 'Считывание завершено.',
                    }));
                } else if (order.status !== MeterReadingInstantOrderStatus.Created) {
                    dispatch(createSnackbar({
                        type: 'red',
                        message: `Считывание завершено с ошибкой: ${meterReadingInstantOrderStatusDictionary[order.status]}`,
                    }));
                }
            }
        }
    }, [order]);


    useEffect(() => {
        fetchOrder();
        fetchProfile();
        return () => {
            dispatch(resetMeterReadingInstantOrder());
            dispatch(resetMeterReadingInstantProfile());
        };
    }, []);

    const onClickSubmit = async () => {
        try {
            await dispatch(createMeterReadingInstantOrder(meterId));
            dispatch(createSnackbar({
                type: 'white',
                message: 'Считывание запущено. Процесс займет некоторое время.',
            }));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
    };

    const onRefresh = async () => {
        await fetchOrder();
        await fetchProfile();
    };

    useInterval(onRefresh, refreshDelay);

    const meterModel = meterModels.find(item => item.id === meter.meterModelId);

    const isCreated = order?.status === MeterReadingInstantOrderStatus.Created;

    const [data, columns] = getInstantProfileDataColumns(meterModel, profile);

    const fullPowerVectorChartAngle = profile?.cos
        ? signMultiplier(profile.signedReactivePower) > 0
            ? cosToAngleDeg(profile.cos)
            : pi2Degree - cosToAngleDeg(profile.cos)
        : 0;

    return View({
        ...props,
        data,
        order,
        columns,
        profile,
        isCreated,
        onClickSubmit,
        fullPowerVectorChartAngle,
        phaseVectorDiagramChartVectors: getPhaseVectorDiagramChartVectors(meterModel, profile),
    });
};
