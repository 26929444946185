import { MeterGisTaskCreator } from '../../../../common/model/meter/meterGisTask/meterGisTaskCreator';
import { MeterGisTaskViewModel } from '../../../../common/model/meter/meterGisTask/meterGisTaskViewModel';
import { View } from './view';
import { MeterGisTasksQuery } from '../../../../common/model/meter/meter/meterGisTasks';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import {
    meterGisTaskIsFilterVisible,
    meterGisTaskLoading,
    meterGisTasksAvailableSelector,
    meterGisTasksTotalSelector,
} from '../../../../redux/selectors/meterGisTasks';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import {
    archiveGroupMeterGisTasks,
    closeGroupMeterGisTasks,
    createMeterGisTask,
    getMeterGisTasks,
    syncTasksWithGis
} from '../../../../redux/actions/meterGisTasks';
import { ISort } from '../../../../common/sort';
import { usePagination } from '../../../shared/components/pagination';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { GisTaskProps, StateProps } from './model';
import { meterGisTasksSlice } from '../../../../redux/reducers/meterGisTasks/meterGisTasks';
import { Checkbox } from '../../../shared/components/checkbox';
import { push } from 'connected-react-router';
import { getRoute } from '../../../shared/pipes';
import { MeterTabs, Routes } from '../../../shared/constants';
import { MeterGisTasksStatus } from '../../../../common/model/enum/meterGisTasks';
import { formatDate } from '../../../shared/utils/dates';
import { Box, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getAllUsers } from '../../../../redux/actions/user/list';
import { transformDatesParams } from '../../../shared/utils/transformers/dates';
import { usersAllListSelector } from '../../../../redux/selectors';
import { Typography } from '../../../shared/components/typography';
import { getUserName } from '../../../shared/utils/userName';
import { Button } from '../../../shared/components/button';
import { Tooltip } from '../../../shared/components/tooltip';
import { TaskInfoCard } from './taskInfoCard';
import { StatusIcon } from '../../../shared/components/statusIcon';
import _ from 'lodash';

const initialFilterValues: MeterGisTasksQuery = {
    search: '',
    status: null,
    foreignId: null,
    dateFrom: null,
    dateTo: null,
    createdBy: undefined,
};

export const GisTasks = (entryProps: GisTaskProps) => {
    const [isCloseGroupAvailable, setIsCloseGroupAvailable] = useState<boolean>(false);
    const [isArchiveGroupAvailable, setIsArchiveGroupAvailable] = useState<boolean>(false);
    const theme = useTheme();
    const dispatch = useDispatch<BaseDispatch>();
    const loading = useSelector(meterGisTaskLoading);
    const isFilterVisible = useSelector(meterGisTaskIsFilterVisible);
    const users = useSelector(usersAllListSelector);

    const data = useSelector(meterGisTasksAvailableSelector);
    const dataTotal = useSelector(meterGisTasksTotalSelector);

    const userById = useMemo(() => {
        return _.keyBy(users, 'id');
    }, [users]);

    const getInitialValues = () => {
        if (entryProps.foreignId) {
            return {
                ...initialFilterValues,
                foreignId: entryProps.foreignId,
            };
        }
        return initialFilterValues;
    };

    const [filterValues, setFilterValues] = useSyncQueryParams<MeterGisTasksQuery>(getInitialValues());
    const [isCreateTaskModalOpened, setIsCreateTaskModalOpened] = useState<boolean>(false);

    const fetch = async (sort: ISort, dataFilterParams: MeterGisTasksQuery = filterValues) => {
        await dispatch(getMeterGisTasks({
            ...transformDatesParams({
                ...dataFilterParams,
            }),
        }, sort));
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 10,
        orderBy: null,
        orderDirection: null,
    }, fetch);

    const refreshTableData = (dataFilterParams: MeterGisTasksQuery) => {
        onFetch(pagination, dataFilterParams);
    };

    useEffect(() => {
        refreshTableData(filterValues);
        dispatch(getAllUsers());
    }, []);

    const onClickNewTask = () => {
        setIsCreateTaskModalOpened(true);
    };

    const onCloseCreateTaskModal = () => {
        setIsCreateTaskModalOpened(false);
    };

    const onFilterSubmit: SubmitHandler<MeterGisTasksQuery> = (values) => {
        setFilterValues(values);
        refreshTableData(values);
    };

    const onCreateSubmit: SubmitHandler<MeterGisTaskCreator> = (values) => {
        dispatch(createMeterGisTask(values));
        onCloseCreateTaskModal();
    };

    useMemo(() => {
        const selectedItems = data?.filter(item => item.selected);

        if (selectedItems.length > 0) {
            setIsCloseGroupAvailable(true);
            setIsArchiveGroupAvailable(true);
            if (selectedItems.find(item => item.status === MeterGisTasksStatus.closed)) {
                setIsCloseGroupAvailable(false);
            }
            if (selectedItems.find(item => item.status === MeterGisTasksStatus.open)) {
                setIsArchiveGroupAvailable(false);
            }
        } else {
            setIsCloseGroupAvailable(false);
            setIsArchiveGroupAvailable(false);
        }
    }, [data]);

    const findSelected = () => {
        return data?.filter(element => element.selected).map(element => element.id) || [];
    };

    const resetSelected = () => {
        dispatch(meterGisTasksSlice.actions.resetSelected());
    };

    const onCloseGroup = () => {
        dispatch(closeGroupMeterGisTasks({ ids: findSelected() }));
        resetSelected();
    };

    const onArchiveGroup = () => {
        dispatch(archiveGroupMeterGisTasks({ ids: findSelected() }));
        resetSelected();
    };

    const onSyncGroup = async () => {
        await dispatch(syncTasksWithGis({ syncIds: findSelected() }));
        resetSelected();
        refreshTableData(filterValues);
    };


    const isFilteredBySearch = !!filterValues.search;
    const isFilteredByStatus = !!filterValues.status;
    const isFilteredByForeignId = !!filterValues.foreignId;
    const isFilteredByDateFrom = !!filterValues.dateFrom;
    const isFilteredByDateTo = !!filterValues.dateTo;
    const isFilteredByCreatedBy = !!filterValues.createdBy;

    const appliedFiltersCount = [
        isFilteredByCreatedBy,
        isFilteredByDateFrom,
        isFilteredByDateTo,
        isFilteredByForeignId,
        isFilteredBySearch,
        isFilteredByStatus,
    ].filter(item => !!item).length;

    const props: StateProps = {
        appliedFiltersCount,
        foreignId: entryProps.foreignId,
        address: entryProps.address,
        pagination,
        setOrder,
        setPage,
        orderParams,
        dataTotal,
        data: entryProps.foreignId ? data?.filter(task => task.foreignId === entryProps.foreignId) : data,
        loading,
        isFilterVisible,
        filterValues,
        initialFilterValues,
        isCreateTaskModalOpened,
        onCreateSubmit,
        isCloseGroupAvailable,
        isArchiveGroupAvailable,
        isSyncAvailable: loading || !!data.find(element => element.selected),
        onCloseGroup,
        onArchiveGroup,
        onSyncGroup,
        onFiltersOpen: () => {
            dispatch(meterGisTasksSlice.actions.setFilterVisibility(true));
        },
        onFiltersClose: () => {
            dispatch(meterGisTasksSlice.actions.setFilterVisibility(false));
        },
        onFilterSubmit,
        onClickNewTask,
        onCloseCreateTaskModal,
        columnDefinition: [
            {
                title: '',
                field: 'selected',
                cellClassName: 'cell-checkbox',
                sorting: false,
                render: (row: MeterGisTaskViewModel) => {
                    return <Checkbox
                        checked={row.selected}
                        onChange={() => dispatch(meterGisTasksSlice.actions.updateSelectedOneElement({ ...row, selected: !row.selected }))}
                        disabled={row.status === MeterGisTasksStatus.archived}
                    />;
                },
            },
            {
                title: 'Статус',
                field: 'status',
                sorting: false,
                cellClassName: 'cell-status',
                render: (row: MeterGisTaskViewModel) => {
                    return (
                        <Box marginTop='5px'>
                            <StatusIcon status={row.status} />
                        </Box>);
                }
            },
            {
                title: 'ИД',
                field: 'number',
                sorting: true,
                cellClassName: 'cell-id',
            },
            {
                title: 'Дата создания',
                field: 'createdAt',
                sorting: true,
                cellClassName: 'cell-createdAt',
                render: (dataItem: MeterGisTaskViewModel) => {
                    return (formatDate(dataItem.createdAt));
                },
            },
            {
                title: 'Название',
                field: 'title',
                sorting: false,
                cellClassName: 'cell-title',
                render: (row: MeterGisTaskViewModel) => {
                    return row.title ? (
                        <Tooltip
                            title={<TaskInfoCard
                                task={row}
                                createdBy={userById[row.createdBy]}
                                updatedBy={userById[row.updatedBy]}
                            />}
                            placement='top-start'
                            minWidth='460px'
                            isLight
                            arrow
                        >
                            <Typography variant='body'>{row.title}</Typography>
                        </Tooltip>
                    ) : null;
                }
            },
            {
                title: 'Заводской номер',
                field: 'meterSerialNum',
                sorting: false,
                hidden: Boolean(entryProps.foreignId), // hidden this column if in meter page
                cellClassName: 'cell-meterSerialNum',
                render: (dataItem: MeterGisTaskViewModel) => {
                    const { meterId, meterSerialNum, foreignId } = dataItem;
                    const link = meterId
                        ? getRoute(Routes.meter, { meterId }, { tab: MeterTabs.Information })
                        :
                        foreignId
                            ? getRoute(Routes.meterSearch, { }, {
                                asc: true,
                                count: 20,
                                offset: 0,
                                foreignId,
                            })
                            : null;
                    return (
                        link
                            ?
                            <Link
                                style={{ color: theme.palette.primary.light }}
                                to={link}
                            >
                                {`${meterSerialNum} >`}
                            </Link>
                            : <Typography variant='caption'>{meterSerialNum}</Typography>
                    );
                }
            },
            {
                title: 'Адрес',
                field: 'address',
                sorting: false,
                cellClassName: 'cell-address',
            },
            {
                title: 'Кем создан',
                field: 'createdBy',
                sorting: false,
                render: (row) => {
                    const createdBy = users?.find(user => user.id === row.createdBy);
                    return (
                        <Box display='flex' flexDirection='column' position='relative'>
                            <Typography variant='body'>
                                { createdBy && getUserName(createdBy.lastName, createdBy.name, createdBy.patronymic)}
                            </Typography>
                            <Typography variant='caption'>{createdBy && createdBy.email}</Typography>

                            <Button
                                variant="primary"
                                onClick={() => dispatch(push(getRoute(Routes.meterGisTaskEdit, { taskId: row.id })))}
                            >
                                        Перейти
                            </Button>
                        </Box>
                    );
                }
            },
        ],
    };

    return View(props);
};
