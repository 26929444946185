import { Box, Container, makeStyles, Theme } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SearchIcon from '@material-ui/icons/Search';
import { Tree } from 'app/shared/components/tree';
import classNames from 'classnames';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { renderRoutes } from 'react-router-config';
import { GlobalKeyboardListener } from '../../shared/components/globalKeyboardListener';
import { LeftMenu } from '../../shared/components/leftMenu';
import { ModalNotifier } from '../../shared/components/modalNotifier';
import { Profile } from '../../shared/components/profile';
import { ResourceProvider } from '../../shared/components/resourceProvider';
import { Search } from '../../shared/components/search';
import { Tooltip } from '../../shared/components/tooltip';
import { TopBar } from '../../shared/components/topBar';
import { TopMenu } from '../../shared/components/topMenu';
import { Notifier } from '../snackbar/Notifier';
import { StateProps } from './model';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingLeft: '100px',
        paddingTop: '16px',
        paddingBottom: '70px',
    },
    success: {
        backgroundColor: theme.palette.system.success,
        color: theme.palette.text.primary,
    },
    iconSuccess: {
        fill: theme.palette.text.primary,
    },
    error: {
        backgroundColor: theme.palette.system.error,
        color: theme.palette.neutral.white,
    },
    iconError: {
        fill: theme.palette.neutral.white,
    },
    warning: {
        backgroundColor: theme.palette.neutral.dark,
        color: theme.palette.neutral.white,
    },
    iconWarning: {
        fill: theme.palette.neutral.white,
    },
    info: {
        backgroundColor: theme.palette.neutral.white,
        color: theme.palette.text.secondary,
    },
    iconInfo: {
        fill: theme.palette.text.secondary,
    },
    icon: {
        marginRight: 8,
    },
    snackbar: {
        '& .MuiSnackbarContent-root': {
            height: 40,
        },
        '& .MuiSnackbarContent-message': {
            height: '100%',
            display: 'flex',
        },
        '& .MuiSnackbarContent-action .MuiSvgIcon-root': {
            cursor: 'pointer',
        },
    },
    moreIcon: {
        marginLeft: 13,
        width: '24px',
        height: '24px',
        '&:hover': {
            color: 'white',
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: theme.palette.text.secondary,
            width: '24px',
            height: '24px',
        }
    },
    blockAdvancedSearch: {
        position: 'absolute',
        top: 128,
        right: 15,
        color: '#F5F5F5',
        fill: '#F5F5F5',
        padding: '4px 13px',
        backgroundColor: '#343434',
        width: 185,
        height: 40,
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 2px 24px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#212121',
        }
    },
    tooltip: {
        marginLeft: 20,
    },
    leftMenuContainer: {
        padding: '8px 16px 8px 16px',
        display: 'flex',
        backgroundColor: '#616161',
        justifyContent: 'space-around',
        flexDirection: 'column',
        width: 352,
    },
    metersTreeView: {
        flex: '0 1 auto',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'inherit',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            backgroundColor: theme.palette.neutral.dark,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.neutral.main,
            borderRadius: '100px'
        }
    },
}));

const isExtendedSearchAvailable = false;

export const View = ({
    childRoutes,
    user,
    onProfileClick,
    activeMenu,
    onMenuItemClick,
    leftMenuCollapsed,
    onChangeLeftMenuCollapsed,
    onLeftMenuTreeItemClick,
    leftMenuTreeData,
    isHiddenAdvancedSearch,
    onMoreIconClick,
    outsideClickRef,
    onClickAdvancedSearch,
    searchProps,
    snackbarClose,
}: StateProps) => {

    const classes = useStyles();

    return (
        <ResourceProvider>
            <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.snackbar}
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantWarning: classes.warning,
                    variantInfo: classes.info,
                }}
                iconVariant={{
                    success: <CheckIcon className={classNames(classes.icon, classes.iconSuccess)} />,
                    warning: <InfoOutlined className={classNames(classes.icon, classes.iconWarning)} />,
                    error: <InfoOutlined className={classNames(classes.icon, classes.iconError)} />,
                    info: <InfoOutlined className={classNames(classes.icon, classes.iconInfo)} />,
                }}
                action={(key: string) => <CloseIcon onClick={() => snackbarClose(key)} />}
            >
                <>
                    <GlobalKeyboardListener />
                    <Notifier />
                    <TopBar>
                        <TopMenu activeMenu={activeMenu} onMenuItemClick={onMenuItemClick} />
                        <Profile user={user} onProfileClick={onProfileClick} />
                    </TopBar>
                    <LeftMenu title='Список приборов учета' collapsed={leftMenuCollapsed} onChangeMenuCollapsed={onChangeLeftMenuCollapsed}>
                        <div className={classes.leftMenuContainer}>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <Box width='100%'>
                                    <Search {...searchProps} />
                                </Box>
                                {isExtendedSearchAvailable && (
                                    <>
                                        <Tooltip text='Дополнительно' placement="bottom-start">
                                            <MoreHorizIcon className={classes.moreIcon} onClick={onMoreIconClick} />
                                        </Tooltip>
                                        {
                                            isHiddenAdvancedSearch && <div
                                                onClick={onClickAdvancedSearch}
                                                ref={outsideClickRef}
                                                className={classes.blockAdvancedSearch}
                                            >
                                                <SearchIcon />
                                                <div>
                                                    <span>Расширенный поиск</span>
                                                </div>
                                            </div>
                                        }
                                    </>
                                )}
                            </Box>
                        </div>
                        <Box className={classes.metersTreeView} padding='12px' paddingLeft='24px'>
                            <Tree
                                items={leftMenuTreeData}
                                handleClickItem={(elem) => onLeftMenuTreeItemClick(elem)}
                                isBlackMenu />
                        </Box>
                    </LeftMenu>
                    <Container maxWidth={false} classes={{ root: classes.root }}>
                        {renderRoutes(childRoutes)}
                    </Container>
                    <ModalNotifier />
                </>
            </SnackbarProvider>
        </ResourceProvider>
    );
};
