import React from 'react';
import { Box } from '@material-ui/core';
import { Heading } from '../heading';
import { CreateAddressForm } from './formAddress';
import { IStateProps } from './model';
import { Modal } from '../modal';

export const View: React.FC<IStateProps> = (props) => {
    const {
        onSubmit,
        onClose,
    } = props;
    return (
        <>
            <Modal
                alignDialog='center'
                onHide={onClose}
                width='600px'>
                <Box display='flex' justifyContent='center' mb={2}>
                    <Heading heading='Добавление адреса' />
                </Box>
                <Box mt={2}>
                    <CreateAddressForm onCancelCity={onClose} onSubmit={onSubmit} />
                </Box>
            </Modal>
        </>
    );
};
