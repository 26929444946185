import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMeterLabelTypes } from 'redux/actions/meterLabelType';
import { View } from './view';

export const MeterHealth = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMeterLabelTypes());
    }, []);

    return View();
};
