import React from 'react';
import { MeterDictionariesNames } from '../../../../../common/model/meter/meterDictionaries/meterDictionariesNames';
import { CreateTaskViewProps } from './modal';
import { FormProvider } from 'react-hook-form';
import { Grid, makeStyles } from '@material-ui/core';
import { HookField } from '../../../../shared/components/hookField';
import { Typography } from '../../../../shared/components/typography';
import { Button } from '../../../../shared/components/button';
import { Forbidden } from '../../../../shared/components/forbidden';

const createTaskFormStyles = makeStyles(() => ({
    inputLabel: {
        textAlign: 'right',
    },
    buttonContainer: {
        paddingTop: '16px',
    },
}));

export const View = ({
    form,
    onSubmit,
    meterAutocomplete,
    onCancel,
    hasAccess,
    foreignId,
}: CreateTaskViewProps) => {
    if (!hasAccess) {
        return <Forbidden />;
    }

    const classes = createTaskFormStyles();
    const { handleSubmit } = form;

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={3} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Название</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <HookField
                                    type='autoselectDictionaryInput'
                                    name='title'
                                    dictionaryName={MeterDictionariesNames.GisTasks}
                                    placeholder='Введите название'
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={3} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Примечание</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <HookField
                                    type='textarea'
                                    name='notes'
                                    label='Введите примечание'
                                    minWidth='279px'
                                    maxWidth='270px'
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={3} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Прибор учёта</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <HookField
                                    type='autocomplete'
                                    name='foreignId'
                                    label='Выберите ПУ'
                                    fullWidth
                                    disabled={!!foreignId}
                                    {...meterAutocomplete}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={3} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Адрес</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <HookField
                                    type='text'
                                    name='address'
                                    label='Адрес'
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                            <Grid item>
                                <Button variant='primary' type='submit' icon='check'>Создать инцидент</Button>
                            </Grid>
                            <Grid item>
                                <Button variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </form>
        </FormProvider>
    );
};
