
import * as React from 'react';
import { useEventListener } from '../../hooks/useEventListenner';

export const KeyCodes = {
    Enter: 13,
    Escape: 27,
    ArrowUp: 38,
    ArrowDown: 40,
    ArrowLeft: 37,
    ArrowRight: 39,
    Tab: 9,
    Backspace: 8,
    Delete: 46,
};

// чтобы событие не пробрасывалось по стеку возвращаем false
export type KeyboardListener = (event: React.KeyboardEvent) => Promise<boolean | void>;

export type GlobalKeyboardEventTypes = 'keydown' | 'keypress' | 'keyup';

const listeners: Record<GlobalKeyboardEventTypes, KeyboardListener[]> = { keydown: [], keypress: [], keyup: [] };

export const GlobalKeyboardListenerController = {
    addEventListener: (eventType: GlobalKeyboardEventTypes, listener: KeyboardListener) => {
        if (!listeners[eventType].includes(listener)) {
            listeners[eventType].push(listener);
        }
    },
    removeEventListener: (eventType: GlobalKeyboardEventTypes, listener: KeyboardListener) => {
        listeners[eventType] = listeners[eventType].filter((item) => item !== listener);
    }
};

export const GlobalKeyboardListener: React.FC<any> = () => {
    const eventListener = (event: React.KeyboardEvent) => {
        (async () => {
            if (Array.isArray(listeners[event.type])) {
                for (const listener of listeners[event.type].reverse()) {
                    const result = await listener(event);
                    if (result === false) {
                        break;
                    }
                }
            }
        })();
    };
    useEventListener('keyup', eventListener, document);
    useEventListener('keydown', eventListener, document);
    useEventListener('keypress', eventListener, document);
    return null;
};
