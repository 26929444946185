import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../shared/components/button';
import { Wrapper } from '../../../shared/components/wrapper';
import { Table } from '../../../shared/components/table';
import { setColumnWidth } from '../../../shared/pipes/styles';
import { DefaultNetworkOperatorForm } from './components/defaultNetworkOperatorForm';
import { DeleteNetworkOperatorForm } from './components/deleteNetworkOperatorForm';
import { NetworkOperatorForm } from './components/networkOperatorForm';
import { IStateProps } from './model';

const useStyles = makeStyles((theme) => ({
    defaultNetworkOperatorLabel: {
        ...theme.typography.caption,
        color: theme.palette.text.hint,
    },
    defaultNetworkOperatorValue: {
        ...theme.typography.caption,
        color: theme.palette.text.primary,
    },
    tableWrapper: {
        '& .col-id': {
            ...setColumnWidth('140px'),
        },
        '& .col-name': {
            ...setColumnWidth('300px'),
        },
        '& .col-server': {
            ...setColumnWidth('200px'),
        },
        '& .col-login': {
            ...setColumnWidth('160px'),
        },
        '& .col-password': {
            ...setColumnWidth('160px'),
        },
        '& .col-actions': {
        },
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const {
        data,
        columns,
        isDefaultFormOpen,
        defaultNetworkOperator,
        editFormNetworkOperator,
        deleteFormNetworkOperator,
        onClickNewNetworkOperator,
        onCancelEditFormNetworkOperator,
        onSubmitEditFormNetworkOperator,
        onDeleteNetworkOperator,
        onCancelDeleteNetworkOperator,
        onClickEditDefaultNetworkOperator,
        onCancelDefaultFormNetworkOperator,
        onSubmitDefaultFormNetworkOperator,
        isUserCanChangeSettings,
        isNetworkOperatorFormLoading,
        isDeleteNetworkOperatorLoading,
        isDefaultNetworkOperatorLoading,
    } = props;

    const classes = useStyles();

    const actions = (
        <>
            <Grid container justify='space-between' alignItems='center'>
                <Grid item>
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item>
                            <div className={classes.defaultNetworkOperatorLabel}>
                                По умолчанию для ПУ использовать
                            </div>
                        </Grid>
                        {defaultNetworkOperator && (
                            <Grid item>
                                <div className={classes.defaultNetworkOperatorValue}>
                                    <span>
                                        [{defaultNetworkOperator.id}]{' '}
                                    </span>
                                    <span>
                                        {defaultNetworkOperator.name}
                                    </span>
                                </div>
                            </Grid>
                        )}
                        <Grid item>
                            <Button
                                type='button'
                                variant='text'
                                onClick={onClickEditDefaultNetworkOperator}
                                disabled={!isUserCanChangeSettings}
                            >
                                Изменить
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        icon='add'
                        variant='primary'
                        onClick={onClickNewNetworkOperator}
                    >
                        Новая платформа
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    return (
        <>
            <Wrapper actions={actions}>
                <div className={classes.tableWrapper}>
                    <Table
                        title={null}
                        columns={columns}
                        data={data}
                        variant='white'
                        options={{
                            paging: 'disabled',
                            toolbar: false,
                        }}
                    />
                </div>
            </Wrapper>
            {editFormNetworkOperator && (
                <NetworkOperatorForm
                    networkOperator={editFormNetworkOperator}
                    onCancel={onCancelEditFormNetworkOperator}
                    onSubmit={onSubmitEditFormNetworkOperator}
                    isLoading={isNetworkOperatorFormLoading}
                />
            )}
            {deleteFormNetworkOperator && (
                <DeleteNetworkOperatorForm
                    onCancel={onCancelDeleteNetworkOperator}
                    onDelete={() => onDeleteNetworkOperator(deleteFormNetworkOperator)}
                    isLoading={isDeleteNetworkOperatorLoading}
                />
            )}
            {isDefaultFormOpen && (
                <DefaultNetworkOperatorForm
                    onCancel={onCancelDefaultFormNetworkOperator}
                    onSubmit={onSubmitDefaultFormNetworkOperator}
                    networkOperators={data}
                    defaultNetworkOperator={defaultNetworkOperator}
                    isLoading={isDefaultNetworkOperatorLoading}
                />
            )}
        </>
    );
};
