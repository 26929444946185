import { metersListSlice, MetersListState } from './list';
import { combineReducers } from 'redux';
import { meterDetailsSlice, MeterDetailsState } from './details';


export interface MetersState {
    list: MetersListState;
    details: MeterDetailsState;
}

export const meters = combineReducers<MetersState>({
    list: metersListSlice.reducer,
    details: meterDetailsSlice.reducer,
});
