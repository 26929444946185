import { Box, Grid, makeStyles } from '@material-ui/core';
import { Column } from '@softmedialab/materialui-table';
import React, { FC } from 'react';
import { DateFormats } from '../../../../common/constants/date';
import {
    meterChangePasswordOrderStatusDictionary
} from '../../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderStatus';
import {
    MeterChangePasswordOrderViewModel
} from '../../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderViewModel';
import {
    MeterChangePasswordRequestViewModel
} from '../../../../common/model/meter/meterChangePasswordRequest/meterChangePasswordRequestViewModel';
import { Button } from '../../../shared/components/button';
import { HookField } from '../../../shared/components/hookField';
import { Pagination } from '../../../shared/components/pagination';
import { Table } from '../../../shared/components/table';
import { TextElipsis } from '../../../shared/components/textElipsis';
import { MeterTabs, Routes } from '../../../shared/constants';
import { useWebDictionarySelectSuggestions } from '../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../shared/pipes';
import { setColumnWidth } from '../../../shared/pipes/styles';
import { formatDate } from '../../../shared/utils/dates';
import { MeterSerialNumView } from '../../reports/components/common/meterSerianNumView';
import { MeterChangePasswordRequestModal } from './meterChangePasswordRequestModal';
import { IStateProps } from './model';
import { Wrapper } from '../../../shared/components/wrapper';
import { FormProvider } from 'react-hook-form';

const useStyles = makeStyles(() => ({
    filterAction: {
        flex: 1
    },
    createAction: {
        marginLeft: 'auto'
    },
    tableRow: {
        '&:not(:hover) button': {
            visibility: 'hidden',
        },
    },
    requestsTableWrapper: {
        '& .cell-creationDate': {
            ...setColumnWidth('150px'),
        },
        '& .cell-regionId': {
            ...setColumnWidth('180px'),
        },
        '& .cell-groupName': {
            ...setColumnWidth('180px'),
        },
        '& .cell-userName': {
            ...setColumnWidth('140px'),
        },
        '& .cell-metersCount': {
            ...setColumnWidth('140px'),
        },
        '& .cell-tasksInProgress': {
            ...setColumnWidth('140px'),
        },
        '& .cell-tasksSuccess': {
            ...setColumnWidth('140px'),
        },
        '& .cell-tasksErrors': {
            ...setColumnWidth('140px'),
        },
        '& .cell-actions': {
            ...setColumnWidth('120px'),
        },
    },
    ordersTableWrapper: {
        '& .cell-meterModelId': {
            ...setColumnWidth('120px'),
        },
        '& .cell-meterSerialNumber': {
            ...setColumnWidth('160px'),
        },
        '& .cell-status': {
            ...setColumnWidth('200px'),
        },
        '& .cell-operationTime': {
            ...setColumnWidth('180px'),
        },
        '& .cell-creationDate': {
            ...setColumnWidth('212px'),
        },
        '& .cell-resultMessage': {
            ...setColumnWidth('220px'),
        },
    }
}));

export const View: FC<IStateProps> = (props) => {
    const {
        form,
        orders,
        requests,
        isOrdersLoading,
        isRequestsLoading,
        onClickRefresh,
        meterGroupAutocomplete,
        ordersPagination,
        requestsPagination,
        onClickSelectRequest,
        regionById,
        meterModelById,
        onSubmitCreateRequest,
        onClickCloseRequest,
        onClickCreateRequest,
        isCreateRequestFormOpen,
        isFormSubmitting
    } = props;

    const classes = useStyles();

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const filterForm = (
        <Grid container spacing={2}>
            <Grid item className={classes.filterAction}>
                <FormProvider {...form}>
                    <Grid container spacing={2}>
                        <Grid item lg={2}>
                            <HookField
                                fullWidth
                                name='dateFrom'
                                type='datepicker'
                                showTime={true}
                                label='Начало периода'
                                disabled={isRequestsLoading}
                            />
                        </Grid>
                        <Grid item lg={2}>
                            <HookField
                                fullWidth
                                name='dateTo'
                                type='datepicker'
                                showTime={true}
                                label='Конец периода'
                                disabled={isRequestsLoading}
                            />
                        </Grid>
                        <Grid item lg={2}>
                            <HookField
                                fullWidth
                                isClearable
                                name='regionId'
                                type='select'
                                label='Регион'
                                suggestions={regionSuggestions}
                                disabled={isRequestsLoading}
                            />
                        </Grid>
                        <Grid item lg={2}>
                            <HookField
                                type='autocomplete'
                                name='meterGroupId'
                                label='Группа ПУ'
                                {...meterGroupAutocomplete}
                                disabled={isRequestsLoading}
                                fullWidth />
                        </Grid>
                        <Grid item lg={2}>
                            <Button
                                variant='outline'
                                icon='refresh'
                                square
                                onClick={onClickRefresh}
                                disabled={isRequestsLoading}
                                isLoading={isRequestsLoading}
                            />
                        </Grid>
                    </Grid>
                </FormProvider>
            </Grid>
            <Grid item className={classes.createAction}>
                <Button
                    variant='primary'
                    onClick={onClickCreateRequest}
                >
                    Создать заявку
                </Button>
            </Grid>
        </Grid>
    );

    const requestsColumns: Column[] = [
        {
            title: 'Дата создания',
            field: 'creationDate',
            cellClassName: 'cell-creationDate',
            render: (record: MeterChangePasswordRequestViewModel) => {
                return formatDate(record.creationDate, DateFormats.dateFormat);
            }
        },
        {
            title: 'Регион',
            field: 'regionId',
            cellClassName: 'cell-regionId',
            render: (record: MeterChangePasswordRequestViewModel) => {
                return regionById[record.regionId]?.name ?? record.regionId;
            }
        },
        {
            title: 'Группа',
            field: 'groupName',
            cellClassName: 'cell-groupName',
        },
        {
            title: 'Пользователь',
            field: 'userName',
            cellClassName: 'cell-userName',
        },
        {
            title: 'Количество ПУ',
            field: 'metersCount',
            cellClassName: 'cell-metersCount',
        },
        {
            title: 'Кол-во задач в работе',
            field: 'tasksInProgress',
            cellClassName: 'cell-tasksInProgress',
        },
        {
            title: 'Кол-во успешно выполненных задач',
            field: 'tasksSuccess',
            cellClassName: 'cell-tasksSuccess',
        },
        {
            title: 'Ошибки смены пароля',
            field: 'tasksErrors',
            cellClassName: 'cell-tasksErrors',
        },
        {
            title: '',
            field: '',
            cellClassName: 'cell-actions',
            render: (record: MeterChangePasswordRequestViewModel) => {
                return (
                    <Button
                        variant='primary'
                        onClick={async () => {
                            await onClickSelectRequest(record);
                        }}
                    >
                        Подробнее
                    </Button>
                );
            }
        },
    ];

    const ordersColumns: Column[] = [
        {
            title: 'Тип ПУ',
            field: 'meterModelId',
            cellClassName: 'cell-meterModelId',
            render: (record: MeterChangePasswordOrderViewModel) => {
                return meterModelById[record.meterModelId]?.shortName ?? record.meterModelId ?? record.meterId;
            }
        },
        {
            title: 'Заводской номер',
            field: 'meterSerialNumber',
            cellClassName: 'cell-meterSerialNumber',
            render: (record: MeterChangePasswordOrderViewModel) => {
                const route = getRoute(Routes.meter, { meterId: record.meterId }, { tab: MeterTabs.Information });
                return <MeterSerialNumView
                    label={record.meterSerialNum}
                    url={route}
                />;
            }
        },
        {
            title: 'Статус задачи на смену пароля',
            field: 'status',
            cellClassName: 'cell-status',
            render: (record: MeterChangePasswordOrderViewModel) => {
                return meterChangePasswordOrderStatusDictionary[record.status];
            }
        },
        {
            title: 'Дата изменения статуса',
            field: 'operationTime',
            cellClassName: 'cell-operationTime',
            render: (record: MeterChangePasswordOrderViewModel) => {
                return formatDate(record.operationTime, DateFormats.dateTimeZoneFormat);
            }
        },
        {
            title: 'Дата создания заявки',
            field: 'creationDate',
            cellClassName: 'cell-creationDate',
            render: (record: MeterChangePasswordOrderViewModel) => {
                return formatDate(record.creationDate, DateFormats.dateTimeZoneFormat);
            }
        },
        {
            title: 'Текст ошибки',
            field: 'resultMessage',
            cellClassName: 'cell-resultMessage',
            render: (record: MeterChangePasswordOrderViewModel) => {
                if (!record.resultMessage) {
                    return '—';
                }
                return (
                    <TextElipsis
                        lines={1}
                        text={record.resultMessage}
                    >
                        {record.resultMessage}
                    </TextElipsis>
                );
            }
        },
    ];

    return (
        <>
            <Box mb={2}>
                <Wrapper isBigHeader actions={filterForm} className={classes.requestsTableWrapper}>
                    <Table
                        columns={requestsColumns}
                        data={requests}
                        title={''}
                        variant='white'
                        options={{
                            showTitle: false,
                            toolbar: false,
                            search: false,
                            draggableHeader: false,
                            paging: 'disabled',
                            rowClassName: classes.tableRow,
                        }}
                    />
                    <Pagination
                        {...requestsPagination.pagination}
                        count={requestsPagination.dataTotal}
                        onChangePage={requestsPagination.setPage}
                        isLoading={isRequestsLoading}
                    />
                </Wrapper>
            </Box>
            <Box mb={2}>
                <Wrapper className={classes.ordersTableWrapper}>
                    <Table
                        columns={ordersColumns}
                        data={orders}
                        title={''}
                        variant='white'
                        options={{
                            showTitle: false,
                            toolbar: false,
                            search: false,
                            draggableHeader: false,
                            paging: 'disabled',
                        }}
                    />
                    <Pagination
                        {...ordersPagination.pagination}
                        count={ordersPagination.dataTotal}
                        onChangePage={ordersPagination.setPage}
                        isLoading={isOrdersLoading}
                    />
                </Wrapper>
            </Box>
            {isCreateRequestFormOpen && (
                <MeterChangePasswordRequestModal
                    onClose={onClickCloseRequest}
                    onSubmit={onSubmitCreateRequest}
                    isLoading={isFormSubmitting}
                />
            )}
        </>
    );
};
