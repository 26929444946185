import React from 'react';
import { OwnProps } from './model';
import { withStyles } from '@material-ui/core/styles';
import { createStyles, Tab, Theme } from '@material-ui/core';


export const View = withStyles((theme: Theme) => createStyles({
    root: {
        opacity: '1',
        textTransform: 'none',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.neutral.ultraLight,
        border: `1px solid ${theme.palette.neutral.light}`,
        borderRadius: '40px',
        minHeight: '50px',
        padding: '6px 20px',
        fontWeight: theme.typography.fontWeightMedium,
        '&:not(:last-child)': {
            marginRight: '12px',
        },
        '&:hover': {
            color: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.light}`,
        },
    },
    selected: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.neutral.white,
        border: `1px solid ${theme.palette.primary.light}`,
        '&:hover': {
            color: theme.palette.neutral.white,
        },
    },
}),
)((props: OwnProps) => <Tab disableRipple style={props.styles} {...props} />);
