import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ElectricityConsumptionBillingReportResponse } from '../../../../../../common/model/meter/reports/electricityConsumptionBillingReport';

export interface ElectricityConsumptionBillingReportState {
    available: ElectricityConsumptionBillingReportResponse;
}

const initialState: ElectricityConsumptionBillingReportState = {
    available: null,
};

export const electricityConsumptionBillingReportSlice = createSlice({
    name: '@@vv/reports/electricityConsumptionBillingReport',
    initialState,
    reducers: {
        setReport(state, action: PayloadAction<ElectricityConsumptionBillingReportResponse>): void {
            state.available = action.payload;
        },
    },
});
