import React from 'react';
import { ChartIcon, ElectricityIcon, ListColored, ReportIcon, SettingsIcon, UnionIcon } from '../../../../assets/icons';
import { AccessRule } from '../../../../common/model/access/accessRule';
import { AdministrationTabs, MeterActivationOrderTabs, MonitoringTabs, Routes } from '../../constants';
import { useAccessRules } from '../../hooks/useAccessRules';
import { getRoute } from '../../pipes';
import { IStateProps, MenuConfigurationItem, MenuItemType, OwnProps } from './model';
import { View } from './view';
import { useSelector } from 'react-redux';
import { controlsLeftMenuSelector } from '../../../../redux/selectors';

export const TopMenu = ({ activeMenu, onMenuItemClick }: OwnProps) => {

    const { notHasAccess } = useAccessRules();

    const { tabData } = useSelector(controlsLeftMenuSelector());

    const menu: MenuConfigurationItem[] = [
        {
            disabled: notHasAccess(AccessRule.CanReadMetersList),
            name: 'Приборы учета',
            menuItemType: MenuItemType.meters,
            route: Routes.empty,
            icon: React.createElement(ElectricityIcon),
        },
        {
            name: 'Мониторинг',
            menuItemType: MenuItemType.monitoring,
            route: getRoute(Routes.monitoring, {}, { tab: MonitoringTabs.SurveyCondition }),
            icon: React.createElement(ChartIcon),
        },
        {
            name: 'Отчетность',
            menuItemType: MenuItemType.report,
            route: Routes.report,
            icon: React.createElement(ReportIcon),
        },
        {
            name: 'Администрирование',
            menuItemType: MenuItemType.administration,
            route: getRoute(Routes.administration, {}, { tab: AdministrationTabs.Schemes }),
            icon: React.createElement(SettingsIcon),
        },
        {
            disabled: notHasAccess(AccessRule.CanReadRelatedSystems),
            name: 'Смежные системы',
            menuItemType: MenuItemType.relatedSystems,
            route: getRoute(Routes.relatedSystems, {}, { meterActivationOrdersTab: MeterActivationOrderTabs.Opened }),
            icon: React.createElement(UnionIcon),
        },
        {
            disabled: notHasAccess(AccessRule.CanReadEventLogs),
            name: 'Журнал событий',
            menuItemType: MenuItemType.eventLog,
            route: getRoute(Routes.eventLog),
            icon: React.createElement(ListColored),
        }
    ];

    if (tabData.length) {
        menu.push({
            name: 'ПУ',
            menuItemType: MenuItemType.metersDropdown,
            route: Routes.empty,
            icon: React.createElement(ElectricityIcon),
            isTab: true,
        });
    }

    const props: IStateProps = {
        activeMenu,
        menu: {
            main: menu,
        },
        onMenuItemClick,
    };

    return View(props);
};
