import React from 'react';
import { makeStyles, Divider, Box } from '@material-ui/core';
import { Uploader } from 'app/shared/components/uploader';
import { ImportCrqUploaderViewProps } from './model';

const templateFile = '/xlsx/crq-meters.xlsx';
const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingBottom: 20
    },
    label: {
        ...theme.typography.label,
        color: theme.palette.text.hint,
    },
    divider: {
        marginBottom: 16,
        marginLeft: -16,
        marginRight: -16
    },
    downloadLink: {
        ...theme.typography.body,
        color: theme.palette.primary.main,
    },
    helpText: {
        height: 20,
        margin: 8,
    }
}));

export const View: React.FC<ImportCrqUploaderViewProps> = ({ onSubmit, onUploadEnd }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Box width='100%' display='flex' justifyContent='flex-end' className={classes.helpText}>
                <a href={templateFile} className={classes.downloadLink}>Скачать шаблон</a>
            </Box>
            <Divider className={classes.divider} />
            <Uploader
                accepted={['xlsx']}
                onSubmit={onSubmit}
                onUploadEnd={onUploadEnd}
            />
        </div>
    );
};
