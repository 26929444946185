import { Box, Divider, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../shared/components/button';
import { Forbidden } from '../../../shared/components/forbidden';
import { Heading } from '../../../shared/components/heading';
import { Pagination } from '../../../shared/components/pagination';
import { setOrderTransformer } from '../../../shared/components/pagination/helpers';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { Table } from '../../../shared/components/table';
import { VisualizationSource } from '../../../shared/components/table/model';
import { Wrapper } from '../../../shared/components/wrapper';
import { defaults, Tables } from '../../../shared/constants';
import { StateProps } from './model';
import { DrawerPanel } from '../../../shared/components/drawerPanel';
import { FormProvider } from 'react-hook-form';
import { HookField } from '../../../shared/components/hookField';
import { transformLabelValueTypeToMultiSelectSuggestion } from '../../../shared/components/multiSelect/helpers';
import { activationOrderStatuses } from '../../../shared/constants/enums';
import { MeterActivationOrderStatus } from '../../../../common/model/meter/meterActivationOrder/meterActivationOrderStatus';
import { Typography } from '../../../shared/components/typography';
import { useWebDictionarySelectSuggestions } from '../../../shared/hooks/useWebDictionary';

const useStyles = makeStyles({
    wrapper: {
        padding: 0,
        position: 'relative',
        minHeight: 530,
        '& .MuiTableRow-root.errorString': {
            backgroundColor: '#FFD2D2',
        },
        '& .custom-table table .MuiTableBody-root .MuiTableRow-root.errorString:hover': {
            backgroundColor: 'rgba(255, 210, 210, 0.8)',
        },
        '& .custom-table table .MuiTableBody-root .MuiTableRow-root:not(:hover)': {
            '& a': {
                visibility: 'hidden',
            },
        },
        '& td.line-clamp > span': {
            width: '100%',
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': '3',
            '-webkit-box-orient': 'vertical'
        }
    },
    formWrapper: {
        flex: 1,
    },
    tableContainer: {
        overflowX: 'auto',
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
        },
    },
    tableCols: {
        '&.col-orderNumber': {
            width: '80px',
        },
        '&.col-dateStatusChange': {
            width: '110px',
        },
    },
    tableAction: {
        height: 56,
    },
    divider: {
        marginTop: 'auto',
    },
    drawer: {
        width: 420,
        maxWidth: '100%',
    },
    newOrderButton: {
        position: 'absolute',
        right: 16,
    },
    totalOrder: {
        display: 'flex',
        alignItems: 'center',
    },
});

export const View = ({
    form,
    columnDefinition,
    data,
    onCreateActivationOrder,
    canReadMeterActivationOrdersList,
    canPostMeterActivationOrder,
    dataTotal,
    setOrder,
    pagination,
    setPage,
    orderParams,
    isFilterVisible,
    onFiltersOpen,
    onFiltersClose,
    onClickFormApply,
    onResetFilter,
    isLoading,
    appliedFiltersCount,
    networkOperators,
}: StateProps) => {

    const classes = useStyles();

    const { meterModels } = useWebDictionarySelectSuggestions();

    const networkOperatorUsersSuggestions = networkOperators.map<SelectSuggestion>(item => ({
        label: `[${item.id}] ${item.name}`,
        value: item.id,
    }));

    const tableActionRender = (
        <Grid container spacing={2} className={classes.tableAction}>
            {!isFilterVisible && (
                <Grid xs={2} item>
                    <Button
                        fullWidth
                        variant='outline'
                        icon={appliedFiltersCount > 0 ? 'filterFilled' : 'filter'}
                        onClick={onFiltersOpen}
                    >
                        Фильтрация{appliedFiltersCount > 0 ? ` (${appliedFiltersCount})` : ''}
                    </Button>
                </Grid>
            )}
            <Grid item className={classes.totalOrder}>
                <Typography variant='bodyBold'>Всего заявок: { dataTotal }</Typography>
            </Grid>
            <Grid item>
                <Button
                    icon='add'
                    variant='primary'
                    className={classes.newOrderButton}
                    onClick={onCreateActivationOrder}
                    disabled={!canPostMeterActivationOrder || isLoading}
                >
                    Новая заявка
                </Button>
            </Grid>
        </Grid>
    );

    const drawer = <DrawerPanel
        className={classes.drawer}
        open={isFilterVisible}
        handleDrawerClose={onFiltersClose}
        title='Фильтрация'
        anchor='left'
    >
        <FormProvider {...form}>
            <Grid container spacing={2}>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='numberMeterActivationOrder'
                        type='text'
                        label='Номер заявки'
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='statuses'
                        type='multiselect'
                        suggestions={transformLabelValueTypeToMultiSelectSuggestion(activationOrderStatuses)}
                        label='Статус заявки'
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='meterModelId'
                        type='select'
                        suggestions={meterModels}
                        label='Тип ПУ'
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='meterSerialNum'
                        type='text'
                        label='Заводской номер'
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='address'
                        type='text'
                        label='Адрес установки'
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='dateFrom'
                        type='datepicker'
                        label='Дата создания от'
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='dateTo'
                        type='datepicker'
                        label='Дата создания до'
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        isClearable
                        name='networkOperatorId'
                        type='select'
                        suggestions={networkOperatorUsersSuggestions}
                        label='Платформа Центр2М'
                    />
                </Grid>
                <Divider className={classes.divider} />
                <Grid xs={12} item>
                    <Box display='flex' alignItems='center' justifyContent='between'>
                        <Button onClick={onClickFormApply} variant='primary' type='button' className='m-r-16' isLoading={isLoading}>Применить</Button>
                        <Button onClick={onResetFilter} variant='secondary' type='button'>Очистить фильтры</Button>
                    </Box>
                </Grid>
            </Grid>
        </FormProvider>
    </DrawerPanel>;

    return canReadMeterActivationOrdersList ? (
        <>
            <Box mb={2} display='flex' justifyContent='space-between'>
                <Heading heading='Заявки на ввод ПУ в эксплуатацию' />
            </Box>
            <Wrapper
                isBigHeader
                actions={tableActionRender}
                drawer={drawer}
                isHeaderUppercase={false}
                className={classes.wrapper}
            >
                <Box className={classes.tableContainer}>
                    <Table
                        visualizationSource={VisualizationSource.clientProfile}
                        name={Tables.meterActivationOrderTable}
                        columns={columnDefinition}
                        data={data ?? []}
                        variant='white'
                        title={null}
                        options={{
                            headerClassName: classes.tableCols,
                            grouping: false,
                            filtering: false,
                            search: false,
                            toolbar: false,
                            showTitle: false,
                            paging: 'disabled',
                            rowStyle: { position: 'relative' },
                            rowClassName: (rowData) => {
                                if (rowData.meterActivationOrderStatus === MeterActivationOrderStatus.RuntimeError) {
                                    return 'errorString';
                                }
                                return '';
                            },
                        }}
                        fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                        onOrderChange={setOrderTransformer(columnDefinition, setOrder)}
                        {...orderParams}
                    />
                    <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={isLoading || !data} />
                </Box>
            </Wrapper>
        </>
    ) : (
        <Forbidden />
    );
};
