import React from 'react';
import { BreadcrumbsProps } from './model';
import { Box, Breadcrumbs, SvgIcon } from '@material-ui/core';
import { ArrowSmallRightIcon } from '../../../../assets/icons';
import './styles.scss';
import { Typography } from '../typography';

export const View: React.FC<BreadcrumbsProps> = ({ breadcrumbs, onClickBreadcrumb }) => {
    const parentBreadCrumb = breadcrumbs[breadcrumbs.length - 1].name;
    return (
        <Breadcrumbs
            separator={
                <SvgIcon style={{ height: 18 }} component={ArrowSmallRightIcon} className='breadcrumb-arrow' />
            }
        >
            {
                breadcrumbs
                && breadcrumbs.map((breadcrumb): JSX.Element => {
                    if (breadcrumb.name === parentBreadCrumb) {
                        return (
                            <Typography
                                key={breadcrumb.name}
                                className='breadcrumbs-item'
                                variant="caption"
                            >{breadcrumb.name}</Typography>
                        );
                    }
                    return (
                        <Box onClick={() => onClickBreadcrumb(breadcrumb)} key={breadcrumb.name}>
                            <Typography
                                className='breadcrumbs-item'
                                variant="caption"
                            >
                                {breadcrumb.name}
                            </Typography>
                        </Box>
                    );
                })}
        </Breadcrumbs>
    );
};
