import { DateFormats } from '../../../../../../common/constants/date';
import { transformLocalTimeMeter } from '../../../../../shared/utils/dates';
import { LogGroup, LogGroupType, OwnProps, StateProps } from './model';
import {
    meterActivationOrderLogGroupDictionary
} from '../../../../../../common/model/meter/meterActivationOrder/meterActivationOrderLogGroup';
import { View } from './view';


export const MeterActivationOrderLog = ({
    meterActivationOrder,
}: OwnProps) => {

    const logBlocks: LogGroup[] = Object.keys(meterActivationOrderLogGroupDictionary).map(logGroup => {

        const logGroupItems = meterActivationOrder?.logs?.filter(log => log.logGroup === logGroup);

        const logGroupType: LogGroupType = logGroupItems?.length === 0
            ? 'notPerformed'
            : logGroupItems?.some(log => log.error)
                ? 'error'
                : 'success';

        return {
            title: meterActivationOrderLogGroupDictionary[logGroup],
            type: logGroupType,
            items: logGroupItems?.sort((itemOne, itemTwo) => itemOne.id > itemTwo.id ? 1 : itemOne.id < itemTwo.id ? -1 : 0)
                .map(log => ({
                    message: log.message,
                    date: transformLocalTimeMeter(
                        new Date(log.date)
                    ).format(DateFormats.dateFullTimeFormat),
                })),
        };
    });


    const props: StateProps = {
        data: logBlocks,
        meterActivationOrder,
    };

    return View(props);
};
