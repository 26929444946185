import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MassOperationCheckResponse } from '../../../../common/model/meter/massOperation/massOperationCheckResponse';
import { MassOperationShortViewModel } from '../../../../common/model/meter/massOperation/massOperationShortViewModel';

export interface MassOperationsListState {
    available: MassOperationShortViewModel[];
    hasNotCompleted: boolean;
}

const initialState: MassOperationsListState = {
    available: null,
    hasNotCompleted: true,
};

export const massOperationsListSlice = createSlice({
    name: '@@vv/massOperations/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<MassOperationShortViewModel[]>) {
            state.available = action.payload;
        },
        setHasNotCompleted(state, action: PayloadAction<MassOperationCheckResponse>) {
            state.hasNotCompleted = action.payload.hasNotCompleted;
        },
    },
});
