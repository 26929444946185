import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ElectricityConsumptionReportResponse } from '../../../../common/model/meter/reports/electricityConsumptionReport';

export interface ElectricityConsumptionReportState {
    available: ElectricityConsumptionReportResponse;
}

const initialState: ElectricityConsumptionReportState = {
    available: null,
};

export const electricityConsumptionReportSlice = createSlice({
    name: '@@vv/reports/electricityConsumptionReport',
    initialState,
    reducers: {
        setReport(state, action: PayloadAction<ElectricityConsumptionReportResponse>): void {
            state.available = action.payload;
        },
    },
});
