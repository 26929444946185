import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { mergeDeep } from '../../../../app/shared/pipes';
import { UserViewModel } from '../../../../common/model/access/user/userViewModel';


import { ActionType } from '../../../actions';

export interface ActiveUserState {
    user: UserViewModel;
}

const initialState: ActiveUserState = {
    user: null,
};

export const usersActiveSlice = createSlice({
    name: '@@vv/users/active',
    initialState,
    reducers: {
        setActiveUser(state, action: PayloadAction<UserViewModel>): void {
            state.user = action.payload;
        },
        mergeActiveUser(state, action: PayloadAction<UserViewModel>): void {
            state.user = mergeDeep(state.user, action.payload);
        }
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): ActiveUserState => initialState
    }
});
