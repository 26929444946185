import React from 'react';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { createAddress } from '../../../../redux/actions/addresses';
import { createSnackbar } from '../../../../redux/actions/controls';
import { getErrorMessage } from '../../utils/error';
import { InputProps, IStateProps } from './model';
import { View } from './view';

export const CreateAddress: React.FC<InputProps> = (props) => {
    const dispatch = useDispatch<BaseDispatch>();

    const viewProps: IStateProps = {
        ...props,
        onSubmit: async (formData) => {
            try {
                const address = await dispatch(createAddress(formData));
                props.onCreateAddress(address);
                props.onClose();
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
        },
    };
    return <View {...viewProps} />;
};
