import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../../redux/actions';
import { getSearchEventTypes, resetSearchEventTypes } from '../../../../../redux/actions/eventTypes';
import { VAutocompleteOption } from '../model';
import { EventTypeViewModel } from '../../../../../common/model/meter/eventLog/eventTypeViewModel';
import { EventTypeFindRequest } from '../../../../../common/model/meter/eventLog/eventTypeFindRequest';
import { debounce } from '../../../pipes';
import { defaults } from '../../../../shared/constants';
import { searchEventTypesListSelector, searchEventTypesLoadingSelector } from '../../../../../redux/selectors';

const initialQueryParams: EventTypeFindRequest = {
    count: 10,
};

export const useEventTypeAutocomplete = (isVisible?: boolean) => {
    const searchEventTypes: EventTypeViewModel[] = useSelector(searchEventTypesListSelector);
    const loading: boolean = useSelector(searchEventTypesLoadingSelector);

    const dispatch = useDispatch<BaseDispatch>();

    const eventTypesSuggestions: VAutocompleteOption[] = searchEventTypes?.map(item => ({
        label: item.description,
        optionsLabel: item.description,
        value: item.id,
    }));


    const onSearchEventTypes = async (value?: string) => {
        if (!value) {
            return dispatch(resetSearchEventTypes());
        }
        await debounce(() => dispatch(
            getSearchEventTypes({
                ...initialQueryParams,
                description: value,
                isVisible,
            })
        ), defaults.debounceTimeForAutocompleteSearchMs);
    };

    useEffect(() => {
        dispatch(getSearchEventTypes({
            ...initialQueryParams,
            isVisible,
        }));
        return () => {
            dispatch(resetSearchEventTypes());
        };
    }, []);

    return {
        loading,
        onInputChange: (event: any, value: string) => onSearchEventTypes(value),
        options: eventTypesSuggestions || [],
    };
};
