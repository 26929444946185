import { Column, Query } from '@softmedialab/materialui-table';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MeterSearchQuery } from '../../../../../../common/model/meter/meter/meterSearchQuery';
import { MeterViewModel } from '../../../../../../common/model/meter/meter/meterViewModel';
import { addressesQueryTransformer } from '../../../../../../common/shared/utils/addresses';
import { Sort } from '../../../../../../common/sort';
import { BaseDispatch } from '../../../../../../redux/actions';
import { getMetersDynamicGroupListForInfinitiveScroll } from '../../../../../../redux/actions/meterGroup';
import { meterGroupMetersListSelector } from '../../../../../../redux/selectors/meterGroup';
import { MeterTabs, Routes } from '../../../../../shared/constants';
import { useWebDictionaryLookup } from '../../../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../../../shared/pipes';
import { MeterSerialNumView } from '../../../../reports/components/common/meterSerianNumView';

import { DynamicMeterProps } from './model';
import { View } from './view';

export const EditDynamicGroupForm = (props: DynamicMeterProps) => {
    const dispatch = useDispatch<BaseDispatch>();
    const meterGroupDynamicMetersList = useSelector(
        meterGroupMetersListSelector()
    );
    const metersTotal = meterGroupDynamicMetersList?.total ?? 0;
    const isLoading = meterGroupDynamicMetersList === null;

    const [isShowTable, setIsShowTable] = useState(false);

    const onOpenTable = () => {
        setIsShowTable(!isShowTable);
    };

    const { meterModels } = useWebDictionaryLookup();

    const getData = async (query: Query) => {
        const sort = new Sort({
            count: query.pageSize,
            offset: query.page * query.pageSize,
        });
        const {
            groupParamsFilter: {
                params: {
                    relayStatus,
                    meterModelId,
                    status,
                    relayOn,
                    accountType,
                    meterPurpose,
                    legalEntityType,
                    priceCategory,
                    readingSource,
                },
                addresses,
            },
            meterGroupFilter,
        } = props;
        const filter: MeterSearchQuery = {
            relayStatus,
            relayOn,
            status,
            meterModelId,
            accountType,
            meterPurpose,
            legalEntityType,
            priceCategory,
            readingSource,
            addresses: addresses?.length
                ? addressesQueryTransformer(addresses)
                : undefined,
            regionId: meterGroupFilter?.regionId,
            ...sort,
            id: undefined,
        };
        const result = await dispatch(
            getMetersDynamicGroupListForInfinitiveScroll(filter)
        );

        return {
            data: result.data,
            page: query.page,
            totalCount: result.total,
        };
    };

    const columnsDefinition: Column[] = [
        {
            title: 'Тип ПУ',
            field: 'meterModelId',
            lookup: meterModels,
            sorting: true,
            cellClassName: 'col-meter-model',
        },
        {
            title: 'Заводской номер',
            field: 'meterSerialNum',
            sorting: false,
            cellClassName: 'col-number',
            render: (item: MeterViewModel) => {
                const route = getRoute(
                    Routes.meter,
                    { meterId: item.id },
                    { tab: MeterTabs.Information }
                );
                return <MeterSerialNumView label={item.meterSerialNum} url={route} />;
            },
        },
        {
            title: 'Адрес',
            field: 'address.address',
            sorting: false,
        },
    ];

    return View({
        ...props,
        getData,
        metersTotal,
        meterModels,
        columnsDefinition,
        onOpenTable,
        isShowTable,
        isLoading,
        onSubmit: async (...args) => {
            try {
                setIsShowTable(false);
                await props.onSubmit(...args);
            } finally {
                setIsShowTable(true);
            }
        },
    });
};
