import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../../shared/components/button';
import { HookField } from '../../../../../shared/components/hookField';
import { Typography } from '../../../../../shared/components/typography';
import { InputProps } from './model';

export const useStyles = makeStyles((theme) => ({
    inputLabel: {
        textAlign: 'right',
    },
    buttonContainer: {
        paddingTop: '16px',
    },
    infoText: {
        ...theme.typography.label,
        color: theme.palette.neutral.main,
    },
    infoTextSymbol: {
        ...theme.typography.h3,
        marginRight: 5,
        lineHeight: 'inherit',
    },
    infoTextWrapper: {
        padding: '0 0 0 24px !important',
        marginTop: -10,
    },
}));

export const View: React.FC<InputProps> = (props) => {

    const {
        form,
        onSubmit,
        onCancel,
        isLoading,
    } = props;

    const { handleSubmit, formState: { isDirty } } = form;

    const classes = useStyles();

    return <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} className={classes.inputLabel}>
                            <Typography variant='bodyBold'>
                                Тип ПУ<br />(маска)
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                name='meterModelMask'
                                type='text'
                                label='Тип ПУ (маска)'
                                disabled={isLoading}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.infoTextWrapper}>
                    <Grid container alignItems='center'>
                        <Grid item xs={3} />
                        <Grid item xs={9}>
                            <Box className={classes.infoText}>
                                <div>Можно использовать символы подстановки:</div>
                                <div>
                                    <span className={classes.infoTextSymbol}>
                                        *
                                    </span>
                                    заменяет любое количество символов</div>
                                <div>
                                    <span className={classes.infoTextSymbol}>
                                        ?
                                    </span>
                                    заменяет любой один символ</div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} className={classes.inputLabel}>
                            <Typography variant='bodyBold'>
                                Порядковый №<br />версии
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                name='sequenceNumber'
                                type='number'
                                label='Порядковый № версии'
                                disabled={isLoading}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} className={classes.inputLabel}>
                            <Typography variant='bodyBold'>Версия ПО</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                name='version'
                                type='text'
                                label='Версия ПО'
                                disabled={isLoading}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                        <Grid item>
                            <Button
                                icon='check'
                                type='submit'
                                variant='primary'
                                isLoading={isLoading}
                                disabled={!isDirty || isLoading}
                            >
                                Сохранить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type='button'
                                onClick={onCancel}
                                variant='secondary'
                                disabled={isLoading}
                            >
                                Отмена
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </FormProvider>;
};
