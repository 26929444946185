import { push } from 'connected-react-router';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { MeterChangePasswordOrderType } from '../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderType';
import { ISort } from '../../../common/sort';
import { BaseDispatch } from '../../../redux/actions';
import { createSnackbar } from '../../../redux/actions/controls';
import {
    createMeterChangePasswordOrder,
    getMeterChangePasswordOrdersControl,
} from '../../../redux/actions/meterChangePasswordOrders';
import { getMeterData } from '../../../redux/actions/meters';
import { updateMeterPasswordFields } from '../../../redux/actions/meterUpdateAttributes';
import { resourcesMeterModelsSelector } from '../../../redux/selectors';
import { meterChangePasswordOrdersControlListSelector } from '../../../redux/selectors/meterChangePasswordOrders';
import { meterSelector } from '../../../redux/selectors/meters';
import { usePagination } from '../../shared/components/pagination';
import { MeterTabs, Routes } from '../../shared/constants';
import { useBreadcrumbs } from '../../shared/hooks/useBreadcrumbs';
import { useHeading } from '../../shared/hooks/useHeading';
import { useInterval } from '../../shared/hooks/useInterval';
import { getRoute } from '../../shared/pipes';
import { msInSecond } from '../../shared/utils/dates';
import { getErrorMessage } from '../../shared/utils/error';
import { useYupValidationResolver } from '../../shared/utils/validation';
import { MeterPasswordManagementForm } from './model';
import { View } from './view';

const meterPasswordManagementFormValidationScheme = yup.object({
    autoGeneratePassword: yup.boolean(),
    newAdminPassword: yup
        .string()
        .when('autoGeneratePassword', {
            is: false,
            then: yup.string().required('Укажите пароль администратора')
        }),
    newUserPassword: yup
        .string()
        .when('autoGeneratePassword', {
            is: false,
            then: yup.string().required('Укажите пароль пользователя')
        }),
});

const defaultValues: MeterPasswordManagementForm = {
    autoGeneratePassword: false,
    newAdminPassword: undefined,
    newUserPassword: undefined,
};

const fetchOrdersDelayMS = 10 * msInSecond;

export const MeterPasswordManagement = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const params = useParams<{meterId?: string}>();
    const meterId = params.meterId;

    const meter = useSelector(meterSelector);
    const meterModels = useSelector(resourcesMeterModelsSelector());
    const orderListResponse = useSelector(meterChangePasswordOrdersControlListSelector);
    const [isWriteLoading, setIsWriteLoading] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(false);
    const [isOrdersLoading, setIsOrdersLoading] = useState(false);
    const [isChangeWithoutWriteLoading, setIsChangeWithoutWriteLoading] = useState(false);
    const [isPasswordsVisible, setIsPasswordsVisible] = useState(false);
    const [isPasswordsViewLoading, setIsPasswordsViewLoading] = useState(false);

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Приборы учета', link: getRoute(Routes.meterSearch) },
        { name: 'Карточка ПУ', link: getRoute(Routes.meter, { meterId }) },
        { name: 'Управление', link: getRoute(Routes.meter, { meterId }, { tab: MeterTabs.Management }) },
        { name: 'Проверка и изменение паролей', link: null },
    ]);

    const headingProps = useHeading('Проверка и изменение паролей', () => {
        dispatch(push(getRoute(Routes.meter, { meterId }, { tab: MeterTabs.Management })));
    });

    useEffect(() => {
        dispatch(getMeterData(meterId));
    }, [meterId]);


    const resolver = useYupValidationResolver<MeterPasswordManagementForm>(meterPasswordManagementFormValidationScheme);
    const form = useForm<MeterPasswordManagementForm>({
        resolver,
        defaultValues
    });

    const resetForm = () => {
        form.reset(defaultValues);
    };

    const fetchData = async (sort: ISort, _isPasswordsVisible = isPasswordsVisible) => {
        setIsOrdersLoading(true);
        try {
            await dispatch(getMeterChangePasswordOrdersControl(meterId, {
                count: sort.count,
                offset: sort.offset,
                isPasswordsVisible: _isPasswordsVisible,
            }));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsOrdersLoading(false);
    };

    const { pagination, setOrder, setPage, orderParams, onFetch } = usePagination({
        page: 1,
        rowsPerPage: 20,
        orderBy: null,
        orderDirection: null,
    }, fetchData);

    const refetch = async () => {
        await onFetch(pagination, isPasswordsVisible);
    };

    useEffect(() => {
        refetch();
    }, [meterId]);

    useInterval(async () => {
        await refetch();
    }, fetchOrdersDelayMS);

    const meterNote = useMemo(() => {
        const meterModel = meter?.meterModelId
            ? meterModels.find(item => item.id === meter?.meterModelId)
            : null;
        return meter
            ? `${meterModel.shortName} №${meter.meterSerialNum}`
            : '—';
    }, [meter, meterModels]);

    const isLoading = isCheckLoading || isWriteLoading || isChangeWithoutWriteLoading || isPasswordsViewLoading;

    const data = orderListResponse?.data ?? [];

    return View({
        ...headingProps,
        ...breadcrumbProps,
        pagination,
        setOrder,
        setPage,
        data,
        form,
        meter,
        isLoading,
        meterNote,
        orderParams,
        isWriteLoading,
        isCheckLoading,
        isOrdersLoading,
        isPasswordsVisible,
        isPasswordsViewLoading,
        isChangeWithoutWriteLoading,
        dataTotal: orderListResponse?.total ?? 0,
        onClickWrite: async (formData) => {
            setIsWriteLoading(true);
            try {
                const { newAdminPassword, newUserPassword } = formData;
                await dispatch(createMeterChangePasswordOrder({
                    newUserPassword,
                    newAdminPassword,
                    meterId,
                    actionType: MeterChangePasswordOrderType.ChangePassword
                }));
                resetForm();
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsWriteLoading(false);
            await refetch();
        },
        onClickCheck: async (formData) => {
            setIsCheckLoading(true);
            try {
                const { newAdminPassword, newUserPassword } = formData;
                await dispatch(createMeterChangePasswordOrder({
                    meterId,
                    newUserPassword,
                    newAdminPassword,
                    actionType: MeterChangePasswordOrderType.ValidatePassword
                }));
                resetForm();
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsCheckLoading(false);
            await refetch();
        },
        onClickChangeWithoutWrite: async (formData) => {
            setIsChangeWithoutWriteLoading(true);
            try {
                await dispatch(updateMeterPasswordFields(meterId, {
                    adminPassword: formData.newAdminPassword,
                    userPassword: formData.newUserPassword,
                }));
                resetForm();
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsChangeWithoutWriteLoading(false);
            await refetch();
        },
        onClickHidePasswords: async () => {
            setIsPasswordsViewLoading(true);
            setIsPasswordsVisible(false);
            await onFetch(pagination, false);
            setIsPasswordsViewLoading(false);
        },
        onClickShowPasswords: async () => {
            setIsPasswordsViewLoading(true);
            setIsPasswordsVisible(true);
            await onFetch(pagination, true);
            setIsPasswordsViewLoading(false);
        },
    });
};
