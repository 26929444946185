import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EventLogFindRequest } from '../../../../common/model/meter/eventLog/eventLogFindRequest';
import { logTypeDictionary } from '../../../../common/model/meter/eventLog/logType';
import { MeterSearchQuery } from '../../../../common/model/meter/meter/meterSearchQuery';
import { MeterStatus } from '../../../../common/model/meter/meterStatus';
import { BaseDispatch } from '../../../../redux/actions';
import { findCommonMeters } from '../../../../redux/actions/eventLogs';
import { commonMetersSelector } from '../../../../redux/selectors/eventLogs';
import { useEventTypeAutocomplete } from '../../../shared/components/autocomplete/hooks/useEventTypeAutocomplete';
import { MultiSelectValue } from '../../../shared/components/multiselectwithsearch/model';
import { Suggestion } from '../../../shared/interfaces';
import { FilterFormProps } from './model';
import { View } from './view';

let timeoutId: NodeJS.Timeout = null;
const TIMEOUT_WRITE = 400;
const searchIsVisibleEventTypes = true;

const defaultSearchQuery: MeterSearchQuery = {
    count: 100,
    statuses: [
        MeterStatus.Active,
        MeterStatus.Created,
        MeterStatus.Defective,
    ],
};

export const EventLogFilterForm: React.FC<FilterFormProps> = (props) => {
    const params = useParams<{meterId?: string}>();
    const { filterValues, initialFilterValues } = props;
    const form = useForm<EventLogFindRequest>({ defaultValues: filterValues });
    const { watch } = form;
    const watchDateTo = watch('dateTo');
    const watchDateFrom = watch('dateFrom');
    const eventTypesSuggestionsSelected: string[] = watch('eventType');
    const meters = useSelector(commonMetersSelector);
    const dispatch = useDispatch<BaseDispatch>();
    const eventTypeAutocomplete = useEventTypeAutocomplete(searchIsVisibleEventTypes);

    const logTypesSuggestions: Suggestion[] = [{ value: '', label: 'Все' }]
        .concat(Object.entries(logTypeDictionary)
            .map(([value, label]) => ({ label, value }))
        );

    const eventTypesSuggestions: MultiSelectValue[] =
        eventTypeAutocomplete.options
            .map((item) => ({ label: item.label, value: item.value.toString() }));

    const fetchAllMeters = async () => {
        await dispatch(findCommonMeters({ ...defaultSearchQuery }));
    };

    const onSearch = (val: string): void => {
        const meterSerialNum = val;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            dispatch(findCommonMeters({ ...defaultSearchQuery, meterSerialNum }));
        }, TIMEOUT_WRITE);
    };

    const onSearchEventType = async (val: string): Promise<void> => {
        await eventTypeAutocomplete.onInputChange(null, val);
    };

    const onResetForm = async () => {
        form.reset(initialFilterValues);
        props.onSubmit(initialFilterValues);
        await fetchAllMeters();
    };

    useEffect(() => {
        fetchAllMeters();
    }, []);

    const suggestions: MultiSelectValue[] = meters.map(item => {
        return {
            label: `${item.meterModel} ${item.meterSerialNum}`,
            value: item.id
        };
    });

    return View({
        ...props,
        form,
        onResetForm,
        logTypesSuggestions,
        eventTypesSuggestions,
        eventTypesSuggestionsSelected,
        watchDateFrom,
        watchDateTo,
        suggestions,
        params,
        onSearch,
        onSearchEventType,
    });
};
