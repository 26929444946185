import React from 'react';
import { Props } from './model';

import './style.scss';

import { LeftSlider, RightSlider } from '../../../../../../assets/icons';

export const SliderHeader = (props: Props) => {
    return <div className='monitoring-slider-header'>
        {props.showSlider
            ? <div className='slider-container'>
                {props.prevId ? <LeftSlider onClick={() => props.onClick(props.prevId)} width={32} height={32} /> : <div />}
                <span className='text bold'>{props.sliderText}</span>
                {props.nextId ? <RightSlider onClick={() => props.onClick(props.nextId)} width={32} height={32} /> : <div />}
            </div>
            : <span className='text bold'>{props.withoutSliderText}</span>
        }
        {props?.children ?? null}
    </div>;
};
