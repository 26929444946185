import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import {
    NotConnectedMetersReportQueryParams,
    NotConnectedMetersReportResponse,
} from '../../../../common/model/meter/reports/notConnectedMeters';
import { notConnectedMetersReportSlice } from '../../../reducers/reports/notConnectedMetersReport';
import { AppThunk } from '../../index';

export const getNotConnectedMetersReport = (params: NotConnectedMetersReportQueryParams): AppThunk => async (dispatch) => {
    const { regionId, daysOffline } = params;
    const route = getApiRoute(appUrls.api_reports_not_connected_meters, {}, { regionId, daysOffline });
    const data = await httpService.get<NotConnectedMetersReportResponse>(route);
    dispatch(notConnectedMetersReportSlice.actions.setReport(data));
};

export const resetNotConnectedMetersReport = (): AppThunk => (dispatch) => {
    dispatch(notConnectedMetersReportSlice.actions.setReport(null));
};
