import React from 'react';
import { IStateProps } from './model';
import { Table } from '../../shared/components/table';
import { VisualizationSource } from '../../shared/components/table/model';
import { defaults, Tables } from '../../shared/constants';
import { Wrapper } from '../../shared/components/wrapper';
import { makeStyles } from '@material-ui/core';

const useCommonTableStyles = makeStyles(() => ({
    actions: {
        height: 72,
        display: 'flex',
        alignItems: 'center',
    },
    actionsHeader: {
        padding: '8px',
        width: '200px',
    },
    consumptionWrapper: {
        display: 'flex',
    },
}));

export const View = ({
    data,
    columnDefinition,
}: IStateProps) => {
    const classes = useCommonTableStyles();

    return (
        <>
            <Wrapper isHeaderUppercase={false} className={classes.consumptionWrapper}>
                {data && (
                    <Table
                        visualizationSource={VisualizationSource.clientProfile}
                        name={Tables.metersTable}
                        columns={columnDefinition}
                        data={data || []}
                        title='Приборы учета'
                        variant='white'
                        useQueryString={true}
                        options={{
                            grouping: false,
                            columnsButton: false,
                            filtering: false,
                            search: false,
                        }}
                        fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                    />
                )}
            </Wrapper>
        </>
    );
};
