import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../../redux/actions';
import { getMassOperationsCheck } from '../../../../../redux/actions/massOperations';
import { useInterval } from '../../../../shared/hooks/useInterval';

const massOperationCheckIntervalMs = 5000;

export const useCheckMassOperation = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const check = async () => {
        await dispatch(getMassOperationsCheck());
    };

    useEffect(() => {
        check();
    }, []);

    useInterval(async () => {
        await check();
    }, massOperationCheckIntervalMs);

};
