import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useBreadcrumbs } from '../../../shared/hooks/useBreadcrumbs';
import { getRoute } from '../../../shared/pipes';
import { AdministrationTabs, Routes } from '../../../shared/constants';
import { View } from './view';
import { useHeading } from '../../../shared/hooks/useHeading';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { createColumns } from './columns';
import { MeterLabelTypeViewModel } from '../../../../common/model/meter/meterLabelType/meterLabelTypeViewModel';
import { StorageState } from '../../../../redux/reducers';
import { getMeterLabelTypes } from '../../../../redux/actions/meterLabelType';

export const MeterLabelType: React.FC = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const [editableLabel, setEditableLabel] = useState<MeterLabelTypeViewModel>(null);
    const meterLabelTypes = useSelector((state: StorageState) => state.meterLabelType.list.available);

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.Schemes }) },
        { name: 'Справочники', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.Dictionaries }) },
        { name: 'Типы меток', link: null },
    ]);
    const headingProps = useHeading('Типы меток', () => {
        dispatch(push(getRoute(Routes.administration, null, { tab: AdministrationTabs.Dictionaries })));
    });
    const columns = useMemo(() => createColumns({ setEditableLabel }), []);
    const onCancelEditingLabel = useCallback(() => setEditableLabel(null), []);

    useEffect(() => {
        dispatch(getMeterLabelTypes());
    }, []);

    return View({
        ...breadcrumbProps,
        ...headingProps,
        columns,
        data: meterLabelTypes,
        editableLabel,
        onCancelEditingLabel
    });
};
