import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SpecialDayBaseGetQueryParams } from '../../../../common/model/meter/specialDayBase/specialDayBaseGetQueryParams';
import { SpecialDayBaseViewModel } from '../../../../common/model/meter/specialDayBase/specialDayBaseViewModel';
import { monthsDictionary } from '../../../../common/shared/utils/date';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import {
    createSpecialDayBase,
    getSpecialDayBaseList,
    updateSpecialDayBase
} from '../../../../redux/actions/specialDayBase';
import { specialDayBaseListSelector } from '../../../../redux/selectors/specialDayBase';
import { AdministrationTabs, Routes } from '../../../shared/constants';
import { useBreadcrumbs } from '../../../shared/hooks/useBreadcrumbs';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { useHeading } from '../../../shared/hooks/useHeading';
import { getRoute } from '../../../shared/pipes';
import { getErrorMessage } from '../../../shared/utils/error';
import { SpecialDayBaseFormValues } from './components/specialDayBaseForm/model';
import { View } from './view';
import { Button } from '../../../shared/components/button';

const initialFormValues: SpecialDayBaseGetQueryParams = {
    month: null,
};

const formRefreshDelayMs = 200;

export const SpecialDayBase: React.FC = () => {

    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const [editableSpecialDayBase, setEditableSpecialDayBase] = useState<SpecialDayBaseViewModel>(null);

    const dispatch = useDispatch<BaseDispatch>();

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.Schemes }) },
        { name: 'Справочники', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.Dictionaries }) },
        { name: 'Базовый календарь специальных дней', link: null },
    ]);

    const headingProps = useHeading('Базовый календарь специальных дней', () => {
        dispatch(push(getRoute(Routes.administration, null, { tab: AdministrationTabs.Dictionaries })));
    });

    const form = useForm<SpecialDayBaseGetQueryParams>({
        defaultValues: initialFormValues,
    });

    const formData = form.watch();

    const fetch = async () => {
        await dispatch(getSpecialDayBaseList(formData));
    };

    const formDataChange = useDebounce(formData, formRefreshDelayMs);

    useEffect(() => {
        fetch();
    }, [formDataChange]);

    const data: SpecialDayBaseViewModel[] = useSelector(specialDayBaseListSelector);

    const editSpecialDayBase = (item: SpecialDayBaseViewModel) => {
        setEditableSpecialDayBase(item);
        setIsFormOpen(true);
    };

    const columns: Column[] = [
        {
            field: 'day',
            title: 'День',
        },
        {
            field: 'month',
            title: 'Месяц',
            render: (item: SpecialDayBaseViewModel) => {
                return monthsDictionary[+item.month - 1];
            },
        },
        {
            field: 'name',
            title: 'Название',
        },
        {
            field: 'key',
            title: '',
            render: (item: SpecialDayBaseViewModel) => {
                return <Button
                    type='button'
                    variant='primary'
                    onClick={() => editSpecialDayBase(item)}
                >
                    Редактировать
                </Button>;
            },
        }
    ];

    const onClickNewDay = () => {
        setIsFormOpen(true);
    };

    const onCloseForm = () => {
        setIsFormOpen(false);
        setEditableSpecialDayBase(null);
    };

    const onSubmitSpecialDayBaseForm: SubmitHandler<SpecialDayBaseFormValues> = async (formValues) => {
        try {
            const isNewRecord = !editableSpecialDayBase;
            if (isNewRecord) {
                await createSpecialDayBase(formValues);
            } else {
                await updateSpecialDayBase(editableSpecialDayBase.id, formValues);
            }
            onCloseForm();
            await fetch();
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
    };

    return View({
        ...breadcrumbProps,
        ...headingProps,
        data,
        form,
        columns,
        isFormOpen,
        onCloseForm,
        onClickNewDay,
        editableSpecialDayBase,
        onSubmitSpecialDayBaseForm,
    });
};
