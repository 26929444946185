import React from 'react';
import { Box, makeStyles, Divider, CircularProgress, Grid } from '@material-ui/core';
import { WrapperProps } from './model';
import { Theme } from '@material-ui/core/styles';
import classNames from 'classnames';


const useStyles = makeStyles((theme: Theme) => ({
    block: {
        height: '100%',
        backgroundColor: theme.palette.neutral.white,
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 2px 24px rgba(0, 0, 0, 0.12)',
        borderRadius: 5,
        flex: '1',
    },
    header: {
        padding: '6px 16px',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
    },
    headerText: {
        lineHeight: '40px',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    },
    headerColor: {
        backgroundColor: theme.palette.neutral.white,
    },
    content: {
        overflowX: 'auto',
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    fullWidth: {
        width: '100%',
    },
    isBigHeader: {
        padding: '16px',
    },
    children: {
        padding: '16px',
    },
    height: {
        height: '100%',
    },
    withDrawer: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    bodyClassName: {
        overflowX: 'auto',
    }
}));


export const View = (props: WrapperProps) => {
    const {
        title,
        drawer,
        actions,
        children,
        className,
        isBigHeader,
        headerColor,
        justifyContent,
        isHeaderUppercase,
        childrenWrapperClassName,
        flexShrink,
        isTitleFullWidth,
    } = props;
    const isUppercase = isHeaderUppercase === undefined ? true : isHeaderUppercase;
    const classes = useStyles({ isTitleFullWidth });

    return (
        <Box className={classNames(classes.block, className)}>
            <Grid container className={classNames({ [classes.withDrawer]: Boolean(drawer) }) }>
                {
                    Boolean(drawer) && drawer
                }
                <Grid className={classNames(classes.bodyClassName, classes.content)} item xs={12} lg={12} md={12} xl={12}>
                    { title || actions ?
                        <>
                            <Box
                                className={
                                    classNames(classes.header,
                                        { [classes.isBigHeader]: isBigHeader },
                                        { [classes.headerColor]: headerColor === 'white' }
                                    )
                                }
                                display='flex'
                                justifyContent={justifyContent || 'space-between'}
                                alignItems='center'
                            >
                                {
                                    Boolean(title) && <Box
                                        className={
                                            classNames(
                                                classes.headerText,
                                                {
                                                    [classes.uppercase]: isUppercase,
                                                    [classes.fullWidth]: isTitleFullWidth,
                                                },
                                            )
                                        }
                                        flexShrink={flexShrink || 1}
                                    >{title}</Box>
                                }
                                {actions}
                            </Box>
                            {
                                children && <Divider />
                            }
                        </> : null
                    }

                    {
                        props.isLoading ?
                            <Box display='flex' justifyContent="center" flexDirection='column' alignItems='center'>
                                <div className={classes.height}>
                                    <CircularProgress />
                                </div>
                            </Box> : (children && <Box className={classNames(classes.children, childrenWrapperClassName)}>{children}</Box>)
                    }
                </Grid>
            </Grid>
        </Box>
    );
};
