import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MeterFirmwareVersionCreator } from '../../../common/model/meter/meterFirmwareVersion/meterFirmwareVersionCreator';
import { MeterFirmwareVersionUpdater } from '../../../common/model/meter/meterFirmwareVersion/meterFirmwareVersionUpdater';
import { MeterFirmwareVersionViewModel } from '../../../common/model/meter/meterFirmwareVersion/meterFirmwareVersionViewModel';
import { meterFirmwareVersionsListSlice } from '../../reducers/meterFirmwareVersions/list';
import { AppThunk } from '../index';

export const getMeterFirmwareVersions = (): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_firmware_version);
    const data = await httpService.get<MeterFirmwareVersionViewModel[]>(route);
    dispatch(meterFirmwareVersionsListSlice.actions.setAvailable(data));
};

export const createMeterFirmwareVersion = async (creator: MeterFirmwareVersionCreator) => {
    const route = getApiRoute(appUrls.api_post_meter_firmware_version);
    await httpService.post<MeterFirmwareVersionViewModel>(route, creator);
};

export const updateMeterFirmwareVersion = async (id: number, updater: MeterFirmwareVersionUpdater) => {
    const route = getApiRoute(appUrls.api_put_meter_firmware_version, { id });
    await httpService.put<MeterFirmwareVersionViewModel[]>(route, updater);
};

export const deleteMeterFirmwareVersion = async (id: number) => {
    const route = getApiRoute(appUrls.api_delete_meter_firmware_version, { id });
    await httpService.delete(route);
};
