import { useEffect, useRef } from 'react';

export type EventListenerHookEvent = (event: any) => void;

export const useEventListener = (eventName: string, handler: EventListenerHookEvent, element: any) => {
    const savedHandler = useRef<EventListenerHookEvent>();
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            const isSupported = element.addEventListener;
            if (!isSupported) {
                return null;
            }
            const eventListener = (event: any) => savedHandler.current(event);
            element.addEventListener(eventName, eventListener);
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element]
    );
};
