import React, { useState } from 'react';
import { View } from './view';
import { TabListProps } from './model';
import { Tab } from '../../../../../redux/reducers/controls/menu';
import useReactRouter from 'use-react-router';
import { RouterParams } from '../../../pipes';

export const RenderTabList: React.FC<TabListProps> = ({
    isOpen,
    tabData,
    handleClickItem,
    anchorEl,
    handleClose
}) => {
    const { location } = useReactRouter<RouterParams>();
    const locations = (location?.pathname || '').split('/');
    const meterId = locations.filter(elem => elem.length > 0)[1];

    const [hoverTab, setHoverTab] = useState<Tab | null>(null);

    return View({ isOpen, tabData, handleClickItem, hoverTab, setHoverTab, anchorEl, handleClose, meterId });
};
