import { useRef, useEffect, useCallback } from 'react';

export const useOutsideClick = <T = any>(onOutsideClick: () => any): React.MutableRefObject<T> => {
    const ref = useRef();
    const target = useRef();

    const onMouseUp = useCallback(() => {
        if (!target.current && ref.current) {
            onOutsideClick();
        }

        target.current = null;
    }, [onOutsideClick]);

    const onMouseDown = (event: any) => {
        const nodeElement = ref.current;
        let currentTarget = event.target;

        if (nodeElement && currentTarget) {
            while (currentTarget) {
                if (currentTarget) {
                    if (currentTarget === nodeElement) {
                        target.current = currentTarget;
                        break;
                    } else {
                        currentTarget = currentTarget.parentNode;
                    }
                }
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('mousedown', onMouseDown);

        return () => {
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('mousedown', onMouseDown);
        };
    }, [onMouseUp]);

    return ref;
};
