import { makeStyles } from '@material-ui/core';
import React from 'react';
import { IconType } from '../icon/model';
import { Icon } from '../icon';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.primary.light,
    },
}));

interface Props {
    icon?: IconType;
}

export const TableTitle: React.FC<Props> = ({ icon, children }) => {
    const classes = useStyles();

    return <div className={classes.root}>
        {children}
        {icon && (
            <Icon className={classes.icon} name={icon} />
        )}
    </div>;
};
