import React from 'react';
import { View } from './view';
import { TabIconOwnProps, TabIconProps } from './model';
import { ArrowSmallDownIcon, ArrowSmallUpIcon } from '../../../../../assets/icons';

export const TabIcon: React.FC<TabIconProps> = ({
    isOpen,
}): JSX.Element | null => {
    const arrowUp = React.createElement(ArrowSmallUpIcon);
    const arrowDown = React.createElement(ArrowSmallDownIcon);

    const getPropsImage = (): JSX.Element | null => {
        return isOpen ? arrowUp : arrowDown;
    };

    const props: TabIconOwnProps = {
        image: getPropsImage(),
    };

    return View(props);
};
