import React, { useState } from 'react';
import { Props } from './model';
import { View } from './view';

export const DropdownMenu: React.FC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const onClickButton = () => {
        setIsOpen(!isOpen);
    };

    return View({
        ...props,
        isOpen,
        onClickButton,
    });
};
