import { Column } from '@softmedialab/materialui-table';
import classNames from 'classnames';
import { angleRadToDeg, multiplierNum, MultiplierType, roundNumber } from '../../../../../common/shared/utils/math';
import { MeterReadingInstantProfileViewModel } from '../../../../../common/model/meter/meter/meterReadingInstantProfileViewModel';
import { MeterModelViewModel } from '../../../../../common/model/meter/meterModel/meterModelViewModel';
import { InstantProfileTableData } from './model';

const fraction = 3;

const roundAndTransformOrReturnEmpty = (num: number, multiplier: MultiplierType = null): string | number => {
    if (num === undefined || num === null) {
        return '–';
    }
    if (multiplier !== null) {
        return roundNumber(multiplierNum[multiplier] * num, fraction);
    }
    return roundNumber(num, fraction);
};

const getTriplePhaseInstantProfileData = (profile: MeterReadingInstantProfileViewModel): InstantProfileTableData[] => {
    return [
        {
            parameter: 'P, Вт',
            valueA: roundAndTransformOrReturnEmpty(profile?.signedActivePowerA, MultiplierType.Kilo),
            valueB: roundAndTransformOrReturnEmpty(profile?.signedActivePowerB, MultiplierType.Kilo),
            valueC: roundAndTransformOrReturnEmpty(profile?.signedActivePowerC, MultiplierType.Kilo),
            valueSum: roundAndTransformOrReturnEmpty(profile?.signedActivePower, MultiplierType.Kilo),
        },
        {
            parameter: 'Q, Вар',
            valueA: roundAndTransformOrReturnEmpty(profile?.signedReactivePowerA, MultiplierType.Kilo),
            valueB: roundAndTransformOrReturnEmpty(profile?.signedReactivePowerB, MultiplierType.Kilo),
            valueC: roundAndTransformOrReturnEmpty(profile?.signedReactivePowerC, MultiplierType.Kilo),
            valueSum: roundAndTransformOrReturnEmpty(profile?.signedReactivePower, MultiplierType.Kilo),
        },
        {
            parameter: 'S, ВА',
            valueA: roundAndTransformOrReturnEmpty(profile?.apparentPowerA, MultiplierType.Kilo),
            valueB: roundAndTransformOrReturnEmpty(profile?.apparentPowerB, MultiplierType.Kilo),
            valueC: roundAndTransformOrReturnEmpty(profile?.apparentPowerC, MultiplierType.Kilo),
            valueSum: roundAndTransformOrReturnEmpty(profile?.apparentPower, MultiplierType.Kilo),
        },
        {
            parameter: 'Cos',
            valueA: roundAndTransformOrReturnEmpty(profile?.cosA),
            valueB: roundAndTransformOrReturnEmpty(profile?.cosB),
            valueC: roundAndTransformOrReturnEmpty(profile?.cosC),
            valueSum: roundAndTransformOrReturnEmpty(profile?.cos),
        },
        {
            parameter: 'Сдвиг фаз (U, I)',
            valueA: roundAndTransformOrReturnEmpty(profile?.angleBetweenAB),
            valueB: roundAndTransformOrReturnEmpty(profile?.angleBetweenBC),
            valueC: roundAndTransformOrReturnEmpty(profile?.angleBetweenAC),
            valueSum: null,
        },
        {
            parameter: 'Угол, град. (U, U)',
            valueA: roundAndTransformOrReturnEmpty(profile?.phaseShiftA),
            valueB: roundAndTransformOrReturnEmpty(profile?.phaseShiftB),
            valueC: roundAndTransformOrReturnEmpty(profile?.phaseShiftC),
            valueSum: null,
        },
        {
            parameter: 'I, мА',
            valueA: roundAndTransformOrReturnEmpty(profile?.currentA, MultiplierType.Kilo),
            valueB: roundAndTransformOrReturnEmpty(profile?.currentB, MultiplierType.Kilo),
            valueC: roundAndTransformOrReturnEmpty(profile?.currentC, MultiplierType.Kilo),
            valueSum: null,
        },
        {
            parameter: 'Uф, В',
            valueA: roundAndTransformOrReturnEmpty(profile?.voltageA, MultiplierType.Kilo),
            valueB: roundAndTransformOrReturnEmpty(profile?.voltageB, MultiplierType.Kilo),
            valueC: roundAndTransformOrReturnEmpty(profile?.voltageC, MultiplierType.Kilo),
            valueSum: null,
        },
        {
            parameter: 'Uл, В',
            valueA: roundAndTransformOrReturnEmpty(profile?.voltageAB, MultiplierType.Kilo),
            valueB: roundAndTransformOrReturnEmpty(profile?.voltageBC, MultiplierType.Kilo),
            valueC: roundAndTransformOrReturnEmpty(profile?.voltageAC, MultiplierType.Kilo),
            valueSum: null,
        },
        {
            parameter: 'Небаланс токов в фазном и нулевом проводах',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.currentsImbalance),
        },
        {
            parameter: 'Частота сети',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.frequency),
        },
    ];
};

const getSinglePhaseInstantProfileData = (profile: MeterReadingInstantProfileViewModel): InstantProfileTableData[] => {
    return [
        {
            parameter: 'P, Вт',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.signedActivePower, MultiplierType.Kilo),
        },
        {
            parameter: 'Q, Вар',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.signedReactivePower, MultiplierType.Kilo),
        },
        {
            parameter: 'S, ВА',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.apparentPower, MultiplierType.Kilo),
        },
        {
            parameter: 'Cos',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.cos),
        },
        {
            parameter: 'Сдвиг фаз (U, I)',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.phaseShiftA),
        },
        {
            parameter: 'Угол, град. (U, U)',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: null,
        },
        {
            parameter: 'I, мА',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.current, MultiplierType.Kilo),
        },
        {
            parameter: 'Uф, В',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.voltage, MultiplierType.Kilo),
        },
        {
            parameter: 'Uл, В',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: null,
        },
        {
            parameter: 'Небаланс токов в фазном и нулевом проводах',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.currentsImbalance),
        },
        {
            parameter: 'Частота сети',
            valueA: null,
            valueB: null,
            valueC: null,
            valueSum: roundAndTransformOrReturnEmpty(profile?.frequency),
        },
    ];
};

const darkCellClassName = (additionalClass: string) => (data: any) => {
    return classNames(additionalClass, data === null ? 'dark-cell' : '');
};

const getTriplePhaseInstantProfileColumns = (): Column[] => {
    return [
        {
            title: 'Параметр',
            field: 'parameter',
            cellClassName: darkCellClassName('col-parameter'),
        },
        {
            title: 'A (A-B)',
            field: 'valueA',
            cellClassName: darkCellClassName('col-valueA'),
        },
        {
            title: 'B (B-C)',
            field: 'valueB',
            cellClassName: darkCellClassName('col-valueB'),
        },
        {
            title: 'C (C-A)',
            field: 'valueC',
            cellClassName: darkCellClassName('col-valueC'),
        },
        {
            title: 'Сумма фаз',
            field: 'valueSum',
            cellClassName: darkCellClassName('col-valueSum'),
        },
    ];
};

const getSinglePhaseInstantProfileColumns = (): Column[] => {
    return [
        {
            title: 'Параметр',
            field: 'parameter',
            cellClassName: darkCellClassName('col-parameter'),
        },
        {

            title: 'Сумма фаз',
            field: 'valueSum',
            cellClassName: darkCellClassName('col-valueSumAll'),
        },
    ];
};

export const getInstantProfileDataColumns = (
    meterModel: MeterModelViewModel,
    profile: MeterReadingInstantProfileViewModel
): [
    InstantProfileTableData[],
    Column[],
] => {
    const data: InstantProfileTableData[] = meterModel?.isSinglePhase
        ? getSinglePhaseInstantProfileData(profile)
        : getTriplePhaseInstantProfileData(profile);
    const columns: Column[] = meterModel?.isSinglePhase
        ? getSinglePhaseInstantProfileColumns()
        : getTriplePhaseInstantProfileColumns();
    return [
        data,
        columns,
    ];
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const PI2 = 2 * Math.PI;

export const getPhaseVectorDiagramChartVectors = (
    meterModel: MeterModelViewModel,
    profile: MeterReadingInstantProfileViewModel
) => {
    return meterModel.isSinglePhase
        ? [
            {
                label: 'Ua',
                angle: profile?.phaseShiftA
                    ? angleRadToDeg(profile.phaseShiftA % PI2)
                    : 0,
            },
            {
                label: 'Ub',
                angle: null,
            },
            {
                label: 'Uc',
                angle: null,
            },
        ]
        : [
            {
                label: 'Ua',
                angle: profile?.phaseShiftA
                    ? angleRadToDeg(profile.phaseShiftA % PI2)
                    : 0,
            },
            {
                label: 'Ub',
                angle: profile?.phaseShiftB
                    ? angleRadToDeg(profile.phaseShiftB % PI2)
                    : 0,
            },
            {
                label: 'Uc',
                angle: profile?.phaseShiftC
                    ? angleRadToDeg(profile.phaseShiftC % PI2)
                    : 0,
            }
        ];
};
