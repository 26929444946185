import { MeterActivationOrderSerialNumSuggestions } from '../../../../common/model/meter/meterActivationOrder/meterActivationOrderSerialNumSuggestions';
import { MeterActivationOrderViewModel } from '../../../../common/model/meter/meterActivationOrder/meterActivationOrderViewModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterActivationOrderListResponse } from '../../../../common/model/meter/meterActivationOrder/meterActivationOrderListResponse';


export interface MeterActivationOrdersListState {
    available: MeterActivationOrderViewModel[];
    total: number;
    serialNumSuggestions: MeterActivationOrderSerialNumSuggestions;
    isFilterVisible: boolean;
}

const initialState: MeterActivationOrdersListState = {
    available: null,
    total: 0,
    serialNumSuggestions: [],
    isFilterVisible: false,
};

export const meterActivationOrdersListSlice = createSlice({
    name: '@@vv/meterActivationOrders/list',
    initialState,
    reducers: {
        setAvailableMeterActivationOrders(state, action: PayloadAction<MeterActivationOrderListResponse>) {
            state.available = action.payload.data;
            state.total = action.payload.total;
        },
        setSerialNumSuggestions(state, action: PayloadAction<MeterActivationOrderSerialNumSuggestions>) {
            state.serialNumSuggestions = action.payload;
        },
        setFilterVisibility(state, action: PayloadAction<boolean>) {
            state.isFilterVisible = action.payload;
        },
    }
});
