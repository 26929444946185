import { Box, makeStyles } from '@material-ui/core';
import { CrqChannels } from 'app/content/crqChannels';
import { ReadingSource } from 'common/model/meter/readingSource';
import React from 'react';
import {
    MeterStatus,
    meterStatusDictionary,
} from '../../../common/model/meter/meterStatus';
import { MeterPageUIConfig } from '../../../redux/reducers/meters/details/ui';
import { Badge } from '../../shared/components/badge';
import { BadgeState } from '../../shared/components/badge/model';
import { Breadcrumbs } from '../../shared/components/breadcrumbs';
import { Heading } from '../../shared/components/heading';
import { Icon } from '../../shared/components/icon';
import { Spinner } from '../../shared/components/spinner';
import { TabMenu } from '../../shared/components/tabMenu';
import { TabMenuItem } from '../../shared/components/tabMenuItem';
import { Tooltip } from '../../shared/components/tooltip';
import { Typography } from '../../shared/components/typography';
import { MeterTabs, Routes } from '../../shared/constants';
import { EventLog } from '../eventLog';
import { GisTasks } from '../relatedSystems/gisTasks';
import { MeterActions } from './actions';
import { Consumption } from './consumption';
import { Gis } from './gis';
import { Information } from './information';
import { MeterActionPlan } from './information/plan';
import { Management } from './management';
import { IStateProps, MeterTabsCommonProps } from './model';
import { Problems } from './problems';
import { Reading } from './reading';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.hint,
        marginLeft: '16px',
        paddingTop: '4px',
    },
    contentContainer: {
        paddingTop: '17px',
    },
}));

const isTabVisible = (tab: MeterTabs, uiConfig: MeterPageUIConfig): boolean => {
    return uiConfig.tabs[tab];
};

const isActiveTabVisible = (
    activeTab: MeterTabs,
    tab: MeterTabs,
    uiConfig: MeterPageUIConfig
): boolean => {
    return isTabVisible(tab, uiConfig) && activeTab === tab;
};

const badgeStateByMeterStatus: Record<MeterStatus, BadgeState> = {
    [MeterStatus.Defective]: 'fail',
    [MeterStatus.Active]: 'success',
    [MeterStatus.ActivationError]: 'fail',
    [MeterStatus.Created]: 'notDetermined',
    [MeterStatus.Decommissioned]: 'notDetermined',
};

export const View = ({
    meter,
    tabs,
    activeTab,
    onChangeTab,
    breadcrumbs,
    onClickBreadcrumb,
    uiConfig,
}: IStateProps) => {
    const classes = useStyles();
    if (!meter) {
        return <Spinner />;
    }

    const isPartnerMeter = meter.readingSource !== ReadingSource.Reading;

    const commonProps: MeterTabsCommonProps = {
        meterId: meter.id,
    };

    return (
        <div className="meter-page">
            <Box mb={2} marginBottom="0">
                <Breadcrumbs
                    breadcrumbs={breadcrumbs}
                    onClickBreadcrumb={onClickBreadcrumb}
                />
            </Box>
            <Box display="flex" alignItems="center" marginTop="8px">
                <Heading heading="Прибор учета" defaultBackUrl={Routes.meterSearch} />
                <Typography variant="h4" className={classes.title}>
                    {meter.meterModel} №{meter.meterSerialNum}
                </Typography>
                {isPartnerMeter && (
                    <Box ml={1.5}>
                        <Tooltip text={meter.partner?.name}>
                            <Icon width={32} height={32} name="partnersMeterIcon" />
                        </Tooltip>
                    </Box>
                )}
                <Box ml={1.5}>
                    <Badge
                        state={badgeStateByMeterStatus[meter.status]}
                        text={meterStatusDictionary[meter.status]}
                    />
                </Box>
                <Box marginLeft="auto">
                    <MeterActions />
                </Box>
            </Box>

            <TabMenu
                value={activeTab || MeterTabs.Information}
                onChange={onChangeTab}
            >
                {tabs
                    .filter((item) => isTabVisible(item.key, uiConfig))
                    .map((tab) => (
                        <TabMenuItem label={tab.title} key={tab.key} value={tab.key} />
                    ))}
            </TabMenu>

            <Box py={3} className={classes.contentContainer}>
                {isActiveTabVisible(activeTab, MeterTabs.Information, uiConfig) && (
                    <Information {...commonProps} />
                )}
                {isActiveTabVisible(activeTab, MeterTabs.Polls, uiConfig) && (
                    <MeterActionPlan {...commonProps} />
                )}
                {isActiveTabVisible(activeTab, MeterTabs.Reading, uiConfig) && (
                    <Reading {...commonProps} />
                )}
                {isActiveTabVisible(activeTab, MeterTabs.Consumption, uiConfig) && (
                    <Consumption {...commonProps} />
                )}
                {isActiveTabVisible(activeTab, MeterTabs.Gis, uiConfig) && <Gis />}
                {isActiveTabVisible(activeTab, MeterTabs.EventLog, uiConfig) && (
                    <EventLog {...commonProps} />
                )}
                {isActiveTabVisible(activeTab, MeterTabs.Management, uiConfig) && (
                    <Management {...commonProps} />
                )}
                {isActiveTabVisible(activeTab, MeterTabs.CrqInterface, uiConfig) &&
          meter.readingSource === ReadingSource.ImportCrq && (
                    <CrqChannels
                        meterId={meter.id}
                        partnerId={meter.partnerId?.toString()}
                        isUpdate
                    />
                )}
                {isActiveTabVisible(activeTab, MeterTabs.Problems, uiConfig) && (
                    <Problems
                        meterId={meter.id}
                        foreignId={meter.foreignId}
                        address={meter.address.address}
                    />
                )}
                {isActiveTabVisible(activeTab, MeterTabs.Incidents, uiConfig) && (
                    <GisTasks
                        foreignId={meter.foreignId}
                        address={meter.address.address}
                    />
                )}
            </Box>
        </div>
    );
};
