import { push } from 'connected-react-router';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BaseDispatch } from '../../../redux/actions';
import { getTimeSynchronizationSchemes } from '../../../redux/actions/timeSynchronizationSchemes';
import { timeSynchronizationSchemesListAvailableSelector } from '../../../redux/selectors/timeSynchronizationSchemes';
import { AdministrationTabs, Routes } from '../../shared/constants';
import { getRoute } from '../../shared/pipes';

export const TimeSynchronizationSchemeResolver = (viewComponent: React.FC<any>) => {
    return (props: any) => {
        const dispatch = useDispatch<BaseDispatch>();
        const schemes = useSelector(timeSynchronizationSchemesListAvailableSelector);
        const { schemeId } = useParams<{ schemeId: string; }>();

        const scheme = useMemo(() => (schemes ?? []).find(item => item.id === +schemeId), [schemes, schemeId]);

        React.useEffect(() => {
            if (!schemes) {
                dispatch(getTimeSynchronizationSchemes());
            }
        }, [schemes]);

        React.useEffect(() => {
            if (!!schemes && !scheme) {
                dispatch(push(getRoute(Routes.administration, {}, { tab: AdministrationTabs.TimeSynchronizationSchemes })));
            }
        }, [scheme]);

        if (!scheme) {
            return null;
        }

        return React.createElement(viewComponent, {
            ...props,
            scheme,
        });
    };
};
