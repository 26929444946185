import { Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../../shared/components/button';
import { HookField } from '../../../../../shared/components/hookField';
import { SelectSuggestion } from '../../../../../shared/components/select/model';
import { Typography } from '../../../../../shared/components/typography';
import { IStateProps } from './model';

export const useUpdateFromAttributesStyles = makeStyles((theme) => ({
    pristineField: {
        color: theme.palette.neutral.light,
    },
    inputLabel: {
        textAlign: 'right',
    },
    buttonContainer: {
        paddingTop: '16px',
    },
    actionsModalContainer: {
        minWidth: '560px',
    },
    actionModalMiddleContainer: {
        minWidth: '700px',
    },
    actionsModalBigContainer: {
        minWidth: '840px',
    },
}));

export const View: React.FC<IStateProps> = ({
    form,
    onSubmit,
    onCancel,
    meterActionSchemes,
    timeSynchronizationSchemes,
    isImportCrq
}) => {
    const classes = useUpdateFromAttributesStyles();
    const { handleSubmit, formState: { isDirty, dirtyFields } } = form;

    const meterActionSchemesSuggestion: SelectSuggestion[] = meterActionSchemes
        .map(item => ({ label: item.name, value: item.id }));

    const timeSynchronizationSchemesSuggestion: SelectSuggestion[] = timeSynchronizationSchemes
        .map(item => ({ label: item.name, value: item.id }));

    return (
        <FormProvider { ...form }>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Схема<br />опроса</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='meterActionSchemeId'
                                    type='select'
                                    label='Название'
                                    suggestions={meterActionSchemesSuggestion}
                                    inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['meterActionSchemeId'] }) }}
                                    fullWidth />
                            </Grid>
                        </Grid>

                    </Grid>
                    {!isImportCrq && (
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item xs={4} className={classes.inputLabel}>
                                    <Typography variant='bodyBold'>Схема<br />синхронизации</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <HookField
                                        name='timeSynchronizationSchemeId'
                                        type='select'
                                        label='Название'
                                        suggestions={timeSynchronizationSchemesSuggestion}
                                        isClearable
                                        inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['timeSynchronizationSchemeId'] }) }}
                                        fullWidth />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                            <Grid item>
                                <Button variant='primary' type='submit' disabled={!isDirty} icon='check'>Сохранить</Button>
                            </Grid>
                            <Grid item>
                                <Button variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
