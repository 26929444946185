import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { CircleProgress } from '../../circleProgress';
import { Icon } from '../../icon';
import { Tooltip } from '../../tooltip';
import { UploaderFileProps, UploaderFileState } from './model';

const useStyles = makeStyles((theme) => ({
    file: {
        ...theme.typography.body,
        position: 'relative',
        marginBottom: 12,
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: theme.palette.neutral.background,
        },
    },
    fileNameWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    fileName: {
        position: 'relative',
        wordBreak: 'break-word',
        display: 'block',
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& svg': {
            width: 20,
            height: 20,
        }
    },
    deleteButton: {
        '$file:not(:hover) &': {
            opacity: 0,
        },
        marginLeft: 5
    },
    errorText: {
        ...theme.typography.caption,
        color: theme.palette.error.main,
        overflow: 'hidden',
    },
    errorIcon: {
        color: theme.palette.error.main,
        marginLeft: 5
    },
    successIcon: {
        color: theme.palette.success.main,
        marginLeft: 5
    },
}));

export const View: React.FC<UploaderFileProps> = (props) => {
    const classes = useStyles();
    const { file, onDelete } = props;
    const error = file.state === UploaderFileState.error;

    const indicator = useMemo(() => {
        switch (file.state) {
            case UploaderFileState.uploaded: {
                return (
                    <span className={classes.action}>
                        <Icon className={classes.successIcon} name='success' />
                    </span>
                );
            }
            case UploaderFileState.error: {
                return (
                    <Tooltip text={file.message ? file.message : 'Error message'}>
                        <span className={classes.action}>
                            <Icon className={classes.errorIcon} name='warning' />
                        </span>
                    </Tooltip>
                );
            }
            case UploaderFileState.new: {
                return (
                    <Tooltip text='Удалить'>
                        <span
                            onClick={() => onDelete(file.id)}
                            className={classNames(classes.action, classes.deleteButton)}>
                            <Icon name='delete' />
                        </span>
                    </Tooltip>
                );
            }
            case UploaderFileState.uploading: {
                return (
                    <CircleProgress />
                );
            }
            default: {
                return null;
            }
        }
    }, [file.state]);

    return <span className={classNames(classes.file)}>
        <div className={classes.fileNameWrapper}>
            <span className={classes.fileName}>
                {file.file.name}
            </span>
            {indicator}
        </div>
        {error && (
            <div className={classes.errorText}>
                {file.message}
            </div>
        )}
    </span>;
};
