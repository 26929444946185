import { useSyncQueryParams } from 'app/shared/hooks/useQueryParams';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StorageState } from 'redux/reducers';
import { columnNamesByCategory } from '../../constant';
import { MeterHealthQueryParams } from '../../queryParams';

import { View } from './view';

export const ErrorDetalization = () => {
    const metersErrorDetalizations = useSelector((state: StorageState) => state.monitoring.metersHealth.errorDetalization);
    const meterLabelTypes = useSelector((state: StorageState) => state.meterLabelType.list.available);

    const [filterParams, updateFilterParams] = useSyncQueryParams<MeterHealthQueryParams>({
        regionId: null,
        readingSource: null,
        metersHealthActiveTypeId: null,
        metersHealthActiveCategoryId: null,
    });

    const { metersHealthActiveCategoryId } = filterParams;
    const onMoreClick = (typeId: number) => {
        updateFilterParams({
            metersHealthActiveTypeId: typeId,
        });
    };

    const onSliderClick = (id: number) => {
        updateFilterParams({
            metersHealthActiveCategoryId: id,
            metersHealthActiveTypeId: null,
        });
    };

    const [prevId, nextId] = useMemo(() => {
        if (!metersHealthActiveCategoryId || !metersErrorDetalizations?.length) {
            return [null, null];
        }

        const index = columnNamesByCategory.findIndex(data => data.categoryId === metersHealthActiveCategoryId);

        const prevId = index === 0
            ? null
            : columnNamesByCategory[index - 1].categoryId;
        const nextId = index === columnNamesByCategory.length - 1
            ? null
            : columnNamesByCategory[index + 1].categoryId;

        return [prevId, nextId];
    }, [metersHealthActiveCategoryId, metersErrorDetalizations]);

    const title = useMemo(() => {
        if (!metersHealthActiveCategoryId) {
            return null;
        }

        const title = columnNamesByCategory?.find(item => item.categoryId === metersHealthActiveCategoryId)?.label;
        return title;
    }, [metersHealthActiveCategoryId]);

    const data = useMemo(() => {
        return meterLabelTypes
            .filter(type => type.meterLabelCategoryId === metersHealthActiveCategoryId)
            .map(type => {
                const typeData = metersErrorDetalizations.find(item => item.label === type.id);
                return {
                    typeId: type.id,
                    label: type.taskTitle,
                    count: typeData?.count ?? 0,
                    created: typeData?.created ?? 0,
                    opened: typeData?.opened ?? 0
                };
            });
    }, [metersHealthActiveCategoryId, metersErrorDetalizations, meterLabelTypes]);

    return View({
        categoryId: metersHealthActiveCategoryId,
        onMoreClick,
        data,
        title,
        nextId,
        prevId,
        onSliderClick
    });
};
