import React, { CSSProperties, MutableRefObject } from 'react';
import { IStateProps, MultiSelectValue } from './model';
import './style.scss';
import Paper from '@material-ui/core/Paper';
import { CloseIcon } from '../../../../assets/icons';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { List } from 'react-virtualized';
import { ListRowRenderer } from 'react-virtualized/dist/es/List';
import { numDeclinationPipe } from '../../pipes';
import { EllipsisTooltip } from '../ellipsisTooltip';
import { Checkbox } from '@material-ui/core';
import { Icon } from '../icon';

const MultiselectDropdownDefaultWidth = 300;
const defaultMaxVisibleSelections = 0;

interface RenderItemViewProps {
    item: MultiSelectValue;
    checked: boolean;
    disabled: boolean;
    ref: MutableRefObject<HTMLDivElement>;
    style: CSSProperties;
    onClick: () => void;
    onChangeCheckbox: () => void;
}

const RenderItemView: React.FC<RenderItemViewProps> = (props) => {
    const {
        item,
        checked,
        disabled,
        style,
        ref,
        onClick,
        onChangeCheckbox,
    } = props;
    return (
        <div
            style={style}
            tabIndex={-1}
            ref={ref}
            className={classNames('multiselect-dropdown__value', {
                'multiselect-dropdown__value--focused': checked
            })}
            onClick={onClick}
        >
            <Checkbox
                tabIndex={-1}
                checked={checked}
                disabled={disabled}
                onChange={onChangeCheckbox} />
            <EllipsisTooltip text={item.label}>
                {item.label}
            </EllipsisTooltip>
        </div>
    );
};

export const View = (props: IStateProps) => {

    const {
        focusedItem,
        setFocusedItem,
        searchInputRef,
        blockInputRef,
        listRef,
        valuesRef,
        onKeyDownInput,
        lastValuesIndex,
        selectAll,
        toggleSelectAll,
        onReset,
        openSelect,
        closeSelect,
        outsideClickRef,
        focusInput,
        onInputSearch,
        active,
        isClearable,
        value,
        required,
    } = props;
    const selectedValues = props.originalValues?.filter(item => value.includes(item.value));
    const isValueSelected = selectedValues?.length > 0;
    const maxVisibleSelections = props.maxVisibleSelections !== undefined
        ? props.maxVisibleSelections
        : defaultMaxVisibleSelections;
    const showFullValue = isValueSelected && selectedValues.length <= maxVisibleSelections;
    const showShortValue = isValueSelected && selectedValues.length > maxVisibleSelections;
    const selectedCount = selectedValues?.length || 0;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const visibleCount = props.dropdownVisibleCount ?? 4;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const listHeight = ((props.values?.length > visibleCount
        ? visibleCount
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        : (props.values?.length > 0 ? props.values?.length : 1)
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    ) * 46) + 9;

    const isValueDisabled = (val: any) => Array.isArray(props.disabledValues) && props.disabledValues.includes(val);

    const renderRowItem: ListRowRenderer = ({
        key,
        index,
        style,
    }) => {
        const item = props.values[index];
        const checked = value.includes(item.value);
        const disabled = isValueDisabled(item.value);
        return (
            <RenderItemView
                item={item}
                checked={checked}
                disabled={disabled}
                key={key}
                style={style}
                ref={valuesRef.current[index]}
                onChangeCheckbox={() => {
                    props.changeValue(item.value);
                }}
                onClick={() => {
                    if (!disabled) {
                        setFocusedItem(index);
                        props.changeValue(item.value);
                    }
                }}
            />
        );
    };

    const isAllSelected = value.length > 0 && value.length === props.values.length;

    return (
        <div
            ref={outsideClickRef}
            className={classNames(
                'multiselect-dropdown',
                {
                    'multiselect-dropdown--error': props.error,
                    'multiselect-dropdown--disabled': props.disabled,
                    'multiselect-dropdown--active': props.active,
                    'multiselect-dropdown--dark': props.dark,
                }
            )}>
            <div
                ref={blockInputRef}
                onClick={() => {
                    focusInput();
                    if (active) {
                        closeSelect();
                    } else {
                        openSelect();
                    }
                }}
                onKeyDown={onKeyDownInput}
                tabIndex={props.searchable ? -1 : 0}
                className='multiselect-dropdown__input'
                onFocus={() => {
                    if (!props.searchable && !props.active) {
                        openSelect();
                    }
                }}
            >
                {
                    (selectedCount > 0 || props.search?.length > 0)
                    && (
                        <div className={classNames('top-label', { 'top-label-focused': props.active })}>
                            {props.placeholder}
                        </div>
                    )
                }
                {showFullValue && (
                    <div className='multiselect-dropdown__selected'>
                        {selectedValues.map((item => (
                            <div
                                key={item.value}
                                className={classNames('multiselect-dropdown__selected__value', { 'disabled': isValueDisabled(item.value) })}
                            >
                                <EllipsisTooltip text={item.label}>{item.label}</EllipsisTooltip>
                                <IconButton
                                    tabIndex={-1}
                                    className='multiselect-dropdown__selected__value__delete'
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.changeValue(item.value);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        )))}
                    </div>
                )}
                {showShortValue && (
                    `${selectedCount} ${numDeclinationPipe(selectedCount, ['выбран', 'выбрано', 'выбрано'])}`
                )}
                {props.searchable && (
                    <input
                        ref={searchInputRef}
                        tabIndex={0}
                        type='text'
                        value={props.search}
                        placeholder={!isValueSelected && props.search === '' ? props.placeholder : ''}
                        className='multiselect-dropdown__input__search'
                        onChange={onInputSearch}
                        autoComplete='off'
                        autoCorrect='off'
                        onKeyDown={onKeyDownInput}
                        required={required}
                    />
                )}
                <div className='multiselect-dropdown__input__icon-wrapper'>
                    {(value?.length > 0) && isClearable &&
                        <span className='multiselect-dropdown__input__icon'>
                            <Icon
                                name='close'
                                fontSize='small'
                                onMouseDown={() => onReset()}
                                className='multiselect-dropdown__input__icon-clear'
                                width='15px'
                            />
                        </span>
                    }
                    <span className='multiselect-dropdown__input__icon'>
                        <Icon
                            name='arrowSmallDown'
                            width='20px'
                            height='20px'
                            className={classNames('dropdownArrow', {
                                'dropdownArrowRotate': props.active,
                            })}
                        />
                    </span>
                </div>
            </div>
            <div className={classNames(
                'multiselect-dropdown__dropdown',
                {
                    active: props.active
                }
            )}>
                <Paper>
                    {selectAll && (
                        <div>
                            <div
                                tabIndex={0}
                                ref={valuesRef?.current[lastValuesIndex]}
                                onKeyPress={toggleSelectAll}
                                onClick={toggleSelectAll}
                                className={classNames(
                                    'multiselect-dropdown__value',
                                    {
                                        'multiselect-dropdown__value--focused': focusedItem === lastValuesIndex,
                                    }
                                )}>
                                <Checkbox
                                    tabIndex={-1}
                                    checked={isAllSelected}
                                    disabled={false}
                                    onChange={toggleSelectAll} />
                                <EllipsisTooltip text={'Выбрать все'}>
                                    Выбрать все
                                </EllipsisTooltip>
                            </div>
                        </div>
                    )}
                    <div className='multiselect-dropdown__dropdown__list'>
                        {(props.values?.length > 0 && props.active) && (
                            <List
                                tabIndex={-1}
                                ref={listRef}
                                rowRenderer={renderRowItem}
                                width={outsideClickRef.current?.clientWidth || MultiselectDropdownDefaultWidth}
                                height={listHeight}
                                rowCount={props.values?.length}
                                rowHeight={46} />
                        )}
                    </div>
                </Paper>
            </div>
            <div className='multiselect-dropdown__input__helper'>
                {props.helperText}
            </div>
        </div>
    );
};
