import { combineReducers } from 'redux';
import { meterFirmwareVersionsListSlice, MeterFirmwareVersionListState } from './list';

export interface MeterFirmwareVersionsState {
    list: MeterFirmwareVersionListState;
}

export const meterFirmwareVersions = combineReducers<MeterFirmwareVersionsState>({
    list: meterFirmwareVersionsListSlice.reducer,
});
