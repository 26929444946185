import { combineReducers } from 'redux';
import { tariffScheduleEditableSlice, TariffScheduleEditableState } from './editable';
import { tariffScheduleListSlice, TariffScheduleListState } from './list';
import { tariffScheduleOrdersSlice, TariffScheduleOrdersState } from './orders';

export interface TariffScheduleState {
    list: TariffScheduleListState;
    editable: TariffScheduleEditableState;
    orders: TariffScheduleOrdersState;
}

export const tariffSchedule = combineReducers<TariffScheduleState>({
    list: tariffScheduleListSlice.reducer,
    editable: tariffScheduleEditableSlice.reducer,
    orders: tariffScheduleOrdersSlice.reducer,
});
