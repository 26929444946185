import { combineReducers } from 'redux';
import { TimeSynchronizationSchemesListState, timeSynchronizationSchemesSlice } from './list';

export interface TimeSynchronizationSchemesState {
    list: TimeSynchronizationSchemesListState;
}

export const timeSynchronizationSchemes = combineReducers({
    list: timeSynchronizationSchemesSlice.reducer,
});
