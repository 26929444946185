import { DailyProfilesState, dailyProfiles } from './dailyProfiles';
import { combineReducers } from 'redux';
import { incrementalProfiles, IncrementalProfilesState } from './incrementalProfiles';
import { meterReadingInstantSlice, MeterReadingInstantState } from './instantProfiles';


export interface MeterReadingState {
    dailyProfiles: DailyProfilesState;
    incrementalProfiles: IncrementalProfilesState;
    instantProfiles: MeterReadingInstantState;
}

export const meterReading = combineReducers<MeterReadingState>({
    dailyProfiles,
    incrementalProfiles,
    instantProfiles: meterReadingInstantSlice.reducer,
});
