export enum MeterAccountType {
    Commercial = "Коммерческий",
    Technical = "Технический",
    Calculated = "Расчетный",
}

export const meterAccountTypeDictionary: Record<MeterAccountType, string> = {
    [MeterAccountType.Commercial]: MeterAccountType.Commercial,
    [MeterAccountType.Technical]: MeterAccountType.Technical,
    [MeterAccountType.Calculated]: MeterAccountType.Calculated,
};
