import React from 'react';
import { PowerSwitchOrdersFilterFormProps } from './model';
import View from './view';

export const PowerSwitchOrdersFilterForm: React.FC<PowerSwitchOrdersFilterFormProps> = (props) => {
    const { initialFilterValues, form } = props;
    const onResetForm = () => {
        form.reset(initialFilterValues);
        props.onSubmit(initialFilterValues);
    };

    return View({
        ...props,
        onResetForm,
    });
};
