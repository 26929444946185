import { StorageState } from '../../reducers';
import { createSelector } from 'reselect';
import * as _ from 'lodash';

const baseSelector = (state: StorageState) => state.resources;

export const resourcesSelector = () => createSelector(baseSelector, resources => ({
    D: resources.dictionaries,
    settings: resources.settings,
    regions: resources.regions
}));

export const resourcesSpecialDayBasesSelector = () => createSelector(
    baseSelector,
    resources => resources.dictionaries?.specialDayBases || [],
);

export const resourcesUserRolesSelector = () => createSelector(
    baseSelector,
    resources => resources.dictionaries?.userRoles || [],
);

export const resourcesAccessRulesSelector = () => createSelector(
    baseSelector,
    resources => resources.dictionaries?.accessRules || [],
);

export const resourcesSettingsSelector = () => createSelector(
    baseSelector,
    resources => resources.settings,
);

export const isTranslationEmptySelector = () => createSelector(
    resourcesSelector(),
    ({ D }) => Object.keys(D).length === 0
);

export const resourcesRegionsListSelector = () => createSelector(
    baseSelector,
    resources => resources.regions.list || [],
);

export const resourcesMeterModelsSelector = () => createSelector(
    baseSelector,
    resources => resources.dictionaries.meterModels || [],
);

export const resourcesMeterActionSchemesSelector = (isImportCrq?: boolean) => createSelector(
    baseSelector,
    resources => {
        const schemes = resources.dictionaries.meterActionSchemes || [];
        if (isImportCrq) {
            return schemes.filter(item => Boolean(item.isCrq));
        }
        return schemes;
    },
);

export const resourcesTimeSynchronizationSchemesSelector = () => createSelector(
    baseSelector,
    resources => resources.dictionaries.timeSynchronizationSchemes || [],
);

export const resourcesMeterFirmwareVersionsSelector = () => createSelector(
    baseSelector,
    resources => resources.dictionaries.meterFirmwareVersions || [],
);

export const resourcesNetworkOperatorsSelector = () => createSelector(
    baseSelector,
    resources => resources.dictionaries.networkOperators || [],
);

export const resourcesMeterActionSchemesByIdSelector = () => createSelector(
    resourcesMeterActionSchemesSelector(),
    meterActionSchemes => _.keyBy(meterActionSchemes, 'id'),
);

export const resourcesTimeSynchronizationSchemesByIdSelector = () => createSelector(
    resourcesTimeSynchronizationSchemesSelector(),
    timeSynchronizationSchemes => _.keyBy(timeSynchronizationSchemes, 'id'),
);

export const resourcesRegionsByIdSelector = () => createSelector(
    resourcesSelector(),
    ({ regions }) => regions.byId ?? {},
);


export const resourcesRegionByIdSelector = (id: number) => createSelector(
    resourcesSelector(),
    ({ regions }) => regions.byId[id] ?? null,
);
