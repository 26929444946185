import { Grid, Box } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { HookField } from '../../../shared/components/hookField';
import { Table } from '../../../shared/components/table';
import { Typography } from '../../../shared/components/typography';
import { meterLegalTypes } from '../../../shared/constants/enums';
import { useWebDictionarySelectSuggestions } from '../../../shared/hooks/useWebDictionary';
import { TariffScheduleForm } from './components/tariffScheduleForm';
import { IStateProps } from './model';
import { Wrapper } from '../../../shared/components/wrapper';
import { Button } from '../../../shared/components/button';
import { Modal } from '../../../shared/components/modal';

export const View: React.FC<IStateProps> = (props) => {

    const {
        form,
        data,
        columns,
        onClickNewSchedule,
        onClickOrdersList,
        onSubmitNewTariffSchedule,
        onCancelNewTariffSchedule,
        isNewTariffScheduleOpen,
    } = props;

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const actions = (
        <Grid container justify='space-between'>
            <Grid item lg={5}>
                <FormProvider {...form}>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <HookField
                                fullWidth
                                label='Регион'
                                type='select'
                                name='regionId'
                                isClearable
                                suggestions={regionSuggestions}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <HookField
                                fullWidth
                                label='Правовая форма'
                                type='select'
                                name='legalEntityType'
                                suggestions={meterLegalTypes}
                                isClearable
                            />
                        </Grid>
                    </Grid>
                </FormProvider>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            icon='add'
                            type='button'
                            variant='primary'
                            onClick={onClickNewSchedule}
                        >
                            Новое расписание
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            icon='tree'
                            type='button'
                            variant='outline'
                            onClick={onClickOrdersList}
                        >
                            Заявки на смену расписания
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Wrapper isBigHeader actions={actions}>
                <Table
                    title={null}
                    data={data || []}
                    columns={columns}
                    variant='white'
                    options={{
                        paging: 'disabled',
                        grouping: false,
                        filtering: false,
                        search: false,
                        toolbar: false,
                        showTitle: false,
                        defaultExpanded: true,
                    }}
                />
            </Wrapper>
            {isNewTariffScheduleOpen && (
                <Modal
                    title='Новое тарифное расписания'
                    onHide={onCancelNewTariffSchedule}
                >
                    <Box mb={3}>
                        <Typography variant='body'>
                            Укажите параметры нового тарифного расписания
                        </Typography>
                    </Box>
                    <TariffScheduleForm
                        onCancel={onCancelNewTariffSchedule}
                        onSubmit={onSubmitNewTariffSchedule}
                    />
                </Modal>
            )}
        </>
    );
};
