import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { AccessRule } from '../../../common/model/access/accessRule';
import { BaseDispatch } from '../../../redux/actions';
import { Routes } from '../../shared/constants';
import { useAccessRules } from '../../shared/hooks/useAccessRules';
import { getRoute } from '../../shared/pipes';
import { ReportsTabData, ReportType } from './model';
import { View, ViewReportRender } from './view';

export const Reports = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const { notHasAccess } = useAccessRules();

    return View({
        columns: [
            {
                title: 'Название отчёта',
                field: 'name',
                sorting: true,
                cellClassName: 'col-report-name',
            },
            {
                title: null,
                field: 'key',
                sorting: true,
                render: (data: ReportsTabData) => {
                    return ViewReportRender({
                        disabled: data.disabled,
                        onClick: async () => {
                            const defaultRoute = getRoute(
                                Routes.reportPage,
                                {
                                    reportType: data.key,
                                },
                                {},
                            );
                            await dispatch(push(defaultRoute));
                        }
                    });
                }
            }
        ],
        data: [
            {
                name: 'Перечень ПУ, с которыми нет связи',
                key: ReportType.MetersNotConnectedReport,
                disabled: notHasAccess(AccessRule.CanGetNotConnectedMeters),
            },
            {
                name: 'Профиль ПУ: А+А-R+R-',
                key: ReportType.MeterProfileReport,
                disabled: notHasAccess(AccessRule.CanGetReportsMeterProfile),
            },
            {
                name: 'Показания и расход электроэнергии за расчётный период',
                key: ReportType.MeterElectricityConsumptionReport,
                disabled: notHasAccess(AccessRule.CanGetReportsElectricityConsumption),
            },
            {
                name: 'Показания электроэнергии по тарифам',
                key: ReportType.MeterElectricityTariffReport,
                disabled: notHasAccess(AccessRule.CanGetReportsElectricityTariff),
            },
            {
                name: 'Отчёт по количеству сбоев',
                key: ReportType.CrashByRegionsReport,
                disabled: notHasAccess(AccessRule.CanGetReportsCrashByRegions),
            },
            {
                name: 'Отчёт о состоянии ПУ после диагностики',
                key: ReportType.ConfigurationHistoryReport,
                disabled: notHasAccess(AccessRule.CanGetReportsConfigurationHistory),
            },
            {
                name: 'Выгрузка для биллинга: показания и расход электроэнергии за расчетный период',
                key: ReportType.ElectricityConsumptionBillingReport,
                disabled: notHasAccess(AccessRule.CanGetReportsElectricityConsumptionBilling),
            },
        ].filter(x => !!x),
    });
};
