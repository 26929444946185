import { Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { Typography } from '../../typography';
import { TabListProps } from './model';

import './styles.scss';
import { TabListItemDeleteIcon } from './tabListItemDeleteIcon';
import classNames from 'classnames';

export const View: React.FC<TabListProps> = ({
    isOpen,
    handleClickItem,
    tabData,
    hoverTab,
    setHoverTab,
    anchorEl,
    handleClose,
    meterId
}): JSX.Element | null => {
    return (
        <Menu
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            className='main-tab-list-container'
        >
            {
                tabData.map(tab => (
                    <MenuItem
                        key={tab.id}
                        onMouseEnter={() => setHoverTab(tab)}
                        onMouseLeave={() => setHoverTab(null)}
                        className='tab-list-item-container'
                    >
                        <div
                            onClick={() => {
                                setHoverTab(null);
                                handleClickItem(tab.id, 'select');
                            }}
                            className={classNames('tab-list-item', { 'active-item': tab.id === meterId })}
                        >
                            <Typography variant='h4' className='tab-list-item-name'>{tab.name}</Typography>
                        </div>

                        <TabListItemDeleteIcon
                            tabId={tab.id}
                            activeTabId={hoverTab && hoverTab.id}
                            handleClickItem={handleClickItem}
                        />
                    </MenuItem>

                ))
            }
        </Menu>
    );
};
