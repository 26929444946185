import { combineReducers } from 'redux';
import { networkOperatorsListSlice, NetworkOperatorsListState } from './list';

export interface NetworkOperatorsState {
    list: NetworkOperatorsListState;
}

export const networkOperators = combineReducers<NetworkOperatorsState>({
    list: networkOperatorsListSlice.reducer,
});
