import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AccessRule } from '../../../common/model/access/accessRule';
import { resourcesAccessRulesSelector } from '../../../redux/selectors';
import { availableAccessRulesSelector } from '../../../redux/selectors/access/accessRules';
import * as _ from 'lodash';

export const useAccessRules = () => {
    const accessRules = useSelector(availableAccessRulesSelector());
    const accessRuleDictionary = useSelector(resourcesAccessRulesSelector());

    const accessState = useMemo(() => {
        return Object.values(AccessRule).reduce((prev, current) => ({
            ...prev,
            [current]: accessRules.includes(current),
        }), {});
    }, [accessRules]);

    const dictionaryAccessState = useMemo(() => {
        return _.mapKeys(accessRuleDictionary, 'accessRule');
    }, [accessRuleDictionary]);

    return {
        dictionaryAccessState,
        hasAccess: (accessRule: AccessRule) => {
            return accessState[accessRule];
        },
        notHasAccess: (accessRule: AccessRule) => {
            return !accessState[accessRule];
        },
    };
};
