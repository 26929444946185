import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ElectricityTariffReportResponse } from '../../../../common/model/meter/reports/electricityTariffReport';

export interface ElectricityTariffReportState {
    available: ElectricityTariffReportResponse;
}

const initialState: ElectricityTariffReportState = {
    available: null,
};

export const electricityTariffReportSlice = createSlice({
    name: '@@vv/reports/electricityTariffReport',
    initialState,
    reducers: {
        setReport(state, action: PayloadAction<ElectricityTariffReportResponse>): void {
            state.available = action.payload;
        },
    },
});
