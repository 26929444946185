import { Box, Grid, makeStyles } from '@material-ui/core';
import { Wrapper } from 'app/shared/components/wrapper';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Region } from '../../../../common/model/resources/regions';
import { Button } from '../../../shared/components/button';
import { Checkbox } from '../../../shared/components/checkbox';
import { HookField } from '../../../shared/components/hookField';
import { Table } from '../../../shared/components/table';
import { defaults, Tables } from '../../../shared/constants';
import { useWebDictionarySelectSuggestions } from '../../../shared/hooks/useWebDictionary';
import { IStateProps } from './model';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        display: 'flex',
        minHeight: '880px',
        '& .custom-table': {
            '& button': {
                visibility: 'hidden',
            },
            '& .MuiTableRow-root': {
                '&:hover': {
                    '& button': {
                        visibility: 'visible',
                    },
                }
            },
        },
        '& .MuiCheckbox-root.Mui-checked': {
            color: theme.palette.neutral.light,
        },
    },
    actions: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    filterWrapper: {
        flex: 1,
    },
    actionsLeft: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
        flex: 1,
    },
    actionsRight: {
        marginLeft: 'auto',
    },
    fieldRegion: {
        flex: '0 0 320px'
    },
    fieldStatus: {
        flex: '0 0 120px'
    },
    tableRow: {
        '& .checkbox-isCrq': {
            display: 'flex',
            justifyContent: 'center'
        },
    },
}));

export const RegionSchemeView = ({ region }: { region: Region }) => {
    if (!region) {
        return 'Регион не найден';
    }
    return <div>{region.name}</div>;
};

export const DefaultSchemeView = (val: boolean) => {
    return <div className="checkbox-isCrq">
        <Checkbox dark disabled checked={val} />
    </div>;
};

export const View = (props: IStateProps) => {
    const { columnDefinition, schemes, isLoading, form, onClickNewScheme, handleClear } = props;
    const classes = useStyles();

    const { regions: regionSuggestions, meterActionSchemeStatuses: statusSuggestions } = useWebDictionarySelectSuggestions();

    const filterRender = (
        <FormProvider {...form}>
            <Grid className={classes.filterWrapper} container spacing={2}>
                <Grid item className={classes.fieldRegion}>
                    <HookField
                        type='select'
                        name='regionId'
                        label='Регион'
                        fullWidth
                        isClearable
                        handleClear={() => handleClear('regionId')}
                        suggestions={regionSuggestions} />
                </Grid>
                <Grid item className={classes.fieldStatus}>
                    <HookField
                        type='select'
                        name='status'
                        label='Статус'
                        fullWidth
                        isClearable
                        handleClear={() => handleClear('status')}
                        suggestions={statusSuggestions} />
                </Grid>
                <Grid item>
                    <HookField
                        type='checkbox'
                        name='isDefault'
                        label='Только схемы по умолчанию'
                    />
                </Grid>
            </Grid>
        </FormProvider>
    );

    const tableActionsRender = <Box className={classes.actions}>
        <Box className={classes.actionsLeft}>
            {filterRender}
        </Box>
        <Box className={classes.actionsRight}>
            <Button onClick={onClickNewScheme} variant='primary' icon='add'>Новая схема</Button>
        </Box>
    </Box>;

    return (
        <div>
            <Wrapper actions={tableActionsRender} className={classes.tableWrapper} isBigHeader isHeaderUppercase={false}>
                {schemes && (
                    <Table
                        name={Tables.administrationSchemesTable}
                        columns={columnDefinition}
                        data={schemes || []}
                        title={null}
                        variant='white'
                        useQueryString={true}
                        options={{
                            grouping: false,
                            columnsButton: false,
                            filtering: false,
                            search: false,
                            sorting: true,
                            paging: 'disabled',
                            toolbar: false,
                            showTitle: false,
                            rowClassName: classes.tableRow,
                        }}
                        fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                        isLoading={isLoading}
                    />
                )}
            </Wrapper>
        </div>
    );
};
