import { CreateTaskNameProps } from './model';
import { useYupValidationResolver } from '../../../../../shared/utils/validation';
import yup from '../../../../../shared/utils/yup';
import { useForm } from 'react-hook-form';
import { View } from './view';
import { useAccessRules } from '../../../../../shared/hooks/useAccessRules';
import { AccessRule } from '../../../../../../common/model/access/accessRule';
import { MeterDictionaryValuesViewModel } from '../../../../../../common/model/meter/meterDictionaryValues/meterDictionaryValuesViewModel';

const taskNameValidationSchema = yup.object({
    value: yup.string().required(),
});

const defaultValues: MeterDictionaryValuesViewModel = {
    value: '',
};

export const CreateTaskNameForm = (props: CreateTaskNameProps) => {
    const resolver = useYupValidationResolver<MeterDictionaryValuesViewModel>(taskNameValidationSchema);
    const form = useForm<MeterDictionaryValuesViewModel>({
        defaultValues,
        resolver
    });
    const { hasAccess } = useAccessRules();

    return View({
        ...props,
        form,
        hasAccess: hasAccess(AccessRule.CanCreateMeterDictionaryValue)
    });
};
