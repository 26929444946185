import { combineReducers } from 'redux';
import { partnersDetailsSlice, PartnersDetailsState } from './details';
import { partnersListSlice, PartnersListState } from './list';

export interface PartnersState {
    list: PartnersListState;
    details: PartnersDetailsState;
}
export const partners = combineReducers<PartnersState>({
    list: partnersListSlice.reducer,
    details: partnersDetailsSlice.reducer,
});
