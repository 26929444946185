import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../shared/components/button';
import { IStateProps } from './model';
import { Table } from '../../../shared/components/table';
import { Wrapper } from '../../../shared/components/wrapper';

const useStyles = makeStyles(() => ({
    tableRow: {
        '&:not(:hover) button': {
            visibility: 'hidden',
        },
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const { data, columns, onClickNew } = props;
    const classes = useStyles();

    const actions = <Button
        onClick={onClickNew}
        variant='primary'
        icon='add'
    >Новая схема</Button>;

    return (
        <Wrapper
            title='СХЕМЫ СИНХРОНИЗАЦИИ ВРЕМЕНИ'
            actions={actions}
        >
            <Table
                data={data}
                title={null}
                variant='white'
                columns={columns}
                options={{
                    paging: 'disabled',
                    search: false,
                    toolbar: false,
                    grouping: false,
                    columnsButton: false,
                    filtering: false,
                    sorting: true,
                    showTitle: false,
                    rowClassName: classes.tableRow,
                }}
            />
        </Wrapper>
    );
};
