import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { LegalEntityType } from '../../../../../../common/model/meter/legalEntityType';
import { isMeterActivationOrderReprocessable } from '../../../../../../common/shared/utils/meterActivationOrder';
import { CreateAddress } from '../../../../../shared/components/createAddress';
import { Button } from '../../../../../shared/components/button';
import { AddAddressLink } from '../../../../../shared/components/createAddress/addAddressLink';
import { HookField } from '../../../../../shared/components/hookField';
import { SelectSuggestion } from '../../../../../shared/components/select/model';
import { Typography } from '../../../../../shared/components/typography';
import {
    accountTypes,
    connectionTypes,
    legalTypes,
    mapLabelValueTypeToVAutocompleteOptions,
    priceCategories,
    purposeTypes,
    timeZoneTypes,
    tariffCountSuggestions
} from '../../../../../shared/constants/enums';
import { useWebDictionarySelectSuggestions } from '../../../../../shared/hooks/useWebDictionary';
import { MeterActivationOrderMode } from '../model';
import { ExpandedPanels, MeterActivationOrderFormViewProps } from './model';

const useStyles = makeStyles((theme) => ({
    accordion: {
        background: 'transparent',
        boxShadow: 'none',
        '& .MuiAccordionSummary-expandIcon': {
            color: theme.palette.primary.light
        },
        '& .MuiAccordionSummary-root': {
            padding: 0,
        },
        '& .Mui-expanded': {
            marginTop: 0,
            marginBottom: 0,
            minHeight: 'auto',
        },
        '&.Mui-expanded': {
            marginTop: 0,
            marginBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&:after': {
            content: '""',
            display: 'block',
            height: '0.5px',
            backgroundColor: theme.palette.neutral.light,
        }
    },
}));

export const View = ({
    form,
    mode,
    onSubmit,
    onResetForm,
    addressAutocomplete,
    expandedPanels,
    onCreateAddress,
    networkOperators,
    handleChangeExpanded,
    meterActivationOrder,
    isCreateAddressFormOpen,
    onCloseCreateAddressForm,
    onOpenCreateAddressForm,
    canPostMeterActivationOrder,
    canReprocessMeterActivationOrder,
}: MeterActivationOrderFormViewProps) => {

    const classes = useStyles();
    const { meterModels } = useWebDictionarySelectSuggestions();
    const {
        handleSubmit,
        formState,
        getValues,
    } = form;

    const networkOperatorUsersSuggestions = networkOperators.map<SelectSuggestion>(item => ({
        label: `[${item.id}] ${item.name}`,
        value: item.id,
    }));
    const formValues = getValues();

    const isCompany = formValues.legalEntityType === LegalEntityType.Company;

    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>

                    <Accordion
                        className={classes.accordion}
                        expanded={expandedPanels[ExpandedPanels.CommonParameters]}
                        onChange={handleChangeExpanded(ExpandedPanels.CommonParameters)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant='h2'>Общие параметры</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='meterModelId'
                                        type='select'
                                        suggestions={meterModels}
                                        label='Тип ПУ'
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='meterSerialNum'
                                        type='text'
                                        label='Заводской номер'
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='foreignId'
                                        type='text'
                                        label='УИД ПУ'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='manufactureDate'
                                        type='datepicker'
                                        label='Дата производства ПУ'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='accountType'
                                        type='select'
                                        label='Тип учета'
                                        fullWidth
                                        suggestions={accountTypes}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        fullWidth
                                        type='select'
                                        name='meterPurpose'
                                        disabled={isCompany}
                                        label='Назначение прибора'
                                        suggestions={isCompany ? [] : purposeTypes}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='connectionType'
                                        type='select'
                                        label='Тип включения'
                                        fullWidth
                                        suggestions={connectionTypes}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='legalEntityType'
                                        type='select'
                                        label='Правовая форма владельца'
                                        fullWidth
                                        suggestions={legalTypes}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='priceCategory'
                                        type='select'
                                        label='Ценовая категория'
                                        fullWidth
                                        suggestions={priceCategories}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='code'
                                        type='text'
                                        label='АИИС КУЭ'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} xl={8}>
                                    <HookField
                                        name='fiasAoGuid'
                                        type='autocomplete'
                                        label='Адрес'
                                        fullWidth
                                        noOptionsText='Адрес не найден'
                                        loadingText='Поиск...'
                                        required
                                        {...addressAutocomplete}
                                    />
                                    <AddAddressLink onClick={onOpenCreateAddressForm} />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='addressSpecifier'
                                        type='text'
                                        label='Номер помещения'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='installationSite'
                                        type='text'
                                        label='Место установки'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='installationDate'
                                        type='datepicker'
                                        label='Дата установки'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='nextVerificationDate'
                                        type='datepicker'
                                        label='Дата ист-я срока межпов. интервала'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        className={classes.accordion}
                        expanded={expandedPanels[ExpandedPanels.SecurityParameters]}
                        onChange={handleChangeExpanded(ExpandedPanels.SecurityParameters)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant='h2'>Параметры безопасности</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='adminPassword'
                                        type='password'
                                        isShowHidePasswordVisible={true}
                                        label='Пароль администратора'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='userPassword'
                                        type='password'
                                        isShowHidePasswordVisible={true}
                                        label='Пароль пользователя'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='password3'
                                        type='password'
                                        isShowHidePasswordVisible={true}
                                        label='Пароль 3'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        className={classes.accordion}
                        expanded={expandedPanels[ExpandedPanels.Communicator]}
                        onChange={handleChangeExpanded(ExpandedPanels.Communicator)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant='h2'>Коммуникатор</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <HookField
                                        fullWidth
                                        name='networkOperatorId'
                                        type='select'
                                        label='Платформа Центр2М'
                                        required
                                        suggestions={networkOperatorUsersSuggestions}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='iccId'
                                        type='text'
                                        label='Серийный номер СИМ-карты (ICC ID)'
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='apn'
                                        type='text'
                                        label='Точка доступа'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='communicatorModel'
                                        type='text'
                                        label='Тип коммуникатора'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='communicatorSerialNum'
                                        type='text'
                                        label='Заводской номер радиомодема'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='networkAddress'
                                        type='text'
                                        label='Сетевой адрес'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='ipPort'
                                        type='text'
                                        label='Порт'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='communicatorPassword1'
                                        type='password'
                                        isShowHidePasswordVisible={true}
                                        label='Пароль коммуникатора 1'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='communicatorPassword2'
                                        type='password'
                                        isShowHidePasswordVisible={true}
                                        label='Пароль коммуникатора 2'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        className={classes.accordion}
                        expanded={expandedPanels[ExpandedPanels.TechnicalParameters]}
                        onChange={handleChangeExpanded(ExpandedPanels.TechnicalParameters)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant='h2'>Технические параметры</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='cTRatio'
                                        type='text'
                                        label='Коэф-т трансформации по току'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='tariffCount'
                                        type='select'
                                        label='Количество тарифов'
                                        suggestions={tariffCountSuggestions}
                                        fullWidth
                                        isClearable
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='pTRatio'
                                        type='text'
                                        label='Коэф-т трансформации по напр-ю'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='timeZone'
                                        type='autocomplete'
                                        options={mapLabelValueTypeToVAutocompleteOptions(timeZoneTypes)}
                                        label='Временная зона ПУ'
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='dst'
                                        type='checkbox'
                                        label='Применять переход на летнее время'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='changePasswordForbidden'
                                        type='checkbox'
                                        label='Запрещено изменять пароль'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='changeTariffForbidden'
                                        type='checkbox'
                                        label='Запрещено изменять тарифное расписание'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='changeTimeForbidden'
                                        type='checkbox'
                                        label='Запрещена коррекция времени'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={4}>
                                    <HookField
                                        name='changeIntervalForbidden'
                                        type='checkbox'
                                        label='Запрещено изменять время интегрирования мощности'
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Box display='flex' marginTop={2}>
                        <Button
                            variant='primary'
                            type="submit"
                            className='m-r-16'
                            disabled={
                                (meterActivationOrder
                                    && (
                                        !isMeterActivationOrderReprocessable(meterActivationOrder.meterActivationOrderStatus)
                                        || !canReprocessMeterActivationOrder
                                    )
                                )
                                || (!meterActivationOrder && !canPostMeterActivationOrder)
                                || !formState.isDirty
                            }
                            icon={meterActivationOrder ? null : 'add'}
                        >
                            {meterActivationOrder ? 'Сохранить' : 'Создать заявку'}
                        </Button>
                        {
                            mode === MeterActivationOrderMode.Update &&
                                <Button
                                    variant='secondary'
                                    type="button"
                                    onClick={onResetForm}
                                    disabled={!formState.isDirty}
                                >
                                    Отмена
                                </Button>
                        }
                    </Box>

                </form>
            </FormProvider>
            {isCreateAddressFormOpen && (
                <CreateAddress onClose={onCloseCreateAddressForm} onCreateAddress={onCreateAddress} />
            )}
        </>
    );
};
