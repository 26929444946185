import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { UserViewModel } from '../../../../../common/model/access/user/userViewModel';
import { ActionType } from '../../../../actions';

export interface ActiveUserAccessState {
    user: UserViewModel;
    accessRules: AccessRule[];
}

const initialState: ActiveUserAccessState = {
    user: null,
    accessRules: [],
};

export const activeUserAccessSlice = createSlice({
    name: '@@vostok/access/users/active',
    initialState,
    reducers: {
        setActiveUser(state, action: PayloadAction<UserViewModel>): void {
            state.user = action.payload;
        },
        setAccessRules(state, action: PayloadAction<AccessRule[]>): void {
            state.accessRules = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): ActiveUserAccessState => initialState,
    }
});
