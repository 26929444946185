import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RouteConfigComponentProps } from 'react-router-config';
import { useParams } from 'react-router-dom';
import { BaseDispatch } from '../../../redux/actions';
import {
    getEditableTariffScheduleById,
    getTariffScheduleVersion,
    setTariffScheduleEditable,
    setTariffScheduleVersion
} from '../../../redux/actions/tariffSchedule';
import { tariffScheduleEditableSelector, tariffScheduleVersionEditableSelector } from '../../../redux/selectors/tariffSchedule';
import { TariffScheduleVersionProps } from '../../content/administration/tariffScheduleVersion/model';
import { AdministrationTabs, Routes } from '../../shared/constants';
import { getRoute } from '../../shared/pipes';

export const TariffScheduleVersionResolver = (viewComponent: React.FC<TariffScheduleVersionProps>) => {
    return (props: RouteConfigComponentProps) => {
        const dispatch = useDispatch<BaseDispatch>();
        const tariffSchedule = useSelector(tariffScheduleEditableSelector);
        const tariffScheduleVersion = useSelector(tariffScheduleVersionEditableSelector);
        const { tariffScheduleId, tariffScheduleVersionId } = useParams<{ tariffScheduleId: string; tariffScheduleVersionId: string; }>();
        const [isLoaded, setIsLoaded] = useState(false);

        const fetch = async () => {
            setIsLoaded(false);
            try {
                await dispatch(getEditableTariffScheduleById(tariffScheduleId));
                if (tariffScheduleVersionId) {
                    await dispatch(getTariffScheduleVersion(tariffScheduleId, tariffScheduleVersionId));
                }
            } catch (e) {
                dispatch(push(getRoute(Routes.administration, {}, { tab: AdministrationTabs.TariffSchedules })));
            }
            setIsLoaded(true);
        };

        React.useEffect(() => {
            fetch();
        }, [tariffScheduleId, tariffScheduleVersionId]);

        React.useEffect(() => {
            return () => {
                dispatch(setTariffScheduleVersion(null));
                dispatch(setTariffScheduleEditable(null));
            };
        }, []);

        if (!isLoaded) {
            return null;
        }

        return React.createElement(viewComponent, {
            ...props,
            tariffSchedule,
            tariffScheduleVersion,
        });
    };
};
