import React from 'react';
import { useForm } from 'react-hook-form';
import { MeterFirmwareVersionFormData, MeterFirmwareVersionFormProps } from './model';
import { View } from './view';

export const MeterFirmwareVersionForm: React.FC<MeterFirmwareVersionFormProps> = (props) => {

    const { source } = props;

    const form = useForm<MeterFirmwareVersionFormData>({
        defaultValues: source,
    });

    return View({
        ...props,
        form,
    });
};
