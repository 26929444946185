import { View } from './view';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../shared/hooks/useBreadcrumbs';
import { getRoute } from '../../shared/pipes';
import { RelatedSystemsTabs, Routes } from '../../shared/constants';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../redux/actions';
import { meterGisTaskById } from '../../../redux/selectors/meterGisTasks';
import { getMeterGisTaskById } from '../../../redux/actions/meterGisTasks';
import { useEffect } from 'react';
import { useHeading } from '../../shared/hooks/useHeading';

export const MeterGisTask = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const params = useParams<{taskId?: string}>();
    const taskId = params.taskId;
    const task = useSelector(meterGisTaskById);


    useEffect(() => {
        dispatch(getMeterGisTaskById({ taskId }));
    }, []);


    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Смежные системы', link: getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.GisTasks }) },
        { name: 'Инциденты', link: getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.GisTasks }) },
        { name: 'Карточка инцидента', link: getRoute(Routes.meterGisTaskEdit, { taskId: taskId.toString() }) },
    ]);

    const headingProps = useHeading(task?.title, () => {
        dispatch(push(getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.GisTasks })));
    });

    return View({
        ...headingProps,
        task,
        breadcrumbs,
        onClickBreadcrumb,
    });
};
