import moment from 'moment';

interface ObjectWithDatesParams {
    dateFrom?: Date;
    dateTo?: Date;
}

interface ObjectWithDateParams {
    date: Date;
}

export const transformDatesParams = (params: ObjectWithDatesParams): any => {
    return {
        ...params,
        dateTo: params.dateTo ? moment(params.dateTo).endOf('day').toISOString() : null,
        dateFrom: params.dateFrom ? moment(params.dateFrom).startOf('day').toISOString() : null,
    };
};

export const transformDatesParamsWithoutEnds = (params: ObjectWithDatesParams): any => {
    return {
        ...params,
        dateTo: params.dateTo ? moment(params.dateTo).toISOString() : null,
        dateFrom: params.dateFrom ? moment(params.dateFrom).toISOString() : null,
    };
};

export const transformDatesParamsWithoutTz = (params: ObjectWithDatesParams): any => {
    return {
        ...params,
        dateTo: params.dateTo ? moment(params.dateTo).utc(true).toISOString() : null,
        dateFrom: params.dateFrom ? moment(params.dateFrom).utc(true).toISOString() : null,
    };
};

export const transformDateParams = (params: ObjectWithDateParams): any => {
    return {
        ...params,
        date: new Date(params.date).toISOString(),
    };
};
