import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { DateFormats } from '../../../common/constants/date';
import { OrderTypeDictionary } from '../../../common/model/inspectionLog/OrderType';
import {
    InspectionSeverityType,
    InspectionSeverityTypeDictionary
} from '../../../common/model/inspectionLog/SeverityType';
import { InspectionLogSourceType } from '../../../common/model/inspectionLog/SourceType';
import { MeterActionTypeDictionary } from '../../../common/model/meter/meterActionType';
import { HookField } from '../../shared/components/hookField';
import { Icon } from '../../shared/components/icon';
import { InfiniteScroll } from '../../shared/components/infiniteScroll';
import { transformLabelValueTypeToMultiSelectSuggestion } from '../../shared/components/multiSelect/helpers';
import { TextElipsis } from '../../shared/components/textElipsis';
import { Tooltip } from '../../shared/components/tooltip';
import { Wrapper } from '../../shared/components/wrapper';
import { inspectionSeverityTypes, meterActionTypeTypes } from '../../shared/constants/enums';
import { transformLocalTimeMeter } from '../../shared/utils/dates';
import { useCommonTableStyles } from '../meter/style';
import { SeverityColumn } from './components/severity';
import { InspectionLogFilterTypes, InspectionLogTableColumns, StateProps } from './model';

import { useStyles } from './style';

const dampPeriods = [
    {
        label: '15 мин',
        value: 0.25
    },
    {
        label: '1 час',
        value: 1
    },
    {
        label: '4 часа',
        value: 4
    },
    {
        label: '12 часов',
        value: 12
    },
    {
        label: '24 часа',
        value: 24
    },
];

export const View = ({
    data,
    last,
    form,
    isFilterVisible,
    onClick,
    onClickSetNowMinCreatedAt,
    onClickSetNowMaxCreatedAt,
    isDumpVisible,
    visibleColumns,
    visibleFilters,
    onInfiniteScrollStart,
    onInfiniteScrollEnd,
}: StateProps) => {
    const tableClasses = useCommonTableStyles();
    const classes = useStyles();

    const isFilterVisibleByType: Partial<Record<InspectionLogFilterTypes, boolean>> = useMemo(() => {
        return Object.values(InspectionLogFilterTypes).reduce<Partial<Record<InspectionLogFilterTypes, boolean>>>((prev, current) => {
            return { ...prev, [+current]: visibleFilters.includes(+current as InspectionLogFilterTypes) };
        }, {});
    }, [visibleFilters]);

    const isColumnVisibleByType: Partial<Record<InspectionLogTableColumns, boolean>> = useMemo(() => {
        return Object.values(InspectionLogTableColumns).reduce<Partial<Record<InspectionLogTableColumns, boolean>>>((prev, current) => {
            return { ...prev, [+current]: visibleColumns.includes(+current as InspectionLogTableColumns) };
        }, {});
    }, [visibleColumns]);

    const filter = (
        <FormProvider {...form}>
            <form>
                <Grid container spacing={2}>
                    {isFilterVisibleByType[InspectionLogFilterTypes.IsMy] && (
                        <Grid item>
                            <Tooltip text='Показать только логи выполнения операций,инициированных мной'>
                                <HookField
                                    name='isMy'
                                    type='checkbox'
                                    label='Только мои'
                                />
                            </Tooltip>
                        </Grid>
                    )}
                    <Grid className={classes.filterFieldsItem} item>
                        <Grid container spacing={2}>
                            {isFilterVisibleByType[InspectionLogFilterTypes.Critical] && (
                                <Grid item xs={6} md={3} lg={2}>
                                    <HookField
                                        fullWidth
                                        name='severity'
                                        type='select'
                                        label='Критичность'
                                        suggestions={inspectionSeverityTypes}
                                        isClearable
                                    />
                                </Grid>
                            )}
                            {isFilterVisibleByType[InspectionLogFilterTypes.OrderType] && (
                                <Grid item xs={6} md={3} lg={2}>
                                    <HookField
                                        fullWidth
                                        name='meterActionType'
                                        type='multiselect'
                                        label='Тип опроса'
                                        suggestions={transformLabelValueTypeToMultiSelectSuggestion(meterActionTypeTypes)}
                                    />
                                </Grid>
                            )}
                            {isFilterVisibleByType[InspectionLogFilterTypes.DateFrom] && (
                                <Grid item xs={6} md={3} lg={2} className={classes.nowActionWrapper}>
                                    <HookField
                                        fullWidth
                                        name='minCreatedAt'
                                        type='datepicker'
                                        showTime={true}
                                        label='Дата От'
                                    />
                                    <Box onClick={onClickSetNowMinCreatedAt} className={classes.nowAction}>Сейчас</Box>
                                </Grid>
                            )}
                            {isFilterVisibleByType[InspectionLogFilterTypes.DateTo] && (
                                <Grid item xs={6} md={3} lg={2} className={classes.nowActionWrapper}>
                                    <HookField
                                        fullWidth
                                        name='maxCreatedAt'
                                        type='datepicker'
                                        showTime={true}
                                        label='Дата До'
                                    />
                                    <Box onClick={onClickSetNowMaxCreatedAt} className={classes.nowAction}>Сейчас</Box>
                                </Grid>
                            )}
                            {isDumpVisible && (
                                <Grid item xs={6} md={3} lg={2}>
                                    <HookField
                                        fullWidth
                                        name='dumpPeriod'
                                        type='select'
                                        label='Показывать дамп'
                                        suggestions={dampPeriods}
                                        isClearable
                                    />
                                </Grid>
                            )}
                            {isFilterVisibleByType[InspectionLogFilterTypes.Search] && (
                                <Grid item xs={6} md={3} lg={2}>
                                    <HookField
                                        fullWidth
                                        name='text'
                                        type='text'
                                        placeholder='Поиск...'
                                        leftIcon='search'
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );

    const filterToggle = <div className={classes.rightButton} onClick={onClick}>
        <Tooltip text={isFilterVisible ? 'Свернуть' : 'Развернуть'}>
            <div className={classes.rightButtonIconWrapper}>
                <Icon
                    className={classNames(classes.rightButtonIcon, classes.rightButtonIconDefault)}
                    name={isFilterVisible ? 'collapseFilled' : 'expandFilled'}
                />
                <Icon
                    className={classNames(classes.rightButtonIcon, classes.rightButtonIconHover)}
                    name={isFilterVisible ? 'collapseOutlined' : 'expandOutlined'}
                />
            </div>
        </Tooltip>
    </div>;

    const actions = (
        <Grid justify='space-between' className={tableClasses.actions} container spacing={2}>
            <Grid item className={tableClasses.actionsFilter}>{isFilterVisible ? filter : null}</Grid>
            <Grid item>{filterToggle}</Grid>
        </Grid>
    );

    const title = useMemo(() => <div className={classes.lastOperationsTitle}>
        <span>Последние операции</span>
        {!isFilterVisible && last ? <div className={classNames(classes.lastOperationContainer, {
            [classes.dangerRow]:
            last.severity === InspectionSeverityType.Error,
            [classes.warningRow]:
            last.severity === InspectionSeverityType.Warning,
        })}>
            <SeverityColumn
                tooltip={InspectionSeverityTypeDictionary[last.severity]}
                severity={last.severity}
            />
            <div className={classes.firstLogTextView}>
                {transformLocalTimeMeter(
                    new Date(last.createdAt)
                ).format(DateFormats.dateFullTimeFormat)}
            </div>
            <div className={classes.firstLogTextView}>
                {last.message}
            </div>
            <div className={classes.firstLogTextView}>
                {OrderTypeDictionary[last.orderType]}
            </div>

        </div > : null}
    </div>, [isFilterVisible, last]);

    if (!isFilterVisible) {
        return (
            <Wrapper
                isBigHeader
                actions={actions}
                isHeaderUppercase={true}
                title={title}
                className={classNames(tableClasses.wrapper, classes.headerWrapper)} />
        );
    }
    return (
        <div className={classes.inspectionLogWrapper}>
            <Wrapper isBigHeader actions={actions} isHeaderUppercase={false}
                className={classNames(tableClasses.wrapper, classes.headerWrapper)}>
                <InfiniteScroll
                    scrollToSelector={'.inspection-row'}
                    onScrollStart={onInfiniteScrollStart}
                    onScrollEnd={onInfiniteScrollEnd}
                    wrapperProps={{ className: classNames(classes.scrollableWrapper) }}
                >
                    {data && (
                        <table className={classes.tableWrapper}>
                            <tbody>
                                {data.map((item) => (
                                    <tr className={
                                        classNames('inspection-row', {
                                            [classes.dangerRow]:
                                            item.severity === InspectionSeverityType.Error,
                                            [classes.warningRow]:
                                            item.severity === InspectionSeverityType.Warning,
                                        })
                                    } key={item.id}>
                                        {isColumnVisibleByType[InspectionLogTableColumns.Critical] && (
                                            <td className={classes.cellSeverity}>
                                                <SeverityColumn
                                                    tooltip={InspectionSeverityTypeDictionary[item.severity]}
                                                    severity={item.severity}
                                                />
                                            </td>
                                        )}
                                        {isColumnVisibleByType[InspectionLogTableColumns.Date] && (
                                            <td className={classes.cellCreatedAt}>
                                                {transformLocalTimeMeter(
                                                    new Date(item.createdAt)
                                                ).format(DateFormats.dateFullTimeFormat)}
                                            </td>
                                        )}
                                        {isColumnVisibleByType[InspectionLogTableColumns.OrderType] && (
                                            <td className={classes.cellOrderType}>
                                                <TextElipsis lines={1} text={MeterActionTypeDictionary[item.meterActionType]}>
                                                    {MeterActionTypeDictionary[item.meterActionType]}
                                                </TextElipsis>
                                            </td>
                                        )}
                                        {isColumnVisibleByType[InspectionLogTableColumns.Message] && (
                                            <td className={classNames(classes.cellMessage, {
                                                'cell-meters-controller': item.source === InspectionLogSourceType.MetersController
                                            })}
                                            >
                                                <TextElipsis lines={1} text={item.message}>
                                                    {item.message}
                                                </TextElipsis>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </InfiniteScroll>
            </Wrapper>
        </div>
    );
};
