import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { DateFormats } from '../../../../../../common/constants/date';
import { SettingType } from '../../../../../../common/model/resources/settings/type';
import { HookField } from '../../../../../shared/components/hookField';
import { formatDate } from '../../../../../shared/utils/dates';
import { IStateProps } from './model';
import { FormProvider } from 'react-hook-form';
import { Modal } from '../../../../../shared/components/modal';
import { Button } from '../../../../../shared/components/button';

const useStyles = makeStyles((theme) => ({
    helpText: {
        color: theme.palette.neutral.main,
        ...theme.typography.body,
    },
    userInfo: {
        color: theme.palette.neutral.light,
        ...theme.typography.caption,
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const { form, setting, onCloseModal, onClickCancel, isFormLoading } = props;
    const { label, title, changedAt, user, help, defaultValue, type, minValue, maxValue, regex } = setting;

    const classes = useStyles();

    const valueFieldRender = () => {
        switch (type) {
            case SettingType.boolean: {
                return <HookField
                    disabled={isFormLoading}
                    name='value'
                    type='radio'
                    values={[
                        {
                            id: 'true',
                            name: 'Да',
                        },
                        {
                            id: 'false',
                            name: 'Нет',
                        }
                    ]}
                />;
            }
            case SettingType.datetime: {
                return <HookField
                    fullWidth
                    disabled={isFormLoading}
                    name='value'
                    type='datepicker'
                    showTime
                    label={label}
                />;
            }
            case SettingType.time: {
                return <HookField
                    fullWidth
                    disabled={isFormLoading}
                    name='value'
                    type='timepicker'
                    label={label}
                />;
            }
            case SettingType.number: {
                return <HookField
                    disabled={isFormLoading}
                    fullWidth
                    name='value'
                    type='number'
                    label={label}
                />;
            }
            default: {
                return <HookField
                    fullWidth
                    name='value'
                    type='text'
                    label={label}
                />;
            }
        }
    };

    return (
        <div>
            <Modal title={title} close='Отмена' onHide={onCloseModal}>
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(props.onSubmit)}>
                        <Grid container spacing={2}>
                            {help && (
                                <Grid item xs={12}>{help}</Grid>
                            )}
                            {(changedAt || user) && (
                                <Grid item xs={12}>
                                    <div className={classes.userInfo}>
                                        {changedAt && (
                                            <div>Данные изменены: {formatDate(changedAt, DateFormats.dateFullTimeFormat)}</div>
                                        )}
                                        {user && (
                                            <div>Пользователь: {user.name || user.email}</div>
                                        )}
                                    </div>
                                </Grid>
                            )}
                            {(minValue || maxValue || regex || defaultValue) && (
                                <Grid item xs={12}>
                                    <div className={classes.userInfo}>
                                        {minValue !== null && (
                                            <div>Минимальное значение: {minValue}</div>
                                        )}
                                        {maxValue !== null && (
                                            <div>Максимальное значение: {maxValue}</div>
                                        )}
                                        {regex !== null && (
                                            <div>Должно соответствовать регулярному выражению: {regex}</div>
                                        )}
                                        {defaultValue !== null && (
                                            <div>Значение по умолчанию: <b>{defaultValue}</b></div>
                                        )}
                                    </div>
                                </Grid>
                            )}
                            <Grid item xs={9}>
                                {valueFieldRender()}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Button disabled={isFormLoading} type='submit' variant='primary'>Сохранить</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button disabled={isFormLoading} type='button' variant='secondary' onClick={onClickCancel}>Отмена</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Modal>
        </div>
    );
};
