import { IconType } from '../icon/model';
import React from 'react';
import { Box, Grid, Theme } from '@material-ui/core';
import { Icon } from '../icon';
import { Link } from 'react-router-dom';

interface Props {
    title: string | number;
    icon: IconType;
    fillColor: string;
    width?: string;
    height?: string;
    isTextAsLink?: boolean;
    route?: string;
    theme?: Theme;
}

export const TextWithIcon: React.FC<Props> = ({
    title,
    icon,
    fillColor,
    width,
    height,
    isTextAsLink = false,
    route,
    theme,
}) => {
    const renderTitle = () => {
        if (isTextAsLink) {
            return (
                <Link
                    style={{
                        color: theme.palette.primary.light,
                        marginLeft: '5px',
                    }}
                    to={route}
                >{title}</Link>
            );
        }
        return (
            <Box marginLeft='5px'>{title}</Box>
        );
    };

    return (
        <Grid container>
            <Icon name={icon} fillColor={fillColor} width={width} height={height} />
            {renderTitle()}
        </Grid>
    );
};
