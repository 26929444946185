import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { MeterMeterTariffScheduleUpdater } from '../../../../../../../common/model/meter/meterMeterTariffScheduleUpdater';
import { DateFormats } from '../../../../../common/constants/date';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import {
    getMeter,
    getMeterMeterTariffScheduleInOrder,
    getMeterTariffSchedules,
    rewriteMeterTariffSchedule,
    updateMeterMeterTariffSchedule
} from '../../../../../redux/actions/meters';
import { meterSelector, meterTariffScheduleInOrderSelector, meterTariffSchedulesSelector } from '../../../../../redux/selectors/meters';
import { useInterval } from '../../../../shared/hooks/useInterval';
import { formatDate } from '../../../../shared/utils/dates';
import { getErrorMessage } from '../../../../shared/utils/error';
import { InputProps } from './model';
import { View } from './view';

const defaultTariffScheduleUpdater: MeterMeterTariffScheduleUpdater = {
    tariffCount: null,
};

const fetchDelayMs = 3000;

export const TariffScheduleManagement: React.FC<InputProps> = (props) => {
    const { meterId } = props;
    const response = useSelector(meterTariffSchedulesSelector);
    const meterTariffScheduleInOrder = useSelector(meterTariffScheduleInOrderSelector);
    const meter = useSelector(meterSelector);
    const dispatch = useDispatch<BaseDispatch>();
    const [isFormActive, setIsFormActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetch = async () => {
        await dispatch(getMeterTariffSchedules(meterId));
        await dispatch(getMeterMeterTariffScheduleInOrder(meterId));
        await dispatch(getMeter(meterId));
    };

    useEffect(() => {
        fetch();
    }, [meterId]);

    useInterval(async () => {
        if (!isFormActive) {
            await fetch();
        }
    }, fetchDelayMs);

    const form = useForm<MeterMeterTariffScheduleUpdater>({
        defaultValues: defaultTariffScheduleUpdater,
    });

    useEffect(() => {
        if (response?.currentMeterTariffSchedule?.tariffSchedule) {
            form.reset({
                tariffCount: response.currentMeterTariffSchedule.tariffSchedule.tariffCount,
            });
        }
    }, [response]);

    return View({
        ...props,
        response,
        form,
        isLoading,
        isFormActive,
        meterTariffScheduleInOrder,
        changeTariffForbidden: meter?.changeTariffForbidden,
        onClickChangeTariff: () => {
            setIsFormActive(true);
        },
        onRewriteTariff: async () => {
            setIsLoading(true);
            try {
                const meterTariffSchedule = await rewriteMeterTariffSchedule(meterId);
                if (!meterTariffSchedule) {
                    throw new Error('Произошла неизвестная ошибка');
                }
                dispatch(createSnackbar({
                    type: 'green',
                    message: `Запланирована установка ТР на ${formatDate(meterTariffSchedule.planDate, DateFormats.dateTimeFormat)}`,
                }));
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsLoading(false);
            await fetch();
        },
        onSubmit: async (data) => {
            setIsLoading(true);
            try {
                await updateMeterMeterTariffSchedule(meterId, data);
                dispatch(createSnackbar({
                    type: 'green',
                    message: 'Процесс обновления схемы тарификации запущен',
                }));
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsFormActive(false);
            setIsLoading(false);
            await fetch();
        },
        onCancel: () => {
            setIsFormActive(false);
        },
    });
};
