import { MeterFirmwareVersionViewModel } from '../../../../common/model/meter/meterFirmwareVersion/meterFirmwareVersionViewModel';
import { MeterGroupViewModel } from '../../../../common/model/meter/meterGroup/meterGroupViewModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MeterFirmwareVersionListState {
    available: MeterFirmwareVersionViewModel[];
}

const initialState: MeterFirmwareVersionListState = {
    available: [],
};

export const meterFirmwareVersionsListSlice = createSlice({
    name: '@@vv/meterFirmwareVersion/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<MeterGroupViewModel[]>): void {
            state.available = action.payload;
        },
    },
});
