import React from 'react';
import { Grid, Table, TableRow, TableHead, TableCell, TableBody, IconButton } from '@material-ui/core';
import { CloseIcon } from '../../../../../../assets/icons';
import { HookField } from '../../../../../shared/components/hookField';
import { sortTariffDayNames } from './helpers';
import { IStateProps } from './model';
import { Button } from '../../../../../shared/components/button';
import { FormProvider } from 'react-hook-form';

export const View: React.FC<IStateProps> = (props) => {

    const {
        form,
        onCancel,
        onSubmit,
        formArray,
        onClickAddRow,
        onClickDeleteRow,
        isEditMode,
        formData,
        codeSuggestions,
    } = props;

    const {
        handleSubmit,
    } = form;

    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding='none'>Код</TableCell>
                                    <TableCell padding='none'>Название</TableCell>
                                    <TableCell padding='none' />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formArray.fields
                                    .map((tariffDayName, index) => ({ tariffDayName, index }))
                                    .sort(sortTariffDayNames(formData))
                                    .map(({ tariffDayName, index }) => (
                                        <TableRow key={tariffDayName.id}>
                                            <TableCell padding='none'>
                                                {isEditMode && (
                                                    <HookField
                                                        fullWidth
                                                        placeholder='Не выбрано'
                                                        name={`tariffDayNames.${index}.code`}
                                                        type='select'
                                                        variant='standard'
                                                        suggestions={codeSuggestions(index)}
                                                    />
                                                )}
                                                {!isEditMode && (
                                                    formData.tariffDayNames[index].code
                                                )}
                                            </TableCell>
                                            <TableCell padding='none'>
                                                {isEditMode && (
                                                    <HookField
                                                        fullWidth
                                                        placeholder='Не указано'
                                                        name={`tariffDayNames.${index}.name`}
                                                        type='text'
                                                        variant='standard'
                                                    />
                                                )}
                                                {!isEditMode && (
                                                    formData.tariffDayNames[index].name
                                                )}
                                            </TableCell>
                                            <TableCell padding='none'>
                                                {isEditMode && (
                                                    <IconButton onClick={() => onClickDeleteRow(index)} size='small' type='button'>
                                                        <CloseIcon />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        {isEditMode && (
                            <Grid item xs={12}>
                                <Button
                                    icon='add'
                                    type='button'
                                    variant='text'
                                    onClick={onClickAddRow}
                                >
                                    Добавить
                                </Button>
                            </Grid>
                        )}
                        {isEditMode && (
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Button
                                            type='submit'
                                            variant='primary'
                                        >
                                            Сохранить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type='button'
                                            variant='secondary'
                                            onClick={onCancel}
                                        >
                                            Отмена
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </FormProvider>
        </>
    );
};
