import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IStateProps } from './model';
import { BaseDispatch } from '../../../../../redux/actions';
import {
    findMeterReadingDailyProfilesChart,
} from '../../../../../redux/actions/meterReading';
import {
    meterReadingDailyProfileChartFilterSelector,
    meterReadingDailyProfileChartSelector,
} from '../../../../../redux/selectors/meterReading';
import { View } from './view';
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { DateFormats } from '../../../../../common/constants/date';
import { MeterReadingDailyProfileFilter } from '../../../../../common/model/meter/meter/meterReadingDailyProfileFilter';
import { dailyProfilesChartSlice } from '../../../../../redux/reducers/meterReading/dailyProfiles/chart';


export const MeterReadingDailyChart = () => {

    const dispatch = useDispatch<BaseDispatch>();
    const params = useParams<{meterId?: string}>();
    const meterId = params.meterId;
    const dailyProfiles = useSelector(meterReadingDailyProfileChartSelector);
    const filter = useSelector(meterReadingDailyProfileChartFilterSelector);

    const handleChangeFilter = (field: keyof MeterReadingDailyProfileFilter, value: any) => {
        dispatch(dailyProfilesChartSlice.actions.setFilter({ ...filter, [field]: value }));
    };

    const handleClearFilter = () => {
        dispatch(dailyProfilesChartSlice.actions.clearFilter());
    };

    const readingDates = useMemo(() => dailyProfiles.map(profile => profile.readingDate), [dailyProfiles]);
    const cumulativeActiveEnergyExp = useMemo(() => dailyProfiles.map(profile => profile.cumulativeActiveEnergyExp), [dailyProfiles]);
    const cumulativeActiveEnergyImp = useMemo(() => dailyProfiles.map(profile => profile.cumulativeActiveEnergyImp), [dailyProfiles]);
    const cumulativeActiveEnergyTZ1 = useMemo(() => dailyProfiles.map(profile => profile.cumulativeActiveEnergyTZ1), [dailyProfiles]);
    const cumulativeActiveEnergyTZ2 = useMemo(() => dailyProfiles.map(profile => profile.cumulativeActiveEnergyTZ2), [dailyProfiles]);
    const cumulativeActiveEnergyTZ3 = useMemo(() => dailyProfiles.map(profile => profile.cumulativeActiveEnergyTZ3), [dailyProfiles]);
    const cumulativeReactiveEnergyExp = useMemo(() => dailyProfiles.map(profile => profile.cumulativeReactiveEnergyExp), [dailyProfiles]);
    const cumulativeReactiveEnergyImp = useMemo(() => dailyProfiles.map(profile => profile.cumulativeReactiveEnergyImp), [dailyProfiles]);

    const chartId = 'reading';

    const xaxis: ApexXAxis = {
        type: 'datetime',
        categories: readingDates,
        labels: {
            formatter: (val: string) => moment(val).format(DateFormats.dateTimeFormat)
        },
    };

    const series = [
        {
            name: '1. Активная энергия +',
            data: cumulativeActiveEnergyImp,
        },
        {
            name: '2. Активная энергия -',
            data: cumulativeActiveEnergyExp,
        },
        {
            name: '3. Активная энергия + (1 тариф)',
            data: cumulativeActiveEnergyTZ1
        },
        {
            name: '4. Активная энергия + (2 тариф)',
            data: cumulativeActiveEnergyTZ2
        },
        {
            name: '5. Активная энергия + (3 тариф)',
            data: cumulativeActiveEnergyTZ3
        },
        {
            name: '6. Реактивная энергия +',
            data: cumulativeReactiveEnergyImp
        },
        {
            name: '7. Реактивная энергия -',
            data: cumulativeReactiveEnergyExp
        }
    ];

    useEffect(() => {
        if (meterId && filter) {
            dispatch(findMeterReadingDailyProfilesChart(meterId, filter));
        }
    }, [meterId, filter]);


    const props: IStateProps = {
        chartId,
        filter,
        handleChangeFilter,
        handleClearFilter,
        xaxis,
        yaxis: {},
        series,
    };

    return View(props);
};
