import { Column } from '@softmedialab/materialui-table';
import { CrqChannelViewModel } from 'common/model/meter/crq/crqChannelViewModel';
import { CrqChannelCreator } from 'common/model/meter/crq/crqChannelCreator';
import { CrqChannelCategory } from 'common/model/meter/crq/crqChannelCategory';
import { renderType, actionsButtons } from './cell';

export const getCrqChannelsColumns = (
    handleOpenModal: (channel?: CrqChannelCreator)=> void,
    handleRemove: (category?: CrqChannelCategory)=> void
): Column[] => {
    return [
        {
            title: 'Категория',
            field: 'category',
            cellClassName: 'col-category',
            sorting: false,
        },
        {
            title: 'Тип канала',
            field: 'type',
            cellClassName: 'col-type',
            sorting: false,
            render: (channel: CrqChannelViewModel) => {
                return renderType(channel.type);
            }
        },
        {
            title: 'Номер канала',
            field: 'number',
            cellClassName: 'col-number',
            sorting: false,
        },
        {
            title: 'Единица измерения',
            field: 'unitOfMeasurement',
            cellClassName: 'col-unitOfMeasurement',
            sorting: false,
        },
        {
            title: 'Действия',
            field: 'category',
            cellClassName: 'col-actions',
            sorting: false,
            render: (data: CrqChannelViewModel) => {
                const onEdit = () => handleOpenModal(data);
                const onRemove = () => handleRemove(data.category);
                return actionsButtons(onEdit, onRemove);
            },
        }
    ];
};
