import { Box, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { DateFormats, hoursInDay, minutesInHour } from '../../../../../common/constants/date';
import { getCurrentDate } from '../../../utils/dates';
import { Typography } from '../../typography';
import { TimeInputProps } from './model';

const useStyles = makeStyles((theme) => ({
    timeInput: {
        width: '116px',
        backgroundColor: theme.palette.neutral.white,
    },
    timeWrapper: {
        height: '252px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
    },
    timeIntervalsWrapper: {
        height: '100%',
        width: '36px',
        overflow: 'hidden',
    },
    timeIntervals: {
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: '17px',
        boxSizing: 'content-box',
        '-webkit-overflow-scrolling': 'touch',
    },
    timeItem: {
        height: '32px',
        width: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '4px',
        cursor: 'pointer',
        borderRadius: '100px',
        '&:hover': {
            backgroundColor: theme.palette.neutral.background,
        },
    },
    futureTime: {
        color: theme.palette.neutral.light,
    },
    selectedTime: {
        color: theme.palette.neutral.white,
        backgroundColor: theme.palette.primary.light,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        }
    },
    disabledTime: {
        color: theme.palette.neutral.light,
        cursor: 'not-allowed',
    },
}));

// TODO scroll to selected time when calendar open
export const TimeInput = ({ date, value, onChange, minDateTime, maxDateTime }: TimeInputProps) => {
    const classes = useStyles();

    const currentDate = getCurrentDate();
    const isFutureDate = moment(date).isAfter(moment(currentDate), 'day');
    const isCurrentDate = moment(date).isSame(moment(currentDate), 'day');
    const currentHour = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();

    const maxTime = maxDateTime && moment(maxDateTime).format(DateFormats.timeFormat);
    const minTime = minDateTime && moment(minDateTime).format(DateFormats.timeFormat);
    const isMaxDateSameAsSelected = maxDateTime && moment(maxDateTime).isSame(moment(date), 'day');
    const isMinDateSameAsSelected = minDateTime && moment(minDateTime).isSame(moment(date), 'day');
    const isMaxHourSameAsSelected = maxDateTime && moment(maxDateTime).isSame(moment(date), 'hours');
    const isMinHourSameAsSelected = minDateTime && moment(minDateTime).isSame(moment(date), 'hours');

    const maxHour = isMaxDateSameAsSelected
        ? maxTime.slice(0, maxTime.indexOf(':'))
        : hoursInDay;
    const maxMinutes = isMaxDateSameAsSelected && isMaxHourSameAsSelected
        ? maxTime.slice(maxTime.indexOf(':') + 1)
        : minutesInHour;
    const minHour = isMinDateSameAsSelected
        ? minTime?.slice(0, minTime.indexOf(':'))
        : 0;
    const minMinutes = isMinDateSameAsSelected && isMinHourSameAsSelected
        ? minTime?.slice(minTime.indexOf(':') + 1)
        : 0;

    const hours: string[] = [];
    const minutes: string[] = [];
    const availableHours: string[] = [];
    const availableMinutes: string[] = [];

    for (let i = +minHour; i < +maxHour; i++) {
        availableHours.push(i < 10 ? `0${i}` : i.toString());
    }

    for (let i = +minMinutes; i < +maxMinutes; i++) {
        availableMinutes.push(i < 10 ? `0${i}` : i.toString());
    }

    for (let i = 0; i < hoursInDay; i++) {
        hours.push(i < 10 ? `0${i}` : i.toString());
    }

    for (let i = 0; i < minutesInHour; i++) {
        minutes.push(i < 10 ? `0${i}` : i.toString());
    }

    const selectedHour = value?.slice(0, value.indexOf(':')) ?? hours[0];
    const selectedMinutes = value?.slice(value.indexOf(':') + 1) ?? minutes[0];

    const getValue = (val: string, type: 'hour' | 'minutes') => {
        return type === 'hour'
            ? `${val}:${selectedMinutes}`
            : `${selectedHour}:${val}`;
    };

    return (
        <div className={classes.timeInput}>
            <Box display='flex' alignItems='center' justifyContent='center' pb={2}>
                <Typography variant='h3'>Время</Typography>
            </Box>
            <div className={classes.timeWrapper}>
                <div className={classes.timeIntervalsWrapper}>
                    <div className={classes.timeIntervals}>
                        {hours.map((hour) => {
                            const isHourAvailable = availableHours.includes(hour);
                            return (
                                <div
                                    className={
                                        classNames(
                                            classes.timeItem,
                                            {
                                                [classes.selectedTime]: selectedHour === hour,
                                                [classes.futureTime]: isFutureDate || (isCurrentDate && parseInt(hour) > currentHour),
                                                [classes.disabledTime]: !isHourAvailable
                                            }
                                        )
                                    }
                                    key={hour}
                                    onClick={isHourAvailable ? () => onChange(getValue(hour, 'hour')) : null}
                                >
                                    <Typography variant='body'>{hour}</Typography>
                                </div>
                            );
                        }) }
                    </div>
                </div>
                <div className={classes.timeIntervalsWrapper}>
                    <div className={classes.timeIntervals}>
                        {minutes.map((minute) => {
                            const isMinuteAvailable = availableMinutes.includes(minute);
                            return (
                                <div
                                    className={
                                        classNames(
                                            classes.timeItem,
                                            {
                                                [classes.selectedTime]: selectedMinutes === minute,
                                                [classes.futureTime]: isFutureDate || (isCurrentDate && parseInt(minute) > currentMinutes),
                                                [classes.disabledTime]: !isMinuteAvailable
                                            }
                                        )
                                    }
                                    key={minute}
                                    onClick={isMinuteAvailable ? () => onChange(getValue(minute, 'minutes')) : null}
                                >
                                    <Typography variant='body'>{minute}</Typography>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
