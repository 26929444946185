import React from 'react';

interface Props {
    visible: boolean;
}

export const ConditionView: React.FC<Props> = ({ visible, children }) => {
    if (!visible) {
        return null;
    }
    return (
        <>{children}</>
    );
};
