import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Tabs } from '@material-ui/core';
import { OwnProps } from './model';

export const View = withStyles(() =>
    createStyles({
        root: {
            minHeight: '50px',
        },
        indicator: {
            display: 'none',
        },
    })
)((props: OwnProps) => <Tabs style={props.styles} {...props} variant='standard' />);
