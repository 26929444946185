export const DateFormats = {
    dateTimeFormat: 'DD.MM.YYYY HH:mm',
    dateTimeFormatShort: 'YYYYMMDDHHmmss',
    dateFullTimeFormat: 'DD.MM.YYYY HH:mm:ss',
    dateTimeZoneFormat: 'DD.MM.YYYY HH:mm (Z)',
    dateFormat: 'DD.MM.YYYY',
    dateFormatShort: 'YYYYMMDD',
    timeFormat: 'HH:mm',
    dateFormatNative: 'YYYY-MM-DD',
    dateTimeInputFormat: 'dd.MM.yyyy HH:mm',
    dateInputFormat: 'dd.MM.yyyy',
    apiDateTransformFormat: 'YYYY-MM-DDTHH:mm:ss[Z]',
    apiDateParseFormat: 'YYYY-MM-DDTHH:mm:ssZ',
    dateFullTimeSQL: 'YYYY-MM-DD HH:mm:ss',
    dateOnlyTimeZone: 'Z',
    timeFullWithTimeZone: 'HH:mm:ss (Z)',
    dateTimeFullWithTimeZone: 'YYYY-MM-DD HH:mm:ss (Z)',
    dateTimeFullWithTimeZoneAndMs: 'YYYY-MM-DD HH:mm:ss.SSS (Z)',
    dateTimeFullWithoutTimeZone: 'YYYY-MM-DDTHH:mm:ss',
    timeFormatShort: 'HHmm',
};

export const hoursInDay = 24;
export const minutesInHour = 60;
