export enum Routes {
    empty = '/',
    login = '/login',
    authError = '/auth-error',
    profile = '/profile',

    meters = '/meters',
    meterConfigurationHistory = '/meters/:meterId/meter-configuration-history',
    meter = '/meters/:meterId/',
    meterPasswordManagement = '/meters/:meterId/password-management',
    monitoring = '/monitoring',
    report = '/report',
    reportPage = '/report/:reportType',
    eventLog = '/eventLog',
    administration = '/administration',
    relatedSystems = '/relatedSystems',
    meterActivationOrder = '/meter-activation-orders/:meterActivationOrderId',
    importCrqLog = '/import-crq-log/:importCrqFileId',
    newMeterActivationOrder = '/meter-activation-orders/new',
    meterActionPlan = '/meter-survey-plan',
    meterSearch = '/search',
    meterActionSchemeCreate = '/administration/schemes/create',
    meterActionSchemeEdit = '/administration/schemes/edit/:schemeId',
    meterGroupCreate = '/meter-groups/create',
    meterGroupEdit = '/meter-groups/edit/:groupId',
    meterPowerSwitchOrder = '/meter-power-switch-orders/:meterPowerSwitchOrderId',
    PartnerCreate = '/partners/create',
    PartnerEdit = '/partners/:partnerId',
    PartnerMetersCreate = '/partners/:partnerId/meters/create',
    meterGisTaskEdit = '/meter-gis-task/:taskId',
    meterDictionary = '/dictionary/:dictionaryName',
    meterModels = '/dictionary/meter-models',
    specialDayBase = '/dictionary/special-day-base',
    meterLabelType = '/dictionary/meter-label-type',
    eventTypes = '/dictionary/event-types',
    timeSynchronizationSchemeDetails = '/time-synchronization-schemes/:schemeId',
    timeSynchronizationSchemeNew = '/time-synchronization-schemes/new',

    tariffSchedules = '/tariff-schedules',
    tariffScheduleVersionCreate = '/tariff-schedules/:tariffScheduleId/create',
    tariffScheduleVersionEdit = '/tariff-schedules/:tariffScheduleId/:tariffScheduleVersionId',
    tariffScheduleOrders = '/tariff-schedules/orders',
    massOperation = '/mass-operations/:id',
    massOperationCreate = '/mass-operations/create',
    meterFirmwareVersions = '/meter-firmware-versions',
}

export enum MeterTabs {
    Reading = 'reading',
    Consumption = 'consumption',
    Gis = 'gis',
    Information = 'information',
    Management = 'management',
    EventLog = 'event-log',
    Requests = 'requests',
    CrqInterface = 'crq-interface',
    Problems = 'problems',
    Incidents = 'incidents',
    Polls = 'polls',
}

export enum ReadingSubs {
    List = 'list',
    Chart = 'chart',
}

export enum ConsumptionSubTabs {
    Electricity = 'electricity',
    ElectricityTariff = 'electricity-tariff',
}

export enum ImportMeterSubTabs {
    ImportCrq = 'import-crq',
    ImportOrders = 'import-orders',
}

export enum AdministrationTabs {
    Schemes = 'schemes',
    TariffSchedules = 'tariff-schedules',
    Access = 'access',
    Settings = 'settings',
    Groups = 'groups',
    Users = 'users',
    Audit = 'audit',
    Dictionaries = 'dictionaries',
    TimeSynchronizationSchemes = 'time-synchronization-schemes',
    ChangePasswordRequests = 'change-password-requests',
}

export enum MonitoringTabs {
    MassOperation = 'massOperation',
    MeterHealth = 'meterHealth',
    SurveyCondition = 'surveyCondition',
    UnlegalAccess = 'unlegalAccess',
    Users = 'users',
    Consumption = 'consumption',
    SurveyStatistic = 'surveyStatistic',
    EventLog = 'eventLog',
}

export enum ReportTabs {
    DefaultReports = 'default',
}

export enum ReportPageTabs {
    NotConnectedMeters = 'not-connected-meters',
    AllMeters = 'all-meters',
}

export enum AccessTabs {
    Roles = 'roles',
    Users = 'users',
}

export enum MeterActivationOrderTabs {
    Opened = 'opened',
    Completed = 'completed',
}

export enum RelatedSystemsTabs {
    MeterActivationOrders = 'meter-activation-orders',
    ImportMeterActivationOrders = 'import-meter-activation-orders',
    PowerSwitchOrders = 'power-switch-orders',
    Import8002080040 = 'import-80020-80040',
    Export8002080040 = 'export-80020-80040',
    Partners = 'partners',
    GisTasks = 'gis-tasks',
    NetworkOperators = 'network-operators',

}
