import React from 'react';
import _ from 'lodash';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { Breadcrumbs } from '../../../shared/components/breadcrumbs';
import { Heading } from '../../../shared/components/heading';
import { HookField } from '../../../shared/components/hookField';
import { Table } from '../../../shared/components/table';
import { Wrapper } from '../../../shared/components/wrapper';
import { logTypeSuggestions, criticalSuggestions } from './common';
import { setOrderTransformer } from '../../../shared/components/pagination/helpers';
import { Pagination } from '../../../shared/components/pagination';
import { IStateProps } from './model';
import { setColumnWidth } from '../../../shared/pipes/styles';
import { HookFieldTransformer } from '../../../shared/components/hookField/model';
import { VAutocompleteOption } from '../../../shared/components/autocomplete/model';

const useStyles = makeStyles(() => ({
    table: {
        '&.col-event-code': {
            ...setColumnWidth('30px'),
        },
        '&.col-event-type': {
            ...setColumnWidth('120px'),
        },
        '&.col-event-desc': {
            ...setColumnWidth('120px'),
        },
        '&.col-event-format': {
            ...setColumnWidth('120px'),
        },
        '&.col-event-critical': {
            ...setColumnWidth('80px'),
            '& span': {
                textAlign: 'center',
                justifyContent: 'center',
            }
        },
        '&.col-event-visible': {
            ...setColumnWidth('80px'),
            '& span': {
                textAlign: 'center'
            }
        },
    },
    tableRow: {
        '& .event-row-checkbox': {
            display: 'flex',
            justifyContent: 'center',
            '& .MuiFormControlLabel-root': {
                margin: 0,
            }
        },
    },
    form: {
        width: '100%',
        maxWidth: 800,
    }
}));


export const View: React.FC<IStateProps> = (props) => {
    const {
        form,
        data,
        columns,
        heading,
        breadcrumbs,
        onBackHandler,
        onClickBreadcrumb,
        pagination,
        dataTotal,
        setPage,
        orderParams,
        loading,
        setOrder,
        eventTypeAutocomplete,
    } = props;
    const classes = useStyles();

    const eventTypeValuesTransformer: HookFieldTransformer = {
        input: (v) => {
            return eventTypeAutocomplete.options.filter(item => v?.includes(item.value));
        },
        output: (v) => {
            return _.uniq(v.map((item: VAutocompleteOption) => item.value));
        },
    };

    const actions = <>
        <FormProvider {...form}>
            <form className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item xs={4} >
                        <HookField
                            fullWidth
                            label='Тип журнала'
                            type='select'
                            name='logTypeId'
                            suggestions={logTypeSuggestions}
                            isClearable
                        />
                    </Grid>

                    <Grid item xs={5} >
                        <HookField
                            withCheckbox
                            name='eventTypeIds'
                            type='autocomplete'
                            label='Тип события'
                            fullWidth
                            noOptionsText='Событие не найдено'
                            loadingText='Поиск...'
                            {...eventTypeAutocomplete}
                            transform={eventTypeValuesTransformer}
                        />
                    </Grid>

                    <Grid item xs={3} >
                        <HookField
                            fullWidth
                            label='Критичность'
                            type='select'
                            name='isCritical'
                            suggestions={criticalSuggestions}
                            isClearable
                        />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    </>;

    return <>
        <Box mb={3}>
            <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
        </Box>
        <Box mb={3}>
            <Heading heading={heading} onBackHandler={onBackHandler} />
        </Box>
        <Wrapper isBigHeader actions={actions}>
            <Table
                title={null}
                data={data || []}
                columns={columns}
                variant='white'
                options={{
                    paging: 'disabled',
                    grouping: false,
                    filtering: false,
                    search: false,
                    toolbar: false,
                    headerClassName: classes.table,
                    showTitle: false,
                    rowClassName: classes.tableRow,
                }}
                isLoading={loading}
                onOrderChange={setOrderTransformer(columns, setOrder)}
                {...orderParams}
            />
            <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={loading || !data} />
        </Wrapper>
    </>;
};
