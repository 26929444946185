import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { RoleViewModel } from '../../../../../common/model/access/role/roleViewModel';
import { ActionType } from '../../../../actions';

export interface ActiveRoleAccessState {
    role: RoleViewModel;
    accessRules: AccessRule[];
}

const initialState: ActiveRoleAccessState = {
    role: null,
    accessRules: [],
};

export const activeRoleAccessSlice = createSlice({
    name: '@@vostok/access/roles/active',
    initialState,
    reducers: {
        setActiveRole(state, action: PayloadAction<RoleViewModel>): void {
            state.role = action.payload;
        },
        setAccessRules(state, action: PayloadAction<AccessRule[]>): void {
            state.accessRules = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): ActiveRoleAccessState => initialState,
    }
});
