import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { AccessRule } from '../../../../../../common/model/access/accessRule';
import { AddressViewModel } from '../../../../../../common/model/meter/addressViewModel/addressViewModel';
import { MeterActivationOrderCreator } from '../../../../../../common/model/meter/meterActivationOrder/meterActivationOrderCreator';
import { BaseDispatch } from '../../../../../../redux/actions';
import { findAddressSuggestions } from '../../../../../../redux/actions/addresses';
import { resourcesNetworkOperatorsSelector } from '../../../../../../redux/selectors';
import { useAddressAutocomplete } from '../../../../../shared/components/autocomplete/hooks/useAddressAutocomplete';
import { useCreateAddress } from '../../../../../shared/components/createAddress/useCreateAddress';
import { useAccessRules } from '../../../../../shared/hooks/useAccessRules';
import { useYupValidationResolver } from '../../../../../shared/utils/validation';
import { ExpandedPanels, ExpandedPanelsState, MeterActivationOrderFormProps } from './model';
import { View } from './view';

const initialExpandedPanels = {
    [ExpandedPanels.CommonParameters]: true,
    [ExpandedPanels.SecurityParameters]: false,
    [ExpandedPanels.Communicator]: false,
    [ExpandedPanels.TechnicalParameters]: false,
};
const getInitialMeterActivationOrder = (networkOperatorId: number = undefined): MeterActivationOrderCreator => ({
    adminPassword: '',
    connectionType: null,
    foreignId: '',
    code: '',
    installationSite: '',
    ipPort: null,
    meterModelId: null,
    meterSerialNum: '',
    userPassword: '',
    networkAddress: '',
    iccId: '',
    communicatorSerialNum: '',
    fiasAoGuid: null,
    addressSpecifier: '',
    manufactureDate: null,
    password3: '',
    accountType: null,
    meterPurpose: null,
    legalEntityType: null,
    priceCategory: null,
    nextVerificationDate: null,
    installationDate: null,
    communicatorModel: '',
    apn: '',
    communicatorPassword1: '',
    communicatorPassword2: '',
    cTRatio: '',
    pTRatio: '',
    timeZone: 300,
    dst: false,
    changePasswordForbidden: true,
    changeTariffForbidden: false,
    changeTimeForbidden: false,
    changeIntervalForbidden: false,
    powerSwitchForbidden: false,
    tariffCount: null,
    networkOperatorId,
});

const meterActivationOrderValidationScheme = yup.object({
    meterModelId: yup.number()
        .required('Выберите модель ПУ'),
    meterSerialNum: yup.string()
        .required('Укажите заводской номер'),
    iccId: yup.string()
        .required('Укажите серийный номер сим-карты'),
    fiasAoGuid: yup.string()
        .required('Адрес не выбран'),
    installationDate: yup.date()
        .required()
        .typeError('Укажите дату установки'),
});

export const MeterActivationOrderForm: React.FC<MeterActivationOrderFormProps> = (props) => {
    const { hasAccess } = useAccessRules();
    const resolver = useYupValidationResolver(meterActivationOrderValidationScheme);
    const dispatch = useDispatch<BaseDispatch>();
    const [expandedPanels, setExpandedPanels] = useState<ExpandedPanelsState>(initialExpandedPanels);
    const networkOperators = useSelector(resourcesNetworkOperatorsSelector());

    const addressAutocomplete = useAddressAutocomplete({
        valueMapKey: 'addressGuid',
    });

    const defaultNetworkOperatorId = networkOperators.find(item => item.isDefault)?.id;

    const form = useForm<MeterActivationOrderCreator>({
        defaultValues: props.meterActivationOrder ?? getInitialMeterActivationOrder(defaultNetworkOperatorId),
        resolver
    });

    const handleChangeExpanded = (panel: ExpandedPanels) => (event: any, isExpanded: boolean) => {
        setExpandedPanels((expandedState) => ({
            ...expandedState,
            [panel]: isExpanded,
        }));
    };

    const onResetForm = () => {
        form.reset(getInitialMeterActivationOrder(defaultNetworkOperatorId));
        props.onFormDirtyChange(false);
    };

    // TODO При открытии формы сразу запрашиваем все адреса, после интеграции адресов с биллингом необходимо убрать
    useEffect(() => {
        dispatch(findAddressSuggestions());
    }, []);

    useEffect(() => {
        props.onFormDirtyChange(form.formState.isDirty);
    }, [form.formState.isDirty]);

    const useCreateAddressProps = useCreateAddress((address: AddressViewModel) => {
        addressAutocomplete.onInputChange(null, address.address, 'input');
        if (address?.addressGuid) {
            form.setValue('fiasAoGuid', address?.addressGuid);
        }
    });

    return View({
        ...props,
        ...useCreateAddressProps,
        form,
        onResetForm,
        addressAutocomplete,
        expandedPanels,
        handleChangeExpanded,
        networkOperators,
        canPostMeterActivationOrder: hasAccess(AccessRule.CanPostMeterActivationOrder),
        canReprocessMeterActivationOrder: hasAccess(AccessRule.CanReprocessMeterActivationOrder),
    });
};
