import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterActivationOrderDetailsViewModel } from '../../../../common/model/meter/meterActivationOrder/meterActivationOrderDetailsViewModel';


export interface MeterActivationOrderDetailsState {
    meterActivationOrder: MeterActivationOrderDetailsViewModel;
    loading: boolean;
}

const initialState: MeterActivationOrderDetailsState = {
    meterActivationOrder: null,
    loading: false,
};

export const meterActivationOrderDetailsSlice = createSlice({
    name: '@@vv/meterActivationOrders/details',
    initialState,
    reducers: {
        setMeterActivationOrderDetails(state, action: PayloadAction<MeterActivationOrderDetailsViewModel>): void {
            state.meterActivationOrder = action.payload;
            state.loading = false;
        },
        clearMeterActivationOrderDetails(state) {
            state.meterActivationOrder = null;
            state.loading = false;
        },
        setMeterActivationOrderLoading(state, action: PayloadAction<boolean>): void {
            state.loading = action.payload;
        },
    }
});
