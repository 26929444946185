import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DictionaryItem } from '../../../../../../../../common/model/resources/dictionaries/dictionaryItem';
import { BaseDispatch } from '../../../../../../../../redux/actions';
import {
    findAreaNamesSuggestions, findHouseNumbersSuggestions,
    findStreetNamesSuggestions,
    findTownNamesSuggestions
} from '../../../../../../../../redux/actions/addresses';
import {
    addressSuggestionsLoadingSelector,
    areaNamesSuggestionsSelector,
    houseNumbersSuggestionsSelector,
    streetNamesSuggestionsSelector,
    townNamesSuggestionsSelector
} from '../../../../../../../../redux/selectors/addresses';
import { VAutocompleteOption } from '../../../../../../../shared/components/autocomplete/model';
import { defaults } from '../../../../../../../shared/constants';
import { useWebDictionary } from '../../../../../../../shared/hooks/useWebDictionary';
import { debounce } from '../../../../../../../shared/pipes';
import { InputProps } from './model';
import { View } from './view';

export const AddressPicker: React.FC<InputProps> = (props) => {

    const dispatch = useDispatch<BaseDispatch>();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onClickModalOpen = () => {
        setIsModalOpen(true);
    };

    const onClickModalClose = () => {
        setIsModalOpen(false);
    };

    // TODO пока забираем все адреса сразу с бэкенда, когда будет интеграция с биллингом надо будет переделать
    const areaNamesSuggestions = useSelector(areaNamesSuggestionsSelector);
    const townNamesSuggestions = useSelector(townNamesSuggestionsSelector);
    const streetNamesSuggestions = useSelector(streetNamesSuggestionsSelector);
    const houseNumbersSuggestions = useSelector(houseNumbersSuggestionsSelector);
    const isAddressSuggestionsLoading = useSelector(addressSuggestionsLoadingSelector);
    const { regions } = useWebDictionary();
    const [regionSuggestions, setRegionSuggestions] = useState<DictionaryItem[]>([]);

    const getAllSuggestions = () => {
        dispatch(findAreaNamesSuggestions());
        dispatch(findTownNamesSuggestions());
        dispatch(findStreetNamesSuggestions());
        dispatch(findHouseNumbersSuggestions());
    };

    const onSearchAreaName = async (value?: string) => {
        await debounce(() => dispatch(findAreaNamesSuggestions(value)), defaults.debounceTimeForAutocompleteSearchMs);
    };

    const onSearchTownName = async (value?: string) => {
        await debounce(() => dispatch(findTownNamesSuggestions(value)), defaults.debounceTimeForAutocompleteSearchMs);
    };

    const onSearchStreetName = async (value?: string) => {
        await debounce(() => dispatch(findStreetNamesSuggestions(value)), defaults.debounceTimeForAutocompleteSearchMs);
    };

    const onSearchHouseNumber = async (value?: string) => {
        await debounce(() => dispatch(findHouseNumbersSuggestions(value)), defaults.debounceTimeForAutocompleteSearchMs);
    };

    const onSearchRegions = (value?: string) => {
        setRegionSuggestions(regions.filter(region => region.name.includes(value)));
    };

    const mapToOptions = (suggestions: string[]): VAutocompleteOption[] => {
        return suggestions.map(suggestion => ({
            value: suggestion,
            optionsLabel: suggestion,
            label: suggestion
        }));
    };

    useEffect(() => {
        getAllSuggestions();
    }, []);

    useEffect(() => {
        setRegionSuggestions(regions);
    }, [regions]);

    return View({
        ...props,
        isModalOpen,
        onClickModalOpen,
        onClickModalClose,
        isAddressSuggestionsLoading,
        areaNamesSuggestions: mapToOptions(areaNamesSuggestions),
        townNamesSuggestions: mapToOptions(townNamesSuggestions),
        streetNamesSuggestions: mapToOptions(streetNamesSuggestions),
        houseNumbersSuggestions: mapToOptions(houseNumbersSuggestions),
        regions: regionSuggestions.map(region => ({
            value: region.id,
            label: region.name,
            optionsLabel: region.name
        })),
        onSearchRegions,
        onSearchAreaName,
        onSearchTownName,
        onSearchStreetName,
        onSearchHouseNumber,
    });
};
