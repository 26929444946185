import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box/Box';
import React from 'react';
import { Button } from '../button';
import { IconType } from '../icon/model';

interface Props {
    onClick: () => void;
    boxProps?: BoxProps;
    title?: string;
    icon?: IconType;
}

export const DetailsButtonView: React.FC<Props> = ({
    onClick,
    boxProps = {},
    title = 'Подробнее',
    icon = undefined,
}) => {
    return <Box display="flex" justifyContent="flex-end" {...boxProps}>
        <Button variant="primary" icon={icon} onClick={onClick}>{title}</Button>
    </Box>;
};
