import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { Button } from '../../../../shared/components/button';
import { HookField } from '../../../../shared/components/hookField';
import { Modal } from '../../../../shared/components/modal';
import { Typography } from '../../../../shared/components/typography';
import { useWebDictionarySelectSuggestions } from '../../../../shared/hooks/useWebDictionary';
import { IStateProps } from './model';

export const View: FC<IStateProps> = (props) => {
    const {
        form,
        onClose,
        onSubmit,
        isLoading,
        meterGroupAutocomplete
    } = props;

    const { handleSubmit } = form;

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    return (
        <>
            <Modal
                onHide={onClose}
                title='Новая заявка'
                close='Отмена'
                width='508px'
            >
                <FormProvider {...form}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={3} >
                                        <Typography variant='bodyBold'>Регион</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <HookField
                                            type='select'
                                            name='regionId'
                                            label='Регион'
                                            disabled={isLoading}
                                            suggestions={regionSuggestions}
                                            fullWidth />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={3} >
                                        <Typography variant='bodyBold'>Группа ПУ</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <HookField
                                            type='autocomplete'
                                            name='meterGroupId'
                                            label='Группа ПУ'
                                            disabled={isLoading}
                                            {...meterGroupAutocomplete}
                                            fullWidth />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify='center'>
                                    <Grid item>
                                        <Button
                                            isLoading={isLoading}
                                            variant='primary'
                                            type='submit'
                                            icon='check'
                                        >
                                        Сохранить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='secondary' type='button' onClick={onClose}>Отмена</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Modal>
        </>
    );
};
