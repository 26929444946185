import { dailyProfilesListSlice, DailyProfilesListState } from './list';
import { dailyProfilesChartSlice, DailyProfilesChartState } from './chart';
import { combineReducers } from 'redux';


export interface DailyProfilesState {
    list: DailyProfilesListState;
    chart: DailyProfilesChartState;
}

export const dailyProfiles = combineReducers<DailyProfilesState>({
    list: dailyProfilesListSlice.reducer,
    chart: dailyProfilesChartSlice.reducer,
});
