import { MeterReadingIncrementalProfileFilter } from '../../../../../common/model/meter/meter/meterReadingIncrementalProfileFilter';
import { MeterReadingIncrementalProfileViewModel } from '../../../../../common/model/meter/meter/meterReadingIncrementalProfileViewModel';
import {
    getCurrentDate,
    getDateMonthBefore
} from '../../../../../app/shared/utils/dates';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IncrementalProfilesChartState {
    filter: MeterReadingIncrementalProfileFilter;
    available: MeterReadingIncrementalProfileViewModel[];
}

const initialState: IncrementalProfilesChartState = {
    filter: {
        dateFrom: getDateMonthBefore(),
        dateTo: getCurrentDate(),
    },
    available: [],
};

export const incrementalProfilesChartSlice = createSlice({
    name: '@@vv/meterReading/incrementalProfile/chart',
    initialState,
    reducers: {
        setAvailableIncrementalProfiles(state, action: PayloadAction<MeterReadingIncrementalProfileViewModel[]>) {
            state.available = action.payload;
        },
        setFilter(state, action: PayloadAction<MeterReadingIncrementalProfileFilter>) {
            state.filter = action.payload;
        },
        clearFilter(state) {
            state.filter = initialState.filter;
        }
    }
});
