import React from 'react';
import { useForm } from 'react-hook-form';
import { newGuid } from '../../../../../../common/shared/utils';
import { InputProps, SpecialDayBaseFormValues } from './model';
import { View } from './view';

const getFormDefaultValues = (): SpecialDayBaseFormValues => ({
    id: newGuid(),
    day: null,
    month: null,
    name: null,
});

export const SpecialDayBaseForm: React.FC<InputProps> = (props) => {
    const {
        viewModel,
    } = props;
    const form = useForm<SpecialDayBaseFormValues>({
        defaultValues: viewModel || getFormDefaultValues(),
    });

    return View({
        ...props,
        form,
    });
};
