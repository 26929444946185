import React from 'react';
import { Button } from '../../../../../shared/components/button';

interface Props {
    onClick: () => void;
}

export const ShowMetersButton: React.FC<Props> = ({ onClick }) => {
    return <Button className="btn-details" onClick={onClick} variant="primary">Приборы учета</Button>;
};
