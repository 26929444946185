import { combineReducers } from 'redux';
import { CommonMeterGisState, meterGisTasksSlice } from './meterGisTasks';


export interface MeterGisTasks {
    meterGisTasks: CommonMeterGisState;
}

export const meterGisTasks = combineReducers<MeterGisTasks>({
    meterGisTasks: meterGisTasksSlice.reducer,
});
