import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button } from 'app/shared/components/button';
import { Wrapper } from 'app/shared/components/wrapper';
import { Select } from 'app/shared/components/select';
import { CrqChannelsViewProps } from './model';
import { CrqChannelsTable } from './components/crqChannelsTable';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 570
    },
    saveBtn: {
        alignSelf: 'start',
    }
}));

export const View: React.FC<CrqChannelsViewProps> = ({
    crqSystemId,
    meterActionSchemeId,
    schemesSuggestions,
    crqSystemsSuggestions,
    isLoading,
    isDisabledSaveButton,
    crqChannels,
    handleChangeCrqSystemId,
    handleChangeMeterActionSchemeId,
    handleChangeCrqChannels,
    handleSave,
}) => {
    const classes = useStyles();

    return (
        <Wrapper title='КАНАЛЫ CRQ-ИНТЕРФЕЙСА' isLoading={isLoading} >
            <Box className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                        <Select
                            label='CRQ-интерфейс'
                            fullWidth
                            value={crqSystemId}
                            onChange={handleChangeCrqSystemId}
                            suggestions={crqSystemsSuggestions}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                        <Select
                            label='Схема опроса'
                            fullWidth
                            value={meterActionSchemeId}
                            onChange={handleChangeMeterActionSchemeId}
                            suggestions={schemesSuggestions}
                        />
                    </Grid>

                    {crqSystemId && crqChannels && (
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <CrqChannelsTable
                                crqChannels={crqChannels}
                                handleChangeCrqChannels={handleChangeCrqChannels}
                            />
                        </Grid>
                    )}
                </Grid>

                <Button
                    variant='primary'
                    className={classes.saveBtn}
                    disabled={isDisabledSaveButton}
                    onClick={() => handleSave()}
                >
                    Сохранить
                </Button>

            </Box>
        </Wrapper>
    );
};
