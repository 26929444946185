// Состояние заявки на активацию ПУ

export enum MeterActivationOrderStatus {
    Created = 'Created',
    DeterminationOfIPAddress = 'DeterminationOfIPAddress',
    InitialDiagnostic = 'InitialDiagnostic',
    CompletedSuccessfully = 'CompletedSuccessfully',
    RuntimeError = 'Runtime error',
    Deleted = 'Deleted',
}

export const meterActivationOrderStatusDictionary: Record<MeterActivationOrderStatus, string> = {
    [MeterActivationOrderStatus.Created]: 'Создана',
    [MeterActivationOrderStatus.DeterminationOfIPAddress]: 'Определение IP-адреса',
    [MeterActivationOrderStatus.InitialDiagnostic]: 'Первичная диагностика ПУ',
    [MeterActivationOrderStatus.CompletedSuccessfully]: 'Выполнена успешно',
    [MeterActivationOrderStatus.RuntimeError]: 'Ошибка выполнения',
    [MeterActivationOrderStatus.Deleted]: 'Удалена',
};
