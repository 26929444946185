import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

/* polyfills.js */
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/from';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/ends-with';
import 'core-js/features/number/is-nan';
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './styles/style.scss';

import { App } from './app';
import './app/shared/utils/yup';
import { configureStore } from './redux/store/configureStore';

export const store = configureStore();

ReactDOM.render(
    <App />,
    document.getElementById('root'),
);
