import React from 'react';
import { ImportCrqLogHeaderViewProps } from './model';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Typography } from 'app/shared/components/typography';
import { Wrapper } from 'app/shared/components/wrapper';
import { Badge } from 'app/shared/components/badge';
import { BadgeState } from 'app/shared/components/badge/model';
import { formatDate } from 'app/shared/utils/dates';
import { DateFormats } from 'common/constants/date';
import { MeterFileImportStatus, meterFileImportStatusDictionary } from 'common/model/meter/importCrq/meterFileImportStatus';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        marginTop: 16,
        marginBottom: 16
    },
    title: {
        ...theme.typography.captionBold,
        color: theme.palette.text.hint,
        overflow: 'hidden',
        wordWrap: 'break-word',
    },
    item: {
        color: theme.palette.text.primary,
        fontSize: '14px',
        overflow: 'hidden',
        wordWrap: 'break-word',
    },
    itemRed: {
        color: theme.palette.error.main,
        fontWeight: 600,
    },
}));

export const View: React.FC<ImportCrqLogHeaderViewProps> = ({
    importCrq,
}) => {
    const classes = useStyles();

    const badgeStateByMeterStatus: Record<MeterFileImportStatus, BadgeState> = {
        [MeterFileImportStatus.Received]: 'open',
        [MeterFileImportStatus.Processing]: 'notDetermined',
        [MeterFileImportStatus.Finished]: 'success',
        [MeterFileImportStatus.Error]: 'fail',
    };

    return (
        <Wrapper className={classes.headerWrapper}>
            <Grid container spacing={2}>
                <Grid item xs={2} >
                    <Box mb={1} mr={1}>
                        <Typography className={classes.title} variant="caption">
                                Строк в файле
                        </Typography>
                    </Box>
                    <Typography className={classes.item} variant="caption">
                        {importCrq.rowCount}
                    </Typography>
                </Grid>
                <Grid item xs={2} >
                    <Box mb={1} mr={1}>
                        <Typography className={classes.title} variant="caption">
                                Статус
                        </Typography>
                    </Box>
                    <Badge
                        state={badgeStateByMeterStatus[importCrq.importStatus]}
                        text={meterFileImportStatusDictionary[importCrq.importStatus]}
                    />
                </Grid>
                <Grid item xs={2} >
                    <Box mb={1} mr={1}>
                        <Typography className={classes.title} variant="caption">
                            Начало обработки
                        </Typography>
                    </Box>
                    <Typography className={classes.item} variant="caption">
                        {formatDate(importCrq.createdAt, DateFormats.dateTimeZoneFormat)}
                    </Typography>
                </Grid>
                <Grid item xs={2} >
                    <Box mb={1} mr={1}>
                        <Typography className={classes.title} variant="caption">
                            Окончание обработки
                        </Typography>
                    </Box>
                    <Typography className={classes.item} variant="caption">
                        {importCrq.importStatus === MeterFileImportStatus.Finished && importCrq.changedAt ?
                            formatDate(importCrq.changedAt, DateFormats.dateTimeZoneFormat) : '-'
                        }
                    </Typography>
                </Grid>
                <Grid item xs={2} >
                    <Box mb={1} mr={1}>
                        <Typography className={classes.title} variant="caption">
                        Ошибок
                        </Typography>
                    </Box>
                    <Typography
                        className={classNames(classes.item,
                            { [classes.itemRed]: importCrq.errorCount > 0 })}
                        variant="caption"
                    >
                        {importCrq.errorCount}
                    </Typography>
                </Grid>
            </Grid>
        </Wrapper>

    );
};
