import { CrqSystemViewModel } from 'common/model/meter/crq/crqSystemViewModel';

export interface CrqSystemTableOwnProps {
    isNewPartner: boolean;
}

export interface CrqSystemTableProps extends CrqSystemTableOwnProps {
    crqSystems?: CrqSystemViewModel[];
    isLoading: boolean;
    isFormOpen: boolean;
    deleteCrqSystemId?: number;
    editableCrqSystem?: CrqSystemViewModel;
    crqSystemSettingAlertType?: CrqSystemSettingAlertType | undefined;
    handleOpenModal: (id?: number | undefined) => void;
    handleRemoveModalToggle: (id?: number)=> void,
    handleRemove: ()=> void,
    handleCloseForm: () => void;
    handleToggleCrqSystemSettingAlertTypeModal: (type?: CrqSystemSettingAlertType) => void;
}

export interface CrqSystemParams {
    crqSystemId?: number | null,
}

export enum CrqSystemSettingAlertType {
    Add = 'Add',
    Edit = 'Edit'
}
