import { makeStyles, Theme } from '@material-ui/core';
import { setColumnWidth } from '../../../../shared/pipes/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: 0,
    },
    noDataText: {
        ...theme.typography.body,
        color: theme.palette.text.hint,
    },
    tableCols: {
        '&.col-1': {
            ...setColumnWidth('160px'),
        },
        '&.col-street': {
            ...setColumnWidth('160px'),
        },
        '&.col-home': {
            ...setColumnWidth('160px'),
        },
        '&.col-apartment': {
            ...setColumnWidth('110px'),
        },
        '&.col-meterSerialNum': {
            ...setColumnWidth('90px'),
        },
        '&.col-code': {
            ...setColumnWidth('320px'),
        },
        '&.col-tariff': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyImpStart': {
            ...setColumnWidth('110px'),
        },
        '&.col-cumulativeActiveEnergyImpEnd': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyTZ1Start': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyTZ1End': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyTZ2Start': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyTZ2End': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyTZ3Start': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyTZ3End': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyTZ1Diff': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyTZ2Diff': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyTZ3Diff': {
            ...setColumnWidth('140px'),
        },
        '&.col-cumulativeActiveEnergyTzSum': {
            ...setColumnWidth('140px'),
        },
    },
}));
