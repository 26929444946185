import { combineReducers } from 'redux';
import { addressSuggestionsSlice, AddressSuggestionsState } from './addressSuggestions';

export interface AddressesState {
    suggestions: AddressSuggestionsState;
}

export const addresses = combineReducers<AddressesState>({
    suggestions: addressSuggestionsSlice.reducer,
});
