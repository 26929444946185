import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import {
    ElectricityConsumptionBillingReportData,
    ElectricityConsumptionBillingReportQuery,
} from '../../../../../common/model/meter/reports/electricityConsumptionBillingReport';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { useMeterGroupAutocomplete } from '../../../../shared/components/autocomplete/hooks/useMeterGroupAutocomplete';
import {
    defaults,
    MeterTabs,
    ReportPageTabs,
    ReportTabs,
    Routes,
} from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useSyncQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useWebDictionarySelectSuggestions } from '../../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../../shared/pipes';
import { getErrorMessage } from '../../../../shared/utils/error';
import { transformDatesParamsWithoutEnds } from '../../../../shared/utils/transformers/dates';
import { MeterSerialNumView } from '../common/meterSerianNumView';
import { makeExcelReport } from './excel';
import {
    transformAddressString,
    transformElectricityConsumptionReportData,
} from './helper';
import { View } from './view';
import { electricityConsumptionBillingReportSelector } from '../../../../../redux/selectors/reports/electricityConsumptionBillingReport';
import {
    getElectricityConsumptionBillingReport,
    resetElectricityConsumptionBillingReport,
} from '../../../../../redux/actions/reports/electricityConsumptionBillingReport';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';

const filterFormDefaultValues: ElectricityConsumptionBillingReportQuery = {
    dateFrom: moment(new Date()).add(-1, 'day').startOf('day').toDate(),
    dateTo: moment(new Date()).endOf('day').toDate(),
    meterGroupId: undefined,
    regionId: defaults.defaultRegionId,
};

export const ElectricityConsumptionBillingReport = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const report = useSelector(electricityConsumptionBillingReportSelector());
    const [isLoading, setIsLoading] = useState(false);
    const { notHasAccess } = useAccessRules();
    const [
        queryParams,
        setQueryParams,
    ] = useSyncQueryParams<ElectricityConsumptionBillingReportQuery>(
        filterFormDefaultValues
    );
    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();
    const params = useParams<{ meterId?: string }>();
    const meterId = params.meterId;

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        {
            name: 'Отчетность',
            link: getRoute(Routes.report, { meterId }, { tab: ReportTabs }),
        },
        {
            name:
        'Выгрузка для биллинга: показания и расход электроэнергии за расчетный период',
            link: getRoute(
                Routes.report,
                { meterId },
                { tab: ReportPageTabs.NotConnectedMeters }
            ),
        },
    ]);

    const form = useForm<ElectricityConsumptionBillingReportQuery>({
        defaultValues: queryParams,
    });
    const formData = form.watch();

    const fetch = async () => {
        setIsLoading(true);
        try {
            setQueryParams(transformDatesParamsWithoutEnds(formData));
            await dispatch(
                getElectricityConsumptionBillingReport(
                    transformDatesParamsWithoutEnds(formData)
                )
            );
        } catch (e) {
            dispatch(
                createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                })
            );
        }
        setIsLoading(false);
    };

    const headingOnBackHandler = () => {
        dispatch(push(Routes.report));
    };

    useEffect(() => {
        return () => {
            dispatch(resetElectricityConsumptionBillingReport());
        };
    }, []);

    const data = useMemo(
        () =>
            report?.data
                ? report.data.map((item) =>
                    transformElectricityConsumptionReportData(item)
                )
                : null,
        [report]
    );

    const columns: Column[] = [
        {
            title: 'Район',
            field: 'areaName',
            cellClassName: 'col-district',
            sorting: true,
        },
        {
            title: 'Наименование населенного пункта',
            field: 'townName',
            cellClassName: 'col-locality',
            sorting: true,
            render: (item: ElectricityConsumptionBillingReportData) =>
                transformAddressString(item),
        },
        {
            title: '№ ПУ',
            field: 'meterSerialNum',
            cellClassName: 'col-meterSerialNum',
            sorting: true,
            render: (item: ElectricityConsumptionBillingReportData) => {
                return (
                    <MeterSerialNumView
                        label={item.meterSerialNum}
                        url={getRoute(
                            Routes.meter,
                            { meterId: item.meterId },
                            { tab: MeterTabs.Information }
                        )}
                    />
                );
            },
        },
        {
            title: 'Код АСКУЭРР',
            field: 'code',
            cellClassName: 'col-code',
            sorting: true,
        },
        {
            title: 'Показания сумма тарифов, кВт*ч, Нач. ОП',
            field: 'cumulativeActiveEnergyImpStart',
            cellClassName: 'col-cumulativeActiveEnergyImpStart',
            sorting: true,
        },
        {
            title: 'Показания сумма тарифов, кВт*ч, Кон. ОП',
            field: 'cumulativeActiveEnergyImpEnd',
            cellClassName: 'col-cumulativeActiveEnergyImpEnd',
            sorting: true,
        },
        {
            title: 'Показания по первому тарифу (Т1), кВт*ч, Нач. ОП',
            field: 'cumulativeActiveEnergyTZ1Start',
            cellClassName: 'col-cumulativeActiveEnergyTZ1Start',
            sorting: true,
        },
        {
            title: 'Показания по первому тарифу (Т1), кВт*ч, Кон. ОП',
            field: 'cumulativeActiveEnergyTZ1End',
            cellClassName: 'col-cumulativeActiveEnergyTZ1End',
            sorting: true,
        },
        {
            title: 'Показания по второму тарифу (Т2), кВт*ч, Нач. ОП',
            field: 'cumulativeActiveEnergyTZ2Start',
            cellClassName: 'col-cumulativeActiveEnergyTZ2Start',
            sorting: true,
        },
        {
            title: 'Показания по второму тарифу (Т2), кВт*ч, Кон. ОП',
            field: 'cumulativeActiveEnergyTZ2End',
            cellClassName: 'col-cumulativeActiveEnergyTZ2End',
            sorting: true,
        },
        {
            title: 'Потребление электроэнергии, кВт*ч, Т1',
            field: 'cumulativeActiveEnergyTZ1Diff',
            cellClassName: 'col-cumulativeActiveEnergyTZ1Diff',
            sorting: true,
        },
        {
            title: 'Потребление электроэнергии, кВт*ч, Т2',
            field: 'cumulativeActiveEnergyTZ2Diff',
            cellClassName: 'col-cumulativeActiveEnergyTZ2Diff',
            sorting: true,
        },
        {
            title: 'Потребление электроэнергии, кВт*ч, ∑ (Т1+Т2)',
            field: 'cumulativeActiveEnergyTzSum',
            cellClassName: 'col-cumulativeActiveEnergyTzSum',
            sorting: true,
        },
    ];

    const isExcelExportNotAvailable =
    notHasAccess(AccessRule.CanGetReportsElectricityConsumptionExcel) ||
    report?.data?.length === 0;

    const meterGroupAutocomplete = useMeterGroupAutocomplete({
        regionId: formData.regionId,
    });

    return View({
        form,
        data,
        columns,
        isLoading,
        breadcrumbs,
        onClickBreadcrumb,
        regionSuggestions,
        headingOnBackHandler,
        meterGroupAutocomplete,
        isExcelExportNotAvailable,
        isReportFormed: !!data,
        onClickFormExcel: async () => {
            try {
                await makeExcelReport({
                    response: report,
                    params: queryParams,
                    filename: 'Показания и расход электроэнергии за расчетный период',
                });
            } catch (e) {
                dispatch(
                    createSnackbar({
                        type: 'red',
                        message: getErrorMessage(e),
                    })
                );
            }
        },
        onClickFormReport: async () => {
            await fetch();
        },
        onClickRefresh: async () => {
            await fetch();
        },
    });
};
