import { push } from 'connected-react-router';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormats } from '../../../common/constants/date';
import { MeterViewModel } from '../../../common/model/meter/meter/meterViewModel';
import { BaseDispatch } from '../../../redux/actions';
import { createSnackbar } from '../../../redux/actions/controls';
import { createMeter, deleteMeter, findMeters, updateMeter } from '../../../redux/actions/meters';
import { metersSelector } from '../../../redux/selectors/meters';
import { MeterTabs, Routes } from '../../shared/constants';
import { useMakeBackUrl } from '../../shared/hooks/useBackUrl';
import { useQueryParams } from '../../shared/hooks/useQueryParams';
import { useWebDictionaryLookup } from '../../shared/hooks/useWebDictionary';
import { getRoute } from '../../shared/pipes';
import { getErrorMessage } from '../../shared/utils/error';
import { MeterStatusRender } from './components/meterStatus';
import { IStateProps, MetersMode } from './model';
import { View } from './view';
import { MetersActions } from './components/actions';

export const Meters = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const meters = useSelector(metersSelector);
    const params = useQueryParams();
    const meterId = params.get('meterId');
    const mode = params.get('mode') as MetersMode ?? MetersMode.Default;

    const meter = (meters ?? []).find(item => item.id === meterId);

    const makeBackUrl = useMakeBackUrl();

    const { meterModels } = useWebDictionaryLookup();

    const loadMeters = () => {
        dispatch(findMeters({
            limit: 100,
        }));
    };

    useEffect(() => {
        loadMeters();
    }, []);

    const handleMeterOpen = (id: string) => {
        const route = getRoute(
            Routes.meter,
            { meterId: id },
            { tab: MeterTabs.Information, backUrl: makeBackUrl() }
        );
        dispatch(push(route));
    };

    const onClickNewMeter = () => {
        const route = getRoute(
            Routes.meters,
            {},
            { mode: MetersMode.Create, backUrl: makeBackUrl() }
        );
        dispatch(push(route));
    };

    const onCloseCreateModal = () => {
        const route = getRoute(
            Routes.meters,
        );
        dispatch(push(route));
    };

    const onSubmit: SubmitHandler<MeterViewModel> = async (values) => {
        try {
            // eslint-disable-next-line default-case
            switch (mode) {
                case MetersMode.Create: {
                    await dispatch(createMeter(values));
                    break;
                }
                case MetersMode.Update: {
                    await dispatch(updateMeter(meterId, values));
                    break;
                }
            }
            loadMeters();
            onCloseCreateModal();
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
    };

    const props: IStateProps = {
        mode,
        meter,
        onSubmit,
        data: meters,
        onClickNewMeter,
        onCloseCreateModal,
        columnDefinition: [
            {
                title: 'Внутренний идентификатор',
                field: 'id',
                sorting: true,
            },
            {
                title: 'Модель ПУ',
                field: 'meterModelId',
                lookup: meterModels,
                sorting: true,
            },
            {
                title: 'Статус',
                field: 'status',
                sorting: true,
                render: (data: MeterViewModel) => {
                    return MeterStatusRender({
                        meter: data,
                    });
                }
            },
            {
                title: 'Место установки ПУ',
                field: 'installationSite',
                sorting: true,
            },
            {
                title: 'IP-адрес',
                field: 'ipAddress',
                sorting: true,
            },
            {
                title: 'IP-порт',
                field: 'ipPort',
                sorting: true,
            },
            {
                title: 'Дата ввода в эксплуатацию',
                field: 'launchDate',
                sorting: true,
                render: (data: MeterViewModel) => moment(data.installationDate).format(DateFormats.dateTimeFormat),
            },
            {
                title: 'Заводской номер',
                field: 'meterSerialNum',
                sorting: true,
            },
            {
                title: 'Дата следующей поверки',
                field: 'nextVerificationDate',
                sorting: true,
                render: (data: MeterViewModel) => moment(data.nextVerificationDate).format(DateFormats.dateTimeFormat),
            },
            {
                title: 'Сетевой адрес',
                field: 'networkAddress',
                sorting: true,
            },
            {
                title: 'Код ФИАС адреса установки ПУ',
                field: 'fiasCode',
                sorting: true,
            },
            {
                title: 'Действия',
                cellClassName: 'cell-fixed',
                sorting: false,
                render: ({ id }) => {
                    return MetersActions({
                        actions: [
                            {
                                label: 'Открыть',
                                text: 'Открыть',
                                square: false,
                                onPress: () => {
                                    handleMeterOpen(id);
                                },
                            },
                            {
                                icon: 'close',
                                label: 'Удалить',
                                onPress: async () => {
                                    if (window.confirm('Удалить ПУ?')) {
                                        await dispatch(deleteMeter(id));
                                        loadMeters();
                                    }
                                },
                            }
                        ],
                    });
                },
            },
        ]
    };

    return useMemo(() => View(props), [props]);
};
