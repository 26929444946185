import { combineReducers } from 'redux';
import { meterGroupListSlice, MeterGroupListState } from './list';

export interface MeterGroupState {
    list: MeterGroupListState;
}

export const meterGroup = combineReducers<MeterGroupState>({
    list: meterGroupListSlice.reducer,
});
