import { MeterViewModel } from '../../../../common/model/meter/meter/meterViewModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchMetersResponse } from '../../../../common/model/meter/searchMeters/searchMetersResponse';

export interface MetersListState {
    available: MeterViewModel[];
    search: MeterViewModel[];
    searchTotal: number;
}

const initialState: MetersListState = {
    available: null,
    search: null,
    searchTotal: 0,
};

export const metersListSlice = createSlice({
    name: '@@vv/meters/list',
    initialState,
    reducers: {
        setAvailableMeters(state, action: PayloadAction<MeterViewModel[]>): void {
            state.available = action.payload;
        },
        setMetersSearch(state, action: PayloadAction<SearchMetersResponse>): void {
            state.search = action.payload.data;
            state.searchTotal = action.payload.total;
        }
    },
});
