import { Border, Cell, Row, Worksheet } from 'exceljs';

export const excelBaseFontName = 'Arimo';

export const baseTableBorderStyle: Partial<Border> = {
    style: 'thin',
    color: {
        argb: '00000000'
    },
};
export const addBordersToCell = (cell: Cell, borderStyle = baseTableBorderStyle) => {
    cell.style.border = {
        top: borderStyle,
        left: borderStyle,
        right: borderStyle,
        bottom: borderStyle,
    };
};

export const addRowSpaceToWorksheet = (worksheet: Worksheet): Row => {
    return worksheet.addRow(['']);
};
