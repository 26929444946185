import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import { groupBy, keyBy, uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
    MeterFirmwareVersionCreator
} from '../../../../common/model/meter/meterFirmwareVersion/meterFirmwareVersionCreator';
import {
    MeterFirmwareVersionUpdater
} from '../../../../common/model/meter/meterFirmwareVersion/meterFirmwareVersionUpdater';
import { MeterFirmwareVersionViewModel } from '../../../../common/model/meter/meterFirmwareVersion/meterFirmwareVersionViewModel';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import {
    createMeterFirmwareVersion, deleteMeterFirmwareVersion,
    updateMeterFirmwareVersion
} from '../../../../redux/actions/meterFirmwareVersions';
import { MeterFirmwareVersionResolverChildProps } from '../../../routing/resolvers/meterFirmwareVersionsResolver';
import { DropdownMenu } from '../../../shared/components/dropdownMenu';
import { DropdownMenuOption } from '../../../shared/components/dropdownMenu/model';
import { SetOrderFunc } from '../../../shared/components/pagination/model';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { AdministrationTabs, Routes } from '../../../shared/constants';
import { PaginationOrderParams } from '../../../shared/hooks/useActualPage';
import { useBreadcrumbs } from '../../../shared/hooks/useBreadcrumbs';
import { useHeading } from '../../../shared/hooks/useHeading';
import { getRoute } from '../../../shared/pipes';
import { spreadItemsByCondition } from '../../../shared/pipes/spreadItemsByCondition';
import { getErrorMessage } from '../../../shared/utils/error';
import { MeterFirmwareVersionFormData } from './components/meterFirmwareVersionForm/model';
import { MeterFirmwareVersionFilter } from './model';
import { View } from './view';

const defaultFormModel: MeterFirmwareVersionFormData = {
    sequenceNumber: null,
    version: null,
    meterModelMask: null,
};

const filterDefault: MeterFirmwareVersionFilter = {
    meterModelMask: null,
};

const mapMeterFirmwareVersionViewModelToMeterFirmwareVersionFormData = (
    model: MeterFirmwareVersionViewModel
): MeterFirmwareVersionFormData => {
    return {
        id: model.id,
        sequenceNumber: model.sequenceNumber,
        version: model.version,
        meterModelMask: model.meterModelMask,
    };
};

const mapMeterFirmwareVersionFormDataToMeterFirmwareVersionCreator = (
    model: MeterFirmwareVersionFormData
): MeterFirmwareVersionCreator => {
    return {
        sequenceNumber: model.sequenceNumber,
        version: model.version,
        meterModelMask: model.meterModelMask,
    };
};

const mapMeterFirmwareVersionFormDataToMeterFirmwareVersionUpdater = (
    model: MeterFirmwareVersionFormData
): MeterFirmwareVersionUpdater => {
    return {
        id: model.id,
        sequenceNumber: model.sequenceNumber,
        version: model.version,
        meterModelMask: model.meterModelMask,
    };
};

export const MeterFirmwareVersions: React.FC<MeterFirmwareVersionResolverChildProps> = (props) => {

    const {
        reFetch,
    } = props;

    const dispatch = useDispatch<BaseDispatch>();

    const [formModel, setFormModel] = useState<MeterFirmwareVersionFormData>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orderParams, setOrderParams] = useState<PaginationOrderParams>({
        orderBy: undefined,
        orderDirection: 'asc',
    });

    const form = useForm<MeterFirmwareVersionFilter>({
        defaultValues: filterDefault,
    });

    const formData = form.watch();

    const pageTitle = 'Версии метрологического ПО ПУ';

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration) },
        { name: 'Справочники', link: getRoute(Routes.administration, {}, { tab: AdministrationTabs.Dictionaries }) },
        { name: pageTitle, link: getRoute(Routes.meterFirmwareVersions) },
    ]);

    const headingProps = useHeading(
        pageTitle,
        () => {
            dispatch(
                push(
                    getRoute(
                        Routes.administration,
                        {},
                        { tab: AdministrationTabs.Dictionaries }
                    )
                )
            );
        });

    const meterModelMaskSuggestions: SelectSuggestion[] = useMemo(
        () => uniqBy(
            props
                .data
                .map(item => ({
                    value: item.meterModelMask,
                    label: item.meterModelMask
                })),
            'value',
        ),
        [props.data]
    );

    const onClickEdit = (model: MeterFirmwareVersionViewModel) => {
        setFormModel(
            mapMeterFirmwareVersionViewModelToMeterFirmwareVersionFormData(model)
        );
    };

    const data = useMemo(() => {
        const filteredData = props.data.filter(item => {
            if (formData.meterModelMask) {
                return item.meterModelMask === formData.meterModelMask;
            }
            return true;
        });
        const sortedData = orderParams.orderBy
            ? [...filteredData].sort((a, b) => {
                const field1 = orderParams.orderBy === 'sequenceNumber'
                    ? +a[orderParams.orderBy]
                    : a[orderParams.orderBy];
                const field2 = orderParams.orderBy === 'sequenceNumber'
                    ? +b[orderParams.orderBy]
                    : b[orderParams.orderBy];
                if (orderParams.orderDirection === 'desc') {
                    return field1 < field2 ? 1 : -1;
                } else {
                    return field1 > field2 ? 1 : -1;
                }
            })
            : filteredData;
        return sortedData;
    }, [
        props.data,
        formData.meterModelMask,
        orderParams.orderBy,
        orderParams.orderDirection,
    ]);

    const neighboursVersionsById = useMemo(() => {
        const sameItemsByMask = groupBy(data, 'meterModelMask');
        const getNeighbour = (item: MeterFirmwareVersionViewModel, prev = false) => {
            const sameItems = sameItemsByMask[item.meterModelMask];
            const itemInDataIndex = sameItems.findIndex(datum => datum.id === item.id);
            let prevItemIndex = itemInDataIndex + (prev ? -1 : 1);
            let prevItem = sameItems[prevItemIndex];
            while (prevItem && prevItem.sequenceNumber === item.sequenceNumber) {
                prevItemIndex = prevItemIndex + (prev ? -1 : 1);
                prevItem = sameItems[prevItemIndex];
            }
            return prevItem;
        };
        return keyBy(data.map(item => ({
            id: item.id,
            prev: getNeighbour(item, true),
            next: getNeighbour(item, false),
        })), 'id');
    }, [data]);

    const changeSequenceOrder = async (item: MeterFirmwareVersionViewModel, prevItem: MeterFirmwareVersionViewModel) => {
        if (prevItem) {
            const itemSequenceNumber = item.sequenceNumber;
            const prevItemSequenceNumber = prevItem.sequenceNumber;
            try {
                await updateMeterFirmwareVersion(
                    item.id,
                    {
                        ...item,
                        sequenceNumber: prevItemSequenceNumber,
                    },
                );
                await updateMeterFirmwareVersion(
                    prevItem.id,
                    {
                        ...prevItem,
                        sequenceNumber: itemSequenceNumber,
                    },
                );
                await reFetch();
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
        }
    };

    const columns: Column[] = [
        {
            field: 'sequenceNumber',
            title: 'Порядковый № версии',
            cellClassName: 'cell-sequenceNumber',
            sorting: true,
        },
        {
            field: 'version',
            title: 'Версия ПО',
            cellClassName: 'cell-version',
            sorting: false,
        },
        {
            field: 'meterModelMask',
            title: 'Тип ПУ (маска)',
            cellClassName: 'cell-meterModelMask',
            sorting: true,
        },
        {
            field: '',
            title: '',
            sorting: false,
            render: (item: MeterFirmwareVersionViewModel) => {
                const prevItem = neighboursVersionsById[item.id]?.prev;
                const nextItem = neighboursVersionsById[item.id]?.next;
                return <DropdownMenu
                    icon='moreVert'
                    dropdownPosition='bottomRight'
                    options={[
                        {
                            label: 'Редактировать',
                            icon: 'edit',
                            onClick: () => {
                                onClickEdit(item);
                            },
                        },
                        {
                            label: 'Удалить',
                            icon: 'delete',
                            onClick: async () => {
                                try {
                                    await deleteMeterFirmwareVersion(item.id);
                                    await reFetch();
                                } catch (e) {
                                    dispatch(createSnackbar({
                                        type: 'red',
                                        message: getErrorMessage(e),
                                    }));
                                }
                            },
                        },
                        ...spreadItemsByCondition<DropdownMenuOption>(
                            !!prevItem,
                            [
                                {
                                    label: 'Переместить выше',
                                    icon: 'arrowSmallUp',
                                    onClick: async () => {
                                        await changeSequenceOrder(item, prevItem);
                                    },
                                },
                            ]
                        ),
                        ...spreadItemsByCondition<DropdownMenuOption>(
                            !!nextItem,
                            [
                                {
                                    label: 'Переместить ниже',
                                    icon: 'arrowSmallDown',
                                    onClick: async () => {
                                        await changeSequenceOrder(item, nextItem);
                                    },
                                }
                            ]
                        ),
                    ]} />;
            },
        },
    ];

    const setOrder: SetOrderFunc = (orderBy: string, orderDirection: 'asc' | 'desc') => {
        setOrderParams({
            orderBy,
            orderDirection,
        });
        return Promise.resolve();
    };

    return View({
        ...props,
        ...breadcrumbProps,
        ...headingProps,
        data,
        form,
        columns,
        isLoading,
        formModel,
        meterModelMaskSuggestions,
        setOrder,
        orderParams,
        onCloseModal: () => {
            setFormModel(null);
        },
        onClickNewVersion: () => {
            setFormModel(defaultFormModel);
        },
        onSubmitForm: async (data) => {
            setIsLoading(true);
            try {
                if (data.id) {
                    await updateMeterFirmwareVersion(
                        data.id,
                        mapMeterFirmwareVersionFormDataToMeterFirmwareVersionUpdater(data)
                    );
                } else {
                    await createMeterFirmwareVersion(
                        mapMeterFirmwareVersionFormDataToMeterFirmwareVersionCreator(data)
                    );
                }
                setFormModel(null);
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            await reFetch();
            setIsLoading(false);
        },
    });
};
