import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import { UserUpdater } from '../../../../common/model/access/user/userUpdater';
import { UserViewModel } from '../../../../common/model/access/user/userViewModel';
import { userEditableSlice } from '../../../reducers/users/editable';
import { AppThunk } from '../../index';


export const getEditableUser = (
    userId: number
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_user, { userId });
    const user = await httpService.get(route);
    dispatch(userEditableSlice.actions.setEditableUser(user));
};

export const createUser = (creator: UserUpdater): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_create_user);
    await httpService.post<UserUpdater>(route, creator);
};

export const updateUser = (id: number, updater: UserUpdater): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_update_user, { userId: id });
    await httpService.put<UserViewModel>(route, updater);
};

export const deleteUser = (id: number): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_delete_user, { userId: id });
    await httpService.delete<void>(route);
};
