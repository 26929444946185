// Правила доступа
export enum AccessRule {
    CanReadMetersList = 'can_read_meters_list',
    CanReadMetersSearch = 'can_read_meters_search',
    CanReadMeterGroupsList = 'can_read_meter_groups',
    CanCreateMeterGroup = 'can_create_meter_group',
    CanEditMeterGroup = 'can_edit_meter_group',
    CanDeleteMeterGroup = 'can_delete_meter_group',
    CanReadMeter = 'can_read_meter',
    CanReadMeterCount = 'can_read_meter_count',
    CanReadMeterError = 'can_read_meter_error',
    CanUpdateMeterCommunicationParams = 'can_update_meter_communication_params',
    CanUpdateMeterReplacingCommunicator = 'can_update_meter_replacing_communicator',
    CanUpdateMeterTransformationRatios = 'can_update_meter_transformation_ratios',
    CanUpdateMeterVerification = 'can_update_meter_verification',
    CanUpdateMeterInformationFields = 'can_update_meter_information_fields',
    CanUpdateMeterPasswordFields = 'can_update_meter_password_fields',
    CanUpdateMeterTechnicalParams = 'can_update_meter_technical_params',
    CanViewMeterTechnicalParams = 'can_view_meter_technical_params',
    CanGetMeterTechnicalParamsOrder = 'can_get_meter_technical_params_order',
    CanUpdateMeterAddress = 'can_update_meter_address',
    CanUpdateMeterStatusToActive = 'can_update_meter_status_to_active',
    CanUpdateMeterStatusToDecommissioned = 'can_update_meter_status_to_decommissioned',
    CanUpdateMeterLableType = 'can_update_meter_lable_type',
    CanReadMeterActionPlan = 'can_read_meter_action_plan',
    CanReadMeterActionPlans = 'can_read_meter_action_plans',
    CanSyncMeterActionPlans = 'can_sync_meter_action_plans',
    CanReadMeterCommunicatorInformation = 'can_read_meter_communicator_information',
    CanReadMeterTariffSchedules = 'can_read_meter_tariff_schedules',
    CanReadMeterConsumptionElectricity = 'can_read_meter_consumption_electricity',
    CanReadMeterConsumptionElectricityTariff = 'can_read_meter_consumption_electricity_tariff',
    CanReadMeterConsumptionElectricityDownloadExcel = 'can_read_meter_consumption_electricity_download_excel',
    CanReadMeterConsumptionElectricityTariffDownloadExcel = 'can_read_meter_consumption_electricity_tariff_download_excel',
    CanReadMeterLatestProfile = 'can_read_meter_latest_profile',
    CanReadMeterGis = 'can_read_meter_gis',
    CanReadMeterDetails = 'can_read_meter_details',
    CanReadMeterLabels = 'can_read_meter_labels',
    CanCloseMeterLabel = 'can_close_meter_label',
    CanReadMeterConfigurationHistory = 'can_read_meter_configuration_history',
    CanGetMeterPowerSwitch = 'can_get_meter_power_switch',
    CanPutMeterPowerSwitch = 'can_put_meter_power_switch',
    DailyMonitoring = 'daily_monitoring',
    CanReadMeterActivationOrdersList = 'can_read_meter_activation_orders_list',
    CanReadMeterActivationOrderDetails = 'can_read_meter_activation_order_details',
    CanPostMeterActivationOrder = 'can_post_meter_activation_order',
    CanReprocessMeterActivationOrder = 'can_reprocess_meter_activation_order',
    CanDeleteMeterActivationOrder = 'can_delete_meter_activation_order',
    CanReadRelatedSystems = 'can_read_related_systems',
    CanReadMeterSchemes = 'can_read_meter_schemes',
    CanCreateMeterSchemes = 'can_create_meter_schemes',
    CanUpdateMeterSchemes = 'can_update_meter_schemes',
    CanGetMeterActionSchemeChangeRequest = 'can_get_meter_action_scheme_change_request',
    CanReadEventTypes = 'can_read_event_types',
    CanUpdateEventTypes = 'can_update_event_types',
    CanReadEventLogs = 'can_read_event_logs',
    CanReadCriticalEventLogs = 'can_read_critical_event_logs',
    CanDownloadEventLogsExcel = 'can_download_event_logs_excel',
    CanReadSettings = 'can_read_settings',
    CanPostSettings = 'can_post_settings',
    CanGetNotConnectedMeters = 'can_get_not_connected_meters',
    CanGetNotConnectedMetersExcel = 'can_get_not_connected_meters_excel',
    CanGetUsers = 'can_get_users',
    CanGetAllUsers = 'can_get_all_users',
    CanCreateUser = 'can_create_user',
    CanUpdateUser = 'can_update_user',
    CanDeleteUser = 'can_delete_user',
    CanRepeatMeterTasks = 'can_repeat_meter_tasks',
    CanGetReportsMeterProfile = 'can_get_reports_meter_profile',
    CanGetReportsMeterProfileExcel = 'can_get_reports_meter_profile_excel',
    CanGetReportsElectricityConsumption = 'can_get_reports_electricity_consumption',
    CanGetReportsElectricityConsumptionExcel = 'can_get_reports_electricity_consumption_excel',
    CanGetReportsElectricityConsumptionBilling = 'can_get_reports_electricity_consumption_billing',
    CanGetReportsElectricityTariff = 'can_get_reports_electricity_tariff',
    CanGetReportsElectricityTariffExcel = 'can_get_reports_electricity_tariff_excel',
    CanGetReportsAdvancedCrash = 'can_get_reports_advanced_crash',
    CanGetReportsAdvancedCrashExcel = 'can_get_reports_advanced_crash_excel',
    CanReadAddresses = 'can_read_addresses',
    CanCreateAddresses = 'can_create_addresses',
    CanGetReportsCrashByRegions = 'can_get_reports_crash_by_regions',
    CanGetReportsCrashByRegionsExcel = 'can_get_reports_crash_by_regions_excel',
    CanGetMonitoringSurveyCondition = 'can_get_monitoring_survey_condition',
    CanGetReportsConfigurationHistory = 'can_get_reports_configuration_history',
    CanGetReportsConfigurationHistoryExcel = 'can_get_reports_configuration_history_excel',
    CanGetExport8002080040 = 'can_get_export_8002080040',
    CanGetMeterPowerSwitchRequest = 'can_get_meter_power_switch_requests',
    CanGetMeterPowerSwitchOrders = 'can_get_meter_power_switch_orders',
    CanGetMeterPowerSwitchCounters = 'can_get_meter_power_switch_counters',
    CanGetMeterPowerSwitchOrder = 'can_get_meter_power_switch_order',
    CanPostImport8002080040 = 'can_post_import_8002080040',
    CanGetPartners = 'can_get_partners',
    CanGetPartner = 'can_get_partner',
    CanPostPartner = 'can_post_partner',
    CanPutPartner = 'can_put_partner',
    CanGetPartnerCrqSystems = 'can_get_partner_crq_systems',
    CanPostCrqSystem = 'can_post_crq_system',
    CanPutCrqSystem = 'can_put_crq_system',
    CanPostMeterCrqSystemAndChannels = 'can_post_meter_crq_system_and_channels',
    CanDeleteCrqSystem = 'can_delete_crq_system',
    CanGetPartnerMeters = 'can_get_partner_meters',
    CanPostPartnerMeter = 'can_post_partner_meter',
    CanPostPartnerCrqMetersByExcel = 'can_post_partner_crq_meters_by_excel',
    CanCreateMeterGisTask = 'can_post_meter_gis_task',
    CanGetMeterGisTasks = 'can_get_meter_gis_tasks',
    CanUpdateMeterGisTask = 'can_put_meter_gis_task',
    CanGetMeterGisTask = 'can_get_meter_gis_task',
    CanCloseMeterGisTask = 'can_close_meter_gis_task',
    CanArchiveMeterGisTask = 'can_archive_meter_gis_task',
    CanGetHistoryMeterGisTasks = 'can_get_history_meter_gis_tasks',
    CanPostMeterReadingInstantOrder = 'can_post_meter_reading_instant_order',
    CanGetMeterReadingInstantOrder = 'can_get_meter_reading_instant_order',
    CanGetSystemLog = 'can_get_system_log',
    CanGetSystemLogToken = 'can_get_system_log_token',
    GetGetMeterReadingInstantProfile = 'get_get_meter_reading_instant_profile',
    CanSyncMeterGisTasks = 'can_sync_meter_gis_tasks',
    CanGetMeterDictionaries = 'can_get_meter_dictionaries',
    CanGetMeterDictionaryValues = 'can_get_meter_dictionary_values',
    CanEditMeterDictionaryValue = 'can_edit_meter_dictionary_value',
    CanDeleteMeterDictionaryValue = 'can_delete_meter_dictionary_value',
    CanCreateMeterDictionaryValue = 'can_create_meter_dictionary_value',
    CanExternalSystem = 'can_external_system',
    CanExternalPostMeterDeactivation = 'can_external_post_meter_deactivation',
    CanReadTimeSynchronizationSchemes = 'can_read_time_synchronization_schemes',
    CanCreateTimeSynchronizationScheme = 'can_create_time_synchronization_scheme',
    CanUpdateTimeSynchronizationScheme = 'can_update_time_synchronization_scheme',
    CanDeleteTimeSynchronizationScheme = 'can_delete_time_synchronization_scheme',
    CanPutMeterSyncSchemes = 'can_put_meter_sync_schemes',
    CanGetMeterModels = 'can_get_meter_models',
    CanPutMeterModels = 'can_put_meter_models',
    CanReadInspectionLog = 'can_read_inspection_logs',
    CanApprovePowerSwitchOrder = 'can_approve_power_switch_order',
    CanGetTariffSchedules = 'can_get_tariff_schedules',
    CanPostTariffSchedules = 'can_post_tariff_schedules',
    CanPostTariffScheduleVersion = 'can_post_tariff_schedule_version',
    CanPutTariffScheduleVersion = 'can_put_tariff_schedule_version',
    CanGetTariffScheduleVersion = 'can_get_tariff_schedule_version',
    CanGetTariffScheduleOrders = 'can_get_tariff_schedule_orders',
    CanGetSpecialDayBase = 'can_get_special_day_base',
    CanPostSpecialDayBase = 'can_post_special_day_base',
    CanPutSpecialDayBase = 'can_put_special_day_base',
    CanPutMeterMeterTariffSchedule = 'can_put_meter_meter_tariff_schedule',
    CanPostMeterMeterTariffScheduleRewrite = 'can_post_meter_meter_tariff_schedule_rewrite',
    CanGetMeterMeterTariffScheduleOrder = 'can_get_meter_meter_tariff_schedule_order',
    CanGetDynamicMeterGroupAddresses = 'can_get_dynamic_meter_group_addresses',
    CanPostDynamicMeterGroupAddresses = 'can_post_dynamic_meter_group_addresses',
    CanPutDynamicMeterGroupAddresses = 'can_put_dynamic_meter_group_addresses',
    CanDeleteDynamicMeterGroupAddresses = 'can_delete_dynamic_meter_group_addresses',
    CanGetNetworkOperators = 'can_get_network_operators',
    CanPostNetworkOperator = 'can_post_network_operator',
    CanPostNetworkOperatorCheck = 'can_post_network_operator_check',
    CanPutNetworkOperator = 'can_put_network_operator',
    CanDeleteNetworkOperator = 'can_delete_network_operator',
    CanGetMassOperations = 'can_get_mass_operations',
    CanGetMassOperation = 'can_get_mass_operation',
    CanCheckMassOperations = 'can_check_mass_operations',
    CanCreateMassOperation = 'can_create_mass_operation',
    CanGetMeterFirmwareVersion = 'can_get_meter_firmware_version',
    CanCreateMeterFirmwareVersion = 'can_create_meter_firmware_version',
    CanUpdateMeterFirmwareVersion = 'can_update_meter_firmware_version',
    CanDeleteMeterFirmwareVersion = 'can_delete_meter_firmware_version',
    CanGetMeterPassword = 'can_get_meter_password',
    CanReadMeterFileImports = 'can_read_meter_file_imports',
    CanPostMeterChangePasswordOrder = 'can_post_meter_change_password_order',
    CanGetMeterChangePasswordOrder = 'can_get_meter_change_password_order',
    CanGetMeterChangePasswordRequest = 'can_get_meter_change_password_request',
    CanPostMeterChangePasswordRequest = 'can_post_meter_change_password_request',
    CanGetMeterChangePasswordLatestTaskByMeterId = 'can_get_meter_change_password_latest_task_by_meter_id',
}
