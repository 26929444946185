import { View } from './view';
import { PeriodProps } from './model';
import { useEffect, useState } from 'react';
import { Period } from '../model';
import { hoursInDay } from '../../../../../common/constants/date';

export const TimePeriod = (props: PeriodProps) => {
    const { time, updatePeriod } = props;

    const [period, setPeriod] = useState<Period>(time);
    const [disabledSave, setDisabledSave] = useState<boolean>(true);

    useEffect(() => {
        setPeriod(time);
    }, [time]);

    const onChangePeriod = (newTime: Period) => {
        setDisabledSave(false);
        setPeriod({ ...period, ...newTime });
    };

    const onSavePeriod = (newPeriod: Period) => {
        const startTime = parseInt(newPeriod.startTime.split(':')[0]);
        const endTime = parseInt(newPeriod.endTime.split(':')[0]);
        if ((startTime < endTime || endTime === 0) && startTime <= hoursInDay - 1 && (endTime <= hoursInDay - 1 || endTime === 0)) {
            setDisabledSave(true);
            updatePeriod(newPeriod);
        }
    };

    return View({
        ...props,
        period,
        disabledSave,
        onChangePeriod,
        onSavePeriod,
    });
};
