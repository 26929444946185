import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { PartnerQueryParams } from '../../../../common/model/meter/partners/partnerQueryParams';
import { PartnerViewModel } from '../../../../common/model/meter/partners/partnerViewModel';
import { BaseDispatch } from '../../../../redux/actions';
import { getPartners } from '../../../../redux/actions/partners';
import { partnersListSelector } from '../../../../redux/selectors/partners';
import { DetailsButtonView } from '../../../shared/components/helpers/detailsButtonView';
import { Routes } from '../../../shared/constants';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { getRoute } from '../../../shared/pipes';
import { Checkbox } from 'app/shared/components/checkbox';
import { View } from './view';

const initialQueryParams: PartnerQueryParams = {
    name: null,
    innCode: null,
};

const fetchDebounceTimeMS = 600;

export const Partners = () => {

    const dispatch = useDispatch<BaseDispatch>();

    const partners = useSelector(partnersListSelector());


    const fetch = async (params: PartnerQueryParams) => {
        await dispatch(getPartners(params));
    };

    const [queryFilter, setQueryFilter] = useSyncQueryParams<PartnerQueryParams>(initialQueryParams);

    const form = useForm<PartnerQueryParams>({ defaultValues: queryFilter });

    const formData = form.watch();

    useEffect(() => {
        setQueryFilter({
            ...formData,
        });
    }, [formData]);

    const changesFormData = useDebounce(formData, fetchDebounceTimeMS);

    useEffect(() => {
        fetch({ ...changesFormData });
    }, [changesFormData]);

    const columns: Column[] = [
        {
            title: 'Название',
            field: 'name',
            cellClassName: 'col-name',
        },
        {
            title: 'ИНН',
            field: 'innCode',
            cellClassName: 'col-inn-code',
        },
        {
            title: 'Кол-во приборов учета',
            field: 'metersCount',
            cellClassName: 'col-meters-count',
        },
        {
            title: 'Получение данных по CRQ',
            field: 'isCrq',
            cellClassName: 'col-isCrq',
            render: (data: PartnerViewModel) => {
                return <div className="checkbox-isCrq">
                    <Checkbox color='#A8A8A8' disabled checked={data.isCrq} />
                </div>;
            }
        },
        {
            title: '',
            cellClassName: 'col-actions',
            render: (item: PartnerViewModel) => {
                return <DetailsButtonView
                    title='Перейти'
                    boxProps={{ justifyContent: 'flex-start' }}
                    onClick={() => {
                        dispatch(push(getRoute(Routes.PartnerEdit, {
                            partnerId: item.id.toString()
                        })));
                    }
                    }
                />;
            },
        }
    ];

    const onClickAddPartner = () => {
        dispatch(push(Routes.PartnerCreate));
    };

    return View({
        form,
        columns,
        data: partners,
        onClickAddPartner,
    });
};
