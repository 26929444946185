import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { InputProps } from './model';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    label: {
        ...theme.typography.caption,
        color: theme.palette.text.hint,
    },
    labelHeading: {
        ...theme.typography.h2,
        color: theme.palette.text.primary,
    },
    value: {
        ...theme.typography.body,
        color: theme.palette.text.secondary,
        marginLeft: 8,
    },
}));

export const View: React.FC<InputProps> = (props) => {
    const {
        value,
        label,
        isHeading,
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classNames(classes.label, { [classes.labelHeading]: isHeading })}>
                {label}
            </div>
            <div className={classes.value}>
                {value}
            </div>
        </div>
    );

};
