import { StorageState } from '../../reducers';
import { createSelector } from 'reselect';

const MeterGisTasksSelector = (state: StorageState) => state.meterGisTasks.meterGisTasks;

export const meterGisTasksAvailableSelector = createSelector(
    MeterGisTasksSelector,
    meterGisTasks => meterGisTasks.available,
);

export const meterGisTasksTotalSelector = createSelector(
    MeterGisTasksSelector,
    meterGisTasks => meterGisTasks.total,
);

export const historyGisTasks = createSelector(
    MeterGisTasksSelector,
    meterGisTasks => meterGisTasks.historyGisTasks,
);

export const meterGisTasksLoadingSelector = createSelector(
    MeterGisTasksSelector,
    meterGisTasks => meterGisTasks.loading,
);

export const meterGisTasksIsFilterVisibleSelector = createSelector(
    MeterGisTasksSelector,
    meterGisTasks => meterGisTasks.isFilterVisible,
);

export const meterGisTaskById = createSelector(
    MeterGisTasksSelector,
    meterGisTask => meterGisTask.taskById,
);

export const meterGisTaskIsFilterVisible = createSelector(
    MeterGisTasksSelector,
    meterGisTask => meterGisTask.isFilterVisible,
);

export const meterGisTaskLoading = createSelector(
    MeterGisTasksSelector,
    meterGistTask => meterGistTask.loading,
);
