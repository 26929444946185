import { ISort } from '../../../../common/sort';
import { AppThunk } from '../../index';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import { httpService } from '../../../../app/shared/httpWrapper';
import { dailyProfilesListSlice } from '../../../reducers/meterReading/dailyProfiles/list';
import { MeterReadingDailyProfileListResponse } from '../../../../common/model/meter/meter/meterReadingDailyProfileListResponse';
import { MeterReadingDailyProfileFilter } from '../../../../common/model/meter/meter/meterReadingDailyProfileFilter';
import { dailyProfilesChartSlice } from '../../../reducers/meterReading/dailyProfiles/chart';
import { MeterReadingDailyProfileViewModel } from '../../../../common/model/meter/meter/meterReadingDailyProfileViewModel';

export const findMeterReadingDailyProfilesList = (
    meterId: string,
    sort: ISort,
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_find_meters_reading_daily_list, { meterId }, { ...sort });
    const data = await httpService.get<MeterReadingDailyProfileListResponse>(route);
    dispatch(dailyProfilesListSlice.actions.setAvailableDailyProfiles(data));
};

export const findMeterReadingDailyProfilesChart = (
    meterId: string,
    filter: MeterReadingDailyProfileFilter,
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_find_meters_reading_daily_chart, { meterId }, { ...filter });
    const data = await httpService.get<MeterReadingDailyProfileViewModel[]>(route);
    dispatch(dailyProfilesChartSlice.actions.setAvailableDailyProfiles(data));
};
