import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { IProps } from './model';

import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

export const View = React.forwardRef((props: IProps, ref: React.Ref<any>) => {
    const classes = useStyles();

    const search = new URLSearchParams(props.to.search);
    const location = useLocation();
    const backUrl = btoa(JSON.stringify(location));
    if (props.appendBackUrl) {
        search.set('backUrl', backUrl);
    }
    return <Link
        className={classNames(classes.root, props.className)}
        ref={ref}
        to={{
            ...props.to,
            search: search.toString(),
        }}
    >
        {props.children}
    </Link>;
});
