/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Alignment, Cell, Row, Workbook, Worksheet } from 'exceljs';
import { DateFormats } from '../../../../../common/constants/date';
import {
    ConfigurationHistoryReportData,
    ConfigurationHistoryReportResponse
} from '../../../../../common/model/meter/reports/configurationHistoryReport';
import { sleep } from '../../../../shared/pipes';
import { formatDate } from '../../../../shared/utils/dates';
import { downloadExcelData } from '../../../../shared/utils/download';
import { addBordersToCell, addRowSpaceToWorksheet, excelBaseFontName } from '../../excelHelpers';
import { MeterLabelName } from '../../../../../common/model/meter/meterLabel/meterLabelName';

const columnWidths: Record<number, number> = {
    1: 8,
    2: 27,
    3: 53,
    4: 21,
    5: 21,
    6: 53,
    7: 24,
    8: 24,
    9: 24,
};

const columnsDataAlignment: Record<number, Alignment['horizontal']> = {
    1: 'center',
    2: 'left',
    3: 'left',
    4: 'center',
    5: 'center',
    6: 'left',
    7: 'center',
    8: 'left',
    9: 'left',
};

export interface MakeExcelReportConfig {
    response: ConfigurationHistoryReportResponse;
    date: Date;
    filename: string;
}

const makeColumns = (worksheet: Worksheet) => {
    for (const col in columnWidths) {
        worksheet.getColumn(+col).width = +columnWidths[col];
    }
};

const dataToTableCells = (data: ConfigurationHistoryReportData): string[] => {
    return [
        data.rowNumber?.toString() ?? '',
        [data.areaName, data.townName].join(', '),
        data.address ?? '',
        data.meterModel ?? '',
        data.meterSerialNum?.toString() ?? '',
        MeterLabelName[data.meterLabelTypeId] ?? '',
        formatDate(data.createdAt, DateFormats.dateFullTimeFormat),
        data.meterValue ? `${data.meterValue} ${data.timeErrorAfter ? `, ${data.timeErrorAfter} сек` : ''}` : '',
        data.systemValue ?? '',
    ];
};


const makeMainTitle = (worksheet: Worksheet, title: string) => {
    worksheet.addRow([title]);
    worksheet.getCell('A1').style.font = {
        size: 12,
        name: excelBaseFontName,
        bold: true,
    };
    worksheet.mergeCells('A1:E1');
};

const makeAnnotationRow = (worksheet: Worksheet, title: string, renderValue: (row: Row, valueCell: Cell) => void) => {
    const row = addRowSpaceToWorksheet(worksheet);
    const titleCellStart = row.getCell(1);
    titleCellStart.value = title;
    titleCellStart.style.font = {
        bold: true,
        size: 11,
        name: excelBaseFontName,
    };
    const titleCellEnd = row.getCell(2);
    worksheet.mergeCells(`${titleCellStart.address}:${titleCellEnd.address}`);

    const valueCell = row.getCell(3);
    valueCell.style.font = {
        size: 11,
        name: excelBaseFontName,
    };
    renderValue(row, valueCell);
};

const makeProfilePage = (workbook: Workbook, config: MakeExcelReportConfig): void => {
    const { response, date } = config;
    const { data, regionName, meterGroupName } = response;
    const worksheet = workbook.addWorksheet('Главная');

    const timeZone = formatDate(date, DateFormats.dateOnlyTimeZone);

    makeColumns(worksheet);
    makeMainTitle(worksheet, 'Отчет о состоянии ПУ (ошибки после диагностика ПУ)');

    makeAnnotationRow(worksheet, 'Регион:', (row, cell) => {
        cell.value = regionName;
    });

    makeAnnotationRow(worksheet, 'Наименование группы ПУ:', (row, cell) => {
        cell.value = meterGroupName;
    });

    makeAnnotationRow(worksheet, 'Дата формирования отчета:', (row, cell) => {
        cell.value = `${formatDate(date, DateFormats.dateTimeFormat)} (${timeZone})`;
    });

    addRowSpaceToWorksheet(worksheet);

    const excelMainTableTitles: string[] = [
        '№ пп',
        'Район, город',
        'Адрес ПУ',
        'Тип ПУ',
        'Заводской номер',
        'Проблема',
        `С даты (${timeZone})`,
        'Значение в ПУ',
        'Значение в системе',
    ];

    const titlesRow = addRowSpaceToWorksheet(worksheet);
    for (let i = 1; i <= excelMainTableTitles.length; i++) {
        const title = excelMainTableTitles[i - 1];
        const cell = titlesRow.getCell(i);
        cell.value = title;
        cell.style.font = {
            bold: true,
            name: excelBaseFontName,
            size: 11,
        };
        cell.style.alignment = {
            wrapText: true,
            horizontal: 'center',
        };
        addBordersToCell(cell);
    }

    for (const mainTableDatum of data) {
        const row = worksheet.addRow(dataToTableCells(mainTableDatum));
        row.eachCell((cell, index) => {
            cell.style.font = {
                size: 8,
                name: excelBaseFontName,
            };
            cell.style.alignment = {
                horizontal: columnsDataAlignment[index] ?? 'left',
            };
            addBordersToCell(cell);
        });
    }
};

export const makeExcelReport = async (config: MakeExcelReportConfig) => {
    const workbook = new Workbook();
    makeProfilePage(workbook, config);
    await sleep(10);
    const buffer = await workbook.xlsx.writeBuffer() as unknown as Buffer;
    const excelData = buffer.toString('base64');

    downloadExcelData(
        excelData,
        config.filename,
    );
};

/* eslint-enable @typescript-eslint/no-magic-numbers */
