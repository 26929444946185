import React from 'react';
import { DateFormats } from '../../../../../../common/constants/date';
import { MassOperationStatus } from '../../../../../../common/model/meter/massOperation/massOperationStatus';
import { formatDate } from '../../../../../shared/utils/dates';
import { InputProps } from './model';

export const View: React.FC<InputProps> = (props) => {
    const { massOperation } = props;
    const {
        stats,
        meterCount,
        region,
        meterGroup,
        status,
        planDate,
    } = massOperation;

    const {
        notHandledMetersCount,
        futureReviews,
    } = stats;

    if (status === MassOperationStatus.Planed) {
        return <>Массовый опрос запланирован на {formatDate(planDate, DateFormats.dateTimeZoneFormat)}</>;
    }

    const futureReviewsText = futureReviews.map(({ count, nextReviewTimestamp }) => (
        <div key={new Date(nextReviewTimestamp).toISOString()}>
            Опрос для {count} ПУ будет запущен {formatDate(nextReviewTimestamp, DateFormats.dateTimeZoneFormat)}.
        </div>
    ));

    const groupName = meterGroup
        ? `группе “${meterGroup.name}”`
        : `региону “${region.name}”`;

    const timeWindowText = futureReviews.length > 0
        ? (
            <div>В соответствии с конфигурацией окон опроса:</div>
        )
        : null;

    if (notHandledMetersCount === meterCount) {
        return (
            <>
                <div>Запланирован массовый опрос по {groupName}.</div>
                {timeWindowText}
                {futureReviewsText}
            </>
        );
    }

    const handledMetersCount = meterCount - notHandledMetersCount;

    return (
        <>
            <div>Опрос для {handledMetersCount} ПУ запущен.</div>
            {timeWindowText}
            {futureReviewsText}
        </>
    );
};
