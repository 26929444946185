import { ElectricityTariffReportData } from '../../../../../common/model/meter/reports/electricityTariffReport';
import { roundReadings } from '../../../../../common/shared/utils';

export const transformElectricityTariffReportData = (source: ElectricityTariffReportData): ElectricityTariffReportData => {
    return {
        ...source,
        cumulativeActiveEnergyImp: +roundReadings(source.cumulativeActiveEnergyImp),
        cumulativeActiveEnergyTZ1: +roundReadings(source.cumulativeActiveEnergyTZ1),
        cumulativeActiveEnergyTZ2: +roundReadings(source.cumulativeActiveEnergyTZ2),
        cumulativeActiveEnergyTZ3: +roundReadings(source.cumulativeActiveEnergyTZ3),
    };
};
