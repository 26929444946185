import { makeStyles, Theme } from '@material-ui/core';
import { setColumnWidth } from '../../../../shared/pipes/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: 0,
    },
    noDataText: {
        ...theme.typography.body,
        color: theme.palette.text.hint,
        paddingRight: '8px',
    },
    tableContainer: {
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
        },
    },
    tableCols: {
        '&.col-district': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('160px'),
        },
        '&.col-city': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('160px'),
        },
        '&.col-street': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('160px'),
        },
        '&.col-home': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('80px'),
        },
        '&.col-apartment': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('110px'),
        },
        '&.col-meterSerialNum': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('120px'),
        },
        '&.col-code': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('140px'),
        },
        '&.col-tariff': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('120px'),
        },
        '&.col-cumulativeActiveEnergyTZ1': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('160px'),
        },
        '&.col-cumulativeActiveEnergyTZ2': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('160px'),
        },
        '&.col-cumulativeActiveEnergyTZ3': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('160px'),
        },
        '&.col-cumulativeActiveEnergyImp': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('160px'),
        },
        '&.col-dateString': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('160px'),
        },
        '&.col-timeString': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('160px'),
        },
        '&.col-timeZoneString': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('100px'),
        },
    },
}));
