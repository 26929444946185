import React, { useEffect, useState } from 'react';
import { MeterDictionariesNames } from '../../../../../common/model/meter/meterDictionaries/meterDictionariesNames';
import { View } from './view';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../../redux/actions';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { getRoute } from '../../../../shared/pipes';
import { AdministrationTabs, Routes } from '../../../../shared/constants';
import { useHeading } from '../../../../shared/hooks/useHeading';
import { push } from 'connected-react-router';
import { dictionariesSelector, meterDictionaryValues } from '../../../../../redux/selectors/dictionaries';
import {
    createMeterDictionaryValue,
    deleteMeterDictionaryValue,
    getDictionaries,
    getMeterDictionaryValues,
    updateMeterDictionaryValue,
} from '../../../../../redux/actions/dictionaries';
import { useParams } from 'react-router-dom';
import { Column } from '@softmedialab/materialui-table';
import { DropdownMenu } from '../../../../shared/components/dropdownMenu';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { CurrentTaskName } from './model';
import { useForm } from 'react-hook-form';
import { useMeterDictionaryValues } from '../../../../shared/components/autocomplete/hooks/useMeterDictionaryValues';
import { Box } from '@material-ui/core';
import { useSyncQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useDebounce } from '../../../../shared/hooks/useDebounce';
import { MeterDictionaryValuesViewModel } from '../../../../../common/model/meter/meterDictionaryValues/meterDictionaryValuesViewModel';

const fetchDebounceTimeMS = 600;

export const GisDictionary = () => {
    const params = useParams<{dictionaryName?: string}>();
    const dispatch = useDispatch<BaseDispatch>();
    const tasksName = useSelector(meterDictionaryValues);
    const dictionaries = useSelector(dictionariesSelector);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [currentTaskName, setCurrentTaskName] = useState<CurrentTaskName>(null);
    const [queryFilter, setQueryFilter] = useSyncQueryParams<{search: string}>({ search: '' });
    const form = useForm<{search: string}>({
        defaultValues: queryFilter
    });

    const fetch = async (queryParams: {search: string}) => {
        await dispatch(getMeterDictionaryValues(MeterDictionariesNames.GisTasks, { ...queryParams }));
        await dispatch(getDictionaries());
    };

    const tasksNameAutocomplete = useMeterDictionaryValues();

    const formData = form.watch();

    useEffect(() => {
        setQueryFilter({
            ...formData,
        });
    }, [formData]);

    const changesFormData = useDebounce(formData, fetchDebounceTimeMS);

    useEffect(() => {
        fetch({ ...changesFormData });
    }, [changesFormData]);

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration, {}, { tab: AdministrationTabs.Schemes }) },
        { name: 'Справочники', link: getRoute(Routes.administration, {}, { tab: AdministrationTabs.Dictionaries }) },
        { name: 'Типы инцидентов (заявок, регламентных работ)', link: getRoute(Routes.meterDictionary, { dictionaryName: MeterDictionariesNames.GisTasks }) },
    ]);

    const headingProps = useHeading(dictionaries?.find(dictionary => dictionary.name === params.dictionaryName)?.title, () => {
        dispatch(push(getRoute(Routes.administration, {}, { tab: AdministrationTabs.Dictionaries })));
    });

    const columns: Column[] = [
        {
            title: 'id',
            field: 'id',
            sorting: true,
            cellClassName: 'cell-id'
        },
        {
            title: 'Тип инцидентов',
            field: 'value',
            sorting: true,
            cellClassName: 'cell-title'
        },
        {
            title: 'Действия',
            sorting: false,
            cellClassName: 'cell-action',
            render: (rowData) => {
                return (
                    <Box marginLeft='18px'>
                        <DropdownMenu
                            icon='moreVert'
                            dropdownPosition='bottomRight'
                            options={[
                                {
                                    label: 'Редактировать',
                                    icon: 'edit',
                                    onClick: () => {
                                        setIsEditModalOpen(true);
                                        setCurrentTaskName(rowData);
                                    },
                                },
                                {
                                    label: 'Удалить',
                                    icon: 'delete',
                                    onClick: () => {
                                        setIsDeleteModalOpen(true);
                                        setCurrentTaskName(rowData);
                                    },
                                }
                            ]} />
                    </Box>
                );
            }
        }
    ];

    const onClickNewTaskName = () => {
        setIsCreateModalOpen(true);
    };

    const onCloseModal = () => {
        setIsCreateModalOpen(false);
        setIsDeleteModalOpen(false);
        setIsEditModalOpen(false);
    };

    const onCreateSubmit: SubmitHandler<MeterDictionaryValuesViewModel> = (values) => {
        dispatch(createMeterDictionaryValue({
            ...values,
            dictionaryId: dictionaries?.find(dictionary => dictionary.name === params.dictionaryName)?.id,
        }));
        onCloseModal();
    };

    const onUpdatedSubmit: SubmitHandler<MeterDictionaryValuesViewModel> = (values) => {
        dispatch(updateMeterDictionaryValue(values));
        onCloseModal();
    };

    const onDeleteSubmit = () => {
        dispatch(deleteMeterDictionaryValue({ id: currentTaskName.id }));
        onCloseModal();
    };

    return View({
        ...headingProps,
        tasksName,
        breadcrumbs,
        onClickBreadcrumb,
        columns,
        isCreateModalOpen,
        isEditModalOpen,
        isDeleteModalOpen,
        onClickNewTaskName,
        onCloseModal,
        onCreateSubmit,
        currentTaskName,
        onUpdatedSubmit,
        onDeleteSubmit,
        form,
        tasksNameAutocomplete,
    });
};
