import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MeterPartnerCreator } from '../../../common/model/meter/meter/meterPartnerCreator';
import { MeterPartnerViewModel } from '../../../common/model/meter/meter/meterPartnerViewModel';
import { PartnerCreator } from '../../../common/model/meter/partners/partnerCreator';
import { PartnerMetersResponse } from '../../../common/model/meter/partners/partnerMetersResponse';
import { PartnerQueryParams } from '../../../common/model/meter/partners/partnerQueryParams';
import { PartnerUpdater } from '../../../common/model/meter/partners/partnerUpdater';
import { PartnerViewModel } from '../../../common/model/meter/partners/partnerViewModel';
import { ISort } from '../../../common/sort';
import { partnersDetailsSlice } from '../../reducers/partners/details';
import { partnersListSlice } from '../../reducers/partners/list';
import { AppThunk } from '../index';

export const getPartners = (params: PartnerQueryParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_partners, {}, params);
    const data = await httpService.get<PartnerViewModel[]>(route);
    dispatch(partnersListSlice.actions.setPartners(data));
};

export const setPartners = (partners: PartnerViewModel[]): AppThunk => (dispatch) => {
    dispatch(partnersListSlice.actions.setPartners(partners));
};

export const getPartner = (partnerId: number): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_partner, { partnerId });
    const data = await httpService.get<PartnerViewModel>(route);
    dispatch(partnersDetailsSlice.actions.setPartner(data));
};

export const createPartner = (partnerCreator: PartnerCreator): Promise<PartnerViewModel> => {
    const route = getApiRoute(appUrls.api_post_partner);
    return httpService.post<PartnerViewModel>(route, partnerCreator);
};

export const updatePartner = async (partnerId: number, partnerUpdater: PartnerUpdater) => {
    const route = getApiRoute(appUrls.api_put_partner, { partnerId });
    await httpService.put<PartnerViewModel>(route, partnerUpdater);
};

export const getPartnerMeters = (partnerId: number, sort: ISort): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_partner_meters, { partnerId }, { ...sort });
    const data = await httpService.get<PartnerMetersResponse>(route);
    dispatch(partnersDetailsSlice.actions.setMeters(data));
};

export const createPartnerMeter = (partnerId: number, meterPartnerCreator: MeterPartnerCreator): Promise<MeterPartnerViewModel> => {
    const route = getApiRoute(appUrls.api_post_partner_meters, { partnerId });
    return httpService.post<MeterPartnerViewModel>(route, meterPartnerCreator);
};
