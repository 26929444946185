import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { VAutocompleteOption } from '../../../../../shared/components/autocomplete/model';
import { Breadcrumbs } from '../../../../../shared/components/breadcrumbs';
import { Button } from '../../../../../shared/components/button';
import { CreateAddress } from '../../../../../shared/components/createAddress';
import { AddAddressLink } from '../../../../../shared/components/createAddress/addAddressLink';
import { Heading } from '../../../../../shared/components/heading';
import { HookField } from '../../../../../shared/components/hookField';
import { Wrapper } from '../../../../../shared/components/wrapper';
import {
    mapLabelValueTypeToVAutocompleteOptions,
    timeZoneTypes,
    tariffCountSuggestions,
    accountTypes, legalTypes,
    priceCategories, purposeTypes, connectionTypes
} from '../../../../../shared/constants/enums';
import { useWebDictionarySelectSuggestions } from '../../../../../shared/hooks/useWebDictionary';
import { IStateProps } from './model';
import { FormProvider } from 'react-hook-form';
import { CrqChannels } from 'app/content/crqChannels';
import { readingSourceSuggestionsForPartner } from '../../../../../shared/utils/readingSourceSuggestions';

export const View: React.FC<IStateProps> = (props) => {
    const {
        breadcrumbs,
        onClickBreadcrumb,
        heading,
        onBackHandler,
        onSubmit,
        form,
        partners,
        partnerId,
        addressAutocomplete,
        onCreateAddress,
        isCreateAddressFormOpen,
        onCloseCreateAddressForm,
        onOpenCreateAddressForm,
        createdCrqPartnerMeterId,
    } = props;
    const { handleSubmit } = form;

    const { meterModels } = useWebDictionarySelectSuggestions();

    const partnersOptions: VAutocompleteOption[] = partners?.map(item => ({
        label: item.name,
        optionsLabel: item.name,
        value: item.id,
    }));

    return (
        <Box>
            <Box mb={1}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2}>
                <Heading heading={heading} onBackHandler={onBackHandler} />
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item lg={6}>
                        <Wrapper title='ДАННЫЕ ПУ'>
                            <FormProvider {...form}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <HookField
                                                fullWidth
                                                name='partnerId'
                                                type='select'
                                                disabled
                                                suggestions={partnersOptions}
                                                label='Партнер' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='readingSource'
                                                type='select'
                                                isClearable
                                                required
                                                suggestions={readingSourceSuggestionsForPartner}
                                                label='Источник показаний' />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <HookField
                                                fullWidth
                                                name='installationDate'
                                                type='datepicker'
                                                label='Дата установки'
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='meterModelId'
                                                type='select'
                                                isClearable
                                                required
                                                suggestions={meterModels}
                                                label='Тип ПУ' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='meterSerialNum'
                                                type='text'
                                                required
                                                label='Заводской номер' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='accountType'
                                                type='select'
                                                isClearable
                                                suggestions={accountTypes}
                                                label='Тип учета' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='meterPurpose'
                                                type='select'
                                                isClearable
                                                suggestions={purposeTypes}
                                                label='Назначение прибора' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='connectionType'
                                                type='select'
                                                isClearable
                                                suggestions={connectionTypes}
                                                label='Тип включения' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                name='tariffCount'
                                                type='select'
                                                label='Количество тарифов'
                                                isClearable
                                                suggestions={tariffCountSuggestions}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='legalEntityType'
                                                type='select'
                                                isClearable
                                                suggestions={legalTypes}
                                                label='Правовая форма владельца' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='priceCategory'
                                                type='select'
                                                isClearable
                                                label='Ценовая категория'
                                                suggestions={priceCategories}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='code'
                                                type='text'
                                                label='АИИС КУЭ' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='timeZone'
                                                type='autocomplete'
                                                options={mapLabelValueTypeToVAutocompleteOptions(timeZoneTypes)}
                                                required
                                                label='Временная зона (смещение от UTC)' />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <HookField
                                                fullWidth
                                                name='addressId'
                                                label='Адрес'
                                                type='autocomplete'
                                                noOptionsText='Адрес не найден'
                                                loadingText='Поиск...'
                                                required
                                                {...addressAutocomplete}
                                            />
                                            <AddAddressLink onClick={onOpenCreateAddressForm} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='addressSpecifier'
                                                type='text'
                                                label='Номер помещения' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='installationSite'
                                                type='text'
                                                label='Место установки' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='cTRatio'
                                                type='text'
                                                label='Коэф-т трансформации по току' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HookField
                                                fullWidth
                                                name='pTRatio'
                                                type='text'
                                                label='Коэф-т трансформации по напряжению' />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <HookField
                                                fullWidth
                                                name='timeInterval'
                                                type='number'
                                                required
                                                label='Длительность основного интервала интегрирования, минут' />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type='submit'
                                                variant='primary'
                                            >
                                                Сохранить
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </FormProvider>
                        </Wrapper>
                    </Grid>

                    {createdCrqPartnerMeterId && (
                        <Grid item lg={6} xs={12}>
                            <CrqChannels meterId={createdCrqPartnerMeterId} partnerId={partnerId} />
                        </Grid>
                    )}
                </Grid>
            </Box>
            {isCreateAddressFormOpen && (
                <CreateAddress onClose={onCloseCreateAddressForm} onCreateAddress={onCreateAddress} />
            )}
        </Box>
    );
};
