import React from 'react';
import { meterRelayStatusDictionary, RelayStatus } from '../../../../../common/model/meter/relayStatus';
import { Button } from '../../../../shared/components/button';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Block } from '../../../../shared/components/wrapper/block';
import { BlockProps, BlockPropsItem } from '../../../../shared/components/wrapper/block/model';
import { spreadItemsByCondition } from '../../../../shared/pipes/spreadItemsByCondition';
import { IStateProps } from './model';
import { PowerSwitchIndicator } from './powerSwitchIndicator';
import { Tooltip } from '../../../../shared/components/tooltip';

export const View: React.FC<IStateProps> = (props) => {
    const { powerSwitchDetails, onClickButton, isPowerSwitchLimitsExceed, powerSwitchMetersCountPerDay, isLoading } = props;

    if (!powerSwitchDetails) {
        return null;
    }

    const isActive = powerSwitchDetails.relayStatus === RelayStatus.On;

    const relayActionsButton = (
        <Button
            disabled={powerSwitchDetails.isNotCompletedPowerSwitchOrder || isPowerSwitchLimitsExceed || isLoading}
            onClick={onClickButton}
            isLoading={isLoading}
            icon={isActive ? 'pause' : 'play'}
            type='button'
            variant='outline'
        >{isActive ? 'Ограничить' : 'Возобновить'}</Button>
    );

    const relayActions = isPowerSwitchLimitsExceed
        ? (
            <Tooltip
                text={`Ограничение потребления недоступно, так как количество заявок за сутки превысило ${powerSwitchMetersCountPerDay}`}
            >
                {relayActionsButton}
            </Tooltip>
        )
        : relayActionsButton;

    const meterCommunicatorBlock: BlockProps = {
        type: 'vertical',
        items: [
            ...spreadItemsByCondition<BlockPropsItem>(
                !!powerSwitchDetails,
                [
                    {
                        title: 'Заявка на ограничение потребления',
                        value: <PowerSwitchIndicator
                            status={powerSwitchDetails.latestMeterPowerSwitchOrderStatus}
                        />,
                        type: 'text',
                    }
                ],
            ),
            {
                title: 'Текущее значение',
                value: meterRelayStatusDictionary[powerSwitchDetails.relayStatus],
                type: 'text',
            },
        ],
    };

    return (
        <>
            <Wrapper title='Состояние реле' actions={relayActions}>
                <Block {...meterCommunicatorBlock} />
            </Wrapper>
        </>
    );
};
