import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormats } from '../../../../../common/constants/date';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { NotConnectedMetersReportQueryParams } from '../../../../../common/model/meter/reports/notConnectedMeters';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { getNotConnectedMetersReport, resetNotConnectedMetersReport } from '../../../../../redux/actions/reports';
import { notConnectedReportSelector } from '../../../../../redux/selectors/reports';
import { SetOrderFunc } from '../../../../shared/components/pagination/model';
import {
    defaults,
    MeterTabs,
    ReportPageTabs,
    ReportTabs,
    Routes
} from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { PaginationOrderParams } from '../../../../shared/hooks/useActualPage';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { useSyncQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useTabs } from '../../../../shared/hooks/useTabs';
import { useWebDictionarySelectSuggestions } from '../../../../shared/hooks/useWebDictionary';
import { getErrorMessage } from '../../../../shared/utils/error';
import { ReportType } from '../../model';
import { MeterSerialNumView } from '../common/meterSerianNumView';
import { makeExcelReport } from './excel';
import {
    NotConnectedMetersReportFilterParams,
    NotConnectedMetersReportTableData,
    NotConnectedMetersReportTableDataSummary,
    ReportPageTab
} from './model';
import {
    transformNotConnectedMetersReportDataItemToTableData,
    transformNotConnectedMetersReportResponseToTableData,
} from './transformer';
import { RenderFieldView, View } from './view';
import { getRoute } from '../../../../shared/pipes';
import { useParams } from 'react-router-dom';

const defaultOrderParams: PaginationOrderParams = {
    orderBy: 'rowNumber',
    orderDirection: 'asc',
};

const filterFormDefaultValues: NotConnectedMetersReportFilterParams = {
    regionId: defaults.defaultRegionId,
    daysOffline: null,
    ...defaultOrderParams,
};

const getSortableDate = (dateStr: string): number => {
    if (!dateStr) {
        return 0;
    }
    const date = moment(dateStr, DateFormats.dateFullTimeFormat).toDate();
    return date.getTime();
};

const getSortableNumber = (num: number|string): number => {
    if (!num) {
        return 0;
    }
    return +num;
};

const customColumnSort = (field: keyof NotConnectedMetersReportTableData) => (
    data1: NotConnectedMetersReportTableDataSummary,
    data2: NotConnectedMetersReportTableDataSummary
): number => {
    if (data1?.extraRowNumber || data2?.extraRowNumber) {
        return (data2?.extraRowNumber || 0) - (data1?.extraRowNumber || 0);
    }
    if (field === 'readingDate') {
        return getSortableDate(data1?.[field]) > getSortableDate(data2?.[field])
            ? 1
            : -1;
    }
    if (field === 'rowNumber' || field === 'cumulativeActiveEnergyImp') {
        return getSortableNumber(data1?.[field]) > getSortableNumber(data2?.[field]) ? 1 : -1;
    } else {
        return data1?.[field] > data2?.[field] ? 1 : -1;
    }
};

export const NotConnectedMetersReport = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const response = useSelector(notConnectedReportSelector());
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const { notHasAccess } = useAccessRules();
    const params = useParams<{meterId?: string}>();
    const meterId = params.meterId;

    const isReportFormed = !!response;

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Отчетность', link: getRoute(Routes.report, { meterId }, { tab: ReportTabs }) },
        { name: 'Перечень ПУ, с которыми нет связи', link: getRoute(Routes.report, { meterId }, { tab: ReportPageTabs.NotConnectedMeters }) },
    ]);

    const {
        activeTab,
        setTab,
    } = useTabs<ReportPageTabs>({
        values: ReportPageTabs,
        defaultTab: ReportPageTabs.NotConnectedMeters,
        route: Routes.reportPage,
        params: { reportType: ReportType.MetersNotConnectedReport },
    });

    const [queryParams, setQueryParams] = useSyncQueryParams<NotConnectedMetersReportFilterParams>(filterFormDefaultValues);

    const form = useForm<NotConnectedMetersReportFilterParams>({
        defaultValues: queryParams,
    });

    const formData = form.watch();

    const setOrder: SetOrderFunc = (orderBy, orderDirection) => {
        setQueryParams({
            ...formData,
            orderBy,
            orderDirection,
        });
        return Promise.resolve();
    };

    const orderParams: PaginationOrderParams = {
        orderBy: queryParams.orderBy,
        orderDirection: queryParams.orderDirection,
    };

    useEffect(() => {
        return () => {
            dispatch(resetNotConnectedMetersReport());
        };
    }, []);

    const fetch = async (fetchParams: NotConnectedMetersReportQueryParams) => {
        setIsLoading(true);
        setCurrentDate(new Date());
        try {
            await dispatch(getNotConnectedMetersReport(fetchParams));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    const columns: Column[] = [
        {
            title: '№',
            field: 'rowNumber',
            sorting: true,
            customSort: customColumnSort('rowNumber'),
            render: (data) => {
                return RenderFieldView(data, 'rowNumber');
            }
        },
        {
            title: 'Район, город',
            field: 'city',
            sorting: true,
            customSort: customColumnSort('city'),
            render: (data) => {
                return RenderFieldView(data, 'city');
            }
        },
        {
            title: 'Наименование точки учета',
            field: 'address',
            sorting: true,
            customSort: customColumnSort('address'),
            render: (data) => {
                return RenderFieldView(data, 'address');
            }
        },
        {
            title: 'Заводской номер',
            field: 'meterSerialNumber',
            sorting: true,
            customSort: customColumnSort('meterSerialNumber'),
            render: (data: NotConnectedMetersReportTableData) => {
                if (data.rowNumber) {
                    return <MeterSerialNumView
                        label={data.meterSerialNumber}
                        url={getRoute(Routes.meter, { meterId: data.meterId }, { tab: MeterTabs.Information })}
                    />;
                }
                return data.meterSerialNumber;
            }
        },
        {
            title: 'Дата последнего считывания',
            field: 'readingDate',
            sorting: true,
            customSort: customColumnSort('readingDate'),
            render: (data) => {
                return RenderFieldView(data, 'readingDate');
            }
        },
        {
            title: 'Значение энергии, кВт*час',
            field: 'cumulativeActiveEnergyImp',
            sorting: true,
            customSort: customColumnSort('cumulativeActiveEnergyImp'),
            render: (data) => {
                return RenderFieldView(data, 'cumulativeActiveEnergyImp');
            }
        },
    ];

    const daysOffline = queryParams.daysOffline;

    const data = useMemo(() => (
        isReportFormed
            ? activeTab === ReportPageTabs.NotConnectedMeters
                ? transformNotConnectedMetersReportResponseToTableData(response, daysOffline)
                : transformNotConnectedMetersReportDataItemToTableData(response.data)
            : []
    ), [
        response,
        isReportFormed,
        activeTab,
    ]);

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const isExcelExportNotAvailable = notHasAccess(AccessRule.CanGetNotConnectedMetersExcel) || response?.data?.length === 0;

    const getRegion = () => {
        return regionSuggestions.find(item => item.value === queryParams.regionId)?.label;
    };

    const tabs: ReportPageTab[] = [
        {
            key: ReportPageTabs.NotConnectedMeters,
            title: 'НЕТ СВЯЗИ',
            tableTitle: `Перечень ПУ "${getRegion()}", для которых нет показаний более ${daysOffline} суток`
        },
        {
            key: ReportPageTabs.AllMeters,
            title: 'ВСЕГО ОБЪЕКТОВ',
            tableTitle: `Перечень всех ИПУ ${getRegion()}`,
        }
    ];

    return View({
        data,
        form,
        tabs,
        setTab,
        columns,
        setOrder,
        orderParams,
        activeTab,
        isLoading,
        breadcrumbs,
        isReportFormed,
        regionSuggestions,
        onClickBreadcrumb,
        onClickRefresh: async () => {
            await setOrder(defaultOrderParams.orderBy, defaultOrderParams.orderDirection);
            await fetch(formData);
        },
        isExcelExportNotAvailable,
        onClickFormReport: async () => {
            await setOrder(defaultOrderParams.orderBy, defaultOrderParams.orderDirection);
            await fetch(formData);
        },
        headingOnBackHandler: () => {
            dispatch(push(Routes.report));
        },
        onClickFormExcel: async () => {
            setIsLoading(true);
            try {
                const { regionId } = queryParams;
                const region = regionSuggestions.find(item => item.value === regionId);
                await makeExcelReport({
                    regionTitle: region.label,
                    daysOffline,
                    response,
                    reportDate: currentDate,
                    filename: 'Перечень ПУ в регионе, с которыми нет связи',
                });
                dispatch(createSnackbar({
                    type: 'green',
                    message: 'Отчет успешно создан.',
                }));
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsLoading(false);
        },
    });
};
