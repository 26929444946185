import * as React from 'react';

import { Tooltip } from '../tooltip';
import { InputProps } from './model';
import classNames from 'classnames';
import './style.scss';

export const TextElipsis: React.FC<InputProps> = (props) => {
    const ref = React.useRef<HTMLSpanElement>();
    const [tooltip, setTooltip] = React.useState('');
    React.useEffect(() => {
        if (ref.current) {
            if (
                ref.current.scrollHeight > ref.current.clientHeight ||
                ref.current.scrollWidth > ref.current.clientWidth
            ) {
                setTooltip(props.text);
            } else {
                setTooltip('');
            }
        }
    }, [
        ref?.current?.scrollHeight, ref?.current?.clientHeight,
        ref?.current?.scrollWidth, ref?.current?.clientWidth
    ]);

    const childRender = (
        <span ref={ref} className={classNames(
            'text-elipsis',
            {
                [`text-elipsis__lines-${props.lines}`]: props.lines,
            },
            props.className,
        )}>{props.children}</span>
    );

    if (!tooltip) {
        return childRender;
    }

    return <Tooltip text={tooltip}>
        {childRender}
    </Tooltip>;
};
