import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import {
    MeterUpdateAttributesActions,
    meterUpdateAttributesActionsDictionary
} from '../../../../../common/model/meter/meter/meterUpdateAttributesActions';
import { Modal } from '../../../../shared/components/modal';
import { Spinner } from '../../../../shared/components/spinner';
import { CommunicationParamsUpdateForm } from '../communicationParamsUpdateForm';
import { MeterInformationFieldsUpdateForm } from '../meterInformationFieldsUpdateForm';
import { MeterTechnicalParamsUpdateForm } from '../meterTechnicalParamsUpdateForm';
import { MeterVerificationUpdateForm } from '../meterVerificationUpdateForm';
import { ReplacingCommunicatorUpdateForm } from '../replacingCommunicatorUpdateForm';
import { useUpdateFromAttributesStyles } from '../styles';
import { TransformationRatiosUpdateForm } from '../transformationRatiosUpdateForm';
import { ChangeAddressUpdateForm } from '../changeAddressUpdateFrom';
import { StateProps } from './model';


export const View = ({ action, handleCloseModal, isLoading }: StateProps) => {
    const classes = useUpdateFromAttributesStyles();

    if (!action || !Object.values(MeterUpdateAttributesActions).includes(action)) {
        return null;
    }

    if (isLoading) {
        return <Spinner />;
    }

    //TODO: modal vertical position center

    return (
        <Modal
            title={meterUpdateAttributesActionsDictionary[action]}
            close='Отмена'
            onHide={handleCloseModal}
            className={classNames(classes.actionsModalContainer, {
                [classes.actionsModalBigContainer]: action === MeterUpdateAttributesActions.ChangeTransformationRatios
                    || action === MeterUpdateAttributesActions.ChangeAddress,
                [classes.actionModalMiddleContainer]: action === MeterUpdateAttributesActions.MeterVerification,
                [classes.actionModalSmallContainer]: action === MeterUpdateAttributesActions.ChangeTechnicalParams,
            })}
        >
            <Box marginTop={2}>
                {action === MeterUpdateAttributesActions.ChangeCommunicationParams && <CommunicationParamsUpdateForm />}
                {action === MeterUpdateAttributesActions.ReplacingCommunicator && <ReplacingCommunicatorUpdateForm />}
                {action === MeterUpdateAttributesActions.ChangeTransformationRatios && <TransformationRatiosUpdateForm />}
                {action === MeterUpdateAttributesActions.MeterVerification && <MeterVerificationUpdateForm />}
                {action === MeterUpdateAttributesActions.ChangeInformationFields && <MeterInformationFieldsUpdateForm />}
                {action === MeterUpdateAttributesActions.ChangeAddress && <ChangeAddressUpdateForm />}
                {action === MeterUpdateAttributesActions.ChangeTechnicalParams && <MeterTechnicalParamsUpdateForm />}
            </Box>
        </Modal>
    );
};
