import React from 'react';
import { useForm } from 'react-hook-form';
import { SettingUpdater } from '../../../../../../common/model/resources/settings/settingUpdater';
import { SettingEditFormProps } from './model';
import { View } from './view';

export const SettingEditForm: React.FC<SettingEditFormProps> = (props) => {
    const { setting, onCloseModal } = props;

    const defaultValues: SettingUpdater = {
        id: setting.id,
        value: setting.value,
    };
    const form = useForm<SettingUpdater>({ defaultValues });

    return View({
        ...props,
        form,
        onClickCancel: () => {
            onCloseModal();
        }
    });
};
