/* eslint-disable @typescript-eslint/no-magic-numbers */
import { DateFormats } from '../../../../../common/constants/date';
import { sleep } from '../../../../shared/pipes';
import { Alignment, Border, Cell, Row, Workbook, Worksheet } from 'exceljs';
import { formatDate } from '../../../../shared/utils/dates';
import { downloadExcelData } from '../../../../shared/utils/download';
import { addBordersToCell, addRowSpaceToWorksheet, excelBaseFontName } from '../../excelHelpers';
import { transformAddressString, transformElectricityConsumptionReportData } from './helper';
import {
    ElectricityConsumptionBillingReportData, ElectricityConsumptionBillingReportQuery,
    ElectricityConsumptionBillingReportResponse
} from '../../../../../common/model/meter/reports/electricityConsumptionBillingReport';

const columnWidths: Record<number, number> = {
    1: 23,
    2: 52,
    3: 25,
    4: 19,
    5: 17,
    6: 17,
    7: 17,
    8: 17,
    9: 17,
    10: 17,
    11: 17,
    12: 17,
    13: 17,
};

const columnsDataAlignment: Record<number, Alignment['horizontal']> = {
    1: 'left',
    2: 'left',
    3: 'center',
    4: 'center',
    5: 'right',
    6: 'right',
    7: 'right',
    8: 'right',
    9: 'right',
    10: 'right',
    11: 'right',
    12: 'right',
    13: 'right',
};

export interface MakeExcelReportConfig {
    response: ElectricityConsumptionBillingReportResponse;
    params: ElectricityConsumptionBillingReportQuery;
    filename: string;
}

const mediumBorderStyle: Partial<Border> = {
    style: 'medium',
    color: {
        argb: '00000000'
    },
};

const makeColumns = (worksheet: Worksheet) => {
    for (const col in columnWidths) {
        worksheet.getColumn(+col).width = +columnWidths[col];
    }
};

const dataToTableCells = (data: ElectricityConsumptionBillingReportData): string[] => {
    data.streetName = transformAddressString(data);
    return [
        data.areaName ?? '',
        data.streetName ?? '',
        data.meterSerialNum ?? '',
        data.code ?? '',
        data.cumulativeActiveEnergyImpStart?.toString() ?? '',
        data.cumulativeActiveEnergyImpEnd?.toString() ?? '',
        data.cumulativeActiveEnergyTZ1Start?.toString() ?? '',
        data.cumulativeActiveEnergyTZ1End?.toString() ?? '',
        data.cumulativeActiveEnergyTZ2Start?.toString() ?? '',
        data.cumulativeActiveEnergyTZ2End?.toString() ?? '',
        data.cumulativeActiveEnergyTZ1Diff?.toString() ?? '',
        data.cumulativeActiveEnergyTZ2Diff?.toString() ?? '',
        data.cumulativeActiveEnergyTzSum?.toString() ?? '',
    ];
};


const makeMainTitle = (worksheet: Worksheet, title: string) => {
    worksheet.addRow([title]);
    worksheet.getCell('A1').style.font = {
        size: 12,
        name: excelBaseFontName,
        bold: true,
    };
    worksheet.getCell('A1').style.alignment = {
        horizontal: 'center',
    };
    worksheet.mergeCells('A1:M1');
};

const makeAnnotationRow = (worksheet: Worksheet, title: string, renderValue: (row: Row, valueCell: Cell) => void) => {
    const regionNameRow = addRowSpaceToWorksheet(worksheet);
    const regionLabelCellStart = regionNameRow.getCell(2);
    regionLabelCellStart.value = title;
    regionLabelCellStart.style.font = {
        size: 12,
        name: excelBaseFontName,
    };
    regionLabelCellStart.style.alignment = {
        horizontal: 'right',
    };
    const regionLabelCellEnd = regionNameRow.getCell(4);
    worksheet.mergeCells(`${regionLabelCellStart.address}:${regionLabelCellEnd.address}`);

    const regionValueCellStart = regionNameRow.getCell(9);
    regionValueCellStart.style.font = {
        size: 11,
        name: excelBaseFontName,
    };
    renderValue(regionNameRow, regionValueCellStart);
};

const makeProfilePage = (workbook: Workbook, config: MakeExcelReportConfig): void => {
    const { response, params } = config;
    const { dateFrom, dateTo } = params;
    const { data, ppObjectName } = response;
    const worksheet = workbook.addWorksheet('Главная');
    makeColumns(worksheet);
    makeMainTitle(worksheet, 'Показания  электроэнергии за расчетный период по 2 тарифам');

    makeAnnotationRow(worksheet, 'Наименование объекта РР:', (row, cell) => {
        const regionValueCellEnd = row.getCell(10);
        worksheet.mergeCells(`${cell.address}:${regionValueCellEnd.address}`);
        cell.value = ppObjectName;
    });

    makeAnnotationRow(worksheet, 'Отчетный период (ОП):', (row) => {
        const dateTitleStartCell = row.getCell(5);
        const dateTitleEndCell = row.getCell(6);
        dateTitleStartCell.value = 'с';
        dateTitleEndCell.value = 'по';
        dateTitleStartCell.style.font = {
            name: excelBaseFontName,
            size: 12,
        };
        dateTitleEndCell.style.font = {
            name: excelBaseFontName,
            size: 12,
        };
    });

    const dateRangeRow = addRowSpaceToWorksheet(worksheet);
    const dateStartCell = dateRangeRow.getCell(5);
    const dateEndCell = dateRangeRow.getCell(6);
    dateStartCell.value = formatDate(dateFrom, DateFormats.dateFormat);
    dateStartCell.style = {
        font: { color: { argb: '4BACC6' }, name: excelBaseFontName, size: 11 },
        alignment: { horizontal: 'center' }
    };
    dateEndCell.value = formatDate(dateTo, DateFormats.dateFormat);
    dateEndCell.style = {
        font: { color: { argb: '4BACC6' }, name: excelBaseFontName, size: 11 },
        alignment: { horizontal: 'center' }
    };

    addBordersToCell(dateStartCell, mediumBorderStyle);
    addBordersToCell(dateEndCell, mediumBorderStyle);

    const excelMainTableTitles: {
        title: string;
        mergeRows: boolean;
        mergeNextColsCount: number;
        topTitle?: string;
    }[] = [
        {
            title: 'Район',
            mergeNextColsCount: 0,
            mergeRows: true,
        },
        {
            title: 'Наименование населенного пункта',
            mergeNextColsCount: 0,
            mergeRows: true,
        },
        {
            title: '№, ПУ ',
            mergeNextColsCount: 0,
            mergeRows: true,
        },
        {
            title: 'Код АСКУЭРР',
            mergeNextColsCount: 0,
            mergeRows: true,
        },
        {
            title: 'Нач.ОП',
            mergeNextColsCount: 1,
            mergeRows: false,
            topTitle: 'Показания счетчика сумма тарифов, кВт*ч',
        },
        {
            title: 'Кон.ОП',
            mergeNextColsCount: 0,
            mergeRows: false,
        },
        {
            title: 'Нач.ОП',
            mergeNextColsCount: 1,
            mergeRows: false,
            topTitle: 'Показания счетчика по первому тарифу (Т1), кВт*ч',
        },
        {
            title: 'Кон.ОП',
            mergeNextColsCount: 0,
            mergeRows: false,
        },
        {
            title: 'Нач.ОП',
            mergeNextColsCount: 1,
            mergeRows: false,
            topTitle: 'Показания счетчика по второму тарифу (Т2), кВт*ч',
        },
        {
            title: 'Кон.ОП',
            mergeNextColsCount: 0,
            mergeRows: false,
        },
        {
            title: 'T1',
            mergeNextColsCount: 2,
            mergeRows: false,
            topTitle: 'Потребление электроэнергии, кВт*ч',
        },
        {
            title: 'T2',
            mergeNextColsCount: 0,
            mergeRows: false,
        },
        {
            title: '∑ (Т1+Т2)',
            mergeNextColsCount: 0,
            mergeRows: false,
        },
    ];

    const titlesRow = addRowSpaceToWorksheet(worksheet);
    const titlesRow2 = addRowSpaceToWorksheet(worksheet);
    titlesRow.height = 54;
    titlesRow2.height = 20;
    for (let i = 1; i <= excelMainTableTitles.length; i++) {
        const titleData = excelMainTableTitles[i - 1];
        const cell1 = titlesRow.getCell(i);
        const cell2 = titlesRow2.getCell(i);
        if (titleData?.mergeNextColsCount > 0) {
            worksheet.mergeCells([+cell1.row, +cell1.col, +cell1.row, +cell1.col + titleData.mergeNextColsCount]);
        }

        if (titleData.mergeRows) {
            const mergingAddresses = `${cell1.address}:${cell2.address}`;
            worksheet.mergeCells(mergingAddresses);
        }
        if (titleData.topTitle) {
            cell1.value = titleData.topTitle;
            cell2.value = titleData.title;
        } else {
            cell2.value = titleData.title;
        }
        cell1.style = {
            font: { name: excelBaseFontName, size: 10 },
            alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' }
        };
        cell2.style = {
            font: { name: excelBaseFontName, size: 8 },
            alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' }
        };
        addBordersToCell(cell1, mediumBorderStyle);
        addBordersToCell(cell2, mediumBorderStyle);
    }

    const mainTableData: ElectricityConsumptionBillingReportData[] = data.map(item => transformElectricityConsumptionReportData(item));
    for (const mainTableDatum of mainTableData) {
        const row = worksheet.addRow(dataToTableCells(mainTableDatum));
        row.eachCell((cell, index) => {
            cell.style.font = {
                size: 8,
                name: excelBaseFontName,
            };
            cell.style.alignment = {
                horizontal: columnsDataAlignment[index] ?? 'left',
            };
            if (!!mainTableDatum.cumulativeActiveEnergyTZ3Start) {
                cell.style.font.color = { argb: 'E36C09' };
            }
            addBordersToCell(cell);
        });
    }
};

export const makeExcelReport = async (config: MakeExcelReportConfig) => {
    const workbook = new Workbook();
    makeProfilePage(workbook, config);
    await sleep(10);
    const buffer = await workbook.xlsx.writeBuffer() as unknown as Buffer;
    const excelData = buffer.toString('base64');

    downloadExcelData(
        excelData,
        config.filename,
    );
};

/* eslint-enable @typescript-eslint/no-magic-numbers */
