import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { MeterViewModel } from '../../../../../../../../common/model/meter/meter/meterViewModel';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { Column } from '@softmedialab/materialui-table';
import { RefObject } from 'react';
import { AddressFilter, MeterSearchModel } from '../../../../search/model';
import { MeterGroupViewModel } from '../../../../../../common/model/meter/meterGroup/meterGroupViewModel';

export interface EditMeterGroup {
    onSubmit: SubmitHandler<MeterSearchModel>;
    onCancelSearchForm: () => void;
    form: UseFormReturn<MeterSearchModel>;
    columnsDefinition: Column[];
    columnsGroup: Column[];
    data: MeterViewModel[];
    isShowResult: boolean;
    tableRef: RefObject<any>;
    onClickAddToGroup: () => void;
    metersGroupList: MeterViewModel[];
    handleChange: (data: ExpandedPanels) => (event: any, isExpended: boolean) => void;
    expanded: ExpandedPanels;
    countActiveFilters: number;
    onSelectionChange?: (data: any[], rowData?: any) => void;
    isSelectedRow?: boolean;
    addressFilterDefaults: AddressFilter;
}

export enum ExpandedPanels {
    filterPanel = 'filterPanel',
    tablePanel = 'tablePanel',
    none = '',
}

export interface EditGroupProps {
    group: MeterGroupViewModel;
}
