import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterPowerSwitchOrderViewModel } from '../../../../common/model/meter/meterPowerSwitchOrderViewModel';


export interface MeterPowerSwitchOrderDetailsState {
    meterPowerSwitchOrder: MeterPowerSwitchOrderViewModel;
    loading: boolean;
}

const initialState: MeterPowerSwitchOrderDetailsState = {
    meterPowerSwitchOrder: null,
    loading: false,
};

export const meterPowerSwitchOrderDetailsSlice = createSlice({
    name: '@@vv/meterPowerSwitchOrders/details',
    initialState,
    reducers: {
        setMeterPowerSwitchOrderDetails(state, action: PayloadAction<MeterPowerSwitchOrderViewModel>): void {
            state.meterPowerSwitchOrder = action.payload;
            state.loading = false;
        },
        clearMeterPowerSwitchOrderDetails(state) {
            state.meterPowerSwitchOrder = null;
            state.loading = false;
        },
        setMeterPowerSwitchOrderLoading(state, action: PayloadAction<boolean>): void {
            state.loading = action.payload;
        },
    }
});
