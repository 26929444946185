import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventTypeViewModel } from '../../../../common/model/meter/eventLog/eventTypeViewModel';
import { EventTypesFindResponse } from '../../../../common/model/meter/eventLog/eventTypesFindResponse';
export interface CommonEventTypesState {
    available: EventTypeViewModel[];
    loading: boolean;
    total: number;
}

const initialState: CommonEventTypesState = {
    available: null,
    loading: false,
    total: 0,
};

export const commonEventTypesSlice = createSlice({
    name: '@@vv/eventTypes/common',
    initialState,
    reducers: {
        setAvailableEventTypes(state, action: PayloadAction<EventTypesFindResponse>) {
            state.available = action.payload.data;
            state.total = action.payload.total;
        },
        setLoadingEventTypes(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        updateAvailableEventTypes(state, action: PayloadAction<EventTypeViewModel>) {
            state.available = state.available?.map(eventType => {
                if (eventType.id === action.payload.id) {
                    return action.payload;
                }
                return eventType;
            });
        },
    }
});
