import { renderRoutes, RouteConfig } from 'react-router-config';
import { MeterFirmwareVersions } from '../content/administration/meterFirmwareVersions';
import { MeterModels } from '../content/administration/meterModels';
import { SchemeCreateForm } from '../content/administration/scheme/create';
import { SchemeEditForm } from '../content/administration/scheme/edit';
import { SpecialDayBase } from '../content/administration/specialDayBase';
import { TariffScheduleOrders } from '../content/administration/tariffScheduleOrders';
import { TariffScheduleVersion } from '../content/administration/tariffScheduleVersion';
import { TimeSynchronizationSchemeForm } from '../content/administration/timeSynchronizationSchemes/timeSynchronizationSchemeForm';
import { AuthError } from '../content/authError';
import { EventLog } from '../content/eventLog';
import { Layout } from '../content/layout';
import { Login } from '../content/login';
import { MeterConfigurationHistory } from '../content/meter/information/meterConfigurationHistory';
import { MeterPasswordManagement } from '../content/meterPasswordManagement';
import { MassOperationDetails } from '../content/monitoring/massOperation/massOperationDetails';
import { MassOperationForm } from '../content/monitoring/massOperation/massOperationForm';
import { PartnerCreate } from '../content/relatedSystems/partners/partnerCreate';
import { PartnerEdit } from '../content/relatedSystems/partners/partnerEdit';
import { PartnerMeterForm } from '../content/relatedSystems/partners/partnerEdit/partnerMeterForm';
import { MeterPowerSwitchOrderDetails } from '../content/relatedSystems/powerSwitchOrders/details';
import { Reports } from '../content/reports';
import { ReportPage } from '../content/reports/components/reportPage';
import { Routes } from '../shared/constants';
import { AccessRule } from '../../common/model/access/accessRule';
import { AccessRuleResolver } from './resolvers/access';
import { IsUserResolver } from './resolvers/isUser';
import { Profile } from '../content/profile';
import { Meters } from '../content/meters';
import { Meter } from '../content/meter/';
import { Administration } from '../content/administration';
import { RelatedSystems } from '../content/relatedSystems';
import { ImportCrqLog } from '../content/relatedSystems/importCrqLog';
import { MeterActivationOrderDetails } from '../content/relatedSystems/meterActivationOrders/meterActivationOrderDetails';
import { Snackbar } from '../content/snackbar';
import { Monitoring } from '../content/monitoring';
import { MassOperationResolver } from './resolvers/massOperationResolver';
import { MeterActionSchemeResolver } from './resolvers/meterActionSchemeResolver';
import { Search } from '../content/search';
import { CreateGroup } from '../content/administration/groups/create';
import { MeterFirmwareVersionResolver } from './resolvers/meterFirmwareVersionsResolver';
import { MeterGroupResolver } from './resolvers/meterGroupResover';
import { EditGroupForm } from '../content/administration/groups/edit';
import { PartnerResolver } from './resolvers/partnerResolver';
import { MeterGisTask } from '../content/meterGisTask';
import { GisDictionary } from '../content/administration/dictionaries/gisDictionary';
import { TariffScheduleVersionResolver } from './resolvers/tariffScheduleVersionResolver';
import { TimeSynchronizationSchemeResolver } from './resolvers/timeSynchronizationSchemeResolver';
import { MeterLabelType } from '../content/administration/meterLabelType';
import { EventTypes } from '../content/administration/eventTypes';

const routesConfig: RouteConfig[] = [
    {
        path: Routes.login,
        component: Login,
    },
    {
        path: Routes.authError,
        component: AuthError,
    },
    {
        path: Routes.empty,
        component: IsUserResolver(Layout),
        routes: [
            {
                path: Routes.profile,
                component: IsUserResolver(Profile),
            },
            {
                path: Routes.meterConfigurationHistory,
                exact: true,
                component: MeterConfigurationHistory,
            },
            {
                path: Routes.meterPasswordManagement,
                component: AccessRuleResolver(AccessRule.CanGetMeterChangePasswordOrder)(MeterPasswordManagement),
            },
            {
                path: Routes.meter,
                component: AccessRuleResolver(AccessRule.CanReadMeterDetails)(Meter),
            },
            {
                path: Routes.empty,
                exact: true,
                component: Search,
            },
            {
                path: Routes.meters,
                component: Meters,
            },
            {
                path: Routes.meterActionSchemeCreate,
                component: SchemeCreateForm,
            },
            {
                path: Routes.meterActionSchemeEdit,
                component: MeterActionSchemeResolver(SchemeEditForm),
            },
            {
                path: Routes.administration,
                component: Administration,
            },
            {
                path: Routes.meterModels,
                component: MeterModels,
            },
            {
                path: Routes.specialDayBase,
                component: SpecialDayBase,
            },
            {
                path: Routes.meterLabelType,
                component: MeterLabelType,
            },
            {
                path: Routes.eventTypes,
                component: EventTypes,
            },
            {
                path: Routes.relatedSystems,
                component: RelatedSystems,
            },
            {
                path: Routes.importCrqLog,
                component: ImportCrqLog,
            },
            {
                path: Routes.eventLog,
                component: EventLog,
            },
            {
                path: Routes.meterActivationOrder,
                component: MeterActivationOrderDetails,
            },
            {
                path: Routes.monitoring,
                component: Monitoring,
            },
            {
                path: '/example-snackbar',
                component: Snackbar,
            },
            {
                path: Routes.meterSearch,
                component: Search,
            },
            {
                path: Routes.meterGroupCreate,
                component: CreateGroup,
            },
            {
                path: Routes.meterGroupEdit,
                component: MeterGroupResolver(EditGroupForm),
            },
            {
                path: Routes.reportPage,
                component: ReportPage,
            },
            {
                path: Routes.report,
                component: Reports,
            },
            {
                path: Routes.meterPowerSwitchOrder,
                component: MeterPowerSwitchOrderDetails,
            },
            {
                path: Routes.PartnerCreate,
                component: PartnerCreate,
            },
            {
                path: Routes.PartnerMetersCreate,
                component: PartnerResolver(PartnerMeterForm),
            },
            {
                path: Routes.PartnerEdit,
                component: PartnerResolver(PartnerEdit),
            },
            {
                path: Routes.meterGisTaskEdit,
                exact: true,
                component: AccessRuleResolver(AccessRule.CanUpdateMeterGisTask)(MeterGisTask),
            },
            {
                path: Routes.meterDictionary,
                component: GisDictionary,
            },
            {
                path: Routes.timeSynchronizationSchemeNew,
                component: TimeSynchronizationSchemeForm,
            },
            {
                path: Routes.tariffScheduleOrders,
                component: TariffScheduleOrders,
            },
            {
                path: Routes.timeSynchronizationSchemeDetails,
                component: TimeSynchronizationSchemeResolver(TimeSynchronizationSchemeForm),
            },
            {
                path: Routes.tariffScheduleVersionCreate,
                component: TariffScheduleVersionResolver(TariffScheduleVersion),
            },
            {
                path: Routes.tariffScheduleVersionEdit,
                component: TariffScheduleVersionResolver(TariffScheduleVersion),
            },
            {
                path: Routes.massOperationCreate,
                component: MassOperationForm,
            },
            {
                path: Routes.massOperation,
                component: MassOperationResolver(MassOperationDetails),
            },
            {
                path: Routes.meterFirmwareVersions,
                component: MeterFirmwareVersionResolver(MeterFirmwareVersions),
            },
        ]
    },

];

export const Content = renderRoutes(routesConfig);
