import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button } from 'app/shared/components/button';
import { Table } from 'app/shared/components/table';
import { Wrapper } from 'app/shared/components/wrapper';
import { setColumnWidth } from 'app/shared/pipes/styles';
import { Modal } from 'app/shared/components/modal';
import { CrqSystemTableProps, CrqSystemSettingAlertType } from './model';
import { getCrqSystemsColumns } from './columns';
import { CrqSystemForm } from '../form';
import { ActionModal } from 'app/shared/components/actionModal';

const useStyles = makeStyles(() => ({
    addButtonWrapper: {
        marginLeft: 'auto',
    },
    tableCols: {
        '&.col-name': {
            ...setColumnWidth('200px'),
        },
        '&.col-https': {
            ...setColumnWidth('60px'),
        },
        '&.col-server': {
            ...setColumnWidth('120px'),
        },
        '&.col-port': {
            ...setColumnWidth('120px'),
        },
        '&.col-login': {
            ...setColumnWidth('120px'),
        },
        '&.col-password': {
            ...setColumnWidth('100px'),
        },
        '&.col-scheme': {
            ...setColumnWidth('120px'),
        },
        '&.col-maxTasksCount': {
            ...setColumnWidth('120px'),
        },
        '&.col-timeoutInSeconds': {
            ...setColumnWidth('120px'),
        },
        '&.col-id': {
            ...setColumnWidth('120px'),
        },
    },
    tableRow: {
        '&:not(:hover) .crq__col-details': {
            visibility: 'hidden',
        },
        '& .crq__col-password': {
            maxWidth: 120
        },
    },
    addCrqButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
}));

export const View: React.FC<CrqSystemTableProps> = ({
    crqSystems,
    handleOpenModal,
    isLoading,
    isFormOpen,
    isNewPartner,
    handleCloseForm,
    handleRemove,
    handleRemoveModalToggle,
    deleteCrqSystemId,
    crqSystemSettingAlertType,
    handleToggleCrqSystemSettingAlertTypeModal,
}) => {
    const classes = useStyles();

    const crqBlockActions = (
        <Grid container spacing={2} justify='space-between'>
            {!isNewPartner && handleOpenModal && (
                <Grid item className={classes.addCrqButton}>
                    <Button
                        variant='primary'
                        type='button'
                        icon='add'
                        onClick={() => handleOpenModal()}
                    >
                        Новое подключние
                    </Button>
                </Grid>
            )}
        </Grid>
    );

    const columns = getCrqSystemsColumns(handleOpenModal, handleRemoveModalToggle);

    return (
        <Box mb={2}>
            <Wrapper actions={crqBlockActions} title='НАСТРОЙКИ ПОДКЛЮЧЕНИЯ К CRQ-ИНТЕРФЕЙСУ' isTitleFullWidth>
                <Table
                    title={null}
                    data={crqSystems || []}
                    columns={columns}
                    variant='white'
                    isLoading={isLoading}
                    options={{
                        headerClassName: classes.tableCols,
                        rowClassName: classes.tableRow,
                        paging: 'disabled',
                        grouping: false,
                        filtering: false,
                        search: false,
                        toolbar: false,
                        showTitle: false,
                        sorting: false,
                    }}
                />
            </Wrapper>

            {isFormOpen && (
                <Modal title='Параметры подключения к CRQ' close='Очистить' onHide={handleCloseForm}>
                    <CrqSystemForm
                        handleCloseModal={handleCloseForm}
                        handleToggleCrqSystemSettingAlertTypeModal={handleToggleCrqSystemSettingAlertTypeModal}
                    />
                </Modal>
            )}

            {crqSystemSettingAlertType && (
                <ActionModal
                    title='Установка настроек CRQ-интерфейса'
                    description={
                        crqSystemSettingAlertType === CrqSystemSettingAlertType.Add ?
                            'Вы добавили настройки подключения к CRQ-интерфейсу партнера. Необходимо настроить параметры приборов учета.' :
                            'Вы изменили настройки подключения к CRQ-интерфейсу партнера. Необходимо проверить настройки приборов учета.'
                    }
                    closeModal={() => handleToggleCrqSystemSettingAlertTypeModal()}
                    actions={[
                        {
                            onClick: () => handleToggleCrqSystemSettingAlertTypeModal(),
                            title: 'Понятно',
                            variant: 'primary',
                        }
                    ]}
                />
            )}

            {deleteCrqSystemId && (
                <ActionModal
                    title='Удаление подключения к CRQ'
                    description='Вы действительно хотите удалить подключение к CRQ?'
                    closeModal={() => handleRemoveModalToggle()}
                    actions={[
                        {
                            onClick: () => handleRemove(),
                            title: 'Да, удалить',
                            variant: 'primary',
                        },
                        {
                            onClick: () => handleRemoveModalToggle(),
                            title: 'Отмена',
                            variant: 'secondary',
                        }
                    ]}
                />
            )}
        </Box>
    );
};
