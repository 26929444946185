import { AppThunk } from '../index';
import { ActiveTreeitemType, leftMenuSlice, Tab } from '../../reducers/controls/menu';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { httpService } from '../../../app/shared/httpWrapper';
import { MeterViewModel } from '../../../common/model/meter/meter/meterViewModel';
import { metersListSlice } from '../../reducers/meters/list';
import { TreeItem, TreeItemType } from 'app/shared/components/tree/model';
import { SearchByGroupAddressModel } from '../../../common/model/meter/searchByGroupAddress';
import { MeterStatus } from 'common/model/meter/meterStatus';
import { AddressViewModel } from 'common/model/meter/addressViewModel/addressViewModel';
import { TreeFilterModel } from 'redux/reducers/controls/menu/model';

export const changeLeftMenuCollapsed = (collapsed?: boolean): AppThunk => dispatch => {
    dispatch(leftMenuSlice.actions.changeCollapsed(collapsed));
};

export const updateTreeDataLeftMenu = (id: string): AppThunk => dispatch => {
    dispatch(leftMenuSlice.actions.updateTree(id));
};

export const closeAllTagsTreeLeftMenu = (): AppThunk => dispatch => {
    dispatch(leftMenuSlice.actions.closeTree());
};

export const saveTreeItemToTagList = (id: string | number, name: string): AppThunk => dispatch => {
    dispatch(leftMenuSlice.actions.saveTag({ id, name }));
};

export const removeTreeItemFromTabList = (id: string): AppThunk => dispatch => {
    dispatch(leftMenuSlice.actions.removeTab(id));
};

export const setActiveTab = (tab: Tab): AppThunk => dispatch => {
    dispatch(leftMenuSlice.actions.setActiveTab(tab));
};

export const removeActiveTab = (): AppThunk => dispatch => {
    dispatch(leftMenuSlice.actions.removeActiveTab());
};

export const getMeters = (): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meters);
    const data = await httpService.get<MeterViewModel[]>(route);
    dispatch(metersListSlice.actions.setAvailableMeters(data));
    return data;
};

export const getLeftbarPossibleMeters = (search?: string): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        if (state.controls.leftMenu.isFetchingSearchMeters) {
            return;
        }
        dispatch(leftMenuSlice.actions.setFetchingSearchMeters(true));
        if (!search) {
            dispatch(leftMenuSlice.actions.setPossibleSearchMeters(null));
            dispatch(leftMenuSlice.actions.setFetchingSearchMeters(false));
            return;
        }
        const query = { search, limit: 10, statuses: [MeterStatus.Active, MeterStatus.Defective] };
        const route = getApiRoute(appUrls.api_get_meters, null, query);
        const data = await httpService.get<MeterViewModel[]>(route);
        dispatch(leftMenuSlice.actions.setPossibleSearchMeters(data));
    } catch (e) {
        dispatch(leftMenuSlice.actions.setPossibleSearchMeters(null));
    } finally {
        dispatch(leftMenuSlice.actions.setFetchingSearchMeters(false));
    }
};

export const getLeftBarAddresses = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { treeFilter } = state.controls.leftMenu;
    const addressRoute = getApiRoute(appUrls.api_find_addresses_with_meters, null, treeFilter as any);
    const data = await httpService.get<SearchByGroupAddressModel>(addressRoute);
    dispatch(leftMenuSlice.actions.setTreeData(data));
};

export const getLeftBarMeterPositionByAddress = (address: AddressViewModel): AppThunk => async (dispatch) => {
    dispatch(leftMenuSlice.actions.setFetchingMeterPosition(true));

    const fetchAndUpdateData = async (activeTreeItem: ActiveTreeitemType, treeFilter: TreeFilterModel) => {
        const addressRoute = getApiRoute(appUrls.api_find_addresses_with_meters, null, treeFilter as any);
        const data = await httpService.get<SearchByGroupAddressModel>(addressRoute);
        dispatch(leftMenuSlice.actions.setActiveTreeItem(activeTreeItem));
        dispatch(leftMenuSlice.actions.setTreeFilter({ ...treeFilter }));
        dispatch(leftMenuSlice.actions.setTreeData(data));
    };

    try {
        if (address.regionId) {
            const treeFilter: TreeFilterModel = {
                regionId: address.regionId.toString()
            };
            await fetchAndUpdateData('regionId', treeFilter);
        }

        if (address.areaName) {
            const treeFilter: TreeFilterModel = {
                regionId: address.regionId.toString(),
                areaName: address.areaName
            };
            await fetchAndUpdateData('areaName', treeFilter);

        }

        if (address.townName) {
            const treeFilter: TreeFilterModel = {
                regionId: address.regionId.toString(),
                areaName: address.areaName,
                townName: address.townName,
            };
            await fetchAndUpdateData('townName', treeFilter);
        }

        if (address.streetName) {
            const treeFilter: TreeFilterModel = {
                regionId: address.regionId.toString(),
                areaName: address.areaName,
                townName: address.townName,
                streetName: address.streetName,
            };
            await fetchAndUpdateData('streetName', treeFilter);
        }

        if (address.houseNumber) {
            const treeFilter: TreeFilterModel = {
                regionId: address.regionId.toString(),
                areaName: address.areaName,
                townName: address.townName,
                streetName: address.streetName,
                houseNumber: address.houseNumber
            };
            await fetchAndUpdateData('houseNumber', treeFilter);
        }
    } finally {
        dispatch(leftMenuSlice.actions.setFetchingMeterPosition(false));
    }
};

const getActiveTreeItemByType = (value: TreeItemType): ActiveTreeitemType => {
    switch (value) {
        case TreeItemType.Region:
            return 'regionId';
        case TreeItemType.House:
            return 'houseNumber';
        case TreeItemType.Area:
            return 'areaName';
        case TreeItemType.Street:
            return 'streetName';
        case TreeItemType.Town:
            return 'townName';
        default:
            return null;
    }
};

export const setTreeFilter = (value: TreeItem): AppThunk => (dispatch, getState) => {
    const getValue = (limitTreeItemType : TreeItemType, baseValue: string) => {
        return value.type < limitTreeItemType
            ? null
            : value.type === limitTreeItemType
                ? value.realId === baseValue
                    ? null
                    : value.realId
                : baseValue;
    };
    const state = getState();
    const { treeFilter } = state.controls.leftMenu;
    let newTreeFilter = { ...treeFilter };
    let newActiveTreeItem = getActiveTreeItemByType(value.type);
    newTreeFilter = {
        regionId: getValue(TreeItemType.Region, treeFilter.regionId?.toString()),
        areaName: getValue(TreeItemType.Area, treeFilter.areaName),
        townName: getValue(TreeItemType.Town, treeFilter.townName),
        streetName: getValue(TreeItemType.Street, treeFilter.streetName),
        houseNumber: getValue(TreeItemType.House, treeFilter.houseNumber)
    };

    if (!newTreeFilter.regionId) {
        newActiveTreeItem = null;
    }

    dispatch(leftMenuSlice.actions.setActiveTreeItem(newActiveTreeItem));
    dispatch(leftMenuSlice.actions.setTreeFilter(newTreeFilter));
};
