import { MeterSearchModel } from '../../../../search/model';

export const calculateSelectedFiltersCount = (filter: MeterSearchModel): number => {
    let counter = 0;

    if (Array.isArray(filter.meterModelValues) && filter.meterModelValues.length > 0) {
        counter++;
    }
    if (!!filter.meterPurpose) {
        counter++;
    }
    if (!!filter.legalEntityType) {
        counter++;
    }
    if (!!filter.priceCategory) {
        counter++;
    }
    if (!!filter.status) {
        counter++;
    }
    if (!!filter.accountType) {
        counter++;
    }
    if (!!filter.connectionType) {
        counter++;
    }
    if (!!filter.meterSerialNum) {
        counter++;
    }
    if (!!filter.installationSite) {
        counter++;
    }
    if (!!filter.id) {
        counter++;
    }
    if (!!filter.nextVerificationDate) {
        counter++;
    }
    if (!!filter.communicatorSerialNum) {
        counter++;
    }
    if (!!filter.installationDate) {
        counter++;
    }
    if (!!filter.fiasCode) {
        counter++;
    }
    if (!!filter.relayOn) {
        counter++;
    }
    if (filter.relayStatus) {
        counter++;
    }
    if (filter.meterModelError) {
        counter++;
    }
    if (filter.meterFirmwareVersionError) {
        counter++;
    }
    if (filter.cTRatioError) {
        counter++;
    }
    if (filter.pTRatioError) {
        counter++;
    }
    if (filter.manufactureDateError) {
        counter++;
    }
    if (
        filter.areaName
        || filter.townName
        || filter.streetName
        || filter.houseNumber
        || filter.addressString
    ) {
        counter++;
    }

    if (filter.timeSynchronizationSchemeId) {
        counter++;
    }

    return counter;
};
