import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteConfigComponentProps } from 'react-router-config';
import { BaseDispatch } from '../../../redux/actions';
import { activeUserSelector, controlsLeftMenuSelector } from '../../../redux/selectors';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { StateProps } from './model';
import { push } from 'connected-react-router';
import { MeterTabs, Routes } from '../../shared/constants';
import { View } from './view';
import useReactRouter from 'use-react-router';
import { getRoute, RouterParams } from '../../shared/pipes';
import { MenuItemType } from '../../shared/components/topMenu/model';
import {
    changeLeftMenuCollapsed,
    closeSnackbar,
    saveTreeItemToTagList,
    setActiveTab,
    updateTreeDataLeftMenu,
    getLeftBarAddresses,
    setTreeFilter,
    getLeftbarPossibleMeters,
    getLeftBarMeterPositionByAddress
} from '../../../redux/actions/controls';
import { hoverTimeoutMs } from './constants';
import { useOutsideClick } from '../../shared/hooks';
import { SearchItem, SearchProps } from '../../shared/components/search/model';
import { prepareMeters } from '../../shared/utils/prepareMeters';
import { TreeItemType } from '../../shared/components/tree/model';
import { leftMenuSlice } from 'redux/reducers/controls/menu';
const debounceTime = 500;
const minSearchLeftBarPossibleLength = 3;

export const Layout = ({ route: layoutRoute }: RouteConfigComponentProps) => {
    const [currentSearchValue, setSearchSelected] = useState('');
    const dispatch = useDispatch<BaseDispatch>();
    const activeUser = useSelector(activeUserSelector());
    const { location } = useReactRouter<RouterParams>();
    const locations = (location?.pathname || '').split('/');
    const activeMenu = (locations.length > 1 && location?.pathname !== '/') ? MenuItemType[locations[1]] : MenuItemType.meters;
    const {
        collapsed,
        treeData,
        treeFilter,
        possibleSearchMeters,
        isFetchingMeterPosition,
        isFetchingSearchMeters: loadingSearch } = useSelector(controlsLeftMenuSelector());
    const [isHover, changeHover] = useState<boolean>(false);
    const [isHiddenAdvancedSearch, setAdvancedSearch] = useState<boolean>(false);
    const timeout = useRef<ReturnType<typeof setTimeout>>(null);

    const outsideClickRef = useOutsideClick(() => {
        setAdvancedSearch(false);
    });

    const onMoreIconClick = (): void => {
        setAdvancedSearch(!isHiddenAdvancedSearch);
    };

    const onClickAdvancedSearch = (): void => {
        const defaultRoute = getRoute(
            Routes.meterSearch,
        );
        dispatch(push(defaultRoute));
        setAdvancedSearch(!isHiddenAdvancedSearch);
    };

    useEffect(() => {
        if (isHover) {
            timeout.current = setTimeout(() => {
                dispatch(changeLeftMenuCollapsed(!isHover));
            }, hoverTimeoutMs);
        } else {
            dispatch(changeLeftMenuCollapsed(!isHover));
        }

        return () => {
            clearTimeout(timeout.current);
        };
    }, [isHover]);

    useEffect(() => {
        if (isFetchingMeterPosition) {
            return;
        }
        dispatch(getLeftBarAddresses());

    }, [treeFilter]);

    const onChangeSearchSelected = (selectedItem: SearchItem) => {
        if (!selectedItem.isSelectable) {
            return;
        }
        const value = selectedItem.value;
        setSearchSelected(value);
        const selectedMeter = possibleSearchMeters.find(meter => meter.meterSerialNum === selectedItem.value);
        dispatch(getLeftBarMeterPositionByAddress(selectedMeter.address));
        const params = { meterId: selectedMeter?.id };
        const query = { tab: MeterTabs.Information };
        dispatch(leftMenuSlice.actions.setPossibleSearchMeters(null));
        const route = getRoute(Routes.meter, params, query);
        changeHover(false);
        dispatch(push(route));
    };

    const onChangeSearchInput = (value: string) => {
        setSearchSelected(value);
        dispatch(leftMenuSlice.actions.setPossibleSearchMeters(null));
    };

    const debouncedSearchValue = useDebounce(() => {
        return currentSearchValue;
    }, debounceTime);

    useEffect(() => {
        if (currentSearchValue?.length >= minSearchLeftBarPossibleLength) {
            dispatch(getLeftbarPossibleMeters(currentSearchValue));
        }
    }, [debouncedSearchValue]);

    const hintSearchItems = useMemo(() => {
        let findItems = [];
        if (!possibleSearchMeters) {
            findItems = null;
        } else if (!possibleSearchMeters.length) {
            findItems.push({
                value: null,
                label: 'Ничего не найдено',
                searchString: '',
            });
        } else {
            findItems.push(...prepareMeters(possibleSearchMeters));
        }

        return (findItems || []).map(item => ({
            value: item.value,
            label: item.label,
            isSelectable: !!item.value,
        }));
    }, [possibleSearchMeters, possibleSearchMeters?.length]);

    const searchProps: SearchProps = {
        items: hintSearchItems,
        loading: loadingSearch,
        value: currentSearchValue,
        dark: true,
        fullWidth: true,
        onChange: onChangeSearchInput,
        onChangeSelected: onChangeSearchSelected,
        onEscPressed: () => dispatch(leftMenuSlice.actions.setPossibleSearchMeters(null))
    };

    const props: StateProps = {
        childRoutes: layoutRoute.routes,
        user: activeUser,
        activeMenu,
        outsideClickRef,
        isHiddenAdvancedSearch,
        leftMenuCollapsed: collapsed,
        leftMenuTreeData: treeData,
        onMoreIconClick,
        onProfileClick: () => {
            dispatch(push(Routes.profile));
        },
        onMenuItemClick: (route: Routes | string) => {
            dispatch(push(route));
        },
        onChangeLeftMenuCollapsed: (isCollapsed) => {
            if (isCollapsed) {
                setAdvancedSearch(false);
            }
            changeHover(!isCollapsed);
        },
        onLeftMenuTreeItemClick: (item) => {
            if (item.type === TreeItemType.Meter) {
                changeHover(false);
                dispatch(updateTreeDataLeftMenu(item.realId));
                dispatch(saveTreeItemToTagList(item.realId, item.title));
                dispatch(setActiveTab({ id: item.realId, name: item.title }));

                const route = getRoute(
                    Routes.meter,
                    { meterId: item.realId },
                    { tab: MeterTabs.Information }
                );
                dispatch(push(route));
            } else {
                dispatch(setTreeFilter(item));
            }
        },
        searchProps,
        onClickAdvancedSearch,
        snackbarClose: (args: string) => dispatch(closeSnackbar(args)),
    };

    return View(props);
};
