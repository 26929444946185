import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BaseDispatch } from '../../../../../redux/actions';
import { getMeterPowerSwitchOrder } from '../../../../../redux/actions/meterPowerSwitchOrders';
import { meterPowerSwitchOrdersDetailsSelector } from '../../../../../redux/selectors/meterPowerSwitchOrders';
import { RelatedSystemsTabs, Routes } from '../../../../shared/constants';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { getRoute } from '../../../../shared/pipes';
import { View } from './view';

export const MeterPowerSwitchOrderDetails = () => {
    const { meterPowerSwitchOrderId } = useParams<{ meterPowerSwitchOrderId: string }>();
    const dispatch = useDispatch<BaseDispatch>();

    const detailsState = useSelector(meterPowerSwitchOrdersDetailsSelector);

    useEffect(() => {
        dispatch(getMeterPowerSwitchOrder(meterPowerSwitchOrderId));
    }, []);

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Смежные системы', link: getRoute(Routes.relatedSystems) },
        { name: 'Ограничение потребления', link: getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.PowerSwitchOrders }) },
        { name: 'Информация об операции', link: null },
    ]);

    const order = detailsState.meterPowerSwitchOrder;

    return View({
        breadcrumbs,
        isLoading: !order,
        onClickBreadcrumb,
        order,
    });
};
