import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { DateFormats } from '../../../../../common/constants/date';
import { Button } from '../../../../shared/components/button';
import { Heading } from '../../../../shared/components/heading';
import { HookField } from '../../../../shared/components/hookField';
import { Table } from '../../../../shared/components/table';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Tables } from '../../../../shared/constants';
import { formatDate } from '../../../../shared/utils/dates';
import { IStateProps } from './model';
import { useStyles } from './styles';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';

export const View: React.FC<IStateProps> = (props) => {
    const {
        form,
        data,
        columns,
        reportDate,
        isLoading,
        onClickFormReport,
        regionSuggestions,
        isReportFormed,
        onClickRefresh,
        onClickFormExcel,
        isExcelExportNotAvailable,
        headingOnBackHandler,
        meterGroupAutocomplete,
        meterLabelTypesSuggestions,
        breadcrumbs,
        onClickBreadcrumb,
    } = props;

    const classes = useStyles();

    const filterRender = <>
        <FormProvider {...form}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={3} md={2} item>
                    <HookField
                        type='select'
                        name='regionId'
                        label='Регион'
                        fullWidth
                        disabled={isLoading}
                        suggestions={regionSuggestions} />
                </Grid>
                <Grid xs={12} sm={3} md={2} item>
                    <HookField
                        type='autocomplete'
                        name='meterGroupId'
                        label='Группа ПУ'
                        disabled={isLoading}
                        fullWidth
                        {...meterGroupAutocomplete}
                    />
                </Grid>
                <Grid xs={12} sm={3} md={2} item>
                    <HookField
                        type='datepicker'
                        showTime
                        name='dateFrom'
                        label='Начало'
                        disabled={isLoading}
                        fullWidth
                    />
                </Grid>
                <Grid xs={12} sm={3} md={2} item>
                    <HookField
                        type='datepicker'
                        showTime
                        name='dateTo'
                        label='Конец'
                        disabled={isLoading}
                        fullWidth
                    />
                </Grid>
                <Grid xs={12} sm={3} md={2} item>
                    <HookField
                        type='autocomplete'
                        name='meterLabelTypeId'
                        label='Тип метки'
                        disabled={isLoading}
                        fullWidth
                        options={meterLabelTypesSuggestions}
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        {isReportFormed && (
                            <>
                                <Grid item>
                                    <Button
                                        onClick={onClickRefresh}
                                        variant='outline'
                                        square
                                        icon='refresh'
                                        type='button'
                                        disabled={isLoading}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={onClickFormExcel}
                                        variant='primary'
                                        icon='refresh'
                                        type='button'
                                        disabled={isExcelExportNotAvailable || isLoading}
                                    >
                                        Выгрузить в Excel
                                    </Button>
                                </Grid>
                            </>
                        )}
                        {!isReportFormed && (
                            <Grid item>
                                <Button
                                    onClick={onClickFormReport}
                                    variant='primary'
                                    type='button'
                                    disabled={isLoading}
                                >
                                    Сформировать
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </FormProvider>
    </>;

    const isTableAvailable = data?.length > 0;

    const reportDateFormatted = `${formatDate(reportDate, DateFormats.dateTimeFormat)}` +
        `(${formatDate(reportDate, DateFormats.dateOnlyTimeZone)})`;

    return (
        <Box mb={3}>
            <Box mb={2}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2}>
                <Heading heading='Отчёт о состоянии ПУ после диагностики' onBackHandler={headingOnBackHandler} />
            </Box>
            <Wrapper isBigHeader actions={filterRender} childrenWrapperClassName={classes.wrapper}>
                {isTableAvailable && (
                    <Box p={2}>
                        <Box pb={2}>
                            <Grid alignItems='center' container spacing={1}>
                                <Grid item className={classes.reportDateKey}>Дата и время формирования отчёта</Grid>
                                <Grid item className={classes.reportDateValue}>{reportDateFormatted}</Grid>
                            </Grid>
                        </Box>
                        <Box className={classes.tableWrapper}>
                            <Table
                                name={Tables.meterProfileReportTable}
                                columns={columns}
                                data={data || []}
                                title={''}
                                useQueryString={true}
                                options={{
                                    headerClassName: classes.tableCols,
                                    showTitle: false,
                                    toolbar: false,
                                    paging: 'classic',
                                    pageSize: 500,
                                    grouping: false,
                                    columnsButton: false,
                                    filtering: false,
                                    search: false,
                                }}
                            />
                        </Box>
                    </Box>
                )}
                {!isTableAvailable && (
                    <Box p={2}>
                        <Box className={classes.noDataText}>
                            <div>Нет данных</div>
                        </Box>
                    </Box>
                )}
            </Wrapper>
        </Box>
    );
};
