import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import {
    ElectricityConsumptionReportQuery,
    ElectricityConsumptionReportResponse
} from '../../../../common/model/meter/reports/electricityConsumptionReport';
import { electricityConsumptionReportSlice } from '../../../reducers/reports/electricityConsumptionReport';
import { AppThunk } from '../../index';

export const getElectricityConsumptionReport = (params: ElectricityConsumptionReportQuery): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_reports_electricity_consumption, {}, params);
    const data = await httpService.get<ElectricityConsumptionReportResponse>(route);
    dispatch(electricityConsumptionReportSlice.actions.setReport(data));
};

export const resetElectricityConsumptionReport = (): AppThunk => (dispatch) => {
    dispatch(electricityConsumptionReportSlice.actions.setReport(null));
};
