import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormats } from '../../../../common/constants/date';
import { AccessRule } from '../../../../common/model/access/accessRule';
import {
    MeterActivationOrderStatus,
    meterActivationOrderStatusDictionary
} from '../../../../common/model/meter/meterActivationOrder/meterActivationOrderStatus';
import {
    MeterActivationOrderViewModel
} from '../../../../common/model/meter/meterActivationOrder/meterActivationOrderViewModel';
import { meterRelayStatusDictionary } from '../../../../common/model/meter/relayStatus';
import { ISort } from '../../../../common/sort';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import {
    findMeterActivationOrders,
    findMeterActivationOrderSerialNums
} from '../../../../redux/actions/meterActivationOrders';
import { getAllUsers } from '../../../../redux/actions/user/list';
import { meterActivationOrderDetailsSlice } from '../../../../redux/reducers/meterActivationOrders/details';
import { meterActivationOrdersListSlice } from '../../../../redux/reducers/meterActivationOrders/list';
import { resourcesNetworkOperatorsSelector, usersAllListSelector } from '../../../../redux/selectors';
import {
    meterActivationOrderFilterVisibleSelector,
    meterActivationOrderSerialNumsSelector,
    meterActivationOrdersListSelector,
    meterActivationOrdersListTotalSelector
} from '../../../../redux/selectors/meterActivationOrders';
import { Button } from '../../../shared/components/button';
import { Link } from '../../../shared/components/link';
import { usePagination } from '../../../shared/components/pagination';
import { TableTitle } from '../../../shared/components/tableTitle';
import { Tooltip } from '../../../shared/components/tooltip';
import { MeterTabs, Routes } from '../../../shared/constants';
import { useAccessRules } from '../../../shared/hooks/useAccessRules';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { useWebDictionaryLookup } from '../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../shared/pipes';
import { formatDate } from '../../../shared/utils/dates';
import { getErrorMessage } from '../../../shared/utils/error';
import { transformDatesParams } from '../../../shared/utils/transformers/dates';
import { MeterActivationOrderMode } from './meterActivationOrderDetails/model';
import { MeterActivationOrderFilterForm, StateProps } from './model';
import { View } from './view';

const filterFormDefaultValues: MeterActivationOrderFilterForm = {
    numberMeterActivationOrder: null,
    statuses: [],
    meterSerialNum: '',
    meterModelId: null,
    address: null,
    dateFrom: null,
    dateTo: null,
    search: undefined,
    networkOperatorId: null,
};

const tableTitleNames: Record<keyof MeterActivationOrderFilterForm, string> = {
    address: 'Адрес',
    createDate: 'Дата создания заявки',
    dateFrom: '',
    dateTo: '',
    meterModelId: 'Тип ПУ',
    meterSerialNum: 'Заводской номер',
    networkOperatorId: 'Платформа Центр2М',
    numberMeterActivationOrder: 'Номер заявки',
    search: '',
    statuses: 'Статус',
};

export const MeterActivationOrdersList = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const { hasAccess } = useAccessRules();

    const data = useSelector(meterActivationOrdersListSelector);
    const dataTotal = useSelector(meterActivationOrdersListTotalSelector);
    const { meterModels } = useWebDictionaryLookup();

    const users = useSelector(usersAllListSelector);
    const isFilterVisible = useSelector(meterActivationOrderFilterVisibleSelector);
    const networkOperators = useSelector(resourcesNetworkOperatorsSelector());

    const [queryParams, setQueryParams] = useSyncQueryParams<MeterActivationOrderFilterForm>(filterFormDefaultValues);

    const serialNumSuggestions = useSelector(meterActivationOrderSerialNumsSelector);
    const form = useForm<MeterActivationOrderFilterForm>({
        defaultValues: queryParams
    });

    const formData = form.watch();

    const userById = useMemo(() => _.keyBy(users, 'id'), [users]);

    const [isLoading, setIsLoading] = useState(null);
    const [appliedFilter, setAppliedFilter] = useState<MeterActivationOrderFilterForm>(formData);

    const findSerialNumSuggestions = (value: string) => {
        dispatch(findMeterActivationOrderSerialNums(value));
    };

    const fetch = async (sort: ISort, filterParams: MeterActivationOrderFilterForm = queryParams) => {
        setIsLoading(true);
        setAppliedFilter(filterParams);
        try {
            // https://github.com/SoftMediaLab/Vostok.VMetra/issues/1851
            // Также необходимо доработать UI - отображение страницы "Смежные системы"
            // "Заявки на ввод ПУ в эксплуатацию" и не отображать заявки в статусе "Удалена".
            // Заявки в статусе удалена показываются только при явном фильтре по этому статусу.
            const copyFilterParams = { ...filterParams };
            if (!copyFilterParams.statuses?.length) {
                copyFilterParams.statuses = Object.values(MeterActivationOrderStatus)
                    .filter(item => item !== MeterActivationOrderStatus.Deleted);
            }
            await dispatch(findMeterActivationOrders(sort, transformDatesParams(copyFilterParams)));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 30,
        orderBy: 'createDate',
        orderDirection: 'desc',
    }, fetch);

    useEffect(() => {
        onFetch(pagination);
        dispatch(getAllUsers());
    }, []);


    const canReadMeterActivationOrderDetails = hasAccess(AccessRule.CanReadMeterActivationOrderDetails);

    const onCreateActivationOrder = () => {
        const route = getRoute(
            Routes.newMeterActivationOrder,
            {},
            { mode: MeterActivationOrderMode.Create },
        );
        dispatch(meterActivationOrderDetailsSlice.actions.clearMeterActivationOrderDetails());
        dispatch(push(route));
    };

    const isFilteredByNumberMeterActivationOrder = !!appliedFilter.numberMeterActivationOrder;
    const isFilteredByStatuses = appliedFilter.statuses.length > 0;
    const isFilteredMeterSerialNum = !!appliedFilter.meterSerialNum;
    const isFilteredMeterModelId = !!appliedFilter.meterModelId;
    const isFilteredAddress = !!appliedFilter.address;
    const isFilteredDateFrom = !!appliedFilter.dateFrom;
    const isFilteredDateTo = !!appliedFilter.dateTo;
    const isFilteredSearch = !!appliedFilter.search;
    const isFilteredNetworkOperatorId = !!appliedFilter.networkOperatorId;

    const appliedFiltersCount = [
        isFilteredByNumberMeterActivationOrder,
        isFilteredByStatuses,
        isFilteredMeterSerialNum,
        isFilteredMeterModelId,
        isFilteredAddress,
        isFilteredDateFrom,
        isFilteredDateTo,
        isFilteredSearch,
        isFilteredNetworkOperatorId,
    ].filter(item => !!item).length;

    const networkOperatorsLookup = networkOperators.reduce<Record<number, string>>((prev, current) => {
        return {
            ...prev,
            [current.id]: `[${current.id}] ${current.name}`,
        };
    }, {});

    const filteredFields: Record<keyof MeterActivationOrderFilterForm, boolean> = {
        address: isFilteredAddress,
        createDate: false,
        dateFrom: isFilteredDateFrom,
        dateTo: isFilteredDateTo,
        meterModelId: isFilteredMeterModelId,
        meterSerialNum: isFilteredMeterSerialNum,
        networkOperatorId: isFilteredNetworkOperatorId,
        numberMeterActivationOrder: isFilteredByNumberMeterActivationOrder,
        search: isFilteredSearch,
        statuses: isFilteredByStatuses,
    };

    const getTableTitleByName = (name: keyof MeterActivationOrderFilterForm) => {
        return (
            <TableTitle
                icon={filteredFields[name] ? 'filterFilled' : undefined}>
                {tableTitleNames[name]}
            </TableTitle>
        );
    };

    const columnDefinition: Column[] = [
        {
            title: useMemo(
                () => getTableTitleByName('numberMeterActivationOrder'),
                [filteredFields?.numberMeterActivationOrder]
            ),
            field: 'numberMeterActivationOrder',
            cellClassName: 'col-orderNumber',
            sorting: true,
        },
        {
            title: useMemo(
                () => getTableTitleByName('createDate'),
                [filteredFields?.createDate]
            ),
            field: 'createDate',
            sorting: true,
            render: (dataItem: MeterActivationOrderViewModel) => {
                return formatDate(dataItem.createDate, DateFormats.dateFullTimeFormat);
            }
        },
        {
            title: useMemo(
                () => getTableTitleByName('statuses'),
                [filteredFields?.statuses]
            ),
            field: 'meterActivationOrderStatus',
            sorting: true,
            lookup: meterActivationOrderStatusDictionary,
        },
        {
            title: 'Дата перехода в статус',
            field: 'dateStatusChange',
            cellClassName: 'col-dateStatusChange',
            sorting: true,
            render: (dataItem: MeterActivationOrderViewModel) => {
                if (!dataItem.dateStatusChange) {
                    return '—';
                }
                return formatDate(dataItem.dateStatusChange, DateFormats.dateFullTimeFormat);
            }
        },
        {
            title: useMemo(
                () => getTableTitleByName('meterModelId'),
                [filteredFields?.meterModelId]
            ),
            field: 'meterModelId',
            lookup: meterModels,
            sorting: true,
        },
        {
            title: useMemo(
                () => getTableTitleByName('meterSerialNum'),
                [filteredFields?.meterSerialNum]
            ),
            field: 'meterSerialNum',
            sorting: true,
            render: (dataItem: MeterActivationOrderViewModel) => {
                return dataItem.meterActivationOrderStatus === MeterActivationOrderStatus.CompletedSuccessfully
                && dataItem.meterId
                    ? <Tooltip text='Открыть карточку ПУ'>
                        <Link
                            to={{
                                pathname: getRoute(
                                    Routes.meter,
                                    { meterId: dataItem.meterId },
                                ),
                                search: new URLSearchParams({ meterActivationOrdersTab: MeterTabs.Information }).toString(),
                            }}
                            appendBackUrl={true}
                        >
                            {`${dataItem.meterSerialNum} >`}
                        </Link>
                    </Tooltip>
                    : dataItem.meterSerialNum;
            }
        },
        {
            title: useMemo(
                () => getTableTitleByName('address'),
                [filteredFields?.address]
            ),
            sorting: false,
            field: 'address',
        },
        {
            title: 'Состояние реле',
            sorting: true,
            field: 'relayStatus',
            render: (dataItem: MeterActivationOrderViewModel) => {
                if (dataItem.relayStatus === null) {
                    return null;
                }
                return meterRelayStatusDictionary[dataItem.relayStatus];
            }
        },
        {
            title: 'Дата установки',
            sorting: true,
            field: 'installationDate',
            render: (dataItem: MeterActivationOrderViewModel) => {
                return formatDate(dataItem.installationDate, DateFormats.dateFormat);
            }
        },
        {
            title: 'Пользователь',
            field: 'userId',
            sorting: false,
            render: (item) => userById[item.userId]?.email ?? '—',
        },
        {
            title: 'Ошибка',
            sorting: true,
            field: 'resultMessage',
            cellClassName: 'line-clamp'
        },
        {
            title: useMemo(
                () => getTableTitleByName('networkOperatorId'),
                [filteredFields?.networkOperatorId]
            ),
            sorting: false,
            field: 'networkOperatorId',
            lookup: networkOperatorsLookup,
        },
        ...(canReadMeterActivationOrderDetails
            ? [
                {
                    title: '',
                    sorting: false,
                    field: '',
                    render: (dataItem: MeterActivationOrderViewModel) => {
                        return <Link
                            to={{
                                pathname: getRoute(
                                    Routes.meterActivationOrder,
                                    { meterActivationOrderId: dataItem.meterActivationOrderId },
                                ),
                                search: new URLSearchParams({ mode: MeterActivationOrderMode.Update }).toString(),
                            }}
                            appendBackUrl={true}
                        >
                            <Button variant='primary' type='button'>Открыть</Button>
                        </Link>;
                    }
                },
            ]
            : []
        ),
    ];

    const props: StateProps = {
        data,
        form,
        setPage,
        setOrder,
        dataTotal,
        pagination,
        orderParams,
        columnDefinition,
        serialNumSuggestions,
        onCreateActivationOrder,
        findSerialNumSuggestions,
        isFilterVisible,
        filterFormDefaultValues,
        queryParams,
        isLoading,
        appliedFiltersCount,
        networkOperators,
        canPostMeterActivationOrder: hasAccess(AccessRule.CanPostMeterActivationOrder),
        canReadMeterActivationOrdersList: hasAccess(AccessRule.CanReadMeterActivationOrdersList),
        onFiltersOpen: () => {
            dispatch(meterActivationOrdersListSlice.actions.setFilterVisibility(true));
        },
        onFiltersClose: () => {
            dispatch(meterActivationOrdersListSlice.actions.setFilterVisibility(false));
        },
        onClickFormApply: async () => {
            setQueryParams(formData);
            await onFetch(pagination, formData);
        },
        onResetFilter: async () => {
            form.reset(filterFormDefaultValues);
            await onFetch(pagination, filterFormDefaultValues);
            setQueryParams(filterFormDefaultValues);
        }
    };

    return View(props);
};
