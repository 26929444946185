import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { SpecialDayBaseCreator } from '../../../common/model/meter/specialDayBase/specialDayBaseCreator';
import { SpecialDayBaseGetQueryParams } from '../../../common/model/meter/specialDayBase/specialDayBaseGetQueryParams';
import { SpecialDayBaseUpdater } from '../../../common/model/meter/specialDayBase/specialDayBaseUpdater';
import { SpecialDayBaseViewModel } from '../../../common/model/meter/specialDayBase/specialDayBaseViewModel';
import { specialDayBaseListSlice } from '../../reducers/specialDayBase/specialDayBaseList';
import { AppThunk } from '../index';

export const getSpecialDayBaseList = (
    params: SpecialDayBaseGetQueryParams,
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_special_day_base, {}, { ...params });
    const data = await httpService.get<SpecialDayBaseViewModel[]>(route);
    dispatch(specialDayBaseListSlice.actions.setAvailable(data));
};

export const createSpecialDayBase = async (
    payload: SpecialDayBaseCreator,
): Promise<SpecialDayBaseViewModel> => {
    const route = getApiRoute(appUrls.api_post_special_day_base);
    const data = await httpService.post<SpecialDayBaseViewModel>(route, payload);
    return data;
};

export const updateSpecialDayBase = async (
    id: string,
    payload: SpecialDayBaseUpdater,
): Promise<SpecialDayBaseViewModel> => {
    const route = getApiRoute(appUrls.api_put_special_day_base, { id });
    const data = await httpService.put<SpecialDayBaseViewModel>(route, payload);
    return data;
};
