import * as React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import WebSocketProvider from './shared/contexts/webSocket';
import { history } from '../redux/store/configureStore';
import { Content } from './routing';
import { HttpWrapper } from './shared/httpWrapper';
import { ThemeProvider } from '@material-ui/styles';
import { store } from '../index';
import { materialUiTheme } from '../styles/materialUi';


export const View = () => {
    return (
        <Provider store={store}>
            <WebSocketProvider>
                <ConnectedRouter history={history}>
                    <ThemeProvider theme={materialUiTheme}>
                        <div className='root-element'>
                            <HttpWrapper />
                            {Content}
                        </div>
                    </ThemeProvider>
                </ConnectedRouter>
            </WebSocketProvider>
        </Provider>
    );
};
