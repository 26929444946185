import moment from 'moment';
import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DateFormats } from '../../../../../common/constants/date';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { MeterConfigurationHistoryViewModel } from '../../../../../common/model/meter/meterConfigurationHistory/meterConfigurationHistoryViewModel';
import { ISort } from '../../../../../common/sort';
import { BaseDispatch } from '../../../../../redux/actions';
import { getMeter, getMeterConfigurationHistory } from '../../../../../redux/actions/meters';
import { meterConfigurationHistorySelector, meterConfigurationHistoryTotalSelector, meterSelector } from '../../../../../redux/selectors/meters';
import { usePagination } from '../../../../shared/components/pagination';
import { MeterTabs, Routes } from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { getRoute } from '../../../../shared/pipes';
import { MeterConfigurationHistoryFilterParams, StateProps } from './model';
import { View } from './view';
import { useSyncQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useForm } from 'react-hook-form';
import { transformDatesParams } from '../../../../shared/utils/transformers/dates';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { MeterConfigurationHistoryParams } from '../../../../../common/model/meter/meterConfigurationHistory/meterConfigurationHistoryParams';

const filterDefaultValues: MeterConfigurationHistoryFilterParams = {
    dateFrom: moment().add(-1, 'month').toDate(),
    dateTo: moment().add(1, 'day').toDate(),
    configurationParam: MeterConfigurationHistoryParams.All
};

export const MeterConfigurationHistory = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const params = useParams<{meterId?: string}>();
    const meterId = params.meterId;

    const data = useSelector(meterConfigurationHistorySelector);
    const dataTotal = useSelector(meterConfigurationHistoryTotalSelector);
    const meter = useSelector(meterSelector);

    const [filter, setFilter] = useSyncQueryParams<MeterConfigurationHistoryFilterParams>(filterDefaultValues);

    const form = useForm<MeterConfigurationHistoryFilterParams>({
        defaultValues: filter
    });

    const { hasAccess } = useAccessRules();

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Прибор учета', link: getRoute(Routes.meter, { meterId }, { tab: MeterTabs.Information }) },
        { name: 'Карточка ПУ', link: getRoute(Routes.meter, { meterId }, { tab: MeterTabs.Information }) },
        { name: 'Проблемы ПУ', link: getRoute(Routes.meter, { meterId }, { tab: MeterTabs.Problems }) },
        { name: 'История смены конфигурации', link: getRoute(Routes.meterConfigurationHistory, { meterId }) },
    ]);

    const onCancel = () => {
        dispatch(push(getRoute(Routes.meter, { meterId }, { tab: MeterTabs.Problems })));
    };

    const fetch = async (sort: ISort) => {
        const newFilterData = { ...filterDefaultValues, ...form.watch() };
        setFilter(transformDatesParams(newFilterData));
        await dispatch(getMeterConfigurationHistory(meterId, { ...transformDatesParams(newFilterData), ...sort }));
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 10,
        orderBy: null,
        orderDirection: null,
    }, fetch);

    const onSubmit: SubmitHandler<MeterConfigurationHistoryFilterParams> = () => {
        onFetch(pagination);
    };

    useEffect(() => {
        const subscription = form.watch((value: MeterConfigurationHistoryFilterParams) => {
            const timeoutBeforeRequest = 300;
            setTimeout(() => onSubmit(value), timeoutBeforeRequest);
        });
        return () => subscription.unsubscribe();
    }, [form.watch]);

    useEffect(() => {
        if (meterId) {
            dispatch(getMeter(meterId));
        }
    }, [meterId]);

    useEffect(() => {
        onFetch(pagination);
    }, [meterId]);

    const props: StateProps = {
        meter,
        hasAccessToMeterConfigurationHistory: hasAccess(AccessRule.CanReadMeterConfigurationHistory),
        breadcrumbs,
        onClickBreadcrumb,
        onCancel,
        pagination,
        data,
        dataTotal,
        setOrder,
        setPage,
        orderParams,
        form,
        onSubmit,
        columnDefinition: [
            {
                title: 'Дата изменения',
                field: 'createdAt',
                render: (dataItem: MeterConfigurationHistoryViewModel) => {
                    return moment(dataItem.createdAt).format(DateFormats.dateFullTimeFormat);
                },
                sorting: true,
            },
            {
                title: 'Тип ПУ',
                field: 'meterModel',
                sorting: false,
            },
            {
                title: 'Версия прошивки (ПО) ПУ',
                field: 'meterFirmwareVersion',
                sorting: false,
            },
            {
                title: 'Коэффициент трансформации по току, прошитый в ПУ',
                field: 'cTRatio',
                sorting: false,
            },
            {
                title: 'Коэффициент трансформации по напряжению, прошитый в ПУ',
                field: 'pTRatio',
                sorting: false,
            },
            {
                title: 'Дата производства ПУ',
                field: 'manufactureDate',
                render: (dataItem: MeterConfigurationHistoryViewModel) => {
                    return moment(dataItem.manufactureDate).format(DateFormats.dateTimeFormat);
                },
                sorting: false,
            }
        ]
    };

    return View(props);
};
