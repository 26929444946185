import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

export interface Props {
    isDataExists: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    noDataText: {
        ...theme.typography.body,
        color: theme.palette.text.hint,
    },
}));

export const NoDataWrapper: React.FC<Props> = (props) => {
    const { isDataExists, children } = props;
    const classes = useStyles();
    if (!isDataExists) {
        return <div className={classes.noDataText}>Нет данных</div>;
    }
    return <>{children}</>;
};
