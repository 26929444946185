import React from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { BlockProps, BlockPropsItem } from './model';
import { useStyles } from './styles';
import { BlockIcon } from './BlockIcon';
import { BlockText } from './BlockText';
import { BlockPassword } from './BlockPassword';
import { BlockRaw } from './BlockRaw';

export const View = (props: BlockProps) => {
    const { items, type, className, childClassName } = props;
    const itemsCount = items.length;
    const classes = useStyles({ count: itemsCount });

    return (
        <Box
            className={classNames(
                type === 'horizontal' ? classes.horizontal : classes.vertical,
                className
            )}
        >
            {items.map((item: BlockPropsItem, index) => {
                const blockItemProps = {
                    className: classNames(childClassName),
                    count: itemsCount,
                    item,
                    type,
                };

                if (item.type === 'icon') {
                    return <BlockIcon key={index} {...blockItemProps} />;
                }
                if (item.type === 'password') {
                    return <BlockPassword key={index} {...blockItemProps} />;
                }
                if (item.type === 'raw') {
                    return <BlockRaw key={index} {...blockItemProps} />;
                }

                return <BlockText key={index} {...blockItemProps} />;
            })}
        </Box>
    );
};
