import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import { MeterProfileReportQueryParams, MeterProfileReportResponse } from '../../../../common/model/meter/reports/meterProfileReport';
import { meterProfileReportSlice } from '../../../reducers/reports/meterProfileReport';
import { AppThunk } from '../../index';

export const getMeterProfileReport = (params: MeterProfileReportQueryParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_reports_meter_profile, {}, params);
    const data = await httpService.get<MeterProfileReportResponse>(route);
    dispatch(meterProfileReportSlice.actions.setReport(data));
};

export const resetMeterProfileReport = (): AppThunk => (dispatch) => {
    dispatch(meterProfileReportSlice.actions.setReport(null));
};
