import React from 'react';
import _ from 'lodash';
import { Pagination } from '../../../shared/components/pagination';
import { setOrderTransformer } from '../../../shared/components/pagination/helpers';
import { Wrapper } from '../../../shared/components/wrapper';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '../../../shared/components/button';
import { UnlegalAccessProps } from './model';
import { VisualizationSource } from '../../../shared/components/table/model';
import { Table } from 'app/shared/components/table';
import { defaults, Tables } from '../../../shared/constants';
import { FormProvider } from 'react-hook-form';
import { HookField } from '../../../shared/components/hookField';
import { HookFieldTransformer } from '../../../shared/components/hookField/model';
import { VAutocompleteOption } from '../../../shared/components/autocomplete/model';

const useStyles = makeStyles(() => ({
    monitoringWrapper: {
        display: 'flex',
        minHeight: '880px',
        '& .MuiPaper-root': {
            justifyContent: 'space-around',
        },
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'nowrap',
        '& .MuiGrid-grid-xs-3': {
            minWidth: 170,
        },
        '& .MuiGrid-grid-xs-1': {
            maxWidth: 'initial',
            flexBasis: 'inherit',
        }
    },
    form: {
        width: '100%',
        maxWidth: 800,
    }
}));


export const View: React.FC<UnlegalAccessProps> = ({
    form,
    onSubmit,
    columnDefinition,
    data,
    updateData,
    dataTotal,
    setOrder,
    pagination,
    setPage,
    orderParams,
    eventTypeAutocomplete,
}) => {
    const classes = useStyles();

    const eventTypeValuesTransformer: HookFieldTransformer = {
        input: (v) => {
            return eventTypeAutocomplete.options.filter(item => v?.includes(item.value));
        },
        output: (v) => {
            return _.uniq(v.map((item: VAutocompleteOption) => item.value));
        },
    };

    const tableActions = <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={classes.form} >
            <Grid container spacing={2} className={classes.formContainer}>
                <Grid xs={3} item>
                    <HookField
                        fullWidth
                        name='dateFrom'
                        type='datepicker'
                        label='Начало'
                    />
                </Grid>
                <Grid xs={3} item>
                    <HookField
                        fullWidth
                        name='dateTo'
                        type='datepicker'
                        label='Конец'
                    />
                </Grid>
                <Grid xs={4} item>
                    <HookField
                        withCheckbox
                        fullWidth
                        type='autocomplete'
                        name='eventType'
                        label='Событие'
                        noOptionsText='Событие не найдено'
                        loadingText='Поиск...'
                        {...eventTypeAutocomplete}
                        transform={eventTypeValuesTransformer}
                    />
                </Grid>
                <Grid xs={1} item>
                    <Button
                        variant='outline'
                        icon='refresh'
                        square
                        onClick={updateData}
                    />
                </Grid>
            </Grid>
        </form>
    </FormProvider>;

    return (
        <Wrapper title='Несанкционированный доступ' actions={tableActions} className={classes.monitoringWrapper} isBigHeader>
            <Table
                visualizationSource={VisualizationSource.clientProfile}
                name={Tables.commonEventLogTable}
                columns={columnDefinition}
                data={data ?? []}
                title={null}
                variant='white'
                options={{
                    grouping: false,
                    columnsButton: false,
                    filtering: false,
                    search: false,
                    sorting: true,
                    toolbar: false,
                    showTitle: false,
                    paging: 'disabled',
                }}
                fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                onOrderChange={setOrderTransformer(columnDefinition, setOrder)}
                {...orderParams}
            />
            <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={!data} />
        </Wrapper>
    );
};
