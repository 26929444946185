import { StorageState } from '../../reducers';
import { createSelector } from '@reduxjs/toolkit';


export const listSelector = (state: StorageState) => state.meterActivationOrders.list;

export const meterActivationOrdersListSelector = createSelector(
    listSelector,
    list => list.available,
);

export const meterActivationOrdersListTotalSelector = createSelector(
    listSelector,
    list => list.total,
);

export const meterActivationOrderSerialNumsSelector = createSelector(
    listSelector,
    list => list.serialNumSuggestions
);

const detailsSelector = (state: StorageState) => state.meterActivationOrders.details;

export const meterActivationOrderDetailsSelector = createSelector(
    detailsSelector,
    details => details.meterActivationOrder,
);

export const meterActivationOrderDetailsLoadingSelector = createSelector(
    detailsSelector,
    details => details.loading,
);

export const meterActivationOrderFilterVisibleSelector = createSelector(
    listSelector,
    list => list.isFilterVisible,
);
