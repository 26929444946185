import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

const baseSelector = (state: StorageState) => state.timeSynchronizationSchemes;

export const timeSynchronizationSchemesListSelector = createSelector(
    baseSelector,
    state => state.list,
);

export const timeSynchronizationSchemesListAvailableSelector = createSelector(
    baseSelector,
    state => state.list.available,
);
