export enum MeterChangePasswordOrderStatus {
    MeterNotFound = 'MeterNotFound',
    ChangePasswordOperationNotAvailable = 'ChangePasswordOperationNotAvailable',
    ReadyForWork = 'ReadyForWork',
    InProgress = 'InProgress',
    RuntimeError = 'RuntimeError',
    SuccessfullyCompleted = 'SuccessfullyCompleted',
    ValidationPasswordError = 'ValidationPasswordError',
    InvalidMeterStatus = 'InvalidMeterStatus',
    RejectedDueToPendingOrder = 'RejectedDueToPendingOrder',
    ValidationPasswordsNotProvided = 'ValidationPasswordsNotProvided',
    TTLExpired = 'TTLExpired',
}

export const meterChangePasswordOrderStatusDictionary: Record<MeterChangePasswordOrderStatus, string> = {
    [MeterChangePasswordOrderStatus.MeterNotFound]: 'ПУ не найден',
    [MeterChangePasswordOrderStatus.ChangePasswordOperationNotAvailable]: 'Недоступна операция смены паролей',
    [MeterChangePasswordOrderStatus.ReadyForWork]: 'Готова к работе',
    [MeterChangePasswordOrderStatus.InProgress]: 'В работе',
    [MeterChangePasswordOrderStatus.RuntimeError]: 'Ошибка смены паролей',
    [MeterChangePasswordOrderStatus.SuccessfullyCompleted]: 'Выполнена успешно',
    [MeterChangePasswordOrderStatus.ValidationPasswordError]: 'Ошибка подключения к ПУ с новым паролей',
    [MeterChangePasswordOrderStatus.InvalidMeterStatus]: 'Неверный статус ПУ',
    [MeterChangePasswordOrderStatus.ValidationPasswordsNotProvided]: 'Не указаны пароли для валидации',
    [MeterChangePasswordOrderStatus.RejectedDueToPendingOrder]: 'Запрос отклонен, так как операция в процессе',
    [MeterChangePasswordOrderStatus.TTLExpired]: 'Превышено время жизни заявки',
};
