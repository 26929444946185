import React from 'react';
import { Grid, makeStyles, Box } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { Button } from 'app/shared/components/button';
import { CrqSystemFormViewProps } from './model';
import { Typography } from 'app/shared/components/typography';
import { HookField } from 'app/shared/components/hookField';
import { Loader } from 'app/shared/components/loader';

const useStyles = makeStyles((theme) => ({
    requiredLabel: {
        color: theme.palette.system.error,
    },
    footer: {
        marginTop: 20
    }
}));

export const View = (props: CrqSystemFormViewProps) => {
    const {
        form,
        onSubmit,
        handleCloseModal,
        isLoading,
        schemesSuggestions,
    } = props;

    const classes = useStyles();
    const { handleSubmit } = form;

    if (isLoading) {
        return <Box display='flex' justifyContent='center'>
            <Loader visible={true} size={50} />
        </Box>;
    }

    return <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Название</Typography>
                            <span className={classes.requiredLabel}> *</span>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='text'
                                name='name'
                                fullWidth
                                label='Название'
                            />
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>HTTPS</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='checkbox'
                                name='isHttps'
                                label="Подключаться по https"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Сервер</Typography>
                            <span className={classes.requiredLabel}> *</span>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='text'
                                name='server'
                                fullWidth
                                label='Укажите сервер'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Порт</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='number'
                                name='port'
                                fullWidth
                                label='Укажите порт'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Логин</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='text'
                                name='login'
                                fullWidth
                                label='Укажите логин'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Пароль</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='password'
                                name='password'
                                fullWidth
                                isShowHidePasswordVisible={true}
                                label='Укажите пароль'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Схема опроса</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                type='select'
                                name='meterActionSchemeId'
                                label='Схема опроса'
                                fullWidth
                                isClearable
                                suggestions={schemesSuggestions}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Количество потоков</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                fullWidth
                                type='number'
                                placeholder='10'
                                name='maxTasksCount'
                                label='Количество потоков, шт.'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} >
                            <Typography variant='bodyBold'>Время отклика</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                fullWidth
                                type='number'
                                placeholder='300'
                                label='Время отклика, сек.'
                                name='timeoutInSeconds'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.footer}>
                    <Grid container spacing={3} justify='center'>
                        <Grid item>
                            <Button variant='primary' type='submit' icon='check'>Сохранить</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='secondary' type='button' onClick={handleCloseModal}>Отмена</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </FormProvider>
    ;
};
