import { makeStyles } from '@material-ui/core';
import { setColumnWidth } from '../../../shared/pipes/styles';

export const useStyles = makeStyles(() => ({
    tableContainer: {
        position: 'relative',
        '& .cell-sequenceNumber': {
            ...setColumnWidth('120px'),
        },
        '& .cell-version': {
            ...setColumnWidth('200px'),
        },
        '& .cell-meterModelMask': {
            ...setColumnWidth('120px'),
        },
    },
}));
