import { MeterActionPlanStatus } from '../../../../../common/model/meter/meterActionPlanStatus';
import { MeterActionTaskStatus } from '../../../../../common/model/meter/meterActionTaskStatus';
import {
    MeterTariffScheduleInOrderViewModel
} from '../../../../../common/model/meter/meterTariffSchedule/meterTariffScheduleInOrderViewModel';
import { MeterTariffScheduleStatus } from '../../../../../common/model/meter/meterTariffSchedule/meterTariffScheduleStatus';
import { addMsToDate, getCurrentDate, msInHour } from '../../../../shared/utils/dates';
import { TariffScheduleOrderIndicatorProps } from './tariffScheduleOrderIndicator';

export const tariffScheduleIndicatorMapper = (
    meterTariffScheduleInOrder: MeterTariffScheduleInOrderViewModel
): TariffScheduleOrderIndicatorProps => {

    const props: TariffScheduleOrderIndicatorProps = {
        status: 'success',
        title: 'Успешно',
        attemptCount: undefined,
        tooltip: undefined,
        hidden: false,
    };

    if (!meterTariffScheduleInOrder) {
        props.hidden = true;
        return props;
    }

    const {
        status,
        planDate,
        meterActionPlan,
    } = meterTariffScheduleInOrder;

    const hourAgo = addMsToDate(getCurrentDate(), -1 * msInHour);

    const isPlanDateGreaterThenHourAgo = new Date(planDate).getTime() < hourAgo.getTime();

    switch (status) {
        case MeterTariffScheduleStatus.IsSetup: {
            props.title = 'Выполнено';
            if (isPlanDateGreaterThenHourAgo) {
                props.hidden = true;
            }
            break;
        }
        case MeterTariffScheduleStatus.SetupNotRequired: {
            props.title = 'Установка не требуется';
            if (isPlanDateGreaterThenHourAgo) {
                props.hidden = true;
            }
            break;
        }
        case MeterTariffScheduleStatus.Planned: {
            switch (meterActionPlan.taskStatus) {
                case MeterActionTaskStatus.Queue:
                case MeterActionTaskStatus.Pending: {
                    props.status = 'inProgress';
                    props.title = 'В работе';
                    props.attemptCount = meterActionPlan.attemptCount;
                    break;
                }
                case MeterActionTaskStatus.Processed: {
                    props.status = 'success';
                    props.title = 'Выполнено';
                    break;
                }
                case MeterActionTaskStatus.NotProcessed: {
                    props.status = 'error';
                    props.title = 'Ошибка';
                    props.tooltip = meterActionPlan.taskResultMessage;
                    break;
                }
                default: {
                    switch (meterActionPlan.planStatus) {
                        case MeterActionPlanStatus.Planned:
                        case MeterActionPlanStatus.InProgress: {
                            props.status = 'inProgress';
                            props.title = 'В работе';
                            props.attemptCount = meterActionPlan.attemptCount;
                            break;
                        }
                        case MeterActionPlanStatus.Disabled: {
                            props.status = 'error';
                            props.title = 'План опроса заблокирован';
                            break;
                        }
                        default: {
                            // do nothing
                        }
                    }
                }
            }
            break;
        }
        default: {
            // do nothing
        }
    }

    return props;
};
