import React, { useEffect, useMemo, useRef } from 'react';
import './styles.scss';
// eslint-disable-next-line init-declarations
declare const ymaps: any;

interface MyPlacemark {
    content: string;
    coordinates: number[];
}

interface MapProps {
    center: number[];
    zoom?: number;
    placemarks?: MyPlacemark[];
}

const defaultZoom = 15;

export const Map = ({ center, zoom = defaultZoom, placemarks = [] }: MapProps) => {
    const mapRef = useRef<ymaps.Map>(null);

    const BalloonContentLayout = useMemo(() => {
        return ymaps.templateLayoutFactory.createClass(
            (
                `<div class="balloon-root">
                    <div class="arrow balloon-pin"></div>
                    <div class="balloon-body balloon">$[properties.balloonContent]</div>
                </div>`
            ),
            {
                build () {
                    this.constructor.superclass.build.call(this);
                    this._$element = this.getParentElement().querySelector('.balloon-root');
                    this.applyElementOffset();
                    this._$element
                        .addEventListener('click', () => {
                            this.onCloseClick.call(this);
                        });
                },
                onCloseClick () {
                    this.events.fire('userclose');
                },
                applyElementOffset () {
                    const { width, height } = this._$element.getBoundingClientRect();
                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    this._$element.style.left = `${-width / 2}px`;
                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    this._$element.style.top = `${-height - 8}px`;
                },
            });
    }, []);

    useEffect(() => {
        mapRef.current = new ymaps.Map('map', {
            center,
            zoom,
        });

        for (const placemark of placemarks) {
            const pm = new ymaps.Placemark(placemark.coordinates, {
                balloonContent: placemark.content,
            }, {
                preset: 'islands#redCircleDotIcon',
                balloonLayout: BalloonContentLayout,
                // Запретим замену обычного балуна на балун-панель.
                // Если не указывать эту опцию, на картах маленького размера откроется балун-панель.
                balloonPanelMaxMapArea: 1,
            });
            mapRef.current.geoObjects.add(pm);
            pm.balloon.open();
            pm.balloon.autoPan();
            return () => {
                mapRef.current.geoObjects.remove(pm);
            };
        }

        return () => {
            mapRef.current?.destroy();
        };
    }, []);

    return <div>
        <div id="map" style={{ width: '100%', height: '640px' }} />
    </div>;
};
