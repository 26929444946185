import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

const importCrqSelector = (state: StorageState) => state.meterImportCrq;

export const importCrqListSelector = createSelector(
    importCrqSelector,
    data => data.list.available,
);

export const importCrqByIdSelector = (id:string) => createSelector(
    importCrqListSelector,
    (data) => data?.find(item => item.id.toString() === id),
);

export const importCrqLogListSelector = createSelector(
    importCrqSelector,
    data => data.logList,
);
