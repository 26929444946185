import { UploaderOptions } from './model';
import { UploaderFile, UploaderFileState } from './uploaderFile/model';
import { View } from './view';
import { useCallback, useState } from 'react';
import { newGuid } from '../../../../common/shared/utils';
import { useIsMounted } from '../../hooks/useIsMounted';
import { sleep, splitArrayToChunks } from '../../pipes';

const getAcceptedFiles = (files: UploaderFile[]): UploaderFile[] => {
    return files.filter(file => file.state !== UploaderFileState.error && file.state !== UploaderFileState.uploaded);
};

export const createUploaderFile = (file: File, state = UploaderFileState.new) => {
    return {
        file,
        state,
        id: newGuid(),
    };
};

const getFileNameWithoutExt = (filename: string): string => {
    return filename.replace(/(\.\w+)$/g, '');
};

type ChangeFileStateCallback = (file: UploaderFile, state: UploaderFileState, message: string) => void;

export const Uploader = (props: UploaderOptions) => {
    const [files, setFiles] = useState<UploaderFile[]>([]);
    const [isUploading, setUploading] = useState<boolean>(false);
    const isMounted = useIsMounted();
    const changeFileState = useCallback<ChangeFileStateCallback>((file: UploaderFile, state: UploaderFileState, message: string) => {
        if (isMounted.current) {
            setFiles((oldFiles) => {
                return oldFiles.map(item => {
                    if (item.id === file.id) {
                        return {
                            ...item,
                            state,
                            message,
                        };
                    }
                    return item;
                });
            });
        }
    }, [files, isMounted.current]);
    const submit = useCallback(async () => {
        setUploading(true);
        const filesResults = props.onSubmit(getAcceptedFiles(files));
        const fileCountInChunk = 5;
        const delayBetweenChunksMs = 1000;
        const filesChunksResults = splitArrayToChunks(filesResults, fileCountInChunk);
        const results = [];
        for (const filesChunk of filesChunksResults) {
            const chunkResult = await Promise.all(filesChunk.map(async ({ promise, file }) => {
                changeFileState(file, UploaderFileState.uploading, '');
                try {
                    await promise();
                    changeFileState(file, UploaderFileState.uploaded, 'Файл успешно прикреплен');
                    return { result: true, file };
                } catch (err) {
                    const message = err && err.message ? err.message : 'Ошибка при прикреплении';
                    changeFileState(file, UploaderFileState.error, message);
                    return { result: false, file };
                }
            }));
            results.push(...chunkResult);
            await sleep(delayBetweenChunksMs);
        }
        if (props.onUploadEnd && filesResults.length > 0) {
            const success = results.filter(file => file.result).map(file => file.file);
            const rejected = results.filter(file => !file.result).map(file => file.file);
            props.onUploadEnd(success, rejected);
        }
        if (isMounted.current) {
            setUploading(false);
        }
    }, [files, isMounted]);

    return View({
        ...props,
        files,
        uploading: isUploading,
        deleteFile: (id: string) => {
            setFiles(f => f.filter(file => file.id !== id));
        },
        submit,
        handleDropFiles: (accepted, rejected) => {
            setFiles((oldFiles: UploaderFile[]) => ([
                ...oldFiles,
                ...rejected
                    .map(file => {
                        return {
                            ...createUploaderFile(file.file),
                            state: UploaderFileState.error,
                            message: file.reason,
                        };
                    }),
                ...accepted
                    .map(file => createUploaderFile(file))
                    .filter(newFile => {
                        return !oldFiles.find((f) => f.file.name === newFile.file.name);
                    })
                    .map(file => {
                        const exist = props.forbiddenFiles
                            ? props.forbiddenFiles.map(getFileNameWithoutExt).includes(getFileNameWithoutExt(file.file.name))
                            : false;
                        return {
                            ...file,
                            state: exist ? UploaderFileState.error : UploaderFileState.new,
                            message: exist ? 'Файл уже прикреплен' : null,
                        };
                    })
            ]));
        },
    });
};
