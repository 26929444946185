import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { MeterActivationOrderCreator } from '../../../../../common/model/meter/meterActivationOrder/meterActivationOrderCreator';
import { MeterActivationOrderStatus } from '../../../../../common/model/meter/meterActivationOrder/meterActivationOrderStatus';
import { BaseDispatch } from '../../../../../redux/actions';
import {
    deleteMeterActivationOrder,
    createMeterActivationOrder,
    getMeterActivationOrder,
    reprocessMeterActivationOrder,
    updateAndReprocessMeterActivationOrder,
    resetMeterActivationOrder
} from '../../../../../redux/actions/meterActivationOrders';
import { meterActivationOrderDetailsSlice } from '../../../../../redux/reducers/meterActivationOrders/details';
import {
    meterActivationOrderDetailsLoadingSelector,
    meterActivationOrderDetailsSelector
} from '../../../../../redux/selectors/meterActivationOrders';
import { MeterActivationOrderTabs, MeterTabs, Routes } from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { getRoute } from '../../../../shared/pipes';
import { MeterActivationOrderMode, StateProps } from './model';
import { View } from './view';

const refreshOrderIntervalMs = 5000;

export const MeterActivationOrderDetails = () => {

    const dispatch = useDispatch<BaseDispatch>();
    const { meterActivationOrderId } = useParams<{ meterActivationOrderId?: string }>();
    const queryParams = useQueryParams();
    const mode = queryParams.get('mode') as MeterActivationOrderMode;

    const { hasAccess } = useAccessRules();

    const meterActivationOrder = useSelector(meterActivationOrderDetailsSelector);
    const meterActivationOrderLoading = useSelector(meterActivationOrderDetailsLoadingSelector);

    const [showExecuteModal, setShowExecuteModal] = useState(false);
    const [submittedMeterActivationOrder, setSubmittedMeterActivationOrder] = useState<MeterActivationOrderCreator>(null);
    const [isFormDirty, setIsFormDirty] = useState(false);

    useEffect(() => {
        if (mode === MeterActivationOrderMode.Update) {
            dispatch(meterActivationOrderDetailsSlice.actions.setMeterActivationOrderLoading(true));
            dispatch(getMeterActivationOrder(meterActivationOrderId));
        }
    }, [meterActivationOrderId, mode]);

    useEffect(() => {
        return () => {
            dispatch(resetMeterActivationOrder());
        };
    }, []);

    // Периодически перезапрашиваем заявку с бэкенда, если она в статусе первичной диагностики
    useEffect(() => {
        let interval: NodeJS.Timeout = null;
        if (
            mode === MeterActivationOrderMode.Update &&
            meterActivationOrder?.meterActivationOrderStatus === MeterActivationOrderStatus.InitialDiagnostic
        ) {
            interval = setInterval(() => {
                dispatch(getMeterActivationOrder(meterActivationOrderId));
            }, refreshOrderIntervalMs);
        }

        if (interval && meterActivationOrder?.meterActivationOrderStatus !== MeterActivationOrderStatus.InitialDiagnostic) {
            clearInterval(interval);
        }

        return () => interval ? clearInterval(interval) : null;
    }, [mode, meterActivationOrder]);

    const onSubmit: SubmitHandler<MeterActivationOrderCreator> = (values) => {
        setShowExecuteModal(true);
        setSubmittedMeterActivationOrder(values);
    };

    const onExecuteActivationOrder = (values: MeterActivationOrderCreator, runInitialDiagnostic: boolean) => {
        // eslint-disable-next-line default-case
        switch (mode) {
            case MeterActivationOrderMode.Create:
                dispatch(createMeterActivationOrder(values, runInitialDiagnostic));
                break;
            case MeterActivationOrderMode.Update:
                dispatch(updateAndReprocessMeterActivationOrder(meterActivationOrderId, values, runInitialDiagnostic));
                break;
        }
        setSubmittedMeterActivationOrder(null);
        setShowExecuteModal(false);
        setIsFormDirty(false);
    };

    const onCloseExecuteModal = () => {
        setShowExecuteModal(false);
    };

    const onReprocess = () => {
        if (confirm('Вы действительно хотите запустить обработку заявки?')) {
            dispatch(reprocessMeterActivationOrder(meterActivationOrderId));
        }
    };

    const onDeleteOrder = () => {
        if (confirm('Вы действительно хотите удалить заявку?')) {
            dispatch(deleteMeterActivationOrder(meterActivationOrderId));
        }
    };

    const onFormDirtyChange = (isDirty: boolean) => {
        setIsFormDirty(isDirty);
    };

    const onBackToList = () => {
        const route = getRoute(Routes.relatedSystems, {}, { meterActivationOrdersTab: MeterActivationOrderTabs.Opened });
        dispatch(push(route));
    };

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Смежные системы', link: getRoute(Routes.relatedSystems) },
        { name: 'Заявки на ввод в эксплуатацию ПУ', link: getRoute(Routes.relatedSystems, {}, { meterActivationOrdersTab: MeterActivationOrderTabs.Opened }) },
        {
            name: mode === MeterActivationOrderMode.Create ? 'Новая заявка' : 'Просмотр и редактирование заявки',
            link: getRoute(Routes.meterActivationOrder, {}, { mode })
        }
    ]);

    const onOpenPUPage = () => {
        const route = getRoute(Routes.meter, { meterId: meterActivationOrder.meterId }, { tab: MeterTabs.Information });
        dispatch(push(route));
    };

    const props: StateProps = {
        meterActivationOrder,
        mode,
        onSubmit,
        breadcrumbs,
        onReprocess,
        onFormDirtyChange,
        isFormDirty,
        onClickBreadcrumb,
        showExecuteModal,
        onOpenPUPage,
        onCloseExecuteModal,
        onExecuteActivationOrder,
        submittedMeterActivationOrder,
        onBackToList,
        loading: meterActivationOrderLoading,
        onDeleteOrder,
        canReadMeterActivationOrderDetails: hasAccess(AccessRule.CanReadMeterActivationOrderDetails),
        canReprocessMeterActivationOrder: hasAccess(AccessRule.CanReprocessMeterActivationOrder),
        canDeleteMeterActivationOrder: hasAccess(AccessRule.CanDeleteMeterActivationOrder),
        canReadMeter: hasAccess(AccessRule.CanReadMeter),
    };

    return View(props);
};
