import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterPartnerViewModel } from '../../../../common/model/meter/meter/meterPartnerViewModel';
import { PartnerMetersResponse } from '../../../../common/model/meter/partners/partnerMetersResponse';
import { PartnerViewModel } from '../../../../common/model/meter/partners/partnerViewModel';

export interface PartnersDetailsState {
    available: PartnerViewModel;
    meters: MeterPartnerViewModel[];
    totalMeters: number;
}

const initialState: PartnersDetailsState = {
    available: null,
    meters: null,
    totalMeters: null,
};

export const partnersDetailsSlice = createSlice({
    name: '@vv/partners/details',
    initialState,
    reducers: {
        setPartner(state, action: PayloadAction<PartnerViewModel>): void {
            state.available = action.payload;
        },
        setMeters(state, action: PayloadAction<PartnerMetersResponse>): void {
            state.meters = action.payload?.data;
            state.totalMeters = action.payload?.total;
        },
        resetPartner(state): void {
            state.available = null;
            state.meters = null;
            state.totalMeters = null;
        }
    },
});
