import { AddressViewModel } from 'common/model/meter/addressViewModel/addressViewModel';
import { ActiveTreeitemType } from '.';
import { TreeItem, TreeItemType } from '../../../../app/shared/components/tree/model';
import { MeterViewModel } from '../../../../common/model/meter/meter/meterViewModel';
import { TreeFilterModel } from './model';

const updateTreeDataItem = (item: TreeItem, id: string): TreeItem => {
    if (item.id === id) {
        if (item.type === TreeItemType.Meter && item.realId === id) {
            return {
                ...item,
                isOpened: true,
            };
        }
        return {
            ...item,
            isOpened: !item.isOpened,
        };
    }

    const newItems = item.items?.map((i: TreeItem) => updateTreeDataItem(i, id));

    if (newItems?.some(i => i.type === TreeItemType.Meter && i.realId === id)) {
        return {
            ...item,
            items: newItems,
            isOpened: true,
        };
    }

    if (item.type === TreeItemType.Meter) {
        return {
            ...item,
            isOpened: false,
        };
    }

    return {
        ...item,
        items: newItems,
        isOpened: item.isOpened || newItems?.some(i => i.isOpened),
    };
};

const closeTreeItem = (item: TreeItem): TreeItem => {
    if (item.type === TreeItemType.Meter) {
        return {
            ...item,
            isOpened: false,
        };
    }
    const newItems = item.items?.map(i => closeTreeItem(i));

    return {
        ...item,
        isOpened: false,
        items: newItems,
    };
};

export const closeAllTabsInTree = (items: TreeItem[]) => {
    return items.map(item => closeTreeItem(item));
};

export const updateTreeData = (items: TreeItem[], id: string): TreeItem[] => {
    return items.map(item => updateTreeDataItem(item, id));
};

const createInitTreeItem = (id: string, title: string, parentId: string, type: TreeItemType) => {
    const treeItem: TreeItem = { id, title: title || id, isOpened: false, parentId, realId: id, type, items: [] };
    return treeItem;
};

const meterToTreeItem = (meter: MeterViewModel, parentId: string) => {
    const meterTreeItem: TreeItem = {
        id: meter.id,
        title: `${meter.meterModel} ${meter.meterSerialNum}`,
        realId: meter.id,
        isOpened: false,
        parentId,
        type: TreeItemType.Meter,
        items: [],
        icon: !!meter?.partner?.name ? 'partnersMeterDarkIcon' : null,
        iconTooltip: meter?.partner?.name || null,
    };
    return meterTreeItem;
};

export const buildAddressTreeModified = (
    existTreeData: TreeItem[],
    addresses: AddressViewModel[],
    meters: MeterViewModel[],
    treeFilter: TreeFilterModel,
    activeTreeItem: ActiveTreeitemType): TreeItem[] => {

    const treeToModify = !activeTreeItem ? [] : [...existTreeData];

    if (!activeTreeItem) {
        for (let i = 0; i < addresses?.length; i++) {
            const address = addresses[i];
            const treeItem = createInitTreeItem(address.regionId.toString(), address.region.name, null, TreeItemType.Region);
            treeToModify.push(treeItem);
        }
        return treeToModify;
    }

    const fillTreeItem = (treeItem: TreeItem, isSearched: boolean, nestedTreeItem: TreeItem, meters: MeterViewModel[]) => {
        treeItem.isOpened = isSearched;
        if (!isSearched) {
            treeItem.items = [];
            return treeItem;
        }
        if (!nestedTreeItem.title) {
            return treeItem;
        }
        const isItemInList = treeItem.items.some(i => i.id === nestedTreeItem.id);
        if (isItemInList) {
            return treeItem;
        }
        treeItem.items.push(nestedTreeItem);

        const isMetersAdded = treeItem.items.some(item => item.type === TreeItemType.Meter);
        if (!isMetersAdded) {
            const metersTreeItem = (meters || []).map(m => meterToTreeItem(m, treeItem.id));
            treeItem.items = [...metersTreeItem, ...treeItem.items];
        }
        return treeItem;
    };
    switch (activeTreeItem) {
        case 'regionId': {
            for (let i = 0; i < addresses?.length; i++) {
                const address = addresses[i];
                const regionidStr = address.regionId.toString();
                const idxOfRegion = treeToModify.findIndex(regionItem => regionItem.id === regionidStr);
                if (idxOfRegion === -1) {
                    continue;
                }
                for (let regionId = 0; regionId < treeToModify.length; regionId++) {
                    const currentItem = treeToModify[regionId];
                    const isSearched = idxOfRegion === regionId;
                    const nestedTreeItem = createInitTreeItem(address.areaName, null, regionidStr, TreeItemType.Area);
                    treeToModify[regionId] = fillTreeItem(currentItem, isSearched, nestedTreeItem, meters);
                }
            }
            break;
        }
        case 'areaName': {
            for (let i = 0; i < addresses?.length; i++) {
                const address = addresses[i];
                const { regionId, areaName } = treeFilter;
                for (let regionId_ = 0; regionId_ < treeToModify.length; regionId_++) {
                    const regionItem = treeToModify[regionId_];
                    if (regionItem.realId.toString() !== regionId.toString()) {
                        continue;
                    }
                    for (let areaId = 0; areaId < regionItem.items.length; areaId++) {
                        const currentItem = regionItem.items[areaId];
                        const isSearched = currentItem.realId === areaName;
                        const townTreeItem = createInitTreeItem(address.townName, null, areaName, TreeItemType.Town);
                        regionItem.items[areaId] = fillTreeItem(currentItem, isSearched, townTreeItem, meters);
                    }
                }
            }
            break;
        }
        case 'townName': {
            const { regionId, areaName, townName } = treeFilter;
            for (let i = 0; i < addresses?.length; i++) {
                const address = addresses[i];
                for (let regionId_ = 0; regionId_ < treeToModify.length; regionId_++) {
                    const regionItem = treeToModify[regionId_];
                    const regionItemIsSearched = regionItem.realId.toString() === regionId.toString();
                    if (!regionItemIsSearched) {
                        continue;
                    }
                    for (let areaId = 0; areaId < regionItem.items.length; areaId++) {
                        const areaItem = regionItem.items[areaId];
                        const isAreaIsSearched = areaItem.realId === areaName;
                        if (!isAreaIsSearched) {
                            continue;
                        }
                        for (let townId = 0; townId < areaItem.items.length; townId++) {
                            const currentItem = areaItem.items[townId];
                            const isSearched = currentItem.realId === treeFilter.townName;
                            const nestedTreeItem = createInitTreeItem(address.streetName, null, townName, TreeItemType.Street);
                            areaItem.items[townId] = fillTreeItem(currentItem, isSearched, nestedTreeItem, meters);
                        }
                    }
                }
            }
            break;
        }
        case 'streetName': {
            const { regionId, areaName, townName, streetName } = treeFilter;
            for (let i = 0; i < addresses?.length; i++) {
                const address = addresses[i];
                for (let regionId_ = 0; regionId_ < treeToModify.length; regionId_++) {
                    const regionItem = treeToModify[regionId_];
                    const regionItemIsSearched = regionItem.realId.toString() === regionId.toString();
                    if (!regionItemIsSearched) {
                        continue;
                    }
                    for (let areaId = 0; areaId < regionItem.items.length; areaId++) {
                        const areaItem = regionItem.items[areaId];
                        const isAreaIsSearched = areaItem.realId === areaName;
                        if (!isAreaIsSearched) {
                            continue;
                        }
                        for (let townId = 0; townId < areaItem.items.length; townId++) {
                            const townItem = areaItem.items[townId];
                            const isTownIsSearched = townItem.realId === townName;
                            townItem.isOpened = isTownIsSearched;
                            // eslint-disable-next-line max-depth
                            if (!isTownIsSearched) {
                                continue;
                            }
                            // eslint-disable-next-line max-depth
                            for (let streetId = 0; streetId < townItem.items.length; streetId++) {
                                const streetItem = townItem.items[streetId];
                                const isSearched = streetItem.realId === streetName;
                                const houseTreeItem = createInitTreeItem(address.houseNumber, null, streetName, TreeItemType.House);
                                townItem.items[streetId] = fillTreeItem(streetItem, isSearched, houseTreeItem, meters);
                            }
                        }
                    }
                }
            }
            break;
        }
        case 'houseNumber': {
            const { regionId, areaName, townName, streetName, houseNumber } = treeFilter;
            for (let regionId_ = 0; regionId_ < treeToModify.length; regionId_++) {
                const regionItem = treeToModify[regionId_];
                const regionItemIsSearched = regionItem.realId.toString() === regionId.toString();
                if (!regionItemIsSearched) {
                    continue;
                }
                for (let areaId = 0; areaId < regionItem.items.length; areaId++) {
                    const areaItem = regionItem.items[areaId];
                    const isAreaIsSearched = areaItem.realId === areaName;
                    if (!isAreaIsSearched) {
                        continue;
                    }
                    for (let townId = 0; townId < areaItem.items.length; townId++) {
                        const townItem = areaItem.items[townId];
                        const isTownIsSearched = townItem.realId === townName;
                        if (!isTownIsSearched) {
                            continue;
                        }
                        for (let streetId = 0; streetId < townItem.items.length; streetId++) {
                            const streetItem = townItem.items[streetId];
                            const isStreetIsSearched = streetItem.realId === streetName;
                            // eslint-disable-next-line max-depth
                            if (!isStreetIsSearched) {
                                continue;
                            }
                            // eslint-disable-next-line max-depth
                            for (let houseId = 0; houseId < streetItem.items.length; houseId++) {
                                const houseItem = streetItem.items[houseId];
                                const isSearchedHouse = houseItem.realId === houseNumber;
                                houseItem.isOpened = isSearchedHouse;
                                // eslint-disable-next-line max-depth
                                if (!isSearchedHouse) {
                                    houseItem.items = [];
                                    continue;
                                }
                                houseItem.items = meters.map(meter => meterToTreeItem(meter, houseNumber));
                            }
                        }
                    }
                }
            }
            break;
        }
        default:
            break;
    }
    return treeToModify;
};
