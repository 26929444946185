import yup from '../../../shared/utils/yup';
import { SurveyConditionFormQueryParams } from './model';

const surveyConditionQueryParamsValidationSchema = yup.object({
    regionId: yup
        .number()
        .required('Необходимо выбрать регион'),
    daysOffline: yup
        .number()
        .min(0)
        .required('Необходимо задать параметр "Нет показаний, дней"'),
});

export const validateSurveyConditionQueryParams = (params: SurveyConditionFormQueryParams) => {
    return surveyConditionQueryParamsValidationSchema.validate(params);
};
