import { Column } from '@softmedialab/materialui-table';
import {
    AdvancedCrashReportRow,
    AdvancedCrashReportRowReading,
    AdvancedCrashReportRowReadingProblems
} from '../../../../common/model/meter/reports/advancedCrashReportRow';
import { ColumnArrayView } from './components/columnArrayView';
import { AdvancedCrashReportTabs } from './model';

export const columnsTitles = {
    index: '№',
    shortAddress: 'Район, город',
    fullAddress: 'Точка учета',
    meterSerialNum: 'Заводской номер',
    lastReadingDate: 'Дата последних показаний',
    lastReadingValue: 'Значение энергии (А+), кВт*час',
    readingDate: 'Дата, время последних показаний',
    tasksCount: 'Кол-во опросов / кол-во успешных опросов',
    readingDates: 'Дата и время опроса',
    readingResults: 'Результат опроса',
    readingProblems: 'Ошибки',
    readingLastSuccessDate: 'Дата, время последнего успешного считывания',
    meterFirmwareVersion: 'Версия прошивки ПУ',
    criticalProblems: 'Критические события в журналах',
};

const makeReadingDateColumn = (field: keyof AdvancedCrashReportRow): Column => {
    return {
        title: columnsTitles.readingDate,
        cellClassName: 'cell-readingDate',
        sorting: false,
        field: `${field}.readingDate`,
    };
};

const makeLastSuccessDateColumn = (field: keyof AdvancedCrashReportRow): Column => {
    return {
        title: columnsTitles.readingLastSuccessDate,
        cellClassName: 'cell-readingLastSuccessDate',
        sorting: false,
        field: `${field}.lastSuccessDate`,
    };
};

const makeMeterFirmwareVersionColumn = (field: keyof AdvancedCrashReportRow): Column => {
    return {
        title: columnsTitles.meterFirmwareVersion,
        cellClassName: 'cell-meterFirmwareVersion',
        sorting: false,
        field: `${field}.meterFirmwareVersion`,
    };
};

type ExtractSpecificPropertyNames<T, S> = {
    [K in keyof T]: T[K] extends S ? K : never
}[keyof T]

const makeTasksCountColumn = (field: keyof AdvancedCrashReportRow): Column => {
    return {
        title: columnsTitles.tasksCount,
        cellClassName: 'cell-tasksCount',
        sorting: false,
        field: `${field}.tasksCount`,
    };
};

const makeReadingArrayColumn = (
    field: ExtractSpecificPropertyNames<AdvancedCrashReportRow, AdvancedCrashReportRowReading>,
    subField: ExtractSpecificPropertyNames<AdvancedCrashReportRowReading, string[]>
): Column => {
    return {
        title: columnsTitles[subField],
        cellClassName: `cell-${subField}`,
        sorting: false,
        field: `${field}.${subField}`,
        render: (item: AdvancedCrashReportRow) => {
            return ColumnArrayView({
                data: item[field][subField],
            });
        },
    };
};

const makeReadingProblemsColumn = (
    field: ExtractSpecificPropertyNames<AdvancedCrashReportRow, AdvancedCrashReportRowReadingProblems>,
    subField: ExtractSpecificPropertyNames<AdvancedCrashReportRowReadingProblems, string[]>
): Column => {
    return {
        title: columnsTitles[subField],
        cellClassName: `cell-${subField}`,
        sorting: false,
        field: `${field}.${subField}`,
        render: (item: AdvancedCrashReportRow) => {
            return ColumnArrayView({
                data: item[field][subField],
            });
        },
    };
};

const makeCriticalProblemsColumn = (): Column => {
    return {
        title: columnsTitles.criticalProblems,
        cellClassName: 'cell-criticalProblems',
        sorting: false,
        field: 'criticalEventsProblems',
        render: (item: AdvancedCrashReportRow) => {
            return ColumnArrayView({
                data: item.criticalEventsProblems,
            });
        },
    };
};

export const getExtraColumnsByTab = (tab: AdvancedCrashReportTabs): Column[] => {
    switch (tab) {
        case AdvancedCrashReportTabs.DailyProfileReadings: {
            return [
                makeReadingDateColumn('dailyProfile'),
                makeTasksCountColumn('dailyProfile'),
                makeReadingArrayColumn('dailyProfile', 'readingDates'),
                makeReadingArrayColumn('dailyProfile', 'readingResults'),
                makeReadingProblemsColumn('dailyProfile', 'readingProblems'),
            ];
        }
        case AdvancedCrashReportTabs.CurrentProfileReadings: {
            return [
                makeReadingDateColumn('currentProfile'),
                makeTasksCountColumn('currentProfile'),
                makeReadingArrayColumn('currentProfile', 'readingDates'),
                makeReadingArrayColumn('currentProfile', 'readingResults'),
                makeReadingProblemsColumn('currentProfile', 'readingProblems'),
            ];
        }
        case AdvancedCrashReportTabs.IncrementalProfileReadings: {
            return [
                makeLastSuccessDateColumn('incrementalProfile'),
                makeReadingDateColumn('incrementalProfile'),
                makeTasksCountColumn('incrementalProfile'),
                makeReadingArrayColumn('incrementalProfile', 'readingDates'),
                makeReadingArrayColumn('incrementalProfile', 'readingResults'),
                makeReadingProblemsColumn('incrementalProfile', 'readingProblems'),
            ];
        }
        case AdvancedCrashReportTabs.ConfigurationSnapshot: {
            return [
                makeLastSuccessDateColumn('configurationSnapshot'),
                makeMeterFirmwareVersionColumn('configurationSnapshot'),
                makeTasksCountColumn('configurationSnapshot'),
                makeReadingArrayColumn('configurationSnapshot', 'readingDates'),
                makeReadingArrayColumn('configurationSnapshot', 'readingResults'),
                makeReadingProblemsColumn('configurationSnapshot', 'readingProblems')
            ];
        }
        case AdvancedCrashReportTabs.ValidateOrChangeTariff: {
            return [
                makeLastSuccessDateColumn('validateOrChangeTariff'),
                makeTasksCountColumn('validateOrChangeTariff'),
                makeReadingArrayColumn('validateOrChangeTariff', 'readingDates'),
                makeReadingArrayColumn('validateOrChangeTariff', 'readingResults'),
                makeReadingProblemsColumn('validateOrChangeTariff', 'readingProblems')
            ];
        }
        case AdvancedCrashReportTabs.ElectricPowerQualityEvents: {
            return [
                makeLastSuccessDateColumn('electricPowerQualityEvents'),
                makeTasksCountColumn('electricPowerQualityEvents'),
                makeReadingArrayColumn('electricPowerQualityEvents', 'readingDates'),
                makeReadingArrayColumn('electricPowerQualityEvents', 'readingResults'),
                makeCriticalProblemsColumn(),
            ];
        }
        case AdvancedCrashReportTabs.PowerSwitchEvents: {
            return [
                makeLastSuccessDateColumn('powerSwitchEvents'),
                makeTasksCountColumn('powerSwitchEvents'),
                makeReadingArrayColumn('powerSwitchEvents', 'readingDates'),
                makeReadingArrayColumn('powerSwitchEvents', 'readingResults'),
                makeCriticalProblemsColumn(),
            ];
        }
        case AdvancedCrashReportTabs.DataCorrectionEvents: {
            return [
                makeLastSuccessDateColumn('dataCorrectionEvents'),
                makeTasksCountColumn('dataCorrectionEvents'),
                makeReadingArrayColumn('dataCorrectionEvents', 'readingDates'),
                makeReadingArrayColumn('dataCorrectionEvents', 'readingResults'),
                makeCriticalProblemsColumn(),
            ];
        }
        case AdvancedCrashReportTabs.TamperingEvents: {
            return [
                makeLastSuccessDateColumn('tamperingEvents'),
                makeTasksCountColumn('tamperingEvents'),
                makeReadingArrayColumn('tamperingEvents', 'readingDates'),
                makeReadingArrayColumn('tamperingEvents', 'readingResults'),
                makeCriticalProblemsColumn(),
            ];
        }
        case AdvancedCrashReportTabs.AccessControlEvents: {
            return [
                makeLastSuccessDateColumn('accessControlEvents'),
                makeTasksCountColumn('accessControlEvents'),
                makeReadingArrayColumn('accessControlEvents', 'readingDates'),
                makeReadingArrayColumn('accessControlEvents', 'readingResults'),
                makeCriticalProblemsColumn(),
            ];
        }
        case AdvancedCrashReportTabs.VoltageEvents: {
            return [
                makeLastSuccessDateColumn('voltageEvents'),
                makeTasksCountColumn('voltageEvents'),
                makeReadingArrayColumn('voltageEvents', 'readingDates'),
                makeReadingArrayColumn('voltageEvents', 'readingResults'),
                makeCriticalProblemsColumn(),
            ];
        }
        case AdvancedCrashReportTabs.CurrentEvents: {
            return [
                makeLastSuccessDateColumn('currentEvents'),
                makeTasksCountColumn('currentEvents'),
                makeReadingArrayColumn('currentEvents', 'readingDates'),
                makeReadingArrayColumn('currentEvents', 'readingResults'),
                makeCriticalProblemsColumn(),
            ];
        }
        case AdvancedCrashReportTabs.SelfDiagnosticEvents: {
            return [
                makeLastSuccessDateColumn('selfDiagnosticEvents'),
                makeTasksCountColumn('selfDiagnosticEvents'),
                makeReadingArrayColumn('selfDiagnosticEvents', 'readingDates'),
                makeReadingArrayColumn('selfDiagnosticEvents', 'readingResults'),
                makeCriticalProblemsColumn(),
            ];
        }
        default: {
            return [];
        }
    }
};
