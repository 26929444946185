import { useState } from 'react';
import { TwoSideMultiselectProps } from './model';
import { View } from './view';

export const TwoSideMultiselect = (props: TwoSideMultiselectProps) => {
    const { options = [], value = [], onChange } = props;
    const [leftSelectedIndex, setLeftSelectedIndex] = useState<number>(0);
    const [rightSelectedIndex, setRightSelectedIndex] = useState<number>(0);

    const selectedOptions = options.filter(option => value.includes(option.value));
    const selectableOptions = options.filter(option => !value.includes(option.value));

    const rightSelectedValue = selectedOptions[rightSelectedIndex];
    const leftSelectedValue = selectableOptions[leftSelectedIndex];

    const onClickMoveLeft = () => {
        const selectedValue = rightSelectedValue;
        if (!selectedValue) {
            return;
        }
        const val = selectedValue.value;
        onChange(value.filter(v => v !== val));
    };

    const onClickMoveRight = () => {
        const selectedValue = leftSelectedValue;
        if (!selectedValue) {
            return;
        }
        onChange([
            ...value,
            selectedValue.value,
        ]);
    };

    const onClickMoveLeftForce = () => {
        onChange([]);
    };

    const onClickMoveRightForce = () => {
        onChange(options.map(item => item.value));
    };

    return View({
        ...props,
        leftSelectedIndex,
        rightSelectedIndex,
        onClickMoveLeft,
        onClickMoveRight,
        onClickMoveLeftForce,
        onClickMoveRightForce,
        selectedOptions,
        selectableOptions,
        rightSelectedValue,
        leftSelectedValue,
        onClickLeftOption: (index) => {
            setLeftSelectedIndex(index);
        },
        onClickRightOption: (index) => {
            setRightSelectedIndex(index);
        },
    });
};
