import { combineReducers } from 'redux';
import { advancedCrashReportSlice, AdvancedCrashReportState } from './advancedCrashReport';
import { configurationHistoryReportSlice, ConfigurationHistoryReportState } from './configurationHistoryReport';
import { crashByRegionsReportSlice, CrashByRegionsReportState } from './crashByRegionsReport';
import { electricityConsumptionReportSlice, ElectricityConsumptionReportState } from './electricityConsumptionReport';
import { electricityTariffReportSlice, ElectricityTariffReportState } from './electricityTariffReport';
import { meterProfileReportSlice, MeterProfileReportState } from './meterProfileReport';
import { notConnectedMetersReportSlice, NotConnectedMetersReportState } from './notConnectedMetersReport';
import { ElectricityConsumptionBillingReportState, electricityConsumptionBillingReportSlice } from './electricityConsumptionBillingReport';


export interface ReportsState {
    notConnectedMetersReport: NotConnectedMetersReportState;
    meterProfileReport: MeterProfileReportState;
    electricityConsumptionReport: ElectricityConsumptionReportState;
    electricityTariffReport: ElectricityTariffReportState;
    crashByRegionsReport: CrashByRegionsReportState;
    configurationHistoryReport: ConfigurationHistoryReportState;
    electricityConsumptionBillingReport: ElectricityConsumptionBillingReportState;
    advancedCrashReport: AdvancedCrashReportState;

}

export const reports = combineReducers<ReportsState>({
    notConnectedMetersReport: notConnectedMetersReportSlice.reducer,
    meterProfileReport: meterProfileReportSlice.reducer,
    electricityConsumptionReport: electricityConsumptionReportSlice.reducer,
    electricityTariffReport: electricityTariffReportSlice.reducer,
    crashByRegionsReport: crashByRegionsReportSlice.reducer,
    configurationHistoryReport: configurationHistoryReportSlice.reducer,
    electricityConsumptionBillingReport: electricityConsumptionBillingReportSlice.reducer,
    advancedCrashReport: advancedCrashReportSlice.reducer,
});
