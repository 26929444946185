import { MeterReadingDailyProfileViewModel } from '../../../../../common/model/meter/meter/meterReadingDailyProfileViewModel';
import { getCurrentDate, getDateMonthBefore } from '../../../../../app/shared/utils/dates';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterReadingDailyProfileFilter } from '../../../../../common/model/meter/meter/meterReadingDailyProfileFilter';


export interface DailyProfilesChartState {
    filter: MeterReadingDailyProfileFilter;
    available: MeterReadingDailyProfileViewModel[];
}

const initialState: DailyProfilesChartState = {
    filter: {
        dateFrom: getDateMonthBefore(),
        dateTo: getCurrentDate(),
    },
    available: [],
};

export const dailyProfilesChartSlice = createSlice({
    name: '@@vv/meterReading/dailyProfile/chart',
    initialState,
    reducers: {
        setAvailableDailyProfiles(state, action: PayloadAction<MeterReadingDailyProfileViewModel[]>) {
            state.available = action.payload;
        },
        setFilter(state, action: PayloadAction<MeterReadingDailyProfileFilter>) {
            state.filter = action.payload;
        },
        clearFilter(state) {
            state.filter = initialState.filter;
        }
    }
});
