import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { AddressCreator } from '../../../common/model/meter/addressCreator/addressCreator';
import { AddressViewModel } from '../../../common/model/meter/addressViewModel/addressViewModel';
import { addressSuggestionsSlice } from '../../reducers/addresses/addressSuggestions';
import { AppThunk } from '../index';


export const findAddressSuggestions = (search?: string, regionId?: number): AppThunk => async (dispatch) => {
    dispatch(addressSuggestionsSlice.actions.setAddressSuggestionLoading());
    const route = getApiRoute(appUrls.api_find_addresses, {}, { search, regionId });
    const addresses = await httpService.get<AddressViewModel[]>(route);
    dispatch(addressSuggestionsSlice.actions.setAddressSuggestions(addresses));
};

export const findAreaNamesSuggestions = (search?: string): AppThunk => async (dispatch) => {
    dispatch(addressSuggestionsSlice.actions.setAddressSuggestionLoading());
    const route = getApiRoute(appUrls.api_find_area_names, {}, { search });
    const data = await httpService.get<string[]>(route);
    dispatch(addressSuggestionsSlice.actions.setAreaNamesSuggestions(data));
};

export const findTownNamesSuggestions = (search?: string): AppThunk => async (dispatch) => {
    dispatch(addressSuggestionsSlice.actions.setAddressSuggestionLoading());
    const route = getApiRoute(appUrls.api_find_town_names, {}, { search });
    const data = await httpService.get<string[]>(route);
    dispatch(addressSuggestionsSlice.actions.setTownNamesSuggestions(data));
};

export const findStreetNamesSuggestions = (search?: string): AppThunk => async (dispatch) => {
    dispatch(addressSuggestionsSlice.actions.setAddressSuggestionLoading());
    const route = getApiRoute(appUrls.api_find_street_names, {}, { search });
    const data = await httpService.get<string[]>(route);
    dispatch(addressSuggestionsSlice.actions.setStreetNamesSuggestions(data));
};

export const findHouseNumbersSuggestions = (search?: string): AppThunk => async (dispatch) => {
    dispatch(addressSuggestionsSlice.actions.setAddressSuggestionLoading());
    const route = getApiRoute(appUrls.api_find_house_numbers, {}, { search });
    const data = await httpService.get<string[]>(route);
    dispatch(addressSuggestionsSlice.actions.setHouseNumbersSuggestions(data));
};

export const createAddress = (addressCreator: AddressCreator): AppThunk => (): Promise<AddressViewModel> => {
    const route = getApiRoute(appUrls.api_post_addresses);
    return httpService.post<AddressViewModel>(route, addressCreator);
};
