export enum LogEntryOperation {
    ApiUndefined = 0,
    ApiLogin = 1,
    ApiLoginCallback = 2,
    ApiLogout = 3,
    ApiLogoutCallback = 4,
    ApiGetCurrentUser = 5,
    ApiGetResources = 6,
    ApiGetResourcesVersion = 7,
    ApiGetCacheClear = 8,
    ApiGetSettings = 9,
    ApiPostSettings = 10,
    ApiGetUsers = 11,
    ApiGetUser = 12,
    ApiCreateUser = 13,
    ApiUpdateUser = 14,
    ApiDeleteUser = 15,
    ApiFindAddresses = 16,
    ApiFindAreaNames = 17,
    ApiFindTownNames = 18,
    ApiFindStreetNames = 19,
    ApiFindHouseNumbers = 20,
    ApiPostMetersRepeatTasks = 21,
    ApiGetMeters = 22,
    ApiSearchMeters = 23,
    ApiGetMeter = 24,
    ApiGetMeterCommunicatorInformation = 25,
    ApiGetMeterConsumptionElectricity = 26,
    ApiGetMeterConsumptionElectricityDownloadExcel = 27,
    ApiGetMeterConsumptionElectricityTariff = 28,
    ApiGetMeterConsumptionElectricityTariffDownloadExcel = 29,
    ApiGetMeterLatestProfile = 30,
    ApiGetMeterGis = 31,
    ApiGetMeterLabels = 32,
    ApiGetMeterConfigurationHistory = 33,
    ApiGetMeterPowerSwitch = 34,
    ApiPutMeterPowerSwitch = 35,
    ApiPutMeterPowerSwitchApproval = 36,
    ApiFindMetersReadingDailyList = 37,
    ApiFindMetersReadingDailyChart = 38,
    ApiFindMetersReadingIncrementalList = 39,
    ApiFindMetersReadingIncrementalChart = 40,
    ApiPostMeters = 41,
    ApiPutMeters = 42,
    ApiPutMeterCommunicationParams = 43,
    ApiPutMeterReplacingCommunicator = 44,
    ApiPutMeterTransformationRatios = 45,
    ApiPutMeterVerification = 46,
    ApiPutMeterInformationFields = 47,
    ApiPutMeterAddress = 48,
    ApiPutMeterStatusToActive = 49,
    ApiPutMeterStatusToDecommissioned  = 50,
    ApiDeleteMeters = 51,
    ApiGetPartners = 52,
    ApiGetPartner = 53,
    ApiPostPartner = 54,
    ApiPutPartner = 55,
    ApiGetPartnerMeters = 56,
    ApiPostPartnerMeters = 57,
    ApiGetMeterPowerSwitchOrders = 58,
    ApiGetMeterPowerSwitchOrder = 59,
    ApiGetExport8002080040 = 60,
    ApiPostImport8002080040 = 61,
    ApiGetMonitoringSurveyCondition = 62,
    ApiGetMeterGroups = 63,
    ApiGetDynamicMeterGroupParams = 64,
    ApiPostDynamicMeterGroupParams = 65,
    ApiPutDynamicMeterGroupParams = 66,
    ApiDeleteDynamicMeterGroupParams = 67,
    ApiDeleteMeterGroup = 68,
    ApiCreateMeterGroups = 69,
    ApiCreateStaticMeterGroupContent = 70,
    ApiDeleteStaticMeterGroupContent = 71,
    ApiGetStaticMeterGroupContent = 72,
    ApiPutMeterGroup = 73,
    ApiFindSchedules = 74,
    ApiGetSchedule = 75,
    ApiCreateSchedule = 76,
    ApiUpdateSchedule = 77,
    ApiDeleteSchedule = 78,
    ApiGetMeterActionSchemes = 79,
    ApiPostMeterActionScheme = 80,
    ApiPutMeterActionScheme = 81,
    ApiFindEventLogs = 82,
    ApiDownloadEventLogsExcel = 83,
    ApiFindHighCriticalLevelEventLogs = 84,
    ApiReportsNotConnectedMeters = 85,
    ApiReportsMeterProfile = 86,
    ApiReportsElectricityConsumption = 87,
    ApiReportsElectricityTariff = 88,
    ApiReportsCrashByRegions = 89,
    ApiReportsConfigurationHistory = 90,
    ApiReportsElectricityConsumptionBilling = 91,
    ApiGetMeterActivationOrder = 92,
    ApiFindMeterActivationOrders = 93,
    ApiFindMeterActivationOrderSerialNums = 94,
    ApiPostMeterActivationOrder = 95,
    ApiReprocessMeterActivationOrder = 96,
    ApiReprocessMeterActivationOrders = 97,
    ApiUpdateAndReprocessMeterActivationOrder = 98,
    ApiDeleteMeterActivationOrder = 99,
    ApiGetMeterActionPlan = 100,
    ApiPostMeterActionPlanSync = 101,
    ApiExternalPostMeterActivationOrders = 102,
    ApiExternalGetMeterActivationOrderById = 103,
    ApiExternalGetMeterActivationOrderByForeignId = 104,
    ApiExternalMeterActivationOrderFromExcel = 105,
    ApiExternalPostMeterPowerSwitchRequests = 106,
    ApiExternalGetMeterPowerSwitchRequests = 107,
    ApiTestAccess = 108,
    ApiGetAccessAccessRules = 109,
    ApiGetAccessRoles = 110,
    ApiPostAccessRoles = 111,
    ApiPutAccessRoles = 112,
    ApiDeleteAccessRoles = 113,
    ApiGetAccessRolesAccessRules = 114,
    ApiPutAccessRolesAccessRules = 115,
    ApiGetAccessUsersAccessRules = 116,
    ApiPutAccessUsersAccessRules = 117,
    ApiGetMeterActiveEnergyReadingData = 118,
    ApiGetMeterIncrementalEnergyReadingData = 119,
    ApiPostMeterCurrentReadingOrders = 120,
    ApiGetMeterCurrentReadingOrders = 121,
    ApiPostMeterGisTask = 122,
    ApiGetMeterGisTasks = 123,
    ApiPutMeterGisTask = 124,
    ApiGetMeterGisTask = 125,
    /** @deprecated */
    UpdateModelPlan = 126,
    UpdateModelScheme = 127,
    ApiGetSystemLogToken = 128,
    TokenNotFound = 129,
    TokenExpired = 130,
    InvalidToken = 131,
    UserNotFound = 132,
    ExternalSystem = 133,
    ApiReadTimeSynchronizationSchemes = 134,
    ApiCreateTimeSynchronizationScheme = 135,
    ApiUpdateTimeSynchronizationScheme = 136,
    ApiDeleteTimeSynchronizationScheme = 137,
    ApiPutMeterSyncSchemes = 138,
    ApiGetMeterModels = 139,
    ApiPutMeterModels = 140,
    ApiPostAddresses = 141,
    ApiGetTariffSchedules = 142,
    ApiPostTariffSchedules = 143,
    ApiPostTariffScheduleVersion = 144,
    ApiPutTariffScheduleVersion = 145,
    ApiGetTariffScheduleVersion = 146,
    ApiGetTariffScheduleOrders = 147,
    ApiGetSpecialDayBase = 148,
    ApiPostSpecialDayBase = 149,
    ApiPutSpecialDayBase = 150,
    ApiPutMeterMeterTariffSchedule = 151,
    ApiGetMeterMeterTariffScheduleOrder = 152,
    ApiGetDynamicMeterGroupAddresses = 153,
    ApiPostDynamicMeterGroupAddresses = 154,
    ApiPutDynamicMeterGroupAddresses = 155,
    ApiDeleteDynamicMeterGroupAddresses = 156,
    ApiUpdateMeterLabelType = 157,
    ApiPostCrqSystem = 158,
    ApiPutCrqSystem = 159,
    ApiDeleteCrqSystem = 160,
    ApiPostMeterCrqChannels = 161,
    ApiPutEventType = 162,
    ApiExternalPostMeterDeactivation = 163,
    ApiGetMassOperations = 164,
    ApiGetMassOperation = 165,
    ApiCreateMassOperation = 166,
    ApiReportsAdvancedCrashReport = 167,
    ApiReportsAdvancedCrashReportExcel = 168,
    ApiPostMeterMeterTariffScheduleRewrite = 169,
    ApiPostPartnerCrqMetersFromExcel = 170,
    ApiGetMeterFirmwareVersion = 171,
    ApiCreateMeterFirmwareVersion = 172,
    ApiUpdateMeterFirmwareVersion = 173,
    ApiDeleteMeterFirmwareVersion = 174,
    ApiGetMeterPassword = 175,
    ApiGetNetworkOperators = 176,
    ApiPostNetworkOperator = 177,
    ApiPutNetworkOperator = 178,
    ApiDeleteNetworkOperator = 179,
    ApiPostNetworkOperatorCheck = 180,
    ApiPutMeterTechnicalParams = 181,
    ApiGetMeterTechnicalParamsOrder = 182,
    ApiGetMeterActionSchemeChangeRequest = 183,
    ApiPostMeterChangePasswordOrder = 184,
    ApiGetMeterChangePasswordOrder = 185,
    ApiGetMeterChangePasswordRequest = 186,
    ApiPostMeterChangePasswordRequest = 187,
    ApiPutMeterPasswordFields = 188,
    ApiGetMeterChangePasswordLatestTaskByMeterId = 189
}

export const logEntryOperationDictionary: Record<LogEntryOperation, string> = {
    [LogEntryOperation.ApiUndefined]: 'Неизвестный API',
    [LogEntryOperation.ApiLogin]: 'Доступ к авторизации',
    [LogEntryOperation.ApiLoginCallback]: 'Успешная авторизация',
    [LogEntryOperation.ApiLogout]: 'Logout',
    [LogEntryOperation.ApiLogoutCallback]: 'Успешный Logout',
    [LogEntryOperation.ApiGetCurrentUser]: 'Профиль пользователя',
    [LogEntryOperation.ApiGetResources]: 'Ресурсы пользователя',
    [LogEntryOperation.ApiGetResourcesVersion]: 'Версия ресурсов пользователя',
    [LogEntryOperation.ApiGetCacheClear]: 'Очистка redis кэш',
    [LogEntryOperation.ApiGetSettings]: 'Доступ к Настройкам',
    [LogEntryOperation.ApiPostSettings]: 'Доступ к изменению настроек',
    [LogEntryOperation.ApiGetUsers]: 'Доступ к списку пользователей',
    [LogEntryOperation.ApiGetUser]: 'Доступ к конкретному пользователю',
    [LogEntryOperation.ApiCreateUser]: 'Доступ к созданию пользователя',
    [LogEntryOperation.ApiUpdateUser]: 'Доступ к изменению пользователя',
    [LogEntryOperation.ApiDeleteUser]: 'Доступ к удалению пользователя',
    [LogEntryOperation.ApiFindAddresses]: 'Доступ к списку адресов',
    [LogEntryOperation.ApiFindAreaNames]: 'Доступ к списку областей',
    [LogEntryOperation.ApiFindTownNames]: 'Доступ к списку городов',
    [LogEntryOperation.ApiFindStreetNames]: 'Доступ к списку улиц',
    [LogEntryOperation.ApiFindHouseNumbers]: 'Доступ к списку домов',
    [LogEntryOperation.ApiPostMetersRepeatTasks]: 'Доступ к повтору заданий',
    [LogEntryOperation.ApiGetMeters]: 'Доступ к списку ПУ',
    [LogEntryOperation.ApiSearchMeters]: 'Доступ к поиску ПУ',
    [LogEntryOperation.ApiGetMeter]: 'Доступ к конкретному ПУ',
    [LogEntryOperation.ApiGetMeterCommunicatorInformation]: 'Доступ к информации о коммуникаторе ПУ',
    [LogEntryOperation.ApiGetMeterConsumptionElectricity]: 'Доступ к потреблению ПУ',
    [LogEntryOperation.ApiGetMeterConsumptionElectricityDownloadExcel]: 'Доступ к скачиванию отчета по потреблению ПУ',
    [LogEntryOperation.ApiGetMeterConsumptionElectricityTariff]: 'Доступ к потреблению по тарифам ПУ',
    [LogEntryOperation.ApiGetMeterConsumptionElectricityTariffDownloadExcel]: 'Доступ к скачиванию отчета по потребления по тарифам ПУ',
    [LogEntryOperation.ApiGetMeterLatestProfile]: 'Доступ к последним показаниям ПУ',
    [LogEntryOperation.ApiGetMeterGis]: 'Доступ к GIS ПУ',
    [LogEntryOperation.ApiGetMeterLabels]: 'Доступ к лейблам ПУ',
    [LogEntryOperation.ApiGetMeterConfigurationHistory]: 'Доступ к истории конфигурации ПУ',
    [LogEntryOperation.ApiGetMeterPowerSwitch]: 'Доступ к состоянию реле ПУ',
    [LogEntryOperation.ApiPutMeterPowerSwitch]: 'Доступ к изменению состояния реле ПУ',
    [LogEntryOperation.ApiPutMeterPowerSwitchApproval]: 'Доступ к подтверждению заявки на ограничение потребления',
    [LogEntryOperation.ApiFindMetersReadingDailyList]: 'Доступ к суточным показаниям ПУ',
    [LogEntryOperation.ApiFindMetersReadingDailyChart]: 'Доступ к графику суточных показаний ПУ',
    [LogEntryOperation.ApiFindMetersReadingIncrementalList]: 'Доступ к получасовкам ПУ',
    [LogEntryOperation.ApiFindMetersReadingIncrementalChart]: 'Доступ к графику получасовок ПУ',
    [LogEntryOperation.ApiPostMeters]: 'Доступ к добавлению ПУ',
    [LogEntryOperation.ApiPutMeters]: 'Доступ к изменению ПУ',
    [LogEntryOperation.ApiPutMeterCommunicationParams]: 'Доступ к изменению параметров связи ПУ',
    [LogEntryOperation.ApiPutMeterReplacingCommunicator]: 'Доступ к замене коммуникатора ПУ',
    [LogEntryOperation.ApiPutMeterTransformationRatios]: 'Доступ к изменению параметров трансформации ПУ',
    [LogEntryOperation.ApiPutMeterVerification]: 'Доступ к изменению верификации ПУ',
    [LogEntryOperation.ApiPutMeterInformationFields]: 'Доступ к изменению информационных полей ПУ',
    [LogEntryOperation.ApiPutMeterAddress]: 'Доступ к изменению адреса ПУ',
    [LogEntryOperation.ApiPutMeterStatusToActive]: 'Доступ к изменению статуса ПУ на Активен',
    [LogEntryOperation.ApiPutMeterStatusToDecommissioned]: 'Доступ к изменению статуса ПУ на Выведен из эксплуатации',
    [LogEntryOperation.ApiDeleteMeters]: 'Доступ к удалению ПУ',
    [LogEntryOperation.ApiGetPartners]: 'Доступ к списку партнеров',
    [LogEntryOperation.ApiGetPartner]: 'Доступ к конкретному партнеру',
    [LogEntryOperation.ApiPostPartner]: 'Доступ к добавлению партнера',
    [LogEntryOperation.ApiPutPartner]: 'Доступ к изменению партнера',
    [LogEntryOperation.ApiGetPartnerMeters]: 'Доступ к счетчикам партнера',
    [LogEntryOperation.ApiPostPartnerMeters]: 'Доступ к добавлению счетчиков партнера',
    [LogEntryOperation.ApiGetMeterPowerSwitchOrders]: 'Доступ к списку заявок на переключение реле',
    [LogEntryOperation.ApiGetMeterPowerSwitchOrder]: 'Доступ к заявке на переключение реле',
    [LogEntryOperation.ApiGetExport8002080040]: 'Доступ к экспорту',
    [LogEntryOperation.ApiPostImport8002080040]: 'Доступ к импорту',
    [LogEntryOperation.ApiGetMonitoringSurveyCondition]: 'Доступ к мониторингу',
    [LogEntryOperation.ApiGetMeterGroups]: 'Доступ к группам счетчиков',
    [LogEntryOperation.ApiGetDynamicMeterGroupParams]: 'Доступ к параметрам динамических групп',
    [LogEntryOperation.ApiPostDynamicMeterGroupParams]: 'Доступ к добавлению параметров динамических групп',
    [LogEntryOperation.ApiPutDynamicMeterGroupParams]: 'Доступ к изменению параметров динамических групп',
    [LogEntryOperation.ApiDeleteDynamicMeterGroupParams]: 'Доступ к удалению параметров динамических групп',
    [LogEntryOperation.ApiDeleteMeterGroup]: 'Доступ к удалению групп',
    [LogEntryOperation.ApiCreateMeterGroups]: 'Доступ к добавлению групп',
    [LogEntryOperation.ApiCreateStaticMeterGroupContent]: 'Доступ к добавлению статических групп',
    [LogEntryOperation.ApiDeleteStaticMeterGroupContent]: 'Доступ к удалению статических групп',
    [LogEntryOperation.ApiGetStaticMeterGroupContent]: 'Доступ к статическим группам',
    [LogEntryOperation.ApiPutMeterGroup]: 'Доступ к группам',
    [LogEntryOperation.ApiFindSchedules]: 'Доступ к списку расписания',
    [LogEntryOperation.ApiGetSchedule]: 'Доступ к расписанию',
    [LogEntryOperation.ApiCreateSchedule]: 'Доступ к добавлению расписания',
    [LogEntryOperation.ApiUpdateSchedule]: 'Доступ к изменению расписания',
    [LogEntryOperation.ApiDeleteSchedule]: 'Доступ к удалению расписания',
    [LogEntryOperation.ApiGetMeterActionSchemes]: 'Доступ к схемам опроса',
    [LogEntryOperation.ApiPostMeterActionScheme]: 'Доступ к добавлению схемы опроса',
    [LogEntryOperation.ApiPutMeterActionScheme]: 'Доступ к изменению схемы опроса',
    [LogEntryOperation.ApiFindEventLogs]: 'Доступ к поиску событий',
    [LogEntryOperation.ApiDownloadEventLogsExcel]: 'Доступ к отчету событий',
    [LogEntryOperation.ApiFindHighCriticalLevelEventLogs]: 'Доступ к поиску критических событий',
    [LogEntryOperation.ApiReportsNotConnectedMeters]: 'Доступ к отчету: Перечень ПУ, с которыми нет связи',
    [LogEntryOperation.ApiReportsMeterProfile]: 'Доступ к отчету: Профиль ПУ: A+A-R+R-',
    [LogEntryOperation.ApiReportsElectricityConsumption]: 'Доступ к отчету: Показания и расход электроэнергии за расчетный период',
    [LogEntryOperation.ApiReportsElectricityTariff]: 'Доступ к отчету: Показания электроэнергии по тарифам',
    [LogEntryOperation.ApiReportsCrashByRegions]: 'Доступ к отчету: Отчет по количеству сбоев',
    [LogEntryOperation.ApiReportsConfigurationHistory]: 'Доступ к отчету: Отчет о состоянии ПУ после диагностики',
    [LogEntryOperation.ApiReportsElectricityConsumptionBilling]: 'Доступ к отчету: Выгрузка для биллинга: показания и расход электроэнергии за расчетный период',
    [LogEntryOperation.ApiGetMeterActivationOrder]: 'Доступ к списку заявок на ввод в эксплуатацию',
    [LogEntryOperation.ApiFindMeterActivationOrders]: 'Доступ к поиску заявок на ввод в эксплуатацию',
    [LogEntryOperation.ApiFindMeterActivationOrderSerialNums]: 'Доступ к поиску заявок на ввод в эксплуатацию по серийному номеру',
    [LogEntryOperation.ApiPostMeterActivationOrder]: 'Доступ к созданию заявки на ввод в эксплуатацию',
    [LogEntryOperation.ApiReprocessMeterActivationOrder]: 'Доступ к перезапуску заявки на ввод в эксплуатацию',
    [LogEntryOperation.ApiReprocessMeterActivationOrders]: 'Доступ к перезапуску заявок на ввод в эксплуатацию',
    [LogEntryOperation.ApiUpdateAndReprocessMeterActivationOrder]: 'Доступ к обновлению и перезапуску заявки на ввод в эксплуатацию',
    [LogEntryOperation.ApiDeleteMeterActivationOrder]: 'Доступ к удалению заявки на ввод в эксплуатацию',
    [LogEntryOperation.ApiGetMeterActionPlan]: 'Доступ к плану опроса ПУ',
    [LogEntryOperation.ApiPostMeterActionPlanSync]: 'Доступ к синхронизации планов опроса ПУ',
    [LogEntryOperation.ApiExternalPostMeterActivationOrders]: 'Внешний доступ к созданию заявки на ввод в эксплуатацию',
    [LogEntryOperation.ApiExternalGetMeterActivationOrderById]: 'Внешний доступ к заявке на ввод в эксплуатацию',
    [LogEntryOperation.ApiExternalGetMeterActivationOrderByForeignId]: 'Внешний доступ к заявке на ввод в эксплуатацию по внешнему идентификатору',
    [LogEntryOperation.ApiExternalMeterActivationOrderFromExcel]: 'Внешний доступ к созданию заявок на ввод в эксплуатацию из excel',
    [LogEntryOperation.ApiExternalPostMeterPowerSwitchRequests]: 'Внешний доступ к созданию заявки на переключение реле',
    [LogEntryOperation.ApiExternalGetMeterPowerSwitchRequests]: 'Внешний доступ к состоянию заявки на переключение реле',
    [LogEntryOperation.ApiTestAccess]: 'Доступ к API проверки доступа',
    [LogEntryOperation.ApiGetAccessAccessRules]: 'Доступ к контролю доступа',
    [LogEntryOperation.ApiGetAccessRoles]: 'Доступ к ролям',
    [LogEntryOperation.ApiPostAccessRoles]: 'Доступ к созданию роли',
    [LogEntryOperation.ApiPutAccessRoles]: 'Доступ к изменению роли',
    [LogEntryOperation.ApiDeleteAccessRoles]: 'Доступ к удалению роли',
    [LogEntryOperation.ApiGetAccessRolesAccessRules]: 'Доступ к правилам по роли',
    [LogEntryOperation.ApiPutAccessRolesAccessRules]: 'Доступ к изменению правил роли',
    [LogEntryOperation.ApiGetAccessUsersAccessRules]: 'Доступ к правилам по пользователю',
    [LogEntryOperation.ApiPutAccessUsersAccessRules]: 'Доступ к изменению правил пользователя',
    [LogEntryOperation.ApiGetMeterActiveEnergyReadingData]: 'Доступ к показаниям активной энергии',
    [LogEntryOperation.ApiGetMeterIncrementalEnergyReadingData]: 'Доступ к показаниям активной энергии по получасовкам',
    [LogEntryOperation.ApiPostMeterCurrentReadingOrders]: 'Доступ к созданию заявки на чтение текущих показаний',
    [LogEntryOperation.ApiGetMeterCurrentReadingOrders]: 'Доступ к состоянию заявки на чтение текущих показаний',
    [LogEntryOperation.ApiPostMeterGisTask]: 'Доступ к созданию Git заданий',
    [LogEntryOperation.ApiGetMeterGisTasks]: 'Доступ к списку Git заданий',
    [LogEntryOperation.ApiPutMeterGisTask]: 'Доступ к изменению Git задания',
    [LogEntryOperation.ApiGetMeterGisTask]: 'Доступ к Git заданию',
    [LogEntryOperation.UpdateModelPlan]: 'Изменение сущности Plan',
    [LogEntryOperation.UpdateModelScheme]: 'Изменение сущности Scheme',
    [LogEntryOperation.ApiGetSystemLogToken]: 'Получение токена аудита',
    [LogEntryOperation.TokenNotFound]: 'Токен не найден',
    [LogEntryOperation.TokenExpired]: 'Токен просрочен',
    [LogEntryOperation.InvalidToken]: 'Недействительный токен',
    [LogEntryOperation.UserNotFound]: 'Не найден пользователь',
    [LogEntryOperation.ExternalSystem]: 'Внешняя система',
    [LogEntryOperation.ApiReadTimeSynchronizationSchemes]: 'Доступ к списку схем синхронизации времени',
    [LogEntryOperation.ApiCreateTimeSynchronizationScheme]: 'Доступ к созданию схемы синхронизации времени',
    [LogEntryOperation.ApiUpdateTimeSynchronizationScheme]: 'Доступ к редактированию схемы синхронизации времени',
    [LogEntryOperation.ApiDeleteTimeSynchronizationScheme]: 'Доступ к удалению схемы синхронизации времени',
    [LogEntryOperation.ApiPutMeterSyncSchemes]: 'Доступ к изменению схем опроса и синхронизации счетчика',
    [LogEntryOperation.ApiGetMeterModels]: 'Доступ к списку моделей счетчиков',
    [LogEntryOperation.ApiPutMeterModels]: 'Доступ к изменению моделей счетчиков',
    [LogEntryOperation.ApiPostAddresses]: 'Доступ к добавлению адреса',
    [LogEntryOperation.ApiGetTariffSchedules]: 'Доступ к списку тарифных расписаний',
    [LogEntryOperation.ApiPostTariffSchedules]: 'Доступ к созданию нового тарифного расписания',
    [LogEntryOperation.ApiPostTariffScheduleVersion]: 'Доступ к созданию новой версии тарифного расписания',
    [LogEntryOperation.ApiPutTariffScheduleVersion]: 'Доступ к изменению версии тарифного расписания',
    [LogEntryOperation.ApiGetTariffScheduleVersion]: 'Доступ к просмотру версии тарифного расписания',
    [LogEntryOperation.ApiGetTariffScheduleOrders]: 'Доступ к списку заявок тарифных расписания',
    [LogEntryOperation.ApiGetSpecialDayBase]: 'Доступ к базовому календарю специальных дней',
    [LogEntryOperation.ApiPostSpecialDayBase]: 'Доступ к добавлению базового календаря специальных дней',
    [LogEntryOperation.ApiPutSpecialDayBase]: 'Доступ к обновлению базового календаря специальных дней',
    [LogEntryOperation.ApiPutMeterMeterTariffSchedule]: 'Доступ к изменению схемы тарификации',
    [LogEntryOperation.ApiGetMeterMeterTariffScheduleOrder]: 'Доступ к статусу изменения схемы тарификации',
    [LogEntryOperation.ApiGetDynamicMeterGroupAddresses]: 'Доступ к просмотру адресов динамической группы',
    [LogEntryOperation.ApiPostDynamicMeterGroupAddresses]: 'Доступ к добавлению адреса динамической группы',
    [LogEntryOperation.ApiPutDynamicMeterGroupAddresses]: 'Доступ к изменению адреса динамической группы',
    [LogEntryOperation.ApiDeleteDynamicMeterGroupAddresses]: 'Доступ к удалению адреса динамической группы',
    [LogEntryOperation.ApiUpdateMeterLabelType]: 'Доступ к изменению типа метки',
    [LogEntryOperation.ApiExternalPostMeterDeactivation]: 'Внешний доступ к выводу из эксплуатации ПУ',
    [LogEntryOperation.ApiPostCrqSystem]: 'Доступ к созданию CRQ системы',
    [LogEntryOperation.ApiPutCrqSystem]: 'Доступ к редактированию CRQ системы',
    [LogEntryOperation.ApiDeleteCrqSystem]: 'Доступ к удалению CRQ системы',
    [LogEntryOperation.ApiPostMeterCrqChannels]: 'Доступ к созданию CRQ каналов',
    [LogEntryOperation.ApiPutEventType]: 'Изменение записи в справочнике типов событий',
    [LogEntryOperation.ApiGetMassOperations]: 'Доступ к массовым операциям',
    [LogEntryOperation.ApiGetMassOperation]: 'Доступ к деталям массовой операции',
    [LogEntryOperation.ApiCreateMassOperation]: 'Доступ к созданию массовой операции',
    [LogEntryOperation.ApiReportsAdvancedCrashReport]: 'Доступ к отчету: Расширенный отчет о сбоях',
    [LogEntryOperation.ApiReportsAdvancedCrashReportExcel]: 'Доступ к скачиванию отчета: Расширенный отчет о сбоях',
    [LogEntryOperation.ApiPostMeterMeterTariffScheduleRewrite]: 'Доступ к перезаписи схемы тарификации',
    [LogEntryOperation.ApiPostPartnerCrqMetersFromExcel]: 'Доступ к импорту CRQ счётчиков из Excel',
    [LogEntryOperation.ApiGetMeterFirmwareVersion]: 'Доступ к списку версий прошивок',
    [LogEntryOperation.ApiCreateMeterFirmwareVersion]: 'Доступ к созданию версии прошивки',
    [LogEntryOperation.ApiUpdateMeterFirmwareVersion]: 'Доступ к редактированию версии прошивки',
    [LogEntryOperation.ApiDeleteMeterFirmwareVersion]: 'Доступ к удалению версии прошивки',
    [LogEntryOperation.ApiGetMeterPassword]: 'Доступ к паролям ПУ',
    [LogEntryOperation.ApiPutEventType]: 'Изменение записи в справочнике типов событий',
    [LogEntryOperation.ApiGetNetworkOperators]: 'Доступ к списку операторов связи',
    [LogEntryOperation.ApiPostNetworkOperator]: 'Доступ к созданию оператора связи',
    [LogEntryOperation.ApiPutNetworkOperator]: 'Доступ к изменению оператора связи',
    [LogEntryOperation.ApiDeleteNetworkOperator]: 'Доступ к удалению оператора связи',
    [LogEntryOperation.ApiPostNetworkOperatorCheck]: 'Доступ к проверке оператора связи',
    [LogEntryOperation.ApiPutMeterTechnicalParams]: 'Доступ к изменению технических параметров ПУ',
    [LogEntryOperation.ApiGetMeterTechnicalParamsOrder]: 'Доступ к просмотру заявки на изменение технических параметров ПУ',
    [LogEntryOperation.ApiGetMeterActionSchemeChangeRequest]: 'Доступ к просмотру статус заявки на изменение схемы',
    [LogEntryOperation.ApiPostMeterChangePasswordOrder]: 'Доступ к созданию заявки на смену паролей по одному ПУ',
    [LogEntryOperation.ApiGetMeterChangePasswordOrder]: 'Доступ к списку заявок на смену паролей по одному ПУ',
    [LogEntryOperation.ApiGetMeterChangePasswordRequest]: 'Доступ к списку запросов на массовую смену паролей',
    [LogEntryOperation.ApiPostMeterChangePasswordRequest]: 'Доступ к созданию запрос на массовую смену паролей',
    [LogEntryOperation.ApiPutMeterPasswordFields]: 'Доступ к изменению паролей ПУ',
    [LogEntryOperation.ApiGetMeterChangePasswordLatestTaskByMeterId]: 'Доступ к последней задаче на изменение паролей ПУ',
};
