import { Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { HookField } from '../../../../../shared/components/hookField';
import { SelectSuggestion } from '../../../../../shared/components/select/model';
import { IStateProps } from './model';
import { Modal } from '../../../../../shared/components/modal';
import { Button } from '../../../../../shared/components/button';
import { FormProvider } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    title: {
        ...theme.typography.h2,
        color: theme.palette.text.primary,
    },
    subTitle: {
        ...theme.typography.body,
        color: theme.palette.neutral.main,
    },
    mb8: {
        marginBottom: 8,
    },
    modal: {
        '& .modal-content .modal-body': {
            padding: '0 24px 24px !important',
        },
        '& .modal-content .modal-header': {
            padding: '12px !important',
        },
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const {
        form,
        onCancel,
        onSubmit,
        isLoading,
        networkOperators,
    } = props;

    const classes = useStyles();

    const defaultNetworkOperatorSuggestions: SelectSuggestion[] = useMemo(
        () => [...networkOperators]
            .sort((a, b) => a.id - b.id)
            .map<SelectSuggestion>(item => ({
                label: `[${item.id}] ${item.name}`,
                value: item.id,
            })),
        [networkOperators],
    );

    return (
        <>
            <Modal
                onHide={onCancel}
                className={classes.modal}
            >
                <FormProvider { ...form }>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className={classNames(classes.title, classes.mb8)}>
                                    По умолчанию для ПУ использовать
                                </div>
                                <div className={classes.subTitle}>
                                    Укажите экземпляр платформы Центр2М к которому необходимо подключаться по умолчанию
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <HookField
                                    type='select'
                                    name='defaultNetworkOperatorId'
                                    label='Платформа по умолчанию'
                                    fullWidth
                                    suggestions={defaultNetworkOperatorSuggestions}
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Button
                                            type='submit'
                                            variant='primary'
                                            disabled={isLoading}
                                            isLoading={isLoading}
                                        >
                                            Сохранить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type='button'
                                            onClick={onCancel}
                                            variant='secondary'
                                            disabled={isLoading}
                                        >
                                            Отмена
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Modal>
        </>
    );
};
