import React from 'react';
import { Table } from '../../../shared/components/table';
import { Wrapper } from '../../../shared/components/wrapper';
import { makeStyles } from '@material-ui/core';
import { DictionariesViewProps } from './model';
import { setColumnWidth } from '../../../shared/pipes/styles';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        minHeight: '150px',
        display: 'flex',
        '& .MuiCheckbox-root.Mui-checked': {
            color: theme.palette.neutral.light,
        },
    },
    tableRow: {
        '&:not(:hover) button': {
            visibility: 'hidden',
        },
    },
    tableCols: {
        '&.col-title': {
            ...setColumnWidth('400px'),
        },
    },
}));

export const View = (props: DictionariesViewProps) => {
    const { data, columns } = props;
    const classes = useStyles();
    return (
        <Wrapper className={classes.tableWrapper}>
            <Table
                title={null}
                data={data}
                columns={columns}
                variant='white'
                useQueryString={true}
                options={{
                    headerClassName: classes.tableCols,
                    paging: 'disabled',
                    grouping: false,
                    filtering: false,
                    search: false,
                    toolbar: false,
                    showTitle: false,
                    rowClassName: classes.tableRow,
                }}
            />
        </Wrapper>
    );
};
