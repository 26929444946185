import { Box, Grid, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Typography } from 'app/shared/components/typography';
import classNames from 'classnames';
import React from 'react';
import { Tooltip } from '../../../../../shared/components/tooltip';
import { Wrapper } from '../../../../../shared/components/wrapper';
import { MeterActionPlanItem } from '../model';

const usePlanCardStyles = makeStyles((theme: Theme) => ({
    title: {
        color: theme.palette.text.hint,
        fontSize: theme.typography.captionBold.fontSize,
    },
    item: {
        marginTop: '8px',
        color: theme.palette.text.primary,
        fontSize: '14px',
    },
    header: {
        paddingLeft: '16px',
    },
    block: {
        minHeight: 150,
    },
    card: {
        miHeight: '100%',
    },
    success: {
        fill: theme.palette.system.success,
        color: theme.palette.system.success,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    error: {
        fill: theme.palette.system.error,
        color: theme.palette.system.error,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    verticalPosition: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    wrap: {
        minHeight: 181
    },
}));

interface PlanCardViewProps {
    title: string;
    plan: MeterActionPlanItem[];
}

export const PlanCard = (props: PlanCardViewProps) => {
    const { plan, title } = props;
    const classes = usePlanCardStyles();
    return (
        <Wrapper title={title} isLoading={plan === undefined}>
            {
                plan ?
                    <Box className={classes.wrap}>
                        {
                            plan?.map((item: MeterActionPlanItem, index) => {
                                return (
                                    !item.hidden &&
                                    <Grid key={index} container direction="row" alignItems="baseline"
                                        className={classes.verticalPosition}>
                                        <Grid item>
                                            <Typography className={classes.title} variant="caption">
                                                {item.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item className={classes[item.status]}>
                                            <Typography className={classNames(classes.item, classes[item.status])}
                                                variant="caption">
                                                {item.tooltip ? (
                                                    <Tooltip text={item.tooltip}>
                                                        {item.value}
                                                    </Tooltip>
                                                ) :
                                                    item.value
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }
                    </Box> :
                    <Box className={classes.wrap}>
                        <Typography variant='caption' className={classes.title}>Нет данных</Typography>
                    </Box>
            }
        </Wrapper>
    );
};
