import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { DynamicMeterGroupAddressesViewModel } from '../../../../../../common/model/meter/meter/dynamicMeterGroupAddressesViewModel';
import { meterRelayStatusDictionary } from '../../../../../../common/model/meter/relayStatus';
import { joinAddressesString } from '../../../../../../common/shared/utils/addresses';
import { setOrderTransformer } from '../../../../../shared/components/pagination/helpers';
import { Wrapper } from '../../../../../shared/components/wrapper';
import React, { useMemo, useRef } from 'react';
import { useWebDictionarySelectSuggestions } from '../../../../../shared/hooks/useWebDictionary';
import { setColumnWidth } from '../../../../../shared/pipes/styles';
import { DynamicGroupForm } from './form';
import classNames from 'classnames';
import { DynamicMeterProps } from './model';
import { Table } from '../../../../../shared/components/table';
import { VisualizationSource } from '../../../../../shared/components/table/model';
import { defaults, Tables } from '../../../../../shared/constants';
import { Icon } from '../../../../../shared/components/icon';
import { meterStatusDictionary } from '../../../../../../common/model/meter/meterStatus';
import { readingSourceDictionary } from 'common/model/meter/readingSource';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        height: 470,
        overflowY: 'auto',
    },
    text: {
        color: theme.palette.text.hint,
        fontSize: 14,
    },
    paddingTitle: {
        paddingLeft: 12,
    },
    title: {
        color: theme.palette.text.hint,
    },
    value: {
        fontSize: 14,
        paddingLeft: 4,
        color: theme.palette.text.primary,
    },
    table: {
        '& .MuiCheckbox-root': {
            color: theme.palette.neutral.light,
        },
        '& .Mui-checked': {
            color: theme.palette.primary.light,
        },
        '&.col-meter-model': {
            ...setColumnWidth('100px'),
        },
        '&.col-number': {
            ...setColumnWidth('170px'),
        },
        '&.col-checkbox': {
            ...setColumnWidth('80px'),
        },
    },
    list: {
        color: theme.palette.primary.light,
        cursor: 'pointer',
        width: 175,
    },
    wrapScroll: {
        height: 384,
        overflow: 'hidden',
    },
}));

const titleText = {
    meterModelId: 'Тип ПУ',
    status: 'Состояние ПУ',
    relayOn: 'Состояние реле',
    relayStatus: 'Состояние реле',
    accountType: 'Тип учета',
    meterPurpose: 'Назначение прибора',
    legalEntityType: 'Правовая форма владельца',
    priceCategory: 'Ценовая категория',
    regionId: 'Регион',
    addresses: 'Адрес',
    readingSource: 'Источник показаний',
};

const visibleFields = [
    'meterModelId',
    'status',
    'relayOn',
    'relayStatus',
    'accountType',
    'meterPurpose',
    'legalEntityType',
    'priceCategory',
    'regionId',
    'addresses',
    'readingSource',
];

export const View = (props: DynamicMeterProps) => {
    const classes = useStyles();
    const arrowDown = <Icon name="arrowSmallDown" />;
    const arrowUp = <Icon name="arrowSmallUp" />;
    const {
        onSubmit,
        form,
        isShowParams,
        groupParamsFilter,
        metersTotal,
        columnsDefinition,
        onOpenTable,
        isShowTable,
        isLoading,
        meterGroupFilter,
        setOrder,
        orderParams,
        meterModels,
        getData,
    } = props;

    const paramsWrapperRef = useRef<HTMLDivElement>();
    const paramsRef = useRef<HTMLDivElement>();

    const groupParamsFilterWithRegion = useMemo(() => {
        return {
            ...groupParamsFilter.params,
            addresses: groupParamsFilter.addresses?.length
                ? groupParamsFilter.addresses
                : null,
            regionId: meterGroupFilter?.regionId,
        };
    }, [groupParamsFilter, meterGroupFilter]);

    const paramsWrapperOffsetHeight = paramsWrapperRef?.current?.offsetHeight;
    const paramsOffsetHeight = paramsRef?.current?.offsetHeight;

    const tableHeight = useMemo(
        () =>
            paramsWrapperOffsetHeight && paramsOffsetHeight
                ? // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                paramsWrapperOffsetHeight - paramsOffsetHeight - 24
                : // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                290,
        [paramsWrapperOffsetHeight, paramsOffsetHeight, groupParamsFilterWithRegion]
    );

    const tableGroupActionText = isLoading ? (
        <>Идет обновление состава групп...</>
    ) : (
        <>На текущий момент в группе {metersTotal} приборов учета</>
    );

    const tableGroupAction = (
        <Typography
            variant="caption"
            className={classNames(classes.text, classes.paddingTitle)}
        >
            {tableGroupActionText}
        </Typography>
    );

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const params = (title: string, value: any) => {
        if (value !== null) {
            let result = null;
            result = value;
            if (title === 'status') {
                result = meterStatusDictionary[value];
            }
            if (title === 'relayStatus') {
                result = meterRelayStatusDictionary[value];
            }
            if (title === 'meterModelId') {
                result = meterModels[value];
            }
            if (title === 'regionId') {
                result =
          regionSuggestions.find((item) => item.value === value)?.label ?? '–';
            }
            if (title === 'readingSource') {
                result = readingSourceDictionary[value] ?? '–';
            }
            if (title === 'addresses') {
                result =
          value && Array.isArray(value)
              ? value
                  .map((item: DynamicMeterGroupAddressesViewModel) =>
                      joinAddressesString(item)
                  )
                  .join('; ')
              : '–';
            }
            return (
                <Grid container alignItems="center" justify="flex-start">
                    <Grid item>
                        <Typography variant="caption" className={classes.title}>
                            {titleText[title]}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" className={classes.value}>
                            {result}
                        </Typography>
                    </Grid>
                </Grid>
            );
        } else {
            return <></>;
        }
    };
    return (
        <>
            <Box mt={2}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Wrapper
                            className={classes.wrapper}
                            title="Параметры ПУ для включения в группу"
                        >
                            <DynamicGroupForm
                                form={form}
                                onSubmit={onSubmit}
                                regionId={meterGroupFilter?.regionId}
                            />
                        </Wrapper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Wrapper
                            justifyContent="start"
                            isHeaderUppercase={false}
                            actions={tableGroupAction}
                            className={classes.wrapper}
                            title="СОСТАВ ГРУППЫ"
                        >
                            {isShowParams ? (
                                <div ref={paramsWrapperRef} className={classes.wrapScroll}>
                                    <div ref={paramsRef}>
                                        <Box mb={1}>
                                            <Typography variant="h3">Параметры:</Typography>
                                        </Box>
                                        {Object.entries(groupParamsFilterWithRegion)
                                            .filter(([key]) => visibleFields.includes(key))
                                            .map(([title, value]: any) => {
                                                return <div key={title}>{params(title, value)}</div>;
                                            })}
                                        {metersTotal > 0 && (
                                            <Box
                                                onClick={onOpenTable}
                                                display="flex"
                                                alignItems="center"
                                                className={classes.list}
                                            >
                                                <Typography variant="h3">
                          Развернуть список ПУ
                                                </Typography>
                                                {isShowTable && metersTotal ? arrowUp : arrowDown}
                                            </Box>
                                        )}
                                    </div>
                                    <Box mt={1}>
                                        <Box>
                                            {isShowTable && (
                                                <Table
                                                    variant="white"
                                                    visualizationSource={
                                                        VisualizationSource.clientProfile
                                                    }
                                                    name={Tables.metersTable}
                                                    columns={columnsDefinition}
                                                    data={getData}
                                                    title={null}
                                                    useQueryString={false}
                                                    options={{
                                                        maxBodyHeight: tableHeight,
                                                        pageSize: 30,
                                                        headerClassName: classes.table,
                                                        paging: 'infinite',
                                                        columnsButton: false,
                                                        filtering: false,
                                                        search: false,
                                                        showTitle: false,
                                                        toolbar: false,
                                                    }}
                                                    fullScreenHeightDelta={
                                                        defaults.fullScreenHeightDeltaForOneTable
                                                    }
                                                    onOrderChange={setOrderTransformer(
                                                        columnsDefinition,
                                                        setOrder
                                                    )}
                                                    {...orderParams}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </div>
                            ) : (
                                <Typography variant="caption" className={classes.text}>
                  Укажите параметры, по которым ПУ будут добавляться в группу.
                                </Typography>
                            )}
                        </Wrapper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
