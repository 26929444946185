import { MeterActivationOrderStatus } from '../../model/meter/meterActivationOrder/meterActivationOrderStatus';


export const isMeterActivationOrderInFiniteStatus = (status: MeterActivationOrderStatus) => {
    return status === MeterActivationOrderStatus.CompletedSuccessfully
        || status === MeterActivationOrderStatus.RuntimeError;
}

export const isMeterActivationOrderCompleteSuccess = (status: MeterActivationOrderStatus) => {
    return status === MeterActivationOrderStatus.CompletedSuccessfully;
}

export const isMeterActivationOrderInErrorStatus = (status: MeterActivationOrderStatus) => {
    return status === MeterActivationOrderStatus.RuntimeError;
}

export const isMeterActivationOrderReprocessable = (status: MeterActivationOrderStatus) => {
    return status === MeterActivationOrderStatus.RuntimeError
        || status === MeterActivationOrderStatus.DeterminationOfIPAddress
        || status === MeterActivationOrderStatus.Created;
}

export const isMeterActivationOrderDeletable = (status: MeterActivationOrderStatus) => {
    return status === MeterActivationOrderStatus.RuntimeError;
}

export const isMeterActivationOrderProcessed = (status: MeterActivationOrderStatus) => {
    return status === MeterActivationOrderStatus.CompletedSuccessfully;
}
