import React from 'react';
import { IStateProps } from './model';
import { Uploader } from '../../../shared/components/uploader';
import { Wrapper } from '../../../shared/components/wrapper';
import { Box, Grid } from '@material-ui/core';

export const View: React.FC<IStateProps> = (props) => {
    const {
        onUploadEnd,
        onSubmit,
    } = props;
    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
                    <Wrapper title='ФАЙЛЫ ДЛЯ ИМПОРТА' isBigHeader>
                        <Uploader
                            accepted={['xml']}
                            onUploadEnd={onUploadEnd}
                            onSubmit={onSubmit}
                        />
                    </Wrapper>
                </Grid>
            </Grid>
        </Box>
    );
};
