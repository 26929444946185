import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';


const commonEventLogSelector = (state: StorageState) => state.eventLogs.common;

export const commonEventLogsAvailableSelector = createSelector(
    commonEventLogSelector,
    common => common.available,
);

export const commonEventLogTotalSelector = createSelector(
    commonEventLogSelector,
    common => common.total,
);

export const commonEventLogLoadingSelector = createSelector(
    commonEventLogSelector,
    common => common.loading,
);

export const commonEventLogIsFilterVisibleSelector = createSelector(
    commonEventLogSelector,
    common => common.isFilterVisible,
);

export const commonMetersSelector = createSelector(
    commonEventLogSelector,
    common => common.meters,
);

export const commonCriticalEventHighLevel = createSelector(
    commonEventLogSelector,
    common => common.highCriticalLevel,
);

export const commonCriticalEventHighLevelTotal = createSelector(
    commonEventLogSelector,
    common => common.criticalTotal,
);
