import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import moment from 'moment';

import { DateFormats } from '../../../../../common/constants/date';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import {
    ConfigurationHistoryReportData,
    ConfigurationHistoryReportQueryParams
} from '../../../../../common/model/meter/reports/configurationHistoryReport';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import {
    getConfigurationHistoryReport,
    resetConfigurationHistoryReport
} from '../../../../../redux/actions/reports/configurationHistoryReport';
import {
    configurationHistoryReportSelector
} from '../../../../../redux/selectors/reports/configurationHistoryReport';
import { useMeterGroupAutocomplete } from '../../../../shared/components/autocomplete/hooks/useMeterGroupAutocomplete';
import { defaults, MeterTabs, ReportPageTabs, ReportTabs, Routes } from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useSyncQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useWebDictionarySelectSuggestions } from '../../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../../shared/pipes';
import { formatDate } from '../../../../shared/utils/dates';
import { getErrorMessage } from '../../../../shared/utils/error';
import { MeterSerialNumView } from '../common/meterSerianNumView';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { getMeterLabelTypes } from 'redux/actions/meterLabelType';
import { StorageState } from 'redux/reducers';
import { VAutocompleteOption } from 'app/shared/components/autocomplete/model';

import { makeExcelReport } from './excel';
import { View } from './view';

const invalidDate = 'Invalid date';

const filterFormDefaultValues: ConfigurationHistoryReportQueryParams = {
    meterGroupId: undefined,
    regionId: defaults.defaultRegionId,
    dateFrom: moment().add(-1, 'month').toDate(),
    dateTo: moment().toDate(),
    meterLabelTypeId: undefined,
};

export const ConfigurationHistoryReport: React.FC = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const report = useSelector(configurationHistoryReportSelector());
    const meterLabelTypes = useSelector((state: StorageState) => state.meterLabelType.list.available);
    const [isLoading, setIsLoading] = useState(false);
    const [reportDate, setReportDate] = useState(new Date());
    const { notHasAccess } = useAccessRules();
    const [queryParams, setQueryParams] = useSyncQueryParams<ConfigurationHistoryReportQueryParams>(filterFormDefaultValues);
    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();
    const params = useParams<{meterId?: string}>();
    const meterId = params.meterId;

    const meterLabelTypesSuggestions: VAutocompleteOption[] = useMemo(() => {
        return meterLabelTypes.map(item => ({
            label: item.taskTitle,
            optionsLabel: item.taskTitle,
            value: item.id
        }));
    }, [meterLabelTypes]);

    useEffect(() => {
        dispatch(getMeterLabelTypes());
    }, []);

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Отчетность', link: getRoute(Routes.report, { meterId }, { tab: ReportTabs }) },
        { name: 'Отчёт о состоянии ПУ после диагностики', link: getRoute(Routes.report, { meterId }, { tab: ReportPageTabs.NotConnectedMeters }) },
    ]);

    const form = useForm<ConfigurationHistoryReportQueryParams>({
        defaultValues: queryParams,
    });

    const formData = form.watch();

    const fetch = async () => {
        setIsLoading(true);
        try {
            setQueryParams(formData);
            await dispatch(getConfigurationHistoryReport(formData));
            setReportDate(new Date());
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    const headingOnBackHandler = () => {
        dispatch(push(Routes.report));
    };

    useEffect(() => {
        return () => {
            dispatch(resetConfigurationHistoryReport());
        };
    }, []);

    const data = useMemo(
        () => report?.data ?? [],
        [report]);

    const reportDateTimeZone = formatDate(reportDate, DateFormats.dateOnlyTimeZone);

    const columns: Column[] = [
        {
            title: '№ пп',
            field: 'rowNumber',
            cellClassName: 'col-rowNumber',
            sorting: true,
            defaultSort: 'asc',
            customSort: (data1: ConfigurationHistoryReportData, data2: ConfigurationHistoryReportData) => {
                return +data1.rowNumber > +data2.rowNumber ? 1 : -1;
            }
        },
        {
            title: 'Район, город',
            field: 'areaName',
            cellClassName: 'col-areaName',
            sorting: true,
            render: (item: ConfigurationHistoryReportData) => {
                return [item.areaName, item.townName].join(', ');
            },
            customSort: (data1: ConfigurationHistoryReportData, data2: ConfigurationHistoryReportData) => {
                const areaName1 = [data1.areaName, data1.townName].join(', ');
                const areaName2 = [data2.areaName, data2.townName].join(', ');
                return areaName1 > areaName2 ? 1 : -1;
            }
        },
        {
            title: 'Адрес ПУ',
            field: 'address',
            cellClassName: 'col-address',
            sorting: true,
        },
        {
            title: 'Тип ПУ',
            field: 'meterModel',
            cellClassName: 'col-meterModel',
            sorting: true,
        },
        {
            title: 'Заводской номер',
            field: 'meterSerialNum',
            cellClassName: 'col-meterSerialNum',
            sorting: true,
            render: (item: ConfigurationHistoryReportData) => {
                return <MeterSerialNumView
                    label={item.meterSerialNum}
                    url={getRoute(Routes.meter, { meterId: item.meterId }, { tab: MeterTabs.Information })}
                />;
            }
        },
        {
            title: 'Проблема',
            field: 'meterLabelDescription',
            cellClassName: 'col-meterLabelTypeId',
            sorting: true,
        },
        {
            title: `С даты (${reportDateTimeZone})`,
            field: 'createdAt',
            cellClassName: 'col-createdAt',
            sorting: true,
            render: (item) => {
                return formatDate(item.createdAt, DateFormats.dateFullTimeFormat);
            },
        },
        {
            title: 'Значение в ПУ',
            field: 'meterValue',
            cellClassName: 'col-meterValue',
            sorting: false,
            render: (item) => {
                if (!item.systemValue) {
                    return item.meterValue;
                }

                const data = formatDate(new Date(item.meterValue), DateFormats.dateFormat);
                return data === invalidDate ? item.meterValue : `${data} ${item.timeErrorAfter ? `,${item.timeErrorAfter} сек` : ''}`;
            },
        },
        {
            title: 'Значение в системе',
            field: 'systemValue',
            cellClassName: 'col-systemValue',
            sorting: false,
            render: (item) => {
                if (!item.systemValue) {
                    return item.systemValue;
                }
                // 5 is label = “Несоответствие даты производства ПУ"
                const specialLabelTypeId = 5;
                const format = item.meterLabelTypeId === specialLabelTypeId
                    ? DateFormats.dateFormat
                    : DateFormats.dateFullTimeFormat;
                const data = formatDate(item.systemValue, format);
                return data === invalidDate ? item.meterValue : data;
            },
        },
    ];

    const isExcelExportNotAvailable = notHasAccess(AccessRule.CanGetReportsConfigurationHistoryExcel) || report?.data?.length === 0;

    const meterGroupAutocomplete = useMeterGroupAutocomplete({
        regionId: formData.regionId,
    });

    return View({
        form,
        data,
        columns,
        isLoading,
        reportDate,
        breadcrumbs,
        onClickBreadcrumb,
        regionSuggestions,
        headingOnBackHandler,
        isExcelExportNotAvailable,
        meterLabelTypesSuggestions,
        isReportFormed: !!report,
        meterGroupAutocomplete,
        onClickFormExcel: async () => {
            try {
                await makeExcelReport({
                    response: report,
                    date: reportDate,
                    filename: 'Отчет о состоянии ПУ (ошибки после диагностика ПУ)',
                });
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
        },
        onClickFormReport: async () => {
            await fetch();
        },
        onClickRefresh: async () => {
            await fetch();
        },
    });
};
