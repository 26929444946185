import { combineReducers } from 'redux';
import { AuditLogsState, auditSlice } from './list';

export interface AuditState {
    list: AuditLogsState;
}

export const audit = combineReducers<AuditState>({
    list: auditSlice.reducer
});
