import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { SpecialDayCreator } from '../../../common/model/meter/specialDay/specialDayCreator';
import { TariffDayCreator } from '../../../common/model/meter/tariffDay/tariffDayCreator';
import { TariffDayNameCreator } from '../../../common/model/meter/tariffDayName/tariffDayNameCreator';
import { TariffScheduleCreator } from '../../../common/model/meter/tariffSchedule/tariffScheduleCreator';
import { TariffScheduleGetQueryParams } from '../../../common/model/meter/tariffSchedule/tariffScheduleGetQueryParams';
import { TariffScheduleOrdersQuery } from '../../../common/model/meter/tariffSchedule/tariffScheduleOrdersQuery';
import { TariffScheduleOrdersResponse } from '../../../common/model/meter/tariffSchedule/tariffScheduleOrdersResponse';
import { TariffScheduleViewModel } from '../../../common/model/meter/tariffSchedule/tariffScheduleViewModel';
import { TariffScheduleVersionCreator } from '../../../common/model/meter/tariffScheduleVersion/tariffScheduleVersionCreator';
import { TariffScheduleVersionViewModel } from '../../../common/model/meter/tariffScheduleVersion/tariffScheduleVersionViewModel';
import { TariffScheduleVersionStatus } from '../../../common/model/meter/tariffScheduleVersionStatus';
import { TariffSeasonCreator } from '../../../common/model/meter/tariffSeason/tariffSeasonCreator';
import { TariffZoneCreator } from '../../../common/model/meter/tariffZone/tariffZoneCreator';
import { newGuid } from '../../../common/shared/utils';
import { ISort } from '../../../common/sort';
import { tariffScheduleEditableSlice } from '../../reducers/tariffSchedule/editable';
import { tariffScheduleListSlice } from '../../reducers/tariffSchedule/list';
import { tariffScheduleOrdersSlice } from '../../reducers/tariffSchedule/orders';
import { AppThunk } from '../index';

export const fetchTariffSchedules = (params: TariffScheduleGetQueryParams): Promise<TariffScheduleViewModel[]> => {
    const route = getApiRoute(appUrls.api_get_tariff_schedules, {}, { ...params });
    return httpService.get<TariffScheduleViewModel[]>(route);
};

export const getTariffSchedules = (
    params: TariffScheduleGetQueryParams,
): AppThunk => async (dispatch) => {
    const data = await fetchTariffSchedules(params);
    dispatch(tariffScheduleListSlice.actions.setAvailable(data));
};

export const createTariffSchedule = async (
    payload: TariffScheduleCreator
): Promise<TariffScheduleViewModel> => {
    const route = getApiRoute(appUrls.api_post_tariff_schedule);
    const data = await httpService.post<TariffScheduleViewModel>(route, payload);
    return data;
};

export const createTariffScheduleVersion = async (
    tariffScheduleId: string,
    payload: TariffScheduleVersionCreator,
): Promise<TariffScheduleViewModel> => {
    const route = getApiRoute(appUrls.api_post_tariff_schedule_version, { tariffScheduleId });
    const data = await httpService.post<TariffScheduleVersionViewModel>(route, payload);
    return data;
};

export const updateTariffScheduleVersion = async (
    tariffScheduleId: string,
    tariffScheduleVersionId: string,
    payload: TariffScheduleVersionCreator,
): Promise<TariffScheduleViewModel> => {
    const route = getApiRoute(appUrls.api_put_tariff_schedule_version, { tariffScheduleId, tariffScheduleVersionId });
    const data = await httpService.put<TariffScheduleVersionViewModel>(route, payload);
    return data;
};

export const setTariffScheduleVersion = (data: TariffScheduleVersionViewModel): AppThunk => (dispatch) => {
    dispatch(tariffScheduleEditableSlice.actions.setTariffScheduleVersion(data));
};

export const getTariffScheduleVersion = (
    tariffScheduleId: string,
    tariffScheduleVersionId: string,
): AppThunk => async (dispatch) => {
    const route = getApiRoute(
        appUrls.api_get_tariff_schedule_version,
        { tariffScheduleId, tariffScheduleVersionId }
    );
    const data = await httpService.get<TariffScheduleVersionViewModel>(route);
    dispatch(setTariffScheduleVersion(data));
};

export const setTariffScheduleEditable = (
    payload: TariffScheduleViewModel,
): AppThunk => (dispatch) => {
    dispatch(tariffScheduleEditableSlice.actions.setTariffSchedule(payload));
};

export const getEditableTariffScheduleById = (
    tariffScheduleId: string,
): AppThunk => async (dispatch) => {
    const data = await fetchTariffSchedules({ regionId: null, legalEntityType: null });
    const tariffSchedule = data.find(item => item.id === tariffScheduleId);
    await dispatch(setTariffScheduleEditable(tariffSchedule));
};

const prepareTariffScheduleSource = (
    tariffScheduleSource: TariffScheduleViewModel
): TariffScheduleCreator => {
    return {
        ...tariffScheduleSource,
        id: newGuid(),
    };
};

const prepareTariffScheduleSourceVersion = (
    tariffScheduleSource: TariffScheduleViewModel,
    tariffScheduleVersionSource: TariffScheduleVersionViewModel,
): TariffScheduleVersionCreator => {
    const newTariffScheduleVersionId = newGuid();
    const tariffDayNamesIdMap: Record<string, string> = {};
    const tariffDaysIdMap: Record<string, string> = {};

    const tariffDayNames: TariffDayNameCreator[] = tariffScheduleVersionSource.tariffDayNames
        .map((item) => {
            const id = newGuid();
            tariffDayNamesIdMap[item.id] = id;
            return {
                ...item,
                id,
                tariffScheduleVersionId: newTariffScheduleVersionId,
            };
        });

    const tariffDays: TariffDayCreator[] = tariffScheduleVersionSource.tariffDays
        .map((item) => {
            const id = newGuid();
            tariffDaysIdMap[item.id] = id;
            return {
                ...item,
                id,
                tariffScheduleVersionId: newTariffScheduleVersionId,
                tariffDayNameId: tariffDayNamesIdMap[item.tariffDayNameId],
            };
        });

    const tariffZones: TariffZoneCreator[] = tariffScheduleVersionSource.tariffZones
        .map((item) => {
            const id = newGuid();
            return {
                ...item,
                id,
                tariffScheduleVersionId: newTariffScheduleVersionId,
                tariffDayId: tariffDaysIdMap[item.tariffDayId],
            };
        });

    const tariffSeasons: TariffSeasonCreator[] = tariffScheduleVersionSource.tariffSeasons
        .map((item) => {
            const id = newGuid();
            return {
                ...item,
                id,
                tariffScheduleVersionId: newTariffScheduleVersionId,
                workingTariffDayId: tariffDaysIdMap[item.workingTariffDayId],
                weekendTariffDayId: tariffDaysIdMap[item.weekendTariffDayId],
            };
        });

    const specialDays: SpecialDayCreator[] = tariffScheduleVersionSource.specialDays
        .map((item) => {
            const id = newGuid();
            return {
                ...item,
                id,
                tariffScheduleVersionId: newTariffScheduleVersionId,
                tariffDayId: tariffDaysIdMap[item.tariffDayId],
            };
        });

    return {
        ...tariffScheduleVersionSource,
        id: newTariffScheduleVersionId,
        tariffScheduleId: tariffScheduleSource.id,
        status: TariffScheduleVersionStatus.Draft,
        tariffDayNames,
        tariffDays,
        tariffZones,
        tariffSeasons,
        specialDays,
    };
};

export const duplicateTariffScheduleVersion = async (
    tariffScheduleSource: TariffScheduleViewModel,
    tariffScheduleVersionSource: TariffScheduleVersionViewModel,
): Promise<{
    createdTariffScheduleVersion: TariffScheduleVersionViewModel,
}> => {

    const tariffScheduleVersionCreator = prepareTariffScheduleSourceVersion(
        tariffScheduleSource,
        tariffScheduleVersionSource,
    );

    const createdTariffScheduleVersion = await createTariffScheduleVersion(
        tariffScheduleSource.id,
        tariffScheduleVersionCreator,
    );

    return {
        createdTariffScheduleVersion,
    };
};

export const duplicateTariffScheduleWithVersion = async (
    tariffScheduleSource: TariffScheduleViewModel,
    tariffScheduleVersionSource: TariffScheduleVersionViewModel,
): Promise<{
    createdTariffSchedule: TariffScheduleViewModel,
    createdTariffScheduleVersion: TariffScheduleVersionViewModel,
}> => {

    const tariffScheduleCreator = prepareTariffScheduleSource(tariffScheduleSource);

    const createdTariffSchedule = await createTariffSchedule(tariffScheduleCreator);

    const tariffScheduleVersionCreator = prepareTariffScheduleSourceVersion(
        createdTariffSchedule,
        tariffScheduleVersionSource,
    );

    const createdTariffScheduleVersion = await createTariffScheduleVersion(
        createdTariffSchedule.id,
        tariffScheduleVersionCreator,
    );

    return {
        createdTariffSchedule,
        createdTariffScheduleVersion,
    };
};

export const getTariffScheduleOrders = (
    query: TariffScheduleOrdersQuery,
    sort: ISort,
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_tariff_schedule_orders, {}, { ...query, ...sort });
    const data = await httpService.get<TariffScheduleOrdersResponse>(route);
    dispatch(tariffScheduleOrdersSlice.actions.setAvailable(data));
};
