import { Box } from '@material-ui/core';
import React from 'react';
import { Heading } from '../../shared/components/heading';
import { TabMenu } from '../../shared/components/tabMenu';
import { TabMenuItem } from '../../shared/components/tabMenuItem';
import { RelatedSystemsTabs } from '../../shared/constants';
import { Export8002080040 } from './export8002080040';
import { Import8002080040 } from './import8002080040';
import { MeterActivationOrdersList } from './meterActivationOrders';
import { ImportMeters } from './importMeter';
import { StateProps } from './model';
import { NetworkOperators } from './networkOperators';
import { Partners } from './partners';
import { PowerSwitchOrders } from './powerSwitchOrders';
import { GisTasks } from './gisTasks';


export const View: React.FC<StateProps> = (props) => {
    const { activeTab, setTab, tabs } = props;

    return (
        <div className='related-systems-page'>
            <Box mb={2} display='flex' justifyContent='space-between'>
                <Heading heading='Смежные системы' />
            </Box>
            <Box mb={2}>
                <TabMenu value={activeTab || RelatedSystemsTabs.MeterActivationOrders} onChange={(e, tab) => setTab(tab)}>
                    {
                        tabs.map(tab =>
                            <TabMenuItem
                                label={tab.title}
                                key={tab.key}
                                value={tab.key}
                            />
                        )
                    }
                </TabMenu>
            </Box>
            {activeTab === RelatedSystemsTabs.MeterActivationOrders && (
                <MeterActivationOrdersList />
            )}
            {activeTab === RelatedSystemsTabs.ImportMeterActivationOrders && (
                <ImportMeters />
            )}
            {activeTab === RelatedSystemsTabs.PowerSwitchOrders && (
                <PowerSwitchOrders />
            )}
            {activeTab === RelatedSystemsTabs.Import8002080040 && (
                <Import8002080040 />
            )}
            {activeTab === RelatedSystemsTabs.Export8002080040 && (
                <Export8002080040 />
            )}
            {activeTab === RelatedSystemsTabs.Partners && (
                <Partners />
            )}
            {activeTab === RelatedSystemsTabs.GisTasks && (
                <GisTasks />
            )}
            {activeTab === RelatedSystemsTabs.NetworkOperators && (
                <NetworkOperators />
            )}
        </div>
    );
};
