import React from 'react';
import { Column } from '@softmedialab/materialui-table';
import { Checkbox } from '../../../shared/components/checkbox';
import { ColumnActions } from './model';
import { EventTypeViewModel } from '../../../../common/model/meter/eventLog/eventTypeViewModel';
import { LogType, logTypeDictionary } from '../../../../common/model/meter/eventLog/logType';

const CheckboxColumn: React.FC<{
    checked: boolean,
    handleChange: (val: boolean) => void,
    disabled: boolean
 }> = ({ checked, handleChange, disabled }) => {
     return <Checkbox checked={checked} disabled={disabled} onChange={handleChange} />;
 };

const LogTypeColumn: React.FC<{logTypeId: LogType}> = ({ logTypeId }) => {
    return <p>{logTypeDictionary[logTypeId]}</p>;
};

export const createColumns = (actions: ColumnActions, isDisabledUpdateEvent: boolean): Column[] => ([
    {
        title: 'Код',
        field: 'id',
        cellClassName: 'col-event-code',
    },
    {
        title: 'Тип журнала',
        field: 'logTypeId',
        cellClassName: 'col-event-type',
        render: ({ logTypeId }: EventTypeViewModel) => <LogTypeColumn logTypeId={logTypeId} />
    },
    {
        title: 'Описание',
        field: 'description',
        cellClassName: 'col-event-desc',
    },
    {
        title: 'Строка форматирования',
        field: 'format',
        cellClassName: 'col-event-format',
    },
    {
        title: 'Критичность',
        sorting: false,
        cellClassName: 'col-event-critical',
        render: (event: EventTypeViewModel) =>
            <div className="event-row-checkbox">
                <CheckboxColumn
                    checked={event.isCritical}
                    disabled={isDisabledUpdateEvent}
                    handleChange={() => actions.handleCritical(event.id, { isCritical: !event.isCritical })}
                />
            </div>
    },
    {
        title: 'Отображать в интерфейсе',
        cellClassName: 'col-event-visible',
        sorting: false,
        render: (event: EventTypeViewModel) =>
            <div className="event-row-checkbox">
                <CheckboxColumn
                    checked={event.isVisible}
                    disabled={isDisabledUpdateEvent}
                    handleChange={() => actions.handleVisible(event.id, { isVisible: !event.isVisible })}
                />
            </div>
    },
]);
