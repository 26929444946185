import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

export const meterSchemesSelector = createSelector(
    (state: StorageState) => state.meterSchemes.list,
    list => list.available
);

export const meterSchemeByIdSelector = (id: number) => createSelector(
    meterSchemesSelector,
    list => list.find(item => item.id === id)
);

export const meterSchemeWithCrqSelector = () => createSelector(
    meterSchemesSelector,
    list => list.filter(item => Boolean(item.isCrq))
);

export const currentSchemeChangeRequestSelector = createSelector(
    (state: StorageState) => state.meterSchemes.list,
    list => list.currentSchemeChangeRequest
);
