import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import { ConsumptionSubTabs, MeterTabs, Routes } from '../../../shared/constants';
import React, { useEffect, useMemo } from 'react';
import { IStateProps, ConsumingSub, Props } from './model';
import { push } from 'connected-react-router';
import { getRoute } from '../../../shared/pipes';
import { View } from './view';
import { meterSelector } from '../../../../redux/selectors/meters';
import { ReadingSource } from 'common/model/meter/readingSource';
import { spreadItemsByCondition } from '../../../shared/pipes/spreadItemsByCondition';

export const Consumption: React.FC<Props> = (ownProps) => {
    const dispatch = useDispatch<BaseDispatch>();
    const meter = useSelector(meterSelector);
    const { meterId } = ownProps;

    const queryParams = useQueryParams();
    const activeSub = queryParams.get('sub') as ConsumptionSubTabs;

    useEffect(() => {
        if (meterId && (!activeSub || !Object.values(ConsumptionSubTabs).includes(activeSub))) {
            const defaultRoute = getRoute(
                Routes.meter,
                { meterId },
                { tab: MeterTabs.Consumption, sub: ConsumptionSubTabs.Electricity }
            );
            dispatch(push(defaultRoute));
        }
    });

    const subs = useMemo(() => {
        const result = [
            {
                key: ConsumptionSubTabs.Electricity,
                title: 'Объемы эл. энергии',
            },
            ...spreadItemsByCondition<ConsumingSub>(meter.readingSource !== ReadingSource.ImportCrq, [
                {
                    key: ConsumptionSubTabs.ElectricityTariff,
                    title: 'Объемы эл. энергии согласно тарифного расписания',
                }
            ]),
        ];

        return result;
    }, [meter.readingSource]);

    const props: IStateProps = {
        ...ownProps,
        activeSub,
        subs,
        onChangeSub: (e, sub) => dispatch(push(getRoute(
            Routes.meter,
            { meterId },
            { tab: MeterTabs.Consumption, sub }
        ))),
    };

    return View(props);
};
