import { combineReducers } from 'redux';
import { MeterChangePasswordRequestsList, meterChangePasswordRequestsListSlice } from './list';

export interface MeterChangePasswordRequestsState {
    list: MeterChangePasswordRequestsList;
}

export const meterChangePasswordRequests = combineReducers<MeterChangePasswordRequestsState>({
    list: meterChangePasswordRequestsListSlice.reducer,
});
