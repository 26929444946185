import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MetersHealthData } from '../../../../common/model/meter/monitoring/metersHealth';
import {
    MeterHealthSummaryInfo,
    MetersWithErrorResponse
} from '../../../../../../common/model/meter/monitoring/metersHealth';

export interface MetersHealthState {
    count: number;
    meterHealthData: MetersHealthData[];
    summaryInformation: MeterHealthSummaryInfo[];
    errorDetalization: MeterHealthSummaryInfo[];
    metersWithErrors: MetersWithErrorResponse;
}

export const initialState: MetersHealthState = {
    count: 0,
    meterHealthData: [],
    summaryInformation: [],
    errorDetalization: [],
    metersWithErrors: {
        total: 0,
        data: []
    }
};

export const metersHealthSlice = createSlice({
    name: '@@vv/monitoring/metersHealth',
    initialState,
    reducers: {
        setMetersHealthData(state, action: PayloadAction<MetersHealthData[]>): void {
            state.meterHealthData = action.payload;
        },
        setSummaryInformation(state, action: PayloadAction<MeterHealthSummaryInfo[]>): void {
            state.summaryInformation = action.payload;
        },
        setErrorDetalization(state, action: PayloadAction<MeterHealthSummaryInfo[]>): void {
            state.errorDetalization = action.payload;
        },
        setMetersWithErrors(state, action: PayloadAction<MetersWithErrorResponse>): void {
            state.metersWithErrors = action.payload;
        },
        setMetersCount(state, action: PayloadAction<number>): void {
            state.count = action.payload;
        },
    },
});
