import { StorageState } from '../../../reducers';
import { createSelector } from '@reduxjs/toolkit';

const listSelector = (state: StorageState) => state.meterReading.incrementalProfiles.list;
export const meterReadingIncrementalProfileListSelector = createSelector(
    listSelector,
    list => list.available
);

export const meterReadingIncrementalProfileListTotalSelector = createSelector(
    listSelector,
    list => list.total
);

export const meterReadingIncrementalProfileListFilterSelector = createSelector(
    listSelector,
    list => list.filter
);

const chartSelector = (state: StorageState) => state.meterReading.incrementalProfiles.chart;
export const meterReadingIncrementalProfileChartSelector = createSelector(
    chartSelector,
    chart => chart.available
);

export const meterReadingIncrementalProfileChartFilterSelector = createSelector(
    chartSelector,
    chart => chart.filter
);
