import * as React from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { MTablePagination } from '@softmedialab/materialui-table';
import classNames from 'classnames';
import { IPropsState } from './model';
import './style.scss';

const tableIcons = {
    Add: AddBox,
    Check,
    Clear,
    Delete: DeleteOutline,
    DetailPanel: ChevronRight,
    Edit,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage,
    LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
    ResetSearch: Clear,
    Search,
    SortArrow: ArrowUpward,
    ThirdStateCheck: Remove,
    ViewColumn
};

export const View = (props: IPropsState) => {
    return <div className='custom-pagination'>
        <MTablePagination {...{
            onChangePage: (e: any, val: number) => {
                props.onChangePage(parseInt(val.toString()) + 1);
            },
            icons: tableIcons,
            page: +props.page - 1,
            count: props.count,
            rowsPerPage: props.rowsPerPage,
            classes: {
                root: classNames(props.className)
            },
            showFirstLastPageButtons: true,
            localization: {
                firstTooltip: 'Первая страница',
                previousTooltip: 'Предыдущая страница',
                nextTooltip: 'Следующая страница',
                lastTooltip: 'Последняя страница',
                labelDisplayedRows: '{from}-{to} из {count}',
                labelRowsPerPage: 'Строк на страницу:'
            }
        } as any} />
    </div>;
};
