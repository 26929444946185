import classNames from 'classnames';
import React, { useMemo } from 'react';
import {
    Box,
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { CloseIcon, ArrowSmallDownIcon } from '../../../../../../assets/icons';
import { Button } from '../../../../../shared/components/button';
import { HookField } from '../../../../../shared/components/hookField';
import { SelectSuggestion } from '../../../../../shared/components/select/model';
import { minutesToTimeParser } from '../../../../../shared/components/timePicker/helpers';
import { IStateProps, TariffZoneItem } from './model';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        borderColor: theme.palette.primary.light,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
    },
    name: {
        ...theme.typography.bodyBold,
        color: theme.palette.text.primary,
    },
    button: {
        ...theme.typography.bodyBold,
        color: theme.palette.text.primary,
        cursor: 'pointer',
    },
    buttonArrow: {
        color: theme.palette.text.hint,
    },
    buttonArrowActive: {
        transform: 'rotate(180deg)',
    },
    buttonDeleteWrapper: {
        position: 'absolute',
        right: -16,
        top: -16,
    },
    buttonDelete: {
        borderRadius: '50%',
        background: theme.palette.neutral.white,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        padding: 0,
        width: 32,
        height: 32,
        color: theme.palette.text.hint,
        '&:hover': {
            opacity: 1,
            color: theme.palette.primary.light,
            background: theme.palette.neutral.white,
        },
        '$root:not(:hover) &': {
            opacity: 0,
        }
    },
}));

const tariffZonesSorting = (a: TariffZoneItem, b: TariffZoneItem): number => {
    return a.zone.startTime > b.zone.startTime ? 1 : -1;
};

const getTimeSuggestions = (): SelectSuggestion[] => {
    const suggestions: SelectSuggestion[] = [];
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    for (let i = 0; i < 48; i++) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const minutes = i % 2 === 0 ? '00' : '30';
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const hours = `0${Math.floor(i / 2)}`.slice(-2);
        const label = `${hours}:${minutes}`;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const value = 30 * i;
        suggestions.push({
            label,
            value,
        });
    }
    return suggestions;
};

export const View: React.FC<IStateProps> = (props) => {

    const {
        name,
        isZonesOpen,
        onClickOpenZones,
        tariffZones,
        onClickDelete,
        onClickAddZone,
        nameSuggestions,
        tariffCountSelections,
        isEditMode,
        tariffDay,
    } = props;

    const classes = useStyles();

    const timeSuggestions = useMemo(() => getTimeSuggestions(), []);

    return <>
        <Box
            className={classes.root}
            padding={2}
        >
            {isEditMode && (
                <Box className={classes.buttonDeleteWrapper}>
                    <IconButton
                        size='small'
                        type='button'
                        onClick={onClickDelete}
                        className={classes.buttonDelete}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            )}
            <Box mb={2}>
                {isEditMode && (
                    <HookField
                        fullWidth
                        name={name}
                        type='select'
                        label='Название'
                        placeholder='Не выбрано'
                        suggestions={nameSuggestions}
                        isClearable
                    />
                )}
                {!isEditMode && (
                    <Box className={classes.name}>
                        {nameSuggestions.find(item => item.value === tariffDay?.tariffDayNameId)?.label ?? '–'}
                    </Box>
                )}
            </Box>
            <Box>
                <Grid
                    container
                    spacing={2}
                    justify='space-between'
                    onClick={onClickOpenZones}
                    className={classes.button}
                >
                    <Grid item>
                        Тарифные зоны
                    </Grid>
                    <Grid item>
                        <ArrowSmallDownIcon className={classNames(classes.buttonArrow, { [classes.buttonArrowActive]: isZonesOpen })} />
                    </Grid>
                </Grid>
            </Box>
            {isZonesOpen && (
                <Box mt={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding='none'>Время начала</TableCell>
                                <TableCell padding='none'>Тариф</TableCell>
                                <TableCell padding='none' />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tariffZones.sort(tariffZonesSorting).map((item) => (
                                <TableRow key={item.name}>
                                    <TableCell padding='none'>
                                        {isEditMode && (
                                            <HookField
                                                name={`${item.name}.startTime`}
                                                type='select'
                                                suggestions={timeSuggestions
                                                    .filter(t => {
                                                        if (t.value === item.zone.startTime) {
                                                            return true;
                                                        }
                                                        return !tariffZones.find(a => a.zone.startTime === t.value);
                                                    })
                                                }
                                                variant='standard'
                                            />
                                        )}
                                        {!isEditMode && (
                                            minutesToTimeParser(item.zone.startTime)
                                        )}
                                    </TableCell>
                                    <TableCell padding='none'>
                                        {isEditMode && (
                                            <HookField
                                                placeholder={'Не выбран'}
                                                name={`${item.name}.tariffNumber`}
                                                type='select'
                                                suggestions={tariffCountSelections}
                                                variant='standard'
                                            />
                                        )}
                                        {!isEditMode && (
                                            item.zone.tariffNumber
                                        )}
                                    </TableCell>
                                    <TableCell padding='none'>
                                        {isEditMode && (
                                            <IconButton onClick={item.onClickDelete} size='small' type='button'>
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {isEditMode && (
                        <Box>
                            <Button onClick={onClickAddZone} variant='text' type='button' icon='add'>
                                Добавить
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    </>;

};
