import { combineReducers } from 'redux';
import { ActiveUserState, usersActiveSlice } from './active';
import { userEditableSlice, UserEditableState } from './editable';
import { userListSlice, UserListState } from './list';

export interface UsersState {
    active: ActiveUserState;
    list: UserListState;
    editable: UserEditableState;
}

export const users = combineReducers<UsersState>({
    active: usersActiveSlice.reducer,
    list: userListSlice.reducer,
    editable: userEditableSlice.reducer,
});
