import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { MeterVerificationUpdater } from '../../../../../common/model/meter/meter/meterVerificationUpdater';
import { BaseDispatch } from '../../../../../redux/actions';
import { updateMeterVerification } from '../../../../../redux/actions/meterUpdateAttributes';
import { meterSelector } from '../../../../../redux/selectors/meters';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import yup from '../../../../shared/utils/yup';
import { actionQueryParam } from '../model';
import { StateProps } from './model';
import { View } from './view';


const verificationValidationScheme = yup.object({
    nextVerificationDate: yup.date().required(),
});

export const MeterVerificationUpdateForm = () => {

    const { meterId } = useParams<{ meterId: string }>();
    const queryParams = useQueryParams();
    const meter = useSelector(meterSelector);

    const dispatch = useDispatch<BaseDispatch>();

    const { hasAccess } = useAccessRules();

    const defaultValues: MeterVerificationUpdater = {
        nextVerificationDate: meter.nextVerificationDate ?? null,
    };

    const onCloseModal = () => {
        queryParams.delete(actionQueryParam);
        dispatch(push({
            search: queryParams.toString()
        }));
    };

    const onSubmit: SubmitHandler<MeterVerificationUpdater> = async (values) => {
        await dispatch(updateMeterVerification(meterId, values));
        onCloseModal();
    };

    const resolver = useYupValidationResolver<MeterVerificationUpdater>(verificationValidationScheme);

    const form = useForm<MeterVerificationUpdater>({
        defaultValues,
        resolver,
    });

    const props: StateProps = {
        form,
        onCancel: onCloseModal,
        onSubmit,
        hasAccess: hasAccess(AccessRule.CanUpdateMeterVerification),
    };

    return View(props);
};
