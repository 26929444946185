import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../../redux/actions';
import { getMeterChangePasswordLatestTask, setMeterChangePasswordLatestTask } from '../../../../../redux/actions/meterChangePasswordOrders';
import { meterChangePasswordOrdersControlLatestSelector } from '../../../../../redux/selectors/meterChangePasswordOrders';
import { useInterval } from '../../../../shared/hooks/useInterval';
import { msInSecond } from '../../../../shared/utils/dates';
import { Props } from './model';
import { View } from './view';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const refreshDelayMs = 15 * msInSecond;

export const PasswordManagement: React.FC<Props> = (props) => {

    const dispatch = useDispatch<BaseDispatch>();
    const latestTask = useSelector(meterChangePasswordOrdersControlLatestSelector);

    const refetch = async () => {
        await dispatch(getMeterChangePasswordLatestTask(props.meterId));
    };

    useEffect(() => {
        refetch();
        return () => {
            dispatch(setMeterChangePasswordLatestTask(null));
        };
    }, [props.meterId]);

    useInterval(async () => {
        await refetch();
    }, refreshDelayMs);

    return View({
        ...props,
        latestTask,
    });
};
