export enum LogEntryType {
	Undefined,
	Message,
	AccessIsDenied,
	AccessIsAllowed,
}

export const logEntryTypeDictionary: Record<LogEntryType, string> = {
    [LogEntryType.Undefined]: 'Неизвестно',
    [LogEntryType.Message]: 'Сообщение',
    [LogEntryType.AccessIsDenied]: 'Доступ запрещен',
    [LogEntryType.AccessIsAllowed]: 'Доступ разрешен',
};
