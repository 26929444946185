import React from 'react';
import { Box, IconButton, makeStyles, Slide } from '@material-ui/core';
import { StateProps } from './model';
import { Typography } from '../typography';
import { Icon } from '../icon';
import classNames from 'classnames';

const drawerWidth = '315px';

const useStyles = makeStyles((theme) => ({
    drawer: {
        backgroundColor: theme.palette.neutral.main,
        minWidth: drawerWidth,
        display: 'flex',
        flexDirection: 'column',
        borderBottomLeftRadius: '4px',
        borderTopLeftRadius: '4px',
    },
    header: {
        padding: '24px 16px 16px 16px',
    },
    content: {
        padding: '16px',
        flex: '1',
    },
    title: {
        maxWidth: '260px',
        color: theme.palette.primary.light,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    close: {
        color: theme.palette.primary.light,
        padding: 0,
    },
}));

export const View = ({
    open,
    handleDrawerClose,
    title,
    children,
    className,
}: StateProps) => {

    const classes = useStyles();

    return (
        <Slide
            direction='right'
            in={open}
            mountOnEnter
            unmountOnExit
        >
            <Box className={classNames(classes.drawer, className)}>
                <Box display='flex' alignItems='center' justifyContent='space-between' className={classes.header}>
                    <Typography className={classes.title} variant='h2'>{title}</Typography>
                    <IconButton className={classes.close} onClick={handleDrawerClose}><Icon name='close' /></IconButton>
                </Box>
                <Box className={classes.content}>{children}</Box>
            </Box>
        </Slide>
    );
};
