import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Grid, Divider } from '@material-ui/core';
import { ViewProps } from './model';
import useStyles from './styles';
import { HookField } from '../../../../shared/components/hookField';
import { Button } from '../../../../shared/components/button';

import { meterPowerSwitchOrderStatuses } from '../../../../shared/constants/enums';
import { transformEndDay, transformStartDay } from '../../../../shared/utils/dates';


export default (props: ViewProps) => {
    const {
        form,
        onSubmit,
        onResetForm,
        meterAutocomplete,
        regionSuggestions,
    } = props;
    const { handleSubmit } = form;
    const classes = useStyles();

    return (
        <FormProvider {...form}>
            <Grid container spacing={3} component='form' onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <HookField
                                type='select'
                                name='regionId'
                                label='Регион'
                                suggestions={regionSuggestions}
                                dark
                                fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <HookField
                                name='dateFrom'
                                type='datepicker'
                                label='Начало'
                                transform={{
                                    output: transformStartDay,
                                }}
                                dark
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <HookField
                                dark
                                fullWidth
                                name='dateTo'
                                type='datepicker'
                                label='Конец'
                                transform={{
                                    output: transformEndDay,
                                }} />
                        </Grid>
                        <Grid item xs={12}>
                            <HookField
                                dark
                                type='autocomplete'
                                name='meterId'
                                label='Заводской номер'
                                fullWidth
                                {...meterAutocomplete} />
                        </Grid>
                        <Grid item xs={12}>
                            <HookField
                                dark
                                type='select'
                                name='status'
                                label='Статус обработки'
                                isClearable
                                suggestions={meterPowerSwitchOrderStatuses}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.actionsWrapper}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} className={classes.actions}>
                            <Button type='submit' className='m-r-16' variant='primary'>Применить</Button>
                            <Button onClick={onResetForm} variant='secondary'>Очистить фильтры</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormProvider>
    );
};
