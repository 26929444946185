import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegionViewModel } from '../../../../common/model/meter/regionViewModel/regionViewModel';
import { Region } from '../../../../common/model/resources/regions';
import * as _ from 'lodash';

export type RegionsState = {
    list: Region[];
    byId: Record<number, Region>
};
const initialState: RegionsState = {
    list: [],
    byId: {},
};

export const regionsSlice = createSlice({
    name: '@@wh/resources/regions',
    initialState,
    reducers: {
        update(state, action: PayloadAction<RegionViewModel[]>): RegionsState {
            return Object.assign({}, state, {
                list: action.payload,
                byId: _.keyBy(action.payload, 'id')
            });
        }
    }
});
