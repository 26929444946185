import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { ListItemIcon } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '../typography';
import { RenderTabList } from './tabList';
import { TabElementOwnProps } from './model';
import { useStyles } from './styles';

import './style.scss';
import { TabIcon } from './tabIcon';

export const View: React.FC<TabElementOwnProps> = ({
    tabListAnchor,
    isTabListOpen,
    tabData,
    handleClickItem,
    handleClickTabElem,
    menuItem,
    handleChangeAvailabilityDeleteButton,
    handleCloseTabList,
    className
}) => {
    const classes = useStyles();

    return (
        <>
            <ListItem
                key={menuItem.route}
                classes={{ root: classes.listItemRoot, selected: classes.listItemSelected }}
                className={className}
                selected={false}
                onClick={(event) => handleClickTabElem(event)}
                disabled={menuItem.disabled}
                disableRipple
                button
                onMouseEnter={() => handleChangeAvailabilityDeleteButton(true)}
                onMouseLeave={() => handleChangeAvailabilityDeleteButton(false)}
                data-targetid={menuItem.menuItemType}
            >
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}>{menuItem.icon}</ListItemIcon>
                <ListItemText classes={{ root: classes.listItemTextRoot }} primary={
                    <Typography variant='h2' className='tab-title'>Недавно открытые</Typography>
                } />
                <TabIcon
                    isOpen={isTabListOpen}
                />
            </ListItem>
            <RenderTabList
                tabData={tabData}
                isOpen={isTabListOpen}
                handleClickItem={handleClickItem}
                anchorEl={tabListAnchor}
                handleClose={handleCloseTabList}
            />
        </>
    );
};
