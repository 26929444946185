import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { DateFormats } from '../../../../../common/constants/date';
import {
    MeterUpdateTechnicalParamsOrderViewModel
} from '../../../../../common/model/meter/meter/meterUpdateTechnicalParamsOrderViewModel';
import {
    meterReadingInstantOrderStatusDictionary,
    MeterUpdateTechnicalParamsOrderStatus
} from '../../../../../common/model/meter/meterUpdateTechnicalParamsOrder/meterUpdateTechnicalParamsOrderStatus';
import { Button } from '../../../../shared/components/button';
import { Forbidden } from '../../../../shared/components/forbidden';
import { HookField } from '../../../../shared/components/hookField';
import { formatDate } from '../../../../shared/utils/dates';
import { useUpdateFromAttributesStyles } from '../styles';
import { StateProps } from './model';

const getOrderView = (order: MeterUpdateTechnicalParamsOrderViewModel) => {
    return (
        <Box>
            <Grid container>
                <Grid item xs={6}>
                    Дата заявки:
                </Grid>
                <Grid item xs={6}>
                    {formatDate(order.creationDate, DateFormats.dateTimeFullWithTimeZone)}
                </Grid>
                <Grid item xs={6}>
                    Статус заявки:
                </Grid>
                <Grid item xs={6}>
                    {meterReadingInstantOrderStatusDictionary[order.status]}
                </Grid>
                {!!order.failureCount && (
                    <>
                        <Grid item xs={6}>
                            Количество неудачных попыток:
                        </Grid>
                        <Grid item xs={6}>
                            {order.failureCount}
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export const View = ({
    form,
    order,
    onSubmit,
    onCancel,
    hasAccess,
    hasEditAccess,
}: StateProps) => {

    if (!hasAccess) {
        return <Forbidden />;
    }

    const { handleSubmit } = form;
    const classes = useUpdateFromAttributesStyles();

    const isOrderInProgress = order && [
        MeterUpdateTechnicalParamsOrderStatus.Created,
        MeterUpdateTechnicalParamsOrderStatus.InProgress,
    ].includes(order.status);

    if (isOrderInProgress) {
        return getOrderView(order);
    }

    return <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12}>
                            <HookField
                                disabled
                                name='dst'
                                type='checkbox'
                                label='Применять переход на летнее время'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12}>
                            <HookField
                                disabled={!hasEditAccess || isOrderInProgress}
                                name='changePasswordForbidden'
                                type='checkbox'
                                label='Запрещено менять пароль'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12}>
                            <HookField
                                disabled={!hasEditAccess || isOrderInProgress}
                                name='changeTariffForbidden'
                                type='checkbox'
                                label='Запрещено изменять тарифное расписание'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12}>
                            <HookField
                                disabled={!hasEditAccess || isOrderInProgress}
                                name='changeTimeForbidden'
                                type='checkbox'
                                label='Запрещена коррекция времени'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12}>
                            <HookField
                                disabled={!hasEditAccess || isOrderInProgress}
                                name='changeIntervalForbidden'
                                type='checkbox'
                                label='Запрещено изменять время интегрирования мощности'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2} justify='center' className={classes.buttonContainer}>
                        <Grid item>
                            <Button disabled={!hasEditAccess || isOrderInProgress} variant='primary' type='submit' icon='check'>Сохранить</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </FormProvider>;
};
