export enum LogEntrySeverity {
	Undefined,
	Critical,
	Error,
	Warning,
	Info,
}

export const logSeverityDictionary: Record<LogEntrySeverity, string> = {
    [LogEntrySeverity.Undefined]: 'Неизвестный',
    [LogEntrySeverity.Critical]: 'Критический',
    [LogEntrySeverity.Error]: 'Ошибка',
    [LogEntrySeverity.Warning]: 'Предупреждение',
    [LogEntrySeverity.Info]: 'Инфо',
};
