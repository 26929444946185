import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrashByRegionsReportResponse } from '../../../../../../common/model/meter/reports/crashByRegionsReport';

export interface CrashByRegionsReportState {
    available: CrashByRegionsReportResponse;
}

const initialState: CrashByRegionsReportState = {
    available: null,
};

export const crashByRegionsReportSlice = createSlice({
    name: '@@vv/reports/crashByRegionsReport',
    initialState,
    reducers: {
        setReport(state, action: PayloadAction<CrashByRegionsReportResponse>): void {
            state.available = action.payload;
        },
    },
});
