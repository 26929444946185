import { DictionaryItem } from '../../../../../common/model/resources/dictionaries/dictionaryItem';
import { sleep } from '../../../../shared/pipes';
import { Workbook, Worksheet } from 'exceljs';
import { MeterProfileReportQueryParams, MeterProfileReportResponse } from '../../../../../common/model/meter/reports/meterProfileReport';
import { downloadExcelData } from '../../../../shared/utils/download';
import {
    getMeterProfileReportResponseIntervalString,
    getMeterProfileReportResponsePeriodDateString,
    transformMeterProfileReportDataViewsToMean,
    transformMeterProfileReportDataViewsToSummary,
    transformMeterProfileReportDataViewToTableItem
} from './helpers';
import { MeterProfileReportTableItem } from './model';
import { addBordersToCell, addRowSpaceToWorksheet, excelBaseFontName } from '../../excelHelpers';

const columnWidths: Record<number, number> = {
    1: 30,
    2: 45,
    3: 30,
    4: 30,
    5: 30,
};

export interface MakeExcelReportConfig {
    response: MeterProfileReportResponse;
    params: MeterProfileReportQueryParams;
    meterModelsById: Record<number, DictionaryItem>;
    filename: string;
}

const makeColumns = (worksheet: Worksheet) => {
    for (const col in columnWidths) {
        worksheet.getColumn(+col).width = +columnWidths[col];
    }
};

const dataToTableCells = (mainTableDatum: MeterProfileReportTableItem): string[] => {
    return [
        mainTableDatum.readingDateView,
        mainTableDatum.sumActivePowerExp,
        mainTableDatum.sumActivePowerImp,
        mainTableDatum.sumReactivePowerExp,
        mainTableDatum.sumReactivePowerImp,
    ];
};

const makeProfilePage = (workbook: Workbook, config: MakeExcelReportConfig): void => {
    const { response, params, meterModelsById } = config;
    const worksheet = workbook.addWorksheet(`Профиль ${response.meterSerialNum}`);
    makeColumns(worksheet);

    const meterInfoData: string[][] = [
        [
            'Заводской номер ПУ',
            response.meterSerialNum,
        ],
        [
            'Тип ПУ',
            meterModelsById[response?.meterModelId]?.name ?? '–',
        ],
        [
            'Период',
            getMeterProfileReportResponsePeriodDateString(response, params),
        ],
        [
            'Интервал',
            getMeterProfileReportResponseIntervalString(response),
        ],
    ];
    const meterInfoRows = worksheet.addRows(meterInfoData);
    for (const meterInfoRow of meterInfoRows) {
        meterInfoRow.eachCell((cell, index) => {
            cell.style.font = {
                bold: index === 1,
                size: 11,
                name: excelBaseFontName,
            };
        });
    }

    addRowSpaceToWorksheet(worksheet);

    const excelMainTableTitles = [
        `Дата и время списания показаний\n(${response.meterTimeZone})`,
        'Активная энергия, отдача\nЗначения на интервале, кВт*ч',
        'Активная энергия, прием\nЗначения на интервале, кВт*ч',
        'Реактивная энергия, отдача\nЗначения на интервале',
        'Реактивная энергия, прием\nЗначения на интервале, кВт*ч',
    ];

    const titlesRow = worksheet.addRow(excelMainTableTitles);
    for (let i = 1; i <= excelMainTableTitles.length; i++) {
        const cell = titlesRow.getCell(i);
        cell.style.font = {
            bold: true,
            name: excelBaseFontName,
            size: 11,
        };
        cell.style.alignment = {
            wrapText: true,
        };
        addBordersToCell(cell);
    }

    const mainTableData: MeterProfileReportTableItem[] = response.data.map(transformMeterProfileReportDataViewToTableItem);
    for (const mainTableDatum of mainTableData) {
        const row = worksheet.addRow(dataToTableCells(mainTableDatum));
        row.eachCell((cell) => {
            cell.style.font = {
                size: 11,
                name: excelBaseFontName,
            };
            addBordersToCell(cell);
        });
    }

    const mainTableSummaryRows: MeterProfileReportTableItem[] = [
        transformMeterProfileReportDataViewsToSummary(response.data),
        transformMeterProfileReportDataViewsToMean(response.data),
    ];

    for (const mainTableDatum of mainTableSummaryRows) {
        const row = worksheet.addRow(dataToTableCells(mainTableDatum));
        row.eachCell((cell) => {
            cell.style.font = {
                size: 11,
                name: excelBaseFontName,
                bold: true,
            };
            addBordersToCell(cell);
        });
    }
};

export const makeExcelReport = async (config: MakeExcelReportConfig) => {
    const workbook = new Workbook();
    makeProfilePage(workbook, config);
    await sleep(10);
    const buffer = await workbook.xlsx.writeBuffer() as unknown as Buffer;
    const excelData = buffer.toString('base64');

    downloadExcelData(
        excelData,
        config.filename,
    );
};
