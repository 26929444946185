import { TariffDayNamesFormValues } from './model';

interface SortableItem {
    index: number;
    tariffDayName: Record<'id', string>;
}
export const sortTariffDayNames = (formData: TariffDayNamesFormValues) => (a: SortableItem, b: SortableItem): number => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const sortA = formData.tariffDayNames[a.index].code ?? 998;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const sortB = formData.tariffDayNames[b.index].code ?? 999;
    return sortA > sortB ? 1 : -1;
};
