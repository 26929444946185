import { combineReducers } from 'redux';
import { meterModelsListSlice, MeterModelsListState } from './list';

export interface MeterModelsState {
    list: MeterModelsListState;
}

export const meterModels = combineReducers<MeterModelsState>({
    list: meterModelsListSlice.reducer,
});
