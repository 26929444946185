import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { PasswordResponse } from '../../../common/model/meter/meterPassword/passwordResponse';
import { PasswordTypes } from '../../../common/model/meter/meterPassword/passwordTypes';

export const getPassword = async (passwordType: PasswordTypes, meterId: string): Promise<string> => {
    const response = await httpService.get<PasswordResponse>(
        getApiRoute(
            appUrls.api_get_meter_password,
            { passwordType, meterId },
        )
    );
    return response?.password;
};
