export const defaults = {
    fullScreenHeightDeltaForOneTable: 270,
    exportDelimiter: ';',
    emptyDateLabel: 'Нет',
    emptyTimeLabel: '00:00',
    dateFormat: 'DD.MM.YYYY',
    timeFormat: 'HH:MM',
    defaultRegionId: 1,
    debounceTimeForAutocompleteSearchMs: 500,
    shiftTimeZone: 5
};
