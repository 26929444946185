import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createSnackbar } from 'redux/actions/controls';
import {
    SurveyConditionData,
    SurveyConditionMetersData,
} from '../../../../common/model/meter/monitoring/surveryCondition';
import { BaseDispatch } from '../../../../redux/actions';
import { getMonitoringSurveyCondition } from '../../../../redux/actions/monitoring';
import { selectSurveyConditionResponse } from '../../../../redux/selectors/monitoring';
import { MeterTabs, Routes } from '../../../shared/constants';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { useWebDictionary } from '../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../shared/pipes';
import { getErrorMessage } from '../../../shared/utils/error';
import { LeftTableBaseColumn } from './components/leftTableBaseColumn';
import { MeterNameColumn } from './components/meterNameColumn';
import { ShowMetersButton } from './components/showMetersButton';
import { InputProps, SurveyConditionFormQueryParams } from './model';
import { validateSurveyConditionQueryParams } from './validation';
import { View } from './view';
import { ReadingSource } from 'common/model/meter/readingSource';

const filterFormDefaultValues: SurveyConditionFormQueryParams = {
    daysOffline: null,
    selectedAreaName: null,
    readingSource: ReadingSource.Reading,
};

export const SurveyCondition = (props: InputProps) => {
    const dispatch = useDispatch<BaseDispatch>();

    const [
        queryParams,
        setQueryParams,
    ] = useSyncQueryParams<SurveyConditionFormQueryParams>(
        filterFormDefaultValues
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const surveyConditionResponse = useSelector(selectSurveyConditionResponse);

    const form = useForm<SurveyConditionFormQueryParams>({
        defaultValues: {
            daysOffline: queryParams.daysOffline,
            selectedAreaName: queryParams.selectedAreaName,
        },
    });

    const formData = form.watch();
    const { selectedAreaName } = formData;

    const fetch = async () => {
        setIsLoading(true);
        form.setValue('selectedAreaName', null);
        try {
            setQueryParams({
                ...formData,
            });
            const params = {
                ...formData,
                regionId: props.regionId,
            };
            await validateSurveyConditionQueryParams(params);
            await dispatch(getMonitoringSurveyCondition(params));
        } catch (e) {
            dispatch(
                createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                })
            );
        }
        setIsLoading(false);
    };

    const onClickSurveyConditionShowMeters = (data: SurveyConditionData) => {
        form.setValue('selectedAreaName', data.areaName);
        setQueryParams({
            ...formData,
            selectedAreaName: data.areaName,
        });
    };

    const leftTableData: SurveyConditionData[] = useMemo(
        () => surveyConditionResponse?.data ?? [],
        [surveyConditionResponse]
    );

    const selectedLeftTableData = useMemo(
        () => leftTableData.find((item) => item.areaName === selectedAreaName),
        [selectedAreaName, leftTableData]
    );

    const rightTableData: SurveyConditionMetersData[] = useMemo(
        () =>
            (selectedLeftTableData?.meters || []).filter(
                (item: any) => item.isCrashedMeter
            ) ?? [],
        [selectedLeftTableData]
    );

    const maxCrashMetersCountAreaName = useMemo(
        () => _.maxBy(leftTableData, 'crashMetersCount')?.areaName,
        [leftTableData]
    );

    const maxCrashMetersPercentAreaName = useMemo(
        () => _.maxBy(leftTableData, 'crashMetersPercent')?.areaName,
        [leftTableData]
    );

    const { meterModels } = useWebDictionary();

    const meterModelsById = useMemo(() => _.keyBy(meterModels, 'id'), [
        meterModels,
    ]);

    const onClickMeterDetails = (meterId: string) => {
        const route = getRoute(
            Routes.meter,
            { meterId },
            { tab: MeterTabs.Information }
        );
        dispatch(push(route));
    };

    const leftTableColumns: Column[] = [
        {
            title: 'Район',
            field: 'areaName',
            sorting: true,
            render: (data: SurveyConditionData) => {
                return <LeftTableBaseColumn text={data.areaName} />;
            },
        },
        {
            title: 'Всего ПУ',
            field: 'metersCount',
            sorting: true,
            render: (data: SurveyConditionData) => {
                return <LeftTableBaseColumn text={data.metersCount} />;
            },
        },
        {
            title: 'Нет показаний',
            field: 'crashMetersCount',
            sorting: true,
            render: (data: SurveyConditionData) => {
                return (
                    <LeftTableBaseColumn
                        text={data.crashMetersCount}
                        isHighlighted={maxCrashMetersCountAreaName === data.areaName}
                    />
                );
            },
        },
        {
            title: 'Процент неопроса',
            field: 'crashMetersPercent',
            sorting: true,
            render: (data: SurveyConditionData) => {
                return (
                    <LeftTableBaseColumn
                        text={data.crashMetersPercent}
                        isHighlighted={maxCrashMetersPercentAreaName === data.areaName}
                    />
                );
            },
        },
        {
            title: '',
            render: (data: SurveyConditionData) => {
                return (
                    <ShowMetersButton
                        onClick={() => onClickSurveyConditionShowMeters(data)}
                    />
                );
            },
        },
    ];

    const rightTableColumns: Column[] = [
        {
            title: 'Прибор учета',
            field: 'id',
            render: (data: SurveyConditionMetersData) => {
                return (
                    <MeterNameColumn
                        meterModel={meterModelsById[data.meterModelId]?.name ?? '–'}
                        meter={data}
                        onClick={() => onClickMeterDetails(data.id)}
                    />
                );
            },
        },
    ];

    const isLeftTableFormed =
    !!surveyConditionResponse && leftTableData.length > 0;
    const isRightTableFormed = isLeftTableFormed && !!selectedAreaName;

    return View({
        ...props,
        form,
        isLoading,
        leftTableData,
        rightTableData,
        leftTableColumns,
        selectedAreaName,
        rightTableColumns,
        isLeftTableFormed,
        isRightTableFormed,
        selectedLeftTableData,
        onClickFormReport: async () => {
            await fetch();
        },
        onClickRefresh: async () => {
            await fetch();
        },
    });
};
