import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import { postImportDocument8002080040 } from '../../../../redux/actions/import';
import { SnackbarNotification } from '../../../../redux/reducers/controls/snackbar';
import { fileToBase64 } from '../../../shared';
import { UploaderFile } from '../../../shared/components/uploader/uploaderFile/model';
import { View } from './view';

export const Import8002080040 = () => {
    const dispatch = useDispatch<BaseDispatch>();

    return View({
        onSubmit: (files: UploaderFile[]) => {
            return files.map(file => ({
                file,
                promise: async () => {
                    const base64 = await fileToBase64(file.file);
                    const result = await postImportDocument8002080040({ file: {
                        base64,
                        filename: file.file.name,
                    } });
                    if (!result.isSuccessImport) {
                        throw new Error(result.importResult);
                    }
                }
            }));
        },
        onUploadEnd: (success: UploaderFile[], rejected: UploaderFile[]) => {
            const options: SnackbarNotification = {
                message: 'Загрузка выполнена успешно',
                type: 'green',
            };
            if (success.length === 0 && rejected.length === 0) {
                return;
            }
            if (success.length === 0) {
                options.type = 'red';
                options.message = 'Произошла ошибка при загрузке';
            } else if (rejected.length >= 1) {
                options.type = 'red';
                options.message = `Файлы были загружено частично: успешно ${success.length} из ${success.length + rejected.length}`;
            }
            dispatch(createSnackbar(options));
        },
    });
};
