import {
    Options,
    Column,
    Action,
    Localization,
    Icons,
    Components,
    MaterialTableProps
} from '@softmedialab/materialui-table';
import React, { RefObject } from 'react';
import { PaginationOrderParams } from '../../hooks/useActualPage';

export enum VisualizationSource {
    none = 0,
    clientProfile = 1,
}

export interface InputModel extends MaterialTableProps, PaginationOrderParams {
    columns: Column[];
    actions?: Action[];
    editable?: {
        onRowAdd?: (newData: any) => Promise<void>;
        onRowUpdate?: (newData: any, oldData?: any) => Promise<void>;
        onRowDelete?: (oldData: any) => Promise<void>;
    };
    icons?: Icons;
    localization?: Localization;
    title: string | React.ReactElement<any>;
    name?: string;
    visualizationSource?: VisualizationSource;
    isLoading?: boolean;
    fullScreenHeightDelta?: number;
    options?: Options;
    onRowClick?: (event?: React.MouseEvent, rowData?: any, toggleDetailPanel?: (panelIndex?: number) => void) => void;
    parentChildData?: (row: any, rows: any) => any;
    components?: Components;
    tableRef?: RefObject<any>;
    onSelectionChange?: (data: any[], rowData?: any) => void;
    variant?: 'default' | 'white' | 'gray';
    useQueryString?: boolean;
}

export type TableProps = InputModel;
