import React from 'react';
import { ImportCrqLogViewProps } from './model';
import { Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Loader } from 'app/shared/components/loader';
import { Breadcrumbs } from 'app/shared/components/breadcrumbs';
import { Heading } from 'app/shared/components/heading';
import { Typography } from 'app/shared/components/typography';
import { ImportCrqLogHeader } from './components/importCrqLogHeader';
import { ImportCrqLogTable } from './components/importCrqLogTable';

const useStyles = makeStyles((theme) => ({
    mainTitle: {
        color: theme.palette.text.hint,
        marginLeft: '16px',
        paddingTop: '4px',
        textTransform: 'inherit'
    },
}));

export const View: React.FC<ImportCrqLogViewProps> = ({
    importCrq,
    isLoading,
    breadcrumbs,
    onClickBreadcrumb,
    importCrqLog,
    setOrder,
    orderParams,
    pagination,
    dataTotal,
    setPage,
}) => {
    const classes = useStyles();
    const history = useHistory();

    if (isLoading) {
        return <Box display='flex' justifyContent='center'>
            <Loader visible={true} size={50} />
        </Box>;
    }

    if (!importCrq) {
        return null;
    }

    return (
        <>
            <Box mb={2} marginBottom='0'>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box display='flex' alignItems='center' marginTop='8px'>
                <Heading heading='Информация о файле' onBackHandler={history.goBack} />
                <Typography variant='h4' className={classes.mainTitle}>{importCrq.fileName}</Typography>
            </Box>

            <ImportCrqLogHeader importCrq={importCrq} />

            <ImportCrqLogTable
                data={importCrqLog}
                setOrder={setOrder}
                orderParams={orderParams}
                pagination={pagination}
                dataTotal={dataTotal}
                setPage={setPage}
            />

        </>
    );
};
