import { makeStyles } from '@material-ui/core';
import { setColumnWidth } from '../../../../../shared/pipes/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: theme.palette.neutral.white,
        minHeight: 105,
        position: 'relative',
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 16,
        paddingBottom: 16,
        '& .MuiPaper-root div': {
            overflowX: 'auto',
        },
        '& .cell-meterTypeName': {
            ...setColumnWidth('120px'),
        },
        '& .cell-meterSerialNumber': {
            ...setColumnWidth('140px'),
        },
        '& .cell-meterAddress': {
            ...setColumnWidth('420px'),
        },
        '& .cell-meterGisTaskNumber': {
            ...setColumnWidth('140px'),
        },
        '& .cell-incidentStatus': {
            ...setColumnWidth('140px'),
        },
        '& .cell-incidentDescription': {
            minWidth: 312,
        },
        '& .link': {
            display: 'inline-flex',
            whiteSpace: 'nowrap',
        },
    },
}));
