import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Wrapper } from '../../../shared/components/wrapper';
import { Table } from '../../../shared/components/table';
import { ProblemsViewProps } from './model';
import { Button } from '../../../shared/components/button';
import { setColumnWidth } from '../../../shared/pipes/styles';
import { HookField } from '../../../shared/components/hookField';
import { FormProvider } from 'react-hook-form';
import { Modal } from '../../../shared/components/modal';
import { CreateTaskForm } from '../../relatedSystems/gisTasks/createTaskForm';

const useStyles = makeStyles((theme) => ({
    closeIcon: {
        cursor: 'pointer',
    },
    noLabelsText: {
        color: theme.palette.text.hint,
    },
    criticalEventTable: {
        '& .custom-table': {
            marginTop: '-12px',
            '& button': {
                visibility: 'hidden',
            },
            '& .MuiTableRow-root': {
                '&:hover': {
                    '& button': {
                        visibility: 'visible',
                    },
                }
            },
        },
        '& .custom-table table .MuiTableCell-root': {
            '&:first-child': {
                paddingLeft: '8px',
            },
        },
        '& .custom-table table .MuiTableCell-head': {
            color: '#5C6C7F',
            fontWeight: '400',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            height: '48px',
        },
        '& .custom-table table .MuiTableCell-head:hover::before': {
            background: 'none',
        },
        '& .custom-table table .MuiTableCell-head:hover::after': {
            background: 'none',
        },
    },
    tableCols: {
        '&.cell-name': {
            ...setColumnWidth('280px'),
        },
        '&.cell-date': {
            ...setColumnWidth('174px'),
        },
        '&.cell-description': {
            ...setColumnWidth('480px'),
        },
        '&.cell-incident': {
            ...setColumnWidth('168px'),
        },
    },
}));

export const View: React.FC<ProblemsViewProps> = ({
    columns,
    meterLabels,
    onMeterConfigurationHistoryClick,
    form,
    onCloseCreateIncidentModal,
    onCreateSubmit,
    isCreateIncidentModalOpened,
    foreignId,
    address,
    activeMeterLabelTypeId,
}) => {
    const classes = useStyles();

    const filterRender = (
        <FormProvider {...form}>
            <Grid container>
                <Grid item>
                    <HookField
                        type='checkbox'
                        name='isClosedOnly'
                        label='Показать закрытые'
                    />
                </Grid>
            </Grid>
        </FormProvider>
    );

    const tableActionsRender = (
        <>
            <Grid container justify="flex-start">
                { filterRender }
            </Grid>
            <Grid container justify="flex-end" spacing={2}>
                <Grid item>
                    <Button variant="outline" onClick={onMeterConfigurationHistoryClick}>История смены конфигурации</Button>
                </Grid>
            </Grid>
        </>
    );

    return (
        <>
            <Wrapper actions={tableActionsRender} className={classes.criticalEventTable}>
                <Table
                    columns={columns}
                    title={null}
                    data={meterLabels}
                    variant='white'
                    useQueryString={true}
                    options={{
                        headerClassName: classes.tableCols,
                        grouping: false,
                        search: false,
                        filtering: false,
                        showTitle: false,
                        toolbar: false,
                        sorting: true,
                        paging: 'disabled',
                        columnsButton: false,
                        draggableHeader: false,
                    }}
                />
            </Wrapper>

            { isCreateIncidentModalOpened && (
                <Modal
                    title='Новый инцидент'
                    close='Отмена'
                    onHide={onCloseCreateIncidentModal}
                >
                    <CreateTaskForm
                        onSubmit={onCreateSubmit}
                        onCancel={onCloseCreateIncidentModal}
                        foreignId={foreignId}
                        activeMeterLabelTypeId={activeMeterLabelTypeId}
                        address={address}
                    />
                </Modal>
            )}
        </>

    );
};
