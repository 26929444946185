import React from 'react';
import { TabListItemDeleteIcon } from './model';
import { Icon } from '../../../icon';
import { Box } from '@material-ui/core';

export const View: React.FC<TabListItemDeleteIcon> = ({
    tabId,
    activeTabId,
    handleClickItem,
}): JSX.Element | null => {
    if (tabId !== activeTabId) {
        return <Box width='16px' height='16px' />;
    }

    return (
        <Icon name='close' onClick={() => handleClickItem(tabId, 'delete')} width='16px' height='16px' />
    );
};
