import React from 'react';
import { MeterActionType } from '../../../../common/model/meter/meterActionType';
import { InspectionLog } from '../../inspectionLog';
import { InspectionLogPanel } from '../../inspectionLog/components/inspectionLogPanel';
import { ConsumptionElectricityTariff } from './electricityTariff';
import { Electricity } from './electricity';
import { IStateProps } from './model';
import { Box } from '@material-ui/core';
import { ConsumptionSubTabs, ReadingSubs } from '../../../shared/constants';
import { SubMenu } from '../../../shared/components/subMenu';
import { SubMenuItem } from '../../../shared/components/subMenuItem';


export const View = ({
    activeSub,
    onChangeSub,
    meterId,
    subs,
}: IStateProps) => {

    return (
        <>
            <div className='meter-daily-reading-page'>
                <SubMenu value={activeSub || ReadingSubs.List} onChange={onChangeSub}>
                    {
                        subs.map(sub =>
                            <SubMenuItem
                                label={sub.title}
                                key={sub.key}
                                value={sub.key}
                            />
                        )
                    }
                </SubMenu>

                <Box py={3}>
                    { activeSub === ConsumptionSubTabs.Electricity && <Electricity meterId={meterId} />}
                    { activeSub === ConsumptionSubTabs.ElectricityTariff && <ConsumptionElectricityTariff meterId={meterId} />}
                </Box>
            </div>
            <InspectionLogPanel>
                <InspectionLog
                    meterId={meterId}
                    meterActionType={[
                        MeterActionType.IncrementalProfileReading,
                        MeterActionType.DailyProfileReading,
                        MeterActionType.MonthlyProfileReading,
                        MeterActionType.CurrentProfileReading,
                    ]}
                />
            </InspectionLogPanel>
        </>
    );
};
