import { useRef, useState } from 'react';
import { HTMLInputEvent } from '../../interfaces';
import { DropzoneRejectedFile, InputModel, IStateProps } from './model';
import { View } from './view';
import * as mime from 'mime-types';
import fileSize from 'filesize';

export const Dropzone = (props: InputModel) => {
    const { onChange } = props;
    const inputRef = useRef<HTMLInputElement>();
    const [isDragging, setDragging] = useState<boolean>(false);

    const validateFile = (file: File): string => {
        let fileType = file.type
            ? mime.extension(file.type).toString()
            : (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name)[0] : undefined;
        if (!fileType) {
            return 'Тип файла не распознан';
        }
        fileType = fileType.toLowerCase();
        if (props.accepted && !props.accepted.includes(fileType)) {
            return 'Тип файла не поддерживается';
        }
        if (props.maxFileSize && file.size > props.maxFileSize) {
            return `Размер файла слишком большой, ${fileSize(file.size)} > ${fileSize(props.maxFileSize)}`;
        }
        return null;
    };

    const handleDropFiles = (fileList: FileList) => {
        const accepted: File[] = [];
        const rejected: DropzoneRejectedFile[] = [];
        for (let i = 0; i < fileList.length; i++) {
            const currentFile = fileList[i];
            const rejectingReason = validateFile(currentFile);
            if (rejectingReason) {
                rejected.push({
                    file: currentFile,
                    reason: rejectingReason,
                });
            } else {
                accepted.push(currentFile);
            }
        }
        onChange(accepted, rejected);
        inputRef.current.value = null;
    };

    const stateProps: IStateProps = {
        ...props,
        handleDrag: (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setDragging(true);
        },
        handleDragIn: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setDragging(true);
        },
        handleDragOut: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setDragging(false);
        },
        handleDrop: (e: DragEvent) => {
            handleDropFiles(e.dataTransfer.files);
            e.preventDefault();
            e.stopPropagation();
            setDragging(false);
        },
        handleInput: (e: HTMLInputEvent) => {
            handleDropFiles(e.target.files);
        },
        inputRef,
        isDragging,
    };
    return View(stateProps);
};
