import { View } from './view';
import { AutoselectDictionaryInputProps } from './model';
import { SearchItem } from '../search/model';
import React, { useEffect, useState } from 'react';
import { getMeterDictionaryValues } from '../../../../redux/actions/dictionaries';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { meterDictionaryValues } from '../../../../redux/selectors/dictionaries';

export const AutoselectDictionaryInput: React.FC<AutoselectDictionaryInputProps> = ({
    dictionaryName,
    fullWidth = false,
    dark = false,
    placeholder,
    value,
    onChange,
    error,
    errorText
}) => {
    const dispatch = useDispatch<BaseDispatch>();
    const dictionaryValues = useSelector(meterDictionaryValues);
    const [showHints, setShowHints] = useState<boolean>(false);
    const [hintSearchItems, setHintSearchItems] = useState<SearchItem[]>([]);

    useEffect(() => {
        dispatch(getMeterDictionaryValues(dictionaryName));
    }, []);

    useEffect(() => {
        dispatch(getMeterDictionaryValues(dictionaryName, { search: value }));
    }, [value]);

    useEffect(() => {
        setHintSearchItems(dictionaryValues.map(item => ({ value: item.value, label: item.value, isSelectable: true })));
        if (dictionaryValues.length === 0) {
            setShowHints(false);
        }
    }, [dictionaryValues]);

    const onChangeSelected = (event: SearchItem) => {
        onChange(event.value);
        setShowHints(false);
    };

    return View({
        dark,
        fullWidth,
        placeholder,
        dictionaryName,
        onChangeSelected,
        items: hintSearchItems,
        loading: false,
        showHints,
        isSearchIconAvailable: false,
        onChangeShowHints: (newVal: boolean) => setShowHints(newVal),
        hasActions: true,
        value,
        onChange,
        error,
        errorText,
        setShowHints,
    });

};
