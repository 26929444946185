import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Button } from '../../../shared/components/button';
import classNames from 'classnames';
import { DrawerPanel } from '../../../shared/components/drawerPanel';
import { Wrapper } from '../../../shared/components/wrapper';
import { Table } from '../../../shared/components/table';
import { VisualizationSource } from '../../../shared/components/table/model';
import { defaults, Tables } from '../../../shared/constants';
import { setOrderTransformer } from '../../../shared/components/pagination/helpers';
import { Pagination } from '../../../shared/components/pagination';
import { StateProps } from './model';
import { Modal } from '../../../shared/components/modal';
import { CreateTaskForm } from './createTaskForm';
import { GisTaskFilterForm } from './gisTaskFilterForm';
import { setColumnWidth } from '../../../shared/pipes/styles';
import { Typography } from '../../../shared/components/typography';

const useStyles = makeStyles(() => ({
    hidden: {
        display: 'none'
    },
    meterGisTasksWrapper: {
        '& .MuiDivider-root': {
            display: 'none',
        },
        display: 'flex',
        '& .custom-table.white-table .MuiTableCell-head ': {
            fontWeight: 'normal',
            fontSize: '13px',
            lineHeight: '18px',
            color: '#5C6C7F',
        },
        '& .custom-table': {
            marginTop: '-16px',
            '& button': {
                visibility: 'hidden',
                width: '89px',
                alignSelf: 'flex-end',
            },
            '& .MuiTableRow-root': {
                '&:hover': {
                    '& button': {
                        visibility: 'visible',
                    },
                }
            },
        },
    },
    actions: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    filterWrapper: {
        flex: 1,
    },
    actionsLeft: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
        flex: 1,
    },
    actionsRight: {
        marginLeft: 'auto',
    },
    table: {
        '&.cell-checkbox': {
            ...setColumnWidth('40px'),
        },
        '&.cell-status': {
            ...setColumnWidth('60px'),
        },
        '&.cell-id': {
            ...setColumnWidth('60px'),
        },
        '&.cell-address': {
            ...setColumnWidth('272px'),
        },
        '&.cell-title': {
            ...setColumnWidth('272px'),
        },
        '&.cell-createdAt': {
            ...setColumnWidth('148px'),
        },
        '&.cell-meterSerialNum': {
            ...setColumnWidth('270px'),
        },
    },
}));

export const View = ({
    columnDefinition,
    data,
    loading,
    isFilterVisible,
    filterValues,
    initialFilterValues,
    onFiltersOpen,
    onFiltersClose,
    onFilterSubmit,
    dataTotal,
    pagination,
    setPage,
    setOrder,
    orderParams,
    onClickNewTask,
    isCreateTaskModalOpened,
    onCloseCreateTaskModal,
    onCreateSubmit,
    isCloseGroupAvailable,
    isArchiveGroupAvailable,
    isSyncAvailable,
    onCloseGroup,
    onArchiveGroup,
    onSyncGroup,
    foreignId,
    address,
    appliedFiltersCount,
}: StateProps): JSX.Element => {
    const classes = useStyles();
    const tableActions = <Box className={classes.actions}>
        <Box className={classes.actionsLeft}>
            {
                (isCloseGroupAvailable || isArchiveGroupAvailable || isSyncAvailable)
                    ? (
                        <Typography variant='body'>Выбрано инцидентов: {data.filter(elem => elem.selected).length}</Typography>
                    )
                    : (
                        <Button
                            variant='outline'
                            icon={appliedFiltersCount > 0 ? 'filterFilled' : 'filter'}
                            className={classNames('m-r-16', { [classes.hidden]: isFilterVisible })}
                            onClick={onFiltersOpen}
                        >
                            Фильтрация{appliedFiltersCount > 0 ? ` (${appliedFiltersCount})` : ''}
                        </Button>
                    )
            }

        </Box>
        <Box className={classes.actionsRight} display='flex' flexDirection='row'>
            <Box>
                <Button variant='text' disabled={!isCloseGroupAvailable} onClick={onCloseGroup}>Закрыть</Button>
            </Box>
            <Box marginLeft='5px'>
                <Button variant='text' disabled={!isArchiveGroupAvailable} onClick={onArchiveGroup}>Архивировать</Button>
            </Box>
            <Box marginLeft='5px'>
                <Button variant='text' disabled={!isSyncAvailable} onClick={onSyncGroup}>Синхронизировать</Button>
            </Box>
            <Box marginLeft='5px'>
                <Button onClick={onClickNewTask} variant='primary' icon='add'>Добавить</Button>
            </Box>
        </Box>
    </Box>;

    const drawer = <DrawerPanel
        open={isFilterVisible}
        handleDrawerClose={onFiltersClose}
        title='Фильтрация'
        anchor='left'
    >
        <GisTaskFilterForm
            initialFilterValues={initialFilterValues}
            filterValues={filterValues}
            onSubmit={onFilterSubmit}
            foreignId={foreignId}
        />
    </DrawerPanel>;


    return (
        <>
            <Wrapper actions={tableActions} drawer={drawer} className={classes.meterGisTasksWrapper} isBigHeader>
                <Table
                    visualizationSource={VisualizationSource.clientProfile}
                    name={Tables.commonEventLogTable}
                    columns={columnDefinition}
                    data={data || []}
                    title={null}
                    variant='white'
                    options={{
                        headerClassName: classes.table,
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        search: false,
                        toolbar: false,
                        sorting: true,
                        showTitle: false,
                        paging: 'disabled',
                    }}
                    fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                    isLoading={loading}
                    onOrderChange={setOrderTransformer(columnDefinition, setOrder)}
                    {...orderParams}
                />
                <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={!data} />
            </Wrapper>

            { isCreateTaskModalOpened && (
                <Modal
                    title='Новый инцидент'
                    close='Отмена'
                    onHide={onCloseCreateTaskModal}
                >
                    <CreateTaskForm
                        onSubmit={onCreateSubmit}
                        onCancel={onCloseCreateTaskModal}
                        foreignId={foreignId}
                        address={address}
                    />
                </Modal>
            )}
        </>
    );
};
