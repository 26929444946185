import axios, { AxiosRequestConfig } from 'axios';
const CancelToken = axios.CancelToken;

import { HttpWrapperOptions } from './options';
import {
    increaseBlockingCallCounter,
    decreaseBlockingCallCounter,
    newNotificationMessage
} from '../../../redux/actions/httpWrapperActions';

import { store } from '../../../index';
import { Localstorage, Routes } from '../constants';
import { StorageState } from '../../../redux/reducers';
import { checkVersion } from '../../../redux/actions/resources';
import { HttpStatusCode } from '../../../common/httpStatusCode';

const baseApiUrl = process.env.REACT_APP_BACKEND_URL || '';

type RequestType = 'get' | 'post' | 'put' | 'delete';

const cancelRequestBecauseOfNewOne = 'cancel request because of new one';
const initializeTimestamp = new Date().getTime();

const minReloadingIntervalMs = 300000;
const reloadAfterMs = 1000;

class InternalError {
    constructor(public response: any) {
        // do nothing
    }
}

export class HttpService {
    private cancelations: { [key: string]: any } = {};

    public showFanCounter = 0;

    public get<T>(url: string, httpWrapperOptions?: HttpWrapperOptions): Promise<T> {
        const queryStringParams = {
        };
        if (httpWrapperOptions?.cacheQueryParams) {
            queryStringParams['_t'] = httpWrapperOptions.cacheQueryParams;
        }
        return this.processRequest<T>(url, 'get', null, httpWrapperOptions, queryStringParams);
    }

    public post<T = void>(url: string, data?: any, httpWrapperOptions?: HttpWrapperOptions): Promise<T> {
        return this.processRequest<T>(url, 'post', data, httpWrapperOptions);
    }

    public put<T = void>(url: string, data?: any, httpWrapperOptions?: HttpWrapperOptions): Promise<T> {
        return this.processRequest<T>(url, 'put', data, httpWrapperOptions);
    }

    public delete<T = void>(url: string, data?: any, httpWrapperOptions?: HttpWrapperOptions): Promise<T> {
        return this.processRequest<T>(url, 'delete', data, httpWrapperOptions);
    }

    private processRequest<T>(
        url: string,
        method: RequestType,
        data?: any,
        httpWrapperOptions = new HttpWrapperOptions(),
        queryParams: any = null): Promise<T> {

        const result = new Promise<T>((resolve, reject) => {
            (async () => {
                if (httpWrapperOptions.wait) {
                    setTimeout(() => {
                        store.dispatch(increaseBlockingCallCounter());
                    }, httpWrapperOptions.waitTimeout);
                }

                const state = store.getState() as StorageState;
                const headers: any = {};
                if (httpWrapperOptions.requestIdentificator) {
                    headers['ppm-identificator'] = httpWrapperOptions.requestIdentificator;
                }

                const token = localStorage.getItem(Localstorage.token);
                if (token) {
                    headers['x-access-token'] = token;
                }

                const axiosRequestConfig: AxiosRequestConfig = Object.assign({}, {
                    method,
                    withCredentials: true,
                    responseType: httpWrapperOptions.responseType,
                    timeout: httpWrapperOptions.timeout,
                    headers,
                    data: (data) ? data : undefined,
                    params: queryParams,
                });

                const normalizedUrl = this.normalizeUrl(url);
                try {
                    let res: any = null;
                    let callCounter = 0;
                    while (true) {
                        try {
                            if (httpWrapperOptions.requestSequenceIdentificator) {
                                if (this.cancelations[httpWrapperOptions.requestSequenceIdentificator]) {
                                    this.cancelations[httpWrapperOptions.requestSequenceIdentificator](cancelRequestBecauseOfNewOne);
                                    this.cancelations[httpWrapperOptions.requestSequenceIdentificator] = null;
                                }
                                axiosRequestConfig.cancelToken = new CancelToken((c) => {
                                    this.cancelations[httpWrapperOptions.requestSequenceIdentificator] = c;
                                });
                            }
                            const axiosPromise: Promise<any> = axios(normalizedUrl, axiosRequestConfig);
                            res = await axiosPromise;
                            if (res.status === HttpStatusCode.accepted) {
                                throw new InternalError(res);
                            }
                            if (httpWrapperOptions.requestSequenceIdentificator) {
                                this.cancelations[httpWrapperOptions.requestSequenceIdentificator] = null;
                            }
                            break;
                        } catch (err) {
                            if (err.message === cancelRequestBecauseOfNewOne) {
                                res = {
                                    data: null
                                };
                                // console.warn('request canceled by next one');
                                break;
                            }

                            ++callCounter;
                            if (httpWrapperOptions.requestSequenceIdentificator) {
                                if (this.cancelations[httpWrapperOptions.requestSequenceIdentificator]) {
                                    // console.warn('request canceled by next try');
                                    this.cancelations[httpWrapperOptions.requestSequenceIdentificator]();
                                    this.cancelations[httpWrapperOptions.requestSequenceIdentificator] = null;
                                }
                            }

                            if (err.code !== 'ECONNABORTED' || callCounter > httpWrapperOptions.extraTries) {
                                throw err;
                            }
                        }
                    }
                    this.showDialog(httpWrapperOptions, true, undefined);

                    if (res && res.headers && res.headers['web-client-version']
                        && state.resources.settings
                        && res.headers['web-client-version'] !== state.resources.settings.webClientVersion
                        && ((new Date().getTime() - initializeTimestamp) > minReloadingIntervalMs)) {
                        setTimeout(() => window.location.reload(true), reloadAfterMs);
                    }
                    if (res && res.headers && res.headers['resource-version']) {
                        store.dispatch(checkVersion(+res.headers['resource-version']));
                    }
                    resolve(res.data);
                } catch (reason) {
                    const status = (reason.response && reason.response.status) ? reason.response.status : HttpStatusCode.exception;

                    if (status === HttpStatusCode.unauthorized) {
                        window.location.href = Routes.login;
                    }
                    reject(reason.response || reason);
                }
                finally {
                    if (httpWrapperOptions.wait) {
                        store.dispatch(decreaseBlockingCallCounter());
                    }
                }
            })();
        });

        return result;
    }

    private showDialog(httpWrapperOptions: HttpWrapperOptions, success: boolean, text?: string) {
        if (success) {
            if (httpWrapperOptions.success) {
                store.dispatch(newNotificationMessage(
                    {
                        message: httpWrapperOptions.successText || text || 'Какая то информация',
                        messageType: 'error',
                        title: 'Информация'
                    }
                ));
            }
        } else {
            if (httpWrapperOptions.errors) {
                store.dispatch(newNotificationMessage(
                    {
                        message: httpWrapperOptions.errorText || text || 'Неопознанная ошибка',
                        messageType: 'error',
                        title: 'Ошибка'
                    }
                ));
            }
        }
    }

    private normalizeUrl(url: string): string {
        if (!url.startsWith('http')) {
            return `${baseApiUrl}${url}`;
        } else {
            return `${url}`;
        }
    }
}
