import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { OrderType } from '../../../../../common/model/inspectionLog/OrderType';
import {
    MeterActivationOrderStatus
} from '../../../../../common/model/meter/meterActivationOrder/meterActivationOrderStatus';
import {
    isMeterActivationOrderCompleteSuccess,
    isMeterActivationOrderDeletable,
    isMeterActivationOrderReprocessable
} from '../../../../../common/shared/utils/meterActivationOrder';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Button } from '../../../../shared/components/button';
import { Forbidden } from '../../../../shared/components/forbidden';
import { Heading } from '../../../../shared/components/heading';
import { Spinner } from '../../../../shared/components/spinner';
import { Typography } from '../../../../shared/components/typography';
import { Wrapper } from '../../../../shared/components/wrapper';
import { InspectionLog } from '../../../inspectionLog';
import { InspectionLogPanel } from '../../../inspectionLog/components/inspectionLogPanel';
import { InspectionLogFilterTypes, InspectionLogTableColumns } from '../../../inspectionLog/model';
import { MeterActivationOrderLog } from './meterAcitvationOrderLog';
import { MeterActivationOrderExecuteModal } from './meterActivationOrderExecuteModal';
import { MeterActivationOrderForm } from './meterActivationOrderForm';
import { MeterActivationOrderHeader } from './meterActivationOrderHeader';
import { MeterActivationOrderMode, StateProps } from './model';

const useStyles = makeStyles((theme) => ({
    logContainer: {
        height: '100%',
    },
    formWrapper: {
        maxHeight: '850px',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '100px',
            backgroundColor: theme.palette.neutral.lightGray,
        },
    },
}));

export const View = ({
    meterActivationOrder,
    onSubmit,
    onDeleteOrder,
    onBackToList,
    breadcrumbs,
    onClickBreadcrumb,
    mode,
    loading,
    isFormDirty,
    onFormDirtyChange,
    onReprocess,
    onOpenPUPage,
    onExecuteActivationOrder,
    onCloseExecuteModal,
    showExecuteModal,
    submittedMeterActivationOrder,
    canReadMeter,
    canReadMeterActivationOrderDetails,
    canDeleteMeterActivationOrder,
    canReprocessMeterActivationOrder,
}: StateProps) => {
    const classes = useStyles();

    if (!canReadMeterActivationOrderDetails) {
        return <Forbidden />;
    }

    if (mode === MeterActivationOrderMode.Update && !meterActivationOrder && !loading) {
        return <Wrapper title='Заявка'>
            <Typography variant='body'>Заявка не найдена</Typography>
        </Wrapper>;
    }

    if (mode === MeterActivationOrderMode.Update && !meterActivationOrder && loading) {
        return <Spinner />;
    }

    const headerText = mode === MeterActivationOrderMode.Update
        ? 'Заявка на ввод в эксплуатацию ПУ'
        : 'Новая заявка';

    const noteText = meterActivationOrder
        ? `№ ${meterActivationOrder.meterActivationOrderId}`
        : null;

    const meterActivationOrderActions = <>
        {
            mode === MeterActivationOrderMode.Update
                && isMeterActivationOrderDeletable(meterActivationOrder.meterActivationOrderStatus)
                && <Button
                    variant='outline'
                    icon='delete'
                    onClick={onDeleteOrder}
                    disabled={!canDeleteMeterActivationOrder}
                >Удалить</Button>
        }

        {
            mode === MeterActivationOrderMode.Update
                && isMeterActivationOrderCompleteSuccess(meterActivationOrder.meterActivationOrderStatus)
                && meterActivationOrder.meterId
                && <Button
                    variant='primary'
                    onClick={onOpenPUPage}
                    disabled={!canReadMeter}
                >Открыть страницу ПУ</Button>
        }
    </>;

    return (
        <>
            {loading && <Spinner />}
            <Box mb={2}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2}>
                <Heading heading={headerText} note={noteText} onBackHandler={onBackToList} />
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7} lg={6} xl={8}>
                    <Grid container spacing={2}>

                        {
                            mode === MeterActivationOrderMode.Update && <Grid item sm={12}>
                                <MeterActivationOrderHeader meterActivationOrder={meterActivationOrder} />
                            </Grid>
                        }

                        <Grid item sm={12}>
                            <Wrapper
                                title='Заявка'
                                className={classes.formWrapper}
                                actions={meterActivationOrderActions}>
                                <MeterActivationOrderForm
                                    mode={mode}
                                    meterActivationOrder={meterActivationOrder}
                                    onSubmit={onSubmit}
                                    onFormDirtyChange={onFormDirtyChange}
                                />
                                {showExecuteModal && <MeterActivationOrderExecuteModal
                                    formValues={submittedMeterActivationOrder}
                                    onCloseModal={onCloseExecuteModal}
                                    onExecuteActivationOrder={onExecuteActivationOrder}
                                />
                                }
                            </Wrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={5} lg={6} xl={4}>
                    <Wrapper
                        className={classes.logContainer}
                        title='Ход процесса'
                        actions={
                            mode === MeterActivationOrderMode.Update &&
                                <Button
                                    variant='primary'
                                    icon={
                                        meterActivationOrder?.meterActivationOrderStatus === MeterActivationOrderStatus.Created
                                            ? 'play'
                                            : 'restart'
                                    }
                                    disabled={meterActivationOrder && (
                                        !isMeterActivationOrderReprocessable(meterActivationOrder.meterActivationOrderStatus)
                                        || !canReprocessMeterActivationOrder
                                        || isFormDirty
                                    )}
                                    onClick={onReprocess}
                                >{
                                        meterActivationOrder?.meterActivationOrderStatus === MeterActivationOrderStatus.Created
                                            ? 'Запустить ввод ПУ'
                                            : 'Перезапустить'
                                    }
                                </Button>
                        }>
                        <MeterActivationOrderLog meterActivationOrder={meterActivationOrder} />
                    </Wrapper>
                </Grid>
            </Grid>
            {(meterActivationOrder && meterActivationOrder?.meterId) && (
                <InspectionLogPanel>
                    <InspectionLog
                        meterId={meterActivationOrder?.meterId}
                        orderId={meterActivationOrder?.meterActivationOrderId}
                        orderType={OrderType.Activation}
                        visibleFilters={[
                            InspectionLogFilterTypes.Critical,
                            InspectionLogFilterTypes.IsMy,
                            InspectionLogFilterTypes.DateFrom,
                            InspectionLogFilterTypes.DateTo,
                            InspectionLogFilterTypes.Search,
                        ]}
                        visibleColumns={[
                            InspectionLogTableColumns.Critical,
                            InspectionLogTableColumns.Date,
                            InspectionLogTableColumns.Message,
                        ]}
                    />
                </InspectionLogPanel>
            )}
        </>
    );
};
