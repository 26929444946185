import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Tooltip } from '../../../../../shared/components/tooltip';
import { Block } from '../../../../../shared/components/wrapper/block';
import { MassOperationProgressGraphProps, ProgressColors } from './model';

const useGraphViewStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    itemsWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.neutral.background,
        borderRadius: 4,
    },
    item: {
        height: 16,
        borderRadius: 4,
        '& > span': {
            width: '100%',
            height: '100%',
        },
        '&:not(:first-child)': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        '&:not(:last-child)': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    itemRed: {
        backgroundColor: theme.palette.system.error,
    },
    itemGreen: {
        backgroundColor: theme.palette.system.success,
    },
    completedLastItem: {
        flexGrow: 1,
    },
    itemInner: {
        display: 'block',
        width: '100%',
        height: '100%',
    },
    label: {
        ...theme.typography.body,
        color: theme.palette.text.hint,
        textAlign: 'right',
        marginTop: 7,
    },
}));

const calcItemWidth = (val: number, total: number): string => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return `${Math.floor((val / total) * 100)}%`;
};

const GraphView: React.FC<Pick<MassOperationProgressGraphProps, 'dataGroups' | 'total' | 'isComplete'>> = (props) => {
    const { dataGroups, total, isComplete } = props;

    const classes = useGraphViewStyles();

    return <>
        <div className={classes.root}>
            <div className={classes.itemsWrapper}>
                {dataGroups.map((item, index) => (
                    <div
                        key={index}
                        className={classNames(classes.item, {
                            [classes.itemRed]: item.color === ProgressColors.Red,
                            [classes.itemGreen]: item.color === ProgressColors.Green,
                            [classes.completedLastItem]: isComplete && (index === dataGroups.length - 1),
                        })}
                        style={{ width: calcItemWidth(item.value, total) }}
                    >
                        <Tooltip text={item.value}>
                            <div className={classes.itemInner} />
                        </Tooltip>
                        {isComplete && (
                            <div className={classes.label}>{item.value}</div>
                        )}
                    </div>
                ))}
            </div>
            {!isComplete && (
                <div className={classes.label}>{total}</div>
            )}
        </div>
    </>;
};

export const View: React.FC<MassOperationProgressGraphProps> = (props) => {
    const {
        title,
        total,
        dataGroups,
        isComplete,
    } = props;

    return <>
        <Block
            type='horizontal'
            items={[
                {
                    title,
                    type: 'raw',
                    value: <GraphView isComplete={isComplete} dataGroups={dataGroups} total={total} />,
                }
            ]}
        />
    </>;
};
