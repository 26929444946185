import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import {
    ConfigurationHistoryReportQueryParams,
    ConfigurationHistoryReportResponse
} from '../../../../common/model/meter/reports/configurationHistoryReport';
import { configurationHistoryReportSlice } from '../../../reducers/reports/configurationHistoryReport';
import { AppThunk } from '../../index';

export const getConfigurationHistoryReport = (params: ConfigurationHistoryReportQueryParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_reports_configuration_history, {}, params);
    const data = await httpService.get<ConfigurationHistoryReportResponse>(route);
    dispatch(configurationHistoryReportSlice.actions.setReport(data));
};

export const resetConfigurationHistoryReport = (): AppThunk => (dispatch) => {
    dispatch(configurationHistoryReportSlice.actions.setReport(null));
};
