import { MeterPurposeType } from '../meterPurposeType';

export interface CrashByRegionsReportQueryParams {
    daysOffline: number;
}

export enum CrashByRegionsReportDataType {
    Region,
    RegionPurpose,
}

export interface CrashByRegionsReportData {
    index: string;
    regionId: number;
    regionName: string;
    metersCount: number;
    meterPurpose?: MeterPurposeType;
    crashMetersCount: number;
    crashMetersPercent: number;
    type: CrashByRegionsReportDataType;
}

export interface CrashByRegionsReportResponse {
    data: CrashByRegionsReportData[];
    metersCount: number;
    crashMetersCount: number;
    crashMetersPercent: number;
}
