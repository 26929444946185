import { push } from 'connected-react-router';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { MeterInformationFieldsUpdater } from '../../../../../common/model/meter/meter/meterInformationFieldsUpdater';
import { checkMeterModelMask } from '../../../../../common/shared/utils/mask';
import { BaseDispatch } from '../../../../../redux/actions';
import { getMeterSchemes } from '../../../../../redux/actions/meterSchemes';
import { updateMeterInformationFields } from '../../../../../redux/actions/meterUpdateAttributes';
import { getPartners } from '../../../../../redux/actions/partners';
import { resourcesMeterFirmwareVersionsSelector } from '../../../../../redux/selectors';
import { meterSelector, meterUiConfigSelector } from '../../../../../redux/selectors/meters';
import { partnersListSelector } from '../../../../../redux/selectors/partners';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { StateProps } from './model';
import { View } from './view';
import { actionQueryParam } from '../model';


export const MeterInformationFieldsUpdateForm = () => {

    const { meterId } = useParams<{ meterId: string }>();
    const queryParams = useQueryParams();
    const meter = useSelector(meterSelector);
    const uiConfig = useSelector(meterUiConfigSelector);
    const meterFirmwareVersionsSource = useSelector(resourcesMeterFirmwareVersionsSelector());

    const dispatch = useDispatch<BaseDispatch>();

    const { hasAccess } = useAccessRules();

    const defaultValues: MeterInformationFieldsUpdater = {
        connectionType: meter.connectionType ?? null,
        accountType: meter.accountType ?? null,
        meterPurpose: meter.meterPurpose ?? null,
        legalEntityType: meter.legalEntityType ?? null,
        priceCategory: meter.priceCategory ?? null,
        meterFirmwareVersion: meter.meterFirmwareVersion ?? null,

        meterModelId: meter.meterModelId ?? null,
        meterSerialNum: meter.meterSerialNum ?? null,
        timeZone: meter.timeZone ?? null,
        code: meter.code ?? null,
        partnerId: meter.partnerId ?? null,
    };

    const onCloseModal = () => {
        queryParams.delete(actionQueryParam);
        dispatch(push({
            search: queryParams.toString()
        }));
    };

    const onSubmit: SubmitHandler<MeterInformationFieldsUpdater> = async (values) => {
        await dispatch(updateMeterInformationFields(meterId, values));
        onCloseModal();
    };

    const form = useForm<MeterInformationFieldsUpdater>({
        defaultValues,
    });
    const partners = useSelector(partnersListSelector());

    useEffect(() => {
        dispatch(getPartners({}));
        dispatch(getMeterSchemes());
    }, []);

    const meterFirmwareVersions = useMemo(() => {
        return meterFirmwareVersionsSource
            .filter(item => checkMeterModelMask(item.meterModelMask, meter.meterModel));
    }, [meterFirmwareVersionsSource, meter.meterModel]);

    const props: StateProps = {
        form,
        partners,
        uiConfig,
        onSubmit,
        onCancel: onCloseModal,
        meterFirmwareVersions,
        isMeterHasImport: meter.isMeterHasImport,
        hasAccess: hasAccess(AccessRule.CanUpdateMeterInformationFields),
    };

    return View(props);
};
