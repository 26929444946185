import React, { useEffect, useState } from 'react';
import { GlobalKeyboardListenerController, KeyboardListener, KeyCodes } from '../globalKeyboardListener';
import { InputModel, IProps } from './model';
import { View } from './view';

const getFocusableElements = (target: Element): NodeListOf<HTMLElement> => (
    target.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
);

let latestModalIndex = 0;

export const Modal: React.FC<InputModel> = (ownProps) => {
    const modalNodeEl = React.useRef<HTMLDivElement>(null);
    const root = document.getElementById('root');
    const [modalIndex] = useState<number>(() => {
        latestModalIndex++;
        return latestModalIndex;
    });
    const handleFocusIn = ({ target }: any) => {
        if (modalNodeEl.current && root.contains(target) && !modalNodeEl.current.contains(target) && modalIndex === latestModalIndex) {
            const focusableElements = getFocusableElements(modalNodeEl.current);
            if (focusableElements.length) {
                (focusableElements[0]).focus();
            } else {
                if (document.activeElement) {
                    (document.activeElement as HTMLElement).blur();
                }
            }
        }
    };

    const fieldsForm = {};

    useEffect(() => {
        const escapeListener: KeyboardListener = async (e) => {
            if (e.keyCode === KeyCodes.Escape) {
                if (modalIndex === latestModalIndex) {
                    if (ownProps.onHide) {
                        e.preventDefault();
                        e.stopPropagation();
                        ownProps.onHide();
                        return false;
                    } else if (ownProps.actions) {
                        const action = ownProps.actions.find(a => a.onEscape);
                        if (action) {
                            e.preventDefault();
                            e.stopPropagation();
                            await action.onClick();
                            return false;
                        }
                    }
                }
            }
            return true;
        };
        const enterListener: KeyboardListener = async (e) => {
            if (e.keyCode === KeyCodes.Enter) {
                if (modalIndex === latestModalIndex) {
                    if (ownProps.actions) {
                        const action = ownProps.actions.find(a => a.onEnter);
                        if (action) {
                            e.preventDefault();
                            e.stopPropagation();
                            await action.onClick();
                            return false;
                        }
                    }
                }
            }
            return true;
        };
        GlobalKeyboardListenerController.addEventListener('keyup', escapeListener);
        GlobalKeyboardListenerController.addEventListener('keyup', enterListener);
        return () => {
            GlobalKeyboardListenerController.removeEventListener('keyup', escapeListener);
            GlobalKeyboardListenerController.removeEventListener('keyup', enterListener);
        };
    }, []);

    const focusFirstFocusableModalElement = () => {
        const focusableElements = getFocusableElements(modalNodeEl.current);
        if (focusableElements.length) {
            focusableElements[0].focus();
        }
    };

    React.useEffect(() => {
        document.addEventListener('focusin', handleFocusIn);
        focusFirstFocusableModalElement();
        return () => {
            latestModalIndex--;
            document.removeEventListener('focusin', handleFocusIn);
        };
    }, []);

    const props: IProps = {
        size: 'md',
        show: true,
        type: 'default',
        ...ownProps,
        modalNodeEl,
        fieldsForm,
    };

    return View(props);
};
