import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';


export const metersSelector = createSelector(
    (state: StorageState) => state.meters.list,
    list => list.available
);

export const metersSearchSelector = createSelector(
    (state: StorageState) => state.meters.list,
    list => list.search
);

export const metersSearchTotalSelector = createSelector(
    (state: StorageState) => state.meters.list,
    list => list.searchTotal
);

export const staticMeterGroupContentsSelector = createSelector(
    (state: StorageState) => state.meters.details,
    list => list.staticMeterGroupContents
);

export const dynamicMeterGroupParamsSelector = createSelector(
    (state: StorageState) => state.meters.details,
    list => list.dynamicMeterGroupParams
);

export const dynamicMeterGroupAddressesSelector = createSelector(
    (state: StorageState) => state.meters.details,
    list => list.dynamicMeterGroupAddresses
);

export const meterSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meter
);

export const meterUpdateTechnicalParamsOrderSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterUpdateTechnicalParamsOrder
);

export const meterUiConfigSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterUiConfig
);

export const meterGroupsSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterGroups
);

export const nameListGroupsMeterSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.nameListGroupsMeter
);


export const meterCommunicatorInformationSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.communicatorInformation
);

export const meterLatestProfileSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.latestProfile
);

export const meterActionPlanSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterActionPlan
);

export const getMeterActionPlanSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterActionPlan
);


export const meterGisSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterGis
);

export const meterLabelsSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterLabels?.data
);

export const meterLabelsTotalSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterLabels?.total
);

export const meterConfigurationHistorySelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterConfigurationHistory
);

export const meterConfigurationHistoryTotalSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterConfigurationHistoryTotal
);

export const meterUpdateLoadingSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterUpdateLoading
);

export const meterConsumptionElectricitySelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterConsumptionElectricity
);

export const meterConsumptionElectricityTariffSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterConsumptionElectricityTariff
);

export const meterPowerSwitchSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.powerSwitch
);

export const meterTariffSchedulesSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterTariffSchedules
);

export const meterTariffScheduleInOrderSelector = createSelector(
    (state: StorageState) => state.meters.details,
    details => details.meterTariffScheduleInOrder
);
