export interface ISort {
    count?: number;
    offset?: number;
    sortFieldName?: string;
    asc?: boolean;
}

export class Sort implements ISort {

    public count?: number;

    public offset?: number;

    public sortFieldName?: string;

    private acceptableFields?: string[] = [];

    public asc?: boolean;
    constructor(model?: ISort) {
        if (model) {
            if (model.count !== undefined) {
                if ( model.count === null) {
                    this.count = null;
                } else {
                    this.count = +model.count;
                }
            }
            if (model.offset !== undefined) {
                if ( model.offset === null) {
                    this.offset = null;
                } else {
                    this.offset = +model.offset;
                }
            }
            if (model.sortFieldName !== undefined) {
                this.sortFieldName = model.sortFieldName;
            }
            if (model.asc !== undefined) {
                this.asc = (model.asc.toString() === 'true');
            }
        }
    }

    public setAndCheckAcceptableSortFields(fields: string[]) {
        this.acceptableFields = fields;
        if (
            Array.isArray(this.acceptableFields)
            && !this.acceptableFields.includes(this.sortFieldName)
        ) {
            this.sortFieldName = undefined;
        }
    }

}
