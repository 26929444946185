import { MeterLabelTypeViewModel } from '../../../../common/model/meter/meterLabelType/meterLabelTypeViewModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MeterLabelTypeState {
    available: MeterLabelTypeViewModel[];
}

const initialState: MeterLabelTypeState = {
    available: [],
};

export const meterLabelTypeListSlice = createSlice({
    name: '@@vv/meterLabelType/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<MeterLabelTypeViewModel[]>): void {
            state.available = action.payload;
        },
        update(state, { payload }: PayloadAction<MeterLabelTypeViewModel>): void {
            if (state.available) {
                state.available = state.available.map(item => {
                    if (item.id === payload.id) {
                        return payload;
                    }
                    return item;
                });
            }
        }
    },
});
