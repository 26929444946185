import { useState } from 'react';
import { AddressViewModel } from '../../../../common/model/meter/addressViewModel/addressViewModel';
import { WithCreateAddress } from './model';

export const useCreateAddress = (onCreateAddress: (address: AddressViewModel) => void): WithCreateAddress => {
    const [isCreateAddressFormOpen, setIsCreateAddressFormOpen] = useState(false);
    return {
        onCreateAddress,
        isCreateAddressFormOpen,
        onCloseCreateAddressForm: () => {
            setIsCreateAddressFormOpen(false);
        },
        onOpenCreateAddressForm: () => {
            setIsCreateAddressFormOpen(true);
        },
    };
};
