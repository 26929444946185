import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { MeterActionType } from '../../../../common/model/meter/meterActionType';
import { InspectionLog } from '../../inspectionLog';
import { InspectionLogPanel } from '../../inspectionLog/components/inspectionLogPanel';
import { Props } from './model';
import { PasswordManagement } from './passwordManagement';
import { MeterPowerSwitchManagement } from './powerSwitch';
import { TariffScheduleManagement } from './tariffScheduleManagement';

export const View: React.FC<Props> = ({ meterId }) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={4}>
                    <Box mb={2}>
                        <MeterPowerSwitchManagement meterId={meterId} />
                    </Box>
                    <Box>
                        <TariffScheduleManagement meterId={meterId} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} lg={4}>
                    <Box>
                        <PasswordManagement meterId={meterId} />
                    </Box>
                </Grid>
            </Grid>
            <InspectionLogPanel>
                <InspectionLog
                    meterId={meterId}
                    meterActionType={[
                        MeterActionType.PowerSwitchControl,
                        MeterActionType.ValidateOrChangeTariff,
                    ]}
                />
            </InspectionLogPanel>
        </>
    );
};
