import { Box, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import * as _ from 'lodash';
import React, { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { CloseIcon } from '../../../../assets/icons';
import { DateFormats } from '../../../../common/constants/date';
import { meterLegalTypeDictionary } from '../../../../common/model/meter/legalEntityType';
import {
    TariffScheduleVersionStatus,
    tariffScheduleVersionStatusDictionary
} from '../../../../common/model/meter/tariffScheduleVersionStatus';
import { getRange } from '../../../shared';
import { Badge } from '../../../shared/components/badge';
import { Breadcrumbs } from '../../../shared/components/breadcrumbs';
import { Button } from '../../../shared/components/button';
import { Heading } from '../../../shared/components/heading';
import { HookField } from '../../../shared/components/hookField';
import { Loader } from '../../../shared/components/loader';
import { Modal } from '../../../shared/components/modal';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { Wrapper } from '../../../shared/components/wrapper';
import { Block } from '../../../shared/components/wrapper/block';
import { BlockPropsItem } from '../../../shared/components/wrapper/block/model';
import { Typography } from '../../../shared/components/typography';
import { getIndexesById } from '../../../shared/pipes';
import { spreadItemsByCondition } from '../../../shared/pipes/spreadItemsByCondition';
import { formatDate } from '../../../shared/utils/dates';
import { TariffScheduleForm } from '../tariffSchedules/components/tariffScheduleForm';
import { extractTariffScheduleVersionCommonProps } from './common';
import { TariffDayCard } from './components/tariffDayCard';
import { TariffDayCardInputProps } from './components/tariffDayCard/model';
import { TariffDayNamesForm } from './components/tariffDayNamesForm';
import { TariffSeasonStartSelect } from './components/tariffSeasonStartSelect';
import { startDateSuggestions } from './components/tariffSeasonStartSelect/suggestions';
import { TariffZonesTooltip } from './components/tariffZonesTooltip';
import { sortTariffDayCards, sortTariffSeason } from './helpers';
import { IStateProps } from './model';

// TODO: убрать когда сервера опроса научатся это обрабатывать
const showSpecialDays = false;

export const View: React.FC<IStateProps> = (props) => {

    const {
        form,
        heading,
        formData,
        breadcrumbs,
        specialDaysFormArray,
        tariffSeasonsFormArray,
        onBackHandler,
        onClickBreadcrumb,
        onClickAddTariffDay,
        onClickAddTariffSeason,
        onClickAddSpecialDay,
        tariffDaysFormArray,
        isTariffDayNamesOpen,
        onSubmitTariffDayNames,
        onClickEditTariffDayNames,
        onCancelEditTariffDayNames,
        onClickDeleteTariffDay,
        onClickDeleteTariffSeason,
        onClickDeleteSpecialDay,
        onClickDeleteTariffZone,
        regionsSuggestions,
        tariffSchedule,
        onClickAddZoneToDay,
        onClickSaveAsDraft,
        onClickApply,
        onClickDuplicate,
        isDuplicateWindowOpen,
        onCancelDuplicate,
        onSubmitDuplicate,
        onClickClearSpecialDays,
        onClickFillSpecialDaysBase,
        isLoading,
    } = props;

    const commonProps = extractTariffScheduleVersionCommonProps(props);

    const {
        isNewRecord,
        isEditMode,
    } = commonProps;

    const tariffDayNamesById = useMemo(() => _.keyBy(formData.tariffDayNames, 'id'), [formData.tariffDayNames]);
    const tariffDaysById = useMemo(() => _.keyBy(formData.tariffDays, 'id'), [formData.tariffDays]);
    const tariffZonesByDayId = useMemo(() => _.groupBy(formData.tariffZones, 'tariffDayId'), [formData.tariffZones]);
    const tariffZonesIndexById = useMemo(() => getIndexesById(formData.tariffZones), [formData.tariffZones]);

    const tariffDayNameSuggestions: SelectSuggestion[] = useMemo(() => formData.tariffDayNames.map((item) => ({
        label: item.name,
        value: item.id,
    })).sort((a, b) => {
        return tariffDayNamesById[a.value]?.code > tariffDayNamesById[b.value]?.code ? 1 : -1;
    }), [formData.tariffDayNames]);

    const tariffDaysSuggestions: SelectSuggestion[] = useMemo(() => formData.tariffDays.map((item) => ({
        label: tariffDayNamesById[item.tariffDayNameId]?.name,
        value: item.id,
    })).sort((a, b) => {
        return tariffDayNamesById[
            tariffDaysById[a.value]?.tariffDayNameId
        ]?.code > tariffDayNamesById[
            tariffDaysById[b.value]?.tariffDayNameId
        ]?.code
            ? 1
            : -1;
    }), [tariffDayNamesById, formData.tariffDays]);

    const tariffDayNameByTariffDayId = useMemo(() => {
        return _.keyBy(tariffDaysSuggestions, 'value');
    }, [tariffDayNamesById, tariffDaysById]);

    const region = useMemo(
        () => regionsSuggestions.find(item => item.value === tariffSchedule.regionId),
        [tariffSchedule, regionsSuggestions]
    );

    const startDateSuggestionsByKey = useMemo(
        () => _.keyBy(startDateSuggestions, 'value'),
        [],
    );

    const tariffCountSelections: SelectSuggestion[] = getRange(1, tariffSchedule.tariffCount).map<SelectSuggestion>((item) => ({
        label: item.toString(),
        value: item,
    }));

    const tariffDayCards: TariffDayCardInputProps[] = useMemo(() => {
        const selectedTariffDayNameIds = formData.tariffDays.map(item => item.tariffDayNameId);
        return tariffDaysFormArray.fields.map((item, index) => ({
            ...commonProps,
            id: item.id,
            tariffCountSelections,
            tariffDay: tariffDaysById[item.id],
            nameSuggestions: tariffDayNameSuggestions.filter(tdn => {
                if (tariffDaysById[item.id]?.tariffDayNameId === tdn.value) {
                    return true;
                }
                return !selectedTariffDayNameIds.includes(tdn.value);
            }),
            name: `tariffDays.${index}.tariffDayNameId`,
            onClickDelete: () => {
                onClickDeleteTariffDay(index);
            },
            onClickAddZone: () => {
                onClickAddZoneToDay(tariffDaysById[item.id]);
            },
            tariffZones: (tariffZonesByDayId[item.id] ?? []).map((zoneItem) => ({
                name: `tariffZones.${tariffZonesIndexById[zoneItem.id]}`,
                zone: zoneItem,
                onClickDelete: () => {
                    onClickDeleteTariffZone(tariffZonesIndexById[zoneItem.id]);
                },
            })),
        })).sort(sortTariffDayCards(tariffDayNamesById));
    }, [
        tariffDaysFormArray.fields,
        tariffZonesByDayId,
        tariffDaysById,
        tariffDayNameSuggestions,
        tariffDayNamesById,
    ]);

    const tariffDaysActions = (
        <>
            <Grid container spacing={2} justify='flex-end'>
                {isEditMode && (
                    <Grid item>
                        <Button
                            icon='add'
                            type='button'
                            variant='outline'
                            onClick={onClickAddTariffDay}
                        >
                            Добавить
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Button
                        type='button'
                        icon='settings'
                        variant='secondary'
                        onClick={onClickEditTariffDayNames}
                    >
                        Справочник
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    const specialDaysActions = (
        <>
            <Grid container spacing={2} justify='flex-end'>
                {isEditMode && (
                    <Grid item>
                        <Button
                            type='button'
                            icon='calendar'
                            variant='outline'
                            onClick={onClickFillSpecialDaysBase}
                        >
                            Загрузить базовый календарь
                        </Button>
                    </Grid>
                )}
                {isEditMode && (
                    <Grid item>
                        <Button
                            icon='close'
                            type='button'
                            variant='secondary'
                            onClick={onClickClearSpecialDays}
                        >
                            Очистить
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    );

    const headingWrapped = (
        <>
            {heading}
            <Box ml={2} component='span'>
                <Badge
                    showIcon={false}
                    text={tariffScheduleVersionStatusDictionary[formData.status]}
                    state={formData.status === TariffScheduleVersionStatus.Draft ? 'notDetermined' : 'success'}
                />
            </Box>
        </>
    );

    if (isLoading) {
        return <Box display='flex' justifyContent='center'>
            <Loader visible={true} size={50} />
        </Box>;
    }

    return (
        <>
            <FormProvider {...form}>
                <Box mb={2}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
                </Box>
                <Box mb={2}>
                    <Grid container spacing={2} justify='space-between'>
                        <Grid item>
                            <Heading heading={headingWrapped} onBackHandler={onBackHandler} />
                        </Grid>
                        {!isEditMode && (
                            <Grid item>
                                <Button
                                    type='button'
                                    icon='duplicate'
                                    variant='outline'
                                    onClick={onClickDuplicate}
                                >
                                    Дублировать
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Wrapper
                        title='Общие параметры'
                    >
                        <Grid container spacing={2}>
                            {/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
                            <Grid item md={isEditMode ? 5 : 8}>
                                <Block
                                    type='horizontal'
                                    items={[
                                        {
                                            type: 'text',
                                            title: 'Регион',
                                            value: region?.label ?? '–',
                                        },
                                        {
                                            type: 'text',
                                            title: 'Количество тарифов',
                                            value: tariffSchedule.tariffCount ?? '–',
                                        },
                                        {
                                            type: 'text',
                                            title: 'Правовая форма',
                                            value: meterLegalTypeDictionary[tariffSchedule.legalEntityType] ?? '–',
                                        },
                                        ...spreadItemsByCondition<BlockPropsItem>(
                                            !isEditMode,
                                            [
                                                {
                                                    type: 'text',
                                                    title: 'Дата ввода в действие',
                                                    value: formData?.startDate
                                                        ? formatDate(formData.startDate, DateFormats.dateFormat)
                                                        : '–',
                                                },
                                            ]
                                        )
                                    ]}
                                />
                            </Grid>
                            <Grid item md={3}>
                                {isEditMode && (
                                    <HookField
                                        fullWidth
                                        name='startDate'
                                        type='datepicker'
                                        label='Дата ввода в действие'
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Wrapper>
                </Box>
                <Box mb={2}>
                    <Wrapper
                        title='Суточные тарифные расписания'
                        actions={tariffDaysActions}
                    >
                        <Grid container spacing={2}>
                            {tariffDayCards.map((itemProps) => (
                                <Grid item key={itemProps.id} xs={12} sm={6} md={4} lg={3}>
                                    <TariffDayCard
                                        {...itemProps}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Wrapper>
                </Box>
                <Box mb={2}>
                    <Grid container spacing={3}>
                        {/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
                        <Grid item xs={12} md={showSpecialDays ? 6 : 12}>
                            <Wrapper
                                title='Сезонные тарифные расписания'
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '20%' }} padding='none'>Начало сезона</TableCell>
                                            <TableCell style={{ width: '35%' }} padding='none'>Рабочие дни</TableCell>
                                            <TableCell style={{ width: '35%' }} padding='none'>Нерабочие дни</TableCell>
                                            <TableCell style={{ width: '10%' }} padding='none' />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tariffSeasonsFormArray.fields
                                            .map((field, index) => ({ field, index }))
                                            .sort(sortTariffSeason(formData))
                                            .map(({ field, index }) => (
                                                <TableRow key={field.id}>
                                                    <TableCell padding='none'>
                                                        {isEditMode && (
                                                            <TariffSeasonStartSelect
                                                                season={formData.tariffSeasons[index]}
                                                                onChange={([startDay, startMonth]) => {
                                                                    const { tariffSeasons } = formData;
                                                                    form.setValue(
                                                                        'tariffSeasons',
                                                                        tariffSeasons.map((season, i) => {
                                                                            if (index === i) {
                                                                                return {
                                                                                    ...season,
                                                                                    startDay,
                                                                                    startMonth,
                                                                                };
                                                                            }
                                                                            return season;
                                                                        })
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                        {!isEditMode && (
                                                        // eslint-disable-next-line max-len
                                                            startDateSuggestionsByKey[
                                                            // eslint-disable-next-line max-len
                                                                `${formData.tariffSeasons[index].startDay}:${formData.tariffSeasons[index].startMonth}`
                                                            ]?.label
                                                        )}
                                                    </TableCell>
                                                    <TableCell padding='none'>
                                                        <TariffZonesTooltip
                                                            tariffZones={formData.tariffZones}
                                                            dayId={formData.tariffSeasons[index].workingTariffDayId}
                                                        >
                                                            <>
                                                                {isEditMode && (
                                                                    <HookField
                                                                        fullWidth
                                                                        label={null}
                                                                        placeholder='Не указано'
                                                                        name={`tariffSeasons.${index}.workingTariffDayId`}
                                                                        type='select'
                                                                        suggestions={tariffDaysSuggestions}
                                                                        variant='standard'
                                                                    />
                                                                )}
                                                                {!isEditMode && (
                                                                    tariffDayNameByTariffDayId[
                                                                        formData.tariffSeasons[index].workingTariffDayId
                                                                    ]?.label
                                                                )}
                                                            </>
                                                        </TariffZonesTooltip>
                                                    </TableCell>
                                                    <TableCell padding='none'>
                                                        <TariffZonesTooltip
                                                            tariffZones={formData.tariffZones}
                                                            dayId={formData.tariffSeasons[index].weekendTariffDayId}
                                                        >
                                                            <>
                                                                {isEditMode && (
                                                                    <HookField
                                                                        fullWidth
                                                                        label={null}
                                                                        placeholder='Не указано'
                                                                        name={`tariffSeasons.${index}.weekendTariffDayId`}
                                                                        type='select'
                                                                        suggestions={tariffDaysSuggestions}
                                                                        variant='standard'
                                                                    />
                                                                )}
                                                                {!isEditMode && (
                                                                    tariffDayNameByTariffDayId[
                                                                        formData.tariffSeasons[index].weekendTariffDayId
                                                                    ]?.label
                                                                )}
                                                            </>
                                                        </TariffZonesTooltip>
                                                    </TableCell>
                                                    <TableCell padding='none'>
                                                        {isEditMode && (
                                                            <IconButton
                                                                size='small'
                                                                type='button'
                                                                onClick={() => onClickDeleteTariffSeason(index)}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        {isEditMode && (
                                            <TableRow>
                                                <TableCell colSpan={4} padding='none'>
                                                    <Button
                                                        onClick={onClickAddTariffSeason}
                                                        variant='text'
                                                        type='button'
                                                        icon='add'
                                                    >
                                                        Добавить
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Wrapper>
                        </Grid>
                        {showSpecialDays && (
                            <Grid item xs={12} md={6}>
                                <Wrapper
                                    title='Специальные дни'
                                    actions={specialDaysActions}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: '25%' }} padding='none'>Дата</TableCell>
                                                <TableCell style={{ width: '30%' }} padding='none'>Название</TableCell>
                                                <TableCell style={{ width: '35%' }} padding='none'>Суточное тарифное расписание</TableCell>
                                                <TableCell style={{ width: '10%' }} padding='none' />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {specialDaysFormArray.fields.map((field, index) => (
                                                <TableRow key={field.id}>
                                                    <TableCell padding='none'>
                                                        {isEditMode && (
                                                            <HookField
                                                                fullWidth
                                                                placeholder='Не выбрано'
                                                                name={`specialDays.${index}.date`}
                                                                type='date'
                                                                variant='standard'
                                                            />
                                                        )}
                                                        {!isEditMode && (
                                                            formatDate(
                                                                new Date(formData.specialDays[index].date),
                                                                DateFormats.dateFormat,
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell padding='none'>
                                                        {isEditMode && (
                                                            <HookField
                                                                fullWidth
                                                                type='text'
                                                                variant='standard'
                                                                placeholder='Не указано'
                                                                name={`specialDays.${index}.name`}
                                                            />
                                                        )}
                                                        {!isEditMode && (
                                                            formData.specialDays[index].name
                                                        )}
                                                    </TableCell>
                                                    <TableCell padding='none'>
                                                        <TariffZonesTooltip
                                                            tariffZones={formData.tariffZones}
                                                            dayId={formData.specialDays[index].tariffDayId}
                                                        >
                                                            <>
                                                                {isEditMode && (
                                                                    <HookField
                                                                        fullWidth
                                                                        label={null}
                                                                        type='select'
                                                                        variant='standard'
                                                                        placeholder='Не указано'
                                                                        suggestions={tariffDaysSuggestions}
                                                                        name={`specialDays.${index}.tariffDayId`}
                                                                    />
                                                                )}
                                                                {!isEditMode && (
                                                                    tariffDayNameByTariffDayId[
                                                                        formData.specialDays[index].tariffDayId
                                                                    ]?.label
                                                                )}
                                                            </>
                                                        </TariffZonesTooltip>
                                                    </TableCell>
                                                    <TableCell padding='none'>
                                                        {isEditMode && (
                                                            <IconButton onClick={() => onClickDeleteSpecialDay(index)} size='small' type='button'>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {isEditMode && (
                                                <TableRow>
                                                    <TableCell colSpan={4} padding='none'>
                                                        <Button
                                                            onClick={onClickAddSpecialDay}
                                                            variant='text'
                                                            type='button'
                                                            icon='add'
                                                        >
                                                            Добавить
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Wrapper>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                {isEditMode && (
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    type='button'
                                    variant='primary'
                                    onClick={onClickSaveAsDraft}
                                >
                                    Сохранить как черновик
                                </Button>
                            </Grid>
                            {!isNewRecord && (
                                <Grid item>
                                    <Button
                                        type='button'
                                        variant='secondary'
                                        onClick={onClickApply}
                                    >
                                        Применить
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                )}
            </FormProvider>
            {isTariffDayNamesOpen && (
                <Modal
                    size='md'
                    title='Суточные тарифные расписания'
                    onHide={onCancelEditTariffDayNames}
                >
                    <TariffDayNamesForm
                        {...(commonProps)}
                        viewModel={{ tariffDayNames: formData.tariffDayNames }}
                        onSubmit={onSubmitTariffDayNames}
                        tariffScheduleVersionId={formData.id}
                        onCancel={onCancelEditTariffDayNames}
                    />
                </Modal>
            )}
            {isDuplicateWindowOpen && (
                <Modal
                    title='Дублирование тарифного расписания'
                    onHide={onCancelDuplicate}
                >
                    <Box mb={3}>
                        <Typography variant='body'>
                            Укажите параметры нового тарифного расписания
                        </Typography>
                    </Box>
                    <TariffScheduleForm
                        viewModel={tariffSchedule}
                        onCancel={onCancelDuplicate}
                        onSubmit={onSubmitDuplicate}
                    />
                </Modal>
            )}
        </>
    );
};
