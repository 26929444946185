import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import { MeterReadingInstantOrderViewModel } from '../../../../common/model/meter/meter/meterReadingInstantOrderViewModel';
import { MeterReadingInstantProfileViewModel } from '../../../../common/model/meter/meter/meterReadingInstantProfileViewModel';
import { meterReadingInstantSlice } from '../../../reducers/meterReading/instantProfiles';
import { AppThunk } from '../../index';

export const getMeterReadingInstantOrder = (
    meterId: string
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_reading_instant_order, { meterId });
    const data = await httpService.get<MeterReadingInstantOrderViewModel>(route);
    dispatch(meterReadingInstantSlice.actions.setOrder(data));
};

export const createMeterReadingInstantOrder = (
    meterId: string
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_post_meter_reading_instant_order, { meterId });
    const createdOrder = await httpService.post<MeterReadingInstantOrderViewModel>(route);
    dispatch(meterReadingInstantSlice.actions.setOrder(createdOrder));
};

export const getMeterReadingInstantProfile = (
    meterId: string
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_reading_instant_profile, { meterId });
    const profile = await httpService.get<MeterReadingInstantProfileViewModel>(route);
    if (profile) {
        dispatch(meterReadingInstantSlice.actions.setReading(profile));
    }
};

export const resetMeterReadingInstantOrder = (): AppThunk => (dispatch) => {
    dispatch(meterReadingInstantSlice.actions.setOrder(null));
};

export const resetMeterReadingInstantProfile = (): AppThunk => (dispatch) => {
    dispatch(meterReadingInstantSlice.actions.setReading(null));
};
