import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import {
    CrashByRegionsReportData,
    CrashByRegionsReportDataType
} from '../../../../../common/model/meter/reports/crashByRegionsReport';
import { Button } from '../../../../shared/components/button';
import { Heading } from '../../../../shared/components/heading';
import { HookField } from '../../../../shared/components/hookField';
import { setOrderTransformer } from '../../../../shared/components/pagination/helpers';
import { Table } from '../../../../shared/components/table';
import { Tables } from '../../../../shared/constants';
import { Wrapper } from '../../../../shared/components/wrapper';
import { IStateProps } from './model';
import { FormProvider } from 'react-hook-form';
import { Typography } from '../../../../shared/components/typography';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';

export const RenderFieldView = (
    data: CrashByRegionsReportData, fieldName: keyof CrashByRegionsReportData
): React.ReactNode => {
    return data.type === CrashByRegionsReportDataType.Region ? <strong>{data[fieldName]}</strong> : data[fieldName];
};

export const RenderRegionFieldView = (
    data: CrashByRegionsReportData
): React.ReactNode => {
    return data.type === CrashByRegionsReportDataType.Region
        ? <strong>{data.regionName}</strong>
        : data.meterPurpose ?? 'Не указано';
};

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: 0,
    },
    noDataText: {
        ...theme.typography.body,
        color: theme.palette.text.hint,
    },
    tableHeader: {
        padding: '16px',
    },
    tableContainer: {
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
        },
    },
}));

export const View = (props: IStateProps) => {
    const {
        data,
        form,
        columns,
        setOrder,
        isLoading,
        breadcrumbs,
        orderParams,
        queryParams,
        isReportFormed,
        onClickRefresh,
        onClickFormExcel,
        onClickBreadcrumb,
        onClickFormReport,
        headingOnBackHandler,
        isExcelExportNotAvailable,
    } = props;

    const classes = useStyles();

    const { daysOffline } = queryParams;

    const title = `Количество ПУ, для которых нет показаний более ${daysOffline} суток`;

    const filterRender = <>
        <FormProvider {...form}>
            <Grid container spacing={2}>
                <Grid item>
                    <HookField
                        type='number'
                        name='daysOffline'
                        label='Нет показаний, дней'
                        disabled={isLoading}
                        fullWidth />
                </Grid>
                {isReportFormed && (
                    <>
                        <Grid item>
                            <Button
                                onClick={onClickRefresh}
                                variant='outline'
                                square
                                icon='refresh'
                                type='button'
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClickFormExcel}
                                variant='primary'
                                icon='excel'
                                type='button'
                                disabled={isExcelExportNotAvailable || isLoading}
                            >
                                Выгрузить в Excel
                            </Button>
                        </Grid>
                    </>
                )}
                {!isReportFormed && (
                    <Grid item>
                        <Button
                            onClick={onClickFormReport}
                            variant='primary'
                            type='button'
                            disabled={isLoading}
                        >
                            Сформировать
                        </Button>
                    </Grid>
                )}
            </Grid>
        </FormProvider>
    </>;

    const isTableAvailable = data.length > 0 && !isLoading;

    return <>
        <Box mb={2}>
            <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
        </Box>
        <Box mb={2}>
            <Heading heading='Отчёт по количеству сбоев по всем регионам' onBackHandler={headingOnBackHandler} />
        </Box>
        <Wrapper isBigHeader actions={filterRender} childrenWrapperClassName={classes.wrapper}>
            {isTableAvailable && (
                <Box p={2}>
                    <Typography variant='h3' className={classes.tableHeader}>{title}</Typography>
                    <Box padding='17px 16px 16px 16px' className={classes.tableContainer}>
                        <Table
                            name={Tables.notConnectedMetersReportTable}
                            columns={columns}
                            data={data || []}
                            title={''}
                            isLoading={isLoading}
                            options={{
                                paging: 'classic',
                                pageSize: 500,
                                grouping: false,
                                columnsButton: false,
                                filtering: false,
                                search: false,
                                toolbar: false,
                            }}
                            onOrderChange={setOrderTransformer(columns, setOrder)}
                            {...orderParams}
                        />
                    </Box>
                </Box>
            )}
            {!isTableAvailable && (
                <Box p={2}>
                    <Box className={classes.noDataText}>
                        <div>Нет данных</div>
                    </Box>
                </Box>
            )}
        </Wrapper>
    </>;
};
