import React from 'react';
import { IStateProps } from './model';
import { Box, makeStyles } from '@material-ui/core';
import { Breadcrumbs } from '../../../shared/components/breadcrumbs';
import { Heading } from '../../../shared/components/heading';
import { Table } from '../../../shared/components/table';
import { Wrapper } from '../../../shared/components/wrapper';
import { Modal } from '../../../shared/components/modal';
import { EditLabelTypeForm } from './editLabelTypeForm';

const useStyles = makeStyles(() => ({
    tableRow: {
        '&:not(:hover) button': {
            visibility: 'hidden',
        },
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const {
        data,
        columns,
        breadcrumbs,
        heading,
        editableLabel,
        onCancelEditingLabel,
        onClickBreadcrumb,
        onBackHandler
    } = props;
    const classes = useStyles();

    return (
        <>
            <Box mb={3}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={3}>
                <Heading heading={heading} onBackHandler={onBackHandler} />
            </Box>
            <Wrapper>
                <Table
                    data={data}
                    title={null}
                    variant='white'
                    columns={columns}
                    options={{
                        paging: 'disabled',
                        search: false,
                        toolbar: false,
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        sorting: true,
                        showTitle: false,
                        rowClassName: classes.tableRow,
                    }}
                />
            </Wrapper>
            {editableLabel && (
                <Modal
                    alignDialog='center'
                    title='Описание типа метки'
                    size='md'
                    close='Отмена'
                    onHide={onCancelEditingLabel}
                >
                    <EditLabelTypeForm
                        editableLabel={editableLabel}
                        onCancel={onCancelEditingLabel}
                    />
                </Modal>
            )}
        </>
    );
};
