/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import React from 'react';
import * as d3 from 'd3';
import { useDebounce } from './useDebounce';

export type RenderChartSelection = d3.Selection<any, unknown, any, any>;

export const useD3 = (renderChartFn: (selection: RenderChartSelection, theme: Theme) => void, dependencies: any[]) => {
    const ref = React.useRef();

    const theme = useTheme();

    const debouncedDeps = useDebounce(dependencies, 5);

    React.useEffect(() => {
        renderChartFn(d3.select(ref.current), theme);
        return () => {
            // do nothing
        };
    }, debouncedDeps);
    return ref;
};

export interface DrawArrowOptions {
    selection: RenderChartSelection;
    arrowSize: number;
    coordinates: [number, number][];
    color: string;
    arrowStrokeWidth?: number;
    id: string;
}

export const drawLineArrow = ({
    selection,
    arrowSize,
    coordinates,
    color,
    arrowStrokeWidth = 2,
    id = '',
}: DrawArrowOptions) => {
    const markerBoxWidth = arrowSize;
    const markerBoxHeight = arrowSize;
    const arrowPoints: [number, number][] = [[0, 0], [0, markerBoxHeight], [markerBoxWidth, markerBoxHeight / 2]];

    selection
        .append('defs')
        .append('marker')
        .attr('id', id)
        .attr('viewBox', `0, 0, ${markerBoxWidth + 5}, ${markerBoxHeight + 5}`)
        .attr('refX', markerBoxWidth / 2)
        .attr('refY', markerBoxHeight / 2)
        .attr('markerWidth', markerBoxWidth)
        .attr('markerHeight', markerBoxHeight)
        .attr('orient', 'auto-start-reverse')
        .append('path')
        .attr('d', d3.line()(arrowPoints))
        .attr('fill', color)
        .attr('stroke', color);

    selection
        .append('path')
        .attr('d', d3.line()(coordinates))
        .attr('stroke', color)
        .attr('stroke-width', arrowStrokeWidth)
        .attr('marker-end', `url(#${id})`)
        .attr('fill', 'none');
};
/* eslint-enable @typescript-eslint/no-magic-numbers */
