import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { TimeWindowOwnProps } from './model';
import { TimePeriod } from './timePeriod';
import { Typography } from '../typography';
import { Theme } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    addPeriodText: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginTop: '8px',
    },
    addPeriodTextDisabled: {
        color: theme.palette.text.disabled,
        pointerEvents: 'none'
    }
}));


export const View = (props: TimeWindowOwnProps) => {
    const { periods, updatePeriod, createPeriod, removePeriod, disabled } = props;

    const classes = useStyles();

    return (
        <Box>
            {periods.map((time) => {
                return (
                    <TimePeriod
                        disabled={disabled}
                        time={time}
                        key={time.id}
                        updatePeriod={updatePeriod}
                        removePeriod={removePeriod}
                    />
                );
            })}
            <Typography
                className={classNames(classes.addPeriodText, { [classes.addPeriodTextDisabled]: disabled })}
                variant={'bodyBold'}
                onClick={() => createPeriod()}
            >
                    + Добавить период
            </Typography>
        </Box>
    );
};
