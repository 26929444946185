import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../../shared/components/button';
import { Tooltip } from '../../../../shared/components/tooltip';
import { Modal } from '../../../../shared/components/modal';
import { InspectionLogPanel } from '../../../inspectionLog/components/inspectionLogPanel';
import { MeterSchemeForm } from './meterSchemeForm';
import { MeterActionPlanProps } from './model';
import { PlanCard } from './planCard';
import { InspectionLog } from '../../../inspectionLog';

const useStyles = makeStyles(() => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    topButtons: {
        display: 'none'
    },
}));

export const View = ({
    groups,
    canSyncPlans,
    onClickSyncMeterPlans,
    isLoading,
    canRepeatMeterTasks,
    handleRepeatActionTask,
    handleMeterActionPlanRestart,
    meterActionScheme,
    timeSyncScheme,
    meter,
    isSchemeFormOpen,
    onCloseSchemeForm,
    onOpenSchemeForm,
    onSubmitMeterSyncSchemeUpdate,
    inspectionMeterActionTypeDefaults,
    meterId,
}: MeterActionPlanProps) => {

    const classes = useStyles();

    return (
        <>
            <Box>
                <Box mb={2}>
                    <Grid container>
                        <Grid item sm={8}>
                            {meterActionScheme && (
                                <Box mb={1}>
                                    <b>Схема опроса:</b> {meterActionScheme.name}
                                </Box>
                            )}
                            {timeSyncScheme && (
                                <Box>
                                    <b>Схема синхронизации времени:</b> {timeSyncScheme.name}
                                </Box>
                            )}
                        </Grid>
                        <Grid item sm={4}>
                            <Grid container spacing={2} justify='flex-end'>
                                <Grid item>
                                    <Button variant='outline' icon='edit' onClick={onOpenSchemeForm}>Изменить схемы</Button>
                                </Grid>
                                {canRepeatMeterTasks && (
                                    <Grid item>
                                        <Button variant='primary' onClick={handleMeterActionPlanRestart}>
                                            Запуск планов опроса
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container spacing={2} className={classes.topButtons} justify='flex-end'>
                                { canRepeatMeterTasks && (
                                    <Grid item>
                                        <Button variant='primary' onClick={handleRepeatActionTask}>Повторить опрос</Button>
                                    </Grid>
                                )}
                                {
                                    canSyncPlans && (
                                        <Grid item>
                                            <Tooltip
                                                text='Синхронизировать новые типы планов опросов с существующими счетчиками, нажимать только в случае добавления новых типов задач в систему.'>
                                                <Button disabled={ isLoading } onClick={ onClickSyncMeterPlans }
                                                    variant='outline'>Обновить схему опроса</Button>
                                            </Tooltip>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Grid container spacing={2}>
                {groups.filter(group => !group.hidden).map(({ title, data }) => (
                    <Grid key={title} item xs={6} sm={6} md={6} lg={4} xl={3}>
                        <PlanCard title={title} plan={data} />
                    </Grid>
                ))}
            </Grid>

            <InspectionLogPanel>
                <InspectionLog
                    meterId={meterId}
                    meterActionType={inspectionMeterActionTypeDefaults}
                />
            </InspectionLogPanel>

            {isSchemeFormOpen && (
                <Modal
                    width='543px'
                    close='Отмена'
                    onHide={onCloseSchemeForm}
                    title='Изменение схем'
                >
                    <MeterSchemeForm
                        meter={meter}
                        onCancel={onCloseSchemeForm}
                        onSubmit={onSubmitMeterSyncSchemeUpdate}
                    />
                </Modal>
            )}
        </>
    );
};
