import React, { useState } from 'react';
import { OwnProps, StateProps } from './model';
import { View } from './view';


export const EllipsisTooltip = (ownProps: OwnProps) => {

    const [visibilityTooltip, onChangeTooltipVisibility] = useState(false);

    const isContentOverflow = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        return event.currentTarget.offsetWidth < event.currentTarget.scrollWidth;
    };

    const props: StateProps = {
        visibilityTooltip,
        onChangeTooltipVisibility,
        isContentOverflow,
        ...ownProps,
    };

    return View(props);
};
