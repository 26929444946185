import React, { MouseEvent, useMemo, useState } from 'react';
import { OwnProps } from './model';
import { View } from './view';

export const OverflowMenu: React.FC<OwnProps> = ({ children, visibilityMap, className }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const shouldShowMenu = useMemo(
        () => Object.values(visibilityMap).some((v) => v === false),
        [visibilityMap]
    );

    return View({
        open,
        className,
        anchorEl,
        handleClick,
        handleClose,
        shouldShowMenu,
        visibilityMap,
        children,
    });
};
