import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Forbidden } from '../../../../shared/components/forbidden';
import { Heading } from '../../../../shared/components/heading';
import { Pagination } from '../../../../shared/components/pagination';
import { setOrderTransformer } from '../../../../shared/components/pagination/helpers';
import { Table } from '../../../../shared/components/table';
import { VisualizationSource } from '../../../../shared/components/table/model';
import { Wrapper } from '../../../../shared/components/wrapper';
import { defaults } from '../../../../shared/constants';
import { StateProps } from './model';
import { HookField } from '../../../../shared/components/hookField';
import { transformEndDay, transformStartDay } from '../../../../shared/utils/dates';
import { MeterConfigurationHistoryParams } from '../../../../shared/constants/enums';
import { Button } from '../../../../shared/components/button';
import { Typography } from 'app/shared/components/typography';

import { useStyles } from './style';

export const View = ({
    meter,
    columnDefinition,
    data,
    onCancel,
    breadcrumbs,
    onClickBreadcrumb,
    hasAccessToMeterConfigurationHistory,
    form,
    onSubmit,
    dataTotal,
    setOrder,
    pagination,
    setPage,
    orderParams,
}: StateProps) => {
    const classes = useStyles();

    if (!hasAccessToMeterConfigurationHistory) {
        return <Forbidden />;
    }

    const filter = (
        <FormProvider { ...form }>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <HookField
                            fullWidth
                            name='configurationParam'
                            type='select'
                            label='Тип параметра'
                            suggestions={MeterConfigurationHistoryParams}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <HookField
                            fullWidth
                            name='dateFrom'
                            type='datepicker'
                            label='Начало'
                            transform={{
                                output: transformStartDay
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <HookField
                            fullWidth
                            name='dateTo'
                            type='datepicker'
                            label='Конец'
                            transform={{
                                output: transformEndDay
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant='outline'
                            icon='refresh'
                            square
                            type='submit'
                        />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );

    const actions = (
        <Grid container spacing={2} className={classes.actions}>
            <Grid item xs={8}>{filter}</Grid>
        </Grid>
    );

    return (
        <>
            <Box mb={2}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box display='flex' alignItems='center' mb={2} >
                <Heading heading='История смены конфигурации' onBackHandler={onCancel} />
                <Typography className={classes.title} variant='h4'>{meter?.meterModel} №{meter?.meterSerialNum}</Typography>
            </Box>
            <Wrapper actions={actions} isHeaderUppercase={false} className={classes.configurationHistoryTable}>
                <Table
                    visualizationSource={VisualizationSource.clientProfile}
                    columns={columnDefinition}
                    data={data ?? []}
                    title={null}
                    variant='white'
                    options={{
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        search: false,
                        toolbar: false,
                        paging: 'disabled',
                    }}
                    fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                    onOrderChange={setOrderTransformer(columnDefinition, setOrder)}
                    {...orderParams}
                />
                <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={!data} />
            </Wrapper>
        </>

    );
};
