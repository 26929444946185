import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    MeterPowerSwitchOrderShortViewModel,
    MeterPowerSwitchRequestShortViewModel
} from '../../../../common/model/meter/meterPowerSwitchOrder/MeterPowerSwitchOrderCommonModel';

export interface MeterPowerSwitchOrdersListState {
    totalCountRequests: number;
    requests: MeterPowerSwitchRequestShortViewModel[];
    orders: Record<string | number, MeterPowerSwitchOrderShortViewModel[]>;
    serialNumSuggestions: string[];
}

const initialState: MeterPowerSwitchOrdersListState = {
    totalCountRequests: 0,
    requests: null,
    orders: {},
    serialNumSuggestions: [],
};

export const meterPowerSwitchOrdersListSlice = createSlice({
    name: '@@vv/meterPowerSwitchOrders/list',
    initialState,
    reducers: {
        setAvailableMeterPowerSwitchRequests(
            state,
            action: PayloadAction<{requests: MeterPowerSwitchRequestShortViewModel[]; totalCount: number}>
        ) {
            state.requests = action.payload.requests;
            state.totalCountRequests = action.payload.totalCount;
        },
        setAvailableMeterPowerSwitchOrders(
            state,
            action: PayloadAction<{requestId: number; orders: MeterPowerSwitchOrderShortViewModel[]}>
        ) {
            state.orders[action.payload.requestId] = action.payload.orders;
        },
        clearAvailableMeterPowerSwitchOrders(state) {
            state.orders = {};
        },
        setSerialNumSuggestions(state, action: PayloadAction<string[]>) {
            state.serialNumSuggestions = action.payload;
        },
        updateAvailableMeterPowerSwitchOrder(state, action: PayloadAction<MeterPowerSwitchOrderShortViewModel>) {
            const requestId = action.payload.requestId;
            if (requestId in state.orders) {
                state.orders[requestId] = state.orders[requestId].map(x => x.id === action.payload.id ? action.payload : x);
                state.orders = { ...state.orders };
            }
        },
    }
});
