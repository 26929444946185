import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { CrashByRegionsReportQueryParams } from '../../../../../common/model/meter/reports/crashByRegionsReport';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import {
    getCrashByRegionsReport,
    resetCrashByRegionsReport
} from '../../../../../redux/actions/reports';
import { crashByRegionsReportSelector } from '../../../../../redux/selectors/reports/crashByRegionsReport';
import { SetOrderFunc } from '../../../../shared/components/pagination/model';
import { ReportPageTabs, ReportTabs, Routes } from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { PaginationOrderParams } from '../../../../shared/hooks/useActualPage';
import { useSyncQueryParams } from '../../../../shared/hooks/useQueryParams';
import { getErrorMessage } from '../../../../shared/utils/error';
import { makeExcelReport } from './excel';
import { CrashByRegionsReportFilterParams, CrashByRegionsReportTableData } from './model';
import {
    transformCrashByRegionsReportResponseToTableData
} from './transformer';
import { RenderFieldView, RenderRegionFieldView, View } from './view';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { getRoute } from '../../../../shared/pipes';

const defaultOrderParams: PaginationOrderParams = {
    orderBy: 'index',
    orderDirection: 'asc',
};

const filterFormDefaultValues: CrashByRegionsReportFilterParams = {
    daysOffline: null,
    ...defaultOrderParams,
};

const customColumnSort = (field: keyof CrashByRegionsReportTableData) => (
    data1: CrashByRegionsReportTableData,
    data2: CrashByRegionsReportTableData
): number => {
    if (data1?.metersCount === '') {
        return 1;
    }
    if (data2?.metersCount === '') {
        return 1;
    }
    if (field === 'index' || field === 'metersCount' || field === 'crashMetersCount' || field === 'crashMetersPercent') {
        return +data2?.[field] > +data1?.[field] ? 1 : -1;
    } else {
        return data2?.[field] > data1?.[field] ? 1 : -1;
    }
};

export const CrashByRegionsReport = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const response = useSelector(crashByRegionsReportSelector());
    const [isLoading, setIsLoading] = useState(false);
    const { notHasAccess } = useAccessRules();
    const [currentDate, setCurrentDate] = useState(new Date());
    const isReportFormed = !!response;
    const [queryParams, setQueryParams] = useSyncQueryParams<CrashByRegionsReportFilterParams>(filterFormDefaultValues);
    const params = useParams<{meterId?: string}>();
    const meterId = params.meterId;

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Отчетность', link: getRoute(Routes.report, { meterId }, { tab: ReportTabs }) },
        { name: 'Отчёт по количеству сбоев по всем регионам', link: getRoute(Routes.report, { meterId }, { tab: ReportPageTabs.NotConnectedMeters }) },
    ]);

    const form = useForm<CrashByRegionsReportFilterParams>({
        defaultValues: queryParams,
    });

    useEffect(() => {
        return () => {
            dispatch(resetCrashByRegionsReport());
        };
    }, []);

    const formData = form.watch();

    const setOrder: SetOrderFunc = (orderBy, orderDirection) => {
        setQueryParams({
            ...formData,
            orderBy,
            orderDirection,
        });
        return Promise.resolve();
    };

    const orderParams: PaginationOrderParams = {
        orderBy: queryParams.orderBy,
        orderDirection: queryParams.orderDirection,
    };

    const fetch = async (fetchParams: CrashByRegionsReportQueryParams = formData) => {
        setCurrentDate(new Date());
        setIsLoading(true);
        try {
            setQueryParams(fetchParams);
            await dispatch(getCrashByRegionsReport(fetchParams));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    const columns: Column[] = [
        {
            title: '№',
            field: 'index',
            sorting: true,
            customSort: customColumnSort('index'),
            render: (data) => {
                return RenderFieldView(data, 'index');
            }
        },
        {
            title: 'Регион',
            field: 'regionName',
            customSort: customColumnSort('regionName'),
            sorting: true,
            render: (data) => {
                return RenderRegionFieldView(data);
            }
        },
        {
            title: 'Всего ПУ',
            field: 'metersCount',
            customSort: customColumnSort('metersCount'),
            sorting: true,
            render: (data) => {
                return RenderFieldView(data, 'metersCount');
            }
        },
        {
            title: 'Нет показаний',
            field: 'crashMetersCount',
            customSort: customColumnSort('crashMetersCount'),
            sorting: true,
            render: (data) => {
                return RenderFieldView(data, 'crashMetersCount');
            }
        },
        {
            title: 'Процент неопроса',
            field: 'crashMetersPercent',
            customSort: customColumnSort('crashMetersPercent'),
            sorting: true,
            render: (data) => {
                return RenderFieldView(data, 'crashMetersPercent');
            }
        },
    ];

    const daysOffline = queryParams.daysOffline;

    const data = response ? transformCrashByRegionsReportResponseToTableData(response, daysOffline) : [];

    const isExcelExportNotAvailable = notHasAccess(AccessRule.CanGetReportsCrashByRegionsExcel) || response?.data?.length === 0;

    return View({
        data,
        form,
        columns,
        setOrder,
        isLoading,
        breadcrumbs,
        orderParams,
        queryParams,
        isReportFormed,
        onClickBreadcrumb,
        onClickRefresh: async () => {
            await setOrder(defaultOrderParams.orderBy, defaultOrderParams.orderDirection);
            await fetch(formData);
        },
        isExcelExportNotAvailable,
        onClickFormReport: async () => {
            await fetch(formData);
        },
        headingOnBackHandler: () => {
            dispatch(push(Routes.report));
        },
        onClickFormExcel: async () => {
            setIsLoading(true);
            try {
                await makeExcelReport({
                    daysOffline,
                    response,
                    reportDate: currentDate,
                    filename: 'Количество ИПУ с которыми нет связи',
                });
                dispatch(createSnackbar({
                    type: 'green',
                    message: 'Отчет успешно создан.',
                }));
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsLoading(false);
        },
    });
};
