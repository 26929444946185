import React from 'react';
import { Wrapper } from 'app/shared/components/wrapper';
import { defaults, Tables } from '../../../shared/constants';
import { Table } from '../../../shared/components/table';
import { GroupsProps } from './model';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { HookField } from '../../../shared/components/hookField';
import { Button } from '../../../shared/components/button';
import { useWebDictionarySelectSuggestions } from '../../../shared/hooks/useWebDictionary';
import { Checkbox } from '../../../shared/components/checkbox';
import { Region } from '../../../../common/model/resources/regions';
import { MeterGroupViewModel } from '../../../../common/model/meter/meterGroup/meterGroupViewModel';

const useStyles = makeStyles(() => ({
    tableWrapper: {
        display: 'flex',
        '& .MuiTableRow-root:hover .btn-table': {
            visibility: 'visible',
        }
    },
    actions: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    filterWrapper: {
        flex: 1,
    },
    actionsLeft: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
        flex: 1,
    },
    actionsRight: {
        marginLeft: 'auto',
    },
    lowerCase: {
        textTransform: 'none',
    },
    fieldRegion: {
        flex: '0 0 320px'
    },
    field: {
        minWidth: 200,
    },
}));

export const RegionMeterGroupView = ({ region }: { region: Region }) => {
    if (!region) {
        return 'Регион не найден';
    }
    return <div>{region.name}</div>;
};

export const isStaticContent = ({ isStatic }: { isStatic: boolean }) => {
    if (isStatic) {
        return <div>Список ПУ зафиксирован</div>;
    } else {
        return <div>Список ПУ определяется условиями</div>;
    }
};

export const VisibleAllGroup = (group: MeterGroupViewModel) => {
    return <Checkbox color='#A8A8A8' disabled checked={group.visibleToAll} />;
};

export const View = (props: GroupsProps) => {
    const {
        columnsDefinition,
        form,
        meterGroupsList,
        groupNameSuggestions,
        groupTypeSuggestions,
        onClickCreateGroup,
        userSuggestions
    } = props;
    const classes = useStyles();

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const filterRender = (
        <FormProvider {...form}>
            <Grid className={classes.filterWrapper} container spacing={2}>
                <Grid item xl={2} md={2} xs={2} className={classes.field}>
                    <HookField
                        type='select'
                        name='name'
                        label='Название группы'
                        fullWidth
                        isClearable
                        suggestions={groupNameSuggestions} />
                </Grid>
                <Grid item className={classes.fieldRegion}>
                    <HookField
                        type='select'
                        name='regionId'
                        label='Регион'
                        fullWidth
                        isClearable
                        suggestions={regionSuggestions} />
                </Grid>
                <Grid item xl={3} md={3} xs={3} className={classes.field}>
                    <HookField
                        type='select'
                        name='groupType'
                        label='Тип группы'
                        fullWidth
                        isClearable
                        suggestions={groupTypeSuggestions}
                    />
                </Grid>
                <Grid item xl={2} md={2} xs={2} className={classes.field}>
                    <HookField
                        type='select'
                        name='userId'
                        label='Владелец'
                        fullWidth
                        isClearable
                        suggestions={userSuggestions} />
                </Grid>
                <Grid item className={classes.lowerCase}>
                    <HookField
                        type='checkbox'
                        name='visibleToAll'
                        label='Только видимые всем'
                    />
                </Grid>
            </Grid>
        </FormProvider>
    );

    const tableActionsRender = <Box className={classes.actions}>
        <Box className={classes.actionsLeft}>
            {filterRender}
        </Box>
        <Box className={classes.actionsRight}>
            <Button variant='primary' icon='add' onClick={onClickCreateGroup}>Новая группа</Button>
        </Box>
    </Box>;

    return (
        <Wrapper actions={tableActionsRender} className={classes.tableWrapper} isBigHeader isHeaderUppercase={false}>
            {meterGroupsList && (
                <Table
                    name={Tables.administrationSchemesTable}
                    columns={columnsDefinition}
                    data={meterGroupsList}
                    title={null}
                    variant='white'
                    useQueryString={true}
                    options={{
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        search: false,
                        sorting: true,
                        paging: 'disabled',
                        toolbar: false,
                        showTitle: false,
                    }}
                    fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                />
            )}
        </Wrapper>
    );
};
