import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { createPartner } from '../../../../../redux/actions/partners';
import { RelatedSystemsTabs, Routes } from '../../../../shared/constants';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { useHeading } from '../../../../shared/hooks/useHeading';
import { getRoute } from '../../../../shared/pipes';
import { getErrorMessage } from '../../../../shared/utils/error';
import { PartnerDetails } from '../partnerDetails';

const partnersRoute = getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.Partners });

export const PartnerCreate = () => {

    const dispatch = useDispatch<BaseDispatch>();

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Смежные системы', link: getRoute(Routes.relatedSystems) },
        { name: 'Партнеры', link: getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.Partners }) },
        { name: 'Новый партнер', link: getRoute(Routes.PartnerCreate) },
    ]);

    const headingProps = useHeading('Новый партнер', () => {
        dispatch(push(partnersRoute));
    });

    return PartnerDetails({
        ...breadcrumbProps,
        ...headingProps,
        onSubmit: async (formData) => {
            try {
                const createdPartner = await createPartner(formData);
                if (createdPartner?.id) {
                    dispatch(push(getRoute(Routes.PartnerEdit, { partnerId: createdPartner.id.toString() })));
                }
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    delay: 5000,
                    message: getErrorMessage(e),
                }));
            }
        },
    });
};
