import { AutocompleteOptionsHookReturn, VAutocompleteOption } from '../model';
import { useSelector } from 'react-redux';
import { meterDictionaryValues } from '../../../../../redux/selectors/dictionaries';

export const useMeterDictionaryValues = (): AutocompleteOptionsHookReturn => {
    let taskNames = useSelector(meterDictionaryValues);

    const onSearchTaskName = (value?: string) => {
        taskNames = taskNames.filter(taskName => taskName.value.includes(value));
    };

    const tasksNameOptions: VAutocompleteOption[] = taskNames?.map(taskName => ({
        label: taskName.value,
        optionsLabel: taskName.value,
        value: taskName.value,
    }));

    return {
        options: tasksNameOptions || [],
        onInputChange: (event, value) => onSearchTaskName(value),
        loading: false,
    };
};
