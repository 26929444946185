import { InspectionLogEntryViewModel } from '../../../common/model/inspectionLog/inspectionLogEntryViewModel/inspectionLogEntryViewModel';
import { InspectionLogQuery } from '../../../common/model/inspectionLog/inspectionLogQuery';
import { Column } from '@softmedialab/materialui-table';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { OrderType } from '../../../common/model/inspectionLog/OrderType';
import { MeterActionType } from '../../../common/model/meter/meterActionType';

export enum InspectionLogFilterTypes {
    Critical,
    OrderType,
    IsMy,
    DateFrom,
    DateTo,
    Search,
}

export enum InspectionLogTableColumns {
    Critical,
    Date,
    OrderType,
    Message,
}

export interface Props {
    gisTaskId?: string;
    orderId?: string;
    orderType?: OrderType;
    meterId?: string;
    isDumpVisible?: boolean;
    meterActionType?: MeterActionType[];
    visibleFilters?: InspectionLogFilterTypes[];
    visibleColumns?: InspectionLogTableColumns[];
}

export interface StateProps extends Props {
    columnDefinition: Column[];
    data: InspectionLogEntryViewModel[],
    last: InspectionLogEntryViewModel,
    form: UseFormReturn<InspectionLogQuery>;
    isFilterVisible: boolean;
    onClick: () => void;
    onClickSetNowMinCreatedAt: () => void;
    onClickSetNowMaxCreatedAt: () => void;
    onInfiniteScrollStart: () => Promise<void>;
    onInfiniteScrollEnd: () => Promise<void>;
}
