import { Props as ChartProps } from 'react-apexcharts';
import { IStateProps, OwnProps } from './model';
import { View } from './view';
import { mergeDeep } from '../../pipes';

export const Chart = ({ chartId, title, series, chartProps, xaxis, yaxis, colors, backgroundColor }: OwnProps) => {
    const defaultProps: ChartProps = {
        type: 'line',
        height: 500,
        options: {
            chart: {
                id: chartId,
                stacked: false,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                toolbar: {
                    autoSelected: 'zoom',
                    tools: {
                        download: false,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                    }
                },
            },
            colors,
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 0,
                shape: 'rect',
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            title: {
                text: title,
                align: 'left',
            },
            xaxis,
            yaxis,
            legend: {
                show: false,
            },
        },
        series,

    };

    const mergedChartProps = mergeDeep(defaultProps, chartProps);

    const props: IStateProps = {
        chartProps: mergedChartProps,
        backgroundColor,
    };

    return View(props);
};
