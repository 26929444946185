import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';


const commonInspectionLogSelector = (state: StorageState) => state.inspectionLogs.common;

export const commonInspectionLogLastSelector = createSelector(
    commonInspectionLogSelector,
    common => common.available?.[0] ?? null,
);

export const commonInspectionLogAvailableSelector = createSelector(
    commonInspectionLogSelector,
    common => common.available,
);

export const commonInspectionLogLoadingSelector = createSelector(
    commonInspectionLogSelector,
    common => common.loading,
);

export const commonInspectionLogIsFilterVisibleSelector = createSelector(
    commonInspectionLogSelector,
    common => common.isFilterVisible,
);
