import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../shared/components/button';
import { Forbidden } from '../../../../shared/components/forbidden';
import { HookField } from '../../../../shared/components/hookField';
import { Loader } from '../../../../shared/components/loader';
import { Typography } from '../../../../shared/components/typography';
import { StateProps } from './model';
import { useUpdateFromAttributesStyles } from '../styles';

export const View = ({ form, onSubmit, onCancel, hasAccess, isDefected, isLoading }: StateProps) => {

    if (!hasAccess) {
        return <Forbidden />;
    }

    const { handleSubmit, formState: { isDirty, dirtyFields } } = form;
    const classes = useUpdateFromAttributesStyles();

    if (isLoading) {
        return <Box display='flex' justifyContent='center'>
            <Loader visible={true} size={50} />
        </Box>;
    }

    return <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} className={classes.inputLabel}>
                            <Typography variant='bodyBold'>Модель коммуникатора</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                name='communicatorModel'
                                type='text'
                                label='Модель коммуникатора'
                                inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['communicatorModel'] }) }}
                                fullWidth />
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} className={classes.inputLabel}>
                            <Typography variant='bodyBold'>Заводской номер</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                name='communicatorSerialNum'
                                type='text'
                                label='Заводской номер'
                                inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['communicatorSerialNum'] }) }}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} className={classes.inputLabel}>
                            <Typography variant='bodyBold'>Пароль 1</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                name='communicatorPassword1'
                                type='password'
                                isShowHidePasswordVisible={true}
                                label='Пароль 1'
                                inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['communicatorPassword1'] }) }}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} className={classes.inputLabel}>
                            <Typography variant='bodyBold'>Пароль 2</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <HookField
                                name='communicatorPassword2'
                                type='password'
                                isShowHidePasswordVisible={true}
                                label='Пароль 2'
                                inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['communicatorPassword2'] }) }}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                {isDefected && <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={3} />
                        <Grid item xs={9}>
                            <HookField
                                name='changeStatusToActive'
                                type='checkbox'
                                label='Исправить статус ПУ на активный'
                            />
                        </Grid>
                    </Grid>
                </Grid>}
                <Grid item xs={12}>
                    <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                        <Grid item>
                            <Button variant='primary' type='submit' disabled={!isDirty && !isDefected} icon='check'>Сохранить</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </FormProvider>;
};
