import React from 'react';
import { useForm } from 'react-hook-form';
import { TariffScheduleCreator } from '../../../../../../common/model/meter/tariffSchedule/tariffScheduleCreator';
import { newGuid } from '../../../../../../common/shared/utils';
import { InputProps } from './model';
import { View } from './view';

const getDefaultValues = (): TariffScheduleCreator => ({
    id: newGuid(),
    regionId: null,
    tariffCount: null,
    legalEntityType: null,
});

export const TariffScheduleForm: React.FC<InputProps> = (props) => {

    const {
        viewModel,
    } = props;

    const form = useForm<TariffScheduleCreator>({
        defaultValues: viewModel || getDefaultValues(),
    });

    return View({
        ...props,
        form,
    });
};
