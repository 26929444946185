import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MeterModelUpdater } from '../../../common/model/meter/meterModel/meterModelUpdater';
import { MeterModelViewModel } from '../../../common/model/meter/meterModel/meterModelViewModel';
import { meterModelsListSlice } from '../../reducers/meterModels/list';
import { AppThunk } from '../index';

export const getMeterModels = (): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_models);
    const data = await httpService.get<MeterModelViewModel[]>(route);
    dispatch(meterModelsListSlice.actions.setAvailable(data));
};

export const updateMeterModels = (id: number, updater: MeterModelUpdater): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_put_meter_model, { id });
    const data = await httpService.put<MeterModelViewModel>(route, updater);
    dispatch(meterModelsListSlice.actions.update(data));
};
