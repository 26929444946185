import { MeterDictionariesNames } from '../../../../../common/model/meter/meterDictionaries/meterDictionariesNames';
import { MeterGisTaskCreate } from '../../../../../common/model/meter/meterGisTask/meterGisTaskCreate';
import { View } from './view';
import { CreateTaskFormProps } from './modal';
import { useForm } from 'react-hook-form';
import yup from '../../../../shared/utils/yup';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import { useEffect } from 'react';
import { useMeterAutocomplete } from '../../../../shared/components/autocomplete/hooks/useMeterAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { metersSelector } from '../../../../../redux/selectors/meters';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { BaseDispatch } from '../../../../../redux/actions';
import { getMeterDictionaryValues } from '../../../../../redux/actions/dictionaries';

const taskValidationSchema = yup.object({
    title: yup.string().required(),
});

const defaultValues: MeterGisTaskCreate = {
    title: '',
    notes: '',
    foreignId: null,
    address: '',
    activeMeterLabelTypeId: null,
};

export const CreateTaskForm = (props: CreateTaskFormProps) => {
    const dispatch = useDispatch<BaseDispatch>();
    const meters = useSelector(metersSelector);
    const resolver = useYupValidationResolver<MeterGisTaskCreate>(taskValidationSchema);
    const form = useForm<MeterGisTaskCreate>({
        defaultValues: {
            ...defaultValues,
            foreignId: props.foreignId || null,
            activeMeterLabelTypeId: props.activeMeterLabelTypeId || null,
            address: props.address || '',
        },
        resolver
    });
    const { hasAccess } = useAccessRules();
    const foreignId = form.watch().foreignId;
    const taskTitle = form.watch().title;

    useEffect(() => {
        dispatch(getMeterDictionaryValues(MeterDictionariesNames.GisTasks));
    }, []);

    const meterAutocomplete = useMeterAutocomplete({
        foreignId,
    }, { valueMapKey: 'foreignId' });

    useEffect(() => {
        if (!props.address) {
            form.setValue('address',
                foreignId && meters?.length
                    ? meters.find(meter => meter.foreignId === foreignId)?.address?.address ?? ''
                    : ''
            );
        }
    }, [foreignId]);

    useEffect(() => {
        dispatch(getMeterDictionaryValues(MeterDictionariesNames.GisTasks, { search: taskTitle }));
    }, [taskTitle]);

    return View({
        ...props,
        meterAutocomplete,
        form,
        hasAccess: hasAccess(AccessRule.CanCreateMeterGisTask)
    });
};
