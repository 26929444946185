import { EventLogViewModel } from '../../../../../common/model/meter/eventLog/eventLogViewModel';

export const formatEventLogMessage = (
    model: EventLogViewModel,
): string => {

    const { eventType } = model;
    if (!eventType) {
        return null;
    }

    const { format, description } = eventType;
    if (!format) {
        return description;
    }

    let result = format;
    const { channelNumber, clientAddress } = model;
    result = result.replace('{ChannelNumber}', channelNumber?.toString() ?? '–');
    result = result.replace('{ClientAddress}', clientAddress?.toString() ?? '–');
    return result;
};
