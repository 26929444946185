import React, { useState, useEffect, useMemo } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from 'redux/actions';
import { View } from './view';
import { createSnackbar, getMeters } from 'redux/actions/controls';
import { getCrqSystemList, saveMeterCrqSystemAndChannels, getMeterCrqSystemAndChannels } from 'redux/actions/crqSystem';
import { getMeterSchemes } from 'redux/actions/meterSchemes';
import { getErrorMessage } from 'app/shared/utils/error';
import { SelectSuggestion } from 'app/shared/components/select/model';
import { crqSystemAvailableSelector } from 'redux/selectors/crqSystem';
import { meterSchemeWithCrqSelector } from 'redux/selectors/meterSchemes';
import { CrqChannelCreator } from 'common/model/meter/crq/crqChannelCreator';
import { CrqChannelViewModel } from 'common/model/meter/crq/crqChannelViewModel';
import { newCrqChannels } from './newCrqChannels';
import { MeterCrqSystemAndChannelsCreator, MeterCrqSystemAndChannels } from 'common/model/meter/crq/meterCrqSystemAndChannels';
import { getRoute } from 'app/shared/pipes';
import { Routes } from 'app/shared/constants';
import { CrqChannelsProps } from './model';

export const CrqChannels: React.FC<CrqChannelsProps> = ({ meterId, partnerId, isUpdate }) => {
    const dispatch = useDispatch<BaseDispatch>();
    const [crqSystemId, setCrqSystemId] = useState<number>();
    const [meterActionSchemeId, setMeterActionSchemeId] = useState<number>();
    const [crqChannels, setCrqChannels] = useState<CrqChannelViewModel[]>();
    const [crqSystemsLoading, setCrqSystemsLoading] = useState<boolean>(false);
    const [initialDataLoading, setInitialDataLoading] = useState<boolean>(false);
    const [meterSchemesLoading, setMeterSchemesLoading] = useState<boolean>(false);
    const partnerRoute = getRoute(Routes.PartnerEdit, { partnerId });
    const crqSystems = useSelector(crqSystemAvailableSelector());
    const schemes = useSelector(meterSchemeWithCrqSelector());

    const fetchCrqSystems = async () => {
        setCrqSystemsLoading(true);
        try {
            await dispatch(getCrqSystemList(Number(partnerId)));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setCrqSystemsLoading(false);
    };

    const fetchMeterSchemes = async () => {
        setMeterSchemesLoading(true);
        try {
            await dispatch(getMeterSchemes());
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setMeterSchemesLoading(false);
    };

    useEffect(() => {
        if (partnerId && !crqSystems) {
            fetchCrqSystems();
        }
    }, [partnerId, crqSystems]);

    const fetchInitialData = async () => {
        setInitialDataLoading(true);
        try {
            const data: MeterCrqSystemAndChannels = await dispatch(getMeterCrqSystemAndChannels(meterId));
            if (data) {
                setCrqSystemId(data.crqSystemId);
                setMeterActionSchemeId(data.meterActionSchemeId);
                setCrqChannels(data.crqChannels);
            }
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setInitialDataLoading(false);
    };

    useEffect(() => {
        if (meterId && isUpdate) {
            fetchInitialData();
        }

    }, [meterId, isUpdate]);


    useEffect(() => {
        if (!schemes) {
            fetchMeterSchemes();
        }
    }, [schemes]);

    const schemesSuggestions: SelectSuggestion[] = useMemo(() => {
        return schemes?.map(item => ({
            label: item.name,
            value: item.id,
        })) || [];
    }, [schemes]);

    const crqSystemsSuggestions: SelectSuggestion[] = useMemo(() => {
        return crqSystems?.map(item => ({
            label: item.name,
            value: item.id,
        })) || [];
    }, [crqSystems]);

    const onChangeCrqSystemId = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCrqSystemId(event.target.value as number);
        if (!crqChannels) {
            setCrqChannels(newCrqChannels);
        }
    };

    const onChangeMeterActionSchemeId = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMeterActionSchemeId(event.target.value as number);
    };

    const onChangeCrqChannels = (crqChannels:CrqChannelCreator[]) => {
        setCrqChannels(crqChannels);
    };

    const handleSave = async () => {
        if (!meterId) {
            return;
        }

        const data = crqSystemId && {
            crqSystemId,
            meterActionSchemeId,
            crqChannels
        } as MeterCrqSystemAndChannelsCreator;


        try {
            await saveMeterCrqSystemAndChannels(meterId, data);

            dispatch(createSnackbar({
                type: 'green',
                delay: 5000,
                message: `Данные CRQ-интерфейса ${isUpdate ? 'обновлены' : 'добавлены'} успешно`,
            }));

            if (isUpdate) {
                return;
            }

            dispatch(push(partnerRoute));
            dispatch(getMeters());
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                delay: 5000,
                message: getErrorMessage(e),
            }));
        }
    };

    const isDisabledSaveButton = !crqSystemId || crqChannels?.some(channel => !channel.number);

    return View({
        crqSystemId,
        meterActionSchemeId,
        crqChannels,
        schemesSuggestions,
        crqSystemsSuggestions,
        isDisabledSaveButton,
        isLoading: crqSystemsLoading || meterSchemesLoading || initialDataLoading,
        handleChangeCrqSystemId: onChangeCrqSystemId,
        handleChangeMeterActionSchemeId: onChangeMeterActionSchemeId,
        handleChangeCrqChannels: onChangeCrqChannels,
        handleSave
    });
};
