import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterDictionariesViewModel } from '../../../../common/model/meter/meterDictionaries/meterDictionariesViewModel';
import { MeterDictionaryValuesViewModel } from '../../../../common/model/meter/meterDictionaryValues/meterDictionaryValuesViewModel';

export interface CommonDictionaries {
    available: MeterDictionariesViewModel[];
    dictionaryValues: MeterDictionaryValuesViewModel[];
}

const initialState: CommonDictionaries = {
    available: [],
    dictionaryValues: [],
};

export const dictionariesSlice = createSlice({
    name: '@@vv/dictionaries',
    initialState,
    reducers: {
        setAvailableDictionaries(state, action: PayloadAction<MeterDictionariesViewModel[]>) {
            state.available = action.payload;
        },
        setMeterGisTasksNameTypes(state, action: PayloadAction<MeterDictionaryValuesViewModel[]>) {
            state.dictionaryValues = action.payload;
        },
        updateMeterGisTaskName(state, action: PayloadAction<MeterDictionaryValuesViewModel>) {
            state.dictionaryValues = state.dictionaryValues.map(gisTaskName => {
                if (gisTaskName.id === action.payload.id) {
                    return action.payload;
                }
                return gisTaskName;
            });
        },
        creatMeterGisTaskName(state, action: PayloadAction<MeterDictionaryValuesViewModel>) {
            state.dictionaryValues = [...state.dictionaryValues, action.payload];
        },
        deleteMeterGisTaskName(state, action: PayloadAction<{id: string | number}>) {
            state.dictionaryValues = state.dictionaryValues.filter(dictionaryValue => dictionaryValue.id !== action.payload.id);
        }
    }
});
