import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from 'redux/actions';
import { View } from './view';
import { createSnackbar } from 'redux/actions/controls';
import { getCrqSystemList, resetCrqSystemList, resetEditableCrqSystem, deleteCrqSystem } from 'redux/actions/crqSystem';
import { getErrorMessage } from 'app/shared/utils/error';
import { crqSystemAvailableSelector } from 'redux/selectors/crqSystem';
import { useSyncQueryParams } from 'app/shared/hooks/useQueryParams';
import { CrqSystemParams, CrqSystemSettingAlertType, CrqSystemTableOwnProps } from './model';

const initialCrqSystemParams: CrqSystemParams = {
    crqSystemId: null,
};

export const CrqSystemTable: React.FC<CrqSystemTableOwnProps> = ({ isNewPartner }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const [deleteCrqSystemId, setDeleteCrqSystemId] = useState<number | undefined>(undefined);
    const [crqSystemSettingAlertType, setCrqSystemSettingAlertType] = useState<CrqSystemSettingAlertType | undefined>(undefined);
    const { partnerId } = useParams<{ partnerId?: string }>();
    const dispatch = useDispatch<BaseDispatch>();
    const crqSystems = useSelector(crqSystemAvailableSelector());
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [crqSystemParams, setCrqSystemParams] = useSyncQueryParams<CrqSystemParams>(initialCrqSystemParams);

    const fetchList = async () => {
        setIsLoading(true);
        try {
            await dispatch(getCrqSystemList(Number(partnerId)));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        dispatch(resetCrqSystemList());
        if (partnerId) {
            fetchList();
        }
        setCrqSystemParams(initialCrqSystemParams);
    }, [partnerId]);

    const onCloseForm = () => {
        setIsFormOpen(false);
        dispatch(resetEditableCrqSystem());
        setCrqSystemParams(initialCrqSystemParams);
    };

    const onOpenModal = (crqSystemId?: number) => {
        setIsFormOpen(true);
        if (crqSystemId) {
            setCrqSystemParams({ crqSystemId });
        }
    };

    const onRemoveModalToggle = (crqSystemId?: number) => {
        setDeleteCrqSystemId(crqSystemId);
    };

    const onRemove = async () => {
        try {
            await dispatch(deleteCrqSystem(deleteCrqSystemId));
            setDeleteCrqSystemId(undefined);
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
    };

    const onToggleCrqSystemSettingAlertTypeModal = (type?: CrqSystemSettingAlertType) => {
        setCrqSystemSettingAlertType(type);
    };

    return View({
        crqSystems,
        isLoading,
        isFormOpen,
        isNewPartner,
        deleteCrqSystemId,
        crqSystemSettingAlertType,
        handleToggleCrqSystemSettingAlertTypeModal: onToggleCrqSystemSettingAlertTypeModal,
        handleRemoveModalToggle: onRemoveModalToggle,
        handleOpenModal: onOpenModal,
        handleRemove: onRemove,
        handleCloseForm: onCloseForm
    });
};
