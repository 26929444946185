import { appUrls } from '../../../common/appUrls';
import { MeterDictionariesNames } from '../../../common/model/meter/meterDictionaries/meterDictionariesNames';
import { Routes } from '../constants';
import { stringify } from 'query-string';
import { MeterDictionaryValuesViewModel } from '../../../common/model/meter/meterDictionaryValues/meterDictionaryValuesViewModel';

export interface RequestParams {
  name?: string;
  clientId?: number;
  from?: number;
  to?: number;
  avatarVersion?: number;
  meterId?: string;
  msisdn?: string;
  type?: string | string[] | number | number[];
  scheduleId?: number;
  dateFrom?: Date | string;
  dateTo?: Date | string;
  meterActivationOrderId?: string;
  count?: number;
  offset?: number;
  sortFieldName?: string;
  asc?: boolean;
  roleId?: string;
  userId?: number;
  id?: string | number;
  limit?: number;
  schemeId?: string | number;
  groupId?: number;
  staticMeterGroupContentId?: number;
  regionId?: number;
  daysOffline?: number;
  runInitialDiagnostic?: boolean;
  value?: string;
  search?: string;
  changeStatusToActive?: boolean;
  statuses?: string[];
  meterModel?: string;
  meterSerialNum?: string;
  partnerId?: string | number;
  taskId?: string;
  numberMeterActivationOrder?: string;
  address?: string;
  meterModelId?: string | number;
  orderId?: string;
  syncIds?: string[];
  dictionaryName?: MeterDictionariesNames;
  taskName?: MeterDictionaryValuesViewModel;
  maxId?: number;
  minCreatedAt?: string;
  maxCreatedAt?: string;
  meterActionType?: string | string[] | number | number[];
  orderType?: number | number[];
  gisTaskId?: string;
  severity?: number | number[] | string[];
  text?: string;
  isClosedOnly?: boolean;
  meterLabelTypeId?: number;
  tariffScheduleId?: string;
  tariffScheduleVersionId?: string;
  month?: number;
  dynamicMeterGroupAddressesId?: number;
  taskNumber?: number;
  start?: boolean;
  passwordType?: string;
  fileId?: number | string;
  noValidate?: boolean;
  networkOperatorId?: number;
  errors?: string;
  requestId?: number | string;
}

export const getApiRoute = (
    baseUrl: appUrls,
    params?: RequestParams,
    queryParams_?: RequestParams
): string => {
    const queryParams = { ...(queryParams_ || {}) };
    let result = baseUrl.toString();
    if (params) {
        for (const property in params) {
            if (
                !!params[property] ||
        params[property] === false ||
        params[property] === 0
            ) {
                result = result.replace(`:${property}`, params[property]);
            }
        }
    }
    if (queryParams) {
        for (const propName in queryParams) {
            if (
                queryParams[propName] === null ||
        queryParams[propName] === undefined
            ) {
                delete queryParams[propName];
            }
        }
        for (const key in queryParams) {
            if (queryParams[key] instanceof Date) {
                queryParams[key] = queryParams[key].toISOString();
            }
        }
        const query = stringify(queryParams);
        if (query && query.length) {
            result = `${result}?${query}`;
        }
    }
    return result;
};

export interface RouterParams {
  from?: string;
  to?: string;
  role?: string;
  meterId?: string;
  meterActivationOrderId?: string;
  importCrqFileId?: string;
  count?: string;
  offset?: string;
  sortFieldName?: string;
  asc?: string;
  dateFrom?: string;
  dateTo?: string;
  meterIds?: string;
  isCritical?: string;
  search?: string;
  logType?: string;
  eventType?: string;
  dateRange?: string;
  schemeId?: string;
  groupId?: string;
  reportType?: string;
  meterPowerSwitchOrderId?: string;
  partnerId?: string;
  taskId?: string;
  ids?: string;
  dictionaryName?: MeterDictionariesNames;
  tariffScheduleId?: string;
  tariffScheduleVersionId?: string;
  id?: string;
}

export const getRoute = <T = unknown>(
    routeTemplate: Routes,
    params?: RouterParams,
    queryParams?: T | string
): string => {
    let result: string = routeTemplate;
    if (params) {
        for (const property in params) {
            if (
                !!params[property] ||
        params[property] === false ||
        params[property] === 0 ||
        params[property] === ''
            ) {
                result = result.replace(`:${property}`, params[property]);
            }
        }
    }

    if (queryParams) {
        if (typeof queryParams === 'string') {
            result += queryParams;
        } else {
            for (const propName in queryParams) {
                if (
                    queryParams[propName] === null ||
          queryParams[propName] === undefined
                ) {
                    delete queryParams[propName];
                }
            }
            const query = stringify(queryParams);
            if (query && query.length) {
                result = `${result}?${query}`;
            }
        }
    }

    return result.replace('//', '/');
};

export const getParentRoute = (pathname: string): Routes => {
    return Object.keys(Routes)
        .map((key) => Routes[key])
        .find((route) => pathname.startsWith(route));
};
