import { ISort, Sort } from '../../../../common/sort';
import { PaginationOrderParams, PaginationParams } from '../../hooks/useActualPage';
import { useSyncQueryParams } from '../../hooks/useQueryParams';
import { SetOrderFunc, SetPageFunc } from './model';

type ParamsMap = {
    [key: string]: any;
}

const paginationParamsTransformer = (prefix = '') => {
    return {
        from: (params: PaginationParams): ParamsMap => {
            const paramsTransformed: ParamsMap = {};
            for (const key in params) {
                paramsTransformed[prefix + key] = params[key];
            }
            return paramsTransformed;
        },
        to: (params: ParamsMap): PaginationParams => {
            const paramsTransformed: PaginationParams = {};
            for (const key in params) {
                paramsTransformed[key.replace(prefix, '')] = params[key];
            }
            return paramsTransformed;
        },
        getKey: (key: string): string => {
            return prefix + key;
        }
    };
};

export const usePagination = (
    defaultPagination: PaginationParams,
    fetch: (sort: ISort, ...args: any[]) => Promise<void>,
    prefix = ''
) => {
    const transformer = paginationParamsTransformer(prefix);
    const [pagination, setPagination] = useSyncQueryParams<ParamsMap>(transformer.from(defaultPagination));
    const orderParams: PaginationOrderParams = {
        orderBy: pagination[transformer.getKey('orderBy')],
        orderDirection: pagination[transformer.getKey('orderDirection')],
    };
    const onFetch = async (paginationParams: PaginationParams, ...args: any[]) => {
        const sort = new Sort({
            count: paginationParams.rowsPerPage,
            offset: (paginationParams.page - 1) * paginationParams.rowsPerPage,
            sortFieldName: paginationParams.orderBy,
            asc: paginationParams.orderDirection !== 'desc',
        });
        setPagination(transformer.from(paginationParams));
        await fetch(sort, ...args);
    };
    const setOrder: SetOrderFunc = async (orderBy: string, orderDirection: 'asc' | 'desc') => {
        const updatedPagination: PaginationParams = {
            ...transformer.to(pagination),
            orderBy,
            orderDirection,
        };
        setPagination(transformer.from(updatedPagination));
        await onFetch(updatedPagination);
    };
    const setPage: SetPageFunc = async (page: number) => {
        const updatedPagination: PaginationParams = {
            ...transformer.to(pagination),
            page,
        };
        setPagination(transformer.from(updatedPagination));
        await onFetch(updatedPagination);
    };
    const setPaginationWrapper: typeof setPagination = (params, updateHistory) => {
        setPagination(transformer.from(params), updateHistory);
    };

    return {
        pagination: transformer.to(pagination),
        setPagination: setPaginationWrapper,
        setOrder,
        setPage,
        onFetch,
        orderParams,
    };
};
