import { Snackbar } from '@material-ui/core';
import React, { useRef } from 'react';
import { AccessRule } from '../../../../common/model/access/accessRule';
import { useAccessRules } from '../../hooks/useAccessRules';
import { Loader } from '../loader';
import { StateProps } from './model';
import { useStyles } from './styles';

export const View: React.FC<StateProps> = ({
    showLoader,
    value,
    isPasswordVisible,
    handleVisibilityPassword,
    visibilitySnackbar,
    handleVisibilitySnackbar,
    handlePasswordCopyClipboard
}) => {
    const classes = useStyles();

    const wrapperRef = useRef(null);

    const { notHasAccess } = useAccessRules();

    if (notHasAccess(AccessRule.CanGetMeterPassword)) {
        return (
            <div className={classes.wrapper}>
                Нет доступа.
            </div>
        );
    }

    if (showLoader) {
        return (
            <div className={classes.loader}>
                <Loader visible={showLoader} size={20} startShowLoaderDelayMs={0} />
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            <div ref={wrapperRef}>
                {
                    isPasswordVisible
                        ? <span className={classes.password} onClick={handlePasswordCopyClipboard}>{value}</span>
                        : <span
                            className={classes.viewButton}
                            onClick={() => handleVisibilityPassword(wrapperRef)}
                        >Показать</span>
                }
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    message="Пароль скопирован в буфер"
                    open={visibilitySnackbar}
                    onClose={handleVisibilitySnackbar}
                />
            </div>
        </div>
    );
};
