import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MeterActionTaskShortViewModel } from '../../../common/model/meter/meterActionTaskShortViewModel';
import { MeterChangePasswordOrderCreator } from '../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderCreator';
import { MeterChangePasswordOrderListQuery } from '../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderListQuery';
import {
    MeterChangePasswordOrderListResponse
} from '../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderListResponse';
import {
    MeterChangePasswordOrderStatus,
    meterChangePasswordOrderStatusDictionary
} from '../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderStatus';
import { MeterChangePasswordOrderViewModel } from '../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderViewModel';
import { meterChangePasswordOrdersControlSlice } from '../../reducers/meterChangePasswordOrders/control';
import { meterChangePasswordOrdersListSlice } from '../../reducers/meterChangePasswordOrders/list';
import { createSnackbar } from '../controls';
import { AppThunk } from '../index';

export type GetMeterChangePasswordOrdersQueryParams = Omit<MeterChangePasswordOrderListQuery, 'meterId' | 'requestId'>;
export type GetMeterChangePasswordOrdersControlQueryParams = Omit<MeterChangePasswordOrderListQuery, 'meterId' | 'requestId' | 'meterSerialNum'>;

export const setMeterChangePasswordOrdersAvailable = (
    data: MeterChangePasswordOrderListResponse | null
): AppThunk => (dispatch) => {
    dispatch(meterChangePasswordOrdersListSlice.actions.setAvailable(data));
};

export const getMeterChangePasswordOrders = (
    requestId: number,
    query: GetMeterChangePasswordOrdersQueryParams
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_change_password_order, {}, { ...query, requestId });
    const data = await httpService.get<MeterChangePasswordOrderListResponse>(route);
    dispatch(setMeterChangePasswordOrdersAvailable(data));
};

export const setMeterChangePasswordOrdersControlAvailable = (
    data: MeterChangePasswordOrderListResponse | null
): AppThunk => (dispatch) => {
    dispatch(meterChangePasswordOrdersControlSlice.actions.setAvailable(data));
};

export const getMeterChangePasswordOrdersControl = (
    meterId: string,
    query: GetMeterChangePasswordOrdersControlQueryParams
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_change_password_order, {}, { ...query, meterId });
    const data = await httpService.get<MeterChangePasswordOrderListResponse>(route);
    dispatch(setMeterChangePasswordOrdersControlAvailable(data));
};

export const setMeterChangePasswordLatestTask = (
    data: MeterActionTaskShortViewModel | null
): AppThunk => (dispatch) => {
    dispatch(meterChangePasswordOrdersControlSlice.actions.setLatestTask(data));
};

export const getMeterChangePasswordLatestTask = (meterId: string): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_change_password_latest_task_by_meter_id, { meterId });
    const data = await httpService.get<MeterActionTaskShortViewModel>(route);
    dispatch(setMeterChangePasswordLatestTask(data));
};

export const createMeterChangePasswordOrder = (creator: MeterChangePasswordOrderCreator): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_post_meter_change_password_order);
    const createdOrder = await httpService.post<MeterChangePasswordOrderViewModel>(route, creator);
    const isSuccess = createdOrder.status === MeterChangePasswordOrderStatus.ReadyForWork;
    dispatch(createSnackbar({
        type: isSuccess
            ? 'green'
            : 'red',
        message: isSuccess
            ? 'Заявка успешно создана'
            : meterChangePasswordOrderStatusDictionary[createdOrder.status]
    }));
};
