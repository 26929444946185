import { useDispatch, useSelector } from 'react-redux';
import {
    resourcesMeterActionSchemesByIdSelector,
    resourcesTimeSynchronizationSchemesByIdSelector,
} from '../../../../redux/selectors';
import {
    meterCommunicatorInformationSelector,
    meterLatestProfileSelector,
    meterSelector,
    meterLabelsSelector,
    meterUiConfigSelector,
    meterTariffSchedulesSelector,
} from '../../../../redux/selectors/meters';
import React, { useState, MouseEvent } from 'react';
import { Props } from './model';
import { View } from './view';
import { getRoute } from '../../../shared/pipes';
import { Routes } from '../../../shared/constants';
import { push } from 'connected-react-router';
import { BaseDispatch } from '../../../../redux/actions';
import copy from 'copy-to-clipboard';
import { createSnackbar } from '../../../../redux/actions/controls';

export const Information: React.FC<Props> = (props) => {
    const { meterId } = props;
    const dispatch = useDispatch<BaseDispatch>();
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement>(null);
    const [isShowForeignIdModal, setIsShowForeignIdModal] = useState<boolean>(
        false
    );

    const onOpenPopover = (event: MouseEvent<HTMLElement>) => {
        setPopoverAnchor(event.currentTarget);
    };

    const onClosePopover = () => {
        setPopoverAnchor(null);
    };

    const isPopoverOpen = Boolean(popoverAnchor);

    const meter = useSelector(meterSelector);
    const uiConfig = useSelector(meterUiConfigSelector);
    const information = useSelector(meterCommunicatorInformationSelector);
    const latestProfile = useSelector(meterLatestProfileSelector);
    const meterLabels = useSelector(meterLabelsSelector);

    const meterSchemesById = useSelector(
        resourcesMeterActionSchemesByIdSelector()
    );
    const timeSynchronizationSchemesById = useSelector(
        resourcesTimeSynchronizationSchemesByIdSelector()
    );

    const meterScheme = meterSchemesById[meter?.meterActionSchemeId];

    const timeSynchronizationScheme =
    timeSynchronizationSchemesById[meter?.timeSynchronizationSchemeId];

    const meterTariffScheduleResponse = useSelector(meterTariffSchedulesSelector);

    const onClickBySurveyPlan = (): void => {
        const defaultRoute = getRoute(Routes.meterActionPlan, {}, { meterId });
        dispatch(push(defaultRoute));
    };

    const onCopyToClipboard = () => {
        const isCopied = copy(meter.foreignId);

        if (isCopied) {
            setIsShowForeignIdModal(false);

            dispatch(
                createSnackbar({
                    type: 'white',
                    message: 'ИД ПУ скопирован в буфер обмена',
                })
            );
        }
    };

    return View({
        ...props,
        uiConfig,
        meter,
        information,
        meterScheme,
        latestProfile,
        timeSynchronizationScheme,
        onClickPlanButton: onClickBySurveyPlan,
        meterLabels,
        popoverAnchor,
        onOpenPopover,
        onClosePopover,
        isPopoverOpen,
        isShowForeignIdModal,
        setIsShowForeignIdModal,
        onCopyToClipboard,
        meterTariffScheduleResponse,
    });
};
