import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { createSnackbar } from '../../../../redux/actions/controls';
import { AccessRule } from '../../../../common/model/access/accessRule';
import { userStatusDictionary } from '../../../../common/model/access/user/userStatus';
import { UserUpdater } from '../../../../common/model/access/user/userUpdater';
import { UserViewModel } from '../../../../common/model/access/user/userViewModel';
import { ISort } from '../../../../common/sort';
import { BaseDispatch } from '../../../../redux/actions';
import { createUser, deleteUser, getEditableUser, updateUser } from '../../../../redux/actions/user/editable';
import { getUsers } from '../../../../redux/actions/user/list';
import { userEditableSlice } from '../../../../redux/reducers/users/editable';
import { editableUserSelector, usersListSelector, usersListTotalSelector } from '../../../../redux/selectors';
import { usePagination } from '../../../shared/components/pagination';
import { useAccessRules } from '../../../shared/hooks/useAccessRules';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import { StateProps, UserMode } from './model';
import { View } from './view';
import { getAccessRolesList } from '../../../../redux/actions/access';
import { getErrorMessage } from '../../../shared/utils/error';
import { Box } from '@material-ui/core';
import { DropdownMenu } from 'app/shared/components/dropdownMenu';

export const UsersList = () => {

    const dispatch = useDispatch<BaseDispatch>();

    const { hasAccess } = useAccessRules();

    const queryParams = useQueryParams();
    const mode = queryParams.get('mode') as UserMode;
    const userId = +queryParams.get('userId');

    const data = useSelector(usersListSelector);
    const dataTotal = useSelector(usersListTotalSelector);

    const editableUser = useSelector(editableUserSelector);

    const [isDeleteUserModalAvailable, setIsDeleteUserModalAvailable] = useState<boolean>(false);
    const [activeUser, setActiveUser] = useState<UserViewModel>(null);

    useEffect(() => {
        if (userId && !isNaN(userId)) {
            dispatch(getEditableUser(userId));
        }
    }, [userId]);

    const fetch = async (sort: ISort) => {
        await dispatch(getUsers(sort));
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 10,
        orderBy: null,
        orderDirection: null,
    }, fetch);

    useEffect(() => {
        onFetch(pagination);
        dispatch(getAccessRolesList());
    }, []);

    const refreshTableData = () => {
        onFetch(pagination);
    };

    const onClickNewUser = () => {
        queryParams.set('mode', UserMode.Create);
        dispatch(push({
            search: queryParams.toString()
        }));
    };

    const onClickEditUser = (dataItem: UserViewModel) => {
        queryParams.set('mode', UserMode.Update);
        queryParams.set('userId', dataItem.id.toString());
        dispatch(push({
            search: queryParams.toString()
        }));
    };

    const onClickDeleteUser = async (deletedData: UserViewModel) => {
        await dispatch(deleteUser(deletedData.id));
        refreshTableData();
        setIsDeleteUserModalAvailable(false);
    };

    const onCloseCreateModal = () => {
        queryParams.delete('mode');
        queryParams.delete('userId');
        dispatch(userEditableSlice.actions.clearEditableUser());
        dispatch(push({
            search: queryParams.toString()
        }));
    };

    const onCloseDeleteModal = () => {
        setIsDeleteUserModalAvailable(false);
    };

    const onSubmit: SubmitHandler<UserUpdater> = async (values) => {
        try {
            switch (mode) {
                case UserMode.Create: {
                    await dispatch(createUser(values));
                    break;
                }
                case UserMode.Update: {
                    await dispatch(updateUser(userId, values));
                    break;
                }
                default: break;
            }
            refreshTableData();
            onCloseCreateModal();
        } catch (err) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(err),
            }));
        }
    };

    const props: StateProps = {
        data,
        mode,
        setOrder,
        setPage,
        orderParams,
        pagination,
        onSubmit,
        dataTotal,
        editableUser,
        onClickNewUser,
        onCloseCreateModal,
        isDeleteUserModalAvailable,
        activeUser,
        onDelete: (user) => onClickDeleteUser(user),
        onCloseDeleteModal,
        columnDefinition: [
            {
                title: '#',
                field: 'id',
                sorting: true,
            },
            {
                title: 'Email',
                field: 'email',
                sorting: true,
            },
            {
                title: 'Фамилия',
                field: 'lastName',
                sorting: true,
            },
            {
                title: 'Имя',
                field: 'name',
                sorting: true,
            },
            {
                title: 'Отчество',
                field: 'patronymic',
                sorting: true,
            },
            {
                title: 'Статус',
                field: 'status',
                sorting: true,
                lookup: userStatusDictionary
            },
            {
                title: 'Действия',
                cellClassName: 'cell-action',
                sorting: false,
                render: (user: UserViewModel) => {
                    return (
                        <Box marginLeft='18px'>
                            <DropdownMenu
                                icon='moreVert'
                                dropdownPosition='bottomRight'
                                options={[
                                    {
                                        label: 'Редактировать',
                                        icon: 'edit',
                                        onClick: () => onClickEditUser(user),
                                    },
                                    {
                                        label: 'Удалить',
                                        icon: 'delete',
                                        onClick: () => {
                                            setActiveUser(user);
                                            setIsDeleteUserModalAvailable(true);
                                        },
                                    }
                                ]} />
                        </Box>
                    );
                },
            },
        ],
        localization: {
            body: {
                editRow: {
                    deleteText: 'Вы уверены, что хотите удалить этого пользователя?'
                }
            }
        },
        hasAccessToUsersList: hasAccess(AccessRule.CanGetUsers)
    };

    return View(props);
};
