export interface MeterProfileReportQueryParams {
    regionId: number;
    meterId: string;
    dateFrom: Date;
    dateTo: Date;
    groupingInterval: MeterProfileReportInterval;
}

export enum MeterProfileReportInterval {
    HalfHour = 'half_hour',
    Hour = 'hour',
}

export const meterProfileReportIntervalDictionary: Record<MeterProfileReportInterval, string> = {
    [MeterProfileReportInterval.HalfHour]: '30 мин',
    [MeterProfileReportInterval.Hour]: '1 час',
};

export interface MeterProfileReportData {
    readingDate: Date;
    sumActivePowerExp: number;
    sumActivePowerImp: number;
    sumReactivePowerExp: number;
    sumReactivePowerImp: number;
}

export interface MeterProfileReportDataView extends MeterProfileReportData {
    readingDateView: string;
}

export interface MeterProfileReportResponse {
    data: MeterProfileReportDataView[];
    meterSerialNum: string;
    meterModelId: number;
    meterTimeZone: string;
    groupingInterval: MeterProfileReportInterval;
}

export const getMinutesFromMeterProfileReportInterval = (interval: MeterProfileReportInterval): number => {
    switch (interval) {
        case MeterProfileReportInterval.HalfHour: {
            return 30;
        }
        default: {
            return 60;
        }
    }
};
