import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import { View } from './view';
import { Period, TimeWindowProps } from './model';
import { binToPeriods, intToBin, periodsToBin, shiftValues } from '../../utils/timePeriods';
import { useEffect, useMemo, useState } from 'react';
import { hoursInDay } from '../../../../common/constants/date';

const defaultWindowScheme = 6710886;
const defaultPeriod: Period = {
    startTime: '00:00',
    endTime: '01.00',
    id: 0
};


export const TimeWindow = (props: TimeWindowProps) => {
    const { value, shift, onChange } = props;
    const [periods, setPeriods] = useState<Period[]>([]);
    const dispatch = useDispatch<BaseDispatch>();


    const preparedBin = useMemo(() => {
        return intToBin(value ?? defaultWindowScheme).split('');
    }, [value]);

    useEffect(() => {
        if (preparedBin.length < hoursInDay) {
            while (preparedBin.length < hoursInDay) {
                preparedBin.unshift('0');
            }
        }
        const shiftBin = shiftValues(preparedBin, -shift);
        setPeriods(binToPeriods(shiftBin.slice().reverse()));
    }, [preparedBin, shift]);

    const updatePeriod = (newPeriod: Period) => {
        const newNumber = periodsToBin(periods.map(period => {
            if (period.id === newPeriod.id) {
                return { ...period, ...newPeriod };
            }
            return period;
        }), shift);
        onChange(newNumber);
    };

    const extractedMaxTime = () => {
        const periodWithMaxTime = periods.reduce((previous, current) => {
            return previous.endTime > current.endTime ? previous : current;
        });
        const maxEndTime = periodWithMaxTime.endTime.split(':')[0];
        return { periodWithMaxTime, maxEndTime };
    };

    const createPeriod = () => {
        const helperNumber = 9;
        let newNumber = 0;
        if (!periods.length) {
            newNumber = periodsToBin([defaultPeriod], shift);
            return onChange(newNumber);
        }
        const { periodWithMaxTime, maxEndTime } = extractedMaxTime();
        if (parseInt(maxEndTime) >= hoursInDay - 1 || periods[periods.length - 1].endTime === '00:00') {
            dispatch(createSnackbar({
                type: 'red',
                message: 'Последний период заканчивается в 00:00 следующих суток, чтобы добавить новый период сместите его на более ранее время и нажмите "Применить"',
            }));
            return;
        }
        const startTime = parseInt(maxEndTime) < helperNumber
            ? '0' + (parseInt(maxEndTime) + 1).toString().concat(':00')
            : (parseInt(maxEndTime) + 1).toString().concat(':00');
        const endTime = parseInt(startTime) < helperNumber
            ? '0' + (parseInt(startTime) + 1).toString().concat(':00')
            : (parseInt(startTime) + 1).toString().concat(':00');

        newNumber = periodsToBin([
            ...periods,
            { startTime, endTime, id: periodWithMaxTime.id + 1 }
        ], shift);
        onChange(newNumber);
    };

    const removePeriod = (id: number) => {
        const newNumber = periodsToBin(periods.filter(period => period.id !== id), shift);
        onChange(newNumber);
    };

    return View({
        ...props,
        periods,
        updatePeriod,
        createPeriod,
        removePeriod,
    });
};
