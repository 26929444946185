import React from 'react';
import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import { Wrapper } from '../../../../../shared/components/wrapper';
import classNames from 'classnames';
import { Table } from '../../../../../shared/components/table';
import { defaults, Tables } from '../../../../../shared/constants';
import { Button } from '../../../../../shared/components/button';
import { SearchForm } from '../../../../search/form';
import { EditMeterGroup } from './model';
import { Typography } from 'app/shared/components/typography';

const useStyles = makeStyles((theme: Theme) => ({
    block: {
        ['@media (min-width: 992px)']: {
            width: 'calc(50% - 36px)',
        },
        ['@media (max-width: 991px)']: {
            width: '100%',
        },
    },
    arrowWrapper: {
        ['@media (max-width: 991px)']: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '16px 0',
            '& button': {
                transform: 'rotate(90deg)',
            },
        },
    },
    text: {
        color: theme.palette.text.hint,
        fontSize: 14,
    },
    paddingTitle: {
        paddingLeft: 12,
    },
    wrapper: {
        height: 840,
        overflowY: 'auto',

        '& .MuiTableRow-root': {
            '& .cell-icon': {
                '& svg': {
                    visibility: 'hidden',
                },
            },

            '&:hover': {
                '& .cell-icon': {
                    '& svg': {
                        visibility: 'visible',
                    },
                },
            }
        },

    },
    count: {
        fontWeight: 'normal',
        paddingLeft: '12px',
        color: '#5C6C7F',
    },
    resultText: {
        fontSize: 16,
        fontWeight: 600,
        color: '#18191F',
    },
}));

export const View = (props: EditMeterGroup) => {
    const {
        onCancelSearchForm,
        onSubmit,
        form,
        columnsDefinition,
        data,
        isShowResult,
        tableRef,
        onClickAddToGroup,
        metersGroupList,
        columnsGroup,
        handleChange,
        expanded,
        countActiveFilters,
        onSelectionChange,
        isSelectedRow,
        addressFilterDefaults
    } = props;
    const classes = useStyles();

    const tableActions =
        <Grid container alignItems='center' justify='space-between'>
            <Grid item>
                <Typography variant='h2' className={classes.resultText}>Результаты поиска:</Typography>
                <Typography variant='caption' className={classes.count}>Найдено {data.length} ПУ</Typography>
            </Grid>
        </Grid>;


    const tableGroupAction = <Grid>
        <Typography variant='body' className={classes.count}>В группе {metersGroupList.length} ПУ</Typography>
    </Grid>;

    return (
        <>
            <Box mt={2}>
                <Grid container alignItems='center' justify='space-between' direction="row">
                    <Grid item className={classes.block}>
                        <Wrapper
                            justifyContent='start'
                            isHeaderUppercase={false}
                            actions={tableGroupAction}
                            className={classNames(classes.wrapper)}
                            title='СОСТАВ ГРУППЫ'
                        >
                            {
                                metersGroupList.length ?
                                    <Table
                                        name={Tables.administrationSchemesTable}
                                        columns={columnsGroup}
                                        data={metersGroupList}
                                        title={null}
                                        variant='white'
                                        useQueryString={true}
                                        options={{
                                            paging: 'disabled',
                                            toolbar: false,
                                            showTitle: false,
                                        }}
                                        fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                                    />
                                    : <Typography
                                        variant='caption'
                                        className={classes.text}
                                    >Переместите ПУ из результатов поиска для добавления в группу</Typography>
                            }
                        </Wrapper>
                    </Grid>
                    <Grid item className={classes.arrowWrapper}>
                        <Box ml={2} mr={2}>
                            <Button variant='primary' onClick={onClickAddToGroup} disabled={!isSelectedRow} square={true} icon='arrowBigLeft' />
                        </Box>
                    </Grid>
                    <Grid item className={classes.block}>
                        <Wrapper className={classes.wrapper} title='Поиск ПУ'>
                            <SearchForm
                                form={form}
                                addressFilterDefaults={addressFilterDefaults}
                                onSubmit={onSubmit}
                                onCancel={onCancelSearchForm}
                                columnDefinition={columnsDefinition}
                                data={data}
                                isShowResult={isShowResult}
                                tableActions={tableActions}
                                isShowSelectAllCheckbox={true}
                                isSelection={true}
                                tableRef={tableRef}
                                isTwoColumn
                                isAccordion
                                handleChange={handleChange}
                                expanded={expanded}
                                countActiveFilters={countActiveFilters}
                                onSelectionChange={onSelectionChange}
                            />
                        </Wrapper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
