export enum MeterUpdateTechnicalParamsOrderStatus {
    Created = 'Created',
    InProgress = 'InProgress',
    Error = 'Error',
    Success = 'Success',
    MaxFailureCountError = 'MaxFailureCountError',
}

export const meterReadingInstantOrderStatusDictionary: Record<MeterUpdateTechnicalParamsOrderStatus, string> = {
    [MeterUpdateTechnicalParamsOrderStatus.Created]: 'Создано',
    [MeterUpdateTechnicalParamsOrderStatus.InProgress]: 'В работе',
    [MeterUpdateTechnicalParamsOrderStatus.Error]: 'Ошибка',
    [MeterUpdateTechnicalParamsOrderStatus.Success]: 'Успешно',
    [MeterUpdateTechnicalParamsOrderStatus.MaxFailureCountError]: 'Превышено кол-во попыток',
};
