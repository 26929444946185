import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterModelViewModel } from '../../../../common/model/meter/meterModel/meterModelViewModel';

export interface MeterModelsListState {
    available: MeterModelViewModel[];
}

const initialState: MeterModelsListState = {
    available: null,
};

export const meterModelsListSlice = createSlice({
    name: '@@vv/meterModels/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<MeterModelViewModel[]>): void {
            state.available = action.payload;
        },
        update(state, action: PayloadAction<MeterModelViewModel>): void {
            if (state.available) {
                state.available = state.available.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }
                    return item;
                });
            }
        },
    },
});
