import { httpService } from 'app/shared/httpWrapper';
import { getApiRoute } from 'app/shared/pipes';
import { appUrls } from 'common/appUrls';
import { AppThunk } from '../index';
import { ISort } from 'common/sort';
import { meterImportCrqListSlice } from 'redux/reducers/meterImportCrq/list';
import { meterImportCrqLogListSlice } from 'redux/reducers/meterImportCrq/logList';
import { MeterFileImportQueryParams } from 'common/model/meter/importCrq/meterFileImportQueryParams';
import { MeterFileImportViewModel } from 'common/model/meter/meterFileImport/meterFileImportViewModel';
import { MeterFileImportLogResponse } from 'common/model/meter/importCrq/meterFileImportLogResponse';


export const findMeterImportCrqList = (params: MeterFileImportQueryParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_find_meter_crq_import, {}, params);
    const data = await httpService.get<MeterFileImportViewModel[]>(route);
    await dispatch(meterImportCrqListSlice.actions.setAvailableMeterImportCrqList(data));
};

export const postImportCrqMetersByExcel = async (
    file: File
): Promise<MeterFileImportViewModel> => {
    const data = new FormData();
    data.append('file', file);
    const createdDocument = await httpService.post<MeterFileImportViewModel>(appUrls.api_post_partner_crq_meters_by_excel, data);
    return createdDocument;
};

export const findMeterImportCrqById = (id: string): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_find_meter_crq_import_by_id, { id });
    const data = await httpService.get<MeterFileImportViewModel>(route);
    await dispatch(meterImportCrqListSlice.actions.setAvailableMeterImportCrq(data));
};

export const findMeterImportCrqLog = (id: string, errors: string|undefined, sort: ISort): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_find_meter_crq_import_log, { id }, { errors, ...sort });
    const data = await httpService.get<MeterFileImportLogResponse>(route);
    await dispatch(meterImportCrqLogListSlice.actions.setAvailableMeterImportCrqLog(data));
};
