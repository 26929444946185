import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Tooltip } from '../tooltip';
import { StateProps } from './model';

const useStyles = makeStyles({
    wrapper: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});

export const View = ({
    title,
    text,
    children,
    onChangeTooltipVisibility,
    visibilityTooltip,
    isContentOverflow,
}: StateProps) => {

    const classes = useStyles();

    if (visibilityTooltip) {
        return (
            <Tooltip text={text} title={title}>
                <div className={classes.wrapper} onMouseOut={() => onChangeTooltipVisibility(false)}>
                    {children}
                </div>
            </Tooltip>
        );
    }
    return (
        <div
            className={classes.wrapper}
            onMouseOver={(event) => onChangeTooltipVisibility(isContentOverflow(event))}>
            {children}
        </div>
    );
};
