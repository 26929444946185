import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { LegalEntityType } from '../../../../../common/model/meter/legalEntityType';
import { MeterInformationFields } from '../../../../../redux/reducers/meters/details/ui';
import { VAutocompleteOption } from '../../../../shared/components/autocomplete/model';
import { Button } from '../../../../shared/components/button';
import { ConditionView } from '../../../../shared/components/conditionView';
import { Forbidden } from '../../../../shared/components/forbidden';
import { HookField } from '../../../../shared/components/hookField';
import { Typography } from '../../../../shared/components/typography';
import {
    accountTypes,
    connectionTypes,
    legalTypes,
    mapLabelValueTypeToVAutocompleteOptions,
    priceCategories,
    purposeTypes, timeZoneTypes
} from '../../../../shared/constants/enums';
import { useWebDictionarySelectSuggestions } from '../../../../shared/hooks/useWebDictionary';
import { StateProps } from './model';
import { useUpdateFromAttributesStyles } from '../styles';


export const View = ({
    form,
    onSubmit,
    onCancel,
    hasAccess,
    uiConfig,
    partners,
    isMeterHasImport,
    meterFirmwareVersions,
}: StateProps) => {

    if (!hasAccess) {
        return <Forbidden />;
    }

    const { handleSubmit, formState: { dirtyFields }, getValues } = form;
    const formValues = getValues();

    const isCompany = formValues.legalEntityType === LegalEntityType.Company;

    const classes = useUpdateFromAttributesStyles();

    const { meterModels } = useWebDictionarySelectSuggestions();

    const partnersOptions: VAutocompleteOption[] = partners?.map(item => ({
        label: item.name,
        optionsLabel: item.name,
        value: item.id,
    }));

    const meterFirmwareVersionOptions: VAutocompleteOption[] = meterFirmwareVersions?.map(item => ({
        label: item.version,
        optionsLabel: item.version,
        value: item.version,
    }));

    return <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.ConnectionType]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Тип включения</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='connectionType'
                                    type='select'
                                    label='Тип включения'
                                    suggestions={connectionTypes}
                                    inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['connectionType'] }) }}
                                    fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.AccountType]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Тип учета</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='accountType'
                                    type='select'
                                    label='Тип учета'
                                    suggestions={accountTypes}
                                    inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['accountType'] }) }}
                                    fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.LegalEntityType]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Правовая форма владельца</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='legalEntityType'
                                    type='select'
                                    label='Правовая форма владельца'
                                    suggestions={legalTypes}
                                    inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['legalEntityType'] }) }}
                                    fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.MeterPurpose]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Назначение прибора</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='meterPurpose'
                                    type='select'
                                    disabled={isCompany}
                                    label='Назначение прибора'
                                    suggestions={isCompany ? [] : purposeTypes}
                                    inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['meterPurpose'] }) }}
                                    fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.PriceCategory]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Ценовая категория</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='priceCategory'
                                    type='select'
                                    label='Ценовая категория'
                                    suggestions={priceCategories}
                                    inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['priceCategory'] }) }}
                                    fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.PartnerId]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Партнер</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    fullWidth
                                    name='partnerId'
                                    type='select'
                                    suggestions={partnersOptions}
                                    disabled={isMeterHasImport}
                                    label='Партнер' />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.MeterModel]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Тип ПУ</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    fullWidth
                                    name='meterModelId'
                                    type='select'
                                    required
                                    suggestions={meterModels}
                                    label='Тип ПУ' />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.MeterSerialNum]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Заводской номер</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    fullWidth
                                    name='meterSerialNum'
                                    type='text'
                                    required
                                    label='Заводской номер' />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.Code]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>АИИС КУЭ</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    fullWidth
                                    name='code'
                                    type='text'
                                    required
                                    disabled={isMeterHasImport}
                                    label='АИИС КУЭ' />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.TimeZone]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Временная зона (смещение от UTC)</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    fullWidth
                                    name='timeZone'
                                    type='autocomplete'
                                    options={mapLabelValueTypeToVAutocompleteOptions(timeZoneTypes)}
                                    required
                                    disabled={isMeterHasImport}
                                    label='Временная зона (смещение от UTC)' />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <ConditionView visible={uiConfig.informationFields[MeterInformationFields.MeterFirmwareVersion]}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Версия<br />метрологического ПО</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    fullWidth
                                    name='meterFirmwareVersion'
                                    type='autocompleteText'
                                    required
                                    disabled={isMeterHasImport}
                                    options={meterFirmwareVersionOptions}
                                    label='Версия метрологического ПО' />
                            </Grid>
                        </Grid>
                    </Grid>
                </ConditionView>

                <Grid item xs={12}>
                    <Grid container spacing={2} justify='center' className={classes.buttonContainer}>
                        <Grid item>
                            <Button variant='primary' type='submit' icon='check'>Сохранить</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </FormProvider>;
};
