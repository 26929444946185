import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { IStateProps } from './model';

const useStyles = makeStyles((theme) => ({
    scrollWrapper: {
        position: 'relative',
    },
    scrollDiv: {
        overflowY: 'scroll',
        transition: 'all 0.5s linear',
        '&::-webkit-scrollbar': {
            width: '2px',
            backgroundColor: theme.palette.neutral.white,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '100px',
            backgroundColor: theme.palette.neutral.lightGray,
        },
    },
    notScrollable: {
        pointerEvents: 'none',
        filter: 'blur(4px)',
        opacity: 0.5,
    },
    loadingIndicator: {
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 2,
        textAlign: 'center',
        opacity: 0,
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'center',
    },
    loadingIndicatorTop: {
        top: 0,
    },
    loadingIndicatorBottom: {
        bottom: 0,
    },
    isScrollIndicatorVisible: {
        opacity: 1,
    },
    loadingIndicatorText: {
        ...theme.typography.caption,
        backgroundColor: theme.palette.neutral.white,
        borderRadius: 8,
        padding: '4px 8px',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        display: 'inline-flex',
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const {
        scrollDivRef,
        children,
        isLoadingTop,
        isLoadingBottom,
        isLoading,
        wrapperProps = {},
        onScrollStart,
        onScrollEnd,
        loadingText = 'Загрузка ...',
    } = props;

    const classes = useStyles();

    const loadingIndicatorText = (
        <div className={classes.loadingIndicatorText}>
            {loadingText}
        </div>
    );

    return (
        <div className={classes.scrollWrapper}>
            {onScrollStart && (
                <div
                    className={
                        classNames(
                            classes.loadingIndicator,
                            classes.loadingIndicatorTop,
                            {
                                [classes.isScrollIndicatorVisible]: isLoadingTop
                            },
                        )
                    }
                >
                    {loadingIndicatorText}
                </div>
            )}
            <div
                {...wrapperProps}
                ref={scrollDivRef}
                className={
                    classNames(
                        classes.scrollDiv,
                        wrapperProps?.className,
                        {
                            [classes.notScrollable]: isLoading,
                        },
                    )
                }
            >
                {children}
            </div>
            {onScrollEnd && (
                <div
                    className={
                        classNames(
                            classes.loadingIndicator,
                            classes.loadingIndicatorBottom,
                            {
                                [classes.isScrollIndicatorVisible]: isLoadingBottom
                            },
                        )
                    }
                >
                    {loadingIndicatorText}
                </div>
            )}
        </div>
    );
};
