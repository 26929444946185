import React from 'react';
import { makeStyles, Typography as MuiTypography } from '@material-ui/core';
import { IStateProps } from './model';
import classNames from 'classnames';


const useStyles = makeStyles(() => ({
    h1: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '28px',
        letterSpacing: '0.01em',
    },
    h2: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '0.01em',
    },
    h3: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    h4: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.01em',
        textTransform: 'uppercase',
    },
    body: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    bodyBold: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    caption: {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.01em',
    },
    captionBold: {
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0.01em',
    },
    button: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    input: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.01em',
    },
    label: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0.01em',
    },
}));

export const View = ({ variant, component, className, children, onMouseOver, onMouseOut, onClick }: IStateProps) => {

    const classes = useStyles();

    return (
        <MuiTypography
            className={classNames(classes[variant], className)}
            variant={undefined}
            component={component ?? 'span'}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
        >{children}</MuiTypography>
    );
};
