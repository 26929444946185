import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteConfigComponentProps } from 'react-router-config';
import { useParams } from 'react-router-dom';
import { BaseDispatch } from '../../../redux/actions';
import { getMassOperation, setMassOperation } from '../../../redux/actions/massOperations';
import { massOperationsDetailsAvailableSelector, massOperationsHasNotCompletedSelector } from '../../../redux/selectors/massOperations';
import { useCheckMassOperation } from '../../content/monitoring/massOperation/hooks/useCheckMassOperation';
import { MassOperationProps } from '../../content/monitoring/massOperation/massOperationDetails/model';
import { MonitoringTabs, Routes } from '../../shared/constants';
import { getRoute } from '../../shared/pipes';


export const MassOperationResolver = (viewComponent: React.FC<MassOperationProps>) => {
    return (props: RouteConfigComponentProps) => {

        const massOperation = useSelector(massOperationsDetailsAvailableSelector());
        const hasNotCompleted = useSelector(massOperationsHasNotCompletedSelector());

        const dispatch = useDispatch<BaseDispatch>();
        const { id } = useParams<{ id: string; }>();
        const [isLoading, setIsLoading] = useState(false);

        useCheckMassOperation();

        const fetch = async () => {
            setIsLoading(true);
            try {
                await dispatch(getMassOperation(+id));
            } catch (e) {
                dispatch(push(getRoute(Routes.monitoring, {}, { tab: MonitoringTabs.MassOperation })));
            }
            setIsLoading(false);
        };

        const reFetch = async () => {
            await fetch();
        };

        React.useEffect(() => {
            fetch();
        }, [id]);

        React.useEffect(() => {
            return () => {
                dispatch(setMassOperation(null));
            };
        }, []);

        if (!massOperation) {
            return null;
        }

        return React.createElement(viewComponent, {
            ...props,
            hasNotCompleted,
            massOperation,
            isLoading,
            reFetch,
        });
    };
};
