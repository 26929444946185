import React from 'react';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../../../../shared/utils/validation';
import yup from '../../../../../shared/utils/yup';
import { DefaultNetworkOperatorFormData, InputProps } from './model';
import { View } from './view';

const defaultNetworkOperatorValidationSchema = yup.object({
    defaultNetworkOperatorId: yup.number().required(),
});

export const DefaultNetworkOperatorForm: React.FC<InputProps> = (props) => {
    const {
        defaultNetworkOperator,
    } = props;

    const defaultValues: DefaultNetworkOperatorFormData = {
        defaultNetworkOperatorId: defaultNetworkOperator?.id ?? null,
    };
    const resolver = useYupValidationResolver<DefaultNetworkOperatorFormData>(defaultNetworkOperatorValidationSchema);

    const form = useForm<DefaultNetworkOperatorFormData>({
        defaultValues,
        resolver,
    });

    return View({
        ...props,
        form,
    });
};
