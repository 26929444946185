import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    MeterChangePasswordRequestListResponse
} from '../../../../common/model/meter/meterChangePasswordRequest/meterChangePasswordRequestListResponse';

export interface MeterChangePasswordRequestsList {
    available: MeterChangePasswordRequestListResponse | null;
}

const initialState: MeterChangePasswordRequestsList = {
    available: null,
};

export const meterChangePasswordRequestsListSlice = createSlice({
    name: '@@vv/meterChangePasswordRequests/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<MeterChangePasswordRequestListResponse>): void {
            state.available = action.payload;
        }
    }
});
