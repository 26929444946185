import * as React from 'react';
import { IStateProps } from './model';
import './style.scss';

export const View = (props: IStateProps) => {
    return <div className='highlightJson'>
        <pre>
            <code className='hljs json' ref={props.ref}>
                {props.json}
            </code>
        </pre>
    </div>;
};
