import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    NotConnectedMetersReportResponse,
} from '../../../../common/model/meter/reports/notConnectedMeters';

export interface NotConnectedMetersReportState {
    available: NotConnectedMetersReportResponse;
}

const initialState: NotConnectedMetersReportState = {
    available: null,
};

export const notConnectedMetersReportSlice = createSlice({
    name: '@@vv/reports/notConnectedMetersReport',
    initialState,
    reducers: {
        setReport(state, action: PayloadAction<NotConnectedMetersReportResponse>): void {
            state.available = action.payload;
        },
    },
});
