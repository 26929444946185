import React, { useState } from 'react';
import { View } from './view';
import { CrqChannelCategory } from 'common/model/meter/crq/crqChannelCategory';
import { CrqChannelCreator } from 'common/model/meter/crq/crqChannelCreator';
import { CrqSystemTableProps } from './model';

export const CrqChannelsTable: React.FC<CrqSystemTableProps> = ({ crqChannels, handleChangeCrqChannels }) => {
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const [deleteCrqChannelCategory, setDeleteCrqChannelCategory] = useState<CrqChannelCategory | undefined>(undefined);
    const [editableChannel, setEditableChannel] = useState<CrqChannelCreator | undefined>(undefined);

    const onRemoveChannel = () => {
        const filteredCrqChannels = crqChannels.filter(item => item.category !== deleteCrqChannelCategory);
        setDeleteCrqChannelCategory(undefined);
        return handleChangeCrqChannels(filteredCrqChannels);
    };

    const onRemoveModalToggle = (category:CrqChannelCategory) => {
        setDeleteCrqChannelCategory(category);
    };

    const onEditChannel = (crqChannel:CrqChannelCreator) => {
        const changedChannels = crqChannels.map(item => {
            if (item.category === crqChannel.category) {
                return crqChannel;
            }
            return item;
        });
        return handleChangeCrqChannels(changedChannels);
    };

    const onAddChannel = (crqChannel:CrqChannelCreator) => {
        return handleChangeCrqChannels([...crqChannels, crqChannel]);
    };

    const onCloseForm = () => {
        setIsFormOpen(false);
        setEditableChannel(undefined);
    };

    const onOpenModal = (channel?: CrqChannelCreator) => {
        setIsFormOpen(true);
        if (channel) {
            setEditableChannel(channel);
        }
    };

    return View({
        crqChannels,
        isFormOpen,
        deleteCrqChannelCategory,
        editableChannel,
        handleRemoveModalToggle: onRemoveModalToggle,
        handleOpenModal: onOpenModal,
        handleCloseForm: onCloseForm,
        handleRemoveChannel: onRemoveChannel,
        handleEditChannel: onEditChannel,
        handleAddChannel: onAddChannel,
    });
};
