import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SpecialDayBaseViewModel } from '../../../../common/model/meter/specialDayBase/specialDayBaseViewModel';

export interface SpecialDayBaseListState {
    available: SpecialDayBaseViewModel[];
}

const initialState: SpecialDayBaseListState = {
    available: null,
};

export const specialDayBaseListSlice = createSlice({
    name: '@vv/specialDayBase/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<SpecialDayBaseViewModel[]>) {
            state.available = action.payload;
        },
    },
});
