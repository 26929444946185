import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MeterActionSchemeViewModel } from '../../../common/model/meter/meter-action-scheme/meterActionSchemeViewModel';
import { MeterSchemeChangeRequestViewModel } from '../../../common/model/meter/meter/meterSchemeChangeRequestViewModel';
import { meterSchemesListSlice } from '../../reducers/meterSchemes/list';
import { AppThunk } from '../index';

export const setMeterSchemeChangeRequest = (data: MeterSchemeChangeRequestViewModel): AppThunk => (dispatch) => {
    dispatch(meterSchemesListSlice.actions.setCurrentSchemeChangeRequest(data));
};

export const getMeterSchemeChangeRequest = (schemeId: number): AppThunk => async (dispatch) => {
    try {
        const route = getApiRoute(appUrls.api_get_meter_action_scheme_change_request, { schemeId });
        const data = await httpService.get<MeterSchemeChangeRequestViewModel | null>(route);
        dispatch(setMeterSchemeChangeRequest(data));
    } catch {
        dispatch(setMeterSchemeChangeRequest(null));
    }
};

export const getMeterSchemes = (): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_action_schemes);
    const data = await httpService.get<MeterActionSchemeViewModel[]>(route);
    dispatch(meterSchemesListSlice.actions.setAvailableSchemes(data));
};

export const createMeterActionScheme = (creator: MeterActionSchemeViewModel): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const route = getApiRoute(appUrls.api_post_meter_action_scheme);
    const data = await httpService.post<MeterActionSchemeViewModel>(route, creator);
    dispatch(meterSchemesListSlice.actions.setAvailableSchemes([
        ...state.meterSchemes.list.available,
        data,
    ]));
};


export const updateMeterActionScheme = (schemeId: number, updater: MeterActionSchemeViewModel): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const route = getApiRoute(appUrls.api_put_meter_action_scheme, { schemeId });
    const data = await httpService.put<MeterActionSchemeViewModel>(route, updater);
    dispatch(
        meterSchemesListSlice.actions.setAvailableSchemes(
            state.meterSchemes.list.available.map(item => {
                if (item.id === schemeId) {
                    return data;
                }
                return item;
            })
        )
    );
};
