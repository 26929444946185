import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 28,
        minWidth: 60
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        height: 28,
        minWidth: 60
    },
    viewButton: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    password: {
        color: theme.palette.text.primary,
        cursor: 'pointer'
    }
}));
