import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { MeterCommunicationParamsUpdater } from '../../../../../common/model/meter/meter/meterCommunicationParamsUpdater';
import { MeterStatus } from '../../../../../common/model/meter/meterStatus';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import {
    modalNotificationsPop,
    modalNotificationsPush,
} from '../../../../../redux/actions/controls/modalNotifications';
import {
    updateMeterCl,
    updateMeterCommunicationParams,
} from '../../../../../redux/actions/meterUpdateAttributes';
import { ModalNotificationVariant } from '../../../../../redux/reducers/controls/modalNotifications';
import { resourcesNetworkOperatorsSelector } from '../../../../../redux/selectors';
import { meterSelector, meterCommunicatorInformationSelector } from '../../../../../redux/selectors/meters';
import { HttpStatusCode } from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { getErrorMessage } from '../../../../shared/utils/error';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import yup from '../../../../shared/utils/yup';
import { actionQueryParam } from '../model';
import { MeterCommunicationParamsUpdateFormValues, StateProps } from './model';
import { View } from './view';

const communicationParamsUpdateValidationScheme = yup.object({
    iccId: yup.string().required(),
    ipPort: yup.string().required(),
    networkAddress: yup.string().required(),
});

export const CommunicationParamsUpdateForm = () => {
    const { meterId } = useParams<{ meterId: string }>();
    const queryParams = useQueryParams();
    const meter = useSelector(meterSelector);
    const meterInformation = useSelector(meterCommunicatorInformationSelector);
    const networkOperators = useSelector(resourcesNetworkOperatorsSelector());

    const dispatch = useDispatch<BaseDispatch>();

    const { hasAccess } = useAccessRules();

    const defaultValues: MeterCommunicationParamsUpdateFormValues = {
        iccId: meter.iccId ?? '',
        ipPort: meter.ipPort ?? null,
        networkAddress: meter.networkAddress ?? '',
        networkOperatorId: meter.networkOperatorId ?? null,
        changeStatusToActive: meter.status === MeterStatus.Defective,
    };

    const onCloseModal = () => {
        queryParams.delete(actionQueryParam);
        dispatch(
            push({
                search: queryParams.toString(),
            })
        );
    };

    const onSubmit: SubmitHandler<MeterCommunicationParamsUpdateFormValues> = async (
        values
    ) => {
        try {
            await dispatch(
                updateMeterCommunicationParams(
                    meterId,
                    values,
                    values.changeStatusToActive
                )
            );
            onCloseModal();
        } catch (e: any) {
            if (e.status === HttpStatusCode.notFound) {
                dispatch(
                    modalNotificationsPush({
                        showHide: true,
                        variant: ModalNotificationVariant.Alert,
                        title: 'Ошибка подключения',
                        message: `${getErrorMessage(
                            e
                        )}. Вы уверены, что хотите сохранить изменения?`,
                        actions: [
                            {
                                children: 'Сохранить',
                                variant: 'primary',
                                onClick: async () => {
                                    try {
                                        await dispatch(
                                            updateMeterCommunicationParams(
                                                meterId,
                                                values,
                                                values.changeStatusToActive,
                                                true
                                            )
                                        );
                                        onCloseModal();
                                    } finally {
                                        await dispatch(modalNotificationsPop());
                                    }
                                },
                                onEnter: true,
                            },
                            {
                                children: 'Отмена',
                                variant: 'secondary',
                                onClick: async () => {
                                    await dispatch(modalNotificationsPop());
                                },
                                onEscape: true,
                            },
                        ],
                    })
                );
            } else {
                dispatch(
                    createSnackbar({
                        type: 'red',
                        message: getErrorMessage(e),
                    })
                );
            }
        }
    };

    const resolver = useYupValidationResolver<MeterCommunicationParamsUpdater>(
        communicationParamsUpdateValidationScheme
    );

    const form = useForm<MeterCommunicationParamsUpdateFormValues>({
        defaultValues,
        resolver,
    });

    /**
   * Действия по нажатию CL.
   */
    const hadleClClick = () => {
        dispatch(updateMeterCl(meterId, meterInformation.msisdn));
        onCloseModal();
    };

    const props: StateProps = {
        form,
        onCancel: onCloseModal,
        onSubmit,
        hadleClClick,
        networkOperators,
        hasAccess: hasAccess(AccessRule.CanUpdateMeterCommunicationParams),
        isDefected: meter.status === MeterStatus.Defective,
    };

    return View(props);
};
