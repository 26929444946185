import { Grid } from '@material-ui/core';
import { ReadingSource } from 'common/model/meter/readingSource';
import React from 'react';
import { MeterActionType } from '../../../../common/model/meter/meterActionType';
import { Wrapper } from '../../../shared/components/wrapper';
import { InspectionLog } from '../../inspectionLog';
import { InspectionLogPanel } from '../../inspectionLog/components/inspectionLogPanel';
import { MeterReadingDailyChart } from './chart';
import { MeterReadingInstant } from './instant';
import { MeterReadingDailyList } from './list';
import { IStateProps } from './model';

export const View = ({
    meter,
    meterId,
}: IStateProps) => {

    const isReadingSource = meter.readingSource === ReadingSource.ImportCrq;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const collDailyList = isReadingSource ? 12 : 6;
    return (
        <>
            <div className='meter-daily-reading-page'>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={collDailyList}>
                        <Wrapper
                            title='ПОКАЗАНИЯ ПУ'
                        >
                            <MeterReadingDailyList />
                        </Wrapper>
                    </Grid>
                    {!isReadingSource && (
                        <Grid item xs={12} lg={6}>
                            <MeterReadingInstant meter={meter} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Wrapper>
                            <MeterReadingDailyChart />
                        </Wrapper>
                    </Grid>
                </Grid>
            </div>
            <InspectionLogPanel>
                <InspectionLog
                    meterId={meterId}
                    meterActionType={[
                        MeterActionType.InstantParametersProfile,
                    ]}
                />
            </InspectionLogPanel>
        </>
    );
};
