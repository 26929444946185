import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormats } from '../../../../common/constants/date';
import { meterLegalTypeDictionary } from '../../../../common/model/meter/legalEntityType';
import { TariffScheduleOrdersQuery } from '../../../../common/model/meter/tariffSchedule/tariffScheduleOrdersQuery';
import { TariffScheduleOrderViewModel } from '../../../../common/model/meter/tariffSchedule/tariffScheduleOrderViewModel';
import noun, { tariffCountNouns } from '../../../../common/shared/utils/noun';
import { ISort } from '../../../../common/sort';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import { getTariffScheduleOrders } from '../../../../redux/actions/tariffSchedule';
import { tariffScheduleOrdersSelector } from '../../../../redux/selectors/tariffSchedule';
import { usePagination } from '../../../shared/components/pagination';
import { AdministrationTabs, Routes } from '../../../shared/constants';
import { useBreadcrumbs } from '../../../shared/hooks/useBreadcrumbs';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { useHeading } from '../../../shared/hooks/useHeading';
import { useWebDictionarySelectSuggestions } from '../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../shared/pipes';
import { formatDate } from '../../../shared/utils/dates';
import { getErrorMessage } from '../../../shared/utils/error';
import { TariffScheduleOrderPlanStatus } from './tariffScheduleOrderPlanStatus';
import { View } from './view';

const filterDefaultValues: TariffScheduleOrdersQuery = {
    regionId: null,
    tariffCount: null,
    legalEntityType: null,
    planStatus: null,
};

const fetchDebounceTimeMS = 300;

export const TariffScheduleOrders = () => {

    const dispatch = useDispatch<BaseDispatch>();

    const form = useForm<TariffScheduleOrdersQuery>({
        defaultValues: filterDefaultValues,
    });

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.Schemes }) },
        { name: 'Тарифное расписание', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.TariffSchedules }) },
        { name: 'Заявки на смену тарифного расписания', link: null },
    ]);

    const headingProps = useHeading('Заявки на смену тарифного расписания', () => {
        dispatch(push(getRoute(Routes.administration, null, { tab: AdministrationTabs.TariffSchedules })));
    });

    const { regions: regionsSuggestions } = useWebDictionarySelectSuggestions();
    const regionLabelByValue = useMemo(() => {
        return _.keyBy(regionsSuggestions, 'value');
    }, [regionsSuggestions]);

    const { dataTotal, available: data } = useSelector(tariffScheduleOrdersSelector);
    const columns: Column[] = [
        {
            title: 'Тип ПУ',
            field: 'meterModelName',
            sorting: false,
        },
        {
            title: 'Заводской номер',
            field: 'meterSerialNum',
            sorting: false,
        },
        {
            title: 'Статус опроса',
            field: 'planStatus',
            sorting: false,
            render: (data: TariffScheduleOrderViewModel) => {
                return <TariffScheduleOrderPlanStatus order={data} />;
            },
        },
        {
            title: 'Количество попыток',
            field: 'attemptCount',
            sorting: false,
        },
        {
            title: 'Дата последней попытки',
            field: 'lastAttemptTimestamp',
            render: (data: TariffScheduleOrderViewModel) => {
                if (!data.lastAttemptTimestamp) {
                    return '–';
                }
                return formatDate(data.lastAttemptTimestamp, DateFormats.dateTimeFormat);
            },
        },
        {
            title: 'Результат последней попытки',
            field: 'lastResultTaskResult',
            sorting: false,
        },
        {
            title: 'Дата следующей попытки',
            field: 'nextReviewTimestamp',
            render: (data: TariffScheduleOrderViewModel) => {
                if (!data.nextReviewTimestamp) {
                    return '–';
                }
                return formatDate(data.nextReviewTimestamp, DateFormats.dateTimeFormat);
            },
        },
        {
            title: 'Тарифное расписание',
            field: 'tariffCount',
            sorting: false,
            render: (data: TariffScheduleOrderViewModel) => {
                const region = `${regionLabelByValue[data.regionId]?.label ?? data.regionId}`;
                const tariffCount = `${data.tariffCount} ${noun(data.tariffCount, tariffCountNouns)}`;
                const legalEntityType = `${meterLegalTypeDictionary[data.legalEntityType]}`;
                return `${region}, ${tariffCount} , ${legalEntityType}`;
            },
        },
    ];

    const formData = form.watch();

    const fetch = async (sort: ISort, query: TariffScheduleOrdersQuery = formData) => {
        try {
            await dispatch(getTariffScheduleOrders(query, sort));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 10,
        orderBy: null,
        orderDirection: null,
    }, fetch);

    const changeFormData = useDebounce(formData, fetchDebounceTimeMS);

    useEffect(() => {
        onFetch(pagination);
    }, [changeFormData]);

    const loading = data === null;

    return View({
        ...breadcrumbProps,
        ...headingProps,
        data,
        columns,
        pagination,
        loading,
        setOrder,
        setPage,
        regionsSuggestions,
        orderParams,
        dataTotal,
        form,
    });
};
