import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Table } from 'app/shared/components/table';
import { setColumnWidth } from 'app/shared/pipes/styles';
import { Modal } from 'app/shared/components/modal';
import { CrqChannelsTableViewProps } from './model';
import { getCrqChannelsColumns } from './columns';
import { Typography } from 'app/shared/components/typography';
import { CrqChannelCategory } from 'common/model/meter/crq/crqChannelCategory';
import { CrqChannelsForm } from '../crqChannelsForm';
import { ActionModal } from 'app/shared/components/actionModal';

const useStyles = makeStyles((theme) => ({
    addButtonWrapper: {
        marginLeft: 'auto',
    },
    tableCols: {
        '&.col-category': {
            ...setColumnWidth('120px'),
        },
        '&.col-type': {
            ...setColumnWidth('120px'),
        },
        '&.col-number': {
            ...setColumnWidth('120px'),
        },
        '&.col-unitOfMeasurement': {
            ...setColumnWidth('120px'),
        },
        '&.col-actions': {
            ...setColumnWidth('120px'),
        },
    },
    blockTitle: {
        marginBottom: 10,
        display: 'block',
    },
    addChannelText: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginTop: 10,
        display: 'block',
    },
}));

export const View: React.FC<CrqChannelsTableViewProps> = ({
    crqChannels,
    isFormOpen,
    handleOpenModal,
    deleteCrqChannelCategory,
    handleRemoveModalToggle,
    editableChannel,
    handleEditChannel,
    handleAddChannel,
    handleRemoveChannel,
    handleCloseForm,
}) => {
    const classes = useStyles();

    const columns = getCrqChannelsColumns(handleOpenModal, handleRemoveModalToggle);
    const isVisibleAddChannelsBtn = crqChannels.length === Object.keys(CrqChannelCategory).length;

    return (
        <Box mb={2}>
            <Typography variant='h3' className={classes.blockTitle}>Каналы</Typography>
            <Table
                title="Каналы"
                data={crqChannels}
                columns={columns}
                variant='white'
                options={{
                    headerClassName: classes.tableCols,
                    paging: 'disabled',
                    grouping: false,
                    filtering: false,
                    search: false,
                    toolbar: false,
                    showTitle: false,
                    sorting: false,
                }}
            />

            {!isVisibleAddChannelsBtn && (
                <Typography
                    className={classes.addChannelText}
                    variant={'bodyBold'}
                    onClick={() => handleOpenModal()}
                >
                + Добавить канал
                </Typography>
            )}

            {isFormOpen && (
                <Modal title='Канал CRQ-интерфейса прибора учета' close='Очистить' onHide={handleCloseForm}>
                    <CrqChannelsForm
                        crqChannels={crqChannels}
                        channel={editableChannel}
                        handleAddChannel={handleAddChannel}
                        handleEditChannel={handleEditChannel}
                        handleCloseModal={handleCloseForm}
                    />
                </Modal>
            )}


            {deleteCrqChannelCategory && (
                <ActionModal
                    title='Удаление канала'
                    description='Вы действительно хотите удалить канал?'
                    closeModal={() => handleRemoveModalToggle()}
                    actions={[
                        {
                            onClick: () => handleRemoveChannel(),
                            title: 'Да, удалить',
                            variant: 'primary',
                        },
                        {
                            onClick: () => handleRemoveModalToggle(),
                            title: 'Отмена',
                            variant: 'secondary',
                        }
                    ]}
                />
            )}
        </Box>
    );
};
