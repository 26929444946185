import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { DateFormats } from '../../../../../common/constants/date';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Button } from '../../../../shared/components/button';
import { Heading } from '../../../../shared/components/heading';
import { formatDate } from '../../../../shared/utils/dates';
import { MeterActionSchemeForm } from '../form';
import { SchemeEditViewProps } from './model';
import { Wrapper } from '../../../../shared/components/wrapper';

const useStyles = makeStyles((theme) => ({
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    schemeInProgressWrapper: {
        ...theme.typography.h4,
        color: theme.palette.text.primary,
    },
}));

export const View = (props: SchemeEditViewProps) => {
    const {
        currentSchemeChangeRequest,
        onClickBreadcrumb,
        onClickDuplicate,
        initialValues,
        breadcrumbs,
        onCancel,
        onSubmit,
        scheme
    } = props;

    const classes = useStyles();

    return (
        <div>
            <Box mb={2}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2} className={classes.titleWrapper}>
                <Heading heading={scheme.name} onBackHandler={onCancel} />
                <Button onClick={onClickDuplicate} variant='outline' icon='duplicate'>Дублировать</Button>
            </Box>
            {currentSchemeChangeRequest && (
                <Box mb={2} className={classes.schemeInProgressWrapper}>
                    Изменение схемы опроса в работе, время начала:{' '}
                    <strong>{formatDate(currentSchemeChangeRequest.createdAt, DateFormats.dateFullTimeFormat)}</strong>
                </Box>
            )}
            <Wrapper>
                <MeterActionSchemeForm
                    disabled={!!currentSchemeChangeRequest}
                    initialValues={initialValues}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            </Wrapper>
        </div>
    );
};
