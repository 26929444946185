import { push } from 'connected-react-router';
import React, { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessRule } from '../../../../common/model/access/accessRule';
import { MeterUpdateAttributesActions } from '../../../../common/model/meter/meter/meterUpdateAttributesActions';
import { MeterStatus } from '../../../../common/model/meter/meterStatus';
import { BaseDispatch } from '../../../../redux/actions';
import { modalNotificationsPop, modalNotificationsPush } from '../../../../redux/actions/controls/modalNotifications';
import { updateMeterStatusToActive, updateMeterStatusToDecommissioned } from '../../../../redux/actions/meterUpdateAttributes';
import { ModalNotificationVariant } from '../../../../redux/reducers/controls/modalNotifications';
import { meterSelector, meterUiConfigSelector } from '../../../../redux/selectors/meters';
import { useAccessRules } from '../../../shared/hooks/useAccessRules';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';
import { actionQueryParam, MeterUpdateAttributeAction, StateProps } from './model';
import { View } from './view';

export const MeterActions: React.FC = () => {
    const [actionsPopoverAnchor, setActionsPopoverAnchor] = useState(null);
    const isActionsPopoverOpen = Boolean(actionsPopoverAnchor);
    const meter = useSelector(meterSelector);
    const uiConfig = useSelector(meterUiConfigSelector);

    const queryParams = useQueryParams();

    const dispatch = useDispatch<BaseDispatch>();

    const { hasAccess } = useAccessRules();

    const handleOpenActionsPopover = (event: MouseEvent) => {
        setActionsPopoverAnchor(event.currentTarget);
    };

    const handleCloseActionsPopover = () => {
        setActionsPopoverAnchor(null);
    };

    const handleAction = (action: MeterUpdateAttributeAction) => {
        setActionsPopoverAnchor(null);
        action();
    };

    const props: StateProps = {
        isActionsPopoverOpen,
        handleOpenActionsPopover,
        handleCloseActionsPopover,
        handleAction,
        actionsPopoverAnchor,
        actions: [
            {
                key: MeterUpdateAttributesActions.ChangeCommunicationParams,
                name: 'Изменение параметров связи',
                icon: 'connection',
                hasAccess:
                    hasAccess(AccessRule.CanUpdateMeterCommunicationParams)
                    && uiConfig.actions[MeterUpdateAttributesActions.ChangeCommunicationParams],
                action: () => {
                    queryParams.set(actionQueryParam, MeterUpdateAttributesActions.ChangeCommunicationParams);
                    dispatch(push({
                        search: queryParams.toString()
                    }));
                },
            },
            {
                key: MeterUpdateAttributesActions.ReplacingCommunicator,
                name: 'Замена коммуникатора',
                icon: 'simCard',
                hasAccess:
                    hasAccess(AccessRule.CanUpdateMeterReplacingCommunicator)
                    && uiConfig.actions[MeterUpdateAttributesActions.ReplacingCommunicator],
                action: () => {
                    queryParams.set(actionQueryParam, MeterUpdateAttributesActions.ReplacingCommunicator);
                    dispatch(push({
                        search: queryParams.toString()
                    }));
                },
            },
            {
                key: MeterUpdateAttributesActions.ChangeTransformationRatios,
                name: 'Изменение коэффициентов трансформации',
                icon: 'multilineChart',
                hasAccess:
                    hasAccess(AccessRule.CanUpdateMeterTransformationRatios)
                    && uiConfig.actions[MeterUpdateAttributesActions.ChangeTransformationRatios],
                action: () => {
                    queryParams.set(actionQueryParam, MeterUpdateAttributesActions.ChangeTransformationRatios);
                    dispatch(push({
                        search: queryParams.toString()
                    }));
                },
            },
            {
                key: MeterUpdateAttributesActions.ChangeAddress,
                name: 'Изменение адреса',
                icon: 'location',
                hasAccess:
                    hasAccess(AccessRule.CanUpdateMeterAddress)
                    && uiConfig.actions[MeterUpdateAttributesActions.ChangeAddress],
                action: () => {
                    queryParams.set(actionQueryParam, MeterUpdateAttributesActions.ChangeAddress);
                    dispatch(push({
                        search: queryParams.toString()
                    }));
                },
            },
            {
                key: MeterUpdateAttributesActions.MeterVerification,
                name: 'Поверка счетчика',
                icon: 'event',
                hasAccess:
                    hasAccess(AccessRule.CanUpdateMeterVerification)
                    && uiConfig.actions[MeterUpdateAttributesActions.MeterVerification],
                action: () => {
                    queryParams.set(actionQueryParam, MeterUpdateAttributesActions.MeterVerification);
                    dispatch(push({
                        search: queryParams.toString()
                    }));
                },
            },
            {
                key: MeterUpdateAttributesActions.ChangeStatusToActive,
                name: 'Изменение статуса ПУ на Активный',
                icon: 'notStarted',
                hasAccess:
                    hasAccess(AccessRule.CanUpdateMeterStatusToActive)
                    && meter?.status === MeterStatus.Defective
                    && uiConfig.actions[MeterUpdateAttributesActions.ChangeStatusToActive],
                action: () => {
                    dispatch(modalNotificationsPush({
                        showHide: true,
                        variant: ModalNotificationVariant.Alert,
                        title: 'Изменение статуса ПУ на Активный',
                        message: 'Вы уверены, что хотите сменить статус ПУ на Активный?',
                        actions: [
                            {
                                children: 'Да, изменить статус',
                                variant: 'primary',
                                onClick: async () => {
                                    await dispatch(updateMeterStatusToActive(meter?.id));
                                    await dispatch(modalNotificationsPop());
                                },
                                onEnter: true,
                            },
                            {
                                children: 'Отмена',
                                variant: 'secondary',
                                onClick: async () => {
                                    await dispatch(modalNotificationsPop());
                                },
                                onEscape: true,
                            }
                        ],
                    }));
                }
            },
            {
                key: MeterUpdateAttributesActions.ChangeStatusToDecommissioned,
                name: 'Изменение статуса ПУ на Выведен из эксплуатации',
                icon: 'notStarted',
                hasAccess:
                    hasAccess(AccessRule.CanUpdateMeterStatusToDecommissioned)
                    && meter?.status !== MeterStatus.Decommissioned
                    && uiConfig.actions[MeterUpdateAttributesActions.ChangeStatusToDecommissioned],
                action: () => {
                    dispatch(modalNotificationsPush({
                        showHide: true,
                        variant: ModalNotificationVariant.Alert,
                        title: 'Изменение статуса ПУ на Выведен из эксплуатации',
                        message: 'Вы уверены, что хотите сменить статус ПУ на Выведен из эксплуатации?',
                        actions: [
                            {
                                children: 'Да, изменить статус',
                                variant: 'primary',
                                onClick: async () => {
                                    await dispatch(updateMeterStatusToDecommissioned(meter?.id));
                                    await dispatch(modalNotificationsPop());
                                },
                                onEnter: true,
                            },
                            {
                                children: 'Отмена',
                                variant: 'secondary',
                                onClick: async () => {
                                    await dispatch(modalNotificationsPop());
                                },
                                onEscape: true,
                            }
                        ],
                    }));
                }
            },
            {
                key: MeterUpdateAttributesActions.ChangeInformationFields,
                name: 'Изменение информационных полей',
                icon: 'edit',
                hasAccess:
                    hasAccess(AccessRule.CanUpdateMeterInformationFields)
                    && uiConfig.actions[MeterUpdateAttributesActions.ChangeInformationFields],
                action: () => {
                    queryParams.set(actionQueryParam, MeterUpdateAttributesActions.ChangeInformationFields);
                    dispatch(push({
                        search: queryParams.toString()
                    }));
                },
            },
            {
                key: MeterUpdateAttributesActions.ChangeTechnicalParams,
                name: 'Изменение технических параметров',
                icon: 'tools',
                hasAccess:
                    hasAccess(AccessRule.CanViewMeterTechnicalParams)
                    && uiConfig.actions[MeterUpdateAttributesActions.ChangeTechnicalParams],
                action: () => {
                    queryParams.set(actionQueryParam, MeterUpdateAttributesActions.ChangeTechnicalParams);
                    dispatch(push({
                        search: queryParams.toString()
                    }));
                },
            },
        ]
    };

    return View(props);
};
