import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { NetworkOperatorChecker } from '../../../common/model/meter/networkOperator/networkOperatorChecker';
import { NetworkOperatorCheckResult } from '../../../common/model/meter/networkOperator/networkOperatorCheckResult';
import { NetworkOperatorCreator } from '../../../common/model/meter/networkOperator/networkOperatorCreator';
import { NetworkOperatorUpdater } from '../../../common/model/meter/networkOperator/networkOperatorUpdater';
import { NetworkOperatorViewModel } from '../../../common/model/meter/networkOperator/networkOperatorViewModel';
import { ISort } from '../../../common/sort';
import { networkOperatorsListSlice } from '../../reducers/networkOperators/list';
import { AppThunk } from '../index';

export const getNetworkOperators = (
    sort: ISort
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_network_operators, {}, { ...sort });
    const data = await httpService.get<NetworkOperatorViewModel[]>(route);
    dispatch(networkOperatorsListSlice.actions.setAvailable(data));
};

export const createNetworkOperator = (model: NetworkOperatorCreator) => {
    const route = getApiRoute(appUrls.api_post_network_operator);
    return httpService.post<NetworkOperatorViewModel>(route, model);
};

export const checkNetworkOperator = (model: NetworkOperatorChecker): Promise<NetworkOperatorCheckResult> => {
    const route = getApiRoute(appUrls.api_post_network_operator_check);
    return httpService.post<NetworkOperatorCheckResult>(route, model);
};

export const updateNetworkOperator = (id: number, model: NetworkOperatorUpdater) => {
    const route = getApiRoute(appUrls.api_put_network_operator, { id });
    return httpService.put<NetworkOperatorViewModel>(route, model);
};

export const deleteNetworkOperator = (id: number) => {
    const route = getApiRoute(appUrls.api_delete_network_operator, { id });
    return httpService.delete(route);
};
