import { MeterGisTasksQuery, MeterGisTasksResponse } from '../../../common/model/meter/meter/meterGisTasks';
import { MeterGisTaskViewModel } from '../../../common/model/meter/meterGisTask/meterGisTaskViewModel';
import { ISort } from '../../../common/sort';
import { AppThunk } from '../index';
import { meterGisTasksSlice } from '../../reducers/meterGisTasks/meterGisTasks';
import { getApiRoute, RequestParams } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { httpService } from '../../../app/shared/httpWrapper';
import { createSnackbar } from '../controls';
import { MeterGisTaskUpdater } from '../../../common/model/meter/meterGisTask/meterGisTaskUpdater';
import { MeterGisTaskCreator } from '../../../common/model/meter/meterGisTask/meterGisTaskCreator';
import { MeterGisTaskRequest, MeterGisTasksRequest } from '../../../../../common/model/meter/meterGisTask/meterGisTasksReguest';
import { HistoryMeterGisTaskViewModel } from '../../../common/model/meter/historyMeterGisTask/historyMeterGisTaskViewModel';

export const getMeterGisTasks = (params?: MeterGisTasksQuery, sort?: ISort): AppThunk => async (dispatch) => {
    dispatch(meterGisTasksSlice.actions.setLoading(true));
    const route = getApiRoute(appUrls.api_get_meter_gis_tasks, {}, { ...params, ...sort });
    const data = await httpService.get<MeterGisTasksResponse>(route);
    dispatch(meterGisTasksSlice.actions.setAvailableMeterGisTasks(data));
};

export const createMeterGisTask = (params: MeterGisTaskCreator): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_post_meter_gis_task);
    const data = await httpService.post<MeterGisTaskViewModel>(route, { ...params });

    if (data?.id) {
        dispatch(createSnackbar({
            type: 'green',
            message: 'Инцидент создан успешно',
        }));
    }
    dispatch(meterGisTasksSlice.actions.updateAvailableMeterGisTasksAfterCreate(data));
};

export const getMeterGisTaskById = (params: MeterGisTasksQuery): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_gis_task, { ...params });
    const data = await httpService.get<MeterGisTaskViewModel>(route);
    dispatch(meterGisTasksSlice.actions.updateTaskById(data));
};

export const updateMeterGisTask = (params: MeterGisTaskUpdater): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_put_meter_gis_task);
    const data = await httpService.put<MeterGisTaskViewModel>(route, { ...params });

    if (data?.id) {
        dispatch(createSnackbar({
            type: 'green',
            message: 'Инцидент обновлен успешно',
        }));
    }

    dispatch(meterGisTasksSlice.actions.updateTaskById(data));
};

export const closeMeterGisTask = (params: MeterGisTaskRequest): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_close_meter_gis_task);
    const data = await httpService.post<MeterGisTaskViewModel>(route, { ...params });

    if (data?.id) {
        dispatch(createSnackbar({
            type: 'green',
            message: 'Инцидент успешно закрыт',
        }));
    }

    dispatch(meterGisTasksSlice.actions.updateTaskById(data));
};

export const archivedMeterGisTask = (params: MeterGisTaskRequest): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_archive_meter_gis_task);
    const data = await httpService.post<MeterGisTaskViewModel>(route, { ...params });

    if (data?.id) {
        dispatch(createSnackbar({
            type: 'green',
            message: 'Инцидент отправлен в архив успешно',
        }));
    }

    dispatch(meterGisTasksSlice.actions.updateTaskById(data));
};

export const closeGroupMeterGisTasks = (params: MeterGisTasksRequest): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_close_group_gis_tasks);
    const data = await httpService.post<MeterGisTaskViewModel[]>(route, params);

    if (data?.length > 0) {
        dispatch(createSnackbar({
            type: 'green',
            message: 'Инциденты успешно закрыты',
        }));
    }

    dispatch(meterGisTasksSlice.actions.updateGroupTask(data));
};

export const archiveGroupMeterGisTasks = (params: MeterGisTasksRequest): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_archive_group_gis_tasks);
    const data = await httpService.post<MeterGisTaskViewModel[]>(route, params);

    if (data?.length > 0) {
        dispatch(createSnackbar({
            type: 'green',
            message: 'Инциденты успешно отправлены в архив',
        }));
    }

    dispatch(meterGisTasksSlice.actions.updateGroupTask(data));
};

export const syncTasksWithGis = (params: RequestParams): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_get_gis_tasks_by_ids, {}, { ...params });
    await httpService.get<MeterGisTaskViewModel[]>(route);
};

export const getHistoryMeterGisTask = (params: RequestParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_history_meter_gis_tasks, params);
    const data = await httpService.get<HistoryMeterGisTaskViewModel[]>(route);
    dispatch(meterGisTasksSlice.actions.setHistoryGisTask(data));
};
