import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkOperatorViewModel } from '../../../../common/model/meter/networkOperator/networkOperatorViewModel';

export interface NetworkOperatorsListState {
    available: NetworkOperatorViewModel[];
}

const initialState: NetworkOperatorsListState = {
    available: null
};

export const networkOperatorsListSlice = createSlice({
    name: '@@vv/networkOperators/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<NetworkOperatorViewModel[]>): void {
            state.available = action.payload;
        },
    },
});
