import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { downloadExcelData } from '../../../app/shared/utils/download';
import { appUrls } from '../../../common/appUrls';
import { EventLogFindRequest } from '../../../common/model/meter/eventLog/eventLogFindRequest';
import {
    EventLogExcelResponse,
    EventLogsFindResponse
} from '../../../common/model/meter/eventLog/eventLogsFindResponse';
import { SearchMetersResponse } from '../../../common/model/meter/searchMeters/searchMetersResponse';
import { commonEventLogSlice } from '../../reducers/eventLogs/common';
import { AppThunk } from '../index';
import { CriticalEventLogFindRequest } from '../../../../../common/model/meter/eventLog/eventLogFindRequest';
import { ISort } from '../../../common/sort';
import { MeterSearchQuery } from '../../../common/model/meter/meter/meterSearchQuery';

export const commonEventLogsSetLoading = (isLoading: boolean): AppThunk => (dispatch) => {
    dispatch(commonEventLogSlice.actions.setLoading(isLoading));
};

export const findCommonEventLogs = (options?: EventLogFindRequest, sort?: ISort): AppThunk => async (dispatch) => {
    dispatch(commonEventLogsSetLoading(true));
    const route = getApiRoute(appUrls.api_find_event_logs);
    const data = await httpService.post<EventLogsFindResponse>(route, { ...options, ...sort });
    dispatch(commonEventLogSlice.actions.setAvailableEventLogs(data));
};

export const findCommonMeters = (options?: MeterSearchQuery): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_search_meters, {}, options);
    const data = await httpService.get<SearchMetersResponse>(route);
    dispatch(commonEventLogSlice.actions.setMeters(data.data));
};

export const findCriticalLevelHighEventLogs = (options?: CriticalEventLogFindRequest): AppThunk => async (dispatch) => {
    dispatch(commonEventLogsSetLoading(true));
    const route = getApiRoute(appUrls.api_find_high_critical_level_event_logs);
    const data = await httpService.post<EventLogsFindResponse>(route, options);
    dispatch(commonEventLogSlice.actions.setHighCriticalLevelEventLogs(data));
};

export const downloadEventLog = (options?: EventLogFindRequest): AppThunk => async (dispatch) => {
    dispatch(commonEventLogsSetLoading(true));
    const route = getApiRoute(appUrls.api_download_event_logs_excel);
    const { data } = await httpService.post<EventLogExcelResponse>(route, options);
    downloadExcelData(data, 'eventLog');
    dispatch(commonEventLogsSetLoading(false));
};
