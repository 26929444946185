import { Column } from '@softmedialab/materialui-table';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { TariffDayNameCreator } from '../../../../../../common/model/meter/tariffDayName/tariffDayNameCreator';
import { newGuid } from '../../../../../../common/shared/utils';
import { getRange } from '../../../../../shared';
import { HookField } from '../../../../../shared/components/hookField';
import { SelectSuggestion } from '../../../../../shared/components/select/model';
import { getIndexesById } from '../../../../../shared/pipes';
import { InputProps, TariffDayNamesFormValues } from './model';
import { View } from './view';
import * as _ from 'lodash';

export const TariffDayNamesForm: React.FC<InputProps> = (props) => {

    const {
        viewModel,
        tariffScheduleVersionId,
    } = props;

    const form = useForm<TariffDayNamesFormValues>({
        defaultValues: viewModel,
    });

    const formData = form.watch();

    const formArray = useFieldArray({
        control: form.control,
        name: 'tariffDayNames',
    });

    const { append, remove } = formArray;

    const onClickAddRow = () => {
        const allValues = formData.tariffDayNames.map(item => item.code);
        append({
            id: newGuid(),
            tariffScheduleVersionId,
            code: +1 + (allValues.length ? Math.max(...allValues) : 0),
            name: null,
        });
    };

    const onClickDeleteRow = (index: number) => {
        remove(index);
    };

    const indexById: Record<string, number> = getIndexesById(formArray.fields);

    const formDataById: Record<string, TariffDayNameCreator> = _.keyBy(formData?.tariffDayNames ?? [], 'id');

    const data: TariffDayNameCreator[] = formArray.fields.map(item => formDataById[item.id]);

    const columns: Column[] = [
        {
            field: 'code',
            title: 'Код',
            render: (item: TariffDayNameCreator) => {
                const index = indexById[item.id];
                return <>
                    <div>
                        {index} - {item.id}
                    </div>
                    <HookField
                        placeholder='Не выбрано'
                        name={`tariffDayNames.${index}.code`}
                        type='number'
                        variant='standard'
                    />
                </>;
            }
        },
        {
            field: 'name',
            title: 'Название',
            render: (item: TariffDayNameCreator) => {
                const index = indexById[item.id];
                return <>
                    <div>
                        {index} - {item.id}
                    </div>
                    <HookField
                        placeholder='Не указано'
                        name={`tariffDayNames.${index}.name`}
                        type='text'
                        variant='standard'
                    />
                </>;
            }
        },
    ];

    const codeSuggestions = (index: number): SelectSuggestion[] => {
        const currentValue = formData.tariffDayNames[index].code;
        const allValues = formData.tariffDayNames.map(item => item.code);
        return getRange(1, (allValues.length ? Math.max(...allValues) : 0))
            .filter(code => currentValue === code || !allValues.includes(code))
            .map((code) => ({
                label: code?.toString(),
                value: code,
            }));
    };

    return View({
        ...props,
        data,
        form,
        columns,
        formData,
        formArray,
        onClickAddRow,
        onClickDeleteRow,
        codeSuggestions,
    });
};
