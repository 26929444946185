import { push } from 'connected-react-router';
import React, { useMemo, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SpecialDayCreator } from '../../../../common/model/meter/specialDay/specialDayCreator';
import { TariffDayCreator } from '../../../../common/model/meter/tariffDay/tariffDayCreator';
import { TariffScheduleCreator } from '../../../../common/model/meter/tariffSchedule/tariffScheduleCreator';
import { TariffScheduleVersionCreator } from '../../../../common/model/meter/tariffScheduleVersion/tariffScheduleVersionCreator';
import { TariffScheduleVersionStatus } from '../../../../common/model/meter/tariffScheduleVersionStatus';
import { TariffSeasonCreator } from '../../../../common/model/meter/tariffSeason/tariffSeasonCreator';
import { TariffZoneCreator } from '../../../../common/model/meter/tariffZone/tariffZoneCreator';
import { newGuid } from '../../../../common/shared/utils';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import { modalNotificationsPop, modalNotificationsPush } from '../../../../redux/actions/controls/modalNotifications';
import {
    getTariffScheduleVersion,
    createTariffScheduleVersion,
    updateTariffScheduleVersion,
    getEditableTariffScheduleById,
    duplicateTariffScheduleWithVersion,
} from '../../../../redux/actions/tariffSchedule';
import { ModalNotificationVariant } from '../../../../redux/reducers/controls/modalNotifications';
import { resourcesSpecialDayBasesSelector } from '../../../../redux/selectors';
import { AdministrationTabs, Routes } from '../../../shared/constants';
import { useBreadcrumbs } from '../../../shared/hooks/useBreadcrumbs';
import { useHeading } from '../../../shared/hooks/useHeading';
import { useWebDictionarySelectSuggestions } from '../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../shared/pipes';
import { getCurrentDate } from '../../../shared/utils/dates';
import { getErrorMessage } from '../../../shared/utils/error';
import { TariffScheduleVersionCommonProps } from './common';
import { TariffDayNamesFormValues } from './components/tariffDayNamesForm/model';
import { prepareTariffScheduleVersionCreator } from './helpers';
import { useTariffScheduleName } from './hooks/useTariffScheduleName';
import { TariffScheduleVersionProps } from './model';
import { View } from './view';

const getNewTariffDayCreator = (tariffScheduleVersionId: string): TariffDayCreator => {
    return {
        id: newGuid(),
        tariffScheduleVersionId,
        tariffDayNameId: null,
    };
};

const getNewTariffSeasonMock = (tariffScheduleVersionId: string): TariffSeasonCreator => {
    return {
        id: newGuid(),
        tariffScheduleVersionId,
        startMonth: 1,
        startDay: 1,
        workingTariffDayId: null,
        weekendTariffDayId: null,
    };
};

const getNewSpecialDayMock = (tariffScheduleVersionId: string): SpecialDayCreator => {
    return {
        id: newGuid(),
        tariffScheduleVersionId,
        date: null,
        name: null,
        tariffDayId: null,
    };
};

const getNewTariffZoneMock = (tariffDayId: string, tariffScheduleVersionId: string): TariffZoneCreator => {
    return {
        id: newGuid(),
        startTime: 0,
        tariffNumber: null,
        tariffDayId,
        tariffScheduleVersionId,
    };
};

export const TariffScheduleVersion: React.FC<TariffScheduleVersionProps> = (props) => {

    const {
        tariffSchedule,
        tariffScheduleVersion,
    } = props;

    const tariffScheduleId = tariffSchedule.id;

    const isNewRecord = !tariffScheduleVersion;

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch<BaseDispatch>();

    const [isTariffDayNamesOpen, setIsTariffDayNamesOpen] = useState(false);
    const [isDuplicateWindowOpen, setIsDuplicateWindowOpen] = useState(false);

    const { regions: regionsSuggestions } = useWebDictionarySelectSuggestions();

    const tariffScheduleName = useTariffScheduleName({
        regions: regionsSuggestions,
        tariffSchedule,
        tariffScheduleVersion,
    });

    const specialDayBases = useSelector(resourcesSpecialDayBasesSelector());

    const tariffScheduleVersionId = useMemo(() => tariffScheduleVersion?.id ?? newGuid(), [tariffScheduleVersion]);

    const defaultValues: TariffScheduleVersionCreator = {
        id: tariffScheduleVersionId,
        tariffScheduleId: tariffSchedule.id,
        startDate: getCurrentDate(),
        status: TariffScheduleVersionStatus.Draft,
        tariffDayNames: [],
        tariffDays: [],
        tariffZones: [],
        tariffSeasons: [],
        specialDays: [],
    };

    const form = useForm<TariffScheduleVersionCreator>({
        defaultValues: tariffScheduleVersion ?? defaultValues,
    });

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.Schemes }) },
        { name: 'Тарифное расписание', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.TariffSchedules }) },
        { name: tariffScheduleName, link: null },
    ]);

    const headingProps = useHeading(tariffScheduleName, () => {
        dispatch(push(getRoute(Routes.administration, null, { tab: AdministrationTabs.TariffSchedules })));
    });

    const formData = form.watch();

    const commonProps: TariffScheduleVersionCommonProps = {
        isEditMode: formData.status === TariffScheduleVersionStatus.Draft,
        isNewRecord,
    };

    const onCancelEditTariffDayNames = () => {
        setIsTariffDayNamesOpen(false);
    };

    const onClickEditTariffDayNames = () => {
        setIsTariffDayNamesOpen(true);
    };

    const onSubmitTariffDayNames: SubmitHandler<TariffDayNamesFormValues> = (fData) => {
        const { tariffDayNames } = fData;
        form.setValue('tariffDayNames', tariffDayNames);
        onCancelEditTariffDayNames();
    };

    const tariffDayNamesFormArray = useFieldArray({
        control: form.control,
        name: 'tariffDayNames',
    });

    const tariffDaysFormArray = useFieldArray({
        control: form.control,
        name: 'tariffDays',
    });

    const tariffZonesFormArray = useFieldArray({
        control: form.control,
        name: 'tariffZones',
    });

    const tariffSeasonsFormArray = useFieldArray({
        control: form.control, //
        name: 'tariffSeasons',
    });

    const specialDaysFormArray = useFieldArray({
        control: form.control,
        name: 'specialDays',
    });

    const onClickAddTariffDay = () => {
        tariffDaysFormArray.append(getNewTariffDayCreator(tariffScheduleVersionId));
    };

    const onClickAddTariffSeason = () => {
        tariffSeasonsFormArray.append(getNewTariffSeasonMock(tariffScheduleVersionId));
    };
    const onClickAddSpecialDay = () => {
        specialDaysFormArray.append(getNewSpecialDayMock(tariffScheduleVersionId));
    };

    const onClickDeleteTariffDay = (index: number) => {
        const tariffDay = formData.tariffDays[index];
        if (tariffDay) {
            form.setValue('tariffZones', formData.tariffZones.filter(item => item.tariffDayId !== tariffDay.id));
        }
        tariffDaysFormArray.remove(index);
    };

    const onClickDeleteTariffZone = (index: number) => {
        tariffZonesFormArray.remove(index);
    };

    const onClickDeleteTariffSeason = (index: number) => {
        tariffSeasonsFormArray.remove(index);
    };

    const onClickDeleteSpecialDay = (index: number) => {
        specialDaysFormArray.remove(index);
    };

    const onClickAddZoneToDay = (day: TariffDayCreator) => {
        tariffZonesFormArray.append((
            getNewTariffZoneMock(day.id, day.tariffScheduleVersionId)
        ));
    };

    const fetch = async () => {
        await dispatch(getEditableTariffScheduleById(tariffScheduleId));
        if (tariffScheduleVersionId) {
            await dispatch(getTariffScheduleVersion(tariffScheduleId, tariffScheduleVersionId));
        }
    };

    const create = async (payload: TariffScheduleVersionCreator) => {
        const createdTariffVersion = await createTariffScheduleVersion(
            tariffScheduleId,
            prepareTariffScheduleVersionCreator(payload)
        );
        dispatch(push(
            getRoute(
                Routes.tariffScheduleVersionEdit,
                { tariffScheduleId, tariffScheduleVersionId: createdTariffVersion.id }
            )
        ));
    };

    const update = async (payload: TariffScheduleVersionCreator) => {
        const updatedTariffVersion = await updateTariffScheduleVersion(
            tariffScheduleId,
            tariffScheduleVersionId,
            prepareTariffScheduleVersionCreator(payload)
        );
        dispatch(push(
            getRoute(
                Routes.tariffScheduleVersionEdit,
                { tariffScheduleId, tariffScheduleVersionId: updatedTariffVersion.id }
            )
        ));
        form.reset({ ...updatedTariffVersion });
        await fetch();
    };

    const onClickSaveAsDraft = async () => {
        setIsLoading(true);
        if (isNewRecord) {
            try {
                await create(formData);
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
        } else {
            try {
                await update(formData);
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
        }
        setIsLoading(false);
    };

    const onClickApply = () => {
        setIsLoading(true);
        if (!isNewRecord) {
            dispatch(modalNotificationsPush({
                showHide: true,
                variant: ModalNotificationVariant.Alert,
                title: 'Применение тарифного расписания',
                message: 'Тарифное расписание будет сохранено и применено к приборам учёта. Вы уверены?',
                actions: [
                    {
                        children: 'Применить',
                        variant: 'primary',
                        onClick: async () => {
                            try {
                                await update({
                                    ...formData,
                                    status: TariffScheduleVersionStatus.Ready,
                                });
                                dispatch(push(Routes.tariffScheduleOrders));
                            } catch (e) {
                                dispatch(createSnackbar({
                                    type: 'red',
                                    message: getErrorMessage(e),
                                }));
                            }
                            await dispatch(modalNotificationsPop());
                        },
                        onEnter: true,
                    },
                    {
                        children: 'Отмена',
                        variant: 'secondary',
                        onClick: async () => {
                            await dispatch(modalNotificationsPop());
                        },
                        onEscape: true,
                    }
                ],
            }));
        }
        setIsLoading(false);
    };

    const onClickDuplicate = () => {
        setIsDuplicateWindowOpen(true);
    };

    const onCancelDuplicate = () => {
        setIsDuplicateWindowOpen(false);
    };

    const onSubmitDuplicate: SubmitHandler<TariffScheduleCreator> = async (duplicateTariffSchedule) => {
        try {
            const {
                createdTariffSchedule,
                createdTariffScheduleVersion,
            } = await duplicateTariffScheduleWithVersion(duplicateTariffSchedule, tariffScheduleVersion);
            setIsDuplicateWindowOpen(false);
            dispatch(push(
                getRoute(
                    Routes.tariffScheduleVersionEdit,
                    {
                        tariffScheduleId: createdTariffSchedule.id,
                        tariffScheduleVersionId: createdTariffScheduleVersion.id,
                    }
                )
            ));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
    };

    const onClickFillSpecialDaysBase = () => {
        const now = getCurrentDate();
        const specialDays = specialDayBases.map((base) => ({
            id: newGuid(),
            tariffScheduleVersionId,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            date: `${now.getFullYear()}-${('0' + base.month).slice(-2)}-${('0' + base.day).slice(-2)}`,
            name: base.name,
            tariffDayId: null,
        }));
        form.setValue('specialDays', specialDays);
    };

    const onClickClearSpecialDays = () => {
        form.setValue('specialDays', []);
    };


    return View({
        ...props,
        ...commonProps,
        ...headingProps,
        ...breadcrumbProps,
        form,
        formData,
        onClickAddTariffDay,
        tariffDaysFormArray,
        tariffZonesFormArray,
        specialDaysFormArray,
        isTariffDayNamesOpen,
        isDuplicateWindowOpen,
        tariffSeasonsFormArray,
        tariffDayNamesFormArray,
        onSubmitTariffDayNames,
        onClickEditTariffDayNames,
        onCancelEditTariffDayNames,
        onClickAddTariffSeason,
        onClickAddSpecialDay,
        onClickDeleteTariffDay,
        onClickDeleteTariffZone,
        onClickDeleteTariffSeason,
        onClickDeleteSpecialDay,
        onClickAddZoneToDay,
        regionsSuggestions,
        onClickSaveAsDraft,
        onClickApply,
        onClickDuplicate,
        onCancelDuplicate,
        onSubmitDuplicate,
        onClickFillSpecialDaysBase,
        onClickClearSpecialDays,
        isLoading,
    });
};
