import { Box, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { MeterActionType } from '../../../common/model/meter/meterActionType';
import { Button } from '../../shared/components/button';
import { DrawerPanel } from '../../shared/components/drawerPanel';
import { Forbidden } from '../../shared/components/forbidden';
import { Heading } from '../../shared/components/heading';
import { Pagination } from '../../shared/components/pagination';
import { setOrderTransformer } from '../../shared/components/pagination/helpers';
import { Table } from '../../shared/components/table';
import { VisualizationSource } from '../../shared/components/table/model';
import { Wrapper } from '../../shared/components/wrapper';
import { defaults, Tables } from '../../shared/constants';
import { setColumnWidth } from '../../shared/pipes/styles';
import { InspectionLog } from '../inspectionLog';
import { InspectionLogPanel } from '../inspectionLog/components/inspectionLogPanel';
import { EventLogFilterForm } from './filterForm';
import { StateProps } from './model';

const useStyles = makeStyles(() => ({
    hidden: {
        display: 'none'
    },
    eventLogWrapper: {
        display: 'flex',
        '& .custom-table.white-table .MuiTableCell-head ': {
            fontWeight: 'normal',
            fontSize: '13px',
            lineHeight: '18px',
            color: '#5C6C7F',
        },
    },
    tableCols: {
        '&.col-date': {
            ...setColumnWidth('160px'),
        },
        '&.col-local-time-shift': {
            ...setColumnWidth('84px'),
        },
        '&.col-log-type': {
            ...setColumnWidth('160px'),
        },
        '&.col-is-critical': {
            ...setColumnWidth('40px'),
        },
        '&.col-event-type-id': {
            ...setColumnWidth('560px'),
        },
    }
}));

export const View = ({
    columnDefinition,
    loading,
    isFilterVisible,
    onFiltersOpen,
    onFiltersClose,
    onFilterSubmit,
    data,
    canReadEventLogs,
    canDownloadEventLogsToExcel,
    onUnloadToExcel,
    filterValues,
    dataTotal,
    setOrder,
    pagination,
    setPage,
    orderParams,
    initialFilterValues,
    meterId,
}: StateProps) => {

    const classes = useStyles();

    if (!canReadEventLogs && !loading) {
        return <Forbidden />;
    }

    const tableActions = <Box display='flex'>
        <Button
            variant='primary'
            icon='filter'
            className={classNames('m-r-16', { [classes.hidden]: isFilterVisible })}
            onClick={onFiltersOpen}
        >Фильтрация</Button>
        <Button variant='outline' icon='excel' onClick={onUnloadToExcel} disabled={!canDownloadEventLogsToExcel}>Выгрузить в Excel</Button>
    </Box>;

    const drawer = <DrawerPanel
        open={isFilterVisible}
        handleDrawerClose={onFiltersClose}
        title='Фильтрация'
        anchor='left'
    >
        <EventLogFilterForm initialFilterValues={initialFilterValues} filterValues={filterValues} onSubmit={onFilterSubmit} />
    </DrawerPanel>;

    return (
        <>
            <Box mb={2}>
                <Heading heading='Журнал событий' />
            </Box>
            <Wrapper actions={tableActions} drawer={drawer} className={classes.eventLogWrapper} isBigHeader>
                <Table
                    visualizationSource={VisualizationSource.clientProfile}
                    name={Tables.commonEventLogTable}
                    columns={columnDefinition}
                    data={data || []}
                    title={null}
                    variant='white'
                    options={{
                        headerClassName: classes.tableCols,
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        search: false,
                        toolbar: false,
                        sorting: true,
                        showTitle: false,
                        paging: 'disabled',
                    }}
                    fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                    isLoading={loading}
                    onOrderChange={setOrderTransformer(columnDefinition, setOrder)}
                    {...orderParams}
                />
                <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={loading || !data} />
            </Wrapper>
            {meterId && (
                <InspectionLogPanel>
                    <InspectionLog
                        meterId={meterId}
                        meterActionType={[
                            MeterActionType.TamperingEventsCollection,
                            MeterActionType.PowerSwitchEventsCollection,
                            MeterActionType.AccessControlEventsCollection,
                            MeterActionType.DataCorrectionEventsCollection,
                            MeterActionType.VoltageEventsCollection,
                            MeterActionType.CurrentEventsCollection,
                            MeterActionType.SelfDiagnosticEventsCollection,
                            MeterActionType.ElectricPowerQualityEventsCollection,
                        ]}
                    />
                </InspectionLogPanel>
            )}
        </>
    );
};
