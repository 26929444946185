import { CommonDictionaries, dictionariesSlice } from './dictionaries';
import { combineReducers } from 'redux';

export interface Dictionaries {
    list: CommonDictionaries;
}

export const dictionaries = combineReducers<Dictionaries>({
    list: dictionariesSlice.reducer,
});
