import React from 'react';
import { OwnProps } from './model';
import { withStyles } from '@material-ui/core/styles';
import { createStyles, Tab, Theme } from '@material-ui/core';


export const View = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'uppercase',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            minHeight: '44px',
            opacity: 1,
            '&:hover': {
                fontWeight: theme.typography.fontWeightBold,
            }
        },
        selected: {
            fontWeight: theme.typography.fontWeightBold,
        }
    }),
)((props: OwnProps) => <Tab disableRipple {...props} />);
