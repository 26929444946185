import {
    CrashByRegionsReportQueryParams,
    CrashByRegionsReportResponse
} from '../../../../../../common/model/meter/reports/crashByRegionsReport';
import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import { crashByRegionsReportSlice } from '../../../reducers/reports/crashByRegionsReport';
import { AppThunk } from '../../index';

export const getCrashByRegionsReport = (params: CrashByRegionsReportQueryParams): AppThunk => async (dispatch) => {
    const { daysOffline } = params;
    const route = getApiRoute(appUrls.api_reports_crash_by_regions, {}, { daysOffline });
    const data = await httpService.get<CrashByRegionsReportResponse>(route);
    dispatch(crashByRegionsReportSlice.actions.setReport(data));
};

export const resetCrashByRegionsReport = (): AppThunk => (dispatch) => {
    dispatch(crashByRegionsReportSlice.actions.setReport(null));
};
