import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MeterPowerSwitchOrdersParams } from '../../../common/model/meter/meterPowerSwitch/meterPowerSwitchRequestView';
import { MeterPowerSwitchOrderShortViewModel } from '../../../common/model/meter/meterPowerSwitchOrder/MeterPowerSwitchOrderCommonModel';
import { MeterPowerSwitchOrderViewModel } from '../../../common/model/meter/meterPowerSwitchOrderViewModel';
import { meterPowerSwitchOrderDetailsSlice } from '../../reducers/meterPowerSwitchOrders/details';
import { meterPowerSwitchOrdersListSlice } from '../../reducers/meterPowerSwitchOrders/list';
import { AppThunk } from '../index';
import { MeterPowerSwitchRequestShortViewModel } from '../../../../../common/model/meter/meterPowerSwitchOrder/MeterPowerSwitchOrderCommonModel';
import { newNotificationMessage } from '../httpWrapperActions';


export const getMeterPowerSwitchRequests = (
    filter: MeterPowerSwitchOrdersParams
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_power_switch_requests, {}, { ...filter });
    const data = await httpService.get<{requests: MeterPowerSwitchRequestShortViewModel[]; totalCount: number}>(route);
    dispatch(meterPowerSwitchOrdersListSlice.actions.setAvailableMeterPowerSwitchRequests(data));
};

export const getMeterPowerSwitchOrders = (
    filter: MeterPowerSwitchOrdersParams,
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_power_switch_orders, {}, { ...filter });
    const data = await httpService.get<MeterPowerSwitchOrderShortViewModel[]>(route);
    dispatch(meterPowerSwitchOrdersListSlice.actions.setAvailableMeterPowerSwitchOrders({
        requestId: filter.requestId,
        orders: data
    }));
};

export const getMeterPowerSwitchOrder = (
    id: string
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_power_switch_order, { id });
    const order = await httpService.get<MeterPowerSwitchOrderViewModel>(route);
    dispatch(meterPowerSwitchOrderDetailsSlice.actions.setMeterPowerSwitchOrderDetails(order));
};

export const approveMeterPowerSwitchOrder = (ids: string[]): AppThunk => async (dispatch) => {
    const result = await Promise.all(ids.map(async (id) => {
        try {
            const route = getApiRoute(appUrls.api_put_meter_power_switch_order_approval, { id });
            const order = await httpService.put<MeterPowerSwitchOrderShortViewModel>(route);
            dispatch(meterPowerSwitchOrdersListSlice.actions.updateAvailableMeterPowerSwitchOrder(order));
            return id;
        } catch (e) {
            return null;
        }
    }));
    const successResult = result.filter(item => !!item).length;
    const errorResult = result.filter(item => !item).length;
    dispatch(newNotificationMessage(
        {
            message: `Количество подтвержденных заявок: ${successResult}. ` +
                `Количество заявок при подтверждении которых возникли ошибки: ${errorResult}`,
            messageType: 'info',
            title: 'Заявки подтверждены',
        }
    ));
};
