import React from 'react';
import { AutoselectDictionaryInputViewProps } from './model';
import { Search } from '../search';
import { Box } from '@material-ui/core';

export const View = (props: AutoselectDictionaryInputViewProps) => {
    return (
        <Box
            onFocus={() => {
                if (props.items.length > 0) {
                    props.setShowHints(true);
                }
            }}
        >
            <Search {...props} />
        </Box>
    );
};
