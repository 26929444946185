import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../redux/actions';
import { activeUserSelector } from '../../../redux/selectors';
import { Button } from '../../shared/components/button';
import { Typography } from '../../shared/components/typography';
import { Routes } from '../../shared/constants';
import logo from '../../../assets/logo.svg';

const useStyles = makeStyles({
    authErrorContainer: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    logo: {
        height: '300px'
    }
});

export const View = () => {

    const classes = useStyles();

    const currentUser = useSelector(activeUserSelector());
    const dispatch = useDispatch<BaseDispatch>();

    useEffect(() => {
        if (currentUser) {
            dispatch(push(Routes.empty));
        }
    }, [currentUser]);

    const handleToLogin = () => {
        dispatch(push(Routes.login));
    };

    return (
        <div className={classes.authErrorContainer}>
            <Box marginBottom={4}>
                <img src={logo} alt='logo' className={classes.logo} />
            </Box>
            <Box marginBottom={4} textAlign='center'>
                <Typography variant='h1'>Ошибка авторизации</Typography>
                <Typography variant='body'>Пользователь не найден в системе.</Typography>
                <Typography variant='body'>Попробуйте снова или обратитесь к админстратору.</Typography>
            </Box>
            <Button variant='primary' onClick={handleToLogin}>На страницу входа</Button>
        </div>
    );
};
