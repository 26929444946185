import React, { useMemo } from 'react';
import { angleDegToRad, angleRadToCos } from '../../../../../../common/shared/utils/math';
import { drawLineArrow, useD3 } from '../../../../../shared/hooks/useD3';
import { Props } from './model';
const size = 300;

export const View: React.FC<Props> = ({ angle }) => {

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const angleRad = useMemo(() => angleDegToRad(angle), [angle]);

    const cos = useMemo(() => angleRadToCos(angleRad), [angleRad]);

    const ref = useD3((selection, theme) => {
        /* eslint-disable @typescript-eslint/no-magic-numbers */

        selection
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', size)
            .attr('height', size)
            .attr('fill', '#E5E5E5');

        const linesPadding = 16;

        const circleRadius = (size / 2) - 33;
        const circleCX = size / 2;
        const circleCY = size / 2;

        selection
            .append('circle')
            .attr('fill', 'transparent')
            .attr('stroke', theme.palette.neutral.light)
            .attr('stroke-width', 2)
            .attr('r', circleRadius)
            .attr('cx', circleCX)
            .attr('cy', circleCY);

        drawLineArrow({
            id: 'arrow1',
            selection,
            arrowSize: 10,
            color: theme.palette.neutral.light,
            coordinates: [
                [size / 2, size - linesPadding],
                [size / 2, linesPadding],
            ],
        });

        drawLineArrow({
            id: 'arrow2',
            selection,
            arrowSize: 10,
            color: theme.palette.neutral.light,
            coordinates: [
                [linesPadding, size / 2],
                [size - linesPadding, size / 2],
            ],
        });

        const drawAxisText = (text: string, x: number, y: number) => {
            selection
                .append('text')
                .attr('font-size', 14)
                .attr('font-weight', 600)
                .attr('fill', theme.palette.text.primary)
                .attr('dominant-baseline', 'middle')
                .attr('text-anchor', 'middle')
                .attr('x', x)
                .attr('y', y)
                .text(text);
        };

        const drawQuaterText = (text: string, x: number, y: number) => {
            selection
                .append('text')
                .attr('font-size', 16)
                .attr('font-weight', 300)
                .attr('font-family', 'Merriweather')
                .attr('fill', theme.palette.neutral.light)
                .attr('dominant-baseline', 'middle')
                .attr('text-anchor', 'middle')
                .attr('x', x)
                .attr('y', y)
                .text(text);
        };

        drawAxisText('Q', (size / 2) + 18, 18);
        drawAxisText('P', size - 18, (size / 2) - 18);

        drawAxisText('+', (size / 2) + 10, 45);
        drawAxisText('+', (size / 2) - 10, 45);

        drawAxisText('+', size - 45, (size / 2) + 10);
        drawAxisText('+', size - 45, (size / 2) - 10);

        drawAxisText('-', (size / 2) + 10, size - 45);
        drawAxisText('-', (size / 2) - 10, size - 45);

        drawAxisText('-', 45, (size / 2) + 10);
        drawAxisText('-', 45, (size / 2) - 10);

        drawQuaterText('I', (size / 2) + 30, (size / 2) - 30);
        drawQuaterText('II', (size / 2) - 30, (size / 2) - 30);
        drawQuaterText('III', (size / 2) - 30, (size / 2) + 30);
        drawQuaterText('IV', (size / 2) + 30, (size / 2) + 30);

        const hypo = circleRadius - 6;

        const X = hypo * cos;
        const Y = hypo * Math.sin(angleRad);

        const lineX = (size / 2) + X;
        const lineY = (size / 2) - Y;

        drawLineArrow({
            id: 'arrow3',
            selection,
            arrowSize: 10,
            color: theme.palette.primary.main,
            coordinates: [
                [size / 2, size / 2],
                [lineX, lineY],
            ],
        });

        const legendWidth = 80;
        const legendHeight = 28;

        const legendaG = selection
            .append('g')
            .attr('transform', `translate(${size - legendWidth}, ${size - legendHeight})`);

        legendaG
            .append('rect')
            .attr('width', legendWidth)
            .attr('height', legendHeight)
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('fill', theme.palette.background.paper);

        legendaG
            .append('text')
            .attr('font-size', 14)
            .attr('font-weight', 600)
            .attr('fill', theme.palette.primary.main)
            .attr('dominant-baseline', 'middle')
            .attr('text-anchor', 'middle')
            .attr('x', legendWidth / 2)
            .attr('y', legendHeight / 2)
            .text(`${angle}°`);

    }, [cos, angleRad, angle]);

    return <>
        <svg
            ref={ref}
            style={{
                height: size,
                width: size,
                marginRight: '0px',
                marginLeft: '0px',
            }}
        />
    </>;
};
