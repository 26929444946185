import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import './style.scss';

import { IStateProps } from './model';

export const View = (props: IStateProps) => {

    const { children } = props;

    return (
        <div className='top-bar'>
            <AppBar className='app-bar' elevation={0} >
                <Toolbar>
                    { children }
                </Toolbar>
            </AppBar>
        </div>
    );
};
