import { meterSchemesListSlice, MeterSchemesListState } from './list';
import { combineReducers } from 'redux';


export interface MeterSchemesState {
    list: MeterSchemesListState;
}

export const meterSchemes = combineReducers<MeterSchemesState>({
    list: meterSchemesListSlice.reducer,
});
