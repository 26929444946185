import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { IconComponent, IconProps } from './model';

export const View: React.FC<IconProps> = (props): JSX.Element => {
    const {
        name,
        className,
        fillColor,
        strokeColor,
        width,
        height,
        ...anotherProps
    } = props;
    return (
        <SvgIcon
            component={IconComponent[name]}
            style={{
                fill: fillColor,
                stroke: strokeColor,
                width,
                height,
            }}
            className={className}
            { ...anotherProps }
        />
    );
};
