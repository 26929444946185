import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Icon } from '../../../icon';
import { useStyles } from '../styles';
import { BlockItemProps } from '../model';
import { Typography } from 'app/shared/components/typography';

export const View: React.FC<BlockItemProps> = ({
    item,
    type,
    className,
    count,
}) => {
    const classes = useStyles({ count });
    const withAction = item.onActionClick && item.actionIcon;

    return (
        <Box
            className={classNames(
                type === 'vertical' ? classes.verticalPosition : null,

                !item.isVisible
                    ? {
                        [classes.itemWrapperHorizontal]: type === 'horizontal',
                        [classes.itemWithAction]: withAction,
                    }
                    : {
                        [classes.itemWrapperHorizontal]: type === 'horizontal',
                        [classes.itemWithVisibleAction]: withAction,
                    },

                className
            )}
        >
            <Box mb={1} mr={1}>
                <Typography className={classes.title} variant="caption">
                    {item.title}
                </Typography>
            </Box>
            <Typography className={classes.item} variant="caption">
                {item.value}
            </Typography>
            {withAction && (
                <span
                    className={
                        item.disabled
                            ? !item.isVisible
                                ? classNames(classes.itemAction)
                                : classNames(classes.itemVisibleActionDisabled)
                            : !item.isVisible
                                ? classNames(classes.itemAction)
                                : classNames(classes.itemVisibleAction)
                    }
                    onClick={item.onActionClick}
                >
                    <div>
                        <Icon name={item.actionIcon} />
                    </div>
                </span>
            )}
        </Box>
    );
};
