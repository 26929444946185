import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DateFormats } from '../../../../../common/constants/date';
import { MassOperationCreator } from '../../../../../common/model/meter/massOperation/massOperationCreator';
import { MassOperationStatus } from '../../../../../common/model/meter/massOperation/massOperationStatus';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { modalNotificationsPop, modalNotificationsPush } from '../../../../../redux/actions/controls/modalNotifications';
import { checkMassOperation, setMassOperation, startMassOperation } from '../../../../../redux/actions/massOperations';
import { ModalNotificationVariant } from '../../../../../redux/reducers/controls/modalNotifications';
import { MonitoringTabs, Routes } from '../../../../shared/constants';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { useHeading } from '../../../../shared/hooks/useHeading';
import { getRoute } from '../../../../shared/pipes';
import { formatDate } from '../../../../shared/utils/dates';
import { getErrorMessage } from '../../../../shared/utils/error';
import { makeExcelReport } from './excel';
import { MassOperationProps } from './model';
import { View } from './view';

export const MassOperationDetails: React.FC<MassOperationProps> = (props) => {
    const {
        massOperation,
    } = props;

    const {
        id,
        isComplete,
    } = massOperation;

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch<BaseDispatch>();

    const pageTitle = `Массовый опрос ${formatDate(massOperation.beginProcessingDate, DateFormats.dateTimeZoneFormat)}`;

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Мониторинг', link: getRoute(Routes.monitoring) },
        { name: 'Массовый опрос', link: getRoute(Routes.monitoring, {}, { tab: MonitoringTabs.MassOperation }) },
        { name: pageTitle, link: getRoute(Routes.massOperation, { id: id?.toString() }) },
    ]);

    const headingProps = useHeading(
        pageTitle,
        () => {
            dispatch(
                push(
                    getRoute(
                        Routes.monitoring,
                        {},
                        { tab: MonitoringTabs.MassOperation, regionId: massOperation.regionId }
                    )
                )
            );
        });

    const onClickExcel = async () => {
        // DO NOTHING
        setIsLoading(true);
        try {
            await makeExcelReport({
                massOperation,
                title: pageTitle,
                filename: `MassReading_${new Date(massOperation.beginProcessingDate).getTime()}`,
            });
            dispatch(createSnackbar({
                type: 'green',
                message: 'Отчет успешно создан.',
            }));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    const onClickRepeat = async () => {
        await dispatch(modalNotificationsPush({
            showHide: true,
            variant: ModalNotificationVariant.Alert,
            title: 'Повтор опроса',
            message: 'Вы уверены что хотите начать новый опрос?',
            actions: [
                {
                    children: 'Начать опрос',
                    variant: 'primary',
                    onClick: async () => {
                        try {
                            setIsLoading(true);
                            const creator: MassOperationCreator = {
                                regionId: massOperation.regionId,
                                meterGroupId: massOperation.meterGroupId,
                                localBeginReadingDate: massOperation.localBeginReadingDate,
                            };
                            await checkMassOperation(creator);
                            const createdMassOperation = await startMassOperation(creator);
                            dispatch(setMassOperation(createdMassOperation));
                            dispatch(push(getRoute(Routes.massOperation, { id: createdMassOperation.id?.toString() })));
                        } catch (e) {
                            dispatch(createSnackbar({
                                type: 'red',
                                message: getErrorMessage(e),
                            }));
                        } finally {
                            setIsLoading(false);
                        }
                        await dispatch(modalNotificationsPop());
                    },
                    onEnter: true,
                },
                {
                    children: 'Отмена',
                    variant: 'secondary',
                    onClick: async () => {
                        await dispatch(modalNotificationsPop());
                    },
                    onEscape: true,
                }
            ],
        }));
    };

    return View({
        ...props,
        ...breadcrumbProps,
        ...headingProps,
        isComplete,
        onClickExcel,
        onClickRepeat,
        isLoading: isLoading || props.isLoading,
        isGraphicsVisible: [
            MassOperationStatus.Started,
            MassOperationStatus.Finished,
        ].includes(massOperation.status),
    });
};
