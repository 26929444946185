import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoleViewModel } from '../../../../../common/model/access/role/roleViewModel';
import { ActionType } from '../../../../actions';

export interface RolesListAccessState {
    list: RoleViewModel[];
}

const initialState: RolesListAccessState = {
    list: null,
};

export const rolesListAccessSlice = createSlice({
    name: '@@vostok/access/roles/list',
    initialState,
    reducers: {
        setRolesList(state, action: PayloadAction<RoleViewModel[]>): void {
            state.list = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): RolesListAccessState => initialState,
    }
});
