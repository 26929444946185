import { useWebDictionary } from '../../../shared/hooks/useWebDictionary';
import { View } from './view';
import {
    accountTypes,
    connectionTypes,
    legalTypes,
    meterRelayStatuses,
    meterStatuses,
    priceCategories,
    purposeTypes
} from '../../../shared/constants/enums';
import { SearchFormOwnProps } from './model';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SearchAddressForm } from '../formAddress/model';
import { useDispatch, useSelector } from 'react-redux';
import { StorageState } from '../../../../redux/reducers';
import { getMeterLabelTypes } from '../../../../redux/actions/meterLabelType';
import { meterLabelTypeSuggestionsDictionary } from '../../../../common/model/meter/meterLabelType/meterLabelTypeSuggestions';
import { MultiSelectSuggestion } from '../../../shared/components/multiSelect/model';

const addressFormDefault: SearchAddressForm = {
    regionId: null,
    areaName: '',
    townName: '',
    streetName: '',
    houseNumber: '',
    addressSpecifier: '',
};

export const SearchForm = (props: SearchFormOwnProps) => {
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isShowAll, setShowAll] = useState(false);

    const { regions } = useWebDictionary();

    const meterLabelTypes = useSelector((state: StorageState) => state.meterLabelType.list.available);
    useEffect(() => {
        if (!meterLabelTypes.length) {
            dispatch(getMeterLabelTypes());
        }
    }, []);

    const meterLabelTypeSuggestions: MultiSelectSuggestion[] = useMemo(() => {
        if (!meterLabelTypes.length) {
            return [];
        }

        return meterLabelTypeSuggestionsDictionary.reduce<MultiSelectSuggestion[]>((acc, suggestion) => {
            const meterLabelType = meterLabelTypes.find(item => item.name === suggestion);
            if (meterLabelType) {
                acc.push({ name: meterLabelType.description, value: meterLabelType.id });
            }
            return acc;
        }, []);
    }, [meterLabelTypes]);

    const addressFilterDefaults = {
        ...addressFormDefault,
        ...props.addressFilterDefaults
    };
    const cityForm = useForm<SearchAddressForm>({ defaultValues: addressFilterDefaults });
    const { form } = props;
    return View({
        ...props,
        onSubmit: (data) => {
            const relayStatus: any = data?.relayStatus;
            props.onSubmit({
                ...data,
                relayStatus: relayStatus === -1 ? 0 : relayStatus,
            });
        },
        onCancel: () => {
            cityForm.reset(addressFormDefault);
            props.onCancel();
        },
        onClickByAddress: () => {
            setIsOpenModal(!isOpenModal);
        },
        cityForm,
        isOpenModal,
        purposeTypes,
        legalTypes,
        priceCategories,
        meterStatuses,
        accountTypes,
        connectionTypes,
        meterRelayStatuses,
        meterLabelTypeSuggestions,
        onCloseForm: () => {
            setIsOpenModal(false);
        },
        onCancelCity: () => {
            cityForm.reset(addressFormDefault);
        },
        onSubmitCity: (data) => {
            const { regionId, addressSpecifier, houseNumber, streetName, townName, areaName } = data;
            const regionName = regions.find(region => region.id === regionId)?.name ?? '';
            const addressStr = `${regionName} ${Object.entries(data)
                .filter(([key]) => key !== 'regionId')
                .map(([, value]) => value)
                .filter(value => !!value)
                .join(', ')
                .trim()}`;
            form.setValue('addressString', addressStr ? addressStr : null, { shouldValidate: false, shouldDirty: false, shouldTouch: false });
            form.setValue('areaName', areaName);
            form.setValue('townName', townName);
            form.setValue('streetName', streetName);
            form.setValue('houseNumber', houseNumber);
            form.setValue('regionId', regionId);
            form.setValue('addressSpecifier', addressSpecifier);
            setIsOpenModal(false);
        },
        onClickShowAll: () => {
            setShowAll(!isShowAll);
        },
    });
};
