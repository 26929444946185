import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '../../../../../shared/components/button';
import { TextInput } from '../../../../../shared/components/textInput';
import { TwoSideMultiselect } from '../../../../../shared/components/twoSideMultiselect';
import { IStateProps } from './model';
import { Typography } from '../../../../../shared/components/typography';

const useStyles = makeStyles(() => ({
    form: {
        flex: 1
    },
    formContent: {
        height: '100%',
        overflow: 'hidden'
    },
    formRulesListsWrapper: {
        overflow: 'hidden',
        height: 'calc(100% - (3 * 56px))'
    },
    buttonSubmit: {
        display: 'flex',
        justifyContent: 'center'
    },
    titleAccess: {
        margin: '0 60px 10px',
        display: 'flex',
        justifyContent: 'space-between',
    }
}));

export const View = (props: IStateProps) => {
    const {
        form: { handleSubmit, control },
        onSubmit,
        role,
        accessRoleOptions,
    } = props;

    const classes = useStyles();

    return <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid container spacing={2} className={classes.formContent}>
            <Grid sm={12} item>
                <Controller
                    name='name'
                    control={control}
                    render={({ field }) => <TextInput
                        label='Название роли'
                        fullWidth={true}
                        {...field}
                    />} />
            </Grid>
            <Grid sm={12} item>
                <Controller
                    name='code'
                    control={control}
                    render={({ field }) => <TextInput
                        label='Код роли'
                        fullWidth={true}
                        {...field}
                    />} />
            </Grid>
            <Grid sm={12} item className={classes.formRulesListsWrapper}>
                <Grid sm={12} item justify="center" className={classes.titleAccess}>
                    <Typography variant="body">Запрещено:</Typography>
                    <Typography variant="body">Разрешено:</Typography>
                </Grid>
                <Controller
                    name='accessRules'
                    control={control}
                    render={({ field }) => <TwoSideMultiselect
                        options={accessRoleOptions}
                        value={field.value}
                        onChange={field.onChange} />} />
            </Grid>
            <Grid sm={12} item className={classes.buttonSubmit}>
                <Button variant='primary' type="submit">
                    {role ? 'Сохранить' : 'Добавить'}
                </Button>
            </Grid>
        </Grid>
    </form>;
};
