export enum ProgressColors {
    Red,
    Green,
}

export interface ProgressGroup {
    color: ProgressColors;
    value: number;
}

export interface MassOperationProgressGraphProps {
    title: string;
    total: number;
    isComplete: boolean;
    dataGroups: ProgressGroup[];
}
