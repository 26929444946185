import { makeStyles } from '@material-ui/core';
import React from 'react';
import { drawerClosedWidth } from '../../../../shared/components/leftMenu/view';

const useStyles = makeStyles(() => ({
    inspectionLogPanel: {
        zIndex: 100,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15)',
        position: 'fixed',
        bottom: '0px',
        left: drawerClosedWidth,
        right: '0px',
        background: '#E5E5E5'
    },
}));

export const InspectionLogPanel: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.inspectionLogPanel}>
            {children}
        </div>
    );
};
