import { useState } from 'react';
import { SurveyConditionQueryParams } from '../../../common/model/meter/monitoring/surveryCondition';
import { useSyncQueryParams } from '../../shared/hooks/useQueryParams';
import { useWebDictionarySelectSuggestions } from '../../shared/hooks/useWebDictionary';
import { View } from './view';
import { useTabs } from '../../shared/hooks/useTabs';
import { MonitoringTabs, Routes } from '../../shared/constants';
import { MonitoringProps } from './model';

export const Monitoring = () => {

    const {
        activeTab,
        setTab,
    } = useTabs<MonitoringTabs>({
        values: MonitoringTabs,
        defaultTab: MonitoringTabs.MassOperation,
        route: Routes.monitoring,
    });

    const [isAdvancedCrashReportVisible, setIsAdvancedCrashReportVisible] = useState(false);

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    // TODO: когда будет привязка пользователя к региону, заменить
    const firstRegion = regionSuggestions?.[0]?.value ?? null;

    const [{ regionId }, setQueryParams] = useSyncQueryParams<Pick<SurveyConditionQueryParams, 'regionId'>>({ regionId: firstRegion });

    const onChangeRegion = (id: number) => {
        setQueryParams({ regionId: id });
    };

    const props: MonitoringProps = {
        regionId,
        activeTab,
        onChangeRegion,
        regionSuggestions,
        isAdvancedCrashReportVisible,
        setIsAdvancedCrashReportVisible,
        tabs: [
            {
                key: MonitoringTabs.MassOperation,
                title: 'Массовый опрос',
                disabled: false,
            },
            {
                key: MonitoringTabs.MeterHealth,
                title: 'Здоровье счетчиков',
                disabled: false,
            },
            {
                key: MonitoringTabs.SurveyCondition,
                title: 'Состояние опроса',
                disabled: false,
            },
            {
                key: MonitoringTabs.UnlegalAccess,
                title: 'Несанкционированный доступ',
                disabled: false,
            },
            {
                key: MonitoringTabs.Users,
                title: 'Пользователи',
                disabled: true,
            },
            {
                key: MonitoringTabs.Consumption,
                title: 'Потребление',
                disabled: true,
            },
            {
                key: MonitoringTabs.SurveyStatistic,
                title: 'Статистика опроса',
                disabled: true,
            },
            {
                key: MonitoringTabs.EventLog,
                title: 'Журналы',
                disabled: true,
            },
        ],
        onChangeTab: (e, tab) => {
            setTab(tab);
        },
    };

    return View(props);
};
