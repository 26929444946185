/* eslint-disable @typescript-eslint/no-magic-numbers */
import { DateFormats } from '../../../../../common/constants/date';
import {
    ElectricityConsumptionReportData,
    ElectricityConsumptionReportQuery,
    ElectricityConsumptionReportResponse,
} from '../../../../../common/model/meter/reports/electricityConsumptionReport';
import { sleep } from '../../../../shared/pipes';
import { Alignment, Cell, Row, Workbook, Worksheet } from 'exceljs';
import { formatDate } from '../../../../shared/utils/dates';
import { downloadExcelData } from '../../../../shared/utils/download';
import {
    addBordersToCell,
    addRowSpaceToWorksheet,
    excelBaseFontName,
} from '../../excelHelpers';
import { transformElectricityConsumptionReportData } from './helper';

const columnWidths: Record<number, number> = {
    1: 23,
    2: 23,
    3: 25,
    4: 15,
    5: 15,
    6: 19,
    7: 19,
    8: 19,
    9: 17,
    10: 17,
    11: 17,
    12: 17,
    13: 17,
    14: 17,
    15: 17,
    16: 17,
    17: 17,
    18: 17,
    19: 17,
    20: 17,
};

const columnsDataAlignment: Record<number, Alignment['horizontal']> = {
    1: 'left',
    2: 'left',
    3: 'left',
    4: 'left',
    5: 'left',
    6: 'center',
    7: 'center',
    8: 'center',
    9: 'right',
    10: 'right',
    11: 'right',
    12: 'right',
    13: 'right',
    14: 'right',
    15: 'right',
    16: 'right',
    17: 'right',
    18: 'right',
    19: 'right',
    20: 'right',
};

export interface MakeExcelReportConfig {
  response: ElectricityConsumptionReportResponse;
  params: ElectricityConsumptionReportQuery;
  filename: string;
}

const makeColumns = (worksheet: Worksheet) => {
    for (const col in columnWidths) {
        worksheet.getColumn(+col).width = +columnWidths[col];
    }
};

const dataToTableCells = (data: ElectricityConsumptionReportData): string[] => {
    return [
        data.areaName ?? '',
        data.townName ?? '',
        data.streetName ?? '',
        data.houseNumber ?? '',
        data.addressSpecifier ?? '',
        data.meterSerialNum ?? '',
        data.code ?? '',
        data.tariff ?? '',
        data.cumulativeActiveEnergyImpStart?.toString() ?? '',
        data.cumulativeActiveEnergyImpEnd?.toString() ?? '',
        data.cumulativeActiveEnergyTZ1Start?.toString() ?? '',
        data.cumulativeActiveEnergyTZ1End?.toString() ?? '',
        data.cumulativeActiveEnergyTZ2Start?.toString() ?? '',
        data.cumulativeActiveEnergyTZ2End?.toString() ?? '',
        data.cumulativeActiveEnergyTZ3Start?.toString() ?? '',
        data.cumulativeActiveEnergyTZ3End?.toString() ?? '',
        data.cumulativeActiveEnergyTZ1Diff?.toString() ?? '',
        data.cumulativeActiveEnergyTZ2Diff?.toString() ?? '',
        data.cumulativeActiveEnergyTZ3Diff?.toString() ?? '',
        data.cumulativeActiveEnergyTzSum?.toString() ?? '',
    ];
};

const makeMainTitle = (worksheet: Worksheet, title: string) => {
    worksheet.addRow([title]);
    worksheet.getCell('A1').style.font = {
        size: 12,
        name: excelBaseFontName,
        bold: true,
    };
    worksheet.getCell('A1').style.alignment = {
        horizontal: 'center',
    };
    worksheet.mergeCells('A1:T1');
};

const makeAnnotationRow = (
    worksheet: Worksheet,
    title: string,
    renderValue: (row: Row, valueCell: Cell) => void
) => {
    const regionNameRow = addRowSpaceToWorksheet(worksheet);
    const regionLabelCellStart = regionNameRow.getCell(5);
    regionLabelCellStart.value = title;
    regionLabelCellStart.style.font = {
        bold: true,
        size: 11,
        name: excelBaseFontName,
    };
    regionLabelCellStart.style.alignment = {
        horizontal: 'right',
    };
    const regionLabelCellEnd = regionNameRow.getCell(8);
    worksheet.mergeCells(
        `${regionLabelCellStart.address}:${regionLabelCellEnd.address}`
    );

    const regionValueCellStart = regionNameRow.getCell(9);
    regionValueCellStart.style.font = {
        size: 11,
        name: excelBaseFontName,
    };
    renderValue(regionNameRow, regionValueCellStart);
};

const makeProfilePage = (
    workbook: Workbook,
    config: MakeExcelReportConfig
): void => {
    const { response, params } = config;
    const { dateFrom, dateTo } = params;
    const { data, ppObjectName, regionName, meterGroupName } = response;
    const worksheet = workbook.addWorksheet('Главная');
    makeColumns(worksheet);
    makeMainTitle(
        worksheet,
        'Показания  электроэнергии за расчетный период по тарифам'
    );

    makeAnnotationRow(worksheet, 'Наименование региона:', (row, cell) => {
        const regionValueCellEnd = row.getCell(13);
        worksheet.mergeCells(`${cell.address}:${regionValueCellEnd.address}`);
        cell.value = regionName;
    });

    makeAnnotationRow(worksheet, 'Наименование группы ПУ:', (row, cell) => {
        const regionValueCellEnd = row.getCell(13);
        worksheet.mergeCells(`${cell.address}:${regionValueCellEnd.address}`);
        cell.value = meterGroupName;
    });

    makeAnnotationRow(worksheet, 'Наименование объекта РР:', (row, cell) => {
        const regionValueCellEnd = row.getCell(10);
        worksheet.mergeCells(`${cell.address}:${regionValueCellEnd.address}`);
        cell.value = ppObjectName;
    });

    makeAnnotationRow(worksheet, 'Отчетный период (ОП):', (row) => {
        const dateTitleStartCell = row.getCell(9);
        const dateTitleEndCell = row.getCell(10);
        dateTitleStartCell.value = 'с';
        dateTitleEndCell.value = 'по';
        dateTitleStartCell.style.font = {
            name: excelBaseFontName,
            size: 11,
        };
        dateTitleEndCell.style.font = {
            name: excelBaseFontName,
            size: 11,
        };
    });

    const dateRangeRow = addRowSpaceToWorksheet(worksheet);
    const dateStartCell = dateRangeRow.getCell(9);
    const dateEndCell = dateRangeRow.getCell(10);
    dateStartCell.value = formatDate(dateFrom, DateFormats.dateFormat);
    dateStartCell.style.font = {
        color: {
            argb: '#ff4472c4',
        },
        name: excelBaseFontName,
        size: 11,
    };
    dateEndCell.value = formatDate(dateTo, DateFormats.dateFormat);
    dateEndCell.style.font = {
        color: {
            argb: '#ff4472c4',
        },
        name: excelBaseFontName,
        size: 11,
    };

    addBordersToCell(dateStartCell);
    addBordersToCell(dateEndCell);

    const excelMainTableTitles: {
    title: string;
    mergeRows: boolean;
    mergeNextColsCount: number;
    topTitle?: string;
  }[] = [
      {
          title: 'Район',
          mergeNextColsCount: 0,
          mergeRows: true,
      },
      {
          title: 'Наименование\nнаселенного пункта',
          mergeNextColsCount: 0,
          mergeRows: true,
      },
      {
          title: 'Название улицы',
          mergeNextColsCount: 0,
          mergeRows: true,
      },
      {
          title: '№, дома',
          mergeNextColsCount: 0,
          mergeRows: true,
      },
      {
          title: '№, квартиры',
          mergeNextColsCount: 0,
          mergeRows: true,
      },
      {
          title: '№, ПУ ',
          mergeNextColsCount: 0,
          mergeRows: true,
      },
      {
          title: 'Код АСКУЭРР',
          mergeNextColsCount: 0,
          mergeRows: true,
      },
      {
          title: 'Тип прибора учёта',
          mergeNextColsCount: 0,
          mergeRows: true,
      },
      {
          title: 'Тарифное расписание ПУ',
          mergeNextColsCount: 0,
          mergeRows: true,
      },
      {
          title: 'Нач.ОП',
          mergeNextColsCount: 1,
          mergeRows: false,
          topTitle: 'Показания счетчика сумма тарифов, кВт*ч',
      },
      {
          title: 'Кон.ОП',
          mergeNextColsCount: 0,
          mergeRows: false,
      },
      {
          title: 'Нач.ОП',
          mergeNextColsCount: 1,
          mergeRows: false,
          topTitle: 'Показания счетчика по первому тарифу (Т1), кВт*ч',
      },
      {
          title: 'Кон.ОП',
          mergeNextColsCount: 0,
          mergeRows: false,
      },
      {
          title: 'Нач.ОП',
          mergeNextColsCount: 1,
          mergeRows: false,
          topTitle: 'Показания счетчика по второму тарифу (Т2), кВт*ч',
      },
      {
          title: 'Кон.ОП',
          mergeNextColsCount: 0,
          mergeRows: false,
      },
      {
          title: 'Нач.ОП',
          mergeNextColsCount: 1,
          mergeRows: false,
          topTitle: 'Показания счетчика по третьему тарифу (Т3), кВт*ч',
      },
      {
          title: 'Кон.ОП',
          mergeNextColsCount: 0,
          mergeRows: false,
      },
      {
          title: 'T1',
          mergeNextColsCount: 3,
          mergeRows: false,
          topTitle: 'Потребление электроэнергии, кВт*ч',
      },
      {
          title: 'T2',
          mergeNextColsCount: 0,
          mergeRows: false,
      },
      {
          title: 'T3',
          mergeNextColsCount: 0,
          mergeRows: false,
      },
      {
          title: '∑ (Т1+Т2+T3)',
          mergeNextColsCount: 0,
          mergeRows: false,
      },
  ];

    const titlesRow = addRowSpaceToWorksheet(worksheet);
    const titlesRow2 = addRowSpaceToWorksheet(worksheet);
    for (let i = 1; i <= excelMainTableTitles.length; i++) {
        const titleData = excelMainTableTitles[i - 1];
        const cell1 = titlesRow.getCell(i);
        const cell2 = titlesRow2.getCell(i);
        if (titleData?.mergeNextColsCount > 0) {
            worksheet.mergeCells([
                +cell1.row,
                +cell1.col,
                +cell1.row,
                +cell1.col + titleData.mergeNextColsCount,
            ]);
        }

        if (titleData.mergeRows) {
            const mergingAddresses = `${cell1.address}:${cell2.address}`;
            worksheet.mergeCells(mergingAddresses);
        }
        if (titleData.topTitle) {
            cell1.value = titleData.topTitle;
            cell2.value = titleData.title;
        } else {
            cell2.value = titleData.title;
        }
        cell1.style.font = {
            bold: true,
            name: excelBaseFontName,
            size: 11,
        };
        cell1.style.alignment = {
            wrapText: true,
            horizontal: 'center',
        };
        cell2.style.font = {
            bold: true,
            name: excelBaseFontName,
            size: 11,
        };
        cell2.style.alignment = {
            wrapText: true,
            horizontal: 'center',
        };
        addBordersToCell(cell1);
        addBordersToCell(cell2);
    }

    const mainTableData: ElectricityConsumptionReportData[] = data.map((item) =>
        transformElectricityConsumptionReportData(item)
    );
    for (const mainTableDatum of mainTableData) {
        const row = worksheet.addRow(dataToTableCells(mainTableDatum));
        row.eachCell((cell, index) => {
            cell.style.font = {
                size: 8,
                name: excelBaseFontName,
            };
            cell.style.alignment = {
                horizontal: columnsDataAlignment[index] ?? 'left',
            };
            addBordersToCell(cell);
        });
    }
};

export const makeExcelReport = async (config: MakeExcelReportConfig) => {
    const workbook = new Workbook();
    makeProfilePage(workbook, config);
    await sleep(10);
    const buffer = ((await workbook.xlsx.writeBuffer()) as unknown) as Buffer;
    const excelData = buffer.toString('base64');

    downloadExcelData(excelData, config.filename);
};

/* eslint-enable @typescript-eslint/no-magic-numbers */
