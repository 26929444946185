import React, { ReactNode } from 'react';
import { MeterActionTaskShortViewModel } from '../../../../../common/model/meter/meterActionTaskShortViewModel';
import { MeterActionTaskStatus } from '../../../../../common/model/meter/meterActionTaskStatus';
import { PasswordTypes } from '../../../../../common/model/meter/meterPassword/passwordTypes';
import { Button } from '../../../../shared/components/button';
import { Link } from '../../../../shared/components/link';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Block } from '../../../../shared/components/wrapper/block';
import { BlockProps, BlockPropsItem } from '../../../../shared/components/wrapper/block/model';
import { Routes } from '../../../../shared/constants';
import { getRoute } from '../../../../shared/pipes';
import { spreadItemsByCondition } from '../../../../shared/pipes/spreadItemsByCondition';
import { formatDate } from '../../../../shared/utils/dates';
import { ViewProps } from './model';

const getStatusFromMeterActionTask = (task?: MeterActionTaskShortViewModel): ReactNode => {
    switch (task?.status) {
        case MeterActionTaskStatus.Queue:
        case MeterActionTaskStatus.Pending: {
            return <div>В работе</div>;
        }
        case MeterActionTaskStatus.Processed: {
            return <div>Выполнена</div>;
        }
        case MeterActionTaskStatus.NotProcessed: {
            return <div>Ошибка</div>;
        }
        default: {
            return null;
        }
    }
};

export const View: React.FC<ViewProps> = (props) => {
    const { meterId, latestTask } = props;

    const relayActions = (
        <Link to={{
            pathname: getRoute(Routes.meterPasswordManagement, { meterId }),
        }}><Button
                icon='edit'
                type='button'
                variant='outline'
            >Изменить</Button>
        </Link>
    );

    const meterCommunicatorBlock: BlockProps = {
        type: 'vertical',
        items: [
            ...spreadItemsByCondition<BlockPropsItem>(
                !!latestTask,
                [
                    {
                        title: 'Состояние',
                        value: getStatusFromMeterActionTask(latestTask),
                        type: 'text',
                    }
                ],
            ),
            {
                title: 'Пароль пользователя',
                value: '******',
                type: 'password',
                passwordType: PasswordTypes.UserPassword,
                entityId: meterId,
            },
            {
                title: 'Пароль администратора',
                value: '******',
                type: 'password',
                passwordType: PasswordTypes.AdminPassword,
                entityId: meterId,
            },
            {
                title: 'Дата последней успешной смены паролей',
                value: latestTask?.endDate ? formatDate(latestTask?.endDate) : '—',
                type: 'text',
            },
        ],
    };

    return (
        <Wrapper title='УПРАВЛЕНИЕ ПАРОЛЯМИ' actions={relayActions}>
            <Block {...meterCommunicatorBlock} />
        </Wrapper>
    );
};
