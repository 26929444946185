import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { ExportDocument80020Creator } from '../../../common/model/meter/exportDocument80020Creator';
import { ExportDocument80020ViewModel } from '../../../common/model/meter/exportDocument80020ViewModel';

export const getExportDocument8002080040 = async (
    payload: ExportDocument80020Creator
): Promise<ExportDocument80020ViewModel> => {
    const route = getApiRoute(appUrls.api_get_export_80020_80040, {}, { ...payload });
    const data = await httpService.get<ExportDocument80020ViewModel>(route);
    return data;
};
