import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterPowerSwitchCounters } from '../../../common/model/meter/meterPowerSwitch/meterPowerSwitchCounters';

export type MeterPowerSwitchCountersState = {
    available: MeterPowerSwitchCounters;
};
const initialState: MeterPowerSwitchCountersState = {
    available: null,
};

export const meterPowerSwitchCountersSlice = createSlice({
    name: '@@wh/settings',
    initialState,
    reducers: {
        setMeterPowerSwitchCounters(state, action: PayloadAction<MeterPowerSwitchCounters>): MeterPowerSwitchCountersState {
            return {
                ...state,
                available: action.payload,
            };
        }
    }
});
