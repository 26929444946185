export enum MeterActivationOrderLogGroup {
    CreatingPURecord = 'CreatingPURecord',
    DeterminationOfIpAddress = 'DeterminationOfIpAddress',
    InitialDiagnostics = 'InitialDiagnostics',
    ChangePUStateToActive = 'ChangePUStateToActive',
}

export const meterActivationOrderLogGroupDictionary: Record<MeterActivationOrderLogGroup, string> = {
    [MeterActivationOrderLogGroup.CreatingPURecord]: 'Создание записи о ПУ в системе',
    [MeterActivationOrderLogGroup.DeterminationOfIpAddress]: 'Определение IP адреса',
    [MeterActivationOrderLogGroup.InitialDiagnostics]: 'Первичная диагностика',
    [MeterActivationOrderLogGroup.ChangePUStateToActive]: 'Смена состояния ПУ на Активен',
}
