import { Box } from '@material-ui/core';
import { Column } from '@softmedialab/materialui-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessRule } from '../../../../common/model/access/accessRule';
import { NetworkOperatorViewModel } from '../../../../common/model/meter/networkOperator/networkOperatorViewModel';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import {
    createNetworkOperator,
    deleteNetworkOperator,
    getNetworkOperators,
    updateNetworkOperator
} from '../../../../redux/actions/networkOperators';
import { updateSetting } from '../../../../redux/actions/settings';
import { selectNetworkOperatorsListAvailableResponse } from '../../../../redux/selectors/networkOperators';
import { DropdownMenu } from '../../../shared/components/dropdownMenu';
import { PasswordText } from '../../../shared/components/passwordText';
import { useAccessRules } from '../../../shared/hooks/useAccessRules';
import { getErrorMessage } from '../../../shared/utils/error';
import { DefaultNetworkOperatorFormData } from './components/defaultNetworkOperatorForm/model';
import { NetworkOperatorFormData } from './components/networkOperatorForm/model';
import { View } from './view';


const defaultEditFormNetworkOperator: NetworkOperatorFormData = {
    name: null,
    server: null,
    login: null,
    password: null,
};

export const NetworkOperators: React.FC = () => {

    const dispatch = useDispatch<BaseDispatch>();

    const [editFormNetworkOperator, setEditFormNetworkOperator] = useState<NetworkOperatorFormData>(null);
    const [deleteFormNetworkOperator, setDeleteFormNetworkOperator] = useState<NetworkOperatorFormData>(null);

    const [isDefaultFormOpen, setDefaultFormOpen] = useState(false);
    const [isNetworkOperatorFormLoading, setIsNetworkOperatorFormLoading] = useState(false);
    const [isDeleteNetworkOperatorLoading, setIsDeleteNetworkOperatorLoading] = useState(false);
    const [isDefaultNetworkOperatorLoading, setIsDefaultNetworkOperatorLoading] = useState(false);

    const data = useSelector(selectNetworkOperatorsListAvailableResponse) ?? [];

    const defaultNetworkOperator = useMemo(() => {
        return data.find(item => item.isDefault);
    }, [data]);

    const { hasAccess } = useAccessRules();

    const isUserCanChangeSettings = hasAccess(AccessRule.CanPostSettings);

    const fetch = async () => {
        await dispatch(getNetworkOperators({
            sortFieldName: 'id',
            asc: false,
        }));
    };

    useEffect(() => {
        fetch();
    }, []);

    const onClickEditDefaultNetworkOperator = () => {
        setDefaultFormOpen(true);
    };

    const onCancelEditFormNetworkOperator = () => {
        setEditFormNetworkOperator(null);
    };

    const onSubmitEditFormNetworkOperator = async (formData: NetworkOperatorFormData) => {
        const isNewRecord = !formData?.id;
        setIsNetworkOperatorFormLoading(true);
        try {
            if (isNewRecord) {
                await createNetworkOperator(formData);
            } else {
                await updateNetworkOperator(formData.id, formData);
            }
            await fetch();
            setEditFormNetworkOperator(null);
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        } finally {
            setIsNetworkOperatorFormLoading(false);
        }
    };

    const onCancelDeleteNetworkOperator = () => {
        setDeleteFormNetworkOperator(null);
    };

    const onDeleteNetworkOperator = async (model: NetworkOperatorViewModel) => {
        setIsDeleteNetworkOperatorLoading(true);
        try {
            await deleteNetworkOperator(model.id);
            await fetch();
            setDeleteFormNetworkOperator(null);
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        } finally {
            setIsDeleteNetworkOperatorLoading(false);
        }
    };

    const onCancelDefaultFormNetworkOperator = () => {
        setDefaultFormOpen(false);
    };

    const onSubmitDefaultFormNetworkOperator = async (formData: DefaultNetworkOperatorFormData) => {
        if (!isUserCanChangeSettings) {
            return;
        }
        if (!formData.defaultNetworkOperatorId) {
            return;
        }
        setIsDefaultNetworkOperatorLoading(true);
        try {
            await dispatch(updateSetting({
                id: 'defaultNetworkOperator',
                value: formData.defaultNetworkOperatorId.toString(),
            }));
            await fetch();
            setDefaultFormOpen(false);
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        } finally {
            setIsDefaultNetworkOperatorLoading(false);
        }
    };

    const onClickNewNetworkOperator = () => {
        setEditFormNetworkOperator(defaultEditFormNetworkOperator);
    };

    const columns: Column[] = [
        {
            title: 'Идентификатор',
            field: 'id',
            cellClassName: 'col-id',
            sorting: true,
        },
        {
            title: 'Название',
            field: 'name',
            cellClassName: 'col-name',
            sorting: false,
        },
        {
            title: 'Сервер',
            field: 'server',
            cellClassName: 'col-server',
            sorting: false,
        },
        {
            title: 'Логин',
            field: 'login',
            cellClassName: 'col-login',
            sorting: false,
        },
        {
            title: 'Пароль',
            field: 'password',
            cellClassName: 'col-password',
            sorting: false,
            render: (item: NetworkOperatorViewModel) => {
                return <PasswordText fetchPassword={() => Promise.resolve(item.password)} />;
            }
        },
        {
            title: 'Действия',
            field: '',
            cellClassName: 'col-actions',
            sorting: false,
            render: (item: NetworkOperatorViewModel) => {
                return (
                    <Box marginLeft='18px'>
                        <DropdownMenu
                            icon='moreVert'
                            dropdownPosition='bottomRight'
                            options={[
                                {
                                    label: 'Редактировать',
                                    icon: 'edit',
                                    onClick: () => {
                                        setEditFormNetworkOperator(item);
                                    },
                                },
                                {
                                    label: 'Удалить',
                                    icon: 'delete',
                                    onClick: () => {
                                        setDeleteFormNetworkOperator(item);
                                    },
                                }
                            ]} />
                    </Box>
                );
            }
        },
    ];

    return View({
        data,
        columns,
        defaultNetworkOperator,
        editFormNetworkOperator,
        isDefaultFormOpen,
        onClickNewNetworkOperator,
        deleteFormNetworkOperator,
        onCancelDeleteNetworkOperator,
        onDeleteNetworkOperator,
        onCancelEditFormNetworkOperator,
        onSubmitEditFormNetworkOperator,
        onClickEditDefaultNetworkOperator,
        onCancelDefaultFormNetworkOperator,
        onSubmitDefaultFormNetworkOperator,
        isUserCanChangeSettings,
        isNetworkOperatorFormLoading,
        isDeleteNetworkOperatorLoading,
        isDefaultNetworkOperatorLoading,
    });
};
