import { MetersHealthData, MetersWithErrorResponse } from '../../../../common/model/meter/monitoring/metersHealth';
import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import { metersHealthSlice } from '../../../reducers/monitoring/metersHealth';
import { AppThunk } from '../../index';
import { ReadingSource } from '../../../../common/model/meter/readingSource';
import { MeterHealthSummaryInfo } from '../../../../../../common/model/meter/monitoring/metersHealth';
import { ISort } from '../../../../common/sort';

export const setMetersCountByRegion = (regionId: number, readingSource?: ReadingSource): AppThunk => async (dispatch) => {
    const params = {
        readingSource,
        regionId: +regionId,
    };

    const url = getApiRoute(appUrls.api_get_meters_count, {}, params);
    const response: { count: number; } = await httpService.get(url);
    dispatch(metersHealthSlice.actions.setMetersCount(response.count));
};

export const setMetersSummaryInformationByRegion = (regionId: number, readingSource?: ReadingSource): AppThunk => async (dispatch) => {
    const params = {
        readingSource,
        regionId: +regionId,
    };

    const url = getApiRoute(appUrls.api_get_meter_errors_summary, {}, params);
    const response = await httpService.get<MeterHealthSummaryInfo[]>(url);
    dispatch(metersHealthSlice.actions.setSummaryInformation(response));
};

export const setMetersErrorDetalization = (
    regionId: number,
    categoryId: number,
    readingSource?: ReadingSource
): AppThunk => async (dispatch) => {
    const params = {
        readingSource,
        regionId: +regionId,
        categoryId: +categoryId
    };

    const url = getApiRoute(appUrls.api_get_meter_errors_category, {}, params);
    const response = await httpService.get<MeterHealthSummaryInfo[]>(url);
    dispatch(metersHealthSlice.actions.setErrorDetalization(response));
};

export const setMetersWithErrors = (
    regionId: number,
    categoryId: number,
    readingSource: ReadingSource,
    meterTypeLabel: number,
    sort?: ISort
): AppThunk => async (dispatch) => {
    const params = {
        readingSource,
        regionId: +regionId,
        categoryId: +categoryId,
        meterTypeLabel: +meterTypeLabel,
        ...sort
    };

    const url = getApiRoute(appUrls.api_get_meter_errors_details, {}, params);
    const response = await httpService.get<MetersWithErrorResponse>(url);
    dispatch(metersHealthSlice.actions.setMetersWithErrors(response));
};

export const setMetersHealthByRegion = (regionId: number, readingSource?: ReadingSource): AppThunk => async (dispatch) => {
    const params = {
        readingSource,
        regionId: +regionId,
    };

    const url = getApiRoute(appUrls.api_get_meters_errors, {}, params);
    const response: { data: MetersHealthData[]; } = await httpService.get(url);
    dispatch(metersHealthSlice.actions.setMetersHealthData(response.data));
};
