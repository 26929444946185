import { useEffect, useState, MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import { getErrorMessage } from '../../utils/error';
import { View } from './view';
import { InputProps } from './model';
import copy from 'copy-to-clipboard';

const hidePasswordTimer = 5000;

export const PasswordText = (props: InputProps) => {
    const {
        fetchPassword,
    } = props;
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const [visibilitySnackbar, setVisibilitySnackbar] = useState<boolean>(false);
    const [value, setValue] = useState<string>(null);
    const dispatch = useDispatch<BaseDispatch>();

    useEffect(() => {
        if (isPasswordVisible) {
            const showPasswordTimer = setTimeout(() => {
                setIsPasswordVisible(false);
                setValue(null);
            }, hidePasswordTimer);
            return () => clearTimeout(showPasswordTimer);
        }
        return undefined;
    }, [isPasswordVisible]);

    const onClickClose = (ref: MutableRefObject<any>) => {
        const outsideClickListener = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setIsPasswordVisible(false);
                document.removeEventListener('mousedown', outsideClickListener);
            }
        };
        document.addEventListener('mousedown', outsideClickListener, { once: true });
    };

    const handleVisibilityPassword = async (ref: MutableRefObject<any>) => {
        setShowLoader(true);
        try {
            const password = await fetchPassword();
            setValue(password);
            setIsPasswordVisible(!isPasswordVisible);
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        } finally {
            onClickClose(ref);
            setShowLoader(false);
        }
    };

    const handlePasswordCopyClipboard = () => {
        const isCopied = copy(value);

        if (isCopied) {
            setVisibilitySnackbar(!visibilitySnackbar);
        }
    };

    const handleVisibilitySnackbar = () => {
        setVisibilitySnackbar(!visibilitySnackbar);
    };

    return View({
        ...props,
        value,
        showLoader,
        isPasswordVisible,
        handleVisibilityPassword,
        visibilitySnackbar,
        handleVisibilitySnackbar,
        handlePasswordCopyClipboard
    });
};
