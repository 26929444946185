import { Column, Localization } from '@softmedialab/materialui-table';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { UserUpdater } from '../../../../common/model/access/user/userUpdater';
import { UserViewModel } from '../../../../common/model/access/user/userViewModel';
import { PageWithPagination } from '../../../shared/components/pagination/model';

export enum UserMode {
    Create = 'create',
    Update = 'update',
}

export interface Model {
    editableUser: UserViewModel | undefined;
    mode: UserMode;
}

export interface Actions {
    onSubmit: SubmitHandler<UserUpdater>;
    onCloseCreateModal: () => void;
    onClickNewUser: () => void;
}

export interface StateProps extends Actions, Model, PageWithPagination {
    columnDefinition: Column[];
    data: UserViewModel[];
    hasAccessToUsersList: boolean;
    localization: Localization;
    isDeleteUserModalAvailable: boolean;
    activeUser: UserViewModel;
    onDelete: (user: UserViewModel) => void;
    onCloseDeleteModal: () => void;
}
