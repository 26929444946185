import { DateFormats } from '../../../../../common/constants/date';
import {
    NotConnectedMetersReportResponse,
    NotConnectedMetersReportResponseDataItem
} from '../../../../../common/model/meter/reports/notConnectedMeters';
import { roundReadings } from '../../../../../common/shared/utils';
import { monthsDictionary } from '../../../../../common/shared/utils/date';
import { formatDate } from '../../../../shared/utils/dates';
import { NotConnectedMetersReportTableData, NotConnectedMetersReportTableDataSummary } from './model';

export const getSummaryTableData = (
    key: string,
    value: string,
    extraRowNumber: number,
    isStrong = false
): NotConnectedMetersReportTableDataSummary => {
    return {
        rowNumber: null,
        extraRowNumber,
        city: null,
        address: key,
        meterSerialNumber: value,
        readingDate: null,
        cumulativeActiveEnergyImp: null,
        isStrong,
        meterId: null,
    };
};

export const transformNotConnectedMetersReportResponseToTableDataSummary = (
    response: NotConnectedMetersReportResponse,
    daysOffline: number,
    notConnectedDataLength = 0,
): NotConnectedMetersReportTableData[] => {
    if (!response) {
        return [];
    }
    const { total, amount, percent } = response;
    return [
        getSummaryTableData(
            'Всего ПУ:',
            `${total}`,
            notConnectedDataLength,
            true
        ),
        getSummaryTableData(
            `Нет связи с ПУ более ${daysOffline} суток:`,
            amount?.toString(),
            notConnectedDataLength + 1,
            true
        ),
        getSummaryTableData(
            'Процент неопроса от общего числа ПУ:',
            percent?.toString(),
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            notConnectedDataLength + 2,
            true
        ),
    ];
};

export const transformNotConnectedMetersReportResponseToTableDataSummaryByMonths = (
    response: NotConnectedMetersReportResponse,
    notConnectedDataLength = 0,
): NotConnectedMetersReportTableData[] => {
    if (!response) {
        return [];
    }

    const { byMonth, notConnectedLastTime } = response;
    const byMonthData = byMonth.length > 0 ? [
        getSummaryTableData(
            'Из опроса выпали в:',
            null,
            notConnectedDataLength,
            true
        ),
        ...byMonth.map((item, index) => {
            return getSummaryTableData(
                monthsDictionary[item.monthNumber],
                item.amount?.toString(),
                notConnectedDataLength + index + 1
            );
        })
    ] : [];

    return [
        ...byMonthData,
        getSummaryTableData(
            'Не выходили на связь:',
            notConnectedLastTime?.toString(),
            byMonthData.length + notConnectedDataLength,
            true
        ),
    ];
};

export const transformNotConnectedMetersReportDataItemToTableData = (
    data: NotConnectedMetersReportResponseDataItem[],
): NotConnectedMetersReportTableData[] => {
    if (!data) {
        return [];
    }
    return data.map((item) => ({
        rowNumber: item.rowNumber?.toString(),
        city: [item.areaName, item.townName].filter(a => !!a).join(', '),
        address: item.address,
        meterSerialNumber: item.meterSerialNum?.toString(),
        readingDate: item.readingDate ? formatDate(item.readingDate, DateFormats.dateFullTimeFormat) : null,
        cumulativeActiveEnergyImp: roundReadings(item.cumulativeActiveEnergyImp)?.toString(),
        meterId: item.meterId,
    }));
};

export const transformNotConnectedMetersReportResponseToTableData = (
    response: NotConnectedMetersReportResponse,
    daysOffline: number,
): NotConnectedMetersReportTableData[] => {
    if (!response) {
        return [];
    }
    const { data } = response;
    const notConnectedData = data.filter(item => item.outdated);
    const notConnectedDataLength = notConnectedData.length;
    const dataSummaryLength = 3;
    return [
        ...transformNotConnectedMetersReportDataItemToTableData(notConnectedData),
        ...transformNotConnectedMetersReportResponseToTableDataSummary(response, daysOffline, notConnectedDataLength),
        ...transformNotConnectedMetersReportResponseToTableDataSummaryByMonths(
            response,
            notConnectedDataLength + dataSummaryLength
        ),
    ];
};
