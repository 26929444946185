import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../../../shared/components/button';
import { Modal } from '../../../../../shared/components/modal';
import { Typography } from '../../../../../shared/components/typography';
import { StateProps } from './model';

const useStyle = makeStyles(() => ({
    modalWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .modal-content': {
            '& .modal-header': {
                justifyContent: 'flex-start !important',
                '& span': {
                    fontSize: 16
                },
            },
            '& .modal-body': {
                padding: '0 24px 24px !important'
            }
        },
    }
}));

export const View = ({
    formValues,
    onCloseModal,
    onExecuteActivationOrder,
}: StateProps) => {
    const classes = useStyle();

    return (
        <Modal
            title='Запустить операцию по вводу ПУ в эксплуатацию?'
            close='Отмена'
            onHide={onCloseModal}
            className={classes.modalWrapper}
        >
            <Typography variant='body'>
                Вы хотите запустить процесс ввода ПУ в эксплуатацию или только сохранить заявку, которая будет запущена позже?
            </Typography>
            <Box marginTop={3} display='flex'>
                <Button variant='primary' onClick={() => onExecuteActivationOrder(formValues, true)}>Запустить ввод ПУ</Button>
                <Button className='m-l-16' variant='secondary' onClick={() => onExecuteActivationOrder(formValues, false)}>Запущу позже</Button>
            </Box>
        </Modal>
    );
};
