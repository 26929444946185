import React, { useMemo } from 'react';
import { TaskInfoCardViewProps } from './model';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '../../../../shared/components/typography';
import { formatDate } from '../../../../shared/utils/dates';
import { StatusIcon } from 'app/shared/components/statusIcon';
import { InfoBadge } from './infoBadge';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 16px',
    },
    createdByName: {
        paddingLeft: '8px',
    },
    contentBlock: {
        paddingBottom: '13px',
        width: '100%',
    },
    lastItem: {
        width: '100%',
    },
    taskTitle: {
        flexWrap: 'wrap',
    },
    dividedLine: {
        height: '1px',
        width: '100%',
        backgroundColor: theme.palette.neutral.ultraLight,
    },
}));

export const View = (props: TaskInfoCardViewProps) => {
    const { task, createdBy, historyMeterGisTask } = props;
    const classes = useStyles();

    const prepareBadgeData = useMemo(() => {
        return historyMeterGisTask[task.id]?.filter(history => history.__operation === 'UPDATE')
            .sort((a, b) => new Date(a.__changedate).getTime() - new Date(b.__changedate).getTime());
    }, [historyMeterGisTask, task]);

    return (
        <>
            <Grid container className={classes.container}>
                <Grid item className={classes.contentBlock}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant='caption' component='p'>{formatDate(task.createdAt)}</Typography>
                        </Grid>
                        <Grid item>
                            {
                                task.createdBy && (
                                    <Typography variant='caption' className={classes.createdByName}>{createdBy}</Typography>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.contentBlock}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <StatusIcon status={task.status} />
                        </Grid>
                        <Grid item xs={8} xl={8} lg={8}>
                            <Typography variant='h3'>{task.title}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body'>{task.number}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    task.notes && (
                        <Grid item className={classes.contentBlock}>
                            <Typography variant='body' component='p'>{task.notes}</Typography>
                        </Grid>
                    )
                }
                <Grid item className={classes.contentBlock}>
                    <Box className={classes.dividedLine} />
                </Grid>

                <Grid item className={classes.lastItem}>
                    {
                        prepareBadgeData?.map(history => <InfoBadge key={history.__id} historyGisTask={history} />)
                    }
                </Grid>
            </Grid>
        </>
    );
};
