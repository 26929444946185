import moment from 'moment';
import React, { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import { createSnackbar } from '../../../redux/actions/controls';
import { getErrorMessage } from '../../shared/utils/error';
import { transformDatesParamsWithoutEnds, transformDatesParamsWithoutTz } from '../../shared/utils/transformers/dates';
import { DateFormats } from '../../../common/constants/date';
import { AccessRule } from '../../../common/model/access/accessRule';
import { EventLogFindRequest } from '../../../common/model/meter/eventLog/eventLogFindRequest';
import { EventLogViewModel } from '../../../common/model/meter/eventLog/eventLogViewModel';
import { logTypeDictionary } from '../../../common/model/meter/eventLog/logType';
import { BaseDispatch } from '../../../redux/actions';
import {
    commonEventLogsSetLoading,
    downloadEventLog,
    findCommonEventLogs,
} from '../../../redux/actions/eventLogs';
import { commonEventLogSlice } from '../../../redux/reducers/eventLogs/common';
import {
    commonEventLogTotalSelector,
    commonEventLogIsFilterVisibleSelector,
    commonEventLogLoadingSelector,
    commonEventLogsAvailableSelector,
} from '../../../redux/selectors/eventLogs';
import { Icon } from '../../shared/components/icon';
import { usePagination } from '../../shared/components/pagination';
import { MeterTabs, ReadingSubs, Routes } from '../../shared/constants';
import { useAccessRules } from '../../shared/hooks/useAccessRules';
import { useSyncQueryParams } from '../../shared/hooks/useQueryParams';
import { getRoute } from '../../shared/pipes';
import { formatEventLogMessage } from '../../shared/utils/formatEventLog';
import { Props, StateProps } from './model';
import { View } from './view';
import { ISort } from '../../../common/sort';
import './style.css';


const initialFilterValues: EventLogFindRequest = {
    dateFrom: moment().add(-1, 'month').toDate(),
    dateTo: moment().add(1, 'day').toDate(),
    eventType: [],
    logType: undefined,
    isCritical: undefined,
    search: undefined,
    sortFieldName: undefined,
    asc: undefined,
    meterIds: [],
};

export const EventLog: React.FC<Props> = (ownProps) => {
    const theme = useTheme();
    const params = useParams<{meterId?: string}>();
    const dispatch = useDispatch<BaseDispatch>();
    const { hasAccess } = useAccessRules();
    const loading = useSelector(commonEventLogLoadingSelector);
    const isFilterVisible = useSelector(commonEventLogIsFilterVisibleSelector);
    const data = useSelector(commonEventLogsAvailableSelector);
    const dataTotal = useSelector(commonEventLogTotalSelector);
    const [filterValues, setFilterValues] = useSyncQueryParams<EventLogFindRequest>(initialFilterValues);

    const fetch = async (sort: ISort, dataFilterParams: EventLogFindRequest = filterValues) => {
        try {
            await dispatch(findCommonEventLogs({
                ...transformDatesParamsWithoutTz({
                    ...dataFilterParams,
                }),
                meterIds: params.meterId ? [params.meterId] : dataFilterParams.meterIds,
            }, sort));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                delay: 5000,
                message: getErrorMessage(e),
            }));
            dispatch(commonEventLogsSetLoading(false));
        }
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 10,
        orderBy: null,
        orderDirection: null,
    }, fetch);

    const refreshTableData = (dataFilterParams: EventLogFindRequest) => {
        onFetch(pagination, dataFilterParams);
    };

    useEffect(() => {
        refreshTableData(filterValues);
    }, []);

    /**
     * Dispatch new route on filter form submit
     */
    const onFilterSubmit: SubmitHandler<EventLogFindRequest> = (values) => {
        setFilterValues(transformDatesParamsWithoutEnds(values));
        refreshTableData(values);
    };

    const props: StateProps = {
        ...ownProps,
        canReadEventLogs: hasAccess(AccessRule.CanReadEventLogs),
        canDownloadEventLogsToExcel: hasAccess(AccessRule.CanDownloadEventLogsExcel),
        data,
        pagination,
        setOrder,
        setPage,
        orderParams,
        dataTotal,
        loading,
        filterValues,
        isFilterVisible,
        initialFilterValues,
        onUnloadToExcel: () => {
            dispatch(downloadEventLog({
                ...filterValues,
                meterIds: params.meterId
                    ? [params.meterId]
                    : filterValues.meterIds,
            }));
        },
        onFiltersOpen: () => {
            dispatch(commonEventLogSlice.actions.setFilterVisibility(true));
        },
        onFiltersClose: () => {
            dispatch(commonEventLogSlice.actions.setFilterVisibility(false));
        },
        onFilterSubmit,
        columnDefinition: [
            {
                title: 'Дата',
                field: 'eventDateTime',
                sorting: true,
                render: (dataItem: EventLogViewModel) => moment(dataItem.eventDateTime)
                    .utc()
                    .add(dataItem.localTimeShift, 'minutes')
                    .format(DateFormats.dateFullTimeFormat),
                cellClassName: 'col-date',
            },
            {
                title: 'Смещение',
                field: 'localTimeShift',
                sorting: false,
                render: (dataItem: EventLogViewModel) => {
                    const oneHour = 60;
                    const timeShift = dataItem.localTimeShift > oneHour ? dataItem.localTimeShift / oneHour : dataItem.localTimeShift;
                    return `+${timeShift < 10 ? `0${timeShift}` : timeShift}:00`;
                },
                cellClassName: 'col-local-time-shift',
            },
            {
                title: 'Журнал',
                field: 'logType',
                sorting: false,
                lookup: logTypeDictionary,
                cellClassName: 'col-log-type',
            },
            {
                title: '',
                field: 'isCritical',
                sorting: false,
                render: (dataItem: EventLogViewModel) => {
                    const { eventType } = dataItem;
                    return eventType?.isCritical && <Icon color='error' className='eventLog-icon' name='alert' />;
                },
                cellClassName: 'col-is-critical',
            },
            {
                title: 'Событие',
                field: 'eventTypeId',
                sorting: false,
                render: (dataItem: EventLogViewModel) => formatEventLogMessage(dataItem),
                cellClassName: 'col-event-type-id',
            },
            {
                title: 'Прибор учета',
                sorting: false,
                render: (dataItem: EventLogViewModel) => {
                    if (!dataItem.meterName) {
                        return `Счётчик не найден (${dataItem.meterId})`;
                    }
                    const route = getRoute(Routes.meter, { meterId: dataItem.meterId }, { tab: MeterTabs.Reading, sub: ReadingSubs.List });
                    return <Link
                        style={{
                            color: theme.palette.primary.light,
                            textDecoration: 'none',
                        }}
                        to={route}
                    >{dataItem.meterName}</Link>;
                },
                hidden: Boolean(params.meterId)
            },
        ],
    };

    return View(props);
};
