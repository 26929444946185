import { Column } from '@softmedialab/materialui-table';
import { Button } from '../../../shared/components/button';
import React from 'react';
import { MeterLabelTypeViewModel } from '../../../../common/model/meter/meterLabelType/meterLabelTypeViewModel';
import { Checkbox } from '../../../shared/components/checkbox';
import { ColumnActions } from './model';
import { meterLabelCategoryDictionary } from '../../../../common/model/meter/meterLabel/meterLabelCategory';
import { useTheme } from '@material-ui/core';

const TaskColumn: React.FC<{checked: boolean}> = ({ checked }) => {
    const theme = useTheme();
    return <Checkbox disabled checked={checked} color={theme.palette.neutral.lightGray} />;
};

export const createColumns = (actions: ColumnActions): Column[] => ([
    {
        title: 'Название',
        field: 'name',
    },
    {
        title: 'Описание',
        field: 'description',
    },
    {
        title: 'Категория метки',
        render: ({ meterLabelCategoryId }: MeterLabelTypeViewModel) => {
            return meterLabelCategoryDictionary[meterLabelCategoryId] || 'Не известна';
        },
        customSort: (a, b) => {
            const firstCategory = meterLabelCategoryDictionary[a.meterLabelCategoryId] || 'Не известна';
            const secondCategory = meterLabelCategoryDictionary[b.meterLabelCategoryId] || 'Не известна';

            if (secondCategory > firstCategory) {
                return -1;
            }
            if (secondCategory < firstCategory) {
                return 1;
            }
            return 0;
        }
    },
    {
        title: 'Автоматически создавать инцидент',
        cellStyle: { width: 154, textAlign: 'center' },
        sorting: false,
        render: ({ autoCreateTask }: MeterLabelTypeViewModel) => <TaskColumn checked={autoCreateTask} />
    },
    {
        title: 'Автоматически закрывать инцидент',
        cellStyle: { width: 154, textAlign: 'center' },
        sorting: false,
        render: ({ autoCloseTask }: MeterLabelTypeViewModel) => <TaskColumn checked={autoCloseTask} />
    },
    {
        title: '',
        sorting: false,
        cellStyle: { width: 200, textAlign: 'center', padding: '2px 34px' },
        render: (item: MeterLabelTypeViewModel) => {
            return (
                <Button
                    type='button'
                    variant='primary'
                    fullWidth
                    onClick={() => actions.setEditableLabel(item)}
                >
                    Редактировать
                </Button>
            );
        },
    },
]);
