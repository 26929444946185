import { MeterGroupViewModel } from '../../../../common/model/meter/meterGroup/meterGroupViewModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchMetersResponse } from '../../../../common/model/meter/searchMeters/searchMetersResponse';

export interface MeterGroupListState {
    available: MeterGroupViewModel[];
    metersListResponse: SearchMetersResponse;
}

const initialState: MeterGroupListState = {
    available: [],
    metersListResponse: null,
};

export const meterGroupListSlice = createSlice({
    name: '@@vv/meterGroup/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<MeterGroupViewModel[]>): void {
            state.available = action.payload;
        },
        setDynamicMetersList(state, action: PayloadAction<SearchMetersResponse>): void {
            state.metersListResponse = action.payload;
        },
    },
});
