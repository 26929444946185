import { SvgIconProps } from '@material-ui/core';
import * as Icons from '../../../../assets/icons';

export interface IconProps extends SvgIconProps{
    name: IconType;
    // TODO: refactor fillColor
    fillColor?: string;
    strokeColor?: string;
    className?: string;
}

export type IconType =
    | 'add'
    | 'addToList'
    | 'alert'
    | 'calendar'
    | 'electricity'
    | 'user'
    | 'union'
    | 'settings'
    | 'tools'
    | 'report'
    | 'chart'
    | 'radioOff'
    | 'radioOn'
    | 'arrowListClose'
    | 'arrowListOpen'
    | 'warning'
    | 'success'
    | 'collapseList'
    | 'expandList'
    | 'tree'
    | 'list'
    | 'more'
    | 'arrowSmallUp'
    | 'arrowSmallDown'
    | 'arrowSmallLeft'
    | 'arrowSmallRight'
    | 'pause'
    | 'cancel'
    | 'star'
    | 'checkboxChecked'
    | 'checkboxUnchecked'
    | 'sort'
    | 'filter'
    | 'refresh'
    | 'edit'
    | 'visibility'
    | 'visibilityOff'
    | 'check'
    | 'close'
    | 'exit'
    | 'arrowBigLeft'
    | 'arrowBigRight'
    | 'arrowBigDown'
    | 'search'
    | 'help'
    | 'connection'
    | 'excel'
    | 'restart'
    | 'play'
    | 'calendarWithTime'
    | 'notification'
    | 'amper'
    | 'amperFill'
    | 'clock'
    | 'clockFill'
    | 'meter'
    | 'meterFill'
    | 'version'
    | 'versionFill'
    | 'voltage'
    | 'voltageFill'
    | 'simCard'
    | 'multilineChart'
    | 'location'
    | 'event'
    | 'closeTreeItemArrow'
    | 'inProgress'
    | 'notStarted'
    | 'duplicate'
    | 'delete'
    | 'download'
    | 'upload'
    | 'uploadAlt'
    | 'loader'
    | 'partnersMeterIcon'
    | 'partnersMeterDarkIcon'
    | 'checkCircle'
    | 'open'
    | 'moreVert'
    | 'json'
    | 'chain'
    | 'warningCircle'
    | 'errorOutline'
    | 'infoOutline'
    | 'warningTriangle'
    | 'filterFilled'
    | 'collapseFilled'
    | 'collapseOutlined'
    | 'expandFilled'
    | 'expandOutlined'
    | 'listColored';

export const IconComponent: Record<IconType, any> = {
    add: Icons.AddIcon,
    addToList: Icons.AddToListIcon,
    alert: Icons.AlertIcon,
    calendar: Icons.CalendarIcon,
    electricity: Icons.ElectricityIcon,
    user: Icons.UserIcon,
    union: Icons.UnionIcon,
    settings: Icons.SettingsIcon,
    tools: Icons.ToolsIcon,
    report: Icons.ReportIcon,
    chart: Icons.ChartIcon,
    radioOff: Icons.RadioOffIcon,
    radioOn: Icons.RadioOnIcon,
    arrowListClose: Icons.ArrowListCloseIcon,
    arrowListOpen: Icons.ArrowListOpen,
    warning: Icons.WarningIcon,
    success: Icons.SuccessIcon,
    collapseList: Icons.CollapseListIcon,
    expandList: Icons.ExpandListIcon,
    tree: Icons.TreeIcon,
    list: Icons.ListIcon,
    listColored: Icons.ListColored,
    more: Icons.MoreIcon,
    arrowSmallUp: Icons.ArrowSmallUpIcon,
    arrowSmallDown: Icons.ArrowSmallDownIcon,
    arrowSmallLeft: Icons.ArrowSmallLeftIcon,
    arrowSmallRight: Icons.ArrowSmallRightIcon,
    pause: Icons.PauseIcon,
    cancel: Icons.CancelIcon,
    star: Icons.StarIcon,
    checkboxChecked: Icons.CheckboxCheckedIcon,
    checkboxUnchecked: Icons.CheckboxUncheckedIcon,
    sort: Icons.SortIcon,
    filter: Icons.FilterIcon,
    refresh: Icons.RefreshIcon,
    edit: Icons.EditIcon,
    visibility: Icons.VisibilityIcon,
    visibilityOff: Icons.VisibilityOffIcon,
    check: Icons.CheckIcon,
    close: Icons.CloseIcon,
    exit: Icons.ExitIcon,
    arrowBigLeft: Icons.ArrowBigLeftIcon,
    arrowBigRight: Icons.ArrowSmallRightIcon,
    arrowBigDown: Icons.ArrowBigDownIcon,
    search: Icons.SearchIcon,
    help: Icons.HelpIcon,
    connection: Icons.ConnectionIcon,
    excel: Icons.ExcelIcon,
    restart: Icons.RestartIcon,
    play: Icons.PlayIcon,
    calendarWithTime: Icons.CalendarWIthTimeIcon,
    notification: Icons.NotificationIcon,
    duplicate: Icons.DuplicateIcon,
    amper: Icons.AmperIcon,
    amperFill: Icons.AmperFillIcon,
    clock: Icons.ClockIcon,
    clockFill: Icons.ClockFillIcon,
    meter: Icons.MeterIcon,
    meterFill: Icons.MeterFillIcon,
    version: Icons.VersionIcon,
    versionFill: Icons.VersionFillIcon,
    voltage: Icons.VoltageIcon,
    voltageFill: Icons.VoltageFillIcon,
    simCard: Icons.SimCardIcon,
    multilineChart: Icons.MultilineChartIcon,
    location: Icons.LocationIcon,
    event: Icons.EventIcon,
    closeTreeItemArrow: Icons.CloseTreeItemArrow,
    notStarted: Icons.NotStartedIcon,
    inProgress: Icons.InProgressIcon,
    delete: Icons.DeleteIcon,
    download: Icons.DownloadIcon,
    upload: Icons.UploadIcon,
    uploadAlt: Icons.UploadAltIcon,
    loader: Icons.LoaderIcon,
    partnersMeterIcon: Icons.PartnersMeterIcon,
    partnersMeterDarkIcon: Icons.PartnersMeterDarkIcon,
    open: Icons.OpenIcon,
    moreVert: Icons.MoreVertIcon,
    json: Icons.JsonIcon,
    chain: Icons.ChainIcon,
    warningCircle: Icons.WarningCircleIcon,
    errorOutline: Icons.ErrorOutlineIcon,
    infoOutline: Icons.InfoOutlineIcon,
    warningTriangle: Icons.WarningTriangleIcon,
    filterFilled: Icons.FilterFilledIcon,
    checkCircle: Icons.CheckCircle,
    collapseFilled: Icons.CollapseFilledIcon,
    collapseOutlined: Icons.CollapseOutlinedIcon,
    expandFilled: Icons.ExpandFilledIcon,
    expandOutlined: Icons.ExpandOutlinedIcon,
};
