import { AccessRule } from '../../../common/model/access/accessRule';
import { AdministrationTabs, Routes } from '../../shared/constants';
import { useAccessRules } from '../../shared/hooks/useAccessRules';
import { useTabs } from '../../shared/hooks/useTabs';
import { IStateProps } from './model';
import { View } from './view';

export const Administration = () => {

    const {
        activeTab,
        setTab,
    } = useTabs<AdministrationTabs>({
        values: AdministrationTabs,
        defaultTab: AdministrationTabs.Schemes,
        route: Routes.administration,
    });

    const { notHasAccess } = useAccessRules();

    const props: IStateProps = {
        activeTab,
        tabs: [
            {
                key: AdministrationTabs.Schemes,
                title: 'Схемы опроса',
                isHidden: false,
            },
            {
                key: AdministrationTabs.TariffSchedules,
                title: 'Тарифное расписание',
                isHidden: false,
            },
            {
                key: AdministrationTabs.Settings,
                title: 'Настройки системы',
                isHidden: false,
            },
            {
                key: AdministrationTabs.Access,
                title: 'Контроль доступа',
                isHidden: false,
            },
            {
                key: AdministrationTabs.Users,
                title: 'Пользователи',
                isHidden: false,
            },
            {
                key: AdministrationTabs.Groups,
                title: 'Управление группами',
                isHidden: false,
            },
            {
                key: AdministrationTabs.Dictionaries,
                title: 'Справочники',
                isHidden: false,
            },
            {
                key: AdministrationTabs.Audit,
                title: 'Аудит',
                isHidden: notHasAccess(AccessRule.CanGetSystemLog),
            },
            {
                key: AdministrationTabs.TimeSynchronizationSchemes,
                title: 'Синхронизация времени',
                isHidden: notHasAccess(AccessRule.CanReadTimeSynchronizationSchemes),
            },
            {
                key: AdministrationTabs.ChangePasswordRequests,
                title: 'Массовая смена паролей',
                isHidden: notHasAccess(AccessRule.CanGetMeterChangePasswordRequest),
            },
        ],
        onChangeTab: (e, tab) => {
            setTab(tab);
        },
    };

    return View(props);
};
