const noun = (num = 0, words = [ 'рубль', 'рубля', 'рублей' ]) => {
	const oneHundred = 100;
	const middleEdge = 5;
	const finalEdge = 20;

	const number = Math.abs(num) % oneHundred;
	const numberDecimal = number % 10;
	const numberDecimalFraction = number * 10 % 10;

	// Дробное число, например
	// 1 рубль
	// 1,3 рубля
	// 100 рублей
	if (numberDecimalFraction !== 0) {
		return words[1];
	}

	if (number > 10 && number < finalEdge) {
		return words[2];
	}

	if (numberDecimal > 1 && numberDecimal < middleEdge) {
		return words[1];
	}

	if (numberDecimal === 1) {
		return words[0];
	}

	return words[2];
};
export const daysNouns = [ 'сутки', 'суток', 'суток' ];
export const tariffCountNouns = [ 'тариф', 'тарифа', 'тарифов' ];
export const metersNouns = [ 'счетчик', 'счетчика', 'счетчиков' ];
export const secondsNouns = [ 'секунда', 'секунды', 'секунд' ];
export const symbolNouns = [ 'символ', 'символа', 'символов' ];
export default noun;
