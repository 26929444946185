export const meterActionSchemeValidationRules = {
    configurationSnapshotIntervalHours: {
        min: 1,
        default: 4,
    },
    currentReadingAllowedDelayHours: {
        min: 1,
        default: 8,
    },
    dailyReadingAllowedDelayHours: {
        min: 24,
        default: 24,
    },
    dailyReadingMaxDays: {
        min: 1,
        default: 6,
    },
    eventsCollectingAllowedDelayHours: {
        min: 1,
        default: 4,
    },
    incrementalReadingAllowedDelayHours: {
        min: 1,
        default: 4,
    },
    incrementalReadingMaxHours: {
        min: 1,
        default: 12,
    },
    isDefault: {
        min: 1
    },
    monthlyReadingAllowedDelayMonths: {
        min: 1,
        default: 1,
    },
    minRetryDelay: {
        min: 1,
        default: 15,
        max: 1440,
    },
    maxRetryDelay: {
        min: 1,
        default: 720,
        max: 1440,
    },
    maxFailureCount: {
        min: 2,
        default: 10,
        max: 2147483647,
    },
    timeWindows: {
        min: 0,
        default: 32760,
        max: 16777215,
    },
    validateOrChangeTariffIntervalHours: {
        min: 1,
        default: 24,
        max: 720,
    },
    changePasswordInterval: {
        min: 1,
        max: 1000,
    },
    changePasswordPeriod: {
        min: 1,
        max: 3,
    },
};
