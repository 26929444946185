import React from 'react';
import { FormProvider } from 'react-hook-form';
import { IStateProps } from './model';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '../../../../shared/components/typography';
import { HookField } from '../../../../shared/components/hookField';
import { Button } from '../../../../shared/components/button';
import { meterLabelCategoryDictionary } from '../../../../../common/model/meter/meterLabel/meterLabelCategory';
import { MeterLabelCategory } from '../../../../../../../common/model/meter/meterLabel/meterLabelCategory';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        textAlign: 'right',
    },
    buttonContainer: {
        paddingTop: '16px',
    },
    meterModelName: {
        ...theme.typography.bodyBold,
    },
}));

export const View: React.FC<IStateProps> = ({
    form,
    onCancel,
    onSubmit
}) => {
    const classes = useStyles();
    const { handleSubmit, formState: { isSubmitting, isDirty } } = form;

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Категория<br />
                                    метки
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='meterLabelCategoryId'
                                    label='Категория метки'
                                    disabled
                                    fullWidth
                                    transform={{ input: (value: MeterLabelCategory) => meterLabelCategoryDictionary[value] }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Название
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='name'
                                    label='Название'
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Описание
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='description'
                                    label='Описание'
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Автоматически<br />
                                    создавать инцидент
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField name='autoCreateTask' type='checkbox' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Автоматически<br />
                                    закрывать инцидент
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField name='autoCloseTask' type='checkbox' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Название<br />
                                    инцидента
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='taskTitle'
                                    label='Название инцидента'
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                            <Grid item>
                                <Button
                                    isLoading={isSubmitting}
                                    variant='primary'
                                    type='submit'
                                    disabled={!isDirty}
                                    icon='check'
                                >
                                    Сохранить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
