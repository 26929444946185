import { compose, lifecycle, withState, renderNothing, branch } from 'recompose';
import { connect } from 'react-redux';
import { UserViewModel } from '../../../common/model/access/user/userViewModel';

import { StorageState } from '../../../redux/reducers';
import { store } from '../../../index';
import { getCurrentUser } from '../../../redux/actions/user';
import { Settings } from '../../../common/model/resources/settings';
import { getResources } from '../../../redux/actions/resources';


interface IModel {
    user: UserViewModel;
    settings: Settings;
    setLoaded?: (state: boolean) => void;
    loaded?: boolean;
}

export const UserResolver = (viewComponent: any) => {

    const stateLoaded = withState('loaded', 'setLoaded', null);

    const initUser = lifecycle<IModel, unknown, unknown>({
        async componentDidMount() {
            await Promise.all([
                new Promise<void>((resolve) => {
                    (
                        async () => {
                            try {
                                if (!this.props.user) {
                                    await store.dispatch(getCurrentUser());
                                }
                                if (!this.props.settings) {
                                    await store.dispatch(getResources());
                                }
                            } catch (err) {
                                resolve();
                            }
                            resolve();
                        }
                    )();
                }),
            ]);
            this.props.setLoaded(true);
        },
    });

    const redux = connect<IModel, unknown, unknown, StorageState>(
        (state) => ({
            user: state.users.active.user,
            settings: state.resources.settings,
        }),
    );

    const guard = branch(
        (params: IModel) => (!params.loaded),
        renderNothing,
    );

    return compose(
        redux,
        stateLoaded,
        initUser,
        guard,
    )(viewComponent);
};
