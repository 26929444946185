import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterReadingInstantOrderViewModel } from '../../../../common/model/meter/meter/meterReadingInstantOrderViewModel';
import { MeterReadingInstantProfileViewModel } from '../../../../common/model/meter/meter/meterReadingInstantProfileViewModel';

export interface MeterReadingInstantState {
    order: MeterReadingInstantOrderViewModel;
    profile: MeterReadingInstantProfileViewModel;
}

const initialState: MeterReadingInstantState = {
    order: null,
    profile: null,
};

export const meterReadingInstantSlice = createSlice({
    name: '@@vv/meterReading/meterReadingInstant',
    initialState,
    reducers: {
        setOrder(state, action: PayloadAction<MeterReadingInstantOrderViewModel>) {
            state.order = action.payload;
        },
        setReading(state, action: PayloadAction<MeterReadingInstantProfileViewModel>) {
            state.profile = action.payload;
        },
    },
});
