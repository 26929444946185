import _ from 'lodash';
import moment from 'moment';
import { DateFormats } from '../../../../common/constants/date';
import { logEntryOperationDictionary } from '../../../../common/model/logbook/logEntryOperation';
import { logSeverityDictionary } from '../../../../common/model/logbook/logEntrySeverity';
import { logSourceDictionary } from '../../../../common/model/logbook/logEntrySource';
import { logEntryTypeDictionary } from '../../../../common/model/logbook/logEntryType';
import { LogEntryViewModel } from '../../../../common/model/logbook/logEntryViewModel/logEntryViewModel';
import { ISort } from '../../../../common/sort';
import { getAllUsers } from '../../../../redux/actions/user/list';
import { usersAllListSelector } from '../../../../redux/selectors';
import { useMeterAutocomplete } from '../../../shared/components/autocomplete/hooks/useMeterAutocomplete';
import { DropdownMenu } from '../../../shared/components/dropdownMenu';
import { usePagination } from '../../../shared/components/pagination';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { TableTitle } from '../../../shared/components/tableTitle';
import { PaginationParams } from '../../../shared/hooks/useActualPage';
import { spreadItemsByCondition } from '../../../shared/pipes/spreadItemsByCondition';
import { formatDate } from '../../../shared/utils/dates';
import { SeverityColumn } from './components/severity';
import { FilterMetadata, SystemLogForm, SystemLogTokenForm } from './models';
import { View } from './view';
import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { findAuditLogs, getAuditLogsToken, resetAuditLogs, resetAuditLogsToken } from '../../../../redux/actions/audit';
import {
    auditListSelector,
    auditListTotalSelector,
    auditTokenResponseSelector
} from '../../../../redux/selectors/audit';
import { createSnackbar } from '../../../../redux/actions/controls';
import { getErrorMessage } from '../../../shared/utils/error';
import { Column } from '@softmedialab/materialui-table';
import { useForm } from 'react-hook-form';
import { HttpStatusCode } from '../../../../common/httpStatusCode';

const defaultFormValues: SystemLogForm = {
    severity: [],
    source: [],
    type: [],
    userId: null,
    meterSerialNum: null,
    operation: [],
    requestId: null
};

const defaultTokenFormValues: SystemLogTokenForm = {
    dateFrom: moment(new Date()).add(-1, 'day').startOf('day').toDate(),
    dateTo: moment(new Date()).endOf('day').toDate(),
};

const defaultPagination: PaginationParams = {
    page: 1,
    rowsPerPage: 10,
    orderBy: 'createdAt',
    orderDirection: 'desc',
};

export const Audit = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
    const [jsonModalContent, setJsonModalContent] = useState<any>(null);
    const dispatch = useDispatch<BaseDispatch>();

    const data = useSelector(auditListSelector);
    const tokenResponse = useSelector(auditTokenResponseSelector);
    const dataTotal = useSelector(auditListTotalSelector);

    const users = useSelector(usersAllListSelector);

    const usersById = useMemo(() => _.keyBy(users, 'id'), [users]);

    const form = useForm<SystemLogForm>({
        defaultValues: defaultFormValues,
    });

    const tokenForm = useForm<SystemLogTokenForm>({
        defaultValues: defaultTokenFormValues,
    });

    const formData = form.watch();
    const tokenFormData = tokenForm.watch();
    const token = tokenResponse?.token;

    const [appliedFilter, setAppliedFilter] = useState<SystemLogForm>(formData);

    const fetch = async (sort: ISort, query: SystemLogForm = appliedFilter) => {
        setIsLoading(true);
        setAppliedFilter(query);
        try {
            await dispatch(findAuditLogs({
                ...query,
                ...sort,
                token,
            }));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination(defaultPagination, fetch);

    useEffect(() => {
        dispatch(getAllUsers());
        return () => {
            dispatch(resetAuditLogs());
            dispatch(resetAuditLogsToken());
        };
    }, []);

    useEffect(() => {
        if (token) {
            onFetch(pagination, formData);
        } else {
            dispatch(resetAuditLogs());
        }
    }, [token]);


    const isFilteredBySeverity = appliedFilter.severity.length > 0;
    const isFilteredBySource = appliedFilter.source.length > 0;
    const isFilteredByType = appliedFilter.type.length > 0;
    const isFilteredByOperation = appliedFilter.operation.length > 0;
    const isUserSelected = !!appliedFilter.userId;
    const isMeterSerialNumSelected = !!appliedFilter.meterSerialNum;
    const isRequestSelected = !!appliedFilter.requestId;

    const appliedFiltersCount = [
        isFilteredBySeverity,
        isFilteredBySource,
        isFilteredByType,
        isFilteredByOperation,
        isUserSelected,
        isMeterSerialNumSelected,
        isRequestSelected,
    ].filter(item => !!item).length;

    const columns: Column[] = [
        {
            title: useMemo(
                () => <TableTitle icon={isFilteredBySeverity ? 'filterFilled' : undefined}>Severity</TableTitle>,
                [isFilteredBySeverity]
            ),
            field: 'severity',
            sorting: false,
            lookup: logSeverityDictionary,
            render: (item) => {
                return <SeverityColumn tooltip={logSeverityDictionary[item.severity]} severity={item.severity} />;
            },
        },
        {
            title: 'Дата события',
            field: 'createdAt',
            sorting: true,
            render: (item) => {
                return formatDate(item.createdAt, DateFormats.dateTimeFormat);
            },
        },
        {
            title: useMemo(
                () => <TableTitle icon={isFilteredBySource ? 'filterFilled' : undefined}>Источник события</TableTitle>,
                [isFilteredBySource]
            ),
            field: 'source',
            sorting: false,
            lookup: logSourceDictionary,
        },
        {
            title: useMemo(
                () => <TableTitle icon={isFilteredByType ? 'filterFilled' : undefined}>Тип события</TableTitle>,
                [isFilteredByType]
            ),
            field: 'type',
            sorting: false,
            lookup: logEntryTypeDictionary,
        },
        ...spreadItemsByCondition<Column>(!isUserSelected, [
            {
                title: 'Пользователь',
                field: 'userId',
                sorting: false,
                render: (item) => {
                    return usersById[item.userId]?.email ?? '—';
                }
            },
        ]),
        ...spreadItemsByCondition<Column>(!isMeterSerialNumSelected, [
            {
                title: 'Прибор учета',
                field: 'meterSerialNum',
                sorting: false,
            },
        ]),
        {
            title: useMemo(
                () => <TableTitle icon={isFilteredByOperation ? 'filterFilled' : undefined}>Операция</TableTitle>,
                [isFilteredByOperation]
            ),
            field: 'operation',
            sorting: false,
            lookup: logEntryOperationDictionary,
        },
        {
            title: 'Действия',
            sorting: false,
            render: (item: LogEntryViewModel) => {
                return <DropdownMenu
                    icon='moreVert'
                    options={[
                        {
                            label: 'Посмотреть данные события',
                            icon: 'json',
                            onClick: () => {
                                if (item.data) {
                                    try {
                                        const parsedData = JSON.parse(item.data);
                                        setJsonModalContent(parsedData);
                                    } catch (e) {
                                        dispatch(createSnackbar({
                                            type: 'red',
                                            message: getErrorMessage(e),
                                        }));
                                    }
                                }
                            },
                        },
                        {
                            label: 'Показать связанные события',
                            icon: 'chain',
                            onClick: async () => {
                                form.setValue('requestId', item.requestId);
                                const newFormData = {
                                    ...formData,
                                    requestId: item.requestId,
                                };
                                await onFetch({ ...pagination }, newFormData);
                            },
                        },
                    ]} />;
            }
        },
    ];

    const meterAutocomplete = useMeterAutocomplete({
        meterSerialNum: formData?.meterSerialNum,
    }, {
        valueMapKey: 'meterSerialNum',
    });

    const filterMetadata: FilterMetadata = {
        meterSerialNum: {
            label: 'Пользователь',
            fullValue: formData.userId
                ? `${usersById[formData.userId]?.email}, ${usersById[formData.userId]?.name}, ${usersById[formData.userId]?.lastName}`
                : null,
            isSelected: isUserSelected,
            shortValue: formData.userId ? usersById[formData.userId]?.email : null,
        },
        userId: {
            label: 'Прибор учета',
            fullValue: formData.meterSerialNum,
            isSelected: isMeterSerialNumSelected,
            shortValue: formData.meterSerialNum,
        },
        requestId: {
            label: 'События при обработке запроса с идентификатором:',
            fullValue: formData.requestId,
            isSelected: isRequestSelected,
            onClear: async () => {
                form.setValue('requestId', null);
                const newFormData: SystemLogForm = {
                    ...formData,
                    requestId: null,
                };
                await onFetch(pagination, newFormData);
            },
            icon: 'close',
            shortValue: formData.requestId,
        },
    };

    const periodString = !!tokenResponse
        ? 'Данные за период: ' +
        `${formatDate(new Date(tokenResponse.dateFrom), DateFormats.dateTimeFormat)}` +
        ` - ${formatDate(new Date(tokenResponse.dateTo), DateFormats.dateTimeFormat)}`
        : null;

    const usersSuggestions: SelectSuggestion[] = users.map(item => ({
        label: item.email,
        value: item.id,
    }));

    const isTableAvailable = !!tokenResponse;

    return View({
        form,
        data,
        columns,
        formData,
        tokenForm,
        isLoading,
        periodString,
        filterMetadata,
        isFilterVisible,
        jsonModalContent,
        isTableAvailable,
        meterAutocomplete,
        usersSuggestions,
        appliedFiltersCount,
        setOrder,
        setPage,
        orderParams,
        pagination,
        dataTotal,
        onResetPeriod: () => {
            tokenForm.reset(defaultTokenFormValues);
            dispatch(resetAuditLogs());
            dispatch(resetAuditLogsToken());
        },
        onCloseJsonModal: () => {
            setJsonModalContent(null);
        },
        onFiltersOpen: () => {
            setIsFilterVisible(true);
        },
        onFiltersClose: () => {
            setIsFilterVisible(false);
        },
        onClickFormReport: async () => {
            try {
                await dispatch(getAuditLogsToken(tokenFormData));
            } catch (e: unknown) {
                if (e['status'] === HttpStatusCode.notFound) {
                    dispatch(createSnackbar({
                        type: 'white',
                        message: getErrorMessage(e),
                    }));
                    return;
                }

                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
        },
        onClickFormApply: async () => {
            await onFetch(pagination, formData);
            setIsFilterVisible(false);
        },
        onResetFilter: async () => {
            const newFormData = {
                ...defaultFormValues,
            };
            form.reset(newFormData);
            await onFetch(pagination, newFormData);
        },
    });
};
