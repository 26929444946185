import { Box, Grid, makeStyles } from '@material-ui/core';
import React, { ReactNode, useState, useEffect } from 'react';
import { DateFormats } from '../../../../common/constants/date';
import { connectionTypeDictionary } from '../../../../common/model/meter/connectionType';
import {
    LegalEntityType,
    meterLegalTypeDictionary,
} from '../../../../common/model/meter/legalEntityType';
import { PasswordTypes } from '../../../../common/model/meter/meterPassword/passwordTypes';
import { ReadingSource } from '../../../../common/model/meter/readingSource';
import { M2mResponse, StateProps } from './model';
import {
    meterRelayStatusDictionary,
    RelayStatus,
} from '../../../../common/model/meter/relayStatus';
import { isNullOrUndefined } from '../../../../common/shared/utils';
import { MeterInformationBlocks } from '../../../../redux/reducers/meters/details/ui';
import { ConditionView } from '../../../shared/components/conditionView';
import { Loader } from '../../../shared/components/loader';
import { TextElipsis } from '../../../shared/components/textElipsis';
import { Typography } from '../../../shared/components/typography';
import { Wrapper } from '../../../shared/components/wrapper';
import { Block } from '../../../shared/components/wrapper/block';
import {
    BlockProps,
    BlockPropsItem,
} from '../../../shared/components/wrapper/block/model';
import { spreadItemsByCondition } from '../../../shared/pipes/spreadItemsByCondition';
import {
    formatDate,
    formatDateWithoutTime,
    transformLocalTimeMeter,
    changeOffset,
} from '../../../shared/utils/dates';
import { InspectionLog } from '../../inspectionLog';
import { InspectionLogPanel } from '../../inspectionLog/components/inspectionLogPanel';
import { MeterTariffScheduleWrapper } from '../management/meterTariffScheduleWrapper';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from 'redux/actions';
import { refreshM2m } from 'redux/actions/meterUpdateAttributes';
import './style.scss';

const useStyles = makeStyles((theme) => ({
    wrapperBlock: {
        height: '100%',
    },
    notificationWrapper: {
        minWidth: '60px',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.neutral.white,
        boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 2px 24px rgba(0, 0, 0, 0.12)',
        borderRadius: '5px',
    },
    planButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const oneHour = 60;
const noDataText = 'нет данных';

export const View = (props: StateProps) => {
    const {
        meter,
        information,
        onCopyToClipboard,
        latestProfile,
        meterScheme,
        timeSynchronizationScheme,
        uiConfig,
        meterTariffScheduleResponse,
        meterId,
    } = props;

    const classes = useStyles();

    const dispatch = useDispatch<BaseDispatch>();

    const { timeZone } = meter;
    const isPartnerMeter = meter?.readingSource !== ReadingSource.Reading;
    const isCrqMeter = meter?.readingSource === ReadingSource.ImportCrq;

    const hours = timeZone / oneHour;
    const meterTimeZone = hours > 0 ? `+${hours}:00` : `${hours}:00`;

    const [m2m, setM2m] = useState<M2mResponse>(null);

    /**
   * Получение данных из API.
   */
    const getM2MData = async (showSnackBar: boolean) => {
        if (meterId && information && information.msisdn) {
            const lastUpdateValues = await dispatch(
                refreshM2m(meterId, information.msisdn, showSnackBar)
            );

            if (lastUpdateValues) {
                setM2m(lastUpdateValues);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await getM2MData(false);
        };

        fetchData();
    }, [meterId, information]);

    /**
   * Обновление данных.
   */
    const refreshHandler = async () => {
        await getM2MData(true);
    };

    const meterTitleBlock: BlockProps = {
        type: 'horizontal',
        items: [
            {
                title: 'Заводской номер',
                value: `№${meter?.meterSerialNum}`,
                type: 'text',
            },
            ...spreadItemsByCondition<BlockPropsItem>(
                uiConfig.infoBlocks[MeterInformationBlocks.RelayStatus],
                [
                    {
                        title: 'Состояние реле',
                        value: meterRelayStatusDictionary[meter?.relayStatus],
                        type: 'icon',
                        status: meter?.relayStatus === RelayStatus.On ? 'success' : 'error',
                    },
                ]
            ),
            {
                title: 'АИИС КУЭ',
                value: (
                    <TextElipsis text={meter?.code ?? noDataText} lines={1}>
                        {meter?.code ?? noDataText}
                    </TextElipsis>
                ),
                type: 'text',
            },
        ],
    };
    const meterProfileBlock: BlockProps = {
        type: 'horizontal',
        items: [
            {
                title: 'Текущие показания',
                value: latestProfile?.value ? latestProfile.value : noDataText,
                type: 'text',
            },
            {
                title: 'Дата показаний',
                value: latestProfile?.readingDate
                    ? `${transformLocalTimeMeter(
                        latestProfile.readingDate,
                        meter?.timeZone
                    ).format(DateFormats.dateTimeFormat)} (${meterTimeZone})`
                    : noDataText,
                type: 'text',
            },
        ],
    };
    const meterSchemeBlock: BlockProps = {
        type: 'horizontal',
        items: [
            {
                title: 'Схема опроса',
                value: meterScheme?.name ? meterScheme.name : noDataText,
                type: 'text',
            },
            ...spreadItemsByCondition<BlockPropsItem>(!isPartnerMeter, [
                {
                    title: 'Схема синхронизации',
                    value: timeSynchronizationScheme?.name
                        ? timeSynchronizationScheme.name
                        : noDataText,
                    type: 'text',
                },
            ]),
        ],
    };
    const meterInfoBlock: BlockProps = {
        type: 'vertical',
        items: [
            ...spreadItemsByCondition<BlockPropsItem>(isPartnerMeter, [
                {
                    title: 'Партнер',
                    value: meter?.partner?.name ?? noDataText,
                    type: 'text',
                },
            ]),
            {
                title: 'Адрес объекта',
                value: meter?.address?.address
                    ? `${meter.address.address}, ${meter.addressSpecifier || ''}`
                    : noDataText,
                type: 'text',
            },
            {
                title: 'Тип прибора учета',
                value: meter?.meterModel ? meter.meterModel : noDataText,
                type: 'text',
            },
            {
                title: 'Место установки',
                value: meter?.installationSite ? meter.installationSite : noDataText,
                type: 'text',
            },
            ...spreadItemsByCondition<BlockPropsItem>(!isPartnerMeter || isCrqMeter, [
                {
                    title: 'Тип включения',
                    value: meter?.connectionType
                        ? connectionTypeDictionary[meter.connectionType]
                        : noDataText,
                    type: 'text',
                },
                {
                    title: 'Тип учета',
                    value: meter?.accountType ? meter.accountType : noDataText,
                    type: 'text',
                },
                {
                    title: 'Назначение прибора',
                    value:
            meter?.legalEntityType !== LegalEntityType.Company
                ? meter?.meterPurpose
                    ? meter.meterPurpose
                    : noDataText
                : noDataText,
                    type: 'text',
                },
                {
                    title: 'Дата установки',
                    value: meter?.installationDate
                        ? formatDate(meter.installationDate)
                        : noDataText,
                    type: 'text',
                },
                {
                    title: 'Ценовая категория',
                    value: !isNullOrUndefined(meter?.priceCategory)
                        ? meter.priceCategory
                        : noDataText,
                    type: 'text',
                },
                {
                    title: 'Количество тарифов',
                    value: meter?.tariffCount || noDataText,
                    type: 'text',
                },
                {
                    title: 'Правовая форма владельца',
                    value: meter?.legalEntityType
                        ? meterLegalTypeDictionary[meter.legalEntityType]
                        : noDataText,
                    type: 'text',
                },
            ]),
            ...spreadItemsByCondition<BlockPropsItem>(!isPartnerMeter, [
                {
                    title: 'Дата выпуска прибора учета',
                    value: meter?.manufactureDate
                        ? formatDateWithoutTime(meter.manufactureDate)
                        : noDataText,
                    type: 'text',
                },
                {
                    title: 'Следующая поверка',
                    value: meter?.nextVerificationDate
                        ? formatDateWithoutTime(meter.nextVerificationDate)
                        : noDataText,
                    type: 'text',
                },
                {
                    title: 'Сетевой адрес',
                    value: meter?.networkAddress ? meter.networkAddress : noDataText,
                    type: 'text',
                },
                {
                    title: 'Версия прошивки ПО ПУ',
                    value: meter?.meterFirmwareVersion
                        ? meter.meterFirmwareVersion
                        : noDataText,
                    type: 'text',
                },
            ]),
            {
                title: 'Временная зона',
                value: meterTimeZone ?? noDataText,
                type: 'text',
            },
            {
                title: 'ID прибора учета',
                value: (
                    <div className="btn-copy" onClick={onCopyToClipboard}>
            Копировать
                    </div>
                ),
                type: 'text',
            },
        ],
    };

    const latestGprsDate = m2m?.latestSessionDate && new Date(m2m.latestSessionDate)
        ? `${formatDate(changeOffset(m2m.latestSessionDate, timeZone), DateFormats.dateTimeFormat)} (${meterTimeZone})`
        : noDataText;

    const meterCommunicatorBlock: BlockProps = {
        type: 'vertical',
        items: [
            {
                title: 'Тип',
                value: meter?.communicatorModel ? meter.communicatorModel : noDataText,
                type: 'text',
            },
            {
                title: 'Заводской номер',
                value: meter?.communicatorSerialNum
                    ? meter.communicatorSerialNum
                    : noDataText,
                type: 'text',
            },
            {
                title: 'IMEI',
                value: information?.imei ? information.imei : noDataText,
                type: 'text',
            },
            {
                title: 'Оператор связи',
                value: information?.operator ? information.operator : noDataText,
                type: 'text',
            },
            {
                title: 'IMSI',
                value: information?.imsi ? information.imsi : noDataText,
                type: 'text',
            },
            {
                title: 'MSISDN',
                value: information?.msisdn ? information.msisdn : noDataText,
                type: 'text',
            },
            {
                title: 'ICC ID',
                value: information?.iccId ? information.iccId : noDataText,
                type: 'text',
            },
            {
                title: 'IP-адрес сим-карты',
                value: meter?.ipAddress ? meter.ipAddress : noDataText,
                type: 'text',
            },
            {
                title: 'Порт',
                value: meter?.ipPort ? meter.ipPort : noDataText,
                type: 'text',
            },
            {
                title: 'Версия ПО радиомодема',
                value: meter?.communicationFirmwareVersion
                    ? meter.communicationFirmwareVersion
                    : noDataText,
                type: 'text',
            },
            {
                title: 'Дата последнего опроса',
                value: `${transformLocalTimeMeter(
                    information &&
            information.lastMeterCheck &&
            new Date(information.lastMeterCheck)
                        ? new Date(information.lastMeterCheck)
                        : new Date(),
                    meter?.timeZone
                ).format(DateFormats.dateTimeFormat)} (${meterTimeZone})`,
                type: 'text',
            },
            {
                title: '',
                value: '',
                type: 'text',
            },
            {
                title: '',
                value: '',
                type: 'text',
            },
            {
                title: '',
                value: '',
                type: 'text',
            },
            {
                title: '',
                value: '',
                type: 'text',
            },
            {
                title: 'Дата получения значений',
                value: `${transformLocalTimeMeter(
                    m2m && m2m.receivedAt && new Date(m2m.receivedAt)
                        ? new Date(m2m.receivedAt)
                        : new Date(),
                    meter?.timeZone
                ).format(DateFormats.dateTimeFormat)} (${meterTimeZone})`,
                type: 'raw',
                actionIcon: 'refresh',
                onActionClick: refreshHandler,
                isVisible: true,
                disabled: !(information && information.msisdn),
            },
            {
                title: 'PDP контекст',
                value: m2m && m2m.pdpContext ? m2m.pdpContext : noDataText,
                type: 'text',
            },
            {
                title: 'Дата последней сессии GPRS',
                value: latestGprsDate,
                type: 'text',
            },
        ],
    };

    const meterTransformerBlock: BlockProps = {
        type: 'vertical',
        items: [
            {
                title: 'Коэффициент трансформации по току',
                value: meter?.cTRatio ?? noDataText,
                type: 'text',
            },
            {
                title: 'Коэффициент трансформации по напряжению',
                value: meter?.pTRatio ?? noDataText,
                type: 'text',
            },
        ],
    };

    const meterSecurityBlock: BlockProps = {
        type: 'vertical',
        items: [
            {
                title: 'Пароль пользователь',
                value: noDataText,
                type: 'password',
                passwordType: PasswordTypes.UserPassword,
                entityId: meter.id,
            },
            {
                title: 'Пароль администратор',
                value: noDataText,
                type: 'password',
                passwordType: PasswordTypes.AdminPassword,
                entityId: meter.id,
            },
            {
                title: 'Пароль 3',
                value: noDataText,
                type: 'password',
                passwordType: PasswordTypes.Password3,
                entityId: meter.id,
            },
        ],
    };

    const meterCommunicatorSecurityBlock: BlockProps = {
        type: 'vertical',
        items: [
            {
                title: 'Пароль 1',
                value: noDataText,
                type: 'password',
                passwordType: PasswordTypes.CommunicatorPassword1,
                entityId: meter.id,
            },
            {
                title: 'Пароль 2',
                value: noDataText,
                type: 'password',
                passwordType: PasswordTypes.CommunicatorPassword2,
                entityId: meter.id,
            },
        ],
    };

    const meterElectricityTransformerBlock: BlockProps = {
        type: 'vertical',
        items: [
            {
                title: 'Коэффициент трансформации по току',
                value: meter?.cTRatio ? meter.cTRatio : noDataText,
                type: 'text',
            },
            {
                title: 'Коэффициент трансформации по напряжению',
                value: meter?.pTRatio ? meter.pTRatio : noDataText,
                type: 'text',
            },
        ],
    };

    const showDataOrLoader = (elementToShow: ReactNode, ...data: any[]) => {
        if (data.some((dataItem) => dataItem === undefined)) {
            return (
                <Box
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Loader visible={true} />
                </Box>
            );
        }

        if (data.every((dataItem) => dataItem === null)) {
            return (
                <Box textAlign="center">
                    <Typography variant="caption">{noDataText}</Typography>
                </Box>
            );
        }

        return elementToShow;
    };

    return (
        <>
            <Grid spacing={2} container>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <ConditionView
                            visible={
                                uiConfig.infoBlocks[MeterInformationBlocks.Info] ||
                uiConfig.infoBlocks[MeterInformationBlocks.RelayStatus]
                            }
                        >
                            <Grid item xs={12} sm={6} lg={4} xl={3}>
                                <Wrapper className={classes.wrapperBlock}>
                                    {showDataOrLoader(<Block {...meterTitleBlock} />, meter)}
                                </Wrapper>
                            </Grid>
                        </ConditionView>
                        <ConditionView
                            visible={uiConfig.infoBlocks[MeterInformationBlocks.Scheme]}
                        >
                            <Grid item xs={12} sm={6} lg={4} xl={3}>
                                <Wrapper className={classes.wrapperBlock}>
                                    {showDataOrLoader(
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Block {...meterSchemeBlock} />
                                            </Grid>
                                        </Grid>,
                                        meterScheme
                                    )}
                                </Wrapper>
                            </Grid>
                        </ConditionView>
                        <ConditionView
                            visible={uiConfig.infoBlocks[MeterInformationBlocks.Profile]}
                        >
                            <Grid item xs={12} sm={6} lg={4} xl={3}>
                                <Wrapper className={classes.wrapperBlock}>
                                    {showDataOrLoader(
                                        <Block {...meterProfileBlock} />,
                                        latestProfile
                                    )}
                                </Wrapper>
                            </Grid>
                        </ConditionView>
                    </Grid>
                </Grid>
                <ConditionView
                    visible={
                        uiConfig.infoBlocks[MeterInformationBlocks.Meter] ||
            uiConfig.infoBlocks[MeterInformationBlocks.Communicator] ||
            uiConfig.infoBlocks[MeterInformationBlocks.Tariff] ||
            uiConfig.infoBlocks[MeterInformationBlocks.Security] ||
            uiConfig.infoBlocks[MeterInformationBlocks.CommunicatorSecurity] ||
            uiConfig.infoBlocks[MeterInformationBlocks.ElectricitySecurity]
                    }
                >
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <ConditionView
                                visible={uiConfig.infoBlocks[MeterInformationBlocks.Meter]}
                            >
                                <Grid item xs={12} sm={6} lg={4} xl={3}>
                                    <Wrapper
                                        className={classes.wrapperBlock}
                                        title="ПРИБОР УЧЕТА"
                                    >
                                        {showDataOrLoader(<Block {...meterInfoBlock} />, meter)}
                                    </Wrapper>
                                </Grid>
                            </ConditionView>
                            <ConditionView
                                visible={
                                    uiConfig.infoBlocks[MeterInformationBlocks.Communicator]
                                }
                            >
                                <Grid item xs={12} sm={6} lg={4} xl={3}>
                                    <Wrapper
                                        className={classes.wrapperBlock}
                                        title="КОММУНИКАТОР"
                                    >
                                        {showDataOrLoader(
                                            <Block {...meterCommunicatorBlock} />,
                                            meter,
                                            information
                                        )}
                                    </Wrapper>
                                </Grid>
                            </ConditionView>
                            <ConditionView
                                visible={uiConfig.infoBlocks[MeterInformationBlocks.Tariff]}
                            >
                                <Grid item xs={12} sm={6} lg={4} xl={3}>
                                    <Wrapper
                                        className={classes.wrapperBlock}
                                        title="ТАРИФНОЕ РАСПИСАНИЕ"
                                    >
                                        {meterTariffScheduleResponse &&
                    meterTariffScheduleResponse?.currentMeterTariffSchedule ? (
                                                <MeterTariffScheduleWrapper
                                                    response={meterTariffScheduleResponse}
                                                />
                                            ) : (
                                                '–'
                                            )}
                                    </Wrapper>
                                </Grid>
                            </ConditionView>

                            <ConditionView
                                visible={
                                    uiConfig.infoBlocks[MeterInformationBlocks.Transformer]
                                }
                            >
                                <Grid item xs={12} sm={6} lg={4} xl={3}>
                                    <Wrapper
                                        className={classes.wrapperBlock}
                                        title="ТРАНСФОРМАТОРЫ"
                                    >
                                        {showDataOrLoader(
                                            <Block {...meterTransformerBlock} />,
                                            meter
                                        )}
                                    </Wrapper>
                                </Grid>
                            </ConditionView>

                            <ConditionView
                                visible={
                                    uiConfig.infoBlocks[MeterInformationBlocks.Security] ||
                  uiConfig.infoBlocks[
                      MeterInformationBlocks.CommunicatorSecurity
                  ] ||
                  uiConfig.infoBlocks[
                      MeterInformationBlocks.ElectricitySecurity
                  ]
                                }
                            >
                                <Grid item xs={12} sm={6} lg={12} xl={3}>
                                    <Grid container spacing={2}>
                                        <ConditionView
                                            visible={
                                                uiConfig.infoBlocks[MeterInformationBlocks.Security]
                                            }
                                        >
                                            <Grid item xs={12} lg={4} xl={12}>
                                                <Wrapper
                                                    className={classes.wrapperBlock}
                                                    title="БЕЗОПАСНОСТЬ. ПРИБОР УЧЕТА"
                                                >
                                                    {showDataOrLoader(
                                                        <Block {...meterSecurityBlock} />,
                                                        meter
                                                    )}
                                                </Wrapper>
                                            </Grid>
                                        </ConditionView>
                                        <ConditionView
                                            visible={
                                                uiConfig.infoBlocks[
                                                    MeterInformationBlocks.CommunicatorSecurity
                                                ]
                                            }
                                        >
                                            <Grid item xs={12} lg={4} xl={12}>
                                                <Wrapper
                                                    className={classes.wrapperBlock}
                                                    title="БЕЗОПАСНОСТЬ. КОММУНИКАТОР"
                                                >
                                                    {showDataOrLoader(
                                                        <Block {...meterCommunicatorSecurityBlock} />,
                                                        meter
                                                    )}
                                                </Wrapper>
                                            </Grid>
                                        </ConditionView>
                                        <ConditionView
                                            visible={
                                                uiConfig.infoBlocks[
                                                    MeterInformationBlocks.ElectricitySecurity
                                                ]
                                            }
                                        >
                                            <Grid item xs={12} lg={4} xl={12}>
                                                <Wrapper
                                                    className={classes.wrapperBlock}
                                                    title="ТРАНСФОРМАТОР ТОКА"
                                                >
                                                    {showDataOrLoader(
                                                        <Block {...meterElectricityTransformerBlock} />,
                                                        meter
                                                    )}
                                                </Wrapper>
                                            </Grid>
                                        </ConditionView>
                                    </Grid>
                                </Grid>
                            </ConditionView>
                        </Grid>
                    </Grid>
                </ConditionView>
            </Grid>
            <InspectionLogPanel>
                <InspectionLog meterId={meterId} />
            </InspectionLogPanel>
        </>
    );
};
