import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../shared/components/button';
import { HookField } from '../../../../shared/components/hookField';
import { transformLabelValueTypeToMultiSelectSuggestion } from '../../../../shared/components/multiSelect/helpers';
import { Table } from '../../../../shared/components/table';
import { VisualizationSource } from '../../../../shared/components/table/model';
import { Wrapper } from '../../../../shared/components/wrapper';
import { defaults, Tables } from '../../../../shared/constants';
import { meterConsumptionElectricityTariffs } from '../../../../shared/constants/enums';
import { transformEndDay, transformStartDay } from '../../../../shared/utils/dates';
import { IStateProps } from './model';
import { useCommonTableStyles } from '../../style';

const ownStyles = makeStyles(() => ({
    actions: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    actionsLeft: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
        flex: 1,
    },
    field: {
        minWidth: 200,
    },
    buttonField: {
        minWidth: 100,
    },
    multiselectField: {
        minWidth: 220,
    },
    form: {
        width: '100%',
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const {
        data,
        form,
        onSubmit,
        onClickExcelExport,
        columns,
        isLoading,
    } = props;

    const classes = useCommonTableStyles();
    const ownClasses = ownStyles();

    if (!data) {
        return null;
    }

    const filter = (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={ownClasses.form}>
                <Grid container spacing={2}>
                    <Grid item xl={1} md={1} xs={1} className={ownClasses.field}>
                        <HookField
                            fullWidth
                            name='dateFrom'
                            type='datepicker'
                            label='Начало'
                            transform={{
                                output: transformStartDay,
                            }}
                        />
                    </Grid>
                    <Grid item xl={1} md={1} xs={1} className={ownClasses.field}>
                        <HookField
                            fullWidth
                            name='dateTo'
                            type='datepicker'
                            label='Конец'
                            transform={{
                                output: transformEndDay,
                            }}
                        />
                    </Grid>
                    <Grid item xl={2} md={2} xs={2} className={ownClasses.multiselectField}>
                        <HookField
                            fullWidth
                            name='params'
                            type='multiselect'
                            label='Показатель'
                            suggestions={transformLabelValueTypeToMultiSelectSuggestion(meterConsumptionElectricityTariffs)}
                        />
                    </Grid>
                    <Grid item xl={1} md={1} xs={1} className={ownClasses.buttonField}>
                        <Button fullWidth variant='primary' type='submit'>Показать</Button>
                    </Grid>
                    <Grid item xl={2} md={2} xs={2} className={ownClasses.field}>
                        <Button onClick={onClickExcelExport} fullWidth variant='outline' type='button' icon='excel'>Выгрузить в Excel</Button>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );

    const actions = (
        <Box className={ownClasses.actions}>
            <Box className={ownClasses.actionsLeft}>
                {filter}
            </Box>
        </Box>

    );

    return (
        <Wrapper
            actions={actions}
            className={classes.wrapper}
            isHeaderUppercase={false}
            isBigHeader
            headerColor='white'
        >
            {!isLoading && (
                <Table
                    visualizationSource={VisualizationSource.clientProfile}
                    name={Tables.meterConsumptionElectricityTable}
                    columns={columns}
                    data={data}
                    title={'Потребление'}
                    variant='white'
                    useQueryString={true}
                    options={{
                        headerClassName: classes.tableCols,
                        showTitle: false,
                        toolbar: false,
                        paging: 'disabled',
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        search: false,
                    }}
                    fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                />
            )}
        </Wrapper>
    );
};
