import { Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { InputProps } from './model';
import { Modal } from '../../../../../shared/components/modal';
import { Button } from '../../../../../shared/components/button';

const useStyles = makeStyles((theme) => ({
    title: {
        ...theme.typography.h2,
        color: theme.palette.text.primary,
    },
    subTitle: {
        ...theme.typography.body,
        color: theme.palette.neutral.main,
    },
    mb8: {
        marginBottom: 8,
    },
    modal: {
        '& .modal-content .modal-body': {
            padding: '0 24px 24px !important',
        },
        '& .modal-content .modal-header': {
            padding: '12px !important',
        },
    },
}));

export const View: React.FC<InputProps> = (props) => {

    const {
        onCancel,
        onDelete,
        isLoading,
    } = props;

    const classes = useStyles();

    return (
        <>
            <Modal
                className={classes.modal}
                onHide={onCancel}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className={classNames(classes.title, classes.mb8)}>
                            Удаление
                        </div>
                        <div className={classes.subTitle}>
                            Вы уверены, что хотите удалить настройки подключения к платформе Центр2М?
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    variant='primary'
                                    type='button'
                                    onClick={onDelete}
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                >
                                    Да, удалить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='secondary'
                                    type='button'
                                    onClick={onCancel}
                                    disabled={isLoading}
                                >
                                    Отмена
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};
