import { MeterLabelCategory } from 'common/model/meter/meterLabel/meterLabelCategory';

export const columnNamesByCategory = [
    {
        categoryId: MeterLabelCategory.ActivationErrors,
        label: 'ПУ с ошибками ввода в эксплуатацию',
    },
    {
        categoryId: MeterLabelCategory.RegularReadingErrors,
        label: 'ПУ с ошибками регулярного опроса',
    },
    {
        categoryId: MeterLabelCategory.SuspiciousProfile,
        label: 'Подозрительный профиль потребления',
    },
    {
        categoryId: MeterLabelCategory.ConfigValidation,
        label: 'Ошибки валидации конфигурации и сверки времени',
    },
    {
        categoryId: MeterLabelCategory.CriticalEvents,
        label: 'Критические события в журналах ПУ',
    },
    {
        categoryId: MeterLabelCategory.ConnectionLoss,
        label: 'Потеря связи',
    }
];
