import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { IconType } from '../icon/model';

import { Typography } from '../typography';
import { IStateProps } from './model';
import classNames from 'classnames';
import { Icon } from '../icon';


const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 16px',
        height: '40px',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
        fontFamily: 'SF Pro Display',
        '&:disabled': {
            cursor: 'not-allowed'
        },
        whiteSpace: 'nowrap',
    },
    withIcon: {
        paddingLeft: '12px',
    },
    primary: {
        color: theme.palette.neutral.white,
        backgroundColor: theme.palette.primary.light,
        '&:disabled': {
            opacity: theme.opacity.half,
        },
        '&:hover:enabled': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:active': {
            boxShadow: `inset 0px 0px 8px ${fade(theme.palette.neutral.white, theme.opacity.half)}`,
        }
    },
    secondary: {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.neutral.ultraLight,
        '&:disabled': {
            opacity: theme.opacity.half,
        },
        '&:hover:enabled': {
            backgroundColor: theme.palette.neutral.light,
        },
        '&:active': {
            backgroundColor: theme.palette.neutral.main,
        }
    },
    outline: {
        color: theme.palette.primary.light,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.primary.light}`,
        '&:disabled': {
            opacity: '50%',
        },
        '&:hover:enabled': {
            color: theme.palette.neutral.white,
            backgroundColor: theme.palette.primary.light,
        },
        '&:active': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    text: {
        color: theme.palette.primary.light,
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        '&:disabled': {
            opacity: theme.opacity.half,
        },
        '&:hover:enabled': {
            border: `1px solid ${theme.palette.primary.light}`
        },
        '&:active': {
            backgroundColor: theme.palette.primary.main,
            boxShadow: `inset 0px 0px 9px ${fade(theme.palette.primary.light, theme.opacity.quarter)}`,
        }
    },
    square: {
        padding: '8px',
    },
    fullWidth: {
        width: '100%',
    },
    iconWithText: {
        marginRight: '8px',
    },
    '@keyframes rotateLoading': {
        'from': {
            transform: 'rotate(0)',
        },
        'to': {
            transform: 'rotate(360deg)',
        }
    },
    loading: {
        '& .MuiSvgIcon-root': {
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationDuration: '2s',
            animationName: '$rotateLoading',
        },
    },
}));

export const View = ({
    variant,
    icon: propsIcon,
    onClick,
    children,
    square,
    className,
    fullWidth,
    type,
    disabled,
    iconHeight,
    iconWidth,
    isLoading,
}: IStateProps) => {

    const [isWaiting, setIsWaiting] = useState(false);
    const classes = useStyles();

    const icon: IconType = isLoading ? 'loader' : propsIcon;

    const onClickHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!onClick || isWaiting) {
            return;
        }
        setIsWaiting(true);
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await onClick(event);
        setIsWaiting(false);
    };

    if (square) {
        return (
            <button
                disabled={disabled}
                className={classNames(className, classes.button, classes[variant], classes.square)}
                onClick={onClickHandler}
            >
                <Icon name={icon} width={iconWidth} height={iconHeight} />
            </button>
        );
    }

    return (
        <button
            className={
                classNames(
                    className,
                    classes.button,
                    classes[variant],
                    {
                        [classes.withIcon]: !!icon,
                        [classes.fullWidth]: fullWidth,
                        [classes.loading]: isLoading,
                    }
                )
            }
            onClick={onClickHandler}
            type={type ?? 'button'}
            disabled={disabled}
            tabIndex={0}
        >
            {icon && <Icon className={classes.iconWithText} name={icon} width={iconWidth} height={iconHeight} />}
            <Typography variant='button' component='span'>{children}</Typography>
        </button>
    );
};
