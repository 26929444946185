import { push } from 'connected-react-router';
import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useBackUrl } from '../../hooks/useBackUrl';
import { Typography } from '../typography';
import { StateProps } from './model';
import { Button } from '../button';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        height: 40,
        letterSpacing: '0.01em',
    },
    wrapperIcon: {
        marginRight: '16px',
    },
    heading: {
        color: theme.palette.text.primary,
    },
    note: {
        color: theme.palette.text.hint,
    }
}));

export const View = ({ heading, onBackHandler, note, defaultBackUrl }: StateProps) => {
    const classes = useStyles();
    const backUrl = useBackUrl();
    const dispatch = useDispatch();
    const onClickBack = () => {
        const currentBackUrl = backUrl
            ? backUrl
            : defaultBackUrl
                ? { pathname: defaultBackUrl }
                : null;
        if (onBackHandler) {
            onBackHandler(currentBackUrl);
        } else if (currentBackUrl) {
            dispatch(push(currentBackUrl));
        }
    };
    const showBackButton = Boolean(onBackHandler) || Boolean(backUrl) || Boolean(defaultBackUrl);
    return (
        <div className={classes.root}>
            {
                showBackButton && <Button
                    onClick={onClickBack}
                    variant='outline'
                    icon='arrowBigLeft'
                    className={classes.wrapperIcon}
                    square
                />
            }
            <Box display='flex' alignItems='baseline'>
                <Box mr={2} marginRight='0'>
                    <Typography variant="h1" className={classes.heading}>
                        {heading}
                    </Typography>
                </Box>
                {Boolean(note) &&
                    <Typography variant='body' className={classes.note}>
                        {note}
                    </Typography>}
            </Box>
        </div>
    );
};
