import { MeterActionSchemeViewModel } from '../../../../common/model/meter/meter-action-scheme/meterActionSchemeViewModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterSchemeChangeRequestViewModel } from '../../../../common/model/meter/meter/meterSchemeChangeRequestViewModel';


export interface MeterSchemesListState {
    available: MeterActionSchemeViewModel[];
    currentSchemeChangeRequest: MeterSchemeChangeRequestViewModel;
}

const initialState: MeterSchemesListState = {
    available: null,
    currentSchemeChangeRequest: null,
};

export const meterSchemesListSlice = createSlice({
    name: '@@vv/meter-schemes/list',
    initialState,
    reducers: {
        setAvailableSchemes(state, action: PayloadAction<MeterActionSchemeViewModel[]>): void {
            state.available = action.payload;
        },
        setCurrentSchemeChangeRequest(state, action: PayloadAction<MeterSchemeChangeRequestViewModel>): void {
            state.currentSchemeChangeRequest = action.payload;
        }
    },
});
