import { regionsSlice } from '../../reducers/resources/regions';
import { AppThunk } from '../index';
import { httpService } from '../../../app/shared/httpWrapper';
import { appUrls } from '../../../common/appUrls';
import { ResourcesViewModel } from '../../../common/model/resources';
import { dictionariesSlice } from '../../reducers/resources/dictionaries';
import { resourcesSettingsSlice } from '../../reducers/resources/settings';

export const getResources = (): AppThunk => async dispatch => {
    const resources = await httpService.get<ResourcesViewModel>(
        appUrls.api_get_resources
    );
    if (resources && resources.dictionaries) {
        dispatch(dictionariesSlice.actions.update(resources.dictionaries));
    }
    if (resources && resources.settings) {
        dispatch(resourcesSettingsSlice.actions.update(resources.settings));
    }
    if (resources && resources.regions) {
        dispatch(regionsSlice.actions.update(resources.regions));
    }
};

const setVersion = (version: number): AppThunk => dispatch => {
    dispatch(resourcesSettingsSlice.actions.updateVersion(version));
};


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const checkVersion = (version: number): AppThunk => async (dispatch, getState) => {
    const currentVersion = getState().resources?.settings?.version;
    if (!currentVersion) {
        dispatch(setVersion(version));
    } else if (version > currentVersion) {
        dispatch(setVersion(version));
        await dispatch(getResources());
    }
};
