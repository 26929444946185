import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessRule } from '../../../common/model/access/accessRule';
import { BaseDispatch } from '../../../redux/actions';
import { availableAccessRulesSelector } from '../../../redux/selectors/access/accessRules';
import { Routes } from '../../shared/constants';

export const AccessRuleResolver = (accessRule: AccessRule) => (viewComponent: React.FC<any>) => {
    return (props: any) => {
        const dispatch = useDispatch<BaseDispatch>();
        const availableAccessRules = useSelector(availableAccessRulesSelector());
        const isPageAvailable = availableAccessRules.includes(accessRule);

        React.useEffect(() => {
            if (!isPageAvailable) {
                dispatch(push(Routes.empty));
            }
        }, [isPageAvailable]);

        if (!isPageAvailable) {
            return null;
        }

        return React.createElement(viewComponent, props);
    };
};
