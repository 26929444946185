import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserViewModel } from '../../../../common/model/access/user/userViewModel';


export interface UserEditableState {
    user: UserViewModel;
}

const initialState: UserEditableState = {
    user: null,
};

export const userEditableSlice = createSlice({
    name: '@vv/users/editable',
    initialState,
    reducers: {
        setEditableUser(state, action: PayloadAction<UserViewModel>) {
            state.user = action.payload;
        },
        clearEditableUser(state) {
            state.user = null;
        }
    }
});
