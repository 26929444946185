import { FileImportViewModel } from '../../../../../common/model/meter/fileImportViewModel';
import { httpService, HttpWrapperOptions } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MessageResponse } from '../../../common/model/messageResponse';
import { Import8002080040Payload } from '../../../common/model/meter/import/import8002080040';
import { ImportMeterActivationOrdersPayload } from '../../../common/model/meter/import/importMeterActivationOrders';

const waitTimeoutConnection = 100000;

export const postImportDocument8002080040 = async (
    payload: Import8002080040Payload
): Promise<FileImportViewModel> => {
    const route = getApiRoute(appUrls.api_post_import_80020_80040);
    const data = await httpService.post<FileImportViewModel>(route, payload);
    return data;
};

export const postImportMeterActivationOrder = async (
    payload: ImportMeterActivationOrdersPayload
): Promise<MessageResponse> => {
    const route = getApiRoute(appUrls.api_external_meter_activation_order_from_excel);
    const data = await httpService
        .post<MessageResponse>(route, payload, new HttpWrapperOptions().Timeout(waitTimeoutConnection));
    return data;
};
