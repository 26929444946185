import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Tables } from 'app/shared/constants';
import { Table } from 'app/shared/components/table';
import { ImportCrqLogTableViewProps } from './model';
import { columns } from './columns';
import { setColumnWidth } from 'app/shared/pipes/styles';
import { setOrderTransformer } from 'app/shared/components/pagination/helpers';
import { Pagination } from 'app/shared/components/pagination';

const useStyles = makeStyles(() => ({
    tableCols: {
        '&.col-rowNumber': {
            ...setColumnWidth('100px'),
        },
        '&.col-meterModel': {
            ...setColumnWidth('100px'),
        },
        '&.col-meterSerialNum': {
            ...setColumnWidth('150px'),
        },
        '&.col-errorMessage': {
            ...setColumnWidth('200px'),
        },
    },
}));

export const View: React.FC<ImportCrqLogTableViewProps> = ({
    data,
    setOrder,
    orderParams,
    pagination,
    dataTotal,
    setPage,
}) => {
    const classes = useStyles();

    return (
        <>
            <Table
                data={data || []}
                columns={columns}
                title="ОШИБКИ"
                name={Tables.importCrqLogTable}
                options={{
                    headerClassName: classes.tableCols,
                    grouping: false,
                    filtering: false,
                    search: false,
                    toolbar: false,
                    showTitle: false,
                    paging: 'disabled',
                }}
                onOrderChange={setOrderTransformer(columns, setOrder)}
                {...orderParams}
            />
            <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={!data} />
        </>
    );
};
