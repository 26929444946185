import { Column } from '@softmedialab/materialui-table';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormats } from '../../../common/constants/date';
import {
    InspectionLogEntryViewModel
} from '../../../common/model/inspectionLog/inspectionLogEntryViewModel/inspectionLogEntryViewModel';
import { InspectionLogQuery } from '../../../common/model/inspectionLog/inspectionLogQuery';
import { OrderTypeDictionary } from '../../../common/model/inspectionLog/OrderType';
import { InspectionSeverityTypeDictionary } from '../../../common/model/inspectionLog/SeverityType';
import { logSeverityDictionary } from '../../../common/model/logbook/logEntrySeverity';
import { MeterActionType } from '../../../common/model/meter/meterActionType';
import { ISort } from '../../../common/sort';
import { BaseDispatch } from '../../../redux/actions';
import { createSnackbar } from '../../../redux/actions/controls';
import {
    addLogToInspectionLogs,
    findInspectionLogs, loadInspectionLogsToEndAndIsFinal,
    loadInspectionLogsToStartAndIsFinal
} from '../../../redux/actions/inspectionLogs';
import { commonInspectionLogSlice } from '../../../redux/reducers/inspectionLogs/common';
import { resourcesSettingsSelector } from '../../../redux/selectors';
import {
    commonInspectionLogAvailableSelector,
    commonInspectionLogIsFilterVisibleSelector,
    commonInspectionLogLastSelector
} from '../../../redux/selectors/inspectionLogs';
import { TableTitle } from '../../shared/components/tableTitle';
import { WebSocketContext, WSMessage } from '../../shared/contexts/webSocket';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { getCurrentDate, transformLocalTimeMeter } from '../../shared/utils/dates';
import { SeverityColumn } from './components/severity';
import { InspectionLogFilterTypes, InspectionLogTableColumns, Props, StateProps } from './model';
import { View } from './view';

const fetchDebounceTimeMS = 1000;

const isValidDate = (d: any): boolean => {
    return d instanceof Date && !isNaN(d.getTime());
};
const loadChunkSize = 100;

const defaultIsDumpVisible = true;
const defaultMeterActionTypeDefaults: MeterActionType[] = Object.keys(MeterActionType)
    .map(item => MeterActionType[item]);
const defaultVisibleFilters: InspectionLogFilterTypes[] = Object.keys(InspectionLogFilterTypes)
    .map(item => InspectionLogFilterTypes[item]);
const defaultVisibleColumns: InspectionLogTableColumns[] = Object.keys(InspectionLogTableColumns)
    .map(item => InspectionLogTableColumns[item]);

export const InspectionLog: React.FC<Props> = (parentProps) => {
    const dispatch = useDispatch<BaseDispatch>();
    const ws = useContext(WebSocketContext);
    const data = useSelector(commonInspectionLogAvailableSelector);
    const last = useSelector(commonInspectionLogLastSelector);
    const settings = useSelector(resourcesSettingsSelector());
    const isFilterVisible = useSelector(commonInspectionLogIsFilterVisibleSelector);
    const isFilteredBySeverity = false;
    const [isReceivingPaused, setIsReceivingPaused] = useState(false);
    const inMemoryRecordsLimit = settings.inspectionLogsInMemoryMaxLength;

    const {
        gisTaskId,
        orderId,
        orderType,
        meterId,
        meterActionType = [],
    } = parentProps;

    const form = useForm<InspectionLogQuery>({
        defaultValues: {
            orderType,
            meterActionType,
        },
    });

    const formData = form.watch();

    useEffect(() => {
        ws.subscribeOnMessage((msg: WSMessage) => {
            if (msg.type !== 'logs') {
                return;
            }
            if (isReceivingPaused) {
                return;
            }
            dispatch(addLogToInspectionLogs(msg.data, formData, inMemoryRecordsLimit));
        });
    }, [formData]);

    useEffect(() => {
        ws.sendMessage({
            type: 'receive',
            data: {
                meterId,
            }
        }, 'subscribe');
        return () => ws.sendMessage({
            type: 'receive',
            data: {
                meterId,
            }
        }, 'unsubscribe');
    }, [meterId]);

    const fetch = async (sort: ISort, dataFilterParams: InspectionLogQuery) => {
        await dispatch(findInspectionLogs(dataFilterParams, sort));
    };

    const fetchToStart = (sort: ISort, dataFilterParams: InspectionLogQuery) => {
        return dispatch(loadInspectionLogsToStartAndIsFinal(inMemoryRecordsLimit, dataFilterParams, sort));
    };

    const fetchToEnd = (sort: ISort, dataFilterParams: InspectionLogQuery) => {
        return dispatch(loadInspectionLogsToEndAndIsFinal(inMemoryRecordsLimit, dataFilterParams, sort));
    };

    const filterValues = {};

    const pagination = {
        page: 1,
        rowsPerPage: loadChunkSize,
    };

    const orderParams: ISort = {
        count: pagination.rowsPerPage,
        offset: (pagination.page - 1) * pagination.rowsPerPage,
        sortFieldName: 'id',
        asc: false,
    };

    const currentFormData = form.watch();

    const inspectionLogQuery: InspectionLogQuery = {
        gisTaskId,
        orderType,
        orderId,
        meterActionType,
        ...filterValues,
        ...currentFormData,
        minCreatedAt: currentFormData?.minCreatedAt && isValidDate(new Date(currentFormData?.minCreatedAt))
            ? currentFormData.minCreatedAt
            : undefined,
        maxCreatedAt: currentFormData?.maxCreatedAt && isValidDate(new Date(currentFormData?.maxCreatedAt))
            ? currentFormData.maxCreatedAt
            : undefined,
        isMy: currentFormData?.isMy,
        meterId,
    };

    const changesFormData = useDebounce(formData, fetchDebounceTimeMS);

    const refreshTableData = (force?: boolean) => {
        if (!force) {
            return;
        }
        fetch(orderParams, inspectionLogQuery);
    };

    const onInfiniteScrollStart = useCallback(async () => {
        const ids = data.map(item => item.id).filter(id => !!id);
        const firstLogId = ids.length > 0
            ? Math.max(...ids.map(item => item))
            : undefined;
        try {
            const isFinal = await fetchToStart(
                {
                    ...orderParams,
                    asc: true,
                },
                {
                    ...inspectionLogQuery,
                    minId: firstLogId,
                }
            );
            if (isFinal) {
                setIsReceivingPaused(false);
            }
        } catch {
            // DO NOTHING
        }
    }, [data, orderParams, inspectionLogQuery]);

    const onInfiniteScrollEnd = useCallback(async () => {
        setIsReceivingPaused(true);
        const ids = data.map(item => item.id).filter(id => !!id);
        const lastLogId = ids.length > 0
            ? Math.min(...ids.map(item => item))
            : undefined;
        try {
            const isFinal = await fetchToEnd(
                {
                    ...orderParams,
                },
                {
                    ...inspectionLogQuery,
                    maxId: lastLogId,
                }
            );
            if (isFinal) {
                dispatch(
                    createSnackbar(
                        {
                            type: 'white',
                            message: 'Больше данных нет',
                        }
                    )
                );
            }
        } catch {
            // DO NOTHING
        }
    }, [data, orderParams, inspectionLogQuery]);


    const handleToggleVisible = () => {
        dispatch(commonInspectionLogSlice.actions.setFilterVisibility(!isFilterVisible));
        if (!isFilterVisible) {
            refreshTableData(true);
        }
    };

    useEffect(() => {
        refreshTableData(true);
    }, [changesFormData, meterId]);

    const columnDefinition: Column[] = [
        {
            title: useMemo(
                () => <TableTitle icon={isFilteredBySeverity ? 'filterFilled' : undefined}>Severity</TableTitle>,
                [isFilteredBySeverity]
            ),
            field: 'severity',
            cellClassName: 'cell-severity',
            sorting: false,
            lookup: logSeverityDictionary,
            render: (item) => {
                return <SeverityColumn tooltip={InspectionSeverityTypeDictionary[item.severity]} severity={item.severity} />;
            },
        },
        {
            title: 'Дата',
            field: 'createdAt',
            cellClassName: 'cell-createdAt',
            render: (dataItem: InspectionLogEntryViewModel) => transformLocalTimeMeter(
                new Date(dataItem.createdAt)
            ).format(DateFormats.dateFullTimeFormat),
        },
        {
            title: 'Тип опроса',
            field: 'orderType',
            cellClassName: 'cell-orderType',
            lookup: OrderTypeDictionary,
        },
        {
            title: 'Текст сообщения',
            field: 'message',
            cellClassName: 'cell-message',
        },
    ];

    const onClickSetNowMinCreatedAt = () => {
        form.setValue('minCreatedAt', getCurrentDate()?.toISOString());
    };
    const onClickSetNowMaxCreatedAt = () => {
        form.setValue('maxCreatedAt', getCurrentDate()?.toISOString());
    };

    const props: StateProps = {
        ...parentProps,
        visibleFilters: parentProps?.visibleFilters ?? defaultVisibleFilters,
        isDumpVisible: parentProps?.isDumpVisible ?? defaultIsDumpVisible,
        meterActionType: parentProps?.meterActionType ?? defaultMeterActionTypeDefaults,
        visibleColumns: parentProps?.visibleColumns ?? defaultVisibleColumns,
        columnDefinition,
        data,
        last: last ?? null,
        form,
        isFilterVisible,
        onClick: handleToggleVisible,
        onClickSetNowMinCreatedAt,
        onClickSetNowMaxCreatedAt,
        onInfiniteScrollStart: async () => {
            await onInfiniteScrollStart();
        },
        onInfiniteScrollEnd: async () => {
            await onInfiniteScrollEnd();
        },
    };

    return View(props);
};
