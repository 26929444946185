import React from 'react';
import { MeterChangePasswordRequests } from './meterChangePasswordRequests';
import { IStateProps } from './model';
import { TabMenu } from '../../shared/components/tabMenu';
import { TabMenuItem } from '../../shared/components/tabMenuItem';
import { Box } from '@material-ui/core';
import { AdministrationTabs } from '../../shared/constants';
import { AccessPage } from './access';
import { MeterActionSchemeList } from './scheme';
import { SettingsList } from './settings';
import { GroupsPage } from './groups';
import { TariffSchedules } from './tariffSchedules';
import { TimeSynchronizationSchemes } from './timeSynchronizationSchemes';
import { UsersList } from './users';
import { Audit } from './audit';
import { Dictionaries } from './dictionaries';
import { Heading } from '../../shared/components/heading';


export const View = ({
    activeTab,
    onChangeTab,
    tabs,
}: IStateProps) => {

    return (
        <div className='administration-page' style={{ marginTop: '-8px' }}>
            <Box display="flex" alignItems="center" my={1}>
                <Heading heading="Администрирование" />
            </Box>

            <TabMenu value={activeTab || AdministrationTabs.Schemes} onChange={onChangeTab}>
                {
                    tabs
                        .filter(item => item.isHidden === false)
                        .map(tab =>
                            <TabMenuItem
                                label={tab.title}
                                key={tab.key}
                                value={tab.key}
                            />
                        )
                }
            </TabMenu>

            <Box py={3}>
                { activeTab === AdministrationTabs.Schemes && <MeterActionSchemeList /> }
                { activeTab === AdministrationTabs.TariffSchedules && <TariffSchedules /> }
                { activeTab === AdministrationTabs.Access && <AccessPage /> }
                { activeTab === AdministrationTabs.Users && <UsersList /> }
                { activeTab === AdministrationTabs.Settings && <SettingsList /> }
                { activeTab === AdministrationTabs.Groups && <GroupsPage /> }
                { activeTab === AdministrationTabs.Audit && <Audit /> }
                { activeTab === AdministrationTabs.Dictionaries && <Dictionaries />}
                { activeTab === AdministrationTabs.TimeSynchronizationSchemes && <TimeSynchronizationSchemes />}
                { activeTab === AdministrationTabs.ChangePasswordRequests && <MeterChangePasswordRequests />}
            </Box>
        </div>
    );
};
