export enum MeterPurposeType {
    ODPU = "ОДПУ",
    IGF = "ИЖФ",
    IPU = "ИПУ",
}

export const meterPurposeTypeDictionary: Record<MeterPurposeType, string> = {
    [MeterPurposeType.ODPU]: MeterPurposeType.ODPU,
    [MeterPurposeType.IGF]: MeterPurposeType.IGF,
    [MeterPurposeType.IPU]: MeterPurposeType.IPU,
};
