import { makeStyles, Theme } from '@material-ui/core';
import { setColumnWidth } from '../../shared/pipes/styles';

export const useCommonTableStyles = makeStyles((theme: Theme) => ({
    actions: {
        display: 'flex',
    },
    actionsHeader: {
        padding: '8px',
        width: '200px',
    },
    actionsFilter: {
        [theme.breakpoints.up('md')]: {
            flex: 1,
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    wrapper: {
        display: 'flex',
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
        },
        backgroundColor: theme.palette.neutral.background,
    },
    tableCols: {
        '&.col-time': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('306px'),
        },
        '&.col-tariff-view': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('320px'),
        },
    }
}));
