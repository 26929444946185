import { timeZoneDictionary } from '../../../common/constants/timeZone';
import { OrderTypeDictionary } from '../../../common/model/inspectionLog/OrderType';
import { InspectionSeverityTypeDictionary } from '../../../common/model/inspectionLog/SeverityType';
import { logEntryOperationDictionary } from '../../../common/model/logbook/logEntryOperation';
import { logSeverityDictionary } from '../../../common/model/logbook/logEntrySeverity';
import { logSourceDictionary } from '../../../common/model/logbook/logEntrySource';
import { logEntryTypeDictionary } from '../../../common/model/logbook/logEntryType';
import { exportDocumentFormatDictionary } from '../../../common/model/meter/export/export80020';
import { userStatusDictionary } from '../../../common/model/access/user/userStatus';
import { connectionTypeDictionary } from '../../../common/model/meter/connectionType';
import { electricityConsumptionTariffTypeDictionary } from '../../../common/model/meter/consumption/meterConsumptionElectricityTariffParams';
import { meterConsumptionIntervalDictionary } from '../../../common/model/meter/consumption/meterConsumptionInterval';
import { meterAccountTypeDictionary } from '../../../common/model/meter/meterAccountType';
import { meterLegalTypeDictionary } from '../../../common/model/meter/legalEntityType';
import { meterActionPlanStatusDictionary } from '../../../common/model/meter/meterActionPlanStatus';
import { MeterActionTypeDictionary } from '../../../common/model/meter/meterActionType';
import { meterModelDictionary } from '../../../common/model/meter/meterModel';
import { meterPowerSwitchOrderStatusDictionary } from '../../../common/model/meter/meterPowerSwitch/meterPowerSwitchOrderStatus';
import { meterPurposeTypeDictionary } from '../../../common/model/meter/meterPurposeType';
import { priceCategoriesDictionary } from '../../../common/model/meter/priceCategory';
import { meterStatusDictionary } from '../../../common/model/meter/meterStatus';
import { meterProfileReportIntervalDictionary } from '../../../common/model/meter/reports/meterProfileReport';
import { meterConfigurationParamsDictionary } from '../../../common/model/meter/meterConfigurationHistory/meterConfigurationHistoryParams';
import { VAutocompleteOption } from '../components/autocomplete/model';
import { meterActivationOrderStatusDictionary } from '../../../common/model/meter/meterActivationOrder/meterActivationOrderStatus';
import { meterRelayStatusDictionary, RelayStatus } from '../../../common/model/meter/relayStatus';
import { meterFileImportStatusDictionary } from 'common/model/meter/importCrq/meterFileImportStatus';

export interface LabelValueType {
    label: string;
    value: string | number;
    optionLabel?: string;
}

export const connectionTypes: LabelValueType[] = Object.entries(connectionTypeDictionary)
    .map(([value, label]) => ({ label, value }));
export const meterModels: VAutocompleteOption[] = Object.entries(meterModelDictionary)
    .map(([value, label]) => ({ label, value, optionsLabel: label }));
export const meterRelayOnStatuses: LabelValueType[] = [
    {
        label: 'Включено',
        value: 'true'
    },
    {
        label: 'Выключено',
        value: 'false'
    }
];
export const meterRelayStatuses: LabelValueType[] = [
    {
        label: meterRelayStatusDictionary[RelayStatus.Off],
        value: RelayStatus.Off,
        optionLabel: meterRelayStatusDictionary[RelayStatus.Off],
    },
    {
        label: meterRelayStatusDictionary[RelayStatus.On],
        value: RelayStatus.On,
        optionLabel: meterRelayStatusDictionary[RelayStatus.On],
    },
    {
        label: meterRelayStatusDictionary[RelayStatus.Enabled],
        value: RelayStatus.Enabled,
        optionLabel: meterRelayStatusDictionary[RelayStatus.Enabled],
    }
];
export const accountTypes: LabelValueType[] = Object.entries(meterAccountTypeDictionary)
    .map(([value, label]) => ({ label, value }));
export const purposeTypes: LabelValueType[] = Object.entries(meterPurposeTypeDictionary)
    .map(([value, label]) => ({ label, value }));
export const legalTypes: LabelValueType[] = Object.entries(meterLegalTypeDictionary)
    .map(([value, label]) => ({ label, value }));
export const priceCategories: LabelValueType[] = Object.entries(priceCategoriesDictionary)
    .map(([value, label]) => ({ label, value }));
export const meterStatuses: LabelValueType[] = Object.entries(meterStatusDictionary)
    .map(([value, label]) => ({ label, value }));
export const meterConsumptionIntervals: LabelValueType[] = Object.entries(meterConsumptionIntervalDictionary)
    .map(([value, label]) => ({ label, value }));
export const meterConsumptionElectricityTariffs: LabelValueType[] = Object.entries(electricityConsumptionTariffTypeDictionary)
    .map(([value, label]) => ({ label, value }));
export const userStatuses: LabelValueType[] = Object.entries(userStatusDictionary)
    .map(([value, label]) => ({ label, value }));
export const meterProfileReportIntervals: LabelValueType[] = Object.entries(meterProfileReportIntervalDictionary)
    .map(([value, label]) => ({ label, value }));
export const meterLegalTypes: LabelValueType[] = Object.entries(meterLegalTypeDictionary)
    .map(([value, label]) => ({ label, value }));
export const MeterConfigurationHistoryParams: LabelValueType[] = Object.entries(meterConfigurationParamsDictionary)
    .map(([value, label]) => ({ label, value }));
export const ExportDocumentFormats: LabelValueType[] = Object.entries(exportDocumentFormatDictionary)
    .map(([value, label]) => ({ label, value }));
export const meterPowerSwitchOrderStatuses: LabelValueType[] = Object.entries(meterPowerSwitchOrderStatusDictionary)
    .map(([value, label]) => ({ label, value }));
export const timeZoneTypes: LabelValueType[] = Object.entries(timeZoneDictionary)
    .map(([value, label]) => ({ label, value }))
    .map(item => ({
        ...item,
        value: +item.value,
    }))
    .sort((a, b) => a.value > b.value ? 1 : -1);
export const activationOrderStatuses: LabelValueType[] = Object.entries(meterActivationOrderStatusDictionary)
    .map(([value, label]) => ({ label, value }));


export const logSeverities: LabelValueType[] = Object.entries(logSeverityDictionary)
    .map(([value, label]) => ({ label, value }));
export const logSources: LabelValueType[] = Object.entries(logSourceDictionary)
    .map(([value, label]) => ({ label, value }));
export const logEntryTypes: LabelValueType[] = Object.entries(logEntryTypeDictionary)
    .map(([value, label]) => ({ label, value }));
export const logEntryOperations: LabelValueType[] = Object.entries(logEntryOperationDictionary)
    .map(([value, label]) => ({ label, value }));
export const meterFileImportStatuses: LabelValueType[] = Object.entries(meterFileImportStatusDictionary)
    .map(([value, label]) => ({ label, value }));

export const inspectionOrderTypes: LabelValueType[] = Object.entries(OrderTypeDictionary)
    .map(([value, label]) => ({ label, value }));

export const inspectionSeverityTypes: LabelValueType[] = Object.entries(InspectionSeverityTypeDictionary)
    .map(([value, label]) => ({ label, value }));

export const meterActionPlanStatusTypes: LabelValueType[] = Object.entries(meterActionPlanStatusDictionary)
    .map(([value, label]) => ({ label, value }));

export const meterActionTypeTypes: LabelValueType[] = Object.entries(MeterActionTypeDictionary)
    .map(([value, label]) => ({ label, value: +value }));

export const mapLabelValueTypeToVAutocompleteOption = (item: LabelValueType): VAutocompleteOption => {
    return {
        value: item.value,
        label: item.label,
        optionsLabel: item.label,
    };
};

export const mapLabelValueTypeToVAutocompleteOptions = (items: LabelValueType[]): VAutocompleteOption[] => {
    return items.map(mapLabelValueTypeToVAutocompleteOption);
};

export const tariffCountSuggestions: LabelValueType[] = [
    {
        label: '1',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
];
