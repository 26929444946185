import { meterActivationOrdersListSlice, MeterActivationOrdersListState } from './list';
import { meterActivationOrderDetailsSlice, MeterActivationOrderDetailsState } from './details';
import { combineReducers } from 'redux';


export interface MeterActivationOrdersState {
    list: MeterActivationOrdersListState;
    details: MeterActivationOrderDetailsState;
}

export const meterActivationOrders = combineReducers<MeterActivationOrdersState>({
    list: meterActivationOrdersListSlice.reducer,
    details: meterActivationOrderDetailsSlice.reducer,
});
