import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Heading } from '../../shared/components/heading';
import { Pagination } from '../../shared/components/pagination';
import { setOrderTransformer } from '../../shared/components/pagination/helpers';
import { Wrapper } from '../../shared/components/wrapper';
import { SearchForm } from './form';
import { SearchProps } from './model';
import './style.scss';
import { Checkbox } from '../../shared/components/checkbox';
import { Typography } from '../../shared/components/typography';

const useStyles = makeStyles((theme) => ({
    count: {
        ...theme.typography.body,
        color: theme.palette.text.hint,
        paddingLeft: 10,
    },
    resultText: {
        ...theme.typography.h2,
        color: theme.palette.text.primary,
    },
    tableWrapper: ({ isShowAll }: { isShowAll?: boolean }) => ({
        '& .custom-table.white-table .MuiTableCell-head ': {
            ...theme.typography.caption,
        },
        '& .custom-table': {
            '& button': {
                visibility: isShowAll ? 'visible' : 'hidden',
            },
            '& .MuiTableRow-root': {
                '&:hover': {
                    '& button': {
                        visibility: 'visible',
                    },
                }
            },
        },
    }),
}));

export const View = (props: SearchProps) => {
    const {
        columnDefinition,
        data,
        form,
        setPage,
        setOrder,
        onSubmit,
        onCancel,
        isLoading,
        isShowAll,
        dataTotal,
        pagination,
        orderParams,
        isShowResult,
        onClickShowAll,
        addressFilterDefaults,
    } = props;
    const classes = useStyles({ isShowAll });
    const tableActions =
        <Grid container alignItems='center' justify='space-between'>
            <Grid item>
                <Typography variant='h2' className={classes.resultText}>Результаты поиска:</Typography>
                <Typography variant='body' className={classes.count}>Найдено {dataTotal} ПУ</Typography>
            </Grid>
            <Grid item>
                <Checkbox checked={isShowAll} onChange={onClickShowAll} label='Показывать все поля' />
            </Grid>
        </Grid>;
    return (
        <>
            <Box mb={2}>
                <Heading heading='Расширенный поиск ПУ' />
            </Box>
            <Wrapper className={classes.tableWrapper}>
                <SearchForm
                    form={form}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    columnDefinition={columnDefinition}
                    data={data}
                    isShowResult={isShowResult}
                    tableActions={tableActions}
                    addressFilterDefaults={addressFilterDefaults}
                    tableProps={{
                        options: {
                            paging: 'disabled'
                        },
                        onOrderChange: setOrderTransformer(columnDefinition, setOrder),
                        ...orderParams,
                    }}
                />
                <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={isLoading || !data} />
            </Wrapper>
        </>
    );
};
