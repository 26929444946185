export enum ElectricityTariffConsumptionType {
    CumulativeActiveEnergyExp = 'cumulativeActiveEnergyExp',
    CumulativeActiveEnergyImp = 'cumulativeActiveEnergyImp',
    CumulativeActiveEnergyTZ1 = 'cumulativeActiveEnergyTZ1',
    CumulativeActiveEnergyTZ2 = 'cumulativeActiveEnergyTZ2',
    CumulativeActiveEnergyTZ3 = 'cumulativeActiveEnergyTZ3',
    CumulativeReactiveEnergyExp = 'cumulativeReactiveEnergyExp',
    CumulativeReactiveEnergyImp = 'cumulativeReactiveEnergyImp',
}

export interface MeterConsumptionElectricityTariffParams {
    dateFrom: Date;
    dateTo: Date;
    parameters: string;
}

const meterConsumptionElectricityTariffParamsSplitter = ',';

export const parseMeterElectricityParamTypes = (params: string): ElectricityTariffConsumptionType[] => {
    if (!params) {
        return [];
    }
    return params.split(meterConsumptionElectricityTariffParamsSplitter).map(item => item as ElectricityTariffConsumptionType);
};

export const transformMeterElectricityParamTypes = (params: ElectricityTariffConsumptionType[]): string => {
    return params.join(meterConsumptionElectricityTariffParamsSplitter);
};

export const electricityConsumptionTariffTypeDictionary: Record<ElectricityTariffConsumptionType, string> = {
    [ElectricityTariffConsumptionType.CumulativeActiveEnergyExp]: 'A-',
    [ElectricityTariffConsumptionType.CumulativeActiveEnergyImp]: 'A+',
    [ElectricityTariffConsumptionType.CumulativeActiveEnergyTZ1]: 'T1',
    [ElectricityTariffConsumptionType.CumulativeActiveEnergyTZ2]: 'T2',
    [ElectricityTariffConsumptionType.CumulativeActiveEnergyTZ3]: 'T3',
    [ElectricityTariffConsumptionType.CumulativeReactiveEnergyExp]: 'R-',
    [ElectricityTariffConsumptionType.CumulativeReactiveEnergyImp]: 'R+',
};
