// Тип включения
export enum ConnectionType {
	A = 'A', // трёхфазный косвенного включения
	B = 'B', // трёхфазный полукосвенного включения
	C = 'C', // трёхфазный прямого включения
	D = 'D', // однофазный
}

export const connectionTypeDictionary: Record<ConnectionType, string> = {
	[ConnectionType.A]: 'трёхфазный косвенного включения',
	[ConnectionType.B]: 'трёхфазный полукосвенного включения',
	[ConnectionType.C]: 'трёхфазный прямого включения',
	[ConnectionType.D]: 'однофазный',
};
