import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../../shared/components/button';
import { Tooltip } from '../../../../../shared/components/tooltip';
import { HookField } from '../../../../../shared/components/hookField';
import { Modal } from '../../../../../shared/components/modal';
import { Typography } from '../../../../../shared/components/typography';
import { useUpdateFromAttributesStyles } from '../../../../meter/actions/styles';
import { IStateProps } from './model';

const useStyles = makeStyles(() => ({
    modal: {
        '& .modal-content .modal-body': {
            padding: '24px 24px 24px !important',
        },
    },
}));

export const View: React.FC<IStateProps> = (props) => {

    const {
        form,
        check,
        onCancel,
        onSubmit,
        isLoading,
        isChecking,
        checkResult,
        networkOperator,
    } = props;

    const classes = useStyles();
    const formClasses = useUpdateFromAttributesStyles();

    const isOld = !!networkOperator?.id;

    return (
        <>
            <Modal
                className={classes.modal}
                size='md'
                onHide={onCancel}
                title={isOld ? networkOperator.name : 'Новая платформа'}
            >
                <FormProvider { ...form }>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {isOld && (
                                <Grid item xs={12}>
                                    <Grid container spacing={3} alignItems='center'>
                                        <Grid item xs={3} className={formClasses.inputLabel}>
                                            <Typography variant='bodyBold'>Идентификатор</Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <HookField
                                                type='text'
                                                name='id'
                                                label='Идентификатор'
                                                disabled
                                                fullWidth
                                                isErrorTextAbsolute={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={3} className={formClasses.inputLabel}>
                                        <Typography variant='bodyBold'>Название</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <HookField
                                            type='text'
                                            name='name'
                                            label='Введите название'
                                            fullWidth
                                            disabled={isLoading}
                                            isErrorTextAbsolute={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={3} className={formClasses.inputLabel}>
                                        <Typography variant='bodyBold'>Сервер</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <HookField
                                            fullWidth
                                            isErrorTextAbsolute={false}
                                            type='text'
                                            name='server'
                                            disabled={isLoading}
                                            label='Укажите сервер'
                                            placeholder='http[s]://адрес сервера m2m'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={3} className={formClasses.inputLabel}>
                                        <Typography variant='bodyBold'>Логин</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <HookField
                                            type='text'
                                            name='login'
                                            label='Логин'
                                            fullWidth
                                            isErrorTextAbsolute={false}
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={3} className={formClasses.inputLabel}>
                                        <Typography variant='bodyBold'>Пароль</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <HookField
                                            type='password'
                                            name='password'
                                            label='Пароль'
                                            fullWidth
                                            isErrorTextAbsolute={false}
                                            isShowHidePasswordVisible={true}
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify='space-between' className={formClasses.buttonContainer}>
                                    <Grid item>
                                        <div>
                                            {checkResult && (
                                                <Tooltip text={checkResult?.message ?? 'Соединение с сервером М2М успешно установлено'}>
                                                    <Button
                                                        type='button'
                                                        variant='text'
                                                        onClick={check}
                                                        isLoading={isChecking}
                                                        disabled={isChecking || isLoading}
                                                        icon={checkResult.success ? 'success' : 'errorOutline'}
                                                    >
                                                        {checkResult.success ? 'Успешно' : 'Ошибка'}
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            {!checkResult && (
                                                <Tooltip text={'Проверка соединения с сервером М2М'}>
                                                    <Button
                                                        type='button'
                                                        onClick={check}
                                                        isLoading={isChecking}
                                                        disabled={isChecking || isLoading}
                                                        variant='text'
                                                    >
                                                    Проверить
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={3} justify='flex-end'>
                                            <Grid item>
                                                <Button
                                                    type='submit'
                                                    variant='primary'
                                                    disabled={isLoading}
                                                    isLoading={isLoading}
                                                >
                                                    Сохранить
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    type='button'
                                                    onClick={onCancel}
                                                    variant='secondary'
                                                    disabled={isLoading}
                                                >
                                                    Отмена
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </FormProvider>
            </Modal>
        </>
    );
};
