/* eslint-disable @typescript-eslint/no-magic-numbers */
import { DateFormats } from '../../../../../common/constants/date';
import {
    ElectricityTariffReportData,
    ElectricityTariffReportResponse,
} from '../../../../../common/model/meter/reports/electricityTariffReport';
import { sleep } from '../../../../shared/pipes';
import { Alignment, Cell, Row, Workbook, Worksheet } from 'exceljs';
import { formatDate } from '../../../../shared/utils/dates';
import { downloadExcelData } from '../../../../shared/utils/download';
import {
    addBordersToCell,
    addRowSpaceToWorksheet,
    excelBaseFontName,
} from '../../excelHelpers';
import { transformElectricityTariffReportData } from './helper';

const columnWidths: Record<number, number> = {
    1: 18,
    2: 22,
    3: 30,
    4: 18,
    5: 11,
    6: 19,
    7: 19,
    8: 19,
    9: 19,
    10: 19,
    11: 19,
    12: 19,
    13: 19,
    14: 8,
    15: 8,
};

const columnsDataAlignment: Record<number, Alignment['horizontal']> = {
    1: 'left',
    2: 'left',
    3: 'left',
    4: 'left',
    5: 'left',
    6: 'center',
    7: 'center',
    8: 'center',
    9: 'center',
    10: 'center',
    11: 'center',
    12: 'right',
    13: 'right',
    14: 'right',
    15: 'right',
    16: 'center',
    17: 'right',
    18: 'right',
    19: 'right',
    20: 'center',
    21: 'center',
    22: 'center',
    23: 'center',
    24: 'center',
};

export interface MakeExcelReportConfig {
  response: ElectricityTariffReportResponse;
  filename: string;
  reportDate: Date;
}

const makeColumns = (worksheet: Worksheet) => {
    for (const col in columnWidths) {
        worksheet.getColumn(+col).width = +columnWidths[col];
    }
};

const dataToTableCells = (data: ElectricityTariffReportData): string[] => {
    return [
        data.areaName ?? '',
        data.townName ?? '',
        data.streetName ?? '',
        data.houseNumber ?? '',
        data.addressSpecifier ?? '',
        data.meterSerialNum ?? '',
        data.code ?? '',
        data.entityType ?? '',
        data.actionScheme ?? '',
        data.ipPort ? data.ipPort.toString() : '',
        data.tariff ?? '',
        data.cumulativeActiveEnergyTZ1?.toString() ?? '',
        data.cumulativeActiveEnergyTZ2?.toString() ?? '',
        data.cumulativeActiveEnergyTZ3?.toString() ?? '',
        data.cumulativeActiveEnergyImp?.toString() ?? '',
        data.cumulativeActiveEnergyExp?.toString() ?? '',
        data.dateString ?? '',
        data.ipAddress ?? '',
        data.timeString ?? '',
        data.timeZoneString ?? '',
        data.meterPurpose ?? '',
        data.meterModel ?? '',
        data.installationSite ?? '',
        data.firmware ?? ''
    ];
};

const makeMainTitle = (worksheet: Worksheet, title: string) => {
    worksheet.addRow([title]);
    worksheet.getCell('A1').style.font = {
        size: 12,
        name: excelBaseFontName,
        bold: true,
    };
    worksheet.mergeCells('A1:L1');
};

const makeAnnotationRow = (
    worksheet: Worksheet,
    title: string,
    renderValue: (row: Row, valueCell: Cell) => void
) => {
    const regionNameRow = addRowSpaceToWorksheet(worksheet);
    const regionLabelCellStart = regionNameRow.getCell(1);
    regionLabelCellStart.value = title;
    regionLabelCellStart.style.font = {
        bold: true,
        size: 12,
        name: excelBaseFontName,
    };
    regionLabelCellStart.style.alignment = {
        horizontal: 'left',
    };
    const regionLabelCellEnd = regionNameRow.getCell(2);
    worksheet.mergeCells(
        `${regionLabelCellStart.address}:${regionLabelCellEnd.address}`
    );

    const regionValueCellStart = regionNameRow.getCell(3);
    regionValueCellStart.style.font = {
        size: 12,
        name: excelBaseFontName,
    };
    renderValue(regionNameRow, regionValueCellStart);
};

const makeProfilePage = (
    workbook: Workbook,
    config: MakeExcelReportConfig
): void => {
    const { response, reportDate } = config;
    const { data, date, ppObjectName, regionName, meterGroupName } = response;
    const worksheet = workbook.addWorksheet('Главная');
    makeColumns(worksheet);
    makeMainTitle(
        worksheet,
        'Показания  электроэнергии за расчетный период по тарифам'
    );

    makeAnnotationRow(worksheet, 'Наименование региона:', (row, cell) => {
        cell.value = regionName;
    });

    makeAnnotationRow(worksheet, 'Наименование группы ПУ:', (row, cell) => {
        cell.value = meterGroupName;
    });

    makeAnnotationRow(worksheet, 'Наименование объекта РР:', (row, cell) => {
        cell.value = ppObjectName;
    });

    makeAnnotationRow(worksheet, 'Показания на дату:', (row, cell) => {
        cell.value = formatDate(date, DateFormats.dateTimeZoneFormat);
        addBordersToCell(cell);
        cell.style.font = {
            bold: true,
            name: excelBaseFontName,
            size: 12,
        };
    });

    makeAnnotationRow(
        worksheet,
        'Дата и время формирования отчета:',
        (row, cell) => {
            cell.value = formatDate(reportDate, DateFormats.dateTimeZoneFormat);
            addBordersToCell(cell);
            cell.style.font = {
                bold: true,
                name: excelBaseFontName,
                size: 12,
            };
        }
    );

    const excelMainTableTitles: string[] = [
        'Район',
        'Наименование\nнаселенного пункта',
        'Название улицы',
        '№, дома',
        '№, квартиры',
        'Заводской номер ПУ ',
        'Код АСКУЭРР',
        'Правовая форма владельца',
        'Схема опроса',
        'port',
        'Тарифное расписание ПУ',
        'Показания по первому тарифу (Т1), кВт*ч',
        'Показания по второму тарифу (Т2), кВт*ч',
        'Показания по третьему тарифу (Т3), кВт*ч',
        'Показания (прием) счетчика сумма тарифов, кВт*ч',
        'Показания (отдача)сумма тарифов, кВт*ч',
        'Дата последних показаний',
        'IP',
        'Время снятия показаний',
        'Часовой пояс',
        'Назначение прибора',
        'Тип прибора учёта',
        'Место установки',
        'Версия прошивки'
    ];

    const titlesRow = addRowSpaceToWorksheet(worksheet);
    for (let i = 1; i <= excelMainTableTitles.length; i++) {
        const titleCell = titlesRow.getCell(i);
        titleCell.value = excelMainTableTitles[i - 1];
        titleCell.style.font = {
            bold: true,
            name: excelBaseFontName,
            size: 11,
        };
        titleCell.style.alignment = {
            wrapText: true,
            horizontal: 'center',
        };
        addBordersToCell(titleCell);
    }

    const mainTableData: ElectricityTariffReportData[] = data.map((item) =>
        transformElectricityTariffReportData(item)
    );
    for (const mainTableDatum of mainTableData) {
        const row = worksheet.addRow(dataToTableCells(mainTableDatum));
        row.eachCell((cell, index) => {
            cell.style.font = {
                size: 8,
                name: excelBaseFontName,
            };
            cell.style.alignment = {
                horizontal: columnsDataAlignment[index] ?? 'left',
            };
            addBordersToCell(cell);
        });
    }
};

export const makeExcelReport = async (config: MakeExcelReportConfig) => {
    const workbook = new Workbook();
    makeProfilePage(workbook, config);
    await sleep(10);
    const buffer = ((await workbook.xlsx.writeBuffer()) as unknown) as Buffer;
    const excelData = buffer.toString('base64');

    downloadExcelData(excelData, config.filename);
};

/* eslint-enable @typescript-eslint/no-magic-numbers */
