import { combineReducers } from 'redux';
import { reducer as formReducer, FormStateMap } from 'redux-form';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { access, AccessState } from './access';
import { addresses, AddressesState } from './addresses';
import { eventLogs, EventLogsState } from './eventLogs';
import { inspectionLogs, InspectionLogsState } from './inspectionLogs';
import { massOperations, MassOperationsState } from './massOperations';
import { meterChangePasswordOrders, MeterChangePasswordOrdersState } from './meterChangePasswordOrders';
import { meterChangePasswordRequests, MeterChangePasswordRequestsState } from './meterChangePasswordRequests';
import { meterFirmwareVersions, MeterFirmwareVersionsState } from './meterFirmwareVersions';
import { meterGroup, MeterGroupState } from './meterGroup';
import { meterModels, MeterModelsState } from './meterModels';
import { meterPowerSwitchOrders, MeterPowerSwitchOrdersState } from './meterPowerSwitchOrders';
import { meterSchemes, MeterSchemesState } from './meterSchemes';
import { monitoring, MonitoringState } from './monitoring';
import { networkOperators, NetworkOperatorsState } from './networkOperators';
import { partners, PartnersState } from './partners';
import { reports, ReportsState } from './reports';
import { settingsSlice, SettingsState } from './settings';
import { specialDayBase, SpecialDayBaseState } from './specialDayBase';
import { tariffSchedule, TariffScheduleState } from './tariffSchedule';
import { timeSynchronizationSchemes, TimeSynchronizationSchemesState } from './timeSynchronizationSchemes';
import { users, UsersState } from './users';
import { controls, ControlsState } from './controls';
import { httpWrapper, HttpWrapperState } from './httpWrapper';
import { resources, ResourcesState } from './resources';
import { meters, MetersState } from './meters';
import { meterReading, MeterReadingState } from './meterReading';
import { meterActivationOrders, MeterActivationOrdersState } from './meterActivationOrders';
import { meterGisTasks, MeterGisTasks } from './meterGisTasks';
import { audit, AuditState } from './audit';
import { dictionaries, Dictionaries } from './dictionaries';
import { meterLabelType, MeterLabelTypesState } from './meterLabelType';
import { crqSystem, CrqSystemsState } from './crqSystem';
import { eventTypes, EventTypesState } from './eventTypes';
import { meterImportCrq, MeterImportCrqState } from './meterImportCrq';
import { meterPowerSwitchCountersSlice, MeterPowerSwitchCountersState } from './meterPowerSwitchCounters';

export interface StorageState {
    users: UsersState;
    controls: ControlsState;
    httpWrapper: HttpWrapperState;
    form: FormStateMap;
    router: RouterState;
    resources: ResourcesState;
    meters: MetersState;
    meterReading: MeterReadingState;
    meterActivationOrders: MeterActivationOrdersState;
    access: AccessState;
    meterSchemes: MeterSchemesState;
    eventLogs: EventLogsState;
    inspectionLogs: InspectionLogsState;
    settings: SettingsState;
    reports: ReportsState;
    addresses: AddressesState;
    monitoring: MonitoringState;
    meterGroup: MeterGroupState;
    meterLabelType: MeterLabelTypesState
    meterPowerSwitchOrders: MeterPowerSwitchOrdersState;
    partners: PartnersState;
    meterGisTasks: MeterGisTasks;
    audit: AuditState;
    dictionaries: Dictionaries;
    timeSynchronizationSchemes: TimeSynchronizationSchemesState;
    meterModels: MeterModelsState;
    tariffSchedule: TariffScheduleState;
    specialDayBase: SpecialDayBaseState;
    crqSystem: CrqSystemsState;
    eventTypes: EventTypesState;
    massOperations: MassOperationsState;
    meterFirmwareVersions: MeterFirmwareVersionsState;
    meterImportCrq: MeterImportCrqState;
    networkOperators: NetworkOperatorsState;
    meterChangePasswordRequests: MeterChangePasswordRequestsState;
    meterChangePasswordOrders: MeterChangePasswordOrdersState;
    meterPowerSwitchCounters: MeterPowerSwitchCountersState;
}

export const createRootReducer = (history: History) => combineReducers<StorageState>({
    users,
    controls,
    resources,
    httpWrapper,
    form: formReducer,
    router: connectRouter(history),
    meters,
    meterReading,
    meterLabelType,
    meterActivationOrders,
    access,
    meterSchemes,
    eventLogs,
    inspectionLogs,
    settings: settingsSlice.reducer,
    reports,
    addresses,
    monitoring,
    meterGroup,
    meterPowerSwitchOrders,
    partners,
    meterGisTasks,
    audit,
    dictionaries,
    timeSynchronizationSchemes,
    meterModels,
    tariffSchedule,
    specialDayBase,
    crqSystem,
    eventTypes,
    massOperations,
    meterFirmwareVersions,
    meterImportCrq,
    networkOperators,
    meterChangePasswordRequests,
    meterChangePasswordOrders,
    meterPowerSwitchCounters: meterPowerSwitchCountersSlice.reducer,
});
