import { combineReducers } from 'redux';
import { meterPowerSwitchOrderDetailsSlice, MeterPowerSwitchOrderDetailsState } from './details';
import { meterPowerSwitchOrdersListSlice, MeterPowerSwitchOrdersListState } from './list';

export interface MeterPowerSwitchOrdersState {
    list: MeterPowerSwitchOrdersListState;
    details: MeterPowerSwitchOrderDetailsState;
}

export const meterPowerSwitchOrders = combineReducers<MeterPowerSwitchOrdersState>({
    list: meterPowerSwitchOrdersListSlice.reducer,
    details: meterPowerSwitchOrderDetailsSlice.reducer,
});
