import { push } from 'connected-react-router';
import React, { useEffect, useMemo } from 'react';
import { Column } from '@softmedialab/materialui-table';
import { useDispatch, useSelector } from 'react-redux';
import {
    TimeSynchronizationSchemeViewModel
} from '../../../../common/model/meter/timeSynchronizationScheme/timeSynchronizationSchemeViewModel';
import { BaseDispatch } from '../../../../redux/actions';
import { getTimeSynchronizationSchemes } from '../../../../redux/actions/timeSynchronizationSchemes';
import { timeSynchronizationSchemesListAvailableSelector } from '../../../../redux/selectors/timeSynchronizationSchemes';
import { Button } from '../../../shared/components/button';
import { Checkbox } from '../../../shared/components/checkbox';
import { Routes } from '../../../shared/constants';
import { getRoute } from '../../../shared/pipes';
import { View } from './view';

export const TimeSynchronizationSchemes = () => {

    const dispatch = useDispatch<BaseDispatch>();

    const columns: Column[] = [
        {
            title: 'ИД',
            field: 'id',
            sorting: true,
        },
        {
            title: 'Название',
            field: 'name',
            sorting: true,
        },
        {
            title: useMemo(() => <>Допуск<br />рассинхронизации, сек</>, []),
            field: 'minTimeError',
            sorting: false,
        },
        {
            title: useMemo(() => <>Корректируемое<br />расхождение, сек</>, []),
            field: 'maxTimeError',
            sorting: false,
        },
        {
            title: useMemo(() => <>Шаг корректировки,<br />сек</>, []),
            field: 'timeShift',
            sorting: false,
        },
        {
            title: useMemo(() => <>Принудительная<br />установка разрешена</>, []),
            field: 'flagSetTime',
            sorting: false,
            render: (dataItem: TimeSynchronizationSchemeViewModel) => {
                return <Checkbox dark disabled checked={dataItem.flagSetTime} />;
            },
        },
        {
            title: '',
            field: 'key',
            sorting: false,
            render: (dataItem: TimeSynchronizationSchemeViewModel) => {
                return (
                    <Button
                        type='button'
                        variant='primary'
                        onClick={() => {
                            dispatch(push(getRoute(Routes.timeSynchronizationSchemeDetails, { schemeId: dataItem.id?.toString() })));
                        }}
                    >
                        Подробнее
                    </Button>
                );
            },
        },
    ];
    const data = useSelector(timeSynchronizationSchemesListAvailableSelector) ?? [];

    useEffect(() => {
        dispatch(getTimeSynchronizationSchemes());
    }, []);

    const onClickNew = () => {
        dispatch(push(Routes.timeSynchronizationSchemeNew));
    };

    return View({
        data,
        columns,
        onClickNew,
    });
};
