import { SelectSuggestion } from '../components/select/model';
import { MeterGisTasksStatus } from '../../../common/model/enum/meterGisTasks';

export const statuses: SelectSuggestion[] = [
    {
        value: MeterGisTasksStatus.open,
        label: 'Открыт',
    },
    {
        value: MeterGisTasksStatus.closed,
        label: 'Закрыт',
    },
    {
        value: MeterGisTasksStatus.archived,
        label: 'В архиве',
    },
];
