import { MeterDictionariesNames } from '../../../../common/model/meter/meterDictionaries/meterDictionariesNames';
import { View } from './view';
import { useEffect } from 'react';
import { TaskFormProps } from './model';
import { closeMeterGisTask, updateMeterGisTask } from '../../../../redux/actions/meterGisTasks';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { useForm } from 'react-hook-form';
import { MeterGisTaskUpdater } from '../../../../common/model/meter/meterGisTask/meterGisTaskUpdater';
import { useYupValidationResolver } from '../../../shared/utils/validation';
import yup from '../../../shared/utils/yup';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { getCurrentDate } from '../../../shared/utils/dates';
import { createSnackbar } from '../../../../redux/actions/controls';
import { getErrorMessage } from '../../../shared/utils/error';
import { getMeterDictionaryValues } from '../../../../redux/actions/dictionaries';

const taskValidationSchema = yup.object({
    title: yup.string().required(),
});

export const TaskForm = (props: TaskFormProps) => {
    const { task } = props;
    const resolver = useYupValidationResolver<MeterGisTaskUpdater>(taskValidationSchema);
    const dispatch = useDispatch<BaseDispatch>();
    const form = useForm<MeterGisTaskUpdater>({
        defaultValues: task,
        resolver,
    });

    const watchFormTitle = form.watch().title;

    useEffect(() => {
        dispatch(getMeterDictionaryValues(MeterDictionariesNames.GisTasks));
    }, []);

    useEffect(() => {
        dispatch(getMeterDictionaryValues(MeterDictionariesNames.GisTasks, { search: watchFormTitle }));
        form.reset(task);
    }, [task]);

    const onUpdateSubmit: SubmitHandler<MeterGisTaskUpdater> = (values) => {
        dispatch(updateMeterGisTask({ ...values, id: task.id }));
    };

    const onCancel = () => {
        form.reset(task);
    };


    const saveAndClose = async () => {
        try {
            await dispatch(updateMeterGisTask({ ...form.getValues(), id: task.id, closedAt: getCurrentDate() }));
            dispatch(closeMeterGisTask({ id: task.id }));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
    };

    return View({
        ...props,
        onSubmit: onUpdateSubmit,
        onCancel,
        saveAndClose,
        form,
    });

};
