export enum MeterConfigurationHistoryParams {
    All = 'all',
    MeterModel = 'meterModel',
    MeterFirmwareVersion = 'meterFirmwareVersion',
    cTRatio = 'cTRatio',
    pTRatio = 'pTRatio',
    ManufactureDate = 'manufactureDate',
}

export const meterConfigurationParamsDictionary: Record<MeterConfigurationHistoryParams, string> = {
    [MeterConfigurationHistoryParams.All]: 'Все',
    [MeterConfigurationHistoryParams.MeterModel]: 'Тип ПУ',
    [MeterConfigurationHistoryParams.MeterFirmwareVersion]: 'Версия прошивки (ПО) ПУ',
    [MeterConfigurationHistoryParams.cTRatio]: 'Коэффициент трансформации по току, прошитый в ПУ',
    [MeterConfigurationHistoryParams.pTRatio]: 'Коэффициент трансформации по напряжению, прошитый в ПУ',
    [MeterConfigurationHistoryParams.ManufactureDate]: 'Дата производства ПУ'
}
