import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import { AccessRule } from '../../../../common/model/access/accessRule';
import { accessAccessRulesSlice } from '../../../reducers/access/accessRules';
import { AppThunk, BaseDispatch } from '../../index';

export const setActiveAccessRules = (rules: AccessRule[]): AppThunk => (dispatch): void => {
    dispatch(accessAccessRulesSlice.actions.setAccessRules(rules));
};

export const getActiveAccessRules = (): AppThunk => async (dispatch: BaseDispatch): Promise<void> => {
    const rules = await httpService.get<AccessRule[]>(getApiRoute(appUrls.api_get_access_access_rules));
    await dispatch(setActiveAccessRules(rules));
};
