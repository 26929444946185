import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    MeterChangePasswordRequestCreator
} from '../../../../../common/model/meter/meterChangePasswordRequest/meterChangePasswordRequestCreator';
import { useMeterGroupAutocomplete } from '../../../../shared/components/autocomplete/hooks/useMeterGroupAutocomplete';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import { MeterChangePasswordRequestForm } from '../model';
import { Props } from './model';
import { View } from './view';

const meterChangePasswordRequestCreatorValidationScheme = yup.object({
    regionId: yup.number().required('Регион обязательное поле'),
    meterGroupId: yup.number().required('Группа обязательное поле'),
});

const defaultValues: MeterChangePasswordRequestCreator = {
    regionId: undefined,
    meterGroupId: undefined,
};

export const MeterChangePasswordRequestModal: FC<Props> = (props) => {
    const resolver = useYupValidationResolver<MeterChangePasswordRequestForm>(meterChangePasswordRequestCreatorValidationScheme);

    const form = useForm<MeterChangePasswordRequestCreator>({
        resolver,
        defaultValues,
    });

    const formData = form.watch();

    const meterGroupAutocomplete = useMeterGroupAutocomplete({
        regionId: formData.regionId,
    });

    return View({
        ...props,
        form,
        meterGroupAutocomplete,
    });
};
