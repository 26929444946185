import { MouseEvent } from 'react';
import { MeterUpdateAttributesActions } from '../../../../common/model/meter/meter/meterUpdateAttributesActions';
import { IconType } from '../../../shared/components/icon/model';

export const actionQueryParam = 'action';
export type MeterUpdateAttributeAction = () => void;

export interface PopoverAction {
    key: MeterUpdateAttributesActions;
    name: string;
    icon: IconType;
    action: () => void;
    hasAccess: boolean;
}

export interface Actions {
    handleOpenActionsPopover: (event: MouseEvent) => void;
    handleCloseActionsPopover: () => void;
    handleAction: (action: MeterUpdateAttributeAction) => void;
}

export interface StateProps extends Actions {
    isActionsPopoverOpen: boolean;
    actions: PopoverAction[];
    actionsPopoverAnchor: any;
}
