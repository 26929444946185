import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterGisTasksResponse } from '../../../../common/model/meter/meter/meterGisTasks';
import { MeterGisTaskViewModel } from '../../../../common/model/meter/meterGisTask/meterGisTaskViewModel';
import { MeterGisTasksStatus } from '../../../../common/model/enum/meterGisTasks';
import { HistoryMeterGisTaskViewModel } from '../../../../common/model/meter/historyMeterGisTask/historyMeterGisTaskViewModel';

export interface CommonMeterGisState {
    available: MeterGisTaskViewModel[];
    loading: boolean;
    total: number;
    isFilterVisible: boolean;
    taskById: MeterGisTaskViewModel;
    isSelectedAll: boolean;
    historyGisTasks: Record<string, HistoryMeterGisTaskViewModel[]>;
}

const initialState: CommonMeterGisState = {
    available: [],
    taskById: null,
    loading: false,
    total: 0,
    isFilterVisible: false,
    isSelectedAll: false,
    historyGisTasks: {},
};
export const meterGisTasksSlice = createSlice({
    name: '@@vv/relatedSystems/meterGisTasks',
    initialState,
    reducers: {
        setAvailableMeterGisTasks(state, action: PayloadAction<MeterGisTasksResponse>) {
            state.available = action.payload.data;
            state.total = action.payload.total;
            state.loading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setFilterVisibility(state, action: PayloadAction<boolean>) {
            state.isFilterVisible = action.payload;
        },
        updateAvailableMeterGisTasksAfterCreate(state, action: PayloadAction<MeterGisTaskViewModel>) {
            state.available = [...state.available, action.payload];
            state.total += 1;
        },
        updateTaskById(state, action: PayloadAction<MeterGisTaskViewModel>) {
            state.taskById = action.payload;
        },
        updateGroupTask(state, action: PayloadAction<MeterGisTaskViewModel[]>) {
            state.available = state.available.map(task => {
                const findTask = action.payload.find(t => t.id === task.id);
                if (findTask) {
                    return findTask;
                }
                return task;
            }).filter(task => task.status !== MeterGisTasksStatus.archived);
        },
        resetSelected(state) {
            state.available = state.available.map(item => ({ ...item, selected: false }));
        },
        updateSelectedOneElement(state, action: PayloadAction<MeterGisTaskViewModel>) {
            state.available = state.available.map(element => {
                if (element.id === action.payload.id) {
                    return {
                        ...action.payload,
                        selected: action.payload.selected,
                    };
                }
                return element;
            });
        },
        setHistoryGisTask(state, action: PayloadAction<HistoryMeterGisTaskViewModel[]>) {
            state.historyGisTasks[action.payload[0].id] = action.payload;
        },
    }
});
