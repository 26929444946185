import { MeterLabelName } from '../meterLabel/meterLabelName';

export const meterLabelTypeSuggestionsDictionary: MeterLabelName[] = [
    MeterLabelName.MeterModelError,
    MeterLabelName.MeterFirmwareVersionError,
    MeterLabelName.CTRatioError,
    MeterLabelName.PTRatioError,
    MeterLabelName.ManufactureDate,
    MeterLabelName.CurrentThroughSwitchedOffRelay,
    MeterLabelName.TimeError,
    MeterLabelName.TariffError,
]
