import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    hidden: {
        display: 'none'
    },
    tableHeader: {
        display: 'none'
    },
    scrollableWrapper: {
        height: '230px',
    },
    tableWrapper: {
        borderSpacing: 0,
        '& td': {
            paddingLeft: 8,
            paddingRight: 8,
        },
        '& tr': {
            cursor: 'pointer',
        },
        '& .cell-severity': {
            verticalAlign: 'top',
        },
        '& .cell-createdAt': {
            verticalAlign: 'top',
            whiteSpace: 'nowrap',
        },
        '& .cell-orderType': {
            verticalAlign: 'top',
            minWidth: '265px',
        },
        '& .cell-meters-controller': {
            opacity: '0.7',
        },
        '& tr:hover .cell-createdAt': {
            backgroundColor: theme.palette.text.hint,
            color: theme.palette.neutral.white,
        },
        '& tr:hover .cell-orderType': {
            backgroundColor: theme.palette.text.hint,
            color: theme.palette.neutral.white,
        },
        '& tr:hover .cell-message': {
            backgroundColor: theme.palette.text.hint,
            color: theme.palette.neutral.white,
        },
        '& .cell-empty': {
        },
    },
    dangerRow: {
        color: theme.palette.system.error,
    },
    warningRow: {
        color: '#FF5722',
    },
    headerWrapper: {
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
    },
    rightButton: {
        cursor: 'pointer',
        '&:hover': {
            '& $rightButtonIconHover': {
                opacity: 0,
            },
            '& $rightButtonIconDefault': {
                opacity: 1,
            },
        }
    },
    rightButtonIconWrapper: {
        width: 24,
        height: 24,
        position: 'relative',
    },
    rightButtonIcon: {
        position: 'absolute',
        left: 0,
        right: 0,
    },
    rightButtonIconDefault: {
        opacity: 0,
    },
    rightButtonIconHover: {
        opacity: 1,
    },
    inspectionLogWrapper: {
        width: '100%',
    },
    filterFieldsItem: {
        flex: 1,
    },
    nowActionWrapper: {
        position: 'relative',
    },
    nowAction: {
        ...theme.typography.label,
        color: theme.palette.primary.light,
        cursor: 'pointer',
        position: 'absolute',
        bottom: -8,
        left: 10,
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    lastOperationsTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '75vw',
    },
    lastOperationContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 27,
    },
    firstLogTextView: {
        fontSize: 13,
        lineHeight: 1.3,
        fontWeight: 'normal',
        textTransform: 'none',
        marginLeft: 11,
    },
    cellSeverity: {
        ...theme.typography.caption,
    },
    cellCreatedAt: {
        ...theme.typography.caption,
        width: 160,
    },
    cellOrderType: {
        ...theme.typography.caption,
        width: 200,
    },
    cellMessage: {
        ...theme.typography.caption,
    },
}));
