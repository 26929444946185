import { MeterTabs } from '../../../../app/shared/constants';
import { MeterUpdateAttributesActions } from '../../../../common/model/meter/meter/meterUpdateAttributesActions';
import { MeterViewModel } from '../../../../common/model/meter/meter/meterViewModel';
import { ReadingSource } from '../../../../common/model/meter/readingSource';

export enum MeterInformationBlocks {
    Info = 'info',
    RelayStatus = 'relay-status',
    Scheme = 'scheme',
    Profile = 'profile',
    Alerts = 'alerts',
    Meter = 'meter',
    Communicator = 'communicator',
    Tariff = 'tariff',
    Transformer = 'transformer',
    Security = 'security',
    CommunicatorSecurity = 'communicator-security',
    ElectricitySecurity = 'electricity-security',
}

export enum MeterInformationFields {
    PartnerId = 'partnerId',
    MeterModel = 'meterModel',
    MeterSerialNum = 'meterSerialNum',
    Code = 'code',
    TimeZone = 'timeZone',
    MeterFirmwareVersion = 'meterFirmwareVersion',

    InstallationSite = 'installationSite',
    ConnectionType = 'connectionType',
    AccountType = 'accountType',
    MeterPurpose = 'meterPurpose',
    LegalEntityType = 'legalEntityType',
    PriceCategory = 'priceCategory',
    MeterActionSchemeId = 'meterActionSchemeId',
}

export interface MeterPageUIConfig {
    tabs: Record<MeterTabs, boolean>;
    infoBlocks: Record<MeterInformationBlocks, boolean>;
    actions: Record<MeterUpdateAttributesActions, boolean>;
    informationFields: Record<MeterInformationFields, boolean>
}

export const initialMeterPageUIConfig: MeterPageUIConfig = {
    tabs: {
        [MeterTabs.Information]: true,
        [MeterTabs.Polls]: true,
        [MeterTabs.Incidents]: true,
        [MeterTabs.Reading]: true,
        [MeterTabs.Consumption]: true,
        [MeterTabs.Gis]: true,
        [MeterTabs.Management]: true,
        [MeterTabs.EventLog]: true,
        [MeterTabs.CrqInterface]: true,
        [MeterTabs.Problems]: true,
        [MeterTabs.Requests]: false,
    },
    infoBlocks: {
        [MeterInformationBlocks.Info]: true,
        [MeterInformationBlocks.RelayStatus]: true,
        [MeterInformationBlocks.Scheme]: true,
        [MeterInformationBlocks.Profile]: true,
        [MeterInformationBlocks.Alerts]: true,
        [MeterInformationBlocks.Meter]: true,
        [MeterInformationBlocks.Communicator]: true,
        [MeterInformationBlocks.Tariff]: true,
        [MeterInformationBlocks.Transformer]: false,
        [MeterInformationBlocks.Security]: true,
        [MeterInformationBlocks.CommunicatorSecurity]: true,
        [MeterInformationBlocks.ElectricitySecurity]: true,
    },
    actions: {
        [MeterUpdateAttributesActions.ChangeCommunicationParams]: true,
        [MeterUpdateAttributesActions.ReplacingCommunicator]: true,
        [MeterUpdateAttributesActions.ChangeTransformationRatios]: true,
        [MeterUpdateAttributesActions.ChangeAddress]: true,
        [MeterUpdateAttributesActions.MeterVerification]: true,
        [MeterUpdateAttributesActions.ChangeInformationFields]: true,
        [MeterUpdateAttributesActions.ChangeStatusToActive]: true,
        [MeterUpdateAttributesActions.ChangeStatusToDecommissioned]: true,
        [MeterUpdateAttributesActions.ChangeMeterSyncSchemes]: false,
        [MeterUpdateAttributesActions.ChangeTechnicalParams]: true,
        [MeterUpdateAttributesActions.ChangePasswordFields]: false,
    },
    informationFields: {
        [MeterInformationFields.PartnerId]: false,
        [MeterInformationFields.MeterModel]: false,
        [MeterInformationFields.MeterSerialNum]: false,
        [MeterInformationFields.Code]: false,
        [MeterInformationFields.TimeZone]: false,
        [MeterInformationFields.InstallationSite]: true,
        [MeterInformationFields.ConnectionType]: true,
        [MeterInformationFields.AccountType]: true,
        [MeterInformationFields.MeterPurpose]: true,
        [MeterInformationFields.LegalEntityType]: true,
        [MeterInformationFields.PriceCategory]: true,
        [MeterInformationFields.MeterActionSchemeId]: true,
        [MeterInformationFields.MeterFirmwareVersion]: true,
    },
};

const crqConfig: MeterPageUIConfig = {
    tabs: {
        [MeterTabs.Information]: true,
        [MeterTabs.Polls]: true,
        [MeterTabs.Reading]: true,
        [MeterTabs.Consumption]: true,
        [MeterTabs.Gis]: false,
        [MeterTabs.Management]: false,
        [MeterTabs.EventLog]: true,
        [MeterTabs.Requests]: false,
        [MeterTabs.CrqInterface]: true,
        [MeterTabs.Problems]: true,
        [MeterTabs.Incidents]: true,
    },
    infoBlocks: {
        [MeterInformationBlocks.Info]: true,
        [MeterInformationBlocks.RelayStatus]: true,
        [MeterInformationBlocks.Scheme]: true,
        [MeterInformationBlocks.Profile]: true,
        [MeterInformationBlocks.Alerts]: false,
        [MeterInformationBlocks.Meter]: true,
        [MeterInformationBlocks.Communicator]: false,
        [MeterInformationBlocks.Tariff]: false,
        [MeterInformationBlocks.Transformer]: true,
        [MeterInformationBlocks.Security]: false,
        [MeterInformationBlocks.CommunicatorSecurity]: false,
        [MeterInformationBlocks.ElectricitySecurity]: false,
    },
    actions: {
        [MeterUpdateAttributesActions.ChangeCommunicationParams]: false,
        [MeterUpdateAttributesActions.ReplacingCommunicator]: false,
        [MeterUpdateAttributesActions.ChangeTransformationRatios]: true,
        [MeterUpdateAttributesActions.ChangeAddress]: true,
        [MeterUpdateAttributesActions.MeterVerification]: false,
        [MeterUpdateAttributesActions.ChangeInformationFields]: true,
        [MeterUpdateAttributesActions.ChangeStatusToActive]: true,
        [MeterUpdateAttributesActions.ChangeStatusToDecommissioned]: true,
        [MeterUpdateAttributesActions.ChangeMeterSyncSchemes]: false,
        [MeterUpdateAttributesActions.ChangeTechnicalParams]: false,
        [MeterUpdateAttributesActions.ChangePasswordFields]: false,
    },
    informationFields: {
        [MeterInformationFields.PartnerId]: true,
        [MeterInformationFields.MeterModel]: true,
        [MeterInformationFields.MeterSerialNum]: true,
        [MeterInformationFields.Code]: true,
        [MeterInformationFields.TimeZone]: true,
        [MeterInformationFields.InstallationSite]: true,
        [MeterInformationFields.ConnectionType]: true,
        [MeterInformationFields.AccountType]: true,
        [MeterInformationFields.MeterPurpose]: true,
        [MeterInformationFields.LegalEntityType]: true,
        [MeterInformationFields.PriceCategory]: true,
        [MeterInformationFields.MeterActionSchemeId]: true,
        [MeterInformationFields.MeterFirmwareVersion]: false,
    },
};

const nonReadableMeterPageUIConfig: MeterPageUIConfig = {
    tabs: {
        [MeterTabs.Information]: true,
        [MeterTabs.Polls]: true,
        [MeterTabs.Reading]: false,
        [MeterTabs.Consumption]: true,
        [MeterTabs.Gis]: false,
        [MeterTabs.Management]: false,
        [MeterTabs.EventLog]: false,
        [MeterTabs.Requests]: false,
        [MeterTabs.CrqInterface]: false,
        [MeterTabs.Problems]: true,
        [MeterTabs.Incidents]: true,
    },
    infoBlocks: {
        [MeterInformationBlocks.Info]: true,
        [MeterInformationBlocks.RelayStatus]: false,
        [MeterInformationBlocks.Scheme]: false,
        [MeterInformationBlocks.Profile]: true,
        [MeterInformationBlocks.Alerts]: false,
        [MeterInformationBlocks.Meter]: true,
        [MeterInformationBlocks.Communicator]: false,
        [MeterInformationBlocks.Tariff]: false,
        [MeterInformationBlocks.Transformer]: true,
        [MeterInformationBlocks.Security]: false,
        [MeterInformationBlocks.CommunicatorSecurity]: false,
        [MeterInformationBlocks.ElectricitySecurity]: false,
    },
    actions: {
        [MeterUpdateAttributesActions.ChangeCommunicationParams]: false,
        [MeterUpdateAttributesActions.ReplacingCommunicator]: false,
        [MeterUpdateAttributesActions.ChangeTransformationRatios]: true,
        [MeterUpdateAttributesActions.ChangeAddress]: true,
        [MeterUpdateAttributesActions.MeterVerification]: false,
        [MeterUpdateAttributesActions.ChangeInformationFields]: true,
        [MeterUpdateAttributesActions.ChangeStatusToActive]: true,
        [MeterUpdateAttributesActions.ChangeStatusToDecommissioned]: true,
        [MeterUpdateAttributesActions.ChangeMeterSyncSchemes]: false,
        [MeterUpdateAttributesActions.ChangeTechnicalParams]: false,
        [MeterUpdateAttributesActions.ChangePasswordFields]: false,
    },
    informationFields: {
        [MeterInformationFields.PartnerId]: true,
        [MeterInformationFields.MeterModel]: true,
        [MeterInformationFields.MeterSerialNum]: true,
        [MeterInformationFields.Code]: true,
        [MeterInformationFields.TimeZone]: true,
        [MeterInformationFields.InstallationSite]: false,
        [MeterInformationFields.ConnectionType]: false,
        [MeterInformationFields.AccountType]: false,
        [MeterInformationFields.MeterPurpose]: false,
        [MeterInformationFields.LegalEntityType]: false,
        [MeterInformationFields.PriceCategory]: false,
        [MeterInformationFields.MeterActionSchemeId]: false,
        [MeterInformationFields.MeterFirmwareVersion]: false,
    },
};

export const calculateMeterUIConfig = (meter: MeterViewModel): MeterPageUIConfig => {
    if (meter?.readingSource === ReadingSource.Import80020) {
        return nonReadableMeterPageUIConfig;
    }

    if (meter?.readingSource === ReadingSource.ImportCrq) {
        return crqConfig;
    }
    return initialMeterPageUIConfig;
};
