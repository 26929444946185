import { SelectSuggestion } from 'app/shared/components/select/model';
import { useYupValidationResolver } from 'app/shared/utils/validation';
import yup from 'app/shared/utils/yup';
import { CrqChannelCategory } from 'common/model/meter/crq/crqChannelCategory';
import { CrqChannelCreator } from 'common/model/meter/crq/crqChannelCreator';
import { CrqChannelType } from 'common/model/meter/crq/crqChannelType';
import { UnitOfMeasurement } from 'common/model/meter/crq/unitOfMeasurement';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CrqChannelFormProps } from './model';
import { View } from './view';

const crqChannelTypeSuggestions: SelectSuggestion[] = [
    {
        label: CrqChannelType.B,
        value: CrqChannelType.B,
    }, {
        label: CrqChannelType.J,
        value: CrqChannelType.J,
    }, {
        label: CrqChannelType.E,
        value: CrqChannelType.E,
    }
];

const unitOfMeasurementSuggestions: SelectSuggestion[] = [
    {
        label: UnitOfMeasurement.KVtHour,
        value: UnitOfMeasurement.KVtHour,
    }, {
        label: UnitOfMeasurement.KvarHour,
        value: UnitOfMeasurement.KvarHour,
    },
    {
        label: UnitOfMeasurement.VarHour,
        value: UnitOfMeasurement.VarHour,
    },
    {
        label: UnitOfMeasurement.VtHour,
        value: UnitOfMeasurement.VtHour,
    },
    {
        label: UnitOfMeasurement.Pcs,
        value: UnitOfMeasurement.Pcs,
    },
    {
        label: UnitOfMeasurement.List,
        value: UnitOfMeasurement.List,
    }
];


const validationSchema = yup.object({
    category: yup.string().required('Название категории обязательное поле'),
    number: yup.number().required('Номер канала обязательное поле'),
});


export const CrqChannelsForm: React.FC<CrqChannelFormProps> = ({
    channel,
    crqChannels,
    handleEditChannel,
    handleCloseModal,
    handleAddChannel,
}) => {
    const resolver = useYupValidationResolver<CrqChannelCreator>(validationSchema);

    const defaultValues = channel ?? {};
    const form = useForm<CrqChannelCreator>({ defaultValues, resolver });

    useEffect(() => {
        form.reset(defaultValues);
    }, [channel]);

    const onSubmit = (formData: CrqChannelCreator) => {
        if (channel) {
            handleEditChannel(formData);
        } else {
            handleAddChannel(formData);
        }
        handleCloseModal();
    };

    const categorySuggestions: SelectSuggestion[] = useMemo(() => {
        const categories = Object.values(CrqChannelCategory).map((item) => ({
            label: item,
            value: item,
        }));

        return categories.filter(item => !crqChannels.find(i => i.category === item.value) || item.value === channel?.category);
    }, [crqChannels]);


    return View({
        onSubmit,
        form,
        handleCloseModal,
        categorySuggestions,
        crqChannelTypeSuggestions,
        unitOfMeasurementSuggestions
    });
};
