import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TariffScheduleOrdersResponse } from '../../../../common/model/meter/tariffSchedule/tariffScheduleOrdersResponse';
import { TariffScheduleOrderViewModel } from '../../../../common/model/meter/tariffSchedule/tariffScheduleOrderViewModel';

export interface TariffScheduleOrdersState {
    available: TariffScheduleOrderViewModel[];
    dataTotal: number;
}

const initialState: TariffScheduleOrdersState = {
    available: null,
    dataTotal: null,
};

export const tariffScheduleOrdersSlice = createSlice({
    name: '@@vv/tariffSchedule/orders',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<TariffScheduleOrdersResponse>) {
            state.available = action.payload.data;
            state.dataTotal = action.payload.dataTotal;
        },
    },
});
