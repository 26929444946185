import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Tables } from 'app/shared/constants';
import { Table } from 'app/shared/components/table';
import { ImportCrqTableViewProps } from './model';
import { columns } from './columns';
import { setColumnWidth } from 'app/shared/pipes/styles';
import { MeterFileImportViewModel } from 'common/model/meter/meterFileImport/meterFileImportViewModel';
import { MeterFileImportStatus } from 'common/model/meter/importCrq/meterFileImportStatus';
import { setOrderTransformer } from 'app/shared/components/pagination/helpers';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    tableCols: {
        '&.col-id': {
            ...setColumnWidth('40px'),
        },
        '&.col-fileName': {
            ...setColumnWidth('160px'),
        },
        '&.col-importStatus': {
            ...setColumnWidth('150px'),
        },
        '&.col-createdAt': {
            ...setColumnWidth('160px'),
        },
        '&.col-rowCount': {
            ...setColumnWidth('90px'),
        },
        '&.col-errorCount': {
            ...setColumnWidth('60px'),
        },
        '&.col-btn': {
            ...setColumnWidth('100px'),
        },
    },
    tableRow: {
        '& .meterCrqImport__errorCount--red': {
            color: theme.palette.error.main,
            fontWeight: 600,
        },
        '& .meterCrqImport__status-processed': {
            margin: '-10px 0 8px',
            color: theme.palette.text.hint,
        },
        '& .meterCrqImport__btn': {
            height: 27
        },
        '& p': {
            margin: '8px 0',
        },
        '&:not(:hover)': {
            '& a': {
                visibility: 'hidden',
            },
        },
    },
    tableRowError: {
        backgroundColor: '#FFD2D2 !important',
    }
}));

export const View: React.FC<ImportCrqTableViewProps> = ({ data, isLoading, setOrder, orderParams }) => {
    const classes = useStyles();

    return (
        <Table
            data={data || []}
            columns={columns}
            title={null}
            isLoading={isLoading}
            name={Tables.importCrqMeterTable}
            options={{
                headerClassName: classes.tableCols,
                grouping: false,
                filtering: false,
                search: false,
                toolbar: false,
                showTitle: false,
                paging: 'disabled',
                rowClassName: (rowData: MeterFileImportViewModel) => classNames(classes.tableRow,
                    { [classes.tableRowError]: rowData.importStatus === MeterFileImportStatus.Error })
            }}
            onOrderChange={setOrderTransformer(columns, setOrder)}
            {...orderParams}
        />
    );
};
