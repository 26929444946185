import { Column } from '@softmedialab/materialui-table';
import { CrqSystemViewModel } from 'common/model/meter/crq/crqSystemViewModel';
import { visibleHttps, actionsButtons, renderPassword } from './cell';

export const getCrqSystemsColumns = (
    handleOpenModal: (id?: number)=> void,
    handleRemove: (id?: number)=> void
): Column[] => {
    return [
        {
            title: 'Название',
            field: 'name',
            cellClassName: 'col-name',
            sorting: false,
        },
        {
            title: 'HTTPS',
            field: 'isHttps',
            cellClassName: 'col-https',
            sorting: false,
            render: (data: CrqSystemViewModel) => {
                return visibleHttps(data);
            }
        },
        {
            title: 'Сервер',
            field: 'server',
            cellClassName: 'col-server',
            sorting: false,
        },
        {
            title: 'Порт',
            field: 'port',
            cellClassName: 'col-port',
            sorting: false,
        },
        {
            title: 'Логин',
            field: 'login',
            cellClassName: 'col-login',
            sorting: false,
        },
        {
            title: 'Пароль',
            field: 'password',
            cellClassName: 'col-password',
            sorting: false,
            render: (data: CrqSystemViewModel) => {
                return renderPassword(data);
            }
        },
        {
            title: 'Схема опроса',
            field: 'meterActionSchemeName',
            cellClassName: 'col-scheme',
            sorting: false,
        },
        {
            title: 'Количество потоков',
            field: 'maxTasksCount',
            cellClassName: 'col-maxTasksCount',
            sorting: false,
        },
        {
            title: 'Время отклика',
            field: 'timeoutInSeconds',
            cellClassName: 'col-timeoutInSeconds',
            sorting: false,
        },
        {
            title: 'Действия',
            field: 'id',
            cellClassName: 'col-id',
            sorting: false,
            render: (data: CrqSystemViewModel) => {
                const onEdit = () => handleOpenModal(data.id);
                const onRemove = () => handleRemove(data.id);
                return actionsButtons(onEdit, onRemove);
            },
        }
    ];
};
