import React from 'react';
import { TimePickerProps } from './model';
import { TextField } from '@material-ui/core';


export const View = ({
    label,
    value,
    onChange,
    disabled,
    className,
    fullWidth,
    variant,
    required,
}: TimePickerProps) => (
    <TextField
        variant={variant ?? 'outlined'}
        label={label}
        required={required}
        type='time'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        InputLabelProps={{
            shrink: true,
        }}
        inputProps={{
            step: 300, // 5 min
        }}
        className={className}
        fullWidth={fullWidth}
    />
);
