import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { meterSchemesSelector } from '../../../../../redux/selectors/meterSchemes';

export const useSchemeDefaultRegionCheck = () => {
    const schemes = useSelector(meterSchemesSelector);
    const getSchemesByRegionId = useCallback(
        (regionId: number) => {
            return schemes.filter(item => item.regionId === regionId);
        },
        [schemes],
    );
    return (regionId: number, isDefault: boolean, schemeId?: number) => {
        if (!isDefault) {
            return false;
        }
        const schemesByRegion = getSchemesByRegionId(regionId);
        if (schemeId) {
            return schemesByRegion.filter(item => item.isDefault && item.id !== +schemeId).length > 0;
        } else {
            return schemesByRegion.length > 0;
        }
    };
};
