import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    inputRoot: {
        '& .MuiInputBase-root': {
            paddingRight: 0,
        },
    },
    clearListIcon: {
        position: 'absolute',
        right: 30,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    formControl: {
        minWidth: '120px',
        '& .MuiInputBase-root': {
            paddingRight: 0,
        },
    },
    fullWidth: {
        width: '100%',
    },
    icon: {
        fill: theme.palette.primary.light,
    },
    list: {
        backgroundColor: theme.palette.neutral.white,
        maxWidth: '600px',
    },
    paper: {
        backgroundColor: theme.palette.neutral.white,
    },
    menuItemIcon: {
        marginRight: '12px',
        minWidth: '20px',
    },
    MenuItemRoot: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.neutral.white,

        height: 40,
        maxWidth: '550px',

        '&:hover': {
            backgroundColor: theme.palette.neutral.background,
        },
        '& .MuiListItemIcon-root': {
            minWidth: '20px',
        }
    },
    darkMenuItem: {
        '&.MuiMenuItem-root': {
            backgroundColor: theme.palette.neutral.main,
            color: theme.palette.neutral.white,
        },
        '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
        },
        '& .default-checkbox .MuiSvgIcon-root': {
            color: theme.palette.neutral.white
        }
    },
    darkSelect: {
        color: theme.palette.neutral.light,
        '& .MuiMenu-list': {
            backgroundColor: theme.palette.neutral.main,
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.neutral.white,
        },
        '& .MuiOutlinedInput-root': {
            color: theme.palette.neutral.white,
        },
        '& .MuiOutlinedInput-root fieldset': {
            borderColor: theme.palette.neutral.white,
        },
        '&:hover': {
            '& .MuiOutlinedInput-root fieldset': {
                borderColor: theme.palette.neutral.white,
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.neutral.white,
            },
        }
    },
    isTwoColumns: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxWidth: '300px',

        '& .MuiListItem-button': {
            width: '50%',
        },
    },
    suggestionName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    errorText: {
        color: '#f44336',
        paddingLeft: '12px',
    },
    titleError: {
        color: '#f44336',
    },
}));
