import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { DateFormats } from '../../../../../common/constants/date';
import { OrderType } from '../../../../../common/model/inspectionLog/OrderType';
import { meterPowerSwitchOperationTypeDictionary } from '../../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchOperationType';
import {
    MeterPowerSwitchOrderStatus,
    meterPowerSwitchOrderStatusDictionary
} from '../../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchOrderStatus';
import { meterRelayStatusDictionary } from '../../../../../common/model/meter/relayStatus';
import { roundReadings } from '../../../../../common/shared/utils';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Heading } from '../../../../shared/components/heading';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Block } from '../../../../shared/components/wrapper/block';
import { BlockItemStatus, BlockPropsItem as BlockItem } from '../../../../shared/components/wrapper/block/model';
import { MeterTabs, Routes } from '../../../../shared/constants';
import { getRoute } from '../../../../shared/pipes';
import { formatDate, transformDateToTimeZone } from '../../../../shared/utils/dates';
import { InspectionLog } from '../../../inspectionLog';
import { InspectionLogPanel } from '../../../inspectionLog/components/inspectionLogPanel';
import { InspectionLogFilterTypes, InspectionLogTableColumns } from '../../../inspectionLog/model';
import { MeterSerialNumView } from '../../../reports/components/common/meterSerianNumView';
import { IStateProps } from './model';

const getStatus = (status: MeterPowerSwitchOrderStatus): BlockItemStatus => {
    switch (status) {
        case MeterPowerSwitchOrderStatus.ApprovalRequired:
            return 'notStarted';
        case MeterPowerSwitchOrderStatus.ReadyForWork:
            return 'inProgress';
        case MeterPowerSwitchOrderStatus.SuccessfullyCompleted:
            return 'success';
        default:
            return 'error';
    }
};

const useStyles = makeStyles((theme) => ({
    detailsGroupTitle: {
        ...theme.typography.bodyBold,
        color: theme.palette.text.primary,
    },
    detailsGroupBlock: {
        flexWrap: 'wrap',
    },
    detailsGroupBlockItem: {
        marginBottom: 5,
    },
}));

const formatReadings = (num: number): string => {
    if (num === null || num === undefined) {
        return '–';
    }
    return roundReadings(num).toString();
};

export const View: React.FC<IStateProps> = (props) => {
    const {
        isLoading,
        order,
        breadcrumbs,
        onClickBreadcrumb,
    } = props;

    const classes = useStyles();

    if (isLoading) {
        return null;
    }

    const items: BlockItem[] = [
        {
            title: 'Тип операции',
            value: meterPowerSwitchOperationTypeDictionary[order.operationType],
            type: 'text',
        },
        {
            title: 'Статус обработки',
            value: meterPowerSwitchOrderStatusDictionary[order.status],
            type: 'icon',
            status: getStatus(order.status),
        },
        {
            title: 'Дата создания',
            value: formatDate(order.creationDate, DateFormats.dateTimeFullWithTimeZone),
            type: 'text',
        },
    ];

    const detailItems: BlockItem[] = [
        {
            title: 'Тип прибора учета',
            value: order.meter?.meterModel ?? '–',
            type: 'text',
        },
        {
            title: 'Заводской номер',
            value: order?.meter?.meterSerialNum
                ? <MeterSerialNumView
                    label={order.meter.meterSerialNum}
                    url={getRoute(Routes.meter, { meterId: order.meterId }, { tab: MeterTabs.Information })}
                />
                : '–',
            type: 'text',
        },
        {
            title: 'Код результата операции',
            value: order.resultCode ?? '–',
            type: 'text',
        },
        {
            title: 'Описание результата',
            value: order.resultMessage ?? '–',
            type: 'text',
        },
        {
            title: 'Дата переключения реле по времени ПУ',
            value: order.meterOperationTime
                ? transformDateToTimeZone(order.meterOperationTime, order.meter.timeZone).format(DateFormats.dateTimeFullWithTimeZone)
                : '–',
            type: 'text',
        },
        {
            title: 'Дата переключения реле по времени сервера',
            value: order.operationTime
                ? formatDate(order.operationTime, DateFormats.dateTimeFullWithTimeZone)
                : '–',
            type: 'text',
        },
        {
            title: 'Показания ПУ',
            value: formatReadings(order.cumulativeActiveEnergyImp),
            type: 'text',
        },
        {
            title: <span className={classes.detailsGroupTitle}>Данные до операции</span>,
            value: '',
            type: 'text',
        },
        {
            title: 'Состояние реле',
            value: meterRelayStatusDictionary[order.relayStatusBefore],
            type: 'text',
        },
        {
            title: 'Значение тока. Фаза 1',
            value: formatReadings(order.currentIrBefore),
            type: 'text',
        },
        {
            title: 'Значение тока. Фаза 2',
            value: formatReadings(order.currentIyBefore),
            type: 'text',
        },
        {
            title: 'Значение тока. Фаза 3',
            value: formatReadings(order.currentIbBefore),
            type: 'text',
        },
        {
            title: 'Значение мощности',
            value: formatReadings(order.apparentPowerBefore),
            type: 'text',
        },
        {
            title: <span className={classes.detailsGroupTitle}>Данные после операции</span>,
            value: '',
            type: 'text',
        },
        {
            title: 'Состояние реле',
            value: meterRelayStatusDictionary[order.relayStatusAfter],
            type: 'text',
        },
        {
            title: 'Значение тока. Фаза 1',
            value: formatReadings(order.currentIrAfter),
            type: 'text',
        },
        {
            title: 'Значение тока. Фаза 2',
            value: formatReadings(order.currentIyAfter),
            type: 'text',
        },
        {
            title: 'Значение тока. Фаза 3',
            value: formatReadings(order.currentIbAfter),
            type: 'text',
        },
        {
            title: 'Значение мощности',
            value: formatReadings(order.apparentPowerAfter),
            type: 'text',
        },
    ];

    return (
        <>
            <Box mb={2}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2}>
                <Heading heading='Информация об операции' />
            </Box>
            <Box>
                <Grid container>
                    <Grid item md={8} lg={6} xl={4}>
                        <Box mb={2}>
                            <Wrapper>
                                <Block
                                    className={classes.detailsGroupBlock}
                                    childClassName={classes.detailsGroupBlockItem}
                                    type='horizontal'
                                    items={items}
                                />
                            </Wrapper>
                        </Box>
                        <Box>
                            <Wrapper title='ДЕТАЛИ ОПЕРАЦИИ' isBigHeader>
                                <Block type='vertical' items={detailItems} />
                            </Wrapper>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {order && (
                <InspectionLogPanel>
                    <InspectionLog
                        meterId={order?.meterId}
                        orderId={order?.id}
                        orderType={OrderType.PowerSwitch}
                        visibleFilters={[
                            InspectionLogFilterTypes.Critical,
                            InspectionLogFilterTypes.IsMy,
                            InspectionLogFilterTypes.DateFrom,
                            InspectionLogFilterTypes.DateTo,
                            InspectionLogFilterTypes.Search,
                        ]}
                        visibleColumns={[
                            InspectionLogTableColumns.Critical,
                            InspectionLogTableColumns.Date,
                            InspectionLogTableColumns.Message,
                        ]}
                    />
                </InspectionLogPanel>
            )}
        </>
    );
};
