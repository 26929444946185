import { combineReducers } from 'redux';
import { activeRoleAccessSlice, ActiveRoleAccessState } from './active';
import { rolesListAccessSlice, RolesListAccessState } from './list';

export interface RolesAccessState {
    active: ActiveRoleAccessState;
    list: RolesListAccessState;
}

export const rolesAccessSlice = combineReducers<RolesAccessState>({
    active: activeRoleAccessSlice.reducer,
    list: rolesListAccessSlice.reducer,
});
