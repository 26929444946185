import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Loader } from '../loader';
import { TextInput } from '../textInput';
import { IStateProps, VAutocompleteOption } from './model';
import { VAutocompleteWithCheckbox } from './autocompleteWithCheckbox';
import classNames from 'classnames';
import { Icon } from '../icon';

const useStyles = makeStyles((theme) => ({
    input: {
        '& .MuiAutocomplete-inputRoot': {
            padding: '1px 0 1px 8px',
        },
    },
    darkAutocomplete: {
        color: theme.palette.neutral.white,
        '& .MuiMenu-list': {
            backgroundColor: theme.palette.neutral.main,
            '& .Mui-selected': {
                backgroundColor: theme.palette.text.secondary,
            },
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.neutral.white,
        },
        '& .MuiOutlinedInput-root': {
            color: theme.palette.neutral.white,
        },
        '& .MuiOutlinedInput-root fieldset': {
            borderColor: theme.palette.neutral.white,
        },
        '&:hover': {
            '& .MuiOutlinedInput-root fieldset': {
                borderColor: theme.palette.neutral.white,
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.neutral.white,
            },
        }
    },
    paper: {},
    paperDark: {
        backgroundColor: theme.palette.neutral.main,
        color: theme.palette.neutral.white,

    },
}));

export const View = React.forwardRef((props: IStateProps, ref) => {
    const {
        label,
        value,
        onChange,
        options,
        placeholder,
        required,
        helperText,
        error,
        inputProps,
        withCheckbox,
        dark,
        ...otherProps
    } = props;
    const classes = useStyles();
    const optionValue = options.find(item => item.value === value) ?? null;

    if (withCheckbox) {
        return (
            <VAutocompleteWithCheckbox {...props} />
        );
    }

    return (
        <>
            <Autocomplete<VAutocompleteOption, false, false, false>
                {...otherProps}
                ref={ref}
                options={options}
                onChange={(e, v) => {
                    if (!v) {
                        onChange(null);
                    }
                    onChange(v?.value);
                }}
                value={optionValue}
                renderOption={(option) => <li {...option}>{option.optionsLabel}</li>}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, v) => option.value === v.value}
                renderInput={(params) => <TextInput
                    helperText={helperText}
                    error={error}
                    required={required}
                    dark={dark}
                    className={classes.input}
                    placeholder={placeholder}
                    {...params}
                    inputProps={{ ...params.inputProps, ...inputProps }}
                    label={label}
                />}
                PaperComponent={({ children }) => (
                    <Paper className={classNames(classes.paper, { [classes.paperDark]: dark })}>{children}</Paper>
                )}
                className={classNames({ [classes.darkAutocomplete]: dark })}
                popupIcon={
                    otherProps?.loading
                        ? <Loader visible={true} size={20} />
                        : <Icon name={'arrowSmallDown'} width='20px' height='20px' />
                }
            />
        </>
    );
});
