export enum MeterChangePasswordPeriod {
    Week = 1,
    Month = 2,
    Year = 3,
}
export const meterChangePasswordPeriodDictionary: Record<MeterChangePasswordPeriod, string> = {
    [MeterChangePasswordPeriod.Week]: 'Неделя',
    [MeterChangePasswordPeriod.Month]: 'Месяц',
    [MeterChangePasswordPeriod.Year]: 'Год',
}
