import React from 'react';
import { StateProps } from './model';
import { Box, Grid, makeStyles, SvgIcon } from '@material-ui/core';
import { HelpIcon, SuccessIcon, WarningIcon } from '../../../../../../assets/icons';
import { Typography } from '../../../../../shared/components/typography';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    logContainer: {
        position: 'relative',
        '&:before': {
            content: '""',
            display: 'block',
            height: 'calc(100% - 32px)',
            position: 'absolute',
            top: 16,
            left: 20,
            zIndex: 1,
            borderLeft: `1px dashed ${theme.palette.neutral.background}`,
        },
    },
    icon: {
        zIndex: 2,
    },
    successIcon: {
        fill: theme.palette.system.success,
    },
    warningIcon: {
        fill: theme.palette.system.error,
    },
    notPerformedIcon: {
        fill: theme.palette.text.hint,
    },
    hint: {
        color: theme.palette.text.hint,
    },
    logDate: {
        whiteSpace: 'nowrap',
    }
}));

export const View = ({
    data,
    meterActivationOrder,
}: StateProps) => {

    const classes = useStyles();

    if (!meterActivationOrder) {
        return <Typography
            className={classes.hint}
            variant='body'
        >Здесь появится информация о процессе ввода ПУ в эксплуатацию после запуска.</Typography>;
    }

    return (
        <Grid container spacing={2} className={classes.logContainer}>

            {data.map((logGroup, key) => {

                let icon: any = null;
                switch (logGroup.type) {
                    case 'notPerformed':
                        icon = <SvgIcon component={HelpIcon} className={classNames(classes.notPerformedIcon, classes.icon)} />;
                        break;
                    case 'error':
                        icon = <SvgIcon component={WarningIcon} className={classNames(classes.warningIcon, classes.icon)} />;
                        break;
                    case 'success':
                        icon = <SvgIcon component={SuccessIcon} className={classNames(classes.successIcon, classes.icon)} />;
                        break;
                    default:
                        icon = <SvgIcon component={HelpIcon} className={classNames(classes.notPerformedIcon, classes.icon)} />;
                }

                return (
                    <Grid item sm={12} key={key}>
                        <Box display='flex'>
                            {icon}
                            <Box ml={1.8} flex={1}>
                                <Typography variant='bodyBold'>{logGroup.title}</Typography>
                                {logGroup.items?.map((logItem, itemKey) => (
                                    <Box display='flex' justifyContent='space-between' key={itemKey} className='m-b-2'>
                                        <Typography variant='body'>{logItem.message}</Typography>
                                        <Typography
                                            variant='caption'
                                            className={classNames(classes.hint, classes.logDate)}
                                        >{logItem.date}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    );
};
