import { ReadingSource, readingSourceDictionary } from '../../../common/model/meter/readingSource';

export const readingSourceSuggestions = [
    {
        label: readingSourceDictionary[ReadingSource.Reading],
        value: ReadingSource.Reading,
    },
    {
        label: readingSourceDictionary[ReadingSource.Import80020],
        value: ReadingSource.Import80020,
    },
    {
        label: readingSourceDictionary[ReadingSource.ImportCrq],
        value: ReadingSource.ImportCrq,
    }
];

export const readingSourceSuggestionsForPartner = readingSourceSuggestions
    .filter(readingSource => readingSource.value !== ReadingSource.Reading);

export const readingSourceSuggestionsByReadingAndCrq = [
    { value: '', label: 'Все (Опрос + CRQ)' },
    ...readingSourceSuggestions.filter(({ value }) => value !== ReadingSource.Import80020)];
