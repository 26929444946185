export enum appUrls {
    api_login = '/api/login',
    api_login_callback = '/api/login/callback',
    api_logout = '/api/logout',
    api_logout_callback = '/api/logout/callback',

    api_get_current_user = '/api/current-user',
    api_get_resources = '/api/resources',
    api_get_resources_version = '/api/resources/version',
    api_get_cache_clear = '/api/resources/cache-clear',
    api_get_settings = '/api/resources/settings',
    api_post_settings = '/api/resources/settings',

    api_get_users = '/api/users',
    api_get_all_users = '/api/users/all',
    api_get_user = '/api/user/:userId',
    api_create_user = '/api/users',
    api_update_user = '/api/users/:userId',
    api_delete_user = '/api/users/:userId',

    api_find_addresses = '/api/address',
    api_find_addresses_with_meters = '/api/address-meters/tree',
    api_find_area_names = '/api/address/area-name',
    api_find_town_names = '/api/address/town-name',
    api_find_street_names = '/api/address/street-name',
    api_find_house_numbers = '/api/address/house-number',
    api_post_addresses = '/api/address',

    api_post_meters_repeat_tasks = '/api/meters/:meterId/repeat-tasks',
    api_post_meters_action_plan_restart = '/api/meters/:meterId/meter-action-plan/restart',
    api_post_meters_action_plan_restart_with_active = '/api/meters/meter-action-plan/restart-with-active',
    api_post_meters_action_plan_restart_defined = '/api/meters/meter-action-plan/restart-defined',
    api_delete_meters_duplicates = '/api/meters/meter-action-plan/remove-duplicates',
    api_get_meters = '/api/meters',
    api_search_meters = '/api/meters-search',
    api_get_meter = '/api/meters/:meterId',
    api_get_meter_communicator_information = '/api/meters/:meterId/communicator-information',
    api_get_meter_tariff_schedules = '/api/meters/:meterId/tariff-schedules',
    api_get_meter_consumption_electricity = '/api/meters/:meterId/consumption/electricity',
    api_get_meter_consumption_electricity_download_excel = '/api/meters/:meterId/consumption/electricity/download/excel',
    api_get_meter_consumption_electricity_tariff = '/api/meters/:meterId/consumption/electricity-tariff',
    api_get_meter_consumption_electricity_tariff_download_excel = '/api/meters/:meterId/consumption/electricity-tariff/download/excel',
    api_get_meter_latest_profile = '/api/meters/:meterId/latest-profile',
    api_get_meters_count = '/api/meters-count',
    api_get_meter_errors_summary = '/api/meter-errors/summary',
    api_get_meter_errors_category = '/api/meter-errors/category',
    api_get_meter_errors_details = '/api/meter-errors/details',
    api_get_meters_errors = '/api/meters-errors',
    api_get_meter_gis = '/api/meters/:meterId/gis',
    api_get_meter_labels = '/api/meters/:meterId/labels',
    api_post_meter_label_close = '/api/meters/:meterId/labels/:meterLabelTypeId',
    api_get_meter_label_types = '/api/meters/labels/type/all',
    api_get_meter_configuration_history = '/api/meters/:meterId/configuration',
    api_get_meter_power_switch = '/api/meters/:meterId/power-switch',
    api_put_meter_power_switch = '/api/meters/:meterId/power-switch',
    api_get_meter_password = '/api/meters/:meterId/password/:passwordType',
    api_find_meters_reading_daily_list = '/api/meters/:meterId/reading/daily/list',
    api_find_meters_reading_daily_chart = '/api/meters/:meterId/reading/daily/chart',
    api_find_meters_reading_incremental_list = '/api/meters/:meterId/reading/incremental/list',
    api_find_meters_reading_incremental_chart = '/api/meters/:meterId/reading/incremental/chart',
    api_post_meters = '/api/meters',
    api_put_meters = '/api/meters/:meterId',
    api_put_meter_communication_params = '/api/meters/:meterId/communication-params',
    api_put_meter_replacing_communicator = '/api/meters/:meterId/replacing-communicator',
    api_put_meter_transformation_ratios = '/api/meters/:meterId/transformation-ratios',
    api_put_meter_verification = '/api/meters/:meterId/verification',
    api_put_meter_information_fields = '/api/meters/:meterId/information-fields',
    api_put_meter_password_fields = '/api/meters/:meterId/password-fields',
    api_put_meter_technical_params = '/api/meters/:meterId/technical-params',
    api_put_meter_address = '/api/meter/:meterId/address',
    api_put_meter_status_to_active = '/api/meter/:meterId/status/active',
    api_put_meter_status_to_decommissioned = '/api/meter/:meterId/status/decommissioned',
    api_put_meter_sync_schemes = '/api/meter/:meterId/sync-schemes',
    api_put_meter_label_type = '/api/meters/labels/type/:meterLabelTypeId',
    api_delete_meters = '/api/meters/:meterId',
    api_refresh_communicator_m2m_info = '/api/meters/:meterId/refresh-m2m/:msisdn',
    api_get_meter_reset_connection = '/api/meters/:meterId/reset-connection/:msisdn',

    //Partners
    api_get_partners = '/api/partners',
    api_get_partner = '/api/partners/:partnerId',
    api_post_partner = '/api/partners',
    api_put_partner = '/api/partners/:partnerId',
    api_get_partner_meters = '/api/partners/:partnerId/meters',
    api_post_partner_meters = '/api/partners/:partnerId/meters',
    api_get_partner_crq_systems = '/api/partners/:partnerId/crq-systems',
    api_get_crq_system = '/api/crq-systems/:id',
    api_post_crq_system = '/api/crq-systems',
    api_put_crq_system = '/api/crq-systems/:id',
    api_delete_crq_system = '/api/crq-systems/:id',
    api_get_meter_crq_system_and_channels = '/api/meters/:meterId/crq-channels',
    api_create_meter_crq_system_and_channels = '/api/meters/:meterId/crq-channels',

    // Meter file import
    api_post_partner_crq_meters_by_excel = '/api/meter/crq/upload',
    api_find_meter_crq_import = '/api/meter/crq/import',
    api_find_meter_crq_import_by_id = '/api/meter/crq/import/:id',
    api_find_meter_crq_import_log = '/api/meter/crq/import/:id/logs',

    //Network operators
    api_post_network_operator_check = '/api/network-operators/check',
    api_get_network_operators = '/api/network-operators',
    api_post_network_operator = '/api/network-operators',
    api_put_network_operator = '/api/network-operators/:id',
    api_delete_network_operator = '/api/network-operators/:id',

    //Power switch
    api_get_meter_power_switch_requests = '/api/meter-power-switch-requests',
    api_get_meter_power_switch_orders = '/api/meter-power-switch-orders',
    api_get_meter_power_switch_counters = '/api/api_get_meter_power_switch_counters',
    api_get_meter_power_switch_order = '/api/meter-power-switch-orders/:id',
    api_put_meter_power_switch_order_approval = '/api/meter-power-switch-order/:id/approval',

    //Экспорт данных
    api_get_export_80020_80040 = '/api/export/80020_80040',

    //Импорт данных
    api_post_import_80020_80040 = '/api/import/80020_80040',

    //Мониторинг
    api_get_monitoring_survey_condition = '/api/monitoring/survey-condition',

    //Группы счетчиков
    api_get_meter_groups = '/api/meter-groups',
    api_get_dynamic_meter_group_params = '/api/dynamic-meter-group-params/:groupId',
    api_post_dynamic_meter_group_params = '/api/dynamic-meter-group-params/:groupId',
    api_put_dynamic_meter_group_params = '/api/dynamic-meter-group-params/:groupId',
    api_delete_dynamic_meter_group_params = '/api/dynamic-meter-group-params/:dynamicMeterGroupParamsId',

    api_get_dynamic_meter_group_addresses = '/api/dynamic-meter-group-addresses/:groupId',
    api_post_dynamic_meter_group_addresses = '/api/dynamic-meter-group-addresses/:groupId',
    api_put_dynamic_meter_group_addresses = '/api/dynamic-meter-group-addresses/:groupId/:dynamicMeterGroupAddressesId',
    api_delete_dynamic_meter_group_addresses = '/api/dynamic-meter-group-addresses/:groupId/:dynamicMeterGroupAddressesId',

    api_delete_meter_group = '/api/meter-groups/:groupId',
    api_create_meter_groups = '/api/meter-groups',
    api_create_static_meter_group_content = '/api/static-meter-group-content',
    api_delete_static_meter_group_content = '/api/static-meter-group-content/:staticMeterGroupContentId',
    api_get_static_meter_group_content = '/api/static-meter-group-content/:groupId',
    api_put_meter_group = '/api/meter-groups/:groupId',

    //Модели счетчиков
    api_get_meter_models = '/api/meter-models',
    api_put_meter_model = '/api/meter-models/:id',

    //Схемы опроса
    api_get_meter_action_schemes = '/api/schemes',
    api_post_meter_action_scheme = '/api/schemes',
    api_put_meter_action_scheme = '/api/schemes/:schemeId',
    api_get_meter_action_scheme_change_request = '/api/schemes/:schemeId/change-request',

    //Схемы синхронизации времени
    api_get_time_synchronization_schemes = '/api/time-synchronization-schemes',
    api_post_time_synchronization_scheme = '/api/time-synchronization-schemes',
    api_put_time_synchronization_scheme = '/api/time-synchronization-schemes/:schemeId',
    api_delete_time_synchronization_scheme = '/api/time-synchronization-schemes/:schemeId',

    // Справочник типов событий
    api_find_event_types = '/api/event-types',
    api_update_event_type = '/api/event-types/:id',

    api_find_event_logs = '/api/event-logs',
    api_download_event_logs_excel = '/api/event-logs/excel',
    api_find_high_critical_level_event_logs = '/api/event-logs/high-critical-level',

    api_reports_not_connected_meters = '/api/reports/not-connected-meters',
    api_reports_meter_profile = '/api/reports/meter-profile',
    api_reports_electricity_consumption = '/api/reports/electricity-consumption',
    api_reports_electricity_tariff = '/api/reports/electricity-tariff',
    api_reports_crash_by_regions = '/api/reports/crash-by-regions',
    api_reports_configuration_history = '/api/reports/configuration_history',
    api_reports_electricity_consumption_billing = '/api/reports/electricity-consumption-billing',
    api_reports_advanced_crash_report = '/api/reports/advanced-crash-report',
    api_reports_advanced_crash_report_excel = '/api/reports/advanced-crash-report/excel',

    api_get_meter_activation_order = '/api/meter-activation-orders/:meterActivationOrderId',
    api_find_meter_activation_orders = '/api/meter-activation-orders',
    api_find_meter_activation_order_serial_nums = '/api/meter-activation-orders/serial-nums',
    api_post_meter_activation_order = '/api/meter-activation-orders',
    api_reprocess_meter_activation_order = '/api/meter-activation-orders/:meterActivationOrderId/reprocess',
    api_reprocess_meter_activation_orders = '/api/reprocess-meter-activation-orders',
    api_update_and_reprocess_meter_activation_order = '/api/meter-activation-orders/:meterActivationOrderId',
    api_delete_meter_activation_order = '/api/meter-activation-orders/:meterActivationOrderId',

    api_get_meter_meter_action_plan = '/api/meter/:meterId/meter-action-plan/:meterActionType',
    api_get_meter_meter_action_plans = '/api/meter/:meterId/meter-action-plan',

    api_post_meter_action_plan_sync = '/api/meter-action-plans/sync',

    api_post_meter_reading_instant_order = '/api/meter/:meterId/meter-reading-instant-order',
    api_get_meter_reading_instant_order = '/api/meter/:meterId/meter-reading-instant-order',
    api_get_meter_reading_instant_profile = '/api/meter/:meterId/meter-reading-instant-profile',

    api_external_post_meter_activation_orders = '/external/meter-activation-orders',
    api_external_patch_meter_activation_orders = '/external/meter-activation-orders/:meterActivationOrderId',
    api_external_get_meter_activation_order_by_id = '/external/meter-activation-orders/:meterActivationOrderId',
    api_external_get_meter_activation_order_by_foreign_id = '/external/meter-activation-orders/',
    api_external_meter_activation_order_from_excel = '/external/meter-activation-orders/upload',
    api_external_post_meter_deactivation = '/external/meter-deactivation/:foreignId',
    api_external_post_meter_power_switch_requests = '/external/meter-power-switch-requests',
    api_external_get_meter_power_switch_requests = '/external/meter-power-switch-requests/:meterPowerSwitchRequestId',
    api_external_get_meters = '/external/meters',
    api_external_get_meters_intelligence = '/external/meters-intelligence',
    api_external_post_meter_journal = '/external/meter-journal',

    // вспомогательный метод для тестов
    api_test_access = '/access/test',

    api_get_access_access_rules = '/api/access/access-rules',

    api_get_access_roles = '/api/access/roles',
    api_post_access_roles = '/api/access/roles',
    api_put_access_roles = '/api/access/roles/:id',
    api_delete_access_roles = '/api/access/roles/:id',

    api_get_access_roles_access_rules = '/api/access/roles/:roleId/access-rules',
    api_put_access_roles_access_rules = '/api/access/roles/:roleId/access-rules',

    api_get_access_users_access_rules = '/api/access/users/:userId/access-rules',
    api_put_access_users_access_rules = '/api/access/users/:userId/access-rules',

    // Массовые операции
    api_get_mass_operations = '/api/mass-operations',
    api_check_mass_operations = '/api/mass-operations/check',
    api_get_mass_operation = '/api/mass-operations/:id',
    api_post_mass_operation = '/api/mass-operations',

    // личный кабинет
    api_get_meter_cumulative_energy_reading_data = '/external/meter-cumulative-energy-reading-data',
    api_get_meter_incremental_energy_reading_data = '/external/meter-incremental-energy-reading-data',
    api_post_meter_current_reading_orders = '/external/meter-current-reading-orders',
    api_get_meter_current_reading_orders = '/external/meter-current-reading-orders/:orderId',
    api_get_meter_reading_orders = '/external/meter-reading-orders',

    // задачи ГИС энергоучет
    api_post_meter_gis_task = '/api/meter-gis-task',
    api_get_meter_gis_tasks = '/api/meter-gis-tasks',
    api_put_meter_gis_task = '/api/meter-gis-task/:taskId',
    api_close_meter_gis_task = '/api/meter-gis-task/:taskId/close',
    api_archive_meter_gis_task = '/api/meter-gis-task/:taskId/archive',
    api_get_meter_gis_task = '/api/meter-gis-task/:taskId',
    api_close_group_gis_tasks = '/api/meter-gis-tasks/close-group',
    api_archive_group_gis_tasks = '/api/meter-gis-tasks/archive-group',
    api_get_history_meter_gis_tasks = '/api/history-meter-gis-tasks/:taskNumber',

    // системные логи
    api_get_system_log_token = '/api/system-log-token',
    api_get_system_log = '/api/system-log',

    // инспекционные логи
    api_get_inspection_log = '/api/inspection-log',

    api_get_gis_tasks_by_ids = '/api/gis-tasks-by-id',
    // имитация внешнего сервиса синхронизации
    api_get_gis_sync_tasks = '/tasks',
    api_get_gis_sync_task = '/tasks/:id',
    put_gis_tasks = '/tasks/:id',

    // справочники
    api_get_dictionaries = '/api/meter-dictionaries',
    api_get_dictionary_values_by_name = '/api/meter-dictionary-values-by-name/:dictionaryName',

    api_put_meter_dictionary_value = '/api/meter-dictionary-value/:id',
    api_delete_meter_dictionary_value = '/api/meter-dictionary-value/:id',
    api_create_meter_dictionary_value = '/api/meter-dictionary-value',

    // Версии прошивки
    api_get_meter_firmware_version = '/api/meter-firmware-version',
    api_post_meter_firmware_version = '/api/meter-firmware-version',
    api_put_meter_firmware_version = '/api/meter-firmware-version/:id',
    api_delete_meter_firmware_version = '/api/meter-firmware-version/:id',

    // тарифные расписания
    api_get_tariff_schedules = '/api/tariff-schedules',
    api_post_tariff_schedule = '/api/tariff-schedules',
    api_post_tariff_schedule_version = '/api/tariff-schedules/:tariffScheduleId/version',
    api_put_tariff_schedule_version = '/api/tariff-schedules/:tariffScheduleId/version/:tariffScheduleVersionId',
    api_get_tariff_schedule_version = '/api/tariff-schedules/:tariffScheduleId/version/:tariffScheduleVersionId',
    api_get_tariff_schedule_orders = '/api/tariff-schedules/orders',
    api_put_meter_meter_tariff_schedule = '/api/meter/:meterId/meter-tariff-schedule',
    api_post_meter_meter_tariff_schedule_rewrite = '/api/meter/:meterId/meter-tariff-schedule/rewrite',
    api_get_meter_meter_tariff_schedule_order = '/api/meter/:meterId/meter-tariff-schedule-order',

    // Смена паролей
    api_post_meter_change_password_order = '/api/meter-change-password-order',
    api_get_meter_change_password_order = '/api/meter-change-password-order',
    api_get_meter_change_password_latest_task_by_meter_id = '/api/meter-change-password/:meterId/task/latest',
    api_get_meter_change_password_request = '/api/meter-change-password-request',
    api_post_meter_change_password_request = '/api/meter-change-password-request',

    // заявки на изменение технических параметров
    api_get_meter_update_technical_params_order = '/api/meter/:meterId/meter-update-technical-params-order',

    // базовый календарь специальных дней
    api_get_special_day_base = '/api/special-day-base',
    api_post_special_day_base = '/api/special-day-base',
    api_put_special_day_base = '/api/special-day-base/:id',

    // Аис восток эмулятор rabbit сообщений
    api_get_ais_emulator_generate_message = '/api/ais-vostok/generate/:messageType',

    //Апи для проверки активности ядра
    api_core_check = '/api/core/check',
}
