import * as React from 'react';

export type OrderDirection = 'desc' | 'asc';

export interface PaginationOrderParams {
    orderBy?: string;
    orderDirection?: OrderDirection;
}

export interface PaginationParams extends PaginationOrderParams {
    page?: number;
    rowsPerPage?: number;
}

export const getActualPage = (count: number, params: PaginationParams): number => {

    if (isNaN(+params.page)) {
        return 1;
    }

    if (count === null || count === undefined) {
        return 1;
    }

    const latestPage = Math.ceil(count / params.rowsPerPage);

    if (!params.page || params.page < 1 || latestPage === 0) {
        return 1;
    }

    if (params.page > latestPage && latestPage >= 1) {
        return latestPage;
    }

    return params.page;
};


export const useActualPage = (onChangeActualPage: (page: number) => void, dependencies: [number, PaginationParams]) => {
    const [count, paginationParams] = dependencies;
    React.useEffect(() => {
        const actualPage = getActualPage(count, paginationParams);
        if (actualPage !== paginationParams.page) {
            onChangeActualPage(actualPage);
        }
    }, [count, paginationParams.page]);
    return getActualPage(count, paginationParams);
};
