import React from 'react';

interface Props {
    data: string[];
}

export const ColumnArrayView: React.FC<Props> = ({ data }) => {
    return (
        <>
            {data.map(
                (item, index) => (
                    <React.Fragment key={`item-${index}`}>
                        {item}<br />
                    </React.Fragment>
                )
            )}
        </>
    );
};
