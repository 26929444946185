import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { ListItemIcon } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '../../typography';
import { RenderMenuItemsProps } from './model';
import { TabElement } from '../../tabElement';
import { useStyles } from '../styles';

export const View: React.FC<RenderMenuItemsProps> = ({
    menuItem,
    activeMenu,
    onMenuItemClick,
    className
}) => {
    const classes = useStyles();

    if (menuItem.isTab) {
        return (
            <TabElement className={className} menuItem={menuItem} />
        );
    }

    return (
        <ListItem
            classes={{ root: classes.listItemRoot, selected: classes.listItemSelected }}
            className={className}
            selected={activeMenu === menuItem.menuItemType}
            onClick={() => onMenuItemClick(menuItem.route)}
            disabled={menuItem.disabled}
            disableRipple
            button
            data-targetid={menuItem.menuItemType}
        >
            <ListItemIcon classes={{ root: classes.listItemIconRoot }}>{menuItem.icon}</ListItemIcon>
            <ListItemText classes={{ root: classes.listItemTextRoot }} primary={
                <Typography variant='h2'>{menuItem.name}</Typography>
            } />
        </ListItem>
    );
};
