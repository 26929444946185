import { TariffDayNameCreator } from '../../../../common/model/meter/tariffDayName/tariffDayNameCreator';
import { TariffScheduleVersionCreator } from '../../../../common/model/meter/tariffScheduleVersion/tariffScheduleVersionCreator';
import { TariffDayCardInputProps } from './components/tariffDayCard/model';

interface TariffSeasonSortableItem {
    index: number;
    field: Record<'id', string>;
}
export const sortTariffSeason = (
    formData: TariffScheduleVersionCreator
) => (a: TariffSeasonSortableItem, b: TariffSeasonSortableItem): number => {
    const { index: index1 } = a;
    const { index: index2 } = b;
    const sortA = (
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        (formData.tariffSeasons[index1].startMonth ?? 13) * 100
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    ) + (formData.tariffSeasons[index1].startDay ?? 31) + index1;
    const sortB = (
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        (formData.tariffSeasons[index2].startMonth ?? 13) * 100
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    ) + (formData.tariffSeasons[index2].startDay ?? 31) + index2;
    return sortA - sortB;
};

export const sortTariffDayCards = (
    tariffDayNamesById: Record<string, TariffDayNameCreator>
) => (a: TariffDayCardInputProps, b: TariffDayCardInputProps): number => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const sortA = tariffDayNamesById[a?.tariffDay?.tariffDayNameId]?.code ?? 998;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const sortB = tariffDayNamesById[b?.tariffDay?.tariffDayNameId]?.code ?? 999;
    return sortA - sortB;
};

export const prepareTariffScheduleVersionCreator = (creator: TariffScheduleVersionCreator): TariffScheduleVersionCreator => {
    const {
        tariffZones,
        tariffDays,
    } = creator;
    const tariffDayIds = tariffDays.map(item => item.id);
    return {
        ...creator,
        tariffZones: tariffZones.filter((item) => {
            return tariffDayIds.includes(item.tariffDayId);
        }),
    };
};
