import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../shared/components/button';
import { Heading } from '../../../../shared/components/heading';
import { HookField } from '../../../../shared/components/hookField';
import { Table } from '../../../../shared/components/table';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Tables } from '../../../../shared/constants';
import { meterProfileReportIntervals } from '../../../../shared/constants/enums';
import { IStateProps, MeterProfileReportTableItem } from './model';
import { Typography } from '../../../../shared/components/typography';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: 0,
    },
    noDataText: {
        ...theme.typography.body,
        color: theme.palette.text.hint,
    },
    tableContainer: {
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
        },
    },
    title: {
        ...theme.typography.captionBold,
        color: theme.palette.text.hint,
        overflow: 'hidden',
        wordWrap: 'break-word',
        marginBottom: '8px',
    },
}));

export const RenderFieldView = (
    data: MeterProfileReportTableItem, fieldName: keyof MeterProfileReportTableItem
): React.ReactNode => {
    return data.isResultItem ? <strong>{data[fieldName]}</strong> : data[fieldName];
};

export const View: React.FC<IStateProps> = (props) => {
    const {
        data,
        columns,
        form,
        onClickFormReport,
        regionSuggestions,
        isLoading,
        isReportFormed,
        onClickRefresh,
        onClickFormExcel,
        headerBlock,
        isExcelExportNotAvailable,
        meterAutocomplete,
        headingOnBackHandler,
        breadcrumbs,
        onClickBreadcrumb,
    } = props;

    const classes = useStyles();

    const filterRender = <>
        <FormProvider {...form}>
            <Grid container spacing={2}>
                <Grid xs={2} item>
                    <HookField
                        type='select'
                        name='regionId'
                        label='Регион'
                        fullWidth
                        disabled={isLoading}
                        suggestions={regionSuggestions} />
                </Grid>
                <Grid xs={2} item>
                    <HookField
                        type='autocomplete'
                        name='meterId'
                        label='Номер ПУ'
                        disabled={isLoading}
                        fullWidth
                        {...meterAutocomplete}
                    />
                </Grid>
                <Grid xs={2} item>
                    <HookField
                        fullWidth
                        name='dateFrom'
                        type='datepicker'
                        showTime={true}
                        label='Начало'
                        disabled={isLoading}
                    />
                </Grid>
                <Grid xs={2} item>
                    <HookField
                        fullWidth
                        name='dateTo'
                        type='datepicker'
                        showTime={true}
                        label='Конец'
                        disabled={isLoading}
                    />
                </Grid>
                <Grid xs={2} item>
                    <HookField
                        fullWidth
                        name='groupingInterval'
                        type='select'
                        label='Интервал'
                        disabled={isLoading}
                        suggestions={meterProfileReportIntervals}
                    />
                </Grid>
                {isReportFormed && (
                    <>
                        <Grid item>
                            <Button
                                onClick={onClickRefresh}
                                variant='outline'
                                square
                                icon='refresh'
                                type='button'
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClickFormExcel}
                                variant='primary'
                                icon='excel'
                                type='button'
                                disabled={isExcelExportNotAvailable || isLoading}
                            >
                                Выгрузить в Excel
                            </Button>
                        </Grid>
                    </>
                )}
                {!isReportFormed && (
                    <Grid item>
                        <Button
                            onClick={onClickFormReport}
                            variant='primary'
                            type='button'
                            disabled={isLoading}
                        >
                            Сформировать
                        </Button>
                    </Grid>
                )}
            </Grid>
        </FormProvider>
    </>;

    const isTableAvailable = data.length > 0;

    return (
        <Box mb={3}>
            <Box mb={2}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2}>
                <Heading heading='Профиль ПУ: A+ A- R+ R-' onBackHandler={headingOnBackHandler} />
            </Box>
            <Wrapper isBigHeader actions={filterRender} childrenWrapperClassName={classes.wrapper}>
                {
                    isReportFormed && (
                        <Box p={2} display='flex' flexDirection='row'>
                            {headerBlock.items.map(element => (
                                <Box key={element.value} display='flex' flexDirection='column' marginRight='30px'>
                                    <Typography variant="captionBold" className={classes.title}>
                                        {element.title}
                                    </Typography>
                                    <Typography variant="body">
                                        {element.value}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    )
                }
                {isTableAvailable && (
                    <Box padding='17px 16px 16px 16px' className={classes.tableContainer}>
                        <Table
                            name={Tables.meterProfileReportTable}
                            columns={columns}
                            data={data || []}
                            title={''}
                            useQueryString={true}
                            options={{
                                showTitle: false,
                                toolbar: false,
                                paging: 'classic',
                                pageSize: 500,
                                grouping: false,
                                columnsButton: false,
                                filtering: false,
                                search: false,
                            }}
                        />
                    </Box>
                )}
                {!isTableAvailable && (
                    <Box p={2}>
                        <Box className={classes.noDataText}>
                            <div>Нет данных</div>
                        </Box>
                    </Box>
                )}
            </Wrapper>
        </Box>
    );
};
