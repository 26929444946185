import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MassOperationViewModel } from '../../../../common/model/meter/massOperation/massOperationViewModel';

export interface MassOperationsDetailsState {
    available: MassOperationViewModel;
}

const initialState: MassOperationsDetailsState = {
    available: null,
};

export const massOperationsDetailsSlice = createSlice({
    name: '@@vv/massOperations/details',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<MassOperationViewModel>) {
            state.available = action.payload;
        },
    },
});
