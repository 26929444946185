import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MeterFirmwareVersionViewModel } from '../../../common/model/meter/meterFirmwareVersion/meterFirmwareVersionViewModel';
import { BaseDispatch } from '../../../redux/actions';
import { getMeterFirmwareVersions } from '../../../redux/actions/meterFirmwareVersions';
import { meterFirmwareVersionsListSelector } from '../../../redux/selectors/meterFirmwareVersions';
import { AdministrationTabs, Routes } from '../../shared/constants';
import { getRoute } from '../../shared/pipes';

export interface MeterFirmwareVersionResolverChildProps {
    data: MeterFirmwareVersionViewModel[];
    reFetch: () => Promise<void>;
}

export const MeterFirmwareVersionResolver = (viewComponent: React.FC<any>) => {
    return (props: any) => {
        const dispatch = useDispatch<BaseDispatch>();
        const data = useSelector(meterFirmwareVersionsListSelector);
        const { partnerId } = useParams<{ partnerId: string; }>();

        const reFetch = async () => {
            await dispatch(getMeterFirmwareVersions());
        };

        React.useEffect(() => {
            reFetch();
        }, [partnerId]);

        React.useEffect(() => {
            if (data !== null && !data) {
                dispatch(push(getRoute(Routes.administration, {}, { tab: AdministrationTabs.Dictionaries })));
            }
        }, [data]);

        if (!data) {
            return null;
        }

        return React.createElement(viewComponent, {
            ...props,
            data,
            reFetch,
        });
    };
};
