export enum MeterActionPlanStatus {
    Disabled = 'disabled', // Создание задач опроса по данному плану запрещено.
    Planned = 'planned', // Создание задачи опроса запланировано.
    InProgress = 'in-progress', // Задача опроса создана, ожидается получение ее результата.
}

export const meterActionPlanStatusDictionary: Record<MeterActionPlanStatus, string> = {
    [MeterActionPlanStatus.Disabled]: 'Отключено',
    [MeterActionPlanStatus.Planned]: 'Запланировано',
    [MeterActionPlanStatus.InProgress]: 'В процессе',
};
