/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Alignment, Workbook, Worksheet } from 'exceljs';
import { DateFormats } from '../../../../../common/constants/date';
import { CrashByRegionsReportDataType, CrashByRegionsReportResponse } from '../../../../../common/model/meter/reports/crashByRegionsReport';
import { formatDate } from '../../../../shared/utils/dates';
import { downloadExcelData } from '../../../../shared/utils/download';
import { addBordersToCell, addRowSpaceToWorksheet, excelBaseFontName } from '../../excelHelpers';
import { CrashByRegionsReportTableData } from './model';
import {
    transformCrashByRegionsReportResponseToTableDataSummary,
    transformCrashByRegionsReportDataItemToTableData,
} from './transformer';

const dataToTableCells = (mainTableDatum: CrashByRegionsReportTableData): string[] => {
    const region = mainTableDatum.type === CrashByRegionsReportDataType.Region
        ? mainTableDatum.regionName
        : mainTableDatum.meterPurpose ?? 'Не указано';
    return [
        mainTableDatum.index?.toString(),
        region,
        mainTableDatum.metersCount?.toString(),
        mainTableDatum.crashMetersCount?.toString(),
        mainTableDatum.crashMetersPercent?.toString(),
    ];
};

const excelMainTableTitles = [
    '№ пп',
    'Регион',
    'Всего ПУ',
    'ПУ без показаний',
    'Процент неопроса',
    'Примечание',
];

const columnWidths: Record<number, number> = {
    1: 8,
    2: 28,
    3: 16,
    4: 16,
    5: 16,
    6: 28,
};

export interface MakeExcelReportConfig {
    daysOffline: number;
    response: CrashByRegionsReportResponse;
    reportDate: Date;
    filename: string;
}

const makeColumns = (worksheet: Worksheet) => {
    for (const col in columnWidths) {
        worksheet.getColumn(+col).width = +columnWidths[col];
    }
};

const makeMainTableHeadings = (worksheet: Worksheet) => {
    const titlesRow = worksheet.addRow(excelMainTableTitles);
    const tableHeaderColAlignment: Alignment['horizontal'][] = ['center', 'center', 'right', 'right', 'right', 'center'];
    for (let i = 1; i <= excelMainTableTitles.length; i++) {
        const cell = titlesRow.getCell(i);
        cell.style.font = {
            name: excelBaseFontName,
            size: 10,
            bold: true,
        };
        cell.style.alignment = {
            wrapText: true,
            horizontal: tableHeaderColAlignment[i] ?? 'left',
        };
        addBordersToCell(cell);
    }
};

const makeMainTitle = (worksheet: Worksheet, title: string) => {
    worksheet.addRow([title]);
    worksheet.getCell('A1').style.font = {
        size: 16,
        name: excelBaseFontName,
        bold: true,
    };
    worksheet.getCell('A1').style.alignment = {
        horizontal: 'center',
    };
    worksheet.mergeCells('A1:H1');
};

export const makeCrashByRegionsReportSheet = (workbook: Workbook, config: MakeExcelReportConfig): void => {
    const { daysOffline, response, reportDate } = config;
    const reportData = response.data;
    // заголовок отчета
    const worksheetTitle = `Количество ПУ, для которых нет показаний более ${daysOffline} суток`;
    const worksheetHeader = 'Нет связи';
    const worksheet = workbook.addWorksheet(worksheetHeader);
    makeMainTitle(worksheet, worksheetTitle);
    // дата создания отчета
    const createdDateTableData = [
        '',
        '',
        '',
        'сформирован:',
        formatDate(reportDate, DateFormats.dateFormat),
        formatDate(reportDate, DateFormats.timeFullWithTimeZone),
    ];
    const createdDateRow = worksheet.addRow(createdDateTableData);
    const tableInfoColAlignment: Alignment['horizontal'][] = ['left', 'left', 'left', 'right', 'right', 'center'];
    createdDateRow.eachCell((cell, index) => {
        cell.style.font = {
            name: excelBaseFontName,
            size: 10,
        };
        cell.style.alignment = {
            horizontal: tableInfoColAlignment[index - 1] ?? 'left',
        };
        addBordersToCell(cell);
    });
    makeColumns(worksheet);
    // заголовки таблицы
    makeMainTableHeadings(worksheet);
    const tableBodyColAlignment: Alignment['horizontal'][] = ['left', 'left', 'right', 'right', 'right', 'left'];
    // основная таблица
    const mainTableData = transformCrashByRegionsReportDataItemToTableData(reportData);
    for (const mainTableDatum of mainTableData) {
        const datumRow = dataToTableCells(mainTableDatum);
        const row = worksheet.addRow(datumRow);
        for (let i = 1; i <= excelMainTableTitles.length; i++) {
            const cell = row.getCell(i);
            cell.style.font = {
                name: excelBaseFontName,
                bold: mainTableDatum.type === CrashByRegionsReportDataType.Region,
                size: 10,
            };
            cell.style.alignment = {
                horizontal: tableBodyColAlignment[i - 1] ?? 'left',
            };
            addBordersToCell(cell);
        }
    }

    // space
    addRowSpaceToWorksheet(worksheet);


    // summary data
    const summaryData = transformCrashByRegionsReportResponseToTableDataSummary(response, daysOffline);
    for (const summaryDatum of summaryData) {
        const row = addRowSpaceToWorksheet(worksheet);
        const cell1 = row.getCell(3);
        const cellKey = row.getCell(3);
        cellKey.value = summaryDatum.index;
        worksheet.mergeCells(`${cell1.address}:${cellKey.address}`);
        const cellValue = row.getCell(4);
        cellValue.value = summaryDatum.crashMetersCount;
        cellKey.style.font = {
            name: excelBaseFontName,
            size: 10,
        };
        cellKey.alignment = {
            horizontal: 'right',
        };
        cellValue.style.font = {
            name: excelBaseFontName,
            size: 10,
        };
        cellValue.alignment = {
            horizontal: 'right',
        };
    }

    // space
    addRowSpaceToWorksheet(worksheet);
};

export const makeExcelReport = async (config: MakeExcelReportConfig): Promise<void> => {
    const workbook = new Workbook();
    makeCrashByRegionsReportSheet(workbook, config);

    const buffer = await workbook.xlsx.writeBuffer() as unknown as Buffer;
    const excelData = buffer.toString('base64');

    downloadExcelData(
        excelData,
        config.filename,
    );
};
/* eslint-enable @typescript-eslint/no-magic-numbers */
