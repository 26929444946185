import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { DateFormats } from '../../../../../common/constants/date';
import { MeterReadingInstantOrderStatus } from '../../../../../common/model/meter/meterReadingInstantOrder/meterReaddingInstantOrderStatus';
import { Button } from '../../../../shared/components/button';
import { Table } from '../../../../shared/components/table';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Tables } from '../../../../shared/constants';
import { Icon } from '../../../../shared/components/icon';
import { Tooltip } from '../../../../shared/components/tooltip';
import { setColumnWidth } from '../../../../shared/pipes/styles';
import { formatDate } from '../../../../shared/utils/dates';
import { FullPowerVectorChart } from '../components/fullPowerVectorChart';
import { PhaseVectorDiagramChart } from '../components/phaseVectorDiagramChart';
import { IStateProps } from './model';

const useStyles = makeStyles((theme) => ({
    tableRow: {
        '& .dark-cell': {
            backgroundColor: theme.palette.neutral.middleBg,
        },
    },
    graphicWrapper: {
        backgroundColor: theme.palette.neutral.background,
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 5,
    },
    graphicTitle: {
        ...theme.typography.bodyBold,
        color: theme.palette.text.primary,
        marginBottom: 8,
    },
    tableWrapper: {
        position: 'relative',
        '& .col-parameter': {
            ...setColumnWidth('180px'),
        },
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    errorIcon: {
        marginLeft: 5,
        color: theme.palette.primary.main,
        display: 'inline-flex',
    },
}));

const orderSuccessStatuses = [
    MeterReadingInstantOrderStatus.Success,
    MeterReadingInstantOrderStatus.Created
];

export const View: React.FC<IStateProps> = (props) => {
    const {
        data,
        columns,
        order,
        onClickSubmit,
        fullPowerVectorChartAngle,
        phaseVectorDiagramChartVectors,
        profile,
        isCreated,
    } = props;

    const classes = useStyles();

    const actions =
        <Button
            variant='outline'
            onClick={onClickSubmit}
            disabled={isCreated}
            isLoading={isCreated}
        >Считать</Button>;

    const isErrorOrder = order?.status && !orderSuccessStatuses.includes(order?.status);

    const errorIcon = isErrorOrder
        ? (
            <span className={classes.errorIcon}>
                <Tooltip
                    text={
                        'Последнее считывание ' +
                        `${formatDate(new Date(order?.endDate), DateFormats.dateFullTimeFormat)} ` +
                        'завершено с ошибкой.'
                    }
                >
                    <Icon name='alert' />
                </Tooltip>
            </span>
        )
        : '';

    const titleTime = profile?.readingDate
        ? formatDate(new Date(profile.readingDate), DateFormats.dateFullTimeFormat)
        : '';

    const title = <div className={classes.title}>{`МГНОВЕННЫЕ ЗНАЧЕНИЯ ${titleTime}`} {errorIcon}</div>;

    return <Wrapper
        title={title}
        actions={actions}
    >
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <div className={classes.graphicTitle}>
                    Вектор полной мощности
                </div>
                <Box className={classes.graphicWrapper}>
                    <FullPowerVectorChart angle={fullPowerVectorChartAngle} />
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div className={classes.graphicTitle}>
                    Пофазная векторная диаграмма
                </div>
                <Box className={classes.graphicWrapper}>
                    <PhaseVectorDiagramChart items={phaseVectorDiagramChartVectors} />
                </Box>
            </Grid>
        </Grid>
        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.tableWrapper}>
                <Table
                    name={Tables.meterInstantReadingTable}
                    columns={columns}
                    data={data || []}
                    title={''}
                    variant='white'
                    options={{
                        showTitle: false,
                        toolbar: false,
                        search: false,
                        draggableHeader: false,
                        paging: 'disabled',
                        sorting: false,
                        rowClassName: classes.tableRow,
                    }}
                />
            </Grid>
        </Grid>
    </Wrapper>;
};
