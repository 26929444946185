import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { EllipsisTooltip } from '../../../ellipsisTooltip';
import React from 'react';
import { useStyles } from '../styles';
import { PasswordText } from '../../../passwordText';
import { Typography } from 'app/shared/components/typography';
import { IStateProps } from './model';

export const View: React.FC<IStateProps> = ({ item, type, className, count, fetchPassword }) => {
    const classes = useStyles({ count });
    return (
        <Box className={classNames(
            type === 'vertical' ? classes.verticalPosition : null,
            { [classes.itemWrapperHorizontal]: type === 'horizontal' },
            className,
        )}>
            <Typography className={classes.title} variant="caption">
                <EllipsisTooltip text={item.title}>{item.title}</EllipsisTooltip>
            </Typography>
            <Typography className={classes.item} variant="caption">
                <PasswordText fetchPassword={fetchPassword} />
            </Typography>
        </Box>
    );
};
