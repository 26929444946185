import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MeterGroupQueryParams } from '../../../common/model/meter/meter-group/queryParams';
import { MeterSearchQuery } from '../../../common/model/meter/meter/meterSearchQuery';
import { MeterGroupViewModel } from '../../../common/model/meter/meterGroup/meterGroupViewModel';
import { SearchMetersResponse } from '../../../common/model/meter/searchMeters/searchMetersResponse';
import { meterGroupListSlice } from '../../reducers/meterGroup/list';
import { AppThunk } from '../index';

export const getMeterGroupList = (params: MeterGroupQueryParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_meter_groups, {}, params);
    const data = await httpService.get<MeterGroupViewModel[]>(route);
    dispatch(meterGroupListSlice.actions.setAvailable(data));
};

export const resetMeterGroupList = (): AppThunk => (dispatch) => {
    dispatch(meterGroupListSlice.actions.setAvailable([]));
};

export const getMetersDynamicGroupList = (query?: MeterSearchQuery): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_search_meters, {}, query);
    const data = await httpService.get<SearchMetersResponse>(route);
    dispatch(meterGroupListSlice.actions.setDynamicMetersList(data));
};

export const getMetersDynamicGroupListForInfinitiveScroll = (query?: MeterSearchQuery): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_search_meters, {}, query);
    const data = await httpService.get<SearchMetersResponse>(route);
    return data;
};

export const resetMetersDynamicGroupList = (): AppThunk => (dispatch) => {
    dispatch(meterGroupListSlice.actions.setDynamicMetersList({ data: [], total: 0 }));
};
