import * as React from 'react';
import { Select } from '../../../../../shared/components/select';
import { InputProps } from './model';
import { startDateSuggestions } from './suggestions';

export const View: React.FC<InputProps> = (props) => {
    const { season, onChange } = props;
    return <Select
        fullWidth
        placeholder='Не выбрано'
        type='number'
        variant='standard'
        suggestions={startDateSuggestions}
        value={
            season
                ? `${season?.startDay}:${season?.startMonth}`
                : ''
        }
        onChange={(e) => {
            const result: [number, number] = (e.target.value as string)?.split(':')
                .map<number>(item => +item) as [number, number]
            ?? [null, null];
            onChange(result);
        }}
    />;
};
