import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BaseDispatch } from '../../../redux/actions';
import { getMeterData, resetMeterData } from '../../../redux/actions/meters';
import { getMeterSchemes } from '../../../redux/actions/meterSchemes';
import { meterSelector, meterUiConfigSelector } from '../../../redux/selectors/meters';
import { MeterTabs, Routes } from '../../shared/constants';
import { useBreadcrumbs } from '../../shared/hooks/useBreadcrumbs';
import { useQueryParams } from '../../shared/hooks/useQueryParams';
import { getRoute } from '../../shared/pipes';
import { getErrorMessage } from '../../shared/utils/error';
import { IStateProps } from './model';
import { View } from './view';
import { createSnackbar, saveTreeItemToTagList } from '../../../redux/actions/controls';
import { ProblemTitle } from './problems/label';
import { ReadingSource } from 'common/model/meter/readingSource';

const tabTranslations = {
    [MeterTabs.EventLog]: 'Журналы',
    [MeterTabs.Consumption]: 'Потребление',
    [MeterTabs.Gis]: 'ГИС',
    [MeterTabs.Incidents]: 'Инциденты',
    [MeterTabs.Management]: 'Управление',
    [MeterTabs.Polls]: 'Опросы',
    [MeterTabs.CrqInterface]: 'CRQ-ИНТЕРФЕЙС',
    [MeterTabs.Problems]: 'Проблемы',
    [MeterTabs.Reading]: 'Показания',
};

const getBreadcrumbLastItem = (tab: MeterTabs, meterId: string) => {
    switch (tab) {
        case MeterTabs.EventLog:
        case MeterTabs.Consumption:
        case MeterTabs.Gis:
        case MeterTabs.Incidents:
        case MeterTabs.Management:
        case MeterTabs.Polls:
        case MeterTabs.CrqInterface:
        case MeterTabs.Problems:
        case MeterTabs.Reading:
            return { name: tabTranslations[tab], link: getRoute(Routes.meter, { meterId }, { tab }) };
        default:
            return null;
    }
};

export const Meter = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const params = useParams<{meterId?: string}>();
    const meterId = params.meterId;
    const queryParams = useQueryParams();
    const activeTab = (queryParams.get('tab') as MeterTabs) ?? MeterTabs.Information;
    const meter = useSelector(meterSelector);
    const uiConfig = useSelector(meterUiConfigSelector);

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Приборы учета', link: getRoute(Routes.empty) },
        { name: 'Карточка ПУ', link: getRoute(Routes.meter, { meterId }, { tab: MeterTabs.Information }) },
        getBreadcrumbLastItem(activeTab, meterId)
    ].filter(item => !!item));

    const fetch = async () => {
        try {
            await dispatch(getMeterData(meterId));
            await dispatch(getMeterSchemes());
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
            dispatch(push(Routes.meterSearch));
        }
    };

    useEffect(() => {
        if (meterId && !meter) {
            fetch();
        }
        if (meter) {
            dispatch(saveTreeItemToTagList(meter.id, `${meter.meterModel} ${meter.meterSerialNum}`));
        }
    }, [meterId, meter]);

    useEffect(() => {
        return () => {
            dispatch(resetMeterData());
        };
    }, []);

    const props: IStateProps = {
        meter,
        activeTab,
        uiConfig,
        tabs: [
            {
                key: MeterTabs.Information,
                title: 'ИНФОРМАЦИЯ О ИИК',
            },
            {
                key: MeterTabs.Polls,
                title: 'Опросы',
            },
            {
                key: MeterTabs.Reading,
                title: 'Показания',
            },
            {
                key: MeterTabs.Consumption,
                title: 'Потребление',
            },
            {
                key: MeterTabs.EventLog,
                title: 'Журналы',
            },
            {
                key: MeterTabs.Management,
                title: 'Управление',
            },
            meter?.readingSource === ReadingSource.ImportCrq && {
                key: MeterTabs.CrqInterface,
                title: 'CRQ-ИНТЕРФЕЙС',
            },
            {
                key: MeterTabs.Problems,
                title: ProblemTitle(),
            },
            {
                key: MeterTabs.Incidents,
                title: 'Инциденты',
            },
            {
                key: MeterTabs.Gis,
                title: 'Гис',
            },
        ],
        onChangeTab: (e, tab) => dispatch(push(getRoute(
            Routes.meter,
            { meterId },
            { tab }
        ))),
        breadcrumbs,
        onClickBreadcrumb,
    };

    return View(props);
};
