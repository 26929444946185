export enum InspectionSeverityType {
	Error = 1,
    Warning = 2,
    Info = 3,
    Dump = 4,
}

export const InspectionSeverityTypeDictionary: Record<InspectionSeverityType, string> = {
    [InspectionSeverityType.Error]: 'Ошибка',
    [InspectionSeverityType.Warning]: 'Предупреждение',
    [InspectionSeverityType.Info]: 'Информация',
    [InspectionSeverityType.Dump]: 'DUMP SPODES',
};
