export interface AddressesToJoin {
    areaName?: string;

    townName?: string;

    streetName?: string;

    houseNumber?: string;
}

export const joinAddressesString = (addresses: AddressesToJoin) => {
    if (!addresses) {
        return '';
    }
    return [
        addresses.areaName,
        addresses.townName,
        addresses.streetName,
        addresses.houseNumber,
    ].filter(a => !!a).join(', ')
};

export const addressesQueryParser = (address: string): AddressesToJoin[] => {
    try {
        return JSON.parse(address) as AddressesToJoin[];
    } catch {
        return null;
    }
};

export const authorizedAddressesKeys = [
    'areaName',
    'townName',
    'streetName',
    'houseNumber',
];

export const addressesQueryTransformer = (addresses: AddressesToJoin[]): string => {
    try {
        return JSON.stringify(addresses);
    } catch {
        return null;
    }
};
