import { View } from './view';
import { MeterUpdateAttributesActions } from '../../../../common/model/meter/meter/meterUpdateAttributesActions';
import { AccessRule } from '../../../../common/model/access/accessRule';
import { useAccessRules } from '../../../shared/hooks/useAccessRules';
import { MeterGisTasksStatus } from '../../../../common/model/enum/meterGisTasks';
import { MeterUpdateAttributeAction } from '../../meter/actions/model';
import React, { MouseEvent, useState } from 'react';
import { TaskActionProps } from './model';
import { modalNotificationsPop, modalNotificationsPush } from '../../../../redux/actions/controls/modalNotifications';
import { ModalNotificationVariant } from '../../../../redux/reducers/controls/modalNotifications';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { archivedMeterGisTask, closeMeterGisTask, getMeterGisTaskById, syncTasksWithGis } from '../../../../redux/actions/meterGisTasks';

export const TaskAction: React.FC<TaskActionProps> = ({ taskStatus, taskId }) => {
    const { hasAccess } = useAccessRules();
    const [actionsPopoverAnchor, setActionsPopoverAnchor] = useState(null);
    const isActionsPopoverOpen = Boolean(actionsPopoverAnchor);
    const dispatch = useDispatch<BaseDispatch>();

    const handleAction = (action: MeterUpdateAttributeAction) => {
        setActionsPopoverAnchor(null);
        action();
    };
    const handleOpenActionsPopover = (event: MouseEvent) => {
        setActionsPopoverAnchor(event.currentTarget);
    };

    const handleCloseActionsPopover = () => {
        setActionsPopoverAnchor(null);
    };

    const props = {
        handleAction,
        handleOpenActionsPopover,
        handleCloseActionsPopover,
        actionsPopoverAnchor,
        isActionsPopoverOpen,
        actions: [
            {
                key: MeterUpdateAttributesActions.ChangeCommunicationParams,
                name: taskStatus === MeterGisTasksStatus.open ? 'Закрыть' : 'Архивировать',
                hasAccess: hasAccess(AccessRule.CanUpdateMeterGisTask),
                action: () => {
                    if (taskStatus === MeterGisTasksStatus.open) {
                        dispatch(modalNotificationsPush({
                            showHide: true,
                            variant: ModalNotificationVariant.Alert,
                            title: 'Закрытие инцидента',
                            message: 'Вы уверены, что хотите закрыть инцидент?',
                            actions: [
                                {
                                    children: 'Да, закрыть',
                                    variant: 'primary',
                                    onClick: async () => {
                                        await dispatch(closeMeterGisTask({
                                            id: taskId,
                                        }));
                                        await dispatch(modalNotificationsPop());
                                    },
                                    onEnter: true,
                                },
                                {
                                    children: 'Отмена',
                                    variant: 'secondary',
                                    onClick: async () => {
                                        await dispatch(modalNotificationsPop());
                                    },
                                    onEscape: true,
                                }
                            ],
                        }));
                    } else {
                        dispatch(modalNotificationsPush({
                            showHide: true,
                            variant: ModalNotificationVariant.Alert,
                            title: 'Архивирование инцидента',
                            message: 'Вы уверены, что хотите отправить инцидент в архив?',
                            actions: [
                                {
                                    children: 'Да, архивировать',
                                    variant: 'primary',
                                    onClick: async () => {
                                        await dispatch(archivedMeterGisTask({ id: taskId }));
                                        await dispatch(modalNotificationsPop());
                                    },
                                    onEnter: true,
                                },
                                {
                                    children: 'Отмена',
                                    variant: 'secondary',
                                    onClick: async () => {
                                        await dispatch(modalNotificationsPop());
                                    },
                                    onEscape: true,
                                }
                            ],
                        }));
                    }
                },
            },
            {
                key: MeterUpdateAttributesActions.ChangeCommunicationParams,
                name: 'Синхронизировать',
                hasAccess: hasAccess(AccessRule.CanUpdateMeterGisTask),
                action: async () => {
                    await dispatch(syncTasksWithGis({ syncIds: [taskId] }));
                    await dispatch(modalNotificationsPop());
                    await dispatch(getMeterGisTaskById({ taskId }));
                },
            },
        ],
    };

    return View(props);
};
