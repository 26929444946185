import { combineReducers } from 'redux';
import { metersHealthSlice, MetersHealthState } from './metersHealth';
import { surveyConditionSlice, SurveyConditionState } from './surveyCondition';

export interface MonitoringState {
    surveyCondition: SurveyConditionState;
    metersHealth: MetersHealthState;
}

export const monitoring = combineReducers<MonitoringState>({
    surveyCondition: surveyConditionSlice.reducer,
    metersHealth: metersHealthSlice.reducer,
});
