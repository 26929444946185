import { combineReducers } from 'redux';
import { crqSystemListSlice, CrqSystemState } from './list';
import { editableCrqSystemSlice, EditableCrqSystemState } from './editable';

export interface CrqSystemsState {
    list: CrqSystemState;
    editable: EditableCrqSystemState;
}

export const crqSystem = combineReducers<CrqSystemsState>({
    list: crqSystemListSlice.reducer,
    editable: editableCrqSystemSlice.reducer,
});
