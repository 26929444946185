import { DayOfWeek } from '../../model/meter/dayOfWeek';

export const getDateDayOfWeek = (date: Date): DayOfWeek => {
    return date.getDay();
};

export const monthsDictionary = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const getMaxDate = (data: Date[]): Date => {
    if (data.length === 0) {
        return null;
    }
    return new Date(
        Math.max(
            ...data
                .filter(item => !!item)
                .map(item => new Date(item).getTime())
        )
    );
};

export const getMinDate = (data: Date[]): Date => {
    if (data.length === 0) {
        return null;
    }
    return new Date(
        Math.min(
            ...data
                .filter(item => !!item)
                .map(item => new Date(item).getTime())
        )
    );
};
