const defaultWait = 500;

const timeouts = {};

export const debounce = <T> (func: any, wait = defaultWait, immediate?: boolean): Promise<T> => {

    clearTimeout(timeouts[func]);

    if (immediate) {
        return func.apply();
    } else {
        return new Promise<T>((resolve, reject) => {
            timeouts[func] = setTimeout(() => {
                (async () => {
                    timeouts[func] = null;
                    let result: T = null;
                    try {
                        result = await func.apply();
                    } catch (err) {
                        return reject(err);
                    }
                    return resolve(result);
                })();
            }, wait);
        });
    }
};
