import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AccessRule } from '../../../../../../common/model/access/accessRule';
import { useAccessRules } from '../../../../../shared/hooks/useAccessRules';
import { RoleFormModel, RoleFormProps } from './model';
import { View } from './view';

const initialRole: RoleFormModel = {
    name: '',
    code: '',
    accessRules: [],
};

export const RoleForm = (props: RoleFormProps) => {
    const { role } = props;
    const defaultValues = role ?? initialRole;
    const form = useForm<RoleFormModel>({ defaultValues });
    useEffect(() => {
        form.reset(defaultValues);
    }, [defaultValues]);

    const { dictionaryAccessState } = useAccessRules();

    return View({
        ...props,
        form,
        accessRoleOptions: Object.values(AccessRule).map((key) => ({
            value: key,
            label: dictionaryAccessState[key]?.name ?? key,
        })),
    });
};
