import { useDispatch, useSelector } from 'react-redux';
import { AddressViewModel } from '../../../../../common/model/meter/addressViewModel/addressViewModel';
import { BaseDispatch } from '../../../../../redux/actions';
import { findAddressSuggestions } from '../../../../../redux/actions/addresses';
import { addressSuggestionsLoadingSelector, addressSuggestionsSelector } from '../../../../../redux/selectors/addresses';
import { defaults } from '../../../constants';
import { debounce } from '../../../pipes';
import { AutocompleteOptionsHookReturn, VAutocompleteOption } from '../model';

export interface AddressAutocompleteOptions {
    valueMapKey?: keyof AddressViewModel;
}

export const useAddressAutocomplete = (options: AddressAutocompleteOptions = {}): AutocompleteOptionsHookReturn => {
    const { valueMapKey = 'id' } = options;
    const dispatch = useDispatch<BaseDispatch>();
    const addressSuggestions = useSelector(addressSuggestionsSelector);
    const isAddressesLoading = useSelector(addressSuggestionsLoadingSelector);
    const onSearchAddress = async (value?: string) => {
        await debounce(() => dispatch(
            findAddressSuggestions(value)
        ), defaults.debounceTimeForAutocompleteSearchMs);
    };

    const addressOptions: VAutocompleteOption[] = addressSuggestions?.map(address => ({
        label: address.address,
        optionsLabel: address.address,
        value: address[valueMapKey],
    }));

    return {
        options: addressOptions,
        onInputChange: (event, value) => onSearchAddress(value),
        loading: isAddressesLoading,
    };
};
