import { Column } from '@softmedialab/materialui-table';

export interface ReportsViewProps {
    columns: Column[];
    data: ReportsTabData[];
}

export interface ReportsTabData {
    name: string;
    key: ReportType;
    disabled: boolean;
}

export enum ReportType {
    MetersNotConnectedReport = 'meters-not-connected-report',
    MeterProfileReport = 'meter-profile-report',
    MeterElectricityConsumptionReport = 'meter-electricity-consumption-report',
    MeterElectricityTariffReport = 'meter-electricity-tariff-report',
    CrashByRegionsReport = 'crash-by-regions-report',
    ConfigurationHistoryReport = 'configuration-history-report',
    ElectricityConsumptionBillingReport = 'meter-electricity-consumption-billing-report',
    AdvancedCrashReport = 'advanced-crash-report',
}
