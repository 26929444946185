import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ExportDocument80020Creator } from '../../../../common/model/meter/exportDocument80020Creator';
import { ExportDocument80020ViewModel } from '../../../../common/model/meter/exportDocument80020ViewModel';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import { getExportDocument8002080040 } from '../../../../redux/actions/export';
import { useMeterAutocomplete } from '../../../shared/components/autocomplete/hooks/useMeterAutocomplete';
import { useMeterGroupAutocomplete } from '../../../shared/components/autocomplete/hooks/useMeterGroupAutocomplete';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { ExportDocumentFormats } from '../../../shared/constants/enums';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { useWebDictionarySelectSuggestions } from '../../../shared/hooks/useWebDictionary';
import { getCurrentDate } from '../../../shared/utils/dates';
import { downloadXmlDataWindows1251Encoding } from '../../../shared/utils/download';
import { getErrorMessage } from '../../../shared/utils/error';
import { useYupValidationResolver } from '../../../shared/utils/validation';
import yup from '../../../shared/utils/yup';
import { View } from './view';
import { innLength } from '../../../../common/constants/inn';

const maxDate = moment(getCurrentDate()).startOf('day').add(-1, 'day').toDate();

const exportDocument80020CreatorValidationSchema = yup.object({
    regionId: yup.number().required('Регион обязательное поле'),
    documentFormat: yup.string().required('Формат xml документа обязательное поле'),
    date: yup.string().required('Дата обязательное поле'),
    senderInn: yup.string().length(innLength, `должно быть длинной ${innLength} символов`).required('ИНН отправителя обязательное поле'),
    senderName: yup.string().required('Наименование отправителя обязательное поле'),
    areaInn: yup.string().length(innLength, `должно быть длинной ${innLength} символов`).required('ИНН получателя обязательное поле'),
    areaName: yup.string().required('Наименование получателя обязательное поле'),
});

export const Export8002080040: React.FC = () => {

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch<BaseDispatch>();

    const [formedDocument, setFormedDocument] = useState<ExportDocument80020ViewModel>(null);

    const [formQueryParams, setFormQueryParams] = useSyncQueryParams<ExportDocument80020Creator>({});
    const resolver = useYupValidationResolver<ExportDocument80020Creator>(exportDocument80020CreatorValidationSchema);
    const form = useForm<ExportDocument80020Creator>({
        defaultValues: formQueryParams,
        resolver
    });

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const formData = form.watch();

    const regionId = formData.regionId;

    const exportDocumentFormatSuggestions: SelectSuggestion[] = ExportDocumentFormats;

    const isMeterGroupDisabled = !!formData.meterId;
    const isMeterDisabled = !!formData.meterGroupId;

    const meterAutocomplete = useMeterAutocomplete({
        id: formData.meterId,
        regionId,
    });

    const meterGroupAutocomplete = useMeterGroupAutocomplete({
        regionId: formData.regionId,
        isStatic: true,
    });

    return View({
        form,
        maxDate,
        isLoading,
        formedDocument,
        isMeterDisabled,
        meterAutocomplete,
        regionSuggestions,
        isMeterGroupDisabled,
        meterGroupAutocomplete,
        exportDocumentFormatSuggestions,
        onSubmit: async (data) => {
            setIsLoading(true);
            setFormedDocument(null);
            try {
                setFormQueryParams({ ...data });
                const response = await getExportDocument8002080040(data);
                setFormedDocument(response);
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsLoading(false);
        },
        onDownload: () => {
            if (formedDocument) {
                downloadXmlDataWindows1251Encoding(formedDocument.fileContent, formedDocument.fileName);
            }
        }
    });
};
