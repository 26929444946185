import { orderBy, uniqWith } from 'lodash';
import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { AppThunk } from '../index';
import { ISort } from '../../../common/sort';
import { InspectionLogQuery } from '../../../common/model/inspectionLog/inspectionLogQuery';
import { InspectionLogViewResponse } from '../../../common/model/inspectionLog/inspectionLogViewResponse';
import { commonInspectionLogSlice } from '../../reducers/inspectionLogs/common';
import { InspectionLogEntryViewModel } from 'common/model/inspectionLog/inspectionLogEntryViewModel/inspectionLogEntryViewModel';

export const findInspectionLogs = (options?: InspectionLogQuery, sort?: ISort): AppThunk => async (dispatch) => {
    dispatch(commonInspectionLogSlice.actions.setLoading(true));
    try {
        const route = getApiRoute(appUrls.api_get_inspection_log, {}, { ...options, ...sort });
        const data = await httpService.get<InspectionLogViewResponse>(route);
        dispatch(commonInspectionLogSlice.actions.setInspectionLogs(data));
    } finally {
        dispatch(commonInspectionLogSlice.actions.setLoading(false));
    }
};

export const loadInspectionLogsToStartAndIsFinal = (
    stateLimit: number, options?: InspectionLogQuery, sort?: ISort
): AppThunk => async (dispatch, getState): Promise<boolean> => {
    dispatch(commonInspectionLogSlice.actions.setLoading(true));
    const route = getApiRoute(appUrls.api_get_inspection_log, {}, { ...options, ...sort });
    const data = await httpService.get<InspectionLogViewResponse>(route);
    const state = getState();
    const logs = [...(state.inspectionLogs.common.available || [])];
    logs.unshift(...data.data);
    const uniqLogs = uniqWith(logs, (a, b) => a.partition === b.partition && a.offset === b.offset && a.createdAt === b.createdAt);
    const slicedLogs = uniqLogs.slice(0, stateLimit);
    const sortedLogs = orderBy(slicedLogs, ['createdAt', 'partition', 'offset'], ['desc', 'desc', 'desc']);
    dispatch(commonInspectionLogSlice.actions.setInspectionLogs({
        data: sortedLogs,
        total: slicedLogs.length,
    }));
    dispatch(commonInspectionLogSlice.actions.setLoading(false));
    return data.data.length < sort.count;
};

export const loadInspectionLogsToEndAndIsFinal = (
    stateLimit: number, options?: InspectionLogQuery, sort?: ISort
): AppThunk => async (dispatch, getState): Promise<boolean> => {
    dispatch(commonInspectionLogSlice.actions.setLoading(true));
    const route = getApiRoute(appUrls.api_get_inspection_log, {}, { ...options, ...sort });
    const data = await httpService.get<InspectionLogViewResponse>(route);
    const state = getState();
    const logs = [...(state.inspectionLogs.common.available || [])];
    logs.push(...data.data);
    const uniqLogs = uniqWith(logs, (a, b) => a.partition === b.partition && a.offset === b.offset && a.createdAt === b.createdAt);
    const slicedLogs = uniqLogs.slice(-stateLimit);
    const sortedLogs = orderBy(slicedLogs, ['createdAt', 'partition', 'offset'], ['desc', 'desc', 'desc']);
    dispatch(commonInspectionLogSlice.actions.setInspectionLogs({
        data: sortedLogs,
        total: slicedLogs.length,
    }));
    dispatch(commonInspectionLogSlice.actions.setLoading(false));
    return data.data.length < sort.count;
};

export const addLogToInspectionLogs = (
    log: InspectionLogEntryViewModel,
    filter: InspectionLogQuery,
    limit: number,
): AppThunk => (dispatch, getState) => {
    const state = getState();
    const user = state.users.active.user;
    const logs = [...(state.inspectionLogs.common.available || [])];
    const isLogExist = logs.some(log_ => log_.partition === log.partition && log_.offset === log.offset);

    if (isLogExist) {
        return;
    }
    if (filter.severity && log.severity !== +filter.severity) {
        return;
    }
    if (filter.orderType && filter.orderType !== log.orderType) {
        return;
    }

    if (filter.meterActionType.length && !filter.meterActionType.includes(log.meterActionType)) {
        return;
    }

    if (filter.isMy && log.userId !== user.id) {
        return;
    }

    if (filter.text && !log.message.toLowerCase()?.includes(filter.text.toLowerCase())) {
        return;
    }

    if (filter.text && !log.message.toLowerCase()?.includes(filter.text.toLowerCase())) {
        return;
    }

    if (filter.maxCreatedAt && new Date(filter.maxCreatedAt).getTime() < new Date(log.createdAt).getTime()) {
        return;
    }

    if (filter.minCreatedAt && new Date(filter.minCreatedAt).getTime() > new Date(log.createdAt).getTime()) {
        return;
    }
    const initialTotal = logs.length;
    logs.unshift(log);
    const sortedLogs = orderBy(logs, ['createdAt', 'partition', 'offset'], ['desc', 'desc', 'desc']);

    const data = sortedLogs.slice(-limit);

    dispatch(commonInspectionLogSlice.actions.setInspectionLogs({ data, total: +initialTotal + 1 }));
};
