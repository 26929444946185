import { useMemo, useRef, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import FilterListIcon from '@material-ui/icons/FilterList';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { PaginationParams, useActualPage } from '../../hooks/useActualPage';
import { useSyncQueryParams } from '../../hooks/useQueryParams';
import { InputModel } from './model';
import { View } from './view';
import { useWindowSize } from '../../hooks';
import { mergeDeep } from '../../pipes';
import { isNullOrUndefined } from '../../../../common/shared/utils';
import { defaults } from '../../constants';
import { TableColumnsVisualizationItem } from '../../../../common/model/tableColumns';

export const Table = (ownerProps: InputModel) => {

    const [groupedColumns, setGroupedColumns] = useState<any[]>([]);
    const [filteredColumns, setFilteredColumns] = useState<any[]>([]);

    const size = useWindowSize();

    const savedColumns: TableColumnsVisualizationItem[] = null;

    const clientExportNumericDecimalSeparator = ',';

    const clientExportCsvValueSeparator = defaults.exportDelimiter;

    const clientDatetimeLocaleString = 'ru-RU';

    const [filterParams, updateFilterParams] = useSyncQueryParams<PaginationParams>({
        page: 1,
        rowsPerPage: ownerProps.options?.pageSize ?? 10,
        orderBy: null,
        orderDirection: null,
    });

    const actualPage = useActualPage((page) => {
        if (
            ownerProps.useQueryString
            && ownerProps.data
            && filterParams.page !== page
        ) {
            updateFilterParams({ page });
        }
    }, [ownerProps.data?.length | 0, filterParams]);

    const { title, tableRef: propTableRef, ...ownerPropsForMerge } = ownerProps;

    const tableRef = propTableRef ?? useRef<any>();

    const props = useMemo(() => {
        const groupingColumns = ownerProps.columns
            .filter(column => column.grouping === true || column.grouping === undefined)
            .map(column => column.title);

        if (tableRef?.current && filterParams.page !== actualPage) {
            tableRef.current.dataManager.changeCurrentPage(actualPage - 1);
        }

        const clientRectHeight = size.height;

        const result: InputModel = mergeDeep({
            rowStyle: { minHeight: '48px' },
            icons: {
                Add: AddIcon,
                Check: CheckIcon,
                Clear: ClearIcon,
                Delete: DeleteOutlineIcon,
                DetailPanel: ChevronRightIcon,
                Edit: EditIcon,
                Export: SaveAltIcon,
                Filter: FilterListIcon,
                FirstPage: FirstPageIcon,
                LastPage: LastPageIcon,
                NextPage: ChevronRightIcon,
                PreviousPage: ChevronLeftIcon,
                ResetSearch: ClearIcon,
                Search: SearchIcon,
                SortArrow: ArrowUpwardIcon,
                ThirdStateCheck: RemoveIcon,
                ViewColumn: ViewColumnIcon,
            },
            options: {
                emptyRowsWhenPaging: false,
                grouping: (!groupingColumns.length) ? false : undefined,
                pageSize: 20,
                pageSizeOptions: [],
                maxBodyHeight: (ownerProps.fullScreenHeightDelta > 0) ? clientRectHeight - ownerProps.fullScreenHeightDelta : undefined,
                actionsColumnIndex: ownerProps.columns.length,
                addRowPosition: 'first',
                filterType: 'header',
                exportNumericDecimalSeparator: clientExportNumericDecimalSeparator,
                exportNumericNullToZero: true,
                exportTotals: true,
                datetimeLocaleString: clientDatetimeLocaleString,
                exportDelimiter: clientExportCsvValueSeparator,
                strictDigits: true,
                headerStyle: { position: 'sticky', top: 0, height: 32 },
                rowStyle: { height: 32 },
                ...(
                    ownerProps.useQueryString
                        ? {
                            initialPage: actualPage - 1,
                            pageSize: filterParams.rowsPerPage
                        }
                        : { pageSize: 10 }
                )
            },
            localization: {
                pagination: {
                    labelDisplayedRows: '{from}-{to} из {count}',
                    labelRowsSelect: 'строк',
                    firstTooltip: 'В начало',
                    previousTooltip: 'Предыдущая страница',
                    nextTooltip: 'Следующая страница',
                    labelRowsPerPage: 'Строк на странице',
                    lastTooltip: 'В конец',
                },
                toolbar: {
                    nRowsSelected: '{0} строк выбрано',
                    addRemoveColumns: 'Отметьте видимые колонки',
                    showColumnsTitle: 'Видимость колонок',
                    showColumnsAriaLabel: 'Видимость колонок',
                    searchTooltip: 'Поиск',
                    searchPlaceholder: 'Поиск',
                    exportTitle: 'Экспорт в CSV',
                    exportName: 'CSV'
                } as any,
                header: {
                    actions: 'Действия'
                },
                body: {
                    addTooltip: 'Добавить',
                    deleteTooltip: 'Удалить',
                    editTooltip: 'Редактировать',
                    emptyDataSourceMessage: 'Нет данных',
                    filterRow: {
                        filterTooltip: 'Фильтр'
                    },
                    editRow: {
                        cancelTooltip: 'Отмена',
                        saveTooltip: 'Ok',
                        deleteText: 'Вы уверены, что хотите удалить эту строку?',
                    }
                },
                grouping: (groupingColumns.length > 0)
                    ? {
                        groupedBy: 'Сгруппировано по:',
                        placeholder: `Для группировки перенесите сюда заголовок колонки (${groupingColumns
                            .filter(item => typeof item === 'string')
                            .join()})`,
                    }
                    : undefined,
                filter: {
                    clearFilter: 'Очистить',
                    selectAll: 'Выбрать все',
                },
            },
            onChangePage: (page: number) => {
                if (ownerProps.useQueryString) {
                    updateFilterParams({
                        page: page + 1
                    });
                }
            },
            onOrderChange: (orderBy: number, orderDirection: ('asc' | 'desc')) => {
                if (ownerProps.useQueryString) {
                    updateFilterParams({
                        orderBy: ownerProps.columns[orderBy].field,
                        orderDirection,
                        page: 1,
                    });
                }
            },
            onChangeColumnGroups: (groups: any) => setGroupedColumns(groups),
            onChangeFilter: (filter: any) => setFilteredColumns(filter),
        }, ownerPropsForMerge);

        result.title = title;
        result.tableRef = tableRef;

        if (savedColumns) {
            const clientColumnsObject = {};
            savedColumns.forEach((item, index) => {
                clientColumnsObject[item.name] = item;
                clientColumnsObject[item.name].index = index;
            });

            result.columns = result.columns
                .map(column => {
                    const res = column;
                    const description = clientColumnsObject[column.field];
                    if (isNullOrUndefined(description)) {
                        res.hidden = true;
                    } else {
                        res.hidden = description.hidden;
                    }
                    return res;
                })
                .sort((a, b) => {
                    const a_value = isNullOrUndefined(clientColumnsObject[a.field])
                        ? Number.MAX_SAFE_INTEGER
                        : clientColumnsObject[a.field].index;
                    const b_value = isNullOrUndefined(clientColumnsObject[b.field])
                        ? Number.MAX_SAFE_INTEGER
                        : clientColumnsObject[b.field].index;
                    if (a_value === b_value) {
                        return 0;
                    }
                    return (a_value > b_value) ? 1 : -1;
                });
        }

        if ((groupedColumns && groupedColumns.length)
            || (filteredColumns && filteredColumns.length)) {
            result.columns = result.columns
                .map(column => {
                    const res = column;
                    const findedGroup = groupedColumns?.find(item => item.field === res.field) || null;
                    if (findedGroup) {
                        res.defaultGroupOrder = findedGroup.groupOrder;
                        res.defaultGroupSort = findedGroup.groupSort;
                    }
                    const findedFilter = filteredColumns?.find(item => item.field === res.field) || null;
                    if (findedFilter) {
                        res.defaultFilter = findedFilter.filterValue;
                    }
                    return res;
                });
        }

        result.data = (ownerProps.data && ownerProps.data.length && Array.isArray(ownerProps.data))
            ? (ownerProps.data).map((item: any) => ({ ...item }))
            : ownerProps.data;

        result.columns = result.columns.map(res => {
            if (ownerProps.useQueryString && res.sorting) {
                res.defaultSort = filterParams.orderBy === res.field && filterParams.orderDirection
                    ? filterParams.orderDirection
                    : res.defaultSort;
            } else if (res.sorting && ownerProps.orderBy && ownerProps.orderDirection) {
                res.defaultSort = ownerProps.orderBy === res.field && ownerProps.orderDirection
                    ? ownerProps.orderDirection
                    : res.defaultSort;
            }
            return res;
        });

        return result;
    }, [ownerProps.columns, ownerProps.data, filterParams, savedColumns]);

    return useMemo(() => View(props), [props.data, props.columns, savedColumns]);
};
