import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../shared/components/button';
import { Forbidden } from '../../../../shared/components/forbidden';
import { HookField } from '../../../../shared/components/hookField';
import { Typography } from '../../../../shared/components/typography';
import { StateProps } from './model';
import { useUpdateFromAttributesStyles } from '../styles';

export const View = ({ form, onSubmit, onCancel, hasAccess }: StateProps) => {

    if (!hasAccess) {
        return <Forbidden />;
    }

    const { handleSubmit, formState: { isDirty, dirtyFields } } = form;
    const classes = useUpdateFromAttributesStyles();

    return <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={4} className={classes.inputLabel}>
                            <Typography variant='bodyBold'>Коэффициент трансформации по току</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <HookField
                                name='cTRatio'
                                type='text'
                                label='Коэф-т трансф-и по току'
                                inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['cTRatio'] }) }}
                                fullWidth />
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={4} className={classes.inputLabel}>
                            <Typography variant='bodyBold'>Коэффициент трансформации по напряжению</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <HookField
                                name='pTRatio'
                                type='text'
                                label='Коэф-т трансф-и по напряжению'
                                inputProps={{ className: classNames({ [classes.pristineField]: !dirtyFields['pTRatio'] }) }}
                                fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                        <Grid item>
                            <Button variant='primary' type='submit' disabled={!isDirty} icon='check'>Сохранить</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </FormProvider>;
};
