export enum LogEntrySource {
	Undefined,
	Audit,
	PublicApi,
	PrivateApi,
}

export const logSourceDictionary: Record<LogEntrySource, string> = {
    [LogEntrySource.Undefined]: 'Неизвестно',
    [LogEntrySource.Audit]: 'Аудит',
    [LogEntrySource.PublicApi]: 'Публичный API',
    [LogEntrySource.PrivateApi]: 'Приватный API',
};
