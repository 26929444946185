import { MeterDictionariesNames } from '../../../common/model/meter/meterDictionaries/meterDictionariesNames';
import { AppThunk } from '../index';
import { getApiRoute, RequestParams } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { httpService } from '../../../app/shared/httpWrapper';
import { dictionariesSlice } from '../../reducers/dictionaries/dictionaries';
import { MeterDictionariesViewModel } from '../../../common/model/meter/meterDictionaries/meterDictionariesViewModel';
import { MeterDictionaryValuesViewModel } from '../../../common/model/meter/meterDictionaryValues/meterDictionaryValuesViewModel';

export const getDictionaries = (): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_dictionaries);
    const data = await httpService.get<MeterDictionariesViewModel[]>(route);
    dispatch(dictionariesSlice.actions.setAvailableDictionaries(data));
};

export const getMeterDictionaryValues = (
    dictionaryName: MeterDictionariesNames,
    props?: {search: string},
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_dictionary_values_by_name, { dictionaryName }, { ...props });
    const data = await httpService.get<MeterDictionaryValuesViewModel[]>(route);
    dispatch(dictionariesSlice.actions.setMeterGisTasksNameTypes(data));
};

export const updateMeterDictionaryValue = (params: MeterDictionaryValuesViewModel): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_put_meter_dictionary_value);
    const updatedMeterGisTaskName = await httpService.put<MeterDictionaryValuesViewModel>(route, { ...params });
    dispatch(dictionariesSlice.actions.updateMeterGisTaskName(updatedMeterGisTaskName));
};

export const deleteMeterDictionaryValue = (params: RequestParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_delete_meter_dictionary_value, { ...params });
    await httpService.delete(route);
    dispatch(dictionariesSlice.actions.deleteMeterGisTaskName({ id: params.id }));
};

export const createMeterDictionaryValue = (params: MeterDictionaryValuesViewModel): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_create_meter_dictionary_value);
    const createdMeterGisTaskName = await httpService.post<MeterDictionaryValuesViewModel>(route, { ...params });
    dispatch(dictionariesSlice.actions.creatMeterGisTaskName(createdMeterGisTaskName));
};
