import { Box, Grid, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { DateFormats } from '../../../../common/constants/date';
import { Button } from '../../../shared/components/button';
import { HookField } from '../../../shared/components/hookField';
import { Wrapper } from '../../../shared/components/wrapper';
import React from 'react';
import { formatDate, parseDate } from '../../../shared/utils/dates';
import { StateProps } from './model';
import { FormProvider } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) => ({
    emptyText: {
        color: theme.palette.text.hint,
        ...theme.typography.caption,
    },
    fileName: {
        color: theme.palette.text.primary,
        ...theme.typography.bodyBold,
    }
}));

export const View: React.FC<StateProps> = (props) => {
    const {
        form,
        maxDate,
        onSubmit,
        isLoading,
        onDownload,
        formedDocument,
        isMeterDisabled,
        meterAutocomplete,
        regionSuggestions,
        isMeterGroupDisabled,
        meterGroupAutocomplete,
        exportDocumentFormatSuggestions,
    } = props;

    const classes = useStyles();

    const { handleSubmit } = form;

    const isFormed = !!formedDocument;

    return <>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Wrapper title='Фильтры'>
                    <FormProvider {...form}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <HookField
                                            name='regionId'
                                            type='select'
                                            label='Регион'
                                            isClearable
                                            fullWidth
                                            suggestions={regionSuggestions}
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HookField
                                            name='documentFormat'
                                            type='select'
                                            isClearable
                                            label='Формат xml документа'
                                            fullWidth
                                            suggestions={exportDocumentFormatSuggestions}
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HookField
                                            name='meterGroupId'
                                            type='autocomplete'
                                            label='Группа ПУ'
                                            disabled={isMeterGroupDisabled || isLoading}
                                            {...meterGroupAutocomplete}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HookField
                                            type='autocomplete'
                                            name='meterId'
                                            label='Номер ПУ'
                                            disabled={isMeterDisabled || isLoading}
                                            fullWidth
                                            {...meterAutocomplete}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HookField
                                            name='senderInn'
                                            type='text'
                                            label='ИНН отправителя'
                                            fullWidth
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HookField
                                            name='senderName'
                                            type='text'
                                            label='Наименование отправителя'
                                            fullWidth
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HookField
                                            name='areaInn'
                                            type='text'
                                            label='ИНН получателя'
                                            fullWidth
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HookField
                                            name='areaName'
                                            type='text'
                                            label='Наименование получателя'
                                            fullWidth
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HookField
                                            fullWidth
                                            name='date'
                                            type='datepicker'
                                            maxDate={maxDate}
                                            disabled={isLoading}
                                            label='Измерения на дату'
                                            transform={{
                                                input: (v) => {
                                                    return v
                                                        ? parseDate(v, DateFormats.dateFormat)
                                                        : '';
                                                },
                                                output: (v) => {
                                                    return v
                                                        ? formatDate(v, DateFormats.dateFormat)
                                                        : null;
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Button
                                    type='submit'
                                    disabled={isLoading}
                                    icon={isFormed || isLoading ? 'refresh' : undefined}
                                    variant={isFormed || isLoading ? 'secondary' : 'primary'}
                                >
                                    {isFormed || isLoading ? 'Обновить' : 'Сформировать'}
                                </Button>
                            </Box>
                        </form>
                    </FormProvider>
                </Wrapper>
            </Grid>
            <Grid item xs={6}>
                <Wrapper title='ФАЙЛ'>
                    {isFormed && (
                        <Box>
                            <Box mb={2}>
                                <div className={classes.fileName}>
                                    {formedDocument.fileName}
                                </div>
                            </Box>
                            <Box>
                                <Button
                                    icon='download'
                                    variant='primary'
                                    type='button'
                                    onClick={onDownload}
                                >
                                    Скачать
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {!isFormed && (
                        <div className={classes.emptyText}>
                            Заполните фильтры и сформируйте файл для экспорта
                        </div>
                    )}
                </Wrapper>
            </Grid>
        </Grid>
    </>;
};
