import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    wrapperChildren: {
        backgroundColor: theme.palette.neutral.white,
    },
    table: {
        backgroundColor: theme.palette.neutral.white,
    },
    rightTableTitle: {
        ...theme.typography.bodyBold,
        textTransform: 'uppercase',
        marginBottom: 0,
    },
    rightTableSubTitle: {
        ...theme.typography.body,
    },
    tableContainer: {
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
        },
        '& .custom-table': {
            '& .MuiTableRow-root:not(:hover) .btn-details': {
                visibility: 'hidden',
            },
        },
    },
}));
