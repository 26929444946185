import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

export const meterPowerSwitchOrdersBaseSelector = (state: StorageState) => state.meterPowerSwitchOrders;

export const meterPowerSwitchOrdersDetailsSelector = createSelector(
    meterPowerSwitchOrdersBaseSelector,
    (state) => state.details,
);

export const meterPowerSwitchOrdersSelector = (state: StorageState) =>
    state.meterPowerSwitchOrders.list.orders;

export const meterPowerSwitchRequestsSelector = (state: StorageState) =>
    state.meterPowerSwitchOrders.list.requests || [];

export const meterPowerSwitchTotalCountRequestsSelector = (state: StorageState) =>
    state.meterPowerSwitchOrders.list.totalCountRequests;
