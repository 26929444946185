import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import {
    ElectricityTariffReportData,
    ElectricityTariffReportQuery,
} from '../../../../../common/model/meter/reports/electricityTariffReport';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import {
    getElectricityTariffReport,
    resetElectricityTariffReport,
} from '../../../../../redux/actions/reports';
import { electricityTariffReportSelector } from '../../../../../redux/selectors/reports/electricityTariffReport';
import { useMeterGroupAutocomplete } from '../../../../shared/components/autocomplete/hooks/useMeterGroupAutocomplete';
import {
    defaults,
    MeterTabs,
    ReportPageTabs,
    ReportTabs,
    Routes,
} from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useSyncQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useWebDictionarySelectSuggestions } from '../../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../../shared/pipes';
import { getCurrentDate } from '../../../../shared/utils/dates';
import { getErrorMessage } from '../../../../shared/utils/error';
import { transformDateParams } from '../../../../shared/utils/transformers/dates';
import { MeterSerialNumView } from '../common/meterSerianNumView';
import { makeExcelReport } from './excel';
import { transformElectricityTariffReportData } from './helper';
import { View } from './view';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';

const filterFormDefaultValues: ElectricityTariffReportQuery = {
    date: moment(new Date()).endOf('day').toDate(),
    meterGroupId: undefined,
    regionId: defaults.defaultRegionId,
};

export const ElectricityTariffReport = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const report = useSelector(electricityTariffReportSelector());
    const [isLoading, setIsLoading] = useState(false);
    const [reportDate, setReportDate] = useState(getCurrentDate());
    const { notHasAccess } = useAccessRules();
    const [
        queryParams,
        setQueryParams,
    ] = useSyncQueryParams<ElectricityTariffReportQuery>(filterFormDefaultValues);
    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();
    const params = useParams<{ meterId?: string }>();
    const meterId = params.meterId;

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        {
            name: 'Отчетность',
            link: getRoute(Routes.report, { meterId }, { tab: ReportTabs }),
        },
        {
            name: 'Показания электроэнергии по тарифам',
            link: getRoute(
                Routes.report,
                { meterId },
                { tab: ReportPageTabs.NotConnectedMeters }
            ),
        },
    ]);

    const form = useForm<ElectricityTariffReportQuery>({
        defaultValues: queryParams,
    });

    const formData = form.watch();

    const fetch = async () => {
        setIsLoading(true);
        try {
            setQueryParams(transformDateParams(formData));
            await dispatch(getElectricityTariffReport(transformDateParams(formData)));
            setReportDate(getCurrentDate());
        } catch (e) {
            dispatch(
                createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                })
            );
        }
        setIsLoading(false);
    };

    const headingOnBackHandler = () => {
        dispatch(push(Routes.report));
    };

    useEffect(() => {
        return () => {
            dispatch(resetElectricityTariffReport());
        };
    }, []);

    const data = useMemo(
        () =>
            report?.data
                ? report.data.map((item) => transformElectricityTariffReportData(item))
                : null,
        [report]
    );

    const columns: Column[] = [
        {
            title: 'Район',
            field: 'areaName',
            cellClassName: 'col-district',
            sorting: true,
        },
        {
            title: 'Населённый пункт',
            field: 'townName',
            cellClassName: 'col-city',
            sorting: true,
        },
        {
            title: 'Улица',
            field: 'streetName',
            cellClassName: 'col-street',
            sorting: true,
        },
        {
            title: 'Дом',
            field: 'houseNumber',
            cellClassName: 'col-home',
            sorting: true,
        },
        {
            title: 'Квартира',
            field: 'addressSpecifier',
            cellClassName: 'col-apartment',
            sorting: true,
        },
        {
            title: 'Заводской номер ПУ',
            field: 'meterSerialNum',
            cellClassName: 'col-meterSerialNum',
            sorting: true,
            render: (item: ElectricityTariffReportData) => {
                return (
                    <MeterSerialNumView
                        label={item.meterSerialNum}
                        url={getRoute(
                            Routes.meter,
                            { meterId: item.meterId },
                            { tab: MeterTabs.Information }
                        )}
                    />
                );
            },
        },
        {
            title: 'Код АСКУЭРР',
            field: 'code',
            cellClassName: 'col-code',
            sorting: true,
        },

        {
            title: 'Тип прибора учёта',
            field: 'meterModel',
            cellClassName: 'col-code',
            sorting: true,
        },
        {
            title: 'Назначение прибора',
            field: 'meterPurpose',
            cellClassName: 'col-code',
            sorting: true,
        },
        {
            title: 'Правовая форма владельца',
            field: 'entityType',
            cellClassName: 'col-code',
            sorting: true,
        },
        {
            title: 'Схема опроса',
            field: 'actionScheme',
            cellClassName: 'col-code',
            sorting: true,
        },
        {
            title: 'IP',
            field: 'ipAddress',
            cellClassName: 'col-code',
            sorting: true,
        },
        {
            title: 'port',
            field: 'ipPort',
            cellClassName: 'col-code',
            sorting: true,
        },
        {
            title: 'Тарифное расписание ПУ',
            field: 'tariff',
            cellClassName: 'col-tariff',
            sorting: true,
        },
        {
            title: 'Показания счетчика по первому тарифу (Т1), кВт*ч',
            field: 'cumulativeActiveEnergyTZ1',
            cellClassName: 'col-cumulativeActiveEnergyTZ1',
            sorting: true,
        },
        {
            title: 'Показания счетчика по второму тарифу (Т2), кВт*ч',
            field: 'cumulativeActiveEnergyTZ2',
            cellClassName: 'col-cumulativeActiveEnergyTZ2',
            sorting: true,
        },
        {
            title: 'Показания счетчика по третьему тарифу (Т3), кВт*ч',
            field: 'cumulativeActiveEnergyTZ3',
            cellClassName: 'col-cumulativeActiveEnergyTZ3',
            sorting: true,
        },
        {
            title: 'Показания (прием) счетчика сумма тарифов, кВт*ч',
            field: 'cumulativeActiveEnergyImp',
            cellClassName: 'col-cumulativeActiveEnergyImp',
            sorting: true,
        },
        {
            title: 'Показания (отдача) счетчика сумма тарифов, кВт*ч',
            field: 'cumulativeActiveEnergyExp',
            cellClassName: 'col-cumulativeActiveEnergyExp',
            sorting: true,
        },
        {
            title: 'Дата последних показаний',
            field: 'dateString',
            cellClassName: 'col-dateString',
            customSort: (
                data1: ElectricityTariffReportData,
                data2: ElectricityTariffReportData
            ): number => {
                if (data1?.date && data2?.date) {
                    return new Date(data1.date).getTime() >=
                        new Date(data2.date).getTime()
                        ? 1
                        : -1;
                } else if (data1?.date) {
                    return -1;
                } else if (data2?.date) {
                    return 1;
                }
                return 1;
            },
            sorting: true,
        },
        {
            title: 'Время снятия показаний',
            field: 'timeString',
            cellClassName: 'col-timeString',
            sorting: true,
        },
        {
            title: 'Часовой пояс',
            field: 'timeZoneString',
            cellClassName: 'col-timeZoneString',
            sorting: true,
        },
    ];

    const isExcelExportNotAvailable =
        notHasAccess(AccessRule.CanGetReportsElectricityTariffExcel) ||
        report?.data?.length === 0;

    const meterGroupAutocomplete = useMeterGroupAutocomplete({
        regionId: formData.regionId,
    });

    return View({
        form,
        data,
        columns,
        isLoading,
        breadcrumbs,
        onClickBreadcrumb,
        regionSuggestions,
        headingOnBackHandler,
        isExcelExportNotAvailable,
        isReportFormed: !!data,
        meterGroupAutocomplete,
        onClickFormExcel: async () => {
            try {
                await makeExcelReport({
                    response: report,
                    filename: 'Отчет по показаниям электроэнергии по тарифам',
                    reportDate,
                });
            } catch (e) {
                dispatch(
                    createSnackbar({
                        type: 'red',
                        message: getErrorMessage(e),
                    })
                );
            }
        },
        onClickFormReport: async () => {
            await fetch();
        },
        onClickRefresh: async () => {
            await fetch();
        },
    });
};
