import { logTypeDictionary } from '../../../../common/model/meter/eventLog/logType';
import { Suggestion } from '../../../shared/interfaces';

export const logTypeSuggestions: Suggestion[] = [{ value: '', label: 'Все' }]
    .concat(Object.entries(logTypeDictionary)
        .map(([value, label]) => ({ label, value }))
    );

export const criticalSuggestions: Suggestion[] = [
    { value: '', label: 'Без фильтра' },
    { value: true, label: 'Да' },
    { value: false, label: 'Нет' }
];
