import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartnerViewModel } from '../../../../common/model/meter/partners/partnerViewModel';

export interface PartnersListState {
    available: PartnerViewModel[];
}

const initialState: PartnersListState = {
    available: null,
};

export const partnersListSlice = createSlice({
    name: '@vv/partners/list',
    initialState,
    reducers: {
        setPartners(state, action: PayloadAction<PartnerViewModel[]>): void {
            state.available = action.payload;
        }
    },
});
