import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { updatePartner } from '../../../../../redux/actions/partners';
import { partnersDetailsSlice } from '../../../../../redux/reducers/partners/details';
import { PartnerResolverChildProps } from '../../../../routing/resolvers/partnerResolver';
import { RelatedSystemsTabs, Routes } from '../../../../shared/constants';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { useHeading } from '../../../../shared/hooks/useHeading';
import { getRoute } from '../../../../shared/pipes';
import { getErrorMessage } from '../../../../shared/utils/error';
import { PartnerDetails } from '../partnerDetails';

const partnersRoute = getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.Partners });

export const PartnerEdit: React.FC<PartnerResolverChildProps> = (props) => {


    const { partner } = props;

    const dispatch = useDispatch<BaseDispatch>();

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Смежные системы', link: getRoute(Routes.relatedSystems) },
        { name: 'Партнеры', link: getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.Partners }) },
        { name: partner.name, link: getRoute(Routes.PartnerEdit, { partnerId: partner.id.toString() }) },
    ]);

    const headingProps = useHeading(partner.name, () => {
        dispatch(push(partnersRoute));
    });

    React.useEffect(() => {
        return () => {
            dispatch(partnersDetailsSlice.actions.resetPartner());
        };
    }, []);

    return PartnerDetails({
        ...props,
        ...breadcrumbProps,
        ...headingProps,
        onClickAddMeter: () => {
            dispatch(push(
                getRoute(
                    Routes.PartnerMetersCreate,
                    {
                        partnerId: partner.id.toString(),
                    }
                )
            ));
        },
        onSubmit: async (formData) => {
            try {
                await updatePartner(partner.id, { ...partner, ...formData });
                dispatch(push(partnersRoute));
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    delay: 5000,
                    message: getErrorMessage(e),
                }));
            }
        },
    });
};
