import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Icon } from '../../../../../shared/components/icon';
import { Tooltip } from '../../../../../shared/components/tooltip';

interface Props {
    label: string;
    tooltipTest: string;
    count: number;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        ...theme.typography.caption,
        color: theme.palette.text.hint,
    },
    icon: {
        marginLeft: 4,
    },
    count: {
        ...theme.typography.body,
        marginLeft: 8,
        color: theme.palette.text.primary,
    },
}));

export const ParallelTasksInfo: React.FC<Props> = (props) => {
    const {
        label,
        count,
        tooltipTest,
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <span className={classes.label}>{label}</span>
            <span className={classes.icon}>
                <Tooltip text={tooltipTest}>
                    <Icon width={16} height={16} name='help' />
                </Tooltip>
            </span>
            <span className={classes.count}>{count}</span>
        </div>
    );
};
