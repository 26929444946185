import { Column } from '@softmedialab/materialui-table';
import moment from 'moment';
import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormats } from '../../../../../common/constants/date';
import { MeterConsumptionElectricityDataView } from '../../../../../common/model/meter/consumption/meterConsumptionElectricityData';
import { MeterConsumptionElectricityParams } from '../../../../../common/model/meter/consumption/meterConsumptionElectricityParams';
import { MeterConsumptionInterval } from '../../../../../common/model/meter/consumption/meterConsumptionInterval';
import { BaseDispatch } from '../../../../../redux/actions';
import { getMeterConsumptionElectricity, meterConsumptionElectricityDownloadExcel } from '../../../../../redux/actions/meters';
import { meterConsumptionElectricitySelector, meterSelector } from '../../../../../redux/selectors/meters';
import { useSyncQueryParams } from '../../../../shared/hooks/useQueryParams';
import { timeZonePipe } from '../../../../shared/pipes/timeZone';
import { transformDatesParams } from '../../../../shared/utils/transformers/dates';
import { IModel } from './model';
import { View } from './view';
import { transformMomentToTimeZone } from '../../../../shared/utils/dates';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const dateFrom = moment().add(-3, 'month').toDate();
const dateTo = moment().add(1, 'day').toDate();

const filterDefaultValues: MeterConsumptionElectricityParams = {
    dateFrom,
    dateTo,
    groupingInterval: MeterConsumptionInterval.Hour,
};

export const Electricity = (props: IModel) => {
    const tableRef: MutableRefObject<any> = useRef();
    const { meterId } = props;
    const dispatch = useDispatch<BaseDispatch>();

    const meterConsumptionElectricity = useSelector(meterConsumptionElectricitySelector);

    const data = meterConsumptionElectricity ?? [];

    const activeMeter = useSelector(meterSelector);

    const [filter, setFilter] = useSyncQueryParams<MeterConsumptionElectricityParams>(filterDefaultValues);

    const form = useForm<MeterConsumptionElectricityParams>({
        defaultValues: filter
    });

    const formData = form.watch();

    const fetchData = () => {
        const newFilterData = {
            ...filterDefaultValues,
            ...formData
        };
        setFilter(transformDatesParams(newFilterData));
        dispatch(getMeterConsumptionElectricity(meterId, transformDatesParams(newFilterData)));
    };

    useEffect(() => {
        fetchData();
    }, [meterId]);

    const onSubmit: SubmitHandler<MeterConsumptionElectricityParams> = () => {
        fetchData();
        tableRef.current?.onChangePage(null, 0);
    };

    const timeZoneStr = timeZonePipe(activeMeter.timeZone);

    const columns: Column[] = [
        {
            field: 'readingDateView',
            title: `Время (UTC${timeZoneStr})`,
            cellClassName: 'col-time',
            sorting: true,
            customSort: (data1: MeterConsumptionElectricityDataView, data2: MeterConsumptionElectricityDataView) => {
                return data1.readingDate < data2.readingDate ? 1 : -1;
            },
        },
        {
            field: 'sumActivePowerImp',
            title: 'A+',
            sorting: false,
        },
        {
            field: 'sumActivePowerExp',
            title: 'A-',
            sorting: false,
        },
        {
            field: 'sumReactivePowerImp',
            title: 'R+',
            sorting: false,
        },
        {
            field: 'sumReactivePowerExp',
            title: 'R-',
            sorting: false,
        }
    ];

    const readingDates = useMemo(
        () => data.map(profile => profile.readingDate),
        [data]
    );
    const sumActivePowerExp = useMemo(
        () => data.map(profile => profile.sumActivePowerExp),
        [data]
    );
    const sumActivePowerImp = useMemo(
        () => data.map(profile => profile.sumActivePowerImp),
        [data]
    );
    const sumReactivePowerExp = useMemo(
        () => data.map(profile => profile.sumReactivePowerExp),
        [data]
    );
    const sumReactivePowerImp = useMemo(
        () => data.map(profile => profile.sumReactivePowerImp),
        [data]
    );

    const chartId = 'consuming';

    const xaxis: ApexXAxis = {
        type: 'datetime',
        categories: readingDates,
        labels: {
            formatter: (val: string) => transformMomentToTimeZone(moment(val), activeMeter.timeZone).format(DateFormats.dateTimeFormat),
        },
    };

    const series = [
        {
            name: '1. Активная мощность +',
            data: sumActivePowerImp,
        },
        {
            name: '2. Активная мощность -',
            data: sumActivePowerExp,
        },
        {
            name: '3. Реактивная мощность +',
            data: sumReactivePowerImp,
        },
        {
            name: '4. Реактивная мощность -',
            data: sumReactivePowerExp,
        }
    ];

    const onClickExcelExport = async () => {
        await dispatch(meterConsumptionElectricityDownloadExcel(meterId, transformDatesParams(formData)));
    };

    return View({
        ...props,
        chartId,
        xaxis,
        yaxis: {},
        series,
        form,
        columns,
        onSubmit,
        onClickExcelExport,
        data,
        tableRef,
        isTableAvailable: !!meterConsumptionElectricity,
    });
};
