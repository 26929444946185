import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MeterSchemeChangeRequestViewModel } from '../../../../../common/model/meter/meter/meterSchemeChangeRequestViewModel';
import { MeterActionSchemeProcessStatus } from '../../../../../common/model/meter/meterActionSchemeProcessStatus';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { modalNotificationsPop, modalNotificationsPush } from '../../../../../redux/actions/controls/modalNotifications';
import { newNotificationMessage } from '../../../../../redux/actions/httpWrapperActions';
import {
    getMeterSchemeChangeRequest,
    setMeterSchemeChangeRequest,
    updateMeterActionScheme
} from '../../../../../redux/actions/meterSchemes';
import { ModalNotificationVariant } from '../../../../../redux/reducers/controls/modalNotifications';
import { currentSchemeChangeRequestSelector } from '../../../../../redux/selectors/meterSchemes';
import { AdministrationTabs, Routes } from '../../../../shared/constants';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { useInterval } from '../../../../shared/hooks/useInterval';
import { getRoute } from '../../../../shared/pipes';
import { getErrorMessage } from '../../../../shared/utils/error';
import { View } from './view';
import { SchemeEditProps } from './model';

export const SchemeEditForm = (props: SchemeEditProps) => {
    const { scheme } = props;
    const dispatch = useDispatch<BaseDispatch>();

    const currentSchemeChangeRequest = useSelector(currentSchemeChangeRequestSelector);
    const [prevCurrentSchemeChangeRequest, setPrevCurrentSchemeChangeRequest] = useState<MeterSchemeChangeRequestViewModel>(null);

    useEffect(() => {
        if (prevCurrentSchemeChangeRequest && !currentSchemeChangeRequest) {
            dispatch(createSnackbar({
                type: 'green',
                message: 'Схема успешно изменена',
            }));
        } else if (currentSchemeChangeRequest && !prevCurrentSchemeChangeRequest) {
            setPrevCurrentSchemeChangeRequest(currentSchemeChangeRequest);
        }
    }, [currentSchemeChangeRequest, prevCurrentSchemeChangeRequest]);

    const fetchSchemeChangeRequest = async () => {
        await dispatch(getMeterSchemeChangeRequest(scheme.id));
    };

    useEffect(() => {
        fetchSchemeChangeRequest();
        return () => {
            dispatch(setMeterSchemeChangeRequest(null));
        };
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    useInterval(fetchSchemeChangeRequest, 6000);

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration) },
        { name: 'Схемы опроса', link: getRoute(Routes.administration, {}, { tab: AdministrationTabs.Schemes }) },
        { name: scheme.name, link: getRoute(Routes.meterActionSchemeEdit, { schemeId: scheme.id.toString() }) },
    ]);

    return View({
        ...props,
        breadcrumbs,
        onClickBreadcrumb,
        initialValues: scheme,
        currentSchemeChangeRequest,
        onClickDuplicate: () => {
            dispatch(push(getRoute(Routes.meterActionSchemeCreate, {}, { duplicateFromSchemeId: scheme.id })));
        },
        onCancel: () => {
            dispatch(push(getRoute(Routes.administration, {}, { tab: AdministrationTabs.Schemes })));
        },
        onSubmit: async (data) => {
            try {
                const update = async () => {
                    try {
                        await dispatch(updateMeterActionScheme(scheme.id, data));
                        await dispatch(push(getRoute(Routes.administration)));
                    } catch (e) {
                        dispatch(newNotificationMessage({
                            message: getErrorMessage(e),
                            messageType: 'error',
                        }));
                    }
                };
                if (
                    data.processStatus === MeterActionSchemeProcessStatus.Stopped
                    && scheme.processStatus !== data.processStatus
                ) {
                    dispatch(modalNotificationsPush({
                        showHide: true,
                        variant: ModalNotificationVariant.Alert,
                        title: 'Остановка процесса опроса',
                        message: 'После сохранения настройки, новые задания по схеме опроса не будут создаваться. Изменение схемы опроса займет некоторое время. Вы уверены?',
                        actions: [
                            {
                                children: 'Сохранить',
                                variant: 'primary',
                                onClick: async () => {
                                    await update();
                                    await dispatch(modalNotificationsPop());
                                },
                                onEnter: true,
                            },
                            {
                                children: 'Отмена',
                                variant: 'secondary',
                                onClick: async () => {
                                    await dispatch(modalNotificationsPop());
                                },
                                onEscape: true,
                            }
                        ],
                    }));
                    return;
                }
                await update();
            } catch (e) {
                dispatch(newNotificationMessage({
                    message: getErrorMessage(e),
                    messageType: 'error',
                }));
            }
        },
    });
};
