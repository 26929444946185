/**
 * Перечень возможных типов журналов
 */
import { MeterActionType } from '../meterActionType';

export enum LogType {
    PowerSwitchEvents = 1,
    DataCorrectionEvents = 2,
    TamperingEvents = 3,
    AccessControlEvents = 4,
    VoltageEvents = 5,
    CurrentEvents = 6,
    SelfDiagnosticEvents = 7,
    ElectricPowerQualityEvents = 8,
    CrqEvents = 9,
}

export enum CriticalLevelType {
    Low,
    High,
}

export const logTypeDictionary: Record<LogType, string> = {
    [LogType.PowerSwitchEvents]: 'Журнал включений/выключений',
    [LogType.DataCorrectionEvents]: 'Журнал коррекций данных',
    [LogType.TamperingEvents]: 'Журнал внешних воздействий',
    [LogType.AccessControlEvents]: 'Журнал контроль доступа',
    [LogType.VoltageEvents]: 'Журнал напряжений',
    [LogType.CurrentEvents]: 'Журнал токов',
    [LogType.SelfDiagnosticEvents]: 'Журнал самодиагностики',
    [LogType.ElectricPowerQualityEvents]: 'Журнал ПКЭ',
    [LogType.CrqEvents]: 'Импортированные события из CRQ',
}

export const logTypeToMeterActionTypeMap: Record<LogType, MeterActionType> = {
    [LogType.PowerSwitchEvents]: MeterActionType.PowerSwitchEventsCollection,
    [LogType.DataCorrectionEvents]: MeterActionType.DataCorrectionEventsCollection,
    [LogType.TamperingEvents]: MeterActionType.TamperingEventsCollection,
    [LogType.AccessControlEvents]: MeterActionType.AccessControlEventsCollection,
    [LogType.VoltageEvents]: MeterActionType.VoltageEventsCollection,
    [LogType.CurrentEvents]: MeterActionType.CurrentEventsCollection,
    [LogType.SelfDiagnosticEvents]: MeterActionType.SelfDiagnosticEventsCollection,
    [LogType.ElectricPowerQualityEvents]: MeterActionType.ElectricPowerQualityEventsCollection,
    [LogType.CrqEvents]: MeterActionType.CrqEventsCollection
}
