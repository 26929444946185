import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterProfileReportResponse } from '../../../../common/model/meter/reports/meterProfileReport';

export interface MeterProfileReportState {
    available: MeterProfileReportResponse;
}

const initialState: MeterProfileReportState = {
    available: null,
};

export const meterProfileReportSlice = createSlice({
    name: '@@vv/reports/meterProfileReport',
    initialState,
    reducers: {
        setReport(state, action: PayloadAction<MeterProfileReportResponse>): void {
            state.available = action.payload;
        },
    },
});
