import { StorageState } from '../reducers';

export enum ActionType {
    RESET = '@@wh/RESET',
    LOGOUT = '@@wh/LOGOUT',
}

export class BaseAction<T = any> {
    type: ActionType;
    payload?: T;
}

export const Reset = (): BaseAction => ({
    type: ActionType.RESET
});

type ThunkActionReturnType = Promise<any> | Promise<void> | void;

export type BaseDispatch = (...args: any[]) => ThunkActionReturnType;

export type AppThunk<R = void, E = unknown> = (
  dispatch: BaseDispatch,
  getState: () => StorageState,
  extraArgument: E
) => R;
