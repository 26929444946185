import React from 'react';
import { DrawerPanel } from '../../../shared/components/drawerPanel';
import { HighlightJson } from '../../../shared/components/highlightJson';
import { Modal } from '../../../shared/components/modal';
import { setOrderTransformer } from '../../../shared/components/pagination/helpers';
import { Tooltip } from '../../../shared/components/tooltip';
import { BlockPropsItem } from '../../../shared/components/wrapper/block/model';
import { logEntryOperations, logEntryTypes, logSeverities, logSources } from '../../../shared/constants/enums';
import { spreadItemsByCondition } from '../../../shared/pipes/spreadItemsByCondition';
import { StateProps } from './models';
import { Box, Divider, Grid, makeStyles } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { HookField } from '../../../shared/components/hookField';
import { Button } from '../../../shared/components/button';
import { Wrapper } from '../../../shared/components/wrapper';
import { Block } from '../../../shared/components/wrapper/block';
import { Typography } from '../../../shared/components/typography';
import { Table } from '../../../shared/components/table';
import { Tables } from '../../../shared/constants';
import { Pagination } from '../../../shared/components/pagination';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .modal-content': {
            width: '100%',
        },
    },
    divider: {
        martinTop: 'auto',
    },
    drawer: {
        width: 420,
        maxWidth: '100%',
    },
    filterResultsBlock: {
        justifyContent: 'flex-start',
    },
    filterResultsBlockItem: {
        flex: '0 1 auto',
    },
    metaItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:not(:hover) span.btn-clear': {
            opacity: 0,
        },
    },
    metaItemIcon: {
        color: theme.palette.primary.main,
        marginLeft: 10,
    },
    field: {
        minWidth: '250px',
    },
}));

export const View: React.FC<StateProps> = (props) => {
    const {
        data,
        form,
        columns,
        isLoading,
        periodString,
        onFiltersOpen,
        onResetPeriod,
        onFiltersClose,
        isFilterVisible,
        onClickFormReport,
        onCloseJsonModal,
        jsonModalContent,
        onResetFilter,
        onClickFormApply,
        filterMetadata,
        meterAutocomplete,
        usersSuggestions,
        appliedFiltersCount,
        pagination,
        dataTotal,
        setPage,
        setOrder,
        orderParams,
        tokenForm,
        isTableAvailable,
    } = props;

    const classes = useStyles();

    const setPeriodRender = (
        <FormProvider {...tokenForm}>
            <Grid container spacing={2}>
                <Grid xl={2} md={2} xs={2} item className={classes.field}>
                    <HookField
                        fullWidth
                        name='dateFrom'
                        type='datepicker'
                        showTime={true}
                        label='Начало'
                        disabled={isLoading}
                    />
                </Grid>
                <Grid xl={2} md={2} xs={2} item className={classes.field}>
                    <HookField
                        fullWidth
                        name='dateTo'
                        type='datepicker'
                        showTime={true}
                        label='Конец'
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xl={2} md={2} xs={2}>
                    <Button
                        variant='primary'
                        type='button'
                        disabled={isLoading}
                        onClick={onClickFormReport}
                    >
                        Показать
                    </Button>
                </Grid>
            </Grid>
        </FormProvider>
    );

    const filterBlockItems: BlockPropsItem[] = Object.values(filterMetadata)
        .flatMap(item => spreadItemsByCondition<BlockPropsItem>(item.isSelected, [
            {
                title: item.label,
                value: <Tooltip text={item.fullValue}>
                    {item.shortValue}
                </Tooltip>,
                type: 'text',
                actionIcon: item.icon,
                onActionClick: item.onClear,
            }
        ]));

    const filterButtonRender = (
        <Grid container spacing={2} alignItems='center'>
            {!isFilterVisible && (
                <Grid xs={2} item>
                    <Button
                        fullWidth
                        onClick={onFiltersOpen}
                        variant='outline'
                        icon={appliedFiltersCount > 0 ? 'filterFilled' : 'filter'}
                    >
                        Фильтрация{appliedFiltersCount > 0 ? ` (${appliedFiltersCount})` : ''}
                    </Button>
                </Grid>
            )}
            <Grid xs={10} item>
                <Block
                    type='horizontal'
                    items={filterBlockItems}
                    className={classes.filterResultsBlock}
                    childClassName={classes.filterResultsBlockItem}
                />
            </Grid>
        </Grid>
    );

    const drawer = <DrawerPanel
        className={classes.drawer}
        open={isFilterVisible}
        handleDrawerClose={onFiltersClose}
        title='Фильтрация'
        anchor='left'
    >
        <FormProvider {...form}>
            <Grid container spacing={2}>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='severity'
                        type='multiselect-with-search'
                        searchable
                        values={logSeverities}
                        placeholder='Severity'
                        disabled={isLoading}
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='source'
                        type='multiselect-with-search'
                        searchable
                        values={logSources}
                        placeholder='Источник'
                        disabled={isLoading}
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='type'
                        type='multiselect-with-search'
                        searchable
                        placeholder='Тип события'
                        values={logEntryTypes}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        isClearable
                        dark
                        fullWidth
                        name='userId'
                        type='select'
                        label='Пользователь'
                        suggestions={usersSuggestions}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        dark
                        fullWidth
                        name='meterSerialNum'
                        type='autocomplete'
                        label='Прибор учета'
                        {...meterAutocomplete}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid xs={12} item>
                    <HookField
                        fullWidth
                        dark
                        name='operation'
                        type='multiselect-with-search'
                        searchable
                        placeholder='Операция'
                        values={logEntryOperations}
                        disabled={isLoading}
                        dropdownVisibleCount={7}
                    />
                </Grid>
                <Divider className={classes.divider} />
                <Grid xs={12} item>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Button fullWidth onClick={onClickFormApply} type='button' className='m-r-16' variant='primary'>Применить</Button>
                        <Button fullWidth onClick={onResetFilter} type='button' variant='secondary'>Очистить фильтры</Button>
                    </Box>
                </Grid>
            </Grid>
        </FormProvider>
    </DrawerPanel>;

    return (
        <Wrapper
            actions={isTableAvailable ? filterButtonRender : setPeriodRender}
            drawer={drawer}
            isBigHeader
            isHeaderUppercase={false}
        >
            <>
                {!isTableAvailable && <Box>
                    <Typography variant='body'>Для отображения данных выберите период не больше недели</Typography>
                </Box>}
                {isTableAvailable && (
                    <Box mb={2}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item>
                                {periodString}
                            </Grid>
                            <Grid item>
                                <Button variant='text' onClick={onResetPeriod}>
                                    Выбрать другой период
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {isTableAvailable && <Box className={classes.tableContainer}>
                    <Table
                        name={Tables.auditTable}
                        columns={columns}
                        data={data || []}
                        title={''}
                        options={{
                            showTitle: false,
                            toolbar: false,
                            search: false,
                            draggableHeader: false,
                            paging: 'disabled',
                        }}
                        onOrderChange={setOrderTransformer(columns, setOrder)}
                        {...orderParams}
                    />
                    <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={!data} />
                </Box>}
                {!!jsonModalContent && (
                    <Modal
                        className={classes.modal}
                        size='lg'
                        close='Отмена'
                        onHide={() => {
                            // do nothing
                            onCloseJsonModal();
                        }}
                    >
                        <HighlightJson data={jsonModalContent} />
                    </Modal>
                )}
            </>
        </Wrapper>
    );
};
