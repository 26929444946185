import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterConsumptionElectricityDataView } from '../../../../../../common/model/meter/consumption/meterConsumptionElectricityData';
import { MeterConsumptionElectricityTariffDataView } from '../../../../../../common/model/meter/consumption/meterConsumptionElectricityTariffData';
import { DynamicMeterGroupParamsViewModel } from '../../../../../../common/model/meter/meter/dynamicMeterGroupParamsViewModel';
import { MeterConfigurationHistoryViewModel } from '../../../../../../common/model/meter/meterConfigurationHistory/meterConfigurationHistoryViewModel';
import { MeterCommunicatorInformation } from '../../../../../../common/model/meter/meterInformation';
import { MeterLatestProfile } from '../../../../../../common/model/meter/meterLatestProfile';
import { DynamicMeterGroupAddressesViewModel } from '../../../../common/model/meter/meter/dynamicMeterGroupAddressesViewModel';
import { MeterUpdateTechnicalParamsOrderViewModel } from '../../../../common/model/meter/meter/meterUpdateTechnicalParamsOrderViewModel';
import { MeterViewModel } from '../../../../common/model/meter/meter/meterViewModel';
import { StaticMeterGroupContentViewModel } from '../../../../common/model/meter/meter/staticMeterGroupContentViewModel';
import { MeterActionPlanViewModel } from '../../../../common/model/meter/meterActionPlanViewModel';
import { MeterActionType } from '../../../../common/model/meter/meterActionType';
import { MeterConfigurationHistoryListResponse } from '../../../../common/model/meter/meterConfigurationHistory/meterConfigurationHistoryListResponse';
import { MeterGis } from '../../../../common/model/meter/meterGis';
import { MeterGroupViewModel } from '../../../../common/model/meter/meterGroup/meterGroupViewModel';
import { MeterLabelViewModel } from '../../../../common/model/meter/meterLabel/meterLabelViewModel';
import { MeterPowerSwitchDetails } from '../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchDetails';
import {
    MeterTariffScheduleInOrderViewModel
} from '../../../../common/model/meter/meterTariffSchedule/meterTariffScheduleInOrderViewModel';
import { MeterTariffSchedulesResponse } from '../../../../common/model/meter/meterTariffSchedule/meterTariffSchedulesResponse';
import { MeterTariffScheduleViewModel } from '../../../../common/model/meter/meterTariffSchedule/meterTariffScheduleViewModel';
import { calculateMeterUIConfig, initialMeterPageUIConfig, MeterPageUIConfig } from './ui';
import { MeterLabelResponse } from '../../../../common/model/meter/meterLabel/meterLabelResponse';
import { MeterGisTaskViewModel } from '../../../../common/model/meter/meterGisTask/meterGisTaskViewModel';

export interface MeterDetailsState {
    meter: MeterViewModel;
    communicatorInformation: MeterCommunicatorInformation;
    meterActionPlan: Partial<Record<MeterActionType, MeterActionPlanViewModel>>,
    latestProfile: MeterLatestProfile;
    meterGis: MeterGis;
    meterLabels: MeterLabelResponse;
    meterConfigurationHistory: MeterConfigurationHistoryViewModel[];
    meterConfigurationHistoryTotal: number;
    meterGroups: MeterGroupViewModel[];
    dynamicMeterGroupParams: DynamicMeterGroupParamsViewModel;
    dynamicMeterGroupAddresses: DynamicMeterGroupAddressesViewModel[];
    nameListGroupsMeter: MeterGroupViewModel[];
    staticMeterGroupContents: StaticMeterGroupContentViewModel[];
    meterConsumptionElectricity: MeterConsumptionElectricityDataView[];
    meterConsumptionElectricityTariff: MeterConsumptionElectricityTariffDataView[];
    meterUpdateLoading: boolean;
    powerSwitch: MeterPowerSwitchDetails;
    meterUiConfig: MeterPageUIConfig;
    meterTariffSchedules: MeterTariffSchedulesResponse;
    meterTariffScheduleInOrder: MeterTariffScheduleInOrderViewModel;
    meterUpdateTechnicalParamsOrder: MeterUpdateTechnicalParamsOrderViewModel;
}

export interface MeterSurveyLoading {
    status: boolean;
    type: string;
}

const initialState: MeterDetailsState = {
    meterActionPlan: {
        [MeterActionType.DailyProfileReading]: undefined,
        [MeterActionType.MonthlyProfileReading]: undefined,
        [MeterActionType.IncrementalProfileReading]: undefined,
        [MeterActionType.InstantParametersProfile]: undefined,
        [MeterActionType.TimeCorrection]: undefined,
        [MeterActionType.PowerLoadProfile]: undefined,
        [MeterActionType.NetworkQualityProfile]: undefined,
        [MeterActionType.AccessControlEventsCollection]: undefined,
        [MeterActionType.DataCorrectionEventsCollection]: undefined,
        [MeterActionType.PowerSwitchEventsCollection]: undefined,
        [MeterActionType.TamperingEventsCollection]: undefined,
        [MeterActionType.MeterSetup]: undefined,
        [MeterActionType.ConfigurationSnapshot]: undefined,
        [MeterActionType.ValidateOrChangeTariff]: undefined,
    },
    meter: undefined,
    communicatorInformation: undefined,
    latestProfile: undefined,
    meterGis: undefined,
    meterLabels: undefined,
    meterConfigurationHistory: null,
    meterConfigurationHistoryTotal: 0,
    meterGroups: null,
    dynamicMeterGroupParams: undefined,
    dynamicMeterGroupAddresses: undefined,
    nameListGroupsMeter: [],
    staticMeterGroupContents: [],
    meterConsumptionElectricity: null,
    meterConsumptionElectricityTariff: null,
    meterUpdateLoading: false,
    powerSwitch: null,
    meterUiConfig: initialMeterPageUIConfig,
    meterTariffSchedules: null,
    meterTariffScheduleInOrder: null,
    meterUpdateTechnicalParamsOrder: null,
};

export const meterDetailsSlice = createSlice({
    name: '@@vv/meters/details',
    initialState,
    reducers: {
        setMeterDetails(state, action: PayloadAction<MeterViewModel>): void {
            state.meter = action.payload;
            state.meterUiConfig = calculateMeterUIConfig(action.payload);
        },
        setMeterCommunicatorInformation(state, action: PayloadAction<MeterCommunicatorInformation>): void {
            state.communicatorInformation = action.payload;
        },
        setMeterLatestProfile(state, action: PayloadAction<MeterLatestProfile>): void {
            state.latestProfile = action.payload;
        },
        resetMeterActionPlans(state): void {
            state.meterActionPlan = {};
        },
        setMeterActionPlan(state, action: PayloadAction<MeterActionPlanViewModel>): void {
            state.meterActionPlan[action.payload.meterActionType] = action.payload;
        },
        updateLoading(state, action: PayloadAction<MeterSurveyLoading>) : void {
            state[action.payload.type] = action.payload.status;
        },
        setMeterGis(state, action: PayloadAction<MeterGis>): void {
            state.meterGis = action.payload;
        },
        setMeterLabels(state, action: PayloadAction<MeterLabelResponse>): void {
            state.meterLabels = action.payload;
        },
        updateMeterLabel(state, action: PayloadAction<MeterLabelViewModel>): void {
            state.meterLabels = {
                ...state.meterLabels,
                data: state.meterLabels.data.map(label => {
                    if (label.id === action.payload.id) {
                        return action.payload;
                    }
                    return label;
                })
            };
        },
        addMeterGisTaskToMeterLabel(state, action: PayloadAction<{meterGisTask: MeterGisTaskViewModel, meterId: string}>): void {
            state.meterLabels = {
                ...state.meterLabels,
                data: state.meterLabels.data.map(label => {
                    if (label.meterId === action.payload.meterId) {
                        return {
                            ...label,
                            meterGisTask: action.payload.meterGisTask,
                        };
                    }
                    return label;
                })
            };
        },
        setMeterConfigurationHistory(state, action: PayloadAction<MeterConfigurationHistoryListResponse>): void {
            state.meterConfigurationHistory = action.payload?.data;
            state.meterConfigurationHistoryTotal = action.payload?.total;
        },
        setMeterGroups(state, action: PayloadAction<MeterGroupViewModel[]>): void {
            state.meterGroups = action.payload;
        },
        setDynamicMeterGroupParams(state, action: PayloadAction<DynamicMeterGroupParamsViewModel>): void {
            state.dynamicMeterGroupParams = action.payload;
        },
        setDynamicMeterGroupAddresses(state, action: PayloadAction<DynamicMeterGroupAddressesViewModel[]>): void {
            state.dynamicMeterGroupAddresses = action.payload;
        },
        setStaticMeterGroupsContents(state, action: PayloadAction<StaticMeterGroupContentViewModel[]>): void {
            state.staticMeterGroupContents = action.payload;
        },
        setNameListMeterGroups(state, action: PayloadAction<MeterGroupViewModel[]>): void {
            state.nameListGroupsMeter = action.payload;
        },
        setMeterConsumptionElectricity(state, action: PayloadAction<MeterConsumptionElectricityDataView[]>): void {
            state.meterConsumptionElectricity = action.payload;
        },
        setMeterConsumptionElectricityTariff(state, action: PayloadAction<MeterConsumptionElectricityTariffDataView[]>): void {
            state.meterConsumptionElectricityTariff = action.payload;
        },
        setMeterUpdateLoading(state, action: PayloadAction<boolean>): void {
            state.meterUpdateLoading = action.payload;
        },
        setMeterPowerSwitch(state, action: PayloadAction<MeterPowerSwitchDetails>): void {
            state.powerSwitch = action.payload;
        },
        setMeterTariffSchedules(state, action: PayloadAction<MeterTariffSchedulesResponse>): void {
            state.meterTariffSchedules = action.payload;
        },
        setMeterTariffScheduleInOrder(state, action: PayloadAction<MeterTariffScheduleViewModel>): void {
            state.meterTariffScheduleInOrder = action.payload;
        },
        setMeterUpdateTechnicalParamsOrder(state, action: PayloadAction<MeterUpdateTechnicalParamsOrderViewModel>): void {
            state.meterUpdateTechnicalParamsOrder = action.payload;
        },
    },
});
