import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import {
    MeterPowerSwitchOrderStatus, meterPowerSwitchOrderStatusDictionary
} from '../../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchOrderStatus';
import { Icon } from '../../../../shared/components/icon';
import { Tooltip } from '../../../../shared/components/tooltip';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    textWrapper: {
        display: 'flex',
        alignItems: 'center',
        ...theme.typography.body,
        color: theme.palette.text.primary,
    },
    success: {
        color: theme.palette.system.success,
    },
    error: {
        color: theme.palette.system.error,
    },
    icon: {
        display: 'inline-flex',
        marginRight: 4,
    },
}));

export const PowerSwitchIndicator: React.FC<{ status: MeterPowerSwitchOrderStatus }> = ({ status }) => {
    const classes = useStyles();
    if (status === undefined || status === null) {
        return null;
    }
    const getStatus = () => {
        switch (status) {
            case MeterPowerSwitchOrderStatus.ApprovalRequired:
            case MeterPowerSwitchOrderStatus.ReadyForWork: {
                return <Box className={classNames(classes.textWrapper)}>
                    <Box className={classes.icon}>
                        <CircularProgress size={20} color='primary' />
                    </Box>
                    В работе
                </Box>;
            }
            case MeterPowerSwitchOrderStatus.SuccessfullyCompleted: {
                return <Box className={classNames(classes.textWrapper, classes.success)}>
                    <Box className={classes.icon}>
                        <Icon name='success' />
                    </Box>
                    Выполнена
                </Box>;
            }
            default: {
                return <Box className={classNames(classes.textWrapper, classes.error)}>
                    <Box className={classes.icon}>
                        <Icon name='warningCircle' />
                    </Box>
                    Ошибка
                </Box>;
            }
        }
    };
    return (
        <Tooltip text={meterPowerSwitchOrderStatusDictionary[status]}>
            <Box className={classNames(classes.root)}>
                {getStatus()}
            </Box>
        </Tooltip>
    );
};
