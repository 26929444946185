import { combineReducers } from 'redux';
import { commonInspectionLogSlice, CommonInspectionLogState } from './common';


export interface InspectionLogsState {
    common: CommonInspectionLogState;
}

export const inspectionLogs = combineReducers<InspectionLogsState>({
    common: commonInspectionLogSlice.reducer,
});
