import { FormControlLabel, makeStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import MaterialSwitch from '@material-ui/core/Switch';
import classNames from 'classnames';
import React, { Ref } from 'react';

export const Switch = withStyles({
    switchBase: {
        '&$checked': {
            color: '#E2834A',
        },
        '&$checked + $track': {
            backgroundColor: '#E2834A',
        },
    },
    checked: {},
    track: {},
})(MaterialSwitch);

export interface SwitchProps {
    label?: string;
    checked?: boolean;
    disabled?: boolean;
    dark?: boolean;
    onChange?: (event?: any) => void | Promise<void | boolean>;
    required?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    dark: {
        color: theme.palette.neutral.white,
        '& .MuiCheckbox-root': {
            color: theme.palette.neutral.white,
            '&.Mui-checked': {
                color: theme.palette.primary.light,
            }
        }
    },
}));

export const SwitchInput: React.FC<SwitchProps> = React.forwardRef((props, ref: Ref<any>) => {
    const { checked, disabled, onChange, label, dark, required } = props;
    const classes = useStyles();
    return (<FormControlLabel
        aria-required={required}
        control={
            <Switch
                required={required}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
        }
        label={label}
        ref={ref}
        className={classNames({ [classes.dark]: dark })}
    />
    );
});
