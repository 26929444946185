import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { TextInput } from '../textInput';
import { StateProps } from './model';
import { VisibilityIcon, VisibilityOffIcon } from '../../../../assets/icons';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            padding: 0,
            margin: 0,
        },
        '& .MuiOutlinedInput-root': {
            padding: 0
        },
        '& .MuiInputBase-input': {
            padding: 0
        },
        '& span': {
            display: 'flex'
        },
        '& svg': {
            fill: `${theme.palette.text.hint} !important`
        }
    }
}));

export const View: React.FC<StateProps> = ({
    values,
    handleClickShowPassword,
    handleClickHidePassword
}) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper} >
            <TextInput
                value={values.password}
                type={values.showPassword ? 'text' : 'password'}
                icon={
                    values.showPassword
                        ? <VisibilityIcon onClick={handleClickHidePassword} />
                        : <VisibilityOffIcon onClick={handleClickShowPassword} />
                }
            />
        </div>
    );
};
