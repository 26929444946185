export enum MeterUpdateAttributesActions {
    ChangeCommunicationParams = 'ChangeCommunicationParams',
    ReplacingCommunicator = 'ReplacingCommunicator',
    ChangeTransformationRatios = 'ChangeTransformationRatios',
    ChangeAddress = 'ChangeAddress',
    MeterVerification = 'MeterVerification',
    ChangeInformationFields = 'ChangeInformationFields',
    ChangeTechnicalParams = 'ChangeTechnicalParams',
    ChangeStatusToActive = 'ChangeStatusToActive',
    ChangeStatusToDecommissioned = 'ChangeStatusToDecommissioned',
    ChangeMeterSyncSchemes = 'ChangeMeterSyncSchemes',
    ChangePasswordFields = 'ChangePasswordFields',
}

export const meterUpdateAttributesActionsDictionary: Record<MeterUpdateAttributesActions, string> = {
    [MeterUpdateAttributesActions.ChangeCommunicationParams]: 'Изменение параметров связи',
    [MeterUpdateAttributesActions.ReplacingCommunicator]: 'Замена коммуникатора',
    [MeterUpdateAttributesActions.ChangeTransformationRatios]: 'Изменить коэффициенты трансформации',
    [MeterUpdateAttributesActions.ChangeAddress]: 'Изменение адреса',
    [MeterUpdateAttributesActions.MeterVerification]: 'Поверка счетчика',
    [MeterUpdateAttributesActions.ChangeInformationFields]: 'Изменение информационных полей',
    [MeterUpdateAttributesActions.ChangeTechnicalParams]: 'Изменение технических параметров',
    [MeterUpdateAttributesActions.ChangeStatusToActive]: 'Изменение статуса ПУ на активный',
    [MeterUpdateAttributesActions.ChangeStatusToDecommissioned]: 'Изменение статуса ПУ на выведен из эксплуатации',
    [MeterUpdateAttributesActions.ChangeMeterSyncSchemes]: 'Изменение схем синхронизации времени и опроса',
    [MeterUpdateAttributesActions.ChangePasswordFields]: 'Изменение паролей',
}
