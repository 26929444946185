import { IconButton, makeStyles } from '@material-ui/core';
import React, { useRef } from 'react';
import { useOutsideClick } from '../../hooks';
import { IStateProps } from './model';
import { Icon } from '../icon';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    button: {
        width: 24,
        height: 24,
        color: theme.palette.text.hint,
        padding: 0,
    },
    dropdownWrapper: {
        width: 248,
        backgroundColor: theme.palette.neutral.white,
        borderRadius: 4,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        position: 'fixed',
        zIndex: 1,
        padding: '8px 0',
    },
    dropdownItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: 40,
        padding: '8px 12px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.neutral.background,
        },
    },
    dropdownItemIcon: {
        color: theme.palette.primary.light,
    },
    dropdownItemText: {
        marginLeft: 5,
        ...theme.typography.input,
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const classes = useStyles();

    const {
        isOpen,
        options,
        icon,
        onClickButton,
        dropdownPosition = 'bottomLeft',
    } = props;

    const buttonRef = useRef<HTMLButtonElement>();
    const dropdownRef = useRef<HTMLDivElement>();

    const { top, width, left, height, right } = buttonRef.current?.getBoundingClientRect() ?? {
        top: 0,
        left: 0,
        right: 0,
        width: 24,
        height: 24,
    };

    const dropdownList = dropdownRef.current?.getBoundingClientRect();

    const wrapperRef = useOutsideClick(() => {
        if (isOpen) {
            onClickButton();
        }
    });

    const getPosition = () => {
        if (dropdownPosition === 'bottomRight') {
            return { top: `${top + height}px`, right: `calc(100% - ${right + dropdownList?.width}px)` };
        }
        return { top: `${top + height}px`, right: `calc(100% - ${left + width}px)` };
    };

    return (
        <div ref={wrapperRef} className={classes.root}>
            <IconButton ref={buttonRef} onClick={onClickButton} className={classes.button}>
                <Icon name={icon} />
            </IconButton>
            {isOpen && (
                <div
                    className={classes.dropdownWrapper}
                    style={getPosition()}
                    ref={dropdownRef}
                >
                    {
                        options.map(option => (
                            <div
                                className={classes.dropdownItem}
                                onClick={() => {
                                    option.onClick();
                                    if (isOpen) {
                                        onClickButton();
                                    }
                                }}
                                key={option.label}
                            >
                                <Icon className={classes.dropdownItemIcon} name={option.icon} />
                                <span className={classes.dropdownItemText}>
                                    {option.label}
                                </span>
                            </div>
                        ))
                    }
                </div>
            )}
        </div>
    );
};
