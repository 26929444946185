import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterFileImportLogViewModel } from 'common/model/meter/meterFileImport/meterFileImportLogViewModel';
import { MeterFileImportLogResponse } from 'common/model/meter/importCrq/meterFileImportLogResponse';

export interface MeterImportCrqLogListState {
    available: MeterFileImportLogViewModel[]
    dataTotal: number;
}

const initialState: MeterImportCrqLogListState = {
    available: null,
    dataTotal: null,
};

export const meterImportCrqLogListSlice = createSlice({
    name: '@@vv/meterImportCrqLog/logList',
    initialState,
    reducers: {
        setAvailableMeterImportCrqLog(state, action: PayloadAction<MeterFileImportLogResponse>) {
            state.available = action.payload.data;
            state.dataTotal = action.payload.total;
        },
    }
});
