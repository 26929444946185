import React, { Ref } from 'react';
import {
    Select,
    FormControl,
    InputLabel,
    makeStyles,
    ListItemIcon,
    SvgIcon,
    MenuItem,
    FormHelperText, Box,
} from '@material-ui/core';
import classNames from 'classnames';
import { SelectProps } from './model';
import { Typography } from '../typography';
import { Icon } from '../icon';


const useStyles = makeStyles((theme) => ({
    menuContainer: {
        '& .MuiMenu-list': {
            '& .Mui-selected': {
                backgroundColor: theme.palette.neutral.ultraLight,
            },
        },
    },
    formControl: {
        minWidth: 120,

        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
        '& .MuiInput-underline:before': {
            display: 'none',
        },
    },
    fullWidth: {
        width: '100%',
    },
    icon: {
        fill: theme.palette.primary.light,
    },
    list: {
        backgroundColor: theme.palette.neutral.white,
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        minHeight: '20px',
    },
    menuItemIcon: {
        marginRight: '12px',
        minWidth: '20px',
    },
    MenuItemRoot: {
        backgroundColor: theme.palette.neutral.white,
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: theme.palette.neutral.background,
        },
        '& .MuiListItemIcon-root': {
            minWidth: '20px',
            marginRight: '12px',
        }
    },
    darkMenuItem: {
        '&.MuiMenuItem-root': {
            backgroundColor: theme.palette.neutral.main,
            color: theme.palette.neutral.white,
        },
        '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
        }
    },
    darkSelect: {
        color: theme.palette.neutral.white,
        '& .MuiMenu-list': {
            backgroundColor: theme.palette.neutral.main,
            '& .Mui-selected': {
                backgroundColor: theme.palette.text.secondary,
            },
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.neutral.white,
        },
        '& .MuiOutlinedInput-root': {
            color: theme.palette.neutral.white,
        },
        '& .MuiOutlinedInput-root fieldset': {
            borderColor: theme.palette.neutral.white,
        },
        '&:hover': {
            '& .MuiOutlinedInput-root fieldset': {
                borderColor: theme.palette.neutral.white,
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.neutral.white,
            },
        }
    },
    errorText: {
        color: theme.palette.system.error,
        fontSize: '13px',
        paddingLeft: '12px',
        position: 'absolute',
        top: 38,
    },
    renderIcons: {
        display: 'flex',
        marginRight: 6,
        '& svg': {
            color: theme.palette.primary.light
        }
    },
    iconClear: {
        cursor: 'pointer',
        position: 'absolute',
        top: '8px',
        right: '30px',
    },
}));


export const View = React.forwardRef(({
    value,
    label,
    onChange,
    fullWidth,
    disabled,
    multiple,
    dark,
    suggestions,
    error,
    helperText,
    handleClear,
    isClearable,
    required,
    ...otherProps
}: SelectProps, ref: Ref<any>) => {

    const classes = useStyles();

    const renderIcons = (props: unknown) => {
        return (
            <Box className={classes.renderIcons}>
                {(value || value === 0) && isClearable &&
                    <Icon
                        {...props}
                        name='close'
                        fontSize='small'
                        onMouseDown={() => handleClear ? handleClear('') : onChange(null)}
                        className={classes.iconClear}
                        width='15px'
                    />
                }
                <Icon
                    {...props}
                    name='arrowSmallDown'
                    fontSize='small'
                />
            </Box>
        );
    };

    return (
        <FormControl
            className={classNames(classes.formControl, { [classes.fullWidth]: fullWidth, [classes.darkSelect]: dark })}
            error={error}
        >
            {label && (
                <InputLabel required={required}>{label}</InputLabel>
            )}
            <Select
                classes={{ icon: classes.icon }}
                variant='outlined'
                value={value ?? ''}
                onChange={onChange}
                disabled={disabled}
                fullWidth={fullWidth}
                label={label}
                IconComponent={(props) => renderIcons(props)}
                multiple={multiple}
                error={error}
                className={classNames({ [classes.darkSelect]: dark })}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 48,
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null,
                    classes: { list: classes.list },
                    className: classNames(classes.menuContainer, { [classes.darkSelect]: dark })
                }}
                ref={ref}
                required={required}
                {...otherProps}
            >
                {suggestions.map(suggestion => {
                    return (
                        <MenuItem
                            key={suggestion.value}
                            value={suggestion.value}
                            classes={{ root: classes.MenuItemRoot }}
                            className={classNames({ [classes.darkMenuItem]: dark })}
                        >
                            <div className={classes.menuItem}>
                                {Boolean(suggestion.icon) && <ListItemIcon className={classes.menuItemIcon}>
                                    <SvgIcon component={suggestion.icon} className={classes.icon} />
                                </ListItemIcon>}
                                <Typography variant='input'>{suggestion.label}</Typography>
                            </div>
                        </MenuItem>
                    );
                })}
            </Select>
            {helperText && (
                <FormHelperText className={classes.errorText}>{helperText}</FormHelperText>
            )}
        </FormControl>

    );
});
