import { Box, makeStyles, Typography } from '@material-ui/core';
import { EditMeterGroup } from './model';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Heading } from '../../../../shared/components/heading';
import { Wrapper } from '../../../../shared/components/wrapper';
import { MeterGroupForm } from '../form';
import React from 'react';
import { EditStaticGroupForm } from './static';
import { EditDynamicGroupForm } from './dynamic';
import { Modal } from '../../../../shared/components/modal';
import { Button } from '../../../../shared/components/button';
import { ModalNotificationVariant } from '../../../../../redux/reducers/controls/modalNotifications';

const useStyles = makeStyles(() => ({
    modal: {
        padding: 2,
    }
}));

export const View = (props: EditMeterGroup) => {
    const {
        onCancel,
        initialValues,
        onSubmitGroup,
        breadcrumbs,
        onClickBreadcrumb,
        groupName,
        group,
        isShowParams,
        onSubmitGroupParams,
        formDynamicMeterGroupParams,
        formDynamicMeterGroupParamsFormData,
        isDisabledSave,
        onCloseForm,
        isFormOpen,
        saveGroup,
        cancelGroup,
        formGroup,
        isRemoveModal,
        removeGroup,
        cancelRemove,
        onShowRemoveModal,
        meterGroupFilter,
    } = props;

    const classes = useStyles();

    return (
        <>
            { isFormOpen && <Modal variant={ModalNotificationVariant.Alert} className={classes.modal} title='Сохранить изменения?' close='Отмена' onHide={onCloseForm}>
                <Typography variant='caption'>Вы хотите покинуть страницу, на которой есть несохраненнные изменения.</Typography>
                <Box mt={3} display='flex' alignItems='center'>
                    <Box mr={1}>
                        <Button variant='primary' type='button' onClick={saveGroup}>Сохранить</Button>
                    </Box>
                    <Box>
                        <Button variant='secondary' type='button' onClick={cancelGroup}>Не сохранять</Button>
                    </Box>
                </Box>
            </Modal>
            }
            { isRemoveModal && <Modal variant={ModalNotificationVariant.Alert} title='Удаление группы ПУ' close='Отмена' onHide={cancelRemove}>
                <Typography variant='caption'>Вы уверены, что хотите удалить группу?</Typography>
                <Box mt={3} display='flex' alignItems='center'>
                    <Box mr={1}>
                        <Button variant='primary' type='button' onClick={removeGroup}>Да, удалить</Button>
                    </Box>
                    <Box>
                        <Button variant='secondary' type='button' onClick={cancelRemove}>Отмена</Button>
                    </Box>
                </Box>
            </Modal>
            }
            <Box mb={2}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2} display='flex' justifyContent='space-between' alignItems='center'>
                <Heading heading={groupName} onBackHandler={onCancel} />
                <Button variant='outline' onClick={onShowRemoveModal}>Удалить</Button>
            </Box>
            <Wrapper title='Общие параметры'>
                <Box display='flex' alignItems='center'>
                    <MeterGroupForm
                        initialValues={initialValues}
                        onSubmitGroup={onSubmitGroup}
                        isDisabledSave={isDisabledSave}
                        form={formGroup}
                        disabledIsStatic
                    />
                </Box>
            </Wrapper>
            {
                group.isStatic ?
                    <EditStaticGroupForm group={group} /> :
                    <EditDynamicGroupForm
                        {...props}
                        form={formDynamicMeterGroupParams}
                        groupParamsFilter={formDynamicMeterGroupParamsFormData}
                        isShowParams={isShowParams}
                        onSubmit={onSubmitGroupParams}
                        meterGroupFilter={meterGroupFilter}
                    />
            }
        </>
    );
};
