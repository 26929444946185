import { LOCATION_CHANGE } from 'connected-react-router';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TreeItem } from '../../../../app/shared/components/tree/model';
import { buildAddressTreeModified, closeAllTabsInTree, updateTreeData } from './helpers';
import { MeterViewModel } from 'common/model/meter/meter/meterViewModel';
import { SearchByGroupAddressModel } from 'common/model/meter/searchByGroupAddress';
import { TreeFilterModel } from './model';

export interface Tab {
    id: string;
    name: string | null;
}

export type ActiveTreeitemType = 'regionId' | 'areaName' | 'townName' | 'streetName' | 'houseNumber' | null;
export interface LeftMenuState {
    collapsed: boolean;
    activeTreeItem: ActiveTreeitemType,
    treeFilter: TreeFilterModel;
    isFetchingSearchMeters: boolean;
    isFetchingMeterPosition: boolean;
    possibleSearchMeters: MeterViewModel[];
    treeData: TreeItem[];
    tabData: Tab[];
    activeTab: Tab;
}

const initialState: LeftMenuState = {
    isFetchingMeterPosition: false,
    collapsed: true,
    possibleSearchMeters: null,
    activeTreeItem: null,
    isFetchingSearchMeters: false,
    treeFilter: {},
    treeData: null,
    tabData: [],
    activeTab: { id: '', name: '' },
};

export const leftMenuSlice = createSlice({
    name: '@@wh/menu',
    initialState,
    reducers: {
        setTreeData(state, action: PayloadAction<SearchByGroupAddressModel>) {
            const treeData = buildAddressTreeModified(
                state.treeData,
                action.payload.addresses,
                action.payload.meters,
                state.treeFilter,
                state.activeTreeItem);
            state.treeData = treeData;
            return state;
        },
        setFetchingMeterPosition(state, action: PayloadAction<boolean>): void {
            state.isFetchingMeterPosition = action.payload;
        },
        setFetchingSearchMeters(state, action: PayloadAction<boolean>): void {
            state.isFetchingSearchMeters = action.payload;
        },
        setActiveTreeItem(state, action: PayloadAction<ActiveTreeitemType>): void {
            state.activeTreeItem = action.payload;
        },
        setPossibleSearchMeters(state, action: PayloadAction<MeterViewModel[]>): void {
            state.possibleSearchMeters = action.payload;
        },
        setTreeFilter(state, action: PayloadAction<any>): void {
            state.treeFilter = action.payload;
        },
        changeCollapsed(state, action): void {
            state.collapsed = !!action.payload;
        },
        updateTree(state, action): void {
            state.treeData = updateTreeData(state.treeData, action.payload);
        },
        saveTag(state, action): void {
            if (!state.tabData.some(el => el.id === action.payload.id)) {
                state.tabData = [...state.tabData, action.payload];
            }
        },
        removeTab(state, action): void {
            state.tabData = state.tabData.filter(elem => elem.id !== action.payload);
        },
        closeTree(state): void {
            state.treeData = closeAllTabsInTree(state.treeData);
        },
        setActiveTab(state, action): void {
            state.activeTab = action.payload;
        },
        removeActiveTab(state): void {
            state.activeTab = { id: '', name: '' };
        },
    },
    extraReducers: {
        [LOCATION_CHANGE]: state => {
            state.collapsed = initialState.collapsed;
        }
    }
});
