import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrqSystemViewModel } from 'common/model/meter/crq/crqSystemViewModel';
import { ActionType } from '../../../actions';

export interface EditableCrqSystemState {
    editableCrqSystem: CrqSystemViewModel;
}

const initialState: EditableCrqSystemState = {
    editableCrqSystem: null,
};

export const editableCrqSystemSlice = createSlice({
    name: '@@vostok/crqSystem/editable',
    initialState,
    reducers: {
        setEditableCrqSystem(state, action: PayloadAction<CrqSystemViewModel>): void {
            state.editableCrqSystem = action.payload;
        }
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): EditableCrqSystemState => initialState,
    }
});
