import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { TimeSynchronizationSchemeCreator } from '../../../common/model/meter/timeSynchronizationScheme/timeSynchronizationSchemeCreator';
import { TimeSynchronizationSchemeUpdater } from '../../../common/model/meter/timeSynchronizationScheme/timeSynchronizationSchemeUpdater';
import {
    TimeSynchronizationSchemeViewModel
} from '../../../common/model/meter/timeSynchronizationScheme/timeSynchronizationSchemeViewModel';
import { timeSynchronizationSchemesSlice } from '../../reducers/timeSynchronizationSchemes/list';
import { AppThunk } from '../index';

export const getTimeSynchronizationSchemes = (): AppThunk => async (dispatch) => {
    const data = await httpService.get<TimeSynchronizationSchemeViewModel[]>(appUrls.api_get_time_synchronization_schemes);
    dispatch(timeSynchronizationSchemesSlice.actions.setAvailable(data));
};

export const createTimeSynchronizationScheme = async (payload: TimeSynchronizationSchemeCreator) => {
    await httpService.post(appUrls.api_post_time_synchronization_scheme, payload);
};

export const updateTimeSynchronizationScheme = async (schemeId: number, payload: TimeSynchronizationSchemeUpdater) => {
    await httpService.put<TimeSynchronizationSchemeViewModel[]>(
        getApiRoute(appUrls.api_put_time_synchronization_scheme, { schemeId }),
        payload,
    );
};

export const deleteTimeSynchronizationScheme = async (schemeId: number) => {
    await httpService.delete<TimeSynchronizationSchemeViewModel[]>(
        getApiRoute(appUrls.api_delete_time_synchronization_scheme, { schemeId })
    );
};
