import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdvancedCrashReportResponse } from '../../../../common/model/meter/reports/advancedCrashReportResponse';

export interface AdvancedCrashReportState {
    available: AdvancedCrashReportResponse;
}

const initialState: AdvancedCrashReportState = {
    available: null,
};

export const advancedCrashReportSlice = createSlice({
    name: '@@vv/reports/advancedCrashReport',
    initialState,
    reducers: {
        setReport(state, action: PayloadAction<AdvancedCrashReportResponse>): void {
            state.available = action.payload;
        },
    },
});
