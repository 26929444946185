import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { View } from './view';
import { InputProps } from './model';
import { MeterLabelTypeViewModel } from '../../../../../common/model/meter/meterLabelType/meterLabelTypeViewModel';
import { useDispatch } from 'react-redux';
import { updateMeterLabelType } from '../../../../../redux/actions/meterLabelType';
import { BaseDispatch } from '../../../../../redux/actions';

export const EditLabelTypeForm: React.FC<InputProps> = ({ editableLabel, onCancel }) => {
    const dispatch = useDispatch<BaseDispatch>();
    const form = useForm<MeterLabelTypeViewModel>({ defaultValues: editableLabel });
    const onSubmit: SubmitHandler<MeterLabelTypeViewModel> = async (data) => {
        await dispatch(updateMeterLabelType(editableLabel.id, data));
        onCancel();
    };

    return View({
        form,
        onCancel,
        onSubmit
    });
};
