import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SettingUpdater } from '../../../../common/model/resources/settings/settingUpdater';
import { SettingViewModel } from '../../../../common/model/resources/settings/settingViewModel';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import { getSettings, updateSetting } from '../../../../redux/actions/settings';
import { settingsAvailableSelector } from '../../../../redux/selectors/settings';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { getErrorMessage } from '../../../shared/utils/error';
import { ActionsColumn } from './components/columns/actionsColumn';
import { ChangedColumn } from './components/columns/changedColumn';
import { ValueColumn } from './components/columns/valueColumn';
import { boldValue } from './components/columns/boldValue';
import { SettingsListFilter } from './model';
import { View } from './view';
import { useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';

const filterDefaultValues: SettingsListFilter = {
    groupName: null,
};

export const SettingsList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [editableSetting, setEditableSetting] = useState<SettingViewModel>(null);
    const dispatch = useDispatch<BaseDispatch>();
    const settings = useSelector(settingsAvailableSelector);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            await dispatch(getSettings());
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: e.message || e?.data?.errorDetails || 'Произошла ошибка при загрузке настроек',
            }));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [schemesFilter, setSchemesFilter] = useSyncQueryParams<SettingsListFilter>(filterDefaultValues);

    const form = useForm<SettingsListFilter>({
        defaultValues: schemesFilter
    });

    const formData = form.watch();

    useEffect(() => {
        setSchemesFilter({
            ...filterDefaultValues,
            ...formData
        });
    }, [formData]);

    const onClickSettingEdit = (setting: SettingViewModel) => {
        setEditableSetting(setting);
    };

    const groupSuggestions: SelectSuggestion[] = useMemo(() => _.uniqBy(
        [
            ...(settings || []).map(item => ({
                label: item.groupName,
                value: item.groupName,
            }))
        ]
        , 'value'), [settings]);

    const filteredSettings = useMemo(() => {
        if (!settings) {
            return null;
        }
        return (settings || []).filter(item => {
            if (formData.groupName) {
                return item.groupName === formData.groupName;
            }
            return true;
        });
    }, [settings, formData]);

    return View({
        form,
        isFormLoading,
        editableSetting,
        groupSuggestions,
        settings: filteredSettings,
        isLoading: isLoading || !settings,
        onCloseEditSettingModal(): void {
            setEditableSetting(null);
        },
        onSubmitEditSetting: async (settingUpdater: SettingUpdater): Promise<void> => {
            setIsFormLoading(true);
            try {
                await dispatch(updateSetting(settingUpdater));
                await fetchData();
                setEditableSetting(null);
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsFormLoading(false);
        },
        columns: [
            {
                field: 'groupName',
                defaultGroupOrder: 0,
                title: 'Группа',
                sorting: true,
                customSort: (group1, group2) => {
                    return group1.order - group2.order;
                },
                render: boldValue,
            },
            {
                field: 'title',
                title: 'Настройка',
                grouping: false,
                sorting: true,
                cellClassName: 'col-title',
            },
            {
                field: 'value',
                title: 'Действующее значение',
                grouping: false,
                sorting: false,
                cellClassName: 'col-value',
                render: (setting: SettingViewModel) => {
                    return ValueColumn({ setting });
                }
            },
            {
                field: 'changedBy',
                title: 'Изменено',
                grouping: false,
                sorting: true,
                cellClassName: 'col-changed',
                customSort: (data1: SettingViewModel, data2: SettingViewModel) => {
                    if (data1?.changedAt && data2?.changedAt) {
                        return new Date(data1.changedAt).getTime() < new Date(data2.changedAt).getTime() ? 1 : -1;
                    } else if (data1?.changedAt) {
                        return -1;
                    } else if (data2?.changedAt) {
                        return 1;
                    }
                    return 1;
                },
                render: (setting: SettingViewModel) => {
                    return ChangedColumn({ setting });
                }
            },
            {
                field: 'action',
                title: '',
                grouping: false,
                sorting: false,
                cellClassName: 'col-action',
                render: (setting: SettingViewModel) => {
                    return ActionsColumn({
                        onClick: () => {
                            onClickSettingEdit(setting);
                        },
                        hasIcon: false,
                    });
                }
            }
        ],
    });
};
