import { View } from './view';
import { InputProps } from './model';
import React, { ChangeEvent, createRef, KeyboardEvent, useEffect, useRef, useState, useMemo } from 'react';
import { List } from 'react-virtualized';
import { useOutsideClick } from '../../hooks';

export const KeyCodes = {
    Enter: 13,
    Escape: 27,
    ArrowUp: 38,
    ArrowDown: 40,
    ArrowLeft: 37,
    ArrowRight: 39,
    Tab: 9,
    Backspace: 8,
    Delete: 46,
};

const createValueRefs = (valuesNumber: number | undefined) => valuesNumber
    ? new Array(valuesNumber + 1).map(() => createRef<HTMLDivElement>())
    : [];

export const MultiselectDropdown = (props: InputProps) => {
    const [active, setActive] = useState(false);
    const value = Array.isArray(props.value) ? props.value : [];
    const valueStr = value.map(val => val.toString());
    const [search, setSearch] = useState('');
    const [focusedItem, setFocusedItem] = useState(0);
    const originalValues = props.values ?? [];
    const maxSelectValues = props.maxSelectValues;
    const values = search
        ? originalValues.filter(item => item.label.toLowerCase().includes(search.toLowerCase()))
        : originalValues;

    const searchInputRef = useRef<HTMLInputElement>();
    const blockInputRef = useRef<HTMLDivElement>();
    const listRef = useRef<List>();
    const valuesRef = useRef<React.MutableRefObject<HTMLDivElement>[]>([]);
    valuesRef.current = useMemo(() => createValueRefs(values?.length), [values?.length]);

    const lastValuesIndex = values?.length;
    const disabledValues = Array.isArray(props.disabledValues) ? props.disabledValues : [];

    const disabledActiveValues = disabledValues.filter(v => value.includes(v));
    const disabledNotActiveValues = disabledValues.filter(v => !value.includes(v));

    const isFieldEmpty = value.filter(v => !disabledActiveValues.includes(v)).length === 0;

    const onChange = (newValue: number[]) => {
        if (maxSelectValues !== undefined && newValue.length > maxSelectValues) {
            return;
        }
        props.onChange(
            [...newValue, ...disabledActiveValues].reduce((arr, v) => {
                if (disabledNotActiveValues.includes(v) || arr.includes(v)) {
                    return arr;
                }
                return [...arr, v];
            }, [])
        );
    };

    const openSelect = () => {
        setActive(true);
    };

    const closeSelect = () => {
        setActive(false);
    };

    const toggleSelectAll = () => {
        if (isFieldEmpty) {
            onChange(values.map(v => v.value));
        } else {
            onChange([]);
        }
    };

    const onReset = () => {
        onChange([]);
        setFocusedItem(0);
        setSearch('');
        if (props.onSearch) {
            props.onSearch('');
        }
    };

    const onKeyDownInput = (e: KeyboardEvent<any>) => {
        if (!active) {
            openSelect();
        }
        if (e.keyCode === KeyCodes.ArrowUp) {
            e.preventDefault();
            if (focusedItem <= 0) {
                setFocusedItem(lastValuesIndex);
            } else {
                setFocusedItem(focusedItem - 1);
            }
        } else if (e.keyCode === KeyCodes.ArrowDown) {
            e.preventDefault();
            if (focusedItem >= lastValuesIndex) {
                setFocusedItem(0);
            } else {
                setFocusedItem(focusedItem + 1);
            }
        } else if (e.keyCode === KeyCodes.Enter) {
            e.preventDefault();
            if (focusedItem === lastValuesIndex) {
                toggleSelectAll();
            } else if (values[focusedItem]) {
                const focusedValue = values[focusedItem].value;
                if (value.includes(focusedValue)) {
                    onChange(value.filter(item => item !== focusedValue));
                } else {
                    onChange([...value, values[focusedItem].value]);
                }
            }
        } else if (e.keyCode === KeyCodes.Escape) {
            openSelect();
        } else if (e.keyCode === KeyCodes.Tab) {
            closeSelect();
        } else if (e.keyCode === KeyCodes.Backspace) {
            if (search === '') {
                onChange(value.slice(0, -1));
            }
        }
    };

    const focusInput = () => {
        const element: HTMLOrSVGElement =
            props.searchable
                ? searchInputRef?.current
                : blockInputRef?.current;
        if (element) {
            element.focus();
        }
    };

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setFocusedItem(0);
        setSearch(e.target.value);
        if (props.onSearch) {
            props.onSearch(e.target.value);
        }
    };

    const outsideClickRef = useOutsideClick(() => {
        if (active) {
            closeSelect();
        }
    });

    useEffect(() => {
        if (listRef && listRef.current) {
            listRef.current.scrollToRow(focusedItem);
        } else {
            setFocusedItem(0);
        }
    }, [focusedItem]);

    return View({
        ...props,
        lastValuesIndex,
        onKeyDownInput,
        valuesRef,
        listRef,
        searchInputRef,
        blockInputRef,
        focusedItem,
        setFocusedItem,
        active,
        setActive,
        onReset,
        value: valueStr,
        search,
        setSearch,
        originalValues,
        values,
        openSelect,
        closeSelect,
        toggleSelectAll,
        outsideClickRef,
        focusInput,
        onInputSearch: (e) => {
            onSearch(e);
        },
        changeValue: (val) => {
            const newValue = valueStr.includes(val)
                ? valueStr.filter(v => v !== val)
                : [...valueStr, val];
            onChange(newValue);
        },
    });
};
