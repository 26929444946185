/**
 * Статус импорта файла пу
 */
export enum MeterFileImportStatus {
    Received = 'received',
    Processing = 'processing',
    Finished = 'finished',
    Error = 'error'
}

export const meterFileImportStatusDictionary: Record<MeterFileImportStatus, string> = {
    [MeterFileImportStatus.Received]: 'Получен',
    [MeterFileImportStatus.Processing]: 'В обработке',
    [MeterFileImportStatus.Finished]: 'Загружен',
    [MeterFileImportStatus.Error]: 'Ошибка',
}
