export enum MeterGisTasksStatus {
    open = 10,
    closed = 20,
    archived = 30,
}

export const MeterGisTasksStatusNames: Record<MeterGisTasksStatus, string> = {
    [MeterGisTasksStatus.archived]: 'Архив',
    [MeterGisTasksStatus.open]: 'Открытая',
    [MeterGisTasksStatus.closed]: 'Закрытая',
};
