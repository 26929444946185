import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { IStateProps } from './model';
import { Icon } from '../icon';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    area: {
        boxSizing: 'border-box',
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: theme.palette.neutral.light,
        minHeight: 180,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: 20,
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
    },
    areaDragging: {
        borderColor: theme.palette.primary.main,
        '& *': {
            pointerEvents: 'none',
        },
    },
    areaDisabled: {
        opacity: 0.5,
        pointerEvents: 'none',
        cursor: 'not-allowed',
        '& *': {
            pointerEvents: 'none',
            color: 'inherit',
        },
    },
    inputFile: {
        opacity: 0,
        zIndex: -1,
        left: -200,
        position: 'absolute',
    },
    limits: {
        marginTop: 2,
    },
    limitsLabel: {
        ...theme.typography.label,
        color: theme.palette.text.hint,
        marginBottom: 4,
    },
    description: {
        userSelect: 'none',
    },
    descriptionText: {
        ...theme.typography.body,
        color: theme.palette.neutral.light,
        textAlign: 'center',
    },
    descriptionIcon: {
        color: theme.palette.grey.A100,
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
        marginBottom: 11,
        '& svg': {
            width: 60,
            height: 60,
        },
        '$areaDragging &': {
            color: theme.palette.primary.main,
        },
    },
    descriptionLink: {
        ...theme.typography.bodyBold,
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
}));

export const View = (props: IStateProps) => {
    const classes = useStyles();
    return <div className={classNames(classes.root)}>
        <div
            onDrop={e => props.handleDrop(e)}
            onDragOver={e => props.handleDrag(e)}
            onDragEnter={e => props.handleDragIn(e)}
            onDragLeave={e => props.handleDragOut(e)}
            onClick={() => props.inputRef.current.click()}
            className={classNames(
                classes.area,
                {
                    [classes.areaDisabled]: props.disabled,
                    [classes.areaDragging]: props.isDragging,
                }
            )}>
            <div className={classes.description}>
                <span className={classes.descriptionIcon}>
                    <Icon name='upload' />
                </span>
                <div className={classes.descriptionText}>
                    Перетащите файл в эту область <br />
                    или <span
                        className={classes.descriptionLink}>Выберите файл</span>
                </div>
                <input
                    multiple
                    type='file'
                    ref={props.inputRef}
                    disabled={props.disabled}
                    className={classes.inputFile}
                    onChange={e => props.handleInput(e)}
                />
            </div>
        </div>
        <div className={classes.limits}>
            {props.accepted && <div className={classes.limitsLabel}>
                * Допустимые форматы - {props.accepted.join(', ')}
            </div>}
            <div className={classes.limitsLabel}>
                {props.accepted && '*'}* Файлы с одинаковыми именами не будут прикреплены
            </div>
        </div>
    </div>;
};
