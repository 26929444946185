import { makeStyles } from '@material-ui/core';

export const useUpdateFromAttributesStyles = makeStyles((theme) => ({
    pristineField: {
        color: theme.palette.neutral.light,
    },
    inputLabel: {
        textAlign: 'right',
    },
    buttonContainer: {
        paddingTop: '16px',
    },
    actionsModalContainer: {
        minWidth: '560px',
    },
    actionModalSmallContainer: {
        minWidth: '420px',
    },
    actionModalMiddleContainer: {
        minWidth: '700px',
    },
    actionsModalBigContainer: {
        minWidth: '840px',
    },
}));
