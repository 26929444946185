
import { Column } from '@softmedialab/materialui-table';

export const columns: Column[] = [
    {
        title: '№ строки в файле',
        field: 'rowNumber',
        cellClassName: 'col-rowNumber',
        sorting: true,
    },
    {
        title: 'Тип ПУ',
        field: 'meterModel',
        cellClassName: 'col-meterModel',
        sorting: false,
    },
    {
        title: 'Заводской номер',
        field: 'meterSerialNum',
        cellClassName: 'col-meterSerialNum',
        sorting: false,
    },
    {
        title: 'Текст ошибки',
        field: 'errorMessage',
        cellClassName: 'col-errorMessage',
        sorting: false,
    },
];
