import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterActionTaskShortViewModel } from '../../../../common/model/meter/meterActionTaskShortViewModel';
import {
    MeterChangePasswordOrderListResponse
} from '../../../../common/model/meter/meterChangePasswordOrder/meterChangePasswordOrderListResponse';

export interface MeterChangePasswordOrdersControl {
    list: MeterChangePasswordOrderListResponse | null;
    latestTask?: MeterActionTaskShortViewModel;
}

const initialState: MeterChangePasswordOrdersControl = {
    list: null,
    latestTask: null
};

export const meterChangePasswordOrdersControlSlice = createSlice({
    name: '@@vv/meterChangePasswordOrders/control',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<MeterChangePasswordOrderListResponse>): void {
            state.list = action.payload;
        },
        setLatestTask(state, action: PayloadAction<MeterActionTaskShortViewModel>): void {
            state.latestTask = action.payload;
        },
    }
});
