export enum MassOperationStatus {
    Planed = 'planed',
    Initiated = 'initiated',
    Started = 'started',
    Finished = 'finished',
}

export const massOperationStatusDictionary: Record<MassOperationStatus, string> = {
    [MassOperationStatus.Planed]: 'Запланирован',
    [MassOperationStatus.Initiated]: 'Начато обработка',
    [MassOperationStatus.Started]: 'Начат массовый опрос (создание задач)',
    [MassOperationStatus.Finished]: 'Окончен',
};
