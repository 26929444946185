// Модель ПУ
export enum MeterModelTypes {
	CE308 = 'CE308',
	CE208 = 'CE208',
	M2M1  = 'M2M-1',
	M2M3  = 'M2M-3',
	NEVACT411545 = 'НЕВА_СТ411',
    NEVACT413545 = 'НЕВА_СТ413_545_BSP-G3E',
    NEVACT414139 = 'Нева СТ414 139 BCSP-G3',
    NEVACT4121395 = 'НЕВА_СТ412',
	NEVAMT315 = 'НЕВА_МТ_315',
	NEVAMT115 = 'НЕВА_МТ_115',
    MIRC07 = 'МИР_С-07',
    MIRC04 = 'МИР_С-04',
    CEB1 = 'СЭБ-1',
    CEB = 'CEB',
    PSC4TM05MK04510A = 'ПСЧ-4ТМ-10',
    PSC4TM05MK045100A = 'ПСЧ-4ТМ-100',
    M2M31 = 'M2M-3-1',
    M2M1S = 'M2M-1S',
    M2M3S = 'M2M-3S',
    MERKURIJ203 = 'Меркурий 203',
    MERKURIJ204 = 'Меркурий 204',
    MERKURIJ230 = 'Меркурий 230',
    MERKURIJ234 = 'Меркурий 234',
    MIRS05 = 'МИР_С-05',
    CE301 = 'CE301',
    CE303 = 'CE303',
    PSCH3TA = 'ПСЧ-3ТА',
    PSCH3ART = 'ПСЧ-3АРТ',
    PSCH4TM05 = 'ПСЧ-4ТМ.05',
    SEB2 = 'СЭБ-2',
    SET4TM = 'СЭТ-4ТМ',
    MIRTEK1 = 'МИРТЕК-1',
    MIRTEK3 = 'МИРТЕК-3',
    MAYAK301ART = 'МАЯК-301АРТ',
    FOBOS1 = 'ФОБОС 1',
    FOBOS3 = 'ФОБОС 3',
    RIM189 = 'РиМ 189',
    RIM489 = 'РиМ 489',
    NEVASP1 = 'Нева СП1',
    NEVASP3 = 'Нева СП3',
    PSCH3ART09 = 'ПСЧ-3АРТ.09',
    PSCH3ART07D = 'ПСЧ-3АРТ.07(Д)',
    PSCH4TM06T05 = 'ПСЧ-4ТМ.06Т.05',

/**
     * @deprecated
     */
	M2M11 = 'M2M-1-1',
    /**
     * @deprecated
     */
}

export const meterModelDictionary: Record<MeterModelTypes, string> = {
	[MeterModelTypes.CE308]: 'CE308 S31.543.OAG.SYUVJLFZ GS01',
	[MeterModelTypes.CE208]: 'CE208 S7.846.2.OG.QYUVFLZ GS01',
	[MeterModelTypes.M2M1 ]: 'M2M-1',
	[MeterModelTypes.M2M3 ]: 'M2M-3',
	[MeterModelTypes.NEVACT411545]: 'НЕВА СТ411 545 BPIO22-G1E',
    [MeterModelTypes.NEVACT413545]: 'НЕВА СТ413 545 BSP-G3E',
    [MeterModelTypes.NEVACT414139]: 'НЕВА СТ414 139 BCSP-G3',
	[MeterModelTypes.NEVACT4121395]: 'НЕВА СТ412 139 BPIO22-G1E',
	[MeterModelTypes.NEVAMT315]: 'НЕВА МТ 315 05 AR GSM11BSRP25',
	[MeterModelTypes.NEVAMT115]: 'НЕВА МТ 115 AR2S GSM1PC',
	[MeterModelTypes.M2M11]: 'M2M-1-1/2-80D-2G',
    [MeterModelTypes.MIRC07]: 'МИР_С-07',
    [MeterModelTypes.MIRC04]: 'МИР_С-04',
    [MeterModelTypes.CEB1]: 'СЭБ-1',
    [MeterModelTypes.CEB]: 'CEB',
    [MeterModelTypes.PSC4TM05MK04510A]: 'ПСЧ-4ТМ-10',
    [MeterModelTypes.PSC4TM05MK045100A]: 'ПСЧ-4ТМ-100',
    [MeterModelTypes.M2M31]: 'M2M-3-1',
    [MeterModelTypes.M2M1S]: 'M2M-1S',
    [MeterModelTypes.M2M3S]: 'M2M-3S',
    [MeterModelTypes.MERKURIJ203]: 'Меркурий 203',
    [MeterModelTypes.MERKURIJ204]: 'Меркурий 204',
    [MeterModelTypes.MERKURIJ230]: 'Меркурий 230',
    [MeterModelTypes.MERKURIJ234]: 'Меркурий 234',
    [MeterModelTypes.MIRS05]: 'МИР_С-05',
    [MeterModelTypes.CE301]: 'CE301',
    [MeterModelTypes.CE303]: 'CE303',
    [MeterModelTypes.PSCH3TA]: 'ПСЧ-3ТА',
    [MeterModelTypes.PSCH3ART]: 'ПСЧ-3АРТ',
    [MeterModelTypes.PSCH4TM05]: 'ПСЧ-4ТМ.05',
    [MeterModelTypes.SEB2]: 'СЭБ-2',
    [MeterModelTypes.SET4TM]: 'СЭТ-4ТМ',
    [MeterModelTypes.MIRTEK1]: 'МИРТЕК-1',
    [MeterModelTypes.MIRTEK3]: 'МИРТЕК-3',
    [MeterModelTypes.MAYAK301ART]: 'МАЯК-301АРТ',
    [MeterModelTypes.FOBOS1]: 'ФОБОС 1',
    [MeterModelTypes.FOBOS3]: 'ФОБОС 3',
    [MeterModelTypes.RIM189]: 'РиМ 189',
    [MeterModelTypes.RIM489]: 'РиМ 489',
    [MeterModelTypes.NEVASP1]: 'Нева СП1',
    [MeterModelTypes.NEVASP3]: 'Нева СП3',
    [MeterModelTypes.PSCH3ART09]: 'ПСЧ-3АРТ.09',
    [MeterModelTypes.PSCH3ART07D]: 'ПСЧ-3АРТ.07(Д)',
    [MeterModelTypes.PSCH4TM06T05]: 'ПСЧ-4ТМ.06Т.05',
};
