import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { EllipsisTooltip } from '../../../ellipsisTooltip';
import React from 'react';
import { useStyles } from '../styles';
import { BlockItemProps } from '../model';
import { Icon } from '../../../icon';
import { IconType } from '../../../icon/model';
import { Typography } from 'app/shared/components/typography';

export const View: React.FC<BlockItemProps> = ({ item, type, className, count }) => {
    const classes = useStyles({ count });

    let iconName: IconType = null;
    switch (item.status) {
        case 'success':
            iconName = 'success';
            break;
        case 'error':
            iconName = 'warning';
            break;
        case 'inProgress':
            iconName = 'inProgress';
            break;
        case 'notStarted':
            iconName = 'notStarted';
            break;
        default: iconName = null;
    }

    return (
        <Box className={classNames({
            [classes.verticalPosition]: type === 'vertical',
            [classes.itemWrapperHorizontal]: type === 'horizontal',
        }, className)}>
            <Box mb={1} mr={1}>
                <Typography className={classes.title} variant="caption">
                    <EllipsisTooltip text={item.title}>{item.title}</EllipsisTooltip>
                </Typography>
            </Box>
            <Typography className={classes.item} variant="caption">
                <Box display="flex" alignItems='center' className={classNames(classes.blockItem, classes[item.status])}>
                    <Icon name={iconName} className={classes.statusIcon} />
                    <Box className={classes.blockItemText} ml={1}><EllipsisTooltip text={item.value}>{item.value}</EllipsisTooltip></Box>
                </Box>
            </Typography>
        </Box>
    );
};
