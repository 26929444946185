import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { appUrls } from '../../../../common/appUrls';
import { SurveyConditionQueryParams, SurveyConditionResponse } from '../../../../common/model/meter/monitoring/surveryCondition';
import { surveyConditionSlice } from '../../../reducers/monitoring/surveyCondition';
import { AppThunk } from '../../index';

export const setMonitoringSurveyCondition = (data: SurveyConditionResponse): AppThunk => (dispatch) => {
    dispatch(surveyConditionSlice.actions.setSurveyConditionResponse(data));
};

export const getMonitoringSurveyCondition = (params: SurveyConditionQueryParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_monitoring_survey_condition, {}, params);
    const data = await httpService.get<SurveyConditionResponse>(route);
    dispatch(setMonitoringSurveyCondition(data));
};
