import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { MeterProfileReportInterval, MeterProfileReportQueryParams } from '../../../../../common/model/meter/reports/meterProfileReport';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { getMeterProfileReport, resetMeterProfileReport } from '../../../../../redux/actions/reports';
import { meterProfileReportSelector } from '../../../../../redux/selectors/reports/meterProfileReport';
import { useMeterAutocomplete } from '../../../../shared/components/autocomplete/hooks/useMeterAutocomplete';
import { BlockProps } from '../../../../shared/components/wrapper/block/model';
import { defaults, ReportPageTabs, ReportTabs, Routes } from '../../../../shared/constants';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useSyncQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useWebDictionary, useWebDictionarySelectSuggestions } from '../../../../shared/hooks/useWebDictionary';
import { getErrorMessage } from '../../../../shared/utils/error';
import { transformDatesParamsWithoutEnds } from '../../../../shared/utils/transformers/dates';
import { makeExcelReport } from './excel';
import {
    getMeterProfileReportResponseIntervalString,
    getMeterProfileReportResponsePeriodDateString,
    transformMeterProfileReportDataViewsToMean,
    transformMeterProfileReportDataViewsToSummary,
    transformMeterProfileReportDataViewToTableItem
} from './helpers';
import { MeterProfileReportTableItem } from './model';
import { RenderFieldView, View } from './view';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { getRoute } from '../../../../shared/pipes';
import { useParams } from 'react-router-dom';

const filterFormDefaultValues: MeterProfileReportQueryParams = {
    dateFrom: moment(new Date()).add(-1, 'day').startOf('day').toDate(),
    dateTo: moment(new Date()).endOf('day').toDate(),
    groupingInterval: MeterProfileReportInterval.HalfHour,
    regionId: defaults.defaultRegionId,
    meterId: null
};

const customColumnSort = (field: keyof MeterProfileReportTableItem) => (
    data1: MeterProfileReportTableItem,
    data2: MeterProfileReportTableItem,
): number => {
    if (data1?.rowNumber === null) {
        return 1;
    }
    if (data2?.rowNumber === null) {
        return 1;
    }
    if (field !== 'readingDateView' && isNaN(+data1?.[field])) {
        return -1;
    }
    if (field !== 'readingDateView' && isNaN(+data2?.[field])) {
        return -1;
    }
    if (field === 'sumActivePowerExp' || field === 'sumActivePowerImp' || field === 'sumReactivePowerExp' || field === 'sumReactivePowerImp') {
        return +data2?.[field] > +data1?.[field] ? 1 : -1;
    } else {
        return data2?.[field] > data1?.[field] ? 1 : -1;
    }
};

export const MeterProfileReport: React.FC = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const response = useSelector(meterProfileReportSelector());
    const [isLoading, setIsLoading] = useState(false);
    const { notHasAccess } = useAccessRules();
    const params = useParams<{meterId?: string}>();
    const meterId = params.meterId;

    const isExcelExportNotAvailable = notHasAccess(AccessRule.CanGetNotConnectedMetersExcel) || response?.data?.length === 0;

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Отчетность', link: getRoute(Routes.report, { meterId }, { tab: ReportTabs }) },
        { name: 'Профиль ПУ: A+ A- R+ R-', link: getRoute(Routes.report, { meterId }, { tab: ReportPageTabs.NotConnectedMeters }) },
    ]);

    useEffect(() => {
        return () => {
            dispatch(resetMeterProfileReport());
        };
    }, []);

    const dataSource = response?.data ?? [];
    const meterTimeZone = response?.meterTimeZone ?? '';

    const isReportFormed = !!response;

    const [queryParams, setQueryParams] = useSyncQueryParams<MeterProfileReportQueryParams>(filterFormDefaultValues);

    const form = useForm<MeterProfileReportQueryParams>({
        defaultValues: queryParams,
    });

    const formData = form.watch();

    const fetch = async () => {
        setIsLoading(true);
        try {
            setQueryParams(transformDatesParamsWithoutEnds(formData));
            await dispatch(getMeterProfileReport(transformDatesParamsWithoutEnds(formData)));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    const columns: Column[] = [
        {
            title: `Дата и время списания показаний${ meterTimeZone ? ` (${meterTimeZone})` : ''}`,
            field: 'readingDateView',
            sorting: true,
            customSort: customColumnSort('readingDateView'),
            render: (data: MeterProfileReportTableItem) => {
                return RenderFieldView(data, 'readingDateView');
            },
        },
        {
            title: 'Активная энергия, отдача. Значения на интервале, кВт*ч',
            field: 'sumActivePowerExp',
            sorting: true,
            customSort: customColumnSort('sumActivePowerExp'),
            render: (data: MeterProfileReportTableItem) => {
                return RenderFieldView(data, 'sumActivePowerExp');
            },
        },
        {
            title: 'Активная энергия, прием. Значения на интервале, кВт*ч',
            field: 'sumActivePowerImp',
            sorting: true,
            customSort: customColumnSort('sumActivePowerImp'),
            render: (data: MeterProfileReportTableItem) => {
                return RenderFieldView(data, 'sumActivePowerImp');
            },
        },
        {
            title: 'Реактивная энергия, отдача. Значения на интервале, кВт*ч',
            field: 'sumReactivePowerExp',
            sorting: true,
            customSort: customColumnSort('sumReactivePowerExp'),
            render: (data: MeterProfileReportTableItem) => {
                return RenderFieldView(data, 'sumReactivePowerExp');
            },
        },
        {
            title: 'Реактивная энергия, прием. Значения на интервале, кВт*ч',
            field: 'sumReactivePowerImp',
            sorting: true,
            customSort: customColumnSort('sumReactivePowerImp'),
            render: (data: MeterProfileReportTableItem) => {
                return RenderFieldView(data, 'sumReactivePowerImp');
            },
        },
    ];

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const reportDatePeriod =
        isReportFormed
            ?
            getMeterProfileReportResponsePeriodDateString(response, queryParams)
            : null;

    const { meterModels } = useWebDictionary();

    const meterModelsById = useMemo(() => _.keyBy(meterModels, 'id'), [meterModels]);

    const headerBlock: BlockProps = {
        type: 'horizontal',
        items: [
            {
                title: 'Заводской номер',
                value: response?.meterSerialNum,
                type: 'text',
            },
            {
                title: 'Тип ПУ',
                value: meterModelsById[response?.meterModelId]?.name ?? '–',
                type: 'text',
            },
            {
                title: 'Период',
                value: reportDatePeriod,
                type: 'text',
            },
            {
                title: 'Интервал',
                value: getMeterProfileReportResponseIntervalString(response),
                type: 'text',
            },
        ],
    };

    const headingOnBackHandler = () => {
        dispatch(push(Routes.report));
    };

    const meterAutocomplete = useMeterAutocomplete({
        id: queryParams.meterId,
    });

    const data = useMemo(() => ([
        ...dataSource.map<MeterProfileReportTableItem>(transformMeterProfileReportDataViewToTableItem),
        ...(dataSource.length > 0
            ? [
                transformMeterProfileReportDataViewsToSummary(dataSource),
                transformMeterProfileReportDataViewsToMean(dataSource),
            ]
            : []
        )
    ]), [dataSource]);

    return View({
        data,
        form,
        columns,
        isLoading,
        breadcrumbs,
        headerBlock,
        isReportFormed,
        onClickBreadcrumb,
        meterAutocomplete,
        regionSuggestions,
        headingOnBackHandler,
        isExcelExportNotAvailable,
        onClickFormExcel: async () => {
            setIsLoading(true);
            try {
                await makeExcelReport({
                    response,
                    meterModelsById,
                    params: queryParams,
                    filename: 'Профиль А+А-R+R-',
                });
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
            setIsLoading(false);
        },
        onClickFormReport: async () => {
            await fetch();
        },
        onClickRefresh: async () => {
            await fetch();
        },
    });
};
