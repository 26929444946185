export enum InspectionLogSourceType {
    MetersController = 1,
    Core = 2,
    MetersControllerEmulator = 3
}

export const InspectionLogSourceTypeDictionary: Record<InspectionLogSourceType, string> = {
    [InspectionLogSourceType.MetersController]: 'Сервер опроса',
    [InspectionLogSourceType.Core]: 'Ядро системы',
    [InspectionLogSourceType.MetersControllerEmulator]: 'Эмулятор сервера опроса',
};
