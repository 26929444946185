import { View } from './view';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from 'redux/actions';
import { UploaderFile } from 'app/shared/components/uploader/uploaderFile/model';
import { SnackbarNotification } from 'redux/reducers/controls/snackbar';
import { createSnackbar } from 'redux/actions/controls';
import { postImportCrqMetersByExcel } from 'redux/actions/meterImportCrq/index';
import { ImportCrqUploaderStateProps } from './model';
import { sleep } from '../../../../../../../shared/pipes';
import { msInSecond } from '../../../../../../../shared/utils/dates';

export const ImportCrqUploader = ({ handleCloseModal, updateData }: ImportCrqUploaderStateProps) => {
    const dispatch = useDispatch<BaseDispatch>();
    return View({
        onSubmit: (files: UploaderFile[]) => {
            return files.map(file => ({
                file,
                promise: async () => {
                    const response = await postImportCrqMetersByExcel(file.file);
                    const options: SnackbarNotification = {
                        message: `Загрузка файла ${file.file.name} выполнена успешно`,
                        type: 'green',
                    };
                    handleCloseModal();
                    if (!response) {
                        throw new Error('Загрузка файла не удалась');
                    }

                    await sleep(msInSecond);

                    updateData();
                    dispatch(createSnackbar(options));
                }
            }));
        },
        onUploadEnd: () => {
            const options: SnackbarNotification = {
                message: 'Загрузка файла выполнена успешно, файлы будут обработаны в ближайшее время.',
                type: 'green',
            };
            dispatch(createSnackbar(options));
        },
    });
};
