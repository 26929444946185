import { Column } from '@softmedialab/materialui-table';
import { SetOrderFunc } from './model';

export const setOrderTransformer = (columnDefinition: Column[], setOrder: SetOrderFunc) => {
    return async (orderBy: number, orderDirection: ('asc' | 'desc')) => {
        await setOrder(
            columnDefinition[orderBy].field,
            orderDirection
        );
    };
};
