import { ISort } from '../../../../../common/sort';
import {
    resetMetersDynamicGroupList,
    getMetersDynamicGroupList,
} from '../../../../../redux/actions/meterGroup';
import { meterGroupMetersListSelector } from '../../../../../redux/selectors/meterGroup';
import { usePagination } from '../../../../shared/components/pagination';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { getErrorMessage } from '../../../../shared/utils/error';
import { DynamicMeterGroupFormModel } from '../commonModel';
import { EditGroupProps } from './static/model';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../../redux/actions';
import { useEffect, useState } from 'react';
import { getRoute } from '../../../../shared/pipes';
import { AdministrationTabs, Routes } from '../../../../shared/constants';
import {
    editMeterGroups,
    getMeterGroups,
    getStaticMeterGroupContent,
} from '../../../../../redux/actions/meters';
import { push } from 'connected-react-router';
import { View } from './view';
import { createSnackbar } from '../../../../../redux/actions/controls';
import {
    dynamicMeterGroupAddressesSelector,
    dynamicMeterGroupParamsSelector,
} from '../../../../../redux/selectors/meters';
import { DynamicMeterGroupParamsViewModel } from '../../../../../../../common/model/meter/meter/dynamicMeterGroupParamsViewModel';
import { useForm } from 'react-hook-form';
import {
    createDynamicMeterGroupAddresses,
    deleteDynamicMeterGroupAddresses,
    deleteMeterGroup,
    getDynamicMeterGroupAddresses,
    getDynamicMeterGroupParams,
    setDynamicMeterGroupParams,
    updateDynamicMeterGroupAddresses,
    updateDynamicMeterGroupParams,
} from '../../../../../redux/actions/groups';
import yup from '../../../../shared/utils/yup';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import { MeterGroupViewModel } from '../../../../../../../common/model/meter/meterGroup/meterGroupViewModel';
import { addressesQueryTransformer } from '../../../../../common/shared/utils/addresses';

const meterGroupValidationSchema = yup.object({
    regionId: yup.number().required('Регион обязательное поле'),
    name: yup.string().required('Название группы обязательное поле'),
    visibleToAll: yup.boolean().required(),
    isStatic: yup.boolean().required('Тип группы обязательное поле'),
});

const dynamicMeterGroupParamsInitialValues: DynamicMeterGroupParamsViewModel = {
    meterModel: null,
    status: null,
    relayOn: null,
    relayStatus: null,
    accountType: null,
    meterPurpose: null,
    legalEntityType: null,
    priceCategory: null,
    meterActionSchemeId: null,
    readingSource: null,
};

export const EditGroupForm = (props: EditGroupProps) => {
    const { group } = props;
    const dispatch = useDispatch<BaseDispatch>();
    const meterGroupDynamicMetersList = useSelector(
        meterGroupMetersListSelector()
    );
    const metersTotal = meterGroupDynamicMetersList?.total ?? 0;
    const dynamicMeterGroupParams = useSelector(dynamicMeterGroupParamsSelector);
    const dynamicMeterGroupAddresses = useSelector(
        dynamicMeterGroupAddressesSelector
    );

    const [isShowParams, setIsShowParams] = useState<boolean>(false);
    const [isDisabledSave, setIsDisabledSave] = useState<boolean>(true);
    const [isRemoveModal, setIsRemoveModal] = useState<boolean>(false);
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

    const formDynamicMeterGroupParamsDefaultValues: DynamicMeterGroupFormModel = {
        params: dynamicMeterGroupParams ?? dynamicMeterGroupParamsInitialValues,
        addresses: dynamicMeterGroupAddresses ?? [],
    };

    const formDynamicMeterGroupParams = useForm<DynamicMeterGroupFormModel>({
        defaultValues: formDynamicMeterGroupParamsDefaultValues,
    });

    const formDynamicMeterGroupParamsFormData = formDynamicMeterGroupParams.watch();

    const resolver = useYupValidationResolver<MeterGroupViewModel>(
        meterGroupValidationSchema
    );
    const formGroup = useForm<MeterGroupViewModel>({
        defaultValues: group,
        resolver,
    });

    const meterGroupFilter = formGroup.watch();

    useEffect(() => {
        dispatch(getStaticMeterGroupContent(group.id));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetMetersDynamicGroupList());
        };
    }, []);

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        {
            name: 'Администрирование',
            link: getRoute(Routes.administration, null, {
                tab: AdministrationTabs.Schemes,
            }),
        },
        {
            name: 'Управление группами ПУ',
            link: getRoute(Routes.administration, null, {
                tab: AdministrationTabs.Groups,
            }),
        },
        { name: 'Просмотр и редактирование группы', link: null },
    ]);

    const onCancel = () => {
        if (!isDisabledSave) {
            setIsFormOpen(true);
            return;
        }
        dispatch(
            push(
                getRoute(Routes.administration, null, {
                    tab: AdministrationTabs.Groups,
                })
            )
        );
    };

    const fetchGroupData = async () => {
        await dispatch(getMeterGroups());
        await dispatch(getDynamicMeterGroupParams({ groupId: group.id }));
        await dispatch(getDynamicMeterGroupAddresses({ groupId: group.id }));
    };

    useEffect(() => {
        fetchGroupData();
    }, []);

    const fetchMeters = async (
        sort: ISort,
        data: DynamicMeterGroupFormModel = formDynamicMeterGroupParamsFormData
    ) => {
        const addresses = data.addresses?.length
            ? addressesQueryTransformer(data.addresses)
            : undefined;

        await dispatch(
            getMetersDynamicGroupList({
                ...sort,
                ...data.params, // ToDo: надо наверное маппер в фильтр параметров ПУ
                id: undefined,
                addresses,
                regionId: meterGroupFilter.regionId,
            })
        );
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination(
        {
            page: 1,
            rowsPerPage: 10,
            orderBy: null,
            orderDirection: null,
        },
        fetchMeters
    );

    useEffect(() => {
        setIsShowParams(!!formDynamicMeterGroupParamsDefaultValues.params);
        formDynamicMeterGroupParams.reset(formDynamicMeterGroupParamsDefaultValues);
        if (formDynamicMeterGroupParamsDefaultValues.params) {
            onFetch(pagination, formDynamicMeterGroupParamsDefaultValues);
        }
    }, [dynamicMeterGroupParams, dynamicMeterGroupAddresses]);

    const onSubmitGroupParams = async (data: DynamicMeterGroupFormModel) => {
        try {
            setIsDisabledSave(false);
            setIsShowParams(Object.entries(data).some((item) => item.length));
            await onFetch(pagination, data);
        } catch (e) {
            dispatch(
                createSnackbar({
                    type: 'red',
                    delay: 5000,
                    message: getErrorMessage(e),
                })
            );
        }
    };

    // Функция сохранения
    const onSubmitGroup = async (data: any) => {
        try {
            await dispatch(editMeterGroups(data.id, data));
            const { params, addresses } = formDynamicMeterGroupParamsFormData;
            if (dynamicMeterGroupParams) {
                dispatch(updateDynamicMeterGroupParams({ groupId: group.id }, params));
            } else {
                dispatch(setDynamicMeterGroupParams({ groupId: group.id }, params));
            }
            for (const address of addresses) {
                if (address.id) {
                    await updateDynamicMeterGroupAddresses(group.id, address.id, {
                        ...address,
                        regionId: group.regionId,
                        meterGroupId: group.id,
                    });
                } else {
                    await createDynamicMeterGroupAddresses(group.id, {
                        ...address,
                        regionId: group.regionId,
                        meterGroupId: group.id,
                    });
                }
            }
            const addressIds = addresses.map((item) => item.id).filter((id) => !!id);
            const deletableAddresses = dynamicMeterGroupAddresses.filter(
                (item) => !addressIds.includes(item.id)
            );
            for (const deletableAddress of deletableAddresses) {
                await deleteDynamicMeterGroupAddresses(
                    deletableAddress.meterGroupId,
                    deletableAddress.id
                );
            }

            await fetchGroupData();

            dispatch(
                createSnackbar({
                    type: 'green',
                    delay: 5000,
                    message: 'Группа успешно сохранена',
                })
            );
        } catch (e) {
            dispatch(
                createSnackbar({
                    type: 'red',
                    delay: 5000,
                    message: getErrorMessage(e),
                })
            );
        }
    };

    const onCloseForm = () => {
        setIsFormOpen(false);
    };

    const saveGroup = async () => {
        await onSubmitGroup(formGroup.getValues());
    };

    const cancelGroup = () => {
        dispatch(
            push(
                getRoute(Routes.administration, null, {
                    tab: AdministrationTabs.Groups,
                })
            )
        );
    };

    const removeGroup = async () => {
        await dispatch(deleteMeterGroup({ groupId: group.id }));
        dispatch(
            createSnackbar({
                type: 'green',
                message: 'Группа успешно удалена',
            })
        );
        dispatch(
            push(
                getRoute(Routes.administration, null, {
                    tab: AdministrationTabs.Groups,
                })
            )
        );
    };

    const cancelRemove = () => {
        setIsRemoveModal(false);
    };

    const onShowRemoveModal = () => {
        setIsRemoveModal(true);
    };

    return View({
        dataTotal: metersTotal,
        orderParams,
        pagination,
        setOrder,
        setPage,
        onCancel,
        onCloseForm,
        saveGroup,
        cancelGroup,
        initialValues: group,
        onSubmitGroup,
        onClickBreadcrumb,
        breadcrumbs,
        groupName: group.name,
        group,
        isShowParams,
        onSubmitGroupParams,
        formDynamicMeterGroupParams,
        formDynamicMeterGroupParamsFormData,
        isDisabledSave,
        isFormOpen,
        formGroup,
        isRemoveModal,
        removeGroup,
        cancelRemove,
        onShowRemoveModal,
        meterGroupFilter,
    });
};
