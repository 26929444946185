import { useState, useEffect } from 'react';
import { View } from './view';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { BaseDispatch } from 'redux/actions';
import { MeterFileImportQueryParams } from 'common/model/meter/importCrq/meterFileImportQueryParams';
import { useSyncQueryParams } from 'app/shared/hooks/useQueryParams';
import { createSnackbar } from 'redux/actions/controls';
import { getErrorMessage } from 'app/shared/utils/error';
import { findMeterImportCrqList } from 'redux/actions/meterImportCrq';
import { importCrqListSelector } from 'redux/selectors';
import { usePagination } from 'app/shared/components/pagination';
import { useDebounce } from 'app/shared/hooks/useDebounce';
import { ISort } from 'common/sort';
import { useAccessRules } from 'app/shared/hooks/useAccessRules';
import { AccessRule } from 'common/model/access/accessRule';

const initialQueryParams: MeterFileImportQueryParams = {
    status: null,
};

const fetchDebounceTimeMS = 600;

export const ImportCrq = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const [isVisibleUploadModal, setVisibleUploadModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [queryFilter, setQueryFilter] = useSyncQueryParams<MeterFileImportQueryParams>(initialQueryParams);
    const form = useForm<MeterFileImportQueryParams>({ defaultValues: queryFilter });
    const formData = form.watch();
    const importCrqList = useSelector(importCrqListSelector);
    const { notHasAccess } = useAccessRules();
    const isDisabledUploadFile = notHasAccess(AccessRule.CanPostPartnerCrqMetersByExcel);

    const fetch = async (sort: ISort, params: MeterFileImportQueryParams = queryFilter) => {
        const newParams = {
            sortFieldName: sort.sortFieldName,
            isErrors: params.isErrors,
            status: params.status,
        };
        setQueryFilter(newParams);
        setIsLoading(true);
        try {
            await dispatch(findMeterImportCrqList(newParams));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    const { pagination, setOrder, onFetch, orderParams } = usePagination({
        orderBy: null,
        orderDirection: null,
    }, fetch);


    const refreshTableData = (dataFilterParams: MeterFileImportQueryParams) => {
        onFetch(pagination, dataFilterParams);
    };

    const changesFormData = useDebounce(formData, fetchDebounceTimeMS);

    useEffect(() => {
        refreshTableData({ ...changesFormData });
    }, [changesFormData]);

    const onOpenUploadModal = () => {
        setVisibleUploadModal(true);
    };

    const onCloseUploadModal = () => {
        setVisibleUploadModal(false);
    };

    const updateData = () => {
        refreshTableData(queryFilter);
    };

    return View({
        isVisibleUploadModal,
        onOpenUploadModal,
        onCloseUploadModal,
        form,
        isLoading,
        data: importCrqList,
        setOrder,
        orderParams,
        isDisabledUploadFile,
        updateData
    });
};
