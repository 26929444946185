import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import * as React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import classNames from 'classnames';
import './style.scss';

import { IStateProps } from './model';

export const View = (props: IStateProps) => {

    const { user } = props;

    return (
        <Container className='profile m-b-50' maxWidth='sm'>
            <Avatar className={classNames('m-t-30', 'bg-primary')}>
                <AccountCircleIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
                Профиль пользователя
            </Typography>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            Email:
                        </TableCell>
                        <TableCell>
                            {user.email}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Container>
    );
};
