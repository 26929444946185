import React, { Ref, useCallback, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { VisibilityIcon, VisibilityOffIcon } from '../../../../assets/icons';
import { StateProps } from './model';
import classNames from 'classnames';
import { Icon } from '../icon';

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%',
    },
    root: {
        '& svg': {
            cursor: 'pointer',
        },
        '& .MuiOutlinedInput-root': {
            paddingLeft: '3px',
        },
        '& .MuiFormHelperText-root': {
            color: theme.palette.system.error,
            fontSize: '13px',
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: theme.palette.system.error,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
        },
        '& .MuiInputBase-root svg': {
            fill: theme.palette.primary.light,
        },
        '& .MuiFormLabel-filled.MuiInputLabel-outlined': {
            pointerEvents: 'all',
        },
        '& .MuiInputLabel-formControl': {
            right: 20,
        },
        '& .MuiInputLabel-outlined.Mui-focused': {
            pointerEvents: 'all',
            right: 'unset',
            width: '100%',
        },
        '& .MuiInputLabel-root': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '75%',
        },
    },
    absolute: {
        '& .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error': {
            position: 'absolute',
            top: 38,
            left: -2
        },
    },
    dark: {
        color: theme.palette.neutral.white,
        '& .MuiInputLabel-root': {
            color: theme.palette.neutral.white,
        },
        '& .MuiOutlinedInput-root': {
            color: theme.palette.neutral.white,
        },
        '& .MuiOutlinedInput-root fieldset': {
            borderColor: theme.palette.neutral.white,
        },
        '&:hover': {
            '& .MuiOutlinedInput-root fieldset': {
                borderColor: theme.palette.neutral.white,
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.neutral.white,
            },
        },
    },
    darkIcon: {
        '.MuiInputBase-root & svg': {
            fill: theme.palette.neutral.light,
        },
    },
}));

export const View = React.forwardRef(({
    value,
    label,
    disabled,
    onClick,
    type,
    error,
    helperText,
    fullWidth,
    dark,
    icon,
    inputProps,
    placeholder,
    className,
    onIconClick,
    isShowHidePasswordVisible,
    leftIcon,
    min,
    variant,
    isErrorTextAbsolute = true,
    ...props
}: StateProps, ref: Ref<any>) => {

    const [isIconClicked, setIsIconClicked] = useState(false);

    const classes = useStyles();

    const isPasswordWithIcon = type === 'password' && isShowHidePasswordVisible;

    const inputType = (isPasswordWithIcon && isIconClicked)
        ? 'text'
        : type;

    const onClickIcon = (e: React.MouseEvent) => {
        if (onIconClick) {
            onIconClick(e);
        }
        setIsIconClicked(!isIconClicked);
    };

    const endAdornmentIcon = icon ?? (
        isPasswordWithIcon
            ? isIconClicked
                ? <VisibilityOffIcon fill='red' />
                : <VisibilityIcon fill='red' />
            : null
    );

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (inputType === 'number' && (min || min === 0)) {
            const inputValue = +e.target.value;
            if (inputValue < min) {
                return;
            }
        }
        props.onChange?.(e);
    }, [props.onChange, inputType, min]);

    return (
        <TextField
            variant={variant ?? 'outlined'}
            placeholder={placeholder}
            className={classNames(
                {
                    [classes.dark]: dark,
                    [classes.fullWidth]: fullWidth,
                    [classes.absolute]: isErrorTextAbsolute
                },
                classes.root,
                className,
            )}
            value={value ? value : ''}
            label={label}
            inputProps={inputProps}
            onClick={onClick}
            type={inputType ?? 'text'}
            disabled={disabled}
            error={error}
            helperText={helperText}
            fullWidth={fullWidth}
            ref={ref}
            InputProps={{
                autoComplete: 'off',
                endAdornment: endAdornmentIcon
                    ? <span
                        className={classNames({ [classes.darkIcon]: isPasswordWithIcon })}
                        onClick={onClickIcon}
                    >{endAdornmentIcon}</span>
                    : null,
                startAdornment: leftIcon
                    ? <Icon name={leftIcon} />
                    : null,
            }}
            {...props}
            onChange={onChange}
        />
    );
});
