import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../redux/actions';
import { createSnackbar, closeSnackbar } from '../../../redux/actions/controls/snackbar';
import { SnackbarNotification } from '../../../redux/reducers/controls/snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import { Button } from '../../shared/components/button';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    root: {
        marginRight: '15px',
    },
    icon: {
        cursor: 'pointer',
    }
}));

export const View = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const enqueueSnackbar = (args: SnackbarNotification) => dispatch(createSnackbar(args));
    const snackbarClose = (args: string) => dispatch(closeSnackbar(args));
    const classes = useStyles();

    const clickBlackSnackbar = () => {
        enqueueSnackbar({
            type: 'dark',
            delay: 1000,
            message: 'Black snackbar!',
            options: {
                key: new Date().getTime() + Math.random(),
            }
        });
    };
    const clickGreenSnackbar = () => {
        enqueueSnackbar({
            type: 'green',
            message: 'Green snackbar!',
            options: {
                key: new Date().getTime() + Math.random(),
            }
        });
    };
    const clickRedSnackbar = () => {
        enqueueSnackbar({
            type: 'red',
            delay: 0,
            message: 'Red snackbar!',
            options: {
                key: new Date().getTime() + Math.random(),
            }
        });
    };
    const action = (key: string) => (
        <Fragment>
            <Button variant='primary' className={classes.root} onClick={() => { alert(`I belong to snackbar with key ${key}`); }}>
                Alert
            </Button>
            <a href='#'>link</a>
            <CloseIcon onClick={() => snackbarClose(key)} className={classNames(classes.icon, classes.root)} />
        </Fragment>
    );
    const clickWhiteSnackbar = () => {
        enqueueSnackbar({
            type: 'white',
            delay: 0,
            message: 'White snackbar!aefnkwelnfwenfkewnfk',
            options: {
                key: new Date().getTime() + Math.random(),
                action,
            },
        });
    };
    return (
        <div>
            <button onClick={clickBlackSnackbar}>black</button>
            <button onClick={clickGreenSnackbar}>green</button>
            <button onClick={clickRedSnackbar}>red</button>
            <button onClick={clickWhiteSnackbar}>white</button>
        </div>
    );
};
