import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ReadingSource } from '../../../../../../common/model/meter/readingSource';
import { MeterSyncSchemeUpdater } from '../../../../../../common/model/meter/meter/meterSyncSchemeUpdater';
import { resourcesMeterActionSchemesSelector, resourcesTimeSynchronizationSchemesSelector } from '../../../../../../redux/selectors';
import { InputProps } from './model';
import { View } from './view';

export const MeterSchemeForm: React.FC<InputProps> = (props) => {

    const { meter } = props;
    const isImportCrq = meter.readingSource === ReadingSource.ImportCrq;

    const defaultValues: MeterSyncSchemeUpdater = {
        meterActionSchemeId: meter.meterActionSchemeId,
        timeSynchronizationSchemeId: !isImportCrq && meter.timeSynchronizationSchemeId,
    };

    const form = useForm<MeterSyncSchemeUpdater>({ defaultValues });

    const timeSynchronizationSchemes = useSelector(resourcesTimeSynchronizationSchemesSelector());
    const meterActionSchemes = useSelector(resourcesMeterActionSchemesSelector(isImportCrq));

    const availableMeterActionSchemes = useMemo(
        () => meterActionSchemes.filter(item => {
            return item.regionId === meter.regionId && (
                meter.readingSource === ReadingSource.ImportCrq
                    ? item.isCrq
                    : !item.isCrq
            );
        }),
        [meter, meterActionSchemes]
    );

    return View({
        ...props,
        form,
        meterActionSchemes: availableMeterActionSchemes,
        timeSynchronizationSchemes,
        isImportCrq,
    });
};
