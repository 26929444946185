import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

const baseSelector = (state: StorageState) => state.tariffSchedule;

export const tariffScheduleListSelector = createSelector(
    baseSelector,
    state => state.list.available,
);

export const tariffScheduleEditableSelector = createSelector(
    baseSelector,
    state => state.editable.tariffSchedule,
);

export const tariffScheduleVersionEditableSelector = createSelector(
    baseSelector,
    state => state.editable.tariffScheduleVersion,
);

export const tariffScheduleOrdersSelector = createSelector(
    baseSelector,
    state => state.orders,
);
