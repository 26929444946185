import { SelectSuggestion } from '../../../../../shared/components/select/model';

export const startDateSuggestions: SelectSuggestion[] = [
    {
        label: '1 января',
        value: '1:1',
    },
    {
        label: '1 февраля',
        value: '1:2',
    },
    {
        label: '1 марта',
        value: '1:3',
    },
    {
        label: '1 апреля',
        value: '1:4',
    },
    {
        label: '1 мая',
        value: '1:5',
    },
    {
        label: '1 июня',
        value: '1:6',
    },
    {
        label: '1 июля',
        value: '1:7',
    },
    {
        label: '1 августа',
        value: '1:8',
    },
    {
        label: '1 сентября',
        value: '1:9',
    },
    {
        label: '1 октября',
        value: '1:10',
    },
    {
        label: '1 ноября',
        value: '1:11',
    },
    {
        label: '1 декабря',
        value: '1:12',
    },
];
