import React, { useState } from 'react';
import { TariffDayCardInputProps } from './model';
import { View } from './view';

export const TariffDayCard: React.FC<TariffDayCardInputProps> = (props) => {

    const [
        isZonesOpen,
        setIsZonesOpen
    ] = useState(false);

    return View({
        ...props,
        isZonesOpen,
        onClickOpenZones: () => {
            setIsZonesOpen(!isZonesOpen);
        },
    });
};
