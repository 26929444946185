import { roundReadings } from '../../../../../common/shared/utils';
import { ElectricityConsumptionBillingReportData } from '../../../../../common/model/meter/reports/electricityConsumptionBillingReport';

export const transformElectricityConsumptionReportData =
    (source: ElectricityConsumptionBillingReportData): ElectricityConsumptionBillingReportData => {
        const data = { ...source, isChangeColor: false };
        if (data.cumulativeActiveEnergyTZ3Start) {
            data.cumulativeActiveEnergyTZ1Start += data.cumulativeActiveEnergyTZ2Start;
            data.cumulativeActiveEnergyTZ1End += data.cumulativeActiveEnergyTZ2End;
            data.cumulativeActiveEnergyTZ2Start = data.cumulativeActiveEnergyTZ3Start;
            data.cumulativeActiveEnergyTZ2End = data.cumulativeActiveEnergyTZ3End;
            data.cumulativeActiveEnergyTZ1Diff += data.cumulativeActiveEnergyTZ2Diff;
            data.cumulativeActiveEnergyTZ2Diff = data.cumulativeActiveEnergyTZ3Diff;
        }
        return {
            ...data,
            cumulativeActiveEnergyImpStart: +roundReadings(data.cumulativeActiveEnergyImpStart),
            cumulativeActiveEnergyImpEnd: +roundReadings(data.cumulativeActiveEnergyImpEnd),
            cumulativeActiveEnergyTZ1Start: +roundReadings(data.cumulativeActiveEnergyTZ1Start),
            cumulativeActiveEnergyTZ1End: +roundReadings(data.cumulativeActiveEnergyTZ1End),
            cumulativeActiveEnergyTZ2Start: +roundReadings(data.cumulativeActiveEnergyTZ2Start),
            cumulativeActiveEnergyTZ2End: +roundReadings(data.cumulativeActiveEnergyTZ2End),
            cumulativeActiveEnergyTZ3Start: +roundReadings(data.cumulativeActiveEnergyTZ3Start),
            cumulativeActiveEnergyTZ3End: +roundReadings(data.cumulativeActiveEnergyTZ3End),
            cumulativeActiveEnergyTZ1Diff: +roundReadings(data.cumulativeActiveEnergyTZ1Diff),
            cumulativeActiveEnergyTZ2Diff: +roundReadings(data.cumulativeActiveEnergyTZ2Diff),
            cumulativeActiveEnergyTZ3Diff: +roundReadings(data.cumulativeActiveEnergyTZ3Diff),
            cumulativeActiveEnergyTzSum: +roundReadings(data.cumulativeActiveEnergyTzSum),
        };
    };

export const transformAddressString = (data: ElectricityConsumptionBillingReportData): string => {
    let address = '';
    if (data.townName) {
        address += data.townName;
    }
    if (data.streetName) {
        address += ', ' + data.streetName;
    }
    if (data.houseNumber) {
        address += ', ' + data.houseNumber;
    }
    if (data.addressSpecifier) {
        address += ', ' + data.addressSpecifier;
    }
    return address;
};
