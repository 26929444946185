import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.text.secondary,
        ...theme.typography.body,
    },
    textHighlighted: {
        ...theme.typography.bodyBold,
        color: theme.palette.system.error,
    },
}));

interface Props {
    text: React.ReactNode;
    isHighlighted?: boolean;
}

export const LeftTableBaseColumn = ({ text, isHighlighted = false }: Props) => {
    const classes = useStyles();
    return (
        <div className={classNames(classes.text, { [classes.textHighlighted]: isHighlighted })}>
            {text}
        </div>
    );
};
