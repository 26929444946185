import React from 'react';
import { Grid } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../shared/components/button';
import { HookField } from '../../../../shared/components/hookField';
import { userStatuses } from '../../../../shared/constants/enums';
import { UserFormViewProps } from './model';


export const View = ({
    form,
    onSubmit,
    roles,
    user,
    handleClearRoles,
}: UserFormViewProps) => {

    const { handleSubmit } = form;

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <HookField
                            type='text'
                            name='name'
                            label='Имя'
                            fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <HookField
                            type='text'
                            name='lastName'
                            label='Фамилия'
                            fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <HookField
                            type='text'
                            name='patronymic'
                            label='Отчество'
                            fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <HookField
                            type='email'
                            name='email'
                            label='Email'
                            fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <HookField
                            type='text'
                            name='userName'
                            label='Имя пользователя'
                            fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <HookField
                            type='select'
                            name='status'
                            label='Статус'
                            suggestions={userStatuses}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <HookField
                            type='multiselect'
                            name='roleIds'
                            label='Роли'
                            handleClear={handleClearRoles}
                            suggestions={roles}
                            fullWidth />
                    </Grid>
                    <Grid xs={12} item>
                        <Button variant='primary' fullWidth type="submit">
                            {user ? 'Сохранить' : 'Создать'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
