import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../shared/components/button';
import { Wrapper } from '../../../shared/components/wrapper';
import { Table } from '../../../shared/components/table';
import { defaults, Tables } from '../../../shared/constants';
import { IStateProps } from './model';

const useStyles = makeStyles(() => ({
    tableWrapper: {
        '& .custom-table': {
            '& button': {
                visibility: 'hidden',
            },
            '& .MuiTableRow-root': {
                '&:hover': {
                    '& button': {
                        visibility: 'visible',
                    },
                }
            },
        },
    }
}));

export const View: React.FC<IStateProps> = (props) => {

    const {
        data,
        columns,
        hasNotCompleted,
        onClickNewMassOperation,
    } = props;

    const classes = useStyles();

    const actions = (<>
        <Button
            icon='add'
            type='button'
            variant='primary'
            onClick={onClickNewMassOperation}
            disabled={hasNotCompleted}
        >
            Новый опрос
        </Button>
    </>);

    return (
        <Box className={classes.tableWrapper}>
            <Wrapper
                isBigHeader
                title='ИСТОРИЯ МАССОВЫХ ОПРОСОВ'
                actions={actions}
            >
                <Table
                    name={Tables.massOperationsTable}
                    columns={columns}
                    data={data ?? []}
                    title={null}
                    variant='white'
                    useQueryString={true}
                    options={{
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        search: false,
                        sorting: true,
                        paging: 'disabled',
                        toolbar: false,
                        showTitle: false,
                    }}
                    fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable} />
            </Wrapper>
        </Box>
    );
};
