import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Pagination } from '../../../../shared/components/pagination';
import { setOrderTransformer } from '../../../../shared/components/pagination/helpers';
import { setColumnWidth } from '../../../../shared/pipes/styles';
import { IStateProps } from './model';
import { Table } from '../../../../shared/components/table';
import { VisualizationSource } from '../../../../shared/components/table/model';
import { defaults, Tables } from '../../../../shared/constants';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        '& .col-readingDate': {
            ...setColumnWidth('110px'),
        },
        '& .col-cumulativeActiveEnergyExp': {
            ...setColumnWidth('100px'),
        },
        '& .col-cumulativeActiveEnergyImp': {
            ...setColumnWidth('100px'),
        },
        '& .col-cumulativeActiveEnergyTZ1': {
            ...setColumnWidth('100px'),
        },
        '& .col-cumulativeActiveEnergyTZ2': {
            ...setColumnWidth('80px'),
        },
        '& .col-cumulativeActiveEnergyTZ3': {
            ...setColumnWidth('80px'),
        },
        '& .col-cumulativeReactiveEnergyExp': {
            ...setColumnWidth('80px'),
        },
        '& .col-cumulativeReactiveEnergyImp': {
            ...setColumnWidth('80px'),
        },
        '& .custom-table.white-table .MuiTableCell-head ': {
            ...theme.typography.caption,
        },
    },
}));

export const View = ({
    columnDefinition,
    data,
    dataTotal,
    setOrder,
    pagination,
    setPage,
    orderParams,
}: IStateProps) => {
    const classes = useStyles();
    return (
        <div className={classes.tableHeader}>
            <Table
                visualizationSource={VisualizationSource.clientProfile}
                name={Tables.meterDailyReadingTable}
                columns={columnDefinition}
                data={data ?? []}
                title=''
                variant='white'
                options={{
                    grouping: false,
                    columnsButton: false,
                    filtering: false,
                    search: false,
                    toolbar: false,
                    paging: 'disabled',
                }}
                fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                onOrderChange={setOrderTransformer(columnDefinition, setOrder)}
                {...orderParams}
            />
            <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={!data} />
        </div>
    );
};
