import { combineReducers } from 'redux';
import { activeUserAccessSlice, ActiveUserAccessState } from './active';
import { usersListAccessSlice, UsersListAccessState } from './list';

export interface UsersAccessState {
    active: ActiveUserAccessState;
    list: UsersListAccessState;
}

export const usersAccessSlice = combineReducers<UsersAccessState>({
    active: activeUserAccessSlice.reducer,
    list: usersListAccessSlice.reducer,
});
