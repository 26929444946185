import { Column } from '@softmedialab/materialui-table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoleViewModel } from '../../../../../common/model/access/role/roleViewModel';
import { BaseDispatch } from '../../../../../redux/actions';
import {
    createRole, deleteRole,
    getAccessRolesList,
    getActiveAccessRoleRules, getActiveAccessRules,
    resetActiveRoleState,
    setActiveAccessRole, updateActiveAccessRoleRules, updateRole
} from '../../../../../redux/actions/access';
import { accessActiveRoleRulesSelector, accessActiveRoleSelector, accessRolesListSelector } from '../../../../../redux/selectors';
import { getErrorMessage } from '../../../../shared/utils/error';
import { RoleFormModel } from './form/model';
import { View } from './view';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { Box } from '@material-ui/core';
import { DropdownMenu } from '../../../../shared/components/dropdownMenu';

export const AccessRolesPage = () => {
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [activeRole, setActiveRole] = useState<RoleViewModel>(null);
    const dispatch = useDispatch<BaseDispatch>();
    const roles = useSelector(accessRolesListSelector());
    const selectedRole = useSelector(accessActiveRoleSelector());
    const selectedRoleRules = useSelector(accessActiveRoleRulesSelector());

    const data: RoleViewModel[] = roles;

    const fetchRoles = () => {
        dispatch(getAccessRolesList());
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    const roleForm: RoleFormModel = selectedRole && selectedRoleRules ? {
        ...selectedRole,
        accessRules: selectedRoleRules,
    } : null;


    const onCloseForm = () => {
        setIsFormOpen(false);
    };

    const columns: Column[] = [
        {
            title: 'Название',
            field: 'name',
            sorting: true,
        },
        {
            title: 'Код',
            field: 'code',
            sorting: true,
        },
        {
            title: 'Действия',
            cellClassName: 'cell-action',
            sorting: false,
            render: (role) => {
                return (
                    <Box marginLeft='18px'>
                        <DropdownMenu
                            icon='moreVert'
                            dropdownPosition='bottomRight'
                            options={[
                                {
                                    label: 'Редактировать',
                                    icon: 'edit',
                                    onClick: async () => {
                                        setIsFormOpen(true);
                                        await dispatch(getActiveAccessRoleRules(role.id));
                                        dispatch(setActiveAccessRole(role));
                                    },
                                },
                                {
                                    label: 'Удалить',
                                    icon: 'delete',
                                    onClick: () => {
                                        setActiveRole(role);
                                        setIsDeleteModalVisible(true);
                                    },
                                }
                            ]} />
                    </Box>
                );
            },
        },
    ];

    const onCloseModal = () => {
        setIsDeleteModalVisible(false);
    };

    const onDelete = async (id: string) => {
        await dispatch(deleteRole(id));
        fetchRoles();
        setIsDeleteModalVisible(false);
    };

    return View({
        data,
        columns,
        roleForm,
        onDelete,
        isFormOpen,
        activeRole,
        onCloseForm,
        selectedRole,
        onCloseModal,
        isDeleteModalVisible,
        onSubmit: async (formData) => {
            try {
                const { accessRules, ...roleFormData } = formData;
                let roleId = null;
                if (selectedRole) {
                    // обновляем роль
                    await dispatch(updateRole(selectedRole.id, roleFormData));
                    roleId = selectedRole.id;
                } else {
                    // создаем роль
                    const createdRole = await dispatch(createRole(roleFormData));
                    roleId = createdRole.id;
                }
                await dispatch(updateActiveAccessRoleRules(roleId, accessRules));
                dispatch(resetActiveRoleState());
                setIsFormOpen(false);
                fetchRoles();
                dispatch(getActiveAccessRules());
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    message: getErrorMessage(e),
                }));
            }
        },
        onClickAddButton: () => {
            dispatch(resetActiveRoleState());
            setIsFormOpen(true);
        }
    });
};
