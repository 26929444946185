import { MeterGroupViewModel } from '../../../../../common/model/meter/meterGroup/meterGroupViewModel';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { getErrorMessage } from '../../../../shared/utils/error';
import { View } from './view';
import { getRoute } from '../../../../shared/pipes';
import { AdministrationTabs, Routes } from '../../../../shared/constants';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../../../redux/actions';
import { createMeterGroups } from '../../../../../redux/actions/meters';
import { createSnackbar } from '../../../../../redux/actions/controls';
import yup from '../../../../shared/utils/yup';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import { useForm } from 'react-hook-form';

const meterGroupValidationSchema = yup.object({
    regionId: yup.number().required('Регион обязательное поле'),
    name: yup.string().required('Название группы обязательное поле'),
    visibleToAll: yup.boolean().required(),
    isStatic: yup.boolean().required('Тип группы обязательное поле'),
});

export const CreateGroup = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const resolver = useYupValidationResolver<MeterGroupViewModel>(meterGroupValidationSchema);
    const form = useForm<MeterGroupViewModel>({
        defaultValues: {
            visibleToAll: false,
        },
        resolver
    });
    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.Schemes }) },
        { name: 'Управление группами ПУ', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.Groups }) },
        { name: 'Новая группа', link: null },
    ]);
    const onCancel = () => {
        dispatch(push(getRoute(Routes.administration, null, { tab: AdministrationTabs.Groups })));
    };
    return View({
        onClickBreadcrumb,
        breadcrumbs,
        onCancel,
        initialValues: {},
        onSubmitGroup: async (data) => {
            try {
                const group = await dispatch(createMeterGroups(data));
                dispatch(createSnackbar({
                    type: 'green',
                    delay: 5000,
                    message: 'Группа успешно создана'
                }));
                await dispatch(push(getRoute(Routes.meterGroupEdit, { groupId: group.id })));
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    delay: 5000,
                    message: getErrorMessage(e),
                }));
            }
        },
        form
    });
};
