/* eslint-disable max-lines */
import { Options } from '@softmedialab/materialui-table';
import _ from 'lodash';
import React, { useMemo } from 'react';
import {
    AccordionSummary,
    Box,
    Divider,
    Grid,
    makeStyles,
    Theme,
    Accordion,
    AccordionDetails, Typography
} from '@material-ui/core';
import { Modal } from 'app/shared/components/modal';
import { useSelector } from 'react-redux';
import {
    resourcesMeterActionSchemesSelector,
    resourcesTimeSynchronizationSchemesSelector
} from '../../../../redux/selectors';
import { VAutocompleteOption } from '../../../shared/components/autocomplete/model';
import { HookField } from '../../../shared/components/hookField';
import { Button } from '../../../shared/components/button';
import { FormProvider } from 'react-hook-form';
import { HookFieldTransformer } from '../../../shared/components/hookField/model';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { useWebDictionaryVAutocompleteOptions } from '../../../shared/hooks/useWebDictionary';
import { setColumnWidth } from '../../../shared/pipes/styles';
import { SearchFormProps } from './model';
import { AddressForm } from '../formAddress';
import { Heading } from '../../../shared/components/heading';
import { Table } from '../../../shared/components/table';
import { VisualizationSource } from '../../../shared/components/table/model';
import { defaults, Tables } from '../../../shared/constants';
import { ExpandedPanels } from '../../administration/groups/edit/static/model';
import { Icon } from '../../../shared/components/icon';
import classNames from 'classnames';
import { meterRelayStatuses } from '../../../shared/constants/enums';
import { DateFormats } from '../../../../common/constants/date';
import { formatDate, parseDate } from '../../../shared/utils/dates';
import { readingSourceSuggestions } from '../../../shared/utils/readingSourceSuggestions';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        marginTop: 24,
    },
    checkbox: {
        '& .MuiFormControlLabel-root': {
            display: 'flex',
            alignItems: 'start',
        },
        '& .MuiTypography-root': {
            margin: '8px',
        }
    },
    checkBoxGroup: {
        '& .MuiTypography-root': {
            margin: 0,
            fontSize: 14
        },
        padding: 0
    },
    wrapper: {
        '& .MuiTableRow-footer': {
            paddingTop: 20,
        },
    },
    count: {
        fontWeight: 'normal',
        paddingLeft: 10,
        color: theme.palette.text.hint,
    },
    resultText: {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.text.primary,
    },
    row: {
        '&:not(:hover) .meters-actions': {
            visibility: 'hidden',
        },
        '&.MuiTableRow-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiTableCell-head .MuiCheckbox-root': {
            '& .Mui-checked': {
                color: theme.palette.primary.light
            },
            color: theme.palette.neutral.light,
        },
        '& .MuiCheckbox-root': {
            color: theme.palette.neutral.light
        },
        '& .Mui-checked': {
            color: theme.palette.primary.light
        },
        '& .MuiTableRow-root.Mui-checked': {
            borderColor: 'red'
        }
    },
    tableHeader: {
        // this styles don't work
        //TODO: width for columns
        '&.cell-address': {
            ...setColumnWidth('250px'),
        },
        '&.cell-foreignId': {
            ...setColumnWidth('250px'),
        },
        '&.cell-connectionType': {
            ...setColumnWidth('250px'),
        },
        // --
        '&.MuiCheckbox-root': {
            color: theme.palette.neutral.light,
        },
        '& .Mui-checked': {
            color: theme.palette.primary.light
        },
        '&.col-meter-model': {
            ...setColumnWidth('100px'),
        },
        '&.col-number': {
            ...setColumnWidth('170px'),
        },
        '&.col-checkbox': {
            ...setColumnWidth('80px'),
        },
        '&.col-cellFixed': {
            display: 'flex',
            padding: '2px 0',
            justifyContent: 'flex-end',
        },
    },
    accordion: {
        background: 'transparent',
        boxShadow: 'none',
        '& .MuiAccordionSummary-expandIcon': {
            color: theme.palette.primary.light
        },
        '& .MuiAccordionSummary-root': {
            padding: 0,
        },
        '&$expanded': {
            margin: 'auto',
        },
        '&:before': {
            opacity: 1,
        },
        '&.MuiAccordion-root.Mui-expanded:before': {
            opacity: 1,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 47,
        }
    },
    accordionWrapper: {
        display: 'block',
    },
    accordionBlock: {
        marginTop: -16,
        '& .MuiAccordionDetails-root': {
            padding: 0
        },
        '& .MuiAccordion-root.Mui-expanded': {
            margin: 0,
        }
    },
    bold: {
        fontWeight: 600,
    },
    wrapCheckbox: {
        '& .MuiFormControlLabel-root': {
            alignItems: 'flex-start',
        },
        '& .MuiCheckbox-root': {
            paddingLeft: 9,
            paddingRight: 9
        },
        '& .MuiTypography-root': {
            marginTop: 9,
        },
        marginBottom: -27
    },
    buttonWrapGroup: {
        marginTop: 43
    },
}));

const six = 6;
const three = 3;
const twelve = 12;

export const View = (props: SearchFormProps) => {
    const {
        form,
        cityForm,
        onSubmit,
        meterStatuses,
        accountTypes,
        purposeTypes,
        connectionTypes,
        priceCategories,
        onCancel,
        legalTypes,
        onClickByAddress,
        isOpenModal,
        onCloseForm,
        onSubmitCity,
        onCancelCity,
        isShowResult,
        columnDefinition,
        data,
        tableActions,
        isShowSelectAllCheckbox,
        isSelection,
        tableRef,
        isTwoColumn,
        handleChange,
        expanded,
        isAccordion,
        countActiveFilters,
        onSelectionChange,
        tableProps,
        meterLabelTypeSuggestions,
    } = props;
    const tablePropsOptions: Options = tableProps?.options ?? {};

    const classes = useStyles();
    const { handleSubmit } = form;

    const { meterModels } = useWebDictionaryVAutocompleteOptions();
    const timeSynchronizationSchemes = useSelector(resourcesTimeSynchronizationSchemesSelector());
    const meterActionSchemes = useSelector(resourcesMeterActionSchemesSelector());

    const sizeXl = isTwoColumn ? six : three;
    const sizeXlAddress = isTwoColumn ? twelve : six;

    const timeSynchronizationSchemesSuggestion: SelectSuggestion[] = timeSynchronizationSchemes
        .map(item => ({ label: item.name, value: item.id }));

    const table = useMemo(() => {
        return <Table
            visualizationSource={VisualizationSource.clientProfile}
            name={Tables.metersTable}
            columns={columnDefinition}
            data={data || []}
            tableRef={tableRef}
            title={null}
            onSelectionChange={onSelectionChange}
            variant='white'
            options={{
                columnsButton: false,
                filtering: false,
                search: false,
                showTitle: false,
                headerClassName: classes.tableHeader,
                toolbar: false,
                showSelectAllCheckbox: isShowSelectAllCheckbox,
                selection: isSelection,
                rowClassName: classes.row,
            }}
            fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
        />;
    }, [data]);

    const meterModelValuesTransformer: HookFieldTransformer = {
        input: (v) => {
            return meterModels.filter(item => v.includes(item.value));
        },
        output: (v) => {
            return _.uniq(v.map((item: VAutocompleteOption) => item.value));
        },
    };

    const meterModelValuesField = (
        <HookField
            type='autocomplete'
            name='meterModelValues'
            label='Тип ПУ'
            fullWidth
            withCheckbox
            options={meterModels}
            transform={meterModelValuesTransformer}
        />
    );

    const meterSerialNumField = (
        <HookField
            type='text'
            name='meterSerialNum'
            label='Заводской номер'
            fullWidth />
    );

    const meterStatusField = (
        <HookField
            type='select'
            name='status'
            label='Состояние ПУ'
            fullWidth
            isClearable
            suggestions={meterStatuses} />
    );

    const meterRelayStatusField = (
        <HookField
            type='select'
            name='relayStatus'
            label='Состояние реле'
            fullWidth
            isClearable
            suggestions={meterRelayStatuses} />
    );

    const meterAccountTypeField = (
        <HookField
            type='select'
            name='accountType'
            label='Тип учета'
            fullWidth
            isClearable
            suggestions={accountTypes} />
    );

    const meterPurposeField = (
        <HookField
            type='select'
            name='meterPurpose'
            label='Назначение прибора'
            fullWidth
            isClearable
            suggestions={purposeTypes} />
    );

    const meterConnectionTypeField = (
        <HookField
            type='select'
            name='connectionType'
            label='Тип включения'
            fullWidth
            isClearable
            suggestions={connectionTypes} />
    );

    const meterLegalEntityTypeField = (
        <HookField
            type='select'
            name='legalEntityType'
            label='Правовая форма владельца'
            fullWidth
            isClearable
            suggestions={legalTypes} />
    );

    const meterPriceCategoryField = (
        <HookField
            type='select'
            name='priceCategory'
            label='Ценовая категория'
            fullWidth
            isClearable
            suggestions={priceCategories} />
    );

    const meterInstallationSiteField = (
        <HookField
            type='text'
            name='installationSite'
            label='место установки'
            fullWidth />
    );

    const meterIdField = (
        <HookField
            type='text'
            name='foreignId'
            label='УИД ПУ'
            fullWidth />
    );

    const meterCommunicatorSerialNumField = (
        <HookField
            type='text'
            name='communicatorSerialNum'
            label='Заводской номер коммуникатора'
            fullWidth />
    );

    const meterNextVerificationDateField = (
        <HookField
            type='datepicker'
            name='nextVerificationDate'
            label='Дата следующей поверки в UTC'
            transform={{
                input: (v) => {
                    return v
                        ? parseDate(v, DateFormats.dateFormat)
                        : '';
                },
                output: (v) => {
                    return v
                        ? formatDate(v, DateFormats.dateFormat)
                        : null;
                }
            }}
            fullWidth />
    );

    const meterInstallationDateField = (
        <HookField
            type='datepicker'
            name='installationDate'
            label='Дата ввода в эксплуатацию'
            transform={{
                input: (v) => {
                    return v
                        ? parseDate(v, DateFormats.dateFormat)
                        : '';
                },
                output: (v) => {
                    return v
                        ? formatDate(v, DateFormats.dateFormat)
                        : null;
                }
            }}
            fullWidth />
    );

    const meterTimeSynchronizationSchemeIdField = (
        <HookField
            name='timeSynchronizationSchemeId'
            type='select'
            label='Схема синхронизации времени'
            suggestions={timeSynchronizationSchemesSuggestion}
            isClearable
            fullWidth />
    );

    const meterAddressStringField = (
        <HookField
            type='text'
            name='addressString'
            label='Выбрать адрес'
            icon={<Icon name='arrowSmallRight' />}
            onClick={onClickByAddress}
            fullWidth />
    );

    const meterLabelTypes = (
        <HookField
            name='meterLabelTypes'
            type='multiselect'
            suggestions={meterLabelTypeSuggestions}
            label='Есть проблема'
            fullWidth />
    );

    const readingSourceField = (
        <HookField
            name='readingSource'
            fullWidth
            type='select'
            isClearable
            suggestions={readingSourceSuggestions}
            label='Источник показаний'
        />
    );

    const meterActionSchemesSuggestion: SelectSuggestion[] = meterActionSchemes
        .map(item => ({ label: item.name, value: item.id }));

    const meterActionSchemeField = (
        <HookField
            name='meterActionSchemeId'
            fullWidth
            type='select'
            isClearable
            suggestions={meterActionSchemesSuggestion}
            label='Схема опроса'
        />
    );

    const searchButton = (
        <Button variant='primary' type='submit' icon='search'>Найти</Button>
    );

    const clearButton = (
        <Button onClick={onCancel} variant='outline' type='button' icon='close'>Очистить</Button>
    );

    return (
        <>
            {
                isOpenModal &&
                <Modal close='Отмена' onHide={onCloseForm} width='600px'>
                    <Box display='flex' justifyContent='center' mb={2}>
                        <Heading heading='Выбор адреса' />
                    </Box>
                    <Box mt={2}>
                        <AddressForm cityForm={cityForm} onSubmit={onSubmitCity} onCancelCity={onCancelCity} />
                    </Box>
                </Modal>
            }
            {
                isAccordion
                    ?
                    <div className={classes.accordionBlock}>
                        <Accordion
                            className={classes.accordion}
                            expanded={expanded === ExpandedPanels.filterPanel}
                            onChange={handleChange(ExpandedPanels.filterPanel)}
                        >
                            <AccordionSummary
                                expandIcon={<Icon name='arrowBigDown' />}
                            >
                                <Typography className={classes.resultText}>Фильтры: ({countActiveFilters})</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormProvider {...form}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterModelValuesField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterSerialNumField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterStatusField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterRelayStatusField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterAccountTypeField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterPurposeField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterConnectionTypeField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterLegalEntityTypeField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterPriceCategoryField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterInstallationSiteField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterIdField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterCommunicatorSerialNumField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterNextVerificationDateField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXl} xl={sizeXl}>
                                                    {meterInstallationDateField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXlAddress} xl={sizeXlAddress}>
                                                    {meterAddressStringField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXlAddress} xl={sizeXlAddress}>
                                                    {meterTimeSynchronizationSchemeIdField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXlAddress} xl={sizeXlAddress}>
                                                    {meterLabelTypes}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXlAddress} xl={sizeXlAddress}>
                                                    {readingSourceField}
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={sizeXlAddress} xl={sizeXlAddress}>
                                                    {meterActionSchemeField}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classNames(classes.button, classes.buttonWrapGroup)}>
                                                <Grid item sm={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            {searchButton}
                                                        </Grid>
                                                        <Grid item>
                                                            {clearButton}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </form>
                                </FormProvider>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            className={classes.accordion}
                            expanded={expanded === ExpandedPanels.tablePanel}
                            onChange={handleChange(ExpandedPanels.tablePanel)}
                        >
                            <AccordionSummary
                                expandIcon={<Icon name='arrowBigDown' />}
                            >
                                {tableActions}
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionWrapper}>
                                {
                                    <Box>
                                        <Divider />
                                        <Box className={classes.wrapper}>
                                            {
                                                data?.length ? table :
                                                    <Box mt={2} display='flex' justifyContent='center'>
                                                        <Typography variant='caption'>Нет данных</Typography>
                                                    </Box>
                                            }
                                        </Box>
                                    </Box>
                                }
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    :
                    <div>
                        <FormProvider {...form}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterModelValuesField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterSerialNumField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterStatusField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterRelayStatusField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterAccountTypeField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterPurposeField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterConnectionTypeField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterLegalEntityTypeField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterPriceCategoryField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterInstallationSiteField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterIdField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterCommunicatorSerialNumField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterNextVerificationDateField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterInstallationDateField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXlAddress}>
                                            {meterAddressStringField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {readingSourceField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterTimeSynchronizationSchemeIdField}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterLabelTypes}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={sizeXl}>
                                            {meterActionSchemeField}
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.button}>
                                        <Grid item sm={12}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    {searchButton}
                                                </Grid>
                                                <Grid item>
                                                    {clearButton}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </FormProvider>
                        {
                            isShowResult && <Box mt={2}>
                                <Divider />
                                <Box mt={2} mb={2}>
                                    {tableActions}
                                </Box>
                                <Divider />
                                <Box className={classes.wrapper}>
                                    <Table
                                        visualizationSource={VisualizationSource.clientProfile}
                                        name={Tables.metersTable}
                                        columns={columnDefinition}
                                        data={data || []}
                                        tableRef={tableRef}
                                        title={null}
                                        variant='white'
                                        options={{
                                            columnsButton: false,
                                            filtering: false,
                                            search: false,
                                            showTitle: false,
                                            toolbar: false,
                                            showSelectAllCheckbox: isShowSelectAllCheckbox,
                                            selection: isSelection,
                                            rowClassName: classes.row,
                                            headerClassName: classes.tableHeader,
                                            ...tablePropsOptions,
                                        }}
                                        fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                                        {...(tableProps ?? {})}
                                    />
                                </Box>
                            </Box>
                        }
                    </div>
            }
        </>
    );
};
