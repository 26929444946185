import { DynamicMeterGroupAddressesCreator } from '../../../common/model/meter/meter/dynamicMeterGroupAddressesCreator';
import { DynamicMeterGroupAddressesUpdater } from '../../../common/model/meter/meter/dynamicMeterGroupAddressesUpdater';
import { DynamicMeterGroupAddressesViewModel } from '../../../common/model/meter/meter/dynamicMeterGroupAddressesViewModel';
import { AppThunk } from '../index';
import { getApiRoute, RequestParams } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { httpService } from '../../../app/shared/httpWrapper';
import { meterDetailsSlice } from '../../reducers/meters/details';
import { DynamicMeterGroupParamsViewModel } from '../../../../../common/model/meter/meter/dynamicMeterGroupParamsViewModel';
import { meterSchemesListSlice } from '../../reducers/meterSchemes/list';
import { MeterGroupViewModel } from '../../../common/model/meter/meterGroup/meterGroupViewModel';

export const getDynamicMeterGroupParams = (params: RequestParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_dynamic_meter_group_params, params);
    const data = await httpService.get<DynamicMeterGroupParamsViewModel>(route);
    dispatch(meterDetailsSlice.actions.setDynamicMeterGroupParams(data));
};

export const getDynamicMeterGroupAddresses = (params: RequestParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_dynamic_meter_group_addresses, params);
    const data = await httpService.get<DynamicMeterGroupAddressesViewModel[]>(route);
    dispatch(meterDetailsSlice.actions.setDynamicMeterGroupAddresses(data));
};

export const createDynamicMeterGroupAddresses = async (groupId: number, creator: DynamicMeterGroupAddressesCreator) => {
    const route = getApiRoute(appUrls.api_post_dynamic_meter_group_addresses, { groupId });
    await httpService.post<DynamicMeterGroupAddressesViewModel>(route, creator);
};

export const updateDynamicMeterGroupAddresses = async (
    groupId: number,
    dynamicMeterGroupAddressesId: number,
    updater: DynamicMeterGroupAddressesUpdater
) => {
    const route = getApiRoute(appUrls.api_put_dynamic_meter_group_addresses, { groupId, dynamicMeterGroupAddressesId });
    await httpService.put<DynamicMeterGroupAddressesViewModel>(route, updater);
};

export const deleteDynamicMeterGroupAddresses = async (
    groupId: number,
    dynamicMeterGroupAddressesId: number
) => {
    const route = getApiRoute(appUrls.api_delete_dynamic_meter_group_addresses, { groupId, dynamicMeterGroupAddressesId });
    await httpService.delete(route);
};

export const setDynamicMeterGroupParams =
    (params: RequestParams, payload: DynamicMeterGroupParamsViewModel): AppThunk => async (dispatch) => {
        const route = getApiRoute(appUrls.api_post_dynamic_meter_group_params, params);
        const data = await httpService.post<DynamicMeterGroupParamsViewModel>(route, payload);
        dispatch(meterDetailsSlice.actions.setDynamicMeterGroupParams(data));
    };

export const updateDynamicMeterGroupParams =
    (params: RequestParams, payload: DynamicMeterGroupParamsViewModel): AppThunk => async (dispatch) => {
        const route = getApiRoute(appUrls.api_put_dynamic_meter_group_params, params);
        const data = await httpService.put<DynamicMeterGroupParamsViewModel>(route, payload);
        dispatch(meterDetailsSlice.actions.setDynamicMeterGroupParams(data));
    };

export const deleteMeterGroup = (groupId: RequestParams): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const route = getApiRoute(appUrls.api_delete_meter_group, groupId);
    await httpService.delete<MeterGroupViewModel>(route);
    dispatch(meterSchemesListSlice.actions.setAvailableSchemes([
        ...state.meters.details.meterGroups.filter(group => group.id !== groupId),
    ]));
};
