/**
 * Возможные источники показаний для ПУ
 */
export enum ReadingSource {
    Reading = 'Reading',
    Import80020 = 'Import80020',
    ImportCrq = 'ImportCrq'
}

export const readingSourceDictionary: Record<ReadingSource, string> = {
    [ReadingSource.Reading]: 'Опрос',
    [ReadingSource.Import80020]: 'Импорт 80020\\80040',
    [ReadingSource.ImportCrq]: 'Импорт CRQ'
}