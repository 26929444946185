import { useMemo } from 'react';
import { DateFormats } from '../../../../../common/constants/date';
import { meterLegalTypeDictionary } from '../../../../../common/model/meter/legalEntityType';
import { TariffScheduleViewModel } from '../../../../../common/model/meter/tariffSchedule/tariffScheduleViewModel';
import { TariffScheduleVersionViewModel } from '../../../../../common/model/meter/tariffScheduleVersion/tariffScheduleVersionViewModel';
import noun, { tariffCountNouns } from '../../../../../common/shared/utils/noun';
import { SelectSuggestion } from '../../../../shared/components/select/model';
import { formatDate } from '../../../../shared/utils/dates';

export interface UseTariffScheduleNameProps {
    tariffSchedule: TariffScheduleViewModel;
    tariffScheduleVersion: TariffScheduleVersionViewModel;
    regions: SelectSuggestion[];
}

export const useTariffScheduleName = (props: UseTariffScheduleNameProps) => {
    const {
        tariffSchedule,
        tariffScheduleVersion,
        regions,
    } = props;
    return useMemo(() => {
        const dateFormatted =
            tariffScheduleVersion
                ? `с ${formatDate(tariffScheduleVersion.startDate, DateFormats.dateFormat)}`
                : null;

        const tariffScheduleRegion = regions.find(item => item.value === tariffSchedule.regionId);

        return [
            tariffScheduleRegion
                ? tariffScheduleRegion.label
                : null,
            `${tariffSchedule.tariffCount} ${noun(tariffSchedule.tariffCount, tariffCountNouns)}`,
            meterLegalTypeDictionary[tariffSchedule.legalEntityType].toLowerCase(),
            dateFormatted,
        ]
            .filter(item => !!item)
            .join(', ');
    }, [tariffSchedule, tariffScheduleVersion, regions]);
};
