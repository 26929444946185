import {
    CrashByRegionsReportData, CrashByRegionsReportDataType,
    CrashByRegionsReportResponse
} from '../../../../../common/model/meter/reports/crashByRegionsReport';
import { CrashByRegionsReportTableData } from './model';

export const getSummaryTableData = (key: string, value: string): CrashByRegionsReportTableData => {
    return {
        index: key,
        crashMetersCount: value,
        crashMetersPercent: '',
        regionName: '',
        meterPurpose: undefined,
        metersCount: '',
        type: CrashByRegionsReportDataType.Region
    };
};

export const transformCrashByRegionsReportResponseToTableDataSummary = (
    response: CrashByRegionsReportResponse,
    daysOffline: number,
): CrashByRegionsReportTableData[] => {
    if (!response) {
        return [];
    }
    const { crashMetersCount, crashMetersPercent, metersCount } = response;
    return [
        getSummaryTableData('Всего ПУ:', `${metersCount}`),
        getSummaryTableData(`ПУ без показаний более ${daysOffline} суток:`, `${crashMetersCount}`),
        getSummaryTableData('Процент ПУ без показаний от общего числа:', `${crashMetersPercent}`),
    ];
};

export const transformCrashByRegionsReportDataItemToTableData = (
    data: CrashByRegionsReportData[],
): CrashByRegionsReportTableData[] => {
    if (!data) {
        return [];
    }
    return data.map((item) => ({
        index: item.index?.toString(),
        regionName: item.regionName?.toString(),
        metersCount: item.metersCount?.toString(),
        crashMetersCount: item.crashMetersCount?.toString(),
        crashMetersPercent: item.crashMetersPercent?.toString(),
        meterPurpose: item.meterPurpose,
        type: item.type,
    }));
};

export const transformCrashByRegionsReportResponseToTableData = (
    response: CrashByRegionsReportResponse,
    daysOffline: number,
): CrashByRegionsReportTableData[] => {
    if (!response) {
        return [];
    }
    const { data } = response;
    return [
        ...transformCrashByRegionsReportDataItemToTableData(data),
        ...transformCrashByRegionsReportResponseToTableDataSummary(response, daysOffline),
    ];
};
