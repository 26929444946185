import React, { useEffect, useState, useMemo } from 'react';
import yup from 'app/shared/utils/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSyncQueryParams } from 'app/shared/hooks/useQueryParams';
import { BaseDispatch } from 'redux/actions';
import { crqSystemEditableSelector } from 'redux/selectors/crqSystem';
import { useYupValidationResolver } from 'app/shared/utils/validation';
import { getMeterSchemes } from 'redux/actions/meterSchemes';
import { createSnackbar } from 'redux/actions/controls';
import { getErrorMessage } from 'app/shared/utils/error';
import { SelectSuggestion } from 'app/shared/components/select/model';
import {
    getEditableCrqSystem,
    createCrqSystem,
    updateCrqSystem,
    getCrqSystemList,
    resetEditableCrqSystem
} from 'redux/actions/crqSystem';
import { meterSchemeWithCrqSelector } from 'redux/selectors/meterSchemes';
import { partnersDetailsSelector } from 'redux/selectors/partners';
import { CrqSystemParams, CrqSystemSettingAlertType } from '../crqSystemTable/model';
import { CrqSystemFormData, CrqSystemFormProps } from './model';
import { View } from './view';

const validationSchema = yup.object({
    name: yup.string().required('Название обязательное поле'),
    server: yup.string().required('Сервер обязательное поле'),
    maxTasksCount: yup.number().min(0).required('Количество потоков'),
    timeoutInSeconds: yup.number().min(0).required('Время отклика'),
});

const initialCrqSystem: CrqSystemFormData = {
    name: '',
    isHttps: false,
    server: '',
    port: null,
    login: '',
    password: '',
    maxTasksCount: null,
    timeoutInSeconds: null,
};

export const CrqSystemForm: React.FC<CrqSystemFormProps> = ({
    handleCloseModal,
    handleToggleCrqSystemSettingAlertTypeModal,
}) => {
    const { partnerId } = useParams<{ partnerId?: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch<BaseDispatch>();
    const crqSystem = useSelector(crqSystemEditableSelector());
    const schemes = useSelector(meterSchemeWithCrqSelector());
    const partnerDetails = useSelector(partnersDetailsSelector());
    const [crqSystemParams] = useSyncQueryParams<CrqSystemParams>({ crqSystemId: null });
    const resolver = useYupValidationResolver<CrqSystemFormData>(validationSchema);
    const defaultValues = crqSystem ?? { ...initialCrqSystem,
        name: partnerDetails ? `${partnerDetails.name} CRQ` : '',
        partnerId: Number(partnerId) };
    const form = useForm<CrqSystemFormData>({ defaultValues, resolver });

    const fetchCrqSystem = async () => {
        setIsLoading(true);
        try {
            await dispatch(getEditableCrqSystem(crqSystemParams.crqSystemId));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (crqSystemParams.crqSystemId) {
            fetchCrqSystem();
        }
    }, [crqSystemParams.crqSystemId]);

    useEffect(() => {
        form.reset(defaultValues);
    }, [crqSystem]);

    useEffect(() => {
        dispatch(getMeterSchemes());
    }, []);

    const onSubmit = async (formData: CrqSystemFormData) => {
        try {
            if (crqSystem) {
                await dispatch(updateCrqSystem(crqSystem.id, formData));
                handleToggleCrqSystemSettingAlertTypeModal(CrqSystemSettingAlertType.Edit);
            } else {
                await dispatch(createCrqSystem(formData));
                handleToggleCrqSystemSettingAlertTypeModal(CrqSystemSettingAlertType.Add);
            }

            handleCloseModal();
            await dispatch(getCrqSystemList(Number(partnerId)));
            dispatch(resetEditableCrqSystem());
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
    };

    const schemesSuggestions: SelectSuggestion[] = useMemo(() => {
        return schemes.map(item => ({
            label: item.name,
            value: item.id,
        }));
    }, [schemes]);

    return View({
        onSubmit,
        form,
        handleCloseModal,
        isLoading,
        schemesSuggestions
    });
};
