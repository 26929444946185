import { View } from './view';
import React, { useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { controlsLeftMenuSelector } from '../../../../redux/selectors';
import {
    closeAllTagsTreeLeftMenu,
    updateTreeDataLeftMenu,
    removeTreeItemFromTabList,
    setActiveTab, removeActiveTab,
} from '../../../../redux/actions/controls';
import { BaseDispatch } from '../../../../redux/actions';
import { TabElementProps } from './model';
import { listAvailabilityAfterItemsCount } from './constants';
import { getRoute } from '../../pipes';
import { MeterTabs, Routes } from '../../constants';
import { push } from 'connected-react-router';


export const TabElement: React.FC<TabElementProps> = ({ menuItem, className }) => {
    const [isDeleteButtonAvailable, setDeleteButtonAvailable] = useState<boolean>(false);
    const [tabListAnchor, setTabListAnchor] = useState(null);
    const isTabListOpen = Boolean(tabListAnchor);
    const { tabData, activeTab } = useSelector(controlsLeftMenuSelector());
    const dispatch = useDispatch<BaseDispatch>();


    const handleCloseTabList = () => {
        setTabListAnchor(null);
    };

    const handleClickItem = (id: string, type: string, event?: MouseEvent) => {
        if (type === 'select') {
            setTabListAnchor(null);
            dispatch(setActiveTab(tabData.find(el => el.id === id)));
            dispatch(closeAllTagsTreeLeftMenu());
            dispatch(updateTreeDataLeftMenu(id));

            const route = getRoute(
                Routes.meter,
                { meterId: id },
                { tab: MeterTabs.Information }
            );
            dispatch(push(route));
        }
        if (type === 'delete') {
            if (id === activeTab.id) {
                dispatch(removeActiveTab());
            }

            if (event) {
                event.stopPropagation();
            }
            dispatch(removeTreeItemFromTabList(id));
            dispatch(closeAllTagsTreeLeftMenu());
        }
    };

    const handleClickTabElem = (event: MouseEvent) => {
        setTabListAnchor(event.currentTarget);
    };

    const handleChangeAvailabilityDeleteButton = (newAvailability: boolean) => {
        if (tabData.length > listAvailabilityAfterItemsCount) {
            setDeleteButtonAvailable(false);
        } else {
            setDeleteButtonAvailable(newAvailability);
        }
    };

    return View({
        tabListAnchor,
        isTabListOpen,
        tabData,
        handleClickTabElem,
        handleClickItem,
        menuItem,
        isDeleteButtonAvailable,
        handleChangeAvailabilityDeleteButton,
        handleCloseTabList,
        activeTab,
        className
    });
};
