import { combineReducers } from 'redux';
import { commonEventLogSlice, CommonEventLogState } from './common';


export interface EventLogsState {
    common: CommonEventLogState;
}

export const eventLogs = combineReducers<EventLogsState>({
    common: commonEventLogSlice.reducer,
});
