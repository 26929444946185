export enum RelayStatus {
    Off = 0, // Отключено
    On = 1, // Включено
    Enabled = 2, // Разрешено включение
}

export const meterRelayStatusDictionary: Record<RelayStatus, string> = {
    [RelayStatus.Off]: 'Отключено',
    [RelayStatus.On]: 'Включено',
    [RelayStatus.Enabled]: 'Разрешено включение',
};
