import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        width: 22,
        height: 22,
        borderRadius: '50%',
        position: 'relative',
    },
    '@keyframes circleProgressRotate': {
        'from': {
            strokeDashoffset: '50.26548245743669',
        },
        'to': {
            strokeDashoffset: 0,
        }
    },
    '@keyframes circleRotate': {
        'from': {
            transform: 'rotate(-90deg)',
        },
        'to': {
            transform: 'rotate(270deg)',
        },
    },
    indicator: {
        width: 20,
        height: 20,
        zIndex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        transform: 'rotate(-90deg)',
        animationIterationCount: 'infinite',
        animationDuration: '2s',
        animationTimingFunction: 'ease-in-out',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    uploadingIndicator: {
        animationName: '$circleRotate',
    },
    progressValue: {
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        animationDuration: '2s',
        strokeDasharray: 50.26548245743669,
        stroke: theme.palette.primary.main,
    },
    progressValueUploading: {
        animationName: '$circleProgressRotate',
        strokeDashoffset: 0,
    },
}));

export const View = () => {
    const classes = useStyles();
    const config = {
        size: 8,
        twoRadius: 2,
        percent100: 100,
    };
    // for the future
    const strokeDashArray = (radius: number) => {
        return radius * Math.PI * config.twoRadius;
    };
    // for the future
    const strokeDashoffset = (value: number) => {
        const progress = value / config.percent100;
        return strokeDashArray(config.size) * (1 - progress);
    };
    return <div className={classNames(classes.root)}>
        <div className={classNames(classes.indicator, classes.uploadingIndicator)}>
            <svg width='16' height='16' viewBox='0 0 16 16'>
                <circle className={classNames(classes.progressValue, classes.progressValueUploading)} cx='8' cy='8' r='7' fill='none'
                    strokeDasharray={strokeDashArray(config.size)} strokeDashoffset={strokeDashoffset(0)} strokeWidth='2' />
            </svg>
        </div>
    </div>;
};
