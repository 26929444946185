import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfigurationHistoryReportResponse } from '../../../../common/model/meter/reports/configurationHistoryReport';

export interface ConfigurationHistoryReportState {
    available: ConfigurationHistoryReportResponse;
}

const initialState: ConfigurationHistoryReportState = {
    available: null,
};

export const configurationHistoryReportSlice = createSlice({
    name: '@@vv/reports/configurationHistoryReport',
    initialState,
    reducers: {
        setReport(state, action: PayloadAction<ConfigurationHistoryReportResponse>): void {
            state.available = action.payload;
        },
    },
});
