import React, { useState } from 'react';
import { Theme } from '@material-ui/core/styles';
import { Box, makeStyles, MenuItem, useTheme } from '@material-ui/core';
import { SearchIcon } from '../../../../assets/icons';
import classNames from 'classnames';
import { useOutsideClick } from '../../hooks';
import { Typography } from '../typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SearchProps } from './model';
import { Icon } from '../icon';


const useStyles = makeStyles((theme: Theme) => ({
    main: {
        width: '100%',
        position: 'relative',
    },
    wrap: {
        width: '100%',
        height: '40px',
        borderRadius: '4px',
        border: '1px solid',
        display: 'flex',
        alignItems: 'center',
        borderColor: theme.palette.neutral.lightGray,
        fill: theme.palette.text.hint,
        '&:hover': {
            borderColor: theme.palette.neutral.dark,
            '&.MuiMenuItem-root': {
                backgroundColor: theme.palette.neutral.background,
            }
        },
        '&:focus-within': {
            borderColor: theme.palette.primary.light,
        },
        '&:visited': {
            fill: theme.palette.neutral.lightGray,
        },
    },
    errorBorder: {
        borderColor: theme.palette.system.error,
    },
    errorText: {
        color: theme.palette.system.error,
        fontSize: 13,
        fontWeight: 16
    },
    itemWrap: {
        position: 'absolute',
        zIndex: 10,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',
        backgroundColor: theme.palette.neutral.white,
        maxHeight: '185px',
        overflowY: 'auto',
        '& .MuiMenuItem-root': {
            paddingLeft: '13px 0',
        },
        '& .MuiMenuItem-root:hover': {
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '100px',
            backgroundColor: theme.palette.neutral.lightGray,
        },
    },
    fullWidth: {
        width: '100%',
    },

    item: {
        fontSize: '14px',
        color: theme.palette.text.secondary,
    },
    input: {
        color: theme.palette.text.primary,
        fontSize: '14px',
        marginLeft: '8px',
        marginRight: '12px',
        width: '100%',
        height: '18px',
        background: 'none',
        backgroundColor: 'none',
        border: 'none',
        outline: 'none',
        '$input:focus::-webkit-input-placeholder': {
            color: 'transparent',
        },
    },
    icon: {
        marginLeft: '9px',
        width: '22px',
        height: '19px',
    },
    dark: {
        fill: theme.palette.neutral.lightGray,
        color: theme.palette.neutral.white,
        '&:hover': {
            '& svg': {
                fill: theme.palette.neutral.white,
            },
            borderColor: theme.palette.neutral.white,
            '&.MuiMenuItem-root': {
                backgroundColor: theme.palette.neutral.main,
            },
            '&.MuiMenuItem-root:hover': {
                backgroundColor: theme.palette.neutral.dark,
            },
            '&.MuiMenuItem-root:active': {
                backgroundColor: theme.palette.text.secondary,
            }
        },
        '&:focus-within': {
            borderColor: theme.palette.primary.light,
            '& svg': {
                fill: theme.palette.primary.light,
            },
        },
        '&.MuiBox-root': {
            backgroundColor: theme.palette.neutral.main,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '100px',
            backgroundColor: theme.palette.neutral.gray,
        },
    },
    actionIcon: {
        width: '20px',
        height: '20px',
    }
}));

const escKeyNumber = 27;
const enterKeyNumber = 13;

export const View = (props: SearchProps) => {
    const {
        items,
        errorText,
        onChangeSelected,
        onChange,
        value,
        dark,
        loading,
        onSearchStart,
        onEscPressed,
        isSearchIconAvailable = true,
        placeholder = 'Поиск',
        onChangeShowHints,
        fullWidth = false,
        hasActions = false,
        required,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [isFocused, setIsFocused] = useState(false);

    const showHints = isFocused;

    const onClickSearch = () => {
        if (onSearchStart) {
            onSearchStart(value);
        }
    };

    const hideHints = () => {
        setIsFocused(true);
    };

    const displayHints = () => {
        setIsFocused(false);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (onSearchStart && event.keyCode === enterKeyNumber) {
            onSearchStart(value);
            hideHints();
        }
        if (onEscPressed && event.keyCode === escKeyNumber) {
            onEscPressed();
        }
    };

    const ref = useOutsideClick(() => {
        setIsFocused(false);
    });

    return (
        <div ref={ref}>
            <div className={classes.main}>
                <div className={classNames(classes.wrap, { [classes.dark]: dark, [classes.errorBorder]: !!errorText })}>
                    {
                        loading
                            ? <CircularProgress size={22} className={classes.icon} />
                            : isSearchIconAvailable && <SearchIcon className={classes.icon} onClick={onClickSearch} />
                    }
                    <input
                        placeholder={placeholder}
                        className={classNames(classes.input, { [classes.dark]: dark })} type="text"
                        onKeyDown={onKeyDown}
                        onChange={(event) => onChange(event.target.value)}
                        onFocus={() => hideHints()}
                        value={value}
                        required={required}
                    />
                    { hasActions && (
                        <Box display='flex' flexDirection='row' marginRight='6px'>
                            { value?.length > 0 && (
                                <Box width='20px'>
                                    <Icon
                                        name='close'
                                        fillColor={theme.palette.primary.light}
                                        className={classes.actionIcon}
                                        onClick={() => onChange('')}
                                    />
                                </Box>
                            )
                            }
                            <Box width='20px'>
                                <Icon
                                    name={showHints ? 'arrowSmallUp' : 'arrowSmallDown'}
                                    fillColor={theme.palette.primary.light}
                                    onClick={() => onChangeShowHints(!showHints)}
                                    className={classes.actionIcon}
                                />
                            </Box>
                        </Box>
                    )}
                </div>
                {
                    errorText ? <span className={classes.errorText}>{errorText}</span> : null
                }
                {
                    showHints
                        ?
                        <Box mt={1} className={classNames(classes.itemWrap, { [classes.dark]: dark, [classes.fullWidth]: fullWidth })}>
                            {
                                items.map((item, idx) => (
                                    <MenuItem
                                        disabled={!item.isSelectable}
                                        onClick={() => {
                                            displayHints();
                                            onChangeSelected(item);
                                        }}
                                        className={classNames({ [classes.dark]: dark })}
                                        key={`${item.value}-${idx}`}
                                        value={item.label}
                                    >
                                        <div className={classNames(classes.item, { [classes.dark]: dark })}>
                                            <Typography variant='input'>{item.label}</Typography>
                                        </div>
                                    </MenuItem>
                                ))
                            }
                        </Box>
                        : null
                }
            </div>
        </div>
    );
};
