import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MeterGroupQueryParams } from '../../../../../common/model/meter/meter-group/queryParams';
import { BaseDispatch } from '../../../../../redux/actions';
import { getMeterGroupList, resetMeterGroupList } from '../../../../../redux/actions/meterGroup';
import { meterGroupListAvailableSelector } from '../../../../../redux/selectors/meterGroup';
import { AutocompleteOptionsHookReturn, VAutocompleteOption } from '../model';

export const useMeterGroupAutocomplete = (queryParams: MeterGroupQueryParams): AutocompleteOptionsHookReturn => {

    const meterGroupSuggestions = useSelector(meterGroupListAvailableSelector());

    const dispatch = useDispatch<BaseDispatch>();

    const meterGroupsSuggestions: VAutocompleteOption[] = meterGroupSuggestions.map(item => ({
        label: item.name,
        optionsLabel: item.name,
        value: item.id,
    }));

    const onSearchMeterGroup = async (val: string) => {
        await dispatch(getMeterGroupList({
            name: val,
            ...queryParams,
        }));
    };

    useEffect(() => {
        dispatch(getMeterGroupList({
            ...queryParams,
        }));
        return () => {
            dispatch(resetMeterGroupList());
        };
    }, [
        queryParams?.ids,
        queryParams?.name,
        queryParams?.regionId,
        queryParams?.userId,
        queryParams?.visibleToAll,
        queryParams?.isStatic,
    ]);

    return {
        loading: false,
        onInputChange: (event, value) => onSearchMeterGroup(value),
        options: meterGroupsSuggestions,
    };
};
