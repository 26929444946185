import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MassOperationCheckResponse } from '../../../common/model/meter/massOperation/massOperationCheckResponse';
import { MassOperationCreator } from '../../../common/model/meter/massOperation/massOperationCreator';
import { MassOperationListQueryParams } from '../../../common/model/meter/massOperation/massOperationListQueryParams';
import { MassOperationShortViewModel } from '../../../common/model/meter/massOperation/massOperationShortViewModel';
import { MassOperationViewModel } from '../../../common/model/meter/massOperation/massOperationViewModel';
import { massOperationsDetailsSlice } from '../../reducers/massOperations/details';
import { massOperationsListSlice } from '../../reducers/massOperations/list';
import { AppThunk } from '../index';

export const getMassOperations = (params: MassOperationListQueryParams): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_mass_operations, {}, { ...params });
    const data = await httpService.get<MassOperationShortViewModel[]>(route);
    dispatch(massOperationsListSlice.actions.setAvailable(data));
};

export const getMassOperationsCheck = (): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_check_mass_operations);
    const data = await httpService.get<MassOperationCheckResponse>(route);
    dispatch(massOperationsListSlice.actions.setHasNotCompleted(data));
};

export const setMassOperation = (payload: MassOperationViewModel): AppThunk => (dispatch) => {
    dispatch(massOperationsDetailsSlice.actions.setAvailable(payload));
};

export const getMassOperation = (id: number): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_mass_operation, { id });
    const data = await httpService.get<MassOperationViewModel>(route);
    dispatch(setMassOperation(data));
};

export const checkMassOperation = async (payload: MassOperationCreator): Promise<MassOperationViewModel> => {
    const route = getApiRoute(appUrls.api_post_mass_operation);
    const data = await httpService.post<MassOperationViewModel>(route, payload);
    return data;
};

export const startMassOperation = async (payload: MassOperationCreator): Promise<MassOperationViewModel> => {
    const route = getApiRoute(appUrls.api_post_mass_operation, {}, { start: true });
    const data = await httpService.post<MassOperationViewModel>(route, payload);
    return data;
};
