import { View } from './view';
import { getHistoryMeterGisTask } from '../../../../../redux/actions/meterGisTasks';
import { useDispatch, useSelector } from 'react-redux';
import { historyGisTasks } from '../../../../../redux/selectors/meterGisTasks';
import { BaseDispatch } from '../../../../../redux/actions';
import { useEffect } from 'react';
import { TaskInfoCardProps } from './model';

export const TaskInfoCard = (props: TaskInfoCardProps) => {
    const historyGisTasksData = useSelector(historyGisTasks);
    const dispatch = useDispatch<BaseDispatch>();

    useEffect(() => {
        if (!(props.task.id in historyGisTasksData)) {
            dispatch(getHistoryMeterGisTask({ taskNumber: props.task.number }));
        }
    }, []);

    return View({
        historyMeterGisTask: historyGisTasksData,
        ...props,
    });
};
