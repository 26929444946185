import { useEffect, useState } from 'react';
import { StateProps } from './model';
import { View } from './view';

const showLoaderAfterMs = 300;

export const Loader = ({ visible, size }: StateProps, startShowLoaderDelayMs: number = showLoaderAfterMs) => {

    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (!visible) {
            setShowLoader(false);
            return null;
        }

        const timeout = setTimeout(() => {
            setShowLoader(true);
        }, startShowLoaderDelayMs);

        return () => clearTimeout(timeout);
    }, [visible]);

    if (!showLoader) {
        return null;
    }

    return View({ size });
};
