import { makeStyles } from '@material-ui/core';
import { Column } from '@softmedialab/materialui-table';
import { keyBy } from 'lodash';
import React from 'react';
import {
    PasswordRequirementType
} from '../../../../../../common/model/meter/passwordRequirements/passwordRequirementType';
import { PasswordRequirementsTableItem, Props } from './model';
import { Table } from '../../../../../shared/components/table';

const yesNoOrEmpty = (val: boolean | undefined): string => {
    switch (val) {
        case val === true: {
            return 'Да';
        }
        case val === false: {
            return 'Нет';
        }
        default: {
            return '—';
        }
    }
};

export const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        '& .MuiPaper-root': {
            boxShadow: 'none'
        },
    },
    header: {
        ...theme.typography.h2,
        color: theme.palette.text.primary,
        marginBottom: 8,
    },
    tableCols: {
        '&.col-name': {
            width: '204px',
        },
        '&.col-adminPassword': {
            width: '135px',
        },
        '&.col-userPassword': {
            width: '135px',
        },
    },
}));

export const View: React.FC<Props> = (props) => {
    const { passwordRequirements } = props;

    const classes = useStyles();

    const columns: Column[] = [
        {
            title: 'Название',
            field: 'name',
            render: (data: PasswordRequirementsTableItem) => {
                if (data.isBold) {
                    return <strong>{data.name}</strong>;
                }
                return <span>{data.name}</span>;
            },
            cellClassName: 'col-name',
        },
        {
            title: 'Административный пароль',
            field: 'adminPassword',
            cellClassName: 'col-adminPassword',
        },
        {
            title: 'Пользовательский пароль',
            field: 'userPassword',
            cellClassName: 'col-userPassword',
        },
    ];

    const byPasswordType = keyBy(passwordRequirements, 'passwordType');
    const adminPasswordRequirements = byPasswordType[PasswordRequirementType.AdminPassword];
    const userPasswordRequirements = byPasswordType[PasswordRequirementType.UserPassword];

    const data: PasswordRequirementsTableItem[] = [
        {
            isBold: false,
            name: 'Длина пароля',
            adminPassword: adminPasswordRequirements?.length ?? '—',
            userPassword: userPasswordRequirements?.length ?? '—'
        },
        {
            isBold: true,
            name: 'Разрешенные символы',
            adminPassword: '',
            userPassword: ''
        },
        {
            isBold: false,
            name: 'Цифры 0-9',
            adminPassword: yesNoOrEmpty(adminPasswordRequirements?.allowNumbers),
            userPassword: yesNoOrEmpty(userPasswordRequirements?.allowNumbers)
        },
        {
            isBold: false,
            name: 'Латинские буквы A-Z,a-z',
            adminPassword: yesNoOrEmpty(adminPasswordRequirements?.allowLatinLetters),
            userPassword: yesNoOrEmpty(userPasswordRequirements?.allowLatinLetters)
        },
        {
            isBold: false,
            name: 'Специальные символы',
            adminPassword: yesNoOrEmpty(adminPasswordRequirements?.allowSpecialSymbols),
            userPassword: yesNoOrEmpty(userPasswordRequirements?.allowSpecialSymbols)
        },
    ];

    return (
        <div className={classes.tableWrapper}>
            <h4 className={classes.header}>Требования к паролям</h4>
            <Table
                data={data}
                title={null}
                columns={columns}
                options={{
                    headerClassName: classes.tableCols,
                    paging: 'disabled',
                    search: false,
                    toolbar: false,
                    grouping: false,
                    columnsButton: false,
                    filtering: false,
                    sorting: false,
                    showTitle: false,
                }}
            />
        </div>
    );
};
