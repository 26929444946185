import React from 'react';
import { Checkbox } from 'app/shared/components/checkbox';
import { Box } from '@material-ui/core';
import { DropdownMenu } from 'app/shared/components/dropdownMenu';
import { PasswordInputText } from 'app/shared/components/passwordInputText';
import { CrqSystemViewModel } from 'common/model/meter/crq/crqSystemViewModel';

export const visibleHttps = (crqSystem: CrqSystemViewModel) => {
    return <Checkbox color='#A8A8A8' disabled checked={crqSystem.isHttps} />;
};

export const renderPassword = (crqSystem: CrqSystemViewModel) => {
    if (!crqSystem.password) {
        return null;
    }
    return <div className="crq__col-password"><PasswordInputText password={crqSystem.password} /></div>;
};

export const actionsButtons = (handleEdit: ()=> void, handleRemove: ()=> void) => {
    return (

        <Box marginLeft='18px'>
            <DropdownMenu
                icon='moreVert'
                dropdownPosition='bottomRight'
                options={[
                    {
                        label: 'Редактировать',
                        icon: 'edit',
                        onClick: handleEdit,
                    },
                    {
                        label: 'Удалить',
                        icon: 'delete',
                        onClick: handleRemove,
                    }
                ]} />
        </Box>
    );
};
