import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../../shared/components/button';
import { Modal } from '../../../../shared/components/modal';
import { Table } from '../../../../shared/components/table';
import { RoleForm } from './form';
import { IStateProps } from './model';
import { Wrapper } from '../../../../shared/components/wrapper';
import { ActionModal } from 'app/shared/components/actionModal';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        display: 'flex',
        minHeight: '880px',
        '& .MuiCheckbox-root.Mui-checked': {
            color: theme.palette.neutral.light,
        },
    },
    modal: {
        '& .modal-content .modal-body': {
            overflow: 'hidden !important',
            display: 'flex'
        }
    }
}));

export const View = (props: IStateProps) => {
    const {
        data,
        columns,
        selectedRole,
        isFormOpen,
        onCloseForm,
        onClickAddButton,
        onSubmit,
        roleForm,
        isDeleteModalVisible,
        onCloseModal,
        onDelete,
        activeRole,
    } = props;
    const modalHeader = selectedRole
        ? `Редактирование роли: ${selectedRole.name}`
        : 'Добавление роли';

    const classes = useStyles();

    const tableActionsRender = (
        <Grid container justify="flex-end" spacing={2}>
            <Grid item>
                <Button onClick={onClickAddButton} variant='primary'>Добавить роль</Button>
            </Grid>
        </Grid>
    );

    return (
        <div>
            <Wrapper actions={tableActionsRender} className={classes.tableWrapper} isBigHeader>
                {data && (
                    <Table
                        title={null}
                        data={data}
                        columns={columns}
                        variant='white'
                        useQueryString={true}
                        options={{
                            paging: 'classic',
                            grouping: false,
                            filtering: false,
                            search: false,
                            toolbar: false,
                            showTitle: false,
                        }}
                    />
                )}
            </Wrapper>
            {isFormOpen && (
                <Modal size="lg" title={modalHeader} close='Отмена' onHide={onCloseForm} className={classes.modal}>
                    <RoleForm onSubmit={onSubmit} role={roleForm} />
                </Modal>
            )}
            {isDeleteModalVisible && (
                <ActionModal
                    title='Удаление роли пользователя'
                    description={`Вы действительно хотите удалить роль ${activeRole.name}`}
                    closeModal={onCloseModal}
                    actions={[
                        {
                            onClick: () => onDelete(activeRole.id),
                            title: 'Да, удалить',
                            variant: 'primary',
                        },
                        {
                            onClick: () => onCloseModal(),
                            title: 'Отмена',
                            variant: 'secondary',
                        }
                    ]}
                />
            )}
        </div>
    );
};
