import React, { useMemo, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
    TimeSynchronizationSchemeUpdater
} from '../../../../../common/model/meter/timeSynchronizationScheme/timeSynchronizationSchemeUpdater';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import {
    createTimeSynchronizationScheme,
    deleteTimeSynchronizationScheme,
    updateTimeSynchronizationScheme
} from '../../../../../redux/actions/timeSynchronizationSchemes';
import { AdministrationTabs, Routes } from '../../../../shared/constants';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { getRoute } from '../../../../shared/pipes';
import { InputProps } from './model';
import { View } from './view';
import { push } from 'connected-react-router';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import yup from '../../../../shared/utils/yup';

const defaultScheme: TimeSynchronizationSchemeUpdater = {
    id: null,
    name: '',
    minTimeError: 5,
    maxTimeError: 180,
    timeShift: 5,
    flagSetTime: false,
};

const timeShiftValidationSchema = yup.object({
    minTimeError: yup.number().positive('Должно быть положительное число').required(),
    maxTimeError: yup.number().positive('Должно быть положительное число').required(),
    timeShift: yup.number().positive('Должно быть положительное число').required(),
});

const schemeSyncRoute = getRoute(Routes.administration, {}, { tab: AdministrationTabs.TimeSynchronizationSchemes });

export const TimeSynchronizationSchemeForm: React.FC<InputProps> = (props) => {
    const dispatch = useDispatch<BaseDispatch>();
    const { scheme } = props;

    const schemeSource = scheme ?? defaultScheme;

    const isNewScheme = !scheme;

    const schemeName = isNewScheme
        ? 'Новая схема'
        : scheme?.name;

    const defaultValues: TimeSynchronizationSchemeUpdater = useMemo(() => ({
        id: schemeSource.id,
        name: schemeSource.name,
        minTimeError: schemeSource.minTimeError,
        maxTimeError: schemeSource.maxTimeError,
        timeShift: schemeSource.timeShift,
        flagSetTime: schemeSource.flagSetTime,
    }), [schemeSource]);

    const resolver = useYupValidationResolver<TimeSynchronizationSchemeUpdater>(timeShiftValidationSchema);


    const form = useForm<TimeSynchronizationSchemeUpdater>({
        defaultValues,
        resolver,
    });

    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration) },
        { name: 'Схемы синхронизации', link: schemeSyncRoute },
        {
            name: schemeName,
            link: isNewScheme
                ? null
                : getRoute(Routes.timeSynchronizationSchemeDetails, { schemeId: scheme?.id?.toString() })
        },
    ]);

    const onSubmit: SubmitHandler<TimeSynchronizationSchemeUpdater> = async (formData) => {
        setIsLoading(true);
        try {
            if (isNewScheme) {
                await createTimeSynchronizationScheme({ ...formData });
            } else {
                await updateTimeSynchronizationScheme(scheme.id, { ...formData });
            }
            dispatch(push(schemeSyncRoute));
            setIsLoading(false);
        } catch (e) {
            dispatch(
                createSnackbar(
                    {
                        type: 'red',
                        message: 'Произошла ошибка при обновлении данных',
                    }
                )
            );
            setIsLoading(false);
        }
    };

    const onClickDelete = () => {
        setIsDeleting(true);
    };

    const onClickClear = () => {
        form.reset({
            ...defaultValues
        });
    };

    const onCancelDelete = () => {
        setIsDeleting(false);
    };

    const onConfirmDelete = async () => {
        await deleteTimeSynchronizationScheme(scheme.id);
        dispatch(push(schemeSyncRoute));
    };

    return View({
        ...props,
        ...breadcrumbProps,
        form,
        scheme,
        onSubmit,
        isLoading,
        isDeleting,
        schemeName,
        isNewScheme,
        onClickClear,
        onClickDelete,
        onCancelDelete,
        onConfirmDelete,
    });
};
