import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { Button } from '../../../../../../shared/components/button';
import { HookField } from '../../../../../../shared/components/hookField';
import {
    accountTypes,
    legalTypes,
    meterRelayStatuses,
    meterStatuses,
    priceCategories,
    purposeTypes,
} from '../../../../../../shared/constants/enums';
import { useWebDictionarySelectSuggestions } from '../../../../../../shared/hooks/useWebDictionary';
import { AddressPicker } from './addressPicker';
import { DynamicMeterFormProps } from './model';
import { readingSourceSuggestions } from '../../../../../../shared/utils/readingSourceSuggestions';
import { httpService } from 'app/shared/httpWrapper';
import { MeterActionSchemeViewModel } from 'common/model/meter/meter-action-scheme/meterActionSchemeViewModel';
import { appUrls } from 'common/appUrls';
import { getApiRoute } from 'app/shared/pipes/routing';

export const View = (props: DynamicMeterFormProps) => {
    const { form, onSubmit, regionId } = props;
    const { meterModels } = useWebDictionarySelectSuggestions();

    const { handleSubmit, control } = form;

    const {
        fields: addressesFields,
        append: appendAddress,
        remove: removeAddress,
    } = useFieldArray({
        control,
        name: 'addresses',
    });

    const [requestSchema, setRequestSchema] = useState([]);

    useEffect(() => {
        const route = getApiRoute(appUrls.api_get_meter_action_schemes);
        httpService.get<MeterActionSchemeViewModel[]>(route).then((response) => {
            if (!response || !response.length) {
                return;
            }

            const columnData = [{ value: '', label: 'Все' }];

            response?.forEach((record) => {
                columnData.push({ value: String(record.id), label: record.name });
            });

            setRequestSchema(columnData as []);
        });
    //dispatch(meterSchemesListSlice.actions.setAvailableSchemes(data));
    }, []);

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xl={6} md={6} sm={6} xs={12} lg={6}>
                        <HookField
                            type="select"
                            name="params.meterModelId"
                            label="Тип ПУ"
                            isClearable
                            fullWidth
                            suggestions={meterModels}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} sm={6} xs={12} lg={6}>
                        <HookField
                            type="select"
                            name="params.status"
                            label="Состояние ПУ"
                            isClearable
                            fullWidth
                            suggestions={meterStatuses}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} sm={6} xs={12} lg={6}>
                        <HookField
                            type="select"
                            name="params.relayStatus"
                            label="Состояние реле"
                            isClearable
                            fullWidth
                            suggestions={meterRelayStatuses}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} sm={6} xs={12} lg={6}>
                        <HookField
                            type="select"
                            name="params.accountType"
                            label="Тип учета"
                            isClearable
                            fullWidth
                            suggestions={accountTypes}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} sm={6} xs={12} lg={6}>
                        <HookField
                            type="select"
                            name="params.meterPurpose"
                            label="Назначение прибора"
                            isClearable
                            fullWidth
                            suggestions={purposeTypes}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} sm={6} xs={12} lg={6}>
                        <HookField
                            type="select"
                            name="params.legalEntityType"
                            isClearable
                            label="Правовая форма владельца"
                            fullWidth
                            suggestions={legalTypes}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} sm={6} xs={12} lg={6}>
                        <HookField
                            type="select"
                            name="params.priceCategory"
                            isClearable
                            label="Ценовая категория"
                            fullWidth
                            suggestions={priceCategories}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} sm={6} xs={12} lg={6}>
                        <HookField
                            name="params.readingSource"
                            fullWidth
                            type="select"
                            isClearable
                            suggestions={readingSourceSuggestions}
                            label="Источник показаний"
                        />
                    </Grid>
                    <Grid item xl={6} md={6} sm={6} xs={12} lg={6}>
                        <HookField
                            name="params.meterActionSchemeId"
                            fullWidth
                            type="select"
                            isClearable
                            suggestions={requestSchema}
                            label="Схема опроса"
                        />
                    </Grid>
                    {addressesFields.map((field, index) => (
                        <Grid item xs={12} sm={12} lg={12} xl={12} key={field.id}>
                            <AddressPicker
                                index={index}
                                name={`addresses.${index}`}
                                onClickRemove={() => {
                                    removeAddress(index);
                                }}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Button
                            onClick={() => {
                                appendAddress({
                                    regionId,
                                    meterGroupId: 0,
                                    areaName: '',
                                    townName: '',
                                    streetName: '',
                                    houseNumber: '',
                                });
                            }}
                            variant="text"
                            type="button"
                        >
              + Добавить адрес
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="primary" type="submit">
              Показать состав группы
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
