import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { BaseDispatch } from '../../../redux/actions';
import { Breadcrumb, BreadcrumbClickHandler, WithBreadcrumbs } from '../components/breadcrumbs/model';

export const useBreadcrumbs = (breadcrumbs: Breadcrumb[], handler?: BreadcrumbClickHandler): WithBreadcrumbs => {
    const dispatch = useDispatch<BaseDispatch>();
    const defaultBreadcrumbHandler: BreadcrumbClickHandler = (item) => {
        dispatch(push(item.link));
    };
    return {
        breadcrumbs,
        onClickBreadcrumb: handler ?? defaultBreadcrumbHandler,
    };
};
