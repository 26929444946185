import { meterActionSchemeValidationRules } from 'common/model/meter/meter-action-scheme/validation';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MeterActionSchemeViewModel } from '../../../../../common/model/meter/meter-action-scheme/meterActionSchemeViewModel';
import { BaseDispatch } from '../../../../../redux/actions';
import { createMeterActionScheme } from '../../../../../redux/actions/meterSchemes';
import { meterSchemeByIdSelector } from '../../../../../redux/selectors/meterSchemes';
import { AdministrationTabs, Routes } from '../../../../shared/constants';
import { useBreadcrumbs } from '../../../../shared/hooks/useBreadcrumbs';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { getRoute } from '../../../../shared/pipes';
import { View } from './view';

const defaultInitialValues: MeterActionSchemeViewModel = {
    configurationSnapshotIntervalHours: meterActionSchemeValidationRules.configurationSnapshotIntervalHours.default,
    currentReadingAllowedDelayHours: meterActionSchemeValidationRules.currentReadingAllowedDelayHours.default,
    dailyReadingAllowedDelayHours: meterActionSchemeValidationRules.dailyReadingAllowedDelayHours.default,
    dailyReadingMaxDays: meterActionSchemeValidationRules.dailyReadingMaxDays.default,
    eventsCollectingAllowedDelayHours: meterActionSchemeValidationRules.eventsCollectingAllowedDelayHours.default,
    incrementalReadingAllowedDelayHours: meterActionSchemeValidationRules.incrementalReadingAllowedDelayHours.default,
    incrementalReadingMaxHours: meterActionSchemeValidationRules.incrementalReadingMaxHours.default,
    isCrq: false,
    isDefault: false,
    monthlyReadingAllowedDelayMonths: meterActionSchemeValidationRules.monthlyReadingAllowedDelayMonths.default,
    minRetryDelay: meterActionSchemeValidationRules.minRetryDelay.default,
    maxRetryDelay: meterActionSchemeValidationRules.maxRetryDelay.default,
    maxFailureCount: meterActionSchemeValidationRules.maxFailureCount.default,
    validateOrChangeTariffIntervalHours: meterActionSchemeValidationRules.validateOrChangeTariffIntervalHours.default,
    name: undefined,
    processStatus: undefined,
    regionId: undefined,
};

export const SchemeCreateForm = () => {
    const dispatch = useDispatch<BaseDispatch>();

    const queryParams = useQueryParams();

    const duplicateFromSchemeId = queryParams.get('duplicateFromSchemeId');

    const duplicateSrcScheme = useSelector(meterSchemeByIdSelector(+duplicateFromSchemeId));

    const initialValues = duplicateSrcScheme ? {
        ...duplicateSrcScheme,
        name: '',
    } : defaultInitialValues;

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration) },
        { name: 'Схемы опроса', link: getRoute(Routes.administration, {}, { tab: AdministrationTabs.Schemes }) },
        { name: 'Новая схема опроса ', link: '' },
    ]);

    return View({
        breadcrumbs,
        initialValues,
        onClickBreadcrumb,
        onCancel() {
            dispatch(push(getRoute(Routes.administration, {}, { tab: AdministrationTabs.Schemes })));
        },
        onSubmit: async (data) => {
            await dispatch(createMeterActionScheme(data));
            await dispatch(push(getRoute(Routes.administration)));
        },
    });
};
