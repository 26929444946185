import * as React from 'react';
import { IStateProps } from './model';
import { Modal } from '../modal';
import './style.scss';

export const View = (props: IStateProps) => {
    return (
        <div className='modal-notifier'>
            {props.notifications.map((notification, i) => {
                return <Modal
                    key={i}
                    show={true}
                    title={notification.title}
                    type='notification'
                    variant={notification.variant}
                    actions={notification.actions}
                    onHide={props.onHide(notification)}
                >
                    {notification.message}
                </Modal>;
            })}
        </div>
    );
};
