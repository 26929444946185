import { useYupValidationResolver } from '../../../../../shared/utils/validation';
import yup from '../../../../../shared/utils/yup';
import { useForm } from 'react-hook-form';
import { View } from './view';
import { useAccessRules } from '../../../../../shared/hooks/useAccessRules';
import { AccessRule } from '../../../../../../common/model/access/accessRule';
import { UpdateTaskNameProps } from './model';
import { MeterDictionaryValuesViewModel } from '../../../../../../common/model/meter/meterDictionaryValues/meterDictionaryValuesViewModel';

const taskNameValidationSchema = yup.object({
    value: yup.string().required(),
});

export const EditTaskNameForm = (props: UpdateTaskNameProps) => {
    const { taskName } = props;
    const resolver = useYupValidationResolver<MeterDictionaryValuesViewModel>(taskNameValidationSchema);
    const form = useForm<MeterDictionaryValuesViewModel>({
        defaultValues: { id: taskName.id, value: taskName.value, dictionaryId: taskName.dictionaryId },
        resolver
    });
    const { hasAccess } = useAccessRules();

    return View({
        ...props,
        form,
        hasAccess: hasAccess(AccessRule.CanEditMeterDictionaryValue)
    });
};
