import { useEffect, useRef } from 'react';

type UseIntervalCallback = () => void|Promise<void>;

export const useInterval = (callback: UseIntervalCallback, delay: number) => {
    const savedCallback = useRef<UseIntervalCallback>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        };
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
        return () => {
            // to nothing
        };
    }, [delay]);
};
