import React from 'react';
import { createMuiTheme, Theme } from '@material-ui/core/styles';

const defaultMuiInputStyles = (theme: Theme) => ({
    root: {
        '& label.Mui-focused': {
            color: theme.palette.primary.light,
        },
        '& .MuiInputLabel-asterisk': {
            color: theme.palette.system.error,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.neutral.light,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.neutral.dark,
            },
            '&.Mui-disabled:hover fieldset': {
                borderColor: theme.palette.neutral.light,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.light,
            },
            '&.Mui-disabled fieldset': {
                opacity: theme.opacity.half,
            },
        },
        '& .MuiOutlinedInput-input, &.MuiOutlinedInput-input': {
            padding: '10px 12px',
            borderRadius: '4px',
            fontFamily: 'SF Pro Display',
            fontSize: '14px',
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '20px',
        },
        '& .MuiInputLabel-root': {
            fontFamily: 'SF Pro Display',
            fontSize: '14px',
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '20px',
            color: theme.palette.text.hint,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        },
        '& .MuiFormHelperText-root': {
            fontFamily: 'SF Pro Display',
            fontSize: '12x',
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '16px',
        },
        '& .MuiInputLabel-outlined, & .MuiInputLabel-formControl': {
            transform: 'translate(12px, 10px) scale(1)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink, & .MuiInputLabel-formControl.MuiInputLabel-shrink': {
            transform: 'translate(14px, -8px) scale(0.86)',
            '&.Mui-error': {
                color: theme.palette.system.error,
            }
        },
        '& .MuiInputBase-input': {
            height: '20px',
        },
        '& .Mui-error fieldset': {
            borderColor: theme.palette.system.error,
        },
    }
});

const baseTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#E64A19',
            light: '#FF5722',
        },
        text: {
            primary: '#18191F',
            secondary: '#343434',
            hint: '#5C6C7F',
        },
        neutral: {
            dark: '#212121',
            main: '#616161',
            gray: '#808080',
            light: '#9E9E9E',
            lightGray: '#A8A8A8',
            background: '#E5E5E5',
            ultraLight: '#D6D6D6',
            white: '#F5F5F5',
            middleBg: '#ECECEC',
        },
        system: {
            error: '#F20808',
            success: '#39CC59',
            warning: '#FFDC00',
        },
        diagrams: {
            light: '#DF8B71',
            medium: '#CC3100',
            dark: '#6B1A00',
        },
    },
    typography: {
        fontFamily: 'SF Pro Display, sans-serif',
        fontWeightBold: 600,
        fontWeightRegular: 400,
        h1: {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '0.01em',
        },
        h2: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0.01em',
        },
        h3: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '0.01em',
        },
        h4: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.01em',
            textTransform: 'uppercase',
        },
        body: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
        },
        bodyBold: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
        },
        caption: {
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '18px',
        },
        captionBold: {
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: '18px',
        },
        button: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            textTransform: 'none',
        },
        input: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
        },
        label: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
        },
    },
    opacity: {
        half: 0.5,
        quarter: 0.25,
    },
    spacing: 8,
    breakpoints: {
        values: {
            xs: 0,
            sm: 960,
            md: 1200,
            lg: 1440,
            xl: 1920,
        }
    }
});

export const materialUiTheme = createMuiTheme({
    overrides: {
        MuiTextField: defaultMuiInputStyles(baseTheme),
        MuiSelect: defaultMuiInputStyles(baseTheme),
        MuiFormControl: defaultMuiInputStyles(baseTheme),
        MuiOutlinedInput: defaultMuiInputStyles(baseTheme),
    }
}, baseTheme);

export const greenCircle = '#5D9F23';
export const greyCircle = '#C4C4C4';

export const menuIconHiddenScreenWidth = 1600;

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        opacity?: {
            half: number;
            quarter: number;
        };
    }

    interface ThemeOptions {
        opacity?: {
            half: number;
            quarter: number;
        };
    }
}

declare module '@material-ui/core/styles/createTypography' {
    interface Typography {
        captionBold: React.CSSProperties;
        body: React.CSSProperties;
        bodyBold: React.CSSProperties;
        input: React.CSSProperties;
        label: React.CSSProperties;
    }

    interface TypographyOptions {
        captionBold?: React.CSSProperties;
        body: React.CSSProperties;
        bodyBold: React.CSSProperties;
        input: React.CSSProperties;
        label: React.CSSProperties;
    }
}

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        neutral: NeutralColors;
        system: SystemColors;
        diagrams: DiagramsColors;
    }
    interface PaletteOptions {
        neutral: NeutralColors;
        system: SystemColors;
        diagrams: DiagramsColors;
    }
    interface NeutralColors {
        main?: React.CSSProperties['color'];
        light?: React.CSSProperties['color'];
        ultraLight?: React.CSSProperties['color'];
        dark?: React.CSSProperties['color'];
        background?: React.CSSProperties['color'];
        white?: React.CSSProperties['color'];
        lightGray?: React.CSSProperties['color'];
        gray?: React.CSSProperties['color'];
        middleBg?: React.CSSProperties['color'];
    }
    interface SystemColors {
        error?: React.CSSProperties['color'];
        success?: React.CSSProperties['color'];
        warning?: React.CSSProperties['color'];
    }
    interface DiagramsColors {
        light?: React.CSSProperties['color'];
        medium?: React.CSSProperties['color'];
        dark?: React.CSSProperties['color'];
    }
}
