import React from 'react';
import { IconType } from '../icon/model';

export interface TreeItem {
    id: string;
    title: string;
    isOpened: boolean;
    realId?: string;
    isSelected?: boolean;
    items?: TreeItem[];
    type?: TreeItemType;
    parentId?: string;
    icon?: IconType;
    iconTooltip?: string;
}

export interface RenderItemsProps {
    items: TreeItem[];
    handleClickItem: (item: TreeItem) => void;
    isBlackMenu?: boolean;
    openArrowSrc?: string;
    arrowSrc?: string;
    visibilityTooltip?: boolean;
    setVisibilityTooltip?: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum TreeItemType {
    Region,
    Area,
    Town,
    Street,
    House,
    Meter,
}
