import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TariffScheduleViewModel } from '../../../../common/model/meter/tariffSchedule/tariffScheduleViewModel';

export interface TariffScheduleListState {
    available: TariffScheduleViewModel[];
}

const initialState: TariffScheduleListState = {
    available: null,
};

export const tariffScheduleListSlice = createSlice({
    name: '@@vv/tariffSchedule/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<TariffScheduleViewModel[]>) {
            state.available = action.payload;
        }
    },
});
