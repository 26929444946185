import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useWatch } from 'react-hook-form';
import {
    DynamicMeterGroupAddressesViewModel
} from '../../../../../../../../common/model/meter/meter/dynamicMeterGroupAddressesViewModel';
import { joinAddressesString } from '../../../../../../../../common/shared/utils/addresses';
import { Button } from '../../../../../../../shared/components/button';
import { Heading } from '../../../../../../../shared/components/heading';
import { Modal } from '../../../../../../../shared/components/modal';
import { HookField } from '../../../../../../../shared/components/hookField';
import { Typography } from '../../../../../../../shared/components/typography';
import {
    useWebDictionarySelectSuggestions
} from '../../../../../../../shared/hooks/useWebDictionary';
import { IStateProps } from './model';
import { TextInput } from '../../../../../../../shared/components/textInput';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: '24px',
        color: theme.palette.text.secondary,
    },
    label: {
        display: 'block',
        textAlign: 'right',
    },
    icon: {
        fontSize: 10
    }
}));

export const View: React.FC<IStateProps> = (props) => {
    const classes = useStyles();
    const {
        name,
        isModalOpen,
        onClickRemove,
        onClickModalOpen,
        onClickModalClose,
        onSearchStreetName,
        onSearchAreaName,
        onSearchHouseNumber,
        onSearchTownName,
        isAddressSuggestionsLoading,
        houseNumbersSuggestions,
        streetNamesSuggestions,
        townNamesSuggestions,
        areaNamesSuggestions,
    } = props;

    const addresses: DynamicMeterGroupAddressesViewModel = useWatch({ name });
    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();
    return <div>
        <Grid container spacing={2}>
            <Grid style={{ flex: 1 }} item>
                <TextInput
                    variant='outlined'
                    value={joinAddressesString(addresses)}
                    onClick={onClickModalOpen}
                    fullWidth
                    placeholder='Выбрать адрес'
                />
            </Grid>
            <Grid item>
                <Button
                    variant='outline'
                    icon='delete'
                    square={true}
                    onClick={() => {
                        onClickRemove();
                    }}
                />
            </Grid>
        </Grid>
        {isModalOpen && (
            <Modal size='md' onHide={onClickModalClose}>
                <Box display='flex' justifyContent='center' mb={2}>
                    <Heading heading='Выбор адреса' />
                </Box>
                <Box mt={2}>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                            <Grid item xs={4}>
                                <Typography className={classes.label} variant='bodyBold'>Регион</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name={`${name}.regionId`}
                                    type='select'
                                    label='Выберите субъект РФ'
                                    suggestions={regionSuggestions}
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                            <Grid item xs={4}>
                                <Typography className={classes.label} variant='bodyBold'>Район</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    type='autocomplete'
                                    name={`${name}.areaName`}
                                    label='Район'
                                    options={areaNamesSuggestions}
                                    loading={isAddressSuggestionsLoading}
                                    onInputChange={(e, val) => onSearchAreaName(val)}
                                    fullWidth
                                    noOptionsText='Район не найден'
                                    loadingText='Поиск...'
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                            <Grid item xs={4}>
                                <Typography className={classes.label} variant='bodyBold'>Населенный пункт</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    type='autocomplete'
                                    name={`${name}.townName`}
                                    label='Выберите населенный пункт'
                                    options={townNamesSuggestions}
                                    loading={isAddressSuggestionsLoading}
                                    onInputChange={(e, val) => onSearchTownName(val)}
                                    fullWidth
                                    noOptionsText='Город не найден'
                                    loadingText='Поиск...' />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                            <Grid item xs={4}>
                                <Typography className={classes.label} variant='bodyBold'>Улица</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name={`${name}.streetName`}
                                    type='autocomplete'
                                    label='Выберите улицу'
                                    options={streetNamesSuggestions}
                                    loading={isAddressSuggestionsLoading}
                                    onInputChange={(e, val) => onSearchStreetName(val)}
                                    fullWidth
                                    noOptionsText='Улица не найдена'
                                    loadingText='Поиск...' />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                            <Grid item xs={4}>
                                <Typography className={classes.label} variant='bodyBold'>Номер здания</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name={`${name}.houseNumber`}
                                    type='autocomplete'
                                    label='Выберите дом'
                                    options={houseNumbersSuggestions}
                                    loading={isAddressSuggestionsLoading}
                                    onInputChange={(e, val) => onSearchHouseNumber(val)}
                                    fullWidth
                                    noOptionsText='Дом не найден'
                                    loadingText='Поиск...' />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item sm={12}>
                                <Grid container spacing={2} justify='center'>
                                    <Grid item>
                                        <Button onClick={onClickModalClose} variant='primary' type='button' icon='check'>Сохранить</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={onClickModalClose} variant='outline' type='button' icon='close'>Отмена</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        )}
    </div>;
};
