import React from 'react';
import { View } from './view';
import { GisTaskFilterFormProps } from './model';
import { useForm } from 'react-hook-form';
import { EventLogFindRequest } from '../../../../../common/model/meter/eventLog/eventLogFindRequest';
import { useDispatch, useSelector } from 'react-redux';
import { findCommonMeters } from '../../../../../redux/actions/eventLogs';
import { MultiSelectValue } from '../../../../shared/components/multiselectwithsearch/model';
import { BaseDispatch } from '../../../../../redux/actions';
import { SearchProps } from '../../../../shared/components/search/model';
import { useUsersAutocomplete } from '../../../../shared/components/autocomplete/hooks/useUsersAutocomplete';
import { statuses } from '../../../../shared/constants/gisTaskStatuses';
import { metersSelector } from '../../../../../redux/selectors/meters';

let timeoutId: NodeJS.Timeout = null;
const TIMEOUT_WRITE = 400;
const MIN_LETTERS_TO_SEARCH = 4;

export const GisTaskFilterForm: React.FC<GisTaskFilterFormProps> = (props) => {
    const { filterValues, initialFilterValues } = props;
    const hintItems: any[] = [];
    const loading = false;
    const meters = useSelector(metersSelector);
    const form = useForm<EventLogFindRequest>({ defaultValues: filterValues });
    const dispatch = useDispatch<BaseDispatch>();

    const usersAutocomplete = useUsersAutocomplete();

    const onResetForm = () => {
        form.reset(initialFilterValues);
        props.onSubmit(initialFilterValues);
    };

    const onSearch = (val: string): void => {
        if (val?.length < MIN_LETTERS_TO_SEARCH) {
            return;
        }

        const currentValue = val;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            dispatch(findCommonMeters({ meterSerialNum: currentValue }));
        }, TIMEOUT_WRITE);
    };

    const suggestions: MultiSelectValue[] = meters?.map(item => {
        return {
            label: `${item.meterModel} ${item.meterSerialNum}`,
            value: item.foreignId,
        };
    });

    const searchProps: SearchProps = {
        items: hintItems,
        loading,
        dark: true,
    };

    return View({
        ...props,
        form,
        onResetForm,
        statuses,
        usersAutocomplete,
        onSearch,
        suggestions,
        searchProps,
    });
};
