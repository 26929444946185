
import { UserViewModel } from '../../../../common/model/access/user/userViewModel';
import { AppThunk, BaseDispatch } from '../../index';
import { httpService } from '../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../common/appUrls';
import { getResources } from '../../resources';
import { usersActiveSlice } from '../../../reducers/users/active';

export const setActiveUser = (client: UserViewModel): AppThunk => async (dispatch): Promise<void> => {
    dispatch(usersActiveSlice.actions.setActiveUser(client));
    await dispatch(getResources());
};

export const getCurrentUser = (): AppThunk => async (dispatch: BaseDispatch): Promise<void> => {
    const user = await httpService.get<UserViewModel>(appUrls.api_get_current_user);
    await dispatch(setActiveUser(user));
};
