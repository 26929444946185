import React from 'react';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Heading } from '../../../../shared/components/heading';
import { HookField } from '../../../../shared/components/hookField';
import { useWebDictionarySelectSuggestions } from '../../../../shared/hooks/useWebDictionary';
import { IStateProps } from './model';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Button } from '../../../../shared/components/button';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: theme.palette.neutral.middleBg,
    },
}));

export const View: React.FC<IStateProps> = (props) => {

    const {
        form,
        heading,
        formData,
        onSubmit,
        isLoading,
        breadcrumbs,
        onBackHandler,
        onClickBreadcrumb,
        meterGroupAutocomplete,
    } = props;

    const classes = useStyles();

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const {
        handleSubmit,
    } = form;

    const actions = <>
        <FormProvider {...form}>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item lg={2}>
                        <HookField
                            name='regionId'
                            type='select'
                            label='Выберите субъект РФ'
                            suggestions={regionSuggestions}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <HookField
                            type='autocomplete'
                            name='meterGroupId'
                            label='Группа ПУ'
                            {...meterGroupAutocomplete}
                            fullWidth />
                    </Grid>
                    <Grid item>
                        <HookField
                            type='checkbox'
                            name='isBeginDateSet'
                            label='Указать период'
                        />
                    </Grid>
                    {formData.isBeginDateSet && (
                        <>
                            <Grid item lg={2}>
                                <HookField
                                    fullWidth
                                    name='localBeginReadingDate'
                                    type='datepicker'
                                    showTime={true}
                                    label='Начало периода опроса'
                                />
                            </Grid>
                            <Grid item lg={2}>
                                <HookField
                                    fullWidth
                                    name='localEndReadingDate'
                                    type='datepicker'
                                    showTime={true}
                                    label='Конец периода опроса'
                                    disabled={true}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item lg={2}>
                        <Button
                            type='submit'
                            variant='primary'
                            isLoading={isLoading}
                        >
                            Запустить
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    </>;

    return <>
        <Box mb={2}>
            <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
        </Box>
        <Box mb={2}>
            <Heading heading={heading} onBackHandler={onBackHandler} />
        </Box>
        <Box>
            <Wrapper
                isBigHeader
                actions={actions}
                headerColor='white'
                className={classes.wrapper}
            >
                Для появления результатов, запустите опрос
            </Wrapper>
        </Box>
    </>;
};
