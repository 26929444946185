import React, { useMemo } from 'react';
import { TariffZoneCreator } from '../../../../../../common/model/meter/tariffZone/tariffZoneCreator';
import { minutesToTimeParser } from '../../../../../shared/components/timePicker/helpers';
import { Tooltip } from '../../../../../shared/components/tooltip';

interface Props {
    tariffZones: TariffZoneCreator[];
    dayId: string;
}

export const TariffZonesTooltip: React.FC<Props> = (props) => {
    const {
        children,
        tariffZones,
        dayId,
    } = props;

    const filteredTariffZones = useMemo(() => [
        ...tariffZones.filter(item => {
            return item.tariffDayId === dayId;
        })
    ].sort((a, b) => a.startTime - b.startTime),
    [dayId, tariffZones]
    );

    const text = useMemo(() => filteredTariffZones.map(item => {
        return `${
            minutesToTimeParser(item.startTime)
        } Тариф ${
            item.tariffNumber
        }`;
    }).map(item => <div key={item}>{item}</div>),
    [filteredTariffZones]
    );

    if (filteredTariffZones.length === 0) {
        return <>{children}</>;
    }

    return <Tooltip text={text}>{children}</Tooltip>;
};
