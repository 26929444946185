import { MeterViewModel } from '../../../common/model/meter/meter/meterViewModel';

export interface SearchPreparedMeter {
    label: string;
    value: string;
    searchString: string;
}

export const prepareMeters = (meters: MeterViewModel[]): SearchPreparedMeter[] => {
    return meters.map(meter => ({
        label: `${meter.meterModel} №${meter.meterSerialNum}`,
        value: meter.meterSerialNum,
        searchString: `${meter.meterSerialNum},${meter.meterModel},${meter?.address?.address ?? ''}`,
    }));
};
