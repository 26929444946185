const shieldedSymbols = ['[', ']', '\\', '^', '$', '.', '|', '+', '(', ')'];

/**
 * Проверка строки по маске
 * Спец символы:
 * * - эквивалент RegExp(.+)
 * ? - эквивалент RegExp(.)
 */
export const checkMeterModelMask = (mask: string, str: string): boolean => {
    const shieldedMask = mask.split('')
        .map(s => shieldedSymbols.indexOf(s) >= 0 ? `\\${s}` : s)
        .join('');
    const formattedMask = shieldedMask
        .replace(/\*/g, '(.+)?')
        .replace(/\?/g, '.')
        .replace(/\//g, '//');
    const regExp = new RegExp(`^${formattedMask}$`);
    return regExp.test(str);
};
