import React from 'react';
import { Button } from '../../../../../shared/components/button';

interface Props {
    onClick: () => void;
    hasIcon?: boolean;
}

export const ActionsColumn: React.FC<Props> = ({ onClick, hasIcon = true }) => {
    return (
        <div>
            <Button variant='primary' onClick={onClick} icon={hasIcon ? 'edit' : null} className='editButton'>
                Редактировать
            </Button>
        </div>
    );
};
