import { useSelector } from 'react-redux';
import { meterGisSelector, meterSelector } from '../../../../redux/selectors/meters';
import { View } from './view';

export const Gis = () => {

    const meter = useSelector(meterSelector);
    const gis = useSelector(meterGisSelector);

    return View({
        gis,
        meter,
    });
};
