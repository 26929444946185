import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { UserStatus } from '../../../../../common/model/access/user/userStatus';
import { UserUpdater } from '../../../../../common/model/access/user/userUpdater';
import { UserViewModel } from '../../../../../common/model/access/user/userViewModel';
import { accessRolesListSelector } from '../../../../../redux/selectors';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import yup from '../../../../shared/utils/yup';
import { UserFormProps } from './model';
import { View } from './view';

const userValidationSchema = yup.object({
    name: yup.string()
        .required('Введите имя'),
    lastName: yup.string()
        .required('Введите фамилию'),
    status: yup.string()
        .required('Выберите статус'),
    userName: yup.string()
        .required('Введите уникальное имя пользователя'),
});

const initialUser: UserUpdater = {
    name: '',
    status: UserStatus.Active,
    roleIds: [],
    userName: '',
    email: '',
    lastName: '',
    patronymic: '',
};

export const UserForm = ({ user, mode, onSubmit }: UserFormProps) => {
    const roles = useSelector(accessRolesListSelector());
    const resolver = useYupValidationResolver<UserViewModel>(userValidationSchema);

    const defaultValues = user ? user : initialUser;
    const form = useForm<UserUpdater>({
        defaultValues,
        resolver
    });

    const handleClearRoles = () => {
        const value = { ...form.watch(), roleIds: [] as string[] };
        form.reset(value);
    };

    useEffect(() => {
        form.reset(defaultValues);
    }, [defaultValues]);

    return View({
        user,
        form,
        mode,
        onSubmit,
        handleClearRoles,
        roles: roles?.map(role => ({
            name: role.name || role.code,
            value: role.id,
        }))
    });

};
