import { ListItem, ListItemIcon, makeStyles, Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Icon } from '../../icon';
import { dataTargetIdAttribute } from '../model';
import { StateProps } from './model';

const useStyles = makeStyles((theme) => ({
    inOverflowMenu: {
        border: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    listItemRoot: {
        height: '100%',
        borderRight: `0.5px solid ${theme.palette.neutral.main}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.neutral.background,
            color: theme.palette.primary.light
        },
    },
    listItemIconRoot: {
        minWidth: 'auto',
    },
    moreIcon: {
        fill: theme.palette.text.primary,
    },
    moreIconActive: {
        fill: theme.palette.primary.light,
    },
    popoverRoot: {
        marginTop: '8px',
    },
}));

export const View: React.FC<StateProps> = ({
    children,
    className,
    visibilityMap,
    handleClose,
    handleClick,
    shouldShowMenu,
    open,
    anchorEl
}) => {

    const classes = useStyles();

    if (!shouldShowMenu) {
        return null;
    }

    return (
        <div className={className}>
            <ListItem
                classes={{ root: classes.listItemRoot }}
                disableRipple
                button
                onClick={handleClick}
            >
                <ListItemIcon classes={{ root: classes.listItemIconRoot }}><Icon className={classNames(classes.moreIcon, { [classes.moreIconActive]: open })} name='more' /></ListItemIcon>
            </ListItem>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                PopoverClasses={{ root: classes.popoverRoot }}
            >
                {React.Children.map(children, (child, idx) => {
                    if (!React.isValidElement(child)) {
                        return null;
                    }

                    if (visibilityMap[child.props[dataTargetIdAttribute]]) {
                        return null;
                    }

                    return <MenuItem key={idx} onClick={!child.props['isTab'] ? handleClose : undefined }>
                        {React.cloneElement(child, {
                            className: classNames(child.props['className'], classes.inOverflowMenu)
                        })}
                    </MenuItem>;
                })}
            </Menu>
        </div>
    );
};
