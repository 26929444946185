import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

export const dictionariesSelector = createSelector(
    (state: StorageState) => state.dictionaries.list,
    list => list.available
);

export const meterDictionaryValues = createSelector(
    (state: StorageState) => state.dictionaries.list,
    list => list.dictionaryValues,
);
