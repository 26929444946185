import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

export const auditListSelector = createSelector(
    (state: StorageState) => state.audit.list,
    list => list.logs
);

export const auditTokenResponseSelector = createSelector(
    (state: StorageState) => state.audit.list,
    list => list.tokenResponse
);

export const auditListTotalSelector = createSelector(
    (state: StorageState) => state.audit.list,
    list => list.logsTotal
);
