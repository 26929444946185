import { HookFieldTransformer } from '../hookField/model';

const minutesInHour = 60;

export const addZeroAtStart = (num: number): string => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return ('0' + num).slice(-2);
};

export const timeToMinutesParser = (timeString: string): number => {
    try {
        const timeArray = timeString.split(':');
        return (+timeArray[0] * minutesInHour) + +timeArray[1];
    } catch {
        // do nothing
    }
    return undefined;
};

export const minutesToTimeParser = (timeMinutes: number): string => {
    try {
        const minutes = timeMinutes % minutesInHour;
        const hours = Math.floor(timeMinutes / minutesInHour);
        return `${addZeroAtStart(hours)}:${addZeroAtStart(minutes)}`;
    } catch {
        // do nothing
    }
    return undefined;
};

export const timeToMinutesTransformer: HookFieldTransformer = {
    input: (v: number) => {
        return minutesToTimeParser(v);
    },
    output: (v: string) => {
        return timeToMinutesParser(v);
    },
};
