import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalAction } from '../../../../app/shared/components/modal/model';
import { ReactNode } from 'react';

export enum ModalNotificationVariant {
    Alert = 'alert',
    Form = 'form',
}

export interface ModalNotification {
    title: string;
    message: string|ReactNode;
    actions?: ModalAction[];
    showHide?: boolean;
    variant?: ModalNotificationVariant;
}

export interface ModalNotificationsState {
    notifications: ModalNotification[];
}

const initialState: ModalNotificationsState = {
    notifications: [],
};

export const modalNotificationsSlice = createSlice({
    name: '@@modalNotifications',
    initialState,
    reducers: {
        push(state, action: PayloadAction<ModalNotification>) {
            state.notifications = [
                ...state.notifications,
                { ...action.payload },
            ];
        },
        pop(state) {
            state.notifications = state.notifications.slice(0, -1);
        },
        clear(state) {
            state.notifications = [];
        }
    }
});
