import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import {
    meterReadingInstantOrderStatusDictionary,
    MeterUpdateTechnicalParamsOrderStatus
} from '../../../../../common/model/meter/meterUpdateTechnicalParamsOrder/meterUpdateTechnicalParamsOrderStatus';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { getMeter, getMeterUpdateTechnicalParamsOrder, setMeterUpdateTechnicalParamsOrder } from '../../../../../redux/actions/meters';
import { updateMeterTechnicalParams } from '../../../../../redux/actions/meterUpdateAttributes';
import { meterSelector, meterUpdateTechnicalParamsOrderSelector } from '../../../../../redux/selectors/meters';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useInterval } from '../../../../shared/hooks/useInterval';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { actionQueryParam } from '../model';
import { MeterTechnicalParamsUpdaterForm, StateProps } from './model';
import { View } from './view';

export const MeterTechnicalParamsUpdateForm = () => {

    const { meterId } = useParams<{ meterId: string }>();
    const queryParams = useQueryParams();
    const meter = useSelector(meterSelector);
    const order = useSelector(meterUpdateTechnicalParamsOrderSelector);
    const [orderStatus, setOrderStatus] = useState<MeterUpdateTechnicalParamsOrderStatus>(null);

    const dispatch = useDispatch<BaseDispatch>();

    const { hasAccess } = useAccessRules();

    const defaultValues: MeterTechnicalParamsUpdaterForm = {
        dst: meter.dst ?? null,
        changePasswordForbidden: meter.changePasswordForbidden ?? null,
        changeTariffForbidden: meter.changeTariffForbidden ?? null,
        changeTimeForbidden: meter.changeTimeForbidden ?? null,
        changeIntervalForbidden: meter.changeIntervalForbidden ?? null,
    };

    const onCloseModal = () => {
        queryParams.delete(actionQueryParam);
        dispatch(push({
            search: queryParams.toString()
        }));
    };

    const refreshOrder = () => {
        dispatch(getMeterUpdateTechnicalParamsOrder(meterId));
    };

    const refreshMeter = () => {
        dispatch(getMeter(meterId));
    };

    const onSubmit: SubmitHandler<MeterTechnicalParamsUpdaterForm> = async (values) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { dst, ...submittableFormData } = values;
        await dispatch(updateMeterTechnicalParams(meterId, submittableFormData));
        refreshOrder();
        refreshMeter();
    };

    const form = useForm<MeterTechnicalParamsUpdaterForm>({
        defaultValues,
    });

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    useInterval(refreshOrder, 2000);

    useEffect(() => {
        form.reset({
            dst: meter.dst ?? null,
            changePasswordForbidden: meter.changePasswordForbidden ?? null,
            changeTariffForbidden: meter.changeTariffForbidden ?? null,
            changeTimeForbidden: meter.changeTimeForbidden ?? null,
            changeIntervalForbidden: meter.changeIntervalForbidden ?? null,
        });
    }, [meter]);

    useEffect(() => {
        refreshOrder();
        return () => {
            dispatch(setMeterUpdateTechnicalParamsOrder(null));
        };
    }, []);

    useEffect(() => {
        if (order) {
            setOrderStatus(order.status);
        }
        if (order && order.status === MeterUpdateTechnicalParamsOrderStatus.Success && orderStatus && order.status !== orderStatus) {
            dispatch(createSnackbar({
                type: 'green',
                message: 'Заяка успешно обработана',
            }));
            setOrderStatus(order.status);
            refreshMeter();
        } else if (
            order
            && [
                MeterUpdateTechnicalParamsOrderStatus.Error,
                MeterUpdateTechnicalParamsOrderStatus.MaxFailureCountError
            ].includes(order.status)
            && orderStatus
            && order.status !== orderStatus
        ) {
            dispatch(createSnackbar({
                type: 'red',
                message: `Заяка обработана с ошибкой: ${meterReadingInstantOrderStatusDictionary[order.status]}`,
            }));
            setOrderStatus(order.status);
        }
    }, [order]);


    const props: StateProps = {
        form,
        order,
        onSubmit,
        onCancel: onCloseModal,
        hasAccess: hasAccess(AccessRule.CanUpdateMeterTechnicalParams) || hasAccess(AccessRule.CanViewMeterTechnicalParams),
        hasEditAccess: hasAccess(AccessRule.CanUpdateMeterTechnicalParams),
    };

    return View(props);
};
