import { MeterReadingDailyProfileViewModel } from '../../../../../common/model/meter/meter/meterReadingDailyProfileViewModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterReadingDailyProfileListResponse } from '../../../../../common/model/meter/meter/meterReadingDailyProfileListResponse';


export interface DailyProfilesListState {
    available: MeterReadingDailyProfileViewModel[];
    total: number;
}

const initialState: DailyProfilesListState = {
    available: null,
    total: 0,
};

export const dailyProfilesListSlice = createSlice({
    name: '@@vv/meterReading/dailyProfile/list',
    initialState,
    reducers: {
        setAvailableDailyProfiles(state, action: PayloadAction<MeterReadingDailyProfileListResponse>) {
            state.available = action.payload.data;
            state.total = action.payload.total;
        }
    }
});
