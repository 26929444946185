import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { MeterPartnerViewModel } from '../../../../../common/model/meter/meter/meterPartnerViewModel';
import { PartnerCreator } from '../../../../../common/model/meter/partners/partnerCreator';
import { ISort } from '../../../../../common/sort';
import { BaseDispatch } from '../../../../../redux/actions';
import { getPartnerMeters } from '../../../../../redux/actions/partners';
import {
    partnersDetailsMetersSelector,
    partnersDetailsMetersTotalSelector
} from '../../../../../redux/selectors/partners';
import { usePagination } from '../../../../shared/components/pagination';
import { MeterTabs, Routes } from '../../../../shared/constants';
import { useWebDictionaryLookup } from '../../../../shared/hooks/useWebDictionary';
import { getRoute } from '../../../../shared/pipes';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import yup from '../../../../shared/utils/yup';
import { MeterSerialNumView } from '../../../reports/components/common/meterSerianNumView';
import { timeZonePipe } from 'app/shared/pipes/timeZone';
import { InputProps } from './model';
import { ReadingSourceDetailsView, View } from './view';

const initialPartnerFormValues: PartnerCreator = {
    name: null,
    innCode: null,
};


const partnerCreatorValidationScheme = yup.object({
    name: yup.string().required(),
    innCode: yup.string()
        .required()
        .length(10)
    ,
});

export const PartnerDetails: React.FC<InputProps> = (props) => {

    const dispatch = useDispatch<BaseDispatch>();

    const { meterModels } = useWebDictionaryLookup();

    const { partner } = props;
    const data = useSelector(partnersDetailsMetersSelector());
    const dataTotal = useSelector(partnersDetailsMetersTotalSelector());

    const fetchPartnerMeters = async (sort: ISort) => {
        if (partner) {
            await dispatch(getPartnerMeters(partner.id, sort));
        }
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 10,
        orderBy: null,
        orderDirection: null,
    }, fetchPartnerMeters);

    const refreshTableData = () => {
        onFetch(pagination);
    };

    useEffect(() => {
        refreshTableData();
    }, []);

    const resolver = useYupValidationResolver<PartnerCreator>(partnerCreatorValidationScheme);

    const isNewPartner = !partner;

    const form = useForm<PartnerCreator>({
        defaultValues: isNewPartner
            ? initialPartnerFormValues
            : { name: partner.name, innCode: partner.innCode },
        resolver,
    });

    const { isDirty } = form.formState;

    const isSaveDisabled = !isNewPartner && !isDirty;

    const metersColumns: Column[] = [
        {
            title: 'Тип ПУ',
            field: 'meterModelId',
            lookup: meterModels,
            cellClassName: 'col-meterModel',
            sorting: false,
        },
        {
            title: 'Заводской номер',
            field: 'meterSerialNum',
            cellClassName: 'col-meterSerialNum',
            sorting: false,
            render: (item: MeterPartnerViewModel) => {
                const route = getRoute(Routes.meter, { meterId: item.id }, { tab: MeterTabs.Information });
                return <MeterSerialNumView
                    label={item.meterSerialNum}
                    url={route}
                />;
            }
        },
        {
            title: 'АИИС КУЭ',
            field: 'code',
            cellClassName: 'col-code',
            sorting: false,
        },
        {
            title: 'Адрес',
            field: 'address.address',
            cellClassName: 'col-address',
            sorting: false,
        },
        {
            title: 'Коэф-т трансф-и по току',
            field: 'cTRatio',
            cellClassName: 'col-cTRatio',
            sorting: false,
        },
        {
            title: 'Коэф-т трансф-и по напряжению',
            field: 'pTRatio',
            cellClassName: 'col-pTRatio',
            sorting: false,
        },
        {
            title: 'Временная зона ПУ',
            field: 'timeZone',
            cellClassName: 'col-timeZone',
            sorting: false,
            render: (item) => {
                return timeZonePipe(item?.timeZone);
            },
        },
        {
            title: 'Источник показаний',
            field: 'readingSource',
            cellClassName: 'col-readingSource',
            sorting: false,
            render: (item) => {
                const route = getRoute(Routes.meter, { meterId: item.id }, { tab: MeterTabs.Information });
                return <ReadingSourceDetailsView
                    item={item}
                    onClick={() => { dispatch(push(route)); }}
                />;
            },
        }
    ];

    return View({
        ...props,
        form,
        data,
        dataTotal,
        setOrder,
        setPage,
        orderParams,
        pagination,
        isNewPartner,
        isSaveDisabled,
        metersColumns,
    });
};
