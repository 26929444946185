import React from 'react';
import InputMask from 'react-input-mask';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button } from '../../button';
import { TextInput } from '../../textInput';
import { ViewPeriodProps } from './model';

const useStyles = makeStyles(() => ({
    input: {
        width: 70
    }
}));

export const View: React.FC<ViewPeriodProps> = (props) => {
    const { period, disabledSave, onChangePeriod, onSavePeriod, removePeriod, disabled } = props;

    const classes = useStyles();

    return (
        <Box marginBottom='8px'>
            <Grid container spacing={2}>
                <Grid item>
                    <InputMask
                        disabled={disabled}
                        mask={'99:00'}
                        value={period.startTime}
                        onChange={(e) => onChangePeriod({
                            startTime: e.target.value,
                        })}>
                        {(maskProps: any) => (
                            <TextInput {...maskProps} disabled={maskProps.disabled || disabled} label='Начало' className={classes.input} />
                        )}
                    </InputMask>
                </Grid>
                <Grid item>
                    <InputMask
                        disabled={disabled}
                        mask={'99:00'}
                        value={period.endTime}
                        onChange={(e) => onChangePeriod({
                            endTime: e.target.value,
                        })}
                    >
                        {(maskProps: any) => (
                            <TextInput {...maskProps} disabled={maskProps.disabled || disabled} label='Конец' className={classes.input} />
                        )}
                    </InputMask>
                </Grid>
                <Grid item>
                    <Button variant='outline' disabled={disabledSave || disabled} onClick={() => onSavePeriod(period)}>Применить</Button>
                </Grid>
                <Grid item>
                    <Button disabled={disabled} variant='outline' icon='delete' square onClick={() => removePeriod(period.id)} />
                </Grid>
            </Grid>
        </Box>
    );
};
