export interface TariffScheduleVersionCommonProps {
    isEditMode: boolean;
    isNewRecord: boolean;
}

export const extractTariffScheduleVersionCommonProps = (props: TariffScheduleVersionCommonProps): TariffScheduleVersionCommonProps => {
    return {
        isEditMode: props.isEditMode,
        isNewRecord: props.isNewRecord,
    };
};
