import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { MassOperationProgressGraph } from '../massOperationProgressGraph';
import { InputProps } from './model';
import { Typography } from '../../../../../shared/components/typography';
import { Block } from '../../../../../shared/components/wrapper/block';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#F5F5F5',
        borderRadius: 5,
        padding: 16,
        paddingBottom: 50,
    }
}));

export const View: React.FC<InputProps> = (props) => {

    const {
        title,
        blockProps,
        graphicProps,
    } = props;

    const classes = useStyles();

    return <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h2'>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={5}>
                <Block {...blockProps} />
            </Grid>
            <Grid item xs={12} lg={7}>
                <MassOperationProgressGraph {...graphicProps} />
            </Grid>
        </Grid>
    </div>;
};
