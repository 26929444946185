import { makeStyles, Box } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../shared/components/button';
import { Forbidden } from '../../../shared/components/forbidden';
import { Pagination } from '../../../shared/components/pagination';
import { setOrderTransformer } from '../../../shared/components/pagination/helpers';
import { Table } from '../../../shared/components/table';
import { VisualizationSource } from '../../../shared/components/table/model';
import { Wrapper } from '../../../shared/components/wrapper';
import { defaults } from '../../../shared/constants';
import { Modal } from '../../../shared/components/modal';
import { UserForm } from './userForm';
import { StateProps, UserMode } from './model';
import { ActionModal } from '../../../shared/components/actionModal';

const useStyles = makeStyles(() => ({
    tableWrapper: {
        display: 'flex',
        minHeight: '880px',
    },
}));

export const View = ({
    data,
    mode,
    dataTotal,
    setOrder,
    pagination,
    setPage,
    orderParams,
    onSubmit,
    editableUser,
    localization,
    onClickNewUser,
    columnDefinition,
    onCloseCreateModal,
    hasAccessToUsersList,
    isDeleteUserModalAvailable,
    activeUser,
    onDelete,
    onCloseDeleteModal,
}: StateProps) => {

    const classes = useStyles();

    const addNewUserAction = <Box marginLeft='auto'>
        <Button onClick={onClickNewUser} variant='primary' icon='add'>
            Новый пользователь
        </Button>
    </Box>;


    if (!hasAccessToUsersList) {
        return <Forbidden />;
    }

    return (
        <Wrapper className={classes.tableWrapper} actions={addNewUserAction} isBigHeader>
            <Table
                title={null}
                columns={columnDefinition}
                data={data ?? []}
                visualizationSource={VisualizationSource.clientProfile}
                localization={localization}
                variant='white'
                options={{
                    grouping: false,
                    columnsButton: false,
                    filtering: false,
                    search: false,
                    toolbar: false,
                    paging: 'disabled',
                }}
                fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                onOrderChange={setOrderTransformer(columnDefinition, setOrder)}
                {...orderParams}
            />
            <Pagination {...pagination} count={dataTotal} onChangePage={setPage} isLoading={!data} />

            {mode === UserMode.Create && (
                <Modal title='Новый пользователь' close='Отмена' onHide={onCloseCreateModal}>
                    <UserForm mode={mode} user={editableUser} onSubmit={onSubmit} />
                </Modal>
            )}

            {mode === UserMode.Update && (
                <Modal title='Редактирование пользователя' close='Отмена' onHide={onCloseCreateModal}>
                    <UserForm mode={mode} user={editableUser} onSubmit={onSubmit} />
                </Modal>
            )}
            {isDeleteUserModalAvailable && (
                <ActionModal
                    title='Удаление пользователя'
                    description={`Вы действительно хотите удалить пользователя ${activeUser.userName}`}
                    actions={[
                        {
                            onClick: () => onDelete(activeUser),
                            title: 'Да, удалить',
                            variant: 'primary',
                        },
                        {
                            onClick: onCloseDeleteModal,
                            title: 'Отмена',
                            variant: 'secondary',
                        }
                    ]}
                    closeModal={onCloseDeleteModal}
                />
            )}
        </Wrapper>
    );

};
