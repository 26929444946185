/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Alignment, Cell, Row, Workbook, Worksheet } from 'exceljs';
import * as _ from 'lodash';
import { DateFormats } from '../../../../common/constants/date';
import { meterPowerSwitchOperationTypeDictionary } from '../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchOperationType';
import { meterPowerSwitchOrderStatusDictionary } from '../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchOrderStatus';
import { MeterPowerSwitchRequestsParams } from '../../../../common/model/meter/meterPowerSwitch/meterPowerSwitchRequestView';
import { MeterPowerSwitchOrderShortViewModel } from '../../../../common/model/meter/meterPowerSwitchOrder/MeterPowerSwitchOrderCommonModel';
import { meterRelayStatusDictionary } from '../../../../common/model/meter/relayStatus';
import { VAutocompleteOption } from '../../../shared/components/autocomplete/model';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { formatDate } from '../../../shared/utils/dates';
import { downloadExcelData } from '../../../shared/utils/download';
import { addBordersToCell, addRowSpaceToWorksheet, excelBaseFontName } from '../../reports/excelHelpers';

const columnWidths: Record<number, number> = {
    1: 10,
    2: 27,
    3: 27,
    4: 36,
    5: 21,
    6: 24,
    7: 24,
    8: 24,
    9: 30,
    10: 34,
};

const columnsDataAlignment: Record<number, Alignment['horizontal']> = {
    1: 'left',
    2: 'center',
    3: 'center',
    4: 'center',
    5: 'center',
    6: 'center',
    7: 'center',
    8: 'center',
    9: 'center',
    10: 'center',
};

export interface MakeExcelReportConfig {
    response: MeterPowerSwitchOrderShortViewModel[];
    date: Date;
    filename: string;
    filter: MeterPowerSwitchRequestsParams;
    regionSuggestions: SelectSuggestion[];
    metersSuggestions: VAutocompleteOption[];
}

const makeColumns = (worksheet: Worksheet) => {
    for (const col in columnWidths) {
        worksheet.getColumn(+col).width = +columnWidths[col];
    }
};

const dataToTableCells = (item: MeterPowerSwitchOrderShortViewModel): string[] => {
    return [
        '',
        item.shortName ?? '–',
        item.meterSerialNum ?? '–',
        item.status ? meterPowerSwitchOrderStatusDictionary[item.status] : '–',
        item.operationTime ? formatDate(item.operationTime, DateFormats.dateTimeFullWithTimeZone) : '–',
        item.userName ?? '–',
        item.creationDate ? formatDate(item.creationDate, DateFormats.dateTimeFullWithTimeZone) : '–',
        item.cumulativeActiveEnergyImp?.toString() ?? '–',
        item.relayStatusBefore !== null ? meterRelayStatusDictionary[item.relayStatusBefore] : '–',
        item.relayStatusAfter !== null ? meterRelayStatusDictionary[item.relayStatusAfter] : '–',
    ];
};


const makeMainTitle = (worksheet: Worksheet, title: string) => {
    worksheet.addRow([title]);
    worksheet.getCell('A1').style.font = {
        size: 12,
        name: excelBaseFontName,
        bold: true,
    };
    worksheet.mergeCells('A1:E1');
};

const makeAnnotationRow = (worksheet: Worksheet, title: string, renderValue: (row: Row, valueCell: Cell) => void) => {
    const row = addRowSpaceToWorksheet(worksheet);
    const titleCellStart = row.getCell(1);
    titleCellStart.value = title;
    titleCellStart.style.font = {
        bold: true,
        size: 11,
        name: excelBaseFontName,
    };
    const titleCellEnd = row.getCell(2);
    worksheet.mergeCells(`${titleCellStart.address}:${titleCellEnd.address}`);

    const valueCell = row.getCell(3);
    valueCell.style.font = {
        size: 11,
        name: excelBaseFontName,
    };
    renderValue(row, valueCell);
};

const makeProfilePage = (workbook: Workbook, config: MakeExcelReportConfig): void => {
    const { response, date, filter, metersSuggestions, regionSuggestions } = config;
    const { regionId, status, dateTo, dateFrom, meterId } = filter;
    const data = response;
    const worksheet = workbook.addWorksheet('Главная');

    const timeZone = formatDate(date, DateFormats.dateOnlyTimeZone);

    makeColumns(worksheet);

    makeMainTitle(worksheet, 'Заявки на ограничение');

    makeAnnotationRow(worksheet, 'Дата формирования отчета:', (row, cell) => {
        cell.value = `${formatDate(date, DateFormats.dateTimeFormat)} (${timeZone})`;
    });

    if (regionId) {
        const region = regionSuggestions.find(item => item.value === regionId);
        makeAnnotationRow(worksheet, 'Регион:', (row, cell) => {
            cell.value = region?.label ?? regionId;
        });
    }

    if (dateFrom) {
        makeAnnotationRow(worksheet, 'Период:', (row, cell) => {
            cell.value = formatDate(dateFrom, DateFormats.dateTimeFullWithTimeZone);
        });
    }

    if (dateTo) {
        makeAnnotationRow(worksheet, 'Период:', (row, cell) => {
            cell.value = formatDate(dateTo, DateFormats.dateTimeFullWithTimeZone);
        });
    }

    if (status) {
        makeAnnotationRow(worksheet, 'Статус:', (row, cell) => {
            cell.value = meterPowerSwitchOrderStatusDictionary[status];
        });
    }

    if (meterId) {
        const meter = metersSuggestions.find(item => item.value === meterId);
        makeAnnotationRow(worksheet, 'Номер ПУ:', (row, cell) => {
            cell.value = meter?.label ?? meterId;
        });
    }

    addRowSpaceToWorksheet(worksheet);

    const excelMainTableTitles: string[] = [
        'Группа',
        'Тип ПУ',
        'Заводской номер',
        'Статус обработки',
        'Дата переключения реле',
        'Пользователь / внешняя система',
        'Дата создания заявки',
        'Текущие показания',
        'Состояние реле до операции',
        'Состояние реле после операции',
    ];

    const titlesRow = addRowSpaceToWorksheet(worksheet);
    for (let i = 1; i <= excelMainTableTitles.length; i++) {
        const title = excelMainTableTitles[i - 1];
        const cell = titlesRow.getCell(i);
        cell.value = title;
        cell.style.font = {
            bold: true,
            name: excelBaseFontName,
            size: 11,
        };
        cell.style.alignment = {
            wrapText: true,
            horizontal: 'center',
        };
        addBordersToCell(cell);
    }

    const groupedData = _.groupBy(data, 'requestId');

    for (const groupedDataKey in groupedData) {
        const groupedDataList = groupedData[groupedDataKey];

        const item = groupedDataList[0];

        const operationTypeString = meterPowerSwitchOperationTypeDictionary[item.operationType];
        const stateString = meterPowerSwitchOrderStatusDictionary[item.status];
        const requestName = `№${item.requestId}.${operationTypeString}.Состояние:${stateString}`;

        const rowGroup = worksheet.addRow([requestName]);
        const titleCellStart = rowGroup.getCell(1);
        const titleCellEnd = rowGroup.getCell(10);
        worksheet.mergeCells(`${titleCellStart.address}:${titleCellEnd.address}`);
        rowGroup.eachCell((cell) => {
            cell.style.font = {
                size: 10,
                name: excelBaseFontName,
                bold: true,
            };
            cell.style.alignment = {
                horizontal: 'left',
            };
            addBordersToCell(cell);
        });

        for (const mainTableDatum of groupedDataList) {
            const row = worksheet.addRow(dataToTableCells(mainTableDatum));
            row.eachCell((cell, index) => {
                cell.style.font = {
                    size: 10,
                    name: excelBaseFontName,
                };
                cell.style.alignment = {
                    horizontal: columnsDataAlignment[index] ?? 'left',
                };
                addBordersToCell(cell);
            });
        }
    }
};

export const makeExcelReport = async (config: MakeExcelReportConfig) => {
    const workbook = new Workbook();
    makeProfilePage(workbook, config);
    const buffer = await workbook.xlsx.writeBuffer() as unknown as Buffer;
    const excelData = buffer.toString('base64');

    downloadExcelData(
        excelData,
        config.filename,
    );
};

/* eslint-enable @typescript-eslint/no-magic-numbers */
