import { Period } from '../components/timeWindow/model';

const BinaryBase = 2;

const time = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
    '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];

export const intToBin = (num: number): string => {
    return num?.toString(BinaryBase);
};

export const fromBinToNum = (num: string): number => {
    return parseInt(num, BinaryBase);
};

export const binToPeriods = (entryTimeBinary: string[]): Period[] => {
    let lastTimeElement = '';
    const preparedPeriod: Period[] = [];
    const currentPeriod: Period = {};

    entryTimeBinary.forEach((timeElement, index) => {
        if ((lastTimeElement === '0' || index === 0) && timeElement === '1') {
            currentPeriod.startTime = time[index];
            currentPeriod.endTime = null;
        }
        if (currentPeriod.startTime && !currentPeriod.endTime && (timeElement === '0' || index === (entryTimeBinary.length - 1))) {
            currentPeriod.endTime = time[index];
            if (timeElement === '1' && index === (entryTimeBinary.length - 1)) {
                currentPeriod.endTime = time[0];
            }
            preparedPeriod.push({ ...currentPeriod, id: index });
        }
        lastTimeElement = timeElement;
    });


    return preparedPeriod;
};

const isHourInOpenPeriod = (hour: string, periods: Period[]) => {
    return periods.find(item =>
        item.startTime <= hour && (hour < item.endTime || item.endTime === '00:00')) ? '1' : '0';
};

export const shiftValues = (arr: string[], offset = 0) => {
    // eslint-disable-next-line no-mixed-operators
    const pivot = (offset < 0 ? 0 : arr.length) - offset % arr.length;
    return arr.slice(pivot).concat(arr.slice(0, pivot));
};

export const periodsToBin = (periods: Period[], shift: number): number => {
    const bin = time.map(hour => isHourInOpenPeriod(hour, periods)).slice().reverse();
    const shiftBin = shiftValues(bin, shift);
    return fromBinToNum(shiftBin.join(''));
};
