import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.hint,
        marginLeft: '16px',
        paddingTop: '4px',
    },
    configurationHistoryTable: {
        '& .custom-table table .MuiTableCell-head': {
            color: '#5C6C7F',
            fontWeight: 'normal',
            lineHeight: '20px',
            height: '48px',
            fontSize: '14px',
        },
    },
    actions: {
        height: 72,
        display: 'flex',
        alignItems: 'center',
    },
}));
