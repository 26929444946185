import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { Button } from '../../shared/components/button';
import { Heading } from '../../shared/components/heading';
import { Table } from '../../shared/components/table';
import { VisualizationSource } from '../../shared/components/table/model';
import { Wrapper } from '../../shared/components/wrapper';
import { defaults, Tables } from '../../shared/constants';
import { setColumnWidth } from '../../shared/pipes/styles';
import { ReportsViewProps } from './model';

const useStyles = makeStyles(() => ({
    consumptionWrapper: {
        display: 'flex',
    },
    tableRow: {
        '&:not(:hover) button': {
            visibility: 'hidden',
        },
    },
    tableCols: {
        '&.col-report-name': {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ...setColumnWidth('400px'),
        },
    },
}));

export const ViewReportRender: React.FC<{ onClick: () => void; disabled: boolean; }> = ({ onClick, disabled }) => {
    return <Button disabled={disabled} onClick={onClick} variant='primary'>Открыть</Button>;
};

export const View = (props: ReportsViewProps) => {
    const { columns, data } = props;
    const classes = useStyles();
    return <>
        <Box mb={2}>
            <Heading heading="Отчётность" />
        </Box>
        <Box py={3}>
            <Wrapper actions={null} className={classes.consumptionWrapper} isHeaderUppercase={false}>
                <Table
                    visualizationSource={VisualizationSource.clientProfile}
                    name={Tables.reportGroupTable}
                    columns={columns}
                    data={data}
                    title={null}
                    variant='white'
                    useQueryString={true}
                    options={{
                        headerClassName: classes.tableCols,
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        search: false,
                        sorting: true,
                        toolbar: false,
                        showTitle: false,
                        rowClassName: classes.tableRow,
                    }}
                    fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                />
            </Wrapper>
        </Box>
    </>;
};
