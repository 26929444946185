import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const hundred = 100;

export const useStyles = makeStyles((theme: Theme) => ({
    itemWrapperHorizontal: ({ count }: { count?: number }) => ({
        maxWidth: `${hundred / (count || 1)}%`,
        flex: `1 1 ${hundred / (count || 1)}%`,
        paddingRight: 14,
        paddingLeft: 14,
        '&:first-child': {
            marginLeft: '-14px',
        },
        '&:last-child': {
            marginRight: '-14px',
        },
    }),
    title: {
        ...theme.typography.captionBold,
        color: theme.palette.text.hint,
        overflow: 'hidden',
        wordWrap: 'break-word',
    },
    item: {
        color: theme.palette.text.primary,
        fontSize: '14px',
        overflow: 'hidden',
        wordWrap: 'break-word',
    },
    itemWithAction: {
        position: 'relative',
        paddingRight: 40,
        '&:not(:hover) $itemAction': {
            opacity: 0,
        },
    },
    itemWithVisibleAction: {
        position: 'relative',
    },
    itemAction: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        top: 'calc(50% - 16px)',
        height: 32,
        width: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: theme.palette.neutral.background,
        },
    },
    itemVisibleAction: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        top: 'calc(50% - 22px)',
        height: 36,
        width: 36,
        display: 'flex',
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: theme.palette.neutral.background,
        },
    },
    itemVisibleActionDisabled: {
        pointerEvents: 'none',
        opacity: '0.4',
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        top: 'calc(50% - 22px)',
        height: 36,
        width: 36,
        display: 'flex',
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: theme.palette.neutral.background,
        },
    },
    statusIcon: {},
    blockItem: {
        textTransform: 'uppercase',
        height: '22px',
        marginLeft: '-5px',
        marginTop: '-1px',
        overflow: 'hidden',
    },
    blockItemText: {
        display: 'inline-grid',
    },
    created: {
        '& $statusIcon': {
            color: theme.palette.text.hint,
        },
    },
    inProgress: {
        '& $statusIcon': {
            color: theme.palette.primary.light,
        },
    },
    success: {
        color: theme.palette.system.success,
    },
    error: {
        color: theme.palette.system.error,
    },
    horizontal: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'start',
        maxWidth: '100%',
        width: '100%',
    },
    vertical: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
    },
    verticalPosition: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
}));
