import React from 'react';
import { HookField } from '../../../shared/components/hookField';
import { Tables } from '../../../shared/constants';
import { setColumnWidth } from '../../../shared/pipes/styles';
import { IStateProps } from './model';
import { Table } from '../../../shared/components/table';
import { Wrapper } from '../../../shared/components/wrapper';
import { FormProvider } from 'react-hook-form';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '../../../shared/components/button';

const useStyles = makeStyles(() => ({
    addButtonWrapper: {
        marginLeft: 'auto',
    },
    tableCols: {
        '&.col-name': {
            ...setColumnWidth('360px'),
        },
        '&.col-inn-code': {
            ...setColumnWidth('200px'),
        },
        '&.col-meters-count': {
            ...setColumnWidth('200px'),
        },
        '&.col-isCrq': {
            ...setColumnWidth('220px'),
            '& span': {
                display: 'flex',
                justifyContent: 'center'
            }
        },
    },
    tableRow: {
        '&:not(:hover) button': {
            visibility: 'hidden',
        },
        '& .checkbox-isCrq': {
            display: 'flex',
            justifyContent: 'center'
        },
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const { data, columns, form, onClickAddPartner } = props;

    const classes = useStyles();

    const actions = (
        <FormProvider {...form}>
            <Grid container spacing={2}>
                <Grid item>
                    <HookField name='name' type='text' placeholder='Название компании' />
                </Grid>
                <Grid item>
                    <HookField name='innCode' type='text' placeholder='ИНН' />
                </Grid>
                <Grid item>
                    <HookField name='isCrq' type='checkbox' label='Получение данных по CRQ' />
                </Grid>
                <Grid className={classes.addButtonWrapper} item>
                    <Button onClick={onClickAddPartner} variant='primary' icon='add'>Новый партнер</Button>
                </Grid>
            </Grid>
        </FormProvider>
    );

    return (
        <Wrapper actions={actions}>
            <Table
                data={data}
                columns={columns}
                title={null}
                name={Tables.partnersTable}
                options={{
                    headerClassName: classes.tableCols,
                    rowClassName: classes.tableRow,
                    grouping: false,
                    filtering: false,
                    search: false,
                    toolbar: false,
                    showTitle: false,
                    paging: 'disabled',
                }}
            />
        </Wrapper>
    );
};
