export enum Tables {
    clientsTable = 'clientsTable',
    translationsTable = 'translationsTable',
    metersTable = 'metersTable',
    meterDailyReadingTable = 'meterDailyReadingTable',
    meterIncrementalReadingTable = 'meterIncrementalReadingTable',
    meterActivationOrderTable = 'meterActivationOrderTable',
    commonEventLogTable = 'commonEventLogTable',
    administrationSchemesTable = 'administrationSchemesTable',
    administrationSettingsTable = 'administrationSettingsTable',
    administrationGroupsTable = 'administrationGroupsTable',
    meterConsumptionElectricityTable = 'meterConsumptionElectricityTable',
    meterConsumptionElectricityTariffTable = 'meterConsumptionElectricityTariffTable',
    reportGroupTable = 'reportGroupTable',
    notConnectedMetersReportTable = 'notConnectedMetersReportTable',
    meterProfileReportTable = 'meterProfileReportTable',
    electricityConsumptionReportTable = 'electricityConsumptionReportTable',
    electricityTariffReportTable = 'electricityTariffReportTable',
    monitoringSurveyConditionTable = 'monitoringSurveyConditionTable',
    monitoringSurveyConditionMetersTable = 'monitoringSurveyConditionMetersTable',
    partnersTable = 'partnersTable',
    auditTable = 'auditTable',
    meterInstantReadingTable = 'meterInstantReadingTable',
    inspectionLogTable = 'inspectionLogTable',
    tariffScheduleOrdersTable = 'tariffScheduleOrdersTable',
    advancedCrashReportTable = 'advancedCrashReportTable',
    massOperationsTable = 'massOperationsTable',
    meterFirmwareVersionsTable = 'meterFirmwareVersionsTable',
    importCrqMeterTable = 'importCrqMeterTable',
    importCrqLogTable = 'importCrqLogTable',
}
