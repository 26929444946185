import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserViewModel } from '../../../../../common/model/access/user/userViewModel';
import { ActionType } from '../../../../actions';

export interface UsersListAccessState {
    list: UserViewModel[];
}

const initialState: UsersListAccessState = {
    list: null,
};

export const usersListAccessSlice = createSlice({
    name: '@@vostok/access/users/list',
    initialState,
    reducers: {
        setUsersList(state, action: PayloadAction<UserViewModel[]>): void {
            state.list = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): UsersListAccessState => initialState,
    }
});
