import { httpService, HttpWrapperOptions } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes';
import { downloadExcelData } from '../../../../app/shared/utils/download';
import { appUrls } from '../../../../common/appUrls';
import { AdvancedCrashReportExcelResponse } from '../../../../common/model/meter/reports/advancedCrashReportExcelResponse';
import { AdvancedCrashReportQuery } from '../../../../common/model/meter/reports/advancedCrashReportQuery';
import { AdvancedCrashReportResponse } from '../../../../common/model/meter/reports/advancedCrashReportResponse';
import { advancedCrashReportSlice } from '../../../reducers/reports/advancedCrashReport';
import { AppThunk } from '../../index';

const waitTimeoutConnection = 600000; // 10 минут

export const getAdvancedCrashReport = (params: AdvancedCrashReportQuery): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_reports_advanced_crash_report, {}, params);
    const data = await httpService.get<AdvancedCrashReportResponse>(
        route,
        new HttpWrapperOptions().Timeout(waitTimeoutConnection)
    );
    dispatch(advancedCrashReportSlice.actions.setReport(data));
};

export const getAdvancedCrashReportExcel = async (params: AdvancedCrashReportQuery) => {
    const route = getApiRoute(appUrls.api_reports_advanced_crash_report_excel, {}, params);
    const { data } = await httpService.get<AdvancedCrashReportExcelResponse>(
        route,
        new HttpWrapperOptions().Timeout(waitTimeoutConnection)
    );
    downloadExcelData(data, 'advancedCrashReport');
};

export const resetAdvancedCrashReport = (): AppThunk => (dispatch) => {
    dispatch(advancedCrashReportSlice.actions.setReport(null));
};
