import { Resolver } from 'react-hook-form/dist/types/resolvers';
import { useCallback } from 'react';
import yup from './yup';

export const useYupValidationResolver = <T>(validationSchema: yup.ObjectSchema<any>): Resolver<T> =>
    useCallback(
        async data => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false
                });

                return {
                    values,
                    errors: {}
                };
            } catch (errors) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? 'validation',
                                message: currentError.message
                            }
                        }),
                        {}
                    )
                };
            }
        },
        [validationSchema]
    );
