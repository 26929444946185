import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextInput } from '../../textInput';
import { IStateProps } from '../model';
import { Checkbox } from '../../checkbox';
import { Typography } from '../../typography';

const useStyles = makeStyles(() => ({
    autocompleteContainer: {},
    input: {
        '& .MuiAutocomplete-inputRoot': {
            padding: '1px 0',
            flexWrap: 'nowrap',
            overflowY: 'hidden',
        },
    },
    tagsContainer: {
        marginLeft: '12px',
    },
}));

export const View = React.forwardRef((props: IStateProps) => {
    const {
        label,
        options,
        required,
        helperText,
        error,
        inputProps,
        onChange,
        value,
        ...otherProps
    } = props;
    const classes = useStyles();

    return (
        <>
            <Autocomplete
                {...otherProps}
                className={classes.autocompleteContainer}
                limitTags={0}
                multiple
                options={options}
                disableCloseOnSelect
                value={value}
                onChange={(e, val) => {
                    onChange(val);
                }}
                getOptionSelected={(opts, v) => opts.value === v.value}
                getOptionLabel={(option) => option.optionsLabel}
                renderOption={(option, { selected }) => (
                    <div {...option}>
                        <Checkbox
                            checked={selected}
                        />
                        {option.optionsLabel}
                    </div>
                )}
                renderInput={(params) => (
                    <TextInput
                        helperText={helperText}
                        error={error}
                        required={required}
                        className={classes.input}
                        inputProps={{ ...params.inputProps, ...inputProps }}
                        label={label}
                        {...params}
                    />
                )
                }
                // ChipProps={{ deleteIcon: <Icon name='close' width='16px' /> }}
                renderTags={() => {
                    if (value.length > 0) {
                        return (
                            <Typography variant='caption' className={classes.tagsContainer}>
                                {`Выбранно ${value.length} элементов`}
                            </Typography>
                        );
                    }
                    return null;
                }}
            />
        </>
    );
});
