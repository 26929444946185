import React from 'react';
import { FormControl, makeStyles, TextareaAutosize } from '@material-ui/core';
import { TextareaProps } from './model';

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'block',
        '& .MuiFormControl-root': {
            display: 'block',
        },
    },
    textarea: {
        overflowY: 'scroll',
        padding: '12px 8px',
        border: `1px solid ${theme.palette.neutral.light}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        backgroundColor: 'inherit',

        fontFamily: 'SF Pro Display',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: theme.palette.text.primary,

        '&:hover': {
            border: `1px solid ${theme.palette.neutral.dark}`,
        },

        '&:focus-visible': {
            border: `1px solid ${theme.palette.primary.light}`,
            outline: 'none',
        },
    },
}));

export const View: React.FC<TextareaProps> = ({
    maxWidth = '150px',
    minWidth = '250px',
    minHeight = '150px',
    maxHeight = '400px',
    label,
    value,
    required,
    ...props
}) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.formControl}>
            {/* TODO: добавить label */}
            {/*<InputLabel>{label}</InputLabel>*/}
            <TextareaAutosize
                {...props}
                placeholder={label}
                required={required}
                style={{
                    maxWidth,
                    minWidth,
                    maxHeight,
                    minHeight,
                }}
                value={value}
                className={classes.textarea}
            />
            {/* TODO: add error */}
        </FormControl>

    );
};
