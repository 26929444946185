import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { MeterLabelTypeViewModel } from '../../../common/model/meter/meterLabelType/meterLabelTypeViewModel';
import { meterLabelTypeListSlice } from '../../reducers/meterLabelType/list';
import { AppThunk } from '../index';

export const getMeterLabelTypes = (): AppThunk => async (dispatch) => {
    const url = getApiRoute(appUrls.api_get_meter_label_types);
    const response: MeterLabelTypeViewModel[] = await httpService.get(url);
    dispatch(meterLabelTypeListSlice.actions.setAvailable(response));
};

export const updateMeterLabelType = (id: number, updater: MeterLabelTypeViewModel): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_put_meter_label_type, { meterLabelTypeId: id });
    const data = await httpService.put<MeterLabelTypeViewModel>(route, updater);
    dispatch(meterLabelTypeListSlice.actions.update(data));
};
