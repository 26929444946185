
import { httpService } from 'app/shared/httpWrapper';
import { getApiRoute } from 'app/shared/pipes';
import { appUrls } from 'common/appUrls';
import { CrqSystemViewModel } from 'common/model/meter/crq/crqSystemViewModel';
import { CrqSystemCreator } from 'common/model/meter/crq/crqSystemCreator';
import { MeterCrqSystemAndChannelsCreator, MeterCrqSystemAndChannels } from 'common/model/meter/crq/meterCrqSystemAndChannels';
import { crqSystemListSlice } from 'redux/reducers/crqSystem/list';
import { AppThunk } from '../index';

export const createCrqSystem = (creator: CrqSystemCreator): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_post_crq_system);
    const crqSystem = await httpService.post<CrqSystemCreator>(route, creator);
    return crqSystem;
};

export const updateCrqSystem = (id: number, updater: CrqSystemCreator): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_put_crq_system, { id });
    const crqSystem = await httpService.put<CrqSystemViewModel>(route, updater);
    return crqSystem;
};

export const deleteCrqSystem = (id: number): AppThunk => async (dispatch) => {
    await httpService.delete<void>(getApiRoute(appUrls.api_delete_crq_system, { id }));
    dispatch(crqSystemListSlice.actions.deleteCrqSystem({ id }));
};

export const saveMeterCrqSystemAndChannels = (
    meterId: string,
    data: MeterCrqSystemAndChannelsCreator
): Promise<MeterCrqSystemAndChannels> => {
    const route = getApiRoute(appUrls.api_create_meter_crq_system_and_channels, { meterId });
    return httpService.post<MeterCrqSystemAndChannels>(route, data);
};

export const getMeterCrqSystemAndChannels = (meterId: string): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_get_meter_crq_system_and_channels, { meterId });
    const data = await httpService.get<MeterCrqSystemAndChannels>(route);
    return data;
};

export * from './editable';
export * from './list';
