import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressViewModel } from '../../../../common/model/meter/addressViewModel/addressViewModel';


export interface AddressSuggestionsState {
    addressSuggestions: AddressViewModel[];
    areaNamesSuggestions: string[];
    townNamesSuggestions: string[];
    streetNamesSuggestions: string[];
    houseNumbersSuggestions: string[];
    isAddressSuggestionsLoading: boolean;
}

const initialState: AddressSuggestionsState = {
    addressSuggestions: [],
    areaNamesSuggestions: [],
    townNamesSuggestions: [],
    streetNamesSuggestions: [],
    houseNumbersSuggestions: [],
    isAddressSuggestionsLoading: false,
};


export const addressSuggestionsSlice = createSlice({
    name: '@@vv/address/suggestions',
    initialState,
    reducers: {
        setAddressSuggestionLoading(state): void {
            state.isAddressSuggestionsLoading = true;
        },
        setAddressSuggestions(state, action: PayloadAction<AddressViewModel[]>): void {
            state.addressSuggestions = action.payload;
            state.isAddressSuggestionsLoading = false;
        },
        setAreaNamesSuggestions(state, action: PayloadAction<string[]>): void {
            state.areaNamesSuggestions = action.payload;
            state.isAddressSuggestionsLoading = false;
        },
        setTownNamesSuggestions(state, action: PayloadAction<string[]>): void {
            state.townNamesSuggestions = action.payload;
            state.isAddressSuggestionsLoading = false;
        },
        setStreetNamesSuggestions(state, action: PayloadAction<string[]>): void {
            state.streetNamesSuggestions = action.payload;
            state.isAddressSuggestionsLoading = false;
        },
        setHouseNumbersSuggestions(state, action: PayloadAction<string[]>): void {
            state.houseNumbersSuggestions = action.payload;
            state.isAddressSuggestionsLoading = false;
        },
    }
});
