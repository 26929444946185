import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AddressViewModel } from '../../../../../../common/model/meter/addressViewModel/addressViewModel';
import { MeterPartnerCreator } from '../../../../../../common/model/meter/meter/meterPartnerCreator';
import { BaseDispatch } from '../../../../../../redux/actions';
import { findAddressSuggestions } from '../../../../../../redux/actions/addresses';
import { createSnackbar, getMeters } from '../../../../../../redux/actions/controls';
import { createPartnerMeter, getPartners, setPartners } from '../../../../../../redux/actions/partners';
import { partnersListSelector } from '../../../../../../redux/selectors/partners';
import { PartnerResolverChildProps } from '../../../../../routing/resolvers/partnerResolver';
import { useAddressAutocomplete } from '../../../../../shared/components/autocomplete/hooks/useAddressAutocomplete';
import { useCreateAddress } from '../../../../../shared/components/createAddress/useCreateAddress';
import { RelatedSystemsTabs, Routes } from '../../../../../shared/constants';
import { useBreadcrumbs } from '../../../../../shared/hooks/useBreadcrumbs';
import { useHeading } from '../../../../../shared/hooks/useHeading';
import { getRoute } from '../../../../../shared/pipes';
import { getErrorMessage } from '../../../../../shared/utils/error';
import { useYupValidationResolver } from '../../../../../shared/utils/validation';
import yup from '../../../../../shared/utils/yup';
import { View } from './view';
import { ReadingSource } from 'common/model/meter/readingSource';


const initialMeterPartnerCreator: MeterPartnerCreator = {
    meterModelId: null,
    meterSerialNum: null,
    addressId: null,
    addressSpecifier: null,
    installationSite: null,
    cTRatio: null,
    pTRatio: null,
    timeZone: null,
    code: null,
    partnerId: null,
    timeInterval: 30,
};

const meterPartnerCreatorValidationScheme = yup.object({
    meterModelId: yup.number().required(),
    meterSerialNum: yup.string().required(),
    readingSource: yup.string().required(),
    addressId: yup.string().required(),
    installationDate: yup.string().required(),
    timeZone: yup.number().required(),
    partnerId: yup.number().required(),
});

export const PartnerMeterForm: React.FC<PartnerResolverChildProps> = (props) => {
    const [createdCrqPartnerMeterId, setCreatedCrqPartnerMeterId] = useState<string | undefined>();
    const dispatch = useDispatch<BaseDispatch>();

    const { partner } = props;

    const partnerRoute = getRoute(Routes.PartnerEdit, { partnerId: partner.id.toString() });

    const partners = useSelector(partnersListSelector());

    const addressAutocomplete = useAddressAutocomplete();

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Смежные системы', link: getRoute(Routes.relatedSystems) },
        { name: 'Партнеры', link: getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.Partners }) },
        { name: partner.name, link: partnerRoute },
        { name: 'Новый ПУ', link: getRoute(Routes.PartnerMetersCreate, { partnerId: partner.id.toString() }) },
    ]);

    const headingProps = useHeading('Новый ПУ партнера', () => {
        dispatch(push(partnerRoute));
    });

    useEffect(() => {
        dispatch(findAddressSuggestions(''));
        dispatch(getPartners({}));
        return () => {
            dispatch(setPartners(null));
        };
    }, []);

    const resolver = useYupValidationResolver<MeterPartnerCreator>(meterPartnerCreatorValidationScheme);

    const form = useForm<MeterPartnerCreator>({
        defaultValues: {
            ...initialMeterPartnerCreator,
            partnerId: partner.id,
        },
        resolver,
    });

    const useCreateAddressProps = useCreateAddress((address: AddressViewModel) => {
        addressAutocomplete.onInputChange(null, address.address, 'input');
        if (address?.id) {
            form.setValue('addressId', address.id);
        }
    });


    return View({
        ...props,
        ...breadcrumbProps,
        ...headingProps,
        ...useCreateAddressProps,
        addressAutocomplete,
        form,
        partners,
        partnerId: partner?.id.toString(),
        createdCrqPartnerMeterId,
        onSubmit: async (values) => {
            try {
                const partnerMeter = await createPartnerMeter(partner.id, values);

                dispatch(createSnackbar({
                    type: 'green',
                    delay: 5000,
                    message: 'Прибор учета добавлен успешно',
                }));

                if (partnerMeter.readingSource === ReadingSource.ImportCrq) {
                    setCreatedCrqPartnerMeterId(partnerMeter.id);
                    return;
                }

                dispatch(push(partnerRoute));
                dispatch(getMeters());
            } catch (e) {
                dispatch(createSnackbar({
                    type: 'red',
                    delay: 5000,
                    message: getErrorMessage(e),
                }));
            }
        },
    });

};
