import React from 'react';
import { CreateMeterGroup } from './model';
import { Box } from '@material-ui/core';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Heading } from '../../../../shared/components/heading';
import { Wrapper } from 'app/shared/components/wrapper';
import { MeterGroupForm } from '../form';

export const View = (props: CreateMeterGroup) => {
    const {
        breadcrumbs,
        onClickBreadcrumb,
        onCancel,
        initialValues,
        onSubmitGroup,
        form
    } = props;
    return (
        <>
            <Box mb={1}>
                <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
            </Box>
            <Box mb={2}>
                <Heading heading='Новая группа' onBackHandler={onCancel} />
            </Box>
            <Wrapper title='Общие параметры'>
                <Box display='flex' alignItems='center'>
                    <MeterGroupForm
                        initialValues={initialValues}
                        onSubmitGroup={onSubmitGroup}
                        form={form}
                    />
                </Box>
            </Wrapper>
        </>
    );
};
