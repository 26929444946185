import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccessRule } from '../../../../../common/model/access/accessRule';
import { MeterTransformationRatiosUpdater } from '../../../../../common/model/meter/meter/meterTransformationRatiosUpdater';
import { BaseDispatch } from '../../../../../redux/actions';
import { updateMeterTransformationRatios } from '../../../../../redux/actions/meterUpdateAttributes';
import { meterSelector } from '../../../../../redux/selectors/meters';
import { useAccessRules } from '../../../../shared/hooks/useAccessRules';
import { useQueryParams } from '../../../../shared/hooks/useQueryParams';
import { useYupValidationResolver } from '../../../../shared/utils/validation';
import yup from '../../../../shared/utils/yup';
import { actionQueryParam } from '../model';
import { StateProps } from './model';
import { View } from './view';


const transformationRatiosValidationScheme = yup.object({
    cTRatio: yup.string().notRequired(),
    pTRatio: yup.string().notRequired(),
});

export const TransformationRatiosUpdateForm = () => {

    const { meterId } = useParams<{ meterId: string }>();
    const queryParams = useQueryParams();
    const meter = useSelector(meterSelector);

    const dispatch = useDispatch<BaseDispatch>();

    const { hasAccess } = useAccessRules();

    const defaultValues: MeterTransformationRatiosUpdater = {
        cTRatio: meter.cTRatio ?? '',
        pTRatio: meter.pTRatio ?? '',
    };

    const onCloseModal = () => {
        queryParams.delete(actionQueryParam);
        dispatch(push({
            search: queryParams.toString()
        }));
    };

    const onSubmit: SubmitHandler<MeterTransformationRatiosUpdater> = async (values) => {
        await dispatch(updateMeterTransformationRatios(meterId, values));
        onCloseModal();
    };

    const resolver = useYupValidationResolver<MeterTransformationRatiosUpdater>(transformationRatiosValidationScheme);

    const form = useForm<MeterTransformationRatiosUpdater>({
        defaultValues,
        resolver,
    });

    const props: StateProps = {
        form,
        onCancel: onCloseModal,
        onSubmit,
        hasAccess: hasAccess(AccessRule.CanUpdateMeterTransformationRatios),
    };

    return View(props);
};
