import classNames from 'classnames';
import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';

import { Typography } from '../typography';
import { IStateProps } from './model';

const useStyles = makeStyles((theme) => ({
    tooltip: ({ isLight, minWidth }: { isLight?: boolean, minWidth: string }) => ({
        padding: '4px 12px',
        borderRadius: '4px',
        backgroundColor: isLight ? theme.palette.neutral.white : theme.palette.neutral.main,
        color: isLight ? theme.palette.text.primary : theme.palette.neutral.white,
        boxShadow: isLight ? '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 2px 24px rgba(0, 0, 0, 0.12)' : 'none',

        minWidth: minWidth || 'none',

        '& .MuiTooltip-arrow': {
            color: isLight ? theme.palette.neutral.white : theme.palette.neutral.main,
        },
    }),
    tooltipContentWrapper: {
        display: 'inline-flex',
    },
    overflowWrapper: {
        overflow: 'hidden',
    }
}));


export const View = ({
    title,
    text,
    children,
    overflowHidden,
    placement = 'bottom',
    arrow = false,
    isLight = false,
    minWidth = null,
}: IStateProps) => {

    const classes = useStyles({ isLight, minWidth });

    const tooltipContent = <>
        {
            Boolean(title) && <Typography variant='captionBold'>{title}</Typography>
        }
        <Typography variant='caption'>{text}</Typography>
    </>;

    return (
        <Tooltip
            title={tooltipContent}
            classes={{ tooltip: classes.tooltip }}
            placement={placement}
            arrow={arrow}
        >
            <span className={classNames(classes.tooltipContentWrapper, { [classes.overflowWrapper]: overflowHidden })}>
                {children}
            </span>
        </Tooltip>
    );
};
