import { Column } from '@softmedialab/materialui-table';
import { useSyncQueryParams } from 'app/shared/hooks/useQueryParams';
import { MeterGisTasksStatusNames } from 'common/model/enum/meterGisTasks';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StorageState } from 'redux/reducers';
import { TextElipsis } from '../../../../../shared/components/textElipsis';
import { Routes } from '../../../../../shared/constants';
import { getRoute } from '../../../../../shared/pipes';
import { MeterHealthQueryParams } from '../../queryParams';
import { MeterWithErrorsItem } from './model';

import { View } from './view';
import { usePagination } from '../../../../../shared/components/pagination';
import { PaginationParams } from '../../../../../shared/hooks/useActualPage';
import { ISort } from '../../../../../../common/sort';
import { BaseDispatch } from '../../../../../../redux/actions';
import { setMetersWithErrors } from '../../../../../../redux/actions/monitoring/metersHealth';

const defaultPagination: PaginationParams = {
    page: 1,
    rowsPerPage: 10,
    orderBy: 'created_at',
    orderDirection: 'asc',
};

export const MetersWithErrors = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const metersWithErrors = useSelector((state: StorageState) => state.monitoring.metersHealth.metersWithErrors);
    const meterLabelTypes = useSelector((state: StorageState) => state.meterLabelType.list.available);

    const [filterParams, updateFilterParams] = useSyncQueryParams<MeterHealthQueryParams>({
        regionId: null,
        readingSource: null,
        metersHealthActiveTypeId: null,
        metersHealthActiveCategoryId: null,
    });

    const { metersHealthActiveCategoryId, metersHealthActiveTypeId } = filterParams;
    const onSliderClick = (id: number) => {
        updateFilterParams({
            metersHealthActiveTypeId: id
        });
    };

    const fetch = async (sort: ISort) => {
        setIsLoading(true);
        if (
            filterParams.regionId &&
            filterParams.metersHealthActiveCategoryId &&
            filterParams.metersHealthActiveTypeId
        ) {
            await dispatch(setMetersWithErrors(
                filterParams.regionId,
                filterParams.metersHealthActiveCategoryId,
                filterParams.readingSource,
                filterParams.metersHealthActiveTypeId,
                sort
            ));
        }
        setIsLoading(false);
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination(defaultPagination, fetch);


    useEffect(() => {
        onFetch(pagination, filterParams);
    }, [
        filterParams.regionId,
        filterParams.metersHealthActiveCategoryId,
        filterParams.readingSource,
        filterParams.metersHealthActiveTypeId
    ]);

    const [prevId, nextId] = useMemo(() => {
        if (!metersHealthActiveCategoryId || !metersHealthActiveTypeId) {
            return [null, null];
        }

        const filteredMeterLabelTypes = meterLabelTypes
            .filter(item => item.meterLabelCategoryId === metersHealthActiveCategoryId);

        if (!filteredMeterLabelTypes.find(x => x.id === metersHealthActiveTypeId)) {
            return [null, null];
        }

        // const categoryInfo = metersWithErrors.data.find(data => data.categoryId === metersHealthActiveCategoryId);
        // if (!categoryInfo) {
        //     return [null, null];
        // }

        const index = filteredMeterLabelTypes.findIndex(item => item.id === metersHealthActiveTypeId);
        const prevId = index === 0
            ? null
            : filteredMeterLabelTypes[index - 1]?.id;
        const nextId = index === meterLabelTypes.length - 1
            ? null
            : filteredMeterLabelTypes[index + 1]?.id;

        return [prevId, nextId];
    }, [metersHealthActiveCategoryId, metersHealthActiveTypeId, metersWithErrors]);

    const title = useMemo(() => {
        if (!metersHealthActiveCategoryId || !metersHealthActiveTypeId) {
            return null;
        }

        const filteredMeterLabelTypes = meterLabelTypes
            .filter(item => item.meterLabelCategoryId === metersHealthActiveCategoryId);

        if (!filteredMeterLabelTypes.find(x => x.id === metersHealthActiveTypeId)) {
            return null;
        }

        const title = meterLabelTypes?.find(item => item.id === metersHealthActiveTypeId)?.taskTitle;
        return title;
    }, [metersHealthActiveCategoryId, metersHealthActiveTypeId, metersWithErrors]);

    const data: MeterWithErrorsItem[] = useMemo(() => {
        if (!metersHealthActiveCategoryId || !metersHealthActiveTypeId) {
            return [];
        }
        const dataToMap = metersWithErrors.data.find(data => data.categoryId === metersHealthActiveCategoryId);

        if (!dataToMap) {
            return [];
        }

        const metersInfo = metersWithErrors.data.filter(item => item.meterLabelTypeId === metersHealthActiveTypeId);

        return metersInfo.map(info => {
            return {
                meterId: info.meterId,
                meterTypeName: info.meterTypeName,
                meterSerialNumber: info.meterSerialNum,
                meterAddress: info.meterAddress,
                incidentId: info.meterLabelId,
                gisIncidentId: info.meterGisTaskId,
                meterGisTaskNumber: info.meterGisTaskNumber,
                incidentStatus: info.meterGisTaskStatus ? MeterGisTasksStatusNames[info.meterGisTaskStatus] : '',
                incidentDescription: info.meterLabelDescription,

            };
        });
    }, [metersHealthActiveCategoryId, metersHealthActiveTypeId, metersWithErrors, meterLabelTypes]);

    const columns: Column[] = [
        {
            title: 'Тип ПУ',
            field: 'meterTypeName',
            cellClassName: 'cell-meterTypeName',
            sorting: false,
            render: (rowData: MeterWithErrorsItem) => {
                return <TextElipsis text={rowData.meterTypeName} lines={1}>{rowData.meterTypeName}</TextElipsis>;
            },
        },
        {
            title: 'Номер ПУ',
            field: 'meterSerialNumber',
            cellClassName: 'cell-meterSerialNumber',
            sorting: false,
            render: (rowData: MeterWithErrorsItem) => {
                const route = getRoute(Routes.meter, { meterId: rowData.meterId });
                return <Link className='link' to={route}>
                    <TextElipsis text={rowData.meterSerialNumber} lines={1}>
                        {rowData.meterSerialNumber}
                    </TextElipsis>{' '}&gt;
                </Link>;
            },
        },
        {
            title: 'Адрес',
            field: 'meterAddress',
            cellClassName: 'cell-meterAddress',
            sorting: false,
            render: (rowData: MeterWithErrorsItem) => {
                return <TextElipsis text={rowData.meterAddress} lines={2}>{rowData.meterAddress}</TextElipsis>;
            },
        },
        {
            title: 'Инцидент',
            field: 'meterGisTaskNumber',
            cellClassName: 'cell-meterGisTaskNumber',
            render: (rowData: MeterWithErrorsItem) => {
                if (!rowData.meterGisTaskNumber) {
                    return null;
                }

                const route = getRoute(Routes.meterGisTaskEdit, { taskId: rowData.gisIncidentId });

                return <Link className='link' to={route}>
                    <TextElipsis text={rowData.meterGisTaskNumber?.toString()} lines={1}>
                        {rowData.meterGisTaskNumber}
                    </TextElipsis>{' '}&gt;
                </Link>;
            },
            sorting: false,
        },
        {
            title: 'Статус инцидента',
            field: 'incidentStatus',
            cellClassName: 'cell-incidentStatus',
            sorting: false,
            render: (rowData: MeterWithErrorsItem) => {
                return <TextElipsis text={rowData.incidentStatus} lines={1}>{rowData.incidentStatus}</TextElipsis>;
            },
        },
        {
            title: 'Описание',
            field: 'incidentDescription',
            cellClassName: 'cell-incidentDescription',
            sorting: false,
            render: (rowData: MeterWithErrorsItem) => {
                return <TextElipsis text={rowData.incidentDescription} lines={2}>{rowData.incidentDescription}</TextElipsis>;
            },
        },
    ];

    return View({
        columns,
        nextId,
        prevId,
        metersHealthActiveTypeId,
        metersHealthActiveCategoryId,
        title,
        onSliderClick,
        data,
        pagination,
        dataTotal: metersWithErrors?.total,
        setPage,
        setOrder,
        orderParams,
        isLoading
    });
};
