import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import {
    MeterActionPlanStatus,
    meterActionPlanStatusDictionary
} from '../../../../common/model/meter/meterActionPlanStatus';
import {
    TariffScheduleOrderViewModel
} from '../../../../common/model/meter/tariffSchedule/tariffScheduleOrderViewModel';

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.body,
    },
    planned: {
        color: theme.palette.text.secondary,
    },
    disabled: {
        color: theme.palette.system.error,
    },
    inProgress: {
        color: theme.palette.system.success,
    },
}));

interface Props {
    order: TariffScheduleOrderViewModel;
}

export const TariffScheduleOrderPlanStatus: React.FC<Props> = ({ order }) => {
    const { planStatus } = order;
    const classes = useStyles();
    return (
        <span
            className={classNames(
                classes.root,
                {
                    [classes.planned]: planStatus === MeterActionPlanStatus.Planned,
                    [classes.disabled]: planStatus === MeterActionPlanStatus.Disabled,
                    [classes.inProgress]: planStatus === MeterActionPlanStatus.InProgress,
                }
            )}
        >
            {meterActionPlanStatusDictionary[planStatus]}
        </span>
    );
};
