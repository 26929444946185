/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Workbook, Worksheet } from 'exceljs';
import { DateFormats } from '../../../../../common/constants/date';
import {
    NotConnectedMetersReportResponse,
} from '../../../../../common/model/meter/reports/notConnectedMeters';
import { formatDate } from '../../../../shared/utils/dates';
import { downloadExcelData } from '../../../../shared/utils/download';
import { excelBaseFontName, addBordersToCell, addRowSpaceToWorksheet } from '../../excelHelpers';
import { NotConnectedMetersReportTableData } from './model';
import {
    transformNotConnectedMetersReportDataItemToTableData,
    transformNotConnectedMetersReportResponseToTableDataSummary,
    transformNotConnectedMetersReportResponseToTableDataSummaryByMonths
} from './transformer';

const dataToTableCells = (mainTableDatum: NotConnectedMetersReportTableData): string[] => {
    return [
        mainTableDatum.rowNumber,
        mainTableDatum.city,
        mainTableDatum.address,
        mainTableDatum.meterSerialNumber,
        mainTableDatum.readingDate,
        mainTableDatum.cumulativeActiveEnergyImp,
    ];
};

const excelMainTableTitles = [
    '№ пп',
    'Район, город',
    'Наименование точки учета',
    'Зав. номер счетчика',
    'Дата последнего считывания',
    'Значение энергии, кВт*час',
    'Дата последнего обхода',
    'Примечание',
];

const columnWidths: Record<number, number> = {
    1: 8,
    2: 23,
    3: 63,
    4: 14,
    5: 17,
    6: 17,
    7: 14,
    8: 45,
};

export interface MakeExcelReportConfig {
    regionTitle: string;
    daysOffline: number;
    response: NotConnectedMetersReportResponse;
    reportDate: Date;
    filename: string;
}

const makeMainTable = (worksheet: Worksheet, mainTableData: NotConnectedMetersReportTableData[]) => {
    for (const mainTableDatum of mainTableData) {
        const datumRow = dataToTableCells(mainTableDatum);
        const row = worksheet.addRow(datumRow);
        const centeredIndexes = [1, 4];
        for (let i = 1; i <= excelMainTableTitles.length; i++) {
            const cell = row.getCell(i);
            cell.style.font = {
                name: excelBaseFontName,
                size: 10,
            };
            cell.style.alignment = {
                horizontal: centeredIndexes.includes(i) ? 'center' : 'left',
            };
            addBordersToCell(cell);
        }
    }
};

const makeColumns = (worksheet: Worksheet) => {
    for (const col in columnWidths) {
        worksheet.getColumn(+col).width = +columnWidths[col];
    }
};

const makeMainTableHeadings = (worksheet: Worksheet) => {
    const titlesRow = worksheet.addRow(excelMainTableTitles);
    for (let i = 1; i <= excelMainTableTitles.length; i++) {
        const cell = titlesRow.getCell(i);
        cell.style.font = {
            name: excelBaseFontName,
            size: 10,
        };
        cell.style.alignment = {
            wrapText: true,
        };
        addBordersToCell(cell);
    }
};

const makeMainTitle = (worksheet: Worksheet, title: string) => {
    worksheet.addRow([title]);
    worksheet.getCell('A1').style.font = {
        size: 16,
        name: excelBaseFontName,
        bold: true,
    };
    worksheet.getCell('A1').style.alignment = {
        horizontal: 'center',
    };
    worksheet.mergeCells('A1:H1');
};

export const makeNonConnectedMetersReportSheet = (workbook: Workbook, config: MakeExcelReportConfig): void => {
    const { regionTitle, daysOffline, response, reportDate } = config;
    const notConnectedData = response.data.filter(item => item.outdated);
    // заголовок отчета
    const worksheetTitle = `Перечень ПУ "${regionTitle}", с которыми нет связи больше ${daysOffline} суток`;
    const worksheetHeader = 'Нет связи';
    const worksheet = workbook.addWorksheet(worksheetHeader);
    makeMainTitle(worksheet, worksheetTitle);
    // дата создания отчета
    const createdDateTableData = [
        '',
        '',
        '',
        '',
        'сформирован:',
        formatDate(reportDate, DateFormats.dateFormat),
        formatDate(reportDate, DateFormats.timeFormat),
        '',
    ];
    const createdDateRow = worksheet.addRow(createdDateTableData);
    createdDateRow.eachCell((cell, index) => {
        cell.style.font = {
            name: excelBaseFontName,
            size: 10,
        };
        if (index === 6) {
            cell.style.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CCCCCC' },
            };
        }
    });
    makeColumns(worksheet);
    // заголовки таблицы
    makeMainTableHeadings(worksheet);

    // основная таблица
    const mainTableData = transformNotConnectedMetersReportDataItemToTableData(notConnectedData);
    for (const mainTableDatum of mainTableData) {
        const datumRow = dataToTableCells(mainTableDatum);
        const row = worksheet.addRow(datumRow);
        const centeredIndexes = [1, 4];
        for (let i = 1; i <= excelMainTableTitles.length; i++) {
            const cell = row.getCell(i);
            cell.style.font = {
                name: excelBaseFontName,
                size: 10,
            };
            cell.style.alignment = {
                horizontal: centeredIndexes.includes(i) ? 'center' : 'left',
            };
            addBordersToCell(cell);
        }
    }

    // space
    addRowSpaceToWorksheet(worksheet);


    // summary data
    const summaryData = transformNotConnectedMetersReportResponseToTableDataSummary(response, daysOffline);
    for (const summaryDatum of summaryData) {
        const datumRow = dataToTableCells(summaryDatum);
        const row = worksheet.addRow(datumRow);
        row.eachCell((cell, index) => {
            cell.style.font = {
                name: excelBaseFontName,
                size: 8,
            };
            cell.alignment = {
                horizontal: index === 3 ? 'center' : 'right',
            };
        });
    }

    // space
    addRowSpaceToWorksheet(worksheet);

    // by month data
    const byMonthSummaryData = transformNotConnectedMetersReportResponseToTableDataSummaryByMonths(response);
    for (const summaryDatum of byMonthSummaryData) {
        const datumRow = dataToTableCells(summaryDatum);
        const row = worksheet.addRow(datumRow);
        row.eachCell((cell, index) => {
            cell.style.font = {
                name: excelBaseFontName,
                size: 8,
            };
            cell.alignment = {
                horizontal: 'right',
            };
            if (index === 4 && +cell.value > 0) {
                const amountPosition = +cell.value / response.notConnectedLastTime;
                cell.fill = {
                    type: 'gradient',
                    gradient: 'angle',
                    degree: 0,
                    stops: [
                        { position: 0, color: { argb: 'FF00ff00' } },
                        { position: amountPosition, color: { argb: '00FFFFFF' } },
                        { position: 1, color: { argb: '00FFFFFF' } }
                    ]
                };
            }
        });
    }
};


export const makeNonConnectedMetersReportSheetAll = (workbook: Workbook, config: MakeExcelReportConfig): void => {
    const { regionTitle, response } = config;
    // title
    const worksheetTitle = `Перечень всех ИПУ "${regionTitle}"`;
    const worksheetHeader = 'Всего объектов';
    const worksheet = workbook.addWorksheet(worksheetHeader);
    makeMainTitle(worksheet, worksheetTitle);
    // space
    addRowSpaceToWorksheet(worksheet);
    makeColumns(worksheet);
    makeMainTableHeadings(worksheet);
    // main table data
    const mainTableData = transformNotConnectedMetersReportDataItemToTableData(response.data);
    makeMainTable(worksheet, mainTableData);
};

export const makeExcelReport = async (config: MakeExcelReportConfig): Promise<void> => {
    const workbook = new Workbook();
    makeNonConnectedMetersReportSheet(workbook, config);
    makeNonConnectedMetersReportSheetAll(workbook, config);

    const buffer = await workbook.xlsx.writeBuffer() as unknown as Buffer;
    const excelData = buffer.toString('base64');

    downloadExcelData(
        excelData,
        config.filename,
    );
};
/* eslint-enable @typescript-eslint/no-magic-numbers */
