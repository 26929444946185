import { makeStyles } from '@material-ui/core';
import React from 'react';
import { InspectionSeverityType } from '../../../../../common/model/inspectionLog/SeverityType';
import { IconType } from '../../../../shared/components/icon/model';
import { Icon } from '../../../../shared/components/icon';
import { Tooltip } from '../../../../shared/components/tooltip';

interface Props {
    severity: InspectionSeverityType;
    tooltip: string;
}

const iconBySeverity: Record<InspectionSeverityType, IconType> = {
    [InspectionSeverityType.Error]: 'errorOutline',
    [InspectionSeverityType.Warning]: 'warningTriangle',
    [InspectionSeverityType.Info]: 'infoOutline',
    [InspectionSeverityType.Dump]: 'infoOutline',
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    }
}));

export const SeverityColumn: React.FC<Props> = ({ severity, tooltip }) => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <Tooltip text={tooltip}>
                <Icon width={20} height={20} name={iconBySeverity[severity] ?? 'alert'} />
            </Tooltip>
        </div>
    );
};
