// Состояние ПУ

export enum MeterStatus {
    Created = 'Created',
    Active = 'Active',
    Defective = 'Defective',
    Decommissioned = 'Decommissioned',
    ActivationError = 'ActivationError',
}

export const meterStatusDictionary: Record<MeterStatus, string> = {
    [MeterStatus.Created]: 'Создан',
    [MeterStatus.Active]: 'Активен',
    [MeterStatus.Defective]: 'Неисправен',
    [MeterStatus.Decommissioned]: 'Выведен из эксплуатации',
    [MeterStatus.ActivationError]: 'Ошибка ввода в эксплуатацию',
};
