import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InspectionLogEntryViewModel } from '../../../../common/model/inspectionLog/inspectionLogEntryViewModel/inspectionLogEntryViewModel';
import { InspectionLogViewResponse } from '../../../../common/model/inspectionLog/inspectionLogViewResponse';


export interface CommonInspectionLogState {
    available: InspectionLogEntryViewModel[];
    loading: boolean;
    total: number;
    isFilterVisible: boolean;
}


const initialState: CommonInspectionLogState = {
    available: null,
    loading: false,
    total: 0,
    isFilterVisible: false,
};

export const commonInspectionLogSlice = createSlice({
    name: '@@vv/inspectionLogs/common',
    initialState,
    reducers: {
        setInspectionLogs(state, action: PayloadAction<InspectionLogViewResponse>) {
            state.available = action.payload.data;
            state.total = action.payload.total;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setFilterVisibility(state, action: PayloadAction<boolean>) {
            state.isFilterVisible = action.payload;
        },
    }
});
