import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    TimeSynchronizationSchemeViewModel
} from '../../../../common/model/meter/timeSynchronizationScheme/timeSynchronizationSchemeViewModel';

export interface TimeSynchronizationSchemesListState {
    available: TimeSynchronizationSchemeViewModel[];
}

export const initialState: TimeSynchronizationSchemesListState = {
    available: null,
};

export const timeSynchronizationSchemesSlice = createSlice({
    name: '@@wh/timeSynchronizationSchemes/list',
    initialState,
    reducers: {
        setAvailable(
            state,
            action: PayloadAction<TimeSynchronizationSchemeViewModel[]>
        ): TimeSynchronizationSchemesListState {
            return {
                ...state,
                available: action.payload,
            };
        }
    }
});
