import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { MeterViewModel } from '../../../../../common/model/meter/meter/meterViewModel';
import { MeterStatus, meterStatusDictionary } from '../../../../../common/model/meter/meterStatus';

interface Props {
    meter: MeterViewModel;
}

const useStyles = makeStyles((theme) => ({
    activationError: {
        color: theme.palette.grey.A400, // ToDo: нет дизайна
    },
    decommissioned: {
        color: theme.palette.grey.A400,
    },
    defective: {
        color: theme.palette.error.dark,
    },
    active: {
        color: theme.palette.success.dark,
    },
    created: {
        color: theme.palette.warning.light,
    },
}));

export const MeterStatusView: React.FC<Props> = (props) => {
    const { meter } = props;
    const classes = useStyles();
    const label = meterStatusDictionary[meter.status];
    return (
        <span className={classNames({
            [classes.decommissioned]: meter.status === MeterStatus.Decommissioned,
            [classes.activationError]: meter.status === MeterStatus.ActivationError,
            [classes.defective]: meter.status === MeterStatus.Defective,
            [classes.active]: meter.status === MeterStatus.Active,
            [classes.created]: meter.status === MeterStatus.Created,
        })}>
            {label}
        </span>
    );
};

export const MeterStatusRender: React.FC<Props> = (props) => {
    return <MeterStatusView {...props} />;
};
