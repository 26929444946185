import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));
interface Props {
    label: string;
    url: string;
}

export const MeterSerialNumView: React.FC<Props> = ({ label, url }) => {
    const styles = useStyles();
    return <a
        className={styles.link}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
    >{`${label}\u00a0>`}</a>;
};
