import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../../shared/components/button';
import { HookField } from '../../../../../shared/components/hookField';
import { Typography } from '../../../../../shared/components/typography';
import { monthSuggestions } from '../../common';
import { IStateProps } from './model';

export const useStyles = makeStyles(() => ({
    inputLabel: {
        textAlign: 'right',
    },
    buttonContainer: {
        paddingTop: '16px',
    },
}));


export const View: React.FC<IStateProps> = (props) => {
    const classes = useStyles();
    const { form, onSubmit, onCancel } = props;
    const { handleSubmit, formState: { isDirty } } = form;
    return <>
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>День</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='day'
                                    type='number'
                                    label='День'
                                    fullWidth />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Месяц</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='month'
                                    type='select'
                                    suggestions={monthSuggestions}
                                    label='Месяц'
                                    fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Название</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='name'
                                    type='text'
                                    label='Название'
                                    fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                            <Grid item>
                                <Button variant='primary' type='submit' disabled={!isDirty} icon='check'>Сохранить</Button>
                            </Grid>
                            <Grid item>
                                <Button variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    </>;
};
