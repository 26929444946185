import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Breadcrumbs } from '../../../shared/components/breadcrumbs';
import { Button } from '../../../shared/components/button';
import { Heading } from '../../../shared/components/heading';
import { HookField } from '../../../shared/components/hookField';
import { Modal } from '../../../shared/components/modal';
import { Table } from '../../../shared/components/table';
import { Wrapper } from '../../../shared/components/wrapper';
import { monthSuggestions } from './common';
import { SpecialDayBaseForm } from './components/specialDayBaseForm';
import { IStateProps } from './model';

export const View: React.FC<IStateProps> = (props) => {
    const {
        form,
        data,
        columns,
        heading,
        isFormOpen,
        breadcrumbs,
        onCloseForm,
        onClickNewDay,
        onBackHandler,
        onClickBreadcrumb,
        editableSpecialDayBase,
        onSubmitSpecialDayBaseForm,
    } = props;

    const actions = <>
        <Grid container spacing={2} justify='space-between'>
            <Grid item xs={6} sm={4} md={3} lg={2}>
                <FormProvider {...form}>
                    <HookField
                        fullWidth
                        label='Месяц'
                        type='select'
                        name='month'
                        suggestions={monthSuggestions}
                        isClearable
                    />
                </FormProvider>
            </Grid>
            <Grid item>
                <Button
                    icon='add'
                    type='button'
                    variant='primary'
                    onClick={onClickNewDay}
                >
                    Новый день
                </Button>
            </Grid>
        </Grid>
    </>;

    return <>
        <Box mb={3}>
            <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
        </Box>
        <Box mb={3}>
            <Heading heading={heading} onBackHandler={onBackHandler} />
        </Box>
        <Wrapper isBigHeader actions={actions}>
            <Table
                title={null}
                data={data || []}
                columns={columns}
                variant='white'
                options={{
                    paging: 'disabled',
                    grouping: false,
                    filtering: false,
                    search: false,
                    toolbar: false,
                    showTitle: false,
                }}
            />
        </Wrapper>
        {isFormOpen && (
            <Modal
                title={
                    editableSpecialDayBase
                        ? editableSpecialDayBase.name
                        : 'Новый специальный день базового календаря'
                }
                size='lg'
                onHide={onCloseForm}
            >
                <SpecialDayBaseForm
                    onCancel={onCloseForm}
                    viewModel={editableSpecialDayBase}
                    onSubmit={onSubmitSpecialDayBaseForm}
                />
            </Modal>
        )}
    </>;
};
