import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

const baseSelector = (state: StorageState) => state.eventTypes;

export const eventTypesListSelector = createSelector(
    baseSelector,
    state => state.list.available,
);

export const eventTypesTotalSelector = createSelector(
    baseSelector,
    state => state.list.total,
);

export const eventTypesLoadingSelector = createSelector(
    baseSelector,
    state => state.list.loading,
);

export const searchEventTypesListSelector = createSelector(
    baseSelector,
    state => state.searchList.available,
);

export const searchEventTypesLoadingSelector = createSelector(
    baseSelector,
    state => state.searchList.loading,
);
