import React from 'react';
import { ImportMeterSubTabs } from '../../../shared/constants';
import { IStateProps } from './model';
import { Box } from '@material-ui/core';
import { SubMenu } from '../../../shared/components/subMenu';
import { SubMenuItem } from '../../../shared/components/subMenuItem';
import { ImportCrq } from './components/importCrq';
import { ImportMeterActivationOrders } from './components/importMeterActivationOrders';

export const View = ({
    activeSub,
    onChangeSub,
    subs,
    isDisabledFileImports,
}: IStateProps) => {
    const defaultSub = isDisabledFileImports ? ImportMeterSubTabs.ImportOrders : ImportMeterSubTabs.ImportCrq;

    return (
        <>
            <SubMenu
                value={activeSub || defaultSub}
                onChange={onChangeSub}
                styles={{ minHeight: 'auto' }}
            >
                {
                    subs.map(sub =>
                        <SubMenuItem
                            label={sub.title}
                            key={sub.key}
                            value={sub.key}
                            disabled={sub.disabled}
                            styles={{
                                padding: '10px 23px',
                                minHeight: '28px',
                                height: '28px',
                                minWidth: 'auto'
                            }}
                        />
                    )
                }
            </SubMenu>

            <Box py={3}>
                { activeSub === ImportMeterSubTabs.ImportCrq && <ImportCrq />}
                { activeSub === ImportMeterSubTabs.ImportOrders && <ImportMeterActivationOrders />}
            </Box>
        </>
    );
};
