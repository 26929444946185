import React from 'react';
import { StatusIconProps } from './model';
import { MeterGisTasksStatus } from '../../../../common/model/enum/meterGisTasks';
import { Icon } from '../icon';
import { useTheme } from '@material-ui/core';

export const View = (props: StatusIconProps) => {
    const { status } = props;
    const theme = useTheme();

    if (status === MeterGisTasksStatus.open) {
        return (
            <Icon name='open' width='21px' height='21px' fillColor={theme.palette.system.success} />
        );
    }

    return (
        <Icon name='checkCircle' width='21px' height='21px' fillColor={theme.palette.text.hint} />
    );
};
