import { CrqChannelCategory } from 'common/model/meter/crq/crqChannelCategory';
import { CrqChannelCreator } from 'common/model/meter/crq/crqChannelCreator';
import { CrqChannelType } from 'common/model/meter/crq/crqChannelType';
import { UnitOfMeasurement } from 'common/model/meter/crq/unitOfMeasurement';

export const newCrqChannels:CrqChannelCreator[] = [
    {
        category: CrqChannelCategory.activeEnergyImp,
        type: CrqChannelType.B,
        unitOfMeasurement: UnitOfMeasurement.KVtHour,
    },
    {
        category: CrqChannelCategory.activeEnergyExp,
        type: CrqChannelType.B,
        unitOfMeasurement: UnitOfMeasurement.KVtHour,
    },
    {
        category: CrqChannelCategory.reactiveEnergyImp,
        type: CrqChannelType.B,
        unitOfMeasurement: UnitOfMeasurement.KvarHour,
    },
    {
        category: CrqChannelCategory.reactiveEnergyExp,
        type: CrqChannelType.B,
        unitOfMeasurement: UnitOfMeasurement.KvarHour,
    },
    {
        category: CrqChannelCategory.eventLog,
        type: CrqChannelType.J,
        unitOfMeasurement: UnitOfMeasurement.Pcs,
    },
    {
        category: CrqChannelCategory.relayStatus,
        type: CrqChannelType.E,
        unitOfMeasurement: UnitOfMeasurement.List,
    }
];
