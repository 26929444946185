import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../shared/components/button';
import { Checkbox } from '../../../shared/components/checkbox';
import { HookField } from '../../../shared/components/hookField';
import { Table } from '../../../shared/components/table';
import { TabMenu } from '../../../shared/components/tabMenu';
import { TabMenuItem } from '../../../shared/components/tabMenuItem';
import { Wrapper } from '../../../shared/components/wrapper';
import { Tables } from '../../../shared/constants';
import { IStateProps, AdvancedCrashReportTabs } from './model';
import { useStyles } from './styles';

export const View: React.FC<IStateProps> = (props) => {

    const {
        data,
        form,
        tabs,
        columns,
        activeTab,
        isLoading,
        onChangeTab,
        isReportFormed,
        onClickRefresh,
        onClickFormExcel,
        onClickFormReport,
        isReportExcelNotAvailable,
        meterGroupAutocomplete,
        isAdvancedCrashReportVisible,
        setIsAdvancedCrashReportVisible,
    } = props;

    const classes = useStyles();

    const filterRender = <>
        <FormProvider {...form}>
            <Grid container spacing={2}>
                <Grid item>
                    <Checkbox
                        label='Подробный отчет'
                        checked={isAdvancedCrashReportVisible}
                        onChange={() => setIsAdvancedCrashReportVisible(!isAdvancedCrashReportVisible)}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={4} lg={3} item>
                    <HookField
                        type='autocomplete'
                        name='meterGroupId'
                        label='Группа ПУ'
                        disabled={isLoading}
                        {...meterGroupAutocomplete}
                        fullWidth />
                </Grid>
                {isReportFormed && (
                    <>
                        <Grid item>
                            <Button
                                onClick={onClickRefresh}
                                variant='outline'
                                square
                                icon='refresh'
                                isLoading={isLoading}
                                type='button'
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClickFormExcel}
                                variant='primary'
                                icon='excel'
                                type='button'
                                isLoading={isLoading}
                                disabled={isLoading || isReportExcelNotAvailable}
                            >
                                Выгрузить в Excel
                            </Button>
                        </Grid>
                    </>
                )}
                {!isReportFormed && (
                    <Grid item>
                        <Button
                            onClick={onClickFormReport}
                            variant='primary'
                            type='button'
                            isLoading={isLoading}
                            disabled={isLoading}
                        >
                            Сформировать
                        </Button>
                    </Grid>
                )}
            </Grid>
        </FormProvider>
    </>;

    const isTableAvailable = data?.length > 0;

    return <>
        <Wrapper isBigHeader actions={filterRender} childrenWrapperClassName={classes.wrapper}>
            {isTableAvailable && (
                <>
                    <TabMenu value={activeTab || AdvancedCrashReportTabs.DailyProfileReadings} onChange={onChangeTab}>
                        {
                            tabs.map(tab =>
                                <TabMenuItem
                                    label={tab.title}
                                    key={tab.key}
                                    value={tab.key}
                                />
                            )
                        }
                    </TabMenu>
                    <Box padding='16px' className={classes.tableContainer}>
                        <Table
                            name={Tables.advancedCrashReportTable}
                            columns={columns}
                            data={data || []}
                            title={''}
                            useQueryString={false}
                            isLoading={isLoading}
                            options={{
                                showTitle: false,
                                toolbar: false,
                                paging: 'classic',
                                grouping: false,
                                columnsButton: false,
                                filtering: false,
                                search: false,
                                fixedColumns: 6,
                                pageSize: 100,
                            }}
                        />
                    </Box>
                </>
            )}
            {!isTableAvailable && (
                <Box p={2}>
                    <Box className={classes.noDataText}>
                        <div>Нет данных</div>
                    </Box>
                </Box>
            )}
        </Wrapper>
    </>;
};
