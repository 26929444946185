import { ActionType } from '../../actions';
import { createSlice } from '@reduxjs/toolkit';

export type BlockingCallCountState = number;
const initialState: BlockingCallCountState = 0;

export const blockingCallCountSlice = createSlice({
    name: '@@callwrapper',
    initialState,
    reducers: {
        increment: (state) => state + 1,
        decrement: (state) => state - 1,
    },
    extraReducers: {
        [ActionType.RESET]: () => initialState
    }
});
