import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { MeterModelUpdater } from '../../../../common/model/meter/meterModel/meterModelUpdater';
import { MeterModelViewModel } from '../../../../common/model/meter/meterModel/meterModelViewModel';
import { BaseDispatch } from '../../../../redux/actions';
import { getMeterModels, updateMeterModels } from '../../../../redux/actions/meterModels';
import { resourcesTimeSynchronizationSchemesByIdSelector } from '../../../../redux/selectors';
import { meterModelsListSelector } from '../../../../redux/selectors/meterModels';
import { Button } from '../../../shared/components/button';
import { AdministrationTabs, Routes } from '../../../shared/constants';
import { useBreadcrumbs } from '../../../shared/hooks/useBreadcrumbs';
import { useHeading } from '../../../shared/hooks/useHeading';
import { getRoute } from '../../../shared/pipes';
import { View } from './view';

export const MeterModels: React.FC = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const [editableMeterModel, setEditableMeterModel] = useState<MeterModelViewModel>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const listState = useSelector(meterModelsListSelector);
    const timeSyncSchemesById = useSelector(resourcesTimeSynchronizationSchemesByIdSelector());
    const data: MeterModelViewModel[] = listState.available ?? [];

    const breadcrumbProps = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration) },
        { name: 'Справочники', link: getRoute(Routes.administration, {}, { tab: AdministrationTabs.Dictionaries }) },
        { name: 'Типы приборов учета', link: getRoute(Routes.meterModels) },
    ]);

    const headingProps = useHeading('Типы приборов учета', () => {
        dispatch(push(getRoute(Routes.administration, {}, { tab: AdministrationTabs.Dictionaries })));
    });

    const columns: Column[] = [
        {
            title: 'Название',
            field: 'shortName',
        },
        {
            title: 'Количество фаз',
            field: 'isSinglePhase',
            render: (item: MeterModelViewModel) => {
                return item.isSinglePhase ? 'Однофазный' : 'Трехфазный';
            },
        },
        {
            title: 'Схема синхронизации времени',
            field: 'timeSynchronizationSchemeId',
            render: (item: MeterModelViewModel) => {
                if (!item.timeSynchronizationSchemeId) {
                    return '–';
                }
                const scheme = timeSyncSchemesById[item.timeSynchronizationSchemeId];
                if (!scheme) {
                    return '–';
                }
                return `${scheme.name}`;
            },
        },
        {
            title: '',
            render: (item: MeterModelViewModel) => {
                return (
                    <Button
                        type='button'
                        variant='primary'
                        onClick={() => {
                            setEditableMeterModel(item);
                        }}
                    >
                        Редактировать
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(getMeterModels());
    }, []);

    const onSubmit: SubmitHandler<MeterModelUpdater> = async (formData) => {
        setIsLoading(true);
        try {
            await dispatch(updateMeterModels(editableMeterModel.id, formData));
            setEditableMeterModel(null);
        } finally {
            setIsLoading(false);
        }
    };

    return View({
        ...breadcrumbProps,
        ...headingProps,
        data,
        columns,
        onSubmit,
        isLoading,
        editableMeterModel,
        onCloseEditMeterModel: () => {
            setEditableMeterModel(null);
        }
    });
};
