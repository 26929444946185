import { meterImportCrqListSlice, MeterImportCrqListState } from './list';
import { meterImportCrqLogListSlice, MeterImportCrqLogListState } from './logList';
import { combineReducers } from 'redux';

export interface MeterImportCrqState {
    list: MeterImportCrqListState;
    logList: MeterImportCrqLogListState;
}

export const meterImportCrq = combineReducers<MeterImportCrqState>({
    list: meterImportCrqListSlice.reducer,
    logList: meterImportCrqLogListSlice.reducer,
});
