import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

const listSelector = (state: StorageState) => state.massOperations.list;

export const massOperationsListAvailableSelector = () => createSelector(
    listSelector,
    (state) => state.available,
);

export const massOperationsHasNotCompletedSelector = () => createSelector(
    listSelector,
    (state) => state.hasNotCompleted,
);
