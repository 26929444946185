import * as React from 'react';

import { IStateProps } from './model';
import { RenderMenuItem } from './renderMenuItems';
import { IntersectionObserverWrapper } from '../intersectionObserverWrapper';
import { useStyles } from './styles';

export const View = (props: IStateProps) => {

    const { menu, activeMenu, onMenuItemClick } = props;

    const classes = useStyles();

    return (
        <nav className={classes.topMenu}>
            <IntersectionObserverWrapper>
                {menu.main.map((menuItem) => (
                    <RenderMenuItem
                        key={menuItem.name}
                        menuItem={menuItem}
                        activeMenu={activeMenu}
                        onMenuItemClick={onMenuItemClick}
                        data-targetid={menuItem.menuItemType}
                        isTab={menuItem.isTab}
                    />
                ))}
            </IntersectionObserverWrapper>
        </nav>
    );
};
