/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Cell, Workbook, Worksheet } from 'exceljs';
import { DateFormats } from '../../../../../common/constants/date';
import { MassOperationViewModel } from '../../../../../common/model/meter/massOperation/massOperationViewModel';
import { formatDate } from '../../../../shared/utils/dates';
import { downloadExcelData } from '../../../../shared/utils/download';
import { addBordersToCell, addRowSpaceToWorksheet, excelBaseFontName } from '../../../reports/excelHelpers';

export interface MakeExcelReportConfig {
    massOperation: MassOperationViewModel;
    filename: string;
    title: string;
}

const columnWidths: Record<number, number> = {
    1: 50,
    2: 25,
    3: 25,
    4: 15,
};

const makeMainTitle = (worksheet: Worksheet, title: string) => {
    worksheet.addRow([title]);
    worksheet.getCell('A1').style.font = {
        size: 14,
        name: excelBaseFontName,
        bold: true,
    };
    worksheet.getCell('A1').style.alignment = {
        horizontal: 'left',
    };
    worksheet.mergeCells('A1:H1');
};

const makeColumns = (worksheet: Worksheet) => {
    for (const col in columnWidths) {
        worksheet.getColumn(+col).width = +columnWidths[col];
    }
};

const addRow = (data: string[], worksheet: Worksheet, cellCallback?: (cell: Cell, index: number) => void) => {
    const row = worksheet.addRow(data);
    for (let i = 1; i <= data.length; i++) {
        const cell = row.getCell(i);
        if (cellCallback) {
            cellCallback(cell, i);
        }
        cell.style.alignment = {
            wrapText: true,
        };
    }
};

export const makeCrashByRegionsReportSheet = (workbook: Workbook, config: MakeExcelReportConfig): void => {
    const {
        title,
        massOperation,
    } = config;

    const worksheetTitle = title;
    const worksheetHeader = 'Результат';
    const worksheet = workbook.addWorksheet(worksheetHeader);
    makeMainTitle(worksheet, worksheetTitle);
    makeColumns(worksheet);

    const addLocalRow = (data: string[]) => {
        addRow(data, worksheet, (cell) => {
            cell.style.font = {
                name: excelBaseFontName,
                size: 11,
            };
        });
    };

    addLocalRow([
        'Дата запуска',
        formatDate(massOperation.beginProcessingDate, DateFormats.dateFormat)
    ]);

    addLocalRow([
        'Регион',
        massOperation.region?.name ?? 'Не указан',
    ]);

    addLocalRow([
        'Группа',
        massOperation.meterGroup?.name ?? 'Не указана',
    ]);

    addLocalRow([
        'Начало периода опроса',
        massOperation.localBeginReadingDate
            ? formatDate(massOperation.localBeginReadingDate, DateFormats.dateFormat)
            : '–'
    ]);

    addRowSpaceToWorksheet(worksheet);

    addLocalRow([
        'Количество ПУ',
        `${massOperation.meterCount}`
    ]);

    addLocalRow([
        '  из них опрошено успешно',
        `${massOperation.successReading}`
    ]);

    addLocalRow([
        '  частично с ошибками',
        `${massOperation.partialSuccessReading}`
    ]);

    addLocalRow([
        '  полностью с ошибками',
        `${massOperation.errorReading}`
    ]);

    addLocalRow([
        'Количество задач',
        `${massOperation.taskCount}`
    ]);

    addLocalRow([
        'Количество успешно обработанных задач',
        `${massOperation.completedTaskCount - massOperation.errorTaskCount}`
    ]);

    addLocalRow([
        'Количество задач с ошибками',
        `${massOperation.errorTaskCount}`
    ]);

    addRowSpaceToWorksheet(worksheet);

    addRowSpaceToWorksheet(worksheet);

    const addTableRow = (data: string[]) => {
        addRow(data, worksheet, (cell) => {
            cell.style.font = {
                name: excelBaseFontName,
                size: 11,
            };
            addBordersToCell(cell);
        });
    };

    addTableRow([
        'Фаза регулярного опроса',
        'Начало',
        'Конец',
        'Длительность фазы',
    ]);

    const addTableSumRow = (data: string[]) => {
        addRow(data, worksheet, (cell, index) => {
            cell.style.font = {
                name: excelBaseFontName,
                size: 11,
            };
            addBordersToCell(cell);
            if (index === 4) {
                const prevCell = worksheet.getRow(+cell.row).getCell(+cell.col - 1);
                const prevPrevCell = worksheet.getRow(+cell.row).getCell(+cell.col - 2);
                cell.numFmt = 'h:mm:ss';
                cell.value = {
                    formula: `${prevCell.address}-${prevPrevCell.address}`,
                    date1904: false,
                };
            }
        });
    };

    addTableSumRow([
        '1. Создание задач, отправка в сервер опроса',
        formatDate(massOperation.createTaskBeginDate, DateFormats.dateFullTimeFormat),
        formatDate(massOperation.createTaskEndDate, DateFormats.dateFullTimeFormat),
        '',
    ]);

    addTableSumRow([
        '2. Обработка задач в сервере опроса, включая ожидание ответа счетчика',
        formatDate(massOperation.readingBeginDate, DateFormats.dateFullTimeFormat),
        formatDate(massOperation.readingEndDate, DateFormats.dateFullTimeFormat),
        '',
    ]);


    addTableSumRow([
        '3. Сохранение результатов в БД',
        formatDate(massOperation.saveResultBeginDate, DateFormats.dateFullTimeFormat),
        formatDate(massOperation.saveResultEndDate, DateFormats.dateFullTimeFormat),
        '',
    ]);
};

export const makeExcelReport = async (config: MakeExcelReportConfig): Promise<void> => {
    const workbook = new Workbook();
    makeCrashByRegionsReportSheet(workbook, config);

    const buffer = await workbook.xlsx.writeBuffer() as unknown as Buffer;
    const excelData = buffer.toString('base64');

    downloadExcelData(
        excelData,
        config.filename,
    );
};
/* eslint-enable @typescript-eslint/no-magic-numbers */
