export enum MeterPowerSwitchOrderStatus {
    MeterNotFound = 'MeterNotFound', // ПУ не найден
    RelaySwitchingOperationNotAvailable = 'RelaySwitchingOperationNotAvailable', // Недоступна операция переключения реле
    ReadyForWork = 'ReadyForWork', // Готова к работе
    RuntimeError = 'RuntimeError', // Ошибка выполнения
    CurrentPresenceBeforeOperation = 'CurrentPresenceBeforeOperation', // Наличие тока до возобновления потребления
    SuccessfullyCompleted = 'SuccessfullyCompleted', // Выполнено успешно
    IncorrectRelayStateAfterOperation = 'IncorrectRelayStateAfterOperation', // Неправильное состояние реле после операции
    IncorrectRelayStateBeforeOperation = 'IncorrectRelayStateBeforeOperation', // Неправильное состояние реле до операции
    CurrentPresenceAfterOperation = 'CurrentPresenceAfterOperation', // Наличие тока после отключения реле
    InvalidMeterStatus = 'InvalidMeterStatus', // Неверный статус ПУ
    RejectedDueToPendingOrder = 'RejectedDueToPendingOrder', // Запрос отклонен, так как операция в процессе
    TTLExpired = 'TTLExpired', // Превышено время жизни задачи
    ApprovalRequired = 'ApprovalRequired', // Требует подтверждения
    OrdersCountExceededDailyLimit = 'OrdersCountExceededDailyLimit', // Количество заявок превысило суточный лимит
}

export const meterPowerSwitchFinalStatuses: MeterPowerSwitchOrderStatus[] = [
    MeterPowerSwitchOrderStatus.MeterNotFound,
    MeterPowerSwitchOrderStatus.RelaySwitchingOperationNotAvailable,
    MeterPowerSwitchOrderStatus.RuntimeError,
    MeterPowerSwitchOrderStatus.CurrentPresenceBeforeOperation,
    MeterPowerSwitchOrderStatus.SuccessfullyCompleted,
    MeterPowerSwitchOrderStatus.IncorrectRelayStateAfterOperation,
    MeterPowerSwitchOrderStatus.IncorrectRelayStateBeforeOperation,
    MeterPowerSwitchOrderStatus.CurrentPresenceAfterOperation,
    MeterPowerSwitchOrderStatus.InvalidMeterStatus,
    MeterPowerSwitchOrderStatus.RejectedDueToPendingOrder,
    MeterPowerSwitchOrderStatus.TTLExpired,
    MeterPowerSwitchOrderStatus.OrdersCountExceededDailyLimit,
];

export const meterPowerSwitchOrderStatusDictionary: Record<MeterPowerSwitchOrderStatus, string> = {
    [MeterPowerSwitchOrderStatus.MeterNotFound]: 'ПУ не найден',
    [MeterPowerSwitchOrderStatus.RelaySwitchingOperationNotAvailable]: 'Недоступна операция переключения реле',
    [MeterPowerSwitchOrderStatus.ApprovalRequired]: 'Требует подтверждения',
    [MeterPowerSwitchOrderStatus.ReadyForWork]: 'Готова к работе',
    [MeterPowerSwitchOrderStatus.RuntimeError]: 'Ошибка выполнения',
    [MeterPowerSwitchOrderStatus.CurrentPresenceBeforeOperation]: 'Наличие тока до возобновления потребления',
    [MeterPowerSwitchOrderStatus.SuccessfullyCompleted]: 'Выполнено успешно',
    [MeterPowerSwitchOrderStatus.IncorrectRelayStateAfterOperation]: 'Неправильное состояние реле после операции',
    [MeterPowerSwitchOrderStatus.IncorrectRelayStateBeforeOperation]: 'Неправильное состояние реле до операции',
    [MeterPowerSwitchOrderStatus.CurrentPresenceAfterOperation]: 'Наличие тока после отключения реле',
    [MeterPowerSwitchOrderStatus.InvalidMeterStatus]: 'Неверный статус ПУ',
    [MeterPowerSwitchOrderStatus.RejectedDueToPendingOrder]: 'Запрос отклонен, так как операция в процессе',
    [MeterPowerSwitchOrderStatus.TTLExpired]: 'Превышено время жизни задачи',
    [MeterPowerSwitchOrderStatus.OrdersCountExceededDailyLimit]: 'Количество заявок превысило суточный лимит',
};
