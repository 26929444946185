import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventLogsFindResponse } from '../../../../common/model/meter/eventLog/eventLogsFindResponse';
import { EventLogViewModel } from '../../../../common/model/meter/eventLog/eventLogViewModel';
import { MeterViewModel } from '../../../../common/model/meter/meter/meterViewModel';


export interface CommonEventLogState {
    available: EventLogViewModel[];
    highCriticalLevel: EventLogViewModel[];
    loading: boolean;
    criticalTotal: number;
    total: number;
    isFilterVisible: boolean;
    meters: MeterViewModel[],
}


const initialState: CommonEventLogState = {
    available: null,
    highCriticalLevel: [],
    loading: false,
    criticalTotal: 0,
    total: 0,
    isFilterVisible: false,
    meters: [],
};

export const commonEventLogSlice = createSlice({
    name: '@@vv/eventLogs/common',
    initialState,
    reducers: {
        setAvailableEventLogs(state, action: PayloadAction<EventLogsFindResponse>) {
            state.available = action.payload.data;
            state.total = action.payload.total;
            state.loading = false;
        },
        setHighCriticalLevelEventLogs(state, action: PayloadAction<EventLogsFindResponse>) {
            state.highCriticalLevel = action.payload.data;
            state.criticalTotal = action.payload.total;
            state.loading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setFilterVisibility(state, action: PayloadAction<boolean>) {
            state.isFilterVisible = action.payload;
        },
        setMeters(state, action: PayloadAction<EventLogViewModel[]>) {
            state.meters = action.payload;
        }
    }
});
