import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

const listSelector = (state: StorageState) => state.meterGroup.list;

export const meterGroupListAvailableSelector = () => createSelector(
    listSelector,
    (state) => state.available,
);

export const meterGroupMetersListSelector = () => createSelector(
    listSelector,
    (state) => state.metersListResponse,
);
