import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

const baseSelector = (state: StorageState) => state.meterReading.instantProfiles;

export const meterReadingInstantOrderSelector = createSelector(
    baseSelector,
    state => state.order,
);

export const meterReadingInstantProfileSelector = createSelector(
    baseSelector,
    state => state.profile,
);
