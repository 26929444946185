export enum TimeZones {
    UTCMinus12 = -720,
    UTCMinus11 = -660,
    UTCMinus10 = -600,
    UTCMinus9_30 = -570,
    UTCMinus9 = -540,
    UTCMinus8 = -480,
    UTCMinus7 = -420,
    UTCMinus6 = -360,
    UTCMinus5 = -300,
    UTCMinus4 = -240,
    UTCMinus3_30 = -210,
    UTCMinus3 = -180,
    UTCMinus2 = -120,
    UTCMinus1 = -60,
    UTCPlus0 = 0,
    UTCPlus1 = 60,
    UTCPlus2 = 120,
    UTCPlus3 = 180,
    UTCPlus3_30 = 210,
    UTCPlus4 = 240,
    UTCPlus4_30 = 270,
    UTCPlus5 = 300,
    UTCPlus5_30 = 330,
    UTCPlus5_45 = 345,
    UTCPlus6 = 360,
    UTCPlus6_30 = 390,
    UTCPlus7 = 420,
    UTCPlus8 = 480,
    UTCPlus8_45 = 525,
    UTCPlus9 = 540,
    UTCPlus9_30 = 570,
    UTCPlus10 = 600,
    UTCPlus10_30 = 630,
    UTCPlus11 = 660,
    UTCPlus12 = 720,
    UTCPlus12_45 = 765,
    UTCPlus13 = 780,
    UTCPlus14 = 840,
}

export const timeZoneDictionary: Record<TimeZones, string> = {
    [TimeZones.UTCMinus12]: '-12:00',
    [TimeZones.UTCMinus11]: '-11:00',
    [TimeZones.UTCMinus10]: '-10:00',
    [TimeZones.UTCMinus9_30]: '-09:30',
    [TimeZones.UTCMinus9]: '-09:00',
    [TimeZones.UTCMinus8]: '-08:00',
    [TimeZones.UTCMinus7]: '-07:00',
    [TimeZones.UTCMinus6]: '-06:00',
    [TimeZones.UTCMinus5]: '-05:00',
    [TimeZones.UTCMinus4]: '-04:00',
    [TimeZones.UTCMinus3_30]: '-03:30',
    [TimeZones.UTCMinus3]: '-03:00',
    [TimeZones.UTCMinus2]: '-02:00',
    [TimeZones.UTCMinus1]: '-01:00',
    [TimeZones.UTCPlus0]: '0:00',
    [TimeZones.UTCPlus1]: '+01:00',
    [TimeZones.UTCPlus2]: '+02:00',
    [TimeZones.UTCPlus3]: '+03:00',
    [TimeZones.UTCPlus3_30]: '+03:30',
    [TimeZones.UTCPlus4]: '+04:00',
    [TimeZones.UTCPlus4_30]: '+04:30',
    [TimeZones.UTCPlus5]: '+05:00',
    [TimeZones.UTCPlus5_30]: '+05:30',
    [TimeZones.UTCPlus5_45]: '+05:45',
    [TimeZones.UTCPlus6]: '+06:00',
    [TimeZones.UTCPlus6_30]: '+06:30',
    [TimeZones.UTCPlus7]: '+07:00',
    [TimeZones.UTCPlus8]: '+08:00',
    [TimeZones.UTCPlus8_45]: '+08:45',
    [TimeZones.UTCPlus9]: '+09:00',
    [TimeZones.UTCPlus9_30]: '+09:30',
    [TimeZones.UTCPlus10]: '+10:00',
    [TimeZones.UTCPlus10_30]: '+10:30',
    [TimeZones.UTCPlus11]: '+11:00',
    [TimeZones.UTCPlus12]: '+12:00',
    [TimeZones.UTCPlus12_45]: '+12:45',
    [TimeZones.UTCPlus13]: '+13:00',
    [TimeZones.UTCPlus14]: '+14:00',
};
