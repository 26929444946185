import { Settings } from '../../../../common/model/resources/settings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ResourcesSettingsState = Settings;
const initialState: ResourcesSettingsState = null;

export const resourcesSettingsSlice = createSlice({
    name: '@@wh/resources/settings',
    initialState,
    reducers: {
        update(state, action: PayloadAction<Settings>): ResourcesSettingsState {
            return Object.assign({}, state, action.payload);
        },
        updateVersion(state, action: PayloadAction<number>): ResourcesSettingsState {
            return {
                ...state,
                version: action.payload,
            };
        },
    }
});
