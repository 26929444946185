import * as _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MeterSearchQuery } from '../../../../../common/model/meter/meter/meterSearchQuery';
import { MeterViewModel } from '../../../../../common/model/meter/meter/meterViewModel';
import { BaseDispatch } from '../../../../../redux/actions';
import { createSnackbar } from '../../../../../redux/actions/controls';
import { searchMeters } from '../../../../../redux/actions/meters';
import { metersSearchSelector } from '../../../../../redux/selectors/meters';
import { useWebDictionarySelectSuggestions } from '../../../hooks/useWebDictionary';
import { getErrorMessage } from '../../../utils/error';
import { SelectSuggestion } from '../../select/model';
import { AutocompleteOptionsHookReturn, VAutocompleteOption } from '../model';

const loadMetersLimit = 20;

export interface MeterAutocompleteOptions {
    valueMapKey?: keyof MeterViewModel;
    labelMapKey?: keyof MeterViewModel;
}

export const useMeterAutocomplete = (
    queryParams: MeterSearchQuery = {},
    options: MeterAutocompleteOptions = {},
): AutocompleteOptionsHookReturn => {
    const { valueMapKey = 'id' } = options;

    const dispatch = useDispatch<BaseDispatch>();

    const [loading, setLoading] = useState(false);

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    const regionSuggestionsById: Record<number, SelectSuggestion> = useMemo(() => _.keyBy(regionSuggestions, 'value'), regionSuggestions);

    const searchedMeters = useSelector(metersSearchSelector) ?? [];

    const fetch = async (val?: string) => {
        setLoading(true);
        try {
            await dispatch(searchMeters({
                ...queryParams,
                meterSerialNum: val,
                count: loadMetersLimit,
            }));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setLoading(false);
    };

    useEffect(() => {
        fetch();
    }, []);

    const onSearchMeter = async (val: string) => {
        await fetch(val);
    };

    const metersSuggestions: VAutocompleteOption[] = useMemo(() => {
        const groupedByMeterSerialNum: Record<number, MeterViewModel[]> = _.groupBy(searchedMeters, 'meterSerialNum');
        return Object.entries<MeterViewModel[]>(groupedByMeterSerialNum)
            .flatMap(([, value]) => {
                return value.length > 1
                    ? value.map<VAutocompleteOption>(item => ({
                        label: item.meterSerialNum,
                        value: item[valueMapKey],
                        optionsLabel: `${item.meterSerialNum}, ${regionSuggestionsById[item.regionId]?.label}`,
                    }))
                    : value.map<VAutocompleteOption>(item => ({
                        label: item.meterSerialNum,
                        value: item[valueMapKey],
                        optionsLabel: item.meterSerialNum,
                    }));
            });
    }, [searchedMeters, regionSuggestionsById]);

    return {
        options: metersSuggestions,
        onInputChange: (_, value) => onSearchMeter(value),
        loading,
    };
};
