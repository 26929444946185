import { Box } from '@material-ui/core';
import React from 'react';
import { Button } from '../../../../shared/components/button';
import { MeterGisTaskViewModel } from '../../../../../common/model/meter/meterGisTask/meterGisTaskViewModel';

interface Props {
    onClickClose: () => void;
    onClickCreate: () => void;
    meterGisTask: MeterGisTaskViewModel;
}

export const DetailsDoubleButtonView: React.FC<Props> = ({
    onClickClose,
    onClickCreate,
    meterGisTask,
}) => {
    return (
        <Box display="flex" justifyContent="flex-end">
            {
                !meterGisTask && <Box marginRight='8px'>
                    <Button variant="outline" icon='add' onClick={onClickCreate}>Создать инцидент</Button>
                </Box>
            }
            <Button variant="primary" icon='check' onClick={onClickClose}>Закрыть метку</Button>
        </Box>
    );
};
