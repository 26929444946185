import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { meterActionSchemeProcessStatusDictionary } from '../../../common/model/meter/meterActionSchemeProcessStatus';
import { DictionaryItem } from '../../../common/model/resources/dictionaries/dictionaryItem';
import { resourcesMeterModelsSelector, resourcesRegionsListSelector } from '../../../redux/selectors';
import { VAutocompleteOption } from '../components/autocomplete/model';
import { SelectSuggestion } from '../components/select/model';

export interface WebDictionary<T> {
    regions: T;
    meterModels: T;
    meterActionSchemeStatuses: T;
}

export const useWebDictionary = (): WebDictionary<DictionaryItem[]> => {
    const meterActionSchemeStatuses = useMemo(() => Object.entries(meterActionSchemeProcessStatusDictionary).map(([id, name]) => ({
        id,
        name,
    } as DictionaryItem)), []);
    const regionsSrc = useSelector(resourcesRegionsListSelector());
    const meterModelsSrc = useSelector(resourcesMeterModelsSelector());
    const regions = useMemo(() => regionsSrc.map((region) => ({
        id: region.id,
        name: region.name,
    } as DictionaryItem)), [regionsSrc]);

    const meterModels = useMemo(() => meterModelsSrc.map((region) => ({
        id: region.id,
        name: region.shortName,
    } as DictionaryItem)), [meterModelsSrc]);

    return {
        regions,
        meterModels,
        meterActionSchemeStatuses,
    };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type LookupObjectType = object;

type MapDictionaryFunc<T> = (item: DictionaryItem[]) => T;

export const dictionaryItemsToLookup: MapDictionaryFunc<LookupObjectType> = (items) => {
    return items.reduce((prev, cur) => {
        return {
            ...prev,
            [cur.id]: cur.name,
        };
    }, {});
};

export const dictionaryItemsToSelectSuggestions: MapDictionaryFunc<SelectSuggestion[]> = (items) => {
    return items.map(item => ({
        label: item.name,
        value: item.id,
    }));
};
export const dictionaryItemsToVAutocompleteOptions: MapDictionaryFunc<VAutocompleteOption[]> = (items) => {
    return items.map(item => ({
        label: item.name,
        optionsLabel: item.name,
        value: item.id,
    }));
};

export const useWebDictionaryMapFunc = <T extends any>(mapFunc: MapDictionaryFunc<T>): WebDictionary<T> => {
    const items = useWebDictionary();
    return useMemo(() => {
        const dictionary: WebDictionary<T> = {
            regions: mapFunc(items.regions),
            meterModels: mapFunc(items.meterModels),
            meterActionSchemeStatuses: mapFunc(items.meterActionSchemeStatuses),
        };
        return dictionary;
    }, [items]);
};

export const useWebDictionaryLookup = () => useWebDictionaryMapFunc<LookupObjectType>(dictionaryItemsToLookup);
export const useWebDictionarySelectSuggestions = () => useWebDictionaryMapFunc<SelectSuggestion[]>(dictionaryItemsToSelectSuggestions);
export const useWebDictionaryVAutocompleteOptions = () => useWebDictionaryMapFunc<VAutocompleteOption[]>(
    dictionaryItemsToVAutocompleteOptions
);
