import { Column } from '@softmedialab/materialui-table';
import { MeterDictionariesViewModel } from '../../../../common/model/meter/meterDictionaries/meterDictionariesViewModel';

export interface DictionariesViewProps {
    data: DictionariesData[];
    columns: Column[];
}

export enum DictionaryDataItemType {
    MeterDictionaryItem,
    CustomDictionaryItem,
}

export interface DictionaryDataItem<T> {
    data: T;
    type: DictionaryDataItemType;
}

export interface CustomDictionariesViewModel {
    title: string;
    url: string;
}

export interface CustomDictionariesDataItem extends DictionaryDataItem<CustomDictionariesViewModel> {
    type: DictionaryDataItemType.CustomDictionaryItem;
}

export interface MeterDictionariesDataItem extends DictionaryDataItem<MeterDictionariesViewModel> {
    type: DictionaryDataItemType.MeterDictionaryItem;
}

export type DictionariesData
    = CustomDictionariesDataItem
    | MeterDictionariesDataItem;
