import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { MeterGroupViewModel } from '../../../../common/model/meter/meterGroup/meterGroupViewModel';
import { Sort } from '../../../../common/sort';
import { BaseDispatch } from '../../../../redux/actions';
import { getMeterGroups } from '../../../../redux/actions/meters';
import { getUsers } from '../../../../redux/actions/user/list';
import { activeUserSelector, resourcesRegionsByIdSelector, usersListSelector, } from '../../../../redux/selectors';
import { meterGroupsSelector, nameListGroupsMeterSelector } from '../../../../redux/selectors/meters';
import { Button } from '../../../shared/components/button';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { Routes } from '../../../shared/constants';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { getRoute } from '../../../shared/pipes';
import { GroupsFilter, GroupStaticType } from './model';
import './style.css';
import { isStaticContent, RegionMeterGroupView, View, VisibleAllGroup } from './view';

const initialFormParams: GroupsFilter = {
    regionId: null,
    name: null,
    userId: null,
    visibleToAll: null,
    groupType: null,
};

export const groupTypeSuggestions: SelectSuggestion[] = [
    {
        label: 'Список ПУ зафиксирован',
        value: GroupStaticType.Static,
    },
    {
        label: 'Список ПУ определяется условиями',
        value: GroupStaticType.Dynamic,
    }
];

const filterMeterGroups = (filter: GroupsFilter, groups: MeterGroupViewModel[]): MeterGroupViewModel[] => {
    if (!groups) {
        return null;
    }
    return groups.filter((group) => {
        let filtered = true;
        if (filter.regionId) {
            filtered = filtered && group.regionId === filter.regionId;
        }
        if (filter.name) {
            filtered = filtered && group.name === filter.name;
        }
        if (filter.userId) {
            filtered = filtered && group.userId === filter.userId;
        }
        if (filter.visibleToAll) {
            filtered = filtered && group.visibleToAll;
        }
        if (filter.groupType === GroupStaticType.Dynamic) {
            filtered = filtered && group.isStatic === false;
        } else if (filter.groupType === GroupStaticType.Static) {
            filtered = filtered && group.isStatic === true;
        }
        return filtered;
    });
};

export const GroupsPage = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const meterGroups = useSelector(meterGroupsSelector);
    const userList = useSelector(usersListSelector);
    const nameGroupListMeters = useSelector(nameListGroupsMeterSelector);
    const [groupsFilter, setGroupsFilter] = useSyncQueryParams<GroupsFilter>(initialFormParams);
    const regionsById = useSelector(resourcesRegionsByIdSelector());
    const form = useForm<GroupsFilter>({ defaultValues: groupsFilter });
    const formData = form.watch();
    const activeClient = useSelector(activeUserSelector());

    useEffect(() => {
        setGroupsFilter({
            ...initialFormParams,
            ...formData
        });
    }, [formData]);

    useEffect(() => {
        const sort = new Sort();
        dispatch(getMeterGroups(groupsFilter));
        dispatch(getUsers(sort));
    }, []);

    const groupNameSuggestions = useMemo(() => {
        return nameGroupListMeters.map(item => ({
            label: item.name,
            value: item.name,
        }));
    }, [nameGroupListMeters]);

    const userSuggestions = useMemo(() => {
        if (!userList) {
            return [];
        }
        return userList.map(item => ({
            label: activeClient.id === item.id ? 'Я' : `${item.name}\n ${item.email}`,
            value: item.id,
        }));
    }, [userList]);

    const onClickEditGroup = (id: string) => {
        dispatch(push(getRoute(Routes.meterGroupEdit, { groupId: id })));
    };


    const columnsDefinition: Column[] = [
        {
            title: 'ИД группы',
            field: 'id',
            sorting: true,
        },
        {
            title: 'Название группы',
            field: 'name',
            sorting: true,
        },
        {
            title: 'Регион',
            field: 'regionId',
            sorting: true,
            render: (data) => {
                return RegionMeterGroupView({
                    region: regionsById[data.regionId],
                });
            }
        },
        {
            title: 'Тип группы',
            field: 'isStatic',
            sorting: true,
            render: (data) => {
                return isStaticContent({
                    isStatic: data.isStatic,
                });
            }
        },
        {
            title: 'Владелец',
            field: 'userId',
            sorting: true,
            render: (data) => {
                if (data.user) {
                    return activeClient.id === data.user.id ? <div>Я</div> : <div>{data.user.name}<br />{data.user.email}</div>;
                } else {
                    return <></>;
                }
            }
        },
        {
            title: 'Показывать всем',
            field: 'visibleToAll',
            sorting: false,
            render: (data) => {
                return VisibleAllGroup(data);
            }
        },
        {
            title: '',
            cellClassName: 'cell-fixed',
            sorting: false,
            render: ({ id }) => {
                return (
                    <Button className='btn-table' variant='primary' onClick={ () => onClickEditGroup(id)} square={false}>Перейти</Button>
                );
            },
        },
    ];

    const filteredMeterGroups = useMemo(() => {
        return filterMeterGroups(formData, meterGroups);
    }, [formData, meterGroups]);

    const onClickCreateGroup = () => {
        dispatch(push(getRoute(Routes.meterGroupCreate)));
    };

    return View({
        columnsDefinition,
        form,
        onClickCreateGroup,
        meterGroupsList: filteredMeterGroups,
        groupNameSuggestions,
        groupTypeSuggestions,
        userSuggestions
    });
};
