import { useParams } from 'react-router-dom';
import { ReportType } from '../../model';
import { View } from './view';

export const ReportPage = () => {
    const { reportType } = useParams<{ reportType: ReportType }>();
    if (!reportType) {
        return null;
    }
    return View({
        reportType,
    });
};
