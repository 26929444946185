import { Box, Button, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { IStateProps, ValueViewProps } from './model';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100% - 26px)',
        overflow: 'hidden'
    },
    label: {
        marginBottom: 15,
    },
    inputArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        height: '100%',
        overflow: 'hidden'
    },
    leftBox: {
        flex: '1',
        height: '100%',
        overflow: 'hidden',
        border: `1px solid ${theme.palette.neutral.dark}`,
        borderRadius: 4,
    },
    middleBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: '0 0 200px',
        height: '100%',
        overflow: 'hidden'
    },
    rightBox: {
        flex: '1',
        height: '100%',
        overflow: 'hidden',
        border: `1px solid ${theme.palette.neutral.dark}`,
        borderRadius: 4,
    },
}));

const useScrollContainerStyles = makeStyles(() => ({
    root: {
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        height: '100%',
    }
}));

const useValueViewStyles = makeStyles((theme) => ({
    root: {
        boxSizing: 'border-box',
        width: '100%',
        padding: 5,
        '&:hover': {
            cursor: 'pointer',
        }
    },
    active: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.neutral.white,
    },
}));

const ValueView = (props: ValueViewProps) => {
    const { label, onClick, isActive } = props;
    const classes = useValueViewStyles();
    return <Box
        onClick={onClick}
        className={classNames(classes.root, {
            [classes.active]: isActive,
        })}
    >
        {label}
    </Box>;
};

const ScrollContainer: React.FC = ({ children }) => {
    const classes = useScrollContainerStyles();
    return <Box className={classes.root}>{children}</Box>;
};

export const View = (props: IStateProps) => {
    const {
        label,
        onClickLeftOption,
        onClickRightOption,
        leftSelectedIndex,
        rightSelectedIndex,
        onClickMoveLeft,
        onClickMoveRight,
        onClickMoveLeftForce,
        onClickMoveRightForce,
        selectableOptions,
        selectedOptions,
        rightSelectedValue,
        leftSelectedValue,
    } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            {label && (
                <Box component='div' className={classes.label}>
                    {label}
                </Box>
            )}
            <Box className={classes.inputArea}>
                <Box className={classes.leftBox}>
                    <ScrollContainer>
                        {selectableOptions.map((option, index) => (
                            <ValueView
                                key={option.value}
                                label={option.label}
                                isActive={index === leftSelectedIndex}
                                onClick={() => onClickLeftOption(index)}
                            />
                        ))}
                    </ScrollContainer>
                </Box>
                <Box className={classes.middleBox}>
                    <Box mb={2}>
                        <Button
                            disabled={!rightSelectedValue}
                            onClick={onClickMoveLeft}
                            variant="outlined"
                            size="small"
                            color="primary"
                        >
                            {'<-'}
                        </Button>
                    </Box>
                    <Box mb={2}>
                        <Button
                            disabled={!leftSelectedValue}
                            onClick={onClickMoveRight}
                            variant="outlined"
                            size="small"
                            color="primary"
                        >
                            {'->'}
                        </Button>
                    </Box>
                    <Box mb={2}>
                        <Button
                            disabled={selectedOptions.length === 0}
                            onClick={onClickMoveLeftForce}
                            variant="outlined"
                            size="small"
                            color="primary"
                        >
                            {'<--'}
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            disabled={selectableOptions.length === 0}
                            onClick={onClickMoveRightForce}
                            variant="outlined"
                            size="small"
                            color="primary"
                        >
                            {'-->'}
                        </Button>
                    </Box>
                </Box>
                <Box className={classes.rightBox}>
                    <ScrollContainer>
                        {selectedOptions.map((option, index) => (
                            <ValueView
                                key={option.value}
                                label={option.label}
                                isActive={index === rightSelectedIndex}
                                onClick={() => onClickRightOption(index)}
                            />
                        ))}
                    </ScrollContainer>
                </Box>
            </Box>
        </Box>
    );
};
