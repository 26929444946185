import { View } from './view';
import { InputProps } from './model';
import { useEffect, useRef, useState } from 'react';
import hljs from 'highlight.js';
import jsonLanguage from 'highlight.js/lib/languages/json';
hljs.registerLanguage('json', jsonLanguage);
let isPluginAdded = false;

const getColorParts = (el: any) => {
    const color = window.getComputedStyle(el).color || '';
    let c: RegExpExecArray = null;
    // <https://www.regular-expressions.out/numericranges.html>
    // eslint-disable-next-line no-cond-assign,max-len
    if (c = /^rgba\s*\(\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*[, ]\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*[, ]\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*[, ]\s*([01]|0?\.\d+)\s*\)$/i.exec(color)) {
        return [+c[1], +c[2], +c[3], +c[4]];
    }
    // eslint-disable-next-line no-cond-assign,max-len
    if (c = /^rgb\s*\(\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*[, ]\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*[, ]\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*\)$/i.exec(color)) {
        return [+c[1], +c[2], +c[3], 1];
    }
    return [0, 0, 0, 1];
};


if (!isPluginAdded) {
    isPluginAdded = true;
    hljs.addPlugin({
        'after:highlightBlock': (props) => {
            const block = props.block as HTMLElement;
            const blockParent = block.parentNode as HTMLElement,
                blockHasParent = blockParent && 'pre' === blockParent.nodeName.toLowerCase(),
                lines = document.createElement('code'),
                numbers = [];
            lines.classList.add('hljs-lines');
            const existentLines = blockParent.querySelector('.hljs-lines');
            if (existentLines) {
                existentLines.remove();
            }
            if (blockHasParent) {
                for (let i = 0, j = block.textContent.split(/\n/).length; i < j; ++i) {
                    numbers.push(i + 1);
                }
                blockParent.insertBefore(lines, block);
                blockParent.style.display = 'flex';
                lines.innerHTML = numbers.join('\n');
                lines.style.textAlign = 'right';
                lines.style.userSelect = 'none'; // Disable selection
                lines.classList.add('hljs'); // Inherit `background` and `padding` from the style sheet
                const rgba = getColorParts(lines);
                lines.style.borderRight = '2px solid rgba(' + rgba[0] + ',' + rgba[1] + ',' + rgba[2] + ',' + (rgba[3] / 10) + ')';
                block.style.flex = '1';
            }
        }
    });
}

export const HighlightJson = (props: InputProps) => {
    const ref = useRef<HTMLPreElement>();
    const [json, setJson] = useState<string>('');
    useEffect(() => {
        try {
            const processedJson = JSON.stringify(props.data, null, '  ');
            setJson(processedJson);
        } catch (e) {
            setJson('');
        }
    }, [props.data]);
    useEffect(() => {
        hljs.highlightElement(ref.current);
    }, [json]);
    return View({
        ...props,
        ref,
        json,
    });
};
