import { Routes } from '../../constants';

export enum MenuItemType {
    meters = 'meters',
    metersDropdown = 'metersDropdown',
    monitoring = 'monitoring',
    report = 'report',
    eventLog = 'eventLog',
    administration = 'administration',
    relatedSystems = 'relatedSystems',
}

export interface MenuConfigurationItem {
    name: string;
    menuItemType: MenuItemType;
    icon: any;
    route: Routes | string;
    disabled?: boolean;
    isTab?: boolean;
}

export interface MenuConfiguration {
    main: MenuConfigurationItem[];
}

export interface IActions {
    onMenuItemClick?: (route: Routes | string) => void;
}

export interface IStateProps extends IActions {
    menu: MenuConfiguration;
    activeMenu: MenuItemType;
}

export interface OwnProps {
    activeMenu: MenuItemType;
    onMenuItemClick?: (route: Routes | string) => void;
}
