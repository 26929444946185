import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { getDictionaries } from '../../../../redux/actions/dictionaries';
import { dictionariesSelector } from '../../../../redux/selectors/dictionaries';
import { Routes } from '../../../shared/constants';
import { getRoute } from '../../../shared/pipes';
import { ViewReportRender } from '../../reports/view';
import { CustomDictionariesDataItem, DictionariesData, DictionaryDataItemType, MeterDictionariesDataItem } from './model';
import { View } from './view';
import { useAccessRules } from '../../../shared/hooks/useAccessRules';
import { AccessRule } from '../../../../common/model/access/accessRule';

export const Dictionaries = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const data = useSelector(dictionariesSelector);
    const { hasAccess } = useAccessRules();
    const isReadEventTypes = hasAccess(AccessRule.CanReadEventTypes);

    useEffect(() => {
        dispatch(getDictionaries());
    }, []);

    let buildData = [
        ...data.map<MeterDictionariesDataItem>(item => ({
            type: DictionaryDataItemType.MeterDictionaryItem,
            data: item,
        })),
        {
            type: DictionaryDataItemType.CustomDictionaryItem,
            data: {
                title: 'Типы приборов учета',
                url: getRoute(Routes.meterModels),
            }
        } as CustomDictionariesDataItem,
        {
            type: DictionaryDataItemType.CustomDictionaryItem,
            data: {
                title: 'Базовый календарь специальных дней',
                url: getRoute(Routes.specialDayBase),
            }
        } as CustomDictionariesDataItem,
        {
            type: DictionaryDataItemType.CustomDictionaryItem,
            data: {
                title: 'Типы меток',
                url: getRoute(Routes.meterLabelType),
            }
        } as CustomDictionariesDataItem,
        {
            type: DictionaryDataItemType.CustomDictionaryItem,
            data: {
                title: 'Версии метрологического ПО ПУ',
                url: getRoute(Routes.meterFirmwareVersions),
            }
        } as CustomDictionariesDataItem,

    ];

    if (isReadEventTypes) {
        buildData = buildData.concat({
            type: DictionaryDataItemType.CustomDictionaryItem,
            data: {
                title: 'Типы событий',
                url: getRoute(Routes.eventTypes),
            }
        } as CustomDictionariesDataItem);
    }

    return View({
        data: buildData,
        columns: [
            {
                title: 'Справочник',
                field: 'data.title',
                sorting: true,
                cellClassName: 'col-title'
            },
            {
                title: null,
                field: 'key',
                sorting: false,
                render: (rowData: DictionariesData) => {
                    return ViewReportRender({
                        disabled: false,
                        onClick: async () => {
                            switch (rowData.type) {
                                case DictionaryDataItemType.MeterDictionaryItem: {
                                    const defaultRoute = getRoute(Routes.meterDictionary, { dictionaryName: rowData.data.name });
                                    await dispatch(push(defaultRoute));
                                    break;
                                }
                                case DictionaryDataItemType.CustomDictionaryItem: {
                                    await dispatch(push(rowData.data.url));
                                    break;
                                }
                                default: {
                                    // do nothing
                                }
                            }
                        }
                    });
                }
            }
        ],
    });
};
