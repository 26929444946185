import React, { ReactHTMLElement, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { controlsSnackbarSelector } from '../../../redux/selectors';
import { addDisplayed, removeDisplayed, removeSnackbar } from '../../../redux/actions/controls/snackbar';

export const Notifier = (): ReactHTMLElement<any> => {
    const dispatch = useDispatch();
    const notifications = useSelector(controlsSnackbarSelector());
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    React.useEffect((): void => {
        notifications.notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                closeSnackbar(key);
                dispatch(removeSnackbar(key));
                return;
            }

            if (notifications.displayed.includes(key)) {
                return;
            }

            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event: SyntheticEvent<any>, ...reason: any) => {
                    if (options.onClose) {
                        options.onClose(event, reason[1]);
                    }
                },
                onExited: (...reason: any): void => {
                    dispatch(removeDisplayed(reason[1]));
                    dispatch(removeSnackbar(reason[1]));
                },
            });
            dispatch(addDisplayed(key));
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
    return null;
};
