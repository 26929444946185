import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { VAutocomplete } from '../autocomplete';
import { TextAutocomplete } from '../autocomplete/textAutcomplete';
import { Checkbox } from '../checkbox';
import { DatePicker } from '../datePicker';
import { Icon } from '../icon';
import { MultiSelect } from '../multiSelect';
import { MultiselectDropdown } from '../multiselectwithsearch';
import { Radio } from '../radio';
import { Select } from '../select';
import { SwitchInput } from '../switch';
import { TextInput } from '../textInput';
import { Search } from '../search';
import { TimePicker } from '../timePicker';
import { Tooltip } from '../tooltip';
import { TwoSideMultiselect } from '../twoSideMultiselect';
import { InputProps, Props } from './model';
import { Textarea } from '../textarea';
import { TimeWindow } from '../timeWindow';
import { AutoselectDictionaryInput } from '../autoselectDictionaryInput';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        '& .MuiInput-underline:before': {
            display: 'none',
        },
    },
    infoTooltip: {
        position: 'absolute',
        right: -8,
        top: -8,
        borderRadius: '50%',
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    icon: {
        color: theme.palette.text.hint,
    },
}));

export const HookField = (props: Props & InputProps) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const classes = useStyles();

    const { name, infoTooltipText, transform } = props;
    const error = !!errors[name];
    const errorMessage = error ? errors[name].message : undefined;

    const getField = (fieldProps: any) => {
        const transformerProps = transform
            ? {
                value: transform?.input
                    ? transform.input(fieldProps.value)
                    : fieldProps.value,
                onChange: transform?.output
                    ? (v: any) => fieldProps.onChange(transform.output(v))
                    : fieldProps.onChange,
            }
            : {};

        switch (props.type) {
            case 'checkbox': {
                return (
                    <Checkbox
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        checked={!!fieldProps.value}
                        {...transformerProps}
                    />
                );
            }
            case 'autoselectDictionaryInput': {
                return (
                    <AutoselectDictionaryInput
                        {...props}
                        error={error}
                        errorText={errorMessage}
                        {...fieldProps}
                        {...transformerProps}
                    />
                );
            }
            case 'select': {
                return (
                    <Select
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'search': {
                return (
                    <Search
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'datepicker': {
                return (
                    <DatePicker
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'timepicker': {
                return (
                    <TimePicker
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'multiselect': {
                return (
                    <MultiSelect
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'multiselect-with-search': {
                return (
                    <MultiselectDropdown
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'two-side-multiselect': {
                return (
                    <TwoSideMultiselect
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'radio': {
                return (
                    <Radio
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'switch': {
                return (
                    <SwitchInput
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        checked={!!fieldProps.value}
                        {...transformerProps}
                    />
                );
            }
            case 'autocomplete': {
                return (
                    <VAutocomplete
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'autocompleteText': {
                return (
                    <TextAutocomplete
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'textarea': {
                return (
                    <Textarea
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            case 'timeWindow': {
                return (
                    <TimeWindow
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
            default: {
                return (
                    <TextInput
                        {...props}
                        {...fieldProps}
                        error={error}
                        helperText={errorMessage}
                        {...transformerProps}
                    />
                );
            }
        }
    };

    return (
        <div className={classes.root}>
            <Controller
                control={control}
                name={props.name}
                render={({ field }) => getField(field)}
            />
            {infoTooltipText && (
                <span className={classNames(classes.infoTooltip, 'infoTooltip')}>
                    <Tooltip text={infoTooltipText}>
                        <Icon
                            className={classes.icon}
                            name="help"
                            style={{ width: 16, height: 16 }}
                        />
                    </Tooltip>
                </span>
            )}
        </div>
    );
};
