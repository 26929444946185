import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

const suggestionsSelector = (state: StorageState) => state.addresses.suggestions;

export const addressSuggestionsSelector = createSelector(
    suggestionsSelector,
    suggestions => suggestions.addressSuggestions
);

export const addressSuggestionsLoadingSelector = createSelector(
    suggestionsSelector,
    suggestions => suggestions.isAddressSuggestionsLoading
);

export const areaNamesSuggestionsSelector = createSelector(
    suggestionsSelector,
    suggestions => suggestions.areaNamesSuggestions
);

export const townNamesSuggestionsSelector = createSelector(
    suggestionsSelector,
    suggestions => suggestions.townNamesSuggestions
);

export const streetNamesSuggestionsSelector = createSelector(
    suggestionsSelector,
    suggestions => suggestions.streetNamesSuggestions
);

export const houseNumbersSuggestionsSelector = createSelector(
    suggestionsSelector,
    suggestions => suggestions.houseNumbersSuggestions
);
