import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Icon } from '../../../../shared/components/icon';
import { Tooltip } from '../../../../shared/components/tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    textWrapper: {
        display: 'flex',
        alignItems: 'center',
        ...theme.typography.body,
        color: theme.palette.text.primary,
    },
    success: {
        color: theme.palette.system.success,
    },
    error: {
        color: theme.palette.system.error,
    },
    icon: {
        display: 'inline-flex',
        marginRight: 4,
    },
    attemptCountText: {
        marginLeft: 4,
        color: theme.palette.text.hint,
        ...theme.typography.label,
    },
}));

export interface TariffScheduleOrderIndicatorProps {
    status: 'success' | 'error' | 'inProgress';
    title: string;
    attemptCount?: number;
    tooltip?: string;
    hidden: boolean;
}

// TODO: реализовать
export const TariffScheduleOrderIndicator: React.FC<TariffScheduleOrderIndicatorProps> = ({
    attemptCount,
    tooltip,
    status,
    hidden,
    title,
}) => {
    const classes = useStyles();
    const getStatusIcon = () => {
        switch (status) {
            case 'inProgress': {
                return <CircularProgress size={20} color='primary' />;
            }
            case 'success': {
                return <Icon name='success' />;
            }
            case 'error': {
                return <Icon name='warningCircle' />;
            }
            default: {
                return null;
            }
        }
    };
    if (hidden) {
        return null;
    }

    const content = (
        <Box className={classNames(classes.root)}>
            <Box className={classNames(classes.textWrapper, {
                [classes.success]: status === 'success',
                [classes.error]: status === 'error',
            })}>
                <Box className={classes.icon}>
                    {getStatusIcon()}
                </Box>
                {title} {!!attemptCount && <span className={classes.attemptCountText}>(попытка {attemptCount})</span>}
            </Box>
        </Box>
    );

    if (!tooltip) {
        return content;
    }

    return (
        <Tooltip text={tooltip}>
            {content}
        </Tooltip>
    );
};
