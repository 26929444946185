import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { Button } from '../../../../shared/components/button';
import { Chart } from '../../../../shared/components/chart';
import { HookField } from '../../../../shared/components/hookField';
import { Table } from '../../../../shared/components/table';
import { VisualizationSource } from '../../../../shared/components/table/model';
import { Wrapper } from '../../../../shared/components/wrapper';
import { defaults, Tables } from '../../../../shared/constants';
import { meterConsumptionIntervals } from '../../../../shared/constants/enums';
import { transformEndDay, transformStartDay } from '../../../../shared/utils/dates';
import { IStateProps } from './model';
import { FormProvider } from 'react-hook-form';
import { useCommonTableStyles } from '../../style';
import { electricityUnit } from '../../../../../common/constants/units';

export const View = (props: IStateProps) => {
    const {
        data,
        form,
        onSubmit,
        onClickExcelExport,
        columns,
        chartId,
        series,
        xaxis,
        yaxis,
        tableRef,
        isTableAvailable,
    } = props;
    const theme = useTheme();

    const colors = [
        theme.palette.primary.light,
        theme.palette.text.hint,
        theme.palette.system.success,
        theme.palette.system.warning,
    ];
    const classes = useCommonTableStyles();

    if (!data) {
        return null;
    }

    const filter = (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                        <HookField
                            fullWidth
                            name='dateFrom'
                            type='datepicker'
                            label='Начало'
                            transform={{
                                output: transformStartDay,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                        <HookField
                            fullWidth
                            name='dateTo'
                            type='datepicker'
                            label='Конец'
                            transform={{
                                output: transformEndDay,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={4} lg={2} xl={2}>
                        <HookField
                            fullWidth
                            name='groupingInterval'
                            type='select'
                            label='Интервал'
                            suggestions={meterConsumptionIntervals}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={1} xl={1}>
                        <Button fullWidth variant='primary' type='submit'>Показать</Button>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2} xl={2}>
                        <Button onClick={onClickExcelExport} fullWidth variant='outline' type='button' icon='excel'>Выгрузить в Excel</Button>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );

    const actions = (
        <Grid className={classes.actions} container spacing={2}>
            <Grid item className={classes.actionsFilter}>{filter}</Grid>
        </Grid>
    );

    return (
        <Wrapper
            title='ПРОФИЛЬ A+ A- R+ R-'
            isBigHeader
            actions={actions}
            isHeaderUppercase={false}
            className={classes.wrapper}
            headerColor='white'
            flexShrink={4}
        >
            {isTableAvailable && (
                <Table
                    visualizationSource={VisualizationSource.clientProfile}
                    name={Tables.meterConsumptionElectricityTable}
                    columns={columns}
                    data={data}
                    title='Потребление'
                    variant='white'
                    useQueryString={true}
                    options={{
                        headerClassName: classes.tableCols,
                        showTitle: false,
                        toolbar: false,
                        grouping: false,
                        columnsButton: false,
                        filtering: false,
                        search: false,
                        maxBodyHeight: 480,
                    }}
                    fullScreenHeightDelta={defaults.fullScreenHeightDeltaForOneTable}
                    tableRef={tableRef}
                />
            )}
            <Chart
                chartId={chartId}
                title={electricityUnit}
                series={series}
                xaxis={xaxis}
                yaxis={yaxis}
                colors={colors}
                backgroundColor='middle'
            />
        </Wrapper>
    );
};
