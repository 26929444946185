import { Box, Grid, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { DateFormats } from '../../../../../common/constants/date';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Button } from '../../../../shared/components/button';
import { Heading } from '../../../../shared/components/heading';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Block } from '../../../../shared/components/wrapper/block';
import { BlockPropsItem } from '../../../../shared/components/wrapper/block/model';
import { spreadItemsByCondition } from '../../../../shared/pipes/spreadItemsByCondition';
import { formatDate, getCurrentDate } from '../../../../shared/utils/dates';
import { CounterBlock } from '../components/counterBlock';
import { MassOperationProcessCard } from '../components/massOperationProcessCard';
import { ProgressColors, ProgressGroup } from '../components/massOperationProgressGraph/model';
import { MassOperationStatsMessage } from '../components/massOperationStatsMessage';
import { ParallelTasksInfo } from '../components/parallelTasksInfo';
import { IStateProps } from './model';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: theme.palette.neutral.middleBg,
    },
    description: {
        ...theme.typography.body,
        color: theme.palette.text.hint,
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const {
        heading,
        reFetch,
        isLoading,
        isComplete,
        breadcrumbs,
        onClickExcel,
        onClickRepeat,
        massOperation,
        onBackHandler,
        hasNotCompleted,
        isGraphicsVisible,
        onClickBreadcrumb,
    } = props;

    const classes = useStyles();

    const now = getCurrentDate();

    const nowMinutes = now.getMinutes();

    const nowFormatted = useMemo(() => {
        return formatDate(now, DateFormats.dateTimeZoneFormat);
    }, [nowMinutes]);

    const blockItems: BlockPropsItem[] = [
        {
            title: 'Регион',
            value: massOperation.region?.name ?? '–',
            type: 'text',
        },
        {
            title: 'Группа ПУ',
            value: massOperation.meterGroup?.name ?? '–',
            type: 'text',
        },
        {
            title: 'Начало периода опроса',
            value: massOperation.localBeginReadingDate
                ? formatDate(massOperation.localBeginReadingDate, DateFormats.dateTimeZoneFormat)
                : '–',
            type: 'text',
        },
        {
            title: 'Конец периода опроса',
            value: nowFormatted,
            type: 'text',
        },
    ];

    const actions = <>
        <Grid container spacing={2}>
            <Grid item lg={7}>
                <Block
                    type='horizontal'
                    items={blockItems}
                />
            </Grid>
            <Grid item lg={5}>
                <Grid container spacing={2} justify='flex-end'>
                    {isComplete && (
                        <>
                            <Grid item>
                                <Button
                                    icon='excel'
                                    type='button'
                                    variant='primary'
                                    onClick={onClickExcel}
                                    isLoading={isLoading}
                                >
                                    Выгрузить в Excel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    icon='refresh'
                                    type='button'
                                    variant='outline'
                                    onClick={onClickRepeat}
                                    isLoading={isLoading}
                                    disabled={hasNotCompleted}
                                >
                                    Повторить опрос
                                </Button>
                            </Grid>
                        </>
                    )}
                    {!isComplete && (
                        <Grid item>
                            <Button
                                icon='refresh'
                                type='button'
                                variant='outline'
                                onClick={reFetch}
                                isLoading={isLoading}
                            >
                                Обновить
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    </>;

    return <>
        <Box mb={2}>
            <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
        </Box>
        <Box mb={2}>
            <Heading heading={heading} onBackHandler={onBackHandler} />
        </Box>
        <Box>
            <Wrapper isBigHeader className={classes.wrapper} actions={actions}>
                <Box>
                    <Box mb={1}>
                        <Grid container spacing={4}>
                            <Grid item>
                                <CounterBlock
                                    isHeading
                                    label='Количество ПУ'
                                    value={`${massOperation?.meterCount ?? '–'}`}
                                />
                            </Grid>
                            {isComplete && (
                                <>
                                    <Grid item>
                                        <CounterBlock
                                            label='Из них без ошибок'
                                            value={`${massOperation?.successReading ?? '–'}`}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CounterBlock
                                            label='Частично с ошибками'
                                            value={`${massOperation?.partialSuccessReading ?? '–'}`}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CounterBlock
                                            label='Полностью с ошибками'
                                            value={`${massOperation?.errorReading ?? '–'}`}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                    {massOperation?.stats && (
                        <Box className={classes.description}>
                            <MassOperationStatsMessage
                                massOperation={massOperation}
                            />
                        </Box>
                    )}
                    {isGraphicsVisible && (
                        <>
                            <Box mt={2}>
                                <MassOperationProcessCard
                                    title='1. Создание задач, отправка в сервер опроса'
                                    blockProps={{
                                        type: 'horizontal',
                                        items: [
                                            {
                                                title: 'Начало',
                                                type: 'text',
                                                value: massOperation.createTaskBeginDate
                                                    ? formatDate(massOperation.createTaskBeginDate, DateFormats.dateTimeZoneFormat)
                                                    : '–',
                                            },
                                            {
                                                title: 'Окончание',
                                                type: 'text',
                                                value: (isComplete && massOperation.createTaskEndDate)
                                                    ? formatDate(massOperation.createTaskEndDate, DateFormats.dateTimeZoneFormat)
                                                    : '–',
                                            },
                                        ],
                                    }}
                                    graphicProps={{
                                        isComplete,
                                        title: 'Создано задач',
                                        total: massOperation.taskCount,
                                        dataGroups: [
                                            {
                                                value: massOperation.createdTasksCount,
                                                color: ProgressColors.Green,
                                            },
                                        ],
                                    }}
                                />
                            </Box>
                            <Box mt={2}>
                                <MassOperationProcessCard
                                    title='2. Обработка задач в сервере опроса, включая ожидание ответа ПУ'
                                    blockProps={{
                                        type: 'horizontal',
                                        items: [
                                            {
                                                title: 'Начало',
                                                type: 'text',
                                                value: massOperation.readingBeginDate
                                                    ? formatDate(massOperation.readingBeginDate, DateFormats.dateTimeZoneFormat)
                                                    : '–',
                                            },
                                            {
                                                title: 'Окончание',
                                                type: 'text',
                                                value: (isComplete && massOperation.readingEndDate)
                                                    ? formatDate(massOperation.readingEndDate, DateFormats.dateTimeZoneFormat)
                                                    : '–',
                                            },
                                        ],
                                    }}
                                    graphicProps={{
                                        isComplete,
                                        title: 'Обработано задач',
                                        total: massOperation.taskCount,
                                        dataGroups: [
                                            ...spreadItemsByCondition<ProgressGroup>(
                                                !!massOperation.errorTaskCount,
                                                [
                                                    {
                                                        value: massOperation.errorTaskCount,
                                                        color: ProgressColors.Red,
                                                    },
                                                ]
                                            ),
                                            {
                                                value: massOperation.completedTaskCount - massOperation.errorTaskCount,
                                                color: ProgressColors.Green,
                                            },
                                        ],
                                    }}
                                />
                            </Box>
                            <Box mt={2}>
                                <MassOperationProcessCard
                                    title='3. Сохранение результатов в БД'
                                    blockProps={{
                                        type: 'horizontal',
                                        items: [
                                            {
                                                title: 'Начало',
                                                type: 'text',
                                                value: massOperation.saveResultBeginDate
                                                    ? formatDate(massOperation.saveResultBeginDate, DateFormats.dateTimeZoneFormat)
                                                    : '–',
                                            },
                                            {
                                                title: 'Окончание',
                                                type: 'text',
                                                value: (isComplete && massOperation.saveResultEndDate)
                                                    ? formatDate(massOperation.saveResultEndDate, DateFormats.dateTimeZoneFormat)
                                                    : '–',
                                            },
                                        ],
                                    }}
                                    graphicProps={{
                                        isComplete,
                                        title: 'Сохранено задач в БД',
                                        total: massOperation.taskCount,
                                        dataGroups: [
                                            {
                                                value: massOperation.completedTaskCount,
                                                color: ProgressColors.Green,
                                            },
                                        ],
                                    }}
                                />
                            </Box>
                        </>
                    )}
                    {isComplete && (
                        <Box mt={2}>
                            <ParallelTasksInfo
                                count={massOperation.concurrentTask ?? 0}
                                label='В это время обработано задач регулярного опроса'
                                tooltipTest='Количество задач, не входящих в массовый опрос, над которыми  система работала во время выполнения массового опроса (создание, обработка, сохранение результатов).'
                            />
                        </Box>
                    )}
                </Box>
            </Wrapper>
        </Box>
    </>;
};
