import { CrqSystemViewModel } from 'common/model/meter/crq/crqSystemViewModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CrqSystemState {
    available: CrqSystemViewModel[];
}

const initialState: CrqSystemState = {
    available: null,
};

export const crqSystemListSlice = createSlice({
    name: '@@vv/crqSystem/list',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<CrqSystemViewModel[]>): void {
            state.available = action.payload;
        },
        update(state, { payload }: PayloadAction<CrqSystemViewModel>): void {
            if (state.available) {
                state.available = state.available.map(item => {
                    if (item.id === payload.id) {
                        return payload;
                    }
                    return item;
                });
            }
        },
        deleteCrqSystem(state, action: PayloadAction<{id: number}>) {
            state.available = state.available?.filter((i: CrqSystemViewModel) => i.id !== action.payload.id);
        }
    },
});
