import { MeterReadingIncrementalProfileViewModel } from '../../../../../../../common/model/meter/meter/meterReadingIncrementalProfileViewModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeterReadingIncrementalProfileListResponse } from '../../../../../common/model/meter/meter/meterReadingIncrementalProfileListResponse';
import { MeterReadingIncrementalProfileFilter } from '../../../../../common/model/meter/meter/meterReadingIncrementalProfileFilter';
import {
    getCurrentDate,
    getDateMonthBefore
} from '../../../../../app/shared/utils/dates';
import { MeterReadingDailyProfileFilter } from '../../../../../common/model/meter/meter/meterReadingDailyProfileFilter';


export interface IncrementalProfilesListState {
    available: MeterReadingIncrementalProfileViewModel[];
    total: number;
    filter: MeterReadingIncrementalProfileFilter;
}

const initialState: IncrementalProfilesListState = {
    available: null,
    total: 0,
    filter: {
        dateFrom: getDateMonthBefore(),
        dateTo: getCurrentDate(),
    }
};

export const incrementalProfilesListSlice = createSlice({
    name: '@@vv/meterReading/incrementalProfiles/list',
    initialState,
    reducers: {
        setAvailableIncrementalProfiles(state, action: PayloadAction<MeterReadingIncrementalProfileListResponse>) {
            state.available = action.payload.data;
            state.total = action.payload.total;
        },
        setFilter(state, action: PayloadAction<MeterReadingDailyProfileFilter>) {
            state.filter = action.payload;
        },
        clearFilter(state) {
            state.filter = initialState.filter;
        }
    }
});
