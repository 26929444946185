import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { Breadcrumbs } from '../../../shared/components/breadcrumbs';
import { Heading } from '../../../shared/components/heading';
import { HookField } from '../../../shared/components/hookField';
import { setOrderTransformer } from '../../../shared/components/pagination/helpers';
import { Table } from '../../../shared/components/table';
import { VisualizationSource } from '../../../shared/components/table/model';
import { Tables } from '../../../shared/constants';
import { MeterFirmwareVersionForm } from './components/meterFirmwareVersionForm';
import { IStateProps } from './model';
import { Modal } from '../../../shared/components/modal';
import { Wrapper } from '../../../shared/components/wrapper';
import { Button } from '../../../shared/components/button';
import { FormProvider } from 'react-hook-form';
import { useStyles } from './styles';

export const View: React.FC<IStateProps> = (props) => {
    const {
        form,
        data,
        heading,
        columns,
        setOrder,
        formModel,
        isLoading,
        orderParams,
        onCloseModal,
        onSubmitForm,
        onClickNewVersion,
        breadcrumbs,
        onBackHandler,
        onClickBreadcrumb,
        meterModelMaskSuggestions,
    } = props;

    const classes = useStyles();

    const actions = (
        <>
            <Grid container justify='space-between' spacing={2}>
                <Grid item xs={12} sm={5} md={4} lg={3}>
                    <FormProvider {...form}>
                        <HookField
                            fullWidth
                            label='Тип ПУ (маска)'
                            type='select'
                            name='meterModelMask'
                            suggestions={meterModelMaskSuggestions}
                            isClearable
                        />
                    </FormProvider>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        icon='add'
                        variant='primary'
                        onClick={onClickNewVersion}
                        type='button'
                    >
                        Новая версия
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    return <>
        <Box mb={2}>
            <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
        </Box>
        <Box mb={2}>
            <Heading heading={heading} onBackHandler={onBackHandler} />
        </Box>
        <Wrapper isBigHeader actions={actions}>
            <div className={classes.tableContainer}>
                <Table
                    variant='white'
                    visualizationSource={VisualizationSource.clientProfile}
                    name={Tables.meterFirmwareVersionsTable}
                    columns={columns}
                    data={data}
                    title={null}
                    useQueryString={false}
                    options={{
                        paging: 'disabled',
                        columnsButton: false,
                        filtering: false,
                        search: false,
                        showTitle: false,
                        toolbar: false,
                    }}
                    onOrderChange={setOrderTransformer(columns, setOrder)}
                    {...orderParams}
                />
            </div>
        </Wrapper>
        {!!formModel && (
            <Modal title='Новая версия ПО' size='md' onHide={onCloseModal}>
                <MeterFirmwareVersionForm
                    isLoading={isLoading}
                    source={formModel}
                    onSubmit={onSubmitForm}
                    onCancel={onCloseModal}
                />
            </Modal>
        )}
    </>;
};
