import { createSelector } from 'reselect';
import { StorageState } from '../../reducers';

const baseSelector = (state: StorageState) => state.meterChangePasswordOrders;

export const meterChangePasswordOrdersListSelector = createSelector(
    baseSelector,
    state => state.list.available,
);

export const meterChangePasswordOrdersControlListSelector = createSelector(
    baseSelector,
    state => state.control.list,
);

export const meterChangePasswordOrdersControlLatestSelector = createSelector(
    baseSelector,
    state => state.control.latestTask,
);
