import { Column } from '@softmedialab/materialui-table';
import { ChangeEvent, ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { AdvancedCrashReportQuery } from '../../../../common/model/meter/reports/advancedCrashReportQuery';
import { AdvancedCrashReportRow } from '../../../../common/model/meter/reports/advancedCrashReportRow';
import { AutocompleteOptionsHookReturn } from '../../../shared/components/autocomplete/model';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { WithMonitoringAdvancedCrashReport } from '../model';

export type AdvancedCrashReportFormData = Omit<AdvancedCrashReportQuery, 'reportId' | 'regionId'>;

export enum AdvancedCrashReportTabs {
    DailyProfileReadings = 'daily-profile-readings',
    CurrentProfileReadings = 'current-profile-readings',
    IncrementalProfileReadings = 'incremental-profile-readings',
    ConfigurationSnapshot = 'configuration-snapshot',
    ValidateOrChangeTariff = 'validate-or-change-tariff',
    ElectricPowerQualityEvents = 'electric-power-quality-events',
    PowerSwitchEvents = 'power-switch-events',
    DataCorrectionEvents = 'data-correction-events',
    TamperingEvents = 'tampering-events',
    AccessControlEvents = 'access-control-events',
    VoltageEvents = 'voltage-events',
    CurrentEvents = 'current-events',
    SelfDiagnosticEvents = 'self-diagnostic-events',
}

export interface AdvancedCrashReportTab {
    key: AdvancedCrashReportTabs;
    title: ReactNode;
}

export interface AdvancedCrashReportProps extends WithMonitoringAdvancedCrashReport {
    regionId: number;
}

export interface IStateProps extends AdvancedCrashReportProps {
    data: AdvancedCrashReportRow[];
    columns: Column[];
    regionSuggestions: SelectSuggestion[];
    isLoading: boolean;
    isReportFormed: boolean;
    isReportExcelNotAvailable: boolean;
    meterGroupAutocomplete: AutocompleteOptionsHookReturn;
    form: UseFormReturn<AdvancedCrashReportFormData>;
    onClickFormReport: () => Promise<void>;
    onClickRefresh: () => Promise<void>;
    onClickFormExcel: () => Promise<void>;
    onChangeTab: (e: ChangeEvent<any>, value: AdvancedCrashReportTabs) => void;
    tabs: AdvancedCrashReportTab[];
    activeTab: AdvancedCrashReportTabs;
}
