import React, { Ref, useState } from 'react';
import { Select, FormControl, InputLabel, MenuItem, useTheme, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import noun from '../../../../common/shared/utils/noun';
import { IStateProps, MultiSelectSuggestion } from './model';
import { Typography } from '../typography';
import { Icon } from '../icon';
import { Checkbox } from '../checkbox';
import { Tooltip } from '../tooltip';
import { useStyles } from './styles';

export const View = React.forwardRef(({
    value,
    label,
    onChange,
    fullWidth,
    disabled,
    dark,
    suggestions,
    isTwoColumns,
    handleClear,
    error,
    helperText,
    required,
}: IStateProps, ref: Ref<HTMLElement>) => {
    const [visibilityTooltip, setVisibilityTooltip] = useState<boolean>(false);
    const classes = useStyles();
    const theme = useTheme();

    const onMouseOut = (e: React.MouseEvent<HTMLElement>) => {
        return e.currentTarget.offsetWidth < e.currentTarget.scrollWidth;
    };

    const onClickClear = () => {
        if (handleClear) {
            handleClear();
        } else {
            onChange([]);
        }
    };

    const renderClearIcon = () => {
        if (value.length === 0) {
            return null;
        }
        return (
            <Icon
                name='close'
                fillColor={theme.palette.primary.light}
                fontSize='small'
                className={classes.clearListIcon}
                onClick={onClickClear}
            />
        );
    };

    const Suggestion = ({ name }: { name: string }) => {
        if (visibilityTooltip) {
            return (
                <Tooltip text={name} overflowHidden>
                    <Typography
                        className={classes.suggestionName}
                        variant='input'
                        onMouseOut={() => setVisibilityTooltip(false)}
                    >{name}</Typography>
                </Tooltip>
            );
        }
        return (
            <Typography
                className={classes.suggestionName}
                variant='input'
                onMouseOver={(e) => setVisibilityTooltip(onMouseOut(e))}
            >{name}</Typography>
        );
    };

    return (
        <FormControl className={classNames(classes.formControl, { [classes.fullWidth]: fullWidth, [classes.darkSelect]: dark })}>
            <InputLabel required={required} className={classNames({ [classes.titleError]: error })}>{label}</InputLabel>
            <Select
                required={required}
                classes={{ icon: classes.icon }}
                variant='outlined'
                value={value}
                onChange={(e) => onChange(e.target.value as MultiSelectSuggestion[])}
                disabled={disabled}
                fullWidth={fullWidth}
                label={label}
                IconComponent={(props) => <Icon name='arrowSmallDown' iconColor={theme.palette.primary.light} { ...props } />}
                className={classNames({ [classes.darkSelect]: dark })}
                MenuProps={{
                    MenuListProps: {
                        className: classNames({ [classes.isTwoColumns]: isTwoColumns })
                    },
                    anchorOrigin: {
                        vertical: 48,
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null,
                    classes: { list: classes.list, paper: classes.paper },
                    className: classNames({ [classes.darkSelect]: dark })
                }}
                renderValue={() => `${value.length} ${noun(value.length, ['значение', 'значения', 'значений'])} выбрано`}
                ref={ref}
                endAdornment={renderClearIcon()}
                error={error}
                multiple
            >
                {suggestions.map(suggestion => (
                    <MenuItem
                        key={suggestion.value}
                        value={suggestion.value}
                        classes={{ root: classes.MenuItemRoot }}
                        className={classNames({ [classes.darkMenuItem]: dark })}
                    >
                        <Checkbox
                            checked={value.map(item => item.toString()).includes(suggestion.value.toString())}
                            label=''
                            onChange={() => null}
                        />
                        <Suggestion name={suggestion.name} />
                    </MenuItem>
                ))}
            </Select>
            {error && helperText && (
                <FormHelperText className={classes.errorText}>{helperText}</FormHelperText>
            )}
        </FormControl>
    );
});
