import { push } from 'connected-react-router';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BaseDispatch } from '../../../redux/actions';
import { AdministrationTabs, Routes } from '../../shared/constants';
import { getRoute } from '../../shared/pipes';
import { meterGroupsSelector } from '../../../redux/selectors/meters';

export const MeterGroupResolver = (viewComponent: React.FC<any>) => {
    return (props: any) => {
        const dispatch = useDispatch<BaseDispatch>();
        const meterGroups = useSelector(meterGroupsSelector);
        const { groupId } = useParams<{ groupId: string; }>();
        const group = useMemo(() => meterGroups.find(item => item.id === +groupId), [meterGroups, groupId]);

        React.useEffect(() => {
            if (!group) {
                dispatch(push(getRoute(Routes.administration, {}, { tab: AdministrationTabs.Groups })));
            }
        }, [group]);

        if (!group) {
            return null;
        }

        return React.createElement(viewComponent, {
            ...props,
            group,
        });
    };
};
