import * as React from 'react';

import './style.scss';
import { CloseIcon } from '../../../../assets/icons';
import { Button } from '../button';
import { IProps } from './model';
import classNames from 'classnames';
import { KeyCodes } from '../globalKeyboardListener';
import { Typography } from '../typography';
import { Box } from '@material-ui/core';

export const View: React.FC<IProps> = ({
    title,
    show,
    onHide,
    size,
    close,
    description,
    actions,
    type,
    children,
    className,
    modalNodeEl,
    variant,
    width = null,
    alignSide = 'center',
    alignDialog = 'center',
}) => {
    if (!show) {
        return null;
    }

    const getPaddingLeft = () => {
        if (alignSide === 'flex-start') {
            return '60px';
        }
        return 'none';
    };

    return (
        <Box>
            <div className={classNames(
                'modal',
                'fade',
                'show',
            )} role='dialog' ref={modalNodeEl}>
                <Box className={classNames(
                    'modal-dialog',
                    `modal_${alignDialog}`,
                    {
                        [`modal-${size}`]: !!size,
                        [`modal-type-${type}`]: !!type,
                        [`modal-variant-${variant}`]: !!variant,
                    },
                    className,
                )} role='document'
                maxWidth={width}>
                    <Box className='modal-content'>
                        <Box className='modal-header' justifyContent={alignSide} paddingLeft={getPaddingLeft()}>
                            {
                                !!title && (
                                    <Typography variant='h1' className='h3 modal-title'>
                                        {title}
                                    </Typography>
                                )
                            }
                            {
                                !!description && (
                                    <Box className='modal-description'>
                                        {description}
                                    </Box>
                                )
                            }
                            {onHide && (
                                <Box
                                    title={close}
                                    onClick={onHide}
                                    tabIndex={0}
                                    className='modal-close'
                                    onKeyPress={({ keyCode }) => {
                                        if (keyCode === KeyCodes.Enter) {
                                            onHide();
                                        }
                                    }}
                                >
                                    <CloseIcon />
                                </Box>
                            )}
                        </Box>
                        <Box className='modal-body'>
                            {children}
                        </Box>
                        {actions &&
                        <Box className='modal-footer'>
                            {actions
                                .map((actionButton, i) =>
                                    <Button {...actionButton} key={i} />
                                )}
                        </Box>
                        }
                    </Box>
                </Box>
            </div>
            <div className='modal-backdrop' />
        </Box>
    );
};
