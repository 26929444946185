import React from 'react';
import { ListItemIcon, makeStyles } from '@material-ui/core';
import { TabIconOwnProps } from './model';

const useStyles = makeStyles({
    listItemIconRoot: {
        minWidth: '24px',
        marginRight: '-2px',
        marginLeft: '18px',
    },
});

export const View: React.FC<TabIconOwnProps> = ({ image }) => {
    const classes = useStyles();

    return (
        <ListItemIcon
            classes={{ root: classes.listItemIconRoot }}
        >{image}</ListItemIcon>
    );
};
