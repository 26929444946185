import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Typography } from '../../typography';

const useStyles = makeStyles((theme) => ({
    addAddressLink: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginTop: '8px',
        '&:hover': {
            textDecoration: 'none',
        }
    },
}));

export interface AddAddressLinkProps {
    onClick: () => void;
}

export const AddAddressLink: React.FC<AddAddressLinkProps> = (props) => {
    const { onClick } = props;
    const classes = useStyles();
    return (
        <Typography variant='bodyBold' className={classes.addAddressLink} onClick={onClick}>
            + Добавить адрес
        </Typography>
    );
};
