/**
 * Статус пользователя
 */
export enum UserStatus {
    Active = 'Active',
    Block = 'Block'
}

export const userStatusDictionary: Record<UserStatus, string> = {
    [UserStatus.Active]: 'Активный',
    [UserStatus.Block]: 'Заблокирован',
}
