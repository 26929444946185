import { Column } from '@softmedialab/materialui-table';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { MeterViewModel } from '../../../common/model/meter/meter/meterViewModel';

export enum MetersMode {
    Default = 'default',
    Create = 'create',
    Update = 'update',
}

export interface IModel {
    data: MeterViewModel[];
    meter: MeterViewModel | undefined;
    mode: MetersMode;
}

export interface IActions {
    onClickNewMeter: () => void;
    onCloseCreateModal: () => void;
    onSubmit: SubmitHandler<MeterViewModel>;
}

export interface IStateProps extends IModel, IActions {
    columnDefinition: Column[];
}
