import { Column } from '@softmedialab/materialui-table';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { HookFieldTransformer } from '../../../shared/components/hookField/model';
import { SelectSuggestion } from '../../../shared/components/select/model';
import { MeterGroupViewModel } from '../../../../common/model/meter/meterGroup/meterGroupViewModel';

export interface GroupsProps {
    columnsDefinition: Column[];
    form: UseFormReturn<GroupsFilter>;
    meterGroupsList: MeterGroupViewModel[];
    groupNameSuggestions: SelectSuggestion[];
    userSuggestions: SelectSuggestion[];
    groupTypeSuggestions: SelectSuggestion[];
    onClickCreateGroup: () => void;
}

export enum GroupStaticType {
    Static = 'static',
    Dynamic = 'dynamic',
}

export interface GroupsFilter {
    regionId: number,
    name: string,
    userId: number,
    visibleToAll: boolean,
    groupType: GroupStaticType,
}

export const groupTypeTransformer: HookFieldTransformer = {
    input: (v) => {
        return v === true
            ? GroupStaticType.Static
            : v === false
                ? GroupStaticType.Dynamic
                : null;
    },
    output: (e) => {
        const v = e.target.value;
        return v === GroupStaticType.Static;
    },
};
