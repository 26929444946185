import { LocationDescriptorObject } from 'history';
import { useLocation } from 'react-router-dom';

export const getBackUrlFromLocation = (): LocationDescriptorObject => {
    const backUrl = new URLSearchParams(location.search).get('backUrl');
    if (backUrl) {
        try {
            return JSON.parse(atob(backUrl)) as LocationDescriptorObject;
            // eslint-disable-next-line no-empty
        } catch {}
    }
    return null;
};

export const useBackUrl = (): LocationDescriptorObject => {
    return getBackUrlFromLocation();
};

export const useMakeBackUrl = (): () => string => {
    const location = useLocation();
    return () => btoa(JSON.stringify(location));
};
