import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Breadcrumbs } from '../../../../shared/components/breadcrumbs';
import { Button } from '../../../../shared/components/button';
import { Heading } from '../../../../shared/components/heading';
import { HookField } from '../../../../shared/components/hookField';
import { Wrapper } from '../../../../shared/components/wrapper';
import { Modal } from '../../../../shared/components/modal';
import { IStateProps } from './model';
import { FormProvider } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    headingWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    confirmTitle: {
        ...theme.typography.h2,
        color: theme.palette.text.primary,
    },
    confirmText: {
        ...theme.typography.body,
        color: theme.palette.neutral.main,
    },
    modalWrapper: {
        '& .modal-body': {
            padding: '0 24px 24px !important',
        },
    },
    flagSetTimeField: {
        maxWidth: 350,
        '& .infoTooltip': {
            top: 0,
            right: 0,
        }
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const {
        form,
        scheme,
        onSubmit,
        isLoading,
        isDeleting,
        schemeName,
        isNewScheme,
        breadcrumbs,
        onClickClear,
        onClickDelete,
        onCancelDelete,
        onConfirmDelete,
        onClickBreadcrumb,
    } = props;

    const { handleSubmit, formState: { isDirty } } = form;

    const classes = useStyles();

    return (
        <>
            <Box>
                <Box mb={1}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onClickBreadcrumb} />
                </Box>
                <Box mb={2}>
                    <div className={classes.headingWrapper}>
                        <Heading heading={schemeName} />
                        {!isNewScheme && (
                            <Box>
                                <Button
                                    icon='delete'
                                    variant='outline'
                                    onClick={onClickDelete}
                                    disabled={scheme.isUsed}
                                >Удалить</Button>
                            </Box>
                        )}
                    </div>
                </Box>
                <Box>
                    <Wrapper>
                        <FormProvider {...form}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <HookField
                                            fullWidth
                                            name='id'
                                            type='text'
                                            label='ИД'
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <HookField
                                            fullWidth
                                            name='name'
                                            type='text'
                                            label='Название'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <HookField
                                            fullWidth
                                            name='minTimeError'
                                            type='number'
                                            label='Допуск рассинхронизации, сек'
                                            infoTooltipText='Значение расхождения, меньше которого корректировка времени не требуется'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <HookField
                                            fullWidth
                                            name='maxTimeError'
                                            type='number'
                                            label='Корректируемое расхождение, сек'
                                            infoTooltipText='Значение расхождения, больше которого время не корректируется автоматически'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <HookField
                                            fullWidth
                                            name='timeShift'
                                            type='number'
                                            label='Шаг корректировки, сек'
                                            infoTooltipText='Шаг корректировки. Величина, на которую происходит корректировка времени за один раз.'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className={classes.flagSetTimeField}>
                                        <HookField
                                            name='flagSetTime'
                                            type='checkbox'
                                            label='Принудительная установка разрешена'
                                            infoTooltipText='Признак разрешения принудительной установки времени, в случае если расхождение превышает верхнуюю границу корректируемого расхождения.'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Button
                                                    type='submit'
                                                    icon='check'
                                                    variant='primary'
                                                    disabled={isLoading}
                                                >
                                                    Сохранить
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    type='button'
                                                    icon='close'
                                                    variant='outline'
                                                    onClick={onClickClear}
                                                    disabled={isLoading || !isDirty}
                                                >
                                                    Очистить
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    </Wrapper>
                </Box>
            </Box>
            {isDeleting && (
                <Modal
                    alignDialog='center'
                    className={classes.modalWrapper}
                    size='sm'
                    width='480px'
                    onHide={onCancelDelete}
                >
                    <Box mb={1} className={classes.confirmTitle}>
                        Удаление схемы синхронизации времени
                    </Box>
                    <Box mb={2} className={classes.confirmText}>
                        Вы уверены, что хотите удалить схему синхронизации?
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button onClick={onConfirmDelete} variant='primary'>Удалить</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={onCancelDelete} variant='secondary'>Отмена</Button>
                        </Grid>
                    </Grid>
                </Modal>
            )}
        </>
    );
};
