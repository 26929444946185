import download from 'downloadjs';
import iconv from 'iconv-lite';

export const downloadExcelData = (data: string, filename: string) => {
    const buffer = new Buffer(data, 'base64');
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    download(blob, `${filename}.xlsx`);
};

export const downloadXmlDataWindows1251Encoding = (data: string, filename: string) => {
    const byteArrayWin1251 = iconv.encode(
        data,
        'win1251'
    );
    const blob = new Blob([byteArrayWin1251], { type: 'application/xml;charset=windows-1251' });
    download(blob, filename);
};
