import { NetworkOperatorShortViewModel } from '../../../../../../common/model/meter/networkOperator/networkOperatorShortViewModel';
import { AutocompleteOptionsHookReturn } from '../../../../../shared/components/autocomplete/model';
import { WithCreateAddress } from '../../../../../shared/components/createAddress/model';
import { MeterActivationOrderMode } from '../model';
import { MeterActivationOrderDetailsViewModel } from '../../../../../../common/model/meter/meterActivationOrder/meterActivationOrderDetailsViewModel';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { MeterActivationOrderCreator } from '../../../../../../common/model/meter/meterActivationOrder/meterActivationOrderCreator';
import { UseFormReturn } from 'react-hook-form/dist/types';

export enum ExpandedPanels {
    CommonParameters = 'CommonParameters',
    SecurityParameters = 'SecurityParameters',
    Communicator = 'Communicator',
    TechnicalParameters = 'TechnicalParameters',
}

export type ExpandedPanelsState = Record<ExpandedPanels, boolean>;

export interface MeterActivationOrderFormProps {
    mode: MeterActivationOrderMode;
    meterActivationOrder: MeterActivationOrderDetailsViewModel | undefined;
    onSubmit: SubmitHandler<MeterActivationOrderCreator>;
    onFormDirtyChange: (isDirty: boolean) => void;
}

export interface MeterActivationOrderFormViewProps extends MeterActivationOrderFormProps, WithCreateAddress {
    form: UseFormReturn<MeterActivationOrderCreator>;
    onResetForm: () => void;
    expandedPanels: ExpandedPanelsState;
    handleChangeExpanded: (data: ExpandedPanels) => (event: any, isExpanded: boolean) => void;
    canPostMeterActivationOrder: boolean;
    canReprocessMeterActivationOrder: boolean;
    addressAutocomplete: AutocompleteOptionsHookReturn;
    networkOperators: NetworkOperatorShortViewModel[];
}
