import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormats } from '../../../../common/constants/date';
import {
    MassOperationListQueryParams
} from '../../../../common/model/meter/massOperation/massOperationListQueryParams';
import { MassOperationShortViewModel } from '../../../../common/model/meter/massOperation/massOperationShortViewModel';
import {
    MassOperationStatus,
    massOperationStatusDictionary
} from '../../../../common/model/meter/massOperation/massOperationStatus';
import { BaseDispatch } from '../../../../redux/actions';
import { getMassOperations } from '../../../../redux/actions/massOperations';
import {
    massOperationsHasNotCompletedSelector,
    massOperationsListAvailableSelector
} from '../../../../redux/selectors/massOperations';
import { Button } from '../../../shared/components/button';
import { Routes } from '../../../shared/constants';
import { getRoute } from '../../../shared/pipes';
import { formatDate } from '../../../shared/utils/dates';
import { useCheckMassOperation } from './hooks/useCheckMassOperation';
import { InputProps } from './model';
import { View } from './view';

export const MassOperation: React.FC<InputProps> = (props) => {

    const {
        regionId,
    } = props;

    const dispatch = useDispatch<BaseDispatch>();

    const data = useSelector(massOperationsListAvailableSelector());
    const hasNotCompleted = useSelector(massOperationsHasNotCompletedSelector());

    const fetch = async (params: MassOperationListQueryParams) => {
        await dispatch(getMassOperations(params));
    };

    useCheckMassOperation();

    useEffect(() => {
        fetch({ regionId });
    }, [
        regionId,
    ]);

    const onClickOpenMassOperation = (massOperation: MassOperationShortViewModel) => {
        dispatch(push(getRoute(Routes.massOperation, { id: massOperation.id?.toString() })));
    };

    const onClickNewMassOperation = () => {
        dispatch(push(getRoute(Routes.massOperationCreate, {}, { regionId })));
    };

    const columns: Column[] = [
        {
            title: '№ опроса',
            field: 'id',
            sorting: true,
            defaultSort: 'desc',
        },
        {
            title: 'Регион',
            field: 'regionId',
            sorting: false,
            render: (data: MassOperationShortViewModel) => {
                return data.region?.name ?? '–';
            }
        },
        {
            title: 'Группа ПУ',
            field: 'meterGroupId',
            sorting: false,
            render: (data: MassOperationShortViewModel) => {
                return data.meterGroup?.name ?? '–';
            }
        },
        {
            title: 'Статус',
            field: 'status',
            sorting: false,
            render: (data: MassOperationShortViewModel) => {
                const statusView = <div>{massOperationStatusDictionary[data.status]}</div>;
                if (data.status === MassOperationStatus.Planed) {
                    return (
                        <>
                            {statusView}
                            <div>{formatDate(data.planDate, DateFormats.dateTimeZoneFormat)}</div>
                        </>
                    );
                }
                return statusView;
            },
        },
        {
            title: 'Дата запуска',
            field: 'beginProcessingDate',
            sorting: true,
            render: (data: MassOperationShortViewModel) => {
                if (!data.beginProcessingDate) {
                    return '';
                }
                return formatDate(
                    data.beginProcessingDate,
                    DateFormats.dateTimeZoneFormat,
                );
            },
        },
        {
            title: 'Дата завершения',
            field: 'endProcessingDate',
            sorting: true,
            render: (data: MassOperationShortViewModel) => {
                if (!data.endProcessingDate) {
                    return '';
                }
                return formatDate(
                    data.endProcessingDate,
                    DateFormats.dateTimeZoneFormat,
                );
            },
        },
        {
            title: 'Кол-во ПУ',
            field: 'meterCount',
            sorting: false,
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            render: (data: MassOperationShortViewModel) => {
                return (
                    <Button
                        type='button'
                        variant='primary'
                        onClick={() => onClickOpenMassOperation(data)}
                    >
                        Открыть
                    </Button>
                );
            }
        },
    ];

    return View({
        ...props,
        data,
        columns,
        hasNotCompleted,
        onClickNewMassOperation,
    });
};
