import { Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { HookField } from '../../../shared/components/hookField';
import { Typography } from '../../../shared/components/typography';
import { Button } from '../../../shared/components/button';
import { AddressFormViewProps } from './model';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginBottom: '24px',
        color: theme.palette.text.secondary,
    },
    icon: {
        fontSize: 10
    }
}));

export const View = (props: AddressFormViewProps) => {
    const classes = useStyles();
    const {
        cityForm,
        onSubmit,
        onCancelCity,
        onSearchStreetName,
        onSearchAreaName,
        onSearchHouseNumber,
        onSearchTownName,
        onSearchRegions,
        isAddressSuggestionsLoading,
        houseNumbersSuggestions,
        streetNamesSuggestions,
        townNamesSuggestions,
        areaNamesSuggestions,
        regions
    } = props;
    const { handleSubmit } = cityForm;
    return (
        <FormProvider {...cityForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                        <Grid item >
                            <Typography variant='bodyBold'>Регион</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} lg={8} xl={8}>
                            <HookField
                                name='regionId'
                                type='autocomplete'
                                label='Выберите субъект РФ'
                                options={regions}
                                onInputChange={(e, val) => onSearchRegions(val)}
                                fullWidth
                                noOptionsText='Субъект не найден'
                                loadingText='Поиск...'
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                        <Grid item >
                            <Typography variant='bodyBold'>Район</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} lg={8} xl={8}>
                            <HookField
                                name='areaName'
                                type='autocomplete'
                                label='Выберите район'
                                options={areaNamesSuggestions}
                                loading={isAddressSuggestionsLoading}
                                onInputChange={(e, val) => onSearchAreaName(val)}
                                fullWidth
                                noOptionsText='Район не найден'
                                loadingText='Поиск...'
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                        <Grid item >
                            <Typography variant='bodyBold'>Населенный пункт</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} lg={8} xl={8}>
                            <HookField
                                name='townName'
                                type='autocomplete'
                                label='Выберите населенный пункт'
                                options={townNamesSuggestions}
                                loading={isAddressSuggestionsLoading}
                                onInputChange={(e, val) => onSearchTownName(val)}
                                fullWidth
                                noOptionsText='Город не найден'
                                loadingText='Поиск...'
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                        <Grid item >
                            <Typography variant='bodyBold'>Улица</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} lg={8} xl={8}>
                            <HookField
                                name='streetName'
                                type='autocomplete'
                                label='Выберите улицу'
                                options={streetNamesSuggestions}
                                loading={isAddressSuggestionsLoading}
                                onInputChange={(e, val) => onSearchStreetName(val)}
                                fullWidth
                                noOptionsText='Улица не найдена'
                                loadingText='Поиск...'
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                        <Grid item >
                            <Typography variant='bodyBold'>Номер здания</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} lg={8} xl={8}>
                            <HookField
                                name='houseNumber'
                                type='autocomplete'
                                label='Выберите дом'
                                options={houseNumbersSuggestions}
                                loading={isAddressSuggestionsLoading}
                                onInputChange={(e, val) => onSearchHouseNumber(val)}
                                fullWidth
                                noOptionsText='Дом не найден'
                                loadingText='Поиск...'
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justify='flex-end' alignItems='center' className={classes.container}>
                        <Grid item >
                            <Typography variant='bodyBold'>Помещение</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} lg={8} xl={8}>
                            <HookField
                                type='text'
                                name='addressSpecifier'
                                label='Укажите помещение'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={12}>
                            <Grid container spacing={2} justify='center'>
                                <Grid item>
                                    <Button variant='primary' type='submit' icon='check'>Выбрать</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={onCancelCity} variant='outline' type='button' icon='close'>Очистить</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
