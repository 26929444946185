import classNames from 'classnames';
import React from 'react';
import { List, makeStyles } from '@material-ui/core';
import { dataTargetIdAttribute, StateProps } from './model';
import { OverflowMenu } from './overflowMenu';


const useIntersectionStyles = makeStyles({
    visible: {
        order: 0,
        visibility: 'visible',
        opacity: 1,
    },
    inVisible: {
        order: 100,
        visibility: 'hidden',
        pointerEvents: 'none',
    },
    wrapper: {
        display: 'flex',
        overflow: 'hidden',
        height: '100%',
    },
    overflowStyle: {
        order: 99,
        position: 'sticky',
        right: 0,
    }
});

export const View: React.FC<StateProps> = ({ children, navRef, visibilityMap }) => {
    const classes = useIntersectionStyles();

    return (
        <List className={classes.wrapper} ref={navRef} disablePadding>
            {React.Children.map(children, (child, idx) => {
                if (!React.isValidElement(child)) {
                    return null;
                }
                return React.cloneElement(child, {
                    className: classNames(child.props.className, {
                        [classes.visible]: !!visibilityMap[child.props[dataTargetIdAttribute]],
                        [classes.inVisible]: !visibilityMap[child.props[dataTargetIdAttribute]],
                    }),
                    key: idx,
                });
            })}

            <OverflowMenu
                visibilityMap={visibilityMap}
                className={classes.overflowStyle}
            >
                {children}
            </OverflowMenu>

        </List>
    );
};
