import { TreeView } from './view';
import React, { useState } from 'react';
import { RenderItemsProps } from './model';

export const Tree: React.FC<RenderItemsProps> = ({ items, handleClickItem }): JSX.Element => {
    const [visibilityTooltip, setVisibilityTooltip] = useState<boolean>(false);

    return TreeView({
        items,
        handleClickItem,
        visibilityTooltip,
        setVisibilityTooltip,
    });
};
