import { combineReducers } from 'redux';
import { massOperationsDetailsSlice, MassOperationsDetailsState } from './details';
import { massOperationsListSlice, MassOperationsListState } from './list';

export interface MassOperationsState {
    list: MassOperationsListState;
    details: MassOperationsDetailsState;
}

export const massOperations = combineReducers<MassOperationsState>({
    list: massOperationsListSlice.reducer,
    details: massOperationsDetailsSlice.reducer,
});
