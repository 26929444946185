import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Button } from '../../../../../shared/components/button';
import { HookField } from '../../../../../shared/components/hookField';
import { Typography } from '../../../../../shared/components/typography';
import { meterLegalTypes, tariffCountSuggestions } from '../../../../../shared/constants/enums';
import { useWebDictionarySelectSuggestions } from '../../../../../shared/hooks/useWebDictionary';
import { IStateProps } from './model';

export const useStyles = makeStyles(() => ({
    inputLabel: {
        textAlign: 'right',
    },
    buttonContainer: {
        paddingTop: '16px',
    },
}));

export const View: React.FC<IStateProps> = (props) => {
    const classes = useStyles();
    const { form, onSubmit, onCancel } = props;
    const { handleSubmit, formState: { isDirty } } = form;

    const { regions: regionSuggestions } = useWebDictionarySelectSuggestions();

    return <>
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>Регион</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='regionId'
                                    type='select'
                                    label='Регион'
                                    placeholder='Выберите субъект РФ'
                                    suggestions={regionSuggestions}
                                    fullWidth />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Количество<br />
                                    тарифов
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='tariffCount'
                                    type='select'
                                    label='Количество тарифов'
                                    isClearable
                                    suggestions={tariffCountSuggestions}
                                    fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={4} className={classes.inputLabel}>
                                <Typography variant='bodyBold'>
                                    Правовая <br />
                                    форма
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <HookField
                                    name='legalEntityType'
                                    type='select'
                                    label='Правовая форма'
                                    suggestions={meterLegalTypes}
                                    isClearable
                                    fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                            <Grid item>
                                <Button variant='primary' type='submit' disabled={!isDirty} icon='check'>Сохранить</Button>
                            </Grid>
                            <Grid item>
                                <Button variant='secondary' type='button' onClick={onCancel}>Отмена</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    </>;
};
