import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveAccessRules } from '../../../../redux/actions/access';
import { getMeterSchemes } from '../../../../redux/actions/meterSchemes';

import { getResources } from '../../../../redux/actions/resources';
import { BaseDispatch } from '../../../../redux/actions';
import { isTranslationEmptySelector } from '../../../../redux/selectors';
import { meterSchemesSelector } from '../../../../redux/selectors/meterSchemes';
import { meterGroupsSelector } from '../../../../redux/selectors/meters';
import { getMeterGroups } from '../../../../redux/actions/meters';

export const ResourceProvider = ({ children }: any) => {
    const dispatch = useDispatch<BaseDispatch>();
    const isTranslationEmpty = useSelector(isTranslationEmptySelector());
    const meterSchemes = useSelector(meterSchemesSelector);
    const meterGroups = useSelector(meterGroupsSelector);

    useEffect(() => {
        dispatch(getActiveAccessRules());
        dispatch(getMeterGroups());
        dispatch(getResources());
        dispatch(getMeterSchemes());
    }, []);

    if (isTranslationEmpty) {
        return null;
    }

    if (!meterSchemes) {
        return null;
    }

    if (!meterGroups) {
        return null;
    }

    return children;
};
