import { modalNotificationsPop } from '../../../../redux/actions/controls/modalNotifications';
import { controlsModalNotificationsSelector } from '../../../../redux/selectors';
import { View } from './view';
import { useDispatch, useSelector } from 'react-redux';
import { ModalNotification } from '../../../../redux/reducers/controls/modalNotifications';

export const ModalNotifier = () => {
    const dispatch = useDispatch();
    const { notifications } = useSelector(controlsModalNotificationsSelector());
    return View({
        notifications,
        onHide: (notification: ModalNotification) => {
            if (notification.showHide) {
                return () => {
                    dispatch(modalNotificationsPop());
                };
            }
            return undefined;
        }
    });
};
