import { makeStyles, Theme } from '@material-ui/core';
import { setColumnWidth } from '../../../shared/pipes/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return ({
        wrapper: {
            padding: 0,
        },
        noDataText: {
            ...theme.typography.body,
            color: theme.palette.text.hint,
            paddingRight: '8px',
        },
        tableContainer: {
            '& .MuiPaper-elevation2': {
                boxShadow: 'none',
            },
            '& .cell-fixed': {
                backgroundColor: theme.palette.neutral.white,
                left: 0,
                ['@media (max-width: 1101px)']: {
                    position: 'relative !important',
                },
            },
            '& .cell-index': {
                ['@media (min-width: 1102px)']: {
                    left: 0,
                },
                ...setColumnWidth('60px'),
            },
            '& .cell-shortAddress': {
                ['@media (min-width: 1102px)']: {
                    left: 60,
                },
                ...setColumnWidth('120px'),
            },
            '& .cell-fullAddress': {
                ['@media (min-width: 1102px)']: {
                    left: 180,
                },
                ...setColumnWidth('180px'),
            },
            '& .cell-meterSerialNum': {
                ['@media (min-width: 1102px)']: {
                    left: 360,
                },
                ...setColumnWidth('100px'),
                wordBreak: 'break-all',
            },
            '& .cell-lastReadingDate': {
                ['@media (min-width: 1102px)']: {
                    left: 460,
                },
                ...setColumnWidth('140px'),
            },
            '& .cell-lastReadingValue': {
                ['@media (min-width: 1102px)']: {
                    left: 600,
                },
                ...setColumnWidth('120px'),
            },
            '& .cell-readingDate': {
                ...setColumnWidth('140px'),
            },
            '& .cell-tasksCount': {
                ...setColumnWidth('120px'),
            },
            '& .cell-readingDates': {
                ...setColumnWidth('200px'),
            },
            '& .cell-readingResults': {
                whiteSpace: 'nowrap',
            },
            '& .cell-readingProblems': {
                ...setColumnWidth('320px'),
            },
            '& .cell-readingLastSuccessDate': {
                ...setColumnWidth('140px'),
            },
            '& .cell-meterFirmwareVersion': {
                ...setColumnWidth('120px'),
            },
            '& .cell-criticalProblems': {
                ...setColumnWidth('200px'),
            },
        },
    });
});
