import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';
import classNames from 'classnames';

import { IStateProps } from './model';
import { TreeIcon } from '../../../../../assets/icons';


const useStyles = makeStyles((theme) => ({
    menuButton: {
        border: 'none',
        backgroundColor: 'transparent',
        padding: 0,
        width: 32,
        height: 32,
        cursor: 'pointer',
    },
    icon: {
        fill: theme.palette.neutral.white,
    },
    iconActive: {
        fill: theme.palette.primary.light,
    }
}));

export const View = ({ active, onClick, className }: IStateProps) => {

    const classes = useStyles();

    return (
        <button className={classNames(className, classes.menuButton)} onClick={onClick}>
            <SvgIcon
                className={classNames(classes.icon, { [classes.iconActive]: active })}
                height={32}
                width={32}
                component={TreeIcon}
            />
        </button>
    );
};
