import { push } from 'connected-react-router';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { EventTypeViewModel } from '../../../../common/model/meter/eventLog/eventTypeViewModel';
import { EventTypeFindRequest } from '../../../../common/model/meter/eventLog/eventTypeFindRequest';
import { useDispatch, useSelector } from 'react-redux';
import { BaseDispatch } from '../../../../redux/actions';
import { getEventTypes, updateEventType } from '../../../../redux/actions/eventTypes';
import { eventTypesListSelector, eventTypesTotalSelector, eventTypesLoadingSelector } from '../../../../redux/selectors';
import { AdministrationTabs, Routes } from '../../../shared/constants';
import { useBreadcrumbs } from '../../../shared/hooks/useBreadcrumbs';
import { useSyncQueryParams } from '../../../shared/hooks/useQueryParams';
import { useHeading } from '../../../shared/hooks/useHeading';
import { getRoute } from '../../../shared/pipes';
import { View } from './view';
import { ISort } from '../../../../common/sort';
import { usePagination } from '../../../shared/components/pagination';
import { createColumns } from './columns';
import { EventTypeUpdater } from '../../../../common/model/meter/eventLog/eventTypeUpdater';
import { useAccessRules } from '../../../shared/hooks/useAccessRules';
import { AccessRule } from '../../../../common/model/access/accessRule';
import { useEventTypeAutocomplete } from '../../../shared/components/autocomplete/hooks/useEventTypeAutocomplete';
import { useDebounce } from '../../../shared/hooks/useDebounce';

const initialFormValues: EventTypeFindRequest = {
    logTypeId: null,
    eventTypeIds: [],
    isCritical: null,
};

const fetchDebounceTimeMS = 600;

export const EventTypes: React.FC = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const data: EventTypeViewModel[] = useSelector(eventTypesListSelector);
    const dataTotal: number = useSelector(eventTypesTotalSelector);
    const loading: boolean = useSelector(eventTypesLoadingSelector);
    const { notHasAccess } = useAccessRules();
    const isDisabledUpdateEvent = notHasAccess(AccessRule.CanUpdateEventTypes);
    const eventTypeAutocomplete = useEventTypeAutocomplete();
    const [filterValues, setFilterValues] = useSyncQueryParams<EventTypeFindRequest>(initialFormValues);
    const form = useForm<EventTypeFindRequest>({ defaultValues: filterValues });
    const formData = form.watch();


    const breadcrumbProps = useBreadcrumbs([
        { name: 'Администрирование', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.Schemes }) },
        { name: 'Справочники', link: getRoute(Routes.administration, null, { tab: AdministrationTabs.Dictionaries }) },
        { name: 'Типы событий', link: null },
    ]);

    const headingProps = useHeading('Типы событий', () => {
        dispatch(push(getRoute(Routes.administration, null, { tab: AdministrationTabs.Dictionaries })));
    });

    const fetch = async (sort: ISort, params: EventTypeFindRequest = filterValues) => {
        await dispatch(getEventTypes({ ...sort, ...params }));
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 10,
        orderBy: null,
        orderDirection: null,
    }, fetch);

    const refreshTableData = (dataFilterParams: EventTypeFindRequest) => {
        onFetch(pagination, dataFilterParams);
    };

    const changesFormData = useDebounce(formData, fetchDebounceTimeMS);

    useEffect(() => {
        refreshTableData({ ...changesFormData });
    }, [changesFormData]);

    useEffect(() => {
        setFilterValues({
            logTypeId: formData.logTypeId,
            isCritical: formData.isCritical,
            eventTypeIds: formData.eventTypeIds,
        });

    }, [changesFormData]);


    const handleVisible = (id: number, val: EventTypeUpdater) => {
        dispatch(updateEventType(id, val));
    };

    const handleCritical = (id: number, val: EventTypeUpdater) => {
        dispatch(updateEventType(id, val));
    };

    const columns = useMemo(() => createColumns({ handleVisible, handleCritical }, isDisabledUpdateEvent), []);

    return View({
        ...breadcrumbProps,
        ...headingProps,
        data,
        dataTotal,
        pagination,
        setOrder,
        setPage,
        orderParams,
        columns,
        form,
        loading,
        eventTypeAutocomplete,
    });
};
