import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { Box, Link, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../assets/logoLight.svg';
import { appUrls } from '../../../common/appUrls';
import { BaseDispatch } from '../../../redux/actions';
import { activeUserSelector } from '../../../redux/selectors';
import { Button } from '../../shared/components/button';
import { Routes } from '../../shared/constants';
import { getApiRoute } from '../../shared/pipes';

const useStyles = makeStyles({
    loginContainer: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    logo: {
        height: '300px'
    }
});

export const View = () => {
    const classes = useStyles();

    const currentUser = useSelector(activeUserSelector());
    const dispatch = useDispatch<BaseDispatch>();

    useEffect(() => {
        if (currentUser) {
            dispatch(push(Routes.empty));
        }
    }, [currentUser]);

    const loginRoute = getApiRoute(appUrls.api_login);

    return <div className={classes.loginContainer}>
        <Box marginBottom={4}>
            <img src={logo} alt='logo' className={classes.logo} />
        </Box>
        <Box><Link href={loginRoute}><Button variant='primary'>Выполнить вход</Button></Link></Box>
    </div>;
};
