import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SystemLogViewResponse } from '../../../../common/model/logbook/systemLogViewResponse';
import { LogEntryViewModel } from '../../../../common/model/logbook/logEntryViewModel/logEntryViewModel';
import { SystemLogTokenResponse } from '../../../../common/model/meter/systemLog/systemLogTokenResponse';

export interface AuditLogsState {
    logs: LogEntryViewModel[];
    logsTotal: number;
    tokenResponse: SystemLogTokenResponse;
}

const initialState: AuditLogsState = {
    logs: null,
    logsTotal: 0,
    tokenResponse: null,
};

export const auditSlice = createSlice({
    name: '@@vv/audit',
    initialState,
    reducers: {
        setAuditLogs(state, action: PayloadAction<SystemLogViewResponse>): void {
            state.logs = action.payload.data;
            state.logsTotal = action.payload.total;
        },
        setAuditLogsToken(state, action: PayloadAction<SystemLogTokenResponse>): void {
            state.tokenResponse = action.payload;
        },
    }
});
