import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Grid, makeStyles } from '@material-ui/core';
import { MeterDictionariesNames } from '../../../../common/model/meter/meterDictionaries/meterDictionariesNames';
import { TaskFormViewProps } from './model';
import { HookField } from '../../../shared/components/hookField';
import { Button } from '../../../shared/components/button';

const createTaskFormStyles = makeStyles(() => ({
    formContainer: {
        paddingTop: '28px',
    },
    inputLabel: {
        textAlign: 'right',
    },
    buttonContainer: {
        paddingTop: '16px',
    },
    releaseDate: {
        paddingRight: '8px',
    },
}));

export const View: React.FC<TaskFormViewProps> = ({
    form,
    onSubmit,
    onCancel,
    saveAndClose,
}) => {
    const classes = createTaskFormStyles();
    const { handleSubmit, formState: { isDirty } } = form;

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.formContainer}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <HookField
                            type='autoselectDictionaryInput'
                            name='title'
                            dictionaryName={MeterDictionariesNames.GisTasks}
                            placeholder='Введите название'
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <HookField
                            type='textarea'
                            name='notes'
                            label='Введите примечание'
                            maxWidth='100%'
                            minWidth='100%'
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={2} className={classes.releaseDate}>
                                <HookField
                                    type='datepicker'
                                    name='resolvedAt'
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <HookField
                                    type='text'
                                    name='assignee'
                                    label='ФИО Исполнителя'
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <HookField
                            type='text'
                            name='resolution'
                            label='Решение'
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <HookField
                            type='textarea'
                            name='report'
                            label='Отчет'
                            maxWidth='100%'
                            minWidth='100%'
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='flex-start' className={classes.buttonContainer}>
                            <Grid item>
                                <Button
                                    variant='primary'
                                    type='submit'
                                    icon='check'
                                    disabled={!isDirty}
                                >
                                    Сохранить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='secondary'
                                    type='button'
                                    icon='check'
                                    disabled={!isDirty}
                                    onClick={saveAndClose}
                                >
                                    Сохранить и закрыть
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='text'
                                    type='button'
                                    onClick={onCancel}
                                    disabled={!isDirty}
                                >
                                    Отмена
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </form>
        </FormProvider>
    );
};
