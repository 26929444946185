import { useActualPage } from '../../hooks/useActualPage';
import { View } from './view';
import { IPropsState } from './model';

export const Pagination = (props: IPropsState) => {
    const actualPage = useActualPage((page) => {
        if (!props.isLoading) {
            props.onChangePage(page);
        }
    }, [props.count, props]);
    return View({
        ...props,
        page: actualPage,
    });
};

export { usePagination } from './usePagination';
