import { Modal } from '../modal';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '../typography';
import { Button } from '../button';
import React from 'react';
import { ActionModalProps } from './model';

const useStyles = makeStyles(() => ({
    buttonContainer: {
        paddingTop: '16px',
    },
}));

export const View: React.FC<ActionModalProps> = ({
    title,
    description,
    actions,
    closeModal,

}) => {
    const classes = useStyles();

    return (
        <Modal title={title} close='Отмена' onHide={closeModal} width='480px'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='caption'>
                        {description}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} justify='center' className={classes.buttonContainer}>
                        {actions.map(action => (
                            <Grid item key={action.title}>
                                <Button variant={action.variant} type='button' icon='check' onClick={action.onClick}>{action.title}</Button>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};
