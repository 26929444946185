import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SurveyConditionResponse } from '../../../../common/model/meter/monitoring/surveryCondition';

export interface SurveyConditionState {
    response: SurveyConditionResponse;
}

export const initialState: SurveyConditionState = {
    response: null,
};

export const surveyConditionSlice = createSlice({
    name: '@@vv/monitoring/surveyCondition',
    initialState,
    reducers: {
        setSurveyConditionResponse(state, action: PayloadAction<SurveyConditionResponse>): void {
            state.response = action.payload;
        },
    },
});
