import React from 'react';
import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import classNames from 'classnames';

export type RadioValue = number | string | boolean;

export interface RadioProps {
    values: {id: RadioValue; name: string}[];
    value?: RadioValue;
    onChange?: (value: RadioValue) => void;
    disabled?: boolean;
    isHorizontalGroup?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: theme.palette.text.hint,
        '&$checked': {
            color: theme.palette.primary.light
        },
        '&$disabled': {
            opacity: 0.5
        }
    },
    checked: {},
    disabled: {},
    radioGroupHorizontal: {
        display: 'flex',
        flexDirection: 'row',
    }
}));

export const View = ({ values, value, onChange, disabled, isHorizontalGroup = false }: RadioProps) => {
    const classes = useStyles();
    return (
        <FormControl component="fieldset">
            <RadioGroup
                value={value}
                onChange={(e, v) => onChange(v as any)}
                className={classNames({ [classes.radioGroupHorizontal]: isHorizontalGroup })}
            >
                {values.map((valueItem) => (
                    <FormControlLabel
                        key={valueItem.id?.toString()}
                        value={valueItem.id}
                        control={<Radio classes={classes} />}
                        label={valueItem.name}
                        disabled={disabled}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
