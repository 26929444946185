import { Column } from '@softmedialab/materialui-table';
import { push } from 'connected-react-router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormats } from '../../../../common/constants/date';
import { meterLegalTypeDictionary } from '../../../../common/model/meter/legalEntityType';
import { TariffScheduleCreator } from '../../../../common/model/meter/tariffSchedule/tariffScheduleCreator';
import {
    TariffScheduleGetQueryParams
} from '../../../../common/model/meter/tariffSchedule/tariffScheduleGetQueryParams';
import { TariffScheduleViewModel } from '../../../../common/model/meter/tariffSchedule/tariffScheduleViewModel';
import {
    TariffScheduleVersionViewModel
} from '../../../../common/model/meter/tariffScheduleVersion/tariffScheduleVersionViewModel';
import { TariffScheduleVersionStatus } from '../../../../common/model/meter/tariffScheduleVersionStatus';
import { BaseDispatch } from '../../../../redux/actions';
import { createSnackbar } from '../../../../redux/actions/controls';
import {
    createTariffSchedule,
    duplicateTariffScheduleVersion,
    getTariffSchedules,
} from '../../../../redux/actions/tariffSchedule';
import { tariffScheduleListSelector } from '../../../../redux/selectors/tariffSchedule';
import { Button } from '../../../shared/components/button';
import { Routes } from '../../../shared/constants';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { getRoute } from '../../../shared/pipes';
import { formatDate } from '../../../shared/utils/dates';
import { getErrorMessage } from '../../../shared/utils/error';
import { Link } from '../../../shared/components/link';
import { View } from './view';
import { resourcesRegionsListSelector } from '../../../../redux/selectors';

const filterDefaultValues: TariffScheduleGetQueryParams = {
    regionId: null,
    legalEntityType: null,
};

const debounceFetchMs = 200;

export const TariffSchedules: React.FC = () => {

    const dispatch = useDispatch<BaseDispatch>();

    const [isNewTariffScheduleOpen, setIsNewTariffScheduleOpen] = useState<boolean>(false);
    const regionsById = useSelector(resourcesRegionsListSelector())
        .reduce((acc, cur) => ({ ...acc, [cur.id]: cur.name }), {});

    const form = useForm<TariffScheduleGetQueryParams>({
        defaultValues: filterDefaultValues,
    });

    const formData = form.watch();

    const fetch = async () => {
        await dispatch(getTariffSchedules(formData));
    };

    const formDataDebounce = useDebounce(formData, debounceFetchMs);

    useEffect(() => {
        fetch();
    }, [formDataDebounce]);

    const data = useSelector(tariffScheduleListSelector);

    const duplicateTariffScheduleVersionFromPrev = async (
        tariffSchedule: TariffScheduleViewModel,
        tariffScheduleVersion: TariffScheduleVersionViewModel
    ) => {
        const {
            createdTariffScheduleVersion
        } = await duplicateTariffScheduleVersion(tariffSchedule, {
            ...tariffScheduleVersion,
            startDate: moment(tariffScheduleVersion.startDate).add(1, 'year').toDate(),
        });
        await dispatch(push(getRoute(
            Routes.tariffScheduleVersionEdit,
            { tariffScheduleId: tariffSchedule.id, tariffScheduleVersionId: createdTariffScheduleVersion.id }
        )));
    };

    const columns: Column[] = [
        {
            field: 'regionId',
            title: 'Регион',
            grouping: true,
            sorting: false,
            defaultGroupOrder: 0,
            lookup: regionsById,
        },
        {
            field: 'legalEntityType',
            title: 'Правовая форма',
            grouping: false,
            sorting: false,
            lookup: meterLegalTypeDictionary,
        },
        {
            field: 'tariffCount',
            title: 'Количество тарифов',
            grouping: false,
            sorting: false,
        },
        {
            field: 'currentScheduleVersion',
            grouping: false,
            sorting: false,
            title: 'Текущая версия',
            render: (item: TariffScheduleViewModel) => {
                if (!item.currentScheduleVersion) {
                    return <Link to={{
                        pathname: getRoute(Routes.tariffScheduleVersionCreate, { tariffScheduleId: item.id }),
                    }}>
                        <Button
                            variant='text'>
                            Создать
                        </Button>
                    </Link>;
                }
                const dateFormatted = formatDate(item.currentScheduleVersion.startDate, DateFormats.dateFormat);
                let title = `с ${dateFormatted}`;
                if (item.currentScheduleVersion.status === TariffScheduleVersionStatus.Draft) {
                    title = `${dateFormatted} (черновик)`;
                }
                return <Link to={{
                    pathname: getRoute(
                        Routes.tariffScheduleVersionEdit,
                        { tariffScheduleId: item.id, tariffScheduleVersionId: item.currentScheduleVersion.id }
                    ),
                }}>
                    <Button
                        variant='text'>
                        {title}
                    </Button>
                </Link>;
            },
        },
        {
            field: 'nextScheduleVersion',
            title: 'Следующая версия',
            grouping: false,
            sorting: false,
            render: (item: TariffScheduleViewModel) => {
                if (!item.nextScheduleVersion) {
                    if (item.currentScheduleVersion) {
                        return <Button
                            variant='text'
                            onClick={() => duplicateTariffScheduleVersionFromPrev(item, item.currentScheduleVersion)}
                        >
                            Создать
                        </Button>;
                    }
                    return <Link to={{
                        pathname: getRoute(Routes.tariffScheduleVersionCreate, { tariffScheduleId: item.id }),
                    }}>
                        <Button
                            variant='text'>
                            Создать
                        </Button>
                    </Link>;
                }
                const dateFormatted = formatDate(item.nextScheduleVersion.startDate, DateFormats.dateFormat);
                let title = `с ${dateFormatted}`;
                if (item.nextScheduleVersion.status === TariffScheduleVersionStatus.Draft) {
                    title = `${dateFormatted} (черновик)`;
                }
                return <Link to={{
                    pathname: getRoute(
                        Routes.tariffScheduleVersionEdit,
                        { tariffScheduleId: item.id, tariffScheduleVersionId: item.nextScheduleVersion.id }
                    ),
                }}>
                    <Button
                        variant='text'>
                        {title}
                    </Button>
                </Link>;
            },
        },
    ];

    const onClickNewSchedule = () => {
        setIsNewTariffScheduleOpen(true);
    };

    const onClickOrdersList = () => {
        dispatch(push(Routes.tariffScheduleOrders));
    };

    const onCancelNewTariffSchedule = () => {
        setIsNewTariffScheduleOpen(false);
    };

    const onSubmitNewTariffSchedule: SubmitHandler<TariffScheduleCreator> = async (formValues) => {
        try {
            const createdTariffSchedule = await createTariffSchedule(formValues);
            await fetch();
            onCancelNewTariffSchedule();
            dispatch(push(getRoute(Routes.tariffScheduleVersionCreate, { tariffScheduleId: createdTariffSchedule.id })));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
    };

    return View({
        form,
        data,
        columns,
        onClickOrdersList,
        onClickNewSchedule,
        isNewTariffScheduleOpen,
        onSubmitNewTariffSchedule,
        onCancelNewTariffSchedule,
    });
};
