import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes';
import { appUrls } from '../../../common/appUrls';
import { AppThunk } from '../index';
import { EventTypesFindResponse } from '../../../common/model/meter/eventLog/eventTypesFindResponse';
import { EventTypeFindRequest } from '../../../common/model/meter/eventLog/eventTypeFindRequest';
import { EventTypeViewModel } from '../../../common/model/meter/eventLog/eventTypeViewModel';
import { EventTypeUpdater } from '../../../common/model/meter/eventLog/eventTypeUpdater';
import { commonEventTypesSlice } from '../../reducers/eventTypes/list';
import { commonSearchEventTypesSlice } from '../../reducers/eventTypes/searchList';
import { createSnackbar } from '../../../redux/actions/controls';
import { getErrorMessage } from '../../../app/shared/utils/error';

export const getEventTypes = (
    params: EventTypeFindRequest,
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_find_event_types, {}, { ...params });
    dispatch(commonEventTypesSlice.actions.setLoadingEventTypes(true));
    try {
        const data = await httpService.get<EventTypesFindResponse>(route);
        dispatch(commonEventTypesSlice.actions.setAvailableEventTypes(data));
    } catch (e) {
        dispatch(createSnackbar({
            type: 'red',
            message: getErrorMessage(e),
        }));
    }
    dispatch(commonEventTypesSlice.actions.setLoadingEventTypes(false));
};

export const getSearchEventTypes = (
    params: EventTypeFindRequest,
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_find_event_types, {}, { ...params });

    dispatch(commonSearchEventTypesSlice.actions.setSearchLoadingEventTypes(true));
    try {
        const data = await httpService.get<EventTypesFindResponse>(route);
        dispatch(commonSearchEventTypesSlice.actions.setSearchAvailableEventTypes(data));
    } catch (e) {
        dispatch(createSnackbar({
            type: 'red',
            message: getErrorMessage(e),
        }));
    }
    dispatch(commonSearchEventTypesSlice.actions.setSearchLoadingEventTypes(false));

};

export const resetSearchEventTypes = (): AppThunk => (dispatch) => {
    dispatch(commonSearchEventTypesSlice.actions.resetSearchAvailableEventTypes());
};

export const updateEventType = (id: number, updater: EventTypeUpdater): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_update_event_type, { id });

    try {
        const eventType = await httpService.put<EventTypeViewModel>(route, updater);
        dispatch(commonEventTypesSlice.actions.updateAvailableEventTypes(eventType));
        dispatch(createSnackbar({
            type: 'green',
            message: 'Значение успешно изменено.',
        }));
    } catch (e) {
        dispatch(createSnackbar({
            type: 'red',
            message: getErrorMessage(e),
        }));
    }

};
