import { combineReducers } from 'redux';
import { leftMenuSlice, LeftMenuState } from './menu';
import { modalNotificationsSlice, ModalNotificationsState } from './modalNotifications';
import { snackbarSlice, SnackbarState } from './snackbar';


export interface ControlsState {
    leftMenu: LeftMenuState;
    snackbar: SnackbarState;
    modalNotifications: ModalNotificationsState;
}

export const controls = combineReducers<ControlsState>({
    leftMenu: leftMenuSlice.reducer,
    snackbar: snackbarSlice.reducer,
    modalNotifications: modalNotificationsSlice.reducer,
});
