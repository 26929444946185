import React, { useCallback, useEffect, useRef, useState } from 'react';
import { sleep } from '../../pipes';
import { Props } from './model';
import { View } from './view';

const sleepBeforeEventMs = 100;

export const InfiniteScroll: React.FC<Props> = (props) => {

    const {
        onScrollStart,
        onScrollEnd,
        scrollToSelector,
    } = props;

    const [isLoadingTop, setIsLoadingTop] = useState<boolean>(false);
    const [isLoadingBottom, setIsLoadingBottom] = useState<boolean>(false);

    const scrollDivRef = useRef<HTMLDivElement>();

    const isLoading = isLoadingBottom || isLoadingTop;

    const scrollToElementAfterScrollEnd = (element: HTMLElement) => {
        if (element) {
            scrollDivRef.current.scrollTo({
                top: element.offsetTop,
            });
        }
    };

    const onScrollStartWrapper = async () => {
        if (!onScrollStart) {
            return;
        }
        setIsLoadingTop(true);
        const elements = document.querySelectorAll<HTMLDivElement>(scrollToSelector);
        try {
            await sleep(sleepBeforeEventMs);
            await onScrollStart();
        } catch (e) {
            // DO NOTHING
        }
        await sleep(sleepBeforeEventMs);
        scrollToElementAfterScrollEnd(elements[0]);
        setIsLoadingTop(false);
    };

    const onScrollEndWrapper = async () => {
        if (!onScrollEnd) {
            return;
        }
        setIsLoadingBottom(true);
        const elements = document.querySelectorAll<HTMLDivElement>(scrollToSelector);
        try {
            await sleep(sleepBeforeEventMs);
            await onScrollEnd();
        } catch (e) {
            // DO NOTHING
        }
        await sleep(sleepBeforeEventMs);
        scrollToElementAfterScrollEnd(elements[elements.length - 1]);
        setIsLoadingBottom(false);
    };

    const handler = useCallback((e: Event) => {
        if (isLoading) {
            return;
        }
        const target = e.target as HTMLDivElement;
        const offsetTop = target.scrollTop;
        const offsetBottom = target.scrollHeight - (target.scrollTop + target.offsetHeight);
        if (offsetTop === 0) {
            onScrollStartWrapper().then();
        }
        if (offsetBottom === 0) {
            onScrollEndWrapper().then();
        }
    }, [onScrollStartWrapper, onScrollEndWrapper]);

    useEffect(() => {
        const handlerClone = handler;
        scrollDivRef.current.addEventListener('scroll', handlerClone);
        return () => {
            scrollDivRef.current.removeEventListener('scroll', handlerClone);
        };
    }, [handler]);

    return View({
        ...props,
        isLoading,
        isLoadingTop,
        isLoadingBottom,
        scrollDivRef,
    });
};
