import { useState, useEffect } from 'react';
import { View } from './view';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BaseDispatch } from 'redux/actions';
import { createSnackbar } from 'redux/actions/controls';
import { getErrorMessage } from 'app/shared/utils/error';
import { findMeterImportCrqById, findMeterImportCrqLog } from 'redux/actions/meterImportCrq';
import { importCrqLogListSelector, importCrqByIdSelector } from 'redux/selectors';
import { useBreadcrumbs } from 'app/shared/hooks/useBreadcrumbs';
import { getRoute } from 'app/shared/pipes';
import { RelatedSystemsTabs, Routes } from 'app/shared/constants';
import { ISort } from '../../../../common/sort';
import { usePagination } from '../../../shared/components/pagination';
import { useQueryParams } from '../../../shared/hooks/useQueryParams';

export const ImportCrqLog = () => {
    const dispatch = useDispatch<BaseDispatch>();
    const [isLoadingCrqFile, setIsLoadingCrqFile] = useState<boolean>(false);
    const [isLoadingLog, setIsLoadingLog] = useState<boolean>(false);
    const params = useParams<{importCrqFileId?: string}>();
    const queryParams = useQueryParams();
    const importCrqFileId = params.importCrqFileId;
    const importCrq = useSelector(importCrqByIdSelector(importCrqFileId));
    const { dataTotal, available: importCrqLog } = useSelector(importCrqLogListSelector);

    const withErrors = queryParams.get('errors') === '' ? 'only' : undefined;

    const fetchImportCrqFile = async (id: string) => {
        setIsLoadingCrqFile(true);
        try {
            await dispatch(findMeterImportCrqById(id));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoadingCrqFile(false);
    };

    const fetchLog = async (sort: ISort, id: string = importCrqFileId) => {
        setIsLoadingLog(true);
        try {
            await dispatch(findMeterImportCrqLog(id, withErrors, sort));
        } catch (e) {
            dispatch(createSnackbar({
                type: 'red',
                message: getErrorMessage(e),
            }));
        }
        setIsLoadingLog(false);
    };

    const { pagination, setOrder, setPage, onFetch, orderParams } = usePagination({
        page: 1,
        rowsPerPage: 10,
        orderBy: null,
        orderDirection: null,
    }, fetchLog);

    useEffect(() => {
        if (importCrqFileId) {
            fetchImportCrqFile(importCrqFileId);
            onFetch(pagination, importCrqFileId);
        }
    }, [importCrqFileId]);

    const { breadcrumbs, onClickBreadcrumb } = useBreadcrumbs([
        { name: 'Смежные системы', link: getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.MeterActivationOrders }) },
        { name: 'Импорт ПУ', link: getRoute(Routes.relatedSystems, {}, { tab: RelatedSystemsTabs.ImportMeterActivationOrders }) },
        { name: 'Информация о файле', link: getRoute(Routes.importCrqLog, { importCrqFileId }) },
    ]);

    return View({
        isLoading: isLoadingCrqFile || isLoadingLog,
        importCrq,
        importCrqLog,
        breadcrumbs,
        onClickBreadcrumb,
        pagination,
        setOrder,
        setPage,
        orderParams,
        dataTotal,
    });
};
