import React from 'react';
import { useSelector } from 'react-redux';
import { meterSelector } from '../../../../redux/selectors/meters';
import { IStateProps, Props } from './model';
import { View } from './view';

export const Reading: React.FC<Props> = (ownProps) => {

    const meter = useSelector(meterSelector);

    const props: IStateProps = {
        ...ownProps,
        meter,
    };

    return View(props);
};
